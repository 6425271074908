import { AxiosResponse } from 'axios';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { SetStateAction, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { EventDetails, ExtendedAccident, PolicyV2 } from '../../../backendsdk';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import { OE_ROLES } from '../Users/UsersComponent';
import AccidentModal from './AccidentModal';
import AccidentModalV1 from './AccidentV1/AccidentModal';
import { EMPTY_ACCIDENT_DETAILS } from './Defs';

dayjs.extend(utc);

export const ACCIDENT_TYPES = [
    'Not clear',
    'Rear end',
    'Right side',
    'Left side',
    'Blind-Spot',
    'Minor collision between motor vehicles',
    'Major collision between motor vehicles',
    'Pedestrian collision',
    'Lost load',
    'Rollover',
    'Jackknife',
    'Damage while stopping / parking',
    'Else',
];

export interface AccidentDetailsComponentProps {
    pageVersion: string;
    accidentId: string;
    refreshTable: CallableFunction;
    changeAccidentStage?: (accidentId: number, stage: string) => Promise<void>;
    setIsLoadingModal: CallableFunction;
    handlingStatusList: Array<string>;
    setHandlingStatusList: CallableFunction;
    onClose: CallableFunction;
    setSelectedAccident?: CallableFunction;
    policies?: PolicyV2[];
    loadingPolicies?: boolean;
}

export interface Alert {
    message: string;
    type: 'info' | 'success' | 'error';
    duration?: number;
}

const AccidentDetailsComponent: React.FC<AccidentDetailsComponentProps> = (props: AccidentDetailsComponentProps) => {
    const [details, setDetails] = useState<ExtendedAccident>();
    const [eventDetails, setEventDetails] = useState<EventDetails>();
    const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [alertElement, setAlert] = useAlert();
    const location = useLocation();
    const history = useHistory();
    const query = useQuery();
    const { t } = useTranslation();
    const { api, agencyApi } = useApi();
    const { profile } = useProfile();

    const allowUpdate = OE_ROLES.includes(profile.role) || details?.stage !== 'progress';

    useEffect(() => {
        if (details?.event && eventDetails?.event_id && details.event !== eventDetails?.event_id) {
            loadEventDetails(details.event, false);
        }
    }, [details?.event, eventDetails?.event_id]);

    const handleChange = (cb: SetStateAction<ExtendedAccident | undefined>) => {
        setDetailsChanged(() => {
            setDetails(cb);
            return true;
        });
    };

    const loadDetails = (res: AxiosResponse<ExtendedAccident>, updateAccidents = false, newAccident = false) => {
        const newDetails = Object.fromEntries(
            Object.entries(res.data).map(([key, origin_value]) => [key, origin_value === null ? '' : origin_value]),
        );

        setDetails(newDetails as ExtendedAccident);
        if (updateAccidents) {
            if (newAccident) {
                props.refreshTable((prev: ExtendedAccident[]) => {
                    return [newDetails, ...prev];
                });
            } else {
                props.refreshTable((prev: ExtendedAccident[]) => {
                    return prev.map((acc) => {
                        if (acc.accident_id === newDetails.accident_id) {
                            return newDetails;
                        }
                        return acc;
                    });
                });
            }
        }
        if (newDetails.event) {
            loadEventDetails(newDetails.event, true);
        }
        setDetailsChanged(false);
    };

    const loadEventDetails = (eventId: number, useAgencyBucket: boolean) => {
        const method = useAgencyBucket
            ? (eventId: number) => agencyApi.agencyV0EventEventIdGet({ eventId })
            : (eventId: number) => api.apiV0EventEventIdGet({ eventId });
        method(eventId)
            .then((res) => {
                setEventDetails(res.data);
            })
            .catch(() => null);
    };

    const getDetails = () => {
        props.setIsLoadingModal(true);
        agencyApi
            .agencyV1AccidentAccidentIdGet({ accidentId: parseInt(props.accidentId) })
            .then((res) => {
                if (props.setSelectedAccident) {
                    props.setSelectedAccident(res.data);
                }
                loadDetails(res);
                props.setIsLoadingModal(false);
            })
            .catch(() => {
                props.setIsLoadingModal(false);
                setAlert({ message: t('content.accidents.not_found'), type: 'error', duration: 6000 });
            });
    };

    useEffect(() => {
        const eventID = query.get('event_id');
        if (eventID) {
            loadEventDetails(parseInt(eventID), false);
        }
    }, [query.toString()]);

    useEffect(() => {
        setEventDetails(undefined);
        if (parseInt(props.accidentId) > 0) {
            getDetails();
        } else {
            let newAccident = EMPTY_ACCIDENT_DETAILS;
            if (profile.sub_fleet) {
                newAccident = { ...newAccident, sub_fleet_name: profile.sub_fleet };
            }
            setDetails(newAccident as ExtendedAccident);
        }
    }, [props.accidentId]);

    if (details == undefined) {
        return alertElement;
    }

    const updateAccidentDetails = (updatedDetails?: ExtendedAccident) => {
        const newDetails = updatedDetails ? updatedDetails : details;
        setSending(true);
        setDetailsChanged(false);
        let future;
        if (!allowUpdate) {
            future = agencyApi.agencyV1AccidentAccidentIdGet({
                accidentId: newDetails.accident_id,
            });
        } else if (newDetails.accident_id > 0) {
            future = agencyApi.agencyV1AccidentAccidentIdPatch({
                accidentId: newDetails.accident_id,
                extendedAccident: { ...newDetails },
            });
        } else {
            future = agencyApi.agencyV1AccidentPost({ extendedAccident: newDetails });
        }
        return future
            .then((res) => {
                loadDetails(res, true, newDetails.accident_id === 0);
                if (newDetails.accident_id === 0) {
                    history.push(`${location.pathname.replace('/0', `/${res.data.accident_id}`)}?${query.toString()}`);
                }
            })
            .catch(() => {
                setDetailsChanged(true);
                setAlert({ message: t('content.accidents.form.error'), type: 'error', duration: 6000 });
            })
            .finally(() => setSending(false));
    };

    const Component = props.pageVersion === 'v1' ? AccidentModalV1 : AccidentModal;

    return (
        <>
            {alertElement}
            <Component
                {...props}
                open={true}
                onClose={() => props.onClose()}
                details={details}
                setDetails={setDetails}
                eventDetails={eventDetails}
                handleChange={handleChange}
                sending={sending}
                setSending={setSending}
                onSave={updateAccidentDetails}
                refreshDetails={getDetails}
                detailsChanged={detailsChanged}
                handlingStatusList={props.handlingStatusList}
                setHandlingStatusList={props.setHandlingStatusList}
                setAlert={setAlert}
                allowUpdate={allowUpdate}
                setAccidents={props.refreshTable}
            />
        </>
    );
};

export default AccidentDetailsComponent;
