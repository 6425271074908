import BarChartIcon from '@mui/icons-material/BarChart';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import UmbrellaIcon from '@mui/icons-material/Umbrella';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import React from 'react';

import { UserProfile, UserRole } from '../backendsdk';

React;

export interface PageDefs {
    name: string;
    path: string[];
    permissions: Record<string, UserRole[]>;
    customers?: string[];
    exclude?: [string, UserRole][];
    new?: boolean;
}

export const flattenRoles = (page: PageDefs) => {
    return Object.values(page.permissions).flat();
};

export const isAllowedCustomer = (pageDef: PageDefs, profile: UserProfile) => {
    const denied =
        pageDef.exclude !== undefined &&
        pageDef.exclude.some(([customer, role]) => {
            return profile.customer.name.toLocaleLowerCase() === customer && profile.role === role;
        });
    const allowed =
        pageDef.customers === undefined ||
        pageDef.customers.includes(profile.customer.name.toLocaleLowerCase()) ||
        profile.role === UserRole.OeAdmin;
    return allowed && !denied;
};

export const fleetOverviewPageDefs: PageDefs = {
    name: 'overview',
    path: ['/overview/:licensePlate/:tripId', '/overview/:licensePlate', '/overview'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev],
        L2: [
            UserRole.OeInsuranceOperator,
            UserRole.FleetAdmin,
            UserRole.FleetExecutive,
            UserRole.FleetSafetyManager,
            UserRole.FleetInsuranceOperator,
        ],
    },
};

export const malfunctionsPageDefs: PageDefs = {
    name: 'malfunctions',
    path: ['/malfunctions/:malfunctionId/:modeStr?', '/malfunctions'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
        L2: [
            UserRole.FleetAdmin,
            UserRole.FleetExecutive,
            UserRole.FleetSafetyManager,
            UserRole.FleetInsuranceOperator,
            UserRole.Technician,
        ],
    },
};

export const fleetDriversPageDefs: PageDefs = {
    name: 'drivers',
    path: ['/fleet_drivers'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
    new: true,
};

export const fleetVehiclesPageDefs: PageDefs = {
    name: 'vehicles',
    path: ['/fleet_vehicles'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
    new: true,
};

export const wellnessPageDefs: PageDefs = {
    name: 'wellness',
    path: ['/wellness/:licensePlate', '/wellness'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
        L2: [
            UserRole.FleetAdmin,
            UserRole.FleetExecutive,
            UserRole.FleetSafetyManager,
            UserRole.FleetInsuranceOperator,
        ],
    },
};

export const findVideoPageDefs: PageDefs = {
    name: 'find_video',
    path: ['/find_video/:commandId', '/find_video'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
        L2: [
            UserRole.FleetAdmin,
            UserRole.FleetExecutive,
            UserRole.FleetSafetyManager,
            UserRole.FleetInsuranceOperator,
        ],
    },
    exclude: [['danbadarom', UserRole.FleetSafetyManager]],
};

export const vehiclesPageDefs: PageDefs = {
    name: 'vehicles',
    path: ['/vehicles/:licensePlate', '/vehicles'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
        L2: [UserRole.FleetAdmin, UserRole.FleetExecutive, UserRole.FleetSafetyManager],
    },
};

export const eventFeedPageDefs: PageDefs = {
    name: 'event_feed',
    path: ['/event_feed/:eventId', '/event_feed'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
};

export const coachingPageDefs: PageDefs = {
    name: 'coaching',
    path: ['/coaching/:sessionId/send', '/coaching/:sessionId/play', '/coaching/:sessionId', '/coaching'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator, UserRole.FleetAdmin],
        L2: [UserRole.FleetExecutive, UserRole.FleetSafetyManager],
    },
};

export const eventsPageDefs: PageDefs = {
    name: 'events',
    path: ['/events'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
        L2: [UserRole.FleetAdmin, UserRole.FleetExecutive, UserRole.FleetSafetyManager],
    },
};

export const metricsBaseURL = '/metrics';

const metricsPermissions = {
    L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    L2: [UserRole.FleetAdmin, UserRole.FleetExecutive, UserRole.FleetSafetyManager, UserRole.FleetInsuranceOperator],
};

export const safetyMetricsPageDefs: PageDefs = {
    name: 'safety_metrics',
    path: [metricsBaseURL, '/metrics/safety'],
    permissions: metricsPermissions,
};

export const wellnessMetricsPageDefs: PageDefs = {
    name: 'wellness_metrics',
    path: ['/metrics/wellness'],
    permissions: metricsPermissions,
};

export const accidentsMetricsPageDefs: PageDefs = {
    name: 'accidents_metrics',
    path: ['/metrics/accidents'],
    permissions: metricsPermissions,
};

export const driverLogsPageDefs: PageDefs = {
    name: 'driver_logs',
    path: ['/driver_logs/:driverId/:dateStr/:requestId?', '/driver_logs/:driverId', '/driver_logs'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
};

export const violationsPageDefs: PageDefs = {
    name: 'violations',
    path: ['/violations'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
};

export const unassignedPageDefs: PageDefs = {
    name: 'unassigned',
    path: ['/unassigned'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
};

export const logEditsPageDefs: PageDefs = {
    name: 'log_edits',
    path: ['/log_edits'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
};

export const eldMalfunctionsPageDefs: PageDefs = {
    name: 'eld_malfunctions',
    path: ['/eld_malfunctions'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
};

export const policiesPageDefs: PageDefs = {
    name: 'policies',
    path: ['/policies'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
};

export const accidentsPageDefs: PageDefs = {
    name: 'accidents',
    path: [
        '/accidents/:accidentId/command/:commandId',
        '/accidents/:accidentId/trip/:tripId',
        '/accidents/:accidentId',
        '/accidents',
    ],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator, UserRole.FleetAdmin],
        L2: [UserRole.FleetExecutive, UserRole.FleetInsuranceOperator],
        L3: [UserRole.FleetSafetyManager, UserRole.CarShop],
    },
};

export const premiumOptimizationPageDefs: PageDefs = {
    name: 'Premium Optimization',
    path: ['/premium_optimization'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
        L2: [UserRole.FleetAdmin, UserRole.FleetExecutive],
    },
};

export const usersPageDefs: PageDefs = {
    name: 'users',
    path: ['/users'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.FleetAdmin],
    },
};

export const driversPageDefs: PageDefs = {
    name: 'drivers',
    path: ['/drivers'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
    },
};

export const reportsPageDefs: PageDefs = {
    name: 'reports',
    path: ['/reports/:reportType/:reportAlertId', '/reports'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator, UserRole.FleetAdmin],
        L2: [UserRole.FleetExecutive, UserRole.FleetSafetyManager],
        L3: [UserRole.FleetInsuranceOperator],
    },
};

export const operationsPageDefs: PageDefs = {
    name: 'operations',
    path: ['/operations'],
    permissions: {
        L1: [UserRole.OeAdmin],
    },
};

export const otaPageDefs: PageDefs = {
    name: 'ota',
    path: ['/ota'],
    permissions: {
        L1: [UserRole.OeAdmin],
    },
};

export const CustomerConfigPageDefs: PageDefs = {
    name: 'customer_config',
    path: ['/customer_config'],
    permissions: {
        L1: [UserRole.OeAdmin],
    },
};

export const userSettingsPageDefs: PageDefs = {
    name: 'user_settings',
    path: ['/user_settings'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.OeInsuranceOperator],
        L2: [
            UserRole.FleetAdmin,
            UserRole.FleetExecutive,
            UserRole.FleetSafetyManager,
            UserRole.FleetInsuranceOperator,
            UserRole.CarShop,
            UserRole.Technician,
        ],
    },
};

export const organizationSettingsPageDefs: PageDefs = {
    name: 'organization_settings',
    path: ['/organization_settings'],
    permissions: {
        L1: [UserRole.OeAdmin, UserRole.OeDev, UserRole.FleetAdmin],
    },
    new: true,
};

export const sections = ['fleet', 'safety', 'metrics', 'compliance', 'insurance', 'settings', 'internal'];

export type Section = typeof sections[number];

export const pagesInSections: Record<Section, PageDefs[]> = {
    fleet: [fleetOverviewPageDefs, malfunctionsPageDefs, reportsPageDefs, findVideoPageDefs],
    safety: [eventFeedPageDefs, vehiclesPageDefs, coachingPageDefs, eventsPageDefs, wellnessPageDefs],
    metrics: [safetyMetricsPageDefs, wellnessMetricsPageDefs, accidentsMetricsPageDefs],
    insurance: [policiesPageDefs, accidentsPageDefs],
    settings: [usersPageDefs, organizationSettingsPageDefs, fleetDriversPageDefs, fleetVehiclesPageDefs],
    internal: [operationsPageDefs, otaPageDefs, CustomerConfigPageDefs],
};

export const getSectionIcons = (fontSize = 18): Record<Section, JSX.Element> => {
    return {
        fleet: <LocalShippingIcon sx={{ fontSize: fontSize }} />,
        safety: <VerifiedUserIcon sx={{ fontSize: fontSize }} />,
        metrics: <BarChartIcon sx={{ fontSize: fontSize }} />,
        compliance: <ContentPasteIcon sx={{ fontSize: fontSize }} />,
        insurance: <UmbrellaIcon sx={{ fontSize: fontSize, transform: 'rotate(180deg)' }} />,
        settings: <SettingsIcon sx={{ fontSize: fontSize }} />,
        internal: <HomeRepairServiceIcon sx={{ fontSize: fontSize }} />,
    };
};
