import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Box, Card, Checkbox, FormControlLabel, Slide, SxProps, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Link as RouterLink } from 'react-router-dom';

import { CoachEventDetailsActionEnum, CoachMetricDataPoint } from '../../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
} from '../../../../../components/TrackedComponents';
import useApi from '../../../../../hooks/api';
import useDrivers from '../../../../../hooks/drivers';
import useProfile from '../../../../../hooks/profile';
import palette from '../../../../ColorPalette';
import { PHONE_NUMBER_MASK_MAP } from '../../../UserSettings/UserSettings';
import { isValidPhoneNumber } from '../../../Users/validation';

interface QuickCoachingModalProps {
    isOpen: boolean;
    licensePlate: string;
    driverToken?: number | null;
    onSend: CallableFunction;
    onClose: CallableFunction;
    eventId: number;
    sessionId?: number | null;
    setAlert: CallableFunction;
    containerRef?: React.RefObject<HTMLDivElement>;
    dialog?: boolean;
    darkMode?: boolean;
}

const QuickCoachingModal: React.FC<QuickCoachingModalProps> = (props: QuickCoachingModalProps) => {
    const [phoneNumber, setPhoneNumber] = React.useState<string>('');
    const [message, setMessage] = React.useState<string>('');
    const [isSending, setIsSending] = React.useState<boolean>(false);
    const [safetyScore, setSafetyScore] = React.useState<CoachMetricDataPoint[] | null>();
    const [addSafetyScore, setAddSafetyScore] = React.useState<boolean>(false);
    const [driverConsent, setDriverConsent] = React.useState<boolean>(false);
    const { drivers, isLoading: isDriverLoading } = useDrivers();
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { api } = useApi();

    const themeColor = props.darkMode ? palette.neutral[50] : undefined;
    const themeInputColorSx: SxProps = {
        // Root class for the input field
        '& .MuiOutlinedInput-root': {
            color: themeColor,
            // Class for the border around the input field
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: themeColor,
            },
        },
        // Class for the label of the input field
        '& .MuiInputLabel-outlined': {
            color: themeColor,
        },
    };
    themeInputColorSx['&:hover'] = { ...themeInputColorSx };

    useEffect(() => {
        if (!isDriverLoading && drivers.length > 0) {
            const driver = drivers.find((d) => d.token === props.driverToken);
            if (driver) {
                setPhoneNumber(driver.phone_number);
            }
        }
    }, [drivers, isDriverLoading, props.driverToken, props.isOpen]);

    useEffect(() => {
        api.apiV0CoachMetricsGet({ licensePlate: props.licensePlate })
            .then((response) => {
                setSafetyScore(response.data.safety.current);
            })
            .catch(() => {
                setSafetyScore(null);
                setAddSafetyScore(false);
            });
    }, [props.licensePlate]);

    useEffect(() => {
        if (!props.isOpen) {
            setPhoneNumber('');
            setMessage('');
            setAddSafetyScore(false);
            setDriverConsent(false);
        }
    }, [props.isOpen]);

    const handleQuickCoaching = async () => {
        setIsSending(true);
        const coachResponse = await api.apiV0EventEventIdCoachPost({
            eventId: props.eventId,
            coachEventDetails: {
                action: CoachEventDetailsActionEnum.Add,
                comment: message,
            },
        });
        const sessionId = coachResponse.data?.session_id;
        if (!sessionId) {
            props.setAlert({
                message: t('content.accidents.modal_header.share.quick_coaching_error'),
                type: 'error',
                duration: 6000,
            });
            setIsSending(false);
            return;
        }
        if (addSafetyScore && sessionId && !!safetyScore) {
            await api
                .apiV0CoachSessionIdMetricsPost({
                    sessionId: sessionId,
                    coachMetricsRequest: {
                        score_type: 'safety',
                        time_frame: 'current',
                        metric_data: safetyScore,
                        additional_data: 'none',
                        session_id: sessionId,
                    },
                })
                .catch(() => null); // Ignore errors
        }
        await api
            .apiV1CoachSendPost({
                coachSessionMessageRequest: {
                    drivers: [
                        {
                            session_id: sessionId,
                            phone_number: phoneNumber,
                            message: message,
                        },
                    ],
                    assignee: profile.user_id,
                },
            })
            .then(() => {
                props.setAlert({
                    message: t('content.safety.save_success'),
                    link: (
                        <Link id="view-coaching-session-link" component={RouterLink} to={`/coaching/${sessionId}`}>
                            {t('content.safety.view_session')}
                        </Link>
                    ),
                    type: 'success',
                    duration: 6000,
                });
                props.onSend(sessionId);
            })
            .catch(() => {
                props.setAlert({
                    message: t('content.accidents.modal_header.share.quick_coaching_error'),
                    type: 'error',
                    duration: 6000,
                });
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const phoneInput: any = () => {
        const inputElement = (
            <TextField
                id="driver-phone-number"
                size="small"
                label={t('content.accidents.modal_header.share.phone_number')}
                inputProps={{ 'data-testid': 'driver-phone-number', style: { direction: 'ltr' } }}
                sx={{ mt: 1, mb: 1.5, ...themeInputColorSx }}
            />
        );
        return inputElement;
    };

    const isLoadingScore = safetyScore === undefined;
    let safetyScoreLabel;
    if (isLoadingScore) {
        safetyScoreLabel = t('content.accidents.modal_header.share.safety_score_loading');
    } else if (safetyScore) {
        if (safetyScore[0].score === -1) {
            safetyScoreLabel = `${t('content.accidents.modal_header.share.add_safety_score')} - ${t(
                `content.safety.score_${safetyScore[0].reason_for_no_score}`,
            )}`;
        } else {
            safetyScoreLabel = t('content.accidents.modal_header.share.add_safety_score_with_score', {
                score: safetyScore[0].score,
            });
        }
    } else {
        safetyScoreLabel = t('content.accidents.modal_header.share.safety_score_unavailable');
    }

    const sendButton = (
        <Button
            id="btn-send"
            data-testid="btn-send"
            variant={props.dialog ? 'contained' : 'outlined'}
            color="secondary"
            disabled={
                !isValidPhoneNumber(phoneNumber) || !driverConsent || isSending || (isLoadingScore && addSafetyScore)
            }
            onClick={handleQuickCoaching}
            fullWidth
            sx={{
                '&:disabled': {
                    color: props.darkMode ? palette.neutral[400] : undefined,
                    borderColor: props.darkMode ? palette.neutral[400] : undefined,
                },
            }}
        >
            {props.sessionId ? t('content.accidents.modal_header.share.resend_button') : t('content.events.send')}
        </Button>
    );

    const content = (
        <>
            <InputMask
                mask={PHONE_NUMBER_MASK_MAP[profile.customer.settings.country]}
                value={phoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                maskChar=""
                color="secondary"
            >
                {phoneInput}
            </InputMask>
            <TextField
                id="message-field"
                data-testid="message-field"
                label={t('content.events.message')}
                multiline
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
                variant="outlined"
                sx={{ mb: 1.5, ...themeInputColorSx }}
            />
            <FormControlLabel
                label={safetyScoreLabel}
                slotProps={{
                    typography: {
                        variant: 'caption',
                        sx: { color: themeColor },
                    },
                }}
                control={
                    <Checkbox
                        id="add-safety-score"
                        checked={addSafetyScore}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddSafetyScore(e.target.checked)}
                        disabled={safetyScore === null}
                        sx={{
                            color: themeColor,
                            '&.Mui-checked': {
                                color: themeColor,
                            },
                        }}
                    />
                }
            />
            <FormControlLabel
                label={t('content.coach.send_session.driver_consent')}
                slotProps={{
                    typography: {
                        variant: 'caption',
                        sx: { color: themeColor },
                    },
                }}
                control={
                    <Checkbox
                        id="driver-consent"
                        data-testid="driver-consent"
                        checked={driverConsent}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDriverConsent(e.target.checked)}
                        sx={{
                            color: themeColor,
                            '&.Mui-checked': {
                                color: themeColor,
                            },
                        }}
                    />
                }
            />
        </>
    );

    return props.dialog ? (
        <Dialog id="send-quick-coaching-dialog" open={props.isOpen} maxWidth="xs" fullWidth onClose={() => null}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                    minHeight: 0,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SendIcon sx={{ mr: 0.5 }} />
                    <Typography>{t('content.accidents.modal_header.share.send_coaching')}</Typography>
                </Box>
            </Box>

            <Box sx={{ p: 1 }}>
                <Card sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>{content}</Card>
                <Box sx={{ mt: 1, width: '100%', display: 'flex' }}>
                    <Button
                        id="btn-cancel"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => props.onClose()}
                        sx={{ mr: 1 }}
                    >
                        {t('content.events.cancel')}
                    </Button>
                    {sendButton}
                </Box>
            </Box>
        </Dialog>
    ) : (
        <Slide in={props.isOpen} direction="up" unmountOnExit container={props.containerRef?.current}>
            <Box
                sx={{
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    minHeight: '300px',
                    maxHeight: '50%',
                    zIndex: 10,
                    backgroundColor: 'primary.main',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexShrink: 0,
                        minHeight: 0,
                        mb: 1,
                    }}
                >
                    <Typography fontSize={18} sx={{ color: palette.neutral[50] }}>
                        {props.sessionId
                            ? t('content.accidents.modal_header.share.resend_coaching')
                            : t('content.accidents.modal_header.share.send_coaching')}
                    </Typography>
                    <IconButton size="small" id="btn-close-modal" onClick={() => props.onClose()}>
                        <CloseIcon sx={{ color: palette.neutral[50] }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        height: '100%',
                        width: '100%',
                        overflowY: 'scroll',
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 1,
                    }}
                >
                    {content}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', minHeight: 0, flexShrink: 0 }}>
                    <Box sx={{ width: '40%' }}>{sendButton}</Box>
                </Box>
            </Box>
        </Slide>
    );
};

export default QuickCoachingModal;
