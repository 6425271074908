import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import { Dialog, ListItem, ListItemIcon, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Artifact } from '../../../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../../../components/TrackedComponents';
import palette from '../../../../ColorPalette';
import AccidentArtifactActionComponent from '../../AccidentArtifactsAction';

interface ActifactActionsProps {
    accident_id: number;
    artifact: Artifact;
    canDeleteFiles: boolean;
    customerTags: Array<string>;
    setCustomerTags: CallableFunction;
    setAlert: CallableFunction;
    onSave: CallableFunction;
}

const ArtifactActionsComponent: React.FC<ActifactActionsProps> = (props: ActifactActionsProps) => {
    const [action, setAction] = useState<[Artifact | undefined, string]>([undefined, '']);
    const { t } = useTranslation();

    return (
        <ListItem disableGutters alignItems="center" key={props.artifact.artifact_id}>
            <ListItemIcon
                sx={{
                    minWidth: '30px',
                    m: 0,
                    p: 0,
                }}
            >
                <Tooltip title={t('content.accidents.files.send')}>
                    <IconButton
                        id={`${props.artifact.artifact_id}-mail`}
                        size="small"
                        onClick={() => setAction([props.artifact, 'mail'])}
                    >
                        <EmailIcon fontSize="small" sx={{ color: palette.neutral[200] }} />
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
            <ListItemIcon
                sx={{
                    minWidth: '30px',
                    m: 0,
                    p: 0,
                }}
            >
                <Tooltip title={t('content.accidents.files.edit')}>
                    <IconButton
                        id={`${props.artifact.artifact_id}-edit`}
                        size="small"
                        onClick={() => setAction([props.artifact, 'edit'])} // TODO: change
                    >
                        <EditIcon fontSize="small" sx={{ color: palette.neutral[200] }} />
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
            {props.canDeleteFiles ? (
                <ListItemIcon
                    sx={{
                        minWidth: '30px',
                        m: 0,
                        p: 0,
                    }}
                >
                    <Tooltip title={t('content.accidents.files.delete')}>
                        <IconButton
                            id={`${props.artifact.artifact_id}-delete`}
                            size="small"
                            onClick={() => setAction([props.artifact, 'delete'])}
                        >
                            <DeleteIcon fontSize="small" sx={{ color: palette.neutral[200] }} />
                        </IconButton>
                    </Tooltip>
                </ListItemIcon>
            ) : null}
            {action[0] != undefined ? (
                <Dialog id="artifact-action-dialog" open={true} onClose={() => setAction([undefined, ''])}>
                    <AccidentArtifactActionComponent
                        {...props}
                        artifact={action[0]}
                        action={action[1]}
                        handleClick={(refresh = true) => {
                            setAction([undefined, '']);
                            if (refresh) {
                                props.onSave();
                            }
                        }}
                    />
                </Dialog>
            ) : null}
        </ListItem>
    );
};

export default ArtifactActionsComponent;
