import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import UploadIcon from '@mui/icons-material/Upload';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MediaFile } from '../../../../backendsdk';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import palette from '../../../ColorPalette';
import FileDetailsDialog from './FileDetailsDialog';
import MediaLibraryItem from './MediaLibraryItem';

interface MediaLibraryProps {
    setAlert: CallableFunction;
    onClose: CallableFunction;
    onSelect: CallableFunction;
}

const MediaLibrary: React.FC<MediaLibraryProps> = (props: MediaLibraryProps) => {
    const [files, setFiles] = useState<MediaFile[]>([]);
    const [expandedTreeItems, setExpandedTreeItems] = React.useState<string[]>([]);
    const [selectedTreeItem, setSelectedTreeItem] = React.useState<string>();
    const [selectedFile, setSelectedFile] = useState<File | MediaFile>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [fileToDelete, setFileToDelete] = useState<MediaFile>();
    const [dragAndDropHover, setDragAndDropHover] = useState<boolean>(false);
    const { api } = useApi();
    const { t } = useTranslation();

    useEffect(() => {
        api.apiV0MediaFilesGet()
            .then((res) => {
                setFiles(res.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsError(true);
                setIsLoading(false);
            });
    }, []);

    const handleSelect = (_event: React.SyntheticEvent, nodeId: string) => {
        setSelectedTreeItem(nodeId);
    };

    const handleToggle = (_event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpandedTreeItems(nodeIds);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const inputFiles = Array.from(e.target.files);
            const inputFile = inputFiles[0];
            if (inputFile.size > 100000000) {
                props.setAlert({
                    message: t('content.coach.add_item.media_file.library.error.size'),
                    type: 'error',
                    duration: 6000,
                });
            } else {
                setSelectedFile(inputFile);
            }
        }
    };

    const handleDelete = () => {
        if (!!fileToDelete) {
            setIsLoadingAction(true);
            api.apiV0MediaFilesMediaFileIdDelete({
                mediaFileId: fileToDelete.id,
            })
                .then(() => {
                    setFiles((prev: MediaFile[]) => prev.filter((item) => item.id !== fileToDelete.id));
                    setFileToDelete(undefined);
                    setIsLoadingAction(false);
                })
                .catch(() => {
                    props.setAlert({
                        message: t('content.coach.add_item.media_file.library.error.delete'),
                        type: 'error',
                        duration: 6000,
                    });
                    setIsLoadingAction(false);
                });
        }
    };

    const fileMap = {
        public: files.filter((file) => file.is_public),
        private: files.filter((file) => !file.is_public),
    };
    const isFileSelected = !!selectedTreeItem && files.map((f) => f.id.toString()).includes(selectedTreeItem);

    let content;
    if (isLoading) {
        content = <CircularProgress />;
    } else if (isError) {
        content = (
            <Typography variant="overline" color="error">
                {t('content.coach.add_item.media_file.library.error.load')}
            </Typography>
        );
    } else {
        content = (
            <>
                <Paper
                    onDragOver={(e: React.DragEvent) => {
                        // Prevent default behavior (Prevent file from being opened)
                        e.preventDefault();
                        setDragAndDropHover(true);
                    }}
                    onDragEnter={() => {
                        setDragAndDropHover(true);
                    }}
                    onDrop={(e: React.DragEvent) => {
                        // Prevent default behavior (Prevent file from being opened)
                        e.preventDefault();
                        if (e.dataTransfer.files.length > 1) {
                            props.setAlert({
                                message: t('content.coach.add_item.media_file.library.error.multiple_files'),
                                type: 'error',
                                duration: 6000,
                            });
                        } else {
                            const fileToUpload = e.dataTransfer.files[0];
                            const fileType = fileToUpload.type.split('/')[0];
                            if (fileType !== 'image' && fileType !== 'video') {
                                props.setAlert({
                                    message: t('content.coach.add_item.media_file.library.error.type'),
                                    type: 'error',
                                    duration: 6000,
                                });
                            } else {
                                setSelectedFile(fileToUpload);
                            }
                        }
                        setDragAndDropHover(false);
                    }}
                    onDragLeave={() => {
                        setDragAndDropHover(false);
                    }}
                    onDragEnd={() => {
                        setDragAndDropHover(false);
                    }}
                    onDragExit={() => {
                        setDragAndDropHover(false);
                    }}
                    sx={{
                        width: '100%',
                        height: '100%',
                        p: 1,
                        overflowY: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {dragAndDropHover ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <UploadIcon fontSize="large" sx={{ color: palette.neutral[500] }} />
                            <Typography sx={{ color: palette.neutral[500] }}>
                                {t(`content.coach.add_item.media_file.library.drop_here`)}
                            </Typography>
                        </Box>
                    ) : files.length > 0 ? (
                        <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            selected={selectedTreeItem}
                            expanded={expandedTreeItems}
                            onNodeToggle={handleToggle}
                            onNodeSelect={handleSelect}
                            sx={{ width: '100%', height: '100%', userSelect: 'none' }}
                        >
                            {Object.entries(fileMap).map(([category, relevantFiles]) =>
                                relevantFiles.length === 0 ? null : (
                                    <TreeItem
                                        key={category}
                                        id={`${category}-tree-item`}
                                        data-testid={`${category}-tree-item`}
                                        nodeId={category}
                                        label={
                                            <Box sx={{ display: 'flex', alignItems: 'center', height: 40 }}>
                                                <Typography>
                                                    {t(`content.coach.add_item.media_file.library.${category}`)}
                                                </Typography>
                                            </Box>
                                        }
                                    >
                                        {relevantFiles.map((file) => (
                                            <MediaLibraryItem
                                                key={file.id}
                                                file={file}
                                                setSelectedFile={setSelectedFile}
                                                setFiles={setFiles}
                                                setAlert={props.setAlert}
                                                setFileToDelete={setFileToDelete}
                                            />
                                        ))}
                                    </TreeItem>
                                ),
                            )}
                        </TreeView>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="overline" sx={{ lineHeight: 1.5 }}>
                                {t('content.coach.add_item.media_file.library.empty1')}
                            </Typography>
                            <Typography variant="overline" sx={{ lineHeight: 1.5 }}>
                                {t('content.coach.add_item.media_file.library.empty2')}
                            </Typography>
                        </Box>
                    )}
                </Paper>
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    <Button
                        id="btn-upload-file"
                        data-testid="btn-upload-file"
                        variant="contained"
                        component="label"
                        fullWidth
                        sx={{ mr: 1 }}
                    >
                        {t('content.coach.add_item.media_file.library.upload_file')}
                        <input
                            id="file-field"
                            data-testid="file-field"
                            hidden
                            multiple
                            type="file"
                            accept="image/*,video/*"
                            onChange={handleFileChange}
                        />
                    </Button>
                    <Button
                        id="btn-select-file"
                        data-testid="btn-select-file"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={!isFileSelected}
                        onClick={() => {
                            const fileToSelect = files.find((f) => f.id.toString() === selectedTreeItem);
                            if (!!fileToSelect) {
                                props.onSelect(fileToSelect);
                                props.onClose();
                            }
                        }}
                    >
                        {t('content.coach.add_item.media_file.library.select')}
                    </Button>
                </Box>
            </>
        );
    }

    return (
        <Dialog
            id="media-library-modal"
            open={true}
            fullWidth
            onClose={() => props.onClose()}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '450px',
                        height: '350px',
                    },
                },
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 4, top: 4 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
                    <PermMediaIcon sx={{ mr: 0.5 }} />
                    <Typography>{t('content.coach.add_item.media_file.library.header')}</Typography>
                </Box>
            </Box>
            <Box sx={{ p: 1, width: '100%', height: '100%', minHeight: 0 }}>
                {!!selectedFile ? (
                    <FileDetailsDialog
                        selectedFile={selectedFile}
                        setFiles={setFiles}
                        onClose={() => setSelectedFile(undefined)}
                        setAlert={props.setAlert}
                        setExpandedTreeItems={setExpandedTreeItems}
                    />
                ) : null}
                <ConfirmDialog
                    isDialogOpen={!!fileToDelete}
                    isLoading={isLoadingAction}
                    headerText={t('content.coach.add_item.media_file.library.delete_file_header')}
                    bodyText={[
                        t('content.coach.add_item.media_file.library.confirm_delete1'),
                        t('content.coach.add_item.media_file.library.confirm_delete2'),
                    ]}
                    onClose={() => {
                        if (!isLoadingAction) {
                            setFileToDelete(undefined);
                        }
                    }}
                    onConfirm={() => handleDelete()}
                    buttonText={t('content.coach.add_item.media_file.library.delete')}
                    buttonColor="redColor"
                />
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {content}
                </Box>
            </Box>
        </Dialog>
    );
};

export default MediaLibrary;
