import { Box, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { CustomerConfig, DeviceConfigStatus, Overlay } from '../../../backendsdk';
import { TabPanel, a11yProps } from '../../../components/Tab';
import { TrackedTab as Tab } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import palette from '../../ColorPalette';
import CustomerConfigComponent from './CustomerConfig';
import DeviceConfigStatusComponent from './DeviceConfigStatus';

const CustomerConfigPageComponent: React.FC = () => {
    const [tab, setTab] = useState<number>(0);
    const [customers, setCustomers] = useState<Array<CustomerConfig>>([]);
    const [overlays, setOverlays] = useState<Array<Overlay>>([]);
    const [configStatuses, setConfigStatuses] = useState<Array<DeviceConfigStatus>>([]);
    const [alertElement, setAlert] = useAlert();
    const { controlApi } = useApi();

    const loadStatuses = (controller?: AbortController) => {
        controlApi
            .controlV0ConfigStatusGet({}, { signal: controller?.signal })
            .then((res) => {
                setConfigStatuses(res.data);
            })
            .catch((res) => {
                if (!controller?.signal.aborted) {
                    const message = res.response.status === 404 ? 'no_data' : 'fetch_error';
                    setAlert({
                        message: message,
                        type: 'error',
                        duration: 6000,
                    });
                }
            });
    };
    const loadOverlays = (controller?: AbortController) => {
        controlApi
            .controlV0OverlayGet({ signal: controller?.signal })
            .then((res) => {
                setOverlays(res.data.overlays);
            })
            .catch((res) => {
                if (!controller?.signal.aborted) {
                    const message = res.response.status === 404 ? 'no_data' : 'fetch_error';
                    setAlert({
                        message: message,
                        type: 'error',
                        duration: 6000,
                    });
                }
            });
    };

    const loadCustomer = (controller?: AbortController) => {
        controlApi
            .controlV0CustomerConfigGet({ signal: controller?.signal })
            .then((res) => {
                setCustomers(res.data);
            })
            .catch((res) => {
                if (!controller?.signal.aborted) {
                    const message = res.response.status === 404 ? 'no_data' : 'fetch_error';
                    setAlert({
                        message: message,
                        type: 'error',
                        duration: 6000,
                    });
                }
            });
    };

    useEffect(() => {
        const controller = new AbortController();
        loadOverlays(controller);
        loadCustomer(controller);
        loadStatuses(controller);
        return () => controller.abort();
    }, []);

    const toolbar = (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${palette.neutral[150]}`,
            }}
        >
            <Tabs
                value={tab}
                onChange={(_e, newValue) => setTab(newValue)}
                TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
            >
                <Tab
                    label={<Typography fontSize={14}>Customer Config</Typography>}
                    {...a11yProps(0)}
                    id="customer-config-tab"
                    data-testid="tab-customer-config"
                />
                <Tab
                    label={<Typography fontSize={14}>Device Statuses</Typography>}
                    {...a11yProps(1)}
                    id="device-statuses-tab"
                    data-testid="tab-device-statuses"
                />
            </Tabs>
        </Box>
    );

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                p: 1,
                pt: 0,
            }}
        >
            {alertElement}
            <TabPanel value={tab} index={0} sx={{ flexGrow: 1, minHeight: 0 }}>
                <CustomerConfigComponent
                    customers={customers}
                    overlays={overlays}
                    setCustomers={setCustomers}
                    setOverlays={setOverlays}
                    loadCustomer={loadCustomer}
                    toolbar={toolbar}
                />
            </TabPanel>
            <TabPanel value={tab} index={1} sx={{ flexGrow: 1, minHeight: 0 }}>
                <DeviceConfigStatusComponent
                    configStatuses={configStatuses}
                    loadStatuses={loadStatuses}
                    toolbar={toolbar}
                />
            </TabPanel>
        </Box>
    );
};

export default CustomerConfigPageComponent;
