import { Box, useMediaQuery, useTheme } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceV2 } from '../../../backendsdk';
import { TrackedButton as Button } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useProfile from '../../../hooks/profile';
import { fleetOverviewPageDefs, otaPageDefs } from '../../../utils/Pages';
import { DEFAULT_TITLE } from '../../Layout';
import UpdateFile from '../UpdateFile';
import MuiDeviceTable from './MuiDeviceTable';

const DeviceComponent: React.FC = () => {
    const [selectedDevice, setSelectedDevice] = useState<DeviceV2>();
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [showFileUpdate, setShowFileUpdate] = useState<boolean>(false);
    const { profile } = useProfile();
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const ALLOW_UPDATE_FILE = [...fleetOverviewPageDefs.permissions.L1];
    const { t } = useTranslation();
    const [alertElement, setAlert] = useAlert();

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${otaPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        if (!showFileUpdate) {
            setSelectionModel([]);
        }
    }, [showFileUpdate]);

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', pb: 2 }}>
            {alertElement}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    flexShrink: 1,
                    minWidth: 0,
                }}
            >
                <MuiDeviceTable
                    selectedDevice={selectedDevice}
                    setSelectedDevice={setSelectedDevice}
                    singleSelection={!showFileUpdate}
                    selectionModel={selectionModel}
                    setSelectionModel={setSelectionModel}
                    toolbar={
                        ALLOW_UPDATE_FILE.includes(profile.role) && (
                            <Button
                                id="ota-btn"
                                size="small"
                                variant={showFileUpdate ? 'contained' : 'outlined'}
                                onClick={() => setShowFileUpdate((prev) => !prev)}
                                sx={{ flexShrink: 0, height: '32px', ml: 1, mb: isMobile ? 1 : 0 }}
                            >
                                {t('content.fleet.update.update_file')}
                            </Button>
                        )
                    }
                    setAlert={setAlert}
                />
            </Box>
            {showFileUpdate ? (
                <Box
                    sx={{
                        width: '400px',
                        height: '100%',
                        flexShrink: 0,
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        minWidth: 0,
                        ml: 1,
                    }}
                >
                    <UpdateFile
                        deviceIds={selectionModel.map((id) => parseInt(id.toString()))}
                        handleShowFileUpdate={() => setShowFileUpdate((prev) => !prev)}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

export default DeviceComponent;
