import { Box, Card, Grid, Skeleton, capitalize } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import {
    CoachWellnessDetailsActionEnum,
    FleetDriver,
    FrontFacingWellness,
    InCabinWellnessV1,
} from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedLink as Link,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import mapNA from '../../../images/map_not_available.png';
import { WELLNESS_TYPE_MAP } from '../Device/WellnessBox';
import NewCoachingModal from '../Vehicles/NewCoachingModal';
import { CardField } from '../Vehicles/VehicleCard';
import { STATUS } from '../Vehicles/VehicleModal';

interface WellnessStatusDetailsProps {
    status: InCabinWellnessV1 | FrontFacingWellness;
    itemType: string;
    image?: string;
    setAlert: CallableFunction;
    handleStatusChange: CallableFunction;
    itemStatuses: Record<number, STATUS>;
    setSelectedItem: CallableFunction;
    driver?: FleetDriver;
}

const WellnessStatusDetails: React.FC<WellnessStatusDetailsProps> = (props: WellnessStatusDetailsProps) => {
    const [coachingModal, setCoachingModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const { api } = useApi();
    const { profile } = useProfile();

    const handleEventAction = (status: STATUS, comment?: string) => {
        if (status === STATUS.SAVED) {
            setCoachingModal(false);
        }
        const prevStatus = props.itemStatuses[props.status.id];
        props.handleStatusChange(props.status, status);
        api.apiV0WellnessStatusWellnessIdCoachPost({
            wellnessId: props.status.id,
            coachWellnessDetails: {
                action:
                    status === STATUS.SAVED
                        ? CoachWellnessDetailsActionEnum.Add
                        : CoachWellnessDetailsActionEnum.Remove,
                comment,
            },
        })
            .then((res) => {
                if (status === STATUS.SAVED) {
                    const link = (
                        <Link
                            id="view-coaching-session-link"
                            component={RouterLink}
                            to={`/coaching/${res.data.session_id}`}
                        >
                            {t('content.safety.view_session')}
                        </Link>
                    );
                    props.setAlert({
                        message: t('content.safety.save_success'),
                        link,
                        type: 'success',
                        duration: 6000,
                    });
                }
            })
            .catch(() => {
                props.handleStatusChange(props.status, prevStatus);
                props.setSelectedItem(props.status.id);
                const message =
                    status === STATUS.SAVED ? t('content.safety.save_fail') : t('content.safety.dismiss_fail');
                props.setAlert({ message, type: 'error', duration: 6000 });
            });
    };

    return (
        <>
            <Dialog id="new-coaching-dialog" open={coachingModal} onClose={() => setCoachingModal(false)}>
                <NewCoachingModal
                    defaultDriver={props.driver ? `${props.driver.first_name} ${props.driver.last_name}` : undefined}
                    onSave={handleEventAction}
                >
                    <Button
                        id="btn-cancel-save-for-coaching"
                        variant="outlined"
                        size="small"
                        onClick={() => setCoachingModal(false)}
                        sx={{ ml: 1 }}
                    >
                        {t('content.fleet.update.cancel')}
                    </Button>
                </NewCoachingModal>
            </Dialog>
            <Grid container columns={2} columnSpacing={1} rowSpacing={0.5}>
                <Grid item xs={2} order={1}>
                    <Box sx={{ width: '100%', height: '40px', mb: 1, mt: 0.5 }}></Box>
                    {props.image !== undefined ? (
                        <img src={props.image} style={{ width: '100%', aspectRatio: '8 / 3' }} />
                    ) : (
                        <Box sx={{ width: '100%', aspectRatio: '8 / 3', mb: 0.5 }}>
                            <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%', height: '100%' }} />
                        </Box>
                    )}
                </Grid>
                <Grid item xs={2} sm={1} order={{ xs: 3, sm: 2 }}>
                    <Card
                        sx={{
                            width: '100%',
                            height: '100%',
                            p: 1,
                            aspectRatio: { xs: '8 / 3', sm: '8 / 5' },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Grid container columns={2}>
                            <Grid item xs={1}>
                                <CardField
                                    name={t('content.wellness.camera')}
                                    value={t(`content.wellness.${props.itemType}`)}
                                    containerStyle={{ mb: 1 }}
                                />
                                <CardField
                                    name={t('content.wellness.status')}
                                    value={capitalize(
                                        t(
                                            WELLNESS_TYPE_MAP[
                                                props.status.wellness_state as keyof typeof WELLNESS_TYPE_MAP
                                            ],
                                        ),
                                    )}
                                    containerStyle={{ mb: 1 }}
                                />
                                <CardField
                                    name={t('table.headers.date')}
                                    value={dayjs.unix(props.status.timestamp).format(profile.dateFormat)}
                                    containerStyle={{ mb: 1 }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={1} order={{ xs: 2, sm: 3 }}>
                    <Card
                        sx={{
                            width: '100%',
                            aspectRatio: { xs: '8 / 3', sm: '8 / 5' },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        <img style={{ height: '100%' }} src={mapNA} />
                    </Card>
                </Grid>
                <Grid item xs={1} order={4}>
                    <Button
                        id="save-for-coaching-btn"
                        data-testid="save-for-coaching-btn"
                        variant="contained"
                        size="small"
                        fullWidth
                        color="neutral"
                        disabled={props.driver === undefined || props.itemStatuses[props.status.id] === STATUS.SAVED}
                        sx={{ mt: 0.5 }}
                        onClick={() => {
                            setCoachingModal(true);
                        }}
                    >
                        {props.itemStatuses[props.status.id] !== STATUS.SAVED
                            ? t('content.safety.save_event')
                            : t('content.safety.saved')}
                    </Button>
                </Grid>
                <Grid item xs={1} order={5}>
                    <Button
                        id="dismiss-btn"
                        data-testid="dismiss-btn"
                        variant="contained"
                        size="small"
                        fullWidth
                        color="redColor"
                        disabled={props.itemStatuses[props.status.id] === STATUS.DISMISSED}
                        sx={{ mt: 0.5 }}
                        onClick={() => handleEventAction(STATUS.DISMISSED)}
                    >
                        {props.itemStatuses[props.status.id] !== STATUS.DISMISSED
                            ? t('content.safety.dismiss')
                            : t('content.safety.dismissed')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default WellnessStatusDetails;
