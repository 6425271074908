import { Box, Grid, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { ExtendedAccident, Witness } from '../../../backendsdk';
import useApi from '../../../hooks/api';
import { MuiTextField } from './MuiFormControls';

export const EMPTY_ACCIDENT_WITNESS_DETAILS: Witness = {
    accident_id: 0,
    witness_id: 0,
    contact: {
        name: '',
        phone_number: '',
        contact_made: false,
    },
    statement: '',
};

interface AccidentWitnessFormProps {
    details?: Witness;
    accidentDetails: ExtendedAccident;
    setUpdateCallbacks: CallableFunction;
    allowUpdate: boolean;
    disabled?: boolean;
}

const AccidentWitnessForm: React.FC<AccidentWitnessFormProps> = (props: AccidentWitnessFormProps) => {
    const empty_witness_details = JSON.parse(JSON.stringify(EMPTY_ACCIDENT_WITNESS_DETAILS));
    const [details, setDetails] = useState<Witness>(props.details || empty_witness_details);
    const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
    const { t } = useTranslation();
    const { agencyApi } = useApi();
    const disabled = props.accidentDetails.accident_id === 0 || !props.allowUpdate || props.disabled;

    useEffect(() => {
        if (props.details !== undefined) {
            setDetails(props.details);
            setDetailsChanged(false);
        }
    }, [props.details]);

    useEffect(() => {
        if (detailsChanged) {
            props.setUpdateCallbacks((prev: Record<string, CallableFunction>) => {
                return { ...prev, witness: updateAccidentWitnessDetails };
            });
        }
    }, [details]);

    const recurrsiveUpdateDetails = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        recDetails: Record<string, any>, // recDetails is object by ref
        key: string,
        value: string | number | boolean,
    ) => {
        const SEP = '__';
        if (key in recDetails) {
            recDetails[key] = value;
        } else if (key.includes(SEP)) {
            const firstKey = key.split(SEP, 1)[0];
            const newKey = key.substr(firstKey.length + SEP.length, key.length);
            const newRecDetails = firstKey in recDetails ? recDetails[firstKey] : { [newKey.split(SEP, 1)[0]]: {} };
            recDetails[firstKey] = recurrsiveUpdateDetails(newRecDetails, newKey, value);
        } else {
            recDetails[key] = value;
        }
        return recDetails;
    };

    const updateAccidentWitnessDetails = () => {
        let promise;
        if (details.witness_id > 0) {
            promise = agencyApi.agencyV1AccidentAccidentIdWitnessWitnessIdPatch({
                accidentId: props.accidentDetails.accident_id,
                witnessId: details.witness_id,
                witness: details,
            });
        } else {
            promise = agencyApi.agencyV1AccidentAccidentIdWitnessPost({
                accidentId: props.accidentDetails.accident_id,
                witness: details,
            });
        }
        return promise;
    };

    const handleChange = (target: string, value: string | boolean) => {
        setDetailsChanged(true);
        (setDetails as CallableFunction)((oldDetails: Witness) => {
            const newDetails = Object.fromEntries(
                Object.entries(oldDetails).map(([key, origin_value]) => [key, key === target ? value : origin_value]),
            );
            return recurrsiveUpdateDetails(newDetails, target, value);
        });
    };

    const textOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.name, event.target.value);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Box sx={{ mb: 1 }}>
                <Typography variant="overline">{t('content.accidents.witness.header')}</Typography>
            </Box>
            <Prompt when={detailsChanged} message={t('content.accidents.prompt_msg')} />
            <Grid container columns={2} spacing={1.5}>
                <Grid item xs={1}>
                    <MuiTextField
                        id="witness-name-field"
                        label={t('content.accidents.witness.name')}
                        value={details.contact.name}
                        disabled={disabled}
                        onChange={textOnChange}
                        name="contact__name"
                    />
                </Grid>
                <Grid item xs={1}>
                    <MuiTextField
                        id="witness-phone-number"
                        label={t('content.accidents.witness.phone_number')}
                        value={details.contact.phone_number}
                        disabled={disabled}
                        onChange={textOnChange}
                        name="contact__phone_number"
                    />
                </Grid>
                <Grid item xs={2}>
                    <MuiTextField
                        id="witness-statement"
                        label={t('content.accidents.witness.statement')}
                        value={details.statement}
                        onChange={textOnChange}
                        disabled={disabled}
                        name="statement"
                        multiline={true}
                        rows={2}
                        sx={{ height: '100%' }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AccidentWitnessForm;
