import CloseIcon from '@mui/icons-material/Close';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Box, Paper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedDialog as Dialog, TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import ResetForm from '../../Login/ResetForm';

interface ChangePasswordModalProps {
    onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = (props: ChangePasswordModalProps) => {
    const { t } = useTranslation();

    return (
        <Dialog id="change-password-dialog" open={true} fullWidth={true} maxWidth="xs" onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LockResetIcon sx={{ mr: 0.5 }} />
                    {t('content.user_settings.user_details.change_password')}
                </Box>
            </Box>
            <Paper sx={{ backgroundColor: 'bgColor.main', pt: 1, borderRadius: 0 }}>
                <ResetForm onSuccess={() => props.onClose()} errorMessage={'content.safety.change_password_error'} />
            </Paper>
        </Dialog>
    );
};

export default ChangePasswordModal;
