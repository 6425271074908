import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Fade, List, ListItemButton, Button as MuiButton, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseAccidentV2 } from '../../../../backendsdk';
import useProfile from '../../../../hooks/profile';
import { getStorageItem, setStorageItem } from '../../../../utils/Storage';
import { HOURS_IN_DAY } from '../../../../utils/TimeFormatter';
import palette from '../../../ColorPalette';
import { RTLDirectionContext } from '../../../Layout';
import { IL_STAGES, STAGE_ICONS, Stage, US_STAGES } from '../Defs';

interface AccidentStagesProps {
    stageAccidents: Record<string, BaseAccidentV2[]>;
    currentStage: string;
    onStageClick: (stage: string) => void;
}

export const ACCIDENT_STAGE_WIDTH = 300;
const ACCIDENT_MINIMIZED_KEY = 'OE-accident-stage-mini';

export const AccidentStages: React.FC<AccidentStagesProps> = (props) => {
    const [showMinimizeButton, setShowMinimizeButton] = useState<boolean>(false);
    const initialMinimized = getStorageItem(ACCIDENT_MINIMIZED_KEY) == 'true';
    const [isMinimized, setIsMinimized] = useState<boolean>(initialMinimized);
    const [showContent, setShowContent] = useState<boolean>(!initialMinimized);
    const { profile } = useProfile();
    const stages = profile.customer.settings.country == 'il' ? IL_STAGES : US_STAGES;
    const allStages: Array<Stage | 'all'> = ['all', ...stages];
    const isRTL = useContext(RTLDirectionContext);

    const ExpandIcon = isRTL ? ArrowForwardIosIcon : ArrowBackIosNewIcon;
    const MinimizeIcon = isRTL ? ArrowBackIosNewIcon : ArrowForwardIosIcon;

    useEffect(() => {
        setStorageItem(ACCIDENT_MINIMIZED_KEY, isMinimized.toString(), 7 * HOURS_IN_DAY);
    }, [isMinimized]);

    const panelWidth = !isMinimized ? ACCIDENT_STAGE_WIDTH : 65;

    return (
        <Box
            onMouseEnter={() => {
                setShowMinimizeButton(true);
            }}
            onMouseLeave={() => {
                if (!isMinimized) {
                    setShowMinimizeButton(false);
                }
            }}
            sx={{
                width: panelWidth,
                height: '100%',
                backgroundColor: palette.bgColor,
                boxShadow: 1,
                flexShrink: 0,
                overflowY: 'auto',
                minHeight: 0,
                transition: 'width 0.3s',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: '50px',
                    height: '50px',
                    left: panelWidth - 12.5,
                    transition: 'left 0.3s',
                    top: '110px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Fade in={showMinimizeButton}>
                    <MuiButton
                        id="minimize-sidepanel"
                        variant="contained"
                        disableElevation
                        onClick={() =>
                            setIsMinimized((prev) => {
                                if (!prev) {
                                    setShowContent(false);
                                } else {
                                    setTimeout(() => setShowContent(true), 250);
                                }
                                return !prev;
                            })
                        }
                        sx={{
                            p: 0,
                            borderRadius: '50%',
                            minWidth: 0,
                            height: '25px',
                            width: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                            zIndex: 1001,
                        }}
                    >
                        {isMinimized ? (
                            <MinimizeIcon sx={{ fontSize: 14, ml: '2px' }} />
                        ) : (
                            <ExpandIcon sx={{ fontSize: 14 }} />
                        )}
                    </MuiButton>
                </Fade>
            </Box>
            <List disablePadding sx={{ display: 'flex', flexDirection: 'column' }}>
                {allStages.map((stage: Stage | 'all') => {
                    const accidents =
                        stage == 'all' ? Object.values(props.stageAccidents).flat() : props.stageAccidents[stage] || [];
                    const accidentCount = accidents.length;
                    return (
                        <AccidentStage
                            key={stage}
                            name={stage}
                            lastUpdated={Math.max(...accidents.map((accident) => accident.updated_at))}
                            accidentCount={accidentCount}
                            isCurrentStage={props.currentStage === stage}
                            onClick={() => props.onStageClick(stage)}
                            showContent={showContent}
                        />
                    );
                })}
            </List>
        </Box>
    );
};

interface AccidentStageProps {
    name: Stage | 'all';
    accidentCount: number;
    isCurrentStage: boolean;
    lastUpdated: number;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    showContent: boolean;
}

const AccidentStage: React.FC<AccidentStageProps> = (props) => {
    const { t } = useTranslation();
    const { profile } = useProfile();

    const lastUpdatedText =
        props.lastUpdated > 0
            ? t('content.accidents.stage.last_updated', {
                  date: dayjs.unix(props.lastUpdated).format(profile.dateTimeFormat),
              })
            : t('content.accidents.stage.no_accidents');
    const badgeNumber = (
        <Box
            sx={{
                height: 25,
                width: 25,
                backgroundColor: palette.neutral[200],
                border: 1,
                borderColor: palette.neutral[300],
                borderRadius: '50%',
                textAlign: 'center',
            }}
        >
            <Typography
                data-testid={`${props.name}-accidents-count`}
                data-count={props.accidentCount}
                sx={{ fontSize: 13, fontWeight: 500 }}
            >
                {props.accidentCount}
            </Typography>
        </Box>
    );
    const stageIcon = STAGE_ICONS[props.name];

    const content = props.showContent ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                {stageIcon}
                <Typography variant="h6">
                    {t(`content.accidents.stage.${profile.customer.settings.country}.${props.name}`)}
                </Typography>
            </Box>
            <Typography color={palette.gray[600]}>{lastUpdatedText}</Typography>
        </Box>
    ) : (
        <Tooltip
            title={t(`content.accidents.stage.${profile.customer.settings.country}.${props.name}`)}
            placement="right"
        >
            <Box sx={{ display: 'flex', p: 0.5 }}>{stageIcon}</Box>
        </Tooltip>
    );

    return (
        <ListItemButton
            id={`${props.name}-stage`}
            data-testid={`${props.name}-stage`}
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'space-between',
                borderBottom: props.showContent ? 1 : undefined,
                borderColor: palette.neutral[300],
                gap: 1,
            }}
            onClick={props.onClick}
            selected={props.isCurrentStage}
        >
            {content}
            {props.showContent ? badgeNumber : null}
        </ListItemButton>
    );
};
