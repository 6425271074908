import { Box, CircularProgress, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { TrackedLink as Link } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import { driverLogsPageDefs } from '../../../../utils/Pages';
import { DEFAULT_TITLE } from '../../../Layout';
import DriverCountersDataGrid from './DriverCountersDataGrid';
import DriverLog from './DriverLog';
import { DRIVERS } from './driversData';

dayjs.extend(utc);

const DriverLogsComponent: React.FC = () => {
    const { api } = useApi();
    const { data, isLoading, isError } = useQuery({
        queryKey: ['drivers'],
        queryFn: () => api.hosV0SettingsCustomerGet().then((res) => res.data),
    });
    const { driverId } = useParams<{ driverId: string }>();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const history = useHistory();

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${driverLogsPageDefs.name}`)}`;
    }, []);

    if (isLoading) {
        return <CircularProgress />;
    } else if (isError) {
        return (
            <Typography variant="overline">
                Error fetching drivers logs.{' '}
                <Link
                    id="try-again-link"
                    component="button"
                    variant="overline"
                    onClick={() => queryClient.refetchQueries(['drivers'])}
                >
                    Try again.
                </Link>
            </Typography>
        );
    }

    const selectedDriver = data.drivers?.find((d) => d.driver_id.toString() === driverId);

    if (driverId !== undefined && selectedDriver === undefined) {
        history.replace('/driver_logs');
    }

    return (
        <Box sx={{ width: '100%', height: '100%', pb: 2 }}>
            {selectedDriver === undefined ? (
                <DriverCountersDataGrid drivers={DRIVERS} />
            ) : (
                <DriverLog now={dayjs().utc()} driver={selectedDriver} />
            )}
        </Box>
    );
};

export default DriverLogsComponent;
