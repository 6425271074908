import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Box, Grid, ImageList, ImageListItem, ImageListItemBar, Switch, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedAccident } from '../../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
} from '../../../../../components/TrackedComponents';
import useApi from '../../../../../hooks/api';
import useProfile from '../../../../../hooks/profile';
import palette from '../../../../ColorPalette';
import { SAFETY_MANAGER_DELETE_FILES_ROLE } from '../../../../Layout';
import { ExistingAccidentArtifactComponent, NewAccidentArtifactComponent } from '../../AccidentArtifacts';
import AccidentDamageDisposalModal from '../../AccidentDamageDisposalModal';
import AccidentNoticeFormComponent from '../../AccidentNoticeFormModal';
import { IMAGE_FILE_EXTENTION } from '../../Defs';
import { MuiTextField } from '../../MuiFormControls';
import ArtifactActionsComponent from './AccidentArtifactsActions';

interface BaseAccidentArtifactComponentProps {
    details: ExtendedAccident;
    files: Array<File> | null;
    onSave: CallableFunction;
    handleChange: CallableFunction;
    setFiles: CallableFunction;
    setAlert: CallableFunction;
    isImageList: boolean;
    setIsImageList: CallableFunction;
    disabled?: boolean;
}

const AccidentArtifactComponent: React.FC<BaseAccidentArtifactComponentProps> = (
    props: BaseAccidentArtifactComponentProps,
) => {
    const [customerTags, setCustomerTags] = useState<Array<string>>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [showDisposalLetterModal, setShowDisposalLetterModal] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const { details, files } = props;
    const { t } = useTranslation();
    const { agencyApi } = useApi();
    const { profile } = useProfile();
    const disabled = props.details.accident_id === 0 || props.disabled;
    const canDeleteFiles = profile.admin || profile.roles.includes(SAFETY_MANAGER_DELETE_FILES_ROLE);

    useEffect(() => {
        agencyApi.agencyV1AccidentArtifactTagsGet().then((response) => {
            setCustomerTags(response.data);
        });
    }, []);

    useEffect(() => {
        if (!showSearch) {
            setSearchText('');
        }
    }, [showSearch]);

    const disposalLetterButton = (
        <Button
            id="disposal-btn"
            variant="contained"
            size="small"
            disabled={disabled || props.details.damage_list.length === 0}
            onClick={() => setShowDisposalLetterModal(true)}
            sx={{ width: '100%' }}
        >
            {t('content.accidents.damage.disposal_letter.create')}
        </Button>
    );

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <Typography variant="overline">{t('content.accidents.files.header')}</Typography>
                </Box>
                {details.artifact_list.length > 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mr: 1, height: 34 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', p: 1, pb: 0 }}>
                            <ListIcon />
                            <Switch
                                onClick={() => props.setIsImageList((prev: boolean) => !prev)}
                                checked={props.isImageList}
                                color="default"
                            />
                            <ViewModuleIcon />
                        </Box>
                        {showSearch ? (
                            <Box>
                                <MuiTextField
                                    id="search-artifact"
                                    name="search-artifact"
                                    fullWidth={false}
                                    value={searchText}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                                    sx={{ mr: 1 }}
                                />
                            </Box>
                        ) : null}
                        <Tooltip title={t(`content.accidents.files.${showSearch ? 'close_search' : 'open_search'}`)}>
                            <IconButton id="btn-search" size="small" onClick={() => setShowSearch((prev) => !prev)}>
                                {showSearch ? <SearchOffIcon /> : <SearchIcon />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : null}
            </Box>

            {details.artifact_list.length === 0 ? (
                <Box sx={{ px: 1 }}>
                    <Typography variant="caption">{t('content.accidents.files.no_files')}</Typography>
                </Box>
            ) : props.isImageList ? (
                <ImageList sx={{ maxHeight: '50vh', justifyItems: 'center', p: 1 }} cols={3} rowHeight={164}>
                    {details.artifact_list.map((artifact) => (
                        <ImageListItem key={artifact.artifact_id} sx={{ width: '100%', height: 164, scroll: 'clip' }}>
                            <Link
                                id={`artifact-${artifact.artifact_id}-open`}
                                title={artifact.file_name}
                                onClick={() => {
                                    const newWindow = window.open(artifact.url, '_blank', 'noopener,noreferrer');
                                    if (newWindow) {
                                        newWindow.opener = null;
                                    }
                                }}
                                sx={{ cursor: 'pointer', width: '100%', height: 164, scroll: 'clip' }}
                            >
                                {IMAGE_FILE_EXTENTION.includes(artifact.file_name.split('.').pop() || '') ? (
                                    <img
                                        srcSet={artifact.url}
                                        src={artifact.url}
                                        alt={artifact.file_name}
                                        loading="lazy"
                                        style={{ objectFit: 'contain', width: '100%', height: 164 }}
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: palette.neutral[500],
                                        }}
                                    >
                                        <InsertDriveFileIcon sx={{ fontSize: 80, mx: 1 }} />
                                    </Box>
                                )}
                                <ImageListItemBar
                                    title={artifact.tag}
                                    subtitle={artifact.file_name}
                                    actionIcon={
                                        // avoid open the file on action clicked
                                        <Box onClick={(e) => e.stopPropagation()}>
                                            <ArtifactActionsComponent
                                                accident_id={details.accident_id}
                                                artifact={artifact}
                                                canDeleteFiles={canDeleteFiles}
                                                customerTags={customerTags}
                                                setCustomerTags={setCustomerTags}
                                                setAlert={props.setAlert}
                                                onSave={props.onSave}
                                            />
                                        </Box>
                                    }
                                />
                            </Link>
                        </ImageListItem>
                    ))}
                </ImageList>
            ) : (
                <Box sx={{ maxHeight: '10rem', overflowY: 'scroll', p: 1 }}>
                    <ExistingAccidentArtifactComponent
                        accident_id={details.accident_id}
                        artifact_list={details.artifact_list}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        onSave={props.onSave}
                        files={null}
                        customerTags={customerTags}
                        setCustomerTags={setCustomerTags}
                        setAlert={props.setAlert}
                    />
                </Box>
            )}
            <NewAccidentArtifactComponent
                accident_id={details.accident_id}
                onSave={props.onSave}
                files={files}
                setFiles={props.setFiles}
                customerTags={customerTags}
                setCustomerTags={setCustomerTags}
                open={open}
                setOpen={setOpen}
                setAlert={props.setAlert}
            />
            <Box sx={{ p: 1 }}>
                <Grid container columns={3} columnSpacing={1}>
                    <Grid item xs={1}>
                        <Button
                            id="add-file-btn"
                            variant="contained"
                            size="small"
                            disabled={disabled}
                            onClick={() => setOpen(true)}
                            sx={{ width: '100%' }}
                        >
                            {t('content.accidents.files.add')}
                        </Button>
                    </Grid>
                    <AccidentNoticeFormComponent {...props} />
                    <Grid item xs={1}>
                        {props.details.damage_list.length === 0 && !disabled ? (
                            <Tooltip title={t('content.accidents.damage.disposal_letter.third_party_required')}>
                                <Box>{disposalLetterButton}</Box>
                            </Tooltip>
                        ) : (
                            disposalLetterButton
                        )}
                    </Grid>
                </Grid>
            </Box>
            {props.details.damage_list.length > 0 ? (
                <AccidentDamageDisposalModal
                    {...props}
                    details={props.details}
                    show={showDisposalLetterModal}
                    onClose={() => setShowDisposalLetterModal(false)}
                />
            ) : null}
        </>
    );
};

export default AccidentArtifactComponent;
