import { Box, CircularProgress, Typography } from '@mui/material';
import { latLngBounds } from 'leaflet';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer } from 'react-leaflet';

import { isLocationValid, parseLocation } from '../../../../utils/location';
import MapControls from '../../Overview/MapControls';
import AccidentMapIcons from './AccidentMapIcons';

interface AccidentsMapProps {
    locations: string[][];
    setSelectedAccidentId: CallableFunction;
    isLoading: boolean;
}

const AccidentsMap: React.FC<AccidentsMapProps> = (props: AccidentsMapProps) => {
    const [isClustered, setIsClustered] = React.useState<boolean>(true);
    const { t } = useTranslation();

    const [validLocationMap, accidentLocations] = useMemo(() => {
        const validLocations = props.locations.filter((item) => isLocationValid(item[1]));
        const validLocationMap = Object.fromEntries(validLocations.map((item) => [item[0], parseLocation(item[1])]));
        const accidentLocations = Object.values(validLocationMap);
        return [validLocationMap, accidentLocations];
    }, [props.locations]);

    if (props.isLoading || accidentLocations.length === 0) {
        return (
            <Box
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                {props.isLoading ? (
                    <CircularProgress />
                ) : (
                    <Typography variant="overline">{t('content.accident_overview.no_accident_locations')}</Typography>
                )}
            </Box>
        );
    }

    const bounds = latLngBounds(accidentLocations).pad(0.1);

    return (
        <MapContainer
            className="accident-map"
            zoomSnap={0.5}
            id="map-widget"
            style={{ height: '100%', width: '100%' }}
            bounds={bounds}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapControls
                isClustered={isClustered}
                setIsClustered={setIsClustered}
                bounds={bounds}
                isSelectedElement={false}
                isGeofenceMode={false}
            />
            <AccidentMapIcons
                locationMap={validLocationMap}
                isClustered={isClustered}
                setSelectedAccidentId={props.setSelectedAccidentId}
            />
        </MapContainer>
    );
};

export default AccidentsMap;
