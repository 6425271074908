import { Box, CircularProgress, Typography, circularProgressClasses } from '@mui/material';
import React from 'react';

interface CircularProgressComponentProps {
    dismissed: number;
    total: number;
    isOdd: boolean;
}

const CircularProgressComponent: React.FC<CircularProgressComponentProps> = (props) => {
    const progress = props.dismissed / props.total;
    const progressColor = progress < 0.5 ? '#E66D57' : progress < 0.97 ? '#E6CF57' : '#62CE47';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                width: '100%',
                height: 60,
                color: progress ? progressColor : 'lightgray',
            }}
        >
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{ color: !props.isOdd ? '#e0e0e0' : '#cdcdcd' }}
                    size={60}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    disableShrink
                    sx={{
                        color: progressColor,
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    value={progress * 100}
                    size={60}
                    thickness={4}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {props.dismissed}/{props.total}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default CircularProgressComponent;
