import { CommandData } from '../../../backendsdk';
import { normalizeLicensePlate, normalizeName } from '../../../utils/Str';
import { getRequestStatus, statusToColor } from './RequestList';

export const sortingOptions = ['created_at', 'license_plate', 'sub_fleet', 'start_time', 'end_time', 'status'];

export const sortingFunctions: Record<string, CallableFunction> = {
    created_at: (requests: CommandData[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...requests].sort((a, b) => {
            if (a.created_at < b.created_at) {
                return -1 * factor;
            }
            if (a.created_at > b.created_at) {
                return 1 * factor;
            }
            return 0;
        });
    },
    license_plate: (requests: CommandData[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...requests].sort((a, b) => {
            if (normalizeLicensePlate(a.device.license_plate) < normalizeLicensePlate(b.device.license_plate)) {
                return -1 * factor;
            }
            if (normalizeLicensePlate(a.device.license_plate) > normalizeLicensePlate(b.device.license_plate)) {
                return 1 * factor;
            }
            return 0;
        });
    },
    sub_fleet: (requests: CommandData[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...requests].sort((a, b) => {
            if (normalizeName(a.device.customer_sub_fleet) < normalizeName(b.device.customer_sub_fleet)) {
                return -1 * factor;
            }
            if (normalizeName(a.device.customer_sub_fleet) > normalizeName(b.device.customer_sub_fleet)) {
                return 1 * factor;
            }
            return 0;
        });
    },
    start_time: (requests: CommandData[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...requests].sort((a, b) => {
            const aRequestData = JSON.parse(a.data);
            const aStartTime = aRequestData.start_time;
            const bRequestData = JSON.parse(b.data);
            const bStartTime = bRequestData.start_time;
            if (aStartTime < bStartTime) {
                return -1 * factor;
            }
            if (aStartTime > bStartTime) {
                return 1 * factor;
            }
            return 0;
        });
    },
    end_time: (requests: CommandData[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...requests].sort((a, b) => {
            const aRequestData = JSON.parse(a.data);
            const aEndTime = aRequestData.end_time;
            const bRequestData = JSON.parse(b.data);
            const bEndTime = bRequestData.end_time;
            if (aEndTime < bEndTime) {
                return -1 * factor;
            }
            if (aEndTime > bEndTime) {
                return 1 * factor;
            }
            return 0;
        });
    },
    status: (requests: CommandData[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        const colorToOrderMap = {
            [statusToColor.failure]: 1,
            [statusToColor.pending]: 2,
            [statusToColor.success]: 3,
        };

        return [...requests].sort((a, b) => {
            const aColor = statusToColor[getRequestStatus(a)];
            const bColor = statusToColor[getRequestStatus(b)];
            if (colorToOrderMap[aColor] < colorToOrderMap[bColor]) {
                return -1 * factor;
            }
            if (colorToOrderMap[aColor] > colorToOrderMap[bColor]) {
                return 1 * factor;
            }
            return 0;
        });
    },
};
