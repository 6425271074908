import * as L from 'leaflet';
import React, { useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';

import { EventDetails, RouteData } from '../../../backendsdk';
import generalIcon from '../../../images/markers/general.svg';
import offlineSvg from '../../../images/markers/overview/marker-offline.svg';
import onlineSvg from '../../../images/markers/overview/marker-online.svg';
import selectedSvg from '../../../images/markers/overview/marker-selected.svg';
import MarkerA from '../../../images/markers/overview/route-marker-a.svg';
import MarkerB from '../../../images/markers/overview/route-marker-b.svg';
import { isLocationValid } from '../../../utils/location';
import { toEventTypeText } from '../Event/EventDetailsSection';
import { eventTypeToIconMap } from '../EventMarkers';
import { getTopEvent } from './MapRoute';

const LIST_MARKER_LEGEND: Record<string, string> = {
    online: onlineSvg,
    offline: offlineSvg,
    selected: selectedSvg,
};

const ROUTE_MARKER_LEGEND: Record<string, string> = {
    start: MarkerA,
    end: MarkerB,
};

const MARKER_ICON_WIDTH = 20;
const EVENT_ICON_WIDTH = 40;

interface MapLegendProps {
    position: L.ControlPosition;
    isDeviceListView?: boolean;
    isTripView?: boolean;
    tripRoute?: RouteData;
}

const MapLegend: React.FC<MapLegendProps> = (props: MapLegendProps) => {
    const { tripRoute } = props;
    const map = useMap();
    const { t } = useTranslation();

    const getLegendItem = (key: string, label: string, icon: string, iconWidth: number, iconContainerWidth?: number) =>
        ReactDOMServer.renderToStaticMarkup(
            <div key={key} id={`${key}-legend-item`} className="map-legend-item">
                <div
                    style={{
                        width: iconContainerWidth,
                        marginRight: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <img src={icon} alt={label} width={iconWidth} />
                </div>
                <span>{label}</span>
            </div>,
        );

    useEffect(() => {
        if (!map) return;

        let relevantItems: Record<string, string> = {};
        const eventTypes = new Set<string>();
        if (props.isDeviceListView) {
            relevantItems = LIST_MARKER_LEGEND;
        } else if (props.isTripView) {
            relevantItems = ROUTE_MARKER_LEGEND;
        }
        if (tripRoute?.events && tripRoute.events.length > 0) {
            const locationToEvents = tripRoute.events.reduce((acc: Record<string, EventDetails[]>, e: EventDetails) => {
                if (e.location) {
                    acc[e.location] ??= [];
                    acc[e.location].push(e);
                }
                return acc;
            }, {} as Record<string, EventDetails[]>);

            for (const location of Object.keys(locationToEvents)) {
                const event = getTopEvent(locationToEvents[location]);
                if (isLocationValid(location)) {
                    eventTypes.add(event.type);
                }
            }
        }

        const eventTypesArray = Array.from(eventTypes);
        const eventTypesWithIcons = eventTypesArray.filter((eventType) => eventType in eventTypeToIconMap);
        const hasTypesWithoutIcons = eventTypesArray.length > eventTypesWithIcons.length;
        const legend = L.Control.extend({
            options: {
                position: props.position,
            },
            onAdd: () => {
                const container = L.DomUtil.create('div', `map-legend`);
                const iconContainerWidth = eventTypes.size > 0 ? EVENT_ICON_WIDTH : undefined;
                const legendItems = Object.entries(relevantItems).map(([key, icon]) => {
                    return getLegendItem(
                        key,
                        t(`content.fleet.map.legend.${key}`),
                        icon,
                        MARKER_ICON_WIDTH,
                        iconContainerWidth,
                    );
                });
                for (const eventType of eventTypesWithIcons) {
                    legendItems.push(
                        getLegendItem(
                            eventType,
                            t(toEventTypeText(eventType)),
                            eventTypeToIconMap[eventType],
                            EVENT_ICON_WIDTH,
                            iconContainerWidth,
                        ),
                    );
                }
                if (hasTypesWithoutIcons) {
                    legendItems.push(
                        getLegendItem(
                            'other',
                            t('content.fleet.map.legend.other'),
                            generalIcon,
                            EVENT_ICON_WIDTH,
                            iconContainerWidth,
                        ),
                    );
                }
                container.innerHTML = legendItems.join('');
                return container;
            },
        });
        const attribution = L.control.attribution({ position: props.position }).addTo(map);
        const control = new legend();
        control.addTo(map);

        return () => {
            control.remove();
            attribution.remove();
        };
    }, [map, props.isDeviceListView, props.tripRoute]);

    return null;
};

export default MapLegend;
