import React from 'react';

interface FixedContainerProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const FixedContainer: React.FC<FixedContainerProps> = (props) => (
    <div
        style={{
            width: '100%',
            aspectRatio: '4 / 3',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ...props.style,
        }}
    >
        {props.children}
    </div>
);

export default FixedContainer;
