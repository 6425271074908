import { GridColDef, getGridNumericOperators, getGridStringOperators } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';

import { TrackedDataGrid as StyledDataGrid } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import palette from '../../../ColorPalette';
import { FilterInput, durationFormatter, durationParser } from '../DriverLogs/DriverCountersDataGrid';

export interface AnnotatedRow {
    id: number;
    device: string;
    startTime: string;
    endTime: string;
    duration: number;
    annotation: string;
}

interface AnnotatedDataGridProps {
    rows: AnnotatedRow[];
    setSelectionModel: CallableFunction;
}

const AnnotatedDataGrid: React.FC<AnnotatedDataGridProps> = (props: AnnotatedDataGridProps) => {
    const isMobile = useIsMobile();

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'device',
                headerName: 'Device',
                width: 150,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'startTime',
                headerName: 'Start Time',
                width: 250,
                filterable: false,
                sortable: false,
            },
            {
                field: 'endTime',
                headerName: 'End Time',
                width: 250,
                filterable: false,
                sortable: false,
            },
            {
                field: 'duration',
                type: 'number',
                headerName: 'Duration',
                width: 80,
                valueFormatter: durationFormatter,
                filterOperators: getGridNumericOperators().map((operator) => ({
                    ...operator,
                    InputComponent: FilterInput,
                })),
                valueParser: durationParser,
            },
            {
                field: 'annotation',
                headerName: 'Annotation',
                minWidth: 200,
                flex: 1,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
        ],
        [],
    );

    return (
        <StyledDataGrid
            id="annotated-data-grid"
            rows={props.rows}
            columns={columns}
            columnBuffer={5}
            checkboxSelection
            disableVirtualization={isMobile}
            pagination={isMobile}
            onRowSelectionModelChange={(newSelectionModel) => {
                props.setSelectionModel(newSelectionModel);
            }}
            slotProps={{
                columnsPanel: {
                    sx: {
                        '& .MuiDataGrid-panelFooter button:first-child': {
                            display: 'none',
                        },
                    },
                },
            }}
            sx={{
                height: '100%',
                width: '100%',
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: palette.neutral[50],
            }}
        />
    );
};

export default AnnotatedDataGrid;
