import { Box, Typography } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';

import { useAlert } from '../../../hooks/alert';
import EventDetailsComponent from '../Event/EventDetails';
import { eventTypeToIcon } from '../EventMarkers';
import { MapData, PROHUB_MIXPANEL_SESSION } from './Defs';

interface ClusterMapComponentProps {
    header: string;
    details: MapData;
}

const ClusterMapComponent: React.FC<ClusterMapComponentProps> = (props) => {
    const [selectedEventId, setSelectedEventId] = useState<string>();
    const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(false);
    const [alertElement, setAlert] = useAlert();
    const defaultMapPosition = props.details.location;

    const handleMarkerClick = (eventId: number) => {
        mixpanel.track('prohub', {
            name: `event ${eventId} clicked`,
            session_id: PROHUB_MIXPANEL_SESSION,
        });
        setSelectedEventId(eventId.toString());
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
                height: '100%',
            }}
        >
            {alertElement}
            {!!selectedEventId ? (
                <EventDetailsComponent
                    eventId={selectedEventId}
                    setAlert={setAlert}
                    isLoading={isLoadingEvent}
                    setIsLoading={setIsLoadingEvent}
                    isVisible={!!selectedEventId}
                    setIsVisible={() => setSelectedEventId(undefined)}
                    onEventsPage={false}
                    setSelectedEventId={setSelectedEventId}
                    showEditVideo
                    showFindVideo
                />
            ) : null}
            <Box
                sx={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    flexShrink: 0,
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    position: 'relative',
                }}
            >
                <MapContainer
                    center={defaultMapPosition}
                    zoom={15}
                    scrollWheelZoom={false}
                    dragging={false}
                    doubleClickZoom={false}
                    attributionControl={false}
                    zoomControl={false}
                    style={{ height: '100%', width: '100%' }}
                    touchZoom={false}
                    boxZoom={false}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <MapContentComponent position={defaultMapPosition} />
                    {props.details.events.map((event, idx) => {
                        const [eventType, eventId, lattitude, longitude] = event;
                        return (
                            <Marker
                                key={idx}
                                icon={eventTypeToIcon(eventType)}
                                position={[lattitude, longitude]}
                                eventHandlers={{
                                    click: () => handleMarkerClick(eventId),
                                }}
                            />
                        );
                    })}
                </MapContainer>
            </Box>
            <Box>
                <Typography sx={{ fontSize: '18', fontWeight: 'bold' }}>{props.header}</Typography>
                <Typography sx={{ fontSize: '18' }}>{props.details.description}</Typography>
            </Box>
        </Box>
    );
};

interface MapContentComponentProps {
    position: L.LatLng | [number, number];
}

const MapContentComponent: React.FC<MapContentComponentProps> = (props: MapContentComponentProps) => {
    const map = useMap();

    useEffect(() => {
        map.setView(props.position, map.getZoom());
    }, [map, props.position]);

    return null;
};

export default ClusterMapComponent;
