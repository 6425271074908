import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import {
    GRID_DATETIME_COL_DEF,
    GRID_DATE_COL_DEF,
    GridCellParams,
    GridColTypeDef,
    GridFilterInputValueProps,
    GridFilterItem,
    GridRenderEditCellParams,
    useGridApiContext,
} from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import localeEn from 'date-fns/locale/en-US';
import localeHe from 'date-fns/locale/he';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React from 'react';

import { ResponsiveDatePicker, ResponsiveDateTimePicker } from '../components/MuiDatePicker';
import useProfile from '../hooks/profile';

dayjs.extend(isBetween);

React;

export const localeObjectMap: Record<string, Locale> = {
    en: localeEn,
    he: localeHe,
};

function buildApplyDateFilterFn(
    filterItem: GridFilterItem,
    compareFn: (value1: number, value2: number) => boolean,
    showTime = false,
) {
    if (!filterItem.value) {
        return null;
    }

    // Make a copy of the date to not reset the hours in the original object
    const filterValueCopy = new Date(filterItem.value);

    const filterValueMs = filterValueCopy.getTime();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ({ value }: GridCellParams<any, Date>): boolean => {
        if (!value) {
            return false;
        }

        // Make a copy of the date to not reset the hours in the original object
        const dateCopy = new Date(value);
        dateCopy.setHours(showTime ? value.getHours() : 0, showTime ? value.getMinutes() : 0, 0, 0);
        const cellValueMs = dateCopy.getTime();

        return compareFn(cellValueMs, filterValueMs);
    };
}

const getDateFilterOperators = (
    dateAdapter: AdapterDateFns,
    showTime = false,
    minDate: Dayjs | undefined = undefined,
): GridColTypeDef['filterOperators'] => {
    const operators: GridColTypeDef['filterOperators'] = [
        {
            value: 'is',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 === value2, showTime);
            },
            InputComponent: (props) => GridFilterDateInput({ ...props, dateAdapter, minDate }),
            InputComponentProps: { showTime },
        },
        {
            value: 'not',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 !== value2, showTime);
            },
            InputComponent: (props) => GridFilterDateInput({ ...props, dateAdapter, minDate }),
            InputComponentProps: { showTime },
        },
        {
            value: 'after',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 > value2, showTime);
            },
            InputComponent: (props) => GridFilterDateInput({ ...props, dateAdapter, minDate }),
            InputComponentProps: { showTime },
        },
        {
            value: 'onOrAfter',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 >= value2, showTime);
            },
            InputComponent: (props) => GridFilterDateInput({ ...props, dateAdapter, minDate }),
            InputComponentProps: { showTime },
        },
        {
            value: 'before',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 < value2, showTime);
            },
            InputComponent: (props) => GridFilterDateInput({ ...props, dateAdapter, minDate }),
            InputComponentProps: { showTime },
        },
        {
            value: 'onOrBefore',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 <= value2, showTime);
            },
            InputComponent: (props) => GridFilterDateInput({ ...props, dateAdapter, minDate }),
            InputComponentProps: { showTime },
        },
        {
            value: 'isEmpty',
            getApplyFilterFn: () => {
                return ({ value }): boolean => {
                    return value == null;
                };
            },
            requiresFilterValue: false,
        },
        {
            value: 'isNotEmpty',
            getApplyFilterFn: () => {
                return ({ value }): boolean => {
                    return value != null;
                };
            },
            requiresFilterValue: false,
        },
    ];
    if (showTime) {
        operators.push({
            value: 'onDate',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) =>
                        dayjs(value1).isBetween(dayjs(value2).startOf('day'), dayjs(value2).endOf('day')),
                    showTime,
                );
            },
            InputComponent: (props) => GridFilterDateInput({ ...props, dateAdapter, minDate }),
        });
    }
    return operators;
};

/**
 * `date` column
 */

export const getDateColumnType = (locale: Locale): GridColTypeDef<Date, string> => {
    const dateAdapter = new AdapterDateFns({ locale });
    return {
        ...GRID_DATE_COL_DEF,
        renderEditCell: (params) => {
            return <GridEditDateCell dateAdapter={dateAdapter} {...params} />;
        },
        filterOperators: getDateFilterOperators(dateAdapter),
    };
};

const GridEditDateInput = styled(InputBase)({
    fontSize: 'inherit',
    padding: '0 9px',
});

function WrappedGridEditDateInput(props: TextFieldProps) {
    const { InputProps, ...other } = props;
    return <GridEditDateInput fullWidth {...InputProps} {...(other as InputBaseProps)} />;
}

function GridEditDateCell({
    id,
    field,
    value,
    colDef,
    dateAdapter,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
GridRenderEditCellParams<any, Date | string | null> & { dateAdapter: AdapterDateFns }) {
    const apiRef = useGridApiContext();
    const { profile } = useProfile();

    const Component = colDef.type === 'dateTime' ? ResponsiveDateTimePicker : ResponsiveDatePicker;

    const handleChange = (newValue: unknown) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <Component
            value={value}
            onChange={handleChange}
            slots={{ textField: WrappedGridEditDateInput }}
            slotProps={{ textField: { id: `${field}-input` } }}
            dayOfWeekFormatter={(day: string, date: Date) => {
                const dayOfWeek = dateAdapter.getWeekdays()[date.getDay()];
                return !!dayOfWeek ? dayOfWeek : day;
            }}
            format={colDef.type === 'dateTime' ? profile.dataGridDateTimeFormat : profile.dataGridDateFormat}
        />
    );
}

function GridFilterDateInput(
    props: GridFilterInputValueProps & { dateAdapter: AdapterDateFns; showTime?: boolean; minDate: Dayjs | undefined },
) {
    const { item, showTime, applyValue, apiRef } = props;
    const { profile } = useProfile();

    const Component = showTime ? ResponsiveDateTimePicker : ResponsiveDatePicker;

    const handleFilterChange = (newValue: unknown) => {
        applyValue({ ...item, value: newValue });
    };

    return (
        <Component
            value={item.value || null}
            label={apiRef.current.getLocaleText('filterPanelInputLabel')}
            slotProps={{
                textField: {
                    variant: 'standard',
                },
                inputAdornment: {
                    sx: {
                        '& .MuiButtonBase-root': {
                            marginRight: -1,
                        },
                    },
                },
            }}
            onChange={handleFilterChange}
            dayOfWeekFormatter={(day: string, date: Date) => {
                const dayOfWeek = props.dateAdapter.getWeekdays()[date.getDay()];
                return !!dayOfWeek ? dayOfWeek : day;
            }}
            format={showTime ? profile.dataGridDateTimeFormat : profile.dataGridDateFormat}
            minDate={props.minDate?.toDate()}
        />
    );
}

/**
 * `dateTime` column
 */

export const getDateTimeColumnType = (
    locale: Locale,
    minDate: Dayjs | undefined = undefined,
): GridColTypeDef<Date, string> => {
    const dateAdapter = new AdapterDateFns({ locale });
    return {
        ...GRID_DATETIME_COL_DEF,
        renderEditCell: (params) => {
            return <GridEditDateCell dateAdapter={dateAdapter} {...params} />;
        },
        filterOperators: getDateFilterOperators(dateAdapter, true, minDate),
    };
};
