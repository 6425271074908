import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { Box, CircularProgress, List, ListItem, SxProps, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommandData, DeviceV3 } from '../../../backendsdk';
import { TrackedListItemButton as ListItemButton } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import { formatTime } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';

export const statusToColor: Record<string, string> = {
    pending: palette.yellow[500],
    success: palette.green[900],
    failure: palette.red[700],
};

export type RequestStatus = 'pending' | 'success' | 'failure';

export const getRequestStatus = (request: CommandData): RequestStatus => {
    const requestData = JSON.parse(request.data);
    let status: RequestStatus = 'failure';
    if (!!requestData.final_result) {
        // if there is final result in payload (new requests)
        if (!!requestData.event_id) {
            status = 'success';
        } else if (requestData.final_result === 'pending' && request.status !== 'cancelled') {
            status = 'pending';
        }
    } else {
        // if there is no final result in payload (old requests)
        if (!!request.backend_result.event_id) {
            status = 'success';
        } else if (!!request.backend_result.status) {
            if (request.backend_result.status === 'success') {
                status = 'pending';
            } else {
                status = request.backend_result.status;
            }
        } else {
            // if there is no backend result
            if (request.status === 'pending' || request.status === 'success') {
                status = 'pending';
            }
        }
    }
    return status;
};

export const getRequestEventId = (request: CommandData): string | undefined => {
    const requestData = JSON.parse(request.data);
    return requestData.event_id || request.backend_result.event_id;
};

interface RequestListProps {
    requests: CommandData[];
    devices: DeviceV3[];
    isLoading: boolean;
    isError: boolean;
    onClick?: CallableFunction;
    inAccident?: boolean;
    disabled?: boolean;
}

const RequestList: React.FC<RequestListProps> = (props: RequestListProps) => {
    const { t } = useTranslation();
    const { profile } = useProfile();

    const deviceMap = Object.fromEntries(props.devices.map((d: DeviceV3) => [d.device.device_id, d]));

    if (props.isLoading) {
        return (
            <Box
                id="request-list"
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (props.isError) {
        return (
            <Box id="request-list" sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Typography variant="overline" sx={{ color: 'error.main' }}>
                    {t('content.find_video.previous_requests_error')}
                </Typography>
            </Box>
        );
    } else if (props.requests.length === 0) {
        return (
            <Box id="request-list" sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Typography variant="overline" sx={{ color: 'wellnessGray.main' }}>
                    {t('content.find_video.no_requests')}
                </Typography>
            </Box>
        );
    }

    return (
        <Box id="request-list" sx={{ width: '100%', height: '100%', overflowY: 'scroll', minHeight: 0 }}>
            <List data-testid="request-list" disablePadding>
                {props.requests.map((request) => {
                    const requestData = JSON.parse(request.data);
                    const startTime = requestData.start_time;
                    const endTime = requestData.end_time;
                    const startTimeStr = dayjs.unix(startTime).format(profile.dateTimeFormat);

                    const durationField = (
                        <>
                            <Box sx={{ width: 24, display: 'flex', alignItems: 'center' }}>
                                <TimelapseIcon fontSize="small" sx={{ color: 'primary.main' }} />
                            </Box>
                            <Typography>{formatTime(endTime - startTime, t, true, false, false)}</Typography>
                        </>
                    );

                    const status = getRequestStatus(request);
                    let statusStr = t(
                        `content.find_video.status.${['pending', 'success'].includes(status) ? status : 'failure'}`,
                    );
                    if (status === 'pending' && !deviceMap[request.device.device_id]?.current_trip) {
                        statusStr = `${statusStr} (${t('content.find_video.turn_on')})`;
                    }
                    const statusField = <Typography>{statusStr}</Typography>;
                    let failureReason;
                    if (status === 'failure') {
                        failureReason =
                            request.backend_result.message === 'no video files found'
                                ? 'no_video'
                                : 'error_creating_clip';
                    }

                    let isPartialSuccess;
                    try {
                        const payload = JSON.parse(request.data);
                        isPartialSuccess = payload.preview_available;
                    } catch {
                        isPartialSuccess = false;
                    }

                    let listItemButtonSx: SxProps = {};
                    if (props.disabled) {
                        listItemButtonSx = {
                            cursor: 'default',
                            ':hover': {
                                backgroundColor: 'transparent',
                            },
                        };
                    }

                    return (
                        <ListItem
                            key={request.id}
                            className={`request-by-user-${request.user_id}`}
                            data-testid="request-list-item"
                            disableGutters
                            disablePadding
                            sx={{
                                borderBottomWidth: '1px',
                                borderBottomStyle: 'solid',
                                borderBottomColor: 'lightGrayColor.main',
                            }}
                        >
                            <ListItemButton
                                id={`request-${request.id}-btn`}
                                data-testid={`request-${request.id}-btn`}
                                onClick={() => {
                                    if (props.onClick) {
                                        props.onClick(request);
                                    }
                                }}
                                disableRipple={props.disabled}
                                sx={listItemButtonSx}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        position: 'relative',
                                    }}
                                >
                                    {!props.inAccident ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ width: 28, display: 'flex', alignItems: 'center' }}>
                                                <LocalShippingIcon fontSize="small" sx={{ color: 'primary.main' }} />
                                            </Box>
                                            <Typography>{`${request.device.license_plate}${
                                                !!request.device.customer_sub_fleet
                                                    ? ` (${request.device.customer_sub_fleet})`
                                                    : ''
                                            }`}</Typography>
                                        </Box>
                                    ) : null}
                                    <Typography
                                        variant="body3"
                                        sx={{ color: 'wellnessGray.main', position: 'absolute', top: 5, right: 0 }}
                                    >
                                        {dayjs.unix(request.created_at).format(profile.dateFormat)}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
                                        <Box sx={{ width: 28 }}>
                                            <AccessTimeIcon
                                                fontSize="small"
                                                sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}
                                            />
                                        </Box>
                                        <Typography sx={{ mr: 1 }}>{startTimeStr}</Typography>
                                        {durationField}
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                                        <Box sx={{ width: 28 }}>
                                            <Box sx={{ width: 17, ml: '1px', mt: '2px' }}>
                                                <svg
                                                    viewBox="0 0 17 17"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{
                                                        filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
                                                        overflow: 'visible',
                                                    }}
                                                >
                                                    {isPartialSuccess ? (
                                                        <>
                                                            <circle
                                                                cx="8.5"
                                                                cy="8.5"
                                                                r="7.5"
                                                                transform="rotate(-90 8.5 8.5)"
                                                                stroke={palette.green[900]}
                                                                strokeWidth={1.5}
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M8.99906 0.515366C13.1845 0.773418 16.4992 4.24962 16.4992 8.5C16.4992 12.7503 13.1845 16.2265 8.99921 16.4846C8.9994 14.4395 8.99912 13.5787 8.99879 12.6078C8.99849 11.6948 8.99815 10.6843 8.99815 8.5C8.99815 6.31487 8.99815 5.55013 8.99825 4.78539C8.99836 3.95916 8.99859 3.13292 8.99906 0.515366Z"
                                                                fill={palette.green[900]}
                                                                stroke={palette.green[900]}
                                                            />
                                                        </>
                                                    ) : (
                                                        <circle
                                                            data-testid={`request-${request.id}-status`}
                                                            className="status-circle"
                                                            cx="8.5"
                                                            cy="8.5"
                                                            r="8.5"
                                                            fill={statusToColor[status]}
                                                        />
                                                    )}
                                                </svg>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                {statusField}
                                                {failureReason ? (
                                                    <Typography fontSize={12}>
                                                        {t(`content.find_video.${failureReason}`)}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                            {!!request.accident_id && !props.inAccident ? (
                                                <Tooltip
                                                    title={t('content.find_video.accident_number', {
                                                        number: request.accident_id,
                                                    })}
                                                >
                                                    <CarCrashIcon
                                                        fontSize="small"
                                                        sx={{ color: 'wellnessGray.main', mt: 'auto' }}
                                                    />
                                                </Tooltip>
                                            ) : null}
                                        </Box>
                                    </Box>
                                </Box>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default RequestList;
