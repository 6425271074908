import { Box, Skeleton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CoachSession, CoachWellnessStatus } from '../../../../backendsdk';
import { TrackedButton as Button } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import palette from '../../../ColorPalette';
import { SaveFirstTooltip } from './CoachingAgenda';

interface WellnessStatusFormProps {
    sessionId: number;
    itemId: number;
    wellnessStatus: CoachWellnessStatus;
    image?: string;
    onClose: CallableFunction;
    setDetails: CallableFunction;
    isLoading: boolean;
    setIsLoading: CallableFunction;
    isCompleted: boolean;
    isSaved: boolean;
    setAlert: CallableFunction;
    onUpdate: CallableFunction;
}

const WellnessStatusForm: React.FC<WellnessStatusFormProps> = (props: WellnessStatusFormProps) => {
    const [comment, setComment] = useState<string>(props.wellnessStatus.comment);
    const { api } = useApi();
    const { t } = useTranslation();

    const isEditable = !props.isCompleted && props.isSaved;

    const handleSubmit = () => {
        props.setIsLoading(true);
        api.apiV0CoachSessionIdWellnessStatusWellnessIdPatch({
            sessionId: props.sessionId,
            wellnessId: props.itemId,
            updateCoachWellnessStatus: { comment },
        })
            .then((res) => {
                props.setDetails((prev: CoachSession) => ({
                    ...prev,
                    wellness_statuses: prev.wellness_statuses.map((status) =>
                        status.id === res.data.id ? res.data : status,
                    ),
                }));
                props.onUpdate();
                props.onClose();
            })
            .catch(() => {
                props.setAlert({
                    message: t('content.coach.errors.update_item'),
                    type: 'error',
                    duration: 6000,
                });
                props.setIsLoading(false);
            });
    };

    const commentField = (
        <Box
            sx={{
                width: '100%',
                border: `1px solid ${palette.gray[400]}`,
                backgroundColor: palette.white,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                p: 1,
            }}
        >
            <Typography variant="body2" sx={{ mb: 0.5 }}>
                {t('content.coach.add_item.wellness_status.instructions')}
            </Typography>
            <TextField
                id="comment-field"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                inputProps={{ 'data-testid': 'comment-field-input' }}
                sx={{ '& .MuiInputBase-root': { padding: '8px 10px' } }}
                disabled={!isEditable}
            />
        </Box>
    );

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '175.5px',
                    mb: 1,
                }}
            >
                {props.image ? (
                    <img src={props.image} width="100%" height="100%" />
                ) : (
                    <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%', height: '100%' }} />
                )}
            </Box>
            {props.isSaved ? commentField : <SaveFirstTooltip>{commentField}</SaveFirstTooltip>}
            {isEditable ? (
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    <Button
                        id="btn-cancel-item"
                        data-testid="btn-cancel-item"
                        variant="contained"
                        color="primary"
                        disabled={props.isLoading}
                        onClick={() => props.onClose()}
                        sx={{ ml: 'auto' }}
                    >
                        {t('content.coach.add_item.cancel')}
                    </Button>
                    <Button
                        id="btn-save-item"
                        data-testid="btn-save-item"
                        variant="contained"
                        color="secondary"
                        sx={{ ml: 1 }}
                        disabled={props.isLoading}
                        onClick={handleSubmit}
                    >
                        {t('content.coach.add_item.update')}
                    </Button>
                </Box>
            ) : null}
        </>
    );
};

export default WellnessStatusForm;
