import { Autocomplete, Box, Slide, TextField, Typography } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedCustomerDataAllOfUsers, MalfunctionTypeEnum } from '../../../backendsdk';
import { TrackedButton as Button, TrackedMenuItem as MenuItem } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import { flattenRoles, malfunctionsPageDefs } from '../../../utils/Pages';
import palette from '../../ColorPalette';
import { getUserAvatar } from '../Coaching/SessionDetails';

interface MalfunctionsFilterProps {
    isOpen: boolean;
    filterModel: GridFilterModel;
    setFilterModel: (filterModel: GridFilterModel) => void;
    licensePlates: string[];
    users: Record<number, ExtendedCustomerDataAllOfUsers>;
    onClose: CallableFunction;
}

const MalfunctionsFilter: React.FC<MalfunctionsFilterProps> = (props) => {
    const { users, licensePlates } = props;
    const { profile } = useProfile();
    const { t } = useTranslation();
    const malfunctionTypeFilter = props.filterModel.items.find((item) => item.field === 'malfunction')?.value;
    const licensePlateFilter = props.filterModel.items.find((item) => item.field === 'license_plate')?.value;
    const subFleetFilter = props.filterModel.items.find((item) => item.field === 'sub_fleet')?.value;
    const assigneeFilter = props.filterModel.items.find((item) => item.field === 'assignee')?.value;
    // put logged in user first in the users option list
    const currentUser = props.users[profile.user_id];
    const otherUsers = Object.values(props.users).filter((u) => u.user_id !== profile.user_id);
    const sortedUsers = [currentUser, ...otherUsers];
    return (
        <Slide direction="up" in={props.isOpen} unmountOnExit>
            <Box
                onClick={(e) => {
                    e.stopPropagation();
                }}
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    p: 2,
                    position: 'absolute',
                    bottom: 0,
                    zIndex: 2,
                    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.25)',
                    backgroundColor: palette.neutral[50],
                }}
            >
                <Autocomplete
                    fullWidth
                    id="malfunction-filter-field"
                    data-testid="malfunction-filter-field"
                    options={Object.values(MalfunctionTypeEnum)}
                    getOptionLabel={(option) => t(`content.malfunctions.table.type.${option.toLocaleLowerCase()}`)}
                    value={malfunctionTypeFilter || null}
                    onChange={(_event, newValue) => {
                        let newFilterModel = { ...props.filterModel };
                        const malfunctionFilterIndex = newFilterModel.items.findIndex(
                            (item) => item.field === 'malfunction',
                        );
                        if (malfunctionFilterIndex !== -1) {
                            if (newValue) {
                                newFilterModel.items[malfunctionFilterIndex].value = newValue;
                            } else {
                                newFilterModel = {
                                    items: newFilterModel.items.filter((item) => item.field !== 'malfunction'),
                                };
                            }
                        } else {
                            newFilterModel.items.push({
                                field: 'malfunction',
                                operator: 'is',
                                value: newValue,
                            });
                        }
                        props.setFilterModel(newFilterModel);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('content.malfunctions.table.malfunction')}
                            size="small"
                            sx={{ mb: 2 }}
                            id="type-field-input"
                            InputLabelProps={{ shrink: !!malfunctionTypeFilter }}
                        />
                    )}
                />
                <Autocomplete
                    fullWidth
                    id="license-plate-filter-field"
                    data-testid="license-plate-filter-field"
                    options={licensePlates}
                    value={licensePlateFilter || null}
                    onChange={(_event, newValue) => {
                        let newFilterModel = { ...props.filterModel };
                        const malfunctionFilterIndex = newFilterModel.items.findIndex(
                            (item) => item.field === 'license_plate',
                        );
                        if (malfunctionFilterIndex !== -1) {
                            if (newValue) {
                                newFilterModel.items[malfunctionFilterIndex].value = newValue;
                            } else {
                                newFilterModel = {
                                    items: newFilterModel.items.filter((item) => item.field !== 'license_plate'),
                                };
                            }
                        } else {
                            newFilterModel.items.push({
                                field: 'license_plate',
                                operator: 'equals',
                                value: newValue,
                            });
                        }
                        props.setFilterModel(newFilterModel);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('content.malfunctions.table.license_plate')}
                            size="small"
                            sx={{ mb: 2 }}
                            id="license_plate-field-input"
                            InputLabelProps={{ shrink: !!licensePlateFilter }}
                        />
                    )}
                />
                <Autocomplete
                    fullWidth
                    id="sub-fleet-filter-field"
                    data-testid="sub-fleet-filter-field"
                    options={profile.customer.sub_fleets}
                    value={subFleetFilter || null}
                    onChange={(_event, newValue) => {
                        let newFilterModel = { ...props.filterModel };
                        const malfunctionFilterIndex = newFilterModel.items.findIndex(
                            (item) => item.field === 'sub_fleet',
                        );
                        if (malfunctionFilterIndex !== -1) {
                            if (newValue) {
                                newFilterModel.items[malfunctionFilterIndex].value = newValue;
                            } else {
                                newFilterModel = {
                                    items: newFilterModel.items.filter((item) => item.field !== 'sub_fleet'),
                                };
                            }
                        } else {
                            newFilterModel.items.push({
                                field: 'sub_fleet',
                                operator: 'is',
                                value: newValue,
                            });
                        }
                        props.setFilterModel(newFilterModel);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('content.malfunctions.table.sub_fleet')}
                            size="small"
                            sx={{ mb: 2 }}
                            id="sub-fleet-field-input"
                            InputLabelProps={{ shrink: !!subFleetFilter }}
                        />
                    )}
                />
                <Autocomplete
                    fullWidth
                    id="assignee-filter-field"
                    data-testid="assignee-filter-field"
                    value={assigneeFilter}
                    onChange={(_event, newValue) => {
                        let newFilterModel = { ...props.filterModel };
                        const malfunctionFilterIndex = newFilterModel.items.findIndex(
                            (item) => item.field === 'assignee',
                        );
                        if (malfunctionFilterIndex !== -1) {
                            if (newValue) {
                                newFilterModel.items[malfunctionFilterIndex].value = newValue;
                            } else {
                                newFilterModel = {
                                    items: newFilterModel.items.filter((item) => item.field !== 'assignee'),
                                };
                            }
                        } else {
                            newFilterModel.items.push({
                                field: 'assignee',
                                operator: 'is',
                                value: newValue,
                            });
                        }
                        props.setFilterModel(newFilterModel);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('content.malfunctions.table.assignee')}
                            size="small"
                            sx={{ mb: 2 }}
                            id="assignee-field-input"
                            InputLabelProps={{ shrink: !!assigneeFilter }}
                        />
                    )}
                    getOptionLabel={(option) => users[option].name}
                    renderOption={(props, option) => {
                        const user = users[option];
                        return (
                            <MenuItem
                                id={`user-${user.user_id}-menu-item`}
                                data-testid={`user-${user.user_id}-menu-item`}
                                {...props}
                                key={user.user_id}
                            >
                                {getUserAvatar(user)}
                                <Typography variant="body2">
                                    {user.user_id == profile.user_id
                                        ? `${user.name} (${t('content.malfunctions.me')})`
                                        : user.name}
                                </Typography>
                            </MenuItem>
                        );
                    }}
                    options={sortedUsers
                        .filter((u) => flattenRoles(malfunctionsPageDefs).includes(u.role))
                        .map((u) => u.user_id)}
                />
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Button
                        id="btn-clear"
                        variant="outlined"
                        onClick={() => {
                            props.setFilterModel({ items: [] });
                            props.onClose();
                        }}
                        sx={{ ml: 'auto', mr: 1 }}
                    >
                        {t('content.fleet.filter.clear')}
                    </Button>
                    <Button
                        id="btn-apply"
                        variant="contained"
                        onClick={() => {
                            props.onClose();
                        }}
                    >
                        {t('content.fleet.filter.apply')}
                    </Button>
                </Box>
            </Box>
        </Slide>
    );
};

export default MalfunctionsFilter;
