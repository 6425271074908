import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Avatar, Box, List, ListItem, Menu } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import {
    TrackedLink as Link,
    TrackedListItemButton as ListItemButton,
    TrackedMenuItem as MenuItem,
} from '../../components/TrackedComponents';
import useProfile from '../../hooks/profile';
import Logo from '../../images/logo/logo_mini.svg';
import { stringAvatar } from '../../utils/Avatar';
import { PageDefs, Section, getSectionIcons, userSettingsPageDefs } from '../../utils/Pages';
import palette from '../ColorPalette';
import { DEFAULT_PAGE, LANGUAGES_OPTIONS, MINIFIED_WIDTH, RTL_LANGUAGES } from './Navigator';

interface MinifiedContentProps {
    availableSections: Section[];
    availablePages: Record<Section, PageDefs[]>;
    setOpenDrawer: CallableFunction;
    onLogout: CallableFunction;
}

const MinifiedContent: React.FC<MinifiedContentProps> = (props: MinifiedContentProps) => {
    const [openSection, setOpenSection] = useState<Section>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { profile } = useProfile();
    const location = useLocation();
    const history = useHistory();
    const { i18n, t } = useTranslation();

    const selectedPage = location.pathname.split('/')[1] ? `/${location.pathname.split('/')[1]}` : DEFAULT_PAGE;

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenSection(undefined);
    };

    const minifiedMenu = !!openSection ? (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={!!openSection}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    backgroundColor: 'primary.light',
                    color: palette.white,
                    ml: RTL_LANGUAGES.includes(i18n.languages[0]) ? 0 : 5,
                    mr: RTL_LANGUAGES.includes(i18n.languages[0]) ? -5 : 0,
                },
            }}
            transformOrigin={{
                horizontal: RTL_LANGUAGES.includes(i18n.languages[0]) ? 'right' : 'right',
                vertical: 'top',
            }}
            anchorOrigin={{
                horizontal: RTL_LANGUAGES.includes(i18n.languages[0]) ? 'right' : 'right',
                vertical: 'top',
            }}
        >
            {props.availablePages[openSection].map((page) => (
                <MenuItem
                    id={`navbar-${page.name}`}
                    type="navigator"
                    key={page.name}
                    selected={page.path.includes(selectedPage)}
                    onClick={() => {
                        history.push(page.path[page.path.length - 1]);
                        handleMenuClose();
                    }}
                    sx={{
                        justifyContent: RTL_LANGUAGES.includes(i18n.languages[0]) ? 'end' : 'start',
                    }}
                >
                    {t(`navigator.${page.name}`, page.name)}
                </MenuItem>
            ))}
        </Menu>
    ) : null;

    const minifiedAvatarMenu = (
        <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === 'avatar-mini'}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    backgroundColor: 'primary.light',
                    color: palette.white,
                    ml: RTL_LANGUAGES.includes(i18n.languages[0]) ? 0 : 5,
                    mr: RTL_LANGUAGES.includes(i18n.languages[0]) ? -5 : 0,
                    mt: 1.5,
                },
            }}
            transformOrigin={{
                horizontal: RTL_LANGUAGES.includes(i18n.languages[0]) ? 'right' : 'right',
                vertical: 'top',
            }}
            anchorOrigin={{
                horizontal: RTL_LANGUAGES.includes(i18n.languages[0]) ? 'right' : 'right',
                vertical: 'top',
            }}
        >
            <MenuItem
                id="navbar-user-settings"
                onClick={() => {
                    history.push(userSettingsPageDefs.path[0]);
                }}
            >
                {t('navigator.settings')}
            </MenuItem>
            <MenuItem
                id="navbar-sign-out"
                onClick={() => {
                    handleMenuClose();
                    props.onLogout();
                }}
            >
                {t('navigator.sign_out')}
            </MenuItem>
        </Menu>
    );

    const sectionIcons = getSectionIcons();

    const avatarProps = profile.image_url
        ? { src: profile.image_url, sx: { cursor: 'pointer' } }
        : { ...stringAvatar(profile.name, { cursor: 'pointer' }) };

    return (
        <>
            <List disablePadding sx={{ width: MINIFIED_WIDTH }}>
                <Link id="link-to-default-page" component={RouterLink} to={DEFAULT_PAGE}>
                    <Box
                        sx={{
                            width: '100%',
                            height: MINIFIED_WIDTH,
                            display: 'flex',
                            justifyContent: 'center',
                            my: 1,
                        }}
                    >
                        <img
                            data-testid="logo-minified"
                            src={Logo}
                            style={{ width: MINIFIED_WIDTH - 10, objectFit: 'contain' }}
                        />
                    </Box>
                </Link>
                {props.availableSections.map((section) => (
                    <React.Fragment key={section}>
                        <ListItem key={section} disablePadding>
                            <ListItemButton
                                id={`navbar-${section}`}
                                sx={{ py: 1.75 }}
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                    handleMenuOpen(event);
                                    setOpenSection(section);
                                }}
                            >
                                {sectionIcons[section]}
                            </ListItemButton>
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
            {minifiedMenu}
            <List disablePadding style={{ marginTop: `auto`, width: MINIFIED_WIDTH }}>
                <ListItem disablePadding>
                    <ListItemButton
                        id="navbar-user-manual"
                        type="navigator"
                        sx={{ py: 1 }}
                        onClick={() => {
                            window.open(
                                `/documents/openeyes_user_manual-${
                                    LANGUAGES_OPTIONS.includes(i18n.languages[0]) ? i18n.languages[0] : 'en'
                                }.pdf`,
                                '_blank',
                            );
                        }}
                    >
                        <HelpOutlineIcon fontSize="small" />
                    </ListItemButton>
                </ListItem>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', my: 1 }}>
                    <Avatar id="avatar-mini" {...avatarProps} onClick={handleMenuOpen} />
                </Box>
                <ListItem disablePadding>
                    <ListItemButton
                        id="navbar-toggle-width"
                        sx={{ py: 1 }}
                        onClick={() => {
                            setOpenSection(undefined);
                            props.setOpenDrawer((prev: boolean) => !prev);
                        }}
                    >
                        {RTL_LANGUAGES.includes(i18n.languages[0]) ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
                    </ListItemButton>
                </ListItem>
            </List>
            {minifiedAvatarMenu}
        </>
    );
};

export default MinifiedContent;
