import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RateReviewIcon from '@mui/icons-material/RateReview';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Checkbox, FormControlLabel, IconButton, Paper, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    CoachAgendaItem,
    CoachDriverResponse,
    CoachEvent,
    CoachManagerNotes,
    CoachMediaFile,
    CoachMetrics,
    CoachSession,
    CoachWellnessStatus,
    CoachingMetadataMetadataActionEnum,
} from '../../../../backendsdk';
import { TrackedDialog as Dialog } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import palette from '../../../ColorPalette';
import { WELLNESS_EVENT_TYPES } from './CoachingAgenda';
import CoachingEventForm from './CoachingEventForm';
import DriverResponseForm from './DriverResponseForm';
import ManagerNotesForm from './ManagerNotesForm';
import MediaFileForm from './MediaFileForm';
import MetricsForm from './MetricsForm';
import { getWellnessType } from './WellnessStatus';
import WellnessStatusForm from './WellnessStatusForm';

interface AgendaItemModalProps {
    sessionDetails: CoachSession;
    agendaItem?: CoachAgendaItem;
    item?: CoachManagerNotes | CoachDriverResponse | CoachEvent | CoachMetrics | CoachMediaFile | CoachWellnessStatus;
    onClose: CallableFunction;
    setDetails: CallableFunction;
    setAlert: CallableFunction;
    setSessions: CallableFunction;
    eventByItemId: Record<number, CoachEvent>;
    images: Record<string, string>;
    onUpdate: CallableFunction;
    setItemToDelete: CallableFunction;
}

export const getItemIcon = (type: string, isSelected: boolean): React.ReactNode => {
    const style = { color: isSelected ? 'primary.main' : 'lightGrayColor.main' };
    const itemTypeToIcon: Record<string, React.ReactNode> = {
        event: <WarningIcon sx={style} />,
        notes: <AssignmentIcon sx={style} />,
        response: <RateReviewIcon sx={style} />,
        metrics: <AssessmentOutlinedIcon sx={style} />,
        media_file: <PhotoLibraryIcon sx={style} />,
        wellness_status: <HeartBrokenIcon sx={style} />,
    };
    return itemTypeToIcon[type];
};

const AgendaItemModal: React.FC<AgendaItemModalProps> = (props: AgendaItemModalProps) => {
    const type = props.agendaItem?.type;
    const [selectedType, setSelectedType] = useState<string>(type || 'notes');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [addAnotherItem, setAddAnotherItem] = useState<boolean>(false);
    const [itemsAdded, setItemsAdded] = useState<number>(0);
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const isEditMode = props.item !== undefined;
    const radioButtonStyle = { fontSize: 16, mr: 0.5, mt: 0.3 };
    const typeOptions = type ? [type] : ['notes', 'response', 'metrics', 'media_file'];

    const isCompleted = !!props.sessionDetails.completed_at;
    const isSaved = !props.sessionDetails.auto_generated || props.sessionDetails.is_saved;
    const isEditable = !isCompleted && isSaved;

    const watchedVideos =
        props.sessionDetails.metadata.find((m) => m.metadata.action === CoachingMetadataMetadataActionEnum.Watch)
            ?.metadata.coach_event_ids || [];

    const addAnotherCheckbox =
        isEditable && props.item === undefined ? (
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="add-another-item-checkbox"
                            data-testid="add-another-item-checkbox"
                            checked={addAnotherItem}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setAddAnotherItem(e.target.checked)}
                            size="small"
                            disableRipple
                            sx={{ '& .MuiSvgIcon-root': { height: 16, width: 16 }, ml: '2px', mr: '-2px' }}
                        />
                    }
                    label={
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant="body2">{t('content.coach.add_item.add_another_item')}</Typography>
                            {itemsAdded !== 0 ? (
                                <Typography
                                    data-testid={`${itemsAdded}-items-added`}
                                    variant="body2"
                                    sx={{ color: palette.green[900], ml: 0.5 }}
                                >
                                    {`(${
                                        itemsAdded === 1
                                            ? t('content.coach.add_item.item_added')
                                            : t('content.coach.add_item.items_added', { count: itemsAdded })
                                    })`}
                                </Typography>
                            ) : null}
                        </Box>
                    }
                />
            </Box>
        ) : null;

    return (
        <Dialog
            id="agenda-item-dialog"
            open={true}
            onClose={() => props.onClose()}
            sx={{ '& .MuiDialog-scrollPaper': { alignItems: 'start' } }}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0 }}>
                    {isEditMode && isEditable ? (
                        <IconButton
                            id="btn-delete-item"
                            data-testid="btn-delete-item"
                            size="small"
                            disabled={isLoading}
                            onClick={() => props.setItemToDelete(props.agendaItem)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    ) : null}
                    <IconButton
                        id="btn-close-item-modal"
                        data-testid="btn-close-item-modal"
                        size="small"
                        disabled={isLoading}
                        onClick={() => props.onClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {!isEditable
                        ? t('content.coach.add_item.header')
                        : isEditMode
                        ? t('content.coach.add_item.header_edit')
                        : t('content.coach.add_item.header_add')}
                </Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    p: 2,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: isMobile ? undefined : '500px',
                }}
            >
                {typeOptions.map((type) => {
                    const isSelected = type === selectedType;
                    let typeStr = type;
                    if (
                        type === 'event' &&
                        props.item !== undefined &&
                        WELLNESS_EVENT_TYPES.includes((props.item as CoachEvent).event_details.type)
                    ) {
                        typeStr = 'wellness_status';
                    }
                    return (
                        <Box
                            key={type}
                            id={`btn-${type}-item`}
                            data-testid={`btn-${type}-item`}
                            onClick={() => {
                                if (!isEditMode) {
                                    setSelectedType(type);
                                }
                            }}
                            sx={{
                                width: '100%',
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: isEditMode ? 'default' : 'pointer',
                                userSelect: 'none',
                                backgroundColor: isSelected ? palette.white : undefined,
                                border: `1px solid ${palette.gray[400]}`,
                                p: 1,
                                mb: 1,
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {isEditMode ? null : isSelected ? (
                                        <RadioButtonCheckedIcon sx={radioButtonStyle} />
                                    ) : (
                                        <RadioButtonUncheckedIcon
                                            sx={{ ...radioButtonStyle, color: palette.gray[600] }}
                                        />
                                    )}
                                    <Typography sx={{ color: isSelected ? palette.black : palette.gray[600] }}>
                                        {t(`content.coach.add_item.${typeStr}.header`)}
                                    </Typography>
                                </Box>
                                <Typography sx={{ fontSize: 14 }}>
                                    {t(`content.coach.add_item.${typeStr}.sub_header`)}
                                </Typography>
                            </Box>
                            {getItemIcon(typeStr, isSelected)}
                        </Box>
                    );
                })}
                {selectedType === 'notes' && (
                    <ManagerNotesForm
                        sessionId={props.sessionDetails.session_id}
                        item={props.item as CoachManagerNotes}
                        onClose={props.onClose}
                        setDetails={props.setDetails}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isCompleted={isCompleted}
                        isSaved={isSaved}
                        setAlert={props.setAlert}
                        onUpdate={props.onUpdate}
                        addAnotherItem={addAnotherItem}
                        setItemsAdded={setItemsAdded}
                    >
                        {addAnotherCheckbox}
                    </ManagerNotesForm>
                )}
                {selectedType === 'response' && (
                    <DriverResponseForm
                        sessionId={props.sessionDetails.session_id}
                        item={props.item as CoachDriverResponse}
                        onClose={props.onClose}
                        setDetails={props.setDetails}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isCompleted={isCompleted}
                        isSaved={isSaved}
                        setAlert={props.setAlert}
                        onUpdate={props.onUpdate}
                        addAnotherItem={addAnotherItem}
                        setItemsAdded={setItemsAdded}
                    >
                        {addAnotherCheckbox}
                    </DriverResponseForm>
                )}
                {selectedType === 'metrics' && (
                    <MetricsForm
                        sessionId={props.sessionDetails.session_id}
                        licensePlate={props.sessionDetails.license_plate}
                        item={props.item as CoachMetrics}
                        onClose={props.onClose}
                        setDetails={props.setDetails}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isCompleted={isCompleted}
                        isSaved={isSaved}
                        setAlert={props.setAlert}
                        onUpdate={props.onUpdate}
                        addAnotherItem={addAnotherItem}
                        setItemsAdded={setItemsAdded}
                    >
                        {addAnotherCheckbox}
                    </MetricsForm>
                )}
                {selectedType === 'media_file' && (
                    <MediaFileForm
                        sessionId={props.sessionDetails.session_id}
                        item={props.item as CoachMediaFile}
                        onClose={props.onClose}
                        setDetails={props.setDetails}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isCompleted={isCompleted}
                        isSaved={isSaved}
                        setAlert={props.setAlert}
                        onUpdate={props.onUpdate}
                        addAnotherItem={addAnotherItem}
                        setItemsAdded={setItemsAdded}
                    >
                        {addAnotherCheckbox}
                    </MediaFileForm>
                )}
                {selectedType === 'wellness_status' && props.item !== undefined && (
                    <WellnessStatusForm
                        sessionId={props.sessionDetails.session_id}
                        itemId={props.item.id}
                        wellnessStatus={props.item as CoachWellnessStatus}
                        image={props.images[`${getWellnessType(props.item as CoachWellnessStatus)}:${props.item.id}`]}
                        onClose={props.onClose}
                        setDetails={props.setDetails}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isCompleted={isCompleted}
                        isSaved={isSaved}
                        setAlert={props.setAlert}
                        onUpdate={props.onUpdate}
                    />
                )}
                {selectedType === 'event' && props.item !== undefined && (
                    <CoachingEventForm
                        sessionId={props.sessionDetails.session_id}
                        itemId={props.item.id}
                        event={props.item as CoachEvent}
                        onClose={props.onClose}
                        setDetails={props.setDetails}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isCompleted={isCompleted}
                        isSaved={isSaved}
                        setAlert={props.setAlert}
                        onUpdate={props.onUpdate}
                        isWatched={watchedVideos.includes(props.item.id)}
                    />
                )}
            </Paper>
        </Dialog>
    );
};

export default AgendaItemModal;
