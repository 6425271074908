import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';

import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';
import useIsMobile from '../../../hooks/isMobile';
import { getIcon } from '../Overview/MapIcons';

interface DefaultLocationModalProps {
    open: boolean;
    onClose: CallableFunction;
    position: L.LatLng;
    setPosition: CallableFunction;
    isMarkerVisible: boolean;
}

const DefaultLocationModal: React.FC<DefaultLocationModalProps> = (props: DefaultLocationModalProps) => {
    const [position, setPosition] = useState<L.LatLng>(props.position);
    const [isMarkerVisible, setIsMarkerVisible] = useState<boolean>(props.isMarkerVisible);
    const isMobile = useIsMobile();

    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const markerRef = useRef<any>(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    setPosition(marker.getLatLng());
                }
            },
            drag() {
                const marker = markerRef.current;
                if (marker != null) {
                    setPosition(marker.getLatLng());
                }
            },
        }),
        [],
    );

    return (
        <Dialog
            id="default-location-dialog"
            fullWidth
            maxWidth="md"
            open={props.open}
            fullScreen={isMobile}
            onClose={() => props.onClose()}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 4, top: 4 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
                    {t('content.org_settings.customization.default_location')}
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    aspectRatio: isMobile ? undefined : '16/9',
                    height: isMobile ? '100%' : undefined,
                    position: 'relative',
                }}
            >
                <MapContainer center={props.position} zoom={14} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {isMarkerVisible ? (
                        <Marker
                            eventHandlers={eventHandlers}
                            position={position}
                            ref={markerRef}
                            icon={getIcon('', 'online')}
                            draggable
                        />
                    ) : null}
                    <MapEvents setPosition={setPosition} setIsMarkerVisible={setIsMarkerVisible} />
                </MapContainer>
                <Box sx={{ position: 'absolute', bottom: 10, left: 10, zIndex: 1001, display: 'flex' }}>
                    <Button
                        id="btn-cancel-default-location"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.onClose();
                        }}
                        sx={{ mr: 1 }}
                    >
                        {t('content.org_settings.customization.cancel')}
                    </Button>
                    <Button
                        id="btn-set-default-location"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            props.setPosition(position);
                            props.onClose();
                        }}
                        disabled={!isMarkerVisible}
                    >
                        {t('content.org_settings.customization.set')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

interface MapEventsProps {
    setPosition: CallableFunction;
    setIsMarkerVisible: CallableFunction;
}

const MapEvents: React.FC<MapEventsProps> = ({ setPosition, setIsMarkerVisible }) => {
    const map = useMapEvents({
        click(e) {
            setIsMarkerVisible(true);
            setPosition(e.latlng);
            map.panTo(e.latlng);
        },
    });

    useEffect(() => {
        if (!map) return;
        const controls: L.Control[] = [];
        const resetZoomControl = L.Control.extend({
            options: {
                position: 'topleft',
            },
            onAdd: (map: L.Map) => {
                const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
                const button = L.DomUtil.create('a', 'leaflet-buttons-control-button', container);
                button.innerHTML =
                    '<svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" style="margin: 2px"><path d="M12 9.09091C10.3927 9.09091 9.09091 10.3927 9.09091 12C9.09091 13.6073 10.3927 14.9091 12 14.9091C13.6073 14.9091 14.9091 13.6073 14.9091 12C14.9091 10.3927 13.6073 9.09091 12 9.09091ZM18.5018 11.2727C18.1673 8.24 15.76 5.83273 12.7273 5.49818V4H11.2727V5.49818C8.24 5.83273 5.83273 8.24 5.49818 11.2727H4V12.7273H5.49818C5.83273 15.76 8.24 18.1673 11.2727 18.5018V20H12.7273V18.5018C15.76 18.1673 18.1673 15.76 18.5018 12.7273H20V11.2727H18.5018ZM12 17.0909C9.18545 17.0909 6.90909 14.8145 6.90909 12C6.90909 9.18545 9.18545 6.90909 12 6.90909C14.8145 6.90909 17.0909 9.18545 17.0909 12C17.0909 14.8145 14.8145 17.0909 12 17.0909Z" fill="black"/></svg>';
                L.DomEvent.disableClickPropagation(button);
                L.DomEvent.on(button, 'click', function () {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            map.panTo(new L.LatLng(position.coords.latitude, position.coords.longitude));
                        });
                    }
                });
                return container;
            },
        });
        const control = new resetZoomControl();
        control.addTo(map);
        controls.push(control);

        return () => {
            controls.forEach((control) => {
                map.removeControl(control);
            });
        };
    }, [map]);

    return null;
};

export default DefaultLocationModal;
