import { Alert, AlertColor, Portal, Snackbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

React;

interface Alert {
    message: string;
    type: AlertColor;
    link?: React.ReactNode;
    duration?: number;
}

export const useAlert = (): [JSX.Element, React.Dispatch<React.SetStateAction<Alert | undefined>>] => {
    const [open, setOpen] = useState<boolean>(false);
    const [alert, setAlert] = useState<Alert>();

    useEffect(() => {
        if (alert !== undefined) {
            setOpen(true);
        }
    }, [alert]);

    const handleClose = () => {
        setOpen(false);
    };

    const alertElement = (
        <Portal>
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleClose}
                autoHideDuration={alert?.duration}
            >
                <Alert id="mui-alert" onClose={handleClose} severity={alert?.type}>
                    <Typography>
                        {alert?.message}
                        {!!alert?.link ? '. ' : ''}
                        {alert?.link}
                    </Typography>
                </Alert>
            </Snackbar>
        </Portal>
    );

    return [alertElement, setAlert];
};
