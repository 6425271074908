import { GridColDef, GridRenderCellParams, GridRowParams, getGridStringOperators } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { HosDriver } from '../../../../backendsdk';
import { TrackedDataGrid as StyledDataGrid } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import palette from '../../../ColorPalette';
import { RECORD_CODE, RECORD_STATUS, abbrMap } from '../Defs';
import { StatusAvatar, durationFormatter } from './DriverCountersDataGrid';
import { Edit } from './Graph';
import { timestampsToRangeString } from './RecordsDataGrid';

dayjs.extend(utc);

export const driverResponseMap: Record<keyof typeof RECORD_STATUS, string> = {
    INACTIVE_CHANGED: 'Changed',
    ACTIVE: 'Confirmed',
    INACTIVE_CHANGE_REQUESTED: 'Pending',
    INACTIVE_CHANGE_REJECTED: 'Rejected',
};

export const getEditColumns = (sortable: boolean): GridColDef[] => [
    {
        field: 'time',
        headerName: 'Time',
        width: 160,
        sortable,
        filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
    },
    {
        field: 'duration',
        type: 'number',
        headerName: 'Duration',
        width: 80,
        valueFormatter: durationFormatter,
        sortable,
    },
    {
        field: 'status',
        type: 'singleSelect',
        valueOptions: Object.values(abbrMap),
        headerName: 'Status',
        width: 75,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => <StatusAvatar status={params.value} />,
        sortable,
    },
    {
        field: 'comment',
        headerName: 'Comment',
        minWidth: 150,
        flex: 1,
        sortable,
        filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
    },
    {
        field: 'response',
        headerName: 'Response',
        width: 120,
        sortable,
        filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
    },
];

interface EditRequestsDataGridProps {
    driver: HosDriver;
    requests: Edit[];
}

const EditRequestsDataGrid: React.FC<EditRequestsDataGridProps> = (props: EditRequestsDataGridProps) => {
    const history = useHistory();
    const { driverId, dateStr, requestId } = useParams<{ driverId: string; dateStr: string; requestId: string }>();
    const isMobile = useIsMobile();
    const { profile } = useProfile();

    const columns = useMemo(() => getEditColumns(false), []);

    const rows = props.requests.map((request) => {
        return {
            id: request.id,
            time: timestampsToRangeString(request.startTime, request.endTime, profile, props.driver),
            duration: request.endTime - request.startTime,
            status: abbrMap[RECORD_CODE[request.recordCode as number] as keyof typeof abbrMap],
            comment: request.comment,
            response:
                request.status !== undefined
                    ? driverResponseMap[RECORD_STATUS[request.status] as keyof typeof RECORD_STATUS]
                    : '',
        };
    });

    return (
        <StyledDataGrid
            id="edit-requests-data-grid"
            rows={rows}
            columns={columns}
            columnBuffer={5}
            disableRowSelectionOnClick
            disableColumnMenu
            disableVirtualization={isMobile}
            pagination={isMobile}
            getRowClassName={(params) => (requestId === params.row.id.toString() ? 'selected' : '')}
            onRowClick={(params: GridRowParams) => {
                history.replace(`/driver_logs/${driverId}/${dateStr}/${params.id}`);
            }}
            columnHeaderHeight={45}
            rowHeight={45}
            slotProps={{
                columnsPanel: {
                    sx: {
                        '& .MuiDataGrid-panelFooter button:first-child': {
                            display: 'none',
                        },
                    },
                },
            }}
            sx={{
                height: '100%',
                minHeight: '325px',
                maxWidth: '1200px',
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
                '& .selected': {
                    backgroundColor: palette.white,
                },
                '& .selected:hover': {
                    backgroundColor: palette.white,
                },
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: palette.neutral[50],
            }}
        />
    );
};

export default EditRequestsDataGrid;
