import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Box, Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CoachEvent, CoachSession } from '../../../../backendsdk';
import { TrackedButton as Button, TrackedLink as Link } from '../../../../components/TrackedComponents';
import VideoComponent from '../../../../components/video';
import useApi from '../../../../hooks/api';
import palette from '../../../ColorPalette';
import { isEventBlurred } from '../SessionDetails';
import { SaveFirstTooltip } from './CoachingAgenda';

interface CoachingEventFormProps {
    sessionId: number;
    itemId: number;
    event: CoachEvent;
    onClose: CallableFunction;
    setDetails: CallableFunction;
    isLoading: boolean;
    setIsLoading: CallableFunction;
    isCompleted: boolean;
    isSaved: boolean;
    setAlert: CallableFunction;
    onUpdate: CallableFunction;
    isWatched: boolean;
}

const CoachingEventForm: React.FC<CoachingEventFormProps> = (props: CoachingEventFormProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBlurred, setIsBlurred] = useState<boolean>(props.event.is_blurred);
    const [comment, setComment] = useState<string>(props.event.comment);
    const { api } = useApi();
    const { t } = useTranslation();

    const video = props.event.event_details.artifact_path['video'];
    const blurredVideo = props.event.event_details.artifact_path['blurred_video'];

    const blurredByConfig = isEventBlurred(props.event.event_details);

    const isEditable = !props.isCompleted && props.isSaved;

    const refreshDetails = () => {
        setIsLoading(true);
        api.apiV1CoachSessionIdGet({ sessionId: props.sessionId })
            .then((res) => {
                props.setDetails({ ...res.data });
            })
            .finally(() => setIsLoading(false));
    };

    const handleSubmit = () => {
        props.setIsLoading(true);
        api.apiV0CoachSessionIdEventItemIdPatch({
            sessionId: props.sessionId,
            itemId: props.itemId,
            updateCoachEvent: { comment, is_blurred: isBlurred },
        })
            .then((res) => {
                props.setDetails((prev: CoachSession) => ({
                    ...prev,
                    events: prev.events.map((event) => (event.id === res.data.id ? res.data : event)),
                }));
                props.onUpdate();
                props.onClose();
            })
            .catch(() => {
                props.setAlert({
                    message: t('content.coach.errors.update_item'),
                    type: 'error',
                    duration: 6000,
                });
                props.setIsLoading(false);
            });
    };

    let videoField;
    if (video) {
        if (isBlurred && !blurredVideo) {
            videoField = (
                <Box
                    sx={{
                        width: '100%',
                        height: '175.5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 1,
                    }}
                >
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Typography variant="overline" sx={{ mb: -1 }}>
                                {t('content.coach.add_item.event.generating_blur')}
                            </Typography>
                            <Link id="refresh-link" component="button" onClick={() => refreshDetails()}>
                                <Typography variant="overline">{t('content.coach.add_item.event.refresh')}</Typography>
                            </Link>
                        </>
                    )}
                </Box>
            );
        } else {
            videoField = (
                <Box
                    sx={{
                        width: '100%',
                        height: '175.5px',
                        mb: 1,
                    }}
                >
                    <VideoComponent event={props.event.event_details} video={isBlurred ? blurredVideo : video} />
                </Box>
            );
        }
    }

    const blurredField = (
        <Box
            sx={{
                width: '100%',
                border: `1px solid ${palette.gray[400]}`,
                backgroundColor: palette.white,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                p: 1,
                mb: 1,
            }}
        >
            <FormControlLabel
                disabled={!isEditable}
                control={
                    <Checkbox
                        id="blur-event-checkbox"
                        data-testid="blur-event-checkbox"
                        checked={isBlurred}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setIsBlurred(e.target.checked)}
                        size="small"
                        disableRipple
                        sx={{ '& .MuiSvgIcon-root': { height: 16, width: 16 }, ml: '2px', mr: '-2px' }}
                    />
                }
                label={
                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                        {t('content.coach.add_item.event.blur')}
                    </Typography>
                }
            />
        </Box>
    );

    const commentField = (
        <Box
            sx={{
                width: '100%',
                border: `1px solid ${palette.gray[400]}`,
                backgroundColor: palette.white,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                p: 1,
            }}
        >
            <Typography variant="body2" sx={{ mb: 0.5 }}>
                {t('content.coach.add_item.event.instructions')}
            </Typography>
            <TextField
                id="comment-field"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                inputProps={{ 'data-testid': 'comment-field-input' }}
                sx={{ '& .MuiInputBase-root': { padding: '8px 10px' } }}
                disabled={!isEditable}
            />
        </Box>
    );

    return (
        <>
            {videoField}
            {!!video && !blurredByConfig ? (
                props.isSaved ? (
                    blurredField
                ) : (
                    <SaveFirstTooltip>{blurredField}</SaveFirstTooltip>
                )
            ) : null}
            {props.isSaved ? commentField : <SaveFirstTooltip>{commentField}</SaveFirstTooltip>}
            {props.isWatched ? (
                <Box
                    sx={{
                        width: '100%',
                        border: `1px solid ${palette.gray[400]}`,
                        backgroundColor: palette.white,
                        display: 'flex',
                        alignItems: 'center',
                        p: 1,
                        mt: 1,
                    }}
                >
                    <DoneAllIcon fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body2">{t('content.coach.add_item.event.watched')}</Typography>
                </Box>
            ) : null}
            {isEditable ? (
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    <Button
                        id="btn-cancel-item"
                        data-testid="btn-cancel-item"
                        variant="contained"
                        color="primary"
                        disabled={props.isLoading}
                        onClick={() => props.onClose()}
                        sx={{ ml: 'auto' }}
                    >
                        {t('content.coach.add_item.cancel')}
                    </Button>
                    <Button
                        id="btn-save-item"
                        data-testid="btn-save-item"
                        variant="contained"
                        color="secondary"
                        sx={{ ml: 1 }}
                        disabled={props.isLoading}
                        onClick={handleSubmit}
                    >
                        {t('content.coach.add_item.update')}
                    </Button>
                </Box>
            ) : null}
        </>
    );
};

export default CoachingEventForm;
