import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextFieldProps,
    ThemeProvider,
    Tooltip,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedAccident, ExtendedCustomerDataAllOfUsers } from '../../../backendsdk';
import { ResponsiveDateTimePicker } from '../../../components/MuiDatePicker';
import { TrackedMenuItem as MenuItem } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import { accidentsPageDefs } from '../../../utils/Pages';
import { MuiSelect, MuiTextField, theme } from './MuiFormControls';

export const SAFETY_MANAGER_ROLE = 'safety_manager';
export const DEFAULT_ACCIDENT_ASSIGNEE_ROLE = 'default_accident_assignee';
export const ACCIDENT_TYPES = ['accident', 'burglary', 'electrical', 'fire', 'flood', 'mechanical', 'theft'];
const AT_FAULT_OPTIONS = {
    UNKNOWN: 'unknown',
    OWN: 'own',
    THIRD_PARTY: '3rd',
    NOT_DRIVER_FAULT: 'not_driver',
};
const DAMAGE_TYPES = ['MBI', 'PROPERTY', 'MBI + PROPERTY', 'NO_CLAIM'];
const DAMAGE_TYPES_US = ['AL', 'ADP', 'CARGO', 'GL'];
const SUING_OPTIONS = ['NA', 'POLICY', '3rd PARTY', 'STATE'];

interface AccidentGeneralDetailsProps {
    details: ExtendedAccident;
    handleChange: CallableFunction;
    textOnChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    editHandlingStatus: boolean;
    setEditHandlingStatus: CallableFunction;
    handlingStatusList: Array<string>;
    setValidateCallbacks: CallableFunction;
    customerUsers: ExtendedCustomerDataAllOfUsers[];
    allowUpdate: boolean;
    sending?: boolean;
}

const AccidentGeneralDetails: React.FC<AccidentGeneralDetailsProps> = (props: AccidentGeneralDetailsProps) => {
    const { details } = props;
    const { t, i18n } = useTranslation();
    const { profile } = useProfile();
    const [dateFieldError, setDateFieldError] = useState<boolean>(false);
    const datePickerFormat = profile.locale === 'il' ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY hh:mm A';
    const ALLOW_ASSIGNEE_ROLES = [...accidentsPageDefs.permissions.L1, ...accidentsPageDefs.permissions.L2];
    const disabled = !props.allowUpdate || props.sending;
    const isUSCustomer = profile.customer.settings.country === 'us';

    let damageCategoryField;
    if (isUSCustomer) {
        const damageCategories = !!details.damage_category ? details.damage_category.split(',') : [];

        const handleChange = (event: SelectChangeEvent<typeof damageCategories>) => {
            const {
                target: { value },
            } = event;
            let stringifiedValue;
            if (typeof value === 'string') {
                stringifiedValue = value.split(', ');
            } else {
                stringifiedValue = value.length > 0 ? (value.length > 1 ? value.join(',') : value[0]) : '';
            }
            props.handleChange('damage_category', stringifiedValue);
        };

        damageCategoryField = (
            <ThemeProvider theme={theme}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="damage-category-label">{t('content.accidents.form.damage_category')}</InputLabel>
                    <Select
                        labelId="damage-category-label"
                        id="damage-category-dropdown"
                        multiple
                        size="small"
                        value={damageCategories}
                        onChange={handleChange}
                        disabled={disabled}
                        input={<OutlinedInput label={t('content.accidents.form.damage_category')} />}
                        renderValue={(selected) =>
                            selected.map((s) => t(`content.accidents.damage.${s.toLocaleLowerCase()}`)).join(', ')
                        }
                    >
                        {DAMAGE_TYPES_US.map((damageType) => (
                            <MenuItem id={`${damageType}-menu-item`} key={damageType} value={damageType}>
                                <Checkbox checked={damageCategories.indexOf(damageType) > -1} />
                                <ListItemText
                                    primary={t(`content.accidents.damage.${damageType.toLocaleLowerCase()}`)}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ThemeProvider>
        );
    } else {
        damageCategoryField = (
            <MuiSelect
                id="damage-category-dropdown"
                value={details.damage_category}
                options={DAMAGE_TYPES}
                label={t('content.accidents.form.damage_category')}
                onChange={(e: SelectChangeEvent<unknown>) => props.handleChange('damage_category', e.target.value)}
                t={(s: string) => t(`content.accidents.damage.${s.toLocaleLowerCase().replaceAll(' ', '_')}`)}
                disabled={disabled}
            />
        );
    }
    useEffect(() => {
        props.setValidateCallbacks((prev: Record<string, CallableFunction>) => {
            return { ...prev, [`general`]: validateGeneralDetails };
        });
    }, [details]);

    const validateGeneralDetails = () => {
        const year = dayjs.unix(details.timestamp).year();
        if (year < 2021 || dayjs.unix(details.timestamp).isAfter(dayjs())) {
            setDateFieldError(true);
            return false;
        } else {
            setDateFieldError(false);
            return true;
        }
    };

    const default_assignees = props.customerUsers
        .filter((u) => u.roles.includes(DEFAULT_ACCIDENT_ASSIGNEE_ROLE))
        .map((u) => u.name);
    const users = props.customerUsers
        .filter(
            (u) =>
                ALLOW_ASSIGNEE_ROLES.includes(u.role) &&
                (!props.details.sub_fleet_name || !u.sub_fleet || u.sub_fleet === props.details.sub_fleet_name),
        )
        .map((u) => u.name)
        .sort((a, b) => {
            const isADefault = default_assignees.includes(a);
            const isBDefault = default_assignees.includes(b);
            if (isADefault && !isBDefault) {
                return -1;
            } else if (!isADefault && isBDefault) {
                return 1;
            } else {
                return a.localeCompare(b);
            }
        });

    const subFleetOptions = !!profile.sub_fleet
        ? ['', profile.sub_fleet]
        : ['', ...profile.customer.sub_fleets.filter(Boolean)];
    const isAssigneeDisabled =
        !ALLOW_ASSIGNEE_ROLES.includes(profile.role) || details.stage.toLocaleLowerCase() === 'done';

    return (
        <Box>
            <Box sx={{ mb: 1 }}>
                <Typography variant="overline">{t('content.accidents.header')}</Typography>
            </Box>
            <Grid sx={{ mb: 2 }} container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={3}>
                    <Tooltip title={isAssigneeDisabled ? t('content.accidents.form.cannot_change_assignee') : null}>
                        <Box>
                            <MuiSelect
                                id="assignee-dropdown"
                                value={details.assignee || ''}
                                options={users}
                                label={t('content.accidents.form.assignee')}
                                onChange={(e: SelectChangeEvent<unknown>) =>
                                    props.handleChange('assignee', e.target.value)
                                }
                                disabled={isAssigneeDisabled || disabled}
                            />
                        </Box>
                    </Tooltip>
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id="license-plate-field"
                        label={t('content.accidents.form.license_plate')}
                        value={details.license_plate}
                        name="license_plate"
                        onChange={props.textOnChange}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiSelect
                        id="category-dropdown"
                        value={details.category}
                        options={ACCIDENT_TYPES}
                        label={t('content.accidents.form.category')}
                        onChange={(e: SelectChangeEvent<unknown>) => props.handleChange('category', e.target.value)}
                        t={(s: string) =>
                            t(`content.accidents.form.categories.${s.toLocaleLowerCase().replaceAll(' ', '_')}`)
                        }
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id="claim-number-field"
                        label={t('content.accidents.damage.claim_no')}
                        value={details.claim_number}
                        name="claim_number"
                        onChange={props.textOnChange}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {props.editHandlingStatus ? (
                            <>
                                <MuiTextField
                                    id="handling-status-text-field"
                                    value={details.handling_status}
                                    label={t('content.accidents.form.handling_status')}
                                    onChange={props.textOnChange}
                                    name="handling_status"
                                    disabled={disabled}
                                />
                                <MenuIcon
                                    id="handling-status-menu"
                                    sx={{ cursor: 'pointer', ml: 0.5, color: 'action.active' }}
                                    fontSize="small"
                                    onClick={() => props.setEditHandlingStatus(false)}
                                />
                            </>
                        ) : (
                            <>
                                <MuiSelect
                                    id="handling-status-select-field"
                                    value={details.handling_status}
                                    options={props.handlingStatusList}
                                    label={t('content.accidents.form.handling_status')}
                                    onChange={props.textOnChange}
                                    name="handling_status"
                                    disabled={disabled}
                                />
                                <EditIcon
                                    id="handling-status-edit"
                                    sx={{ cursor: 'pointer', ml: 0.5, color: 'action.active' }}
                                    fontSize="small"
                                    onClick={() => props.setEditHandlingStatus(true)}
                                />
                            </>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.languages[0]}>
                            <ResponsiveDateTimePicker
                                id="date-field"
                                label={t('content.accidents.form.date')}
                                value={details.timestamp > 0 ? dayjs.unix(details.timestamp) : undefined}
                                onChange={(value: Dayjs | null) => {
                                    if (value !== null) {
                                        props.handleChange('timestamp', value.unix());
                                    }
                                }}
                                maxDateTime={dayjs()}
                                slotProps={{
                                    textField: {
                                        id: 'date-field',
                                        size: 'small',
                                        error: dateFieldError,
                                        sx: { direction: 'ltr' },
                                    } as TextFieldProps,
                                }}
                                format={datePickerFormat}
                                ampm={profile.locale !== 'il'}
                                disabled={disabled}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={3}>
                    <MuiSelect
                        id="at-fault-dropdown"
                        label={t('content.accidents.form.at_fault.header')}
                        options={Object.values(AT_FAULT_OPTIONS)}
                        value={details.at_fault}
                        onChange={(e: SelectChangeEvent<unknown>) => props.handleChange('at_fault', e.target.value)}
                        t={(s: string) => t(`content.accidents.form.at_fault.${s}`)}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id="internal-id-field"
                        label={t('content.accidents.form.internal_id')}
                        value={details.internal_id}
                        onChange={props.textOnChange}
                        name="internal_id"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id="location-field"
                        label={t('content.accidents.form.location')}
                        value={details.location}
                        onChange={props.textOnChange}
                        name="location"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiSelect
                        id="sub_fleet-dropdown"
                        label={t('content.accidents.form.sub_fleet')}
                        value={details.sub_fleet_name}
                        options={subFleetOptions}
                        onChange={(e: SelectChangeEvent<unknown>) =>
                            props.handleChange('sub_fleet_name', e.target.value)
                        }
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id="garage-field"
                        label={t('content.accidents.damage.garage')}
                        value={details.garage}
                        onChange={props.textOnChange}
                        name="garage"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id="appraiser-field"
                        label={t('content.accidents.form.appraiser')}
                        value={details.appraiser}
                        onChange={props.textOnChange}
                        name="appraiser"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    {damageCategoryField}
                </Grid>
                {isUSCustomer ? (
                    <Grid item xs={3}>
                        <MuiTextField
                            id="police-report-number-field"
                            label={t('content.accidents.form.police_report_number')}
                            value={details.police_report_number}
                            onChange={props.textOnChange}
                            name="police_report_number"
                            disabled={disabled}
                        />
                    </Grid>
                ) : null}
                <Grid item xs={3}>
                    <MuiSelect
                        id="suing-dropdown"
                        value={details.suing}
                        options={SUING_OPTIONS}
                        label={t('content.accidents.form.suing')}
                        onChange={(e: SelectChangeEvent<unknown>) => props.handleChange('suing', e.target.value)}
                        t={(s: string) => t(`content.accidents.suing.${s.toLocaleLowerCase().replaceAll(' ', '_')}`)}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        label={t('content.accidents.form.has_lawsuit')}
                        slotProps={{ typography: { variant: 'caption' } }}
                        control={
                            <Checkbox
                                id="has-lawsuit-checkbox"
                                checked={details.has_lawsuit}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    props.handleChange('has_lawsuit', e.target.checked)
                                }
                            />
                        }
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        label={t('content.accidents.form.claim_prevented_by_camera')}
                        slotProps={{ typography: { variant: 'caption' } }}
                        control={
                            <Checkbox
                                id="prevented-by-camera-checkbox"
                                checked={details.claim_prevented_by_camera}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    props.handleChange('claim_prevented_by_camera', e.target.checked)
                                }
                            />
                        }
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <MuiTextField
                id="description-field"
                name="description"
                multiline={true}
                maxRows={3}
                onChange={props.textOnChange}
                label={t('content.accidents.form.description')}
                value={details.description}
                disabled={disabled}
            />
        </Box>
    );
};

export default AccidentGeneralDetails;
