import { PolicyV2, PolicyV2CustomerTypeEnum, PolicyV2VehicleTypeEnum } from '../../../backendsdk';

const COLUMN_TYPES = ['string', 'singleSelect', 'boolean', 'number', 'date'] as const;
type ColumnType = typeof COLUMN_TYPES[number];

export const POLICY_COLUMNS: Record<keyof PolicyV2, ColumnType> = {
    id: 'number',
    customer: 'string',
    license_plate: 'string',
    vehicle_type: 'singleSelect',
    customer_type: 'singleSelect',
    start_date: 'date',
    end_date: 'date',
    cancellation_date: 'date',
    driver_refuse: 'boolean',
    insurance_company: 'string',
    total_loss: 'boolean',
    comment: 'string',
};

export const POLICY_OPTIONS_LIST = {
    vehicle_type: Object.values(PolicyV2VehicleTypeEnum).map(String),
    customer_type: Object.values(PolicyV2CustomerTypeEnum).map(String),
} as const;
const policyOptionsKeys = Object.keys(POLICY_OPTIONS_LIST) as (keyof typeof POLICY_OPTIONS_LIST)[];
export type PolicyOptionsKeys = typeof policyOptionsKeys[number];

export const EMPTY_POLICY = {
    id: 0,
    customer: '',
    license_plate: '',
    vehicle_type: 'other',
    customer_type: 'insured',
    start_date: null,
    end_date: null,
    cancellation_date: null,
    driver_refuse: false,
    insurance_company: '',
    total_loss: false,
    comment: '',
} as PolicyV2;
