import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ErrorIcon from '@mui/icons-material/Error';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, ListItem, Menu, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

import { ExtendedCustomerDataAllOfUsers, ShortCoachSession } from '../../../backendsdk';
import {
    TrackedListItemButton as ListItemButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';

interface ListRowData {
    sessions: ShortCoachSession[];
    selectedSessionId?: string;
    users: ExtendedCustomerDataAllOfUsers[];
    setSessionIdToDelete: CallableFunction;
    canRemoveSession: boolean;
}

const SessionListRow: React.FC<ListChildComponentProps<ListRowData>> = (props) => {
    const { index, style } = props;
    const session = props.data.sessions[index];
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const history = useHistory();
    const { t } = useTranslation();
    const { profile } = useProfile();

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX,
                      mouseY: event.clientY,
                  }
                : null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    let eventsField = null;
    let value;
    if (session.event_count > 0) {
        value =
            session.event_count === 1
                ? t('content.fleet.event')
                : t('content.fleet.events', { count: session.event_count });
    } else {
        value = t('content.fleet.no_events');
    }
    eventsField = (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: 28, display: 'flex', alignItems: 'center' }}>
                <WarningIcon
                    fontSize="small"
                    sx={{ color: session.event_count === 0 ? 'wellnessGray.main' : palette.red[700] }}
                />
            </Box>
            <Typography
                data-testid={`session-${session.session_id}-events-${session.event_count}`}
                sx={{ color: session.event_count === 0 ? 'wellnessGray.main' : palette.red[700] }}
            >
                {value}
            </Typography>
        </Box>
    );

    let datetimeField;
    if (session.completed_at) {
        datetimeField = (
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: 28, display: 'flex', alignItems: 'center' }}>
                    <EventIcon fontSize="small" sx={{ color: 'primary.main' }} />
                </Box>
                <Typography className="completed-field" sx={{ fontSize: '16px' }}>{`${t(
                    'content.coach.completed',
                )}: ${dayjs.unix(session.completed_at).format(profile.dateTimeFormat)}`}</Typography>
            </Box>
        );
    } else if (session.last_sent) {
        datetimeField = (
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: 28, display: 'flex', alignItems: 'center' }}>
                    <SendToMobileIcon fontSize="small" sx={{ color: 'primary.main' }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className="last-sent-field" sx={{ fontSize: '16px' }}>{`${t(
                        'content.coach.sort.last_sent',
                    )}: ${dayjs.unix(session.last_sent).format(profile.dateTimeFormat)}`}</Typography>
                    {session.last_delivery_status === 'failed' || session.last_delivery_status === 'undelivered' ? (
                        <Tooltip title={t('content.coach.undelivered')}>
                            <ErrorIcon color="error" sx={{ fontSize: 16, mb: 0.25, ml: 0.5 }} />
                        </Tooltip>
                    ) : null}
                </Box>
            </Box>
        );
    } else {
        datetimeField = (
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: 28, display: 'flex', alignItems: 'center' }}>
                    <EventIcon fontSize="small" sx={{ color: 'primary.main' }} />
                </Box>
                <Typography className="last-updated-field" sx={{ fontSize: '16px' }}>{`${t(
                    'content.coach.last_updated',
                )}: ${dayjs.unix(session.last_updated).format(profile.dateTimeFormat)}`}</Typography>
            </Box>
        );
    }
    const bottomRow = (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {datetimeField}
            {!!session.auto_generated ? (
                <AutoFixHighIcon fontSize="small" sx={{ color: palette.neutral[400] }} />
            ) : null}
        </Box>
    );
    const licensePlateField = [session.license_plate, session.sub_fleet_name].filter((x) => !!x).join(' / ');

    const assignee = !!session.assignee ? props.data.users.find((user) => user.user_id === session.assignee) : null;

    return (
        <ListItem
            data-testid={`session-list-item-${session.session_id}`}
            disableGutters
            disablePadding
            style={style}
            onContextMenu={(e: React.MouseEvent) => {
                if (props.data.canRemoveSession) {
                    handleContextMenu(e);
                }
            }}
            sx={{ width: '100%', height: '100px', borderBottom: `1px solid ${palette.gray[400]}`, direction: 'ltr' }}
        >
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                }
            >
                <MenuItem
                    id="remove-session-menu-item"
                    onClick={() => {
                        props.data.setSessionIdToDelete(session.session_id);
                        handleClose();
                    }}
                >
                    {t('content.coach.details.remove')}
                </MenuItem>
            </Menu>
            <ListItemButton
                id={`session-list-item-${session.driver_name?.toLocaleLowerCase().replaceAll(' ', '-')}`}
                data-testid="session-list-item"
                className={`session-${session.driver_name || 'unknown'}`}
                selected={
                    !!props.data.selectedSessionId
                        ? parseInt(props.data.selectedSessionId) === session.session_id
                        : false
                }
                sx={{ width: '100%', height: '125px', position: 'relative' }}
                onClick={() => history.push(`/coaching/${session.session_id}`)}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        py: 0.5,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <Box sx={{ width: 28, display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                            <PersonIcon
                                fontSize="small"
                                sx={{ color: !!session.driver_name ? 'primary.main' : 'wellnessGray.main' }}
                            />
                        </Box>
                        <Typography
                            data-testid="list-item-driver-name"
                            sx={{
                                color: !!session.driver_name ? undefined : palette.neutral[700],
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {session.driver_name !== '' ? session.driver_name : t('content.fleet.unknown_driver')}
                        </Typography>
                        {!!session.auto_generated ? (
                            <Typography
                                sx={{ ml: 0.5, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                            >{`(${licensePlateField})`}</Typography>
                        ) : null}
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ width: 28, display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                            <SupervisorAccountIcon
                                fontSize="small"
                                sx={{ color: !!assignee ? 'primary.main' : 'wellnessGray.main' }}
                            />
                        </Box>
                        <Typography
                            data-testid="list-item-assignee"
                            sx={{
                                color: !!assignee ? undefined : 'wellnessGray.main',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {!!assignee ? assignee.name : t('content.coach.details.unassigned')}
                        </Typography>
                    </Box>
                    {eventsField}
                    {bottomRow}
                </Box>
            </ListItemButton>
        </ListItem>
    );
};

export default SessionListRow;
