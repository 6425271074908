import { Stack } from '@mui/material';
import { getGridSingleSelectOperators, getGridStringOperators } from '@mui/x-data-grid';
import {
    GridCellParams,
    GridColDef,
    GridFilterItem,
    GridRowParams,
    GridRowSelectionModel,
    GridValueGetterParams,
    getGridNumericOperators,
} from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';

import { HosDriver, OverrideDriving, OverrideStop, RequestStatus } from '../../../../backendsdk';
import { TrackedDataGrid as StyledDataGrid } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import palette from '../../../ColorPalette';
import { FilterInput, StatusAvatar, durationFormatter, durationParser } from '../DriverLogs/DriverCountersDataGrid';

export interface UnassignedRow {
    id: number;
    device: string;
    startTime: string;
    endTime: string;
    driving_status: [OverrideDriving, OverrideStop];
    duration: number;
    driver?: HosDriver;
    status: string;
}

interface UnassignedDataGridProps {
    rows: UnassignedRow[];
    selectionModel: GridRowSelectionModel;
    setSelectionModel: CallableFunction;
}

const drivingStatusFilterOperators = getGridSingleSelectOperators()
    .filter((operator) => operator.value === 'isAnyOf')
    .map((operator) => {
        const newOperator = { ...operator };
        const newGetApplyFilterFn = (filterItem: GridFilterItem) => {
            return (params: GridCellParams): boolean => {
                const value = params.value as string[];
                let isOk = true;
                filterItem?.value?.forEach((item: string) => {
                    isOk = isOk && value.includes(item);
                });
                return isOk;
            };
        };
        newOperator.getApplyFilterFn = newGetApplyFilterFn;
        return newOperator;
    });

const statusOptions: (OverrideDriving | OverrideStop)[] = [
    ...Object.values(OverrideDriving),
    ...Object.values(OverrideStop),
];

const UnassignedDataGrid: React.FC<UnassignedDataGridProps> = (props: UnassignedDataGridProps) => {
    const isMobile = useIsMobile();

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'device',
                headerName: 'Device',
                width: 150,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'startTime',
                headerName: 'Start Time',
                width: 250,
                filterable: false,
                sortable: false,
            },
            {
                field: 'endTime',
                headerName: 'End Time',
                width: 250,
                filterable: false,
                sortable: false,
            },
            {
                field: 'duration',
                type: 'number',
                headerName: 'Duration',
                width: 80,
                valueFormatter: durationFormatter,
                filterOperators: getGridNumericOperators().map((operator) => ({
                    ...operator,
                    InputComponent: FilterInput,
                })),
                valueParser: durationParser,
            },
            {
                field: 'driving_status',
                type: 'singleSelect',
                valueOptions: statusOptions,
                headerName: 'Driving Status',
                width: 150,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => (
                    <Stack direction="row" spacing={1}>
                        {params.row.driving_status.map((status: OverrideDriving | OverrideStop) => (
                            <StatusAvatar key={`${params.row.id}-${status}`} status={status} />
                        ))}
                    </Stack>
                ),
                filterOperators: drivingStatusFilterOperators,
                sortable: false,
            },
            {
                field: 'driverResponse',
                headerName: 'Driver Response',
                minWidth: 200,
                flex: 1,
                valueGetter: (params: GridValueGetterParams) => {
                    const driver = params.row.driver;
                    return driver ? `${driver.first_name} ${driver.last_name} (${params.row.status})` : '';
                },
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
        ],
        [],
    );

    return (
        <StyledDataGrid
            id="unassigned-data-grid"
            rows={props.rows}
            columns={columns}
            columnBuffer={6}
            checkboxSelection
            disableVirtualization={isMobile}
            pagination={isMobile}
            rowSelectionModel={props.selectionModel}
            onRowSelectionModelChange={(newSelectionModel) => {
                props.setSelectionModel(newSelectionModel);
            }}
            isRowSelectable={(params: GridRowParams) => params.row.status !== RequestStatus.Approved}
            slotProps={{
                columnsPanel: {
                    sx: {
                        '& .MuiDataGrid-panelFooter button:first-child': {
                            display: 'none',
                        },
                    },
                },
            }}
            sx={{
                height: '100%',
                width: '100%',
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: palette.neutral[50],
            }}
        />
    );
};

export default UnassignedDataGrid;
