import { Box, Card, CircularProgress, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaggingData } from '../../../../backendsdk';
import { TabPanel, a11yProps } from '../../../../components/Tab';
import { TrackedTab as Tab } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import palette from '../../../ColorPalette';
import { PIE_COLORS } from './AccidentCausesChart';
import OverviewPieChart from './OverviewPieChart';

interface FaultSpeedCardProps {
    taggingData?: TaggingData;
    isLoading: boolean;
}

const FaultSpeedCard: React.FC<FaultSpeedCardProps> = ({ taggingData, isLoading }: FaultSpeedCardProps) => {
    const [faultSpeedTab, setFaultSpeedTab] = useState<number>(0);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const isMobile = useIsMobile();

    let faultData;
    if (taggingData?.fault !== undefined) {
        faultData = Object.entries(taggingData.fault)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([key, _]) => !!key)
            .map(([key, value]) => {
                return {
                    name: key,
                    value: value,
                };
            });
    }

    let faultContent;
    if (isLoading) {
        faultContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (!faultData || faultData.length === 0) {
        faultContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.accident_overview.no_data')}</Typography>
            </Box>
        );
    } else {
        faultContent = !!faultData ? (
            <OverviewPieChart data={faultData} translationPrefix="content.accidents.tags.accident_details.fault" />
        ) : null;
    }

    const speedOrder = ['<0kph', '0kph', '1-20kph', '20-50kph', '>=50kph', 'unknown'];

    let speedData;
    if (taggingData?.speed !== undefined) {
        speedData = [];
        for (const speed of speedOrder) {
            if (taggingData.speed[speed] !== undefined) {
                speedData.push({ name: speed, value: taggingData.speed[speed] });
            }
        }
    }

    let speedContent;
    if (isLoading) {
        speedContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <CircularProgress />;
            </Box>
        );
    } else if (!speedData || speedData.length === 0) {
        speedContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.accident_overview.no_data')}</Typography>
            </Box>
        );
    } else {
        const speedUnit = profile.customer.settings.country === 'il' ? 'kmh' : 'mph';
        speedContent = !!speedData ? (
            <OverviewPieChart
                data={speedData}
                colors={PIE_COLORS.slice(0, 6).reverse()}
                translationPrefix={`content.accidents.tags.accident_details.speed.${speedUnit}`}
            />
        ) : null;
    }

    const handleFaultSpeedTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setFaultSpeedTab(newValue);
    };

    return (
        <Card
            sx={{
                width: isMobile ? '100%' : 540,
                height: 400,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                flexShrink: 0,
                mt: isMobile ? 2 : 0,
            }}
        >
            <Tabs
                value={faultSpeedTab}
                onChange={handleFaultSpeedTabChange}
                TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                sx={{ width: '100%', borderBottom: `1px solid ${palette.gray[400]}` }}
            >
                <Tab
                    label={<Typography fontSize={14}>{t('content.accident_overview.fault')}</Typography>}
                    {...a11yProps(0)}
                    data-testid={`tab-fault`}
                    sx={{ width: '50%' }}
                />
                <Tab
                    label={<Typography fontSize={14}>{t('content.accident_overview.speed')}</Typography>}
                    {...a11yProps(1)}
                    data-testid={`tab-speed`}
                    sx={{ width: '50%' }}
                />
            </Tabs>
            <TabPanel value={faultSpeedTab} index={0} sx={{ width: '100%', height: '100%' }}>
                {faultContent}
            </TabPanel>
            <TabPanel value={faultSpeedTab} index={1} sx={{ width: '100%', height: '100%' }}>
                {speedContent}
            </TabPanel>
        </Card>
    );
};

export default FaultSpeedCard;
