import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Card, Grid, Tooltip, Typography } from '@mui/material';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedAccident } from '../../../../../backendsdk';
import useApi from '../../../../../hooks/api';
import { getCustomer } from '../../../../../utils/customer';
import AccidentCommentComponent from '../../AccidentComments';
import AccidentReminderComponent from '../../AccidentReminder';
import AccidentArtifactComponent from './AccidentArtifacts';

interface DiscussionTabProps {
    details: ExtendedAccident;
    setDetails: CallableFunction;
    handleChange: CallableFunction;
    onSave: CallableFunction;
    setSending: CallableFunction;
    handlingStatusList: Array<string>;
    setHandlingStatusList: CallableFunction;
    setAlert: CallableFunction;
    updateCallbacks: Record<string, CallableFunction>;
    setUpdateCallbacks: CallableFunction;
    validateCallbacks: Record<string, CallableFunction>;
    setValidateCallbacks: CallableFunction;
    removeCallbacks: CallableFunction;
    handleSave: CallableFunction;
    sending: boolean;
}

const DiscussionTab: React.FC<DiscussionTabProps> = (props: DiscussionTabProps) => {
    const { t } = useTranslation();
    const { details } = props;
    const [newFiles, setNewFiles] = useState<Array<File> | null>(null);
    const [isImageList, setIsImageList] = useState<boolean>(false);
    const [dragAndDropHover, setDragAndDropHover] = useState<boolean>(false);
    const { api } = useApi();

    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    const { data: customer } = useReactQuery({ queryKey: ['customer'], queryFn: () => getCustomer(api) });
    const customerUsers = customer?.users || [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (target: string, value: any) => {
        props.handleChange((oldDetails: ExtendedAccident) => {
            if (oldDetails) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const newDetails: Record<string, any> = { ...oldDetails };
                newDetails[target] = value;
                return newDetails;
            }
        });
    };

    const conditionalTooltip = (component: React.ReactNode) => {
        if (details.accident_id === 0) {
            return (
                <Tooltip title={t('content.accidents.form.please_save')} followCursor>
                    <Box>{component}</Box>
                </Tooltip>
            );
        } else {
            return component;
        }
    };

    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={1} columns={2}>
                <Grid item xs={1}>
                    <Card sx={{ mb: 1 }}>
                        {conditionalTooltip(
                            <AccidentCommentComponent
                                details={details}
                                setDetails={props.setDetails}
                                onSave={props.handleSave}
                                customerUsers={customerUsers}
                                disabled={props.sending}
                            />,
                        )}
                    </Card>
                    <Card>
                        <Box>
                            {conditionalTooltip(
                                <AccidentReminderComponent
                                    accidentDetails={details}
                                    users={customerUsers}
                                    disabled={props.sending}
                                />,
                            )}
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card
                        onDragOver={(e: React.DragEvent) => {
                            // Prevent default behavior (Prevent file from being opened)
                            e.preventDefault();
                            setDragAndDropHover(true);
                        }}
                        onDragEnter={() => {
                            setDragAndDropHover(true);
                        }}
                        onDrop={(e: React.DragEvent) => {
                            // Prevent default behavior (Prevent file from being opened)
                            e.preventDefault();
                            if (e.dataTransfer.files.length > 0) {
                                setNewFiles(Array.from(e.dataTransfer.files));
                            }
                            setDragAndDropHover(false);
                        }}
                        onDragLeave={() => {
                            setDragAndDropHover(false);
                        }}
                        onDragEnd={() => {
                            setDragAndDropHover(false);
                        }}
                        onDragExit={() => {
                            setDragAndDropHover(false);
                        }}
                        sx={{ mb: 1 }}
                    >
                        {dragAndDropHover ? (
                            <Box
                                sx={{
                                    p: 1,
                                    m: 1,
                                    height: '14rem',
                                    border: '2px dashed var(--gray-color)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <FileUploadIcon color="primary" fontSize="large" />
                                <Typography color="primary" variant="overline" display="block">
                                    {t('content.accidents.files.drop_here')}
                                </Typography>
                            </Box>
                        ) : (
                            conditionalTooltip(
                                <AccidentArtifactComponent
                                    details={details}
                                    onSave={props.handleSave}
                                    handleChange={handleChange}
                                    files={newFiles}
                                    setFiles={setNewFiles}
                                    setAlert={props.setAlert}
                                    isImageList={isImageList}
                                    setIsImageList={setIsImageList}
                                    disabled={props.sending}
                                />,
                            )
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DiscussionTab;
