import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, CircularProgress, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker, MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { CommandData, TripDetails } from '../../../backendsdk';
import { TrackedButton as Button } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useDevices from '../../../hooks/devices';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import { findVideoPageDefs } from '../../../utils/Pages';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { SortingModel } from '../Overview/FleetOverview';
import SortMenu from '../Overview/SortMenu';
import RequestList, { getRequestStatus, statusToColor } from './RequestList';
import VideoRequestFilter, { EMPTY_FILTERS, VideoRequestFilterModel } from './VideoRequestFilter';
import VideoRequestForm from './VideoRequestForm';
import { sortingFunctions, sortingOptions } from './utils';

export const MAX_DAYS_BACK = 7;
export const SYSTEM_USER_ID = 444;

const FindVideoComponent: React.FC = () => {
    const { devices, isLoading: isLoadingDevices } = useDevices();
    const [requests, setRequests] = useState<CommandData[]>([]);
    const [isLoadingRequests, setIsLoadingRequests] = useState<boolean>(true);
    const [requestsError, setRequestsError] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [sorting, setSorting] = useState<SortingModel>({
        field: 'created_at',
        order: 'desc',
    });
    const [filters, setFilters] = useState<VideoRequestFilterModel>(EMPTY_FILTERS);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [requestDates, setRequestDates] = useState<DateRange<Dayjs>>([dayjs().subtract(7, 'day'), dayjs()]);
    const [selectedTrip, setSelectedTrip] = useState<TripDetails | undefined>(undefined);
    const query = useQuery();
    const initialDate = query.get('date');
    const initialLicensePlate = query.get('license_plate');
    const [isRequestOpen, setIsRequestOpen] = useState<boolean>(!!initialLicensePlate || !!initialDate);
    const [handlingStatusList, setHandlingStatusList] = useState<string[]>([]);
    const params = useParams<{ commandId?: string }>();
    const [alertElement, setAlert] = useAlert();
    const { i18n, t } = useTranslation();
    const { api, agencyApi } = useApi();
    const { profile } = useProfile();
    const isMobile = useIsMobile();
    const history = useHistory();

    const licensePlates = devices.map((d) => d.device.license_plate);
    const selectedRequestId = params.commandId ? parseInt(params.commandId) : undefined;
    const selectedRequest = licensePlates.length > 0 ? requests.find((r) => r.id === selectedRequestId) : undefined;

    const isFilterSet = filters.subFleets.length > 0 || filters.status.length > 0 || filters.userRequests;

    const loadRequests = () => {
        const startDate = requestDates[0]?.startOf('day');
        const endDate = requestDates[1]?.endOf('day');
        if (!startDate || !endDate || !startDate.isValid() || !endDate.isValid() || !startDate.isBefore(endDate)) {
            return;
        }
        setIsLoadingRequests(true);
        api.apiV0CommandGet({
            timeFrom: startDate.unix(),
            timeTo: endDate.unix(),
        })
            .then((res) => {
                // filter out retries
                const requestList = res.data.filter((r) => {
                    const requestData = JSON.parse(r.data);
                    return !requestData.retry;
                });
                if (params.commandId) {
                    return api
                        .apiV0CommandCommandIdGet({ commandId: parseInt(params.commandId) })
                        .then((res) => {
                            if (!requestList.map((r) => r.id).includes(res.data.id)) {
                                requestList.push(res.data);
                            }
                            setRequests(requestList);
                        })
                        .catch(() => setRequests(requestList));
                } else {
                    setRequests(requestList);
                }
            })
            .catch(() => setRequestsError(true))
            .finally(() => setIsLoadingRequests(false));
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${findVideoPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        agencyApi
            .agencyV1CustomerDataGet()
            .then((res) =>
                setHandlingStatusList(res.data.handling_status.map((status: { name: string }) => status.name)),
            );
    }, []);

    useEffect(loadRequests, [requestDates]);

    useEffect(() => {
        if (!history.location.search.includes('date') && !history.location.search.includes('license_plate')) {
            setIsRequestOpen(!!selectedRequest);
        }
    }, [selectedRequest]);

    const filteredRequests = useMemo(() => {
        let filteredRequests = [...requests];
        filteredRequests = filteredRequests.filter((r) => {
            return (
                !!requestDates[0] &&
                !!requestDates[1] &&
                r.created_at >= requestDates[0].startOf('day').unix() &&
                r.created_at <= requestDates[1].endOf('day').unix()
            );
        });
        if (searchText) {
            filteredRequests = filteredRequests.filter((r) => {
                const normalizedText = searchText.toLocaleLowerCase().replaceAll('-', '');
                const normalizedLicensePlate = r.device.license_plate.toLocaleLowerCase().replaceAll('-', '');
                return !!normalizedLicensePlate && normalizedLicensePlate.includes(normalizedText);
            });
        }
        if (filters.userRequests) {
            filteredRequests = filteredRequests.filter((r) => {
                return r.user_id === profile.user_id;
            });
        }
        if (filters.subFleets.length > 0) {
            filteredRequests = filteredRequests.filter((r) => {
                return filters.subFleets.includes(r.device.customer_sub_fleet);
            });
        }
        if (filters.status.length > 0) {
            filteredRequests = filteredRequests.filter((r) => {
                const statusColor = statusToColor[getRequestStatus(r)];
                return filters.status.includes(statusColor);
            });
        }
        filteredRequests = sortingFunctions[sorting.field](filteredRequests, sorting.order);
        return filteredRequests;
    }, [requestDates, requests, searchText, sorting, filters]);

    const handleSelect = (request: CommandData) => {
        history.push(`/find_video/${request.id}`);
    };

    const loadingIndicator = (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    );

    if (isLoadingDevices || (isLoadingRequests && selectedRequestId !== undefined)) {
        return loadingIndicator;
    }

    let toolbarContent;
    if (isRequestOpen) {
        toolbarContent = (
            <Button
                id="back-btn"
                data-testid="back-btn"
                variant="outlined"
                color="primary"
                onClick={() => {
                    setIsRequestOpen(false);
                    setSelectedTrip(undefined);
                    history.push('/find_video');
                }}
            >
                {t('content.find_video.back')}
            </Button>
        );
    } else {
        toolbarContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                }}
            >
                <TextField
                    fullWidth={isMobile}
                    id="search-field"
                    variant="outlined"
                    size="small"
                    placeholder={t('content.fleet.search')}
                    disabled={isFilterOpen}
                    value={searchText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                    sx={{ '& .MuiInputBase-input': { paddingY: '0px', paddingLeft: '8px' } }}
                    inputProps={{ sx: { height: 35 } }}
                />
                <Box
                    sx={{
                        mt: isMobile ? 1 : 0,
                        display: 'flex',
                        flexWrap: 'nowrap',
                        width: isMobile ? '100%' : undefined,
                    }}
                >
                    <Button
                        id="new-request-btn"
                        data-testid="new-request-btn"
                        variant="contained"
                        color="secondary"
                        disabled={isFilterOpen}
                        onClick={() => setIsRequestOpen(true)}
                        startIcon={<AddIcon />}
                        sx={{
                            flexShrink: 0,
                            height: 35,
                            ml: isMobile ? 0 : 1,
                            mr: isMobile ? 1 : 0,
                            flex: isMobile ? 1 : undefined,
                        }}
                    >
                        {isMobile ? t('content.find_video.new') : t('content.find_video.new_request')}
                    </Button>
                    <SortMenu
                        disabled={isFilterOpen}
                        options={sortingOptions}
                        selected={sorting}
                        setSelected={setSorting}
                        translationKey="content.find_video.sort"
                        buttonStyles={{ flex: isMobile ? 1 : undefined }}
                    />
                    <Button
                        fullWidth={isMobile}
                        id="filter-btn"
                        data-testid="filter-btn"
                        variant={isFilterOpen || isFilterSet ? 'contained' : 'outlined'}
                        color="primary"
                        startIcon={<FilterAltIcon />}
                        sx={{
                            flexShrink: 0,
                            height: 35,
                            flex: isMobile ? 1 : undefined,
                        }}
                        onClick={() => setIsFilterOpen((prev) => !prev)}
                    >
                        {t('content.fleet.filter.header')}
                    </Button>
                </Box>
            </Box>
        );
    }

    const DateRangePicker = isMobile ? MobileDateRangePicker : DesktopDateRangePicker;

    let leftPanelContent;
    if (isRequestOpen) {
        leftPanelContent = (
            <Box sx={{ width: '100%', height: '100%' }}>
                <VideoRequestForm
                    isLoading={isLoadingDevices}
                    devices={devices}
                    selectedRequest={selectedRequest}
                    setRequests={setRequests}
                    selectedTrip={selectedTrip}
                    setSelectedTrip={setSelectedTrip}
                    setAlert={setAlert}
                    handlingStatusList={handlingStatusList}
                    setHandlingStatusList={setHandlingStatusList}
                />
            </Box>
        );
    } else {
        leftPanelContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: isMobile ? '100%' : '385px',
                    height: '100%',
                    flexShrink: 0,
                    backgroundColor: palette.neutral[50],
                    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.25)',
                    minHeight: 0,
                    zIndex: 1002,
                }}
            >
                {isFilterOpen ? (
                    <VideoRequestFilter
                        filters={filters}
                        setFilters={setFilters}
                        setIsFilterOpen={setIsFilterOpen}
                        results={filteredRequests.length}
                    />
                ) : (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                p: 2,
                                pt: 2.5,
                                borderBottom: '1px solid',
                                borderColor: 'lightGrayColor.main',
                                minHeight: 0,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.languages[0]}>
                                <DateRangePicker
                                    slots={{ field: SingleInputDateRangeField }}
                                    label={t('content.find_video.requests_from')}
                                    slotProps={{
                                        textField: {
                                            id: 'request-dates-picker',
                                            inputProps: { 'data-testid': 'request-dates-picker' },
                                            fullWidth: true,
                                            size: 'small',
                                        },
                                    }}
                                    format={profile.dateFormat}
                                    value={requestDates}
                                    onChange={(newValue) => setRequestDates(newValue)}
                                    disableFuture
                                />
                            </LocalizationProvider>
                        </Box>
                        <RequestList
                            requests={filteredRequests}
                            devices={devices}
                            isLoading={isLoadingRequests}
                            isError={requestsError}
                            onClick={handleSelect}
                        />
                    </>
                )}
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                position: 'relative',
                overflowX: 'hidden',
            }}
        >
            {alertElement}
            <Box
                sx={{
                    height: isMobile ? undefined : 70,
                    width: '100%',
                    flexShrink: 0,
                    backgroundColor: palette.neutral[50],
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                    p: 2,
                    zIndex: 1003,
                }}
            >
                {toolbarContent}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    width: '100%',
                    minHeight: 0,
                }}
            >
                {leftPanelContent}
            </Box>
        </Box>
    );
};

export default FindVideoComponent;
