import AddAlertIcon from '@mui/icons-material/AddAlert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {
    Autocomplete,
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedAccident, ExtendedCustomerDataAllOfUsers, Notification } from '../../../backendsdk';
import { ResponsiveDateTimePicker } from '../../../components/MuiDatePicker';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import { getUserAvatar } from '../Coaching/SessionDetails';
import { MuiTextField, theme } from './MuiFormControls';

const REMINDER_ROW_BACKGROUNDS = ['inherit', palette.neutral[100]];

interface AccidentReminderRowProps {
    accidentDetails: ExtendedAccident;
    details: Notification;
    users: ExtendedCustomerDataAllOfUsers[];
    refresh?: CallableFunction;
    background: string;
}

const AccidentReminderRow: React.FC<AccidentReminderRowProps> = (props: AccidentReminderRowProps) => {
    const [edit, setEdit] = useState<boolean>(false);
    const { agencyApi } = useApi();
    const { t } = useTranslation();
    const { profile } = useProfile();

    const deleteReminder = () => {
        agencyApi
            .agencyV1AccidentAccidentIdNotificationDelete({
                accidentId: props.accidentDetails.accident_id,
                notificationRequest: { pk: props.details.pk },
            })
            .then(() => {
                if (props.refresh) {
                    props.refresh();
                }
            });
    };

    const isSent = props.details.sent_at;

    const reminderUser = props.users.find((user) => user.user_id === props.details.user);

    return (
        <>
            <Dialog id="accident-reminder-dialog" open={edit} onClose={() => setEdit(false)} fullWidth={true}>
                <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        {t('content.accidents.reminders.edit_header')}
                    </Typography>
                    <AccidentReminderForm
                        {...props}
                        refresh={() => {
                            if (props.refresh) {
                                props.refresh();
                            }
                            setEdit(false);
                        }}
                    />
                </Box>
            </Dialog>
            <ListItem disableGutters alignItems="center" sx={{ backgroundColor: props.background, py: 0.5 }}>
                {isSent ? (
                    <NotificationsActiveIcon fontSize="small" sx={{ mx: 1 }} />
                ) : (
                    <NotificationsIcon fontSize="small" sx={{ mx: 1 }} />
                )}
                <ListItemText>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex' }}>
                            {!!reminderUser ? (
                                <>
                                    <Typography sx={{ fontSize: 12, color: palette.neutral[700] }}>
                                        {reminderUser.name}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: palette.neutral[700], mx: 0.5 }}>
                                        |
                                    </Typography>
                                </>
                            ) : null}
                            <Typography sx={{ fontSize: 12, color: palette.neutral[700] }}>
                                {dayjs.unix(props.details.datetime).format(profile.dateTimeFormat)}
                            </Typography>
                        </Box>
                        {!!props.details.message ? (
                            <Typography variant="body2">{props.details.message}</Typography>
                        ) : null}
                    </Box>
                </ListItemText>
                {!isSent ? (
                    <>
                        <ListItemIcon
                            sx={{
                                minWidth: '30px',
                                m: 0,
                                p: 0,
                            }}
                        >
                            <Tooltip title={t('content.accidents.reminders.edit')}>
                                <IconButton id="btn-edit-reminder" size="small" onClick={() => setEdit((p) => !p)}>
                                    <EditNotificationsIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemIcon
                            sx={{
                                minWidth: '30px',
                                m: 0,
                                mr: 0.5,
                                p: 0,
                            }}
                        >
                            <Tooltip title={t('content.accidents.reminders.delete')}>
                                <IconButton id="btn-delete-reminder" onClick={deleteReminder} size="small">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ListItemIcon>
                    </>
                ) : null}
            </ListItem>
        </>
    );
};

interface AccidentReminderProps {
    accidentDetails: ExtendedAccident;
    users: ExtendedCustomerDataAllOfUsers[];
    details?: Notification;
    refresh?: CallableFunction;
    disabled?: boolean;
}

const AccidentReminderForm: React.FC<AccidentReminderProps> = (props: AccidentReminderProps) => {
    const [note, setNote] = useState<string>(props.details?.message || '');
    const [date, setDate] = useState<Dayjs>(
        props.details ? dayjs(props.details.datetime) : dayjs().set('second', 0).set('millisecond', 0),
    );
    const { profile } = useProfile();
    const [userId, setUserId] = useState<number | null>(props.details?.user || profile.user_id);
    const { i18n, t } = useTranslation();
    const { agencyApi } = useApi();

    const disabled = props.accidentDetails.accident_id === 0 || props.disabled;
    const isEdit = props.details !== undefined;
    const datePickerFormat = profile.locale === 'il' ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY hh:mm A';

    const userIdToUser = (userId: number) => {
        return props.users.find((user) => user.user_id === userId);
    };

    const setReminder = () => {
        if (!userId) {
            return;
        }
        let promise;
        if (isEdit) {
            promise = agencyApi.agencyV1AccidentAccidentIdNotificationPatch({
                accidentId: props.accidentDetails.accident_id,
                notificationRequest: {
                    pk: props.details?.pk,
                    datetime: date.toISOString(),
                    message: note,
                    user: userId,
                },
            });
        } else {
            promise = agencyApi.agencyV1AccidentAccidentIdNotificationPost({
                accidentId: props.accidentDetails.accident_id,
                notificationRequest: {
                    pk: props.details?.pk,
                    datetime: date.toISOString(),
                    message: note,
                    user: userId,
                },
            });
        }
        promise
            .then(() => {
                if (props.refresh) {
                    props.refresh();
                }
            })
            .then(() => {
                setUserId(profile.user_id);
                setNote('');
                setDate(dayjs());
            });
    };

    const reminderTimeFromNow = (days: number) => {
        setDate(dayjs().add(days, 'day').set('second', 0).set('millisecond', 0));
    };

    const reminderButton = (
        <Box sx={{ ml: 1 }}>
            <IconButton
                id="btn-reminder"
                disabled={disabled || !userId}
                size="small"
                color="primary"
                onClick={setReminder}
            >
                {isEdit ? <NotificationsIcon /> : <AddAlertIcon />}
            </IconButton>
        </Box>
    );

    return (
        <Grid container columnSpacing={1} sx={{ mt: 0.5 }}>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Autocomplete
                    id="recipient-field"
                    fullWidth
                    size="small"
                    options={props.users.map((user) => user.user_id)}
                    value={userId}
                    onChange={(_e, value) => setUserId(value)}
                    renderInput={(params) => (
                        <TextField {...params} fullWidth label={t('content.accidents.reminders.recipient')} />
                    )}
                    getOptionLabel={(option) => userIdToUser(option)?.name || ''}
                    renderOption={(props, option) => {
                        const user = userIdToUser(option);
                        if (!user) {
                            return null;
                        }
                        return (
                            <MenuItem id={`${option}-menu-item`} {...props} key={option}>
                                {getUserAvatar(user)}
                                <Typography variant="body2">{userIdToUser(option)?.name || ''}</Typography>
                            </MenuItem>
                        );
                    }}
                    sx={{ mb: 1.5 }}
                />
                <MuiTextField
                    id="new-reminder-field"
                    name="reminder_message"
                    label={t('content.accidents.reminders.note')}
                    multiline={true}
                    disabled={disabled}
                    rows={3}
                    value={note}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
                    sx={{ flexGrow: 2 }}
                />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'end' }}>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box sx={{ display: 'flex', mb: 2 }}>
                        <Button
                            id="reminder-week-btn"
                            size="small"
                            variant="contained"
                            disabled={disabled}
                            onClick={() => reminderTimeFromNow(7)}
                            sx={{ textTransform: 'none', height: '1.5rem', mr: 1 }}
                        >
                            {t('content.accidents.reminders.7days')}
                        </Button>
                        <Button
                            id="reminder-two-weeks-btn"
                            size="small"
                            variant="contained"
                            disabled={disabled}
                            onClick={() => reminderTimeFromNow(14)}
                            sx={{ textTransform: 'none', height: '1.5rem' }}
                        >
                            {t('content.accidents.reminders.14days')}
                        </Button>
                        <Button
                            id="reminder-month-btn"
                            size="small"
                            variant="contained"
                            disabled={disabled}
                            onClick={() => reminderTimeFromNow(30)}
                            sx={{ textTransform: 'none', height: '1.5rem', ml: 1 }}
                        >
                            {t('content.accidents.reminders.30days')}
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ThemeProvider theme={theme}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.languages[0]}>
                                <ResponsiveDateTimePicker
                                    label={t('content.accidents.reminders.datetime')}
                                    value={date}
                                    onChange={(value: Dayjs | null) => {
                                        if (value !== null) {
                                            setDate(value.set('second', 0).set('millisecond', 0));
                                        }
                                    }}
                                    format={datePickerFormat}
                                    ampm={profile.locale !== 'il'}
                                    disabled={disabled}
                                    slotProps={{
                                        textField: {
                                            id: 'new-reminder-date',
                                            size: 'small',
                                            sx: { direction: 'ltr' },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                        {!disabled ? (
                            <Tooltip
                                title={
                                    isEdit
                                        ? t('content.accidents.reminders.update')
                                        : t('content.accidents.reminders.new')
                                }
                            >
                                {reminderButton}
                            </Tooltip>
                        ) : (
                            reminderButton
                        )}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

const AccidentReminderComponent: React.FC<AccidentReminderProps> = (props: AccidentReminderProps) => {
    const [details, setDetails] = useState<Notification[]>([]);
    const { agencyApi } = useApi();
    const { t } = useTranslation();

    const refresh = () =>
        agencyApi
            .agencyV1AccidentAccidentIdNotificationGet({ accidentId: props.accidentDetails.accident_id })
            .then((response) => {
                setDetails(response.data);
            });

    useEffect(() => {
        if (props.accidentDetails.accident_id !== 0) {
            refresh();
        }
    }, [props.accidentDetails.accident_id]);

    return (
        <>
            <Box sx={{ p: 1, pb: 0 }}>
                <Typography variant="overline">{t('content.accidents.reminders.header')}</Typography>
            </Box>
            {details.length > 0 ? (
                <Box
                    sx={{
                        p: 1,
                        maxHeight: '10rem',
                        overflowY: 'scroll',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                >
                    <List sx={{ p: 0 }}>
                        {details
                            .sort((a, b) => (a.datetime > b.datetime ? 1 : a.datetime < b.datetime ? -1 : 0))
                            .map((detail: Notification, idx: number) => {
                                return (
                                    <AccidentReminderRow
                                        key={detail.pk}
                                        {...props}
                                        details={detail}
                                        refresh={refresh}
                                        background={REMINDER_ROW_BACKGROUNDS[idx % 2]}
                                    />
                                );
                            })}
                    </List>
                </Box>
            ) : null}
            <Box sx={{ p: 1 }}>
                <Typography variant="overline">{t('content.accidents.reminders.new')}</Typography>
                <Box>
                    <AccidentReminderForm {...props} refresh={refresh} />
                </Box>
            </Box>
        </>
    );
};

export default AccidentReminderComponent;
