import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    Box,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import { statusToColor } from './RequestList';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const statusToThemeColor: Record<string, 'wellnessGreen' | 'secondary' | 'wellnessRed'> = {
    success: 'wellnessGreen',
    pending: 'secondary',
    failure: 'wellnessRed',
};

export interface VideoRequestFilterModel {
    subFleets: string[];
    status: string[];
    userRequests: boolean;
}

export const EMPTY_FILTERS: VideoRequestFilterModel = {
    subFleets: [],
    status: [],
    userRequests: false,
};

interface VideoRequestFilterProps {
    filters: VideoRequestFilterModel;
    setFilters: CallableFunction;
    setIsFilterOpen: CallableFunction;
    results: number;
}

const VideoRequestFilter: React.FC<VideoRequestFilterProps> = (props: VideoRequestFilterProps) => {
    const { profile } = useProfile();
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                {t('content.fleet.filter.header')}
            </Typography>
            <FormControlLabel
                control={
                    <Switch
                        id="user-requests-switch"
                        data-testid="user-requests-switch"
                        size="small"
                        color="primary"
                        checked={props.filters.userRequests}
                        onChange={() => {
                            props.setFilters((prev: VideoRequestFilterModel) => ({
                                ...prev,
                                userRequests: !prev.userRequests,
                            }));
                        }}
                    />
                }
                label={t('content.find_video.my_requests')}
                sx={{ pl: 1, mb: 1.5 }}
            />
            <Typography sx={{ fontWeight: 'bold', color: 'primary.main', mb: 1 }}>
                {t('content.fleet.filter.sub_fleet')}
            </Typography>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="sub-fleet-field"
                data-testid="sub-fleet-field"
                value={props.filters.subFleets}
                onChange={(_, newValue) => {
                    props.setFilters((prev: VideoRequestFilterModel) => ({ ...prev, subFleets: newValue }));
                }}
                size="small"
                options={profile.customer.sub_fleets.filter(Boolean)}
                renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ height: 40, paddingLeft: 0 }}>
                        <Checkbox
                            id={`${option}-checkbox`}
                            data-testid={`${option}-checkbox`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                    </li>
                )}
                renderInput={(params) => <TextField {...params} />}
                renderTags={(value: Array<string>, getTagProps: AutocompleteRenderGetTagProps) => {
                    return value.map((v, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                            id={`${v}-chip`}
                            size="small"
                            label={<Typography fontSize={12}>{v}</Typography>}
                            {...getTagProps({ index })}
                            sx={{ borderRadius: 1 }}
                        />
                    ));
                }}
                sx={{ mb: 2 }}
            />
            <Typography sx={{ fontWeight: 'bold', color: 'primary.main', mb: 0.5 }}>
                {t('content.find_video.sort.status')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2, pl: 1 }}>
                <FormGroup>
                    {['success', 'pending', 'failure'].map((status) => {
                        const statusColor = statusToColor[status];
                        return (
                            <FormControlLabel
                                key={status}
                                control={
                                    <Switch
                                        id={`${status}-switch`}
                                        data-testid={`${status}-switch`}
                                        size="small"
                                        color={statusToThemeColor[status]}
                                        checked={props.filters.status.includes(statusColor)}
                                        onChange={() => {
                                            props.setFilters((prev: VideoRequestFilterModel) => ({
                                                ...prev,
                                                status: prev.status.includes(statusColor)
                                                    ? prev.status.filter((s) => s !== statusColor)
                                                    : [...prev.status, statusColor],
                                            }));
                                        }}
                                    />
                                }
                                label={t(`content.find_video.status.${status}`)}
                                sx={{ mb: 0.5 }}
                            />
                        );
                    })}
                </FormGroup>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}>
                <Button
                    id="clear-btn"
                    size="small"
                    variant="outlined"
                    sx={{ mr: 1 }}
                    onClick={() => props.setFilters(EMPTY_FILTERS)}
                >
                    {t('content.fleet.filter.clear')}
                </Button>
                <Button id="apply-btn" size="small" variant="contained" onClick={() => props.setIsFilterOpen(false)}>
                    {t('content.fleet.filter.apply')}
                </Button>
            </Box>
            <Typography variant="body3" color="wellnessGray.main" textAlign="right">
                {t('content.find_video.filter.results', { count: props.results })}
            </Typography>
        </Box>
    );
};

export default VideoRequestFilter;
