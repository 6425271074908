import dayjs from 'dayjs';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import useApi from '../../hooks/api';
import useProfile from '../../hooks/profile';
import {
    CustomerConfigPageDefs,
    PageDefs,
    accidentsMetricsPageDefs,
    accidentsPageDefs,
    coachingPageDefs,
    driverLogsPageDefs,
    driversPageDefs,
    eldMalfunctionsPageDefs,
    eventFeedPageDefs,
    eventsPageDefs,
    findVideoPageDefs,
    flattenRoles,
    fleetDriversPageDefs,
    fleetOverviewPageDefs,
    fleetVehiclesPageDefs,
    isAllowedCustomer,
    logEditsPageDefs,
    malfunctionsPageDefs,
    operationsPageDefs,
    organizationSettingsPageDefs,
    otaPageDefs,
    policiesPageDefs,
    premiumOptimizationPageDefs,
    reportsPageDefs,
    safetyMetricsPageDefs,
    unassignedPageDefs,
    userSettingsPageDefs,
    usersPageDefs,
    vehiclesPageDefs,
    violationsPageDefs,
    wellnessMetricsPageDefs,
    wellnessPageDefs,
} from '../../utils/Pages';
import { DEFAULT_PAGE } from '../Navigator/Navigator';
import AccidentComponent from './Accident';
import CoachComponent from './Coaching/CoachComponent';
import DriverLogsComponent from './Compliance/DriverLogs/DriverLogsComponent';
import DriversComponent from './Compliance/Drivers/DriversComponent';
import LogEditsComponent from './Compliance/LogEdits/LogEditsComponent';
import ELDMalfunctionsComponent from './Compliance/Malfunctions/MalfunctionsComponent';
import UnassignedComponent from './Compliance/Unassigned/UnassignedComponent';
import ViolationsComponent from './Compliance/Violations/ViolationsComponent';
import CustomerConfigPageComponent from './CustomerConfig';
import EventComponent from './Event/Event';
import FeedComponent from './Feed/FeedComponent';
import FindVideoComponent from './FindVideo';
import FleetDriversComponent from './FleetDrivers/FleetDriversComponent';
import FleetVehiclesComponent from './FleetVehicles/FleetVehiclesComponent';
import MalfunctionsComponent from './Malfunctions/MalfunctionsComponent';
import MetricsContent from './Metrics/MetricsContent';
import DeviceComponent from './OTA/DeviceComponent';
import OperationsTable from './Operations/OperationsTable';
import OrganizationSettings from './OrganizationSettings/OrganizationSettings';
import FleetOverview from './Overview/FleetOverview';
import PolicyComponent from './Policies/PolicyComponent';
import PremiumOptimization from './PremiumOptimization/PremiumOptimization';
import ReportAlertComponent from './Reports/Report';
import UserSettings from './UserSettings/UserSettings';
import UsersComponent from './Users/UsersComponent';
import VehiclesComponent from './Vehicles/VehiclesComponent';
import WellnessComponent from './Wellness/WellnessComponent';

const ContentDiv = styled.div`
    overflow-y: auto;
    width: 100%;
    height: 100%;
`;

const ContentPaddedDiv = styled.div`
    padding: 1em;
    padding-bottom: 0;
    overflow-y: auto;
    width: 100%;
    height: 100%;
`;

interface ProtectedRouteProps {
    pageDef: PageDefs;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any> | null;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ pageDef, children }: ProtectedRouteProps) => {
    const { profile } = useProfile();
    const roles = flattenRoles(pageDef);

    if (roles.includes(profile.role) && isAllowedCustomer(pageDef, profile)) {
        return children;
    } else {
        return <Redirect to={profile.home_page ? profile.home_page : DEFAULT_PAGE} />;
    }
};

const Content: React.FC = () => {
    const { profile } = useProfile();
    const { isAuthenticated } = useApi();

    if (!isAuthenticated || profile.user_id === 0) {
        return null;
    }

    return (
        <Switch>
            <Route path={fleetOverviewPageDefs.path}>
                <ProtectedRoute pageDef={fleetOverviewPageDefs}>
                    <ContentDiv>
                        <FleetOverview />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={malfunctionsPageDefs.path}>
                <ProtectedRoute pageDef={malfunctionsPageDefs}>
                    <ContentDiv>
                        <MalfunctionsComponent />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={fleetDriversPageDefs.path}>
                <ProtectedRoute pageDef={fleetDriversPageDefs}>
                    <ContentDiv>
                        <FleetDriversComponent />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={fleetVehiclesPageDefs.path}>
                <ProtectedRoute pageDef={fleetVehiclesPageDefs}>
                    <ContentDiv>
                        <FleetVehiclesComponent />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={wellnessPageDefs.path}>
                <ProtectedRoute pageDef={wellnessPageDefs}>
                    <ContentPaddedDiv>
                        <WellnessComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={findVideoPageDefs.path}>
                <ProtectedRoute pageDef={findVideoPageDefs}>
                    <ContentDiv>
                        <FindVideoComponent />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={eventFeedPageDefs.path}>
                <ProtectedRoute pageDef={eventFeedPageDefs}>
                    <ContentDiv>
                        <FeedComponent />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={vehiclesPageDefs.path}>
                <ProtectedRoute pageDef={vehiclesPageDefs}>
                    <ContentPaddedDiv>
                        <VehiclesComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={coachingPageDefs.path}>
                <ProtectedRoute pageDef={coachingPageDefs}>
                    <ContentDiv>
                        <CoachComponent />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={eventsPageDefs.path}>
                <ProtectedRoute pageDef={eventsPageDefs}>
                    <ContentDiv>
                        <EventComponent />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route exact path={safetyMetricsPageDefs.path}>
                <ProtectedRoute pageDef={safetyMetricsPageDefs}>
                    <ContentPaddedDiv>
                        <MetricsContent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={wellnessMetricsPageDefs.path}>
                <ProtectedRoute pageDef={wellnessMetricsPageDefs}>
                    <ContentPaddedDiv>
                        <MetricsContent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={accidentsMetricsPageDefs.path}>
                <ProtectedRoute pageDef={accidentsMetricsPageDefs}>
                    <ContentPaddedDiv>
                        <MetricsContent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={driverLogsPageDefs.path}>
                <ProtectedRoute pageDef={driverLogsPageDefs}>
                    <ContentPaddedDiv>
                        <DriverLogsComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={violationsPageDefs.path}>
                <ProtectedRoute pageDef={violationsPageDefs}>
                    <ContentPaddedDiv>
                        <ViolationsComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={unassignedPageDefs.path}>
                <ProtectedRoute pageDef={unassignedPageDefs}>
                    <ContentPaddedDiv>
                        <UnassignedComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={logEditsPageDefs.path}>
                <ProtectedRoute pageDef={logEditsPageDefs}>
                    <ContentPaddedDiv>
                        <LogEditsComponent now={dayjs()} />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={eldMalfunctionsPageDefs.path}>
                <ProtectedRoute pageDef={eldMalfunctionsPageDefs}>
                    <ContentPaddedDiv>
                        <ELDMalfunctionsComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={policiesPageDefs.path}>
                <ProtectedRoute pageDef={policiesPageDefs}>
                    <ContentPaddedDiv>
                        <PolicyComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={accidentsPageDefs.path}>
                <ProtectedRoute pageDef={accidentsPageDefs}>
                    <ContentDiv>
                        <AccidentComponent />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={premiumOptimizationPageDefs.path}>
                <ProtectedRoute pageDef={premiumOptimizationPageDefs}>
                    <ContentDiv>
                        <PremiumOptimization />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={usersPageDefs.path}>
                <ProtectedRoute pageDef={usersPageDefs}>
                    <ContentPaddedDiv>
                        <UsersComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={driversPageDefs.path}>
                <ProtectedRoute pageDef={driversPageDefs}>
                    <ContentPaddedDiv>
                        <DriversComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={reportsPageDefs.path}>
                <ProtectedRoute pageDef={reportsPageDefs}>
                    <ContentPaddedDiv>
                        <ReportAlertComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={operationsPageDefs.path}>
                <ProtectedRoute pageDef={operationsPageDefs}>
                    <ContentPaddedDiv>
                        <OperationsTable />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={otaPageDefs.path}>
                <ProtectedRoute pageDef={otaPageDefs}>
                    <ContentPaddedDiv>
                        <DeviceComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={CustomerConfigPageDefs.path}>
                <ProtectedRoute pageDef={CustomerConfigPageDefs}>
                    <ContentPaddedDiv>
                        <CustomerConfigPageComponent />
                    </ContentPaddedDiv>
                </ProtectedRoute>
            </Route>
            <Route path={userSettingsPageDefs.path}>
                <ProtectedRoute pageDef={userSettingsPageDefs}>
                    <ContentDiv>
                        <UserSettings />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path={organizationSettingsPageDefs.path}>
                <ProtectedRoute pageDef={organizationSettingsPageDefs}>
                    <ContentDiv>
                        <OrganizationSettings />
                    </ContentDiv>
                </ProtectedRoute>
            </Route>
            <Route path="*">
                <Redirect to={profile.home_page ? profile.home_page : DEFAULT_PAGE} />
            </Route>
        </Switch>
    );
};

export default Content;
