import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, LinearProgress, TextField, Typography } from '@mui/material';
import {
    GridActionsCellItem,
    GridColDef,
    GridCsvGetRowsToExportParams,
    GridEventListener,
    GridRenderCellParams,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    gridExpandedSortedRowIdsSelector,
    useGridApiContext,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryClient } from '../../../App';
import { DeviceV3, FleetDriver } from '../../../backendsdk';
import { TrackedDataGrid as StyledDataGrid } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useDevices from '../../../hooks/devices';
import useDrivers from '../../../hooks/drivers';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { fleetVehiclesPageDefs } from '../../../utils/Pages';
import { normalizeLicensePlate, normalizeName } from '../../../utils/Str';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { gridLocalization } from '../OTA/MuiDeviceTable';
import { GridRenderWithOptions } from '../Users/UserDataGrid';
import ActionsMenu from './ActionsMenu';
import UploadVehiclesModal from './UploadVehiclesModal';

interface QuickSearchToolbarProps {
    searchText: string;
    setSearchText: CallableFunction;
    children?: React.ReactNode;
}

const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                py: 1,
                backgroundColor: palette.bgColor,
            }}
        >
            <TextField
                id="table-search-field"
                variant="standard"
                value={props.searchText}
                onChange={(e: ChangeEvent<HTMLInputElement>) => props.setSearchText(e.target.value)}
                size="small"
                placeholder={t('table.search')}
                InputProps={{
                    startAdornment: <SearchIcon color="primary" fontSize="small" sx={{ mb: 0.5, mr: 0.25 }} />,
                    ...{ 'data-testid': 'table-search-field-input' },
                }}
                sx={{ mr: 1 }}
            />
            {props.children}
        </Box>
    );
};

interface GridRenderWithDriverOptions extends GridRenderCellParams {
    options: FleetDriver[];
}

const FleetVehiclesComponent: React.FC = () => {
    const { devices, isLoading: isLoadingDevices, isError: isErrorDevices } = useDevices(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { profile } = useProfile();
    const [subFleets, setSubFleets] = useState<string[]>(profile.customer.sub_fleets || []);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [alertComponent, setAlert] = useAlert();
    const { i18n, t } = useTranslation();
    const { api } = useApi();
    const apiRef = useGridApiRef();
    const isMobile = useIsMobile();
    const {
        drivers: allDrivers,
        isLoading: isLoadingDrivers,
        isError: isErrorDrivers,
        refetch: refetchDrivers,
    } = useDrivers();
    const drivers = allDrivers.filter((driver) => driver.active && !driver.is_auto_generated);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const driverTokenToNameMap = useMemo(() => {
        return drivers.reduce((acc, driver) => {
            acc[driver.token] = driver.fullName;
            return acc;
        }, {} as Record<string, string>);
    }, [drivers]);

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${fleetVehiclesPageDefs.name}`)}`;
    }, []);

    const FreeSoloEditInputCell = (props: GridRenderWithOptions) => {
        const { id, field, value } = props;
        const apiRef = useGridApiContext();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleChange = async (_event: any, newValue: string) => {
            await apiRef.current.setEditCellValue({ id, field, value: newValue });
            apiRef.current.stopCellEditMode({ id, field });
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleInputChange = async (_event: any, newValue: string) => {
            await apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        return (
            <Autocomplete
                data-testid="sub-fleet-field"
                freeSolo
                fullWidth
                value={value || ''}
                isOptionEqualToValue={(option, value) => option === value || value === ''}
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={props.options.filter(Boolean)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            disableUnderline: true,
                            // match input style with the cell
                            style: { paddingLeft: '10px', paddingRight: '9px', fontSize: '0.875rem' },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                )}
                sx={{
                    height: '100%',
                    '& .MuiFormControl-root': { height: '100%' },
                    '& .MuiAutocomplete-endAdornment': { paddingRight: '12px' },
                }}
            />
        );
    };

    const renderFreeSoloEditSubfleet = (params: GridRenderCellParams, subFleets: string[]) => {
        return <FreeSoloEditInputCell options={subFleets} {...params} />;
    };

    const AutocompleteInputCell = (props: GridRenderWithDriverOptions) => {
        const { id, field, value } = props;
        const apiRef = useGridApiContext();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleChange = async (_event: any, newValue: string) => {
            await apiRef.current.setEditCellValue({ id, field, value: newValue });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <Autocomplete
                data-testid="default-driver-field"
                fullWidth
                value={value || ''}
                isOptionEqualToValue={(option, value) => option === value || value === ''}
                onChange={handleChange}
                options={props.options.map((d) => d.token)}
                getOptionLabel={(option) => driverTokenToNameMap[option] || ''}
                renderOption={(props, option) => (
                    <li {...props} data-testid={`default-driver-field-${option}`}>
                        <Typography sx={{ pt: 0.5 }}>{driverTokenToNameMap[option] || ''}</Typography>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            disableUnderline: true,
                            // match input style with the cell
                            style: { paddingLeft: '10px', paddingRight: '9px', fontSize: '0.875rem' },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                )}
                sx={{
                    height: '100%',
                    '& .MuiFormControl-root': { height: '100%' },
                    '& .MuiAutocomplete-endAdornment': { paddingRight: '12px' },
                }}
            />
        );
    };

    const renderAutocompleteEditDriver = (params: GridRenderCellParams, drivers: FleetDriver[]) => {
        return <AutocompleteInputCell options={drivers} {...params} />;
    };

    const processRowUpdate = (newRow: GridRowModel, prevRow: GridRowModel) => {
        const updatedDevices = devices.map((d: DeviceV3) => {
            if (d.device.device_id === prevRow.id) {
                return {
                    ...d,
                    device: {
                        ...d.device,
                        default_driver: newRow.default_driver || null,
                        customer_sub_fleet: newRow.sub_fleet,
                    },
                };
            }
            return d;
        });
        if (newRow.subFleet && !subFleets.includes(newRow.sub_fleet)) {
            setSubFleets([...subFleets, newRow.sub_fleet]);
        }
        queryClient.setQueryData(['devices'], updatedDevices);
        api.apiV0DeviceUpdatePatch({
            deviceRequest: [
                {
                    device_id: newRow.id,
                    default_driver: newRow.default_driver || null,
                    customer_sub_fleet: newRow.sub_fleet || '',
                },
            ],
        })
            .then(() => {
                if (newRow.default_driver !== prevRow.default_driver) {
                    refetchDrivers();
                }
            })
            .catch(() => {
                setAlert({
                    message: t('content.fleet_vehicles.error_updating_vehicle'),
                    type: 'error',
                    duration: 6000,
                });
                queryClient.setQueryData(['devices'], () => devices);
            });
        return newRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'license_plate',
            headerName: t('content.fleet_vehicles.license_plate'),
            flex: 1,
        },
        {
            field: 'sub_fleet',
            type: 'singleSelect',
            valueOptions: subFleets.filter((sf) => sf !== ''),
            renderEditCell: (params: GridRenderCellParams) => renderFreeSoloEditSubfleet(params, subFleets),
            headerName: t('content.fleet_vehicles.sub_fleet'),
            flex: 1,
            editable: true,
        },
        {
            field: 'default_driver',
            type: 'singleSelect',
            valueOptions: drivers.map((d) => ({ value: d.token, label: d.fullName })),
            renderEditCell: (params: GridRenderCellParams) => renderAutocompleteEditDriver(params, drivers),
            headerName: t('content.fleet_vehicles.default_driver'),
            sortComparator: (v1, v2) => {
                const v1Name = driverTokenToNameMap[v1] || '';
                const v2Name = driverTokenToNameMap[v2] || '';
                return v1Name.localeCompare(v2Name);
            },
            flex: 1,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: t('content.drivers.actions'),
            width: 100,
            getActions: (params) => {
                const { id } = params;
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            id={`btn-save-${id}`}
                            data-testid={`btn-save-${id}`}
                            icon={<SaveIcon />}
                            label="Save"
                            color="inherit"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            id={`btn-cancel-${id}`}
                            data-testid={`btn-cancel-${id}`}
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        key="edit"
                        id={`btn-edit-${id}`}
                        data-testid={`btn-edit-${id}`}
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const rows = useMemo(() => {
        if (isLoadingDrivers) {
            return [];
        }
        return devices.map((device) => {
            return {
                id: device.device.device_id,
                license_plate: device.device.license_plate,
                sub_fleet: device.device.customer_sub_fleet,
                default_driver: device.device.default_driver,
                device: device,
            };
        });
    }, [devices, isLoadingDrivers]);

    const filteredRows = !!searchText
        ? rows.filter(
              (row) =>
                  normalizeLicensePlate(row.license_plate).includes(normalizeLicensePlate(searchText)) ||
                  normalizeName(row.sub_fleet).includes(normalizeName(searchText)) ||
                  (!!row.default_driver &&
                      normalizeName(driverTokenToNameMap[row.default_driver] || '').includes(
                          normalizeName(searchText),
                      )),
          )
        : rows;

    return (
        <Box sx={{ width: '100%', height: '100%', maxWidth: '1200px', display: 'flex', flexDirection: 'column', p: 2 }}>
            {alertComponent}
            {isUploadModalOpen ? (
                <UploadVehiclesModal
                    onClose={() => setIsUploadModalOpen(false)}
                    devices={devices}
                    drivers={drivers}
                    onDownloadList={() => {
                        const getAllRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
                            gridExpandedSortedRowIdsSelector(apiRef);
                        apiRef.current.exportDataAsCsv({
                            getRowsToExport: getAllRows,
                            allColumns: true,
                            fileName: 'vehicles',
                        });
                    }}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setAlert={setAlert}
                />
            ) : null}
            <StyledDataGrid
                id="fleet-vehicles-data-grid"
                apiRef={apiRef}
                loading={isLoadingDevices || isLoadingDrivers}
                editMode="row"
                columns={columns}
                disableRowSelectionOnClick
                rows={filteredRows}
                rowModesModel={rowModesModel}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{
                    ...gridLocalization[i18n.languages[0]],
                    filterPanelRemoveAll: t('table.remove_all'),
                    columnMenuManageColumns: t('table.manage_columns'),
                    unpin: t('table.unpin'),
                }}
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay:
                        isLoadingDevices || isLoadingDrivers
                            ? () => null
                            : () => (
                                  <Box
                                      sx={{
                                          width: '100%',
                                          height: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                      }}
                                  >
                                      <Typography variant="overline">
                                          {t(
                                              `content.fleet_vehicles.${
                                                  isErrorDevices || isErrorDrivers
                                                      ? 'error_loading'
                                                      : 'no_vehicles_found'
                                              }`,
                                          )}
                                      </Typography>
                                  </Box>
                              ),
                    toolbar: QuickSearchToolbar,
                }}
                columnBuffer={4}
                disableVirtualization={isMobile || process.env.JEST_WORKER_ID !== undefined}
                slotProps={{
                    columnsPanel: {
                        sx: {
                            '& .MuiDataGrid-panelFooter button:first-child': {
                                display: 'none',
                            },
                        },
                    },
                    toolbar: {
                        searchText,
                        setSearchText,
                        children: (
                            <ActionsMenu
                                onExport={() => {
                                    apiRef.current.exportDataAsCsv({
                                        allColumns: true,
                                        fileName: 'vehicles',
                                        utf8WithBom: true,
                                    });
                                }}
                                onUpload={() => setIsUploadModalOpen(true)}
                            />
                        ),
                    },
                }}
                sx={{
                    minWidth: 0,
                    height: '100%',
                    width: '100%',
                    '& .MuiDataGrid-columnHeaders': {
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-cell--editing': {
                        padding: '0 !important',
                        boxShadow: 'none !important',
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-actionsCell': {
                        gridGap: 0,
                    },
                    backgroundColor: palette.neutral[50],
                }}
            />
        </Box>
    );
};

export default FleetVehiclesComponent;
