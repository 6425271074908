import { Avatar, Box, SxProps, TextField, Typography } from '@mui/material';
import {
    GridColDef,
    GridColumnHeaderParams,
    GridFilterInputValueProps,
    GridRenderCellParams,
    GridRowsProp,
    GridValueFormatterParams,
    getGridNumericOperators,
    getGridStringOperators,
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { TrackedDataGrid as StyledDataGrid } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import { SECONDS_IN_HOUR, SECONDS_IN_MINUTE, dayjsToDateString, secondsToTime } from '../../../../utils/TimeFormatter';
import palette from '../../../ColorPalette';
import { DriverWithCounters, RECORD_CODE } from '../Defs';
import { abbrMap } from '../Defs';

dayjs.extend(utc);

export interface DriverCountersDataGridProps {
    drivers: DriverWithCounters[];
}

export const FilterInput = (props: GridFilterInputValueProps) => {
    return (
        <TextField
            type="text"
            variant="standard"
            label="Value"
            placeholder="Filter value"
            value={props.item.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.applyValue({ ...props.item, value: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
};

export const durationFormatter = (params: GridValueFormatterParams<number>) => {
    const time = secondsToTime(params.value);
    return `${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const durationParser = (value: any) => {
    if (value && /^\d{1,2}:\d{2}$/.test(value)) {
        const time = value.split(':');
        return parseInt(time[0]) * SECONDS_IN_HOUR + parseInt(time[1]) * SECONDS_IN_MINUTE;
    }
};

export const StatusAvatar = (props: { status: 'OFF' | 'SB' | 'D' | 'ON' | 'YM' | 'PC'; sx?: SxProps }) => {
    const isOffDuty = props.status === 'OFF' || props.status === 'SB';

    return (
        <Avatar
            variant="square"
            sx={{
                bgcolor: !isOffDuty ? 'secondary.main' : undefined,
                borderRadius: 1,
                width: 30,
                height: 30,
                boxShadow: 'rgba(0, 0, 0, 0.4) 0px 3px 3px',
                ...props.sx,
            }}
        >
            <Typography sx={{ fontSize: 14, mt: 0.25 }}>{props.status}</Typography>
        </Avatar>
    );
};

const CounterText = (props: { isViolation: boolean; children: React.ReactNode }) => {
    return (
        <Typography
            variant="body2"
            sx={{
                color: props.isViolation ? 'error.dark' : 'inherit',
                fontWeight: props.isViolation ? 'bold' : 'inherit',
            }}
        >
            {props.children}
        </Typography>
    );
};

const DriverCountersDataGrid: React.FC<DriverCountersDataGridProps> = (props: DriverCountersDataGridProps) => {
    const history = useHistory();
    const isMobile = useIsMobile();

    const columns: GridColDef[] = useMemo(() => {
        const baseColumns: GridColDef[] = [
            {
                field: 'name',
                headerName: 'Driver',
                minWidth: 150,
                flex: 1,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'device',
                headerName: 'Device',
                width: 150,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'status',
                type: 'singleSelect',
                valueOptions: Object.values(abbrMap),
                headerName: 'Status',
                width: 75,
                align: 'center',
                headerAlign: 'center',
                renderHeader: (params: GridColumnHeaderParams) => (
                    <Box sx={{ mr: 0.75 }}>{params.colDef.headerName}</Box>
                ),
                renderCell: (params: GridRenderCellParams) => <StatusAvatar status={params.value} />,
            },
            {
                field: 'break',
                type: 'number',
                headerName: 'Break',
                width: 100,
                renderCell: (params: GridRenderCellParams) => (
                    <CounterText isViolation={params.value === 0}>{params.formattedValue}</CounterText>
                ),
            },
            {
                field: 'drive',
                type: 'number',
                headerName: 'Drive',
                width: 100,
                renderCell: (params: GridRenderCellParams) => (
                    <CounterText isViolation={params.value === 0}>{params.formattedValue}</CounterText>
                ),
            },
            {
                field: 'shift',
                type: 'number',
                headerName: 'Shift',
                width: 100,
                renderCell: (params: GridRenderCellParams) => (
                    <CounterText isViolation={params.value === 0}>{params.formattedValue}</CounterText>
                ),
            },
            {
                field: 'cycle',
                type: 'number',
                headerName: 'Cycle',
                width: 100,
                renderCell: (params: GridRenderCellParams) => (
                    <CounterText isViolation={params.value === 0}>{params.formattedValue}</CounterText>
                ),
            },
            {
                field: 'violationToday',
                type: 'number',
                headerName: 'Violation (Today)',
                width: 150,
                renderCell: (params: GridRenderCellParams) => (
                    <CounterText isViolation={params.value > 0}>{params.formattedValue}</CounterText>
                ),
            },
            {
                field: 'violationCycle',
                type: 'number',
                headerName: 'Violation (Cycle)',
                width: 150,
                renderCell: (params: GridRenderCellParams) => (
                    <CounterText isViolation={params.value > 0}>{params.formattedValue}</CounterText>
                ),
            },
        ];

        return baseColumns.map((column) => {
            if (column.type === 'number') {
                return {
                    ...column,
                    valueFormatter: durationFormatter,
                    filterOperators: getGridNumericOperators().map((operator) => ({
                        ...operator,
                        InputComponent: FilterInput,
                    })),
                    valueParser: durationParser,
                };
            } else {
                return column;
            }
        });
    }, []);

    const rows: GridRowsProp = useMemo(() => {
        return props.drivers
            .map((driver) => {
                return {
                    id: driver.driver_id,
                    name: `${driver.first_name} ${driver.last_name}`,
                    status: driver.current_status
                        ? abbrMap[RECORD_CODE[driver.current_status] as keyof typeof RECORD_CODE]
                        : 'OFF',
                    device: driver.current_device?.license_plate || '',
                    break: driver.counters.break,
                    drive: driver.counters.drive,
                    shift: driver.counters.shift,
                    cycle: driver.counters.cycle,
                    violationToday: driver.counters.violation_today,
                    violationCycle: driver.counters.violation_cycle,
                };
            })
            .sort((a, b) => a.id - b.id);
    }, [props.drivers]);

    const driversById = Object.fromEntries(props.drivers.map((driver) => [driver.driver_id, driver]));

    return (
        <StyledDataGrid
            id="driver-counters-data-grid"
            rows={rows}
            columns={columns}
            columnBuffer={9}
            disableRowSelectionOnClick
            disableVirtualization={isMobile}
            pagination={isMobile}
            onRowClick={(params) =>
                history.push(
                    `/driver_logs/${params.id.toString()}/${dayjsToDateString(dayjs().utc(), driversById[params.id])}`,
                )
            }
            getRowClassName={(params) =>
                [params.row.break, params.row.drive, params.row.shift, params.row.cycle].includes(0)
                    ? 'in-violation'
                    : ''
            }
            slotProps={{
                columnsPanel: {
                    sx: {
                        '& .MuiDataGrid-panelFooter button:first-child': {
                            display: 'none',
                        },
                    },
                },
            }}
            sx={{
                height: '100%',
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
                '& .in-violation': {
                    backgroundColor: palette.red[200],
                },
                '& .in-violation:hover': {
                    backgroundColor: palette.red[200],
                },
                maxWidth: '1200px',
                backgroundColor: palette.neutral[50],
            }}
        />
    );
};

export default DriverCountersDataGrid;
