import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerData, NewDevice } from '../../../../../backendsdk';
import { TrackedButton as Button } from '../../../../../components/TrackedComponents';
import useApi from '../../../../../hooks/api';
import { normalizeLicensePlate } from '../../../../../utils/Str';
import { HORIZONTAL_APERTURE, Modes, VERTICAL_APERTURE } from '../SetupTool';
import MeasurementImage from '../assets/measurement-image.svg';
import InstDepthImageBus from '../assets/params/passengers/instDepth.svg';
import InstHeightImageBus from '../assets/params/passengers/instHeight.svg';
import InstHoodHeightBus from '../assets/params/passengers/instHoodHeight.svg';
import InstHorizontalOffsetLeftImageBus from '../assets/params/passengers/instHorizontalOffsetLeft.svg';
import InstHorizontalOffsetRightImageBus from '../assets/params/passengers/instHorizontalOffsetRight.svg';
import VehicleWeightImageBus from '../assets/params/passengers/vehicle-weight.svg';
import VehicleWidthImageBus from '../assets/params/passengers/vehicle-width.svg';
import InstDepthImageTruck from '../assets/params/property/instDepth.svg';
import InstHeightImageTruck from '../assets/params/property/instHeight.svg';
import InstHoodHeightTruck from '../assets/params/property/instHoodHeight.svg';
import InstHorizontalOffsetLeftImageTruck from '../assets/params/property/instHorizontalOffsetLeft.svg';
import InstHorizontalOffsetRightImageTruck from '../assets/params/property/instHorizontalOffsetRight.svg';
import VehicleWeightImageTruck from '../assets/params/property/vehicle-weight.svg';
import VehicleWidthImageTruck from '../assets/params/property/vehicle-width.svg';
import FixedContainer from '../ui/FixedContainer';
import { setStoredValue } from '../utils/storage';

export interface Params {
    imei: string;
    license_plate: string;
    instHeight: string;
    instDepth: string;
    instHoodHeight: string;
    'vehicle-width': string;
    'vehicle-weight': string;
    offsetDirection: string;
    instHorizontalOffset: string;
}

export const EMPTY_PARAMS: Params = {
    imei: '',
    license_plate: '',
    instHeight: '',
    instDepth: '',
    instHoodHeight: '',
    'vehicle-width': '',
    'vehicle-weight': '',
    offsetDirection: 'left',
    instHorizontalOffset: '',
};

export const FORM_STAGES: (keyof Params)[][] = [
    ['imei', 'license_plate'],
    [],
    ['instHeight'],
    ['instDepth'],
    ['instHoodHeight'],
    ['vehicle-width'],
    ['vehicle-weight'],
    ['instHorizontalOffset'],
];

const ADJUST_FORM_STAGES = FORM_STAGES.slice(2);

const WORKER_STAGE = 6;

const INTRO: Partial<Record<Modes, string[]>> = {
    install: ['install_intro1', 'install_intro2'],
    switch: ['switch_intro1', 'switch_intro2', 'switch_intro3'],
    adjust: ['switch_intro1', 'switch_intro2', 'switch_intro3'],
};

const getImages = (type: string): Record<string, React.ReactNode> => {
    const isPassengers = type === 'passengers';
    return {
        instHeight: <img src={isPassengers ? InstHeightImageBus : InstHeightImageTruck} width="80%" />,
        instDepth: <img src={isPassengers ? InstDepthImageBus : InstDepthImageTruck} width="100%" />,
        instHoodHeight: <img src={isPassengers ? InstHoodHeightBus : InstHoodHeightTruck} width="100%" />,
        instHorizontalOffsetLeft: (
            <img
                src={isPassengers ? InstHorizontalOffsetLeftImageBus : InstHorizontalOffsetLeftImageTruck}
                width="100%"
            />
        ),
        instHorizontalOffsetRight: (
            <img
                src={isPassengers ? InstHorizontalOffsetRightImageBus : InstHorizontalOffsetRightImageTruck}
                width="100%"
            />
        ),
        'vehicle-weight': <img src={isPassengers ? VehicleWeightImageBus : VehicleWeightImageTruck} width="90%" />,
        'vehicle-width': <img src={isPassengers ? VehicleWidthImageBus : VehicleWidthImageTruck} width="90%" />,
    };
};

const checkLicensePlate = (licensePlate: string): boolean => {
    const patten = new RegExp(/^(\d{2}-?\d{3}-?\d{2}|\d{3}-?\d{2}-?\d{3})$/);
    return patten.test(licensePlate);
};

const isValidNumberInRange = (value: string, min: number, max: number): boolean => {
    const number = parseFloat(value);
    return !Number.isNaN(number) && number >= min && number <= max;
};

const isZeroOrInRange = (value: string, min: number, max: number): boolean => {
    const number = parseFloat(value);
    return number === 0 || (number >= min && number <= max);
};

export const MEASUREMENT_SYSTEM_FACTOR = {
    METRIC: 1,
    IMPERIAL: 39.3700787, // 1 meter in inches
};

export const convertParam = (param: number, from: 'IMPERIAL' | 'METRIC', to: 'IMPERIAL' | 'METRIC') => {
    return (param * MEASUREMENT_SYSTEM_FACTOR[to]) / MEASUREMENT_SYSTEM_FACTOR[from];
};

interface InstallationParamsProps {
    malfunctionId: number;
    mode: Modes;
    customer: CustomerData;
    newDevice: NewDevice;
    formStage: number;
    setFormStage: React.Dispatch<React.SetStateAction<number>>;
    params: Params;
    setParams: React.Dispatch<React.SetStateAction<Params>>;
    onBack: () => void;
    onNext: () => void;
    runWorker: (params: {
        instHeight: string;
        instDepth: string;
        instHoodHeight: string;
        'vehicle-width': string;
        horizontalAperture: number;
        verticalAperture: number;
    }) => void;
    dashboardLink: React.ReactNode;
    expectedLicensePlate?: string;
}

const InstallationParams: React.FC<InstallationParamsProps> = (props) => {
    const isAdjust = props.mode === 'adjust';
    const formStages = isAdjust ? ADJUST_FORM_STAGES : FORM_STAGES;
    const { formStage, setFormStage } = props;
    const [params, setParams] = useState<Params>(props.params);
    const [errors, setErrors] = useState<string[]>([]);
    const [isLicensePlateWarning, setIsLicensePlateWarning] = useState<boolean>(false);
    const { t } = useTranslation();
    const { installApi } = useApi();
    const isUS = props.customer.settings.country === 'us';
    const isMetric = !isUS;
    const measurementSystem = isMetric ? 'METRIC' : 'IMPERIAL';
    const unitFactor = Math.ceil(MEASUREMENT_SYSTEM_FACTOR[measurementSystem]);
    const suffix = `${props.malfunctionId}-${props.mode}`;

    useEffect(() => {
        setStoredValue<Params>('OE-params', suffix, params);
        setStoredValue<number>('OE-form-stage', suffix, formStage);
    }, [params, formStage]);

    useEffect(() => {
        if (formStage === WORKER_STAGE) {
            props.runWorker({
                instHeight: params.instHeight,
                instDepth: params.instDepth,
                instHoodHeight: params.instHoodHeight,
                'vehicle-width': params['vehicle-width'],
                horizontalAperture: HORIZONTAL_APERTURE[props.newDevice.hardware],
                verticalAperture: VERTICAL_APERTURE[props.newDevice.hardware],
            });
        }
    }, [formStage]);

    const validationFunctions: Partial<Record<keyof Params, (value: string) => boolean>> = {
        imei: (imei: string): boolean => {
            return imei.length === 4 && props.newDevice.imei.endsWith(imei);
        },
        license_plate:
            props.mode === 'install'
                ? isUS
                    ? (licensePlate: string): boolean => {
                          return licensePlate.length > 0;
                      }
                    : checkLicensePlate
                : // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  (_licensePlate: string): boolean => true,
        instHeight: (instHeight: string): boolean => {
            return isValidNumberInRange(instHeight, 1 * unitFactor, 4 * unitFactor);
        },
        instDepth: (instDepth: string): boolean => {
            return isValidNumberInRange(instDepth, 0, 3 * unitFactor);
        },
        instHoodHeight: (instHoodHeight: string): boolean => {
            return isZeroOrInRange(instHoodHeight, 0.5 * unitFactor, parseFloat(params.instHeight));
        },
        instHorizontalOffset: (instHorizontalOffset: string): boolean => {
            return isValidNumberInRange(instHorizontalOffset, 0, 1 * unitFactor);
        },
        'vehicle-weight': (vehicleWeight: string): boolean => {
            return isValidNumberInRange(vehicleWeight, 1, 80);
        },
        'vehicle-width': (vehicleWidth: string): boolean => {
            return isValidNumberInRange(vehicleWidth, 1.5 * unitFactor, 3 * unitFactor);
        },
    };

    const handleBack = (): void => {
        if (isLicensePlateWarning) {
            setIsLicensePlateWarning(false);
            return;
        }
        if (formStage > 0) {
            setFormStage((prev) => prev - 1);
        } else {
            props.onBack();
        }
    };

    const handleNext = (): void => {
        let hasError = false;
        formStages[formStage].forEach((field) => {
            const validationFunc = validationFunctions[field];
            if (validationFunc !== undefined && !validationFunc(params[field])) {
                setErrors((prev) => [...prev, field]);
                hasError = true;
            } else {
                setErrors((prev) => prev.filter((error) => error !== field));
            }
        });
        if (hasError) {
            return;
        }
        if (
            props.expectedLicensePlate &&
            formStage === formStages.findIndex((stage) => stage.includes('license_plate'))
        ) {
            const normalizedLicensePlate = normalizeLicensePlate(params.license_plate);
            const normalizedExpectedLicensePlate = normalizeLicensePlate(props.expectedLicensePlate);
            if (normalizedLicensePlate !== normalizedExpectedLicensePlate && !isLicensePlateWarning) {
                setIsLicensePlateWarning(true);
                return;
            }
        }
        if (isLicensePlateWarning) {
            setIsLicensePlateWarning(false);
        }
        if (formStage < formStages.length - 1) {
            if (formStage === 0) {
                installApi.installConnectDeviceIdPost({ deviceId: props.newDevice.imei });
            }
            setFormStage((prev) => prev + 1);
        } else {
            props.setParams(params);
            props.onNext();
        }
    };

    let formContent;
    if (isLicensePlateWarning) {
        formContent = [
            <Typography key="license-plate-warning" color="error" sx={{ fontWeight: 500, mt: 1 }}>
                {t('setup_tool.installation_params.license_plate_warning', {
                    license_plate: params.license_plate,
                    expected_license_plate: props.expectedLicensePlate,
                })}
            </Typography>,
        ];
    } else {
        formContent = formStages[formStage].map((field, idx) => {
            const system = isMetric ? 'metric' : 'imperial';
            const units = field == 'vehicle-weight' ? 'tons' : isMetric ? 'meters' : 'inches';
            const label = t(`setup_tool.installation_params.${field}`, {
                units: t(`setup_tool.installation_params.units.${units}`),
            });

            let afterField;
            if (field === 'instHorizontalOffset') {
                afterField = (
                    <FormControl key="offset-direction">
                        <RadioGroup
                            id="offset-direction-field"
                            row
                            aria-labelledby="offset-direction-field"
                            value={params.offsetDirection}
                            onChange={(e) => setParams({ ...params, offsetDirection: e.target.value })}
                        >
                            <FormControlLabel
                                value="left"
                                control={<Radio id="left-radio-button" size="small" />}
                                label={t('setup_tool.installation_params.left')}
                            />
                            <FormControlLabel
                                value="right"
                                control={<Radio id="right-radio-button" size="small" />}
                                label={t('setup_tool.installation_params.right')}
                            />
                        </RadioGroup>
                    </FormControl>
                );
            }

            const fieldInstructions = (
                <Typography fontSize={14} textAlign="center" sx={{ fontWeight: 500, mb: 1 }}>
                    {t(`setup_tool.installation_params.instructions.${field}`)}
                </Typography>
            );

            let fieldImageName: string = field;
            if (field === 'instHorizontalOffset') {
                if (params.offsetDirection === 'left') {
                    fieldImageName = 'instHorizontalOffsetLeft';
                } else {
                    fieldImageName = 'instHorizontalOffsetRight';
                }
            }

            const image = getImages(props.customer.settings.driving_type)[fieldImageName];
            const isError = errors.includes(field);

            return (
                <React.Fragment key={`${field}-container`}>
                    <TextField
                        key={`${field}-${idx}`}
                        id={`${field.replace('_', '-')}-field`}
                        required
                        fullWidth
                        disabled={field === 'license_plate' && props.mode === 'switch'}
                        value={params[field as keyof Params]}
                        onChange={(e) => setParams({ ...params, [field]: e.target.value })}
                        error={isError || (field === 'license_plate' && isLicensePlateWarning)}
                        helperText={isError ? t(`setup_tool.installation_params.errors.${system}.${field}`) : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ 'data-testid': `${field}-input` }}
                        placeholder={t(`setup_tool.installation_params.examples.${system}.${field}`)}
                        label={label}
                        variant="outlined"
                        sx={{ mt: 2, mb: idx === formStages[formStage].length - 1 && !afterField && !isError ? 2 : 0 }}
                    />
                    {afterField}
                    {!!image ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            {fieldInstructions}
                            {image}
                            {field === 'instHeight' ? (
                                <Typography fontSize={14} textAlign="center" sx={{ fontWeight: 500, mt: 1 }}>
                                    {t('setup_tool.installation_params.instHeight_instructions')}
                                </Typography>
                            ) : null}
                            {field === 'instHorizontalOffset' ? (
                                <Typography fontSize={14} textAlign="center" sx={{ fontWeight: 500, mt: 1 }}>
                                    {t('setup_tool.installation_params.instHorizontalOffset_instructions')}
                                </Typography>
                            ) : null}
                        </Box>
                    ) : null}
                </React.Fragment>
            );
        });
    }

    const measurementsIntro =
        props.mode in INTRO ? (
            <FixedContainer>
                <img src={MeasurementImage} width={200} />
                {INTRO[props.mode]?.map((item) => (
                    <Typography key={item} sx={{ fontWeight: 500, mt: 1, width: '100%' }}>
                        {t(`setup_tool.installation_params.${item}`)}
                    </Typography>
                ))}
            </FixedContainer>
        ) : null;

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: 0,
                }}
            >
                <Typography id={`measurements-header-${formStage + 1}`} variant="h6" textAlign="center">
                    {`${t('setup_tool.installation_params.header')} (${formStage + 1}/${formStages.length})`}
                </Typography>
                {formContent.length > 0 ? formContent : measurementsIntro}
            </Box>
            <Box sx={{ width: '100%', display: 'flex', mt: 1, minHeight: 0, flexShrink: 0 }}>
                <Box sx={{ flex: 1, mr: 1, minHeight: 0 }}>
                    <Button id="btn-back" fullWidth variant="contained" color="primary" onClick={() => handleBack()}>
                        {t('setup_tool.back')}
                    </Button>
                </Box>
                <Box sx={{ flex: 1, minHeight: 0 }}>
                    <Button
                        id="btn-next"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={formStages[formStage].some((field) => params[field as keyof Params] === '')}
                        onClick={() => handleNext()}
                    >
                        {t('setup_tool.next')}
                    </Button>
                </Box>
            </Box>
            {props.dashboardLink}
        </Box>
    );
};

export default InstallationParams;
