export const validateTextField = (value: string) => {
    if (value.length === 0) {
        return 'required';
    }
    if (value.length > 150) {
        return 'max_length';
    }
    return undefined;
};

export const validateRole = (value: string) => {
    if (value === '') {
        return 'required';
    }
    return undefined;
};

export const isValidPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D+/g, '').length === 10;
};
