import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, FormControl, Grid, InputLabel, Paper, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { HosDriver, OverrideDriving, OverrideStop } from '../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../../components/TrackedComponents';

export interface AssignModalProps {
    loading: boolean;
    drivers: HosDriver[];
    onAssign: CallableFunction;
    onClose: CallableFunction;
}

const AssignModal: React.FC<AssignModalProps> = (props: AssignModalProps) => {
    const [driverId, setDriverId] = useState<string>(props.drivers[0].driver_id.toString());
    const [overrideDriving, setOverrideDriving] = useState<OverrideDriving>(OverrideDriving.D);
    const [overrideStop, setOverrideStop] = useState<OverrideStop>(OverrideStop.On);
    const [comment, setComment] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const selectedDriver = props.drivers.find((driver) => driver.driver_id.toString() === driverId);
    const possibleDrivingStatuses = [OverrideDriving.D];
    if (selectedDriver?.personal_conveyance) {
        possibleDrivingStatuses.push(OverrideDriving.Pc);
    }
    if (selectedDriver?.yard_move) {
        possibleDrivingStatuses.push(OverrideDriving.Ym);
    }

    useEffect(() => {
        setOverrideDriving(OverrideDriving.D);
    }, [driverId]);

    const validateComment = () => {
        if (comment.length < 4 || comment.length > 60) {
            setIsError(true);
            return false;
        }
        return true;
    };

    return (
        <Dialog id="assign-dialog" open={true} fullWidth={true} maxWidth="xs" onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AssignmentIndIcon sx={{ mr: 0.5 }} />
                    Assign Records
                </Box>
            </Box>
            <Paper sx={{ backgroundColor: 'bgColor.main', p: 1, borderRadius: 0 }}>
                <Card sx={{ p: 2 }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Grid container columns={2} columnSpacing={1} rowSpacing={2}>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="driver-field-label">Driver</InputLabel>
                                    <Select
                                        labelId="driver-field-label"
                                        id="driver-field"
                                        size="small"
                                        value={driverId}
                                        label="Driver"
                                        onChange={(e: SelectChangeEvent) => setDriverId(e.target.value)}
                                    >
                                        {props.drivers.map((driver) => (
                                            <MenuItem
                                                id={`driver-${driver.driver_id}-menu-item`}
                                                key={driver.driver_id}
                                                value={driver.driver_id.toString()}
                                            >
                                                {`${driver.first_name} ${driver.last_name}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="override-driving-label">Driving Status</InputLabel>
                                    <Select
                                        labelId="override-driving-label"
                                        id="override-driving"
                                        size="small"
                                        value={overrideDriving}
                                        label="Driving Status"
                                        onChange={(e: SelectChangeEvent) =>
                                            setOverrideDriving(e.target.value as OverrideDriving)
                                        }
                                    >
                                        {possibleDrivingStatuses.map((status) => (
                                            <MenuItem
                                                key={status}
                                                value={status}
                                                id={`${status}-status`}
                                                data-testid={`${status}-status`}
                                            >
                                                {status}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="override-stop-label">Stop Status</InputLabel>
                                    <Select
                                        labelId="override-stop-label"
                                        id="override-stop"
                                        size="small"
                                        value={overrideStop}
                                        label="Stop Status"
                                        onChange={(e: SelectChangeEvent) =>
                                            setOverrideStop(e.target.value as OverrideStop)
                                        }
                                    >
                                        {Object.values(OverrideStop).map((status) => (
                                            <MenuItem id={`${status}-menu-item`} key={status} value={status}>
                                                {status}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    id="comment-field"
                                    label="Comment"
                                    value={comment}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
                                    error={isError}
                                    helperText={isError ? 'Comment must be between 4 and 60 characters' : ''}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
                <Grid container columns={2} columnSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={1}>
                        <Button
                            id="btn-cancel-assign"
                            fullWidth
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => props.onClose()}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            id="btn-assign"
                            fullWidth
                            data-testid="btn-assign"
                            color="secondary"
                            variant="contained"
                            size="small"
                            disabled={props.loading}
                            onClick={() => {
                                if (validateComment()) {
                                    props.onAssign({ driverId, overrideDriving, overrideStop, comment });
                                }
                            }}
                        >
                            Assign
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    );
};

export default AssignModal;
