import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Chip, Collapse, List, ListItem, ListItemText } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { Malfunction } from '../../backendsdk';
import {
    TrackedIconButton as IconButton,
    TrackedLink as Link,
    TrackedListItemButton as ListItemButton,
} from '../../components/TrackedComponents';
import Logo from '../../images/logo/logo.svg';
import { PageDefs, Section, flattenRoles, getSectionIcons, metricsBaseURL } from '../../utils/Pages';
import palette from '../ColorPalette';
import { RTLDirectionContext } from '../Layout';
import { OE_ROLES } from '../content/Users/UsersComponent';
import AvatarMenu from './AvatarMenu';
import { DEFAULT_PAGE, LANGUAGES_OPTIONS, RTL_LANGUAGES } from './Navigator';

interface FullContentProps {
    availableSections: Section[];
    availablePages: Record<Section, PageDefs[]>;
    setOpenDrawer: CallableFunction;
    onLogout: CallableFunction;
    malfunctions: Malfunction[];
}

const FullContent: React.FC<FullContentProps> = (props: FullContentProps) => {
    const [openSection, setOpenSection] = useState<Section>();
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const isRTL = useContext(RTLDirectionContext);

    let selectedPage = `/${location.pathname.split('/')[1]}`;
    if (selectedPage === metricsBaseURL) {
        selectedPage = location.pathname;
    }

    useEffect(() => {
        for (const section of props.availableSections) {
            for (const page of props.availablePages[section]) {
                if (page.path.includes(selectedPage)) {
                    return setOpenSection(section);
                }
            }
        }
    }, [selectedPage]);

    const toggleSectionCollapse = (section: Section) => {
        setOpenSection((prevSection) => {
            if (prevSection === section) {
                return undefined;
            }
            return section;
        });
    };

    const sectionIcons = getSectionIcons();

    return (
        <>
            <Link id="link-to-default-page" component={RouterLink} to={DEFAULT_PAGE}>
                <Box sx={{ width: '100%', p: 1, pb: 0 }}>
                    <img data-testid="logo-full" src={Logo} style={{ width: '100%', objectFit: 'contain' }} />
                </Box>
            </Link>
            <List disablePadding>
                {props.availableSections.map((section) => {
                    return (
                        <React.Fragment key={section}>
                            <ListItemButton
                                id={`navbar-${section}`}
                                type="navigator"
                                data-expanded={openSection === section}
                                onClick={() => toggleSectionCollapse(section)}
                            >
                                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                                    {sectionIcons[section]}
                                </Box>
                                <ListItemText primary={t(`navigator.${section}`)} />
                                {openSection === section ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openSection === section} timeout="auto" unmountOnExit>
                                <List
                                    component="div"
                                    disablePadding
                                    sx={{
                                        // selected and selected + hover states
                                        '&& .Mui-selected, && .Mui-selected:hover': {
                                            bgcolor: 'primary.light',
                                            '&, & .MuiListItemIcon-root': {
                                                color: palette.white,
                                            },
                                        },
                                    }}
                                >
                                    {props.availablePages[section].map((page) => {
                                        const isBeta =
                                            flattenRoles(page).every((role) => OE_ROLES.includes(role)) &&
                                            page.name !== 'malfunctions';
                                        return (
                                            <ListItem key={page.name} disablePadding>
                                                <ListItemButton
                                                    id={`navbar-${page.name}`}
                                                    type="navigator"
                                                    selected={page.path.includes(selectedPage)}
                                                    component={RouterLink}
                                                    to={page.path[page.path.length - 1]}
                                                >
                                                    <Box sx={{ width: '25px', flexShrink: 0 }}></Box>
                                                    {page.new || isBeta ? (
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={t(`navigator.${page.name}`, page.name)}
                                                            />
                                                            <Chip
                                                                label={
                                                                    page.new ? t('navigator.new') : t('navigator.beta')
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    color: palette.white,
                                                                    // conditionally position gradient to avoid bug in RTL
                                                                    background: page.new
                                                                        ? `linear-gradient(180deg, ${
                                                                              palette.gray[400]
                                                                          } ${isRTL ? '100' : '0'}%, ${
                                                                              palette.primary
                                                                          } 100%);`
                                                                        : `linear-gradient(180deg, ${
                                                                              palette.gray[600]
                                                                          } ${isRTL ? '100' : '0'}%, ${
                                                                              palette.primary
                                                                          } 100%);`,
                                                                    fontSize: 11,
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <ListItemText
                                                            primary={t(`navigator.${page.name}`, page.name)}
                                                        />
                                                    )}
                                                    {page.name === 'malfunctions' && !!props.malfunctions.length ? (
                                                        <Chip
                                                            size="small"
                                                            label={props.malfunctions.length}
                                                            sx={{ backgroundColor: palette.bad }}
                                                        />
                                                    ) : null}
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    );
                })}
            </List>
            <List style={{ marginTop: `auto` }} disablePadding>
                <AvatarMenu withMenu={true} onLogout={props.onLogout} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ListItem disablePadding>
                        <ListItemButton
                            id="navbar-help"
                            onClick={() => {
                                window.open(
                                    `/documents/openeyes_user_manual-${
                                        LANGUAGES_OPTIONS.includes(i18n.languages[0]) ? i18n.languages[0] : 'en'
                                    }.pdf`,
                                    '_blank',
                                );
                            }}
                        >
                            <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                                <HelpOutlineIcon fontSize="small" />
                            </Box>
                            <ListItemText primary={t('navigator.help')} />
                        </ListItemButton>
                    </ListItem>
                    <IconButton
                        id="navbar-toggle-width"
                        buttonType="navigator"
                        sx={{ flexShrink: 0, color: palette.white }}
                        onClick={() => {
                            setOpenSection(undefined);
                            props.setOpenDrawer((prev: boolean) => !prev);
                        }}
                    >
                        {RTL_LANGUAGES.includes(i18n.languages[0]) ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
                    </IconButton>
                </Box>
            </List>
        </>
    );
};

export default FullContent;
