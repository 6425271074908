import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Fade, Slide } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

import { EventDetails, ExtendedFeedEvent } from '../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useIsMobile from '../../../hooks/isMobile';
import palette from '../../ColorPalette';
import { getGpsTraceFromCsv } from '../Event/EventDetails';
import EventDetailsSectionComponent from '../Event/EventDetailsSection';

interface FeedEventDetailsComponentProps {
    eventId: number;
    showEventDetails: boolean;
    setShowEventDetails: (value: boolean) => void;
    currentEvent: ExtendedFeedEvent;
    setEvents: React.Dispatch<React.SetStateAction<ExtendedFeedEvent[]>>;
    setSnackbarMessage: (message: string) => void;
    containerRef: React.RefObject<HTMLDivElement>;
}

const FeedEventDetailsComponent: React.FC<FeedEventDetailsComponentProps> = (props) => {
    const isMobile = useIsMobile();
    const [details, setDetails] = useState<EventDetails>();
    const [gpsRoute, setGpsRoute] = useState<Array<[string, number, CallableFunction]>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { api } = useApi();

    //remove?
    isMobile;

    const getEventDetails = (controller?: AbortController) => {
        setIsLoading(true);
        api.apiV0EventEventIdGet({ eventId: props.eventId }, { signal: controller?.signal })
            .then((res: { data: EventDetails }) => {
                const newDetails = res.data;
                if (res.data.event_id == props.eventId) {
                    setDetails(newDetails);
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        const controller = new AbortController();
        getEventDetails(controller);
        return () => controller.abort();
    }, [props.eventId]);

    useEffect(() => {
        // get gps file
        if (details) {
            const artifactUrl = Object.entries(details.artifact_path).find(([name]) => name.includes('gps.csv'))?.[1];
            if (artifactUrl) {
                getGpsTraceFromCsv(artifactUrl).then((gpsTrace) => {
                    if (gpsTrace.length > 0) {
                        const gpsTraceRoute: Array<[string, number, CallableFunction]> = gpsTrace.reduce(
                            (acc, entry) => {
                                if (!isNaN(entry.lat) && !isNaN(entry.lng)) {
                                    acc.push([`${entry.lat}:${entry.lng}`, 0, () => palette.accent]);
                                }
                                return acc;
                            },
                            [] as Array<[string, number, CallableFunction]>,
                        );
                        setGpsRoute(gpsTraceRoute);
                    } else {
                        setGpsRoute([]);
                    }
                });
            } else {
                setGpsRoute([]);
            }
        }
    }, [details]);

    return (
        <>
            <Fade in={props.showEventDetails}>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: alpha(palette.primary, 0.5),
                        zIndex: 9,
                    }}
                    onClick={() => props.setShowEventDetails(false)}
                ></Box>
            </Fade>
            {details && !isLoading ? (
                <Slide in={props.showEventDetails} direction="up" unmountOnExit container={props.containerRef?.current}>
                    <Box
                        sx={{
                            width: '100%',
                            position: 'absolute',
                            bottom: 0,
                            minHeight: '300px',
                            maxHeight: '50%',
                            zIndex: 10,
                            backgroundColor: 'primary.main',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 1,
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '50px',
                                height: '50px',
                                top: '-25px',
                                left: 'calc(50% - 25px)',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                id="close-event-details"
                                onClick={() => props.setShowEventDetails(false)}
                                sx={{
                                    p: 0,
                                    borderRadius: '50%',
                                    backgroundColor: 'primary.main',
                                    minWidth: 0,
                                    height: '50px',
                                    width: '50px',
                                    zIndex: 1001,
                                    '&:hover': {
                                        backgroundColor: 'primary.main',
                                    },
                                }}
                            >
                                <KeyboardArrowDownIcon sx={{ color: palette.neutral[300] }} />
                            </IconButton>
                        </Box>
                        <EventDetailsSectionComponent
                            eventId={props.eventId.toString()}
                            isLoading={isLoading}
                            onEventsPage={false}
                            openInDialog={false}
                            fitBoundsCounter={0}
                            details={details}
                            gpsRoute={gpsRoute}
                            darkMode
                        />
                    </Box>
                </Slide>
            ) : null}
        </>
    );
};

export default FeedEventDetailsComponent;
