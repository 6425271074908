import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Box, TextField, useMediaQuery, useTheme } from '@mui/material';
import { GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';

interface QuickSearchToolbarProps {
    value: string;
    onChange: () => void;
    refresh: () => void;
    children?: React.ReactNode;
    filterButtonRef?: React.RefObject<HTMLButtonElement>;
}

const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column-reverse' : 'row',
                alignItems: 'end',
                py: 1,
                overflow: 'none',
                gap: 1,
            }}
        >
            {props.children}
            <TextField
                fullWidth={isMobile}
                id="table-search-field"
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                size="small"
                placeholder={t('table.search')}
                InputProps={{
                    startAdornment: <SearchIcon color="primary" fontSize="small" sx={{ mb: 0.5 }} />,
                }}
                sx={{ mr: 1 }}
            />
            <Box sx={{ flexShrink: 0, display: 'flex' }}>
                <GridToolbarFilterButton
                    componentsProps={{ button: { ref: props.filterButtonRef, variant: 'outlined' } }}
                />
            </Box>
            <IconButton id="refresh-btn" size="small" onClick={() => props.refresh()}>
                <RefreshIcon />
            </IconButton>
        </Box>
    );
};

export default QuickSearchToolbar;
