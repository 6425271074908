import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Collapse, List, ListItem, Slide, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import {
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
    TrackedListItemButton as ListItemButton,
} from '../../components/TrackedComponents';
import useProfile from '../../hooks/profile';
import InvertedLogo from '../../images/logo/logo.svg';
import Logo from '../../images/logo/logo_black.svg';
import MonoLogoTop from '../../images/logo/logo_mini.svg';
import MonoLogoBottom from '../../images/logo/logo_secondary.svg';
import {
    PageDefs,
    Section,
    eventFeedPageDefs,
    flattenRoles,
    getSectionIcons,
    sections,
    userSettingsPageDefs,
} from '../../utils/Pages';
import palette from '../ColorPalette';
import AvatarMenu from './AvatarMenu';
import { DEFAULT_PAGE, LANGUAGES_OPTIONS } from './Navigator';

export interface MobileNavigatorProps {
    pagesInSections: Record<Section, PageDefs[]>;
    onLogout: CallableFunction;
}

const Transition = React.forwardRef(
    (
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) => {
        return <Slide direction="right" ref={ref} {...props} />;
    },
);
Transition.displayName = 'Transition';

const MobileNavigator: React.FC<MobileNavigatorProps> = (props: MobileNavigatorProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const { profile } = useProfile();
    const location = useLocation();
    const history = useHistory();
    const { i18n, t } = useTranslation();

    const availablePages = Object.fromEntries(
        Object.entries(props.pagesInSections).map(([section, pages]) => {
            const pageList = pages.filter((page: PageDefs) => flattenRoles(page).includes(profile.role));
            return [section, pageList];
        }),
    );

    const selectedPage = location.pathname.split('/')[1] ? location.pathname.split('/')[1] : DEFAULT_PAGE;
    let selectedSection: Section | undefined;
    for (const section of Object.keys(availablePages)) {
        for (const page of availablePages[section]) {
            if (page.path.includes(`/${selectedPage}`)) {
                selectedSection = section;
            }
        }
    }

    const [openSection, setOpenSection] = useState<Section | undefined>(selectedSection);

    const availableSections = sections.filter((section) => availablePages[section]?.length > 0);

    const toggleSectionCollapse = (section: Section) => {
        setOpenSection((prevSection) => {
            if (prevSection === section) {
                return undefined;
            }
            return section;
        });
    };

    const handleNavigate = (page: PageDefs) => {
        const path = page.path[page.path.length - 1];
        history.push(path);
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            setOpenSection(selectedSection);
        }
    }, [open]);

    const sectionIcons = getSectionIcons(26);

    const isInvertedColors = location.pathname.includes(eventFeedPageDefs.path[eventFeedPageDefs.path.length - 1]);

    return (
        <>
            <AppBar
                data-testid="mobile-appbar"
                color={isInvertedColors ? 'primary' : 'secondary'}
                position="static"
                sx={{
                    zIndex: 1001,
                    boxShadow: isInvertedColors
                        ? 'none'
                        : '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between', pr: 0 }}>
                    <IconButton
                        id="navbar-open"
                        buttonType="navigator"
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setOpen(true)}
                    >
                        <MenuIcon color={isInvertedColors ? 'secondary' : 'primary'} />
                    </IconButton>
                    <Box sx={{ height: '56px' }}>
                        <Link id="link-to-default-page" component={RouterLink} to={DEFAULT_PAGE}>
                            <img src={isInvertedColors ? InvertedLogo : Logo} style={{ height: '100%' }} />
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
            <Dialog
                id="mobile-navigator"
                fullScreen
                open={open}
                onClose={() => setOpen(false)}
                TransitionComponent={Transition}
                sx={{ '& .MuiPaper-root': { backgroundColor: 'primary.main' }, direction: 'ltr' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                        color: palette.white,
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', p: 0.5 }}>
                        <IconButton
                            id="navbar-close"
                            buttonType="navigator"
                            size="large"
                            sx={{ color: palette.white }}
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon sx={{ fontSize: 28 }} />
                        </IconButton>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                mt: 2,
                            }}
                        >
                            <img src={MonoLogoTop} style={{ width: '50px', marginBottom: '10px' }} />
                            <img src={MonoLogoBottom} style={{ width: '200px' }} />
                        </Box>
                        <IconButton
                            id="navbar-help"
                            buttonType="navigator"
                            size="large"
                            sx={{ color: palette.white }}
                            onClick={() => {
                                window.open(
                                    `/documents/openeyes_user_manual-${
                                        LANGUAGES_OPTIONS.includes(i18n.languages[0]) ? i18n.languages[0] : 'en'
                                    }.pdf`,
                                    '_blank',
                                );
                            }}
                        >
                            <HelpOutlineIcon sx={{ fontSize: 28 }} />
                        </IconButton>
                    </Box>
                    <List disablePadding sx={{ mt: 2 }}>
                        {availableSections.map((section) => {
                            return (
                                <React.Fragment key={section}>
                                    <ListItemButton
                                        id={`navbar-${section}`}
                                        onClick={() => toggleSectionCollapse(section)}
                                        sx={{ alignItems: 'center' }}
                                    >
                                        <Box sx={{ width: '35px', display: 'flex', alignItems: 'center' }}>
                                            {sectionIcons[section]}
                                        </Box>
                                        <Typography sx={{ fontSize: 20, mt: 0.5 }}>
                                            {t(`navigator.${section}`)}
                                        </Typography>
                                        {openSection === section ? (
                                            <ExpandLess sx={{ fontSize: 26 }} />
                                        ) : (
                                            <ExpandMore sx={{ fontSize: 26 }} />
                                        )}
                                    </ListItemButton>
                                    <Collapse in={openSection === section} timeout="auto" unmountOnExit>
                                        <List
                                            component="div"
                                            disablePadding
                                            sx={{
                                                // selected and selected + hover states
                                                '&& .Mui-selected, && .Mui-selected:hover': {
                                                    bgcolor: 'primary.light',
                                                    '&, & .MuiListItemIcon-root': {
                                                        color: palette.white,
                                                    },
                                                },
                                            }}
                                        >
                                            {availablePages[section].map((page) => (
                                                <ListItem key={page.name} disablePadding>
                                                    <ListItemButton
                                                        id={`navbar-${page.name}`}
                                                        type="navigator"
                                                        selected={page.path.includes(`/${selectedPage}`)}
                                                        onClick={() => handleNavigate(page)}
                                                    >
                                                        <Box sx={{ width: '35px' }}></Box>
                                                        <Typography sx={{ fontSize: 20 }}>
                                                            {t(`navigator.${page.name}`, page.name)}
                                                        </Typography>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                            );
                        })}
                    </List>
                    <Box sx={{ marginTop: `auto`, display: 'flex', alignItems: 'center' }}>
                        <List disablePadding sx={{ width: '100%' }}>
                            <AvatarMenu withMenu={false} onClick={() => handleNavigate(userSettingsPageDefs)} />
                        </List>
                        <IconButton
                            id="btn-logout"
                            buttonType="navigator"
                            size="large"
                            onClick={() => props.onLogout()}
                            sx={{ flexShrink: 0, mx: 1 }}
                        >
                            <LogoutIcon sx={{ flexShrink: 0, color: palette.white }} />
                        </IconButton>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
};

export default MobileNavigator;
