import { Box } from '@mui/material';
import React, { useState } from 'react';

import { TrackedButton as Button, TrackedDialog as Dialog } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import { MuiTextField } from '../Accident/MuiFormControls';

interface UpdateOverlayProps {
    onClose: CallableFunction;
    onSuccess: CallableFunction;
}

const AddCustomerModal: React.FC<UpdateOverlayProps> = (props) => {
    const [customerName, setCustomerName] = useState<string>('');
    const [subFleetName, setSubFleetName] = useState<string>('');
    const { controlApi } = useApi();
    const [alertElement, setAlert] = useAlert();

    const handleSave = () => {
        controlApi
            .controlV0CustomerConfigPost({
                body: { customer: customerName, sub_fleet: subFleetName },
            })
            .then((res) => {
                props.onSuccess(res.data);
                props.onClose();
            })
            .catch((err) => {
                setAlert({ message: err.message, type: 'error', duration: 6000 });
            });
    };

    return (
        <Dialog
            id="add-customer-dialog"
            open={true}
            maxWidth="md"
            fullWidth
            onClose={() => props.onClose()}
            sx={{ mx: 1 }}
        >
            <Box sx={{ backgroundColor: 'secondary.main', p: 1 }}>New Customer</Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    m: 1,
                }}
            >
                {alertElement}
                <MuiTextField
                    id="customer-name"
                    name="customer-name"
                    sx={{ mt: 1 }}
                    label="Customer Name"
                    value={customerName}
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setCustomerName(e.target.value)
                    }
                />
                <MuiTextField
                    id="sub-fleet-name"
                    name="sub-fleet-name"
                    sx={{ mt: 1 }}
                    label="Sub Fleet Name"
                    value={subFleetName}
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setSubFleetName(e.target.value)
                    }
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 1 }}>
                    <Button id="btn-save" color="secondary" variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                    <Button id="btn-cancel" color="primary" variant="contained" onClick={() => props.onClose()}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default AddCustomerModal;
