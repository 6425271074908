import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
    Box,
    Card,
    Checkbox,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { HomeTerminal, HosDriver } from '../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../../components/TrackedComponents';
import useProfile from '../../../../hooks/profile';
import { unformatPhoneNumber } from '../../../../utils/Str';
import { PHONE_NUMBER_MASK_MAP } from '../../UserSettings/UserSettings';
import { StyledFormControl } from '../../Users/MuiStyled';
import { isValidPhoneNumber } from '../../Users/validation';
import { STATES_PROVINCES, StateProvince } from '../Defs';

export interface DriverModalProps {
    driver?: HosDriver;
    terminals: HomeTerminal[];
    onAddDriver: CallableFunction;
    onUpdateDriver: CallableFunction;
    onClose: CallableFunction;
}

const getEmptyDriver = (defaultTerminal: HomeTerminal, defaultRuleset: 'PROPERTY' | 'PASSENGER') => ({
    driver_id: 0,
    phone: '',
    email: '',
    license: '',
    current_device: {
        device_id: '',
        customer: '',
        operator: '',
        customer_sub_fleet: '',
        license_plate: '',
        driver_name: '',
    },
    home_terminal: defaultTerminal,
    driving_type: defaultRuleset,
    first_name: '',
    last_name: '',
    driver_license_number: '',
    driver_license_state_province: 'NY' as StateProvince,
    personal_conveyance: false,
    yard_move: false,
    last_login: 0,
    is_active: false,
});

const DriverModal: React.FC<DriverModalProps> = (props: DriverModalProps) => {
    let initialDetails: HosDriver = getEmptyDriver(props.terminals[0], 'PROPERTY');
    if (props.driver) {
        initialDetails = { ...props.driver, phone: unformatPhoneNumber(props.driver.phone) };
    }
    const [details, setDetails] = useState<HosDriver>(initialDetails);
    const [invite, setInvite] = useState<boolean>(false);
    const { profile } = useProfile();

    useEffect(() => {
        if (!details.is_active) {
            setInvite(false);
        }
    }, [details.is_active]);

    useEffect(() => {
        if (invite) {
            setDetails((prev) => ({ ...prev, is_active: true }));
        }
    }, [invite]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDetails((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const updateHomeTerminal = (e: SelectChangeEvent) => {
        const newTerminal = props.terminals.find((terminal) => terminal.id.toString() === e.target.value);
        if (newTerminal) {
            setDetails((prev) => ({
                ...prev,
                home_terminal: newTerminal,
            }));
        }
    };

    const isValid =
        [details.first_name + details.last_name, details.email, details.phone, details.driver_license_number].every(
            (field: string) => field !== '',
        ) && isValidPhoneNumber(details.phone);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const phoneInput: any = (props: any) => {
        const inputElement = (
            <TextField
                id="phone-field"
                name="phone"
                size="small"
                fullWidth
                label="Phone Number"
                inputProps={{ style: { direction: 'ltr' } }}
                sx={{ mb: 2 }}
                {...props}
            />
        );
        return inputElement;
    };

    return (
        <Dialog id="driver-dialog" open={true} fullWidth={true} maxWidth="xs" onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {props.driver === undefined ? <PersonAddIcon sx={{ mr: 0.5 }} /> : <PersonIcon sx={{ mr: 0.5 }} />}
                    {props.driver === undefined ? 'Add Driver' : `${props.driver.first_name} ${props.driver.last_name}`}
                </Box>
            </Box>
            <Paper sx={{ backgroundColor: 'bgColor.main', p: 1 }}>
                <Card sx={{ p: 2 }}>
                    <Grid container columns={2} columnSpacing={1} rowSpacing={2}>
                        <Grid item xs={2} sm={1}>
                            <TextField
                                id="first-name-field"
                                label="First Name"
                                name="first_name"
                                size="small"
                                fullWidth
                                value={details.first_name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <TextField
                                id="last-name-field"
                                label="Last Name"
                                name="last_name"
                                size="small"
                                fullWidth
                                value={details.last_name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="email-field"
                                label="Email"
                                name="email"
                                size="small"
                                fullWidth
                                value={details.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <InputMask
                                mask={PHONE_NUMBER_MASK_MAP[profile.customer.settings.country]}
                                value={details.phone}
                                onChange={handleChange}
                                maskChar=""
                            >
                                {phoneInput}
                            </InputMask>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <TextField
                                id="driver-license-number-field"
                                label="Driver License Number"
                                name="driver_license_number"
                                size="small"
                                fullWidth
                                value={details.driver_license_number}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <FormControl fullWidth>
                                <InputLabel id="driver-license-state-province-field-label">Issuing State</InputLabel>
                                <Select
                                    labelId="driver-license-state-province-field-label"
                                    id="driver-license-state-province-field"
                                    name="driver_license_state_province"
                                    size="small"
                                    value={details.driver_license_state_province}
                                    label="Issuing State"
                                    onChange={(e: SelectChangeEvent) =>
                                        setDetails((prev) => ({
                                            ...prev,
                                            driver_license_state_province: e.target.value as StateProvince,
                                        }))
                                    }
                                >
                                    {STATES_PROVINCES.map((stateProvince) => (
                                        <MenuItem
                                            id={`${stateProvince}-menu-item`}
                                            key={stateProvince}
                                            value={stateProvince}
                                        >
                                            {stateProvince}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="home-terminal-field-label">Home Terminal</InputLabel>
                                <Select
                                    labelId="home-terminal-field-label"
                                    id="home-terminal-field"
                                    name="home_terminal"
                                    size="small"
                                    value={details.home_terminal.id.toString()}
                                    label="Home Terminal"
                                    onChange={updateHomeTerminal}
                                >
                                    {props.terminals.map((terminal) => (
                                        <MenuItem
                                            id={`terminal-${terminal.id}-menu-item`}
                                            key={terminal.id}
                                            value={terminal.id.toString()}
                                        >
                                            {terminal.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="ruleset-field-label">Ruleset</InputLabel>
                                <Select
                                    labelId="ruleset-field-label"
                                    id="ruleset-field"
                                    name="type"
                                    size="small"
                                    value={details.driving_type}
                                    label="Ruleset"
                                    onChange={(e: SelectChangeEvent) =>
                                        setDetails((prev) => ({
                                            ...prev,
                                            driving_type: e.target.value as 'PROPERTY' | 'PASSENGER',
                                        }))
                                    }
                                >
                                    <MenuItem id="property-menu-item" value="PROPERTY">
                                        Federal Property
                                    </MenuItem>
                                    <MenuItem id="passenger-menu-item" value="PASSENGER">
                                        Federal Passenger
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <FormGroup>
                                <StyledFormControl
                                    control={
                                        <Checkbox
                                            id="pc-checkbox"
                                            name="personal_conveyance"
                                            size="small"
                                            checked={details.personal_conveyance}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setDetails((prev) => ({
                                                    ...prev,
                                                    personal_conveyance: e.target.checked,
                                                }))
                                            }
                                        />
                                    }
                                    label="Personal Conveyance"
                                    sx={{ height: '1.5rem' }}
                                />
                                <StyledFormControl
                                    control={
                                        <Checkbox
                                            id="ym-checkbox"
                                            name="yard_move"
                                            size="small"
                                            checked={details.yard_move}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setDetails((prev) => ({ ...prev, yard_move: e.target.checked }))
                                            }
                                        />
                                    }
                                    sx={{ height: '1.5rem' }}
                                    label="Yard Move"
                                />
                            </FormGroup>
                        </Grid>
                        {details.driver_id === 0 ? (
                            <Grid item xs={2} sm={1}>
                                <FormGroup>
                                    <StyledFormControl
                                        control={
                                            <Checkbox
                                                id="activate-checkbox"
                                                size="small"
                                                name="is_active"
                                                checked={details.is_active}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    setDetails((prev) => ({ ...prev, is_active: e.target.checked }))
                                                }
                                            />
                                        }
                                        sx={{ height: '1.5rem' }}
                                        label="Activate driver"
                                    />
                                    <StyledFormControl
                                        control={
                                            <Checkbox
                                                id="invite-checkbox"
                                                size="small"
                                                checked={invite}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    setInvite(e.target.checked)
                                                }
                                                name="invite"
                                            />
                                        }
                                        label="Send invitation"
                                        sx={{ height: '1.5rem' }}
                                    />
                                </FormGroup>
                            </Grid>
                        ) : null}
                    </Grid>
                </Card>
                <Grid container columns={2} columnSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={1}>
                        <Button
                            id="save-btn"
                            color="secondary"
                            variant="contained"
                            size="small"
                            fullWidth
                            disabled={!isValid}
                            onClick={() => {
                                // remove fields not necessary for the request
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const { current_device, last_login, current_status, license, ...requestDetails } =
                                    details;
                                if (details.driver_id === 0) {
                                    props.onAddDriver({
                                        ...requestDetails,
                                        phone: unformatPhoneNumber(requestDetails.phone),
                                        send_invitation: invite,
                                    });
                                } else {
                                    props.onUpdateDriver({
                                        ...requestDetails,
                                        phone: unformatPhoneNumber(requestDetails.phone),
                                        send_invitation: invite,
                                    });
                                }
                            }}
                        >
                            {props.driver === undefined ? 'Add' : 'Update'}
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            id="cancel-btn"
                            color="primary"
                            variant="contained"
                            size="small"
                            fullWidth
                            onClick={() => props.onClose()}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    );
};

export default DriverModal;
