import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import SortIcon from '@mui/icons-material/Sort';
import { Box, SxProps, Typography, styled } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button, TrackedMenuItem as MenuItem } from '../../../components/TrackedComponents';
import useIsMobile from '../../../hooks/isMobile';
import { StyledMenu } from '../Users/MuiStyled';
import { SortingModel } from './FleetOverview';

interface SortMenuProps {
    disabled?: boolean;
    options: string[];
    selected: SortingModel;
    setSelected: CallableFunction;
    translationKey: string;
    buttonStyles?: SxProps;
}

export const OverviewStyledMenu = styled(StyledMenu)(({ theme }) => ({
    '& .MuiList-root': {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    '& .MuiPaper-root': {
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.primary.main,
                margin: theme.spacing(0),
            },
        },
    },
}));

const SortMenu: React.FC<SortMenuProps> = (props: SortMenuProps) => {
    const anchorEl = useRef(null);
    const [open, setOpen] = useState<boolean>(false);
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    return (
        <>
            <Button
                id="sort-menu-btn"
                disableElevation
                variant="outlined"
                color="primary"
                disabled={props.disabled}
                startIcon={<SortIcon />}
                sx={{ height: 35, ml: isMobile ? 0 : 1, mr: 1, flexShrink: 0, ...props.buttonStyles }}
                onClick={() => setOpen((prev) => !prev)}
                endIcon={<ArrowDropDownIcon sx={{ mx: -0.5 }} />}
                buttonRef={anchorEl}
            >
                {t('content.fleet.sort.header')}
            </Button>
            <OverviewStyledMenu
                id="sort-menu"
                anchorEl={anchorEl.current}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                sx={{ py: 0 }}
            >
                {props.options.map((option) => {
                    const isSelected = option === props.selected.field;
                    const menuItemStyles = isSelected ? { pr: 1, pl: 0 } : { pr: 1, pl: '30px' };
                    return (
                        <MenuItem
                            key={option}
                            id={`${option.replaceAll('_', '-')}-menu-item`}
                            sx={menuItemStyles}
                            onClick={() =>
                                props.setSelected((prev: SortingModel) => ({
                                    field: option,
                                    order: prev.order,
                                }))
                            }
                        >
                            {isSelected ? (
                                <Box
                                    sx={{
                                        width: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CheckIcon
                                        data-testid={`${option.replaceAll('_', '-')}-selected`}
                                        fontSize="large"
                                        color="secondary"
                                    />
                                </Box>
                            ) : null}
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {t(`${props.translationKey}.${option}`)}
                            </Typography>
                        </MenuItem>
                    );
                })}
                {['asc', 'desc'].map((option, idx) => {
                    const isSelected = option === props.selected.order;
                    const menuItemStyles = isSelected ? { pr: 1, pl: 0 } : { pr: 1, pl: '30px' };
                    const additionalStyles =
                        idx === 0
                            ? { borderTopWidth: '1px', borderTopStyle: 'solid', borderTopColor: 'lightGrayColor.main' }
                            : {};
                    return (
                        <MenuItem
                            key={option}
                            id={`${option}-menu-item`}
                            sx={{ ...menuItemStyles, ...additionalStyles }}
                            onClick={() =>
                                props.setSelected((prev: SortingModel) => ({
                                    field: prev.field,
                                    order: option,
                                }))
                            }
                        >
                            {isSelected ? (
                                <Box
                                    sx={{
                                        width: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CheckIcon
                                        data-testid={`${option.replaceAll('_', '-')}-selected`}
                                        fontSize="large"
                                        color="secondary"
                                    />
                                </Box>
                            ) : null}
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {t(`content.fleet.sort.${option}`)}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </OverviewStyledMenu>
        </>
    );
};

export default SortMenu;
