import { MILLISECONDS_IN_SECOND, SECONDS_IN_HOUR } from '../../../../../utils/TimeFormatter';

const expirationHours = 12;

export const getStoredValue = <T>(key: string, suffix: string, fallback: T): T => {
    const updatedKey = !!suffix ? `${key}-${suffix}` : key;
    const storedValue = localStorage.getItem(updatedKey);
    if (storedValue !== null) {
        const parsedValue: { value: T; expiration: number } = JSON.parse(storedValue);
        if (new Date().getTime() <= parsedValue.expiration) {
            return parsedValue.value;
        }
    }
    return fallback;
};

export const setStoredValue = <T>(key: string, suffix: string, value: T): void => {
    const updatedKey = !!suffix ? `${key}-${suffix}` : key;
    localStorage.setItem(
        updatedKey,
        JSON.stringify({
            value,
            expiration: new Date().getTime() + expirationHours * SECONDS_IN_HOUR * MILLISECONDS_IN_SECOND,
        }),
    );
};
