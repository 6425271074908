import { Box, Menu, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedMenuItem as MenuItem } from '../../../../../components/TrackedComponents';
import useProfile from '../../../../../hooks/profile';
import palette from '../../../../ColorPalette';
import { IL_STAGES, STAGES, STAGE_ICONS, US_STAGES } from '../../Defs';

interface ChangeStageMenuProps {
    open: boolean;
    currentStage: string;
    anchorElChangeStage: null | HTMLElement;
    handleChangeStageMenuClose: CallableFunction;
    changeStage: CallableFunction;
}

const ChangeStageMenu: React.FC<ChangeStageMenuProps> = (props) => {
    const { t } = useTranslation();
    const { profile } = useProfile();
    const stages = profile.customer.settings.country == 'il' ? IL_STAGES : US_STAGES;

    const isCurrentStage = (stage: string) => stage.toLocaleLowerCase() === props.currentStage.toLocaleLowerCase();

    return (
        <Menu
            id="change-stage-menu"
            anchorEl={props.anchorElChangeStage}
            open={props.open}
            onClose={() => props.handleChangeStageMenuClose()}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
            {stages.map((stage) => (
                <MenuItem
                    key={stage}
                    id={`stage-${stage}`}
                    onClick={() => {
                        props.changeStage(stage.toLocaleLowerCase()).then(() => {
                            props.handleChangeStageMenuClose();
                        });
                    }}
                    sx={{ backgroundColor: isCurrentStage(stage) ? palette.neutral[200] : 'inherit' }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {STAGE_ICONS[stage as typeof STAGES[number]]}
                        <Typography>
                            {t(
                                `content.accidents.stage.${
                                    profile.customer.settings.country
                                }.${stage.toLocaleLowerCase()}`,
                                stage,
                            )}
                        </Typography>
                    </Box>
                </MenuItem>
            ))}
        </Menu>
    );
};

export default ChangeStageMenu;
