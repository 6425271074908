import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Card, Checkbox, FormControlLabel, ListItem, Paper, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { ShortCoachSession } from '../../../backendsdk';
import { TrackedButton as Button, TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import { ExtendedFleetDriver } from '../../../hooks/drivers';
import { useImages } from '../../../hooks/images';
import useProfile from '../../../hooks/profile';
import imageNA from '../../../images/image_not_available.png';
import { stringAvatar } from '../../../utils/Avatar';
import palette from '../../ColorPalette';
import { RTLDirectionContext } from '../../Layout';
import { PHONE_NUMBER_MASK_MAP } from '../UserSettings/UserSettings';
import { isValidPhoneNumber } from '../Users/validation';

interface ListRowData {
    sessions: ShortCoachSession[];
    images: Record<string, string>;
    phoneNumbers: Record<number, string>;
    setPhoneNumbers: CallableFunction;
}

const PhoneNumberRow: React.FC<ListChildComponentProps<ListRowData>> = (props) => {
    const { index, style } = props;
    const { t } = useTranslation();
    const session = props.data.sessions[index];
    const { profile } = useProfile();
    const getDriverDetails = (session: ShortCoachSession) => {
        const driverName = session.driver_name !== '' ? session.driver_name : t('content.fleet.unknown_driver');
        return [driverName, session.license_plate];
    };
    const [driverName, licensePlate] = getDriverDetails(session);
    const isRTL = useContext(RTLDirectionContext);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const phoneInput: any = () => {
        const inputElement = (
            <TextField
                id={`${session.session_id}-phone-number`}
                size="small"
                inputProps={{ 'data-testid': `${session.session_id}-phone-number`, style: { direction: 'ltr' } }}
            />
        );
        return inputElement;
    };
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'wellness-popover',
    });
    const driverImage = props.data.images[session.session_id.toString()];
    const driverImageAvailable = driverImage && driverImage !== imageNA;

    const imageProportions = { width: '70px', height: '52.5px', borderRadius: '5px' };
    return (
        <ListItem disableGutters style={style} sx={{ p: 0, height: '65px', direction: 'ltr' }}>
            <Box
                key={`${session.session_id}-row`}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    id={`${session.license_plate}-driver-image`}
                    data-testid={`${session.session_id}-driver-image`}
                    sx={{
                        display: 'flex',
                        flex: 1,
                        overflow: 'hidden',
                    }}
                >
                    <Box sx={{ m: 'auto' }} {...bindHover(popupState)}>
                        {driverImageAvailable ? (
                            <img
                                src={driverImage}
                                alt={driverName}
                                style={{
                                    ...imageProportions,
                                    overflow: 'hidden',
                                    objectFit: 'cover',
                                    objectPosition: '0 100%',
                                }}
                            />
                        ) : (
                            <Avatar {...stringAvatar(session.driver_name, imageProportions)} />
                        )}
                    </Box>
                    {driverImageAvailable ? (
                        <HoverPopover
                            {...bindPopover(popupState)}
                            anchorOrigin={{ vertical: 'top', horizontal: isRTL ? 'right' : 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: isRTL ? 'right' : 'left' }}
                        >
                            <Box style={{ height: '225px', width: '300px' }}>
                                <img src={driverImage} alt={driverName} className="wellness-image ic-image" />
                            </Box>
                        </HoverPopover>
                    ) : null}
                </Box>
                <Box
                    id={`${session.license_plate}-driver-name`}
                    data-testid={`${session.session_id}-driver-name`}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 2,
                        overflow: 'hidden',
                        gap: 0.25,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography
                            sx={{
                                color: !!session.driver_name ? undefined : palette.neutral[700],
                            }}
                        >
                            {driverName}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <LocalShippingIcon fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography>{licensePlate}</Typography>
                    </Box>
                </Box>
                <Box sx={{ flexShrink: 0 }}>
                    <InputMask
                        mask={PHONE_NUMBER_MASK_MAP[profile.customer.settings.country]}
                        value={props.data.phoneNumbers[session.session_id]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            props.data.setPhoneNumbers((prev: Record<number, string>) => ({
                                ...prev,
                                [session.session_id]: e.target.value,
                            }))
                        }
                        maskChar=""
                    >
                        {phoneInput}
                    </InputMask>
                </Box>
            </Box>
        </ListItem>
    );
};

interface PhoneNumbersFormProps {
    sessions: ShortCoachSession[];
    drivers: ExtendedFleetDriver[];
    isBlurred: boolean;
    assignee: string;
    onBack: CallableFunction;
    onClose: CallableFunction;
    setSessions: CallableFunction;
    setTab: CallableFunction;
    setAlert: CallableFunction;
}

const PhoneNumbersForm: React.FC<PhoneNumbersFormProps> = (props: PhoneNumbersFormProps) => {
    const newPhoneNumbers = useMemo(() => {
        const driverPhoneNumberMap = props.drivers.map((driver) => [driver.fullName, driver.phone_number]);
        const newPhoneNumbers: Record<string, string> = {};
        props.sessions.forEach((session) => {
            const driver = driverPhoneNumberMap.find((d) => d[0] === session.driver_name);
            if (driver) {
                newPhoneNumbers[session.session_id] = driver[1];
            }
        });
        return newPhoneNumbers;
    }, [props.drivers]);
    const [phoneNumbers, setPhoneNumbers] = useState<Record<number, string>>({
        ...Object.fromEntries(props.sessions.map((s) => [s.session_id, ''])),
        ...newPhoneNumbers,
    });
    const [driverConsent, setDriverConsent] = useState<boolean>(false);
    const [images, addToQueue] = useImages(8, (sessionId: string) =>
        api.apiV0CoachSessionIdImageGet({ sessionId: parseInt(sessionId) }, { responseType: 'blob' }),
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const listRef = React.useRef<FixedSizeList>(null);
    const { api } = useApi();
    const { t } = useTranslation();
    const numberOfRows = Object.keys(phoneNumbers).length;

    useEffect(() => {
        addToQueue(props.sessions.map((s) => s.session_id.toString()));
    }, []);

    const handleSend = () => {
        setIsLoading(true);
        const requestBody = {
            drivers: Object.entries(phoneNumbers).map(([sessionId, phoneNumber]) => ({
                session_id: parseInt(sessionId),
                phone_number: phoneNumber,
                message: '',
            })),
            is_blurred: props.isBlurred,
            assignee: props.assignee === 'unassigned' ? null : parseInt(props.assignee),
        };
        api.apiV1CoachSendPost({ coachSessionMessageRequest: requestBody }).then((res) => {
            const resultMap = Object.fromEntries(res.data.results.map((r) => [r.session_id, r.status]));
            if (Object.values(resultMap).every((status) => status === 'queued')) {
                const sessionIds = res.data.results.map((r) => r.session_id);
                props.setSessions((prev: ShortCoachSession[]) =>
                    prev.map((session) => {
                        if (sessionIds.includes(session.session_id)) {
                            return {
                                ...session,
                                assignee: requestBody.assignee,
                                last_sent: dayjs().unix(),
                                last_delivery_status: 'queued',
                                is_saved: true,
                            };
                        }
                        return session;
                    }),
                );
                props.setTab('sent');
                props.setAlert({
                    message: t('content.coach.send_session.sessions_sent'),
                    type: 'success',
                    duration: 6000,
                });
                props.onClose();
            } else {
                const queuedSessions = res.data.results.filter((r) => r.status === 'queued').map((r) => r.session_id);
                const failedSessions = res.data.results.filter((r) => r.status !== 'queued').map((r) => r.session_id);
                props.setSessions((prev: ShortCoachSession[]) =>
                    prev.map((session) => {
                        if (queuedSessions.includes(session.session_id)) {
                            return {
                                ...session,
                                last_sent: dayjs().unix(),
                                is_saved: true,
                            };
                        }
                        return session;
                    }),
                );
                props.setTab('sent');
                setPhoneNumbers((prev) => Object.fromEntries(failedSessions.map((s) => [s, prev[s]])));
                setIsError(true);
                setIsLoading(false);
            }
        });
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 4, top: 4 }}>
                    <IconButton
                        id="btn-close-modal"
                        data-testid="btn-close"
                        size="small"
                        onClick={() => props.onClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
                    {t('content.coach.coaching_at_scale.phone_numbers.header')}
                </Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    p: 1,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 1 }}>
                    <Typography>{t('content.coach.coaching_at_scale.phone_numbers.sub_header')}</Typography>
                    {isError ? (
                        <Typography fontSize={14} color="error" sx={{ mt: 1 }}>
                            {t('content.coach.coaching_at_scale.phone_numbers.error')}
                        </Typography>
                    ) : null}
                    <Box
                        sx={{
                            mt: isError ? 1 : 2,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignContent: 'center',
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <Typography
                                variant="overline"
                                sx={{ display: 'block', width: '100%', textAlign: 'center' }}
                            >
                                {t('content.coach.coaching_at_scale.phone_numbers.column1')}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: 2 }}>
                            <Typography variant="overline">
                                {t('content.coach.coaching_at_scale.phone_numbers.column2')}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '225px', flexShrink: 0 }}>
                            <Typography variant="overline">
                                {t('content.coach.coaching_at_scale.phone_numbers.column3')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <FixedSizeList
                            height={Math.min(numberOfRows * 65, 650)}
                            width="100%"
                            itemSize={65}
                            itemCount={numberOfRows}
                            itemData={{
                                sessions: props.sessions.filter((s) => s.session_id.toString() in phoneNumbers),
                                images,
                                phoneNumbers,
                                setPhoneNumbers,
                            }}
                            ref={listRef}
                        >
                            {PhoneNumberRow}
                        </FixedSizeList>
                    </Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="driver-consent-checkbox"
                                data-testid="driver-consent-checkbox"
                                checked={driverConsent}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDriverConsent(e.target.checked)}
                                size="small"
                                disableRipple
                                sx={{ '& .MuiSvgIcon-root': { height: 16, width: 16 } }}
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {numberOfRows > 1
                                    ? t('content.coach.send_session.drivers_consent')
                                    : t('content.coach.send_session.driver_consent')}
                            </Typography>
                        }
                        sx={{ mt: 1 }}
                    />
                </Card>
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    <Button
                        id="btn-back"
                        data-testid="btn-back"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => props.onBack()}
                        sx={{ mr: 1 }}
                    >
                        {t('content.coach.coaching_at_scale.phone_numbers.back')}
                    </Button>
                    <Button
                        id="btn-send"
                        data-testid="btn-send"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={
                            Object.values(phoneNumbers).some((p) => !isValidPhoneNumber(p)) ||
                            isLoading ||
                            !driverConsent
                        }
                        onClick={handleSend}
                    >
                        {t('content.coach.coaching_at_scale.send_sessions')}
                    </Button>
                </Box>
            </Paper>
        </>
    );
};

export default PhoneNumbersForm;
