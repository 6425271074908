import { Box, Card, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TaggingData } from '../../../../backendsdk';
import AccidentCausesChart from './AccidentCausesChart';

interface AccidentCausesCardProps {
    taggingData?: TaggingData;
    isLoading: boolean;
}

const AccidentCausesCard: React.FC<AccidentCausesCardProps> = ({ taggingData, isLoading }: AccidentCausesCardProps) => {
    const { t } = useTranslation();
    const taggedCauses = taggingData?.primary_cause;

    let content;
    if (isLoading) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (!taggedCauses || Object.keys(taggedCauses).length === 0) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.accident_overview.no_data')}</Typography>
            </Box>
        );
    } else {
        content = (
            <>
                <Typography sx={{ fontWeight: 'bold' }}>{t('content.accident_overview.causes_header')}</Typography>
                <Typography fontSize={14}>{t('content.accident_overview.causes_instructions')}</Typography>
                {!!taggedCauses ? <AccidentCausesChart causes={taggedCauses} /> : null}
            </>
        );
    }

    return (
        <Card
            sx={{
                width: '100%',
                height: 450,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                p: 2,
            }}
        >
            {content}
        </Card>
    );
};

export default AccidentCausesCard;
