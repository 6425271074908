import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import { WellnessCardProps } from '../Wellness/WellnessCard';
import { VehicleCardProps } from './VehicleCard';

interface VehicleModalHeaderProps {
    vehicle?: VehicleCardProps | WellnessCardProps;
    onClose: CallableFunction;
}

const VehicleModalHeader: React.FC<VehicleModalHeaderProps> = (props: VehicleModalHeaderProps) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                backgroundColor: 'secondary.main',
                height: 40,
                minHeight: 0,
                position: 'sticky',
                top: 0,
                p: 1,
                zIndex: 3000,
            }}
        >
            <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PersonIcon sx={{ mr: 0.5 }} />
                    {`${!!props.vehicle?.driverName ? props.vehicle.driverName : t('content.safety.unknown')}${
                        props.vehicle?.subFleet ? ` (${props.vehicle.subFleet})` : ''
                    }`}
                </Box>
            </Box>
        </Box>
    );
};

export default VehicleModalHeader;
