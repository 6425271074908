import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortIcon from '@mui/icons-material/Sort';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ResponsiveDatePicker } from '../../../../components/MuiDatePicker';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import useProfile from '../../../../hooks/profile';
import palette from '../../../ColorPalette';
import { AccidentFilterField, SortField, SortOrder } from '../Defs';
import SortMenu from './SortMenu';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface AccidentToolbarProps {
    handlePageVersionChange: CallableFunction;
    searchText: string;
    setSearchText: Dispatch<SetStateAction<string>>;
    fromDate: Dayjs;
    setFromDate: Dispatch<SetStateAction<Dayjs>>;
    toDate: Dayjs;
    setToDate: Dispatch<SetStateAction<Dayjs>>;
    filterField: AccidentFilterField;
    setFilterField: Dispatch<SetStateAction<AccidentFilterField>>;
    resetState: CallableFunction;
    sort: [SortField, SortOrder];
    setSort: Dispatch<SetStateAction<[SortField, SortOrder]>>;
    setAlert: CallableFunction;
}

export const AccidentToolbar: React.FC<AccidentToolbarProps> = (props) => {
    const [anchorElSort, setAnchorElSort] = useState<null | HTMLElement>(null);
    const { t, i18n } = useTranslation();
    const { profile } = useProfile();
    const { agencyApi } = useApi();
    const history = useHistory();

    const isSortOpen = Boolean(anchorElSort);
    const handleSortMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElSort((prev) => (!prev ? event.currentTarget : null));
    };

    const handleSortMenuClose = () => {
        setAnchorElSort(null);
    };

    const downloadCSV = () => {
        agencyApi.agencyV1AccidentGet({ csv: 1 }).then(() => {
            props.setAlert({
                message: t('content.accidents.report will be sent to your email'),
                type: 'success',
                duration: 6000,
            });
        });
    };

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: 0,
                display: 'flex',
                flexShrink: 0,
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 1,
                py: 1.5,
                backgroundColor: palette.neutral[50],
                boxShadow: 1,
                zIndex: 2,
            }}
        >
            <Box sx={{ display: 'flex', gap: 1, px: 1.5, flexGrow: 2 }}>
                <TextField
                    placeholder={t('content.accidents.toolbar.search')}
                    value={props.searchText}
                    sx={{ width: 400 }}
                    InputProps={{
                        size: 'small',
                        id: 'search-field',
                        ...{ 'data-testid': 'search-field' },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton id="btn-clear-search" onClick={() => props.setSearchText('')} edge="end">
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => props.setSearchText(e.target.value)}
                />
                <Button
                    id="btn-sort"
                    data-testid="btn-sort"
                    variant="contained"
                    color="lightNeutral"
                    size="small"
                    startIcon={<SortIcon />}
                    sx={{ height: 40 }}
                    onClick={handleSortMenuClick}
                >
                    {t('content.accidents.toolbar.sort')}
                </Button>
                <SortMenu
                    open={isSortOpen}
                    anchorElSort={anchorElSort}
                    handleSortMenuClose={handleSortMenuClose}
                    sort={props.sort}
                    setSort={props.setSort}
                />
                <Button
                    id="download-report-btn"
                    variant="contained"
                    color="lightNeutral"
                    size="small"
                    startIcon={<DownloadIcon />}
                    sx={{ height: 40 }}
                    onClick={downloadCSV}
                >
                    {t('content.accidents.download')}
                </Button>
                <Button
                    id="btn-new"
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<AddIcon />}
                    sx={{ height: 40 }}
                    onClick={() => history.push('/accidents/0')}
                >
                    {t('content.accidents.toolbar.new')}
                </Button>
            </Box>
            <Box sx={{ display: 'flex', px: 1.5 }}>
                <Tooltip title={t('content.accidents.change_page_old')}>
                    <Box>
                        <IconButton id="change-page-btn" onClick={() => props.handlePageVersionChange('v0')}>
                            <SwapHorizIcon />
                        </IconButton>
                    </Box>
                </Tooltip>
                <Tooltip title={t('content.accidents.refresh')}>
                    <Box>
                        <IconButton id="refresh-btn" onClick={() => props.resetState()}>
                            <RefreshIcon />
                        </IconButton>
                    </Box>
                </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', px: 1.5 }}>
                <FormControl sx={{ width: 175, mr: 1 }}>
                    <InputLabel id="filter-mode-label">{t('content.accidents.filters.filter_by')}</InputLabel>
                    <Select
                        id="filter-mode-field"
                        labelId="filter-mode-label"
                        fullWidth
                        size="small"
                        label={t('content.accidents.filters.filter_by')}
                        value={props.filterField}
                        onChange={(e: SelectChangeEvent) => {
                            props.setFilterField(e.target.value as AccidentFilterField);
                        }}
                    >
                        {['timestamp', 'created_at'].map((option) => (
                            <MenuItem key={option} value={option} id={option}>
                                {t(`content.accidents.filters.${option}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.languages[0]}>
                    <ResponsiveDatePicker
                        label={t('content.accidents.from')}
                        format={profile.dateFormat}
                        value={props.fromDate}
                        onChange={(value: Dayjs | null) => {
                            if (value !== null) {
                                props.setFromDate(value.startOf('day'));
                            }
                        }}
                        maxDate={props.toDate}
                        slotProps={{
                            textField: { id: 'from-field', size: 'small', sx: { width: 175, mr: 1 } },
                        }}
                    />
                    <ResponsiveDatePicker
                        label={t('content.accidents.to')}
                        format={profile.dateFormat}
                        value={props.toDate}
                        onChange={(value: Dayjs | null) => {
                            if (value !== null) {
                                props.setToDate(value.endOf('day'));
                            }
                        }}
                        maxDate={dayjs()}
                        slotProps={{ textField: { id: 'to-field', size: 'small', sx: { width: 175 } } }}
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
};
