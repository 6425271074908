import { Box, Card, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';

import useProfile from '../../../../hooks/profile';
import { ANIMATION_LENGTH } from '../MetricsContent';

const SAFETY_AREA_COLORS: Record<string, string> = {
    'content.metrics.inactive': '#666666',
    'content.metrics.unknown': '#2c2c2c',
    '0–19': '#c20000',
    '20–39': '#e63d12',
    '40–59': '#f58447',
    '60–79': '#ffc658',
    '80–100': '#82ca9d',
};

const WELLNESS_AREA_COLORS: Record<string, string> = {
    'content.metrics.inactive': '#666666',
    '0': '#780000',
    '1–19': '#c20000',
    '20–39': '#e63d12',
    '40–59': '#f58447',
    '60–79': '#ffc658',
    '80–99': '#bdc47e',
    '100': '#82ca9d',
};

const toPercent = (decimal: number) => `${Math.round(decimal * 100)}%`;
const getPercent = (value: number, total: number) => {
    const ratio = total > 0 ? value / total : 0;

    return toPercent(ratio);
};

interface DistributionChartProps {
    subFleetData: Array<Record<string, number>>;
    scoreType: 'safety' | 'wellness';
    buckets: string[];
    dateFormat: string;
    animate: boolean;
}
const DistributionChart: React.FC<DistributionChartProps> = (props: DistributionChartProps) => {
    return (
        <ResponsiveContainer width="100%" height="100%" className="chart-container">
            <AreaChart
                id="fleet-distribution-chart"
                data={props.subFleetData}
                stackOffset="expand"
                margin={{
                    top: 5,
                    right: 5,
                    left: -18,
                    bottom: 5,
                }}
                syncId="metrics"
                syncMethod="value"
            >
                <CartesianGrid />
                <XAxis
                    tick={{ fontSize: 10 }}
                    dataKey="name"
                    interval={0}
                    angle={-30}
                    textAnchor="end"
                    tickFormatter={(t) => dayjs.unix(t).format(props.dateFormat)}
                />
                <YAxis domain={[0, 1]} tick={{ fontSize: 12 }} tickFormatter={toPercent} />
                <Tooltip content={<CustomAreaTooltip />} />
                {props.subFleetData.length > 0
                    ? props.buckets.map((bucket) => {
                          const color =
                              props.scoreType === 'safety' ? SAFETY_AREA_COLORS[bucket] : WELLNESS_AREA_COLORS[bucket];
                          return (
                              <Area
                                  id={`${props.scoreType}-area-${bucket}`}
                                  key={bucket}
                                  type="monotone"
                                  dataKey={bucket}
                                  stackId="1"
                                  stroke={color}
                                  fill={color}
                                  animationDuration={ANIMATION_LENGTH}
                                  isAnimationActive={props.animate}
                              />
                          );
                      })
                    : null}
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default React.memo(DistributionChart);

const CustomAreaTooltip: React.FC<TooltipProps<number, string>> = (props: TooltipProps<number, string>) => {
    const { active, payload, label } = props;
    const areas = [...(payload || [])].reverse();
    const { t } = useTranslation();
    const { profile } = useProfile();

    if (active && areas.length) {
        const total = areas.reduce((result, entry) => result + (entry.value || 0), 0);
        return (
            <Card sx={{ maxHeight: 250, overflowY: 'auto', p: 1, direction: 'ltr' }}>
                <Typography fontSize={12} sx={{ fontWeight: 'bold' }}>
                    {dayjs.unix(label).format(profile.shortDateFormat)}
                </Typography>
                {areas.map((p) => (
                    <Box key={label + p.name} sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: 2,
                            }}
                        >
                            <Box sx={{ width: 15, height: 15, mr: 0.5 }}>
                                <svg
                                    viewBox="0 0 15 15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ overflow: 'visible' }}
                                >
                                    <circle cx="7.5" cy="7.5" r="7.5" fill={p.color} />
                                </svg>
                            </Box>
                            <Typography fontSize={12}>{t(p.name || '')}</Typography>
                        </Box>
                        <Typography fontSize={12} sx={{ fontWeight: 'bold' }}>
                            {p.value !== undefined && `${getPercent(p.value, total)}`}
                        </Typography>
                    </Box>
                ))}
            </Card>
        );
    }
    return null;
};
