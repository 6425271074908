import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../../components/TrackedComponents';
import { MuiTextField } from '../Accident/MuiFormControls';
import { STATUS } from './VehicleModal';

interface NewCoachingModalProps {
    defaultDriver?: string;
    onSave: CallableFunction;
    children: React.ReactNode;
}

const NewCoachingModal: React.FC<NewCoachingModalProps> = (props: NewCoachingModalProps) => {
    const [comment, setComment] = useState<string>('');
    const { t } = useTranslation();
    const hasNoDriver = props.defaultDriver === undefined;

    return (
        <Box sx={{ p: 2 }}>
            <Typography textAlign="center" variant="h6" sx={{ mb: 2 }}>
                {t('content.safety.save_event')}
            </Typography>
            <MuiTextField
                id="driver-name"
                name="driver_name"
                label={t('table.headers.driver_name')}
                value={props.defaultDriver || ''}
                disabled
                sx={{ mb: 2 }}
            />
            <MuiTextField
                id="comment"
                name="comment"
                label={t('content.events.coach.comment')}
                value={comment}
                multiline={true}
                rows={2}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
                sx={{ mb: hasNoDriver ? 1 : 2 }}
            />
            {hasNoDriver ? (
                <Typography color="error" fontSize={12} sx={{ mb: 1 }}>
                    {t('content.events.coach.no_driver')}
                </Typography>
            ) : null}
            <Box textAlign="center">
                <Button
                    id="save-btn"
                    data-testid="save-btn"
                    variant="contained"
                    size="small"
                    disabled={hasNoDriver}
                    onClick={() => props.onSave(STATUS.SAVED, comment)}
                >
                    {t('content.coach.save')}
                </Button>
                {props.children}
            </Box>
        </Box>
    );
};

export default NewCoachingModal;
