import { Box, Menu, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseAccidentV2 } from '../../../../backendsdk';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { TrackedMenuItem as MenuItem } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import useProfile from '../../../../hooks/profile';

interface AccidentCardContextMenuProps {
    accident_id: number;
    open: boolean;
    anchorEl: { mouseX: number; mouseY: number } | null;
    handleAccidentCardContextMenuClose: CallableFunction;
    changeNotificationStatus: CallableFunction;
    setAccidents: CallableFunction;
}

const AccidentCardContextMenu: React.FC<AccidentCardContextMenuProps> = (props) => {
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { agencyApi } = useApi();

    const changeAnomaly: React.MouseEventHandler = (e: React.MouseEvent) => {
        e.preventDefault();
        agencyApi.agencyV1AccidentAccidentIdPatch({
            accidentId: props.accident_id,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            extendedAccident: { is_anomaly: true } as any,
        });
    };

    const markAsArchived: React.MouseEventHandler = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        agencyApi
            .agencyV1AccidentAccidentIdPatch({
                accidentId: props.accident_id,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                extendedAccident: { archived: true } as any,
            })
            .then(() => {
                setArchiveModalOpen(false);
                props.setAccidents((prev: BaseAccidentV2[]) => {
                    return prev.filter((accident: BaseAccidentV2) => accident.accident_id !== props.accident_id);
                });
            });
    };

    const addReminder = () => {
        props.changeNotificationStatus(false);
        props.handleAccidentCardContextMenuClose();
    };

    return (
        <>
            <ConfirmDialog
                isDialogOpen={archiveModalOpen}
                isLoading={false}
                headerText={t('content.accidents.archive.header')}
                bodyText={t('content.accidents.archive.confirm')}
                buttonText={t('content.accidents.archive.submit')}
                buttonColor="redColor"
                onConfirm={(e: React.MouseEvent) => markAsArchived(e)}
                onClose={() => setArchiveModalOpen(false)}
            />
            <Menu
                id="accident-context-menu"
                open={props.open}
                onClose={() => props.handleAccidentCardContextMenuClose()}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorReference="anchorPosition"
                anchorPosition={
                    props.anchorEl ? { top: props.anchorEl.mouseY, left: props.anchorEl.mouseX } : undefined
                }
            >
                {profile.admin ? (
                    <MenuItem
                        id="set-anomaly"
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            changeAnomaly(e);
                        }}
                    >
                        <Box>
                            <Typography variant="inherit">{t('content.accidents.set_anomaly')}</Typography>
                        </Box>
                    </MenuItem>
                ) : null}
                {profile.admin ? (
                    <MenuItem
                        id="archive-accident"
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.handleAccidentCardContextMenuClose();
                            setArchiveModalOpen(true);
                        }}
                    >
                        <Box>
                            <Typography variant="inherit"> {t('content.accidents.archive.menu')}</Typography>
                        </Box>
                    </MenuItem>
                ) : null}

                <MenuItem
                    id="add-reminder"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        addReminder();
                    }}
                >
                    <Box>
                        <Typography variant="inherit"> {t('content.accidents.add_notification')}</Typography>
                    </Box>
                </MenuItem>
            </Menu>
        </>
    );
};

export default AccidentCardContextMenu;
