import PersonIcon from '@mui/icons-material/Person';
import RouteIcon from '@mui/icons-material/Route';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import VideocamIcon from '@mui/icons-material/Videocam';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, ButtonGroup, List, ListItem, Skeleton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DeviceV3, TripDetails } from '../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
    TrackedListItemButton as ListItemButton,
} from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import useDrivers from '../../../../hooks/drivers';
import useProfile from '../../../../hooks/profile';
import { SECONDS_IN_DAY, formatTime } from '../../../../utils/TimeFormatter';
import { MILES_TO_KM_FACTOR } from '../../../../utils/location';
import palette from '../../../ColorPalette';
import { RELEVANT_EVENT_TYPES } from '../utils';

dayjs.extend(utc);

interface TripsPanelProps {
    timespan: 'day' | 'week';
    setTimespan: CallableFunction;
    additionalTrip?: TripDetails;
    device: DeviceV3;
    selectedTrip?: TripDetails;
    setSelectedTrip: CallableFunction;
}

const TripsPanel: React.FC<TripsPanelProps> = (props: TripsPanelProps) => {
    const { timespan, setTimespan } = props;
    const [trips, setTrips] = useState<TripDetails[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { profile } = useProfile();
    const { api } = useApi();
    const { getDriver } = useDrivers();
    const { t } = useTranslation();
    const history = useHistory();

    const distanceUnit = profile.locale === 'il' ? 'km' : 'mi';

    useEffect(() => {
        const to = dayjs().utc().unix();
        const from = to - 7 * SECONDS_IN_DAY;
        setIsLoading(true);
        api.apiV1TripGet({ licensePlate: props.device.device.license_plate, from, to })
            .then((res) => {
                setTrips(res.data.filter((trip) => Math.floor(trip.duration) > 0));
            })
            .finally(() => setIsLoading(false));
    }, [props.device.device.license_plate]);

    const filteredTrips =
        timespan === 'day' ? trips.filter((trip) => trip.end_time > dayjs().utc().unix() - SECONDS_IN_DAY) : trips;

    const getTripListItem = (trip: TripDetails, idx: number) => {
        const driver = getDriver(trip.driver);
        const driverName = driver?.fullName;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const relevantEvents = trip.number_of_events_per_type!.filter((event) =>
            RELEVANT_EVENT_TYPES.includes(event.event_type),
        );
        let eventsField;
        const eventCount = relevantEvents.reduce((acc, event) => acc + event.count, 0);
        if (eventCount > 0) {
            eventsField =
                eventCount === 1 ? t('content.fleet.event') : t('content.fleet.events', { count: eventCount });
        }
        let tripDistance = trip.distance / 1000;
        if (distanceUnit === 'mi') {
            tripDistance = tripDistance / MILES_TO_KM_FACTOR;
        }
        const tripDistanceStr = tripDistance.toFixed(2);

        return (
            <ListItem
                key={trip.trip_id}
                data-testid="trip-list-item"
                disableGutters
                disablePadding
                sx={
                    idx !== 0
                        ? {
                              borderTopWidth: 1,
                              borderTopStyle: 'solid',
                              borderTopColor: 'lightGrayColor.main',
                          }
                        : undefined
                }
                secondaryAction={
                    <Link
                        id={`${idx}-find-video-link`}
                        onClick={() =>
                            history.push(
                                `/find_video?date=${trip.start_time}&license_plate=${encodeURIComponent(
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    trip.license_plate!,
                                )}`,
                            )
                        }
                    >
                        <IconButton id="btn-find-video" sx={{ mr: 0.5 }}>
                            <VideocamIcon color="primary" />
                        </IconButton>
                    </Link>
                }
            >
                <ListItemButton
                    id={`trip-${trip.trip_id}-list-item`}
                    selected={trip.trip_id === props.selectedTrip?.trip_id}
                    onClick={() => props.setSelectedTrip(trip)}
                    sx={{ p: 1 }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography id={`row-${idx}-trip-times`} component="span">{`${dayjs
                                .unix(trip.start_time)
                                .format(profile.dateTimeFormat)} – ${dayjs
                                .unix(trip.end_time)
                                .format(profile.dateTimeFormat)}`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TimelapseIcon fontSize="small" sx={{ height: 15, width: 15, mr: 0.5 }} />
                            <Typography id={`row-${idx}-trip-duration`} className="trip-duration-field" sx={{ mr: 1 }}>
                                {formatTime(trip.duration, t, true, false)}
                            </Typography>
                            <RouteIcon fontSize="small" sx={{ height: 15, width: 15, mr: 0.5 }} />
                            <Typography id={`row-${idx}-trip-distance`} sx={{ mr: 1 }}>{`${tripDistanceStr} ${t(
                                `content.fleet.details.trips.${distanceUnit}`,
                            )}`}</Typography>
                            {eventCount > 0 ? (
                                <>
                                    <WarningIcon
                                        fontSize="small"
                                        sx={{ height: 15, width: 15, mr: 0.5, color: palette.red[700] }}
                                    />
                                    <Typography id={`row-${idx}-trip-events`} sx={{ color: palette.red[700] }}>
                                        {eventsField}
                                    </Typography>
                                </>
                            ) : null}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <PersonIcon fontSize="small" sx={{ height: 15, width: 15, mr: 0.5 }} />
                            {driverName}
                        </Box>
                    </Box>
                </ListItemButton>
            </ListItem>
        );
    };

    let content;
    if (isLoading) {
        content = (
            <Box
                sx={{
                    width: '100%',
                    height: 64,
                }}
            >
                <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%', height: '100%' }} />
            </Box>
        );
    } else if (filteredTrips.length > 0) {
        content = (
            <List disablePadding sx={{ width: '100%' }}>
                {filteredTrips.map((trip, idx) => {
                    return getTripListItem(trip, idx);
                })}
            </List>
        );
    } else {
        content = <Typography variant="overline">{t('content.fleet.details.trips.no_trips_found')}</Typography>;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', minHeight: 0 }}>
            {props.additionalTrip ? (
                <List disablePadding sx={{ width: '100%', mt: -1, mb: 2 }}>
                    {getTripListItem(props.additionalTrip, 0)}
                </List>
            ) : null}
            <ButtonGroup disableElevation>
                {['day', 'week'].map((ts) => (
                    <Button
                        id={`${ts}-btn`}
                        key={`${ts}-btn`}
                        size="small"
                        variant={timespan === ts ? 'contained' : 'outlined'}
                        onClick={() => setTimespan(ts)}
                    >
                        {t(`content.fleet.details.trips.${ts}`)}
                    </Button>
                ))}
            </ButtonGroup>
            <Box id="trips-container" sx={{ width: '100%', mt: 2 }}>
                {content}
            </Box>
        </Box>
    );
};

export default TripsPanel;
