import VideocamIcon from '@mui/icons-material/Videocam';
import { Box, Card, Tooltip, Typography, lighten } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Damage, ExtendedAccident, PolicyV2 } from '../../../backendsdk';
import ConfirmDialog from '../../../components/ConfirmDialog';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { normalizeLicensePlate } from '../../../utils/Str';
import palette from '../../ColorPalette';
import { RTLDirectionContext } from '../../Layout';
import { VideoSearchIcon } from './Defs';

interface AccidentSpanProps {
    accident: ExtendedAccident;
    policies: Array<PolicyV2>;
    query: string;
    selectedAccident?: ExtendedAccident;
    setSelectedAccident: CallableFunction;
    changeArchivedStatus: CallableFunction;
}

export const AccidentSpan: React.FC<AccidentSpanProps> = (props: AccidentSpanProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const [isAnomaly, setIsAnomaly] = useState(props.accident.is_anomaly);
    const history = useHistory();
    const { t } = useTranslation();
    const { agencyApi } = useApi();
    const { profile } = useProfile();
    const isRTL = useContext(RTLDirectionContext);

    const handleOnClick = () => {
        if (showMenu || archiveModalOpen) {
            setShowMenu(false);
        } else {
            props.setSelectedAccident(props.accident);
            history.push(`/accidents/${props.accident.accident_id}?${props.query.toString()}`);
        }
    };

    const isAdmin = profile.admin;

    const changeAnomaly: React.MouseEventHandler = () => {
        agencyApi
            .agencyV1AccidentAccidentIdPatch({
                accidentId: props.accident.accident_id,
                extendedAccident: { ...props.accident, is_anomaly: !isAnomaly },
            })
            .then(() => setIsAnomaly(!isAnomaly));
    };

    const markAsArchived: React.MouseEventHandler = () => {
        props.changeArchivedStatus(props.accident, true);
        setArchiveModalOpen(false);
    };

    const selected = props.selectedAccident?.accident_id == props.accident.accident_id;
    const ownDamage =
        profile.name == props.accident.assignee ||
        props.accident.damage_list.filter((d: Damage) => !d.vehicle.third_party).length > 0;
    const isLicensePlateValid =
        props.policies.length > 0
            ? props.policies.filter(
                  (p) => p.license_plate.replaceAll('-', '') == props.accident.license_plate.replaceAll('-', ''),
              ).length > 0
            : true;
    const iconStyle = { position: 'absolute', right: '3px', color: palette.gray[600] };
    const videoIcon = props.accident.event ? (
        <VideocamIcon sx={iconStyle} />
    ) : props.accident.video_requested ? (
        <VideoSearchIcon sx={iconStyle} />
    ) : null;

    const style = selected
        ? { backgroundColor: isAdmin && ownDamage ? 'lightcoral' : lighten(palette.accent, 0.8) }
        : { backgroundColor: isAdmin && ownDamage ? 'lightpink' : palette.neutral[50] };

    return (
        <Card
            id={`accident-${props.accident.accident_id}`}
            className="grid-accident-item"
            sx={{
                ...style,
                ontWeight: isAdmin && isAnomaly ? 'bold' : undefined,
                '&& .MuiTypography-root': {
                    lineHeight: 1,
                },
            }}
            onClick={handleOnClick}
            onMouseLeave={() => setShowMenu(false)}
            onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
                if (isAdmin) {
                    e.preventDefault();
                    setAnchorEl(e.currentTarget);
                    setShowMenu((prev) => !prev);
                }
            }}
        >
            <ConfirmDialog
                isDialogOpen={archiveModalOpen}
                isLoading={false}
                headerText={t('content.accidents.archive.header')}
                bodyText={t('content.accidents.archive.confirm')}
                buttonText={t('content.accidents.archive.submit')}
                buttonColor="redColor"
                onConfirm={markAsArchived}
                onClose={() => setArchiveModalOpen(false)}
            />
            {isAdmin && showMenu ? (
                <Menu
                    anchorEl={anchorEl}
                    open={showMenu}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: isRTL ? 'right' : 'left',
                    }}
                >
                    <MenuItem onClick={changeAnomaly}>{isAnomaly ? 'Unset' : 'Set'} Anomaly</MenuItem>
                    <MenuItem onClick={() => setArchiveModalOpen(true)}>{t('content.accidents.archive.menu')}</MenuItem>
                </Menu>
            ) : null}
            <Box position="relative">
                <Typography component="span" className="accident-date">
                    {dayjs.unix(props.accident.timestamp).format(profile.dateFormat)}
                </Typography>
                {videoIcon}
            </Box>
            <Typography component="span">
                <Tooltip title={!isLicensePlateValid ? t('content.accidents.unknown_license_plate') : undefined}>
                    <Typography
                        component="span"
                        className={`accident-license-plate-${normalizeLicensePlate(props.accident.license_plate)}`}
                        sx={{ backgroundColor: !isLicensePlateValid ? palette.red[300] : undefined }}
                    >
                        {props.accident.license_plate}
                    </Typography>
                </Tooltip>
                {!!props.accident.sub_fleet_name ? ` | ${props.accident.sub_fleet_name}` : null}
            </Typography>
            <br />
            <Tooltip title={!props.accident.driver_token ? t('content.accidents.unknown_driver') : undefined}>
                <Typography
                    component="span"
                    sx={{ backgroundColor: !props.accident.driver_token ? palette.red[300] : undefined }}
                >
                    {props.accident.driver_name}
                </Typography>
            </Tooltip>
            <br />
            <Typography component="span">{props.accident.location}</Typography>
            <br />
            <Typography component="span">{props.accident.assignee}</Typography>
            <br />
            <Typography component="span" sx={{ mx: 1 }}>
                {props.accident.accident_id}
            </Typography>
            <br />
            <Typography component="span" className="report-date" fontSize={12} sx={{ color: palette.neutral[500] }}>
                {`${t('content.accidents.reported')}: ${dayjs
                    .unix(props.accident.created_at)
                    .format(profile.dateFormat)}`}
            </Typography>
        </Card>
    );
};

export default AccidentSpan;
