import {
    Box,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    Typography,
    alpha,
    styled,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    CoachMetricByType,
    CoachMetricDataPoint,
    CoachMetrics,
    CoachMetricsAdditionalDataEnum,
    CoachMetricsScoreTypeEnum,
    CoachMetricsTimeFrameEnum,
    CoachSession,
} from '../../../../backendsdk';
import { TrackedButton as Button } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import palette from '../../../ColorPalette';
import { ScoreAvatar } from '../../Metrics/SafetyWellness/DriverList';
import { INVALID_SCORE, isScoreValid } from '../../Metrics/SafetyWellness/MetricsComponent';
import { SaveFirstTooltip } from './CoachingAgenda';
import MetricsGraph from './MetricsGraph';

const additionalDataToKeyMap: Record<CoachMetricsAdditionalDataEnum, Partial<keyof CoachMetricDataPoint>> = {
    [CoachMetricsAdditionalDataEnum.None]: 'score',
    [CoachMetricsAdditionalDataEnum.SubFleet]: 'sub_fleet_score',
    [CoachMetricsAdditionalDataEnum.Fleet]: 'fleet_score',
};

export const CustomRadio = styled(Radio)(() => ({
    color: palette.black,
    '&.Mui-checked': { color: palette.black },
    '& .MuiSvgIcon-root': { fontSize: 16 },
    '&.MuiRadio-root': { paddingRight: 4 },
}));

interface CustomFormControlProps {
    value: string;
    label: string;
    checked: boolean;
    disabled?: boolean;
}

export const CustomFormControl: React.FC<CustomFormControlProps> = ({
    value,
    label,
    checked,
    disabled,
}: CustomFormControlProps) => {
    return (
        <FormControlLabel
            id={`${value}-radio`}
            data-testid={`${value}-radio`}
            value={value}
            control={
                <CustomRadio
                    id={`${value}-radio-btn`}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputProps={{ 'data-testid': `${value}-radio-btn-${checked ? 'checked' : 'unchecked'}` } as any}
                    checked={checked}
                    disabled={disabled}
                    disableRipple
                    sx={{
                        '&.Mui-checked.Mui-disabled': {
                            color: alpha(palette.black, 0.26),
                        },
                    }}
                />
            }
            label={<Typography sx={{ fontSize: 14, pt: 0.25 }}>{label}</Typography>}
            sx={{ height: '26px' }}
        />
    );
};

interface MetricsFormProps {
    children?: React.ReactNode;
    sessionId: number;
    licensePlate: string;
    item?: CoachMetrics;
    onClose: CallableFunction;
    setDetails: CallableFunction;
    isLoading: boolean;
    setIsLoading: CallableFunction;
    isCompleted: boolean;
    isSaved: boolean;
    setAlert: CallableFunction;
    onUpdate: CallableFunction;
    addAnotherItem: boolean;
    setItemsAdded: CallableFunction;
}

const MetricsForm: React.FC<MetricsFormProps> = (props: MetricsFormProps) => {
    const [isUpdatedData, setIsUpdatedData] = useState<boolean>(!props.item);
    const [scoreType, setScoreType] = useState<CoachMetricsScoreTypeEnum>(
        props.item?.score_type || CoachMetricsScoreTypeEnum.Safety,
    );
    const [timeFrame, setTimeFrame] = useState<CoachMetricsTimeFrameEnum>(
        props.item?.time_frame || CoachMetricsTimeFrameEnum.Current,
    );
    const [additionalData, setAdditionalData] = useState<CoachMetricsAdditionalDataEnum>(
        props.item?.additional_data || CoachMetricsAdditionalDataEnum.None,
    );
    const [metricData, setMetricData] = useState<CoachMetricByType>();

    const hasAdditionalData = (additionalDataOption: CoachMetricsAdditionalDataEnum) => {
        if (additionalDataOption === CoachMetricsAdditionalDataEnum.None) {
            return true;
        }
        const key = additionalDataToKeyMap[additionalDataOption];
        return (
            metricData !== undefined &&
            metricData[scoreType][timeFrame].some((metric) => isScoreValid(metric[key] as number))
        );
    };

    const isEditable = !props.isCompleted && props.isSaved;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const { api } = useApi();
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const { profile } = useProfile();

    useEffect(() => {
        api.apiV0CoachMetricsGet({
            licensePlate: props.licensePlate,
        })
            .then((res) => {
                setMetricData(res.data);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (!isUpdatedData && props.item !== undefined) {
            setScoreType(props.item.score_type);
            setTimeFrame(props.item.time_frame);
        }
    }, [isUpdatedData]);

    let relevantData;
    if (isUpdatedData && metricData !== undefined) {
        relevantData = metricData[scoreType][timeFrame];
    } else if (!isUpdatedData && props.item !== undefined) {
        relevantData = props.item.metric_data;
    }

    let metricChartData;
    if (relevantData) {
        metricChartData = relevantData
            .sort((a, b) => a.timestamp - b.timestamp)
            .map((metric) => ({
                name: metric.timestamp,
                score: isScoreValid(metric.score) ? Math.round(metric.score) : null,
                metadata: metric.metadata,
                fleet_score: isScoreValid(metric.fleet_score) ? Math.round(metric.fleet_score) : null,
                sub_fleet_score: isScoreValid(metric.sub_fleet_score) ? Math.round(metric.sub_fleet_score) : null,
            }));
    }

    const handleSubmit = () => {
        if (metricData) {
            props.setIsLoading(true);
            if (props.item !== undefined) {
                api.apiV0CoachSessionIdMetricsMetricsIdPatch({
                    sessionId: props.sessionId,
                    metricsId: props.item.id,
                    coachMetrics: {
                        ...props.item,
                        score_type: scoreType,
                        time_frame: timeFrame,
                        additional_data: additionalData,
                        metric_data: metricData[scoreType][timeFrame],
                    },
                })
                    .then((res) => {
                        props.setDetails((prev: CoachSession) => ({
                            ...prev,
                            metrics: prev.metrics.map((metric) => (metric.id === res.data.id ? res.data : metric)),
                        }));
                        props.onUpdate();
                        props.onClose();
                    })
                    .catch(() => {
                        props.setAlert({
                            message: t('content.coach.errors.update_item'),
                            type: 'error',
                            duration: 6000,
                        });
                        props.setIsLoading(false);
                    });
            } else {
                api.apiV0CoachSessionIdMetricsPost({
                    sessionId: props.sessionId,
                    coachMetricsRequest: {
                        score_type: scoreType,
                        time_frame: timeFrame,
                        additional_data: additionalData,
                        metric_data: metricData[scoreType][timeFrame],
                        session_id: props.sessionId,
                    },
                })
                    .then((res) => {
                        props.setDetails((prev: CoachSession) => ({
                            ...prev,
                            metrics: [...prev.metrics, res.data],
                            agenda: [...prev.agenda, { type: 'metrics', id: res.data.id }],
                        }));
                        props.onUpdate();
                        if (props.addAnotherItem) {
                            setScoreType(CoachMetricsScoreTypeEnum.Safety);
                            setTimeFrame(CoachMetricsTimeFrameEnum.Current);
                            setAdditionalData(CoachMetricsAdditionalDataEnum.None);
                            props.setIsLoading(false);
                            props.setItemsAdded((prev: number) => prev + 1);
                        } else {
                            props.onClose();
                        }
                    })
                    .catch(() => {
                        props.setAlert({
                            message: t('content.coach.errors.add_item'),
                            type: 'error',
                            duration: 6000,
                        });
                        props.setIsLoading(false);
                    });
            }
        }
    };

    let reasonForNoScore;
    if (relevantData !== undefined && timeFrame === CoachMetricsTimeFrameEnum.Current) {
        reasonForNoScore = relevantData[0].reason_for_no_score;
    }

    let preview = null;
    if ((isLoading || isError) && isUpdatedData) {
        preview = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                {isLoading ? (
                    <CircularProgress size={20} />
                ) : (
                    <Typography variant="overline" color="error">
                        {t('content.coach.errors.metrics')}
                    </Typography>
                )}
            </Box>
        );
    } else if (metricChartData !== undefined && metricChartData.length) {
        if (timeFrame === 'current') {
            preview = (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography fontSize={14} textAlign="center">
                                {t('content.coach.add_item.metrics.metric_header_score', {
                                    score_type: t(`content.coach.add_item.metrics.${scoreType}_score_definite`),
                                })}
                            </Typography>
                            <Typography fontSize={14} textAlign="center" sx={{ mb: 1 }}>
                                {t('content.coach.add_item.metrics.metric_header_date', {
                                    date_from: dayjs.unix(metricChartData[0].name).format(profile.shortDateFormat),
                                })}
                            </Typography>
                            <ScoreAvatar
                                score={metricChartData[0].score !== null ? metricChartData[0].score : INVALID_SCORE}
                                size={36}
                                fontSize={20}
                                inactive={reasonForNoScore === 'inactive'}
                                unknown={reasonForNoScore === 'unknown'}
                            />
                        </Box>
                        {additionalData !== CoachMetricsAdditionalDataEnum.None ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    ml: 2,
                                }}
                            >
                                <Typography fontSize={14} textAlign="center">
                                    {t(`content.coach.add_item.metrics.metric_header_${additionalData}_score`, {
                                        score_type: t(`content.coach.add_item.metrics.${scoreType}_score_definite`),
                                    })}
                                </Typography>
                                <Typography fontSize={14} textAlign="center" sx={{ mb: 1 }}>
                                    {t('content.coach.add_item.metrics.metric_header_date', {
                                        date_from: dayjs.unix(metricChartData[0].name).format(profile.shortDateFormat),
                                    })}
                                </Typography>
                                <ScoreAvatar
                                    score={
                                        metricChartData[0][`${additionalData}_score`] !== null
                                            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                              metricChartData[0][`${additionalData}_score`]!
                                            : INVALID_SCORE
                                    }
                                    size={36}
                                    fontSize={20}
                                />
                            </Box>
                        ) : null}
                    </Box>
                    <Typography fontSize={12} sx={{ mt: 1.5 }}>
                        {t(`content.metrics.${scoreType}_tooltip`)}
                    </Typography>
                </Box>
            );
        } else {
            preview = (
                <MetricsGraph metricChartData={metricChartData} scoreType={scoreType} additionalData={additionalData} />
            );
        }
    }

    const metricsForm = (
        <Grid container columns={2}>
            <Grid item xs={2}>
                {props.item !== undefined ? (
                    <FormControlLabel
                        control={
                            <Switch
                                id="use-updated-data-switch"
                                data-testid="use-updated-data-switch"
                                checked={isUpdatedData}
                                disabled={!isEditable}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setIsUpdatedData(e.target.checked)
                                }
                                size="small"
                            />
                        }
                        label={
                            <Typography sx={{ fontSize: 14 }}>
                                {t('content.coach.add_item.metrics.use_updated_data')}
                            </Typography>
                        }
                        sx={{ pl: 1, mb: 1 }}
                    />
                ) : null}
            </Grid>
            <Grid item xs={2} sm={1}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <Typography variant="overline" sx={{ fontSize: 10, lineHeight: 1.5 }}>
                        {t('content.coach.add_item.metrics.score_type')}
                    </Typography>
                    <FormControl sx={{ ml: 0.25 }}>
                        <RadioGroup
                            value={scoreType}
                            onChange={(e) => {
                                setScoreType(e.target.value as CoachMetricsScoreTypeEnum);
                            }}
                        >
                            {['safety', 'wellness'].map((option) => (
                                <CustomFormControl
                                    key={option}
                                    value={option}
                                    label={t(`content.coach.add_item.metrics.${option}_score`)}
                                    checked={scoreType === option}
                                    disabled={props.item !== undefined && !isUpdatedData}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <Typography variant="overline" sx={{ fontSize: 10, lineHeight: 1.5, mt: 1.5 }}>
                        {t('content.coach.add_item.metrics.additional_data')}
                    </Typography>
                    <FormControl sx={{ ml: 0.25, mb: isMobile ? 1 : 0 }}>
                        <RadioGroup
                            value={additionalData}
                            onChange={(e) => {
                                setAdditionalData(e.target.value as CoachMetricsAdditionalDataEnum);
                            }}
                        >
                            {Object.values(CoachMetricsAdditionalDataEnum).map((option) => {
                                const additionalDataOptionEnabled = hasAdditionalData(option);
                                const control = (
                                    <CustomFormControl
                                        key={option}
                                        value={option}
                                        label={t(`content.coach.add_item.metrics.${option}_score`)}
                                        checked={additionalData === option}
                                        disabled={
                                            (props.item !== undefined && !isUpdatedData) || !additionalDataOptionEnabled
                                        }
                                    />
                                );
                                return additionalDataOptionEnabled ? (
                                    control
                                ) : (
                                    <Tooltip
                                        title={t('content.coach.add_item.metrics.no_additional_data')}
                                        key={option}
                                        followCursor
                                    >
                                        <Box>{control}</Box>
                                    </Tooltip>
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={2} sm={1}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <Typography variant="overline" sx={{ fontSize: 10, lineHeight: 1.5 }}>
                        {t('content.coach.add_item.metrics.time_frame')}
                    </Typography>
                    <FormControl sx={{ ml: 0.25 }}>
                        <RadioGroup
                            value={timeFrame}
                            onChange={(e) => {
                                setTimeFrame(e.target.value as CoachMetricsTimeFrameEnum);
                            }}
                        >
                            {['current', 'week', 'month', 'quarter'].map((option) => (
                                <CustomFormControl
                                    key={option}
                                    value={option}
                                    label={t(`content.coach.add_item.metrics.${option}`)}
                                    checked={timeFrame === option}
                                    disabled={props.item !== undefined && !isUpdatedData}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    border: `1px solid ${palette.gray[400]}`,
                    backgroundColor: palette.white,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    p: 1,
                }}
            >
                {props.isSaved ? metricsForm : <SaveFirstTooltip>{metricsForm}</SaveFirstTooltip>}
                <Divider sx={{ my: 1, width: '100%' }}>
                    <Typography variant="overline" sx={{ fontSize: 10, lineHeight: 1.5 }}>
                        {t('content.coach.add_item.metrics.preview')}
                    </Typography>
                </Divider>
                <Box
                    sx={{
                        width: '100%',
                        height: timeFrame === 'current' ? '120px' : '300px',
                    }}
                >
                    {preview}
                </Box>
            </Box>
            {isEditable ? (
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    {props.children}
                    <Button
                        id="btn-cancel-item"
                        data-testid="btn-cancel-item"
                        variant="contained"
                        color="primary"
                        disabled={props.isLoading}
                        onClick={() => props.onClose()}
                        sx={{ ml: 'auto', flexShrink: 0 }}
                    >
                        {t('content.coach.add_item.cancel')}
                    </Button>
                    <Button
                        id="btn-save-item"
                        data-testid="btn-save-item"
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        disabled={
                            props.isLoading ||
                            (!isUpdatedData && props.item !== undefined) ||
                            (isUpdatedData && isLoading) ||
                            (isUpdatedData && isError)
                        }
                        sx={{ ml: 1, flexShrink: 0 }}
                    >
                        {props.item === undefined
                            ? t('content.coach.add_item.add')
                            : t('content.coach.add_item.update')}
                    </Button>
                </Box>
            ) : null}
        </>
    );
};

export default MetricsForm;
