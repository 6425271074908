import { Box, Card, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EventDetails } from '../../../backendsdk';
import MapContent from '../../../components/Map';
import useApi from '../../../hooks/api';
import useDrivers from '../../../hooks/drivers';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import MapNA from '../../../images/map_not_available.png';
import { isLocationValid } from '../../../utils/location';
import palette from '../../ColorPalette';
import { eventToMarker } from '../EventMarkers';
import { EVENT_TYPE_MAP } from './Defs';

export const toEventTypeText = (inputText: string): string => EVENT_TYPE_MAP[inputText] ?? inputText;

export interface EventDetailsSectionComponentProps {
    eventId: string;
    isVisible?: boolean;
    isLoading: boolean;
    onEventsPage: boolean;
    openInDialog: boolean;
    fitBoundsCounter: number;
    details: EventDetails;
    gpsRoute: Array<[string, number, CallableFunction]>;
    currentLocation?: string;
    darkMode?: boolean;
}

const EventDetailsSectionComponent: React.FC<EventDetailsSectionComponentProps> = (props) => {
    const { eventId, details, openInDialog, fitBoundsCounter, gpsRoute, currentLocation } = props;
    const isMobile = useIsMobile();
    const { profile } = useProfile();
    const { t } = useTranslation();
    const { api } = useApi();
    const { getDriver } = useDrivers();
    const apiRef = useRef(api);

    // remove
    eventId;

    useEffect(() => {
        apiRef.current = api;
    }, [api]);

    const driver = getDriver(details?.trip?.driver);
    const driverName = (driver?.fullName || t('content.fleet.unknown_driver'))?.toString();
    const subFleet = driver ? driver.sub_fleet : t('content.events.none');

    if (details == undefined || (!props.onEventsPage && eventId != details.event_id.toString())) {
        return null;
    }

    const eventRoute: Array<[string, number, CallableFunction]> | undefined = details.route
        ? details.route.split(',').map((location) => [location.toString(), 0, () => palette.accent])
        : undefined;
    const markers: Array<React.ReactNode> = [];
    if (!!details.location) {
        markers.push(eventToMarker(details.location, details.type, 1));
    } else {
        const eventEstimatedLocation = eventRoute ? eventRoute[Math.floor(eventRoute.length / 2)][0] : undefined;
        markers.push(eventToMarker(eventEstimatedLocation, details.type, 1));
    }

    const route = gpsRoute.length > 0 ? gpsRoute : eventRoute;
    const displayMap =
        (route !== undefined && route.length > 0 && route.every((r) => isLocationValid(r[0]))) ||
        isLocationValid(details.location);

    if ((props.isVisible !== undefined && !props.isVisible) || (openInDialog && props.isLoading)) {
        return null;
    }
    const themeLabelColor = props.darkMode ? palette.neutral[300] : 'grayColor.main';
    const themeColor = props.darkMode ? palette.neutral[100] : undefined;
    const detailsFontSize = openInDialog ? 14 : 16;

    const eventDetails = (
        <Grid container columns={2} sx={{ my: 1 }}>
            <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography fontSize={12} sx={{ color: themeLabelColor, fontWeight: 'bold' }}>
                    {t('table.headers.name')}
                </Typography>
                <Typography
                    fontSize={detailsFontSize}
                    sx={{
                        color: !!driver?.fullName ? themeColor : palette.neutral[700],
                        mb: 1,
                    }}
                >
                    {driverName}
                </Typography>
                <Typography fontSize={12} sx={{ color: themeLabelColor, fontWeight: 'bold' }}>
                    {t('table.headers.license_plate')}
                </Typography>
                <Typography fontSize={detailsFontSize} sx={{ color: themeColor, mb: 1 }}>
                    {details.device.license_plate}
                </Typography>
                <Typography fontSize={12} sx={{ color: themeLabelColor, fontWeight: 'bold' }}>
                    {t('table.headers.sub_fleet')}
                </Typography>
                <Typography fontSize={detailsFontSize} sx={{ color: !!driver ? themeColor : palette.neutral[700] }}>
                    {subFleet}
                </Typography>
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography fontSize={12} sx={{ color: themeLabelColor, fontWeight: 'bold' }}>
                    {t('table.headers.event_type')}
                </Typography>
                <Typography fontSize={detailsFontSize} sx={{ color: themeColor, mb: 1 }}>
                    {t(toEventTypeText(details.type))}
                </Typography>
                <Typography fontSize={12} sx={{ color: themeLabelColor, fontWeight: 'bold' }}>
                    {t('table.headers.severity')}
                </Typography>
                <Typography fontSize={detailsFontSize} sx={{ color: themeColor, mb: 1 }}>
                    {!!details.severity ? Math.round(details.severity * 10) / 10 : t('content.events.severity.na')}
                </Typography>
                <Typography fontSize={12} sx={{ color: themeLabelColor, fontWeight: 'bold' }}>
                    {t('table.headers.date')}
                </Typography>
                <Typography fontSize={detailsFontSize} sx={{ color: themeColor }}>
                    {dayjs.unix(details.timestamp).format(profile.dateTimeFormat)}
                </Typography>
            </Grid>
        </Grid>
    );

    const content = (
        <Box
            id="event-details-section"
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: openInDialog && !isMobile ? 'row-reverse' : 'column',
                mt: 1,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    aspectRatio: openInDialog && !isMobile ? undefined : '8 / 3',
                    height: '215px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
            >
                {displayMap ? (
                    <MapContent
                        zoom={16}
                        route={route}
                        location={currentLocation}
                        eventLocation={details.location}
                        markers={markers}
                        fitBoundsCounter={fitBoundsCounter}
                        full
                        darkMode={props.darkMode}
                    />
                ) : (
                    <img src={MapNA} height="100%" />
                )}
            </Box>
            {openInDialog && !isMobile ? (
                <Box sx={{ mr: 1, width: '100%', height: '215px' }}>
                    <Card sx={{ px: 1, width: '100%', height: '100%' }}>{eventDetails}</Card>
                </Box>
            ) : (
                eventDetails
            )}
        </Box>
    );

    return content;
};

export default EventDetailsSectionComponent;
