import { PointExpression } from 'leaflet';
import React, { useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useMap } from 'react-leaflet';

import accidentSvg from '../../../../images/markers/overview/marker-accident.svg';
import palette from '../../../ColorPalette';

const iconSize = [25, 25];

const getAccidentIcon = () => {
    return L.divIcon({
        html: ReactDOMServer.renderToStaticMarkup(
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <img src={accidentSvg} />
            </div>,
        ),
        iconSize: iconSize as PointExpression,
        iconAnchor: [iconSize[0] / 2, iconSize[1]],
        className: 'div-icon',
    });
};

interface AccidentMapIconsProps {
    locationMap: Record<string, [number, number]>;
    isClustered: boolean;
    setSelectedAccidentId: CallableFunction;
}

const AccidentMapIcons: React.FC<AccidentMapIconsProps> = (props: AccidentMapIconsProps) => {
    const map = useMap();

    useEffect(() => {
        if (!map) return;

        const markers: L.Marker[] = [];
        const markersLayer = L.markerClusterGroup({
            spiderfyDistanceMultiplier: 2.5,
            polygonOptions: { color: palette.red[500] },
        });

        for (const [accidentId, location] of Object.entries(props.locationMap)) {
            const divIcon = getAccidentIcon();
            const marker = L.marker(location, { icon: divIcon }).on('click', () =>
                props.setSelectedAccidentId(accidentId),
            );
            if (props.isClustered) {
                markersLayer.addLayer(marker);
            } else {
                markers.push(marker.addTo(map));
            }
        }

        if (props.isClustered) {
            map.addLayer(markersLayer);
            return () => markersLayer.remove();
        } else {
            return () => {
                markers.forEach((marker) => marker.remove());
            };
        }
    }, [map, props.locationMap, props.isClustered]);

    useEffect(() => {
        map.fitBounds(Object.values(props.locationMap));
    }, [map, props.locationMap]);

    return null;
};

export default AccidentMapIcons;
