import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../../../../components/TrackedComponents';

interface UploadPhotoProps {
    photo: File | null;
    setPhoto: React.Dispatch<React.SetStateAction<File | null>>;
    onBack: () => void;
    onNext: () => void;
    dashboardLink: React.ReactNode;
}

const UploadPhoto: React.FC<UploadPhotoProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: 0,
            }}
        >
            <Box
                sx={{
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: 0,
                    flexShrink: 0,
                }}
            >
                <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                    {t('setup_tool.upload_photo.header')}
                </Typography>
                <Typography fontSize={14} sx={{ fontWeight: 500, mb: 1 }}>
                    {t('setup_tool.upload_photo.instructions')}
                </Typography>
                <Button
                    id="btn-browse-files"
                    data-testid="btn-browse-files"
                    variant={!!props.photo ? 'contained' : 'outlined'}
                    component="label"
                    sx={{ mb: 2 }}
                >
                    {t('setup_tool.upload_photo.browse')}
                    <input
                        id="file-btn"
                        data-testid="file-btn"
                        hidden
                        type="file"
                        accept="image/*"
                        capture="environment"
                        onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                                props.setPhoto(e.target.files[0]);
                            }
                        }}
                    />
                </Button>
            </Box>
            <Box sx={{ width: '80%', height: '100%', overflowY: 'auto' }}>
                {props.photo ? <img src={URL.createObjectURL(props.photo)} alt="Uploaded" width="100%" /> : null}
            </Box>
            <Box sx={{ width: '100%', display: 'flex', mt: 1, minHeight: 0, flexShrink: 0 }}>
                <Box sx={{ flex: 1, mr: 1 }}>
                    <Button id="btn-back" fullWidth variant="contained" color="primary" onClick={() => props.onBack()}>
                        {t('setup_tool.back')}
                    </Button>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Button
                        id="btn-next"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={!props.photo}
                        onClick={() => props.onNext()}
                    >
                        {t('setup_tool.next')}
                    </Button>
                </Box>
            </Box>
            {props.dashboardLink}
        </Box>
    );
};

export default UploadPhoto;
