import { Box, SelectChangeEvent, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Damage, ExtendedAccident } from '../../../backendsdk';
import { TrackedButton as Button, TrackedDialog as Dialog } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import { MuiSelect, MuiTextField } from './MuiFormControls';

interface AccidentDamageFormProps {
    details: ExtendedAccident;
    onSave: CallableFunction;
    show: boolean;
    onClose: CallableFunction;
    setAlert: CallableFunction;
}

const AccidentDamageDisposalModal: React.FC<AccidentDamageFormProps> = (props: AccidentDamageFormProps) => {
    const [creating, setCreating] = useState<boolean>(false);
    const [disposalPayment, setDisposalPayment] = useState<string>('');
    const contactToDamageIdMap: Record<string, string> = Object.fromEntries(
        props.details.damage_list.map((damage: Damage) => [
            damage.vehicle.contact?.name || '',
            damage.damage_id.toString(),
        ]),
    );
    const [contact, setContact] = useState<string>(props.details.damage_list[0].vehicle.contact?.name);
    const { t } = useTranslation();
    const { agencyApi } = useApi();

    const createDisposalLetter = (payment: string) => {
        setCreating(true);
        props.setAlert({ message: t('content.accidents.damage.disposal_letter.creating'), type: 'info' });
        agencyApi
            .agencyV1DamageDamageIdDisposalPost({
                damageId: contactToDamageIdMap[contact],
                disposalLetterRequest: { payment },
            })
            .then(() => {
                props.setAlert({
                    message: t('content.accidents.damage.disposal_letter.done'),
                    type: 'success',
                    duration: 6000,
                });
                setCreating(false);
                setDisposalPayment('');
                props.onClose();
                props.onSave();
            })
            .catch(() => {
                setCreating(false);
                props.setAlert({
                    message: t('content.accidents.damage.disposal_letter.error'),
                    type: 'error',
                    duration: 6000,
                });
            });
    };

    return (
        <Dialog id="disposal-letter-dialog" open={props.show} onClose={() => props.onClose()}>
            <Box sx={{ p: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {t('content.accidents.damage.disposal_letter.create')}
                </Typography>
                <MuiSelect
                    id="damage-select-field"
                    label={t('content.accidents.damage.third_party_name')}
                    value={contact}
                    options={Object.keys(contactToDamageIdMap)}
                    onChange={(e: SelectChangeEvent<string>) => setContact(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <MuiTextField
                    id="disposal-letter-payment-field"
                    label={t('content.accidents.damage.disposal_letter.payment')}
                    value={disposalPayment}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDisposalPayment(e.target.value)}
                    name="disposal_payment"
                    sx={{ mb: 2 }}
                    type="number"
                />
                <Box textAlign="center">
                    <Button
                        id="disposal-letter-create-btn"
                        size="small"
                        variant="contained"
                        disabled={disposalPayment?.toString() === '' || creating}
                        onClick={() => (disposalPayment ? createDisposalLetter(disposalPayment) : null)}
                        sx={{ mr: 1 }}
                    >
                        {t('content.accidents.damage.disposal_letter.create')}
                    </Button>
                    <Button
                        id="disposal-letter-cancel-btn"
                        size="small"
                        variant="outlined"
                        onClick={() => props.onClose()}
                    >
                        {t('content.accidents.damage.disposal_letter.cancel')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default AccidentDamageDisposalModal;
