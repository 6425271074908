import { Box, FormControlLabel, Menu, MenuProps, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React from 'react';

import palette from '../../ColorPalette';

React;

export const StyledBox = styled(Box)(() => ({
    // fill height of table cell with input in order to place the validation tooltip correctly
    '& .MuiInputBase-root': {
        height: '100%',
    },
}));

export const StyledFormControl = styled(FormControlLabel)(() => ({
    // resize label and form control (radio/checkbox) and align them
    '& .MuiSvgIcon-root': {
        fontSize: 16,
        marginRight: -0.5,
        marginBottom: 1,
    },
    '& .MuiTypography-root': {
        fontSize: 14,
        marginTop: 1,
    },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        marginTop: '8px !important',
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.contrastText,
    },
}));

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    border: 'none',
    // use box-shadow instead of border to prevent horizontal overflow
    '& .MuiDataGrid-main': {
        border: 'none',
        borderRadius: '4px 4px 0 0',
        boxShadow: `${palette.neutral[400]} 0px 0px 0px 1px`,
    },
    '& .MuiDataGrid-columnHeaders': {
        color: palette.white,
        backgroundColor: theme.palette.primary.main,
        '& .MuiSvgIcon-root': {
            fill: palette.white,
        },
    },
    '& .MuiDataGrid-cell': {
        borderColor: palette.neutral[400],
    },
    '& .MuiDataGrid-footerContainer': {
        border: 'none',
        borderRadius: '0 0 4px 4px',
        boxShadow: `${palette.neutral[400]} 0px 0px 0px 1px`,
    },
    '& .MuiDataGrid-pinnedColumnHeaders': {
        backgroundColor: theme.palette.primary.light,
    },
}));

export const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        color: theme.palette.primary.main,
        // box shadow for menu (open state): delicate border on top and soft shadows on bottom and sides
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        // resize text and icon of menu item and align them
        '& .MuiMenuItem-root': {
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            // change background color of active menu item to primary with lower opacity
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));
