import { useMediaQuery, useTheme } from '@mui/material';

const useIsMobile = () => {
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const isTest = process.env.JEST_WORKER_ID !== undefined;

    return isMobile && !isTest;
};

export default useIsMobile;
