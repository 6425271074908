import {
    Box,
    CircularProgress,
    Collapse,
    Divider,
    FormControl,
    List,
    ListItem,
    Select,
    Skeleton,
    Typography,
} from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { EventDetails, RelatedEvents } from '../../../backendsdk';
import {
    TrackedLink as Link,
    TrackedListItemButton as ListItemButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import { useImages } from '../../../hooks/images';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import { EVENT_TYPE_MAP } from './Defs';

interface RelatedEventsProps {
    eventId: string;
    eventDetails: EventDetails;
    onFilterChange: CallableFunction;
}

const RelatedEventsComponent: React.FC<RelatedEventsProps> = (props: RelatedEventsProps) => {
    const [relatedEvents, setRelatedEvents] = useState<RelatedEvents>({ day: [], type: [] });
    const [isLoadingRelatedEvents, setIsLoadingRelatedEvents] = useState<boolean>(true);
    const [filter, setFilter] = useState<keyof RelatedEvents>('day');
    const { api } = useApi();
    const getImage = (eventIdStr: string) => {
        const eventId = parseInt(eventIdStr);
        return api.apiV0EventEventIdImageGet({ eventId }, { responseType: 'blob' });
    };
    const [eventImages, addToQueue] = useImages(10, getImage);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const history = useHistory();
    const query = useQuery();

    let gridFilter: GridFilterModel;
    if (filter === 'day') {
        gridFilter = {
            items: [
                {
                    field: 'license_plate',
                    operator: 'equals',
                    value: props.eventDetails.device.license_plate,
                },
                {
                    field: 'timestamp',
                    operator: 'onDate',
                    value: dayjs.unix(props.eventDetails.timestamp).toDate(),
                },
            ],
        };
    } else if (filter === 'type') {
        gridFilter = {
            items: [
                {
                    field: 'license_plate',
                    operator: 'equals',
                    value: props.eventDetails.device.license_plate,
                },
                {
                    field: 'event_type',
                    operator: 'is',
                    value: props.eventDetails.type,
                },
            ],
        };
    }

    useEffect(() => {
        setRelatedEvents({ day: [], type: [] });
        setIsLoadingRelatedEvents(true);
        api.apiV0EventEventIdRelatedGet({ eventId: parseInt(props.eventId) })
            .then((res) => {
                setRelatedEvents(res.data);
                addToQueue(
                    Object.values(res.data)
                        .flat()
                        .map((e) => e.event_id.toString()),
                );
            })
            .finally(() => setIsLoadingRelatedEvents(false));
    }, [props.eventId]);

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ width: '100%', px: 1, mb: 1 }}>
                <FormControl sx={{ width: '50%' }}>
                    <Select
                        value={filter}
                        id="filter-select"
                        data-testid="filter-select"
                        onChange={(e) => {
                            setFilter(e.target.value as keyof RelatedEvents);
                        }}
                        inputProps={{
                            'data-testid': 'filter-dropdown',
                        }}
                        variant="outlined"
                        size="small"
                    >
                        {['day', 'type'].map((option) => (
                            <MenuItem key={option} value={option} id={option} data-testid={option}>
                                {t(`content.events.same_${option}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {isLoadingRelatedEvents ? (
                <Box sx={{ p: 1 }}>
                    <Box
                        sx={{
                            width: '100%',
                            aspectRatio: '16 / 3',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                </Box>
            ) : relatedEvents[filter].length === 0 ? (
                <Box sx={{ p: 1 }}>
                    <Box
                        sx={{
                            width: '100%',
                            aspectRatio: '16 / 3',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="overline">{t('content.events.no_events_found')}</Typography>
                    </Box>
                </Box>
            ) : null}
            <Collapse in={Object.values(relatedEvents).flat().length > 0}>
                <List disablePadding>
                    {relatedEvents[filter].map((e, idx) => (
                        <React.Fragment key={`${filter}-${e.event_id}`}>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemButton
                                    id={`${e.event_id}-event-btn`}
                                    data-testid={`${e.event_id}-event-btn`}
                                    sx={{ p: 1 }}
                                    onClick={() => {
                                        history.push(`/events/${e.event_id}?${query.toString()}`);
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '50%',
                                            aspectRatio: '8 / 3',
                                            maxWidth: '250px',
                                            mr: 1,
                                            flexShrink: 0,
                                        }}
                                    >
                                        {eventImages[e.event_id] !== undefined ? (
                                            <img
                                                src={eventImages[e.event_id]}
                                                width="100%"
                                                style={{ display: 'block' }}
                                            />
                                        ) : (
                                            <Skeleton
                                                variant="rectangular"
                                                animation="wave"
                                                width="100%"
                                                height="100%"
                                            />
                                        )}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography
                                            id={`${e.event_id}-type`}
                                            variant="body3"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {t(EVENT_TYPE_MAP[e.type])}
                                        </Typography>
                                        <Typography id={`${e.event_id}-risk`} variant="body3">
                                            {t('table.headers.severity')}: {e.severity.toFixed(2)}
                                        </Typography>
                                        <Typography id={`${e.event_id}-datetime`} variant="body3">
                                            {dayjs.unix(e.timestamp).format(profile.dateTimeFormat)}
                                        </Typography>
                                    </Box>
                                </ListItemButton>
                            </ListItem>
                            {idx < relatedEvents[filter].length - 1 || relatedEvents[filter].length === 6 ? (
                                <Divider key={idx} />
                            ) : null}
                        </React.Fragment>
                    ))}
                </List>
            </Collapse>
            {!isLoadingRelatedEvents && relatedEvents[filter].length === 6 ? (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', py: 0.5 }}>
                    <Typography>
                        <Link
                            id="view-more-link"
                            component="button"
                            variant="overline"
                            onClick={() => props.onFilterChange(gridFilter)}
                        >
                            {t('content.events.view_more')}
                        </Link>
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
};

export default RelatedEventsComponent;
