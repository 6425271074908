import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailIcon from '@mui/icons-material/Mail';
import { Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button, TrackedMenuItem as MenuItem } from '../../../components/TrackedComponents';
import { StyledMenu } from './MuiStyled';

interface ActionsMenuProps {
    disabled: boolean;
    onSetActiveState: CallableFunction;
    onInvite: CallableFunction;
    onRemove: CallableFunction;
}

const ActionsMenu: React.FC<ActionsMenuProps> = (props: ActionsMenuProps) => {
    const anchorEl = useRef(null);
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <>
            <Button
                id="actions-menu-btn"
                variant="contained"
                disableElevation
                disabled={props.disabled}
                size="small"
                onClick={() => setOpen((prev) => !prev)}
                endIcon={<KeyboardArrowDownIcon />}
                buttonRef={anchorEl}
            >
                {t('content.users.actions.header')}
            </Button>
            <StyledMenu id="actions-menu" anchorEl={anchorEl.current} open={open} onClose={() => setOpen(false)}>
                <MenuItem
                    id="activate-menu-item"
                    disableRipple
                    onClick={() => {
                        setOpen(false);
                        props.onSetActiveState(true);
                    }}
                >
                    <LockOpenIcon />
                    <Typography variant="body2">{t('content.users.actions.activate')}</Typography>
                </MenuItem>
                <MenuItem
                    id="deactivate-menu-item"
                    disableRipple
                    onClick={() => {
                        setOpen(false);
                        props.onSetActiveState(false);
                    }}
                >
                    <LockIcon />
                    <Typography variant="body2">{t('content.users.actions.deactivate')}</Typography>
                </MenuItem>
                <MenuItem
                    id="invite-menu-item"
                    disableRipple
                    onClick={() => {
                        setOpen(false);
                        props.onInvite();
                    }}
                >
                    <MailIcon />
                    <Typography variant="body2">{t('content.users.actions.invite')}</Typography>
                </MenuItem>
                <MenuItem
                    id="remove-menu-item"
                    disableRipple
                    onClick={() => {
                        setOpen(false);
                        props.onRemove();
                    }}
                >
                    <DeleteIcon />
                    <Typography variant="body2">{t('content.users.actions.remove.button')}</Typography>
                </MenuItem>
            </StyledMenu>
        </>
    );
};

export default ActionsMenu;
