import { Box, Grid, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { Damage } from '../../../backendsdk';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { MuiTextField } from './MuiFormControls';

export const MBI_DAMAGE = 'MBI';

export const EMPTY_ACCIDENT_DAMAGE_DETAILS: Damage = {
    accident_id: 0,
    damage_id: 0,
    vehicle_owner: '',
    vehicle: {
        third_party: false,
        contact: {
            name: '',
            phone_number: '',
            contact_made: false,
        },
        license_plate: '',
        year_of_manufacture: '',
        model: '',
    },
    appraiser: {
        contact: {
            name: '',
            phone_number: '',
            contact_made: false,
        },
        cost: 0,
    },
    garage: '',
    category: '',
    claim: {
        claim_number: '',
        claim_occurred: false,
        insurance_company_status: '',
    },
    description: '',
    scheme: {
        angle_start: 0,
        angle_end: 0,
        move_direction: '',
    },
    payment_list: [],
    insurance_company: '',
    policy_number: '',
};

interface AccidentDamageFormProps {
    index: number;
    accident_id: number;
    details?: Damage;
    children?: React.ReactNode;
    setNewThirdParty?: CallableFunction;
    setUpdateCallbacks: CallableFunction;
    setValidateCallbacks: CallableFunction;
    allowUpdate: boolean;
    disabled?: boolean;
}

const AccidentDamageForm: React.FC<AccidentDamageFormProps> = (props: AccidentDamageFormProps) => {
    const { t } = useTranslation();
    const empty_accident_details = JSON.parse(JSON.stringify(EMPTY_ACCIDENT_DAMAGE_DETAILS));
    const [details, setDetails] = useState<Damage>(
        props.details ? props.details : { ...empty_accident_details, accident_id: props.accident_id },
    );
    const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
    const [yearOfManufactureError, setYearOfManufactureError] = useState<boolean>(false);
    const { agencyApi } = useApi();
    const { profile } = useProfile();
    const disabled = props.accident_id === 0 || !props.allowUpdate || props.disabled;

    const isUSCustomer = profile.customer.settings.country === 'us';

    useEffect(() => {
        setDetails(props.details ? props.details : { ...empty_accident_details, accident_id: props.accident_id });
        setDetailsChanged(false);
    }, [props.details, props.accident_id]);

    useEffect(() => {
        if (detailsChanged) {
            props.setUpdateCallbacks((prev: Record<string, CallableFunction>) => {
                return { ...prev, [`damage-${details.damage_id}`]: updateAccidentDamageDetails };
            });
        }
    }, [details]);

    useEffect(() => {
        props.setValidateCallbacks((prev: Record<string, CallableFunction>) => {
            return { ...prev, [`damage-${details.damage_id}`]: validateThirdParty };
        });
    }, [details]);

    const validateThirdParty = () => {
        let valid = true;
        const numberPattern = /^\d*$/;
        if (details.vehicle.year_of_manufacture && !numberPattern.test(details.vehicle.year_of_manufacture)) {
            setYearOfManufactureError(true);
            valid = false;
        }
        return valid;
    };

    const recurrsiveUpdateDetails = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        recDetails: Record<string, any>, // recDetails is object by ref
        key: string,
        value: string | number | boolean,
    ) => {
        const SEP = '__';
        if (key in recDetails) {
            recDetails[key] = value;
        } else if (key.includes(SEP)) {
            const firstKey = key.split(SEP, 1)[0];
            const newKey = key.substr(firstKey.length + SEP.length, key.length);
            const newRecDetails = firstKey in recDetails ? recDetails[firstKey] : { [newKey.split(SEP, 1)[0]]: {} };
            recDetails[firstKey] = recurrsiveUpdateDetails(newRecDetails, newKey, value);
        } else {
            recDetails[key] = value;
        }
        return recDetails;
    };

    const updateAccidentDamageDetails = () => {
        let promise;
        if (details.damage_id > 0) {
            promise = agencyApi.agencyV1AccidentAccidentIdDamageDamageIdPatch({
                accidentId: details.accident_id,
                damageId: details.damage_id,
                damage: details as Damage,
            });
        } else {
            promise = agencyApi
                .agencyV1AccidentAccidentIdDamagePost({ accidentId: details.accident_id, damage: details as Damage })
                .then(() => {
                    if (props.setNewThirdParty) {
                        props.setNewThirdParty(false);
                    }
                });
        }
        return promise;
    };

    const handleChange = (target: string, value: string | boolean) => {
        setDetailsChanged(true);
        (setDetails as CallableFunction)((oldDetails: Damage) => {
            const newDetails = Object.fromEntries(
                Object.entries(oldDetails).map(([key, origin_value]) => [key, key === target ? value : origin_value]),
            );
            return recurrsiveUpdateDetails(newDetails, target, value);
        });
    };

    const textOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.name, event.target.value);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Prompt when={detailsChanged} message={t('content.accidents.prompt_msg')} />
            <Box sx={{ mb: 1 }}>
                <Typography variant="overline">{t('content.accidents.damage.third_party')}</Typography>
            </Box>
            <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={3}>
                    <MuiTextField
                        id={`${props.index}-vehicle-owner`}
                        label={t('content.accidents.damage.vehicle_owner')}
                        value={details.vehicle_owner}
                        disabled={disabled}
                        onChange={textOnChange}
                        name="vehicle_owner"
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id={`${props.index}-name`}
                        label={t('content.accidents.damage.third_party_name')}
                        value={details.vehicle.contact?.name}
                        disabled={disabled}
                        onChange={textOnChange}
                        name="vehicle__contact__name"
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id={`${props.index}-phone-no`}
                        label={t('content.accidents.damage.third_party_phone_number')}
                        value={details.vehicle.contact?.phone_number}
                        disabled={disabled}
                        onChange={textOnChange}
                        name="vehicle__contact__phone_number"
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id={`${props.index}-license-plate`}
                        label={t('content.accidents.damage.license_plate')}
                        value={details.vehicle.license_plate}
                        disabled={disabled}
                        onChange={textOnChange}
                        name="vehicle__license_plate"
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id={`${props.index}-year-of-manufacture`}
                        label={t('content.accidents.damage.year_of_manufacture')}
                        value={details.vehicle.year_of_manufacture ? details.vehicle.year_of_manufacture : ''}
                        disabled={disabled}
                        error={yearOfManufactureError}
                        onChange={textOnChange}
                        name="vehicle__year_of_manufacture"
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id={`${props.index}-model`}
                        label={t('content.accidents.damage.model')}
                        value={details.vehicle.model}
                        disabled={disabled}
                        onChange={textOnChange}
                        name="vehicle__model"
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiTextField
                        id={`${props.index}-insurance-company`}
                        label={t('content.accidents.damage.insurance_company')}
                        disabled={disabled}
                        value={details.insurance_company || ''}
                        onChange={textOnChange}
                        name="insurance_company"
                    />
                </Grid>
                {isUSCustomer ? (
                    <Grid item xs={3}>
                        <MuiTextField
                            id={`${props.index}-policy-number`}
                            label={t('content.accidents.damage.policy_number')}
                            disabled={disabled}
                            value={details.policy_number}
                            onChange={textOnChange}
                            name="policy_number"
                        />
                    </Grid>
                ) : null}
            </Grid>
            {props.children}
        </Box>
    );
};

export default AccidentDamageForm;
