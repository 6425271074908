// ported from https://github.com/python/cpython/blob/ce87ab4dc5683fcc990c05e3875eff982824a144/Lib/genericpath.py#L121
const splitExtension = (fileName: string): [string, string] => {
    let sepIndex: number;
    if (fileName.includes('/')) {
        sepIndex = fileName.lastIndexOf('/');
    } else {
        sepIndex = fileName.lastIndexOf('\\');
    }
    const dotIndex = fileName.lastIndexOf('.');
    if (dotIndex > sepIndex) {
        // skip all leading dots
        let filenameIndex = sepIndex + 1;
        while (filenameIndex < dotIndex) {
            if (fileName[filenameIndex] !== '.') {
                return [fileName.slice(0, dotIndex), fileName.slice(dotIndex)];
            }
            filenameIndex += 1;
        }
    }
    return [fileName, ''];
};

export default splitExtension;

export const isEventVideo = (fileName: string): boolean => {
    return fileName.endsWith('.mp4') && !fileName.endsWith('_uncompressed.mp4');
};
