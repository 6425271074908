import { Box, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import { LatLngExpression, LatLngTuple, PointExpression } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker, Popup, useMap } from 'react-leaflet';
import { useHistory, useParams } from 'react-router-dom';

import { DeviceV3, EventDetails, RouteData, ShortLocationSample, TripDetails } from '../../../backendsdk';
import useApi from '../../../hooks/api';
import circleIcon from '../../../images/markers/circle.png';
import MarkerA from '../../../images/markers/overview/route-marker-a.svg';
import MarkerB from '../../../images/markers/overview/route-marker-b.svg';
import snapshotMarker from '../../../images/markers/snapshot-icon.svg';
import { SECONDS_IN_DAY } from '../../../utils/TimeFormatter';
import { isLocationValid, parseLocation } from '../../../utils/location';
import palette from '../../ColorPalette';
import { eventTypeToIcon } from '../EventMarkers';

export const routeIconSize = [25, 35];

export const getTopEvent = (events: EventDetails[]) => {
    const recurringIncidentEvent = events.find((e) => e.type === 'MsgRecurringTG');
    return recurringIncidentEvent ? recurringIncidentEvent : events[0];
};

interface MapRouteProps {
    device?: DeviceV3;
    selectedTrip?: TripDetails;
    setSelectedTrip: CallableFunction;
    setSelectedEventId: CallableFunction;
    setIsLoadingEvent: CallableFunction;
    tripRoute?: RouteData;
    setTripRoute?: CallableFunction;
    isCurrentTrip: boolean;
    setIsMapLoading: CallableFunction;
    setAlert: CallableFunction;
    selectedTime?: number[];
    overlayHeight?: number;
    percentVideoPlayed?: number;
    isSelectSegment?: boolean;
    snapshotTimestamps?: number[];
    currentLocation?: ShortLocationSample;
    images?: Record<number, string>;
    addToQueue?: CallableFunction;
    setInvalidateFunc?: CallableFunction;
    setTimespan?: CallableFunction;
    setAdditionalTrip?: CallableFunction;
}

const MapRoute: React.FC<MapRouteProps> = (props: MapRouteProps) => {
    const { tripRoute, setTripRoute, images, addToQueue } = props;
    const { api } = useApi();
    const { t } = useTranslation();
    const map = useMap();
    const [mapZoom, setMapZoom] = useState<number>(map.getZoom());
    const history = useHistory();
    const { licensePlate, tripId } = useParams<{ licensePlate: string; tripId: string }>();
    const [markerLocations, setMarkerLocations] = useState<Set<string>>();
    const [locationToSnapshotMap, setLocationToSnapshotMap] = useState<Record<string, number>>({});

    useEffect(() => {
        if (!map) {
            return;
        }
        if (props.setInvalidateFunc) {
            props.setInvalidateFunc(() => {
                return () => map.invalidateSize();
            });
        }
    }, [map]);

    useEffect(() => {
        const zoomChangeTrack = () => setMapZoom(map.getZoom());
        map.on('zoom', zoomChangeTrack);
        return () => {
            map.off('zoom', zoomChangeTrack);
        };
    }, [map, mapZoom]);

    useEffect(() => {
        if (props.currentLocation) {
            const marker = L.marker([props.currentLocation.latitude, props.currentLocation.longitude], {
                icon: L.icon({
                    iconUrl: circleIcon,
                    iconSize: [35, 20],
                }),
                zIndexOffset: 2,
            }).addTo(map);
            return () => {
                marker.remove();
            };
        }
    }, [props.currentLocation]);

    useEffect(() => {
        if (tripId && licensePlate) {
            const controller = new AbortController();
            props.setIsMapLoading(true);
            api.apiV2TripLicensePlateGet(
                {
                    licensePlate,
                    tripId,
                },
                { signal: controller.signal },
            )
                .then((res) => {
                    props.setSelectedTrip(res.data.trip);
                    if (setTripRoute) {
                        setTripRoute(res.data);
                    }
                    if (props.setTimespan) {
                        if (res.data.trip.end_time > dayjs().utc().unix() - SECONDS_IN_DAY) {
                            props.setTimespan('day');
                        } else if (res.data.trip.end_time > dayjs().utc().unix() - 7 * SECONDS_IN_DAY) {
                            props.setTimespan('week');
                        } else if (props.setAdditionalTrip) {
                            props.setAdditionalTrip(res.data.trip);
                        }
                    }
                })
                .catch(() => {
                    if (!controller.signal.aborted) {
                        props.setAlert({
                            message: t('content.fleet.map.trip_not_found'),
                            type: 'error',
                            duration: 6000,
                        });
                    }
                })
                .finally(() => {
                    props.setIsMapLoading(false);
                    history.replace(`/overview/${encodeURIComponent(licensePlate)}`);
                });
            return () => controller.abort();
        }
    }, [tripId, licensePlate]);

    useEffect(() => {
        if (setTripRoute) {
            if (!!props.selectedTrip) {
                const controller = new AbortController();
                props.setIsMapLoading(true);
                api.apiV2TripLicensePlateGet(
                    {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        licensePlate: props.selectedTrip.license_plate!,
                        timeFrom: dayjs.unix(props.selectedTrip.start_time).toISOString(),
                        timeTo: dayjs.unix(props.selectedTrip.end_time).toISOString(),
                    },
                    { signal: controller.signal },
                )
                    .then((res) => {
                        setTripRoute(res.data);
                    })
                    .catch(() => {
                        if (!controller.signal.aborted) {
                            props.setAlert({
                                message: t('content.fleet.map.error_loading_trip'),
                                type: 'error',
                                duration: 6000,
                            });
                        }
                    })
                    .finally(() => props.setIsMapLoading(false));

                return () => controller.abort();
            } else {
                setTripRoute(undefined);
            }
        }
    }, [props.selectedTrip]);

    useEffect(() => {
        const markers: L.Marker[] = [];
        const points: LatLngExpression[] = (tripRoute?.route || []).map((r) => [r.latitude, r.longitude]);
        let backdropPolyline: L.Polyline | undefined;
        let polyline: L.Polyline | undefined;
        if (points.length > 1) {
            const startMarker = L.marker(points[0], {
                icon: L.icon({
                    iconUrl: MarkerA,
                    iconSize: routeIconSize as PointExpression,
                    iconAnchor: [routeIconSize[0] / 2, routeIconSize[1]],
                    className: 'map-marker-unclickable start-map-marker',
                }),
            }).addTo(map);
            markers.push(startMarker);
            if (!props.isCurrentTrip) {
                const endMarker = L.marker(points[points.length - 1], {
                    icon: L.icon({
                        iconUrl: MarkerB,
                        iconSize: routeIconSize as PointExpression,
                        iconAnchor: [routeIconSize[0] / 2, routeIconSize[1]],
                        className: 'map-marker-unclickable end-map-marker',
                    }),
                }).addTo(map);
                markers.push(endMarker);
            }
            polyline = L.polyline(points, {
                color: !!props.selectedTime ? palette.neutral[400] : palette.accent,
                weight: 5,
                className: 'map-polyline',
            })
                .addTo(map)
                .bringToBack();
            backdropPolyline = L.polyline(points, { color: palette.black, weight: 7, className: 'map-polyline' })
                .addTo(map)
                .bringToBack();
        }

        if (tripRoute?.events && tripRoute.events.length > 0) {
            const locationToEvents = tripRoute.events.reduce((acc: Record<string, EventDetails[]>, e: EventDetails) => {
                if (e.location) {
                    acc[e.location] ??= [];
                    acc[e.location].push(e);
                }
                return acc;
            }, {} as Record<string, EventDetails[]>);

            for (const location of Object.keys(locationToEvents)) {
                const event = getTopEvent(locationToEvents[location]);
                if (isLocationValid(location)) {
                    const marker = L.marker(parseLocation(location), {
                        icon: eventTypeToIcon(event.type),
                    })
                        .addTo(map)
                        .on('click', () => {
                            props.setIsLoadingEvent(true);
                            props.setSelectedEventId(event.event_id.toString());
                        });
                    markers.push(marker);
                }
            }
        }

        if (backdropPolyline) {
            map.fitBounds(backdropPolyline.getBounds().pad(0.1), { paddingBottomRight: [0, props.overlayHeight || 0] });
        } else if (markers.length > 0) {
            map.fitBounds(L.featureGroup(markers).getBounds().pad(0.1), {
                paddingBottomRight: [0, props.overlayHeight || 0],
            });
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let control: any;
        if (backdropPolyline || markers.length > 0) {
            const resetZoomControl = L.Control.extend({
                options: {
                    position: 'topright',
                },
                onAdd: (map: L.Map) => {
                    const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
                    const button = L.DomUtil.create('a', 'leaflet-buttons-control-button', container);
                    button.innerHTML =
                        '<svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" style="margin: 2px"><path d="m15 3 2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z"></path></svg>';
                    L.DomEvent.disableClickPropagation(button);
                    L.DomEvent.on(button, 'click', function () {
                        map.fitBounds(
                            !!backdropPolyline ? backdropPolyline.getBounds() : L.featureGroup(markers).getBounds(),
                        );
                    });

                    container.title = t('content.fleet.map.reset_zoom');

                    return container;
                },
            });
            control = new resetZoomControl();
            control.addTo(map);
        }

        return () => {
            if (polyline) {
                map.removeLayer(polyline);
            }
            if (backdropPolyline) {
                map.removeLayer(backdropPolyline);
            }
            if (markers.length > 0) {
                markers.forEach((marker) => marker.remove());
            }
            if (control) {
                control.remove();
            }
        };
    }, [tripRoute, !!props.selectedTime, props.overlayHeight, map]);

    useEffect(() => {
        const snapshotMarkers: L.Marker[] = [];
        const markerLocations: Set<string> = new Set();

        if (
            !tripRoute ||
            !tripRoute.route ||
            tripRoute.route.length <= 1 ||
            !props.snapshotTimestamps ||
            props.snapshotTimestamps.length === 0 ||
            !addToQueue
        ) {
            return;
        }

        const locationToSnapshotMap: Record<string, number> = {};

        // the initial distance between markers at maximum zoom level (zoom level 20)
        const baseDistance = 0.0005;
        // determines how quickly the distance between markers decreases as the zoom level increases
        const scaleFactor = 0.65;
        const distanceBetweenMarkers = baseDistance * Math.pow(2, scaleFactor * (20 - mapZoom));
        const distanceBetweenMarkersSquared = distanceBetweenMarkers * distanceBetweenMarkers;

        let lastMarkerLocation = tripRoute.route[0];
        for (let i = 1; i < tripRoute.route.length; i++) {
            const currentLocation = tripRoute.route[i];
            const distanceSquared =
                (currentLocation.latitude - lastMarkerLocation.latitude) ** 2 +
                (currentLocation.longitude - lastMarkerLocation.longitude) ** 2;
            if (distanceSquared >= distanceBetweenMarkersSquared) {
                lastMarkerLocation = currentLocation;
                const closestSnapshot = findClosestSnapshot(currentLocation.timestamp, props.snapshotTimestamps);
                const closestSnapshotLocation = findClosestLocationToSnapshot(tripRoute.route, closestSnapshot);
                if (closestSnapshotLocation && !markerLocations.has(locationSampleToString(closestSnapshotLocation))) {
                    const closestLocationString = locationSampleToString(closestSnapshotLocation);
                    markerLocations.add(closestLocationString);
                    locationToSnapshotMap[closestLocationString] = closestSnapshot;
                    addToQueue([closestSnapshot.toString()]);
                }
            }
        }
        setMarkerLocations(markerLocations);
        setLocationToSnapshotMap(locationToSnapshotMap);
        return () => {
            snapshotMarkers.forEach((marker) => marker.remove());
        };
    }, [tripRoute, map, mapZoom]);

    const findClosestSnapshot = (timestamp: number, snapshots: number[]): number => {
        return snapshots.reduce((prev, curr) =>
            Math.abs(curr - timestamp) < Math.abs(prev - timestamp) ? curr : prev,
        );
    };

    const findClosestLocationToSnapshot = (
        route: ShortLocationSample[],
        snapshotTimestamp: number,
    ): ShortLocationSample | undefined => {
        let minDistance = Infinity;
        let closestLocation;
        for (const point of route) {
            const distance = Math.abs(point.timestamp - snapshotTimestamp);
            if (distance < minDistance) {
                minDistance = distance;
                closestLocation = point;
            }
        }
        return closestLocation;
    };

    const locationSampleToString = (location: ShortLocationSample): string => {
        return `${location.latitude},${location.longitude}`;
    };

    useEffect(() => {
        const points: LatLngExpression[] = (tripRoute?.route || []).map((r) => [r.latitude, r.longitude]);
        let selectedPolyline: L.Polyline | undefined;
        if (points.length > 1) {
            if (tripRoute && !!props.selectedTime) {
                const selectedTime = props.selectedTime;
                const selectedSegment = tripRoute.route
                    .filter((item: ShortLocationSample) => {
                        return item.timestamp >= selectedTime[0] && item.timestamp <= selectedTime[1];
                    })
                    .map((item: ShortLocationSample) => [item.latitude, item.longitude] as LatLngTuple);
                selectedPolyline = L.polyline(selectedSegment, {
                    color: palette.accent,
                    weight: 5,
                    className: 'map-polyline',
                }).addTo(map);
            }
        }

        return () => {
            if (selectedPolyline) {
                map.removeLayer(selectedPolyline);
            }
        };
    }, [tripRoute, props.selectedTime]);

    if (!images) {
        return null;
    }

    return (
        <>
            {Array.from(markerLocations || []).map((location) => {
                const [latitude, longitude] = location.split(',').map((l) => parseFloat(l));
                if (
                    props.currentLocation &&
                    latitude === props.currentLocation.latitude &&
                    longitude === props.currentLocation.longitude
                ) {
                    return null;
                }
                return (
                    <Marker
                        icon={L.icon({
                            iconUrl: snapshotMarker,
                            iconSize: [20, 20],
                        })}
                        position={[latitude, longitude]}
                        key={location}
                        eventHandlers={{
                            mouseover: (event) => {
                                event.target.setOpacity(1);
                                event.target.openPopup();
                            },
                            mouseout: (event) => {
                                event.target.setOpacity(0.75);
                                event.target.closePopup();
                            },
                        }}
                        opacity={0.75}
                        zIndexOffset={1}
                    >
                        <Popup>
                            <Box>
                                {!!images[locationToSnapshotMap[location]] ? (
                                    <img
                                        src={images[locationToSnapshotMap[location]]}
                                        width={300}
                                        style={{ display: 'block' }}
                                    />
                                ) : (
                                    <Skeleton width={300} height={112.5} variant="rectangular" animation="wave" />
                                )}
                            </Box>
                        </Popup>
                    </Marker>
                );
            })}
        </>
    );
};

export default MapRoute;
