const objectDetectorInputHeight = 164;
export const imageHeight = 480;
export const fallbackValue = 100;

interface ScoreMap {
    weight: number;
    data: number[];
}

const degToRad = (angle: number): number => {
    return angle * (Math.PI / 180);
};

const radToDeg = (radians: number): number => {
    return radians * (180 / Math.PI);
};

const getMean = (data: number[]): number => {
    return data.reduce((acc, value) => acc + value, 0) / data.length;
};

const getStdDev = (data: number[]): number => {
    const mean = getMean(data);
    const variance = data.reduce((acc, value) => acc + (value - mean) ** 2, 0) / data.length;
    return Math.sqrt(variance);
};

const linspace = (start: number, end: number, num: number): number[] => {
    const arr: number[] = [];
    const step = (end - start) / (num - 1);
    for (let i = 0; i < num; i++) {
        arr.push(start + step * i);
    }
    return arr;
};

const getGroundIntersectionDistance = (
    camHeight: number,
    verticalAperture: number,
    camAngles: number[],
): [number[], number[]] => {
    const beta = camAngles.map((camAngle) => camAngle + verticalAperture / 2);
    const betaRad = beta.map((angle) => degToRad(angle));
    const groundDistance = betaRad.map((angle) => camHeight / Math.tan(angle));
    const fullDistance = betaRad.map((angle) => camHeight / Math.sin(angle));
    return [groundDistance, fullDistance];
};

const getGroundIntersectionWidth = (fullDistance: number[], horizontalAperture: number): number[] => {
    const factor = 2 * Math.tan(degToRad(horizontalAperture / 2));
    return multiplyArrayByScalar(fullDistance, factor);
};

const getGroundDistance = (
    camHeight: number,
    verticalAperture: number,
    camAngle: number | number[],
    bbBottom: number | number[],
    imageHeight: number,
) => {
    const angles = Array.isArray(camAngle) ? camAngle : [camAngle];
    const bottom = Array.isArray(bbBottom) ? bbBottom : [bbBottom];
    const a = safeDivide(
        bottom,
        bottom.map((pixel) => imageHeight - pixel),
        0.001,
    ) as number[];
    const betaRad = degToRad(verticalAperture);
    let aux = a.map((value) => value * Math.sin(betaRad));
    aux = safeDivide(
        aux,
        a.map((value) => 1 + value * Math.cos(betaRad)),
        0.001,
    ) as number[];
    const alpha = aux.map((value) => radToDeg(Math.atan(value)));
    const gamma = angles.map((angle) => angle - 0.5 * verticalAperture);
    let delta: number[] = [];
    // Sum the two arrays
    if (alpha.length !== gamma.length) {
        if (alpha.length === 1) {
            delta = gamma.map((value) => value + alpha[0]);
        } else if (gamma.length === 1) {
            delta = alpha.map((value) => value + gamma[0]);
        } else {
            throw new Error('Inputs must have the same length');
        }
    } else {
        delta = alpha.map((value, index) => value + gamma[index]);
    }
    const groundDist = safeDivide(
        camHeight,
        delta.map((value) => Math.tan(degToRad(value))),
        0.001,
    ) as number[];
    return groundDist.map((value) => (value < 0 ? 9999 : value));
};

const calculateCriterion = (data: number[], threshold: number | undefined = undefined, largerThanThreshold = true) => {
    if (threshold === undefined) {
        return data;
    }
    if (largerThanThreshold) {
        const maxValues = data.map((value) => Math.max(threshold - value, 0));
        const maxValue = Math.max(...maxValues.filter((value) => !isNaN(value)));
        if (maxValue === 0) {
            return maxValues;
        } else {
            return maxValues.map((value) => value / maxValue);
        }
    } else {
        const maxValues = data.map((value) => Math.max(value - threshold, 0));
        const maxValue = Math.max(...maxValues.filter((value) => !isNaN(value)));
        if (maxValue === 0) {
            return maxValues;
        } else {
            return maxValues.map((value) => value / maxValue);
        }
    }
};

const safeDivide = (
    numerator: number | number[],
    denominator: number | number[],
    threshold: number,
): number | number[] => {
    // Check if both inputs are scalars
    if (typeof numerator === 'number' && typeof denominator === 'number') {
        // Directly handle the scalar case
        if (Math.abs(denominator) > threshold) {
            return numerator / denominator;
        } else {
            // Compute the fallback value using the sign of the denominator
            const sign = Math.sign(denominator) !== 0 ? Math.sign(denominator) : 1;
            return numerator / (sign * threshold);
        }
    } else {
        if (typeof numerator === 'number' && Array.isArray(denominator)) {
            // Handle the case where the numerator is a scalar and the denominator is an array
            return denominator.map((value) => safeDivide(numerator, value, threshold)) as number[];
        } else if (Array.isArray(numerator) && typeof denominator === 'number') {
            // Handle the case where the numerator is an array and the denominator is a scalar
            return numerator.map((value) => safeDivide(value, denominator, threshold)) as number[];
        } else if (Array.isArray(numerator) && Array.isArray(denominator)) {
            // Handle the case where both the numerator and denominator are arrays
            if (numerator.length !== denominator.length) {
                throw new Error('Numerator and denominator must have the same length');
            }
            return numerator.map((value, index) => safeDivide(value, denominator[index], threshold)) as number[];
        }
    }
    throw new Error('Invalid input');
};

const pixelFromGroundDistance = (
    camHeight: number,
    verticalAperture: number,
    camAngles: number[],
    hoodDepth: number,
    imageHeight: number,
) => {
    const beta = verticalAperture;
    const betaRad = degToRad(beta);
    const verticalPixel: number[] = [];

    camAngles.forEach((angle) => {
        const gamma = angle - 0.5 * beta;
        const gammaRad = degToRad(gamma);
        const b = Math.sin(betaRad) + Math.tan(gammaRad) * Math.cos(betaRad);
        const c = Math.cos(betaRad) - Math.tan(gammaRad) * Math.sin(betaRad);
        let a = camHeight - hoodDepth * Math.tan(gammaRad);
        a = safeDivide(a, hoodDepth * b - camHeight * c, 0.001) as number;
        let pixel = safeDivide(a * imageHeight, 1 + a, 0.001) as number;
        pixel = Math.max(0, Math.min(pixel, imageHeight));
        verticalPixel.push(pixel);
    });

    return verticalPixel;
};

const multiplyArrayByScalar = (array: number[], scalar: number): number[] => {
    return array.map((value) => value * scalar);
};

const sumArrays = (arrays: number[][]): number[] => {
    if (arrays.length === 0) return [];
    const length = arrays[0].length;
    const result = new Array(length).fill(0);
    for (const array of arrays) {
        for (let i = 0; i < length; i++) {
            result[i] += array[i];
        }
    }
    return result;
};

const analyseInstallation = (
    vehicleWidth: number,
    hoodHeight: number,
    hoodDepth: number,
    installationHeight: number,
    horizontalAperture: number,
    verticalAperture: number,
    objectDetectorInputHeight: number,
    imageHeight: number,
): [number[], Record<string, ScoreMap>, number[]] => {
    const maximalBlindDistance = 0.01;
    const maximalHoodInterruption = 0.0;
    const minimalMaximalRange = 100;

    const scoreMaps: Record<string, ScoreMap> = {};
    const camAngles: number[] = linspace(-15, 30, 470);
    const camHeight = installationHeight;

    const groundIntersectionDistances = getGroundIntersectionDistance(installationHeight, verticalAperture, camAngles);
    const groundIntersectionWidths = getGroundIntersectionWidth(groundIntersectionDistances[1], horizontalAperture);

    scoreMaps['groundIntersectionWidths'] = {
        weight: 1,
        data: calculateCriterion(groundIntersectionWidths, vehicleWidth * 1.2, true),
    };

    const hoodPixel = pixelFromGroundDistance(
        camHeight - hoodHeight,
        verticalAperture,
        camAngles,
        hoodDepth,
        imageHeight,
    );
    const hoodLocation = hoodPixel.map((pixel) => Math.max((imageHeight - pixel) / imageHeight, 0));
    scoreMaps['hoodLocation'] = { weight: 1, data: calculateCriterion(hoodLocation, maximalHoodInterruption, false) };

    let effectiveDist = getGroundDistance(camHeight, verticalAperture, camAngles, hoodPixel, imageHeight);
    effectiveDist = effectiveDist.map((value) => (value === 9999 ? NaN : value));
    scoreMaps['effectiveClosestVisibleGroundDistance'] = {
        weight: 1,
        data: calculateCriterion(effectiveDist, maximalBlindDistance, false),
    };

    const maxRange = getGroundDistance(camHeight, verticalAperture, camAngles, 0, imageHeight);
    scoreMaps['maxRange'] = { weight: 1, data: calculateCriterion(maxRange, minimalMaximalRange, true) };

    const separations = new Array(camAngles.length).fill(NaN);
    const inputHeights = Array.from({ length: objectDetectorInputHeight }, (_, i) => i);
    camAngles.forEach((angle, j) => {
        let dists = getGroundDistance(camHeight, verticalAperture, angle, inputHeights, objectDetectorInputHeight);
        dists = dists.map((value) => (value === 9999 ? NaN : value));
        const diffs: number[] = [];
        for (let k = 0; k < dists.length - 1; k++) {
            diffs.push(-1 * (dists[k + 1] - dists[k]));
        }
        let separation_pixel = NaN;
        // Find the first index where diff <= 2
        for (let i = 0; i < diffs.length; i++) {
            if (diffs[i] <= 2) {
                separation_pixel = (i + 2) / objectDetectorInputHeight;
                break;
            }
        }
        separations[j] = separation_pixel;
    });
    scoreMaps['separationPixel'] = { weight: 1, data: calculateCriterion(separations, 0.5, false) };

    const weightSum = Object.values(scoreMaps).reduce((acc, scoreMap) => acc + scoreMap.weight, 0);
    for (const key in scoreMaps) {
        scoreMaps[key].weight = scoreMaps[key].weight / weightSum;
    }
    const weightedDataArrays = Object.values(scoreMaps).map((val) => multiplyArrayByScalar(val.data, val.weight));
    const sumWeightedData = sumArrays(weightedDataArrays);
    const totalMap = sumWeightedData.map((value) => 1 - value);

    return [camAngles, scoreMaps, totalMap];
};

const findBestSegment = (
    scores: number[],
    camAngles: number[],
    minSegmentLengthPercentage = 0.1,
    mergeTolerance = 0.00005,
    segmentScoreThreshold = 0.9,
) => {
    // calculate standard deviation
    const stdDevScore = getStdDev(scores);
    // calculate minimum segment length based on give percentage
    const minSegmentLength = Math.max(1, Math.floor(minSegmentLengthPercentage * scores.length));
    // initialize variables to track the best segment
    let bestStart = 0;
    let bestEnd = 0;
    let bestAvg = -Infinity;

    // Find initial best segment
    for (let start = 0; start <= scores.length - minSegmentLength; start++) {
        for (let end = start + minSegmentLength; end <= scores.length; end++) {
            const segment = scores.slice(start, end);
            const segmentAvg = getMean(segment);

            // drop tolerance based on the overall standard deviation
            const adaptiveTolerance = 0.1 * stdDevScore;

            if (Math.max(...segment) - Math.min(...segment) <= adaptiveTolerance) {
                if (segmentAvg > bestAvg) {
                    bestStart = start;
                    bestEnd = end;
                    bestAvg = segmentAvg;
                }
            }
        }
    }

    // Helper function to check if segments can be merged
    const canMerge = (currentAvg: number, segment: number[]): boolean => {
        const segmentMean = getMean(segment);
        return (
            Math.abs(currentAvg - segmentMean) <= mergeTolerance &&
            0.5 * (currentAvg + segmentMean) >= segmentScoreThreshold
        );
    };

    // Expand backward
    while (bestStart > 0 && canMerge(bestAvg, scores.slice(bestStart - 1, bestEnd))) {
        bestStart -= 1;
        bestAvg = getMean(scores.slice(bestStart, bestEnd));
    }

    // Expand forward
    while (bestEnd < scores.length && canMerge(bestAvg, scores.slice(bestStart, bestEnd + 1))) {
        bestEnd += 1;
        bestAvg = getMean(scores.slice(bestStart, bestEnd));
    }

    if (bestAvg >= segmentScoreThreshold) {
        return [camAngles[bestStart], camAngles[bestEnd - 1]];
    }
    return [];
};

const getHorizontalRatio = (verticalAperture: number, camAngles: number[]): number[] => {
    const a = verticalAperture / 2;
    return camAngles.map((b) => {
        const ratio = Math.sin(degToRad(a + b)) / Math.sin(degToRad(a - b));
        return ratio / (1 + ratio);
    });
};

const getHorizontalPixels = (
    totalMap: number[],
    verticalAperture: number,
    camAngles: number[],
    imageHeight: number,
): number[] | null => {
    const minValue = Math.min(...totalMap.filter((value) => !isNaN(value)));
    const maxValue = Math.max(...totalMap.filter((value) => !isNaN(value)));
    const normalizedData = totalMap.map((value) => (value - minValue) / (maxValue - minValue));
    const bestSegment = findBestSegment(normalizedData, camAngles);
    if (bestSegment.length === 0) {
        return null;
    }
    const vanishFactor = getHorizontalRatio(verticalAperture, bestSegment).map((value) => 1 - value);
    return vanishFactor.map((value) => Math.round(value * imageHeight));
};

export const getOverlayData = (
    vehicleWidth: number,
    hoodHeight: number,
    hoodDepth: number,
    installationHeight: number,
    horizontalAperture: number,
    verticalAperture: number,
) => {
    const [camAngles, scoreMaps, totalMap] = analyseInstallation(
        vehicleWidth,
        hoodHeight,
        hoodDepth,
        installationHeight,
        horizontalAperture,
        verticalAperture,
        objectDetectorInputHeight,
        imageHeight,
    );
    const horizontalPixels = getHorizontalPixels(totalMap, verticalAperture, camAngles, imageHeight);
    return { scoreMaps, totalMap, horizontalPixels };
};
