import { Box, Card, List, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
    YAxisProps,
} from 'recharts';
import { Payload, Props } from 'recharts/types/component/DefaultLegendContent';
import { Margin } from 'recharts/types/util/types';

import { TrackedListItemButton as ListItemButton } from '../../../../components/TrackedComponents';
import useProfile from '../../../../hooks/profile';
import palette from '../../../ColorPalette';
import { ANIMATION_LENGTH } from '../MetricsContent';

export const HOVERED_LINE_COLOR = palette.neutral[400];
export const INACTIVE_LINE_COLOR = palette.neutral[200];

interface CustomTooltipProps extends TooltipProps<number, string> {
    allFleetString: string;
    valueFormatter?: CallableFunction;
}

const CustomTooltip: React.FC<CustomTooltipProps> = (props: CustomTooltipProps) => {
    const { active, payload, label } = props;
    const { profile } = useProfile();

    if (active && payload && payload.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const sortedSubFleets = [...payload].sort((a, b) => a.name!.localeCompare(b.name!));
        if (sortedSubFleets.length > 0) {
            sortedSubFleets.unshift(
                sortedSubFleets.splice(
                    sortedSubFleets.findIndex((subFleet) => subFleet.dataKey === props.allFleetString),
                    1,
                )[0],
            );
        }

        return (
            <Card id="mouse-over-tooltip" sx={{ maxHeight: 250, overflowY: 'auto', p: 1, direction: 'ltr' }}>
                <Typography fontSize={12} sx={{ fontWeight: 'bold' }}>
                    {dayjs.unix(label).format(profile.shortDateFormat)}
                </Typography>
                {sortedSubFleets.map((p) => (
                    <Box key={label + p.name} sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: 2,
                            }}
                        >
                            <Box sx={{ width: 15, height: 15, mr: 0.5 }}>
                                <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="7.5" cy="7.5" r="7.5" fill={p.color} />
                                </svg>
                            </Box>
                            <Typography fontSize={12}>{p.name}</Typography>
                        </Box>
                        <Typography fontSize={12} sx={{ fontWeight: 'bold' }}>
                            {props.valueFormatter ? props.valueFormatter(p.value) : p.value}
                        </Typography>
                    </Box>
                ))}
            </Card>
        );
    }
    return null;
};

interface PayloadWithDataKey extends Payload {
    dataKey: string;
}

interface CustomLegendProps extends Props {
    selectedSubFleet: string;
    allFleetString: string;
    setHoveredSubFleet: CallableFunction;
    setSelectedSubFleet: CallableFunction;
    hiddenSubFleets: string[];
    setHiddenSubFleets: CallableFunction;
}

const CustomLegend: React.FC<CustomLegendProps> = (props: CustomLegendProps) => {
    const { profile } = useProfile();
    const hasSubFleet = !!profile.sub_fleet;
    const legendPayload: PayloadWithDataKey[] = props.payload as PayloadWithDataKey[];

    const sortedSubFleets = legendPayload
        .filter((item) => item.dataKey !== undefined)
        .sort((a, b) => a.dataKey.localeCompare(b.dataKey));
    if (sortedSubFleets.length > 0) {
        sortedSubFleets.unshift(
            sortedSubFleets.splice(
                sortedSubFleets.findIndex((subFleet) => subFleet.dataKey === props.allFleetString),
                1,
            )[0],
        );
    }

    return (
        <Box sx={{ height: '100%', maxHeight: 250, overflowY: 'auto', direction: 'ltr' }}>
            <List disablePadding>
                {sortedSubFleets.map((p) => {
                    const isHidden = props.hiddenSubFleets.includes(p.dataKey);
                    return (
                        <ListItemButton
                            id={`${p.dataKey}-legend-item`}
                            data-testid={`${p.dataKey}-legend-item`}
                            key={`${p.dataKey}-legend`}
                            disableGutters
                            onMouseEnter={() => props.setHoveredSubFleet(p.dataKey)}
                            onMouseLeave={() => props.setHoveredSubFleet('')}
                            onClick={() => {
                                if (props.selectedSubFleet !== p.dataKey) {
                                    if (isHidden) {
                                        props.setHiddenSubFleets(
                                            props.hiddenSubFleets.filter((item) => item !== p.dataKey),
                                        );
                                    } else if (!hasSubFleet) {
                                        props.setHiddenSubFleets([...props.hiddenSubFleets, p.dataKey]);
                                    }
                                }
                            }}
                            disableRipple={hasSubFleet}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                py: 0.25,
                                px: 1,
                                cursor: !hasSubFleet ? 'pointer' : 'default',
                                overflow: 'visible',
                            }}
                        >
                            <Box sx={{ width: 15, height: 15, mr: 0.5, overflow: 'visible' }}>
                                <svg
                                    viewBox="0 0 15 15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ overflow: 'visible' }}
                                >
                                    <circle
                                        cx="7.5"
                                        cy="7.5"
                                        r="7.5"
                                        stroke={p.color}
                                        strokeWidth={isHidden ? 1 : 0}
                                        fill={isHidden ? 'none' : p.color}
                                    />
                                </svg>
                            </Box>
                            <Typography fontSize={12} sx={{ color: isHidden ? INACTIVE_LINE_COLOR : undefined }}>
                                {p.dataKey}
                            </Typography>
                        </ListItemButton>
                    );
                })}
            </List>
        </Box>
    );
};

interface FleetTrendChartProps {
    data: Record<string, number | null>[];
    subFleets: string[];
    animate: boolean;
    selectedSubFleet: string;
    setSelectedSubFleet: CallableFunction;
    hoveredSubFleet: string;
    setHoveredSubFleet: CallableFunction;
    dateFormat: string;
    allFleetString: string;
    YAxisProps: YAxisProps;
    margin: Margin;
    valueFormatter?: CallableFunction;
    accentColor: string;
    hiddenSubFleets: string[];
    setHiddenSubFleets: CallableFunction;
}

const FleetTrendChart: React.FC<FleetTrendChartProps> = (props: FleetTrendChartProps) => {
    return (
        <ResponsiveContainer width="100%" height="100%" className="chart-container">
            <LineChart
                id="fleet-trend-chart"
                data={props.data}
                margin={props.margin}
                syncId="metrics"
                syncMethod="value"
            >
                <CartesianGrid />
                <XAxis
                    tick={{ fontSize: 12 }}
                    dataKey="name"
                    tickFormatter={(t) => dayjs.unix(t).format(props.dateFormat)}
                />
                <YAxis tick={{ fontSize: 12 }} {...props.YAxisProps} />
                <Tooltip
                    wrapperStyle={{ pointerEvents: 'auto' }}
                    content={
                        <CustomTooltip allFleetString={props.allFleetString} valueFormatter={props.valueFormatter} />
                    }
                />
                <Legend
                    content={
                        <CustomLegend
                            selectedSubFleet={props.selectedSubFleet}
                            allFleetString={props.allFleetString}
                            setHoveredSubFleet={props.setHoveredSubFleet}
                            setSelectedSubFleet={props.setSelectedSubFleet}
                            hiddenSubFleets={props.hiddenSubFleets}
                            setHiddenSubFleets={props.setHiddenSubFleets}
                        />
                    }
                    layout="vertical"
                    align="right"
                    verticalAlign="top"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    height={'100%' as any}
                />
                {props.subFleets.map((subFleet) => (
                    <Line
                        id={`${subFleet}-line`}
                        key={`${subFleet}-${props.selectedSubFleet}`}
                        dataKey={subFleet}
                        stroke={
                            subFleet === props.selectedSubFleet
                                ? props.accentColor
                                : subFleet === props.hoveredSubFleet && !props.hiddenSubFleets.includes(subFleet)
                                ? HOVERED_LINE_COLOR
                                : INACTIVE_LINE_COLOR
                        }
                        strokeWidth={2}
                        isAnimationActive={props.animate}
                        dot={{ id: undefined }}
                        animationDuration={ANIMATION_LENGTH}
                        hide={props.hiddenSubFleets.includes(subFleet)}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default FleetTrendChart;
