import { Box, Card, CircularProgress, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaggingData } from '../../../../backendsdk';
import { TabPanel, a11yProps } from '../../../../components/Tab';
import { TrackedTab as Tab } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import { armyTimeTo12HourLabel } from '../../../../utils/TimeFormatter';
import palette from '../../../ColorPalette';
import OverviewBarChart from './OverviewBarChart';

interface AccidentTimesCardProps {
    taggingData?: TaggingData;
    isLoading: boolean;
}

const AccidentTimesCard: React.FC<AccidentTimesCardProps> = ({ taggingData, isLoading }: AccidentTimesCardProps) => {
    const [timeTab, setTimeTab] = useState<number>(0);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const isMobile = useIsMobile();

    let daysOfWeekData;
    if (taggingData?.day_of_week !== undefined) {
        const orderedDaysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        daysOfWeekData = orderedDaysOfWeek.map((day, i) => {
            return {
                name: day,
                value: taggingData.day_of_week[i] || 0,
            };
        });
        if (profile.customer.settings.country == 'il') {
            // move Sunday to the beginning of the week
            const sunday = daysOfWeekData.pop();
            if (sunday) {
                daysOfWeekData.unshift(sunday);
            }
        }
    }

    let daysContent;
    if (isLoading) {
        daysContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (!daysOfWeekData || Object.keys(daysOfWeekData).length === 0) {
        daysContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.accident_overview.no_data')}</Typography>
            </Box>
        );
    } else {
        daysContent = !!daysOfWeekData ? <OverviewBarChart data={daysOfWeekData} translationPrefix="time.day" /> : null;
    }

    let hoursOfDayData;
    if (taggingData?.hour_of_day !== undefined) {
        const orderedHoursOfDay = Array.from(Array(24).keys());
        hoursOfDayData = orderedHoursOfDay.map((hour) => {
            return {
                name: profile.customer.settings.country == 'il' ? hour.toString() : armyTimeTo12HourLabel(hour),
                value: taggingData.hour_of_day[hour] || 0,
            };
        });
    }

    let hoursContent;
    if (isLoading) {
        hoursContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (!hoursOfDayData || Object.keys(hoursOfDayData).length === 0) {
        hoursContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.accident_overview.no_data')}</Typography>
            </Box>
        );
    } else {
        hoursContent = !!hoursOfDayData ? <OverviewBarChart data={hoursOfDayData} /> : null;
    }

    const handleTimeTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTimeTab(newValue);
    };

    return (
        <Card
            sx={{
                width: '100%',
                height: 450,
                ml: isMobile ? 0 : 2,
                mt: isMobile ? 2 : 0,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Tabs
                value={timeTab}
                onChange={handleTimeTabChange}
                TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                sx={{ width: '100%', borderBottom: `1px solid ${palette.gray[400]}` }}
            >
                <Tab
                    label={<Typography fontSize={14}>{t('content.accident_overview.day_of_week')}</Typography>}
                    {...a11yProps(0)}
                    id="tab-days"
                    data-testid={`tab-days`}
                    sx={{ width: '50%' }}
                />
                <Tab
                    label={<Typography fontSize={14}>{t('content.accident_overview.hour_of_day')}</Typography>}
                    {...a11yProps(1)}
                    id="tab-hours"
                    data-testid={`tab-hours`}
                    sx={{ width: '50%' }}
                />
            </Tabs>
            <TabPanel value={timeTab} index={0} sx={{ width: '100%', height: '100%', p: 2 }}>
                {daysContent}
            </TabPanel>
            <TabPanel value={timeTab} index={1} sx={{ width: '100%', height: '100%', p: 2 }}>
                {hoursContent}
            </TabPanel>
        </Card>
    );
};

export default AccidentTimesCard;
