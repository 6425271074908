import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import EventIcon from '@mui/icons-material/Event';
import GavelIcon from '@mui/icons-material/Gavel';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NumbersIcon from '@mui/icons-material/Numbers';
import PaymentsIcon from '@mui/icons-material/Payments';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import RuleIcon from '@mui/icons-material/Rule';
import VerifiedIcon from '@mui/icons-material/Verified';
import WorkIcon from '@mui/icons-material/Work';
import { SvgIcon, SxProps } from '@mui/material';
import React from 'react';

import { ExtendedAccident } from '../../../backendsdk';
import { EMPTY_DRIVER_DETAILS } from './AccidentDriverDetails';

export type AccidentPageVersion = 'v0' | 'v1';

export type AccidentFilterField = 'timestamp' | 'created_at';

export const SORT_FIELDS = ['accident_id', 'timestamp', 'created_at', 'notification'] as const;
export const SORT_ORDER = ['asc', 'desc'] as const;
export type SortField = typeof SORT_FIELDS[number];
export type SortOrder = typeof SORT_ORDER[number];

export const SORT_ICONS: Record<SortField | SortOrder, JSX.Element> = {
    accident_id: <NumbersIcon />,
    timestamp: <CarCrashIcon />,
    created_at: <EventIcon />,
    notification: <NotificationsIcon />,
    asc: <ArrowUpwardIcon />,
    desc: <ArrowDownwardIcon />,
};

interface VideoSearchIcon {
    sx?: SxProps;
}

export const VideoSearchIcon: React.FC<VideoSearchIcon> = ({ sx }) => (
    <SvgIcon sx={{ ...sx, right: '1px' }}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.125 1.88889V0H15V9.5638C14.4165 9.29983 13.7862 9.12115 13.125 9.04365V7.55556H11.25V9.09895C8.26574 9.6303 6 12.2381 6 15.375C6 15.9366 6.07262 16.4812 6.20897 17H3.75V15.1111H1.875V17H0V0H1.875V1.88889H3.75V0H11.25V1.88889H13.125ZM1.875 13.2222H3.75V11.3333H1.875V13.2222ZM1.875 9.44444H3.75V7.55556H1.875V9.44444ZM1.875 5.66667H3.75V3.77778H1.875V5.66667ZM11.25 5.66667H13.125V3.77778H11.25V5.66667Z"
        />
        <path d="M17.3234 19.0846H16.6709L16.4397 18.8616C17.249 17.9201 17.7363 16.6978 17.7363 15.3681C17.7363 12.4033 15.333 10 12.3681 10C9.40328 10 7 12.4033 7 15.3681C7 18.333 9.40328 20.7363 12.3681 20.7363C13.6978 20.7363 14.9201 20.249 15.8616 19.4397L16.0846 19.6709V20.3234L20.2139 24.4444L21.4444 23.2139L17.3234 19.0846ZM12.3681 19.0846C10.3117 19.0846 8.65174 17.4246 8.65174 15.3681C8.65174 13.3117 10.3117 11.6517 12.3681 11.6517C14.4246 11.6517 16.0846 13.3117 16.0846 15.3681C16.0846 17.4246 14.4246 19.0846 12.3681 19.0846Z" />
    </SvgIcon>
);

export const UnknownVehicleIcon: React.FC<VideoSearchIcon> = ({ sx }) => (
    <SvgIcon sx={{ ...sx, right: '1px' }}>
        <path
            d="M18.4885 14.1842C18.9114 13.4526 19.7244 13.0211 20.1969 12.3737C20.6968 11.6947 20.4166 10.4263 18.9993 10.4263C18.071 10.4263 17.615 11.1 17.4228 11.6579L16 11.0842C16.39 9.96316 17.4502 9 18.9938 9C20.2848 9 21.1692 9.56316 21.6196 10.2684C22.0042 10.8737 22.2294 12.0053 21.6361 12.8474C20.9769 13.7789 20.3452 14.0632 20.0046 14.6632C19.8673 14.9053 19.8123 15.0632 19.8123 15.8421H18.2248C18.2193 15.4316 18.1534 14.7632 18.4885 14.1842ZM20.098 17.9474C20.098 18.5263 19.6036 19 18.9993 19C18.3951 19 17.9007 18.5263 17.9007 17.9474C17.9007 17.3684 18.3951 16.8947 18.9993 16.8947C19.6036 16.8947 20.098 17.3684 20.098 17.9474Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 4H19L20.5335 6.04462C20.2823 6.01515 20.0268 6 19.7678 6C19.4851 6 19.2067 6.01804 18.9336 6.05303L18.5 5.5H16V7.20279C14.3463 8.38118 13.2678 10.3146 13.2678 12.5C13.2678 12.6682 13.2742 12.835 13.2867 13H8C8 14.66 6.66 16 5 16C3.34 16 2 14.66 2 13H0V2C0 0.9 0.9 0 2 0H16V4ZM3.5 13C3.5 13.83 4.17 14.5 5 14.5C5.83 14.5 6.5 13.83 6.5 13C6.5 12.17 5.83 11.5 5 11.5C4.17 11.5 3.5 12.17 3.5 13Z"
            fill="black"
        />
    </SvgIcon>
);

export const UnknownDriverIcon: React.FC<VideoSearchIcon> = ({ sx }) => (
    <SvgIcon sx={{ ...sx, right: '1px' }}>
        <path
            d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
            fill="black"
        />
        <path d="M10.35 14.01C7.62 13.91 2 15.27 2 18V20H11.54C9.07 17.24 10.31 14.11 10.35 14.01Z" fill="black" />
        <path
            d="M15.4885 18.1842C15.9114 17.4526 16.7244 17.0211 17.1969 16.3737C17.6968 15.6947 17.4166 14.4263 15.9993 14.4263C15.071 14.4263 14.615 15.1 14.4228 15.6579L13 15.0842C13.39 13.9632 14.4502 13 15.9938 13C17.2848 13 18.1692 13.5632 18.6196 14.2684C19.0042 14.8737 19.2294 16.0053 18.6361 16.8474C17.9769 17.7789 17.3452 18.0632 17.0046 18.6632C16.8673 18.9053 16.8123 19.0632 16.8123 19.8421H15.2248C15.2193 19.4316 15.1534 18.7632 15.4885 18.1842ZM17.098 21.9474C17.098 22.5263 16.6036 23 15.9993 23C15.3951 23 14.9007 22.5263 14.9007 21.9474C14.9007 21.3684 15.3951 20.8947 15.9993 20.8947C16.6036 20.8947 17.098 21.3684 17.098 21.9474Z"
            fill="black"
        />
    </SvgIcon>
);
export const TAG_LIST = [
    'Driver Form',
    'External Video',
    'Photo - Accident Scene',
    'Photo - Insured',
    'Photo - 3rd Party',
    'Document',
    'Other',
    'Evidence',
    'Appraiser Report',
    'Police Report',
    'Invoice',
];

export const STAGES = [
    'validation',
    'new',
    'video',
    'decision',
    'open',
    'progress',
    'self',
    'self-lawsuit',
    'done',
    'pending-info',
    'pending-3rd-party',
    'policy-claim',
    'demand-on-behalf',
    'self-solve',
    'lawsuit',
] as const;

export const IL_STAGES = [
    'new',
    'pending-info',
    'pending-3rd-party',
    'policy-claim',
    'demand-on-behalf',
    'self-solve',
    'lawsuit',
    'done',
] as const;

export const US_STAGES = [
    'validation',
    'new',
    'video',
    'decision',
    'open',
    'progress',
    'self',
    'self-lawsuit',
    'done',
] as const;

export type Stage = typeof STAGES[number];

export const STAGE_ICONS: Record<Stage | 'all', JSX.Element> = {
    all: <MinorCrashIcon />,
    validation: <NewReleasesIcon />,
    new: <VerifiedIcon />,
    video: <VideoSearchIcon />,
    decision: <RuleIcon />,
    open: <GavelIcon />,
    progress: <NextWeekIcon />,
    self: <PriceChangeIcon />,
    'self-lawsuit': <AccountBalanceIcon />,
    done: <BookmarkAddedIcon />,
    'pending-info': <LiveHelpIcon />,
    'pending-3rd-party': <PendingActionsIcon />,
    'policy-claim': <WorkIcon />,
    'demand-on-behalf': <PriceChangeIcon />,
    'self-solve': <PaymentsIcon />,
    lawsuit: <AccountBalanceIcon />,
};

export const ACCIDENT_TYPES = [
    'Not clear',
    'Rear end',
    'Right side',
    'Left side',
    'Blind-Spot',
    'Minor collision between motor vehicles',
    'Major collision between motor vehicles',
    'Pedestrian collision',
    'Lost load',
    'Rollover',
    'Jackknife',
    'Damage while stopping / parking',
    'Else',
];

export const EMPTY_ACCIDENT_DETAILS: ExtendedAccident = {
    accident_id: 0,
    customer: '',
    event: 0,
    license_plate: '',
    sub_fleet_name: '',
    location: '',
    driver_name: '',
    timestamp: 0,
    claim_number: '',
    policy_number: '',
    category: '',
    damage_category: '',
    description: '',
    internal_status: '',
    handling_status: '',
    internal_id: '',
    assignee: '',
    is_closed: false,
    stage: 'new',
    damage_list: [],
    artifact_list: [],
    witness_list: [],
    comment_list: [],
    at_fault: 'unknown',
    garage: '',
    appraiser: '',
    driver: EMPTY_DRIVER_DETAILS,
    police_report_number: '',
    norm_license_plate: '',
    created_at: 0,
    command_list: [],
    updated_at: 0,
    trip_id: '',
    missing_trip_reason: '',
};

export const IMAGE_FILE_EXTENTION = ['jpg', 'jpeg', 'bmp', 'gif', 'png'];
