import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    Box,
    Checkbox,
    Chip,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import { wellnessColors } from '../StyledElements';
import { ACTION_ITEMS } from './DeviceDetails/WellnessPanel';
import { EMPTY_FILTERS, FilterModel } from './FleetOverview';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const wellnessColorToPalette: Record<string, 'wellnessGray' | 'wellnessRed' | 'wellnessGreen'> = {
    [palette.wellness.gray]: 'wellnessGray',
    [palette.wellness.red]: 'wellnessRed',
    [palette.wellness.green]: 'wellnessGreen',
};

interface FilterComponentProps {
    filters: FilterModel;
    setFilters: CallableFunction;
    setIsFilterOpen: CallableFunction;
    subFleets: Array<string>;
    results: number;
}

const FilterComponent: React.FC<FilterComponentProps> = (props) => {
    const { filters, setFilters } = props;
    const { profile } = useProfile();
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                {t('content.fleet.filter.header')}
            </Typography>
            <Typography sx={{ fontWeight: 'bold', color: 'primary.main', mb: 1 }}>
                {t('content.fleet.filter.sub_fleet')}
            </Typography>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="sub-fleet-field"
                data-testid="sub-fleet-field"
                value={filters.subFleets}
                onChange={(_, newValue) => {
                    setFilters((prev: FilterModel) => ({ ...prev, subFleets: newValue }));
                }}
                size="small"
                options={props.subFleets.filter(Boolean)}
                renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ height: 40, paddingLeft: 0 }}>
                        <Checkbox
                            id={`${option}-checkbox`}
                            data-testid={`${option}-checkbox`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                    </li>
                )}
                renderInput={(params) => <TextField {...params} />}
                renderTags={(value: Array<string>, getTagProps: AutocompleteRenderGetTagProps) => {
                    return value.map((v, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                            id={`${v}-chip`}
                            size="small"
                            label={<Typography fontSize={12}>{v}</Typography>}
                            {...getTagProps({ index })}
                            sx={{ borderRadius: 1 }}
                        />
                    ));
                }}
                sx={{ mb: 2 }}
            />
            <Typography sx={{ fontWeight: 'bold', color: 'primary.main', mb: 1 }}>
                {t('content.fleet.filter.wellness')}
            </Typography>
            <Box
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2, mb: 2, width: '100%' }}
            >
                <Typography fontSize={14} sx={{ color: palette.primary, fontWeight: 500 }}>
                    {t('content.wellness.status')}
                </Typography>
                <Box sx={{ mb: 1 }}>
                    {wellnessColors.map((wellnessColor) => {
                        const isSelected = filters.wellnessStatus.includes(wellnessColor);
                        const WellnessIcon = wellnessColor === palette.wellness.red ? HeartBrokenIcon : FavoriteIcon;
                        return (
                            <Button
                                id={`wellness-${wellnessColor}-btn`}
                                data-testid={`wellness-${wellnessColor}-btn`}
                                key={wellnessColor}
                                variant={isSelected ? 'contained' : 'outlined'}
                                color={isSelected ? wellnessColorToPalette[wellnessColor] : 'primary'}
                                sx={{
                                    width: 40,
                                    height: 40,
                                    minWidth: 40,
                                    px: 0,
                                    mr: 1,
                                    borderRadius: '50%',
                                }}
                                onClick={() => {
                                    setFilters((prev: FilterModel) => ({
                                        ...prev,
                                        wellnessStatus: prev.wellnessStatus.includes(wellnessColor)
                                            ? prev.wellnessStatus.filter((w) => w !== wellnessColor)
                                            : [...prev.wellnessStatus, wellnessColor],
                                    }));
                                }}
                            >
                                <WellnessIcon
                                    fontSize="small"
                                    sx={{ color: isSelected ? palette.bgColor : wellnessColor }}
                                />
                            </Button>
                        );
                    })}
                </Box>
                <Typography fontSize={14} sx={{ color: palette.primary, fontWeight: 500 }}>
                    {t('content.wellness.action_item')}
                </Typography>
                <Autocomplete
                    fullWidth
                    multiple
                    disableCloseOnSelect
                    id="action-item-filter"
                    data-testid="action-item-filter"
                    size="small"
                    options={ACTION_ITEMS}
                    value={filters.requiredAction}
                    onChange={(_, newValue) => {
                        setFilters((prev: FilterModel) => ({ ...prev, requiredAction: newValue }));
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ paddingLeft: 0 }}>
                            <Checkbox
                                id={`${option}-checkbox`}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {t(option)}
                        </li>
                    )}
                    // limits the rendered tags to one chip
                    renderTags={(value: Array<string>, getTagProps: AutocompleteRenderGetTagProps) => {
                        return (
                            <>
                                <Chip
                                    size="small"
                                    label={<Typography fontSize={12}>{t(value[0])}</Typography>}
                                    {...getTagProps({ index: 0 })}
                                    sx={{ borderRadius: 1 }}
                                    onDelete={undefined}
                                />
                                {value.length > 1 ? (
                                    <Typography variant="body2">{`+${value.length - 1}`}</Typography>
                                ) : null}
                            </>
                        );
                    }}
                    sx={{ '& .MuiInputBase-root': { flexWrap: 'nowrap' } }}
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    {t('content.fleet.filter.current_status')}
                </Typography>
                <Tooltip
                    title={
                        <ul style={{ padding: '0 10px' }}>
                            {['online', 'offline', 'inactive'].map((status) => (
                                <li key={status} style={{ marginBottom: '5px' }}>
                                    {t(`content.fleet.filter.current_status_tooltip.${status}`, {
                                        days: profile.customer.settings.inactive_device_threshold,
                                    })}
                                </li>
                            ))}
                        </ul>
                    }
                >
                    <InfoOutlinedIcon color="primary" sx={{ fontSize: 16, ml: 0.3 }} />
                </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', mb: 2 }}>
                {['online', 'offline', 'inactive'].map((status) => (
                    <Button
                        id={`${status}-btn`}
                        data-testid={`${status}-btn`}
                        key={status}
                        size="small"
                        variant={filters.currentStatus.includes(status) ? 'contained' : 'outlined'}
                        onClick={() => {
                            setFilters((prev: FilterModel) => ({
                                ...prev,
                                currentStatus: prev.currentStatus.includes(status)
                                    ? prev.currentStatus.filter((s) => s !== status)
                                    : [...prev.currentStatus, status],
                            }));
                        }}
                        sx={{ mr: 1 }}
                    >
                        {t(`content.fleet.filter.${status}`)}
                    </Button>
                ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}>
                <Button
                    id="clear-btn"
                    size="small"
                    variant="outlined"
                    sx={{ mr: 1 }}
                    onClick={() => setFilters(EMPTY_FILTERS)}
                >
                    {t('content.fleet.filter.clear')}
                </Button>
                <Button id="apply-btn" size="small" variant="contained" onClick={() => props.setIsFilterOpen(false)}>
                    {t('content.fleet.filter.apply')}
                </Button>
            </Box>
            <Typography variant="body3" color="wellnessGray.main" textAlign="right">
                {t('content.fleet.filter.results', { count: props.results })}
            </Typography>
        </Box>
    );
};

export default FilterComponent;
