import UploadFileIcon from '@mui/icons-material/UploadFile';
import { CircularProgress, List, ListItem, ListItemIcon, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BarComponent from '../../components/StatusBar';
import { TrackedButton as Button } from '../../components/TrackedComponents';
import useApi from '../../hooks/api';
import palette from '../ColorPalette';

const validateFilePath = (path: string): boolean => {
    const levels = path.split('/');
    if (levels.length === 2) {
        return levels[1] === 'edmond.apk';
    } else if (levels.length > 2) {
        return levels[1] === 'configuration' && levels[levels.length - 1].endsWith('.json');
    }
    return false;
};

interface UpdateFileProps {
    deviceIds: number[];
    handleShowFileUpdate: CallableFunction;
}

type ExtendedFile = File & { webkitRelativePath?: string };

export const UpdateFile: React.FC<UpdateFileProps> = (props: UpdateFileProps) => {
    const [uploading, setUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<[number, number]>([0, 0]);
    const [error, setError] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [selectedFiles, setSelectedFiles] = useState<Array<ExtendedFile>>([]);
    const { t } = useTranslation();
    const { api } = useApi();
    const uploadComplete = progress[1] > 0 && progress[0] == progress[1];

    useEffect(() => {
        if (selectedFiles.length > 0) {
            const firstFile = selectedFiles[0];
            setName(() => (firstFile.webkitRelativePath ? firstFile.webkitRelativePath.split('/')[0] : firstFile.name));
        }
    }, [selectedFiles]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files as FileList);
            setSelectedFiles(files);
        }
    };

    const resetState = () => {
        setUploading(false);
        setProgress([0, 0]);
        setError('');
        setName('');
        setSelectedFiles([]);
    };

    const uploadSingleFile = async (package_id: number, file: ExtendedFile): Promise<boolean> => {
        let fileRelativePath: string;
        let fileName: string;
        if (file.webkitRelativePath) {
            const parts = file.webkitRelativePath.split('/');
            parts.shift();
            fileRelativePath = parts.join('/');
            fileName = file.name;
        } else {
            fileRelativePath = name;
            fileName = name;
        }

        // request backend for s3 upload url
        const post_res = await api
            .apiV0PackagePackageIdPost({ packageId: package_id, fileData: { file_name: fileName } })
            .catch((err) => setError('Connection error. ' + err.toString()));
        if (!post_res) {
            return false;
        }

        const config = {
            headers: {
                'Content-Type': file.type,
            },
        };

        if (post_res.data.upload_url) {
            // upload file to s3
            const put_file_res = await axios
                .put(post_res.data.upload_url, file, config)
                .catch((err) => setError('failed to upload to s3. ' + err.toString()));
            if (!put_file_res) {
                return false;
            }
        }
        const data = {
            file_name: fileRelativePath,
            s3_path: post_res.data.s3_path,
        };

        // update backend with the uploaded file path
        const put_file_data_res = await api
            .apiV0PackagePackageIdPut({ packageId: package_id, fileData: data })
            .catch((err) => setError('File Upload Error. ' + err.toString()));
        if (!put_file_data_res) {
            return false;
        }

        return true;
    };

    const handleSubmit = async (event: React.MouseEvent) => {
        event.preventDefault();
        if (!name || !selectedFiles) {
            setError('Need to set file params');
            return;
        }

        setUploading(true);
        setProgress([0, selectedFiles.length]);

        const package_name = `update_${name}`;
        const post_res = await api
            .apiV0PackagePost({ packageName: { name: package_name } })
            .catch((err) => setError('Connection error. ' + err.toString()));
        if (!post_res) {
            return;
        }
        const package_id = post_res.data.id;

        if (package_id) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const res = await uploadSingleFile(package_id, selectedFiles[i]);
                if (!res) {
                    return;
                }
                setProgress([i + 1, selectedFiles.length]);
            }
        }

        // set package to devices
        await api
            .apiV0PackagePut({
                packageTrackerData: {
                    package_id: package_id,
                    device_ids: props.deviceIds.map((deviceId) => deviceId.toString()),
                },
            })
            .catch((err) => setError('File Upload Error. ' + err.toString()));
    };
    const fileInputProps = { mozdirectory: '', webkitdirectory: '' };

    return (
        <>
            {uploading ? (
                <>
                    <Typography color={!!error ? 'error' : undefined} sx={{ mt: 1, mb: 1.5 }}>
                        {!!error
                            ? error
                            : progress[1] > 0 && progress[0] == progress[1]
                            ? t('content.fleet.update.complete')
                            : t('content.fleet.update.in_progress')}
                    </Typography>
                    <BarComponent
                        items={[
                            {
                                color: !!error ? 'red' : palette.primary,
                                width: `${(100 * progress[0]) / progress[1]}%`,
                                description: `${progress[0]} ${t('content.fleet.update.files_uploaded')}`,
                            },
                        ]}
                    />
                </>
            ) : (
                <>
                    <Typography sx={{ mt: 1, mb: 1.5 }}>{`${t('content.fleet.update.selected_devices')}: ${
                        props.deviceIds.length
                    }`}</Typography>
                    <TextField
                        id="file-name-field"
                        size="small"
                        label={t('content.fleet.update.name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        name="name"
                        sx={{ mb: 1 }}
                    />
                    <Button id="btn-browse-files" size="small" variant="contained" component="label" sx={{ mr: 1 }}>
                        {t('content.fleet.update.file')}
                        <input
                            id="file-btn"
                            hidden
                            multiple
                            type="file"
                            onChange={handleFileChange}
                            {...fileInputProps}
                        />
                    </Button>
                </>
            )}
            {selectedFiles.length > 0 ? (
                <>
                    <List sx={{ width: '100%' }}>
                        {selectedFiles.map((file, idx) => {
                            const isValid = validateFilePath(file.webkitRelativePath);
                            return (
                                <ListItem
                                    key={file.webkitRelativePath}
                                    alignItems="center"
                                    sx={{
                                        borderTop: '1px solid',
                                        borderColor: 'bgColor.main',
                                        borderRadius: 1,
                                        pl: 0,
                                        pr: 1,
                                        backgroundColor: !isValid ? palette.red[200] : undefined,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignItems: 'center',
                                            minWidth: 30,
                                            width: 30,
                                            height: 24,
                                            ml: 0.5,
                                            flexShrink: 0,
                                        }}
                                    >
                                        {uploading && !error && idx == progress[0] ? (
                                            <CircularProgress size={18} thickness={5} sx={{ ml: 0.425 }} />
                                        ) : (
                                            <UploadFileIcon
                                                color={
                                                    !isValid
                                                        ? 'error'
                                                        : uploading && idx < progress[0]
                                                        ? 'primary'
                                                        : undefined
                                                }
                                            />
                                        )}
                                    </ListItemIcon>
                                    <Typography>{file.webkitRelativePath}</Typography>
                                </ListItem>
                            );
                        })}
                    </List>
                    {!uploading ? (
                        <Button
                            id="submit-btn"
                            variant="contained"
                            color="secondary"
                            size="small"
                            disabled={!(name && props.deviceIds.length)}
                            onClick={handleSubmit}
                        >
                            {t('content.fleet.update.submit')}
                        </Button>
                    ) : null}
                </>
            ) : null}
            {uploading && uploadComplete ? (
                <Button id="done-btn" variant="contained" size="small" color="primary" onClick={() => resetState()}>
                    {t('content.fleet.update.done')}
                </Button>
            ) : null}
            {!!error ? (
                <Button id="done-btn" variant="contained" size="small" color="primary" onClick={() => resetState()}>
                    {t('content.fleet.update.back')}
                </Button>
            ) : null}
        </>
    );
};

export default UpdateFile;
