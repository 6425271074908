import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import Filter9Icon from '@mui/icons-material/Filter9';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import { Box, Card, CardActions, CardContent, CardMedia, Grid, Skeleton, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { EventDetails, FleetDriver } from '../../../backendsdk';
import { TrackedLink as Link } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import { ScoreAvatar } from '../Metrics/SafetyWellness/DriverList';

const ICONS = [
    Filter1Icon,
    Filter2Icon,
    Filter3Icon,
    Filter4Icon,
    Filter5Icon,
    Filter6Icon,
    Filter7Icon,
    Filter8Icon,
    Filter9Icon,
    Filter9PlusIcon,
];
export const NUMBER_ICONS = ICONS.map((Icon, idx) => (
    <Icon key={`${idx}-icon`} fontSize="small" sx={{ color: palette.white }} />
));

export const CARD_WIDTH = 300;
export const CARD_HEIGHT = 330;
export const CARD_MARGIN = 5;
export const IMAGE_ASPECT_RATIO = 0.375;

interface CardFieldProps {
    name: string;
    value?: string | Array<string>;
    valueAlign?: 'left' | 'center' | 'right';
    containerStyle?: SxProps<Theme>;
    valueClassName?: string;
    valueStyle?: SxProps<Theme>;
    children?: React.ReactNode;
}

export const CardField: React.FC<CardFieldProps> = (props: CardFieldProps) => {
    const { t } = useTranslation();
    const values = Array.isArray(props.value) ? props.value : [props.value];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                ...props.containerStyle,
            }}
        >
            <Typography variant="label">{props.name}</Typography>
            {props.children}
            {values.map((v) => {
                v = v?.trim();
                return (
                    <Typography
                        key={`${props.name}-${v}`}
                        className={props.valueClassName}
                        data-testid={`${props.name}-value`}
                        align={props.valueAlign}
                        variant={values.length > 1 ? 'body3' : 'body2'}
                        sx={values.length > 1 ? { mb: 0.25, ...props.valueStyle } : { ...props.valueStyle }}
                    >
                        {!!v ? v : t('content.safety.unknown')}
                    </Typography>
                );
            })}
        </Box>
    );
};

interface CardScoreFieldProps {
    name: string;
    score: number;
    licensePlate: string;
    scoreType: 'safety' | 'wellness';
    inactive?: boolean;
    unknown?: boolean;
    setDefaultTab?: CallableFunction;
}

export const CardScoreField: React.FC<CardScoreFieldProps> = (props: CardScoreFieldProps) => {
    const history = useHistory();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mb: 0.25 }}>
            <Typography variant="label" sx={{ mb: 0.25 }}>
                {props.name}
            </Typography>
            <Box
                onClick={() => {
                    if (props.setDefaultTab) {
                        props.setDefaultTab(1);
                    }
                    history.push(
                        `/${props.scoreType === 'safety' ? 'vehicles' : 'wellness'}/${encodeURIComponent(
                            props.licensePlate,
                        )}`,
                    );
                }}
                sx={{ cursor: 'pointer' }}
            >
                <ScoreAvatar
                    score={Math.round(props.score)}
                    inactive={props.inactive}
                    unknown={props.unknown}
                    size={30}
                    fontSize={16}
                />
            </Box>
        </Box>
    );
};

export interface VehicleCardProps {
    timestamp: number;
    eventIds: number[];
    accidentEventIds: number[];
    driverName?: string;
    licensePlate: string;
    subFleet?: string;
    score: number;
    behaviors: Array<string>;
    mostRecentEvent: EventDetails | null;
    image?: string;
    setDefaultTab?: CallableFunction;
    inactive?: boolean;
    unknown?: boolean;
    driver?: FleetDriver;
}

const VehicleCard: React.FC<VehicleCardProps> = (props: VehicleCardProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { profile } = useProfile();
    let behaviors = props.behaviors;
    if (props.behaviors.length > 3) {
        behaviors = props.behaviors.slice(0, 2);
        behaviors.push(`+${props.behaviors.length - 2}`);
    }

    const icon = (
        <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
            {NUMBER_ICONS[Math.min(props.eventIds.length + props.accidentEventIds.length, 10) - 1]}
        </Box>
    );

    return (
        <Card
            sx={{
                width: CARD_WIDTH,
                height: CARD_HEIGHT,
                display: 'flex',
                flexDirection: 'column',
                m: `${CARD_MARGIN}px`,
            }}
        >
            <CardContent sx={{ p: 1, pb: 0 }}>
                <Grid container columns={2}>
                    <Grid item xs={1} sx={{ p: 0.5 }}>
                        <CardField
                            name={t('table.headers.driver_name')}
                            value={props.driverName}
                            containerStyle={{ mb: 1 }}
                        />
                        <CardField
                            name={t('table.headers.license_plate')}
                            value={props.licensePlate}
                            containerStyle={{ mb: 1 }}
                        />
                        <CardField name={t('table.headers.sub_fleet')} value={props.subFleet} />
                    </Grid>
                    <Grid item xs={1}>
                        <Card sx={{ backgroundColor: palette.bgColor, p: 0.5 }}>
                            <CardScoreField
                                name={t('content.safety.score')}
                                score={props.score}
                                licensePlate={props.licensePlate}
                                setDefaultTab={props.setDefaultTab}
                                inactive={props.inactive}
                                unknown={props.unknown}
                                scoreType="safety"
                            />
                            <CardField
                                name={t('content.safety.behaviors')}
                                value={
                                    behaviors.length > 0
                                        ? behaviors.map((behavior: string) => t(behavior))
                                        : t('content.safety.none')
                                }
                                containerStyle={{ mt: 1 }}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 'auto' }}>
                {!!props.mostRecentEvent ? (
                    <CardField
                        name={t('content.safety.most_recent')}
                        value={dayjs.unix(props.mostRecentEvent.timestamp).format(profile.dateTimeFormat)}
                        containerStyle={{ alignItems: 'center' }}
                        valueStyle={{ whiteSpace: 'nowrap' }}
                    />
                ) : null}
                <Box
                    onClick={() => {
                        if (!!props.mostRecentEvent) {
                            if (props.setDefaultTab) {
                                props.setDefaultTab(0);
                            }
                            history.push(`/vehicles/${encodeURIComponent(props.licensePlate)}`);
                        }
                    }}
                    sx={{ position: 'relative', cursor: !!props.mostRecentEvent ? 'pointer' : 'default' }}
                >
                    {icon}
                    {props.image !== undefined ? (
                        <CardMedia
                            component="img"
                            image={props.image}
                            sx={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
                        />
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={CARD_WIDTH}
                            height={CARD_WIDTH * IMAGE_ASPECT_RATIO}
                        />
                    )}
                </Box>
                <CardActions sx={{ display: 'flex', justifyContent: 'center', height: '32px', flexShrink: 0 }}>
                    {!!props.mostRecentEvent ? (
                        <Link
                            id={`view-events-${props.licensePlate}-btn`}
                            component="button"
                            onClick={() => {
                                if (props.setDefaultTab) {
                                    props.setDefaultTab(0);
                                }
                                history.push(`/vehicles/${encodeURIComponent(props.licensePlate)}`);
                            }}
                        >
                            {t('content.safety.view_events')}
                        </Link>
                    ) : (
                        <Link
                            id="no-events-link"
                            component="button"
                            disabled
                            sx={{ textDecoration: 'none', cursor: 'default' }}
                        >
                            {t('content.safety.no_events_card')}
                        </Link>
                    )}
                </CardActions>
            </Box>
        </Card>
    );
};

export default VehicleCard;
