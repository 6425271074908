import * as L from 'leaflet';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';

import { hideOverlappingLabels } from './MapIcons';

require('leaflet-fullscreen');

interface MapControlsProps {
    isClustered: boolean;
    setIsClustered: CallableFunction;
    bounds?: L.LatLngBounds;
    isGeofenceMode: boolean;
    isSelectedElement: boolean;
}

const MapControls: React.FC<MapControlsProps> = (props: MapControlsProps) => {
    const map = useMap();
    const { t } = useTranslation();

    useEffect(() => {
        if (!map) return;

        const controls: L.Control[] = [];

        if (!props.isGeofenceMode) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const fullscreenControl = new (L.Control as any).Fullscreen(true);
            map.addControl(fullscreenControl);
            controls.push(fullscreenControl);
        }

        if (!props.isSelectedElement) {
            const resetZoomControl = L.Control.extend({
                options: {
                    position: 'topright',
                },
                onAdd: (map: L.Map) => {
                    const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
                    const button = L.DomUtil.create('a', 'leaflet-buttons-control-button', container);
                    button.innerHTML =
                        '<svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" style="margin: 2px"><path d="m15 3 2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z"></path></svg>';
                    L.DomEvent.disableClickPropagation(button);
                    L.DomEvent.on(button, 'click', function () {
                        if (props.bounds) {
                            map.fitBounds(props.bounds);
                            hideOverlappingLabels();
                        }
                    });

                    container.title = t('content.fleet.map.reset_zoom');

                    return container;
                },
            });
            const control = new resetZoomControl();
            control.addTo(map);
            controls.push(control);
        }

        return () => {
            controls.forEach((control) => {
                map.removeControl(control);
            });
        };
    }, [map, props.isClustered, props.bounds, props.isSelectedElement]);
    return null;
};

export default MapControls;
