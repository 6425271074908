import DeleteIcon from '@mui/icons-material/Delete';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, CircularProgress, Tabs, Tooltip, Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import capitalize from 'lodash.capitalize';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HomeTerminal, HosCustomerData, HosDriver, HosDriverRequest } from '../../../../backendsdk';
import { TabPanel, a11yProps } from '../../../../components/Tab';
import {
    TrackedButton as Button,
    TrackedLink as Link,
    TrackedTab as Tab,
} from '../../../../components/TrackedComponents';
import { useAlert } from '../../../../hooks/alert';
import useApi from '../../../../hooks/api';
import { driversPageDefs } from '../../../../utils/Pages';
import { DEFAULT_TITLE } from '../../../Layout';
import ActionsMenu from '../../Users/ActionsMenu';
import RemoveModal from '../../Users/RemoveModal';
import AddTerminalModal from './AddTerminalModal';
import DriverModal from './DriverModal';
import DriversDataGrid from './DriversDataGrid';
import TerminalsDataGrid from './TerminalsDataGrid';

const DriversComponent: React.FC = () => {
    const [driverSelectionModel, setDriverSelectionModel] = useState<GridRowSelectionModel>([]);
    const [terminalSelectionModel, setTerminalSelectionModel] = useState<GridRowSelectionModel>([]);
    const [tab, setTab] = useState<number>(0);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const [selectedDriver, setSelectedDriver] = useState<HosDriver>();
    const [alertElement, setAlert] = useAlert();
    const { api } = useApi();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { data, isLoading, isError } = useQuery({
        queryKey: ['drivers'],
        queryFn: () => api.hosV0SettingsCustomerGet().then((res) => res.data),
    });

    const drivers = Object.fromEntries(
        (data?.drivers || []).map((driver) => {
            return [driver.driver_id, driver];
        }),
    );

    const terminals = Object.fromEntries(
        (data?.home_terminals || []).map((terminal) => {
            return [terminal.id, terminal];
        }),
    );

    const addDriverMutation = useMutation({
        mutationFn: (details: HosDriverRequest) =>
            api.hosV0SettingsDriverManagementPost({ hosDriverRequest: [details] }),
        onSuccess: (res) => {
            if (res.data?.length > 0) {
                queryClient.setQueryData<HosCustomerData>(['drivers'], (oldData) => {
                    if (oldData) {
                        return {
                            ...oldData,
                            drivers: [...oldData.drivers, res.data[0]],
                        };
                    }
                });
            }
            setEditModalOpen(false);
        },
        onError: (err: AxiosError<{ details: string }>) => {
            setAlert({
                message: `Failed to create new driver. ${capitalize(err.response?.data.details || '')}`,
                type: 'error',
                duration: 6000,
            });
        },
    });

    const updateDriverDetailsMutation = useMutation({
        mutationFn: (details: HosDriver) =>
            api.hosV0SettingsDriverManagementPatch({ hosDriverRequest: [details as HosDriverRequest] }),
        onMutate: async (details: HosDriver) => {
            await queryClient.cancelQueries(['drivers']);
            const previousData = queryClient.getQueryData<HosCustomerData>(['drivers']);
            queryClient.setQueryData<HosCustomerData>(['drivers'], (oldData) => {
                if (oldData) {
                    return {
                        ...oldData,
                        drivers: oldData.drivers.map((driver) => {
                            if (driver.driver_id === details.driver_id) {
                                return details;
                            }
                            return driver;
                        }),
                    };
                }
            });
            return { previousData };
        },
        onSuccess: () => setEditModalOpen(false),
        onError: (_err, _details, context) => {
            const previousData = context?.previousData as HosCustomerData;
            queryClient.setQueryData(['drivers'], previousData);
            setAlert({
                message: `Failed to save driver details`,
                type: 'error',
                duration: 6000,
            });
        },
    });

    const updateDriverActiveStateMutation = useMutation({
        mutationFn: (driversRequest: HosDriverRequest[]) =>
            api.hosV0SettingsDriverManagementPatch({ hosDriverRequest: driversRequest }),
        onMutate: async (driversRequest: HosDriverRequest[]) => {
            await queryClient.cancelQueries(['drivers']);
            const previousData = queryClient.getQueryData<HosCustomerData>(['drivers']);
            queryClient.setQueryData<HosCustomerData>(['drivers'], (oldData) => {
                if (oldData) {
                    return {
                        ...oldData,
                        drivers: oldData.drivers.map((driver) => {
                            const driverToUpdate = driversRequest.find((d) => d.driver_id === driver.driver_id);
                            if (driverToUpdate) {
                                return { ...driver, is_active: driverToUpdate.is_active };
                            }
                            return driver;
                        }),
                    };
                }
            });
            return { previousData, newActiveState: driversRequest[0].is_active };
        },
        onError: (_err, _details, context) => {
            const previousData = context?.previousData as HosCustomerData;
            queryClient.setQueryData(['drivers'], previousData);
            setAlert({
                message: `Failed to ${context?.newActiveState ? 'activate' : 'deactivate'} selected drivers`,
                type: 'error',
                duration: 6000,
            });
        },
    });

    const removeDriverMutation = useMutation({
        mutationFn: (driversRequest: HosDriverRequest[]) =>
            api.hosV0SettingsDriverManagementDelete({ hosDriverRequest: driversRequest }),
        onMutate: async (driversRequest: HosDriverRequest[]) => {
            await queryClient.cancelQueries(['drivers']);
            const previousData = queryClient.getQueryData<HosCustomerData>(['drivers']);
            queryClient.setQueryData<HosCustomerData>(['drivers'], (oldData) => {
                if (oldData) {
                    return {
                        ...oldData,
                        drivers: oldData.drivers.filter(
                            (driver) => !driversRequest.find((d) => d.driver_id === driver.driver_id),
                        ),
                    };
                }
            });
            setRemoveModalOpen(false);
            return { previousData };
        },
        onError: (_err, _details, context) => {
            const previousData = context?.previousData as HosCustomerData;
            queryClient.setQueryData(['drivers'], previousData);
            setAlert({
                message: 'Failed to remove selected drivers',
                type: 'error',
                duration: 6000,
            });
        },
    });

    const addHomeTerminalMutation = useMutation({
        mutationFn: (details: HomeTerminal) =>
            api.hosV0SettingsTerminalManagementPost({ homeTerminal: [{ ...details, day_start_time: '00:00' }] }),
        onSuccess: (res) => {
            if (res.data?.length > 0) {
                queryClient.setQueryData<HosCustomerData>(['drivers'], (oldData) => {
                    if (oldData) {
                        return {
                            ...oldData,
                            home_terminals: [...oldData.home_terminals, res.data[0]],
                        };
                    }
                });
            }
            setEditModalOpen(false);
        },
        onError: () => {
            setAlert({
                message: `Failed to create new terminal`,
                type: 'error',
                duration: 6000,
            });
        },
    });

    const updateHomeTerminalDetailsMutation = useMutation({
        mutationFn: (details: HomeTerminal) => api.hosV0SettingsTerminalManagementPatch({ homeTerminal: [details] }),
        onMutate: async (details: HomeTerminal) => {
            await queryClient.cancelQueries(['drivers']);
            const previousData = queryClient.getQueryData<HosCustomerData>(['drivers']);
            queryClient.setQueryData<HosCustomerData>(['drivers'], (oldData) => {
                if (oldData) {
                    return {
                        ...oldData,
                        home_terminals: oldData.home_terminals.map((terminal) => {
                            if (terminal.id === details.id) {
                                return details;
                            }
                            return terminal;
                        }),
                    };
                }
            });
            return { previousData };
        },
        onSuccess: () => setEditModalOpen(false),
        onError: (_err, _details, context) => {
            const previousData = context?.previousData as HosCustomerData;
            queryClient.setQueryData(['drivers'], previousData);
            setAlert({ message: 'Failed to update home terminal', type: 'error', duration: 6000 });
        },
    });

    const removeHomeTerminalMutation = useMutation({
        mutationFn: (terminalsRequest: HomeTerminal[]) =>
            api.hosV0SettingsTerminalManagementDelete({ homeTerminal: terminalsRequest }),
        onMutate: async (terminalsRequest: HomeTerminal[]) => {
            await queryClient.cancelQueries(['drivers']);
            const previousData = queryClient.getQueryData<HosCustomerData>(['drivers']);
            const ids = terminalsRequest.map((terminal) => terminal.id);
            queryClient.setQueryData<HosCustomerData>(['drivers'], (oldData) => {
                if (oldData) {
                    return {
                        ...oldData,
                        home_terminals: oldData.home_terminals.filter((terminal) => !ids.includes(terminal.id)),
                    };
                }
            });
            setRemoveModalOpen(false);
            return { previousData };
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (err: any, _details, context) => {
            const errorData = err.response?.data;
            const previousData = context?.previousData as HosCustomerData;
            queryClient.setQueryData(['drivers'], previousData);
            setAlert({
                message: errorData && !!errorData['error'] ? errorData['error'] : 'Failed to remove selected terminals',
                type: 'error',
                duration: 6000,
            });
        },
    });

    const handleAddDriver = (details: HosDriverRequest) => {
        addDriverMutation.mutate(details);
    };

    const handleUpdateDriver = (details: HosDriver) => {
        updateDriverDetailsMutation.mutate(details);
    };

    const handleSetActiveState = (newActiveState: boolean) => {
        const updatedDriverActiveStates: HosDriverRequest[] = driverSelectionModel.map((driverId) => {
            return { driver_id: driverId as number, is_active: newActiveState };
        });
        updateDriverActiveStateMutation.mutate(updatedDriverActiveStates);
    };

    const handleInvite = () => {
        const invitedDrivers = driverSelectionModel.map((id) => {
            return { driver_id: id as number, send_invitation: true };
        });
        api.hosV0SettingsDriverManagementPatch({ hosDriverRequest: invitedDrivers })
            .then(() => {
                setAlert({
                    message: 'Invitations sent successfully',
                    type: 'success',
                    duration: 6000,
                });
            })
            .catch(() => {
                setAlert({
                    message: 'Failed to send invitations',
                    type: 'error',
                    duration: 6000,
                });
            });
    };

    const handleRemoveDrivers = () => {
        const driversToRemove = driverSelectionModel.map((id) => {
            return { driver_id: id as number };
        });
        removeDriverMutation.mutate(driversToRemove);
        setRemoveModalOpen(false);
    };

    const handleAddTerminal = (details: HomeTerminal) => {
        addHomeTerminalMutation.mutate(details);
    };

    const handleUpdateTerminal = (details: HomeTerminal) => {
        updateHomeTerminalDetailsMutation.mutate(details);
    };

    const handleRemoveTerminals = () => {
        const terminalsToRemove = terminalSelectionModel.map((id) => terminals[id]);
        removeHomeTerminalMutation.mutate(terminalsToRemove);
    };

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${driversPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        if (!editModalOpen) {
            setSelectedDriver(undefined);
        }
    }, [editModalOpen]);

    useEffect(() => {
        if (!isLoading && data?.home_terminals.length === 0) setTab(1);
    }, [isLoading]);

    if (isLoading) {
        return <CircularProgress />;
    } else if (isError) {
        return (
            <Typography variant="overline">
                Error fetching drivers and terminals.{' '}
                <Link
                    id="try-again-link"
                    component="button"
                    variant="overline"
                    onClick={() => {
                        queryClient.refetchQueries(['drivers']);
                    }}
                >
                    Try again.
                </Link>
            </Typography>
        );
    }

    const addDriverButton = (
        <Button
            id="add-driver-btn"
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<PersonAddIcon />}
            disabled={Object.values(terminals).length === 0}
            onClick={() => setEditModalOpen(true)}
            sx={{ mr: 1 }}
        >
            Add Driver
        </Button>
    );

    let toolbar;
    if (tab === 0) {
        toolbar = (
            <Box sx={{ display: 'flex', mb: 1 }}>
                {editModalOpen ? (
                    <DriverModal
                        driver={selectedDriver}
                        terminals={Object.values(terminals)}
                        onClose={() => setEditModalOpen(false)}
                        onAddDriver={handleAddDriver}
                        onUpdateDriver={handleUpdateDriver}
                    />
                ) : null}
                {removeModalOpen ? (
                    <RemoveModal
                        header="Remove Drivers"
                        body="This action will permanently remove the selected drivers."
                        onRemove={handleRemoveDrivers}
                        onClose={() => setRemoveModalOpen(false)}
                    />
                ) : null}
                {Object.values(terminals).length > 0 ? (
                    addDriverButton
                ) : (
                    <Tooltip title="Add a terminal to add a driver" arrow>
                        <Box>{addDriverButton}</Box>
                    </Tooltip>
                )}
                <ActionsMenu
                    disabled={driverSelectionModel.length === 0}
                    onSetActiveState={handleSetActiveState}
                    onInvite={handleInvite}
                    onRemove={() => setRemoveModalOpen(true)}
                />
            </Box>
        );
    } else {
        toolbar = (
            <Box sx={{ display: 'flex', mb: 1 }}>
                {editModalOpen ? (
                    <AddTerminalModal onClose={() => setEditModalOpen(false)} onSave={handleAddTerminal} />
                ) : null}
                {removeModalOpen ? (
                    <RemoveModal
                        header="Remove Terminals"
                        body="This action will permanently remove the selected terminals."
                        onRemove={handleRemoveTerminals}
                        onClose={() => setRemoveModalOpen(false)}
                    />
                ) : null}
                <Button
                    id="add-terminal-btn"
                    size="small"
                    variant="contained"
                    color="secondary"
                    startIcon={<DomainAddIcon />}
                    onClick={() => setEditModalOpen(true)}
                    sx={{ mr: 1 }}
                >
                    Add Terminal
                </Button>
                <Button
                    id="remove-terminal-btn"
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    disabled={terminalSelectionModel.length === 0}
                    onClick={() => setRemoveModalOpen(true)}
                >
                    Remove
                </Button>
            </Box>
        );
    }

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '1200px', height: '100%', pb: 2 }}
        >
            {alertElement}
            <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                >
                    <Tab label="Drivers" {...a11yProps(0)} id="drivers-tab" data-testid={`tab-0`} />
                    <Tab label="Terminals" {...a11yProps(1)} id="terminals-tab" data-testid={`tab-1`} />
                </Tabs>
                {toolbar}
            </Box>
            <TabPanel value={tab} index={0} sx={{ height: '100%' }}>
                {Object.values(drivers).length > 0 ? (
                    <DriversDataGrid
                        drivers={drivers}
                        terminals={terminals}
                        setSelectedDriver={setSelectedDriver}
                        selectionModel={driverSelectionModel}
                        setSelectionModel={setDriverSelectionModel}
                        setEditModalOpen={setEditModalOpen}
                    />
                ) : (
                    <Typography variant="overline">No existing drivers</Typography>
                )}
            </TabPanel>
            <TabPanel value={tab} index={1} sx={{ height: '100%' }}>
                {Object.keys(terminals).length > 0 ? (
                    <TerminalsDataGrid
                        terminals={terminals}
                        onUpdate={handleUpdateTerminal}
                        selectionModel={terminalSelectionModel}
                        setSelectionModel={setTerminalSelectionModel}
                        setAlert={setAlert}
                    />
                ) : (
                    <Typography variant="overline">No existing terminals</Typography>
                )}
            </TabPanel>
        </Box>
    );
};

export default DriversComponent;
