import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import palette from '../../../ColorPalette';
import { CustomTooltip } from './AccidentCausesChart';

interface OverviewBarChartProps {
    data: { name: string; value: number }[];
    translationPrefix?: string;
}

const OverviewBarChart: React.FC<OverviewBarChartProps> = (props: OverviewBarChartProps) => {
    const { t } = useTranslation();

    return (
        <Box data-testid="time-chart-container" sx={{ width: '100%', height: '100%' }}>
            <ResponsiveContainer width="100%" height="100%" className="chart-container">
                <BarChart id="bar-chart" data={props.data} margin={{ left: -30 }}>
                    <XAxis
                        interval={0}
                        tick={{ fontSize: 12 }}
                        dataKey="name"
                        tickFormatter={
                            props.translationPrefix
                                ? (value) => t(`${props.translationPrefix}.${value}`, value)
                                : undefined
                        }
                    />
                    <YAxis allowDecimals={false} tick={{ fontSize: 12 }} />
                    <Tooltip
                        cursor={{ fill: palette.neutral[100] }}
                        content={
                            <CustomTooltip
                                translationPrefix={props.translationPrefix}
                                units="content.accident_overview.accidents_label"
                            />
                        }
                    />
                    <CartesianGrid />
                    <Bar dataKey="value" fill={palette.red[300]} className="time-bar" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default OverviewBarChart;
