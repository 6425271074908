import TouchAppIcon from '@mui/icons-material/TouchApp';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InstallationCommandResult, InstallationCommandResultStatusEnum, NewDevice } from '../../../../../backendsdk';
import { TrackedButton as Button } from '../../../../../components/TrackedComponents';
import useApi from '../../../../../hooks/api';
import palette from '../../../../ColorPalette';
import FixedContainer from '../ui/FixedContainer';
import { imageHeight } from '../utils/analysis';

export const INSTRUCTIONS = {
    ic: ['position', 'occlusion', 'backlight', 'lock'],
    ff: ['position'],
};

export const InCabinOverlay: React.FC = () => {
    return (
        <Box
            sx={{
                top: '15%',
                bottom: '15%',
                right: '15%',
                left: '55%',
                border: '1px solid white',
                position: 'absolute',
                boxShadow: '0px 0px 5px 1px #fff, inset 0px 0px 5px 1px #fff',
            }}
        />
    );
};

interface FrontFacingOverlayProps {
    horizontalPixel?: number;
}

export const FrontFacingOverlay: React.FC<FrontFacingOverlayProps> = (props) => {
    if (props.horizontalPixel === undefined) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10,
                    backgroundColor: palette.white,
                    opacity: 0.5,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    return (
        <Box
            sx={{
                width: '100%',
                height: '0px',
                borderTop: '1px solid white',
                borderBottom: '1px solid white',
                position: 'absolute',
                top: `${(props.horizontalPixel / imageHeight) * 100}%`,
                boxShadow: '0px 0px 5px 1px #fff, inset 0px 0px 5px 1px #fff',
            }}
        />
    );
};

interface DeviceFeedbackProps {
    horizontalPixel?: number;
    newDevice: NewDevice;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onInstall: () => Promise<AxiosResponse<void, any>>;
    onBack: () => void;
    onNext: () => void;
    dashboardLink: React.ReactNode;
}

const DeviceFeedback: React.FC<DeviceFeedbackProps> = (props) => {
    const [commandResult, setCommandResult] = useState<InstallationCommandResult>();
    const [isError, setIsError] = useState<boolean>(false);
    const [imageCounter, setImageCounter] = useState<number>(0);
    const [keyCounter, setKeyCounter] = useState<number>(0);
    const [isLoadingInstall, setIsLoadingInstall] = useState<boolean>(false);
    const [isErrorInstall, setIsErrorInstall] = useState<boolean>(false);
    const { t } = useTranslation();
    const { installApi } = useApi();

    const getCommandStatus = () => {
        installApi
            .installConnectDeviceIdPost({ deviceId: props.newDevice.imei })
            .then((res) => {
                setImageCounter(0);
                setKeyCounter((prev) => prev + 1);
                setCommandResult(res.data);
            })
            .catch(() => {
                setIsError(true);
            });
    };

    const handleInstall = () => {
        setIsLoadingInstall(true);
        setIsErrorInstall(false);
        props
            .onInstall()
            .then(() => {
                props.onNext();
            })
            .catch(() => {
                setIsErrorInstall(true);
                setIsLoadingInstall(false);
            });
    };

    useEffect(getCommandStatus, []);

    useEffect(() => {
        if (
            commandResult?.status !== InstallationCommandResultStatusEnum.Failure &&
            commandResult?.status !== InstallationCommandResultStatusEnum.NoSupport
        ) {
            if (imageCounter >= 3) {
                // wait for progress bar animation to end
                const timerId = setTimeout(() => {
                    getCommandStatus();
                }, 400);
                return () => clearTimeout(timerId);
            } else {
                const timerId = setTimeout(() => {
                    setImageCounter((prev) => prev + 1);
                }, 1000);
                return () => clearTimeout(timerId);
            }
        }
    }, [imageCounter, commandResult?.status]);

    useEffect(() => {
        if (commandResult?.status === InstallationCommandResultStatusEnum.NoSupport) {
            handleInstall();
        }
    }, [commandResult]);

    if (isLoadingInstall || isErrorInstall) {
        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                        {t(`setup_tool.installation.header`)}
                    </Typography>
                    <FixedContainer>
                        {isLoadingInstall ? (
                            <>
                                <CircularProgress />
                                <Typography textAlign="center" sx={{ fontWeight: 500, mt: 1 }}>
                                    {t(`setup_tool.installation.params`)}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <WarningIcon color="error" sx={{ fontSize: 24, mb: 0.5 }} />
                                <Typography variant="overline" color="error" sx={{ lineHeight: 1, mb: 2 }}>
                                    {t('setup_tool.installation.error')}
                                </Typography>
                                <Button id="btn-retry" variant="contained" onClick={handleInstall}>
                                    {t('setup_tool.installation.retry')}
                                </Button>
                            </>
                        )}
                    </FixedContainer>
                </Box>
            </Box>
        );
    }

    let content;
    const isStreaming = commandResult?.status === InstallationCommandResultStatusEnum.Streaming;
    if (isError || commandResult?.status === InstallationCommandResultStatusEnum.Failure) {
        content = (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography>{t(`setup_tool.device_feedback.error`)}</Typography>
                <Typography>{t(`setup_tool.troubleshoot.contact`)}</Typography>
            </Box>
        );
    } else if (commandResult?.status === InstallationCommandResultStatusEnum.Started) {
        content = (
            <FixedContainer>
                <TouchAppIcon sx={{ color: palette.primary, fontSize: 48, mb: 1 }} />
                <Box sx={{ width: '175px' }}>
                    <Typography id="press-driver-button" textAlign="center" sx={{ fontWeight: 500 }}>
                        {t(`setup_tool.device_feedback.press_driver_button`)}
                    </Typography>
                </Box>
            </FixedContainer>
        );
    } else if (isStreaming) {
        content = (
            <>
                {['ic', 'ff'].map((imageKey, idx) => (
                    <Box
                        key={imageKey}
                        sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                        <LinearProgress
                            key={`${keyCounter}-progress`}
                            variant="determinate"
                            value={(1 - imageCounter / 3) * 100}
                            sx={{ width: '100%', flexShrink: 0 }}
                        />
                        <FixedContainer style={{ position: 'relative' }}>
                            <img
                                id={`${imageKey}-image`}
                                data-testid={`${imageKey}-image`}
                                src={idx === 0 ? commandResult.ICImageUrl : commandResult.FFImageUrl}
                                width="100%"
                                height="100%"
                                style={{ display: 'block' }}
                            />
                            {idx === 0 ? (
                                <InCabinOverlay />
                            ) : (
                                <FrontFacingOverlay horizontalPixel={props.horizontalPixel} />
                            )}
                        </FixedContainer>
                        <ul style={{ width: '100%', paddingLeft: '20px' }}>
                            {INSTRUCTIONS[imageKey as 'ic' | 'ff'].map((item) => (
                                <li key={item} id={`li-${item}`} data-testid={`li-${item}`}>
                                    <Typography>{t(`setup_tool.device_feedback.${imageKey}_${item}`)}</Typography>
                                </li>
                            ))}
                        </ul>
                    </Box>
                ))}
            </>
        );
    } else {
        content = (
            <FixedContainer>
                <CircularProgress id="pending" data-testid="pending" />
            </FixedContainer>
        );
    }

    return (
        <Box sx={{ width: '100%', height: isStreaming ? undefined : '100%', display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    width: '100%',
                    height: isStreaming ? undefined : '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography id="device-feedback-header" variant="h6" textAlign="center" sx={{ mb: 2 }}>
                    {t(`setup_tool.device_feedback.header`)}
                </Typography>
                {content}
            </Box>
            <Box sx={{ width: '100%', display: 'flex', mt: 1 }}>
                <Box sx={{ flex: 1, mr: 1 }}>
                    <Button
                        id="btn-back"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.onBack();
                        }}
                    >
                        {t('setup_tool.back')}
                    </Button>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Button
                        id="btn-next"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            handleInstall();
                        }}
                        disabled={commandResult?.status !== InstallationCommandResultStatusEnum.Streaming}
                    >
                        {t('setup_tool.next')}
                    </Button>
                </Box>
            </Box>
            {props.dashboardLink}
        </Box>
    );
};

export default DeviceFeedback;
