import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    Box,
    Card,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    InputLabel,
    Paper,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedCustomerDataAllOfUsers, ShortCoachSession } from '../../../backendsdk';
import { ResponsiveDatePicker } from '../../../components/MuiDatePicker';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useDrivers from '../../../hooks/drivers';
import useProfile from '../../../hooks/profile';
import { CustomRadio } from './Agenda/MetricsForm';
import PhoneNumbersForm from './PhoneNumbersForm';
import { getUserAvatar } from './SessionDetails';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface CoachingAtScaleModalProps {
    autoSessions: ShortCoachSession[];
    sentSessions: ShortCoachSession[];
    onClose: CallableFunction;
    setSessions: CallableFunction;
    setTab: CallableFunction;
    setAlert: CallableFunction;
    users: ExtendedCustomerDataAllOfUsers[];
}

const DRIVERS_TO_COACH = ['all', 'sub_fleet', 'individual'] as const;
type DriversToCoach = typeof DRIVERS_TO_COACH[number];

const DEFAULT_BOTTOM_DRIVERS_NUM_STR = '10';

const CoachingAtScaleModal: React.FC<CoachingAtScaleModalProps> = (props: CoachingAtScaleModalProps) => {
    const [driversToCoach, setDriversToCoach] = useState<DriversToCoach>('all');
    const { profile } = useProfile();
    const [individualSessions, setIndividualSessions] = useState<number[]>([]);
    const [additionalCriteria, setAdditionalCriteria] = useState<string[]>([]);
    const [lastDate, setLastDate] = useState<Dayjs>(dayjs().startOf('day').subtract(7, 'day'));
    const sentLicensePlates = props.sentSessions.filter((s) => s.auto_generated).map((s) => s.license_plate);
    const eligibleSessions = props.autoSessions.filter((session) => !sentLicensePlates.includes(session.license_plate));
    const subFleets = Array.from(new Set(eligibleSessions.map((s) => s.sub_fleet_name))).filter(Boolean);
    const [subFleet, setSubFleet] = useState<string>(subFleets[0] || '');
    const [bottomDriversNumStr, setBottomDriversNumStr] = useState<string>(DEFAULT_BOTTOM_DRIVERS_NUM_STR);
    const [phoneNumbersFormOpen, setPhoneNumbersFormOpen] = useState<boolean>(false);
    const [isBlurred, setIsBlurred] = useState<boolean>(false);
    const [selectedAssignee, setSelectedAssignee] = useState<string>('unassigned');
    const { drivers } = useDrivers(1);
    const { t, i18n } = useTranslation();

    const getDriverDetails = (session: ShortCoachSession) => {
        const driverName = session.driver_name !== '' ? session.driver_name : t('content.fleet.unknown_driver');
        return [driverName, session.license_plate];
    };
    const sessionsById: Record<string, ShortCoachSession> = Object.fromEntries(
        eligibleSessions.map((s) => [s.session_id, s]),
    );

    let filteredSessions = eligibleSessions;
    if (driversToCoach === 'sub_fleet') {
        filteredSessions = filteredSessions.filter((session) => session.sub_fleet_name === subFleet);
    } else if (driversToCoach === 'individual') {
        filteredSessions = filteredSessions.filter((session) => individualSessions.includes(session.session_id));
    }

    if (driversToCoach !== 'individual') {
        if (additionalCriteria.includes('last_completed')) {
            filteredSessions = filteredSessions.filter(
                (session) =>
                    session.last_completed_session === null || session.last_completed_session < lastDate.unix(),
            );
        }
        if (additionalCriteria.includes('bottom_drivers')) {
            const bottomDriversNum = parseInt(bottomDriversNumStr);
            if (!isNaN(bottomDriversNum) && bottomDriversNum > 0) {
                filteredSessions = filteredSessions
                    .filter((s) => s.driver_score !== null)
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    .sort((a, b) => (a.driver_score! > b.driver_score! ? 1 : -1))
                    .slice(0, bottomDriversNum);
            } else {
                filteredSessions = [];
            }
        }
    }

    return (
        <Dialog
            id="coaching-at-scale-dialog"
            open={true}
            onClose={() => props.onClose()}
            fullWidth
            maxWidth="sm"
            sx={{
                '& .MuiDialog-scrollPaper': { alignItems: 'start' },
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': { width: '100%' },
                },
            }}
        >
            {phoneNumbersFormOpen ? (
                <PhoneNumbersForm
                    sessions={filteredSessions}
                    isBlurred={isBlurred}
                    assignee={selectedAssignee}
                    onBack={() => setPhoneNumbersFormOpen(false)}
                    onClose={props.onClose}
                    setSessions={props.setSessions}
                    setTab={props.setTab}
                    setAlert={props.setAlert}
                    drivers={drivers}
                />
            ) : (
                <>
                    <Box
                        sx={{
                            backgroundColor: 'secondary.main',
                            position: 'sticky',
                            top: 0,
                            p: 1,
                        }}
                    >
                        <Box sx={{ position: 'absolute', display: 'flex', right: 4, top: 4 }}>
                            <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
                            {t('content.coach.coaching_at_scale.header')}
                        </Box>
                    </Box>
                    <Paper
                        sx={{
                            backgroundColor: 'bgColor.main',
                            p: 1,
                            borderRadius: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 1, pb: 0 }}>
                            <Typography sx={{ mb: 1 }}>{t('content.coach.coaching_at_scale.sub_header1')}</Typography>
                            <Typography sx={{ mb: 1 }}>{t('content.coach.coaching_at_scale.sub_header2')}</Typography>
                            <Typography fontSize={14} sx={{ mb: 1 }}>
                                <Typography fontSize={14} component="span" sx={{ fontWeight: 600 }}>
                                    {t('content.coach.coaching_at_scale.note_header')}
                                </Typography>{' '}
                                {t('content.coach.coaching_at_scale.note_body')}
                            </Typography>
                            <Typography variant="overline">
                                {t('content.coach.coaching_at_scale.drivers_to_coach')}
                            </Typography>
                            <RadioGroup
                                value={driversToCoach}
                                onChange={(e) => {
                                    setDriversToCoach(e.target.value as DriversToCoach);
                                }}
                            >
                                <FormControlLabel
                                    id="all-radio"
                                    data-testid="all-radio"
                                    value="all"
                                    control={
                                        <CustomRadio
                                            id="all-radio-btn"
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            inputProps={{ 'data-testid': 'all-radio-btn' } as any}
                                            checked={driversToCoach === 'all'}
                                            disableRipple
                                        />
                                    }
                                    label={
                                        <Typography sx={{ fontSize: 14 }}>
                                            {t('content.coach.coaching_at_scale.all')}
                                        </Typography>
                                    }
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
                                    <FormControlLabel
                                        id="sub-fleet-radio"
                                        data-testid="sub-fleet-radio"
                                        value="sub_fleet"
                                        disabled={subFleets.length === 0}
                                        control={
                                            <CustomRadio
                                                id="sub-fleet-radio-btn"
                                                checked={driversToCoach === 'sub_fleet'}
                                                disableRipple
                                            />
                                        }
                                        label={
                                            <Typography sx={{ fontSize: 14 }}>
                                                {t('content.coach.coaching_at_scale.sub_fleet')}
                                            </Typography>
                                        }
                                        sx={{ flexShrink: 0, mr: 0.5 }}
                                    />
                                    <Select
                                        fullWidth
                                        id="sub-fleet-select"
                                        data-testid="sub-fleet-select"
                                        size="small"
                                        value={subFleet}
                                        disabled={driversToCoach !== 'sub_fleet'}
                                        onChange={(e) => setSubFleet(e.target.value)}
                                        inputProps={{ 'data-testid': 'sub-fleet-select-input' }}
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    >
                                        {subFleets.map((subFleet) => (
                                            <MenuItem key={subFleet} id={`${subFleet}-option`} value={subFleet}>
                                                {subFleet}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                    <FormControlLabel
                                        id="individual-radio"
                                        data-testid="individual-radio"
                                        value="individual"
                                        control={
                                            <CustomRadio
                                                id="individual-radio-btn"
                                                checked={driversToCoach === 'individual'}
                                                disableRipple
                                            />
                                        }
                                        label={
                                            <Typography sx={{ fontSize: 14 }}>
                                                {t('content.coach.coaching_at_scale.individual')}
                                            </Typography>
                                        }
                                        sx={{ flexShrink: 0, mr: 0.5, mt: 0.25 }}
                                    />
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        disableCloseOnSelect
                                        id="drivers-field"
                                        data-testid="drivers-field"
                                        disabled={driversToCoach !== 'individual'}
                                        value={individualSessions.map((s) => s.toString())}
                                        onChange={(_, newValue) => {
                                            setIndividualSessions(newValue.map((s) => parseInt(s)));
                                        }}
                                        size="small"
                                        options={eligibleSessions.map((s) => s.session_id.toString())}
                                        renderOption={(props, option, { selected }) => {
                                            const [driverName, licensePlate] = getDriverDetails(sessionsById[option]);
                                            return (
                                                <li {...props} style={{ height: 40, paddingLeft: 0 }}>
                                                    <Checkbox
                                                        id={`${licensePlate}-checkbox`}
                                                        data-testid={`${option}-checkbox`}
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 0 }}
                                                        checked={selected}
                                                    />
                                                    <Typography sx={{ mr: 0.5 }}>{driverName}</Typography>
                                                    <Typography>{`(${licensePlate})`}</Typography>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    'data-testid': 'drivers-field-input',
                                                }}
                                            />
                                        )}
                                        renderTags={(
                                            value: Array<string>,
                                            getTagProps: AutocompleteRenderGetTagProps,
                                        ) => {
                                            return value.map((v, index) => {
                                                const [driverName, licensePlate] = getDriverDetails(sessionsById[v]);
                                                return (
                                                    // eslint-disable-next-line react/jsx-key
                                                    <Chip
                                                        id={`${v}-chip`}
                                                        size="small"
                                                        label={
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Typography fontSize={12} sx={{ mr: 0.25 }}>
                                                                    {driverName}
                                                                </Typography>
                                                                <Typography
                                                                    fontSize={12}
                                                                >{`(${licensePlate})`}</Typography>
                                                            </Box>
                                                        }
                                                        {...getTagProps({ index })}
                                                        sx={{ borderRadius: 1 }}
                                                    />
                                                );
                                            });
                                        }}
                                    />
                                </Box>
                            </RadioGroup>
                            {driversToCoach !== 'individual' ? (
                                <>
                                    <Typography variant="overline" sx={{ mt: 2 }}>
                                        {t('content.coach.coaching_at_scale.additional_criteria')}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
                                        <FormControlLabel
                                            id="last-completed-field"
                                            data-testid="last-completed-field"
                                            value="last_completed"
                                            control={
                                                <Checkbox
                                                    id="last-completed-checkbox"
                                                    checked={additionalCriteria.includes('last_completed')}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAdditionalCriteria((prev) => [
                                                                ...prev,
                                                                'last_completed',
                                                            ]);
                                                        } else {
                                                            setAdditionalCriteria((prev) =>
                                                                prev.filter((c) => c !== 'last_completed'),
                                                            );
                                                        }
                                                    }}
                                                    size="small"
                                                    disableRipple
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            height: 18,
                                                            width: 18,
                                                            marginBottom: '2px',
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: 14 }}>
                                                    {t('content.coach.coaching_at_scale.last_completed_session')}
                                                </Typography>
                                            }
                                            sx={{ flexShrink: 0, mr: 0.5 }}
                                        />
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale={i18n.languages[0]}
                                        >
                                            <ResponsiveDatePicker
                                                fullWidth
                                                id="last-date-field"
                                                data-testid="last-date-field"
                                                format={profile.dateFormat}
                                                value={lastDate}
                                                onChange={(value: Dayjs | null) => {
                                                    if (value !== null) {
                                                        setLastDate(value.startOf('day'));
                                                    }
                                                }}
                                                maxDate={dayjs()}
                                                disabled={!additionalCriteria.includes('last_completed')}
                                                slotProps={{
                                                    textField: {
                                                        inputProps: {
                                                            id: 'last-date-field-input',
                                                            'data-testid': 'last-date-field-input',
                                                        },
                                                        size: 'small',
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControlLabel
                                            id="bottom-drivers-field"
                                            data-testid="bottom-drivers-field"
                                            value="bottom_drivers"
                                            control={
                                                <Checkbox
                                                    id="bottom-drivers-checkbox"
                                                    checked={additionalCriteria.includes('bottom_drivers')}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAdditionalCriteria((prev) => [
                                                                ...prev,
                                                                'bottom_drivers',
                                                            ]);
                                                        } else {
                                                            setAdditionalCriteria((prev) =>
                                                                prev.filter((c) => c !== 'bottom_drivers'),
                                                            );
                                                        }
                                                    }}
                                                    size="small"
                                                    disableRipple
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            height: 18,
                                                            width: 18,
                                                            marginBottom: '2px',
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: 14 }}>
                                                    {t('content.coach.coaching_at_scale.bottom_drivers1')}
                                                </Typography>
                                            }
                                            sx={{ mr: 0 }}
                                        />
                                        <TextField
                                            id="number-of-drivers-field"
                                            data-testid="number-of-drivers-field"
                                            type="number"
                                            size="small"
                                            disabled={!additionalCriteria.includes('bottom_drivers')}
                                            inputProps={{ min: 0, 'data-testid': 'number-of-drivers-field-input' }}
                                            value={bottomDriversNumStr}
                                            onChange={(e) => setBottomDriversNumStr(e.target.value)}
                                            sx={{ width: 100, ml: i18n.languages[0] === 'he' ? 0 : 0.5, mr: 0.5 }}
                                        />
                                        <Typography fontSize={14}>
                                            {t('content.coach.coaching_at_scale.bottom_drivers2')}
                                        </Typography>
                                    </Box>
                                </>
                            ) : null}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="blur-events-checkbox"
                                        data-testid="blur-events-checkbox"
                                        checked={isBlurred}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setIsBlurred(e.target.checked)
                                        }
                                        size="small"
                                        disableRipple
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                height: 18,
                                                width: 18,
                                                marginBottom: '2px',
                                            },
                                        }}
                                    />
                                }
                                label={<Typography sx={{ fontSize: 14 }}>{t('content.coach.details.blur')}</Typography>}
                                sx={{ mt: 2 }}
                            />
                            <FormControl fullWidth sx={{ my: 1 }}>
                                <InputLabel id="assignee-field-label">{t('content.coach.details.assignee')}</InputLabel>
                                <Select
                                    id="assignee-field"
                                    labelId="assignee-field-label"
                                    label={t('content.coach.details.assignee')}
                                    name="assignee"
                                    size="small"
                                    value={selectedAssignee}
                                    onChange={(e: SelectChangeEvent) => {
                                        setSelectedAssignee(e.target.value);
                                    }}
                                    sx={{ height: '50px' }}
                                >
                                    <MenuItem id="unassigned-menu-item" value="unassigned" sx={{ height: '45px' }}>
                                        <Typography>{t('content.coach.details.unassigned')}</Typography>
                                    </MenuItem>
                                    {props.users.map((user) => (
                                        <MenuItem
                                            id={`user-${user.user_id}-menu-item`}
                                            key={user.user_id}
                                            value={user.user_id}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                                                {getUserAvatar(user)}
                                                <Typography>{user.name}</Typography>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Card>
                        <Button
                            id="btn-launch"
                            data-testid="btn-launch"
                            data-number-of-sessions={filteredSessions.length}
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={filteredSessions.length === 0}
                            onClick={() => setPhoneNumbersFormOpen(true)}
                            sx={{ mt: 1 }}
                        >
                            {!!filteredSessions.length
                                ? t('content.coach.coaching_at_scale.send_x_sessions', {
                                      count: filteredSessions.length,
                                  })
                                : t('content.coach.coaching_at_scale.send_sessions')}
                        </Button>
                    </Paper>
                </>
            )}
        </Dialog>
    );
};

export default CoachingAtScaleModal;
