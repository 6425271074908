import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import { Avatar, Box, List, ListItem, Skeleton, Tooltip, Typography, alpha, darken } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DriverScore, WellnessScore } from '../../../../backendsdk';
import { TrackedListItemButton as ListItemButton } from '../../../../components/TrackedComponents';
import useProfile from '../../../../hooks/profile';
import { flattenRoles, vehiclesPageDefs, wellnessPageDefs } from '../../../../utils/Pages';
import palette from '../../../ColorPalette';
import VehicleModal from '../../Vehicles/VehicleModal';
import { getScoreColor } from '../../Wellness/WellnessCard';
import WellnessModal from '../../Wellness/WellnessModal';
import { INVALID_SCORE } from './MetricsComponent';

interface ScoreAvatarProps {
    score?: number;
    size?: number;
    fontSize?: number;
    inactive?: boolean;
    unknown?: boolean;
}

export const ScoreAvatar: React.FC<ScoreAvatarProps> = (props: ScoreAvatarProps) => {
    const { t } = useTranslation();
    let color = palette.gray[600];
    if (props.score !== undefined && props.score !== INVALID_SCORE) {
        color = getScoreColor(props.score);
    }

    let scoreStr = 'NA';
    let iconElement = null;

    if (props.score === INVALID_SCORE) {
        if (props.inactive || props.unknown) {
            scoreStr = props.inactive ? 'inactive' : 'unknown';
            const iconStyle = { fontSize: props.fontSize ? props.fontSize + 4 : 20 };
            const iconComponent = props.inactive ? (
                <RemoveRoadIcon sx={iconStyle} />
            ) : (
                <HeartBrokenIcon sx={iconStyle} />
            );
            iconElement = <Tooltip title={t(`content.safety.score_${scoreStr}`)}>{iconComponent}</Tooltip>;
        }
    } else if (props.score !== undefined) {
        scoreStr = Math.round(props.score).toString();
    }

    const scoreElement = (
        <Typography sx={{ fontSize: props.fontSize || 14, fontWeight: 'bold' }}>{scoreStr}</Typography>
    );

    const finalScoreElement = iconElement || scoreElement;

    return (
        <Avatar
            variant="rounded"
            data-testid="score-avatar"
            data-score={scoreStr}
            sx={{
                width: props.size || 28,
                height: props.size || 28,
                color: darken(color, 0.1),
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: darken(color, 0.1),
                backgroundColor: alpha(color, 0.1),
                alignItems: 'center',
            }}
        >
            {finalScoreElement}
        </Avatar>
    );
};

interface DriverListProps {
    loading: boolean;
    header: string;
    scoreType: 'safety' | 'wellness';
    drivers: DriverScore[] | WellnessScore[];
    order: 'asc' | 'desc';
}

const DriverList: React.FC<DriverListProps> = (props: DriverListProps) => {
    const [selectedLicensePlate, setSelectedLicensePlate] = useState<string>();

    const sortedDrivers = props.drivers.sort((a, b) => {
        if (props.order === 'asc') {
            return a.score - b.score;
        } else {
            return b.score - a.score;
        }
    });
    const { profile } = useProfile();
    const hasLinkPermissions =
        props.scoreType === 'safety'
            ? flattenRoles(vehiclesPageDefs).includes(profile.role)
            : flattenRoles(wellnessPageDefs).includes(profile.role);

    return (
        <Box
            sx={{
                width: '100%',
                height: 245,
                display: 'flex',
                flexDirection: 'column',
                borderRight: props.order === 'asc' ? `1px solid ${palette.neutral[400]}` : undefined,
            }}
        >
            {selectedLicensePlate !== undefined ? (
                props.scoreType === 'safety' ? (
                    <VehicleModal
                        licensePlate={selectedLicensePlate}
                        onClose={() => setSelectedLicensePlate(undefined)}
                        initialTab={1}
                    />
                ) : (
                    <WellnessModal
                        licensePlate={selectedLicensePlate}
                        onClose={() => setSelectedLicensePlate(undefined)}
                        initialTab={1}
                    />
                )
            ) : null}
            <Typography fontSize={14} sx={{ fontWeight: 'bold', px: 1, mb: 0.5 }}>
                {props.header}
            </Typography>
            {props.loading ? (
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', px: 1 }}>
                    {[...Array(5).keys()].map((key) => (
                        <Box
                            key={key}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: 44,
                            }}
                        >
                            <Skeleton variant="rounded" animation="wave" sx={{ height: 21, width: 150 }} />
                            <Skeleton variant="rounded" animation="wave" sx={{ height: 28, width: 28 }} />
                        </Box>
                    ))}
                </Box>
            ) : (
                <List disablePadding>
                    {sortedDrivers.map((driver, idx) => {
                        const trimmedName = driver.driver_name?.trim();
                        const content = (
                            <>
                                <Typography fontSize={14}>
                                    {!!trimmedName ? trimmedName : driver.license_plate}
                                </Typography>
                                <ScoreAvatar score={driver.score} />
                            </>
                        );

                        if (hasLinkPermissions) {
                            return (
                                <ListItemButton
                                    id={`${props.order === 'asc' ? 'top' : 'bottom'}-${props.scoreType}-${idx}`}
                                    data-testid={`${props.order === 'asc' ? 'bottom' : 'top'}-${
                                        props.scoreType
                                    }-${idx}`}
                                    disableGutters
                                    key={`bottom-safety-${driver.license_plate}`}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        p: 1,
                                    }}
                                    onClick={() => setSelectedLicensePlate(driver.license_plate)}
                                >
                                    {content}
                                </ListItemButton>
                            );
                        }
                        return (
                            <ListItem
                                data-testid={`top-${props.scoreType}-${idx}`}
                                disableGutters
                                key={`bottom-safety-${driver.license_plate}`}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    p: 1,
                                    '&:hover': {
                                        backgroundColor: palette.neutral[100],
                                    },
                                }}
                            >
                                {content}
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </Box>
    );
};

export default DriverList;
