import { createTheme } from '@mui/material/styles';

import palette from './ColorPalette';

declare module '@mui/material/styles' {
    interface Palette {
        accent: Palette['primary'];
        bgColor: Palette['primary'];
        neutral: Palette['primary'];
        lightNeutral: Palette['primary'];
        grayColor: Palette['primary'];
        lightGrayColor: Palette['primary'];
        redColor: Palette['primary'];
        wellnessGray: Palette['primary'];
        wellnessRed: Palette['primary'];
        wellnessGreen: Palette['primary'];
    }
    interface PaletteOptions {
        accent: PaletteOptions['primary'];
        bgColor: PaletteOptions['primary'];
        neutral: PaletteOptions['primary'];
        lightNeutral: PaletteOptions['primary'];
        grayColor: PaletteOptions['primary'];
        lightGrayColor: PaletteOptions['primary'];
        redColor: PaletteOptions['primary'];
        wellnessGray: PaletteOptions['primary'];
        wellnessRed: PaletteOptions['primary'];
        wellnessGreen: PaletteOptions['primary'];
    }
    interface TypographyVariants {
        label: React.CSSProperties;
        body3: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        label?: React.CSSProperties;
        body3?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        label: true;
        body3: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        redColor: true;
        neutral: true;
        wellnessRed: true;
        wellnessGreen: true;
        wellnessGray: true;
        lightNeutral: true;
    }
}

declare module '@mui/material/Switch' {
    interface SwitchPropsColorOverrides {
        wellnessRed: true;
        wellnessGreen: true;
        wellnessGray: true;
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: palette.primary,
        },
        info: {
            main: palette.primary,
        },
        secondary: {
            main: palette.secondary,
        },
        accent: {
            main: palette.accent,
        },
        bgColor: {
            main: palette.bgColor,
        },
        neutral: {
            main: palette.neutral[400],
        },
        lightNeutral: {
            main: palette.neutral[200],
        },
        grayColor: {
            main: palette.gray[600],
        },
        lightGrayColor: {
            main: palette.gray[400],
        },
        redColor: {
            main: palette.red[300],
        },
        wellnessGray: {
            main: palette.wellness.gray,
        },
        wellnessRed: {
            main: palette.wellness.red,
        },
        wellnessGreen: {
            main: palette.wellness.green,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1600,
        },
    },
    typography: {
        label: {
            fontSize: 10,
            color: palette.gray[600],
            fontWeight: 500,
        },
        body3: {
            fontSize: 12,
        },
    },
});

export default theme;
