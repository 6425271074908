import { Descendant } from 'slate';

export interface User {
    id: number;
    name: string;
}

export const hasTextValue = (value: Descendant[]): boolean => {
    // recursively check if the editor value has any CustomText nodes with non-empty text
    let res = false;
    for (const node of value) {
        if ('text' in node && node.text !== '') {
            return true;
        } else if ('type' in node && node.type == 'mention') {
            return true;
        } else if ('children' in node) {
            res = res || hasTextValue(node.children);
        }
    }
    return res;
};
