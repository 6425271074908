import { HosDriver } from '../../../backendsdk';

export interface DriverWithCounters extends HosDriver {
    counters: {
        break: number;
        drive: number;
        shift: number;
        cycle: number;
        violation_today: number;
        violation_cycle: number;
    };
}

export interface DriverRecord {
    id: number;
    created_date: number;
    device: string;
    sequence_id_number: number;
    record_status: RECORD_STATUS;
    record_origin: RECORD_ORIGIN;
    record_type: RECORD_TYPE;
    record_code: RECORD_CODE;
    time_zone_offset_from_utc: number;
    event_datetime: number;
    accumulated_vehicle_miles: number | null;
    total_vehicle_miles: number | null;
    elapsed_engine_hours: number | null;
    total_engine_hours: number | null;
    event_latitude: number | null;
    event_longitude: number | null;
    distance_since_last_valid_coordinates: number | null;
    malfunction_indicator_status: boolean | null;
    malfunction_or_diagnostic_code: string | null;
    data_diagnostic_event_indicator_status: boolean | null;
    comment: string;
    drivers_location_description: string;
    event_data_check_value: string;
    driver: number;
}

export const EMPTY_DRIVER_RECORD: DriverRecord = {
    id: 0,
    created_date: 0,
    device: '',
    sequence_id_number: 0,
    record_status: 0,
    record_origin: 0,
    record_type: 0,
    record_code: 0,
    time_zone_offset_from_utc: 0,
    event_datetime: 0,
    accumulated_vehicle_miles: 0,
    total_vehicle_miles: 0,
    elapsed_engine_hours: 0,
    total_engine_hours: 0,
    event_latitude: 0,
    event_longitude: 0,
    distance_since_last_valid_coordinates: 0,
    malfunction_indicator_status: false,
    malfunction_or_diagnostic_code: '',
    data_diagnostic_event_indicator_status: false,
    comment: '',
    drivers_location_description: '',
    event_data_check_value: '',
    driver: 0,
};

export enum RECORD_STATUS {
    ACTIVE = 1,
    INACTIVE_CHANGED = 2,
    INACTIVE_CHANGE_REQUESTED = 3,
    INACTIVE_CHANGE_REJECTED = 4,
}

export enum RECORD_ORIGIN {
    AUTOMATICALLY_RECORDED_BY_ELD = 1,
    EDITED_OR_ENTERED_BY_THE_DRIVER = 2,
    EDIT_REQUESTED_BY_AN_AUTHENTICATED_USER_OTHER_THAN_THE_DRIVER = 3,
    ASSUMED_FROM_UNIDENTIFIED_DRIVER_PROFILE = 4,
}

export enum RECORD_TYPE {
    A_CHANGE_IN_DRIVERS_DUTY_STATUS = 1,
    AN_INTERMEDIATE_LOG = 2,
    AN_AUTHORIZED_PERSONAL_USE_OF_CMV_OR_YARD_MOVES = 3,
    A_DRIVERS_CERTIFICATION_RE_CERTIFICATION_OF_RECORDS = 4,
    A_DRIVERS_LOGIN_LOGOUT_ACTIVITY = 5,
    CMVS_ENGINE_POWER_UP_SHUT_DOWN_ACTIVITY = 6,
    A_MALFUNCTION_OR_DATA_DIAGNOSTIC_DETECTION_OCCURRENCE = 7,
}

export enum RECORD_CODE {
    DRIVERS_DUTY_STATUS_CHANGED_TO_OFF_DUTY = 1,
    DRIVERS_DUTY_STATUS_CHANGED_TO_SLEEPER_BERTH = 2,
    DRIVERS_DUTY_STATUS_CHANGED_TO_DRIVING = 3,
    DRIVERS_DUTY_STATUS_CHANGED_TO_ON_DUTY_NOT_DRIVING = 4,
}

export const abbrMap: Record<keyof typeof RECORD_CODE, string> = {
    DRIVERS_DUTY_STATUS_CHANGED_TO_OFF_DUTY: 'OFF',
    DRIVERS_DUTY_STATUS_CHANGED_TO_SLEEPER_BERTH: 'SB',
    DRIVERS_DUTY_STATUS_CHANGED_TO_DRIVING: 'D',
    DRIVERS_DUTY_STATUS_CHANGED_TO_ON_DUTY_NOT_DRIVING: 'ON',
} as const;

export interface HomeTerminal {
    id: number;
    name: string;
    address: string;
    timezone_offset_from_utc: number;
    consecutive_days: 7 | 8;
    day_start_time: string;
}

export const STATES = [
    'AL',
    'AK',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
] as const;
export const PROVINCES = [
    'AS',
    'GU',
    'MP',
    'PR',
    'VI',
    'AB',
    'BC',
    'MB',
    'NB',
    'NF',
    'NS',
    'NT',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
    'AG',
    'BN',
    'BS',
    'CH',
    'CI',
    'CL',
    'CP',
    'CS',
    'DF',
    'DG',
    'GE',
    'GJ',
    'HD',
    'JA',
    'MC',
    'MR',
    'MX',
    'NA',
    'NL',
    'OA',
    'PU',
    'QE',
    'QI',
    'SI',
    'SL',
    'SO',
    'TA',
    'TB',
    'TL',
    'VC',
    'YU',
    'ZA',
    'OT',
] as const;

export const STATES_PROVINCES = [...STATES, ...PROVINCES] as const;

export type StateProvince = typeof STATES_PROVINCES[number];
