import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Card, Tooltip, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FleetScoreDetails, FleetScoreShort } from '../../../../backendsdk';
import { TrackedLink as Link } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import palette from '../../../ColorPalette';
import DistributionChart from './DistributionChart';
import DriverList from './DriverList';
import FleetScoreCard from './FleetScoreCard';
import FleetTrendChart from './FleetTrendChart';

export const DRIVER_SCORE_BUCKETS = [
    'content.metrics.inactive',
    'content.metrics.unknown',
    '0–19',
    '20–39',
    '40–59',
    '60–79',
    '80–100',
];
export const WELLNESS_SCORE_BUCKETS = [
    'content.metrics.inactive',
    '0',
    '1–19',
    '20–39',
    '40–59',
    '60–79',
    '80–99',
    '100',
];

export const INVALID_SCORE = -1;

export const isScoreValid = (score: number | undefined): boolean => {
    return score !== undefined && score !== INVALID_SCORE;
};

interface MetricsComponentProps {
    scoreType: 'safety' | 'wellness';
    selectedSubFleet: string;
    setSelectedSubFleet: CallableFunction;
    isLoading: boolean;
    isError: boolean;
    fleetScoreDetails: Record<string, FleetScoreDetails>;
    fleetScoreTrend: FleetScoreShort[];
    animate: boolean;
    getMetrics: CallableFunction;
    hiddenSubFleets: string[];
    setHiddenSubFleets: CallableFunction;
}

const MetricsComponent: React.FC<MetricsComponentProps> = (props: MetricsComponentProps) => {
    const { isLoading, isError, fleetScoreDetails, fleetScoreTrend, animate } = props;
    const [hoveredSubFleet, setHoveredSubFleet] = useState<string>('');
    const { t } = useTranslation();
    const { profile } = useProfile();
    const ALL_FLEET = t('content.metrics.all_fleet');
    const isMobile = useIsMobile();
    const isSafetyScore = props.scoreType === 'safety';

    const [data, subFleets, scoresByFleet] = useMemo(() => {
        const subFleets = new Set<string>();
        const fleetScoreTrendData: Record<number, Record<string, number>> = fleetScoreTrend.reduce((acc, curr) => {
            if (acc[curr.timestamp] === undefined) {
                acc[curr.timestamp] = {};
            }
            const currentSubFleet = curr.sub_fleet || ALL_FLEET;
            const currentScore = isSafetyScore ? curr.driver_score : curr.wellness_score;

            if (isScoreValid(currentScore)) {
                acc[curr.timestamp][currentSubFleet] = Math.round(currentScore);
                subFleets.add(currentSubFleet);
            }
            return acc;
        }, {} as Record<number, Record<string, number>>);

        const trendChartData = Object.entries(fleetScoreTrendData).map(([timestamp, subFleetScores]) => ({
            name: parseInt(timestamp),
            ...subFleetScores,
        }));

        trendChartData.sort((a, b) => a.name - b.name);

        const scoreTrendData: Record<string, Record<number, Record<string, number>>> = fleetScoreTrend.reduce(
            (acc, curr) => {
                const currentSubFleet = curr.sub_fleet || ALL_FLEET;
                if (acc[currentSubFleet] === undefined) {
                    acc[currentSubFleet] = {};
                }
                if (acc[currentSubFleet][curr.timestamp] === undefined) {
                    acc[currentSubFleet][curr.timestamp] = { name: curr.timestamp };
                }

                const histogram = isSafetyScore ? curr.driver_score_histogram : curr.wellness_score_histogram;
                for (const [idx, bucket] of histogram.entries()) {
                    acc[currentSubFleet][curr.timestamp][
                        isSafetyScore ? DRIVER_SCORE_BUCKETS[idx] : WELLNESS_SCORE_BUCKETS[idx]
                    ] = bucket;
                }
                return acc;
            },
            {} as Record<string, Record<number, Record<string, number>>>,
        );

        const scoresByFleet = Object.fromEntries(Object.entries(scoreTrendData).map(([k, v]) => [k, Object.values(v)]));

        for (const subFleet of Object.keys(scoresByFleet)) {
            scoresByFleet[subFleet].sort((a, b) => a.name - b.name);
        }

        const subFleetList = Array.from(subFleets);
        return [trendChartData, subFleetList, scoresByFleet];
    }, [fleetScoreTrend, props.scoreType]);

    // reorder sub fleets to make sure the lines of the hovered subfleet and the selected subfleet are on top
    if (!!hoveredSubFleet) {
        subFleets.push(subFleets.splice(subFleets.indexOf(hoveredSubFleet), 1)[0]);
    }
    subFleets.push(subFleets.splice(subFleets.indexOf(props.selectedSubFleet), 1)[0]);

    const selectedFleetScoreDetails = fleetScoreDetails[props.selectedSubFleet];
    let score;
    if (selectedFleetScoreDetails !== undefined) {
        const relevantScore = isSafetyScore
            ? selectedFleetScoreDetails.driver_score
            : selectedFleetScoreDetails.wellness_score;
        score = isLoading ? 0 : Math.round(relevantScore);
    }
    const selectedScoreExists = selectedFleetScoreDetails !== undefined && isScoreValid(score);

    const sortedSubFleets = [...subFleets].filter((sb) => sb !== undefined).sort((a, b) => a.localeCompare(b));
    if (sortedSubFleets.length > 0) {
        sortedSubFleets.unshift(
            sortedSubFleets.splice(
                sortedSubFleets.findIndex((sf) => sf === ALL_FLEET),
                1,
            )[0],
        );
    } else {
        sortedSubFleets.push(ALL_FLEET);
    }

    if (isError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.metrics.metrics_error')}</Typography>
                <Link
                    id="try-again-link"
                    component="button"
                    variant="overline"
                    onClick={() => {
                        props.getMetrics();
                    }}
                >
                    {t('content.metrics.try_again')}
                </Link>
            </Box>
        );
    }

    const bottomDrivers = isSafetyScore
        ? selectedFleetScoreDetails?.bottom_driver_scores
        : selectedFleetScoreDetails?.bottom_wellness_scores;
    const topDrivers = isSafetyScore
        ? selectedFleetScoreDetails?.top_driver_scores
        : selectedFleetScoreDetails?.top_wellness_scores;

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%' }}>
                <FleetScoreCard
                    scoreType={props.scoreType}
                    isLoading={isLoading}
                    dateFormat={profile.shortDateFormat}
                    score={score}
                    selectedFleetScoreDetails={selectedFleetScoreDetails}
                />
                <Card
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 330,
                        p: 1,
                    }}
                >
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t(`content.metrics.fleet_${props.scoreType}_score_trend`)}
                    </Typography>
                    <Box data-testid="fleet-score-trend-container" sx={{ width: '100%', height: '100%' }}>
                        <FleetTrendChart
                            data={data}
                            subFleets={subFleets}
                            animate={animate}
                            selectedSubFleet={props.selectedSubFleet}
                            setSelectedSubFleet={props.setSelectedSubFleet}
                            hoveredSubFleet={hoveredSubFleet}
                            setHoveredSubFleet={setHoveredSubFleet}
                            dateFormat={profile.shortDateFormat}
                            allFleetString={ALL_FLEET}
                            YAxisProps={{ interval: 0, tickCount: 11, domain: [0, 100] }}
                            margin={{
                                top: 5,
                                right: 5,
                                left: -30,
                                bottom: 5,
                            }}
                            accentColor={palette.accent}
                            hiddenSubFleets={props.hiddenSubFleets}
                            setHiddenSubFleets={props.setHiddenSubFleets}
                        />
                    </Box>
                </Card>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', my: 2 }}>
                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', p: 1, mr: isMobile ? 0 : 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {t(`content.metrics.${props.scoreType}_dist`)}
                        </Typography>
                        <Tooltip title={t(`content.metrics.${props.scoreType}_dist_tooltip`)}>
                            <InfoOutlinedIcon sx={{ fontSize: 16, ml: 0.25 }} />
                        </Tooltip>
                    </Box>
                    <Box
                        data-testid={`${props.scoreType}-distribution-container`}
                        sx={{ width: '100%', height: 260, mb: 1 }}
                    >
                        <DistributionChart
                            subFleetData={scoresByFleet[props.selectedSubFleet] || []}
                            scoreType={props.scoreType}
                            buckets={isSafetyScore ? DRIVER_SCORE_BUCKETS : WELLNESS_SCORE_BUCKETS}
                            dateFormat={profile.shortDateFormat}
                            animate={animate}
                        />
                    </Box>
                </Card>
                <Card sx={{ width: '100%', p: 1, display: 'flex', alignItems: 'center', mt: isMobile ? 2 : 0 }}>
                    {selectedScoreExists ? (
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <DriverList
                                loading={isLoading}
                                header={t(`content.metrics.bottom_${props.scoreType}`)}
                                scoreType={props.scoreType}
                                drivers={bottomDrivers || []}
                                order="asc"
                            />
                            <DriverList
                                loading={isLoading}
                                header={t(`content.metrics.top_${props.scoreType}`)}
                                scoreType={props.scoreType}
                                drivers={topDrivers || []}
                                order="desc"
                            />
                        </Box>
                    ) : null}
                </Card>
            </Box>
        </Box>
    );
};

export default MetricsComponent;
