import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
    Autocomplete,
    Avatar,
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListSubheader,
    Paper,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Prompt, useHistory } from 'react-router-dom';

import { CustomerData } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedLink as Link,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import { stringAvatar } from '../../../utils/Avatar';
import { PageDefs, flattenRoles, pagesInSections, sections, userSettingsPageDefs } from '../../../utils/Pages';
import { formatPhoneNumber, unformatPhoneNumber } from '../../../utils/Str';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { LANGUAGES } from '../../Navigator/Navigator';
import { CustomFormControl } from '../Coaching/Agenda/MetricsForm';
import { isValidPhoneNumber } from '../Users/validation';
import ChangePasswordModal from './ChangePasswordModal';
import RequestPermissionsModal from './RequestPermissionsModal';
import UploadImageModal from './UploadImageModal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TIMEZONES: string[] = (Intl as any).supportedValuesOf('timeZone');
const LOCALES = ['us', 'il'];

export const PHONE_NUMBER_MASK_MAP: Record<string, string> = {
    us: '(999) 999-9999',
    il: '999-999-9999',
};

export const INTERVAL_TO_TEXT = {
    0: 'content.user_settings.customization.accident_updates.every_time',
    30: 'content.user_settings.customization.accident_updates.30m',
    60: 'content.user_settings.customization.accident_updates.1h',
    1440: 'content.user_settings.customization.accident_updates.1d',
};

export const DAYS_TO_TEXT = {
    1: 'content.user_settings.customization.pending_session.1_day',
    3: 'content.user_settings.customization.pending_session.3_days',
    7: 'content.user_settings.customization.pending_session.7_days',
};

const UserSettings: React.FC = () => {
    const { profile, setProfile } = useProfile();
    const [name, setName] = useState<string>(profile.name || '');
    const [email, setEmail] = useState<string>(profile.email);
    const [phoneNumber, setPhoneNumber] = useState<string>(
        formatPhoneNumber(profile.customer.settings.country, profile.phone_number || ''),
    );
    const [changePassword, setChangePassword] = useState<boolean>(false);
    const { i18n, t } = useTranslation();
    const [language, setLanguage] = useState<string>(i18n.languages[0]);
    const [timezone, setTimezone] = useState<string>(profile.timezone);
    const [timezoneInputValue, setTimezoneInputValue] = useState<string>(profile.timezone);
    const [locale, setLocale] = useState<string>(profile.locale);
    const [homePage, setHomePage] = useState<string>(profile.home_page);
    const [getFeaturesNotifications, setGetFeaturesNotifications] = useState<boolean>(profile.notify_upcoming_features);
    const [getMentionsNotifications, setGetMentionsNotifications] = useState<boolean>(profile.notify_mentions);
    const [getAccidentUpdateNotifications, setGetAccidentUpdateNotifications] = useState<boolean>(
        profile.notify_accident_update,
    );
    const [accidentUpdateCooldown, setAccidentUpdateCooldown] = useState<number>(
        profile.accident_notification_cooldown_minutes,
    );
    const [getCoachAssignmentNotifications, setGetCoachAssignmentNotifications] = useState<boolean>(
        profile.notify_coach_assignment,
    );
    const [getPenedingSessionNotifications, setGetPenedingSessionNotifications] = useState<boolean>(
        profile.notify_pending_session,
    );
    const [notifyPendingSessionAfterDays, setNotifyPendingSessionAfterDays] = useState<number>(
        profile.notify_pending_session_after_days,
    );
    const [getCoachCompletedNotifications, setGetCoachCompletedNotifications] = useState<boolean>(
        profile.notify_coach_completed,
    );
    const [getMalfunctionAssignmentNotifications, setGetMalfunctionAssignmentNotifications] = useState<boolean>(
        profile.notify_malfunction_assignment,
    );
    const [getMalfunctionCommentNotifications, setGetMalfunctionCommentNotifications] = useState<boolean>(
        profile.notify_malfunction_comment,
    );
    const [getMalfunctionResolvedNotifications, setGetMalfunctionResolvedNotifications] = useState<boolean>(
        profile.notify_malfunction_resolved,
    );
    const [getFeedEventCommentNotifications, setGetFeedEventCommentNotifications] = useState<boolean>(
        profile.notify_feed_event_comment,
    );
    const [requestPermissions, setRequestPermissions] = useState<boolean>(false);
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [base64Image, setBase64Image] = useState<string>('');
    const [phoneNumberError, setPhoneNumberError] = useState<string>('');
    const { api } = useApi();
    const query = useQuery();
    const history = useHistory();
    const [alertElement, setAlert] = useAlert();
    const isMobile = useIsMobile();
    const queryClient = useQueryClient();

    const availablePages = Object.fromEntries(
        Object.entries(pagesInSections).map(([section, pages]) => {
            const pageList = pages.filter((page: PageDefs) => flattenRoles(page).includes(profile.role));
            return [section, pageList];
        }),
    );

    const availableSections = sections.filter((section) => availablePages[section]?.length > 0);

    const detailsChanged =
        name !== profile.name ||
        email !== profile.email ||
        language !== profile.language ||
        unformatPhoneNumber(phoneNumber) !== profile.phone_number ||
        timezone !== profile.timezone ||
        locale !== profile.locale ||
        homePage !== profile.home_page ||
        getFeaturesNotifications !== profile.notify_upcoming_features ||
        getMentionsNotifications !== profile.notify_mentions ||
        getAccidentUpdateNotifications !== profile.notify_accident_update ||
        accidentUpdateCooldown !== profile.accident_notification_cooldown_minutes ||
        getCoachAssignmentNotifications !== profile.notify_coach_assignment ||
        getPenedingSessionNotifications !== profile.notify_pending_session ||
        notifyPendingSessionAfterDays !== profile.notify_pending_session_after_days ||
        getCoachCompletedNotifications !== profile.notify_coach_completed ||
        getMalfunctionAssignmentNotifications !== profile.notify_malfunction_assignment ||
        getMalfunctionCommentNotifications !== profile.notify_malfunction_comment ||
        getMalfunctionResolvedNotifications !== profile.notify_malfunction_resolved ||
        getFeedEventCommentNotifications !== profile.notify_feed_event_comment ||
        base64Image !== '';

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${userSettingsPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        const token = query.get('token');
        if (token) {
            api.apiV0SettingsUserEmailPatch({ changeEmailRequest: { token } }).then((res) => {
                query.delete('token');
                setProfile(res.data);
                setEmail(res.data.email);
                history.replace(`${location.pathname}?${query}`);
                setAlert({ message: t('content.user_settings.email_success'), type: 'success', duration: 6000 });
            });
        }
    }, []);

    const handleSave = () => {
        if (phoneNumber === '' || isValidPhoneNumber(phoneNumber)) {
            setPhoneNumberError('');
            api.apiV1UserPatch({
                userProfile: {
                    ...profile,
                    customer: {} as CustomerData, // reduce request size
                    name,
                    email,
                    phone_number: unformatPhoneNumber(phoneNumber),
                    locale,
                    language,
                    timezone,
                    home_page: homePage,
                    notify_upcoming_features: getFeaturesNotifications,
                    notify_mentions: getMentionsNotifications,
                    notify_accident_update: getAccidentUpdateNotifications,
                    accident_notification_cooldown_minutes: accidentUpdateCooldown,
                    notify_coach_assignment: getCoachAssignmentNotifications,
                    notify_coach_completed: getCoachCompletedNotifications,
                    notify_pending_session: getPenedingSessionNotifications,
                    notify_pending_session_after_days: notifyPendingSessionAfterDays,
                    notify_malfunction_assignment: getMalfunctionAssignmentNotifications,
                    notify_malfunction_comment: getMalfunctionCommentNotifications,
                    notify_malfunction_resolved: getMalfunctionResolvedNotifications,
                    notify_feed_event_comment: getFeedEventCommentNotifications,
                    image_url: base64Image,
                },
            }).then((res) => {
                setProfile(res.data);
                setBase64Image('');
                i18n.changeLanguage(language);
                queryClient.removeQueries({ queryKey: ['help-videos'] });
                setAlert({ message: t('content.user_settings.success'), type: 'success', duration: 6000 });
            });
        } else {
            setPhoneNumberError(t('content.user_settings.invalid_phone_number'));
        }
    };

    const handleRequestPermissions = (message: string) => {
        api.apiV0SettingsUserPermissionPost({ changePermissionsRequest: { message } })
            .then(() => {
                setAlert({ message: t('content.user_settings.permissions_success'), type: 'success', duration: 6000 });
                setRequestPermissions(false);
            })
            .catch(() => {
                setAlert({ message: t('content.user_settings.permissions_error'), type: 'error', duration: 6000 });
            });
    };

    const saveButton = (
        <Button
            id="save-btn"
            data-testid="save-btn"
            disabled={!detailsChanged}
            startIcon={<SaveIcon />}
            variant="contained"
            color="secondary"
            onClick={handleSave}
        >
            {t('content.user_settings.save')}
        </Button>
    );

    const avatarSx = { width: '100%', height: '100%', fontSize: 54, zIndex: 1 };
    const image = base64Image || profile.image_url;
    const avatarProps = image ? { src: image, sx: avatarSx } : { ...stringAvatar(profile.name, avatarSx) };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const phoneInput: any = (props: any) => {
        const inputElement = (
            <TextField
                id="phone-input"
                label={t('content.user_settings.user_details.phone_number')}
                fullWidth
                size="small"
                error={!!phoneNumberError}
                helperText={phoneNumberError}
                inputProps={{ 'data-testid': 'phone-input', direction: 'ltr' }}
                {...props}
            />
        );
        return inputElement;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, pt: 0 }}>
            {alertElement}
            {changePassword ? <ChangePasswordModal onClose={() => setChangePassword(false)} /> : null}
            <Prompt when={detailsChanged} message={t('content.accidents.prompt_msg')} />
            {requestPermissions ? (
                <RequestPermissionsModal
                    onClose={() => setRequestPermissions(false)}
                    onRequest={handleRequestPermissions}
                />
            ) : null}
            {uploadImage ? (
                <UploadImageModal
                    onClose={() => setUploadImage(false)}
                    onUpload={(image: string) => {
                        setBase64Image(image);
                        setUploadImage(false);
                    }}
                />
            ) : null}
            <Box sx={{ position: 'sticky', top: 0, backgroundColor: palette.bgColor, zIndex: 2, pt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', mb: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h5">{t('content.user_settings.header')}</Typography>
                        <Typography variant="body2" sx={{ color: palette.neutral[700] }}>
                            {t('content.user_settings.subheader')}
                        </Typography>
                    </Box>
                    {!isMobile ? saveButton : null}
                </Box>
                <Divider />
            </Box>
            <Grid container columns={3} sx={{ maxWidth: 700 }} padding={2}>
                <Grid item xs={3} sm={1}>
                    <Typography
                        variant="body2"
                        sx={{ color: palette.neutral[700], fontWeight: 'bold', mb: isMobile ? 2 : 0 }}
                    >
                        {t('content.user_settings.user_details.header')}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={2}>
                    <Grid container columns={2} rowSpacing={2} columnSpacing={1.5}>
                        <Grid item xs={2}>
                            <TextField
                                id="username-input"
                                value={profile.username}
                                disabled
                                fullWidth
                                label={t('content.user_settings.user_details.username')}
                                size="small"
                                inputProps={{ 'data-testid': 'username-input' }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="name-input"
                                fullWidth
                                label={t('content.user_settings.user_details.name')}
                                size="small"
                                value={name}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                                inputProps={{ 'data-testid': 'name-input' }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="email-input"
                                fullWidth
                                label={t('content.user_settings.user_details.email')}
                                size="small"
                                value={email}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                inputProps={{ 'data-testid': 'email-input' }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <InputMask
                                mask={PHONE_NUMBER_MASK_MAP[profile.customer.settings.country]}
                                value={phoneNumber}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                                maskChar=""
                            >
                                {phoneInput}
                            </InputMask>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Link
                            id="change-password-link"
                            component="button"
                            fontSize={12}
                            onClick={() => {
                                setChangePassword(true);
                            }}
                            sx={{ pl: 2, mb: 1 }}
                        >
                            {t('content.user_settings.user_details.change_password')}
                        </Link>
                        <Grid item xs={2}>
                            <TextField
                                id="sub-fleet-input"
                                value={profile.sub_fleet || t('content.user_settings.user_details.all_sub_fleets')}
                                disabled
                                fullWidth
                                label={t('content.user_settings.user_details.sub_fleet')}
                                size="small"
                                inputProps={{ 'data-testid': 'sub-fleet-input' }}
                            />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <TextField
                                id="role-input"
                                value={t(`content.users.roles.${profile.role.toLowerCase().replaceAll(' ', '_')}`)}
                                disabled
                                fullWidth
                                label={t('content.user_settings.user_details.role')}
                                size="small"
                                inputProps={{ 'data-testid': 'role-input' }}
                            />
                        </Grid>
                        <Grid item xs={2} sm={1} sx={{ display: 'flex' }}>
                            <Button
                                id="request-permission-btn"
                                data-testid="request-permission-btn"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={() => setRequestPermissions(true)}
                                sx={{ alignSelf: 'center' }}
                            >
                                {t('content.user_settings.user_details.request_permissions')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <Grid container columns={3} sx={{ maxWidth: 700 }} padding={2}>
                <Grid item xs={3} sm={1}>
                    <Typography
                        variant="body2"
                        sx={{ color: palette.neutral[700], fontWeight: 'bold', mb: isMobile ? 2 : 0 }}
                    >
                        {t('content.user_settings.language.header')}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="language-field-label">
                            {t('content.user_settings.language.language')}
                        </InputLabel>
                        <Select
                            id="languange-input"
                            value={language}
                            onChange={(e: SelectChangeEvent) => {
                                setLanguage(e.target.value);
                                if (e.target.value === 'he') {
                                    setLocale('il');
                                }
                            }}
                            labelId="language-field-label"
                            label={t('content.user_settings.language.language')}
                            size="small"
                            inputProps={{ 'data-testid': 'language-input' }}
                        >
                            {Object.entries(LANGUAGES).map(([key, value]) => (
                                <MenuItem id={`${key}-item`} data-testid={`${key}-item`} key={key} value={key}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {userSettingsPageDefs.permissions.L1.includes(profile.role) ? (
                        <Autocomplete
                            disablePortal
                            fullWidth
                            id="timezome-input"
                            data-testid="timezone-input"
                            options={TIMEZONES}
                            value={timezone}
                            onChange={(_event, newValue) => {
                                if (newValue) {
                                    setTimezone(newValue);
                                }
                            }}
                            inputValue={timezoneInputValue}
                            onInputChange={(_event, newInputValue) => {
                                setTimezoneInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('content.user_settings.language.timezone')}
                                    size="small"
                                    sx={{ mb: 2 }}
                                    id="timezone-field-input"
                                />
                            )}
                        />
                    ) : null}
                    <FormControl fullWidth>
                        <InputLabel id="locale-field-label">
                            {t('content.user_settings.language.locale.label')}
                        </InputLabel>
                        <Select
                            id="locale-input"
                            value={locale}
                            onChange={(e: SelectChangeEvent) => setLocale(e.target.value)}
                            labelId="locale-field-label"
                            label={t('content.user_settings.language.locale.label')}
                            size="small"
                            inputProps={{ 'data-testid': 'locale-input' }}
                            disabled={language === 'he'}
                        >
                            {LOCALES.map((localeStr) => (
                                <MenuItem
                                    id={`${localeStr}-item`}
                                    data-testid={`${localeStr}-item`}
                                    key={localeStr}
                                    value={localeStr}
                                >
                                    {t(`content.user_settings.language.locale.${localeStr}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider />
            <Grid container columns={3} padding={2}>
                <Grid item xs={3} sm={1} sx={{ maxWidth: '222px !important' }}>
                    <Typography
                        variant="body2"
                        sx={{ color: palette.neutral[700], fontWeight: 'bold', mb: isMobile ? 2 : 0 }}
                    >
                        {t('content.user_settings.customization.header')}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <FormControl fullWidth sx={{ maxWidth: isMobile ? undefined : '445px' }}>
                        <InputLabel id="home-page-field-label">
                            {t('content.user_settings.customization.home_page')}
                        </InputLabel>
                        <Select
                            id="home-page-input"
                            value={homePage}
                            onChange={(e: SelectChangeEvent) => setHomePage(e.target.value)}
                            labelId="home-page-field-label"
                            label={t('content.user_settings.customization.home_page')}
                            size="small"
                            inputProps={{ 'data-testid': 'home-page-input' }}
                        >
                            {availableSections.map((section) => [
                                <ListSubheader key={section}>{t(`navigator.${section}`)}</ListSubheader>,
                                availablePages[section].map((pageDef) => (
                                    <MenuItem
                                        id={`${pageDef.name}-item`}
                                        data-testid={`${pageDef.name}-item`}
                                        key={pageDef.name}
                                        value={pageDef.path.at(-1)?.slice(1)}
                                    >
                                        {t(`navigator.${pageDef.name}`)}
                                    </MenuItem>
                                )),
                            ])}
                        </Select>
                    </FormControl>
                    <Typography variant="body2" sx={{ color: palette.neutral[700], fontWeight: 'bold', mt: 2 }}>
                        {t('content.user_settings.customization.notify')}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-notifications-input"
                                data-testid="get-notifications-input"
                                checked={getFeaturesNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetFeaturesNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {t('content.user_settings.customization.upcoming_features')}
                            </Typography>
                        }
                        sx={{ mb: 0.5 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-mentions-notifications-input"
                                data-testid="get-mentions-notifications-input"
                                checked={getMentionsNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetMentionsNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">{t('content.user_settings.customization.mentions')}</Typography>
                        }
                        sx={{ mb: isMobile ? 1 : -1 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-accident-updates-notifications-input"
                                data-testid="get-accident-updates-notifications-input"
                                checked={getAccidentUpdateNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetAccidentUpdateNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {t('content.user_settings.customization.accident_updates.header')}
                            </Typography>
                        }
                    />
                    <FormControl sx={{ ml: '28px', mb: 1 }}>
                        <RadioGroup
                            value={accidentUpdateCooldown.toString()}
                            onChange={(e) => {
                                setAccidentUpdateCooldown(parseInt(e.target.value));
                            }}
                        >
                            {Object.entries(INTERVAL_TO_TEXT).map(([interval, text]) => (
                                <CustomFormControl
                                    key={interval}
                                    value={interval}
                                    label={t(text)}
                                    checked={accidentUpdateCooldown === parseInt(interval)}
                                    disabled={!getAccidentUpdateNotifications}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-coach-assignment-notifications-input"
                                data-testid="get-coach-assignment-notifications-input"
                                checked={getCoachAssignmentNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetCoachAssignmentNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {t('content.user_settings.customization.coach_assignment')}
                            </Typography>
                        }
                        sx={{ mb: isMobile ? 1 : -1 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-pending-session-notifications-input"
                                data-testid="get-pending-session-notifications-input"
                                checked={getPenedingSessionNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetPenedingSessionNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {t('content.user_settings.customization.pending_session.header')}
                            </Typography>
                        }
                    />
                    <FormControl sx={{ ml: '28px', mb: isMobile ? 1 : 0 }}>
                        <RadioGroup
                            value={notifyPendingSessionAfterDays.toString()}
                            onChange={(e) => {
                                setNotifyPendingSessionAfterDays(parseInt(e.target.value));
                            }}
                        >
                            {Object.entries(DAYS_TO_TEXT).map(([interval, text]) => (
                                <CustomFormControl
                                    key={interval}
                                    value={interval}
                                    label={t(text)}
                                    checked={notifyPendingSessionAfterDays === parseInt(interval)}
                                    disabled={!getPenedingSessionNotifications}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-coach-completed-notifications-input"
                                data-testid="get-coach-completed-notifications-input"
                                checked={getCoachCompletedNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetCoachCompletedNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {t('content.user_settings.customization.coach_completed')}
                            </Typography>
                        }
                        sx={{ mb: isMobile ? 1 : 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-malfunction-assignment-notifications-input"
                                data-testid="get-malfunction-assignment-notifications-input"
                                checked={getMalfunctionAssignmentNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetMalfunctionAssignmentNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {t('content.user_settings.customization.malfunction_assignment')}
                            </Typography>
                        }
                        sx={{ mb: isMobile ? 1 : -1 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-malfunction-comment-notifications-input"
                                data-testid="get-malfunction-comment-notifications-input"
                                checked={getMalfunctionCommentNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetMalfunctionCommentNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {t('content.user_settings.customization.malfunction_comment')}
                            </Typography>
                        }
                        sx={{ mb: isMobile ? 1 : -1 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-malfunction-resolved-notifications-input"
                                data-testid="get-malfunction-resolved-notifications-input"
                                checked={getMalfunctionResolvedNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetMalfunctionResolvedNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {t('content.user_settings.customization.malfunction_resolved')}
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="get-feed-event-comment-notifications-input"
                                data-testid="get-feed-event-comment-notifications-input"
                                checked={getFeedEventCommentNotifications}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGetFeedEventCommentNotifications(e.target.checked)
                                }
                                size="small"
                                disableRipple
                            />
                        }
                        label={
                            <Typography variant="body2">
                                {t('content.user_settings.customization.feed_event_comment')}
                            </Typography>
                        }
                    />
                    <Typography variant="body2" sx={{ color: palette.neutral[700], fontWeight: 'bold', mt: 2, mb: 1 }}>
                        {t('content.user_settings.customization.profile_picture')}
                    </Typography>
                    <Box
                        id="profile-picture-input"
                        data-testid="profile-picture-input"
                        sx={{ position: 'relative', width: 115, height: 115 }}
                        onClick={() => setUploadImage(true)}
                    >
                        <Paper
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                top: 0,
                                left: 0,
                                opacity: 0,
                                zIndex: 2,
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                '&:hover': { opacity: 1 },
                                background:
                                    'linear-gradient(180deg, rgba(101, 101, 101, 0.21) 0%, rgba(0, 0, 0, 0.6) 100%)',
                                transition: 'opacity 0.2s',
                                cursor: 'pointer',
                            }}
                        >
                            <EditIcon sx={{ color: palette.white }} />
                        </Paper>
                        <Avatar id="avatar-preview" data-testid="avatar-preview" {...avatarProps} />
                    </Box>
                </Grid>
            </Grid>
            {isMobile ? saveButton : null}
        </Box>
    );
};

export default UserSettings;
