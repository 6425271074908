import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WellnessScore } from '../../../backendsdk';
import { TrackedLink as Link } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useCallbackRef from '../../../hooks/callbackRef';
import useDevices from '../../../hooks/devices';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { useQuery as useQueryParams } from '../../../hooks/query';
import { wellnessPageDefs } from '../../../utils/Pages';
import { DEFAULT_TITLE } from '../../Layout';
import { INVALID_SCORE } from '../Metrics/SafetyWellness/MetricsComponent';
import { CARD_HEIGHT, CARD_MARGIN, CARD_WIDTH, WellnessCardProps } from './WellnessCard';
import WellnessGrid, { NAV_BUTTON_WIDTH } from './WellnessGrid';

export const behaviorsMap: Record<string, string> = {
    in_cabin_unwell: 'content.wellness.behaviors.in_cabin_unwell',
    front_facing_unwell: 'content.wellness.behaviors.front_facing_unwell',
    MsgInCabinCameraCovered: 'content.wellness.behaviors.in_cabin_camera_covered',
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const WellnessComponent: React.FC = () => {
    const [cardRows, setCardRows] = useState<number>(2);
    const [cardColumns, setCardColumns] = useState<number>(4);
    const queryParams = useQueryParams();
    const [searchText, setSearchText] = useState<string>(queryParams.get('license_plate') || '');
    const [subfleetValue, setSubfleetValue] = useState<string[]>([]);
    const [behaviorValue, setBehaviorValue] = useState<string[]>([]);
    const [ref, node] = useCallbackRef();
    const { api } = useApi();
    const { profile } = useProfile();
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const { devices, isLoading: isLoadingDevices, isError: isErrorDevices } = useDevices();
    const getScores = async (): Promise<WellnessScore[]> => {
        const { data } = await api.apiV0WellnessScoresGet({ cache: 1 });
        return data;
    };
    const {
        data: wellnessScores = [],
        isLoading: isLoadingScores,
        isError: isErrorScores,
    } = useQuery({ queryKey: ['wellness_scores'], queryFn: getScores });

    let data: WellnessCardProps[] = useMemo(() => {
        return wellnessScores.map((wellnessScore) => {
            return {
                timestamp: wellnessScore.timestamp,
                driverName: wellnessScore.driver_name,
                licensePlate: wellnessScore.license_plate,
                subFleet: wellnessScore.sub_fleet,
                score: Math.round(wellnessScore.score),
                behaviors: wellnessScore.event_types
                    .split(',')
                    .filter(Boolean)
                    .map((b) => behaviorsMap[b] || b),
                eventIds: wellnessScore.event_ids,
                icStatusIds: wellnessScore.in_cabin_wellness_ids,
                ffStatusIds: wellnessScore.front_facing_wellness_ids,
                mostRecentEventTimestamp: wellnessScore.most_recent_event_datetime,
                mostRecentEventId: wellnessScore.most_recent_event_id,
            };
        });
    }, [wellnessScores, devices]);

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${wellnessPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            if (node.offsetHeight) {
                const newRows = Math.floor(node.offsetHeight / (CARD_HEIGHT + CARD_MARGIN * 2));
                setCardRows(Math.max(1, newRows));
            }
            if (node.offsetWidth) {
                const newCols = Math.floor((node.offsetWidth - NAV_BUTTON_WIDTH * 2) / (CARD_WIDTH + CARD_MARGIN * 2));
                setCardColumns(Math.max(1, newCols));
            }
        });
        resizeObserver.observe(node);
        return () => resizeObserver.disconnect();
    }, [node]);

    if (isLoadingScores || isLoadingDevices) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress size={40} />
            </Box>
        );
    }

    if (isErrorScores || isErrorDevices) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.wellness.error')}</Typography>
            </Box>
        );
    }

    if (searchText) {
        const normalizedNameText = searchText.toLocaleLowerCase();
        const normalizedLicensePlate = searchText.toLocaleUpperCase().replaceAll('-', '');
        data = data.filter((card) => {
            return (
                card.driverName?.toLocaleLowerCase().includes(normalizedNameText) ||
                card.licensePlate.toLocaleUpperCase().replaceAll('-', '').includes(normalizedLicensePlate)
            );
        });
    }
    if (subfleetValue && subfleetValue.length > 0) {
        data = data.filter((card) => subfleetValue.includes(card.subFleet?.toLocaleLowerCase()));
    }
    if (behaviorValue && behaviorValue.length > 0) {
        data = data.filter((card) => card.behaviors.some((b) => behaviorValue.includes(b)));
    }
    const inactiveVehicles = data.filter((card) => card.score === INVALID_SCORE);
    const activeVehicles = data.filter((card) => card.score !== INVALID_SCORE).sort((a, b) => a.score - b.score);
    const sortedData = [...activeVehicles, ...inactiveVehicles];

    let content;
    if (sortedData.length === 0) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.wellness.no_data')}</Typography>
                {!!searchText || subfleetValue.length > 0 || behaviorValue.length > 0 ? (
                    <Link
                        id="clear-filters-link"
                        component="button"
                        variant="overline"
                        onClick={() => {
                            setSubfleetValue([]);
                            setBehaviorValue([]);
                            setSearchText('');
                        }}
                    >
                        {t('content.wellness.clear_filters')}
                    </Link>
                ) : null}
            </Box>
        );
    } else {
        content = <WellnessGrid vehicles={sortedData} cardRows={cardRows} cardColumns={cardColumns} ref={ref} />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <TextField
                    id="search-text-field"
                    size="small"
                    fullWidth={isMobile}
                    variant="outlined"
                    label={t('content.wellness.search')}
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                    sx={{ mr: isMobile ? 0 : 1, mb: isMobile ? 1 : 0, width: 250 }}
                />
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="sub-fleet-filter"
                        size="small"
                        options={profile.customer.sub_fleets.filter(Boolean)}
                        value={subfleetValue}
                        onChange={(_, newValue) => setSubfleetValue(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label={t('content.wellness.sub_fleet')} sx={{ width: 250 }} />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} style={{ height: 40, paddingLeft: 0 }}>
                                <Checkbox
                                    id={`${option}-checkbox`}
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        // limits the rendered tags to one chip
                        renderTags={(value: Array<string>, getTagProps: AutocompleteRenderGetTagProps) => {
                            return (
                                <>
                                    <Chip
                                        size="small"
                                        label={<Typography fontSize={12}>{value[0]}</Typography>}
                                        {...getTagProps({ index: 0 })}
                                        sx={{ borderRadius: 1, maxWidth: '160px !important' }}
                                        onDelete={undefined}
                                    />
                                    {value.length > 1 ? (
                                        <Typography variant="body2">{`+${value.length - 1}`}</Typography>
                                    ) : null}
                                </>
                            );
                        }}
                        sx={{
                            mr: isMobile ? 0 : 1,
                            mb: isMobile ? 1 : 0,
                            '& .MuiInputBase-root': { flexWrap: 'nowrap' },
                        }}
                    />
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="behaviors-filter"
                        size="small"
                        options={Object.values(behaviorsMap)}
                        value={behaviorValue}
                        onChange={(_, newValue) => setBehaviorValue(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label={t('content.wellness.behaviors.header')} sx={{ width: 250 }} />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} style={{ paddingLeft: 0 }}>
                                <Checkbox
                                    id={`${option}-checkbox`}
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {t(option)}
                            </li>
                        )}
                        // limits the rendered tags to one chip
                        renderTags={(value: Array<string>, getTagProps: AutocompleteRenderGetTagProps) => {
                            return (
                                <>
                                    <Chip
                                        size="small"
                                        label={<Typography fontSize={12}>{t(value[0])}</Typography>}
                                        {...getTagProps({ index: 0 })}
                                        sx={{ borderRadius: 1, maxWidth: '160px !important' }}
                                        onDelete={undefined}
                                    />
                                    {value.length > 1 ? (
                                        <Typography variant="body2">{`+${value.length - 1}`}</Typography>
                                    ) : null}
                                </>
                            );
                        }}
                        sx={{ '& .MuiInputBase-root': { flexWrap: 'nowrap' } }}
                    />
                </Box>
            </Box>
            {content}
        </Box>
    );
};

export default WellnessComponent;
