import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DoneIcon from '@mui/icons-material/Done';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SendIcon from '@mui/icons-material/Send';
import { Badge, BadgeProps, Box, CircularProgress, Slide, Tabs, TextField, Typography, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { FixedSizeList } from 'react-window';

import { CoachSession, ShortCoachSession } from '../../../backendsdk/api';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { TabPanel, a11yProps } from '../../../components/Tab';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
    TrackedTab as Tab,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useCallbackRef from '../../../hooks/callbackRef';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { coachingPageDefs } from '../../../utils/Pages';
import { getCustomer } from '../../../utils/customer';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE, RTLDirectionContext } from '../../Layout';
import { SortingModel } from '../Overview/FleetOverview';
import SortMenu from '../Overview/SortMenu';
import CoachingAtScaleModal from './CoachingAtScaleModal';
import CoachingOverview from './CoachingOverview';
import SessionPresentation from './Presentation/SessionPresentation';
import SessionDetails from './SessionDetails';
import SessionListRow from './SessionListRow';
import { completedSortingOptions, sentSortingOptions, sortingFunctions, sortingOptions } from './utils';

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${palette.bgColor}`,
        padding: '0 4px',
    },
}));

dayjs.extend(isSameOrBefore);

const CoachComponent: React.FC = () => {
    const { sessionId: selectedSessionId } = useParams<{ sessionId: string }>();
    const [sessions, setSessions] = useState<Array<ShortCoachSession>>([]);
    const isMobile = useIsMobile();
    const [height, setHeight] = useState<number>(870);
    const [containerRef, node] = useCallbackRef();
    const detailsContainerRef = React.useRef<HTMLDivElement>(null);
    const [tab, setTab] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [sorting, setSorting] = useState<SortingModel>({
        field: 'last_updated',
        order: 'desc',
    });
    const [sessionDates, setSessionDates] = useState<DateRange<Dayjs>>([dayjs().subtract(7, 'day'), dayjs()]);
    const [coachingAtScaleOpen, setCoachingAtScaleOpen] = useState<boolean>(false);
    const [sessionIdToDelete, setSessionIdToDelete] = useState<number>();
    const [alertElement, setAlert] = useAlert();
    const { i18n, t } = useTranslation();
    const { api } = useApi();
    const history = useHistory();
    const { profile } = useProfile();
    const isRTL = useContext(RTLDirectionContext);
    const match = useRouteMatch();
    const isPresenting = match.path.endsWith('/play');

    const queuedForSending = sessions.some((s) => s.last_delivery_status === 'queued');
    const failedToSend = sessions.some(
        (s) => s.last_delivery_status === 'failed' || s.last_delivery_status === 'undelivered',
    );

    const { data: customer, isLoading: isLoadingUsers } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['customer'],
        queryFn: () => getCustomer(api),
    });
    const customerUsers = customer?.users || [];

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${coachingPageDefs.name}`)}`;
    }, []);

    const getSessions = (startDate: Dayjs | null, endDate: Dayjs | null, inBackground = false) => {
        if (
            startDate !== null &&
            endDate !== null &&
            startDate.isValid() &&
            endDate.isValid() &&
            startDate.isSameOrBefore(endDate)
        ) {
            if (!inBackground) {
                setIsLoading(true);
                setIsError(false);
            }
            api.apiV1CoachGet({ completedFrom: startDate.unix(), completedTo: endDate.unix() })
                .then((res) => {
                    setSessions([
                        ...res.data.map((session) => ({
                            ...session,
                            driver_name: !!session.driver_name?.trim() ? session.driver_name : '',
                        })),
                    ]);
                })
                .catch(() => {
                    if (!inBackground) {
                        setIsError(true);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    };

    useEffect(() => getSessions(sessionDates[0], sessionDates[1]), [sessionDates]);

    useEffect(() => {
        if (queuedForSending) {
            const timeoutId = setTimeout(() => getSessions(sessionDates[0], sessionDates[1], true), 5000);
            return () => clearTimeout(timeoutId);
        }
    }, [queuedForSending, sessionDates]);

    useEffect(() => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            if (node.offsetHeight) {
                setHeight(node.offsetHeight);
            }
        });
        resizeObserver.observe(node);
        return () => resizeObserver.disconnect();
    }, [node]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const handleComplete = (updatedDetails: CoachSession) => {
        setSessions((prev) => {
            return prev.map((session) => {
                if (session.session_id === selectedSession?.session_id) {
                    return updatedDetails;
                } else {
                    return session;
                }
            });
        });
        setTab(tabsMap['completed']);
    };

    const filteredSessions = useMemo(() => {
        let filteredSessions = [...sessions].sort();
        if (searchText) {
            filteredSessions = filteredSessions.filter((session) => {
                const driverNameMatch = session.driver_name?.toLowerCase().includes(searchText.toLowerCase());
                const licensePlateMatch = session.license_plate?.toLowerCase().includes(searchText.toLowerCase());
                const subFleetMatch = session.sub_fleet_name?.toLowerCase().includes(searchText.toLowerCase());
                return driverNameMatch || licensePlateMatch || subFleetMatch;
            });
        }
        filteredSessions = sortingFunctions[sorting.field](filteredSessions, sorting.order);
        return filteredSessions;
    }, [sessions, searchText, sorting]);
    const toDoSessions = filteredSessions.filter(
        (session) => !session.completed_at && !session.last_sent && (!session.auto_generated || session.is_saved),
    );
    const autoSessions = filteredSessions.filter(
        (session) => !session.completed_at && !session.last_sent && session.auto_generated && !session.is_saved,
    );
    const sentSessions = filteredSessions.filter((session) => !!session.last_sent && !session.completed_at);
    const completedSessions = filteredSessions.filter((session) => !!session.completed_at);
    const selectedSession = sessions.find((session) => session.session_id === parseInt(selectedSessionId));

    const tabsData = [
        {
            name: 'todo',
            sessions: toDoSessions,
            icon: <PendingActionsIcon sx={{ fontSize: 18 }} />,
            sorting: sortingOptions,
        },
        {
            name: 'suggested',
            sessions: autoSessions,
            icon: <AutoFixHighIcon sx={{ fontSize: 18 }} />,
            sorting: sortingOptions,
        },
        {
            name: 'sent',
            sessions: sentSessions,
            icon: <MailOutlineIcon sx={{ fontSize: 18 }} />,
            sorting: sentSortingOptions,
        },
        {
            name: 'completed',
            sessions: completedSessions,
            icon: <DoneIcon sx={{ fontSize: 18 }} />,
            sorting: completedSortingOptions,
        },
    ];

    const tabsMap: Record<string, number> = Object.fromEntries(tabsData.map((tabData, idx) => [tabData.name, idx]));

    const setTabSorting = (newTab: number) => {
        if (
            (newTab === tabsMap['todo'] || newTab === tabsMap['suggested']) &&
            !sortingOptions.includes(sorting.field)
        ) {
            setSorting((prev) => ({ ...prev, field: 'last_updated' }));
        } else if (newTab === tabsMap['sent'] && !sentSortingOptions.includes(sorting.field)) {
            setSorting((prev) => ({ ...prev, field: 'last_sent' }));
        } else if (newTab === tabsMap['completed'] && !completedSortingOptions.includes(sorting.field)) {
            setSorting((prev) => ({ ...prev, field: 'completed_at' }));
        }
    };

    const setTabAndSorting = (newTab: string) => {
        const tabIdx = tabsMap[newTab];
        setTab(tabIdx);
        setTabSorting(tabIdx);
    };

    const tryAgainHandler = () => getSessions(sessionDates[0], sessionDates[1]);

    const handleDelete = (sessionId: number) => {
        setIsLoadingAction(true);
        api.apiV1CoachSessionIdDelete({ sessionId: sessionId })
            .then(() => {
                setSessions((sessions: ShortCoachSession[]) =>
                    sessions.filter((session) => session.session_id !== sessionId),
                );
                setSessionIdToDelete(undefined);
                setAlert({
                    message: t('content.coach.details.remove_session.success'),
                    type: 'success',
                    duration: 6000,
                });
                if (selectedSession && selectedSession.session_id === sessionId) {
                    history.replace(`/coaching${nextSessionId !== undefined ? `/${nextSessionId}` : ''}`);
                }
                setIsLoadingAction(false);
            })
            .catch(() => {
                setAlert({
                    message: t('content.coach.details.remove_session.failure'),
                    type: 'error',
                    duration: 6000,
                });
                setIsLoadingAction(false);
            });
    };

    let listContent: React.ReactNode = null;
    if (isLoading || isLoadingUsers || isError) {
        listContent = (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3,
                }}
            >
                {isLoading || isLoadingUsers ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Typography variant="overline">{t('content.coach.errors.load_sessions')}</Typography>
                        <Link
                            id="try-again-link"
                            component="button"
                            variant="overline"
                            onClick={() => tryAgainHandler()}
                        >
                            {t('content.coach.errors.try_again')}
                        </Link>
                    </>
                )}
            </Box>
        );
    }

    if (selectedSessionId && isPresenting) {
        return (
            <SessionPresentation
                sessionId={parseInt(selectedSessionId)}
                setAlert={setAlert}
                onComplete={handleComplete}
            />
        );
    }

    let toolbar;
    if (isMobile && !!selectedSessionId) {
        toolbar = (
            <Button
                id="btn-back"
                variant="outlined"
                color="primary"
                onClick={() => history.push('/coaching')}
                sx={{ mr: 'auto' }}
            >
                {t('content.coach.back')}
            </Button>
        );
    } else {
        toolbar = (
            <>
                <TextField
                    fullWidth={isMobile}
                    id="search-field"
                    variant="outlined"
                    size="small"
                    placeholder={t('content.fleet.search')}
                    disabled={isMobile && !!selectedSession}
                    value={searchText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                    sx={{ '& .MuiInputBase-input': { paddingY: '0px', paddingLeft: '8px' } }}
                    inputProps={{ sx: { height: 35 } }}
                />
                <SortMenu
                    options={tabsData[tab].sorting}
                    selected={sorting}
                    setSelected={setSorting}
                    disabled={isMobile && !!selectedSession}
                    translationKey="content.coach.sort"
                />
                {isMobile ? (
                    <IconButton
                        id="btn-coaching-at-scale"
                        data-testid="btn-coaching-at-scale"
                        color="secondary"
                        onClick={() => setCoachingAtScaleOpen(true)}
                        disabled={autoSessions.length === 0}
                    >
                        <SendIcon />
                    </IconButton>
                ) : (
                    <Button
                        id="btn-coaching-at-scale"
                        data-testid="btn-coaching-at-scale"
                        variant="contained"
                        color="secondary"
                        onClick={() => setCoachingAtScaleOpen(true)}
                        disabled={autoSessions.length === 0}
                        sx={{ ml: 'auto' }}
                    >
                        {t('content.coach.coaching_at_scale.header')}
                    </Button>
                )}
            </>
        );
    }

    const nextSessionIndex = tabsData[tab].sessions.findIndex((s) => s.session_id === parseInt(selectedSessionId)) + 1;
    const nextSessionId =
        nextSessionIndex < tabsData[tab].sessions.length
            ? tabsData[tab].sessions[nextSessionIndex].session_id
            : undefined;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            {coachingAtScaleOpen ? (
                <CoachingAtScaleModal
                    autoSessions={autoSessions}
                    sentSessions={sentSessions}
                    onClose={() => setCoachingAtScaleOpen(false)}
                    setSessions={setSessions}
                    setTab={setTabAndSorting}
                    setAlert={setAlert}
                    users={customerUsers}
                />
            ) : null}
            {!!sessionIdToDelete ? (
                <ConfirmDialog
                    isDialogOpen={!!sessionIdToDelete}
                    isLoading={isLoadingAction}
                    headerText={t('content.coach.details.remove_session.header')}
                    bodyText={t('content.coach.details.remove_session.body')}
                    buttonText={t('content.coach.details.remove_session.button')}
                    buttonColor="redColor"
                    onClose={() => setSessionIdToDelete(undefined)}
                    onConfirm={() => handleDelete(sessionIdToDelete)}
                />
            ) : null}
            <Box
                sx={{
                    height: 70,
                    width: '100%',
                    flexShrink: 0,
                    backgroundColor: palette.neutral[50],
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    minHeight: 0,
                    position: 'relative',
                    zIndex: 4,
                }}
            >
                {toolbar}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    minHeight: 0,
                }}
            >
                <Box sx={{ width: '100%', height: '100%', display: 'flex', position: 'relative', zIndex: 2 }}>
                    {alertElement}
                    {!isMobile || selectedSession === undefined ? (
                        <Box
                            sx={{
                                width: isMobile ? '100%' : '425px',
                                flexShrink: 0,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: palette.neutral[50],
                                boxShadow: '4px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                                zIndex: 3,
                            }}
                        >
                            <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                                sx={{ width: '100%', borderBottom: `1px solid ${palette.gray[400]}` }}
                            >
                                {tabsData.map((tabData, idx) => (
                                    <Tab
                                        key={`tab-${idx}`}
                                        data-testid={`tab-${idx}`}
                                        label={
                                            <Box
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                            >
                                                {tabData.name === 'sent' && failedToSend ? (
                                                    <StyledBadge badgeContent="!" color="error">
                                                        {tabData.icon}
                                                    </StyledBadge>
                                                ) : (
                                                    tabData.icon
                                                )}
                                                <Typography sx={{ fontSize: 14, mt: 0.75 }}>
                                                    {t(`content.coach.${tabData.name}_sessions`)}
                                                </Typography>
                                            </Box>
                                        }
                                        {...a11yProps(idx)}
                                        sx={{ flex: 1 }}
                                        onClick={() => setTabSorting(idx)}
                                    />
                                ))}
                            </Tabs>
                            <Box sx={{ height: '100%', width: '100%', minHeight: 0 }}>
                                {tabsData.map((tabData, idx) => (
                                    <TabPanel
                                        key={`tab-panel-${idx}`}
                                        value={tab}
                                        index={idx}
                                        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                    >
                                        {tabData.name === 'completed' ? (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    flexShrink: 0,
                                                    borderBottom: `1px solid ${palette.gray[400]}`,
                                                    p: 2,
                                                    minHeight: 0,
                                                }}
                                            >
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                    adapterLocale={i18n.languages[0]}
                                                >
                                                    <DateRangePicker
                                                        slots={{ field: SingleInputDateRangeField }}
                                                        label={t('content.coach.sort.completed_at')}
                                                        slotProps={{
                                                            textField: {
                                                                id: 'request-dates-picker',
                                                                inputProps: { 'data-testid': 'request-dates-picker' },
                                                                fullWidth: true,
                                                                size: 'small',
                                                            },
                                                        }}
                                                        format={profile.dateFormat}
                                                        value={sessionDates}
                                                        onChange={(newValue) => setSessionDates(newValue)}
                                                        disableFuture
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        ) : null}
                                        <Box sx={{ width: '100%', height: '100%', minHeight: 0 }} ref={containerRef}>
                                            {!!listContent ? (
                                                listContent
                                            ) : tabData.sessions.length > 0 ? (
                                                <FixedSizeList
                                                    height={height}
                                                    width="100%"
                                                    itemSize={125}
                                                    itemCount={tabData.sessions.length}
                                                    overscanCount={5}
                                                    itemData={{
                                                        sessions: tabData.sessions,
                                                        selectedSessionId,
                                                        users: customerUsers,
                                                        setSessionIdToDelete,
                                                        canRemoveSession: tab !== tabsMap['completed'],
                                                    }}
                                                >
                                                    {SessionListRow}
                                                </FixedSizeList>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        p: 3,
                                                    }}
                                                >
                                                    <Typography variant="overline">
                                                        {t('content.coach.errors.no_sessions')}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </TabPanel>
                                ))}
                            </Box>
                        </Box>
                    ) : undefined}
                    {!isMobile || !!selectedSessionId ? (
                        <Box
                            sx={{ height: '100%', width: isMobile ? '100%' : '475px', flexShrink: 0 }}
                            ref={detailsContainerRef}
                        >
                            <Slide
                                direction={isRTL ? 'left' : 'right'}
                                in={!!selectedSessionId}
                                container={detailsContainerRef.current}
                                timeout={300}
                                easing={'ease-out'}
                                appear={false}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        boxShadow: '2px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                                    }}
                                >
                                    {!!selectedSessionId ? (
                                        <SessionDetails
                                            sessionId={parseInt(selectedSessionId)}
                                            sessions={sessions}
                                            setSessions={setSessions}
                                            setTab={setTabAndSorting}
                                            setAlert={setAlert}
                                            users={customerUsers}
                                            nextSessionId={nextSessionId}
                                            setSessionIdToDelete={setSessionIdToDelete}
                                        />
                                    ) : null}
                                </Box>
                            </Slide>
                        </Box>
                    ) : null}
                </Box>
                {!isMobile ? (
                    <Box sx={{ position: 'relative', zIndex: 1 }}>
                        <CoachingOverview
                            isLoading={isLoading || isLoadingUsers}
                            isError={isError}
                            onTryAgain={tryAgainHandler}
                            toDoSessions={toDoSessions}
                            autoSessions={autoSessions}
                            sentSessions={sentSessions}
                            users={customerUsers}
                            tabsMap={tabsMap}
                            setTab={setTab}
                        />
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};

export default CoachComponent;
