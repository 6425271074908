const valueWithExpiration = (value: string, expiration: Date): string => {
    return `${value};${expiration.toUTCString()}`;
};

const getValueWithExpiration = (rawValue: string): string | undefined => {
    const parts = rawValue.split(';');
    if (parts.length === 2) {
        const expirationDate = new Date(parts[1]);
        if (expirationDate.getTime() > Date.now()) {
            return parts[0];
        }
    }
    return undefined;
};

export const setStorageItem = (name: string, val: string, expirationHours = 8): void => {
    const expirationDate = new Date();
    const value = val;
    const expirationTime = expirationHours * 60 * 60 * 1000;

    expirationDate.setTime(expirationDate.getTime() + expirationTime);

    // Set it
    localStorage.setItem(name, valueWithExpiration(value, expirationDate));
};

export const getStorageItem = (name: string): string | undefined => {
    const rawValue = localStorage.getItem(name);
    if (rawValue) {
        const value = getValueWithExpiration(rawValue);
        if (value) {
            return value;
        } else {
            removeStorageItem(name);
        }
    }
    return undefined;
};

export const removeStorageItem = (name: string): void => {
    localStorage.removeItem(name);
};
