import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import {
    Autocomplete,
    Box,
    CircularProgress,
    Fade,
    LinearProgress,
    Popper,
    PopperProps,
    Slide,
    TextField,
    Typography,
    alpha,
} from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import {
    GridCellModes,
    GridCellModesModel,
    GridColDef,
    GridFilterModel,
    GridLogicOperator,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridToolbarFilterButton,
    GridValueFormatterParams,
    getGridSingleSelectOperators,
    getGridStringOperators,
    useGridApiContext,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { VariableSizeList } from 'react-window';

import {
    AggregatedMalfunctionsHistory,
    ApiApi,
    ExtendedCustomerDataAllOfUsers,
    Malfunction,
    MalfunctionTypeEnum,
} from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedDataGrid as StyledDataGrid,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useCallbackRef from '../../../hooks/callbackRef';
import useDevices from '../../../hooks/devices';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { getDateTimeColumnType, localeObjectMap } from '../../../utils/DataGridDateTime';
import { flattenRoles, malfunctionsPageDefs } from '../../../utils/Pages';
import { normalizeLicensePlate } from '../../../utils/Str';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from '../../../utils/TimeFormatter';
import { getCustomer } from '../../../utils/customer';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { getUserAvatar } from '../Coaching/SessionDetails';
import { filterColumns, getColumnForNewFilter, gridLocalization } from '../OTA/MuiDeviceTable';
import AssignmentModal from './AssignmentModal';
import MalfunctionDetails from './MalfunctionDetails';
import MalfunctionListRow from './MalfunctionListRow';
import MalfunctionsFilter from './MalfunctionsFilter';
import MalfunctionsOverview from './MalfunctionsOverview';

const CustomPopper = (props: PopperProps) => {
    return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
};

export const getMalfunctions = async (api: ApiApi) => {
    const res = await api.apiV0MalfunctionGet();
    return res.data;
};

interface GridRenderWithUserOptions extends GridRenderCellParams {
    options: ExtendedCustomerDataAllOfUsers[];
}

export interface MalfunctionRow {
    id: number;
    malfunction: MalfunctionTypeEnum;
    license_plate: string;
    sub_fleet: string;
    assignee: number | null;
    since: Date;
    last_updated: Date;
    comment_count: number;
}

interface MalfunctionDetailsWrapperProps {
    malfunctions: Malfunction[];
    vehicleMalfunctions: Record<string, Malfunction[]>;
    setSelectionModel: CallableFunction;
    onClose: CallableFunction;
    users: ExtendedCustomerDataAllOfUsers[];
    setAlert: CallableFunction;
    isLoadingUsers: boolean;
    isErrorUsers: boolean;
}

const MalfunctionDetailsWrapper: React.FC<MalfunctionDetailsWrapperProps> = (props) => {
    const history = useHistory();
    const { malfunctionId } = useParams<{ malfunctionId: string }>();
    const selectedMalfunction = props.malfunctions.find((m) => m.id.toString() === malfunctionId);

    useEffect(() => {
        props.setSelectionModel(selectedMalfunction ? [selectedMalfunction.id] : []);
    }, [selectedMalfunction]);

    if (!selectedMalfunction) {
        if (props.malfunctions.length > 0) {
            history.replace('/malfunctions');
        }
        return null;
    }

    return (
        <MalfunctionDetails
            malfunction={selectedMalfunction}
            vehicleMalfunctions={props.vehicleMalfunctions[selectedMalfunction.license_plate] || []}
            onClose={props.onClose}
            users={props.users}
            setAlert={props.setAlert}
            isLoadingUsers={props.isLoadingUsers}
            isErrorUsers={props.isErrorUsers}
        />
    );
};

interface QuickSearchToolbarProps {
    searchText: string;
    setSearchText: CallableFunction;
    onRefresh: CallableFunction;
    selectedMalfunctionIds: number[];
    onAssign: CallableFunction;
}

const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'start' : 'end',
                py: 1,
                backgroundColor: palette.bgColor,
            }}
        >
            <TextField
                fullWidth={isMobile}
                id="table-search-field"
                variant="standard"
                value={props.searchText}
                onChange={(e: ChangeEvent<HTMLInputElement>) => props.setSearchText(e.target.value)}
                size="small"
                placeholder={t('table.search')}
                InputProps={{
                    startAdornment: <SearchIcon color="primary" fontSize="small" sx={{ mb: 0.5, mr: 0.25 }} />,
                }}
                sx={{ mr: isMobile ? 0 : 1, mb: isMobile ? 1 : 0 }}
            />
            <GridToolbarFilterButton componentsProps={{ button: { variant: 'outlined', id: 'filters-btn' } }} />
            <IconButton
                id="refresh-btn"
                onClick={() => props.onRefresh()}
                sx={{ ml: 0.5, height: '32px', width: '32px' }}
            >
                <RefreshIcon />
            </IconButton>
            <Button
                id="btn-bulk-assign"
                data-testid="btn-bulk-assign"
                variant="contained"
                size="small"
                disabled={props.selectedMalfunctionIds.length === 0}
                onClick={() => props.onAssign()}
                sx={{ height: '32px', ml: 'auto' }}
                startIcon={<AssignmentIndIcon />}
            >
                {t('content.malfunctions.assign')}
            </Button>
        </Box>
    );
};

const MalfunctionsComponent: React.FC = () => {
    const { api } = useApi();
    const {
        data,
        isLoading: isLoadingMalfunctions,
        isFetching: isFetchingMalfunctions,
        isError: isErrorMalfunctions,
    } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['malfunctions'],
        queryFn: () => getMalfunctions(api),
        staleTime: MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * 5,
    });
    const malfunctions = data || ([] as Malfunction[]);
    const [aggregatedMalfunctions, setAggregatedMalfunctions] = useState<AggregatedMalfunctionsHistory>();
    const [isLoadingAggregatedMalfunctions, setIsLoadingAggregatedMalfunctions] = useState<boolean>(true);
    const [isErrorAggregatedMalfunctions, setIsErrorAggregatedMalfunctions] = useState<boolean>(false);
    const { devices, isLoading: isLoadingDevices, isError: isErrorDevices } = useDevices();
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const { profile } = useProfile();
    const { i18n, t } = useTranslation();
    const isMobile = useIsMobile();
    const locale = localeObjectMap[i18n.languages[0]];
    const history = useHistory();
    const [alertElement, setAlert] = useAlert();
    const [containerRef, node] = useCallbackRef();
    const listRef = useRef<VariableSizeList>(null);
    const [height, setHeight] = useState<number>(870);
    const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState<boolean>(false);
    const [isLoadingAssignee, setIsLoadingAssignee] = useState<boolean>(false);
    const [isSelectMode, setIsSelectMode] = useState<boolean>(false);

    const {
        data: customer,
        isLoading: isLoadingUsers,
        isError: isErrorUsers,
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
    } = useQuery({ queryKey: ['customer'], queryFn: () => getCustomer(api) });
    const customerUsers = customer?.users || [];
    const usersById = customerUsers.reduce((acc, user) => {
        acc[user.user_id] = user;
        return acc;
    }, {} as Record<number, ExtendedCustomerDataAllOfUsers>);

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${malfunctionsPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            if (node.offsetHeight) {
                setHeight(node.offsetHeight);
            }
        });
        resizeObserver.observe(node);
        return () => resizeObserver.disconnect();
    }, [node]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [searchText, filterModel]);

    const malfunctionsByLicensePlate = malfunctions.reduce((acc, m) => {
        const licensePlate = m.license_plate;
        if (acc[licensePlate] === undefined) {
            acc[licensePlate] = [m];
        } else {
            acc[licensePlate].push(m);
        }
        return acc;
    }, {} as { [licensePlate: string]: Malfunction[] });

    const getAggregatedMalfunctions = () => {
        setIsErrorAggregatedMalfunctions(false);
        setIsLoadingAggregatedMalfunctions(true);
        api.apiV0MalfunctionAggregatedGet()
            .then((res) => {
                setAggregatedMalfunctions(res.data);
            })
            .catch(() => setIsErrorAggregatedMalfunctions(true))
            .finally(() => setIsLoadingAggregatedMalfunctions(false));
    };

    useEffect(getAggregatedMalfunctions, []);

    const handleAssign = (malfunctionIds: number[], userId: number) => {
        setIsLoadingAssignee(true);
        api.apiV0MalfunctionPatch({
            malfunctionAssigneeRequest: { malfunctions: malfunctionIds, assignee: userId },
        })
            .then(() => {
                queryClient.setQueryData<Malfunction[]>(['malfunctions'], (prev) =>
                    (prev || []).map((malfunction) => {
                        if (malfunctionIds.includes(malfunction.id)) {
                            return {
                                ...malfunction,
                                assignee: userId,
                            };
                        }
                        return malfunction;
                    }),
                );
                setIsLoadingAssignee(false);
                setIsAssignmentModalOpen(false);
            })
            .catch(() => {
                setAlert({
                    message: t(
                        `content.malfunctions.details.error_assigning${malfunctionIds.length > 1 ? '_plural' : ''}`,
                    ),
                    type: 'error',
                    duration: 6000,
                });
                setIsLoadingAssignee(false);
            });
    };

    const handleCellClick = React.useCallback((params: GridCellParams, event: React.MouseEvent) => {
        if (!params.isEditable) {
            return;
        }
        // Ignore portal
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
            return;
        }
        event.stopPropagation();
        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {},
                        ),
                    }),
                    {},
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                        {},
                    ),
                    [params.field]: { mode: GridCellModes.Edit },
                },
            };
        });
    }, []);

    const handleCellModesModelChange = React.useCallback((newModel: GridCellModesModel) => {
        setCellModesModel(newModel);
    }, []);

    const AutocompleteInputCell = (props: GridRenderWithUserOptions) => {
        const [open, setOpen] = useState<boolean>(true);
        const { id, field, value } = props;
        const apiRef = useGridApiContext();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleChange = async (_event: any, newValue: string) => {
            await apiRef.current.setEditCellValue({ id, field, value: newValue });
            apiRef.current.stopCellEditMode({ id, field });
            api.apiV0MalfunctionPatch({
                malfunctionAssigneeRequest: { malfunctions: [props.row.id], assignee: parseInt(newValue) },
            }).catch(() => {
                setAlert({
                    message: t('content.malfunctions.details.error_assigning'),
                    type: 'error',
                    duration: 6000,
                });
                apiRef.current.updateRows([
                    { id: props.row.id, assignee: malfunctions.find((m) => m.id === id)?.assignee },
                ]);
            });
        };

        return (
            <Autocomplete
                id={`malfunctions-data-grid-cell-${id}-${field}`}
                PopperComponent={CustomPopper}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                fullWidth
                value={value || ''}
                isOptionEqualToValue={(option, value) => option === value || value === ''}
                onChange={handleChange}
                getOptionLabel={(option) => usersById[option]?.name || ''}
                renderOption={(props, option) => {
                    const user = usersById[option];
                    return (
                        <li
                            id={`user-${user.user_id}-list-item`}
                            data-testid={`user-${user.user_id}-list-item`}
                            {...props}
                            key={user.user_id}
                            aria-selected={option === value}
                        >
                            {getUserAvatar(user)}
                            <Typography variant="body2">{user.name}</Typography>
                        </li>
                    );
                }}
                options={props.options
                    .filter(
                        (u) =>
                            flattenRoles(malfunctionsPageDefs).includes(u.role) &&
                            (!props.row.malfunction.sub_fleet ||
                                !u.sub_fleet ||
                                props.row.malfunction.sub_fleet === u.sub_fleet),
                    )
                    .map((u) => u.user_id)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        data-testid={'autocomplete-input'}
                        fullWidth
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            disableUnderline: true,
                            // match input style with the cell
                            style: { paddingLeft: '10px', paddingRight: '9px', fontSize: '0.875rem' },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                )}
                sx={{
                    height: '100%',
                    '& .MuiFormControl-root': { height: '100%' },
                    '& .MuiAutocomplete-endAdornment': { paddingRight: '12px' },
                }}
            />
        );
    };

    const renderAutocompleteEditAssignee = (params: GridRenderCellParams, users: ExtendedCustomerDataAllOfUsers[]) => {
        return <AutocompleteInputCell options={users} {...params} />;
    };

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'malfunction',
                headerName: t('content.malfunctions.table.malfunction'),
                type: 'singleSelect',
                valueOptions: Object.values(MalfunctionTypeEnum).map((type) => ({
                    value: type,
                    label: t(`content.malfunctions.table.type.${type.toLocaleLowerCase()}`),
                })),
                valueFormatter: (params: GridValueFormatterParams) =>
                    t(`content.malfunctions.table.type.${params.value.toLocaleLowerCase()}`),
                flex: 1,
                minWidth: 150,
                filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
            },
            {
                field: 'license_plate',
                headerName: t('content.malfunctions.table.license_plate'),
                flex: 1,
                minWidth: 150,
                filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals'),
            },
            {
                field: 'sub_fleet',
                headerName: t('content.malfunctions.table.sub_fleet'),
                type: 'singleSelect',
                valueOptions: profile.customer.sub_fleets,
                flex: 1,
                minWidth: 150,
                filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
            },
            {
                field: 'assignee',
                headerName: t('content.malfunctions.table.assignee'),
                type: 'singleSelect',
                valueOptions: customerUsers.map((user) => ({
                    value: user.user_id,
                    label: user.name,
                })),
                flex: 1,
                minWidth: 150,
                editable: true,
                filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
                renderCell: (params) => {
                    if (!params.value || !usersById[params.value]) {
                        return null;
                    }
                    return (
                        <Box
                            id={`malfunction-${params.id}-assignee`}
                            data-testid={`malfunction-${params.id}-assignee`}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {getUserAvatar(usersById[params.value])}
                            <Typography fontSize={14} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {usersById[params.value].name}
                            </Typography>
                        </Box>
                    );
                },
                renderEditCell: (params) => renderAutocompleteEditAssignee(params, customerUsers),
            },
            {
                field: 'since',
                headerName: t('content.malfunctions.table.since'),
                ...getDateTimeColumnType(locale),
                renderCell: (params) => {
                    return dayjs(params.value as Date).format(profile.dateTimeFormat);
                },
                flex: 1,
                minWidth: 150,
            },
            {
                field: 'last_updated',
                headerName: t('content.malfunctions.table.last_updated'),
                ...getDateTimeColumnType(locale),
                renderCell: (params) => {
                    return dayjs(params.value as Date).format(profile.dateTimeFormat);
                },
                flex: 1,
                minWidth: 150,
            },
            {
                field: 'comment_count',
                headerName: t('content.malfunctions.table.comments'),
                type: 'number',
                align: 'left',
                headerAlign: 'left',
                minWidth: 125,
                renderCell: (params) => {
                    const hasComments = !!params.value;
                    return (
                        <Box
                            id={`malfunction-${params.id}-comments`}
                            data-testid={`malfunction-${params.id}-comments`}
                            data-value={params.value}
                            sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
                        >
                            <ChatBubbleIcon
                                sx={{
                                    fontSize: 28,
                                    color: hasComments ? palette.secondary : palette.neutral[200],
                                }}
                            />
                            {!!params.value ? (
                                <Box sx={{ position: 'absolute', top: 2.5, left: 3, width: 70 / 3 }}>
                                    <Typography fontSize={12} textAlign="center" fontWeight={500}>
                                        {params.value}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                    );
                },
            },
        ];
    }, [profile, customerUsers]);

    const filteredRows: MalfunctionRow[] = useMemo(() => {
        const rows = malfunctions.map((malfunction) => {
            return {
                id: malfunction.id,
                malfunction: malfunction.type,
                license_plate: malfunction.license_plate,
                sub_fleet: malfunction.sub_fleet || '',
                assignee: malfunction.assignee,
                since: dayjs.unix(malfunction.since).toDate(),
                last_updated: dayjs.unix(malfunction.updated_at).toDate(),
                comment_count: malfunction.comment_count,
            };
        });
        let filteredRows = rows;
        if (searchText) {
            filteredRows = rows.filter((row) => {
                const malfunctionMatch = t(`content.malfunctions.table.type.${row.malfunction.toLocaleLowerCase()}`)
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase());
                const licensePlateMatch = normalizeLicensePlate(row.license_plate).includes(
                    normalizeLicensePlate(searchText),
                );
                const subFleetMatch = row.sub_fleet.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
                return malfunctionMatch || licensePlateMatch || subFleetMatch;
            });
        }
        return filteredRows;
    }, [malfunctions, searchText]);

    const handleRefresh = () => {
        void queryClient.invalidateQueries(['malfunctions']);
    };

    let mainContent;
    if (isMobile) {
        if (isLoadingMalfunctions || isLoadingUsers) {
            mainContent = (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            );
        } else {
            let filteredListRows = filteredRows;
            for (const filter of filterModel.items || []) {
                if (['malfunction', 'license_plate', 'sub_fleet', 'assignee'].includes(filter.field)) {
                    filteredListRows = filteredListRows.filter(
                        (row) => row[filter.field as keyof MalfunctionRow] === filter.value,
                    );
                }
            }
            mainContent = (
                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 2,
                            minHeight: 0,
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            backgroundColor: palette.neutral[50],
                        }}
                    >
                        <TextField
                            fullWidth
                            size="small"
                            id="search-field-mobile"
                            placeholder={t('content.fleet.search')}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Button
                            id="btn-filter"
                            variant={filterModel.items?.length || isFilterOpen ? 'contained' : 'outlined'}
                            startIcon={<FilterListIcon />}
                            onClick={() => setIsFilterOpen((prev) => !prev)}
                            sx={{ height: '40px', flexShrink: 0, ml: 1 }}
                        >
                            {t('table.filter')}
                        </Button>
                        <IconButton
                            id="refresh-btn"
                            onClick={() => handleRefresh()}
                            sx={{ ml: 0.5, height: '32px', width: '32px', flexShrink: 0 }}
                        >
                            <RefreshIcon />
                        </IconButton>
                    </Box>
                    <Box ref={containerRef} sx={{ width: '100%', height: '100%', minHeight: 0, position: 'relative' }}>
                        <Fade in={isFilterOpen}>
                            <Box
                                onClick={() => setIsFilterOpen(false)}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: alpha(palette.black, 0.25),
                                    position: 'absolute',
                                    top: 0,
                                    zIndex: 2,
                                }}
                            ></Box>
                        </Fade>
                        {filteredListRows.length > 0 ? (
                            <VariableSizeList
                                height={height}
                                width="100%"
                                itemSize={() => 140}
                                itemCount={filteredRows.length}
                                overscanCount={5}
                                itemData={{
                                    rows: filteredListRows,
                                    usersById,
                                    selectionModel,
                                    setSelectionModel,
                                    isSelectMode,
                                    setIsSelectMode,
                                }}
                                ref={listRef}
                            >
                                {MalfunctionListRow}
                            </VariableSizeList>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                                <Typography variant="overline">
                                    {isErrorMalfunctions
                                        ? t('content.malfunctions.error_loading_malfunctions')
                                        : t('content.malfunctions.no_malfunctions_found')}
                                </Typography>
                            </Box>
                        )}
                        <MalfunctionsFilter
                            isOpen={isFilterOpen}
                            filterModel={filterModel}
                            setFilterModel={setFilterModel}
                            licensePlates={devices.map((d) => d.device.license_plate)}
                            users={customerUsers.reduce((acc, user) => {
                                acc[user.user_id] = user;
                                return acc;
                            }, {} as Record<number, ExtendedCustomerDataAllOfUsers>)}
                            onClose={() => setIsFilterOpen(false)}
                        />
                    </Box>
                    <Slide direction="up" in={isSelectMode} mountOnEnter unmountOnExit>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                zIndex: 2,
                                backgroundColor: palette.bgColor,
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                                p: 1,
                            }}
                        >
                            <Typography>
                                {selectionModel.length > 1
                                    ? t('content.malfunctions.malfunctions_selected', { count: selectionModel.length })
                                    : t('content.malfunctions.malfunction_selected')}
                            </Typography>
                            <Button
                                id="btn-bulk-assign"
                                variant="contained"
                                onClick={() => setIsAssignmentModalOpen(true)}
                                startIcon={<AssignmentIndIcon />}
                            >
                                {t('content.malfunctions.assign')}
                            </Button>
                        </Box>
                    </Slide>
                </Box>
            );
        }
    } else {
        mainContent = (
            <Box sx={{ flex: 1, height: '100%', p: 2, minWidth: 0 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                    <StyledDataGrid
                        id="malfunctions-data-grid"
                        checkboxSelection
                        columns={columns}
                        columnBuffer={8}
                        rows={filteredRows}
                        density="compact"
                        loading={isLoadingMalfunctions || isFetchingMalfunctions || isLoadingUsers}
                        disableRowSelectionOnClick
                        disableMultipleRowSelection
                        showCellVerticalBorder
                        showColumnVerticalBorder
                        cellModesModel={cellModesModel}
                        onCellModesModelChange={handleCellModesModelChange}
                        onCellClick={handleCellClick}
                        rowSelectionModel={selectionModel}
                        onRowClick={(params) => {
                            setSelectionModel([params.id]);
                            history.push(`/malfunctions/${params.id}`);
                        }}
                        onRowSelectionModelChange={(newSelection) => {
                            setSelectionModel(newSelection);
                            if (newSelection.length === 1) {
                                history.push(`/malfunctions/${newSelection[0]}`);
                            } else {
                                history.push('/malfunctions');
                            }
                        }}
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) => {
                            setFilterModel(newModel);
                        }}
                        localeText={
                            {
                                ...gridLocalization[i18n.languages[0]],
                                filterPanelRemoveAll: t('table.remove_all'),
                                columnMenuManageColumns: t('table.manage_columns'),
                                unpin: t('table.unpin'),
                                filterOperatorOnDate: t('table.on_date'),
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            } as any
                        }
                        slots={{
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () => (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="overline">
                                        {isErrorMalfunctions
                                            ? t('content.malfunctions.error_loading_malfunctions')
                                            : t('content.malfunctions.no_malfunctions_found')}
                                    </Typography>
                                </Box>
                            ),
                            toolbar: QuickSearchToolbar,
                        }}
                        slotProps={{
                            filterPanel: {
                                filterFormProps: {
                                    filterColumns: filterColumns,
                                    deleteIconProps: { id: 'delete-icon' },
                                    columnInputProps: { id: 'column-input' },
                                    operatorInputProps: { id: 'operator-input' },
                                    valueInputProps: { InputComponentProps: { id: 'value-input' } },
                                    logicOperatorInputProps: { sx: { display: 'none' } },
                                },
                                logicOperators: [GridLogicOperator.And],
                                getColumnForNewFilter,
                            },
                            toolbar: {
                                searchText,
                                setSearchText,
                                onRefresh: handleRefresh,
                                selectedMalfunctionIds: selectionModel,
                                onAssign: () => setIsAssignmentModalOpen(true),
                            },
                        }}
                        disableVirtualization={isMobile || process.env.JEST_WORKER_ID !== undefined}
                        sx={{
                            height: '100%',
                            width: '100%',
                            maxWidth: '1200px',
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            backgroundColor: palette.neutral[50],
                            '& .MuiDataGrid-overlayWrapperInner': {
                                height: isLoadingMalfunctions || isFetchingMalfunctions ? '4px !important' : undefined,
                            },
                        }}
                    />
                </LocalizationProvider>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
            {alertElement}
            {isAssignmentModalOpen ? (
                <AssignmentModal
                    open={isAssignmentModalOpen}
                    onClose={() => setIsAssignmentModalOpen(false)}
                    users={customerUsers}
                    selectedMalfunctions={malfunctions.filter((m) => selectionModel.includes(m.id))}
                    onAssign={(userId: number) => handleAssign(selectionModel as number[], userId)}
                    isLoading={isLoadingAssignee}
                />
            ) : null}
            {mainContent}
            <Switch>
                <Route path={`/malfunctions/:malfunctionId/:modeStr?`}>
                    <MalfunctionDetailsWrapper
                        key={`malfunction-details-${selectionModel[0]}`}
                        malfunctions={malfunctions}
                        vehicleMalfunctions={malfunctionsByLicensePlate}
                        onClose={() => setSelectionModel([])}
                        setSelectionModel={setSelectionModel}
                        users={customerUsers}
                        setAlert={setAlert}
                        isLoadingUsers={isLoadingUsers}
                        isErrorUsers={isErrorUsers}
                    />
                </Route>
            </Switch>
            {isMobile ? undefined : (
                <MalfunctionsOverview
                    malfunctions={malfunctions}
                    aggregatedMalfunctions={aggregatedMalfunctions}
                    devices={devices}
                    isLoading={isLoadingMalfunctions || isLoadingDevices}
                    isLoadingAggregated={isLoadingAggregatedMalfunctions}
                    isError={isErrorMalfunctions || isErrorDevices}
                    isErrorAggregated={isErrorAggregatedMalfunctions}
                    onRetry={() => {
                        if (isErrorMalfunctions) {
                            queryClient.invalidateQueries(['malfunctions']);
                        }
                        if (isErrorAggregatedMalfunctions) {
                            getAggregatedMalfunctions();
                        }
                        if (isErrorDevices) {
                            queryClient.invalidateQueries(['devices']);
                        }
                    }}
                    setFilterModel={(type: MalfunctionTypeEnum) => {
                        setSelectionModel([]);
                        setFilterModel({
                            items: [{ field: 'malfunction', operator: 'is', value: type }],
                        });
                    }}
                />
            )}
        </Box>
    );
};

export default MalfunctionsComponent;
