import { CSSObject, Theme, styled, useMediaQuery, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import useApi from '../../hooks/api';
import useProfile from '../../hooks/profile';
import {
    PageDefs,
    Section,
    flattenRoles,
    fleetOverviewPageDefs,
    isAllowedCustomer,
    malfunctionsPageDefs,
    sections,
} from '../../utils/Pages';
import { getStorageItem, setStorageItem } from '../../utils/Storage';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from '../../utils/TimeFormatter';
import palette from '../ColorPalette';
import { getMalfunctions } from '../content/Malfunctions/MalfunctionsComponent';
import FullContent from './FullContent';
import MinifiedContent from './MinifiedContent';
import MobileNavigator from './MobileNavigator';

export const LANGUAGES: Record<string, string> = {
    en: 'English',
    he: 'עברית',
};

export const LANGUAGES_OPTIONS = Object.keys(LANGUAGES);

export const RTL_LANGUAGES = ['he'];

export interface NavigatorProps {
    pagesInSections: Record<Section, PageDefs[]>;
    onLogout: CallableFunction;
}

const DRAWER_WIDTH = 250;

export const MINIFIED_WIDTH = 50;

export const DEFAULT_PAGE = fleetOverviewPageDefs.path[fleetOverviewPageDefs.path.length - 1];

const openedMixin = (theme: Theme): CSSObject => ({
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => {
    return {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: MINIFIED_WIDTH,
        [theme.breakpoints.up('sm')]: {
            width: MINIFIED_WIDTH,
        },
    };
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const Navigator: React.FC<NavigatorProps> = (props: NavigatorProps) => {
    const { api } = useApi();
    const { profile } = useProfile();
    const { data } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['malfunctions'],
        queryFn: () => getMalfunctions(api),
        enabled: flattenRoles(malfunctionsPageDefs).includes(profile.role),
        staleTime: MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * 5,
        retry: false,
    });
    const malfunctions = data || [];
    const [openDrawer, setOpenDrawer] = useState<boolean>(getStorageItem('OE-Navbar') !== 'mini');
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

    const availablePages = Object.fromEntries(
        Object.entries(props.pagesInSections).map(([section, pages]) => {
            const pageList = pages.filter(
                (page: PageDefs) => flattenRoles(page).includes(profile.role) && isAllowedCustomer(page, profile),
            );

            return [section, pageList];
        }),
    );

    const availableSections = sections.filter((section) => availablePages[section]?.length > 0);

    useEffect(() => {
        setStorageItem('OE-Navbar', openDrawer ? 'full' : 'mini');
    }, [openDrawer]);

    return isMobile ? (
        <MobileNavigator pagesInSections={props.pagesInSections} onLogout={props.onLogout} />
    ) : (
        <Drawer
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    backgroundColor: 'primary.main',
                    color: palette.white,
                },
                boxShadow:
                    '2px 0px 4px -1px rgba(0,0,0,0.2),4px 0px 5px 0px rgba(0,0,0,0.14),1px 0px 10px 0px rgba(0,0,0,0.12)',
                zIndex: 10,
            }}
            variant="permanent"
            anchor="left"
            open={openDrawer}
        >
            {openDrawer ? (
                <FullContent
                    setOpenDrawer={setOpenDrawer}
                    onLogout={props.onLogout}
                    availableSections={availableSections}
                    availablePages={availablePages}
                    malfunctions={malfunctions}
                />
            ) : (
                <MinifiedContent
                    setOpenDrawer={setOpenDrawer}
                    onLogout={props.onLogout}
                    availableSections={availableSections}
                    availablePages={availablePages}
                />
            )}
        </Drawer>
    );
};

export default Navigator;
