import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Badge, Box, Fab, Menu, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useApi from '../../hooks/api';
import palette from '../../layout/ColorPalette';
import { eventFeedPageDefs, malfunctionsPageDefs } from '../../utils/Pages';
import { MILLISECONDS_IN_SECOND } from '../../utils/TimeFormatter';
import { TrackedMenuItem as MenuItem } from '../TrackedComponents';
import HelpVideos from './HelpVideos';
import ReleaseNotesComponent, { CURRENT_VERSION, RELEASE_NOTES_KEY } from './ReleaseNotesComponent';

const PAGES_WITHOUT_HELP = [malfunctionsPageDefs, eventFeedPageDefs];

const HelpComponent: React.FC = () => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const initialValue = localStorage.getItem(RELEASE_NOTES_KEY);
    const [lastViewed, setLastViewed] = useState<string | null>(initialValue);
    const location = useLocation();
    const { t } = useTranslation();
    const { api } = useApi();
    const { data: helpVideos } = useQuery({
        queryKey: ['help-videos'],
        queryFn: async () => {
            const res = await api.apiV0HelpGet();
            return res.data;
        },
        staleTime: 600 * MILLISECONDS_IN_SECOND,
        refetchInterval: 600 * MILLISECONDS_IN_SECOND,
    });

    useEffect(() => {
        window.addEventListener('storage', () => {
            setLastViewed(localStorage.getItem(RELEASE_NOTES_KEY));
        });
    }, []);

    const currentPage = location.pathname.split('/')[1];

    const isMenuOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const relevantVideos = useMemo(
        () => (helpVideos || []).filter((url) => currentPage && url.split('/')[5] === currentPage),
        [helpVideos, currentPage],
    );
    const hasVideos = relevantVideos.length > 0;
    const hasNotification = lastViewed !== CURRENT_VERSION;

    let fab = (
        <Fab
            id="btn-help"
            data-testid="btn-help"
            className={hasNotification ? 'pulse' : ''}
            size="small"
            color="primary"
            onClick={handleClick}
        >
            <QuestionMarkIcon />
        </Fab>
    );

    if (hasNotification) {
        fab = (
            <Badge
                color="secondary"
                overlap="circular"
                badgeContent=" "
                slotProps={{ badge: { style: { zIndex: 1100, height: '12px', minWidth: '12px' } } }}
            >
                {fab}
            </Badge>
        );
    }

    if (PAGES_WITHOUT_HELP.some((page) => location.pathname.includes(page.path[page.path.length - 1]))) {
        return null;
    }

    return (
        <>
            {hasVideos ? (
                <HelpVideos
                    isOpen={isVideoOpen}
                    setIsOpen={setIsVideoOpen}
                    currentPage={currentPage}
                    relevantVideos={relevantVideos}
                />
            ) : null}
            {isReleaseNotesOpen ? (
                <ReleaseNotesComponent onClose={() => setIsReleaseNotesOpen(false)} setLastViewed={setLastViewed} />
            ) : null}
            {fab}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        backgroundColor: 'primary.light',
                        color: palette.white,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            bottom: -10,
                            right: 19,
                            width: 10,
                            height: 10,
                            bgcolor: 'primary.light',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 1,
                        },
                    },
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                anchorOrigin={{
                    horizontal: 44,
                    vertical: -10,
                }}
                sx={{ pb: 1.5 }}
            >
                {hasVideos ? (
                    <MenuItem
                        id="btn-help-videos"
                        data-testid="btn-help-videos"
                        onClick={() => {
                            setIsVideoOpen(true);
                            handleMenuClose();
                        }}
                    >
                        <Typography>{t('content.help.how_to_use_page')}</Typography>
                    </MenuItem>
                ) : null}
                <MenuItem
                    id="btn-release-notes"
                    data-testid="btn-release-notes"
                    onClick={() => {
                        setIsReleaseNotesOpen(true);
                        handleMenuClose();
                    }}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <Typography>{t('content.help.release_notes.menu_item')}</Typography>
                    {hasNotification ? (
                        <Box sx={{ width: 12, height: 12, mr: '2px', ml: 1.5, overflow: 'visible' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ overflow: 'visible' }}>
                                <circle cx="6" cy="6" r="6" fill={palette.secondary} />
                            </svg>
                        </Box>
                    ) : null}
                </MenuItem>
            </Menu>
        </>
    );
};

export default HelpComponent;
