import { Box, FormControl, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button, TrackedDialog as Dialog } from '../../../components/TrackedComponents';
import { CustomFormControl } from '../Coaching/Agenda/MetricsForm';

interface NewCoachingMetricsModalProps {
    defaultDriver?: string;
    driverToken?: number | null;
    onClose: CallableFunction;
    onSave: CallableFunction;
    isLoading: boolean;
}

const NewCoachingMetricsModal: React.FC<NewCoachingMetricsModalProps> = (props: NewCoachingMetricsModalProps) => {
    const [scoreTime, setScoreTime] = useState<'current' | 'history'>('current');
    const { t } = useTranslation();

    return (
        <Dialog id="new-coaching-metrics-dialog" open={true} onClose={() => props.onClose()}>
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                <Typography textAlign="center" variant="h6" sx={{ mb: 2 }}>
                    {t('content.safety.scores.save_for_coaching.header')}
                </Typography>
                <TextField
                    id="driver-name"
                    name="driver_name"
                    size="small"
                    label={t('table.headers.driver_name')}
                    value={props.defaultDriver}
                    disabled
                    inputProps={{ 'data-testid': 'driver-name-field' }}
                    sx={{ width: 300, mb: 1 }}
                />
                <Box sx={{ width: '100%' }}>
                    <FormControl sx={{ ml: 0.25 }}>
                        <RadioGroup
                            value={scoreTime}
                            onChange={(e) => {
                                setScoreTime(e.target.value as 'current' | 'history');
                            }}
                        >
                            {['current', 'history'].map((value) => (
                                <CustomFormControl
                                    key={value}
                                    value={value}
                                    label={t(`content.safety.scores.${value}`)}
                                    checked={scoreTime === value}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box textAlign="center" sx={{ mt: 2 }}>
                    <Button
                        id="save-btn"
                        data-testid="save-btn"
                        variant="contained"
                        size="small"
                        disabled={!props.defaultDriver || !props.driverToken || props.isLoading}
                        onClick={() => {
                            if (!!props.driverToken) {
                                props.onSave(props.driverToken, scoreTime);
                            }
                        }}
                    >
                        {t('content.coach.save')}
                    </Button>
                    <Button
                        id="cancel-btn"
                        data-testid="cancel-btn"
                        variant="outlined"
                        size="small"
                        disabled={props.isLoading}
                        onClick={() => props.onClose()}
                        sx={{ ml: 1 }}
                    >
                        {t('content.fleet.update.cancel')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default NewCoachingMetricsModal;
