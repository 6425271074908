import BookmarkIcon from '@mui/icons-material/Bookmark';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SpeedIcon from '@mui/icons-material/Speed';
import TheatersIcon from '@mui/icons-material/Theaters';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { Box, FormControlLabel, LinearProgress, TextField, Typography } from '@mui/material';
import MuiSwitch from '@mui/material/Switch';
import {
    GridColDef,
    GridFilterItem,
    GridFilterModel,
    GridLogicOperator,
    GridRowSelectionModel,
    GridToolbarFilterButton,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Event, EventDetails, TripDetails } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedDataGrid as StyledDataGrid,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useCallbackRef from '../../../hooks/callbackRef';
import useDrivers from '../../../hooks/drivers';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import { getDateTimeColumnType } from '../../../utils/DataGridDateTime';
import { localeObjectMap } from '../../../utils/DataGridDateTime';
import { isEventVideo } from '../../../utils/File';
import { eventsPageDefs } from '../../../utils/Pages';
import { normalizeLicensePlate, normalizeName, queryParamsToObject } from '../../../utils/Str';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { filterColumns, getColumnForNewFilter, gridLocalization } from '../OTA/MuiDeviceTable';
import { OE_ROLES } from '../Users/UsersComponent';
import { EVENT_TYPE_MAP } from './Defs';
import EventDetailsComponent, {
    ADMIN_EVENT_TYPES,
    CRASH_DISMISSED_TAG,
    CRASH_INCIDENT_EVENT_TYPE,
    CRASH_REPORTED_TAG,
    EventDetailsComponentProps,
} from './EventDetails';
import { toEventTypeText } from './EventDetailsSection';

interface ExtendedEvent extends Event {
    driver_name?: string;
    sub_fleet?: string;
    artifacts?: Array<string>;
}

const ROW_HEIGHT = 36;

export const filterFields: Record<string, { columnField: string; operatorValue: string }> = {
    driver_name: {
        columnField: 'driver_name',
        operatorValue: 'equals',
    },
    driver_name_contains: {
        columnField: 'driver_name',
        operatorValue: 'contains',
    },
    license_plate: {
        columnField: 'license_plate',
        operatorValue: 'equals',
    },
    license_plate_contains: {
        columnField: 'license_plate',
        operatorValue: 'contains',
    },
    sub_fleet: {
        columnField: 'sub_fleet',
        operatorValue: 'is',
    },
    event_type: {
        columnField: 'event_type',
        operatorValue: 'is',
    },
    severity_from: {
        columnField: 'severity',
        operatorValue: '>=',
    },
    time_from: {
        columnField: 'timestamp',
        operatorValue: 'onOrAfter',
    },
    time_to: {
        columnField: 'timestamp',
        operatorValue: 'onOrBefore',
    },
    date: {
        columnField: 'timestamp',
        operatorValue: 'onDate',
    },
};

const columnsToFilters: Record<string, string> = Object.fromEntries(
    Object.entries(filterFields).map(([key, value]) => [value.columnField, key]),
);

const getColumnOperators = (columnName: string) => {
    return Object.values(filterFields)
        .filter((value) => value.columnField === columnName)
        .map((value) => value.operatorValue);
};

const EventDetailsWrapper: React.FC<Omit<EventDetailsComponentProps, 'eventId'>> = (props) => {
    const { eventId } = useParams<{ eventId: string }>();
    return <EventDetailsComponent key={eventId} {...props} eventId={eventId} />;
};

interface QuickSearchToolbarProps {
    searchText: string;
    setSearchText: CallableFunction;
    canShowMore: boolean;
    showMore: boolean;
    onChangeShowMore: CallableFunction;
    onRefresh: CallableFunction;
    children?: React.ReactNode;
}

const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'start' : 'end',
                py: 1,
                backgroundColor: palette.bgColor,
            }}
        >
            <TextField
                fullWidth={isMobile}
                id="table-search-field"
                variant="standard"
                value={props.searchText}
                onChange={(e: ChangeEvent<HTMLInputElement>) => props.setSearchText(e.target.value)}
                size="small"
                placeholder={t('table.search')}
                InputProps={{
                    startAdornment: <SearchIcon color="primary" fontSize="small" sx={{ mb: 0.5, mr: 0.25 }} />,
                }}
                sx={{ mr: isMobile ? 0 : 1 }}
            />
            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%', mt: isMobile ? 1 : 0 }}>
                <GridToolbarFilterButton componentsProps={{ button: { variant: 'outlined', id: 'filters-btn' } }} />
                {props.canShowMore ? (
                    <Button
                        id="show-more-btn"
                        size="small"
                        variant={props.showMore ? 'contained' : 'outlined'}
                        onClick={() => props.onChangeShowMore()}
                        sx={{ flexShrink: 0, height: '32px', ml: 1 }}
                    >
                        {t('content.events.show_more')}
                    </Button>
                ) : null}
                <IconButton id="refresh-btn" size="small" onClick={() => props.onRefresh()} sx={{ ml: 1 }}>
                    <RefreshIcon />
                </IconButton>
                {props.children}
            </Box>
        </Box>
    );
};

const USE_OPENSEARCH = process.env.REACT_APP_USE_OPENSEARCH == '1';

const EventComponent: React.FC = () => {
    const [lastSessionSearchAfter, setLastSessionSearchAfter] = useState<Record<string, number | undefined>>({});
    const [lastSessionEventId, setSessionLastEventId] = useState<Record<string, number | undefined>>({});
    const [lastSessionTripId, setSessionLastTripId] = useState<Record<string, number | undefined>>({});
    const [sessionEvents, setSessionEvents] = useState<Record<string, Array<ExtendedEvent>>>({});
    const [selectedEvent, setSelectedEvent] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingCounter, setLoadingCounter] = useState<number>(0);
    const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [isCrashMode, setIsCrashMode] = useState<boolean>(false);
    const [isSavedForLater, setIsSavedForLater] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const history = useHistory();
    const query = useQuery();
    const { profile } = useProfile();
    const isAdmin = profile.admin;
    const match = useRouteMatch();
    const { i18n, t } = useTranslation();
    const { api } = useApi();
    const { drivers, getDriver, isLoading: isLoadingDrivers } = useDrivers();
    const [alertElement, setAlert] = useAlert();
    const [isDoneFetching, setIsDoneFetching] = useState<boolean>(false);
    const ALLOW_VIEW_INFORMATIVE_EVENTS = [...eventsPageDefs.permissions.L1];
    const [containerRef, node] = useCallbackRef();
    const [height, setHeight] = useState<number>(0);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [panelWidth, setPanelWidth] = useState<number | undefined>(
        localStorage.getItem('eventDetailsPanelWidth')
            ? parseInt(localStorage.getItem('eventDetailsPanelWidth') as string)
            : undefined,
    );

    const sessionKey = `${query.toString()}-${showMore}`;
    const events = sessionEvents[sessionKey] || [];

    let relevantEvents = events;
    if (isCrashMode) {
        relevantEvents = events.filter(
            (e) => !e.tags?.includes(CRASH_DISMISSED_TAG) && !e.tags?.includes(CRASH_REPORTED_TAG),
        );
    }
    const locale = localeObjectMap[i18n.languages[0]];
    const apiRef = useGridApiRef();

    const items: GridFilterItem[] = [];
    for (const column of Object.keys(filterFields)) {
        if (query.has(column)) {
            items.push({
                id: filterFields[column].columnField,
                field: filterFields[column].columnField,
                operator: filterFields[column].operatorValue,
                value:
                    filterFields[column].columnField === 'timestamp'
                        ? dayjs(query.get(column)).toDate()
                        : query.get(column),
            });
        }
    }

    if (items.length === 0 && [...query.keys()].length === 0) {
        // optimization to reduce number of trips to look after on backend query
        items.push({
            id: 'timestamp',
            field: 'timestamp',
            operator: 'onOrAfter',
            value: dayjs().subtract(14, 'day').toDate(),
        });
    }
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items });

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${eventsPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            if (node.offsetHeight) {
                setHeight(node.offsetHeight);
            }
        });
        resizeObserver.observe(node);
        return () => resizeObserver.disconnect();
    }, [node]);

    useEffect(() => {
        if (panelWidth !== undefined) {
            localStorage.setItem('eventDetailsPanelWidth', panelWidth.toString());
        }
    }, [panelWidth]);

    useEffect(() => {
        // load more events if the table is not full
        const scrollableArea = document.querySelector('.MuiDataGrid-virtualScroller');
        if (!loading && !!scrollableArea && filteredRows.length * ROW_HEIGHT < scrollableArea.clientHeight) {
            if (USE_OPENSEARCH && !lastSessionSearchAfter[sessionKey]) {
                return;
            }
            if (!USE_OPENSEARCH && !lastSessionEventId[sessionKey] && !lastSessionTripId[sessionKey]) {
                return;
            }
            setLoadingCounter((prev) => prev + 1);
        } else {
            setIsDoneFetching(true);
        }
    }, [events.length, height]);

    useEffect(() => {
        const scrollableArea: HTMLElement | null = document.querySelector('.MuiDataGrid-virtualScroller');
        if (
            scrollableArea &&
            // check if the table is scrolled to the bottom
            Math.abs(scrollableArea.scrollHeight - scrollableArea.clientHeight - scrollableArea.scrollTop) <= 1
        ) {
            // scroll up so onRowsScrollEnd can be triggered again
            document.querySelector('.MuiDataGrid-virtualScroller')?.scrollBy(0, -ROW_HEIGHT);
        }
    }, [events.length]);

    useEffect(() => {
        if (selectedEvent !== undefined && selectionModel[0] !== selectedEvent) {
            setSelectionModel([selectedEvent]);
        }
    }, [selectedEvent]);

    const resetState = () => {
        setLoading(false);
        setSessionEvents({});
        setSessionLastEventId({});
        setSessionLastTripId({});
        setLastSessionSearchAfter({});
        setLoadingCounter((prev) => prev + 1);
    };

    const extendEventDriverDetails = (event: Event | EventDetails): ExtendedEvent => {
        const driver = event.trip ? getDriver(event.trip.driver) : undefined;
        return {
            ...event,
            driver_name: driver?.fullName || '',
            sub_fleet: driver?.sub_fleet || '',
        } as ExtendedEvent;
    };

    const loadEvents = useCallback(
        (controller?: AbortController) => {
            setIsDoneFetching(false);
            if (loading) {
                return;
            }
            setLoading(true);
            let promise;
            if (USE_OPENSEARCH) {
                promise = api.apiV2EventGet(
                    {
                        level: showMore ? 'info' : undefined,
                        searchAfter: lastSessionSearchAfter[sessionKey],
                        tag: isSavedForLater ? 'saved-for-later' : undefined,
                        ...queryParamsToObject(getQueryParams(filterModel)), // query parsing must be last in order to allow overriding
                    },
                    { controller: controller },
                );
            } else {
                promise = api.apiV1EventGet(
                    {
                        ...queryParamsToObject(getQueryParams(filterModel)),
                        level: showMore ? 'info' : undefined,
                        lastEvent: lastSessionEventId[sessionKey],
                        lastTrip: lastSessionTripId[sessionKey],
                        tag: isSavedForLater ? 'saved-for-later' : undefined,
                    },
                    { controller: controller },
                );
            }
            promise
                .then((res) => {
                    const response = res.data;
                    if ('search_after' in response) {
                        setLastSessionSearchAfter((p) => {
                            return { ...p, [sessionKey]: !!response.search_after ? response.search_after : undefined };
                        });
                    } else if ('last_event_id' in response) {
                        setSessionLastEventId((p) => {
                            return {
                                ...p,
                                [sessionKey]: !!response.last_event_id ? response.last_event_id : undefined,
                            };
                        });
                        if (!response.last_event_id) {
                            // update trip number only when there is no event
                            setSessionLastTripId((p) => {
                                return {
                                    ...p,
                                    [sessionKey]: !!response.last_trip_id ? response.last_trip_id : undefined,
                                };
                            });
                        }
                    }
                    const hasMoreEvents =
                        ('search_after' in response && !!response.search_after) ||
                        ('last_event_id' in response && !!response.last_event_id) ||
                        ('last_trip_id' in response && !!response.last_trip_id);
                    if (response.events.length > 0) {
                        setSessionEvents((prevEvents) => {
                            const seen = new Set();
                            const all_events = prevEvents[sessionKey]
                                ? [...prevEvents[sessionKey], ...response.events]
                                : response.events;
                            const uniqReducer_events = all_events.reduce((obj: Array<ExtendedEvent>, elem: Event) => {
                                if (seen.has(elem.event_id)) {
                                    return obj;
                                }
                                seen.add(elem.event_id);
                                return [...obj, extendEventDriverDetails(elem)];
                            }, []);
                            return { ...prevEvents, [sessionKey]: uniqReducer_events };
                        });
                        if (
                            hasMoreEvents &&
                            !!searchText &&
                            !response.events.some(
                                (e) =>
                                    normalizeName(e.device.driver_name).includes(normalizeName(searchText)) ||
                                    normalizeLicensePlate(e.device.license_plate).includes(
                                        normalizeLicensePlate(searchText),
                                    ),
                            )
                        ) {
                            setLoadingCounter((prev) => prev + 1);
                        }
                    } else if (hasMoreEvents) {
                        // load more events from the next trip
                        setLoadingCounter((prev) => prev + 1);
                    } else {
                        setIsDoneFetching(true);
                    }
                })
                .catch(() => {
                    if (!controller?.signal.aborted) {
                        setAlert({
                            message: t('content.events.error_loading_events'),
                            type: 'error',
                            duration: 6000,
                        });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [
            loading,
            filterModel,
            showMore,
            lastSessionSearchAfter,
            lastSessionEventId,
            lastSessionTripId,
            sessionKey,
            searchText,
            drivers,
            api,
        ],
    );

    useEffect(() => {
        if (!isLoadingDrivers) {
            const controller = new AbortController();
            loadEvents(controller);
            return () => controller.abort();
        }
    }, [loadingCounter, isLoadingDrivers]);

    useEffect(resetState, [filterModel]);

    const updateTable = (newEventDetails: EventDetails | EventDetails[]) => {
        if (Array.isArray(newEventDetails)) {
            const newEventsMap: Record<number, EventDetails> = Object.fromEntries(
                newEventDetails.map((e) => [e.event_id, e]),
            );
            setSessionEvents((prevEvents) => {
                const updated_events = (prevEvents[sessionKey] || []).map((event) => {
                    if (newEventsMap[event.event_id]) {
                        return {
                            ...extendEventDriverDetails(newEventsMap[event.event_id]),
                            tags: newEventsMap[event.event_id].tags,
                        };
                    } else {
                        return event;
                    }
                });
                return { ...prevEvents, [sessionKey]: updated_events };
            });
        } else {
            setSessionEvents((prevEvents) => {
                if (prevEvents[sessionKey] != undefined) {
                    const updated_events = (prevEvents[sessionKey] || []).map((event) => {
                        if (event.event_id === newEventDetails.event_id) {
                            return {
                                ...extendEventDriverDetails(newEventDetails),
                                artifacts: Object.keys(newEventDetails.artifact_path),
                                tags: newEventDetails.tags,
                            } as ExtendedEvent;
                        } else {
                            return event;
                        }
                    });
                    if (!updated_events.some((event) => event.event_id === newEventDetails.event_id)) {
                        updated_events.push(extendEventDriverDetails(newEventDetails));
                    }
                    return { ...prevEvents, [sessionKey]: updated_events };
                } else {
                    return { ...prevEvents, [sessionKey]: [extendEventDriverDetails(newEventDetails)] };
                }
            });
            setSelectedEvent(newEventDetails.event_id);
            setShowDetails(true);
        }
    };

    const updateTripDetails = (tripDetails: TripDetails) => {
        const tripEvents = sessionEvents[sessionKey]
            .filter((event) => event.trip?.id === tripDetails.id)
            .map((event) => {
                return {
                    ...event,
                    trip: tripDetails,
                } as EventDetails;
            });
        updateTable(tripEvents);
    };

    const getQueryParams = (filterModel: GridFilterModel) => {
        const queryParams = new URLSearchParams();
        // keep query params that are not related to the filter
        for (const [key, value] of query.entries()) {
            if (!Object.keys(filterFields).includes(key)) {
                queryParams.append(key, value);
            }
        }
        if (filterModel.items.length > 0) {
            for (const item of filterModel.items) {
                if (item.value) {
                    if (item.field === 'driver_name') {
                        if (item.operator === 'contains') {
                            queryParams.append('driver_name_contains', item.value);
                        } else {
                            queryParams.append('driver_name', item.value);
                        }
                    } else if (item.field === 'license_plate') {
                        if (item.operator === 'contains') {
                            queryParams.append('license_plate_contains', item.value);
                        } else {
                            queryParams.append('license_plate', item.value);
                        }
                    } else if (item.field === 'timestamp') {
                        if (dayjs(item.value).isValid()) {
                            const value = dayjs(item.value);
                            if (item.operator === 'onOrAfter') {
                                queryParams.append('time_from', value.toISOString());
                            } else if (item.operator === 'onOrBefore') {
                                queryParams.append('time_to', value.toISOString());
                            } else if (item.operator === 'onDate') {
                                queryParams.append('time_from', value.startOf('day').toISOString());
                                queryParams.append('time_to', value.endOf('day').toISOString());
                            }
                        }
                    } else if (item.field === 'severity') {
                        const value = Math.floor(parseFloat(item.value));
                        queryParams.append(columnsToFilters['severity'], value.toString());
                    } else {
                        queryParams.append(columnsToFilters[item.field], item.value);
                    }
                }
            }
        }
        return queryParams;
    };

    const onFilterChange = (filterModel: GridFilterModel) => {
        const queryParams = getQueryParams(filterModel);
        if (query.toString() !== queryParams.toString()) {
            history.replace(`?${queryParams.toString()}`);
        }
        setFilterModel(filterModel);
    };

    const gridColumns: GridColDef[] = useMemo(() => {
        const cols: GridColDef[] = [
            {
                field: 'driver_name',
                headerName: t('table.headers.name'),
                type: 'string',
                filterOperators: getGridStringOperators().filter((operator) =>
                    getColumnOperators('driver_name').includes(operator.value),
                ),
                flex: 1,
            },
            {
                field: 'sub_fleet',
                headerName: t('table.headers.sub_fleet'),
                type: 'singleSelect',
                valueOptions: profile.customer.sub_fleets,
                filterOperators: getGridSingleSelectOperators().filter((operator) =>
                    getColumnOperators('sub_fleet').includes(operator.value),
                ),
                flex: 1,
            },
            {
                field: 'license_plate',
                headerName: t('table.headers.license_plate'),
                type: 'string',
                filterOperators: getGridStringOperators().filter((operator) =>
                    getColumnOperators('license_plate').includes(operator.value),
                ),
                flex: 1,
            },
            {
                field: 'event_type',
                headerName: t('table.headers.event_type'),
                type: 'singleSelect',
                valueOptions: Object.entries(EVENT_TYPE_MAP)
                    .map(([value, label]) => ({ value, label: t(label) }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .filter((type) => isAdmin || !ADMIN_EVENT_TYPES.includes(type.value)),
                filterOperators: getGridSingleSelectOperators().filter((operator) =>
                    getColumnOperators('event_type').includes(operator.value),
                ),
                renderCell: (params) => {
                    const icons: Array<React.ReactNode> = [];
                    if (params.row.tags?.includes('alert-muted')) {
                        icons.push(<VolumeOffIcon key="VolumeOffIcon" fontSize="small" />);
                    }
                    if (params.row.artifacts?.filter((filePath: string) => isEventVideo(filePath)).length) {
                        icons.push(<TheatersIcon key="TheatersIcon" fontSize="small" />);
                    }
                    if (params.row.tags?.includes('saved-for-later')) {
                        icons.push(<BookmarkIcon key="BookmarkIcon" fontSize="small" />);
                    }
                    if (isAdmin && params.row.tags?.includes('broken-files')) {
                        icons.push(<HeartBrokenIcon key="HeartBrokenIcon" fontSize="small" />);
                    }
                    if (isAdmin && params.row.tags?.includes('false_speed')) {
                        icons.push(<SpeedIcon key="SpeedIcon" fontSize="small" />);
                    }
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {t(toEventTypeText(params.value as string))}
                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 0.5 }}>{icons}</Box>
                        </Box>
                    );
                },
                flex: 1,
            },
            {
                field: 'severity',
                headerName: t('table.headers.severity'),
                type: 'number',
                filterOperators: getGridNumericOperators().filter((operator) =>
                    getColumnOperators('severity').includes(operator.value),
                ),
                align: 'left',
                headerAlign: 'left',
                flex: 0.5,
                renderCell: (params) => (!!params.value ? params.value : t('content.events.severity.na')),
            },
        ];
        const minDate = USE_OPENSEARCH ? dayjs().subtract(90, 'day') : undefined;
        const timestampCol: GridColDef = {
            field: 'timestamp',
            headerName: t('table.headers.date'),
            ...getDateTimeColumnType(locale, minDate),
            renderCell: (params) => {
                return dayjs(params.value as Date).format(profile.dateTimeFormat);
            },
            flex: 1,
        };
        timestampCol.filterOperators = timestampCol.filterOperators?.filter((operator) =>
            getColumnOperators('timestamp').includes(operator.value),
        );
        return [...cols, timestampCol];
    }, [i18n.languages[0], selectedEvent]);

    const rows = relevantEvents.map((event) => {
        return {
            id: event.event_id,
            driver_name: event.driver_name ?? '',
            license_plate: event.device.license_plate,
            sub_fleet: event.sub_fleet,
            event_type: event.type,
            severity: Math.round(event.severity * 10) / 10,
            timestamp: dayjs.unix(event.timestamp).toDate(),
            tags: event.tags,
            artifacts: event.artifacts,
        };
    });

    let filteredRows = rows;
    if (searchText) {
        filteredRows = rows.filter((row) => {
            return (
                normalizeName(row.driver_name).includes(normalizeName(searchText)) ||
                normalizeLicensePlate(row.license_plate).includes(normalizeLicensePlate(searchText))
            );
        });
    }

    const pendingCrashEvents = OE_ROLES.includes(profile.role) ? (
        <>
            <FormControlLabel
                control={
                    <MuiSwitch
                        id="crash-mode-switch"
                        data-testid="crash-mode-switch"
                        checked={isCrashMode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.checked) {
                                setSelectedEvent(undefined);
                                onFilterChange({
                                    items: [
                                        {
                                            field: 'event_type',
                                            operator: 'is',
                                            value: CRASH_INCIDENT_EVENT_TYPE,
                                        },
                                        {
                                            field: 'timestamp',
                                            operator: 'onOrAfter',
                                            value: dayjs().subtract(7, 'day').toDate(),
                                        },
                                    ],
                                });
                                setShowMore(true);
                            } else {
                                setSelectedEvent(undefined);
                                setShowMore(false);
                                onFilterChange({
                                    items: [
                                        {
                                            id: 'timestamp',
                                            field: 'timestamp',
                                            operator: 'onOrAfter',
                                            value: dayjs().subtract(14, 'day').toDate(),
                                        },
                                    ],
                                });
                            }
                            setIsCrashMode(e.target.checked);
                        }}
                        size="small"
                    />
                }
                label={<Typography sx={{ fontSize: 14 }}>{t('content.events.pending_crash_incidents')}</Typography>}
                sx={{ ml: 0.5 }}
            />
            <FormControlLabel
                control={
                    <MuiSwitch
                        id="saved-for-later-switch"
                        data-testid="saved-for-later-switch"
                        checked={isSavedForLater}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setIsSavedForLater(e.target.checked);
                            setSelectedEvent(undefined);
                            resetState();
                        }}
                        size="small"
                    />
                }
                label={<Typography sx={{ fontSize: 14 }}>{t('content.events.saved_for_later')}</Typography>}
            />
        </>
    ) : null;

    return (
        <Box ref={containerRef} sx={{ width: '100%', height: '100%', display: 'flex' }}>
            {alertElement}
            <Box sx={{ overflowY: 'auto', flex: 1, p: 2, height: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                    <StyledDataGrid
                        id="events-data-grid"
                        apiRef={apiRef}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'timestamp', sort: 'desc' }],
                            },
                        }}
                        hideFooter
                        density="compact"
                        filterMode="server"
                        loading={!isDoneFetching || isLoadingDetails || isLoadingDrivers}
                        columns={gridColumns}
                        rows={filteredRows}
                        disableMultipleRowSelection={!isCrashMode}
                        showCellVerticalBorder
                        showColumnVerticalBorder
                        rowSelectionModel={selectionModel}
                        filterModel={filterModel}
                        onFilterModelChange={onFilterChange}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            mixpanel.track('select-row', {
                                id: 'events-data-grid',
                                type: 'data-grid',
                                page: window.location.pathname,
                            });
                            const previousEventId = selectedEvent;
                            const selectedEventId = newRowSelectionModel[0];
                            if (selectedEventId !== undefined) {
                                history.push(`${match.url}/${selectedEventId}?${query}`);
                            }
                            setSelectionModel(newRowSelectionModel);
                            if (!!selectedEventId) {
                                setShowDetails(true);
                            }
                            if (!!selectedEventId && previousEventId !== selectedEventId) {
                                setIsLoadingDetails(true);
                            }
                        }}
                        scrollEndThreshold={1}
                        onRowsScrollEnd={() => {
                            if (
                                !!lastSessionSearchAfter[sessionKey] ||
                                !!lastSessionEventId[sessionKey] ||
                                !!lastSessionTripId[sessionKey]
                            ) {
                                setLoadingCounter((prev) => prev + 1);
                            }
                        }}
                        localeText={
                            {
                                ...gridLocalization[i18n.languages[0]],
                                filterPanelRemoveAll: t('table.remove_all'),
                                columnMenuManageColumns: t('table.manage_columns'),
                                unpin: t('table.unpin'),
                                filterOperatorOnDate: t('table.on_date'),
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            } as any
                        }
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: QuickSearchToolbar,
                            noRowsOverlay: isDoneFetching
                                ? () => (
                                      <Box
                                          sx={{
                                              width: '100%',
                                              height: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                          }}
                                      >
                                          <Typography variant="overline">
                                              {t('content.events.no_events_found')}
                                          </Typography>
                                      </Box>
                                  )
                                : () => null,
                        }}
                        slotProps={{
                            columnsPanel: {
                                sx: {
                                    '& .MuiDataGrid-panelFooter button:first-child': {
                                        display: 'none',
                                    },
                                },
                            },
                            filterPanel: {
                                filterFormProps: {
                                    filterColumns: filterColumns,
                                    deleteIconProps: { id: 'delete-icon' },
                                    columnInputProps: { id: 'column-input' },
                                    operatorInputProps: { id: 'operator-input' },
                                    valueInputProps: { InputComponentProps: { id: 'value-input' } },
                                    logicOperatorInputProps: { sx: { display: 'none' } },
                                },
                                logicOperators: [GridLogicOperator.And],
                                getColumnForNewFilter,
                            },
                            toolbar: {
                                searchText,
                                setSearchText,
                                canShowMore: ALLOW_VIEW_INFORMATIVE_EVENTS.includes(profile.role) || isAdmin,
                                showMore,
                                onChangeShowMore: () => {
                                    setShowMore((showMore) => !showMore);
                                    resetState();
                                },
                                onRefresh: () => {
                                    resetState();
                                },
                                children: pendingCrashEvents,
                            },
                        }}
                        columnBuffer={6}
                        sx={{
                            minWidth: 0,
                            height: '100%',
                            width: '100%',
                            maxWidth: '1200px',
                            '& .MuiDataGrid-columnHeaders': {
                                borderBottom: 'none',
                            },
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-cell--editing': {
                                padding: '0 !important',
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            backgroundColor: palette.neutral[50],
                            '& .MuiDataGrid-main': {
                                borderRadius: '4px',
                            },
                            '& .MuiDataGrid-overlayWrapperInner': {
                                height: loading && filteredRows.length > 0 ? '4px !important' : undefined,
                            },
                        }}
                    />
                </LocalizationProvider>
            </Box>
            <Switch>
                <Route path={`${match.path}/:eventId`}>
                    <EventDetailsWrapper
                        updateTable={updateTable}
                        updateTripDetails={updateTripDetails}
                        setAlert={setAlert}
                        isVisible={showDetails}
                        setIsVisible={setShowDetails}
                        panelWidth={panelWidth}
                        setPanelWidth={setPanelWidth}
                        isLoading={isLoadingDetails}
                        setIsLoading={setIsLoadingDetails}
                        onFilterChange={onFilterChange}
                        selectedEventIds={selectionModel}
                        onEventsPage
                        showEditVideo
                        showFindVideo
                        showEventRoute
                    />
                </Route>
            </Switch>
        </Box>
    );
};

export default EventComponent;
