import { Box, Card, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent';

import { CoachMetricsAdditionalDataEnum, ShortCoachSession } from '../../../../backendsdk';
import useProfile from '../../../../hooks/profile';
import { SECONDS_IN_DAY } from '../../../../utils/TimeFormatter';
import palette from '../../../ColorPalette';
import { ScoreAvatar } from '../../Metrics/SafetyWellness/DriverList';
import { getFines } from '../../Vehicles/FinesParser';

interface CustomTooltipProps extends LegendProps {
    scoreType: string;
    additionalData: CoachMetricsAdditionalDataEnum;
    onScoreCard?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any[];
}

const CustomTooltip: React.FC<CustomTooltipProps> = (props: CustomTooltipProps) => {
    const { payload } = props;
    const { t } = useTranslation();
    const fines: Record<string, number> = {};

    if (payload && payload.length) {
        const hasAdditionalData = payload.length > 1;
        return (
            <Card
                id="metrics-tooltip"
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, direction: 'ltr' }}
            >
                {payload.map((p, idx) => {
                    if (p.payload) {
                        Object.assign(fines, getFines(p.payload.metadata));
                        const name = p.name as string;
                        const score = p.payload[name];
                        const labelKey =
                            name === 'score' && props.onScoreCard
                                ? `driver_metric_header_${name}`
                                : `metric_header_${name}`;
                        return (
                            <Box
                                key={p.id}
                                sx={{
                                    display: 'flex',
                                    flexDirection: hasAdditionalData ? 'row' : 'column',
                                    justifyContent: hasAdditionalData ? 'space-between' : 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    mb: hasAdditionalData && idx !== payload.length - 1 ? 0.5 : 0,
                                }}
                            >
                                <Typography
                                    fontSize={12}
                                    sx={{ fontWeight: 'bold', mb: 0.5, mr: hasAdditionalData ? 1 : 0 }}
                                >
                                    {t(`content.coach.add_item.metrics.${labelKey}`, {
                                        score_type: t(
                                            `content.coach.add_item.metrics.${props.scoreType}_score_definite`,
                                        ),
                                    })}
                                </Typography>
                                <ScoreAvatar score={score} />
                            </Box>
                        );
                    }
                })}
                {Object.entries(fines).map(([key, fine]) => (
                    <Box
                        key={key}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                            mt: 1,
                            mb: 0.5,
                        }}
                    >
                        <Typography fontSize={12} sx={{ mr: 1 }}>
                            {t(`content.safety.scores.components.point${fine > 1 ? 's' : ''}`, { fine })}{' '}
                            {t(`content.safety.scores.components.${key}_fine`)}
                        </Typography>
                    </Box>
                ))}
            </Card>
        );
    }
    return null;
};

interface DataPoint {
    name: number;
    score: number | null;
}

interface MetricsGraphProps {
    metricChartData: DataPoint[];
    scoreType: 'safety' | 'wellness';
    additionalData: CoachMetricsAdditionalDataEnum;
    onScoreCard?: boolean;
    isPresenting?: boolean;
    coachingSessions?: ShortCoachSession[];
}

const MetricsGraph: React.FC<MetricsGraphProps> = ({
    metricChartData,
    scoreType,
    additionalData,
    onScoreCard,
    isPresenting,
    coachingSessions,
}: MetricsGraphProps) => {
    const { t } = useTranslation();
    const { profile } = useProfile();

    return (
        <Box
            data-testid="metrics-graph-container"
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {!onScoreCard ? (
                <Typography data-testid="metrics-header" fontSize={isPresenting ? 18 : undefined}>
                    {t('content.coach.add_item.metrics.metrics_header', {
                        score_type: t(`content.coach.add_item.metrics.${scoreType}_score_definite`),
                        date_from: dayjs.unix(metricChartData[0].name).format(profile.shortDateFormat),
                        date_to: dayjs
                            .unix(metricChartData[metricChartData.length - 1].name)
                            .format(profile.shortDateFormat),
                    })}
                </Typography>
            ) : null}
            <ResponsiveContainer width="100%" height="100%" className="chart-container">
                <LineChart
                    id="metrics-graph"
                    data={metricChartData}
                    margin={{
                        top: 5,
                        right: 20,
                        left: -20,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid />
                    <XAxis
                        type="number"
                        tick={{ fontSize: 12 }}
                        dataKey="name"
                        ticks={metricChartData.map((d) => d.name)}
                        tickFormatter={(t) => dayjs.unix(t).format(profile.shortDateFormat)}
                        domain={['dataMin', 'dataMax']}
                        interval={0}
                    />
                    <YAxis tick={{ fontSize: 12 }} interval={0} tickCount={11} domain={[0, 100]} />
                    <Tooltip
                        content={
                            <CustomTooltip
                                scoreType={scoreType}
                                additionalData={additionalData}
                                onScoreCard={onScoreCard}
                            />
                        }
                    />
                    {additionalData !== CoachMetricsAdditionalDataEnum.None ? (
                        <>
                            <Line
                                connectNulls
                                isAnimationActive={false}
                                type="monotone"
                                dataKey={`${additionalData}_score`}
                                stroke={palette.neutral[400]}
                                strokeWidth={2}
                            />
                        </>
                    ) : null}
                    <Line
                        connectNulls
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="score"
                        stroke={palette.accent}
                        strokeWidth={2}
                    />
                    {(coachingSessions || []).map((session) => (
                        <ReferenceLine
                            key={session.session_id}
                            x={Math.floor(session.completed_at / SECONDS_IN_DAY) * SECONDS_IN_DAY}
                            stroke={palette.neutral[400]}
                            strokeDasharray="4 4"
                            strokeWidth={2}
                        />
                    ))}
                    {!!coachingSessions?.length ? (
                        <Line strokeDasharray="4 4" name="coaching" dataKey="null" stroke={palette.neutral[400]} />
                    ) : null}
                    {!!coachingSessions?.length || additionalData !== CoachMetricsAdditionalDataEnum.None ? (
                        <Legend
                            wrapperStyle={{ marginLeft: 20 }}
                            iconType="plainline"
                            formatter={(label) => {
                                if (label === 'score' && onScoreCard) {
                                    return t(`content.coach.add_item.metrics.driver_${label}`);
                                }
                                return t(`content.coach.add_item.metrics.${label}`);
                            }}
                        />
                    ) : null}
                </LineChart>
            </ResponsiveContainer>
            {!onScoreCard ? (
                <Typography data-testid="metrics-footer" fontSize={isPresenting ? 14 : 12} sx={{ mt: 1 }}>
                    {t(`content.metrics.${scoreType}_tooltip`)}
                </Typography>
            ) : null}
        </Box>
    );
};

export default MetricsGraph;
