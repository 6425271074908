import AddIcon from '@mui/icons-material/Add';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PortraitIcon from '@mui/icons-material/Portrait';
import SearchIcon from '@mui/icons-material/Search';
import { Box, ButtonGroup, LinearProgress, TextField, Tooltip, Typography } from '@mui/material';
import {
    GridColDef,
    GridCsvGetRowsToExportParams,
    GridRenderCellParams,
    GridRowSelectionModel,
    gridExpandedSortedRowIdsSelector,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FleetDriver } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedDataGrid as StyledDataGrid,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useDevices from '../../../hooks/devices';
import useDrivers from '../../../hooks/drivers';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { fleetDriversPageDefs } from '../../../utils/Pages';
import { formatPhoneNumber, normalizeLicensePlate, normalizeName, unformatPhoneNumber } from '../../../utils/Str';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { gridLocalization } from '../OTA/MuiDeviceTable';
import ActionsMenu from './ActionsMenu';
import FleetDriverModal from './FleetDriverModal';
import UploadDriversModal from './UploadDriversModal';

const EMPTY_DRIVER: FleetDriver = {
    token: 0,
    first_name: '',
    last_name: '',
    phone_number: '',
    sub_fleet: '',
    image_url: null,
    active: true,
    default_vehicles: [],
    is_auto_generated: false,
};

interface QuickSearchToolbarProps {
    searchText: string;
    setSearchText: CallableFunction;
    setSelectedDriver: CallableFunction;
    filter: 'active' | 'inactive';
    setFilter: CallableFunction;
    children?: React.ReactNode;
}

const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                py: 1,
                backgroundColor: palette.bgColor,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                    id="table-search-field"
                    variant="standard"
                    value={props.searchText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.setSearchText(e.target.value)}
                    size="small"
                    placeholder={t('table.search')}
                    InputProps={{
                        startAdornment: <SearchIcon color="primary" fontSize="small" sx={{ mb: 0.5, mr: 0.25 }} />,
                    }}
                    sx={{ mr: 1 }}
                />
                <Button
                    id="add-driver-btn"
                    variant="contained"
                    color="secondary"
                    onClick={() => props.setSelectedDriver(EMPTY_DRIVER)}
                    sx={{ mr: 1 }}
                    startIcon={<AddIcon />}
                >
                    {t('content.drivers.add_driver')}
                </Button>
                <ButtonGroup disableElevation>
                    {['active', 'inactive'].map((filterValue) => (
                        <Button
                            id={`${filterValue}-btn`}
                            key={`${filterValue}-btn`}
                            variant={props.filter === filterValue ? 'contained' : 'outlined'}
                            onClick={() => props.setFilter(filterValue as 'active' | 'inactive')}
                        >
                            {t(`content.drivers.${filterValue}`)}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>
            {props.children}
        </Box>
    );
};

const FleetDriversComponent: React.FC = () => {
    const { devices, isLoading: isLoadingDevices, isError: isErrorDevices } = useDevices(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [filter, setFilter] = useState<'active' | 'inactive'>('active');
    const [selectedDriver, setSelectedDriver] = useState<FleetDriver | undefined>(undefined);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [alertElement, setAlert] = useAlert();
    const { api } = useApi();
    const { i18n, t } = useTranslation();
    const { profile } = useProfile();
    const [subFleetList, setSubFleetList] = useState<string[]>(profile.customer.sub_fleets || []);
    const apiRef = useGridApiRef();
    const isMobile = useIsMobile();
    const { drivers: allDrivers, isLoading: isLoadingDrivers, isError: isErrorDrivers, setDrivers } = useDrivers();
    const drivers = allDrivers.filter((driver) => driver.is_auto_generated === false);

    const deviceMap: Record<string, string> = useMemo(() => {
        return devices.reduce((acc, device) => {
            acc[device.device.device_id] = device.device.license_plate;
            return acc;
        }, {} as Record<string, string>);
    }, [devices]);

    const tokenToDriverMap: Record<number, FleetDriver> = useMemo(() => {
        return drivers.reduce((acc, driver) => {
            acc[driver.token] = driver;
            return acc;
        }, {} as Record<number, FleetDriver>);
    }, [drivers]);

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${fleetDriversPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        setSelectionModel([]);
    }, [filter]);

    const getBadge = (driverToken: number) => {
        setIsLoading(true);
        api.apiV0DriverBadgeDriverTokenGet({ driverToken: driverToken }, { responseType: 'blob' })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${driverToken}.png`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => setAlert({ message: t('content.drivers.error_badge'), type: 'error', duration: 6000 }))
            .finally(() => setIsLoading(false));
    };

    const getBadges = (driverTokens: number[]) => {
        setIsLoading(true);
        api.apiV0DriverBadgePost(
            { driverBadgeRequest: { driver_tokens: driverTokens.length > 0 ? driverTokens : rows.map((d) => d.id) } },
            { responseType: 'blob' },
        )
            .then((res) => {
                // download the zip file
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'driver_badges.zip');
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => setAlert({ message: t('content.drivers.error_badges'), type: 'error', duration: 6000 }))
            .finally(() => setIsLoading(false));
    };

    const handleSetActiveState = (active: boolean) => {
        setIsLoading(true);
        api.apiV0DriverPatch({
            fleetDriverList: {
                drivers: selectionModel.map((token) => ({ ...tokenToDriverMap[token as number], active })),
            },
        })
            .then(() => {
                setDrivers((prev: FleetDriver[]) =>
                    prev.map((driver) => {
                        if (selectionModel.includes(driver.token)) {
                            return { ...driver, active };
                        }
                        return driver;
                    }),
                );
            })
            .catch(() =>
                setAlert({
                    message: t(`content.drivers.error_${active ? 'activate' : 'deactivate'}`),
                    type: 'error',
                    duration: 6000,
                }),
            )
            .finally(() => setIsLoading(false));
    };

    const columns: GridColDef[] = useMemo(() => {
        return [
            { field: 'first_name', headerName: t('content.drivers.first_name'), flex: 1 },
            { field: 'last_name', headerName: t('content.drivers.last_name'), flex: 1 },
            {
                field: 'phone_number',
                headerName: t('content.drivers.phone_number'),
                valueFormatter: (params) => formatPhoneNumber(profile.customer.settings.country, params.value),
                flex: 1,
            },
            {
                field: 'sub_fleet',
                type: 'singleSelect',
                valueOptions: subFleetList,
                headerName: t('content.drivers.sub_fleet'),
                flex: 1,
            },
            {
                field: 'default_vehicles',
                headerName: t('content.drivers.default_vehicles'),
                flex: 1,
                disableExport: true,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: t('content.drivers.actions'),
                width: 150,
                renderCell: (params: GridRenderCellParams) => {
                    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

                    const popupState = usePopupState({
                        variant: 'popover',
                        popupId: 'driver-image-popover',
                    });

                    return (
                        <Box sx={{ display: 'flex' }}>
                            <Tooltip title={t('content.drivers.edit')}>
                                <Box>
                                    <IconButton
                                        id={`${params.id}-edit-btn`}
                                        data-testid={`${params.id}-edit-btn`}
                                        onClick={() => setSelectedDriver(params.row.driver)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                            {profile.customer.settings.use_qr_identification ? (
                                <Tooltip title={t('content.drivers.get_badge')}>
                                    <IconButton
                                        id={`${params.id}-badge-btn`}
                                        onClick={() => getBadge(params.row.id as number)}
                                    >
                                        <BadgeOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            <Box {...bindHover(popupState)}>
                                <IconButton id={`${params.id}-image-btn`} disabled={!params.row.driver.image_url}>
                                    <PortraitIcon />
                                </IconButton>
                            </Box>
                            {!!params.row.driver.image_url ? (
                                <HoverPopover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    sx={{ visibility: imageLoaded ? 'visible' : 'hidden' }}
                                >
                                    <Box
                                        sx={{
                                            p: 1,
                                        }}
                                    >
                                        <img onLoad={() => setImageLoaded(true)} src={params.row.driver.image_url} />
                                    </Box>
                                </HoverPopover>
                            ) : null}
                        </Box>
                    );
                },
                sortable: false,
                align: 'center',
                headerAlign: 'center',
                disableColumnMenu: true,
                disableExport: true,
            },
        ];
    }, [i18n.languages[0], subFleetList]);

    const rows = useMemo(() => {
        return drivers
            .map((driver) => {
                return {
                    id: driver.token,
                    first_name: driver.first_name,
                    last_name: driver.last_name,
                    phone_number: driver.phone_number,
                    sub_fleet: driver.sub_fleet,
                    active: driver.active,
                    image_url: driver.image_url,
                    default_vehicles: driver.default_vehicles
                        .map((v) => deviceMap[v])
                        .filter(Boolean)
                        .join(', '),
                    driver: driver,
                };
            })
            .filter((driver) => (filter === 'active' ? driver.active : !driver.active));
    }, [drivers, devices, filter]);

    const filteredRows = !!searchText
        ? rows.filter(
              (row) =>
                  normalizeName(row.first_name).includes(normalizeName(searchText)) ||
                  normalizeName(row.last_name).includes(normalizeName(searchText)) ||
                  normalizeName(`${row.first_name} ${row.last_name}`).includes(normalizeName(searchText)) ||
                  unformatPhoneNumber(row.phone_number).includes(searchText.replace(/-() /g, '')) ||
                  normalizeName(row.sub_fleet).includes(normalizeName(searchText)) ||
                  row.default_vehicles
                      .split(', ')
                      .map((v) => normalizeLicensePlate(v))
                      .some((v) => v.includes(normalizeLicensePlate(searchText))),
          )
        : rows;

    return (
        <Box sx={{ width: '100%', height: '100%', maxWidth: '1200px', display: 'flex', flexDirection: 'column', p: 2 }}>
            {alertElement}
            {selectedDriver !== undefined ? (
                <FleetDriverModal
                    driver={selectedDriver}
                    subFleetList={subFleetList}
                    setSubFleetList={setSubFleetList}
                    setAlert={setAlert}
                    onClose={() => setSelectedDriver(undefined)}
                    drivers={drivers}
                    setDrivers={setDrivers}
                />
            ) : null}
            {isUploadModalOpen ? (
                <UploadDriversModal
                    onClose={() => setIsUploadModalOpen(false)}
                    drivers={drivers}
                    setDrivers={setDrivers}
                    onDownloadList={() => {
                        const getAllRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
                            gridExpandedSortedRowIdsSelector(apiRef);
                        apiRef.current.exportDataAsCsv({
                            getRowsToExport: getAllRows,
                            allColumns: true,
                            fileName: 'drivers',
                        });
                    }}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setAlert={setAlert}
                />
            ) : null}
            <StyledDataGrid
                id="fleet-drivers-data-grid"
                apiRef={apiRef}
                loading={isLoadingDrivers || isLoadingDevices}
                columns={columns}
                rows={filteredRows}
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={setSelectionModel}
                localeText={{
                    ...gridLocalization[i18n.languages[0]],
                    filterPanelRemoveAll: t('table.remove_all'),
                    columnMenuManageColumns: t('table.manage_columns'),
                    unpin: t('table.unpin'),
                }}
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay:
                        isLoading || isLoadingDevices
                            ? () => null
                            : () => (
                                  <Box
                                      sx={{
                                          width: '100%',
                                          height: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                      }}
                                  >
                                      <Typography variant="overline">
                                          {t(
                                              `content.drivers.${
                                                  isErrorDrivers || isErrorDevices
                                                      ? 'error_loading'
                                                      : 'no_drivers_found'
                                              }`,
                                          )}
                                      </Typography>
                                  </Box>
                              ),
                    toolbar: QuickSearchToolbar,
                }}
                slotProps={{
                    toolbar: {
                        searchText,
                        setSearchText,
                        setSelectedDriver,
                        filter,
                        setFilter,
                        children: (
                            <ActionsMenu
                                onExport={() => apiRef.current.exportDataAsCsv({ utf8WithBom: true })}
                                onUpload={() => setIsUploadModalOpen(true)}
                                onGetBadges={() => getBadges(selectionModel as number[])}
                                areDriversSelected={selectionModel.length > 0}
                                filter={filter}
                                handleSetActiveState={handleSetActiveState}
                                numberOfDrivers={drivers.length}
                            />
                        ),
                    },
                }}
                columnBuffer={5}
                disableVirtualization={isMobile || process.env.JEST_WORKER_ID !== undefined}
                sx={{
                    minWidth: 0,
                    height: '100%',
                    width: '100%',
                    '& .MuiDataGrid-columnHeaders': {
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-cell--editing': {
                        padding: '0 !important',
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    backgroundColor: palette.neutral[50],
                }}
            />
        </Box>
    );
};

export default FleetDriversComponent;
