import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import {
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridRowModel,
    GridRowModesModel,
    GridValueFormatterParams,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceConfigStatusStatusEnum as ConfigStatusEnum, DeviceConfigStatus } from '../../../backendsdk';
import { TrackedDataGrid as StyledDataGrid } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { getDateTimeColumnType, localeObjectMap } from '../../../utils/DataGridDateTime';
import { CustomerConfigPageDefs } from '../../../utils/Pages';
import { timestampToDate } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { gridLocalization } from '../OTA/MuiDeviceTable';
import QuickSearchToolbar from './QuickSearchToolbar';

const BG_COLOR: Record<ConfigStatusEnum, string> = {
    [ConfigStatusEnum.Created]: palette.score.yellow,
    [ConfigStatusEnum.Pending]: palette.score.orange,
    [ConfigStatusEnum.Downloaded]: palette.score.red,
    [ConfigStatusEnum.Aligned]: palette.score.green,
    [ConfigStatusEnum.NoOverlay]: palette.wellness.gray,
    [ConfigStatusEnum.AlignedObsolete]: palette.wellness.gray,
};

const STATUS_TOOLSTIP: Record<ConfigStatusEnum, string> = {
    [ConfigStatusEnum.Created]: 'new diff detected, package added to device',
    [ConfigStatusEnum.Pending]: 'old diff detected, package still not downloaded',
    [ConfigStatusEnum.Downloaded]: 'there is diff, but the package already downloaded, indicates error',
    [ConfigStatusEnum.Aligned]: 'no diff detected',
    [ConfigStatusEnum.NoOverlay]: 'no configuration defined to the customer',
    [ConfigStatusEnum.AlignedObsolete]: 'customer config been changed since last alignment',
};

type HasVsc = boolean | 'empty';

const hasVscConfig = (vsc: object | null): HasVsc => {
    if (vsc === null) {
        return false;
    }
    return Object.values(vsc).some((v) => Object.values(v).length > 0) ? true : 'empty';
};

const vscConfigIcon = (hasVsc: HasVsc): JSX.Element => {
    if (hasVsc === 'empty') {
        return <RadioButtonUncheckedIcon />;
    }
    return hasVsc ? <DoneIcon /> : <CloseIcon />;
};

interface DeviceConfigStatusComponentProps {
    configStatuses: DeviceConfigStatus[];
    loadStatuses: (controller?: AbortController) => void;
    toolbar: React.ReactNode;
}

const DeviceConfigStatusComponent: React.FC<DeviceConfigStatusComponentProps> = (props) => {
    const { configStatuses } = props;
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [searchText, setSearchText] = useState<string>('');
    const { i18n, t } = useTranslation();
    const { profile } = useProfile();
    const apiRef = useGridApiRef();
    const locale = localeObjectMap[i18n.languages[0]];
    const { controlApi } = useApi();
    const [alertElement, setAlert] = useAlert();

    const rows = configStatuses.map((configStatus) => {
        return {
            id: configStatus.device_id,
            device_id: configStatus.device_id,
            customer: configStatus.customer,
            operator: configStatus.operator,
            package: configStatus.package_id || 0,
            config_id: configStatus.overlay_id || 0,
            config_name: configStatus.overlay_name || 'N/A',
            status: configStatus.status,
            status_updated_at: timestampToDate(configStatus.updated_at),
            has_vsc: hasVscConfig(configStatus.vsc),
            vsc: configStatus.vsc,
        };
    });
    const filteredRows = rows.filter((row) => row.device_id.includes(searchText));
    const rowIdtoRowNum = Object.fromEntries(rows.map((row, index) => [row.id, index]));

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${CustomerConfigPageDefs.name}`)}`;
    }, []);

    const renderCell = (params: GridRenderCellParams) => {
        const value = params.value as ConfigStatusEnum;
        return (
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title={STATUS_TOOLSTIP[value]}>
                    <Box display="flex" sx={{ alignItems: 'center' }}>
                        <CircleIcon fontSize="large" sx={{ color: BG_COLOR[value], p: 1 }} />
                        <Typography sx={{ width: '100%' }}>{params.value}</Typography>
                    </Box>
                </Tooltip>
                <Tooltip title="Resend configuration update to device">
                    <IconButton
                        disabled={value !== ConfigStatusEnum.Downloaded}
                        onClick={() =>
                            controlApi
                                .controlV0ConfigStatusDeviceIdResendPost({ deviceId: params.row.device_id })
                                .then(() => {
                                    setAlert({
                                        message: 'Configuration update sent',
                                        type: 'success',
                                        duration: 6000,
                                    });
                                    props.loadStatuses();
                                })
                                .catch(() => {
                                    setAlert({
                                        message: 'Failed to send configuration update',
                                        type: 'error',
                                        duration: 6000,
                                    });
                                })
                        }
                    >
                        <CloudSyncIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        );
    };

    const renderCellVsc = (params: GridRenderCellParams<typeof rows[0], HasVsc>) => {
        return (
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title={JSON.stringify(params.row.vsc)}>
                    {params.value !== undefined ? vscConfigIcon(params.value) : <Box />}
                </Tooltip>
                {params.value === true ? (
                    <Tooltip title={'empty vsc configuration'}>
                        <IconButton
                            onClick={() =>
                                controlApi
                                    .controlV0ConfigStatusDeviceIdCleanVscPost({ deviceId: params.row.device_id })
                                    .then(() => {
                                        setAlert({
                                            message: 'VSC configuration cleaning update sent',
                                            type: 'success',
                                            duration: 6000,
                                        });
                                    })
                                    .catch(() => {
                                        setAlert({
                                            message: 'Failed to send VSC configuration cleaning update',
                                            type: 'error',
                                            duration: 6000,
                                        });
                                    })
                            }
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Box>
        );
    };

    const columns: GridColDef[] = useMemo(() => {
        const columns: GridColDef[] = [
            {
                field: 'device_id',
                type: 'string',
                headerName: 'device_id',
                headerAlign: 'left',
                align: 'left',
                width: 200,
                editable: false,
                hideable: true,
            },
            {
                field: 'customer',
                type: 'singleSelect',
                headerName: 'customer',
                headerAlign: 'left',
                align: 'left',
                width: 150,
                valueOptions: [...new Set(rows.map((row) => row.customer))],
                editable: false,
                hideable: true,
            },
            {
                field: 'operator',
                type: 'singleSelect',
                headerName: 'operator',
                headerAlign: 'left',
                align: 'left',
                width: 150,
                valueOptions: [...new Set(rows.map((row) => row.operator))],
                editable: false,
                hideable: true,
            },
            {
                field: 'package',
                type: 'string',
                headerName: 'package',
                headerAlign: 'left',
                align: 'left',
                width: 100,
                editable: false,
                hideable: true,
            },
            {
                field: 'config_id',
                type: 'singleSelect',
                headerName: 'config_id',
                headerAlign: 'left',
                align: 'left',
                width: 100,
                valueOptions: [...new Set(rows.map((row) => row.config_id))],
                editable: false,
                hideable: true,
            },
            {
                field: 'config_name',
                type: 'singleSelect',
                headerName: 'config_name',
                headerAlign: 'left',
                align: 'left',
                width: 300,
                valueOptions: [...new Set(rows.map((row) => row.config_name))],
                editable: false,
                hideable: true,
            },
            {
                field: 'status',
                type: 'singleSelect',
                headerName: 'status',
                headerAlign: 'left',
                align: 'left',
                renderCell: renderCell,
                width: 275,
                valueOptions: [...new Set(rows.map((row) => row.status))],
                editable: false,
                hideable: true,
            },
            {
                field: 'status_updated_at',
                type: 'number',
                headerName: 'status_updated_at',
                headerAlign: 'left',
                align: 'left',
                editable: false,
                hideable: true,
                ...getDateTimeColumnType(locale),
                valueFormatter: (params: GridValueFormatterParams) =>
                    !!params.value ? dayjs(params.value).format(profile.dateTimeFormat) : '',
                width: 200,
            },
            {
                field: 'has_vsc',
                type: 'singleSelect',
                headerName: 'has_vsc',
                headerAlign: 'left',
                align: 'left',
                renderCell: renderCellVsc,
                width: 200,
                valueOptions: [
                    { value: 'empty', label: 'Empty' },
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                ],
                editable: false,
                hideable: true,
            },
        ];
        return columns;
    }, [rows]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                pb: 2,
            }}
        >
            {alertElement}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                <StyledDataGrid
                    id="device-config-status-data-grid"
                    apiRef={apiRef}
                    density="compact"
                    columns={columns}
                    rows={filteredRows}
                    editMode="row"
                    onProcessRowUpdateError={() => null}
                    disableMultipleRowSelection
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    disableRowSelectionOnClick
                    pagination={true}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={setRowModesModel}
                    getCellClassName={(params: GridCellParams) => `${params.field}-${rowIdtoRowNum[params.row.id]}`}
                    getRowClassName={(params: GridRowModel) => `row-${params.id}`}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'status_updated_at', sort: 'desc' }],
                        },
                    }}
                    slots={{ toolbar: QuickSearchToolbar }}
                    slotProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value),
                            refresh: props.loadStatuses,
                            children: props.toolbar,
                        },
                        pagination: { sx: { mr: '45px' } },
                        columnsPanel: {
                            sx: {
                                '& .MuiDataGrid-panelFooter button:first-child': { display: 'none' },
                            },
                        },
                    }}
                    localeText={
                        {
                            ...gridLocalization[i18n.languages[0]],
                            filterPanelRemoveAll: t('table.remove_all'),
                            columnMenuManageColumns: t('table.manage_columns'),
                            unpin: t('table.unpin'),
                            filterOperatorOnDate: t('table.on_date'),
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any
                    }
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& .MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScrollerContent': {
                            borderBottom: `1px solid ${palette.neutral[400]}`,
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cell--editing': {
                            padding: '0 !important',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        backgroundColor: palette.neutral[50],
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default DeviceConfigStatusComponent;
