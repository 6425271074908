import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Card, CircularProgress, Skeleton, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FleetScoreDetails } from '../../../../backendsdk';
import useDevices from '../../../../hooks/devices';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import { HOURS_IN_DAY, SECONDS_IN_HOUR } from '../../../../utils/TimeFormatter';
import { MILES_TO_KM_FACTOR } from '../../../../utils/location';
import palette from '../../../ColorPalette';
import { CardField } from '../../Vehicles/VehicleCard';
import { getScoreColor } from '../../Wellness/WellnessCard';
import { isScoreValid } from './MetricsComponent';

interface FleetScoreCardProps {
    scoreType: 'safety' | 'wellness';
    isLoading: boolean;
    dateFormat: string;
    score?: number;
    selectedFleetScoreDetails?: FleetScoreDetails;
}

const FleetScoreCard: React.FC<FleetScoreCardProps> = ({
    scoreType,
    isLoading,
    dateFormat,
    score,
    selectedFleetScoreDetails,
}: FleetScoreCardProps) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const { devices, isLoading: isLoadingDevices, isError } = useDevices();
    const { profile } = useProfile();

    const title = (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography textAlign="center" sx={{ fontWeight: 'bold' }}>
                {t(`content.metrics.fleet_${scoreType}_score`)}
            </Typography>
            <Tooltip title={t(`content.metrics.${scoreType}_tooltip`)}>
                <InfoOutlinedIcon sx={{ fontSize: 16, ml: 0.25 }} />
            </Tooltip>
        </Box>
    );
    let content;
    if (isLoading) {
        content = (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: isMobile ? 2 : 0 }}>
                    {title}
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        sx={{ width: 105, height: 21, mb: isMobile ? 0 : 1, flexShrink: 0 }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'row' : 'column',
                        alignItems: 'center',
                        width: isMobile ? undefined : '100%',
                    }}
                >
                    <Box sx={{ position: 'relative', mr: isMobile ? 2 : 0, mb: isMobile ? 0 : 2 }}>
                        <CircularProgress
                            variant="determinate"
                            value={100}
                            size="8em"
                            thickness={4.5}
                            sx={{ position: 'absolute', color: palette.neutral[100] }}
                        />
                        <CircularProgress
                            variant="determinate"
                            value={score}
                            size="8em"
                            thickness={4.5}
                            sx={{
                                '& .MuiCircularProgress-circle': {
                                    transition: 'stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            width: isMobile ? '50%' : '100%',
                        }}
                    >
                        {[...Array(3).keys()].map((key) => {
                            return (
                                <React.Fragment key={`${key}-field`}>
                                    <Skeleton
                                        variant="rounded"
                                        animation="wave"
                                        sx={{ height: 11, width: 50, mb: 0.25 }}
                                    />
                                    <Skeleton
                                        variant="rounded"
                                        animation="wave"
                                        sx={{ height: 20, width: 95, mb: key !== 2 ? 1 : 0 }}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </Box>
                </Box>
            </>
        );
    } else if (selectedFleetScoreDetails === undefined || !isScoreValid(score)) {
        content = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="overline" textAlign="center" sx={{ lineHeight: 1.5 }}>
                    {t('content.metrics.no_score')}
                </Typography>
            </Box>
        );
    } else if (score !== undefined) {
        const devicesWithScore =
            scoreType === 'safety'
                ? selectedFleetScoreDetails.devices_with_driver_score
                : selectedFleetScoreDetails.devices_with_wellness_score;
        const getDistanceString = () => {
            if (!isLoading) {
                const useImperialUnits = profile.locale === 'us';
                const distanceFactor = useImperialUnits ? MILES_TO_KM_FACTOR : 1;
                const distance = Math.floor(selectedFleetScoreDetails.driven_distance / distanceFactor);
                return `${distance} ${t(`content.metrics.${useImperialUnits ? 'mi' : 'km'}`)}`;
            }
        };
        content = (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: isMobile ? 2 : 0 }}>
                    {title}
                    <Typography
                        data-testid="fleet-score-date"
                        fontSize={14}
                        sx={{ color: palette.neutral[700], mb: isMobile ? 0 : 1 }}
                    >{`${dayjs
                        .unix(selectedFleetScoreDetails.timestamp - 14 * HOURS_IN_DAY * SECONDS_IN_HOUR)
                        .format(dateFormat)}–${dayjs
                        .unix(selectedFleetScoreDetails.timestamp)
                        .format(dateFormat)}`}</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'row' : 'column',
                        alignItems: 'center',
                        width: isMobile ? undefined : '100%',
                    }}
                >
                    <Box sx={{ position: 'relative', mr: isMobile ? 2 : 0, mb: isMobile ? 0 : 2 }}>
                        <CircularProgress
                            variant="determinate"
                            value={100}
                            size="8em"
                            thickness={4.5}
                            sx={{ position: 'absolute', color: palette.neutral[100] }}
                        />
                        <CircularProgress
                            variant="determinate"
                            value={score}
                            size="8em"
                            thickness={4.5}
                            sx={{
                                color: getScoreColor(score),
                                '& .MuiCircularProgress-circle': {
                                    transition: 'stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                },
                            }}
                        />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                id="fleet-score-value"
                                data-testid="fleet-score-value"
                                variant="h3"
                                component="div"
                                color={getScoreColor(score)}
                            >
                                {score}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            width: isMobile ? '50%' : '100%',
                        }}
                    >
                        <CardField
                            name={t('content.metrics.vehicles_with_score')}
                            value={
                                !isLoadingDevices && !isError
                                    ? t('content.metrics.vehicles_of_total', {
                                          vehicles: devicesWithScore,
                                          total: devices.length,
                                      })
                                    : devicesWithScore.toString()
                            }
                            containerStyle={{ width: '100%' }}
                        />
                        <CardField
                            name={t('content.metrics.driving_duration')}
                            value={`${Math.floor(selectedFleetScoreDetails.driven_duration).toString()} ${t(
                                'content.metrics.hours',
                            )}`}
                            containerStyle={{ width: '100%', my: 1 }}
                        />
                        <CardField
                            name={t('content.metrics.driving_distance')}
                            value={getDistanceString()}
                            containerStyle={{ width: '100%' }}
                        />
                    </Box>
                </Box>
            </>
        );
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minWidth: 200,
                width: isMobile ? '100%' : 200,
                height: isMobile ? undefined : 330,
                p: 1,
                mr: isMobile ? 0 : 2,
                mb: isMobile ? 2 : 0,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                {content}
            </Box>
        </Card>
    );
};

export default FleetScoreCard;
