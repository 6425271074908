import {
    Alert,
    Box,
    Collapse,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Snackbar,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccidentTagsV2Details } from '../../../../backendsdk';
import { TrackedDialog as Dialog } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import palette from '../../../ColorPalette';
import AccidentDetails, { validateAccidentDetails } from './AccidentDetails';
import ImpactGraph from './ImpactGraph';
import TaggingTree from './TaggingTree';
import { damageCauses, impactedObjects, pointsOfImpact } from './treeStructures';

interface TaggingComponentProps {
    accidentTags: AccidentTagsV2Details;
    setAccidentTags: React.Dispatch<React.SetStateAction<AccidentTagsV2Details>>;
    accidentId: number;
    eventId: number | null;
    setEventId: (event: React.ChangeEvent<HTMLInputElement>) => void;
    eventVideo?: string;
    onSave: () => void;
    onClose: () => void;
    eventLevel?: string;
}

const TaggingComponent: React.FC<TaggingComponentProps> = (props) => {
    const [eventLevel, setEventLevel] = useState<string | undefined>(props.eventLevel);
    const [selectedCauses, setSelectedCauses] = useState<string[]>(props.accidentTags.damage_causes);
    const [primaryCause, setPrimaryCause] = useState<string>(props.accidentTags.primary_cause);
    const [selectedImpactedObjects, setSelectedImpactedObjects] = useState<string[]>(
        props.accidentTags.impacted_objects,
    );
    const pointsOnGraph = props.accidentTags.points_of_impact?.filter(
        (p) => !pointsOfImpact[0].point_of_impact.includes(p),
    );
    const pointsNotOnGraph = props.accidentTags.points_of_impact?.filter((p) =>
        pointsOfImpact[0].point_of_impact.includes(p),
    );
    const [impactSides, setImpactSides] = useState<string[]>(pointsOnGraph);
    const [selectedPointsOfImpact, setSelectedPointsOfImpact] = useState<string[]>(pointsNotOnGraph);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const { agencyApi } = useApi();
    const { t } = useTranslation();

    useEffect(() => {
        if (primaryCause && !selectedCauses.includes(primaryCause)) {
            setPrimaryCause('');
        }
    }, [selectedCauses]);

    const isTaggingComplete =
        props.accidentTags.not_an_accident ||
        props.accidentTags.missing_video_reason !== 'did_not_search' ||
        (validateAccidentDetails(props.accidentTags) &&
            selectedCauses.length > 0 &&
            primaryCause !== '' &&
            selectedPointsOfImpact.length + impactSides.length > 0 &&
            selectedImpactedObjects.length > 0 &&
            eventLevel !== 'INFO');

    const saveTags = (details: AccidentTagsV2Details, closeDialog = false) => {
        setIsSaving(true);
        const accidentTags: AccidentTagsV2Details = {
            ...details,
            damage_causes: selectedCauses,
            primary_cause: primaryCause,
            points_of_impact: [...selectedPointsOfImpact, ...impactSides],
            impacted_objects: selectedImpactedObjects,
        };
        agencyApi
            .agencyV1AccidentAccidentIdTagsV2Post({
                accidentId: props.accidentId,
                accidentTagsV2: { accident_id: props.accidentId, tag: accidentTags },
            })
            .then(() => {
                setIsSuccess(true);
                props.setAccidentTags(accidentTags);
                props.onSave();
                if (closeDialog) {
                    props.onClose();
                }
            })
            .catch(() => setIsError(true))
            .finally(() => setIsSaving(false));
    };

    const handleCloseAlert = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSuccess(false);
        setIsError(false);
    };

    return (
        <Dialog id="tagging-dialog" fullScreen open>
            <Snackbar open={!props.accidentTags} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="info" sx={{ width: '100%' }}>
                    <Typography>{t('content.accidents.tags.alerts.loading')}</Typography>
                </Alert>
            </Snackbar>
            <Snackbar
                open={isSuccess}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    <Typography>{t('content.accidents.tags.alerts.success')}</Typography>
                </Alert>
            </Snackbar>
            <Snackbar
                open={isError}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    <Typography>{t('content.accidents.tags.alerts.error')}</Typography>
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    overflowY: 'hidden',
                    display: 'flex',
                    userSelect: 'none',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '350px',
                        flexShrink: 0,
                        borderRight: `1px solid ${palette.black}`,
                    }}
                >
                    <AccidentDetails
                        accidentDetails={props.accidentTags}
                        eventId={props.eventId}
                        setEventId={props.setEventId}
                        eventVideo={props.eventVideo}
                        setAccidentTags={props.setAccidentTags}
                        isComplete={isTaggingComplete}
                        onSave={saveTags}
                        onClose={props.onClose}
                        isSaving={isSaving}
                        eventLevel={eventLevel}
                        setEventLevel={setEventLevel}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '500px',
                        flexShrink: 0,
                        borderRight: `1px solid ${palette.black}`,
                        p: 2,
                        pt: 1,
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        {t('content.accidents.tags.damage_cause.header')}
                    </Typography>
                    <TaggingTree
                        structure={damageCauses}
                        selected={selectedCauses}
                        setSelected={setSelectedCauses}
                        toggleControls={true}
                        maxSelection={4}
                        translationPrefix="damage_cause"
                    />
                </Box>
                <Collapse
                    in={selectedCauses.length > 0}
                    orientation="horizontal"
                    sx={{
                        '&.MuiCollapse-entered': {
                            minWidth: '300px !important',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '300px',
                            height: '100%',
                            flex: 0,
                            flexShrink: 0,
                            borderRight: `1px solid ${palette.black}`,
                            p: 2,
                            pt: 1,
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            {t('content.accidents.tags.select_primary_cause')}
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                value={primaryCause}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setPrimaryCause((e.target as HTMLInputElement).value)
                                }
                            >
                                {selectedCauses.map((value) => (
                                    <Tooltip
                                        key={value}
                                        followCursor={true}
                                        title={t(`content.accidents.tags.damage_cause.tooltip.${value}`, '')}
                                    >
                                        <FormControlLabel
                                            key={value}
                                            value={value}
                                            control={<Radio id={`${value}-radio`} />}
                                            label={t(`content.accidents.tags.damage_cause.${value}`)}
                                        />
                                    </Tooltip>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Collapse>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '400px',
                        flexShrink: 0,
                        p: 2,
                        pt: 1,
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        {t('content.accidents.tags.damage_mechanism.header')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            flexDirection: 'column',
                            overflowY: 'auto',
                        }}
                    >
                        <Box>
                            <TaggingTree
                                structure={pointsOfImpact}
                                selected={selectedPointsOfImpact}
                                setSelected={setSelectedPointsOfImpact}
                                translationPrefix="damage_mechanism"
                                expandByDefault={true}
                                additionalItem={
                                    <ImpactGraph
                                        impactSides={impactSides}
                                        setImpactSides={setImpactSides}
                                        width={180}
                                        height={270}
                                    />
                                }
                            />
                        </Box>
                        <Box>
                            <TaggingTree
                                structure={impactedObjects}
                                selected={selectedImpactedObjects}
                                setSelected={setSelectedImpactedObjects}
                                translationPrefix="damage_mechanism"
                                expandByDefault={true}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export default TaggingComponent;
