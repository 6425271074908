import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Box, Card, Checkbox, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { CoachSession, ShortCoachSession } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useDrivers from '../../../hooks/drivers';
import useProfile from '../../../hooks/profile';
import { unformatPhoneNumber } from '../../../utils/Str';
import { PHONE_NUMBER_MASK_MAP } from '../UserSettings/UserSettings';

const TWILIO_ERROR_MESSAGES = ["Invalid 'To' Phone Number", 'cannot be a Short Code', 'cannot be a landline'];

interface SendSessionDialogProps {
    details: CoachSession;
    setDetails: CallableFunction;
    setSessions: CallableFunction;
    setTab: CallableFunction;
    setAlert: CallableFunction;
    onClose: CallableFunction;
}

const SendSessionDialog: React.FC<SendSessionDialogProps> = ({
    details,
    setDetails,
    setSessions,
    setTab,
    setAlert,
    onClose,
}: SendSessionDialogProps) => {
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [assignToMe, setAssignToMe] = useState<boolean>(false);
    const [driverConsent, setDriverConsent] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ phoneNumber: boolean; message: boolean }>({
        phoneNumber: false,
        message: false,
    });
    const { drivers, isLoading: isDriverLoading } = useDrivers();
    const { api } = useApi();
    const { t } = useTranslation();
    const { profile } = useProfile();

    const country = profile.customer.settings.country;

    useEffect(() => {
        if (!isDriverLoading && drivers.length > 0) {
            const driver = drivers.find((d) => d.fullName === details.driver_name);
            if (driver) {
                setPhoneNumber(driver.phone_number);
            }
        }
    }, [drivers, isDriverLoading]);

    const validateForm = () => {
        const newErrors = {
            phoneNumber: phoneNumber.length === 0,
            message: message.length > 300,
        };
        setErrors(newErrors);
        return !newErrors.phoneNumber && !newErrors.message;
    };

    const handleSend = () => {
        setIsLoading(true);
        api.apiV1CoachSessionIdPost({
            sessionId: details.session_id,
            updateCoachSession: details,
        }).then(() => {
            return api
                .apiV1CoachSendPost({
                    coachSessionMessageRequest: {
                        drivers: [
                            {
                                session_id: details.session_id,
                                phone_number: unformatPhoneNumber(phoneNumber),
                                message,
                            },
                        ],
                        assignee: !!details.assignee ? details.assignee : profile.user_id,
                    },
                })
                .then((res) => {
                    const resultMap = Object.fromEntries(res.data.results.map((r) => [r.session_id, r]));
                    const result = resultMap[details.session_id];
                    if (result.status === 'queued') {
                        setDetails((prev: CoachSession) => {
                            return {
                                ...prev,
                                assignee: assignToMe ? profile.user_id : details.assignee,
                                last_sent: dayjs().unix(),
                                is_saved: true,
                            };
                        });
                        setSessions((prev: ShortCoachSession[]) =>
                            prev.map((session) => {
                                if (session.session_id === details.session_id) {
                                    return {
                                        ...session,
                                        last_sent: dayjs().unix(),
                                        last_delivery_status: 'queued',
                                        is_saved: true,
                                    };
                                }
                                return session;
                            }),
                        );
                        setTab('sent');
                        setAlert({
                            message: t('content.coach.send_session.session_sent'),
                            type: 'success',
                            duration: 6000,
                        });
                        onClose();
                    } else {
                        let errorMessage = t('content.coach.errors.session_sent');
                        if (result.message && TWILIO_ERROR_MESSAGES.some((m) => result.message?.includes(m))) {
                            errorMessage += ` – ${t('content.coach.errors.invalid_phone_number')}`;
                        }
                        setAlert({
                            message: errorMessage,
                            type: 'error',
                            duration: 6000,
                        });
                        setIsLoading(false);
                    }
                });
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const phoneInput: any = () => {
        const inputElement = (
            <TextField
                id="phone-number-field"
                size="small"
                label={t('content.coach.send_session.phone_number')}
                error={errors.phoneNumber}
                helperText={errors.phoneNumber ? t('content.coach.send_session.required') : ''}
                inputProps={{ style: { direction: 'ltr' } }}
                sx={{ mb: 1.5 }}
            />
        );
        return inputElement;
    };

    return (
        <Dialog
            id="send-session-dialog"
            open={true}
            fullWidth
            maxWidth="xs"
            onClose={() => onClose()}
            sx={{ overflow: 'visible' }}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SendIcon sx={{ mr: 0.5 }} />
                    {t('content.coach.send_session.header')}
                </Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    p: 1,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 1 }}>
                    <Typography sx={{ mb: 1.5 }}>{t('content.coach.send_session.instructions')}</Typography>
                    <InputMask
                        mask={PHONE_NUMBER_MASK_MAP[country]}
                        value={phoneNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                        maskChar=""
                    >
                        {phoneInput}
                    </InputMask>
                    <TextField
                        id="message-field"
                        size="small"
                        multiline
                        rows={3}
                        label={t('content.coach.send_session.message')}
                        error={errors.message}
                        helperText={errors.message ? t('content.coach.send_session.message_error') : ''}
                        value={message}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
                        sx={{ mb: 1 }}
                    />
                    {details.assignee !== profile.user_id ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="assign-to-me-checkbox"
                                    data-testid="assign-to-me-checkbox"
                                    checked={assignToMe}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setAssignToMe(e.target.checked)}
                                    size="small"
                                    disableRipple
                                    sx={{ '& .MuiSvgIcon-root': { height: 16, width: 16 } }}
                                />
                            }
                            label={
                                <Typography variant="body2" sx={{ mt: 0.5 }}>
                                    {t('content.coach.send_session.assign_to_me')}
                                </Typography>
                            }
                        />
                    ) : null}
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="driver-consent-checkbox"
                                data-testid="driver-consent-checkbox"
                                checked={driverConsent}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDriverConsent(e.target.checked)}
                                size="small"
                                disableRipple
                                sx={{ '& .MuiSvgIcon-root': { height: 16, width: 16 } }}
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {t('content.coach.send_session.driver_consent')}
                            </Typography>
                        }
                    />
                </Card>
                <Box sx={{ display: 'flex', mt: 1, width: '100%' }}>
                    <Button
                        id="cancel-btn"
                        data-testid="cancel-btn"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => onClose()}
                        sx={{ mr: 1 }}
                    >
                        {t('content.coach.send_session.cancel')}
                    </Button>
                    <Button
                        id="send-btn"
                        data-testid="send-btn"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={isLoading || !driverConsent || isDriverLoading}
                        onClick={() => {
                            if (validateForm()) {
                                handleSend();
                            }
                        }}
                    >
                        {t('content.coach.send_session.send')}
                    </Button>
                </Box>
            </Paper>
        </Dialog>
    );
};

export default SendSessionDialog;
