import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, List, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedCustomerDataAllOfUsers, Malfunction } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedListItemButton as ListItemButton,
} from '../../../components/TrackedComponents';
import { flattenRoles, malfunctionsPageDefs } from '../../../utils/Pages';
import { getUserAvatar } from '../Coaching/SessionDetails';

interface AssignmentModalProps {
    open: boolean;
    onClose: () => void;
    users: ExtendedCustomerDataAllOfUsers[];
    selectedMalfunctions: Malfunction[];
    onAssign: CallableFunction;
    isLoading: boolean;
}

const AssignmentModal: React.FC<AssignmentModalProps> = (props) => {
    const [selectedUser, setSelectedUser] = React.useState<number>();
    const { t } = useTranslation();

    let availableUsers = props.users.filter((u) => flattenRoles(malfunctionsPageDefs).includes(u.role));
    const malfunctionSubFleets = props.selectedMalfunctions.map((malfunction) => malfunction.sub_fleet).filter(Boolean);
    if (malfunctionSubFleets.length > 0) {
        availableUsers = availableUsers.filter((u) => !u.sub_fleet || malfunctionSubFleets.includes(u.sub_fleet));
    }

    return (
        <Dialog id="bulk-assign-dialog" open={true} maxWidth="xs" fullWidth onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                    minHeight: 0,
                    flexShrink: 0,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AssignmentIndIcon sx={{ mr: 0.5 }} />
                    <Typography>{t('content.malfunctions.assign_malfunction')}</Typography>
                </Box>
            </Box>
            <Box sx={{ width: '100%', p: 1 }}>
                <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%', minHeight: 0 }}>
                    <List
                        disablePadding
                        sx={{
                            maxHeight: '400px',
                            overflowY: 'auto',
                            minHeight: 0,
                        }}
                    >
                        {availableUsers.map((user) => (
                            <ListItemButton
                                key={user.user_id}
                                id={`user-${user.user_id}-list-item`}
                                data-testid={`user-${user.user_id}-list-item`}
                                onClick={() => setSelectedUser(user.user_id)}
                                selected={user.user_id === selectedUser}
                            >
                                {getUserAvatar(user)}
                                <Typography variant="body2">{user.name}</Typography>
                            </ListItemButton>
                        ))}
                    </List>
                </Card>
                <Box sx={{ width: '100%', minHeight: 0, display: 'flex', mt: 1 }}>
                    <Button
                        id="btn-cancel-assign"
                        variant="contained"
                        color="primary"
                        disabled={props.isLoading}
                        onClick={() => props.onClose()}
                        sx={{ flex: 1, mr: 1 }}
                    >
                        {t('content.malfunctions.cancel')}
                    </Button>
                    <Button
                        id="btn-assign"
                        data-testid="btn-assign"
                        variant="contained"
                        color="secondary"
                        disabled={!selectedUser || props.isLoading}
                        onClick={() => {
                            if (selectedUser) {
                                props.onAssign(selectedUser);
                            }
                        }}
                        sx={{ flex: 1 }}
                    >
                        {t('content.malfunctions.assign')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default AssignmentModal;
