import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import imageNA from '../images/image_not_available.png';

export const useImages = (
    limit: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    apiMethod: (key: string) => Promise<AxiosResponse<any, any>>,
    getImageSizes?: boolean,
): [Record<string, string>, CallableFunction, Record<string, { width: number; height: number }>] => {
    interface PromiseQueue {
        keys: Array<string>;
        promises: Record<string, Promise<void>>;
    }
    const [promiseQueue, setPromiseQueue] = useState<PromiseQueue>({ keys: [], promises: {} });
    const [images, setImages] = useState<Record<string, string>>({});
    const [imageSizes, setImageSizes] = useState<Record<string, { width: number; height: number }>>({});

    const addToQueue = (keys: Array<string>) => {
        setPromiseQueue((prev) => {
            const updatedKeys = [...prev.keys];
            for (const key of keys) {
                if (images[key] === undefined && !updatedKeys.includes(key)) {
                    updatedKeys.push(key);
                }
            }
            return { keys: updatedKeys, promises: prev.promises };
        });
    };

    const removePromise = (key: string) => {
        setPromiseQueue((prev) => {
            if (key in prev.promises) {
                const updated = { ...prev.promises };
                delete updated[key];
                return { ...prev, promises: updated };
            } else {
                return prev;
            }
        });
    };

    const getImageSize = (key: string, image: string) => {
        return new Promise<void>((resolve, reject) => {
            const imageFile = new Image();
            imageFile.src = image;
            imageFile.onload = () => {
                setImageSizes((prev) => {
                    return { ...prev, [key]: { width: imageFile.width, height: imageFile.height } };
                });
                resolve();
            };
            imageFile.onerror = reject;
        });
    };

    const getImage = async (key: string) => {
        return apiMethod(key)
            .then((res) => {
                return new Promise<void>((resolve, reject) => {
                    const reader = new window.FileReader();
                    reader.readAsDataURL(res.data);
                    reader.onload = async () => {
                        const image = reader.result;
                        if (typeof image === 'string') {
                            if (getImageSizes) {
                                await getImageSize(key, image);
                            }
                            setImages((prev) => {
                                return { ...prev, [key]: image };
                            });
                            resolve();
                        }
                    };
                    reader.onerror = reject;
                });
            })
            .catch(() => {
                setImages((prev) => {
                    return { ...prev, [key]: imageNA };
                });
            })
            .finally(() => {
                removePromise(key);
            });
    };

    useEffect(() => {
        const numberOfPromises = Object.keys(promiseQueue.promises).length;
        if (numberOfPromises < limit && promiseQueue.keys.length > 0) {
            setPromiseQueue((prev) => {
                const updatedKeys = [...prev.keys];
                const updatedPromises = { ...prev.promises };
                for (let i = 0; i < limit - Object.keys(promiseQueue.promises).length; i++) {
                    const key = updatedKeys.shift();
                    if (key !== undefined && images[key] === undefined) {
                        updatedPromises[key] = getImage(key);
                    }
                }
                return { keys: updatedKeys, promises: updatedPromises };
            });
        }
    }, [promiseQueue]);

    return [images, addToQueue, imageSizes];
};
