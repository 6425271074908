import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Skeleton, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useIsMobile from '../../../hooks/isMobile';
import palette from '../../ColorPalette';
import { RTLDirectionContext } from '../../Layout';

interface WellnessImages {
    IN_CABIN?: string;
    FRONT_FACING?: string;
}

interface SegmentImagesProps {
    snapshotStart?: string;
    snapshotEnd?: string;
    wellnessStart: WellnessImages;
    wellnessEnd: WellnessImages;
    wellnessStartSizes?: Record<string, { width: number; height: number }>;
    wellnessEndSizes?: Record<string, { width: number; height: number }>;
}

const SegmentImages: React.FC<SegmentImagesProps> = ({
    wellnessStartSizes,
    wellnessEndSizes,
    wellnessStart,
    wellnessEnd,
    snapshotStart,
    snapshotEnd,
}: SegmentImagesProps) => {
    const { t } = useTranslation();
    const isRTL = useContext(RTLDirectionContext);
    const isMobile = useIsMobile();

    const shouldCropStartIC =
        wellnessStartSizes?.IN_CABIN?.width !== undefined &&
        wellnessStartSizes?.IN_CABIN?.height !== undefined &&
        wellnessStartSizes.IN_CABIN.width / wellnessStartSizes.IN_CABIN.height === 8 / 3;
    const shouldCropStartFF =
        wellnessStartSizes?.FRONT_FACING?.width !== undefined &&
        wellnessStartSizes?.FRONT_FACING?.height !== undefined &&
        wellnessStartSizes.FRONT_FACING.width / wellnessStartSizes.FRONT_FACING.height === 8 / 3;
    const shouldCropEndIC =
        wellnessEndSizes?.IN_CABIN?.width !== undefined &&
        wellnessEndSizes?.IN_CABIN?.height !== undefined &&
        wellnessEndSizes.IN_CABIN.width / wellnessEndSizes.IN_CABIN.height === 8 / 3;
    const shouldCropEndFF =
        wellnessEndSizes?.FRONT_FACING?.width !== undefined &&
        wellnessEndSizes?.FRONT_FACING?.height !== undefined &&
        wellnessEndSizes.FRONT_FACING.width / wellnessEndSizes.FRONT_FACING.height === 8 / 3;

    const placeholder = (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Typography variant="overline" color="primary">
                {t('content.find_video.load_images')}
            </Typography>
        </Box>
    );
    const skeleton = <Skeleton variant="rectangular" animation="wave" width="50%" height="100%" />;
    const fullWidthSkeleton = <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />;
    const image = (src: string, cropClassName: string) => (
        <img src={src} className={cropClassName} width="50%" height="100%" style={{ objectFit: 'cover' }} />
    );
    const fullWidthImage = (src: string) => <img src={src} width="100%" height="100%" style={{ objectFit: 'cover' }} />;
    const startInCabinWellness = wellnessStart?.IN_CABIN
        ? wellnessStart.IN_CABIN === 'loading'
            ? skeleton
            : image(wellnessStart.IN_CABIN, shouldCropStartIC ? 'ic-image' : '')
        : placeholder;
    const startFrontFacingWellness = wellnessStart?.FRONT_FACING
        ? wellnessStart.FRONT_FACING === 'loading'
            ? skeleton
            : image(wellnessStart.FRONT_FACING, shouldCropStartFF ? 'ff-image' : '')
        : null;

    let start;
    if (snapshotStart !== undefined) {
        if (snapshotStart === 'no snapshot') {
            start = (
                <>
                    {startInCabinWellness}
                    {startFrontFacingWellness}
                </>
            );
        } else if (snapshotStart === 'loading') {
            start = fullWidthSkeleton;
        } else {
            start = fullWidthImage(snapshotStart);
        }
    } else {
        start = placeholder;
    }

    const endInCabinWellness = wellnessEnd?.IN_CABIN
        ? wellnessEnd.IN_CABIN === 'loading'
            ? skeleton
            : image(wellnessEnd.IN_CABIN, shouldCropEndIC ? 'ic-image' : '')
        : placeholder;
    const endFrontFacingWellness = wellnessEnd?.FRONT_FACING
        ? wellnessEnd.FRONT_FACING === 'loading'
            ? skeleton
            : image(wellnessEnd.FRONT_FACING, shouldCropEndFF ? 'ff-image' : '')
        : null;

    let end;
    if (snapshotEnd !== undefined) {
        if (snapshotEnd === 'no snapshot') {
            end = (
                <>
                    {endInCabinWellness}
                    {endFrontFacingWellness}
                </>
            );
        } else if (snapshotEnd === 'loading') {
            end = fullWidthSkeleton;
        } else {
            end = fullWidthImage(snapshotEnd);
        }
    } else {
        end = placeholder;
    }

    let arrowIcon;
    if (isMobile) {
        arrowIcon = <KeyboardArrowDownIcon color="primary" sx={{ fontSize: 40, mx: 1 }} />;
    } else {
        if (isRTL) {
            arrowIcon = <ArrowBackIosNewIcon color="primary" sx={{ mx: 1 }} />;
        } else {
            arrowIcon = <ArrowForwardIosIcon color="primary" sx={{ mx: 1 }} />;
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', width: '100%' }}>
            <Box
                id="start-images"
                style={{
                    width: '100%',
                    display: 'flex',
                    aspectRatio: 8 / 3,
                    outline: isMobile ? undefined : `1px solid ${palette.primary}`,
                }}
            >
                {start}
            </Box>
            {arrowIcon}
            <Box
                id="end-images"
                style={{
                    width: '100%',
                    display: 'flex',
                    aspectRatio: 8 / 3,
                    outline: isMobile ? undefined : `1px solid ${palette.primary}`,
                }}
            >
                {end}
            </Box>
        </Box>
    );
};

export default SegmentImages;
