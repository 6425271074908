import { Box, Card, Snackbar, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../components/TrackedComponents';

interface NPSNumericQuestionProps {
    setResponse: (response: number) => void;
    cancelButton: React.ReactNode;
}

const NPSNumericQuestion: React.FC<NPSNumericQuestionProps> = (props: NPSNumericQuestionProps) => {
    const { t } = useTranslation();

    const message = (
        <React.Fragment>
            {Array.from(Array(10).keys()).map((i) => (
                <Button
                    id={`nps-numeric-question-${i + 1}`}
                    data-testid={`nps-numeric-question-${i + 1}`}
                    key={i}
                    color="primary"
                    variant="outlined"
                    size="small"
                    sx={{ mx: 1 }}
                    onClick={() => props.setResponse(i + 1)}
                >
                    <Typography fontSize={14}>{i + 1}</Typography>
                </Button>
            ))}
        </React.Fragment>
    );

    return (
        <Snackbar
            open={true}
            color="#000000"
            autoHideDuration={6000}
            onClose={() => null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Card sx={{ backgroundColor: 'white', p: 2 }}>
                <Box sx={{ my: 1, width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Box>
                        <Typography>{t('nps.recommend_quesion')}</Typography>
                    </Box>
                    <Box>{props.cancelButton}</Box>
                </Box>
                <Box sx={{ my: 1, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>{message}</Box>
                </Box>
            </Card>
        </Snackbar>
    );
};

export default NPSNumericQuestion;
