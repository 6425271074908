import { Box, Card, Typography } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import React, { useState } from 'react';

import { TrackedButton as Button } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import VehicleModal from '../Vehicles/VehicleModal';
import WellnessModal from '../Wellness/WellnessModal';
import ClusterMapComponent from './ClusterMap';
import { DataItem, PROHUB_MIXPANEL_SESSION } from './Defs';
import SendReminderDialog from './SendReminderDialog';

interface InsightItemCardProps {
    details: DataItem;
    changeDismissed: CallableFunction;
}

const InsightItemCard: React.FC<InsightItemCardProps> = (props) => {
    const [openReminderDialog, setOpenReminderDialog] = useState<boolean>(false);
    const [selectedVehicleSafety, setSelectedVehicleSafety] = useState<boolean>(false);
    const [selectedVehicleWellness, setSelectedVehicleWellness] = useState<boolean>(false);
    const [alertElement, setAlert] = useAlert();

    const takeAction = (action: string) => {
        if (action == 'send reminder') {
            setOpenReminderDialog(true);
        } else if (action == 'view safety card') {
            setSelectedVehicleSafety(true);
        } else if (action == 'view wellness card') {
            setSelectedVehicleWellness(true);
        } else if (action == 'coaching session') {
            window.open(`/coaching/${props.details.coachingSession}`, '_blank');
        }
        mixpanel.track('prohub', {
            name: `${props.details.type}: ${props.details.id} action ${action} taken`,
            session_id: PROHUB_MIXPANEL_SESSION,
        });
    };

    const additionalAttributes = props.details.collapsedActions
        ? { height: 0, top: '-50px', position: 'relative' }
        : {};

    const changeDismissedStatus = () => {
        const changeToDismissed = !props.details.dismissed;
        mixpanel.track('prohub', {
            name: `${props.details.type}: ${props.details.id} ${changeToDismissed ? 'completed' : 'reopened'}`,
            session_id: PROHUB_MIXPANEL_SESSION,
        });
        props.changeDismissed(props.details.id, changeToDismissed);
    };
    return (
        <Card
            sx={{
                mt: '10px',
                p: 2,
                width: 'clamp(35%, 650px, 75%)',
                backgroundColor: '#ffffff',
                borderRadius: '15px',
            }}
        >
            {alertElement}
            <Box>
                {openReminderDialog && !!props.details.expiryData ? (
                    <SendReminderDialog
                        itemId={props.details.id}
                        expired={props.details.expiryData.expired}
                        driverName={props.details.expiryData.driverName}
                        expiryDate={props.details.expiryData.expiryDate}
                        onClose={() => setOpenReminderDialog(false)}
                        setAlert={setAlert}
                    />
                ) : null}
                {selectedVehicleSafety && !!props.details.licensePlate ? (
                    <VehicleModal
                        licensePlate={props.details.licensePlate}
                        onClose={() => setSelectedVehicleSafety(false)}
                        initialTab={0}
                    />
                ) : null}
                {selectedVehicleWellness && !!props.details.licensePlate ? (
                    <WellnessModal
                        licensePlate={props.details.licensePlate}
                        onClose={() => setSelectedVehicleWellness(false)}
                        initialTab={0}
                    />
                ) : null}
                <Typography sx={{ fontWeight: 'bold', fontSize: '18' }}>{props.details.header}</Typography>
                {props.details.content.map(([contentType, contentItem], idx) => {
                    if (contentType === 'string') {
                        return (
                            <Typography key={idx} sx={{ fontSize: '18' }}>
                                {contentItem}
                            </Typography>
                        );
                    } else if (contentType === 'map' && props.details.map) {
                        return <ClusterMapComponent key={idx} header={contentItem} details={props.details.map} />;
                    } else if (contentType === 'image') {
                        return (
                            <img
                                key={idx}
                                src={contentItem}
                                alt="content"
                                style={{ width: props.details.imageWidth || '50%', height: 'auto' }}
                            />
                        );
                    }
                })}
                <Box sx={{ width: '100%', direction: 'rtl', ...additionalAttributes }}>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: 1,
                        }}
                    >
                        <Button
                            id="complete-btn"
                            data-testid="complete-btn"
                            variant="contained"
                            color="primary"
                            onClick={changeDismissedStatus}
                            sx={{ ml: 1 }}
                        >
                            {props.details.dismissed ? 'REOPEN' : 'COMPLETE'}
                        </Button>
                        {props.details.actions?.map((action, idx) => (
                            <Button
                                key={`action-${idx}`}
                                id={`action-${idx}`}
                                data-testid={`action-${idx}`}
                                variant="contained"
                                color="secondary"
                                disabled={props.details.dismissed}
                                onClick={() => takeAction(action)}
                                sx={{ ml: 1 }}
                            >
                                {action}
                            </Button>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};

export default InsightItemCard;
