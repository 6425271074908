import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MovieIcon from '@mui/icons-material/Movie';
import TreeItem from '@mui/lab/TreeItem';
import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaFile } from '../../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';

interface MediaLibraryItemProps {
    file: MediaFile;
    setSelectedFile: CallableFunction;
    setFiles: CallableFunction;
    setAlert: CallableFunction;
    setFileToDelete: CallableFunction;
}

const MediaLibraryItem: React.FC<MediaLibraryItemProps> = (props: MediaLibraryItemProps) => {
    const { api } = useApi();
    const { t } = useTranslation();
    const fileType = props.file.file_type.split('/')[0];

    const handleOpen = () => {
        api.apiV0MediaFilesMediaFileIdGet({
            mediaFileId: props.file.id,
        }).then((response) => {
            const newWindow = window.open(response.data, '_blank', 'noopener,noreferrer');
            if (newWindow) {
                newWindow.opener = null;
            }
        });
    };

    const handleEdit = () => {
        props.setSelectedFile(props.file);
    };

    const handleDelete = () => {
        props.setFileToDelete(props.file);
    };

    return (
        <TreeItem
            id={`${props.file.id}-tree-item`}
            data-testid={`${props.file.id}-tree-item`}
            nodeId={props.file.id.toString()}
            label={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: 40,
                        py: 0.5,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {fileType === 'image' ? (
                            <ImageIcon fontSize="small" />
                        ) : fileType === 'video' ? (
                            <MovieIcon fontSize="small" />
                        ) : (
                            <InsertDriveFileIcon fontSize="small" />
                        )}
                        <Box sx={{ width: '250px' }}>
                            <Typography
                                sx={{ ml: 0.5, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                            >
                                {props.file.file_name}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Tooltip title={t('content.coach.add_item.media_file.library.open_file')}>
                            <Box>
                                <IconButton
                                    id={`open-file-${props.file.id}`}
                                    data-testid={`open-file-${props.file.id}`}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpen();
                                    }}
                                >
                                    <FileOpenIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title={t('content.coach.add_item.media_file.library.edit_file')}>
                            <Box>
                                <IconButton
                                    id={`edit-file-${props.file.id}`}
                                    data-testid={`edit-file-${props.file.id}`}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit();
                                    }}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title={t('content.coach.add_item.media_file.library.delete_file')}>
                            <Box>
                                <IconButton
                                    id={`delete-file-${props.file.id}`}
                                    data-testid={`delete-file-${props.file.id}`}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete();
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            }
            sx={{ '& .MuiTreeItem-content': { pr: 0.5 } }}
        />
    );
};

export default MediaLibraryItem;
