import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { TrackedDialog as Dialog } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';

interface ConfigModalProps {
    customer: string;
    sub_fleet: string;
    onClose: () => void;
}

const ConfigModal: React.FC<ConfigModalProps> = (props) => {
    const [configuration, setConfiguration] = useState<unknown>();
    const { controlApi } = useApi();

    useEffect(() => {
        controlApi
            .controlV0CustomerConfigCustomerNameSubFleetNameGet({
                customerName: props.customer,
                subFleetName: props.sub_fleet,
            })
            .then((res) => {
                setConfiguration(res.data);
            });
    }, [props.customer, props.sub_fleet]);

    return (
        <Dialog id="config-dialog" open={true} maxWidth="md" fullWidth onClose={props.onClose}>
            <Box sx={{ backgroundColor: 'secondary.main', p: 1 }}>Configuration</Box>
            {!!configuration ? (
                <pre id="config" style={{ overflow: 'auto', maxHeight: '80vh' }}>
                    {JSON.stringify(configuration, null, 2)}
                </pre>
            ) : (
                <CircularProgress />
            )}
        </Dialog>
    );
};

export default ConfigModal;
