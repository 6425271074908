import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Paper, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PolicyV2 } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import { POLICY_COLUMNS, POLICY_OPTIONS_LIST, PolicyOptionsKeys } from './Defs';

interface UploadPoliciesModalProps {
    onClose: () => void;
    onUpload: CallableFunction;
}

const readFile = (file: File) => {
    return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result as string), false);
        reader.readAsText(file);
    });
};

const parseField = (key: keyof PolicyV2, value: string) => {
    const columnType = POLICY_COLUMNS[key];
    if (columnType === 'number') {
        return parseInt(value);
    }
    if (columnType === 'date') {
        if (!value || /^\d{4}-\d{2}-\d{2}$/.test(value)) {
            return value;
        } else {
            throw new Error('invalid_date_format', { cause: { key, value } });
        }
    }
    if (columnType === 'boolean') {
        return value.toLocaleLowerCase() === 'true';
    }
    if (columnType === 'singleSelect') {
        if (POLICY_OPTIONS_LIST[key as PolicyOptionsKeys].includes(value.toLocaleLowerCase())) {
            return value.toLocaleLowerCase();
        } else {
            throw new Error('invalid_option', { cause: { key, value } });
        }
    }
    return value;
};

const readFileContent = (text: string) => {
    const lines = text.split(/\r?\n/);
    let headers: string[] = [];
    const policiesData: PolicyV2[] = [];
    for (const [idx, line] of Array.from(lines.entries())) {
        if (headers.length == 0) {
            headers = line.split(',').map((header: string) => header.trim());
            const wrongHeaders = headers.filter((header) => !Object.keys(POLICY_COLUMNS).includes(header));
            if (wrongHeaders.length > 0) {
                throw new Error('invalid_header', {
                    cause: { line: idx, key: 'headers', value: wrongHeaders.join(',') },
                });
            }
        } else if (!!line) {
            try {
                const parts = line.split(',');
                policiesData.push({
                    id: 0,
                    ...Object.fromEntries(
                        headers.map((key: string, idx: number) => [
                            key,
                            parseField(key as keyof PolicyV2, parts[idx].trim()),
                        ]),
                    ),
                } as PolicyV2);
            } catch (e) {
                const err = e as Error;
                err.cause = { line: idx, ...(err.cause || {}) };
                throw e;
            }
        }
    }
    return policiesData;
};

const UploadPoliciesModal: React.FC<UploadPoliciesModalProps> = (props: UploadPoliciesModalProps) => {
    const [step, setStep] = useState<number>(1);
    const [polciesData, setPoliciesData] = useState<PolicyV2[]>([]);
    const [alertElement, setAlert] = useAlert();
    const { t } = useTranslation();

    const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const fileContent = await readFile(file);
            try {
                const newPolices = readFileContent(fileContent);
                setPoliciesData(newPolices);
                if (newPolices.length > 0) {
                    setStep(3);
                }
            } catch (e) {
                const err = e as Error;
                setAlert({
                    message: t(`content.policies.upload.errors.${err.message}`, err.cause as object),
                    type: 'error',
                    duration: 6000,
                });
            }
        }
    };

    const downloadTemplate = () => {
        const element = document.createElement('a');
        const file = new Blob(
            [
                'license_plate,vehicle_type,customer_type,start_date,end_date,',
                'cancellation_date,driver_refuse,insurance_company,total_loss,comment\n',
            ],
            { type: 'text/csv' },
        );
        element.href = URL.createObjectURL(file);
        element.download = 'policies.csv';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
        setStep(2);
    };

    return (
        <Dialog id="upload-policies-dialog" open={true} fullWidth={true} maxWidth="sm" onClose={() => props.onClose()}>
            {alertElement}
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccountCircleIcon sx={{ mr: 0.5 }} />
                    {t('content.policies.upload.header')}
                </Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    p: 1,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <Box>
                        <ol>
                            <li>
                                <Typography
                                    data-testid="upload-step-1"
                                    variant="body2"
                                    sx={{ fontWeight: step === 1 ? 'bold' : 'normal' }}
                                >
                                    {t('content.policies.upload.explain1')}
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: step === 1 ? 'bold' : 'normal' }}>
                                    {t('content.policies.upload.explain1-1')}
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: step === 1 ? 'bold' : 'normal' }}>
                                    {t('content.policies.upload.explain1-2', {
                                        options: POLICY_OPTIONS_LIST.vehicle_type.join(' / '),
                                    })}
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: step === 1 ? 'bold' : 'normal' }}>
                                    {t('content.policies.upload.explain1-3', {
                                        options: POLICY_OPTIONS_LIST.customer_type.join(' / '),
                                    })}
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: step === 1 ? 'bold' : 'normal' }}>
                                    {t('content.policies.upload.explain1-4')}
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    data-testid="upload-step-2"
                                    variant="body2"
                                    sx={{ fontWeight: step === 2 ? 'bold' : 'normal' }}
                                >
                                    {t('content.policies.upload.explain2')}
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    data-testid="upload-step-3"
                                    variant="body2"
                                    sx={{ fontWeight: step === 3 ? 'bold' : 'normal' }}
                                >
                                    {t('content.policies.upload.explain3')}
                                </Typography>
                            </li>
                        </ol>
                    </Box>
                </Box>
                <Box
                    sx={{
                        backgroundColor: 'bgColor.main',
                        p: 1,
                        borderRadius: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        id="download-template-btn"
                        size="small"
                        color={step == 1 ? 'secondary' : 'primary'}
                        variant="contained"
                        component="label"
                        sx={{ mr: 1 }}
                        onClick={() => downloadTemplate()}
                    >
                        {t('content.policies.upload.dowload_template')}
                    </Button>
                    <Button
                        id="upload-file-btn"
                        color={step == 2 ? 'secondary' : 'primary'}
                        size="small"
                        variant="contained"
                        component="label"
                        sx={{ mr: 1 }}
                    >
                        {t('content.policies.upload.file')}
                        <input
                            id="file-btn"
                            data-testid="file-btn"
                            hidden
                            type="file"
                            accept=".csv"
                            onChange={onFileChange}
                        />
                    </Button>
                    <Button
                        id="submit-upload-btn"
                        size="small"
                        color={step == 3 ? 'secondary' : 'primary'}
                        variant="contained"
                        component="label"
                        sx={{ mr: 1 }}
                        disabled={!polciesData.length}
                        onClick={() => props.onUpload(polciesData)}
                    >
                        {t('content.policies.upload.submit')}
                    </Button>
                </Box>
            </Paper>
        </Dialog>
    );
};

export default UploadPoliciesModal;
