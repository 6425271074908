import { Avatar, Box, List, ListItemAvatar, Typography, lighten, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Descendant, Editor, Range, Element as SlateElement, Transforms } from 'slate';
import { BasePoint } from 'slate';
import {
    Editable,
    ReactEditor,
    RenderElementProps,
    RenderLeafProps,
    Slate,
    useFocused,
    useSelected,
} from 'slate-react';

import { TrackedListItemButton as ListItemButton } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import { stringAvatar } from '../../../utils/Avatar';
import { User } from '../../../utils/slate';
import palette from '../../ColorPalette';
import { CustomText, Leaf } from '../Coaching/Agenda/RichTextField';

const wordRegexp = /[^ ]/;

const getLeftChar = (editor: ReactEditor, point: BasePoint) => {
    // get character to the left of the cursor
    const end = Range.end(editor.selection as Range);
    return Editor.string(editor, {
        anchor: {
            path: end.path,
            offset: point.offset - 1,
        },
        focus: {
            path: end.path,
            offset: point.offset,
        },
    });
};

const getRightChar = (editor: ReactEditor, point: BasePoint) => {
    // get character to the right of the cursor
    const end = Range.end(editor.selection as Range);
    return Editor.string(editor, {
        anchor: {
            path: end.path,
            offset: point.offset,
        },
        focus: {
            path: end.path,
            offset: point.offset + 1,
        },
    });
};

const getCurrentWord = (editor: ReactEditor) => {
    // get currently selected word in slate editor
    const { selection } = editor; // selection is Range type

    if (selection) {
        const end = Range.end(selection); // end is a Point
        let currentWord = '';
        const currentPosition = cloneDeep(end);
        let startOffset = end.offset;
        let endOffset = end.offset;

        // go left from cursor until it finds the non-word character
        while (currentPosition.offset >= 0 && getLeftChar(editor, currentPosition).match(wordRegexp)) {
            currentWord = getLeftChar(editor, currentPosition) + currentWord;
            startOffset = currentPosition.offset - 1;
            currentPosition.offset--;
        }

        // go right from cursor until it finds the non-word character
        currentPosition.offset = end.offset;
        while (currentWord.length && getRightChar(editor, currentPosition).match(wordRegexp)) {
            currentWord += getRightChar(editor, currentPosition);
            endOffset = currentPosition.offset + 1;
            currentPosition.offset++;
        }

        const currentRange: Range = {
            anchor: {
                path: end.path,
                offset: startOffset,
            },
            focus: {
                path: end.path,
                offset: endOffset,
            },
        };

        return {
            currentWord,
            currentRange,
        };
    }

    return {};
};

export interface MentionElement {
    type: 'mention';
    user: User;
    children: CustomText[];
}

interface PortalProps {
    children: React.ReactNode;
}

const Portal: React.FC<PortalProps> = ({ children }) => {
    return ReactDOM.createPortal(children, document.body);
};

interface MentionsTextFieldProps {
    containerSelector?: string;
    setValue?: CallableFunction;
    editor: Editor;
    users?: User[];
    userAvatarsMap?: Record<number, string>;
    initialValue?: Descendant[];
    readOnly?: boolean;
    disabled?: boolean;
    invertMentions?: boolean;
    darkMode?: boolean;
}

const MentionsTextField: React.FC<MentionsTextFieldProps> = (props: MentionsTextFieldProps) => {
    const { editor } = props;
    const ref = useRef<HTMLDivElement>();
    const [target, setTarget] = useState<Range | null>();
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const { t } = useTranslation();
    const theme = useTheme();
    const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

    const users = (props.users || []).filter((user) => user.name.toLowerCase().includes(search.toLowerCase()));

    const scrollItemIntoView = (container: HTMLDivElement | undefined, item: Element) => {
        // scroll item into view if it's not visible
        if (container) {
            const containerRect = container.getBoundingClientRect();
            const itemRect = item.getBoundingClientRect();
            if (itemRect.top < containerRect.top || itemRect.bottom > containerRect.bottom) {
                item.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest',
                });
            }
        }
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
        // handle keyboard events for mentions
        if (target && users.length > 0) {
            const container = ref.current;
            const listItems = document.querySelectorAll('.mention-item');
            switch (event.key) {
                case 'ArrowDown':
                    event.preventDefault();
                    const prevIndex = index >= users.length - 1 ? 0 : index + 1;
                    setIndex(prevIndex);
                    scrollItemIntoView(container, listItems[prevIndex]);
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    const nextIndex = index <= 0 ? users.length - 1 : index - 1;
                    setIndex(nextIndex);
                    scrollItemIntoView(container, listItems[nextIndex]);
                    break;
                case 'Tab':
                case 'Enter':
                    event.preventDefault();
                    Transforms.select(editor, target);
                    insertMention(editor, users[index]);
                    setTarget(null);
                    break;
                case 'Escape':
                    event.preventDefault();
                    setTarget(null);
                    break;
            }
        }
    };

    const placeMentionsContainer = () => {
        // place mentions container next to the cursor and fix its position if it's out of the viewport
        const el = ref.current;
        if (target && users.length > 0 && el) {
            const elHeight = el.offsetHeight;
            const elWidth = el.offsetWidth;
            const domRange = ReactEditor.toDOMRange(editor, target);
            const rect = domRange.getBoundingClientRect();

            if (rect.top + elHeight < window.innerHeight) {
                el.style.top = `${rect.top + window.scrollY + 24}px`;
            } else {
                el.style.top = `${rect.bottom + window.scrollY - elHeight - 20}px`;
            }
            if (theme.direction === 'rtl') {
                if (rect.right - elWidth > 0) {
                    el.style.right = `${Math.max(window.innerWidth - rect.right, 8)}px`;
                } else {
                    el.style.right = `${Math.max(window.innerWidth - elWidth, 8)}px`;
                }
            } else {
                if (rect.right + elWidth < window.innerWidth) {
                    el.style.left = `${Math.max(rect.left, 8)}px`;
                } else {
                    el.style.left = `${Math.max(rect.left - elWidth + 12, 8)}px`;
                }
            }
        }
    };

    useEffect(placeMentionsContainer, [users.length, editor, index, search, target]);

    useEffect(() => {
        // keep mentions container in place when scrolling the parent container
        if (props.containerSelector) {
            const inputContainer = document.querySelector(props.containerSelector);
            if (inputContainer) {
                inputContainer.addEventListener('scroll', placeMentionsContainer);
                return () => inputContainer.removeEventListener('scroll', placeMentionsContainer);
            }
        }
    }, [target]);

    return (
        <Box
            className={`mentions-container${props.readOnly ? '-readonly' : ''}${props.darkMode ? '-dark' : ''}`}
            onScroll={placeMentionsContainer}
        >
            <Slate
                editor={editor}
                initialValue={props.initialValue || ([{ type: 'paragraph', children: [{ text: '' }] }] as Descendant[])}
                onChange={(value) => {
                    const x = getCurrentWord(editor);
                    if (x.currentRange && x.currentWord && x.currentWord.startsWith('@')) {
                        setTarget(x.currentRange);
                        setSearch(x.currentWord.slice(1));
                        setIndex(0);
                    } else {
                        setTarget(null);
                    }
                    if (props.setValue) {
                        props.setValue(value);
                    }
                }}
            >
                <Editable
                    readOnly={props.readOnly || props.disabled}
                    className={`mentions-text-field${props.darkMode ? '-dark' : ''}`}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={onKeyDown}
                    placeholder={t('content.accidents.comments.placeholder')}
                />
                {target && users.length > 0 ? (
                    <>
                        <Portal>
                            <Box
                                ref={ref}
                                sx={{
                                    top: '-9999px',
                                    left: '-9999px',
                                    position: 'fixed',
                                    zIndex: 1500,
                                    padding: '3px',
                                    background: props.darkMode ? '#383838' : palette.white,
                                    color: props.darkMode ? palette.neutral[50] : palette.neutral[700],
                                    borderRadius: '4px',
                                    boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                                    maxHeight: 350,
                                    width: 302,
                                    overflow: 'auto',
                                }}
                                data-cy="mentions-portal"
                            >
                                <List disablePadding>
                                    {users.map((user, i) => {
                                        const avatarProps =
                                            props.userAvatarsMap && props.userAvatarsMap[user.id]
                                                ? { src: props.userAvatarsMap[user.id], sx: { width: 33, height: 33 } }
                                                : {
                                                      ...stringAvatar(user.name, {
                                                          width: 33,
                                                          height: 33,
                                                          fontSize: 16,
                                                      }),
                                                  };
                                        return (
                                            <ListItemButton
                                                id={`${user.id}-mention-option`}
                                                className="mention-item"
                                                key={user.id}
                                                selected={i === index}
                                                onClick={() => {
                                                    Transforms.select(editor, target);
                                                    insertMention(editor, user);
                                                    setTarget(null);
                                                }}
                                                sx={{ px: 1 }}
                                            >
                                                <ListItemAvatar sx={{ minWidth: 0, mr: 1 }}>
                                                    <Avatar imgProps={{ loading: 'lazy' }} {...avatarProps} />
                                                </ListItemAvatar>
                                                <Typography fontSize={14}>{user.name}</Typography>
                                            </ListItemButton>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Portal>
                    </>
                ) : null}
            </Slate>
        </Box>
    );
};

export const withMentions = (editor: Editor) => {
    const { isInline, isVoid, markableVoid } = editor;

    editor.isInline = (element: SlateElement) => {
        return element.type === 'mention' || isInline(element);
    };

    editor.isVoid = (element: SlateElement) => {
        return element.type === 'mention' || isVoid(element);
    };

    editor.markableVoid = (element: SlateElement) => {
        return element.type === 'mention' || markableVoid(element);
    };

    return editor;
};

const insertMention = (editor: Editor, user: User) => {
    const mention: MentionElement = {
        type: 'mention',
        user,
        children: [{ text: '' }],
    };
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
    ReactEditor.focus(editor);
};

const Element = (props: RenderElementProps) => {
    const { attributes, children, element } = props;
    switch (element.type) {
        case 'mention':
            return <Mention {...props} />;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const Mention = ({ attributes, children, element }: RenderElementProps) => {
    const { profile } = useProfile();
    const selected = useSelected();
    const focused = useFocused();
    const style: React.CSSProperties = {
        padding: '0 3px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: element.user?.id === profile.user_id ? lighten(palette.secondary, 0.25) : palette.neutral[300],
        fontSize: '0.9em',
        boxShadow:
            selected && focused
                ? `0 0 0 1px ${palette.neutral[400]}`
                : `1px 1px 1px 1px ${alpha(palette.primary, 0.25)}`,
        color: palette.primary,
    };

    return (
        <span
            {...attributes}
            contentEditable={false}
            data-cy={`mention-${element.user?.name.replace(' ', '-')}`}
            style={style}
        >
            @{element.user?.name}
            {children}
        </span>
    );
};

export default MentionsTextField;
