import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';
import {
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Paper,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Alert, DeviceV3, Geofence, Report } from '../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
} from '../../../../components/TrackedComponents';
import palette from '../../../ColorPalette';
import { EMPTY_REPORT } from '../../Reports/Report';
import ReportAlertDetailsComponent, { REPORT_TYPE_OPTIONS } from '../../Reports/ReportDetails';
import { AlertReport } from '../../Reports/ReportOptions';
import { GeoJSONFeatureProperties, MAX_VERTICES } from '../FleetOverview';

interface ConfirmDeleteDialogProps {
    numberOfRelatedReports: number;
    isDeleteDialogOpen: boolean;
    setIsDeleteDialogOpen: CallableFunction;
    onDelete: CallableFunction;
}

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
    numberOfRelatedReports,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    onDelete,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog id="delete-geofence-dialog" open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => setIsDeleteDialogOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DeleteIcon sx={{ mr: 0.5 }} />
                    {t('content.fleet.geofence.delete_geofence')}
                </Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    pt: 1,
                    pb: 2,
                    px: 3,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography>{t('content.fleet.geofence.confirm')}</Typography>
                {numberOfRelatedReports > 0 ? (
                    <Typography>
                        {numberOfRelatedReports > 1
                            ? t(`content.fleet.geofence.confirm_reports_plural`, { amount: numberOfRelatedReports })
                            : t('content.fleet.geofence.confirm_reports')}
                    </Typography>
                ) : null}
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <Button
                        id="cancel-delete-btn"
                        data-testid="cancel-delete-btn"
                        variant="contained"
                        color="primary"
                        onClick={() => setIsDeleteDialogOpen(false)}
                        sx={{ mr: 1 }}
                    >
                        {t('content.fleet.geofence.cancel')}
                    </Button>
                    <Button
                        id="confirm-delete-btn"
                        data-testid="confirm-delete-btn"
                        variant="contained"
                        color="redColor"
                        onClick={() => onDelete()}
                    >
                        {t('content.fleet.geofence.delete')}
                    </Button>
                </Box>
            </Paper>
        </Dialog>
    );
};

export interface GeofenceOptions {
    enter: boolean;
    leave: boolean;
}

export const getEmptyGeofenceReport = (geofenceId: number): AlertReport => {
    return JSON.parse(
        JSON.stringify({
            ...EMPTY_REPORT,
            report_type: REPORT_TYPE_OPTIONS.GEOFENCE_EVENTS,
            filters: {
                GEOFENCE: [geofenceId.toString()],
            },
        }),
    );
};

interface GeofenceDetailsProps {
    details?: GeoJSONFeatureProperties;
    reports?: { alerts: Alert[]; reports: Report[] };
    devices?: DeviceV3[];
    geofences?: Geofence[];
    isMapInEditMode: boolean;
    onSave: CallableFunction;
    onCancel: CallableFunction;
    onDelete: CallableFunction;
    isValidPolygon: boolean;
    setPreviewColor: CallableFunction;
    isLoading: boolean;
}

const GeofenceDetails: React.FC<GeofenceDetailsProps> = (props: GeofenceDetailsProps) => {
    const { t } = useTranslation();
    const [geofenceName, setGeofenceName] = useState<string>(
        props.details?.name || t('content.fleet.geofence.new_geofence'),
    );
    const [geoEvents, setGeoEvents] = useState<GeofenceOptions>(
        props.details?.events || { enter: false, leave: false },
    );
    const [geofenceColor, setGeofenceColor] = useState(props.details?.color || palette.accent);
    const [isNameError, setIsNameError] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [showNewReport, setShowNewReport] = useState<boolean>(false);
    const theme = useTheme();
    const isEdit = props.details !== undefined;

    const geofenceIdToName = Object.fromEntries((props.geofences || []).map((g) => [g.id.toString(), g.name]));
    let numberOfRelatedReports = 0;
    if (props.details && props.reports) {
        const reportAlerts = [...props.reports.alerts, ...props.reports.reports] as AlertReport[];
        numberOfRelatedReports = reportAlerts.filter(
            (r) =>
                (r.report_type === REPORT_TYPE_OPTIONS.GEOFENCE_EVENTS && r.filters.GEOFENCE === undefined) ||
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (r.filters.GEOFENCE && r.filters.GEOFENCE.includes(props.details!.id.toString())),
        ).length;
    }

    let reportsSectionContent = <Typography variant="body2">{t('content.fleet.geofence.save_geofence')}</Typography>;
    if (props.details) {
        if (numberOfRelatedReports > 0) {
            reportsSectionContent = (
                <>
                    <Typography variant="body2">
                        {numberOfRelatedReports > 1
                            ? t('content.fleet.geofence.number_of_reports_plural', { count: numberOfRelatedReports })
                            : t('content.fleet.geofence.number_of_reports')}
                    </Typography>
                    <Link
                        id="view-reports-btn"
                        component={RouterLink}
                        data-testid="view-reports-btn"
                        to={'/reports?type=geofence_events'}
                    >
                        <Typography variant="body2">{t('content.fleet.geofence.view_reports')}</Typography>
                    </Link>
                </>
            );
        } else {
            reportsSectionContent = (
                <>
                    <Typography variant="body2">{t('content.fleet.geofence.no_reports')}</Typography>
                    <Link
                        id="add-report-btn"
                        data-testid="add-report-btn"
                        component="button"
                        onClick={() => setShowNewReport(true)}
                    >
                        <Typography variant="body2">{t('content.fleet.geofence.add_report')}</Typography>
                    </Link>
                </>
            );
        }
    }

    useEffect(() => {
        return () => props.setPreviewColor(undefined);
    }, []);

    const validateName = () => {
        if (geofenceName.length === 0) {
            setIsNameError(true);
            return false;
        }
        return true;
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                minHeight: 0,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <ConfirmDeleteDialog
                {...{ numberOfRelatedReports, isDeleteDialogOpen, setIsDeleteDialogOpen, onDelete: props.onDelete }}
            />
            {props.details && showNewReport ? (
                <ReportAlertDetailsComponent
                    details={getEmptyGeofenceReport(props.details.id)}
                    devices={props.devices || []}
                    geofences={geofenceIdToName}
                    onClose={() => setShowNewReport(false)}
                    fromOtherPage={true}
                />
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: 60,
                    flexShrink: 0,
                    backgroundColor: palette.neutral[200],
                    pl: 1.75,
                    pr: 0.5,
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {props.isLoading ? (
                        <Box sx={{ width: 28, height: 28, mr: 1 }}>
                            <CircularProgress size={28} />
                        </Box>
                    ) : (
                        <Box sx={{ width: 28, height: 28, mr: 1, position: 'relative' }}>
                            <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="14" cy="14" r="14" fill={geofenceColor} />
                            </svg>
                            <Box
                                sx={{
                                    width: 28,
                                    height: 28,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                            >
                                <PlaceIcon
                                    sx={{
                                        color: theme.palette.getContrastText(geofenceColor),
                                        fontSize: 22,
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {geofenceName}
                    </Typography>
                </Box>
                <IconButton
                    id="delete-geofence-btn"
                    data-testid="delete-geofence-btn"
                    disabled={props.isMapInEditMode || props.isLoading}
                    onClick={() => setIsDeleteDialogOpen(true)}
                >
                    <DeleteIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    p: 2,
                    overflowY: 'auto',
                    minHeight: 0,
                }}
            >
                <TextField
                    size="small"
                    label={t('content.fleet.geofence.name')}
                    error={isNameError}
                    helperText={isNameError ? 'Name cannot be empty' : ''}
                    value={geofenceName}
                    onChange={(e) => setGeofenceName(e.target.value)}
                    inputProps={{ id: 'geofence-name-field', 'data-testid': 'geofence-name-field' }}
                    sx={{ mt: 0.5, mb: 2 }}
                />
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'start' }}>
                    <Typography sx={{ fontSize: 16, color: 'grayColor.main', fontWeight: 'bold' }}>
                        {t('content.fleet.geofence.events')}
                    </Typography>
                    <Typography variant="body2" sx={{ my: 0.5 }}>
                        {t('content.fleet.geofence.events_prefix')}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="geofence-enter-checkbox"
                                data-testid="geofence-enter-checkbox"
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                inputProps={{ 'data-testid': 'geofence-enter-checkbox-input' } as any}
                                disableRipple
                                checked={geoEvents.enter}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGeoEvents((prev) => ({ ...prev, enter: e.target.checked }))
                                }
                                size="small"
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{ mt: 0.25 }}>
                                {t('content.fleet.geofence.enter')}
                            </Typography>
                        }
                        sx={{
                            '& .MuiCheckbox-root': {
                                py: 0,
                                pr: 0.5,
                            },
                            mb: 0.5,
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="geofence-leave-checkbox"
                                data-testid="geofence-leave-checkbox"
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                inputProps={{ 'data-testid': 'geofence-leave-checkbox-input' } as any}
                                disableRipple
                                checked={geoEvents.leave}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setGeoEvents((prev) => ({ ...prev, leave: e.target.checked }))
                                }
                                size="small"
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{ mt: 0.25 }}>
                                {t('content.fleet.geofence.leave')}
                            </Typography>
                        }
                        sx={{
                            '& .MuiCheckbox-root': {
                                py: 0,
                                pr: 0.5,
                            },
                            mb: 2,
                        }}
                    />
                    <Typography sx={{ fontSize: 16, color: 'grayColor.main', fontWeight: 'bold' }}>
                        {t('content.fleet.geofence.reports')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mb: 2 }}>
                        {reportsSectionContent}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'start', mb: 2 }}>
                    <Typography sx={{ fontSize: 16, color: 'grayColor.main', fontWeight: 'bold' }}>
                        {t('content.fleet.geofence.color')}
                    </Typography>
                    <HexColorPicker
                        color={geofenceColor}
                        onChange={(newColor) => {
                            setGeofenceColor(newColor);
                            props.setPreviewColor(newColor);
                        }}
                    />
                </Box>
                {!props.isValidPolygon && (
                    <>
                        <Typography color="error" variant="body2" sx={{ fontWeight: 'bold' }}>
                            {t('content.fleet.geofence.vertex_error', { limit: MAX_VERTICES })}
                        </Typography>
                        <Typography variant="body3" sx={{ color: 'grayColor.main', mb: 2 }}>
                            {t('content.fleet.geofence.vertex_error_instructions')}
                        </Typography>
                    </>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 'auto' }}>
                    <Button
                        id="cancel-btn"
                        data-testid="cancel-btn"
                        color="primary"
                        variant="contained"
                        disabled={props.isMapInEditMode || props.isLoading}
                        onClick={() => props.onCancel()}
                        sx={{ mr: 1 }}
                    >
                        {t('content.fleet.geofence.cancel')}
                    </Button>
                    <Button
                        id="save-btn"
                        data-testid="save-btn"
                        color="secondary"
                        variant="contained"
                        disabled={props.isMapInEditMode || !props.isValidPolygon || props.isLoading}
                        onClick={() => {
                            if (validateName()) {
                                props.onSave(geofenceName, geoEvents, geofenceColor);
                            }
                        }}
                    >
                        {t(`content.fleet.geofence.${isEdit ? 'update' : 'save'}`)}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default GeofenceDetails;
