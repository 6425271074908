import { Box } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { UserRole } from '../../../backendsdk';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import { usersPageDefs } from '../../../utils/Pages';
import { timestampToDate } from '../../../utils/TimeFormatter';
import { DEFAULT_TITLE } from '../../Layout';
import ActionsMenu from './ActionsMenu';
import NewUserModal, { NewUser } from './NewUserModal';
import RemoveModal from './RemoveModal';
import UserDataGrid from './UserDataGrid';

export const ALLOWED_ROLES = [
    UserRole.FleetInsuranceOperator,
    UserRole.FleetSafetyManager,
    UserRole.FleetExecutive,
    UserRole.CarShop,
    UserRole.Technician,
];
export const OE_ROLES = [UserRole.OeAdmin, UserRole.OeInsuranceOperator, UserRole.OeDev];

export interface User {
    id: number;
    name: string;
    email: string;
    subfleet: string;
    phoneNumber: string;
    role: UserRole;
    lastLogin: Date | '';
    activeState: boolean;
}

const UsersComponent: React.FC = () => {
    const [users, setUsers] = useState<Record<number, User>>();
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [newModalOpen, setNewModalOpen] = useState<boolean>(false);
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [alertElement, setAlert] = useAlert();
    const { t } = useTranslation();
    const { api } = useApi();

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${usersPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        api.apiV0SettingsUserManagementGet().then((res) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const allUsers = res.data.map((user: any) => {
                return [
                    user.id,
                    {
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        subfleet: user.sub_fleet,
                        phoneNumber: user.phone_number,
                        role: user.role,
                        lastLogin: user.last_login ? timestampToDate(user.last_login) : '',
                        activeState: user.is_active,
                    },
                ];
            });
            setUsers(Object.fromEntries(allUsers));
        });
    }, []);

    const loadingSpinner = (
        <Box
            sx={{
                width: '100%',
                maxWidth: '1200px',
                height: '100%',
                backgroundColor: 'bgColor.main',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: 0.5,
            }}
        >
            <Box sx={{ transform: 'scale(2)' }}>
                <Spinner animation="border" role="status" />
            </Box>
        </Box>
    );

    if (users === undefined) {
        return loadingSpinner;
    }

    const handleSetActiveState = (newActiveState: boolean) => {
        const prevUserActiveStates = selectionModel.map((userId) => {
            return { id: userId as number, is_active: users[userId as number].activeState };
        });
        const updatedUserActiveStates = prevUserActiveStates.map((user) => {
            return { id: user.id, is_active: newActiveState };
        });
        setUsers((prev: Record<number, User> | undefined) => {
            const updated = { ...prev };
            for (const user of updatedUserActiveStates) {
                updated[user.id].activeState = user.is_active;
            }
            return updated;
        });
        api.apiV0SettingsUserManagementPatch({ userDetails: updatedUserActiveStates }).catch(() => {
            setAlert({
                message: t(`content.users.alerts.error.${newActiveState ? 'activate' : 'deactivate'}`),
                type: 'error',
                duration: 6000,
            });
            setUsers((prev: Record<number, User> | undefined) => {
                const updated = { ...prev };
                for (const user of prevUserActiveStates) {
                    updated[user.id].activeState = user.is_active;
                }
                return updated;
            });
        });
    };

    const handleInvite = () => {
        const invitedUsers = selectionModel.map((userId) => {
            return { id: userId as number, send_invitation: true };
        });
        api.apiV0SettingsUserManagementPatch({ userDetails: invitedUsers })
            .then(() => {
                setAlert({
                    message: t('content.users.alerts.success.invite'),
                    type: 'success',
                    duration: 6000,
                });
            })
            .catch(() => {
                setAlert({
                    message: t('content.users.alerts.error.invite'),
                    type: 'error',
                    duration: 6000,
                });
            });
    };

    const handleAdd = (newUser: NewUser, activate: boolean, invite: boolean) => {
        const newUserToSend = [
            {
                name: newUser.name,
                email: newUser.email,
                phone_number: newUser.phoneNumber,
                sub_fleet: newUser.subfleet || '',
                role: newUser.role,
                is_active: activate,
                send_invitation: invite,
            },
        ];
        api.apiV0SettingsUserManagementPost({ userCreationData: newUserToSend })
            .then((res) => {
                const user = res.data[0];
                setUsers((prev: Record<number, User> | undefined) => {
                    const updated = { ...prev };
                    updated[user.id] = {
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        subfleet: user.sub_fleet,
                        phoneNumber: user.phone_number,
                        role: user.role as UserRole,
                        lastLogin: user.last_login ? new Date(user.last_login) : '',
                        activeState: user.is_active,
                    };
                    return updated;
                });
                setNewModalOpen(false);
            })
            .catch((error) => {
                let alertMessage = 'add';
                if (error.response?.data?.details === 'failed to create user') {
                    alertMessage = 'email_taken';
                } else if (error.response?.data?.details === 'invalid email') {
                    alertMessage = 'email_invalid';
                }
                setAlert({
                    message: t(`content.users.alerts.error.${alertMessage}`),
                    type: 'error',
                    duration: 6000,
                });
            });
    };

    const handleRemove = () => {
        const usersToRemove = selectionModel.map((userId) => users[userId as number]);
        setUsers((prev: Record<number, User> | undefined) => {
            const updated = { ...prev };
            for (const user of usersToRemove) {
                if (user.id in updated) {
                    delete updated[user.id];
                }
            }
            return updated;
        });
        api.apiV0SettingsUserManagementDelete({ userDetails: usersToRemove.map((user) => ({ id: user.id })) }).catch(
            () => {
                setAlert({
                    message: t('content.users.alerts.error.remove'),
                    type: 'error',
                    duration: 6000,
                });
                setUsers((prev: Record<number, User> | undefined) => {
                    const updated = { ...prev };
                    for (const user of usersToRemove) {
                        updated[user.id] = user;
                    }
                    return updated;
                });
            },
        );
        setRemoveModalOpen(false);
    };

    return (
        <>
            {alertElement}
            {newModalOpen ? <NewUserModal onAdd={handleAdd} onClose={() => setNewModalOpen(false)} /> : null}
            {removeModalOpen ? (
                <RemoveModal
                    header={t('content.users.actions.remove.header')}
                    body={t('content.users.actions.remove.confirmation')}
                    onRemove={handleRemove}
                    onClose={() => setRemoveModalOpen(false)}
                />
            ) : null}
            <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '1200px', height: '100%', pb: 2 }}>
                <Box sx={{ width: '100%', height: '100%', minHeight: 0 }}>
                    <UserDataGrid
                        users={users}
                        setUsers={setUsers}
                        selectionModel={selectionModel}
                        setSelectionModel={setSelectionModel}
                        setAlert={setAlert}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        setNewModalOpen={setNewModalOpen}
                    >
                        {' '}
                        <ActionsMenu
                            disabled={selectionModel.length === 0}
                            onSetActiveState={handleSetActiveState}
                            onInvite={handleInvite}
                            onRemove={() => setRemoveModalOpen(true)}
                        />
                    </UserDataGrid>
                </Box>
            </Box>
        </>
    );
};

export default UsersComponent;
