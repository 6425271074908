import { Box, Card, Grid, Tooltip } from '@mui/material';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EventDetails, ExtendedAccident } from '../../../../../backendsdk';
import VideoComponent from '../../../../../components/video';
import useApi from '../../../../../hooks/api';
import videoNA from '../../../../../images/video_not_available.png';
import { isEventVideo } from '../../../../../utils/File';
import { getCustomer } from '../../../../../utils/customer';
import AccidentDriverDetails, { EMPTY_DRIVER_DETAILS } from '../../AccidentDriverDetails';
import AccidentGeneralDetails from '../../AccidentGeneralDetails';

interface DeatilsTabProps {
    details: ExtendedAccident;
    eventDetails?: EventDetails;
    handleChange: CallableFunction;
    handlingStatusList: Array<string>;
    allowUpdate: boolean;
    setUpdateCallbacks: CallableFunction;
    setValidateCallbacks: CallableFunction;
    sending: boolean;
}

const DetailsTab: React.FC<DeatilsTabProps> = (props: DeatilsTabProps) => {
    const { t } = useTranslation();
    const { details } = props;
    const [editHandlingStatus, setEditHandlingStatus] = useState<boolean>(false);
    const { api } = useApi();

    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    const { data: customer } = useReactQuery({ queryKey: ['customer'], queryFn: () => getCustomer(api) });
    const customerUsers = customer?.users || [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (target: string, value: any) => {
        props.handleChange((oldDetails: ExtendedAccident) => {
            if (oldDetails) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const newDetails: Record<string, any> = { ...oldDetails };
                newDetails[target] = value;
                return newDetails;
            }
        });
    };

    const textOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.name, event.target.value);
    };

    const conditionalTooltip = (component: React.ReactNode) => {
        if (details.accident_id === 0) {
            return (
                <Tooltip title={t('content.accidents.form.please_save')} followCursor>
                    <Box>{component}</Box>
                </Tooltip>
            );
        } else {
            return component;
        }
    };

    let videoUrl = '';
    if (props.eventDetails) {
        videoUrl = Object.entries(props.eventDetails.artifact_path)
            .map(([fileName, fileUrl]) => (isEventVideo(fileName) ? fileUrl : ''))
            .filter((e) => e != '')[0];
    }

    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={1} columns={2}>
                <Grid item xs={1}>
                    <Card sx={{ p: 1 }}>
                        <Box>
                            <AccidentGeneralDetails
                                handleChange={handleChange}
                                textOnChange={textOnChange}
                                details={details}
                                editHandlingStatus={editHandlingStatus}
                                setEditHandlingStatus={setEditHandlingStatus}
                                handlingStatusList={props.handlingStatusList}
                                setValidateCallbacks={props.setValidateCallbacks}
                                customerUsers={customerUsers}
                                allowUpdate={props.allowUpdate}
                                sending={props.sending}
                            />
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Card sx={{ p: 1, mb: 1 }}>
                        {props.eventDetails != undefined && details.event == props.eventDetails?.event_id ? (
                            <VideoComponent event={props.eventDetails} video={videoUrl} />
                        ) : (
                            <img src={videoNA} style={{ width: '100%', aspectRatio: '8 / 3', display: 'block' }} />
                        )}
                    </Card>
                    <Card sx={{ p: 1 }}>
                        {conditionalTooltip(
                            <AccidentDriverDetails
                                accident_id={details.accident_id}
                                details={details.driver ? details.driver : EMPTY_DRIVER_DETAILS}
                                setUpdateCallbacks={props.setUpdateCallbacks}
                                setValidateCallbacks={props.setValidateCallbacks}
                                allowUpdate={props.allowUpdate}
                                sending={props.sending}
                            />,
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DetailsTab;
