import { Box, Slide, SlideProps, Snackbar, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { BaseAccidentV2, PolicyV2 } from '../../../backendsdk';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import { accidentsPageDefs } from '../../../utils/Pages';
import { getStorageItem, setStorageItem } from '../../../utils/Storage';
import { b64DecodeUnicode, queryParamsToObject } from '../../../utils/Str';
import { formatDateFromOrdinal, formatDateTime } from '../../../utils/TimeFormatter';
import { DEFAULT_TITLE } from '../../Layout';
import AccidentDetailsComponent from './AccidentDetails';
import AccidentGrid from './AccidentV1/AccidentGrid';
import { AccidentStages } from './AccidentV1/AccidentStage';
import { AccidentToolbar } from './AccidentV1/AccidentToolbar';
import { AccidentFilterField, SortField, SortOrder } from './Defs';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface AccidentComponentProps {
    handlePageVersionChange: CallableFunction;
}

const AccidentComponent: React.FC<AccidentComponentProps> = (props) => {
    const [accidents, setAccidents] = useState<Array<BaseAccidentV2>>([]);
    const [policies, setPolicies] = useState<Array<PolicyV2>>([]);
    const [loadingPolicies, setLoadingPolicies] = useState<boolean>(true);
    const [loadingCounter, setLoadingCounter] = useState<number>(0);
    const [isLoadingAccidents, setIsLoadingAccidents] = useState<boolean>(true);
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [handlingStatusList, setHandlingStatusList] = useState<Array<string>>([]);
    const initialDay = dayjs().date() < 16 ? 1 : 16;
    const [filterField, setFilterField] = useState<AccidentFilterField>(
        (getStorageItem('OE-filter-field') as AccidentFilterField) || 'timestamp',
    );
    const [fromDate, setFromDate] = useState<Dayjs>(
        dayjs().startOf('day').subtract(3, 'month').set('date', initialDay),
    );
    const [toDate, setToDate] = useState<Dayjs>(dayjs().endOf('day'));
    const history = useHistory();
    const query = useQuery();
    const { accidentId } = useParams<{ accidentId: string }>();
    const { t } = useTranslation();
    const { agencyApi } = useApi();
    const { profile } = useProfile();
    const [alertElement, setAlert] = useAlert();
    const [currentStage, setCurrentStage] = useState<string>('all');
    const [searchText, setSearchText] = useState<string>('');
    const [sort, setSort] = useState<[SortField, SortOrder]>(['notification', 'desc']);

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${accidentsPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        getPolicies();
    }, []);

    useEffect(() => {
        agencyApi
            .agencyV1CustomerDataGet()
            .then((res) =>
                setHandlingStatusList(res.data.handling_status.map((status: { name: string }) => status.name)),
            );
    }, []);

    useEffect(() => {
        const queryFilters = query.get('filters');
        if (queryFilters) {
            // load filters from query in v0 fashion (base 64 encoded objects)
            const queryObject = Object.values(JSON.parse(b64DecodeUnicode(queryFilters))).at(0) as string[] | string;
            if (queryObject) {
                if (Array.isArray(queryObject)) {
                    setSearchText(queryObject.at(-1) || '');
                } else {
                    setSearchText(queryObject);
                }
            }
        }
    }, []);

    const resetState = () => {
        setLoadingCounter((prev) => prev + 1);
    };

    const getPolicies = () => {
        agencyApi
            .agencyV2PolicyGet()
            .then((res) => {
                setPolicies(res.data);
            })
            .catch((err) => {
                if (err.response?.status !== 404) {
                    setAlert({ message: t('content.accidents.error_loading'), type: 'error', duration: 6000 });
                }
            })
            .finally(() => setLoadingPolicies(false));
    };

    const loadAccidents = (
        controller: AbortController,
        filterBy: AccidentFilterField,
        from: Dayjs,
        to: Dayjs,
        last_id?: number,
    ) => {
        setIsLoadingAccidents(true);
        let lastId: number | undefined = undefined;
        if (last_id != undefined) {
            lastId = last_id;
        }
        const filterParams =
            filterBy === 'created_at'
                ? { createdFrom: from.unix(), createdTo: to.unix() }
                : { timeFrom: from.unix(), timeTo: to.unix() };
        agencyApi
            .agencyV2AccidentGet(
                {
                    lastId,
                    limit: 1000,
                    ...queryParamsToObject(query),
                    ...filterParams,
                },
                { signal: controller.signal },
            )
            .then((res) => {
                if (res.data.length > 0) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const accidents = res.data.map((a: any) => {
                        a.datetime = formatDateTime(a.datetime).replace(' ', 'T');
                        a.visiting_scene_date = a.visiting_scene_date
                            ? formatDateFromOrdinal(a.visiting_scene_date)
                            : '';
                        return a;
                    });
                    setAccidents((prevAccidents) => {
                        const seen = new Set();
                        const allAccidents = [...accidents, ...prevAccidents];
                        const newAccidents = allAccidents.reduce((obj: Array<BaseAccidentV2>, elem: BaseAccidentV2) => {
                            if (seen.has(elem.accident_id)) {
                                return obj;
                            }
                            seen.add(elem.accident_id);
                            return [...obj, elem];
                        }, []);
                        return newAccidents.sort(
                            (a: BaseAccidentV2, b: BaseAccidentV2) => b.accident_id - a.accident_id,
                        );
                    });
                    loadAccidents(controller, filterBy, from, to, res.data[res.data.length - 1].accident_id);
                } else {
                    setIsLoadingAccidents(false);
                }
            })
            .catch(() => {
                if (!controller?.signal.aborted) {
                    setIsLoadingAccidents(false);
                    setAlert({ message: t('content.accidents.error_loading'), type: 'error', duration: 6000 });
                }
            });
    };

    useEffect(() => {
        setStorageItem('OE-filter-field', filterField);
    }, [filterField]);

    useEffect(() => {
        if (fromDate.isValid() && toDate.isValid() && fromDate.isBefore(toDate)) {
            const controller = new AbortController();
            loadAccidents(controller, filterField, fromDate, toDate);
            return () => controller.abort();
        }
    }, [loadingCounter, filterField, fromDate, toDate]);

    useEffect(() => setLoadingCounter((prev) => prev + 1), []);

    const filterAccidents = (elem: BaseAccidentV2) => {
        const lowerFilterValue = searchText.toLocaleLowerCase();
        return Boolean(
            elem.accident_id.toString().toLocaleLowerCase().includes(lowerFilterValue) ||
                elem.description?.toLocaleLowerCase().includes(lowerFilterValue) ||
                elem.internal_status?.toLocaleLowerCase().includes(lowerFilterValue) ||
                elem.internal_id.toLocaleLowerCase().includes(lowerFilterValue) ||
                elem.license_plate
                    .toLocaleLowerCase()
                    .replaceAll('-', '')
                    .includes(lowerFilterValue.replaceAll('-', '')) ||
                elem.driver_name?.toLocaleLowerCase().includes(lowerFilterValue) ||
                elem.sub_fleet_name?.toLocaleLowerCase().includes(lowerFilterValue) ||
                elem.assignee?.toLocaleLowerCase().includes(lowerFilterValue) ||
                dayjs.unix(elem.created_at).format(profile.dateFormat).includes(lowerFilterValue) ||
                dayjs.unix(elem.timestamp).format(profile.dateFormat).includes(lowerFilterValue),
        );
    };

    const moveAccident = (accidentId: number, stage: string) => {
        setAccidents((prev) => {
            return prev.map((accident) =>
                accident.accident_id == accidentId ? { ...accident, stage: stage } : accident,
            );
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const changeAccidentStage = (accidentId: number, newStage: string) => {
        const accident = accidents.find((accident) => accident.accident_id == accidentId);
        if (accident != undefined) {
            moveAccident(accidentId, newStage);
            return agencyApi
                .agencyV1AccidentAccidentIdPatch({
                    accidentId: accidentId,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    extendedAccident: { ...(accident as any), stage: newStage },
                })
                .then(() => {
                    setAlert({
                        message: t('content.accidents.change_stage_success', {
                            stage: t(`content.accidents.stage.${profile.customer.settings.country}.${newStage}`),
                        }),
                        duration: 6000,
                        type: 'success',
                    });
                })
                .catch((res) => {
                    moveAccident(accidentId, accident.stage);
                    setAlert({
                        message: t(`content.accidents.${res.response.data.message || 'change_stage_error'}`),
                        duration: 6000,
                        type: 'error',
                    });
                });
        }
        return Promise.resolve();
    };

    const accidentsWithinDateRange = accidents.filter((accident) => {
        const datetimeField = filterField == 'timestamp' ? accident.timestamp : accident.created_at;
        return accident[filterField] >= fromDate.unix() && datetimeField <= toDate.unix();
    });
    const filteredAccidents = accidentsWithinDateRange.filter(filterAccidents).sort((a, b) => {
        const [field, order] = sort;
        const aField = a[field];
        const bField = b[field];
        if (aField === null) {
            if (bField === null) {
                return order === 'asc' ? a.accident_id - b.accident_id : b.accident_id - a.accident_id;
            }
            return order === 'asc' ? -1 : 1;
        }
        if (bField === null) {
            return order === 'asc' ? 1 : -1;
        }
        if (aField < bField) {
            return order === 'asc' ? -1 : 1;
        }
        if (aField > bField) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const stageAccidents: Record<string, BaseAccidentV2[]> = filteredAccidents.reduce(
        (acc: Record<string, BaseAccidentV2[]>, accident: BaseAccidentV2) => {
            const stage = accident.stage.toLocaleLowerCase();
            if (acc[stage] == undefined) {
                acc[stage] = [];
            }
            acc[stage].push(accident);
            return acc;
        },
        {},
    );

    const stageAccidentsList = currentStage === 'all' ? filteredAccidents : stageAccidents[currentStage] || [];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
            {alertElement}
            <LoadingSnackbar isLoading={isLoadingAccidents} />
            <AccidentToolbar
                handlePageVersionChange={props.handlePageVersionChange}
                searchText={searchText}
                setSearchText={setSearchText}
                filterField={filterField}
                setFilterField={setFilterField}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                resetState={resetState}
                sort={sort}
                setSort={setSort}
                setAlert={setAlert}
            />
            {isLoadingModal ? (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'var(--bg-color)',
                        opacity: 0.5,
                        zIndex: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ transform: 'scale(2)' }}>
                        <CircularProgress />
                    </div>
                </div>
            ) : null}
            <Box sx={{ display: 'flex', height: '100%', width: '100%', minHeight: 0 }}>
                <AccidentStages
                    stageAccidents={stageAccidents}
                    currentStage={currentStage}
                    onStageClick={(stage: string) => setCurrentStage(stage)}
                />
                <AccidentGrid
                    accidents={stageAccidentsList}
                    accidentId={parseInt(accidentId)}
                    policies={policies}
                    setAccidents={setAccidents}
                />
                {accidentId ? (
                    <AccidentDetailsComponent
                        pageVersion="v1"
                        accidentId={accidentId}
                        refreshTable={setAccidents}
                        changeAccidentStage={changeAccidentStage}
                        setIsLoadingModal={setIsLoadingModal}
                        handlingStatusList={handlingStatusList}
                        setHandlingStatusList={setHandlingStatusList}
                        onClose={() => history.push('/accidents')}
                        policies={policies}
                        loadingPolicies={loadingPolicies}
                    />
                ) : null}
            </Box>
        </Box>
    );
};

export default AccidentComponent;

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

const LoadingSnackbar: React.FC<{ isLoading: boolean }> = React.memo(function LoadingSnackbar(props: {
    isLoading: boolean;
}) {
    const { t } = useTranslation();

    return (
        <Snackbar
            open={props.isLoading}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            TransitionComponent={SlideTransition}
            message={<Typography>{t('content.accidents.loading')}</Typography>}
            sx={{ '& .MuiPaper-root': { backgroundColor: 'primary.main', minWidth: 0 }, zIndex: 1200 }}
        />
    );
});
