import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CoachEvent } from '../../../../backendsdk';
import VideoComponent from '../../../../components/video';
import useProfile from '../../../../hooks/profile';
import videoNA from '../../../../images/video_not_available.png';
import { EVENT_TYPE_MAP } from '../../Event/Defs';

export interface CoachingEventSlideProps {
    event: CoachEvent;
}

const CoachingEventSlide: React.FC<CoachingEventSlideProps> = ({ event }: CoachingEventSlideProps) => {
    const [error, setError] = useState<boolean>(false);
    const { t } = useTranslation();
    const { profile } = useProfile();

    const video = event.event_details.artifact_path['video'];
    const blurredVideo = event.event_details.artifact_path['blurred_video'];
    const isBlurred = event.is_blurred;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                overflow: 'auto',
                px: 4,
                py: 2,
            }}
        >
            <Box sx={{ height: '30px', display: 'flex', alignItems: 'end' }}>
                <Typography data-testid="event-title" fontSize={18}>
                    {`${t(EVENT_TYPE_MAP[event.event_details.type])} ${t('content.coach.presentation.on', {
                        time: dayjs.unix(event.event_details.timestamp).format(profile.timeFormat),
                    })}`}
                </Typography>
            </Box>
            {error ? (
                <img style={{ width: '100%', height: 'auto' }} src={videoNA} />
            ) : (
                <VideoComponent
                    event={event.event_details}
                    video={isBlurred ? blurredVideo : video}
                    onError={() => setError(true)}
                />
            )}
            {event.comment.split('\n').map((line, index) => (
                <Typography key={index} data-testid="event-comment">
                    {line}
                </Typography>
            ))}
        </Box>
    );
};

export default CoachingEventSlide;
