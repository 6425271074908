import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Card, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash.isequal';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceV3, FleetDriver } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { normalizeLicensePlate, normalizeName } from '../../../utils/Str';

const normalizeDriverName = (firstName: string, lastName: string) => {
    return `${normalizeName(firstName)} ${normalizeName(lastName)}`.trim();
};

const VEHICLE_COLUMNS = ['license_plate', 'sub_fleet', 'default_driver'] as const;

const VEHICLE_COLUMN_MAP: Record<string, typeof VEHICLE_COLUMNS[number]> = {
    VIN: 'license_plate',
    'Sub Fleet': 'sub_fleet',
    'Default Driver': 'default_driver',
    'לוחית רישוי': 'license_plate',
    מחלקה: 'sub_fleet',
    'נהג ברירת מחדל': 'default_driver',
};

interface VehicleRowData {
    license_plate: string;
    sub_fleet: string;
    default_driver: number;
}

const checkVehicleChanged = (device: DeviceV3, vehicleInput: VehicleRowData) => {
    return (
        vehicleInput.sub_fleet !== device.device.customer_sub_fleet ||
        vehicleInput.default_driver !== device.device.default_driver
    );
};

const parseValue = (key: string, value: string, drivers: FleetDriver[], lineNumber: number) => {
    if (key === 'default_driver') {
        if (!!value) {
            const driver = drivers.find((d) => normalizeDriverName(d.first_name, d.last_name) === normalizeName(value));
            if (!driver) {
                throw new Error('unknown_driver', { cause: { line: lineNumber } });
            }
            return [key, driver?.token];
        }
        return [key, null];
    }
    return [key, value.trim()];
};

const readFile = (file: File) => {
    return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result as string), false);
        reader.readAsText(file);
    });
};

const readFileContent = (text: string, devices: DeviceV3[], drivers: FleetDriver[]) => {
    const lines = text.split(/\r?\n/);
    let headers: string[] = [];
    const vehicleRequests: VehicleRowData[] = [];
    const existingLicensePlates: Record<string, number> = {};
    for (const [lineNumber, line] of Array.from(lines.entries())) {
        if (headers.length == 0) {
            headers = line.split(',').map((header: string) => VEHICLE_COLUMN_MAP[header]);
            if (!isEqual(headers, VEHICLE_COLUMNS)) {
                throw new Error('invalid_structure');
            }
        } else if (!!line) {
            const parts = line.split(',');
            const rowData = Object.fromEntries(
                headers.map((key: string, idx: number) => parseValue(key, parts[idx], drivers, lineNumber)),
            );
            const licensePlate = normalizeLicensePlate(rowData.license_plate);
            if (!devices.some((d) => normalizeLicensePlate(d.device.license_plate) === licensePlate)) {
                throw new Error('unknown_vehicle', { cause: { line: lineNumber } });
            }
            if (licensePlate in existingLicensePlates) {
                throw new Error('duplicate_vehicles', {
                    cause: { line1: lineNumber, line2: existingLicensePlates[licensePlate] },
                });
            }
            vehicleRequests.push(rowData);
            existingLicensePlates[licensePlate] = lineNumber;
        }
    }
    return vehicleRequests;
};

interface UploadVehiclesModalProps {
    onClose: CallableFunction;
    devices: DeviceV3[];
    drivers: FleetDriver[];
    onDownloadList: CallableFunction;
    isLoading: boolean;
    setIsLoading: CallableFunction;
    setAlert: CallableFunction;
}

interface UploadPreview {
    unchanged: number;
    changed: number;
}

const UploadVehiclesModal: React.FC<UploadVehiclesModalProps> = (props: UploadVehiclesModalProps) => {
    const [dragAndDropHover, setDragAndDropHover] = useState<boolean>(false);
    const [file, setFile] = useState<File>();
    const [preview, setPreview] = useState<UploadPreview>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [vehicles, setVehicles] = useState<VehicleRowData[]>([]);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { api } = useApi();
    const queryClient = useQueryClient();
    const deviceMap = useMemo(
        () =>
            props.devices.reduce((acc, device) => {
                acc[normalizeLicensePlate(device.device.license_plate)] = device;
                return acc;
            }, {} as Record<string, DeviceV3>),
        [props.devices],
    );

    const vehicleMap = useMemo(
        () =>
            vehicles.reduce((acc, vehicle) => {
                acc[normalizeLicensePlate(vehicle.license_plate)] = vehicle;
                return acc;
            }, {} as Record<string, VehicleRowData>),
        [vehicles],
    );

    const onDownloadTemplate = () => {
        const BOM = '\uFEFF';
        const csvContent = [
            `${t('content.fleet_vehicles.license_plate')},`,
            `${t('content.fleet_vehicles.sub_fleet')},`,
            `${t('content.fleet_vehicles.default_driver')}\n`,
            `${profile.customer.settings.country === 'il' ? '11-222-33' : '123'},`,
            `${t('content.fleet_vehicles.upload_csv.central')},`,
            `${t('content.fleet_vehicles.upload_csv.john_doe')}`,
        ].join('');
        const file = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = 'template.csv';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const handleUpload = () => {
        props.setIsLoading(true);
        api.apiV0DeviceUpdatePatch({
            deviceRequest: vehicles.map((d) => {
                return {
                    device_id: deviceMap[normalizeLicensePlate(d.license_plate)].device.device_id,
                    customer_sub_fleet: d.sub_fleet,
                    default_driver: d.default_driver,
                };
            }),
        })
            .then(() => {
                props.setAlert({ message: t('content.drivers.upload_csv.success'), type: 'success', duration: 6000 });
                queryClient.setQueryData(['devices'], (old: DeviceV3[] | undefined) => {
                    if (old) {
                        return old.map((device) => {
                            const vehicle = vehicleMap[normalizeLicensePlate(device.device.license_plate)];
                            if (vehicle) {
                                return {
                                    ...device,
                                    device: {
                                        ...device.device,
                                        customer_sub_fleet: vehicle.sub_fleet,
                                        default_driver: vehicle.default_driver,
                                    },
                                };
                            }
                            return device;
                        });
                    }
                    return old;
                });
                props.onClose();
            })
            .catch(() =>
                props.setAlert({ message: t('content.drivers.upload_csv.error'), type: 'error', duration: 6000 }),
            )
            .finally(() => props.setIsLoading(false));
    };

    useEffect(() => {
        const readInputFile = async (file: File, devices: DeviceV3[], drivers: FleetDriver[]) => {
            const fileContent = await readFile(file);
            return readFileContent(fileContent, devices, drivers);
        };

        const processFile = async () => {
            if (!!file) {
                try {
                    const vehiclesInput = await readInputFile(file, props.devices, props.drivers);
                    const vehiclesInputMap = vehiclesInput.reduce((acc, vehicle) => {
                        const licensePlate = normalizeLicensePlate(vehicle.license_plate);
                        acc[licensePlate] = vehicle;
                        return acc;
                    }, {} as Record<string, VehicleRowData>);
                    setVehicles(vehiclesInput);
                    let changedVehicles = 0;
                    let unchangedVehicles = 0;
                    for (const device of props.devices) {
                        const vehicleInput = vehiclesInputMap[normalizeLicensePlate(device.device.license_plate)];
                        if (vehicleInput) {
                            if (checkVehicleChanged(device, vehicleInput)) {
                                changedVehicles++;
                            } else {
                                unchangedVehicles++;
                            }
                        }
                    }
                    setPreview({ unchanged: unchangedVehicles, changed: changedVehicles });
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (err: any) {
                    if (err.message === 'invalid_structure') {
                        setErrorMessage(t('content.fleet_vehicles.upload_csv.invalid_structure'));
                    } else if (err.message === 'duplicate_vehicles') {
                        setErrorMessage(
                            t('content.fleet_vehicles.upload_csv.duplicate_vehicles', {
                                line1: err.cause.line1 + 1,
                                line2: err.cause.line2 + 1,
                            }),
                        );
                    } else if (err.message === 'unknown_vehicle') {
                        setErrorMessage(
                            t('content.fleet_vehicles.upload_csv.unknown_vehicle', { line: err.cause.line + 1 }),
                        );
                    } else if (err.message === 'unknown_driver') {
                        setErrorMessage(
                            t('content.fleet_vehicles.upload_csv.unknown_driver', { line: err.cause.line + 1 }),
                        );
                    }
                    setPreview(undefined);
                }
            } else {
                setPreview(undefined);
                setErrorMessage('');
                setVehicles([]);
            }
        };

        processFile();
    }, [file]);

    return (
        <Dialog id="upload-vehicles-dialog" open={true} maxWidth="xs" fullWidth onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                    minHeight: 0,
                    flexShrink: 0,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FileUploadIcon sx={{ mr: 0.5 }} />
                    <Typography>{t('content.fleet_vehicles.upload_csv.header')}</Typography>
                </Box>
            </Box>
            <Box sx={{ width: '100%', p: 1 }}>
                <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 1 }}>
                    <Box sx={{ mb: 1 }}>
                        <Typography sx={{ mb: 1 }}>
                            <Typography component="span">
                                {t('content.fleet_vehicles.upload_csv.instructions_1')}
                            </Typography>
                            <Typography component="span">
                                {t('content.fleet_vehicles.upload_csv.instructions_2')}
                            </Typography>
                            <Typography component="span">
                                <Link
                                    id="download-list-link"
                                    component="span"
                                    onClick={() => props.onDownloadList()}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {t('content.fleet_vehicles.upload_csv.instructions_3')}
                                </Link>
                            </Typography>
                            <Typography component="span">
                                {t('content.fleet_vehicles.upload_csv.instructions_4')}
                            </Typography>
                            <Typography component="span">
                                <Link
                                    id="download-template-link"
                                    onClick={() => onDownloadTemplate()}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {t('content.fleet_vehicles.upload_csv.instructions_5')}
                                </Link>
                            </Typography>
                            <Typography component="span">.</Typography>
                        </Typography>
                        <Typography sx={{ mb: 1 }}>
                            <Typography component="span" fontSize={14} sx={{ fontWeight: 'bold' }}>
                                {t('content.fleet_vehicles.upload_csv.note_header')}
                            </Typography>
                            <Typography component="span" fontSize={14}>
                                {': '}
                            </Typography>
                            <Typography component="span" fontSize={14}>
                                {t('content.fleet_vehicles.upload_csv.note_body')}
                            </Typography>
                        </Typography>
                    </Box>
                    <Box
                        onDragOver={(e: React.DragEvent) => {
                            // Prevent default behavior (Prevent file from being opened)
                            e.preventDefault();
                            setDragAndDropHover(true);
                        }}
                        onDragEnter={() => {
                            setDragAndDropHover(true);
                        }}
                        onDrop={(e: React.DragEvent) => {
                            // Prevent default behavior (Prevent file from being opened)
                            e.preventDefault();
                            if (e.dataTransfer.files.length > 0) {
                                setFile(e.dataTransfer.files[0]);
                            }
                            setDragAndDropHover(false);
                        }}
                        onDragLeave={() => {
                            setDragAndDropHover(false);
                        }}
                        onDragEnd={() => {
                            setDragAndDropHover(false);
                        }}
                        onDragExit={() => {
                            setDragAndDropHover(false);
                        }}
                    >
                        <Box
                            sx={{
                                p: 1,
                                height: '6rem',
                                border:
                                    file === undefined ? '2px dashed var(--gray-color)' : '1px solid var(--gray-color)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {file === undefined ? (
                                <>
                                    <FileUploadIcon
                                        color={dragAndDropHover ? 'secondary' : 'primary'}
                                        fontSize="large"
                                        sx={{ mb: 0.5, transition: 'color 0.2s' }}
                                    />
                                    <Box sx={{ display: 'flex' }}>
                                        <Link
                                            id="link-upload-file"
                                            data-testid="link-upload-file"
                                            variant="overline"
                                            component="label"
                                            sx={{ mr: 0.5, cursor: 'pointer', lineHeight: 1 }}
                                        >
                                            {t('content.fleet_vehicles.upload_csv.upload_file')}
                                            <input
                                                id="file-field"
                                                data-testid="file-field"
                                                hidden
                                                type="file"
                                                accept=".csv"
                                                onChange={(e) => {
                                                    if (e.target.files && e.target.files.length > 0) {
                                                        setFile(e.target.files[0]);
                                                    }
                                                }}
                                            />
                                        </Link>
                                        <Typography color="primary" variant="overline" sx={{ lineHeight: 1 }}>
                                            {t('content.fleet_vehicles.upload_csv.or_drop_here')}
                                        </Typography>
                                    </Box>
                                </>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: 14, mb: 0.5 }}>{file.name}</Typography>
                                    <Link
                                        id="clear-file-link"
                                        component="span"
                                        variant="overline"
                                        onClick={() => setFile(undefined)}
                                        sx={{ lineHeight: 1, cursor: 'pointer' }}
                                    >
                                        {t('content.fleet_vehicles.upload_csv.clear')}
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {errorMessage ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                            <Typography variant="body2" color="error">
                                {errorMessage}
                            </Typography>
                        </Box>
                    ) : null}
                    {preview !== undefined ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                            <Typography variant="overline" sx={{ lineHeight: 1, mb: 0.5 }}>
                                {t('content.fleet_vehicles.upload_csv.file_content')}
                            </Typography>
                            <Typography data-testid="unchanged-vehicles" data-value={preview.unchanged}>
                                {t('content.fleet_vehicles.upload_csv.unchanged', { count: preview.unchanged })}
                            </Typography>
                            <Typography data-testid="changed-vehicles" data-value={preview.changed}>
                                {t('content.fleet_vehicles.upload_csv.changed', { count: preview.changed })}
                            </Typography>
                        </Box>
                    ) : null}
                </Card>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Button
                        id="cancel-upload"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 1 }}
                        disabled={props.isLoading}
                        onClick={() => {
                            props.onClose();
                        }}
                    >
                        {t('content.drivers.cancel')}
                    </Button>
                    <Button
                        id="upload-vehicles"
                        data-testid="upload-vehicles"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 1, ml: 1 }}
                        disabled={
                            preview === undefined || preview.changed === 0 || vehicles.length === 0 || props.isLoading
                        }
                        onClick={() => handleUpload()}
                    >
                        {t('content.drivers.upload')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default UploadVehiclesModal;
