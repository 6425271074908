import DoneIcon from '@mui/icons-material/Done';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../../../../components/TrackedComponents';
import { Modes, cleanup } from '../SetupTool';
import FixedContainer from '../ui/FixedContainer';

interface CompletedProps {
    mode: Modes;
    malfunctionId: number;
    onDone: () => void;
}

const Completed: React.FC<CompletedProps> = (props) => {
    const { t } = useTranslation();

    useEffect(() => cleanup(`${props.malfunctionId}-${props.mode}`), []);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography id={`${props.mode}-completed-header`} variant="h6" textAlign="center" sx={{ mb: 2 }}>
                    {t(`setup_tool.completed.${props.mode}_header`)}
                </Typography>
                <FixedContainer>
                    <DoneIcon sx={{ fontSize: 42 }} />
                    <Typography sx={{ fontWeight: 500 }}>{t(`setup_tool.completed.${props.mode}`)}</Typography>
                </FixedContainer>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 1,
                    minHeight: 0,
                    flexShrink: 0,
                }}
            >
                <Box sx={{ width: '50%' }}>
                    <Button
                        id="btn-done"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.onDone();
                        }}
                    >
                        {t('setup_tool.completed.done')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Completed;
