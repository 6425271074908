import { DRIVERS } from '../DriverLogs/driversData';
import { MalfunctionData } from './MalfunctionsComponent';

export const MALFUNCTIONS_DATA: MalfunctionData[] = [
    {
        device: '11-222-33',
        currentDriver: DRIVERS[0],
        malfunctions: ['P', 'E'],
        diagnosticEvents: [],
        since: 1676811000,
    },
    {
        device: '22-333-44',
        currentDriver: DRIVERS[1],
        malfunctions: ['P', 'E', 'T', 'S'],
        diagnosticEvents: [1, 2, 4],
        since: 1676789012,
    },
    { device: '33-444-55', currentDriver: DRIVERS[3], malfunctions: ['R'], diagnosticEvents: [5], since: 1676812541 },
];
