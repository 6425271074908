import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, CircularProgress, List, ListItem, Tooltip, TooltipProps, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import {
    CoachAgendaItem,
    CoachEvent,
    CoachMediaFile,
    CoachMetrics,
    CoachSession,
    ShortCoachSession,
} from '../../../../backendsdk';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { TrackedButton as Button, TrackedIconButton as IconButton } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import { useImages } from '../../../../hooks/images';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import palette from '../../../ColorPalette';
import AgendaItemModal, { getItemIcon } from './AgendaItemModal';
import CoachingEvent from './CoachingEvent';
import CoachingWellnessStatus, { getWellnessType } from './WellnessStatus';

const REQUEST_LIMIT = 5;

export const WELLNESS_EVENT_TYPES = ['MsgInCabinCameraCovered'];

interface SaveFirstTooltipProps {
    placement?: TooltipProps['placement'];
    children: ReactElement;
}

export const SaveFirstTooltip: React.FC<SaveFirstTooltipProps> = ({ children, placement }: SaveFirstTooltipProps) => {
    const { t } = useTranslation();
    return (
        <Tooltip followCursor placement={placement || 'bottom'} title={t('content.coach.details.save_first')}>
            {children}
        </Tooltip>
    );
};

interface CoachingAgendaProps {
    details: CoachSession;
    setDetails: CallableFunction;
    setSessions: CallableFunction;
    setAlert: CallableFunction;
    isLoading: boolean;
}

const CoachingAgenda: React.FC<CoachingAgendaProps> = (props: CoachingAgendaProps) => {
    const [agendaQueue, setAgendaQueue] = useState<CoachAgendaItem[][]>([]);
    const [isAddItemOpen, setIsAddItemOpen] = useState<boolean>(false);
    const [selectedAgendaItem, setSelectedAgendaItem] = useState<CoachAgendaItem>();
    const { api, agencyApi } = useApi();
    const isCompleted = !!props.details.completed_at;
    const accidentEventIds = props.details.events.filter((event) => event.is_accident).map((event) => event.event);
    const getImage = (imageKey: string) => {
        const [type, id] = imageKey.split(':', 2);
        if (type === 'event') {
            const eventId = parseInt(id);
            if (accidentEventIds.includes(eventId)) {
                return agencyApi.agencyV0EventEventIdImageGet({ eventId }, { responseType: 'blob' });
            } else {
                return api.apiV0CoachEventEventIdImageGet({ eventId }, { responseType: 'blob' });
            }
        } else {
            return api.apiV2WellnessImageWellnessTypeGet(
                {
                    wellnessType: type,
                    wellnessId: id,
                },
                { responseType: 'blob' },
            );
        }
    };
    const [eventImages, addToQueue] = useImages(REQUEST_LIMIT, getImage);
    const [itemToDelete, setItemToDelete] = useState<CoachAgendaItem>();
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const isMobile = useIsMobile();

    const isSaved = !props.details.auto_generated || props.details.is_saved;
    const isEditable = !isCompleted && isSaved;

    const eventByItemId: Record<number, CoachEvent> = Object.fromEntries(
        props.details.events.map((event) => [event.id, event]),
    );

    const onCloseItemModal = () => {
        setSelectedAgendaItem(undefined);
        setIsAddItemOpen(false);
    };

    const onUpdate = () => {
        props.setSessions((prev: ShortCoachSession[]) =>
            prev.map((session) => {
                if (session.session_id === props.details.session_id) {
                    return {
                        ...session,
                        last_updated: dayjs().unix(),
                    };
                } else {
                    return session;
                }
            }),
        );
    };

    const handleDelete = (item: CoachAgendaItem) => {
        if (item !== undefined) {
            const itemId = item.id;
            const afterDeleteItem = () => {
                onUpdate();
                setItemToDelete(undefined);
                setIsLoadingDelete(false);
                onCloseItemModal();
            };
            const catchCallback = () => {
                props.setAlert({
                    message: t('content.coach.errors.delete_item'),
                    type: 'error',
                    duration: 6000,
                });
                setIsLoadingDelete(false);
            };
            if (item.type === 'notes') {
                setIsLoadingDelete(true);
                api.apiV0CoachSessionIdNotesNoteIdDelete({
                    sessionId: props.details.session_id,
                    noteId: item.id,
                })
                    .then(() => {
                        props.setDetails((prev: CoachSession) => ({
                            ...prev,
                            notes: prev.notes.filter((note) => note.id !== itemId),
                            agenda: prev.agenda.filter(
                                (agendaItem) => agendaItem.type !== item.type || agendaItem.id !== itemId,
                            ),
                        }));
                        afterDeleteItem();
                    })
                    .catch(catchCallback);
            } else if (item.type === 'response') {
                setIsLoadingDelete(true);
                api.apiV0CoachSessionIdResponseResponseIdDelete({
                    sessionId: props.details.session_id,
                    responseId: item.id,
                })
                    .then(() => {
                        props.setDetails((prev: CoachSession) => ({
                            ...prev,
                            response: prev.response.filter((response) => response.id !== itemId),
                            agenda: prev.agenda.filter(
                                (agendaItem) => agendaItem.type !== item.type || agendaItem.id !== itemId,
                            ),
                        }));
                        afterDeleteItem();
                    })
                    .catch(catchCallback);
            } else if (item.type === 'metrics') {
                setIsLoadingDelete(true);
                api.apiV0CoachSessionIdMetricsMetricsIdDelete({
                    sessionId: props.details.session_id,
                    metricsId: item.id,
                })
                    .then(() => {
                        props.setDetails((prev: CoachSession) => ({
                            ...prev,
                            metrics: prev.metrics.filter((metrics) => metrics.id !== itemId),
                            agenda: prev.agenda.filter(
                                (agendaItem) => agendaItem.type !== item.type || agendaItem.id !== itemId,
                            ),
                        }));
                        afterDeleteItem();
                    })
                    .catch(catchCallback);
            } else if (item.type === 'media_file') {
                setIsLoadingDelete(true);
                api.apiV0CoachSessionIdMediaFileCoachMediaFileIdDelete({
                    sessionId: props.details.session_id,
                    coachMediaFileId: item.id,
                })
                    .then(() => {
                        props.setDetails((prev: CoachSession) => ({
                            ...prev,
                            media_files: prev.media_files.filter((file) => file.id !== itemId),
                            agenda: prev.agenda.filter(
                                (agendaItem) => agendaItem.type !== item.type || agendaItem.id !== itemId,
                            ),
                        }));
                        afterDeleteItem();
                    })
                    .catch(catchCallback);
            } else if (item.type === 'wellness_status') {
                setIsLoadingDelete(true);
                api.apiV0CoachSessionIdWellnessStatusWellnessIdDelete({
                    sessionId: props.details.session_id,
                    wellnessId: item.id,
                })
                    .then(() => {
                        props.setSessions((prev: ShortCoachSession[]) =>
                            prev.map((session) => {
                                if (session.session_id === props.details.session_id) {
                                    return {
                                        ...session,
                                        event_count: session.event_count - 1,
                                        last_updated: dayjs().unix(),
                                    };
                                } else {
                                    return session;
                                }
                            }),
                        );
                        props.setDetails((prev: CoachSession) => ({
                            ...prev,
                            event_count: prev.event_count - 1,
                            wellness_statuses: prev.wellness_statuses.filter((status) => status.id !== itemId),
                            agenda: prev.agenda.filter(
                                (agendaItem) => agendaItem.type !== item.type || agendaItem.id !== itemId,
                            ),
                        }));
                        afterDeleteItem();
                    })
                    .catch(catchCallback);
            } else if (item.type === 'event') {
                const eventId = eventByItemId[itemId].event;
                setIsLoadingDelete(true);
                api.apiV0CoachSessionIdEventItemIdDelete({ sessionId: props.details.session_id, itemId })
                    .then(() => {
                        props.setSessions((prev: ShortCoachSession[]) =>
                            prev.map((session) => {
                                if (session.session_id === props.details.session_id) {
                                    return {
                                        ...session,
                                        event_count: session.event_count - 1,
                                        last_updated: dayjs().unix(),
                                    };
                                } else {
                                    return session;
                                }
                            }),
                        );
                        props.setDetails((prev: CoachSession) => {
                            return {
                                ...prev,
                                event_count: prev.event_count - 1,
                                events: prev.events.filter((event) => event.event !== eventId),
                                agenda: prev.agenda.filter(
                                    (agendaItem) => agendaItem.type !== item.type || agendaItem.id !== itemId,
                                ),
                            };
                        });
                        afterDeleteItem();
                    })
                    .catch(catchCallback);
            }
        }
    };

    useEffect(() => {
        addToQueue(props.details.events.map((event) => `event:${event.event}`));
        addToQueue(
            props.details.wellness_statuses.map((wellness) => {
                const wellnessType = getWellnessType(wellness);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const relevantWellness = (wellness.front_facing_wellness_status || wellness.in_cabin_wellness_status)!;
                return `${wellnessType}:${relevantWellness.id}`;
            }),
        );
    }, []);

    useEffect(() => {
        if (agendaQueue.length > 0) {
            const updatedAgenda = agendaQueue[agendaQueue.length - 1];
            api.apiV1CoachSessionIdPatch({
                sessionId: props.details.session_id,
                coachSession: { ...props.details, agenda: updatedAgenda },
            }).then(() => {
                setAgendaQueue((prev) => prev.slice(0, -1));
                props.setSessions((prev: ShortCoachSession[]) =>
                    prev.map((session) =>
                        session.session_id === props.details.session_id
                            ? { ...session, last_updated: dayjs().unix() }
                            : session,
                    ),
                );
            });
        }
    }, [agendaQueue]);

    const reorder = (list: CoachAgendaItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const updatedAgenda = reorder(props.details.agenda, result.source.index, result.destination.index);
        setAgendaQueue((prev) => [...prev, updatedAgenda]);

        props.setDetails((prev: CoachSession) => ({ ...prev, agenda: updatedAgenda }));
    };

    let selectedItem = undefined;
    if (selectedAgendaItem?.type === 'notes') {
        selectedItem = props.details.notes.find((note) => note.id === selectedAgendaItem?.id);
    } else if (selectedAgendaItem?.type === 'response') {
        selectedItem = props.details.response.find((response) => response.id === selectedAgendaItem?.id);
    } else if (selectedAgendaItem?.type === 'metrics') {
        selectedItem = props.details.metrics.find((metric) => metric.id === selectedAgendaItem?.id);
    } else if (selectedAgendaItem?.type === 'event') {
        selectedItem = props.details.events.find((event) => event.id === selectedAgendaItem?.id);
    } else if (selectedAgendaItem?.type === 'media_file') {
        selectedItem = props.details.media_files.find((mediaFile) => mediaFile.id === selectedAgendaItem?.id);
    } else if (selectedAgendaItem?.type === 'wellness_status') {
        selectedItem = props.details.wellness_statuses.find(
            (wellnessStatus) => wellnessStatus.id === selectedAgendaItem?.id,
        );
    }

    const addItemButton = (
        <Button
            id="add-item-btn"
            data-testid="add-item-btn"
            variant="contained"
            color="primary"
            disabled={!isEditable || props.isLoading}
            startIcon={<AddIcon />}
            onClick={() => setIsAddItemOpen(true)}
        >
            {t('content.coach.details.add_item')}
        </Button>
    );

    const loadingSpinner = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: palette.bgColor,
                opacity: 0.5,
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
            }}
        >
            <CircularProgress />
        </Box>
    );

    const isSuggested = props.details.auto_generated && !props.details.is_saved;
    const confirmText = [t('content.coach.details.confirm_remove')];
    if (isSuggested) {
        confirmText.push(t('content.coach.details.remove_suggested'));
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: isMobile ? undefined : '100%' }}>
            <ConfirmDialog
                isDialogOpen={!!itemToDelete}
                isLoading={isLoadingDelete}
                headerText={t('content.coach.details.remove_item')}
                bodyText={confirmText}
                onClose={() => {
                    if (!isLoadingDelete) {
                        setItemToDelete(undefined);
                    }
                }}
                onConfirm={() => {
                    if (itemToDelete !== undefined) {
                        handleDelete(itemToDelete);
                    }
                }}
                buttonText={t('content.coach.details.remove')}
                buttonColor="redColor"
                maxWidth="xs"
                typographyStyles={[{ mb: 1 }, { fontWeight: 500 }]}
            />
            {isAddItemOpen || selectedItem !== undefined ? (
                <AgendaItemModal
                    sessionDetails={props.details}
                    agendaItem={selectedAgendaItem}
                    item={selectedItem}
                    onClose={onCloseItemModal}
                    setDetails={props.setDetails}
                    setAlert={props.setAlert}
                    setSessions={props.setSessions}
                    eventByItemId={eventByItemId}
                    images={eventImages}
                    onUpdate={onUpdate}
                    setItemToDelete={setItemToDelete}
                />
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: `1px solid ${palette.gray[400]}`,
                    px: 1.5,
                    pb: 1.5,
                    width: '100%',
                    alignItems: 'start',
                }}
            >
                <Box sx={{ display: 'flex', mb: isCompleted ? -1 : -0.5 }}>
                    <Typography variant="overline" sx={{ mr: 0.5, lineHeight: '32px' }}>
                        {t('content.coach.details.agenda')}
                    </Typography>
                    <Typography sx={{ fontSize: 12, lineHeight: '32px' }}>{`(${props.details.agenda.length} ${t(
                        `content.coach.details.item${props.details.agenda.length > 1 ? 's' : ''}`,
                    )})`}</Typography>
                </Box>
                {!isCompleted ? (
                    isSaved ? (
                        addItemButton
                    ) : (
                        <SaveFirstTooltip>
                            <Box>{addItemButton}</Box>
                        </SaveFirstTooltip>
                    )
                ) : null}
            </Box>
            <Box sx={{ minHeight: 0, position: 'relative' }}>
                {props.isLoading ? loadingSpinner : null}
                <Box
                    sx={{
                        height: isMobile ? undefined : '100%',
                        minHeight: 0,
                        overflowY: isMobile ? undefined : 'auto',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {props.details.agenda.length > 0 ? (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="list">
                                    {(provided) => (
                                        <List disablePadding ref={provided.innerRef} {...provided.droppableProps}>
                                            {props.details.agenda.map((item, idx) => {
                                                if (item.type === 'event') {
                                                    const event = eventByItemId[item.id];
                                                    if (event !== undefined) {
                                                        return (
                                                            <Draggable
                                                                key={`${item.type}-${item.id}`}
                                                                draggableId={`${item.type}-${item.id}`}
                                                                index={idx}
                                                                isDragDisabled={!isEditable}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        key={`${item.type}-${item.id}`}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <ListItem
                                                                            id={`event-${item.id}`}
                                                                            data-testid="agenda-item"
                                                                            key={event.event}
                                                                            disableGutters
                                                                            disablePadding
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                position: 'relative',
                                                                                pl: isEditable ? 1 : 2,
                                                                                pr: isCompleted ? 1 : 0,
                                                                                outline: `1px solid ${palette.gray[400]}`,
                                                                                backgroundColor: 'bgColor.main',
                                                                                opacity: snapshot.isDragging ? 0.75 : 1,
                                                                            }}
                                                                        >
                                                                            {isEditable ? (
                                                                                <div
                                                                                    id={`drag-event-${item.id}`}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <DragIndicatorIcon
                                                                                        sx={{ mr: 0.5 }}
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                            <Box
                                                                                className="agenda-event"
                                                                                data-testid={`event-${item.id}`}
                                                                                onClick={() =>
                                                                                    setSelectedAgendaItem(item)
                                                                                }
                                                                                sx={{
                                                                                    cursor: 'pointer',
                                                                                    width: '100%',
                                                                                    py: 1,
                                                                                }}
                                                                            >
                                                                                <CoachingEvent
                                                                                    agendaItemId={item.id}
                                                                                    event={event}
                                                                                    eventImages={eventImages}
                                                                                    isAccident={accidentEventIds.includes(
                                                                                        event.event,
                                                                                    )}
                                                                                    onDelete={
                                                                                        isCompleted
                                                                                            ? undefined
                                                                                            : () =>
                                                                                                  setItemToDelete(item)
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        </ListItem>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                } else if (item.type === 'wellness_status') {
                                                    const status = props.details.wellness_statuses.find(
                                                        (s) => s.id === item.id,
                                                    );
                                                    if (status !== undefined) {
                                                        return (
                                                            <Draggable
                                                                key={`${item.type}-${item.id}`}
                                                                draggableId={`${item.type}-${item.id}`}
                                                                index={idx}
                                                                isDragDisabled={!isEditable}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        key={`${item.type}-${item.id}`}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <ListItem
                                                                            id={`wellness-status-${item.id}`}
                                                                            data-testid="agenda-item"
                                                                            key={`wellness-status-${item.id}`}
                                                                            disableGutters
                                                                            disablePadding
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                position: 'relative',
                                                                                pl: isEditable ? 1 : 2,
                                                                                pr: isCompleted ? 1 : 0,
                                                                                outline: `1px solid ${palette.gray[400]}`,
                                                                                backgroundColor: 'bgColor.main',
                                                                                opacity: snapshot.isDragging ? 0.75 : 1,
                                                                            }}
                                                                        >
                                                                            {isEditable ? (
                                                                                <div
                                                                                    id={`drag-event-${item.id}`}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <DragIndicatorIcon
                                                                                        sx={{ mr: 0.5 }}
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                            <Box
                                                                                className="agenda-wellness-status"
                                                                                data-testid={`wellness-status-${item.id}`}
                                                                                onClick={() =>
                                                                                    setSelectedAgendaItem(item)
                                                                                }
                                                                                sx={{
                                                                                    cursor: 'pointer',
                                                                                    width: '100%',
                                                                                    py: 1,
                                                                                }}
                                                                            >
                                                                                <CoachingWellnessStatus
                                                                                    agendaItemId={item.id}
                                                                                    status={status}
                                                                                    images={eventImages}
                                                                                    onDelete={
                                                                                        isCompleted
                                                                                            ? undefined
                                                                                            : () =>
                                                                                                  setItemToDelete(item)
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        </ListItem>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                } else if (
                                                    item.type === 'notes' ||
                                                    item.type === 'response' ||
                                                    item.type === 'metrics' ||
                                                    item.type === 'media_file'
                                                ) {
                                                    let relevantItem;
                                                    if (item.type === 'response') {
                                                        relevantItem = props.details.response.find(
                                                            (r) => r.id === item.id,
                                                        );
                                                    } else if (item.type === 'notes') {
                                                        relevantItem = props.details.notes.find(
                                                            (n) => n.id === item.id,
                                                        );
                                                    } else if (item.type === 'metrics') {
                                                        relevantItem = props.details.metrics.find(
                                                            (m) => m.id === item.id,
                                                        );
                                                    } else {
                                                        relevantItem = props.details.media_files.find(
                                                            (mf) => mf.id === item.id,
                                                        );
                                                    }
                                                    if (relevantItem !== undefined) {
                                                        let itemTitle = t(`content.coach.${item.type}`);
                                                        if (item.type === 'metrics') {
                                                            relevantItem = relevantItem as CoachMetrics;
                                                            const timestamps = relevantItem.metric_data
                                                                .map((point) => point.timestamp)
                                                                .sort((a, b) => a - b);
                                                            const scoreType = t(
                                                                `content.coach.add_item.metrics.${relevantItem.score_type}_score`,
                                                            );
                                                            const firstDate = dayjs
                                                                .unix(timestamps[0])
                                                                .format(profile.dateFormat);
                                                            const lastDate = dayjs
                                                                .unix(timestamps[timestamps.length - 1])
                                                                .format(profile.dateFormat);
                                                            const dates =
                                                                firstDate === lastDate
                                                                    ? `(${firstDate})`
                                                                    : `(${firstDate}–${lastDate})`;
                                                            itemTitle = `${scoreType} ${dates}`;
                                                        } else if (item.type === 'media_file') {
                                                            relevantItem = relevantItem as CoachMediaFile;
                                                            const fileType = relevantItem.file.file_type.split('/')[0];
                                                            itemTitle = t(`content.coach.${fileType}`);
                                                            if (relevantItem.title) {
                                                                itemTitle = `${itemTitle} (${relevantItem.title})`;
                                                            }
                                                        }
                                                        return (
                                                            <Draggable
                                                                key={`${item.type}-${item.id}`}
                                                                draggableId={`${item.type}-${item.id}`}
                                                                index={idx}
                                                                isDragDisabled={!isEditable}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        key={`${item.type}-${item.id}`}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        style={{ minWidth: 0 }}
                                                                    >
                                                                        <ListItem
                                                                            id={`${item.type}-${item.id}`}
                                                                            data-testid="agenda-item"
                                                                            key={`${item.type}-${item.id}`}
                                                                            disableGutters
                                                                            disablePadding
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                width: '100%',
                                                                                height: '50px',
                                                                                pl: isEditable ? 1 : 2,
                                                                                pr: isCompleted ? 1 : 0,
                                                                                outline: `1px solid ${palette.gray[400]}`,
                                                                                backgroundColor: 'bgColor.main',
                                                                                opacity: snapshot.isDragging ? 0.75 : 1,
                                                                            }}
                                                                        >
                                                                            {isEditable ? (
                                                                                <div
                                                                                    id={`drag-${item.type}-${item.id}`}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <DragIndicatorIcon
                                                                                        sx={{ mr: 0.5, mt: 0.25 }}
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                            <Box
                                                                                id={`${item.type}-${item.id}-content`}
                                                                                data-testid={`${item.type}-${item.id}-content`}
                                                                                className={`agenda-event-${item.type}`}
                                                                                onClick={() =>
                                                                                    setSelectedAgendaItem(item)
                                                                                }
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'space-between',
                                                                                    alignItems: 'center',
                                                                                    width: '100%',
                                                                                    minWidth: 0,
                                                                                    height: '100%',
                                                                                    cursor: 'pointer',
                                                                                    pr: 0,
                                                                                }}
                                                                            >
                                                                                <Box sx={{ width: '400px' }}>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontWeight: 'bold',
                                                                                            fontSize: 16,
                                                                                            color: 'primary.dark',
                                                                                            textOverflow: 'ellipsis',
                                                                                            overflow: 'hidden',
                                                                                            whiteSpace: 'nowrap',
                                                                                        }}
                                                                                    >
                                                                                        {itemTitle}
                                                                                    </Typography>
                                                                                </Box>
                                                                                {getItemIcon(item.type, true)}
                                                                                {isCompleted ? null : (
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            setItemToDelete(item);
                                                                                        }}
                                                                                        id={`delete-item-${item.id}`}
                                                                                    >
                                                                                        <CloseIcon />
                                                                                    </IconButton>
                                                                                )}
                                                                            </Box>
                                                                        </ListItem>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                }
                                            })}
                                            {provided.placeholder}
                                        </List>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        ) : null}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CoachingAgenda;
