import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Typography } from '@mui/material';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import { useImages } from '../../../hooks/images';
import imageNA from '../../../images/image_not_available.png';
import { RTLDirectionContext } from '../../Layout';
import VehicleCard from './VehicleCard';
import { CARD_HEIGHT, CARD_MARGIN } from './VehicleCard';
import { VehicleCardProps } from './VehicleCard';
import VehicleModal from './VehicleModal';

export const NAV_BUTTON_WIDTH = 50;

export interface VehicleGridProps {
    vehicles: Array<VehicleCardProps>;
    cardRows: number;
    cardColumns: number;
    ref?: React.ForwardedRef<HTMLDivElement>;
}

const VehicleGrid: React.FC<VehicleGridProps> = forwardRef<HTMLDivElement, VehicleGridProps>((props, ref) => {
    const { t } = useTranslation();
    const { licensePlate } = useParams<{ licensePlate: string }>();
    const numberOfCards = props.cardRows * props.cardColumns;
    let initialIndex = 0;
    const vehicleIndex = props.vehicles.findIndex((v) => v.licensePlate === licensePlate);
    if (vehicleIndex >= 0) {
        initialIndex = Math.floor(vehicleIndex / numberOfCards) * numberOfCards;
    }
    const [startingIndex, setStartingIndex] = useState<number>(initialIndex);
    const [selectedLicensePlate, setSelectedLicensePlate] = useState<string>();
    const cardsOnScreen = props.cardColumns * props.cardRows;
    const { api, agencyApi } = useApi();
    const getImage = (eventStr: string) => {
        const [eventIdStr, type] = eventStr.split(':');
        const eventId = parseInt(eventIdStr);
        if (type === 'accident') {
            return agencyApi.agencyV0EventEventIdImageGet({ eventId }, { responseType: 'blob' });
        }
        return api.apiV0EventEventIdImageGet({ eventId }, { responseType: 'blob' });
    };
    const [eventImages, addToQueue] = useImages(cardsOnScreen, getImage);
    const [defaultTab, setDefaultTab] = useState<number>(0);
    const isRTL = useContext(RTLDirectionContext);
    const history = useHistory();
    const isSingleColumn = props.cardColumns === 1;

    useEffect(() => {
        const eventKeys = props.vehicles
            .slice(startingIndex, startingIndex + 2 * cardsOnScreen)
            .filter((v) => !!v.mostRecentEvent)
            .map((v) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const eventId = v.mostRecentEvent!.event_id;
                if (v.accidentEventIds.includes(eventId)) {
                    return `${eventId}:accident`;
                }
                return `${eventId}:event`;
            });
        addToQueue(eventKeys);
    }, [props.vehicles, props.cardRows, props.cardColumns, startingIndex]);

    useEffect(() => {
        if (licensePlate) {
            const vehicleExists = props.vehicles.some((v) => v.licensePlate === licensePlate);
            if (vehicleExists) {
                setSelectedLicensePlate(licensePlate);
            } else {
                history.replace('/vehicles');
            }
        } else {
            setSelectedLicensePlate(undefined);
        }
    }, [licensePlate]);

    useEffect(() => {
        setStartingIndex(0);
    }, [props.vehicles.length]);

    useEffect(() => {
        window.addEventListener('keydown', keyScroll);
        return () => window.removeEventListener('keydown', keyScroll);
    });

    const keyMap: Record<string, 'previous' | 'next'> = {
        ArrowLeft: isRTL ? 'next' : 'previous',
        ArrowRight: isRTL ? 'previous' : 'next',
    };

    const keyScroll = (e: KeyboardEvent) => {
        if (e.key in keyMap && selectedLicensePlate === undefined) {
            e.preventDefault();
            if (!e.repeat) {
                turnPage(keyMap[e.key]);
            }
        }
    };

    const turnPage = (direction: 'previous' | 'next') => {
        if (direction === 'previous' && startingIndex > 0) {
            setStartingIndex((prev) => Math.max(0, prev - numberOfCards));
        } else if (direction === 'next' && startingIndex + cards.length < props.vehicles.length) {
            setStartingIndex((prev) => prev + numberOfCards);
        }
    };

    const cards = props.vehicles.slice(startingIndex, startingIndex + numberOfCards).map((card, idx) => {
        return (
            <Box
                id={`${card.licensePlate}-card`}
                key={idx}
                aria-label="vehicle-card"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <VehicleCard
                    timestamp={card.timestamp}
                    eventIds={card.eventIds}
                    accidentEventIds={card.accidentEventIds}
                    driverName={card.driverName}
                    licensePlate={card.licensePlate}
                    subFleet={card.subFleet}
                    score={card.score}
                    behaviors={card.behaviors}
                    mostRecentEvent={card.mostRecentEvent}
                    image={
                        !!card.mostRecentEvent
                            ? eventImages[
                                  `${card.mostRecentEvent.event_id}:${
                                      card.accidentEventIds.includes(card.mostRecentEvent.event_id)
                                          ? 'accident'
                                          : 'event'
                                  }`
                              ]
                            : imageNA
                    }
                    setDefaultTab={setDefaultTab}
                    inactive={card.inactive}
                    unknown={card.unknown}
                />
            </Box>
        );
    });

    const previousBtn = (
        <Box sx={isSingleColumn ? { mr: 1 } : { gridColumn: '1', gridRow: `1 / ${props.cardRows + 1}` }}>
            <IconButton
                id="previous-btn"
                size="large"
                onClick={() => turnPage('previous')}
                disabled={startingIndex === 0}
            >
                {isRTL ? (
                    <NavigateNextIcon sx={{ transform: 'scale(2)' }} />
                ) : (
                    <NavigateBeforeIcon sx={{ transform: 'scale(2)' }} />
                )}
            </IconButton>
        </Box>
    );

    const nextBtn = (
        <Box
            sx={
                isSingleColumn
                    ? undefined
                    : { gridColumn: `${props.cardColumns + 2}`, gridRow: `1 / ${props.cardRows + 1}` }
            }
        >
            <IconButton
                id="next-btn"
                size="large"
                onClick={() => turnPage('next')}
                disabled={startingIndex + cards.length === props.vehicles.length}
            >
                {isRTL ? (
                    <NavigateBeforeIcon sx={{ transform: 'scale(2)' }} />
                ) : (
                    <NavigateNextIcon sx={{ transform: 'scale(2)' }} />
                )}
            </IconButton>
        </Box>
    );

    const selectedVehicle = props.vehicles.find((v) => v.licensePlate === selectedLicensePlate);

    return (
        <>
            {selectedVehicle ? (
                <VehicleModal
                    vehicle={selectedVehicle}
                    onClose={() => history.push('/vehicles')}
                    initialTab={defaultTab}
                />
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    minHeight: `${CARD_HEIGHT + 2 * CARD_MARGIN + 45}px`,
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: isSingleColumn
                            ? '1fr'
                            : `${NAV_BUTTON_WIDTH}px repeat(${props.cardColumns}, 1fr) ${NAV_BUTTON_WIDTH}px`,
                        gridTemplateRows: `repeat(${props.cardRows}, 1fr)`,
                        height: 'calc(100% - 45px)',
                        alignItems: 'center',
                        alignContent: 'center',
                        position: 'relative',
                    }}
                    ref={ref}
                >
                    {isSingleColumn ? (
                        <>{cards}</>
                    ) : (
                        <>
                            {previousBtn}
                            {cards}
                            {nextBtn}
                        </>
                    )}
                </Box>
                {props.vehicles.length > 0 ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: isSingleColumn ? 1 : 0 }}>
                        {isSingleColumn ? (
                            <Box sx={{ width: '100%', mb: 0.5, display: 'flex', justifyContent: 'center' }}>
                                {previousBtn}
                                {nextBtn}
                            </Box>
                        ) : null}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Typography variant="overline" sx={{ lineHeight: 1 }}>
                                {t('content.safety.vehicles_shown', {
                                    start: startingIndex + 1,
                                    end: startingIndex + cards.length,
                                    total: props.vehicles.length,
                                })}
                            </Typography>
                        </Box>
                    </Box>
                ) : null}
            </Box>
        </>
    );
});

VehicleGrid.displayName = 'VehicleGrid';
export default VehicleGrid;
