import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import PortraitIcon from '@mui/icons-material/Portrait';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Box, ButtonGroup, Card, CircularProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Cell, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent';

import {
    AggregatedMalfunctions,
    AggregatedMalfunctionsHistory,
    DeviceV3,
    Malfunction,
    MalfunctionTypeEnum,
    UserRole,
} from '../../../backendsdk';
import { TrackedButton as Button, TrackedLink as Link } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import { UnknownVehicleIcon } from '../Accident/Defs';

dayjs.extend(utc);

const CURRENT_COLORS = [palette.good, palette.bad];
const GRAPH_TICKS = 5;

export const MALFUNCTION_ICONS: Record<MalfunctionTypeEnum, React.ReactNode> = {
    [MalfunctionTypeEnum.FrontFacing]: <InsertPhotoOutlinedIcon color="primary" />,
    [MalfunctionTypeEnum.InCabinV1]: <PortraitIcon color="primary" />,
    [MalfunctionTypeEnum.MultipleButtonPresses]: <TouchAppIcon color="primary" />,
    [MalfunctionTypeEnum.PendingInstallation]: <AddIcon color="primary" />,
    [MalfunctionTypeEnum.PendingRemoval]: <CloseIcon color="primary" />,
    [MalfunctionTypeEnum.WrongInstallation]: <ErrorOutlineIcon color="primary" />,
    [MalfunctionTypeEnum.SystemConstantlyOn]: <PowerSettingsNewIcon color="primary" />,
    [MalfunctionTypeEnum.WrongLicensePlate]: <UnknownVehicleIcon sx={{ color: 'primary' }} />,
    [MalfunctionTypeEnum.InactiveDevice]: <VisibilityOffOutlinedIcon sx={{ color: 'primary' }} />,
};

const prepareChartData = (dataPoints: AggregatedMalfunctions[]) => {
    const dataWithoutDuplicates = dataPoints.reduce((acc, m) => {
        if (m.malfunctions) {
            acc[m.timestamp] = Object.values(m.malfunctions).reduce((sum, n) => sum + n, 0);
        } else {
            acc[m.timestamp] = null;
        }
        return acc;
    }, {} as { [timestamp: number]: number | null });
    return Object.entries(dataWithoutDuplicates).map(([timestamp, value]) => ({
        timestamp,
        value,
    }));
};

interface CustomTooltipProps extends LegendProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any[];
}

export const CustomTooltip: React.FC<CustomTooltipProps> = (props: CustomTooltipProps) => {
    const { payload } = props;
    const { t } = useTranslation();
    const { profile } = useProfile();

    if (payload && payload.length) {
        const data = payload[0].payload;
        if (!!data) {
            return (
                <Card
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 1, direction: 'ltr' }}
                >
                    <Typography>{dayjs.unix(data.timestamp).format(profile.shortDateFormat)}</Typography>
                    <Typography sx={{ color: palette.bad, fontWeight: 500 }}>{`${
                        data.value > 1
                            ? t('content.malfunctions.malfunction_plural', { num: data.value })
                            : t('content.malfunctions.malfunction_singular')
                    }`}</Typography>
                </Card>
            );
        }
    }
    return null;
};

interface MalfunctionsOverviewProps {
    malfunctions: Malfunction[];
    aggregatedMalfunctions?: AggregatedMalfunctionsHistory;
    devices: DeviceV3[];
    isLoading: boolean;
    isLoadingAggregated: boolean;
    isError: boolean;
    isErrorAggregated: boolean;
    onRetry: () => void;
    setFilterModel: CallableFunction;
}

const TIMESPAN_OPTIONS = ['week', 'month', 'quarter'] as const;
type Timespan = typeof TIMESPAN_OPTIONS[number];

const MalfunctionsOverview: React.FC<MalfunctionsOverviewProps> = (props) => {
    const [timespan, setTimespan] = useState<Timespan>('week');
    const { t } = useTranslation();
    const { profile } = useProfile();

    let content;
    if (props.isLoading || props.isError) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                {props.isLoading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Typography variant="overline">{t('content.malfunctions.error_loading_overview')}</Typography>
                        <Link
                            id="try-again-link"
                            component="button"
                            variant="overline"
                            onClick={() => {
                                props.onRetry();
                            }}
                        >
                            {t('content.malfunctions.try_again')}
                        </Link>
                    </>
                )}
            </Box>
        );
    } else {
        const missingDevices = props.malfunctions.filter(
            (m) => m.type === MalfunctionTypeEnum.PendingInstallation,
        ).length;
        const totalDevices = props.devices.length + missingDevices;
        const malfunctionsByLicensePlate = props.malfunctions.reduce((acc, m) => {
            const licensePlate = m.license_plate;
            if (acc[licensePlate] === undefined) {
                acc[licensePlate] = [m];
            } else {
                acc[licensePlate].push(m);
            }
            return acc;
        }, {} as { [licensePlate: string]: Malfunction[] });
        const malfunctionsByType = props.malfunctions.reduce((acc, m) => {
            const malfunctionType = m.type;
            if (acc[malfunctionType] === undefined) {
                acc[malfunctionType] = [m];
            } else {
                acc[malfunctionType].push(m);
            }
            return acc;
        }, {} as { [p: string]: Malfunction[] });
        const malfunctioningDevices = Object.keys(malfunctionsByLicensePlate).length;
        const healthyDevices = totalDevices - malfunctioningDevices;
        const currentData = [
            { name: 'healthy', value: healthyDevices },
            { name: 'malfunctioning', value: malfunctioningDevices },
        ];

        let malfunctionsHistory;
        if (profile.role === UserRole.Technician) {
            malfunctionsHistory = null;
        } else if (props.isLoadingAggregated || props.isErrorAggregated) {
            malfunctionsHistory = (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: props.isLoadingAggregated ? 'center' : 'flex-start',
                        width: '100%',
                        mt: 2,
                    }}
                >
                    {props.isLoadingAggregated ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Typography variant="overline" lineHeight={1}>
                                {t('content.malfunctions.error_loading_history')}
                            </Typography>
                            <Link
                                id="try-again-aggregated-link"
                                component="button"
                                variant="overline"
                                lineHeight={1}
                                onClick={() => {
                                    props.onRetry();
                                }}
                                sx={{ mt: 1.5 }}
                            >
                                {t('content.malfunctions.try_again')}
                            </Link>
                        </>
                    )}
                </Box>
            );
        } else if (props.aggregatedMalfunctions !== undefined) {
            const chartData = Object.fromEntries(
                TIMESPAN_OPTIONS.map((timeframe) => {
                    const aggregatedData =
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        props.aggregatedMalfunctions![timeframe as keyof AggregatedMalfunctionsHistory];
                    return [timeframe, prepareChartData(aggregatedData)];
                }),
            );
            const maxValue = Math.max(...Object.values(chartData).flatMap((data) => data.map((d) => d.value ?? 0)));

            const selectedChartData = chartData[timespan];
            malfunctionsHistory = (
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography variant="overline">{t('content.malfunctions.overview.history')}</Typography>
                    <ButtonGroup disableElevation sx={{ mb: 1 }}>
                        {TIMESPAN_OPTIONS.map((ts) => (
                            <Button
                                key={`${ts}-btn`}
                                id={`${ts}-btn`}
                                data-testid={`${ts}-btn`}
                                size="small"
                                variant={timespan === ts ? 'contained' : 'outlined'}
                                onClick={() => setTimespan(ts)}
                                data-active={timespan === ts}
                            >
                                {t(`content.malfunctions.overview.timespan.${ts}`)}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <LineChart
                        width={325}
                        height={200}
                        id="malfunctions-trend-graph"
                        data={selectedChartData}
                        margin={{
                            top: 10,
                            right: 10,
                            left: -25,
                            bottom: 0,
                        }}
                    >
                        <Tooltip content={<CustomTooltip />} />
                        <CartesianGrid />
                        <XAxis
                            type="number"
                            tick={{ fontSize: 12 }}
                            dataKey="timestamp"
                            ticks={selectedChartData.map((d) => d.timestamp)}
                            tickFormatter={(t) => dayjs.unix(t).format(profile.shortDateFormat)}
                            domain={['dataMin', 'dataMax']}
                            interval={0}
                        />
                        <YAxis
                            tick={{ fontSize: 12 }}
                            domain={[0, 'dataMax']}
                            ticks={[...Array(GRAPH_TICKS)].map(
                                (_, idx) => (idx + 1) * Math.ceil(maxValue / GRAPH_TICKS),
                            )}
                        />
                        <Line
                            connectNulls
                            isAnimationActive={false}
                            type="monotone"
                            dataKey="value"
                            stroke={palette.bad}
                            strokeWidth={2}
                        />
                    </LineChart>
                </Box>
            );
        }

        const percentage = Math.round((malfunctioningDevices / totalDevices) * 100);

        const mulfunctionsTypeCount =
            Object.keys(malfunctionsByType).length > 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'flex-start',
                        mb: 1,
                    }}
                >
                    <Typography fontSize={12} variant="overline">
                        {t('content.malfunctions.overview.current_malfunctions')}
                    </Typography>
                    {Object.entries(malfunctionsByType)
                        .sort((a, b) => b[1].length - a[1].length)
                        .map(([type, malfunctions]) => {
                            const malfunctionStr = t(
                                `content.malfunctions.overview.type.${type.toLocaleLowerCase()}_${
                                    malfunctions.length > 1 ? 'plural' : 'singular'
                                }`,
                            );
                            return (
                                <Box key={type} sx={{ display: 'flex', alignItems: 'flex-start', mb: 0.5 }}>
                                    {MALFUNCTION_ICONS[type as MalfunctionTypeEnum]}
                                    <Link
                                        id={`${type.toLocaleLowerCase().replaceAll('_', '-')}-filter-link`}
                                        data-testid={`${type.toLocaleLowerCase().replaceAll('_', '-')}-filter-link`}
                                        component="button"
                                        onClick={() => props.setFilterModel(type)}
                                    >
                                        <Typography
                                            data-testid={`malfunction-${type.toLocaleLowerCase().replaceAll('_', '-')}`}
                                            data-value={malfunctions.length}
                                            fontSize={14}
                                            sx={{ pl: 0.5, pt: 0.35 }}
                                        >
                                            {`${malfunctions.length} ${malfunctionStr}`}
                                        </Typography>
                                    </Link>
                                </Box>
                            );
                        })}
                </Box>
            ) : null;
        const fleetMalfunctionChart =
            profile.role === UserRole.Technician ? null : (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                    <PieChart width={300} height={250}>
                        <Pie
                            data={currentData}
                            dataKey="value"
                            nameKey="name"
                            isAnimationActive={false}
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            innerRadius={80}
                            fill={palette.neutral[50]}
                        >
                            {currentData.map((_entry, index) => (
                                <Cell key={`cell-${index}`} fill={CURRENT_COLORS[index]} />
                            ))}
                        </Pie>
                    </PieChart>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                    >
                        <Typography
                            data-testid="percentage-label"
                            data-value={percentage}
                            fontSize={46}
                            lineHeight={1}
                        >{`${percentage}`}</Typography>
                        <Typography fontSize={12} textAlign="center" sx={{ width: '42%' }}>
                            {t('content.malfunctions.overview.percentage')}
                        </Typography>
                    </Box>
                </Box>
            );
        content = (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography fontSize={20}>{t('content.malfunctions.overview.header')}</Typography>
                {fleetMalfunctionChart}
                {mulfunctionsTypeCount}
                {malfunctionsHistory}
            </Box>
        );
    }

    return (
        <Box
            sx={{
                width: '350px',
                height: '100%',
                boxShadow:
                    '2px 0px 4px -1px rgba(0,0,0,0.2),4px 0px 5px 0px rgba(0,0,0,0.14),1px 0px 10px 0px rgba(0,0,0,0.12)',
                backgroundColor: palette.bgColor,
                p: 1,
                flexShrink: 0,
                minWidth: 0,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
            }}
        >
            {content}
        </Box>
    );
};

export default MalfunctionsOverview;
