import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button, TrackedDialog as Dialog } from '../../../components/TrackedComponents';

export interface RemoveModalProps {
    header: string;
    body: string;
    onRemove: CallableFunction;
    onClose: CallableFunction;
}

const RemoveModal: React.FC<RemoveModalProps> = (props: RemoveModalProps) => {
    const { t } = useTranslation();

    return (
        <Dialog id="remove-dialog" open={true} fullWidth={true} maxWidth="xs" onClose={() => props.onClose()}>
            <Box sx={{ p: 2 }}>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">{t(props.header, props.header)}</Typography>
                    <Typography variant="body2">{t(props.body, props.body)}</Typography>
                </Box>
                <Grid container columns={2} columnSpacing={1}>
                    <Grid item xs={1}>
                        <Button
                            id="confirm-remove-btn"
                            data-testid="confirm-remove-btn"
                            size="small"
                            variant="contained"
                            color="redColor"
                            fullWidth
                            onClick={() => props.onRemove()}
                        >
                            {t('content.users.actions.remove.button')}
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            id="cancel-remove-btn"
                            size="small"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => props.onClose()}
                        >
                            {t('content.users.actions.remove.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default RemoveModal;
