import { Box, ButtonGroup, CircularProgress, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    CoachingOverviewSessionsData,
    CoachingOverview as CoachingOverviewType,
    ExtendedCustomerDataAllOfUsers,
    ShortCoachSession,
} from '../../../backendsdk';
import { TrackedButton as Button, TrackedLink as Link } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import palette from '../../ColorPalette';
import { INVALID_SCORE } from '../Metrics/SafetyWellness/MetricsComponent';
import CoachingEffectivenessModal from './CoachingEffectivenessModal';
import CoachingOverviewPieChart from './CoachingOverviewPieChart';
import { getUserAvatar } from './SessionDetails';

interface CoachingOverviewProps {
    isLoading: boolean;
    isError: boolean;
    onTryAgain: CallableFunction;
    toDoSessions: ShortCoachSession[];
    autoSessions: ShortCoachSession[];
    sentSessions: ShortCoachSession[];
    users: ExtendedCustomerDataAllOfUsers[];
    tabsMap: Record<string, number>;
    setTab: CallableFunction;
}

const CoachingOverview: React.FC<CoachingOverviewProps> = (props: CoachingOverviewProps) => {
    const [overviewData, setOverviewData] = useState<CoachingOverviewType>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [timespan, setTimespan] = useState<keyof CoachingOverviewSessionsData>('one_month');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { api } = useApi();
    const { t } = useTranslation();

    const twoWeeksChangeData = { improved: 0, worsened: 0, unchanged: 0 };
    const oneMonthChageData = { improved: 0, worsened: 0, unchanged: 0 };
    const threeMonthsChangeData = { improved: 0, worsened: 0, unchanged: 0 };

    if (overviewData !== undefined) {
        for (const dataSet of overviewData.effectiveness_data) {
            if (dataSet.on_completion !== undefined && dataSet.on_completion.score !== INVALID_SCORE) {
                if (dataSet.two_weeks !== undefined && dataSet.two_weeks.score !== INVALID_SCORE) {
                    if (dataSet.two_weeks.score > dataSet.on_completion.score) {
                        twoWeeksChangeData['improved'] += 1;
                    } else if (dataSet.two_weeks.score < dataSet.on_completion.score) {
                        twoWeeksChangeData['worsened'] += 1;
                    } else {
                        twoWeeksChangeData['unchanged'] += 1;
                    }
                }
                if (dataSet.one_month !== undefined && dataSet.one_month.score !== INVALID_SCORE) {
                    if (dataSet.one_month.score > dataSet.on_completion.score) {
                        oneMonthChageData['improved'] += 1;
                    } else if (dataSet.one_month.score < dataSet.on_completion.score) {
                        oneMonthChageData['worsened'] += 1;
                    } else {
                        oneMonthChageData['unchanged'] += 1;
                    }
                }
                if (dataSet.three_months !== undefined && dataSet.three_months.score !== INVALID_SCORE) {
                    if (dataSet.three_months.score > dataSet.on_completion.score) {
                        threeMonthsChangeData['improved'] += 1;
                    } else if (dataSet.three_months.score < dataSet.on_completion.score) {
                        threeMonthsChangeData['worsened'] += 1;
                    } else {
                        threeMonthsChangeData['unchanged'] += 1;
                    }
                }
            }
        }
    }

    const twoWeeksGraphData = Object.entries(twoWeeksChangeData).map(([key, value]) => {
        return { name: key, value };
    });
    const oneMonthGraphData = Object.entries(oneMonthChageData).map(([key, value]) => {
        return { name: key, value };
    });
    const threeMonthsGraphData = Object.entries(threeMonthsChangeData).map(([key, value]) => {
        return { name: key, value };
    });
    const showTwoWeeks = twoWeeksGraphData.some((category) => category.value !== 0);
    const showOneMonth = oneMonthGraphData.some((category) => category.value !== 0);
    const showThreeMonths = threeMonthsGraphData.some((category) => category.value !== 0);

    const loadOverviewData = () => {
        setIsLoading(true);
        setIsError(false);
        api.apiV0CoachingOverviewGet()
            .then((res) => {
                setOverviewData(res.data);
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
    };

    useEffect(loadOverviewData, []);

    let content;
    if (isLoading || props.isLoading || isError || props.isError) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                {isLoading || props.isLoading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Typography variant="overline">{t('content.coach.errors.overview')}</Typography>
                        <Link
                            id="try-again-link"
                            component="button"
                            variant="overline"
                            onClick={() => {
                                if (isError) {
                                    loadOverviewData();
                                } else {
                                    props.onTryAgain();
                                }
                            }}
                        >
                            {t('content.coach.errors.try_again')}
                        </Link>
                    </>
                )}
            </Box>
        );
    } else if (overviewData !== undefined) {
        content = (
            <>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                        {t('content.coach.overview.header')}
                    </Typography>
                    <Typography variant="overline" fontSize={14} fontWeight={300}>
                        {t('content.coach.overview.currently')}
                    </Typography>
                    <Typography>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                            {props.toDoSessions.length}
                        </Typography>{' '}
                        {t('content.coach.overview.open_sessions')}
                    </Typography>
                    <Typography>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                            {props.sentSessions.length}
                        </Typography>{' '}
                        {t('content.coach.overview.pending_sessions')}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1.5 }} />
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <ButtonGroup disableElevation sx={{ mb: 1 }}>
                        {['one_month', 'three_months'].map((ts) => (
                            <Button
                                key={`${ts}-btn`}
                                id={`${ts}-btn`}
                                data-testid={`${ts}-btn`}
                                size="small"
                                variant={timespan === ts ? 'contained' : 'outlined'}
                                onClick={() => setTimespan(ts as keyof CoachingOverviewSessionsData)}
                            >
                                {t(`content.coach.overview.${ts}`)}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <Typography variant="overline" sx={{ color: palette.neutral[400] }}>
                        {t('content.coach.overview.created_sessions')}
                    </Typography>
                    <Typography>
                        <Typography
                            data-testid={`${overviewData.sessions_data[timespan].created_manual}-created-manual`}
                            component="span"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {overviewData.sessions_data[timespan].created_manual}
                        </Typography>{' '}
                        {t('content.coach.overview.manual_sessions')}
                    </Typography>
                    <Typography sx={{ mb: 0.5 }}>
                        <Typography
                            data-testid={`${overviewData.sessions_data[timespan].created_auto}-created-auto`}
                            component="span"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {overviewData.sessions_data[timespan].created_auto}
                        </Typography>{' '}
                        {t('content.coach.overview.auto_sessions')}
                    </Typography>
                    <Typography variant="overline" sx={{ color: palette.neutral[400] }}>
                        {t('content.coach.overview.completed_sessions')}
                    </Typography>
                    <Typography>
                        <Typography
                            data-testid={`${overviewData.sessions_data[timespan].completed_office}-completed-office`}
                            component="span"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {overviewData.sessions_data[timespan].completed_office}
                        </Typography>{' '}
                        {t('content.coach.overview.office_sessions')}
                    </Typography>
                    <Typography sx={{ mb: 0.5 }}>
                        <Typography
                            data-testid={`${overviewData.sessions_data[timespan].completed_remote}-completed-remote`}
                            component="span"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {overviewData.sessions_data[timespan].completed_remote}
                        </Typography>{' '}
                        {t('content.coach.overview.remote_sessions')}
                    </Typography>
                    {overviewData.sessions_data[timespan].top_assignees.length > 0 ? (
                        <>
                            <Typography variant="overline" sx={{ color: palette.neutral[400] }}>
                                {t('content.coach.overview.top_managers')}
                            </Typography>
                            {overviewData.sessions_data[timespan].top_assignees.map((manager, idx) => {
                                const user = props.users.find((u) => u.user_id === manager.user_id);
                                if (user !== undefined) {
                                    return (
                                        <Box
                                            key={manager.user_id}
                                            id={`manager-${manager.user_id}`}
                                            data-testid={`manager-${manager.user_id}`}
                                            sx={{ display: 'flex', alignItems: 'center', mt: idx === 0 ? 0 : 1 }}
                                        >
                                            {getUserAvatar(user, 45)}
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography>{`${user.name}`}</Typography>
                                                <Typography fontSize={14} sx={{ color: palette.neutral[400] }}>
                                                    {t('content.coach.overview.sessions', { count: manager.count })}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    );
                                }
                                return null;
                            })}
                        </>
                    ) : null}
                </Box>
                {showTwoWeeks || showOneMonth || showThreeMonths ? (
                    <>
                        <Divider sx={{ my: 1.5 }} />
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography variant="overline" fontSize={14} fontWeight={300} sx={{ mb: -1 }}>
                                {t('content.coach.overview.effectiveness.header')}
                            </Typography>
                            <Link
                                id="see-details-link"
                                data-testid="see-details-link"
                                component="button"
                                onClick={() => setIsModalOpen(true)}
                                sx={{ mb: 1 }}
                            >
                                <Typography fontSize={14}>{t('content.coach.overview.see_details')}</Typography>
                            </Link>
                            {showTwoWeeks ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                                    <Typography variant="overline" sx={{ color: palette.neutral[400] }}>
                                        {t('content.coach.overview.after_two_weeks')}
                                    </Typography>
                                    <CoachingOverviewPieChart
                                        data={twoWeeksGraphData}
                                        containerDimensions={{ width: 255, height: 150 }}
                                        outerRadius={75}
                                        colors={[palette.good, palette.bad, '#a4a4a4']}
                                        translationPrefix="content.coach.overview"
                                    />
                                </Box>
                            ) : null}
                            {showOneMonth ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                                    <Typography variant="overline" sx={{ color: palette.neutral[400] }}>
                                        {t('content.coach.overview.after_one_month')}
                                    </Typography>
                                    <CoachingOverviewPieChart
                                        data={oneMonthGraphData}
                                        containerDimensions={{ width: 255, height: 150 }}
                                        outerRadius={75}
                                        colors={[palette.good, palette.bad, '#a4a4a4']}
                                        translationPrefix="content.coach.overview"
                                    />
                                </Box>
                            ) : null}
                            {showThreeMonths ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Typography variant="overline" sx={{ color: palette.neutral[400] }}>
                                        {t('content.coach.overview.after_three_months')}
                                    </Typography>
                                    <CoachingOverviewPieChart
                                        data={threeMonthsGraphData}
                                        containerDimensions={{ width: 255, height: 150 }}
                                        outerRadius={75}
                                        colors={[palette.good, palette.bad, '#a4a4a4']}
                                        translationPrefix="content.coach.overview"
                                    />
                                </Box>
                            ) : null}
                        </Box>
                    </>
                ) : null}
            </>
        );
    }

    return (
        <Box
            sx={{
                width: '300px',
                height: '100%',
                boxShadow: '-2px 4px 4px rgba(0, 0, 0, 0.25)',
                backgroundColor: palette.bgColor,
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
            }}
        >
            {isModalOpen && overviewData !== undefined ? (
                <CoachingEffectivenessModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    effectivenessData={overviewData.effectiveness_data}
                    autoSessions={props.autoSessions}
                    tabsMap={props.tabsMap}
                    setTab={props.setTab}
                />
            ) : null}
            {content}
        </Box>
    );
};

export default CoachingOverview;
