import { Box, Fade } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';

import { ExtendedFeedEvent } from '../../../backendsdk';
import useIsMobile from '../../../hooks/isMobile';
import palette from '../../ColorPalette';
import QuickCoachingModal from '../Accident/AccidentV1/Modal/QuickCoachingModal';

interface FeedShareEventComponentProps {
    showQuickCoaching: boolean;
    setShowQuickCoaching: (value: boolean) => void;
    currentEvent: ExtendedFeedEvent;
    setEvents: React.Dispatch<React.SetStateAction<ExtendedFeedEvent[]>>;
    setSnackbarMessage: (message: string) => void;
    containerRef: React.RefObject<HTMLDivElement>;
}

const FeedShareEventComponent: React.FC<FeedShareEventComponentProps> = (props) => {
    const isMobile = useIsMobile();

    return (
        <>
            <Fade in={props.showQuickCoaching}>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: alpha(palette.primary, 0.5),
                        zIndex: 9,
                    }}
                    onClick={() => props.setShowQuickCoaching(false)}
                ></Box>
            </Fade>
            <QuickCoachingModal
                isOpen={props.showQuickCoaching}
                licensePlate={props.currentEvent.license_plate}
                driverToken={props.currentEvent.driver}
                onSend={(sessionId: number) => {
                    props.setShowQuickCoaching(false);
                    props.setEvents((prev) => {
                        return prev.map((e) => {
                            if (e.event_id === props.currentEvent.event_id) {
                                e.coach_session = sessionId;
                            }
                            return e;
                        });
                    });
                }}
                onClose={() => props.setShowQuickCoaching(false)}
                eventId={props.currentEvent.event_id}
                sessionId={props.currentEvent.coach_session}
                setAlert={(e: { message: string }) => props.setSnackbarMessage(e.message)}
                containerRef={isMobile ? undefined : props.containerRef}
                darkMode
            />
        </>
    );
};

export default FeedShareEventComponent;
