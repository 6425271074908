import { Box, Divider, Menu, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedMenuItem as MenuItem } from '../../../../components/TrackedComponents';
import palette from '../../../ColorPalette';
import { SORT_FIELDS, SORT_ICONS, SORT_ORDER, SortField, SortOrder } from '../Defs';

interface SortMenuProps {
    open: boolean;
    anchorElSort: null | HTMLElement;
    handleSortMenuClose: CallableFunction;
    sort: [SortField, SortOrder];
    setSort: Dispatch<SetStateAction<[SortField, SortOrder]>>;
}

const SortMenu: React.FC<SortMenuProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Menu
            id="change-stage-menu"
            anchorEl={props.anchorElSort}
            open={props.open}
            onClose={() => props.handleSortMenuClose()}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
            {SORT_FIELDS.map((field) => (
                <MenuItem
                    key={field}
                    id={`sort-${field}`}
                    data-testid={`sort-${field}`}
                    onClick={() => {
                        props.setSort((prev) => [field, prev[1]]);
                    }}
                    sx={{ backgroundColor: field === props.sort[0] ? palette.neutral[200] : 'inherit' }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {SORT_ICONS[field]}
                        <Typography variant="inherit">{t(`content.accidents.sort.${field}`)}</Typography>
                    </Box>
                </MenuItem>
            ))}
            <Divider />
            {SORT_ORDER.map((order) => (
                <MenuItem
                    key={order}
                    id={`sort-${order}`}
                    data-testid={`sort-${order}`}
                    onClick={() => {
                        props.setSort((prev) => [prev[0], order]);
                    }}
                    sx={{ backgroundColor: order === props.sort[1] ? palette.neutral[200] : 'inherit' }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {SORT_ICONS[order]}
                        <Typography variant="inherit">{t(`content.accidents.sort.${order}`)}</Typography>
                    </Box>
                </MenuItem>
            ))}
        </Menu>
    );
};

export default SortMenu;
