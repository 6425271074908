import { Box, SelectChangeEvent, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import splitExtension from '../../../utils/File';
import { TAG_LIST } from './Defs';
import { MuiSelect, MuiTextField } from './MuiFormControls';

interface AccidentUpdateFileProps {
    token?: string;
    headers?: { [key: string]: Record<string, string> };
    handleFileUpdate: CallableFunction;
    accident_id: number;
    files: Array<File> | null;
    setFiles: CallableFunction;
    children?: React.ReactNode;
    customerTags: Array<string>;
    setCustomerTags: CallableFunction;
    setAlert: CallableFunction;
    setOpen: CallableFunction;
}

export const AccidentUpdateFile: React.FC<AccidentUpdateFileProps> = (props: AccidentUpdateFileProps) => {
    const [uploading, setUploading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [tag, setTag] = useState<string>(TAG_LIST[0]);
    const [selectedFiles, setSelectedFiles] = useState<Array<File>>();
    const [newTag, setNewTag] = useState<boolean>(false);
    const { t } = useTranslation();
    const { agencyApi } = useApi();

    useEffect(() => {
        if (props.files) {
            setSelectedFiles(props.files);
        }
    }, [props.files]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            setSelectedFiles(files);
        }
    };

    const uploadSingleFile = async (file: File, fileName: string) => {
        const post_res = await agencyApi
            .agencyV1AccidentAccidentIdArtifactPost({
                accidentId: props.accident_id,
                fileData: { file_name: fileName },
            })
            .catch((err) =>
                props.setAlert({ message: 'Connection error. ' + err.toString(), type: 'error', duration: 6000 }),
            );
        if (!post_res) {
            return;
        }

        const config = {
            headers: {
                'Content-Type': file.type,
            },
        };
        await axios
            .put(post_res.data.upload_url, file, config)
            .catch((err) =>
                props.setAlert({ message: 'Failed to upload to s3. ' + err.toString(), type: 'error', duration: 6000 }),
            );
        const data = {
            file_name: fileName,
            path: post_res.data.s3_path,
            tag: tag,
        };
        const put_res = await agencyApi
            .agencyV1AccidentAccidentIdArtifactPut({ accidentId: props.accident_id, artifactRequest: data })
            .catch((err) =>
                props.setAlert({ message: 'File Upload Error. ' + err.toString(), type: 'error', duration: 6000 }),
            );
        if (put_res) {
            props.setAlert(undefined);
            setName('');
            setTag(TAG_LIST[0]);
            setSelectedFiles(undefined);
        }
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (!name || !selectedFiles) {
            props.setAlert({ message: 'Need to set file params', type: 'error', duration: 6000 });
            return;
        }

        setUploading(true);
        for (const [fileIndex, file] of selectedFiles.entries()) {
            props.setAlert({
                message: t('content.accidents.files.in_progress', {
                    file_number: fileIndex + 1,
                    total_files: selectedFiles.length,
                }),
                type: 'info',
            });
            let fileName = file.name;
            if (selectedFiles.length == 1) {
                const fileExtension = splitExtension(selectedFiles?.length == 1 ? selectedFiles[0].name : '')[1];
                fileName = `${name}${fileExtension}`;
            }
            await uploadSingleFile(file, fileName);
        }
        setUploading(false);
        props.setOpen(false);
        props.setAlert(undefined);
        props.setFiles(null);
        props.handleFileUpdate();
        setNewTag(false);
        props.setCustomerTags((p: Array<string>) => [...new Set([...p, tag])]);
    };

    useEffect(() => {
        if (selectedFiles) {
            if (selectedFiles.length == 1) {
                const fileBasename = splitExtension(selectedFiles?.length == 1 ? selectedFiles[0].name : '')[0];
                setName(fileBasename);
            } else {
                setName(t('content.accidents.files.multiple'));
            }
        }
    }, [selectedFiles]);

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {t('content.accidents.files.new_file')}
            </Typography>
            <Box textAlign="center">
                <Button id="btn-browse" size="small" variant="contained" component="label" sx={{ mb: 2 }}>
                    Browse
                    <input id="file-field" hidden multiple type="file" onChange={handleFileChange} />
                </Button>
            </Box>

            <MuiTextField
                id="filename-field"
                name="filename"
                value={name}
                disabled={selectedFiles ? selectedFiles.length > 1 : undefined}
                label={t('content.accidents.files.name')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                sx={{ mb: 2 }}
            />
            {newTag ? (
                <MuiTextField
                    id="tag-text-field"
                    name="tag"
                    label={t('content.accidents.files.tag')}
                    value={tag}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTag(e.target.value)}
                    sx={{ mb: 2 }}
                />
            ) : (
                <MuiSelect
                    id="tag-dropdown"
                    options={[...new Set([...TAG_LIST, ...props.customerTags, tag])]}
                    value={tag}
                    label={t('content.accidents.files.tag')}
                    onChange={(e: SelectChangeEvent<string>) => setTag(e.target.value)}
                    t={(s: string) => t(`content.accidents.files.${s.toLocaleLowerCase().replaceAll(' ', '_')}`, s)}
                    sx={{ mb: 2 }}
                />
            )}
            <Box textAlign="center">
                <Button
                    size="small"
                    variant="contained"
                    id="new-tag-btn"
                    onClick={() => setNewTag((p) => !p)}
                    sx={{ mr: 1 }}
                >
                    {t('content.accidents.files.new_tag')}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    id="submit-tag-btn"
                    disabled={!(selectedFiles && name && tag) || uploading}
                    onClick={handleSubmit}
                >
                    {t('content.accidents.files.submit')}
                </Button>
                {props.children}
            </Box>
        </Box>
    );
};

export default AccidentUpdateFile;
