import axios from 'axios';

import { ApiApi } from '../backendsdk';

export const getCustomer = async (api: ApiApi) => {
    const res = await api.apiV0CustomerGet();
    let image = '';
    if (res.data.logo_url) {
        image = await axios
            .get(res.data.logo_url, {
                responseType: 'blob',
            })
            .then((r) => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new window.FileReader();
                    reader.readAsDataURL(r.data);
                    reader.onload = function () {
                        const imageResult = reader.result;
                        if (typeof imageResult === 'string') {
                            resolve(imageResult);
                        }
                    };
                    reader.onerror = reject;
                });
            });
    }
    return { ...res.data, logo_url: image };
};
