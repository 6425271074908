import { Box, Chip, CircularProgress, Stack, Typography } from '@mui/material';
import {
    GridCellParams,
    GridColDef,
    GridComparatorFn,
    GridFilterItem,
    GridRenderCellParams,
    GridValueFormatterParams,
    getGridSingleSelectOperators,
    getGridStringOperators,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HosDriver } from '../../../../backendsdk';
import { TrackedDataGrid as StyledDataGrid } from '../../../../components/TrackedComponents';
import { TrackedLink as Link } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import { getDateTimeColumnType, localeObjectMap } from '../../../../utils/DataGridDateTime';
import { malfunctionsPageDefs } from '../../../../utils/Pages';
import { MINUTES_IN_HOUR, timestampToDate } from '../../../../utils/TimeFormatter';
import palette from '../../../ColorPalette';
import { DEFAULT_TITLE } from '../../../Layout';
import { gridLocalization } from '../../OTA/MuiDeviceTable';
import { offsetToTimezone } from '../Drivers/TerminalsDataGrid';
import { MALFUNCTIONS_DATA } from './malfunctionsData';

dayjs.extend(utc);

export interface MalfunctionData {
    device: string;
    currentDriver: HosDriver;
    malfunctions: MalfunctionCode[];
    diagnosticEvents: DiagnosticEventCode[];
    since: number;
}

const MALFUNCTIONS_MAP = {
    P: 'Power',
    E: 'Engine synchronization',
    T: 'Timing',
    L: 'Positioning',
    R: 'Data recording',
    S: 'Data transfer',
    O: 'Other',
} as const;

type MalfunctionCode = keyof typeof MALFUNCTIONS_MAP;
type MalfunctionType = typeof MALFUNCTIONS_MAP[MalfunctionCode];

const DIAGNOSTIC_EVENT_MAP = {
    1: 'Power',
    2: 'Engine synchronization',
    3: 'Missing required data elements',
    4: 'Data transfer',
    5: 'Unidentified driving records',
    6: 'Other',
} as const;

type DiagnosticEventCode = keyof typeof DIAGNOSTIC_EVENT_MAP;
type DiagnosticEventType = typeof DIAGNOSTIC_EVENT_MAP[DiagnosticEventCode];

const malfunctionsSortComparator: GridComparatorFn<MalfunctionType[] | DiagnosticEventType[]> = (
    malfunctions1: MalfunctionType[] | DiagnosticEventType[],
    malfunctions2: MalfunctionType[] | DiagnosticEventType[],
) => {
    return malfunctions1.length - malfunctions2.length;
};

const malfunctionsFilterOperators = getGridSingleSelectOperators()
    .filter((operator) => operator.value === 'isAnyOf')
    .map((operator) => {
        const newOperator = { ...operator };
        const newGetApplyFilterFn = (filterItem: GridFilterItem) => {
            return (params: GridCellParams): boolean => {
                const value = params.value as string[];
                let isOk = true;
                filterItem?.value?.forEach((item: MalfunctionType | DiagnosticEventType) => {
                    isOk = isOk && value.includes(item);
                });
                return isOk;
            };
        };
        newOperator.getApplyFilterFn = newGetApplyFilterFn;
        return newOperator;
    });

const MalfunctionsComponent: React.FC = () => {
    const [malfunctions, setMalfunctions] = useState<MalfunctionData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const isMobile = useIsMobile();
    const { i18n, t } = useTranslation();
    const { profile } = useProfile();
    const locale = localeObjectMap[i18n.languages[0]];

    const getMalfunctions = () => {
        // TODO: send request with api object
        setIsLoading(true);
        setIsError;
        setMalfunctions(MALFUNCTIONS_DATA);
        setIsLoading(false);
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${malfunctionsPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        getMalfunctions();
    }, []);

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'device',
                headerName: 'Device',
                width: 150,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'currentDriver',
                headerName: 'Current Driver',
                minWidth: 200,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'malfunctions',
                headerName: 'Malfunctions',
                minWidth: 220,
                flex: 1,
                type: 'singleSelect',
                valueOptions: Object.values(MALFUNCTIONS_MAP),
                renderCell: (params: GridRenderCellParams) => (
                    <Stack spacing={0.5} sx={{ alignItems: 'start', alignSelf: 'start' }}>
                        {params.row.malfunctions.map((malfunction: MalfunctionType) => (
                            <Chip
                                key={malfunction}
                                label={<Typography fontSize={12}>{malfunction}</Typography>}
                                sx={{ backgroundColor: 'redColor.main' }}
                            />
                        ))}
                    </Stack>
                ),
                sortComparator: malfunctionsSortComparator,
                filterOperators: malfunctionsFilterOperators,
            },
            {
                field: 'diagnosticEvents',
                headerName: 'Diagnostic Events',
                minWidth: 220,
                flex: 1,
                type: 'singleSelect',
                valueOptions: Object.values(DIAGNOSTIC_EVENT_MAP),
                renderCell: (params: GridRenderCellParams) => (
                    <Stack spacing={0.5} sx={{ alignItems: 'start', alignSelf: 'start' }}>
                        {params.row.diagnosticEvents.map((diagnosticEvent: DiagnosticEventType) => (
                            <Chip
                                key={diagnosticEvent}
                                label={<Typography fontSize={12}>{diagnosticEvent}</Typography>}
                                sx={{ backgroundColor: 'redColor.main' }}
                            />
                        ))}
                    </Stack>
                ),
                sortComparator: malfunctionsSortComparator,
                filterOperators: malfunctionsFilterOperators,
            },
            {
                field: 'since',
                headerName: 'Since',
                ...getDateTimeColumnType(locale),
                width: 250,
                valueFormatter: (params: GridValueFormatterParams) => {
                    const date = dayjs(params.value);
                    return `${date.format(profile.dateTimeFormat)} ${
                        offsetToTimezone[date.utcOffset() / MINUTES_IN_HOUR] || `GMT${date.format('Z')}`
                    }`;
                },
            },
        ],
        [],
    );

    const rows = malfunctions.map((malfunction, idx) => {
        return {
            id: idx,
            device: malfunction.device,
            currentDriver: `${malfunction.currentDriver.first_name} ${malfunction.currentDriver.last_name}`,
            malfunctions: malfunction.malfunctions.map((m) => MALFUNCTIONS_MAP[m]),
            diagnosticEvents: malfunction.diagnosticEvents.map((d) => DIAGNOSTIC_EVENT_MAP[d]),
            since: timestampToDate(malfunction.since),
        };
    });

    if (isLoading) {
        return <CircularProgress />;
    } else if (isError) {
        return (
            <Typography variant="overline">
                Error fetching malfunctions and diagnostic events.{' '}
                <Link id="try-again-link" component="button" variant="overline" onClick={() => getMalfunctions()}>
                    Try again.
                </Link>
            </Typography>
        );
    } else if (malfunctions.length === 0) {
        return <Typography variant="overline">No current malfunctions or diagnostic events</Typography>;
    }

    return (
        <Box sx={{ width: '100%', maxWidth: '1200px', height: '100%', pb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                <StyledDataGrid
                    id="malfunctions-data-grid"
                    rows={rows}
                    columns={columns}
                    columnBuffer={4}
                    disableRowSelectionOnClick
                    disableVirtualization={isMobile}
                    pagination={isMobile}
                    getRowHeight={() => 'auto'}
                    slotProps={{
                        columnsPanel: {
                            sx: {
                                '& .MuiDataGrid-panelFooter button:first-child': {
                                    display: 'none',
                                },
                            },
                        },
                    }}
                    localeText={
                        {
                            ...gridLocalization[i18n.languages[0]],
                            filterPanelRemoveAll: t('table.remove_all'),
                            columnMenuManageColumns: t('table.manage_columns'),
                            unpin: t('table.unpin'),
                            filterOperatorOnDate: t('table.on_date'),
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any
                    }
                    sx={{
                        width: '100%',
                        height: '100%',
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '10px' },
                        backgroundColor: palette.neutral[50],
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default MalfunctionsComponent;
