import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { Box, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { Descendant, createEditor } from 'slate';
import { Editor } from 'slate';
import { Transforms } from 'slate';
import { withReact } from 'slate-react';

import { Comment, ExtendedAccident, ExtendedCustomerDataAllOfUsers } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { accidentsPageDefs, flattenRoles } from '../../../utils/Pages';
import { User, hasTextValue } from '../../../utils/slate';
import palette from '../../ColorPalette';
import { RTLDirectionContext } from '../../Layout';
import MentionsTextField, { withMentions } from './MentionsTextField';

const EMPTY_COMMENT_DETAILS: Comment = {
    accident_id: 1,
    comment_id: 0,
    created_time: 0,
    user: '',
    text: [{ type: 'paragraph', children: [{ text: '' }] }],
};

const COMMENT_ROW_BACKGROUNDS = ['inherit', palette.neutral[100]];

interface AccidentCommentComponentProps {
    details: ExtendedAccident;
    setDetails: CallableFunction;
    onSave: CallableFunction;
    customerUsers: ExtendedCustomerDataAllOfUsers[];
    disabled?: boolean;
}

export const EMPTY_VALUE = [{ type: 'paragraph', children: [{ text: '' }] }];
const AccidentCommentComponent: React.FC<AccidentCommentComponentProps> = (props: AccidentCommentComponentProps) => {
    const { profile } = useProfile();
    const [details, setDetails] = useState<ExtendedAccident>(props.details);
    const [editCommentId, setEditCommentId] = useState<number>(0);
    const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
    const [newComment, setNewComment] = useState<Descendant[]>(EMPTY_VALUE);
    const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
    const { t } = useTranslation();
    const isRTL = useContext(RTLDirectionContext);
    const [editor] = useState<Editor>(() => withMentions(withReact(createEditor())));
    const { agencyApi } = useApi();
    const newCommentDetails = {
        ...EMPTY_COMMENT_DETAILS,
        user: profile.name,
        text: newComment,
        accident_id: details.accident_id,
    };
    const disabled = props.details.accident_id === 0 || props.disabled;

    const users = props.customerUsers.filter(
        (u) =>
            !!u.name.trim() &&
            flattenRoles(accidentsPageDefs).includes(u.role) &&
            (!props.details.sub_fleet_name || !u.sub_fleet || u.sub_fleet === props.details.sub_fleet_name),
    );
    const userList = users.map((u) => ({ id: u.user_id, name: u.name }));
    const userAvatarsMap: Record<number, string> = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        users.filter((u) => !!u.image_url).map((u) => [u.user_id, u.image_url!]),
    );

    useEffect(() => {
        setDetails(props.details);
        setShowCommentModal(false);
    }, [props.details]);

    const addButton = (
        <Box>
            <IconButton
                size="small"
                id="add-comment-btn"
                color="primary"
                disabled={disabled || !hasTextValue(newComment)}
                onClick={() => {
                    agencyApi
                        .agencyV1AccidentAccidentIdCommentPost({
                            accidentId: props.details.accident_id,
                            comment: newCommentDetails,
                        })
                        .then(() => props.onSave());
                    setDetailsChanged(false);
                    Transforms.delete(editor, {
                        at: {
                            anchor: Editor.start(editor, []),
                            focus: Editor.end(editor, []),
                        },
                    });
                    setNewComment(EMPTY_VALUE);
                }}
                sx={{ ml: 0.5 }}
            >
                <SendIcon sx={{ transform: isRTL ? 'scaleX(-1)' : undefined }} />
            </IconButton>
        </Box>
    );

    return (
        <>
            <Prompt when={detailsChanged} message={t('content.accidents.prompt_msg')} />
            <Box sx={{ p: 1, pb: 0 }}>
                <Typography variant="overline">{t('content.accidents.comments.header')}</Typography>
            </Box>
            <Box
                sx={{
                    px: 1,
                    maxHeight: '10rem',
                    overflowY: 'scroll',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            >
                {details.comment_list.length > 0 ? (
                    <List sx={{ p: 0 }}>
                        {details.comment_list
                            .sort((a, b) => a.created_time - b.created_time)
                            .map((p: Comment, idx: number) => (
                                <AccidentCommentSpan
                                    key={p.comment_id}
                                    id={`comment-${idx}`}
                                    details={p}
                                    handleClick={() => {
                                        setEditCommentId(p.comment_id);
                                        setShowCommentModal(true);
                                    }}
                                    customerName={profile.customer.name}
                                    background={COMMENT_ROW_BACKGROUNDS[idx % 2]}
                                />
                            ))}
                    </List>
                ) : null}

                <Dialog
                    id="accident-comment-dialog"
                    open={showCommentModal}
                    fullWidth
                    maxWidth="sm"
                    onClose={() => setShowCommentModal(false)}
                >
                    <AccidentCommentForm
                        open={showCommentModal}
                        setOpen={setShowCommentModal}
                        refreshDetails={() => {
                            props.onSave();
                            setShowCommentModal(() => {
                                setEditCommentId(0);
                                return false;
                            });
                        }}
                        commentId={editCommentId}
                        details={props.details}
                        setDetails={setDetails}
                        setAccidentDetails={props.setDetails}
                        users={userList}
                        userAvatarsMap={userAvatarsMap}
                    >
                        <Button
                            id="btn-cancel"
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                setShowCommentModal(false);
                                setEditCommentId(0);
                            }}
                            sx={{ mr: 1 }}
                        >
                            {t('content.accidents.comments.cancel')}
                        </Button>
                    </AccidentCommentForm>
                </Dialog>
            </Box>
            <Box
                sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <MentionsTextField
                    containerSelector="[role=dialog]"
                    editor={editor}
                    setValue={setNewComment}
                    users={userList}
                    userAvatarsMap={userAvatarsMap}
                    disabled={disabled}
                />
                {disabled ? (
                    addButton
                ) : (
                    <Tooltip title={t('content.accidents.comments.add')}>
                        <Box>{addButton}</Box>
                    </Tooltip>
                )}
            </Box>
        </>
    );
};

interface AccidentCommentFormProps {
    commentId: number;
    details: ExtendedAccident;
    setDetails: CallableFunction;
    setAccidentDetails: CallableFunction;
    refreshDetails: CallableFunction;
    children: React.ReactNode;
    open: boolean;
    setOpen: CallableFunction;
    users: User[];
    userAvatarsMap: Record<number, string>;
}

interface AccidentCommentSpanProps {
    id: string;
    details: Comment;
    customerName: string;
    handleClick: CallableFunction;
    background: string;
}

const AccidentCommentSpan: React.FC<AccidentCommentSpanProps> = (props: AccidentCommentSpanProps) => {
    const { profile } = useProfile();
    const { t } = useTranslation();
    const [editor] = useState<Editor>(() => withMentions(withReact(createEditor())));

    useEffect(() => {
        editor.children = props.details.text as Descendant[];
    }, [props.details]);

    if (!Array.isArray(props.details.text)) {
        return null;
    }

    return (
        <ListItem
            disableGutters
            secondaryAction={
                profile.admin || props.details.user == profile.name ? (
                    <Tooltip title={t('content.accidents.comments.edit')}>
                        <Box>
                            <IconButton size="small" id={props.id} onClick={() => props.handleClick()} sx={{ mr: 0.5 }}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                ) : null
            }
            sx={{ backgroundColor: props.background, py: 0.5 }}
        >
            <ChatBubbleIcon fontSize="small" sx={{ m: 0.5, mx: 1 }} />
            <ListItemText>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ fontSize: 12, color: palette.neutral[700] }}>{props.details.user}</Typography>
                        <Typography sx={{ fontSize: 12, color: palette.neutral[700], mx: 0.5 }}>|</Typography>
                        <Typography sx={{ fontSize: 12, color: palette.neutral[700] }}>
                            {dayjs.unix(props.details.created_time).format(profile.dateTimeFormat)}
                        </Typography>
                    </Box>
                    <MentionsTextField initialValue={EMPTY_VALUE} editor={editor} readOnly />
                </Box>
            </ListItemText>
        </ListItem>
    );
};

const AccidentCommentForm: React.FC<AccidentCommentFormProps> = (props: AccidentCommentFormProps) => {
    const commentDetails = props.details.comment_list.filter((c) => c.comment_id == props.commentId)[0];
    const [details, setDetails] = useState<Comment>(commentDetails);
    const { t } = useTranslation();
    const { agencyApi } = useApi();
    const [editor] = useState<Editor>(() => withMentions(withReact(createEditor())));

    const updateAccidentCommentDetails = () => {
        agencyApi
            .agencyV1AccidentAccidentIdCommentCommentIdPatch({
                accidentId: props.details.accident_id,
                commentId: props.commentId,
                comment: details,
            })
            .then(() => props.refreshDetails());
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {t('content.accidents.comments.edit_comment')}
            </Typography>
            <MentionsTextField
                initialValue={details.text as Descendant[]}
                editor={editor}
                setValue={(value: Descendant[]) => setDetails((prev) => ({ ...prev, text: value }))}
                users={props.users}
                userAvatarsMap={props.userAvatarsMap}
            />
            <Box textAlign="center" sx={{ mt: 2 }}>
                {props.children}
                <Button
                    id="update-comment-btn"
                    size="small"
                    variant="contained"
                    disabled={!details.text}
                    onClick={updateAccidentCommentDetails}
                >
                    {t('content.accidents.comments.update')}
                </Button>
            </Box>
        </Box>
    );
};

export default AccidentCommentComponent;
