import { Box } from '@mui/material';
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams, getGridStringOperators } from '@mui/x-data-grid-pro';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useMemo } from 'react';

import { HosDriver } from '../../../../backendsdk';
import { TrackedDataGrid as StyledDataGrid } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile, { ExtendedProfile } from '../../../../hooks/profile';
import { MILLISECONDS_IN_SECOND } from '../../../../utils/TimeFormatter';
import palette from '../../../ColorPalette';
import { DriverRecord, RECORD_CODE, abbrMap } from '../Defs';
import { StatusAvatar, durationFormatter } from './DriverCountersDataGrid';

dayjs.extend(utc);

interface StatusTimeDelta {
    start: number;
    end: number;
    status: string;
    duration: number;
    device: string;
}

const recordsToDeltas = (records: Array<DriverRecord>) => {
    const deltas: Array<StatusTimeDelta> = [];
    for (let i = 1; i < records.length; i++) {
        const delta: StatusTimeDelta = {
            start: records[i - 1].event_datetime,
            end: records[i].event_datetime,
            status: abbrMap[RECORD_CODE[records[i - 1].record_code] as keyof typeof RECORD_CODE],
            duration: records[i].event_datetime - records[i - 1].event_datetime,
            device: records[i - 1].device,
        };
        deltas.push(delta);
    }
    return deltas;
};

export const timestampsToRangeString = (start: number, end: number, profile: ExtendedProfile, driver?: HosDriver) => {
    const utcOffset = driver ? -driver.home_terminal.timezone_offset_from_utc : 0;
    return `${dayjs(start * MILLISECONDS_IN_SECOND)
        .utcOffset(utcOffset)
        .format(profile.timeFormat)}–${dayjs(end * MILLISECONDS_IN_SECOND)
        .utcOffset(utcOffset)
        .format(profile.timeFormat)}`;
};

interface RecordsDataGridProps {
    driver: HosDriver;
    records: DriverRecord[];
    date: Dayjs;
    isToday: boolean;
}

const RecordsDataGrid: React.FC<RecordsDataGridProps> = (props: RecordsDataGridProps) => {
    const updatedRecords = [...props.records];
    const isMobile = useIsMobile();
    const { profile } = useProfile();

    if (updatedRecords.length > 0) {
        const lastTimestamp = props.isToday ? dayjs().utc().unix() : props.date.add(1, 'day').unix();
        if (updatedRecords[updatedRecords.length - 1].event_datetime < lastTimestamp) {
            const lastRecord = { ...updatedRecords[updatedRecords.length - 1], event_datetime: lastTimestamp };
            updatedRecords.push(lastRecord);
        }
    }
    const deltas = recordsToDeltas(updatedRecords);

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'time',
                headerName: 'Time',
                width: 160,
                sortable: false,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'duration',
                type: 'number',
                headerName: 'Duration',
                width: 80,
                valueFormatter: durationFormatter,
                sortable: false,
            },
            {
                field: 'status',
                type: 'singleSelect',
                valueOptions: Object.values(abbrMap),
                headerName: 'Status',
                width: 75,
                align: 'center',
                headerAlign: 'center',
                renderHeader: (params: GridColumnHeaderParams) => (
                    <Box sx={{ mr: 0.75 }}>{params.colDef.headerName}</Box>
                ),
                renderCell: (params: GridRenderCellParams) => <StatusAvatar status={params.value} />,
                sortable: false,
            },
            {
                field: 'remark',
                headerName: 'Remark',
                minWidth: 150,
                flex: 1,
                sortable: false,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'device',
                headerName: 'Device',
                width: 150,
                sortable: false,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
        ],
        [],
    );

    const rows = deltas.map((delta, idx) => {
        return {
            id: idx,
            time: timestampsToRangeString(delta.start, delta.end, profile, props.driver),
            duration: delta.duration,
            status: delta.status,
            remark: '',
            device: delta.device,
        };
    });

    return (
        <StyledDataGrid
            id="records-data-grid"
            rows={rows}
            columns={columns}
            columnBuffer={5}
            disableRowSelectionOnClick
            disableColumnMenu
            disableVirtualization={isMobile}
            pagination={isMobile}
            columnHeaderHeight={45}
            rowHeight={45}
            slotProps={{
                columnsPanel: {
                    sx: {
                        '& .MuiDataGrid-panelFooter button:first-child': {
                            display: 'none',
                        },
                    },
                },
            }}
            sx={{
                height: '100%',
                minHeight: '325px',
                maxWidth: '1200px',
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: palette.neutral[50],
            }}
        />
    );
};

export default RecordsDataGrid;
