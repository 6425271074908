import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CoachMediaFile, CoachSession, MediaFile } from '../../../../backendsdk';
import { TrackedButton as Button } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import palette from '../../../ColorPalette';
import { SaveFirstTooltip } from './CoachingAgenda';
import MediaLibrary from './MediaLibrary';

interface MediaFileFormProps {
    children?: React.ReactNode;
    sessionId: number;
    item?: CoachMediaFile;
    onClose: CallableFunction;
    setDetails: CallableFunction;
    isLoading: boolean;
    setIsLoading: CallableFunction;
    isCompleted: boolean;
    isSaved: boolean;
    setAlert: CallableFunction;
    onUpdate: CallableFunction;
    addAnotherItem: boolean;
    setItemsAdded: CallableFunction;
}

const MediaFileForm: React.FC<MediaFileFormProps> = (props: MediaFileFormProps) => {
    const [isLibraryOpen, setIsLibraryOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<MediaFile | undefined>(props.item?.file);
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | undefined>(props.item?.file_url);
    const [fileTitle, setFileTitle] = useState<string>(props.item?.title || '');
    const { t } = useTranslation();
    const { api } = useApi();

    const isEditable = !props.isCompleted && props.isSaved;

    const handleSubmit = () => {
        if (selectedFile) {
            props.setIsLoading(true);
            if (props.item !== undefined) {
                api.apiV0CoachSessionIdMediaFileCoachMediaFileIdPatch({
                    sessionId: props.sessionId,
                    coachMediaFileId: props.item.id,
                    coachMediaFileRequest: { media_file_id: selectedFile.id, title: fileTitle },
                })
                    .then((res) => {
                        props.setDetails((prev: CoachSession) => ({
                            ...prev,
                            media_files: prev.media_files.map((media_file) =>
                                media_file.id === res.data.id ? res.data : media_file,
                            ),
                        }));
                        props.onUpdate();
                        props.onClose();
                    })
                    .catch(() => {
                        props.setAlert({
                            message: t('content.coach.errors.update_item'),
                            type: 'error',
                            duration: 6000,
                        });
                        props.setIsLoading(false);
                    });
            } else {
                api.apiV0CoachSessionIdMediaFilePost({
                    sessionId: props.sessionId,
                    coachMediaFileRequest: { media_file_id: selectedFile.id, title: fileTitle },
                })
                    .then((res) => {
                        props.setDetails((prev: CoachSession) => ({
                            ...prev,
                            media_files: [...prev.media_files, res.data],
                            agenda: [...prev.agenda, { type: 'media_file', id: res.data.id }],
                        }));
                        props.onUpdate();
                        if (props.addAnotherItem) {
                            setSelectedFile(undefined);
                            setSelectedFileUrl(undefined);
                            setFileTitle('');
                            props.setIsLoading(false);
                            props.setItemsAdded((prev: number) => prev + 1);
                        } else {
                            props.onClose();
                        }
                    })
                    .catch(() => {
                        props.setAlert({
                            message: t('content.coach.errors.add_item'),
                            type: 'error',
                            duration: 6000,
                        });
                        props.setIsLoading(false);
                    });
            }
        }
    };

    const handleSelectFile = (file: MediaFile) => {
        setSelectedFile(file);
        setFileTitle(file.file_name);
    };

    useEffect(() => {
        if (selectedFile !== undefined) {
            api.apiV0MediaFilesMediaFileIdGet({
                mediaFileId: selectedFile.id,
            }).then((response) => {
                setSelectedFileUrl(response.data);
            });
        }
    }, [selectedFile]);

    let file;
    const hasFileToDisplay = selectedFile && selectedFileUrl;
    if (hasFileToDisplay) {
        const fileType = selectedFile.file_type.split('/')[0];
        if (fileType === 'image') {
            file = <img src={selectedFileUrl} style={{ maxWidth: '100%', maxHeight: '450px' }} />;
        } else if (fileType === 'video') {
            file = <video src={selectedFileUrl} controls style={{ maxWidth: '100%' }} />;
        }
        file = (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <TextField
                    id="title-field"
                    label={t('content.coach.add_item.media_file.title')}
                    size="small"
                    disabled={!isEditable}
                    value={fileTitle}
                    onChange={(e) => setFileTitle(e.target.value)}
                    sx={{ my: 1 }}
                />
                {file}
            </Box>
        );
    }

    const mediaFileField = (
        <Box
            sx={{
                width: '100%',
                border: `1px solid ${palette.gray[400]}`,
                backgroundColor: palette.white,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 1,
            }}
        >
            <Typography variant="body2" sx={{ width: '100%', mb: 0.5 }}>
                {t('content.coach.add_item.media_file.instructions')}
            </Typography>
            {file}
            {isEditable ? (
                <Button
                    id="btn-browse"
                    data-testid="btn-browse"
                    variant="contained"
                    color="primary"
                    disabled={!props.isSaved}
                    onClick={() => setIsLibraryOpen(true)}
                    startIcon={<PermMediaIcon />}
                    sx={{ mt: 1 }}
                >
                    {t('content.coach.add_item.media_file.browse')}
                </Button>
            ) : null}
        </Box>
    );

    return (
        <>
            {props.isSaved ? mediaFileField : <SaveFirstTooltip>{mediaFileField}</SaveFirstTooltip>}
            {isLibraryOpen ? (
                <MediaLibrary
                    setAlert={props.setAlert}
                    onClose={() => setIsLibraryOpen(false)}
                    onSelect={handleSelectFile}
                />
            ) : null}
            {isEditable ? (
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    {props.children}
                    <Button
                        id="btn-cancel-item"
                        data-testid="btn-cancel-item"
                        variant="contained"
                        color="primary"
                        disabled={props.isLoading}
                        onClick={() => props.onClose()}
                        sx={{ ml: 'auto', flexShrink: 0 }}
                    >
                        {t('content.coach.add_item.cancel')}
                    </Button>
                    <Button
                        id="btn-save-item"
                        data-testid="btn-save-item"
                        variant="contained"
                        color="secondary"
                        sx={{ ml: 1, flexShrink: 0 }}
                        disabled={props.isLoading || !hasFileToDisplay}
                        onClick={handleSubmit}
                    >
                        {props.item === undefined
                            ? t('content.coach.add_item.add')
                            : t('content.coach.add_item.update')}
                    </Button>
                </Box>
            ) : null}
        </>
    );
};

export default MediaFileForm;
