import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Card, Grid, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Damage, ExtendedAccident } from '../../../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../../../components/TrackedComponents';
import AccidentDamageForm from '../../AccidentDamageDetails';
import AccidentWitnessForm from '../../AccidentWitnessDetails';

interface AccidentModalProps {
    details: ExtendedAccident;
    allowUpdate: boolean;
    setUpdateCallbacks: CallableFunction;
    setValidateCallbacks: CallableFunction;
    sending: boolean;
}

const ExtraDetailsTab: React.FC<AccidentModalProps> = (props: AccidentModalProps) => {
    const { t } = useTranslation();
    const { details } = props;
    const [newThirdParty, setNewThirdParty] = useState<boolean>(false);

    const conditionalTooltip = (component: React.ReactNode) => {
        if (details.accident_id === 0) {
            return (
                <Tooltip title={t('content.accidents.form.please_save')} followCursor>
                    <Box>{component}</Box>
                </Tooltip>
            );
        } else {
            return component;
        }
    };

    return (
        <Grid container spacing={1} columns={2} sx={{ p: 1 }}>
            <Grid item xs={1}>
                {details.damage_list.map((damage: Damage, idx: number) => (
                    <Card sx={{ p: 1, mt: idx > 0 ? 1 : 0 }} key={damage.damage_id}>
                        {conditionalTooltip(
                            <AccidentDamageForm
                                index={idx}
                                key={idx}
                                accident_id={details.accident_id}
                                details={damage}
                                setUpdateCallbacks={props.setUpdateCallbacks}
                                setValidateCallbacks={props.setValidateCallbacks}
                                allowUpdate={props.allowUpdate}
                                disabled={props.sending}
                            >
                                {idx === details.damage_list.length - 1 && !newThirdParty && props.allowUpdate ? (
                                    <Tooltip title={t('content.accidents.damage.add')}>
                                        <Box>
                                            <IconButton
                                                id="btn-add-damage"
                                                size="small"
                                                onClick={() => setNewThirdParty(true)}
                                                sx={{ position: 'absolute', bottom: 0, right: 0 }}
                                            >
                                                <AddCircleIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                ) : null}
                            </AccidentDamageForm>,
                        )}
                    </Card>
                ))}
                {newThirdParty || details.damage_list.length === 0 ? (
                    <Card sx={{ p: 1, mt: 1 }}>
                        {conditionalTooltip(
                            <AccidentDamageForm
                                index={details.damage_list.length}
                                accident_id={details.accident_id}
                                setNewThirdParty={setNewThirdParty}
                                setUpdateCallbacks={props.setUpdateCallbacks}
                                setValidateCallbacks={props.setValidateCallbacks}
                                allowUpdate={props.allowUpdate}
                                disabled={props.sending}
                            >
                                {details.damage_list.length !== 0 ? (
                                    <IconButton
                                        id="btn-cancel-add-damage"
                                        size="small"
                                        sx={{ position: 'absolute', bottom: 0, right: 0 }}
                                    >
                                        <CancelIcon fontSize="small" onClick={() => setNewThirdParty(false)} />
                                    </IconButton>
                                ) : null}
                            </AccidentDamageForm>,
                        )}
                    </Card>
                ) : null}
            </Grid>
            <Grid item xs={1}>
                <Card sx={{ height: '100%', p: 1 }}>
                    {conditionalTooltip(
                        <AccidentWitnessForm
                            details={details.witness_list[0]}
                            accidentDetails={details}
                            setUpdateCallbacks={props.setUpdateCallbacks}
                            allowUpdate={props.allowUpdate}
                            disabled={props.sending}
                        />,
                    )}
                </Card>
            </Grid>
        </Grid>
    );
};

export default ExtraDetailsTab;
