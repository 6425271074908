import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Circle, MapContainer, Marker, TileLayer, useMap, useMapEvents } from 'react-leaflet';

import { isLocationValid, parseLocation } from '../../../utils/location';

const smallIconSize = [25, 41];

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [smallIconSize[0] / 2, smallIconSize[1]],
});

interface CenterComponentProps {
    position: L.LatLng;
    setPosition: CallableFunction;
    radius: number;
    route?: Array<[string, number | string, CallableFunction]>;
}

const SearchCenterComponent: React.FC<CenterComponentProps> = ({ position, setPosition, radius, route }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const markerRef = useRef<any>(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    setPosition(marker.getLatLng());
                }
            },
            drag() {
                const marker = markerRef.current;
                if (marker != null) {
                    setPosition(marker.getLatLng());
                }
            },
        }),
        [],
    );

    return (
        <MapContainer style={{ height: '100%', width: '100%' }} zoom={15} scrollWheelZoom={false} center={position}>
            <CenterComponent position={position} setPosition={setPosition} radius={radius} route={route} />
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker eventHandlers={eventHandlers} position={position} ref={markerRef} icon={DefaultIcon} draggable />
            <Circle center={position} radius={radius} />
        </MapContainer>
    );
};

const CenterComponent: React.FC<CenterComponentProps> = ({ position, setPosition, route }) => {
    const map = useMap();
    const [polyline, setPolyline] = useState<Array<L.Polyline>>([]);

    useMapEvents({
        click(e) {
            setPosition(e.latlng);
        },
    });

    useEffect(() => {
        if (route != undefined) {
            const waypoints: Array<[L.LatLngExpression, number | string, CallableFunction]> = route
                .filter((waypoint) => isLocationValid(waypoint[0]))
                .map((waypoint) => {
                    const location = parseLocation(waypoint[0]);
                    return [L.latLng(location[0], location[1]), waypoint[1], waypoint[2]];
                });
            const blackLines: Array<L.Polyline> = [];
            const colorLines: Array<L.Polyline> = [];
            for (let i = 1; i < waypoints.length; i++) {
                colorLines.push(
                    L.polyline([waypoints[i - 1][0], waypoints[i][0]], {
                        weight: 5,
                        color: waypoints[i][2](waypoints[i][1]),
                    }),
                );
            }
            setPolyline([...blackLines, ...colorLines]);
        }
    }, [map, route]);

    useEffect(() => {
        if (polyline) {
            polyline.map((new_polyline) => new_polyline.addTo(map));
            const bounds = L.latLngBounds(polyline.map((p) => p.getBounds().getCenter()));
            if (bounds.isValid()) {
                map.fitBounds(bounds);
            }
            return () => {
                polyline.map((new_polyline) => new_polyline.removeFrom(map));
            };
        }
    }, [map, polyline]);

    useEffect(() => {
        map.setView(position);
    }, [map, position]);

    return null;
};

export default SearchCenterComponent;
