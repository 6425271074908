import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Card, Grid, Tabs, Tooltip, Typography } from '@mui/material';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import {
    AccidentTagsV2Details,
    CoachEventDetailsActionEnum,
    Damage,
    EventDetails,
    ExtendedAccident,
    PolicyV2,
} from '../../../backendsdk';
import { TabPanel, a11yProps } from '../../../components/Tab';
import { TrackedTab as Tab } from '../../../components/TrackedComponents';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
} from '../../../components/TrackedComponents';
import VideoComponent from '../../../components/video';
import useApi from '../../../hooks/api';
import useDevices from '../../../hooks/devices';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import videoNA from '../../../images/video_not_available.png';
import { isEventVideo } from '../../../utils/File';
import { getCustomer } from '../../../utils/customer';
import NewCoachingModal from '../Vehicles/NewCoachingModal';
import { STATUS } from '../Vehicles/VehicleModal';
import AccidentArtifactComponent from './AccidentArtifacts';
import AccidentCommentComponent from './AccidentComments';
import AccidentDamageForm from './AccidentDamageDetails';
import AccidentDamagePayment from './AccidentDamagePayment';
import AccidentDriverDetails, { EMPTY_DRIVER_DETAILS } from './AccidentDriverDetails';
import AccidentGeneralDetails from './AccidentGeneralDetails';
import AccidentModalHeader from './AccidentModalHeader';
import AccidentReminderComponent from './AccidentReminder';
import AccidentVideoRequests from './AccidentVideoRequests';
import AccidentWitnessForm from './AccidentWitnessDetails';
import ConfirmSaveDialog from './ConfirmSaveDialog';
import { validateAccidentDetails } from './Tagging/AccidentDetails';
import TaggingComponent from './Tagging/TaggingComponent';

interface AccidentModalProps {
    open: boolean;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    details: ExtendedAccident;
    setDetails: CallableFunction;
    eventDetails?: EventDetails;
    handleChange: CallableFunction;
    onSave: CallableFunction;
    sending: boolean;
    setSending: CallableFunction;
    refreshDetails: CallableFunction;
    detailsChanged: boolean;
    handlingStatusList: Array<string>;
    setHandlingStatusList: CallableFunction;
    setAlert: CallableFunction;
    allowUpdate: boolean;
    policies?: PolicyV2[];
    loadingPolicies?: boolean;
    setAccidents: CallableFunction;
}

export const EMPTY_ACCIDENT_TAGS: AccidentTagsV2Details = {
    source: [],
    not_an_accident: false,
    frame_number: '',
    fault: '',
    speed: '',
    clearly_visible: false,
    in_cabin_wellness: '',
    front_facing_wellness: '',
    confidence: '',
    damage_causes: [],
    primary_cause: '',
    points_of_impact: [],
    impacted_objects: [],
    missing_video_reason: 'did_not_search',
};

const AccidentModal: React.FC<AccidentModalProps> = (props: AccidentModalProps) => {
    const { t } = useTranslation();
    const { details } = props;
    const query = useQuery();
    const { agencyApi } = useApi();
    const [newFiles, setNewFiles] = useState<Array<File> | null>(null);
    const [dragAndDropHover, setDragAndDropHover] = useState<boolean>(false);
    const [newThirdParty, setNewThirdParty] = useState<boolean>(false);
    const [updateCallbacks, setUpdateCallbacks] = useState<Record<string, CallableFunction>>();
    const [validateCallbacks, setValidateCallbacks] = useState<Record<string, CallableFunction>>();
    const [editHandlingStatus, setEditHandlingStatus] = useState<boolean>(false);
    const [duplicateAccidentId, setDuplicateAccidentId] = useState<number>();
    const [openTagging, setOpenTagging] = useState<boolean>(false);
    const [accidentTags, setAccidentTags] = useState<AccidentTagsV2Details>(EMPTY_ACCIDENT_TAGS);
    const [tab, setTab] = useState<number>(!!details.event || !!query.get('event_id') ? 0 : 1);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [coachingModal, setCoachingModal] = useState<boolean>(false);
    const { profile } = useProfile();
    const { api } = useApi();
    const { devices } = useDevices();

    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    const { data: customer } = useReactQuery({ queryKey: ['customer'], queryFn: () => getCustomer(api) });
    const customerUsers = customer?.users || [];
    const isLoadingEvent = !!props.details.event && !props.eventDetails;

    useEffect(() => {
        props.setSending(false);
        agencyApi
            .agencyV1AccidentAccidentIdTagsV2Get({ accidentId: props.details.accident_id })
            .then((res) => {
                setAccidentTags(res.data.tag);
            })
            .catch(() => setAccidentTags({ ...EMPTY_ACCIDENT_TAGS }));
    }, []);

    useEffect(() => {
        const eventID = query.get('event_id');
        if (eventID) {
            handleChange('event', eventID);
        }
    }, [query.toString()]);

    useEffect(() => {
        if (props.eventDetails) {
            setIsSaved(props.eventDetails.coach.event != undefined);
        }
    }, [props.eventDetails]);

    const removeCallback = (setCallbacks: CallableFunction, key: string) => {
        setCallbacks((prev: Record<string, CallableFunction> | undefined) => {
            if (prev !== undefined && key in prev) {
                const updated = { ...prev };
                delete updated[key];
                return updated;
            } else {
                return prev;
            }
        });
    };

    useEffect(() => {
        if (props.eventDetails && details.accident_id === 0) {
            handleChange('license_plate', props.eventDetails.device.license_plate);
            handleChange('sub_fleet_name', props.eventDetails.device.customer_sub_fleet);
            handleChange('timestamp', props.eventDetails.timestamp);
        }
    }, [props.eventDetails]);

    useEffect(() => {
        if (!newThirdParty) {
            removeCallback(setUpdateCallbacks, 'damage-0');
            removeCallback(setValidateCallbacks, 'damage-0');
        }
    }, [newThirdParty]);

    const checkDuplicate = () => {
        agencyApi
            .agencyV1AccidentGet({
                timeFrom: dayjs.unix(details.timestamp).startOf('day').unix(),
                timeTo: dayjs.unix(details.timestamp).endOf('day').unix(),
                normLicensePlate: details.license_plate.replaceAll('-', '').toLocaleUpperCase(),
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setDuplicateAccidentId(res.data[0].accident_id);
                } else {
                    handleSave();
                }
            })
            .catch(() => handleSave());
    };

    const handleSave = (updatedDetails?: ExtendedAccident) => {
        const sectionsAreValid: Array<boolean> = [];
        if (validateCallbacks) {
            for (const func of Object.values(validateCallbacks)) {
                const res: boolean = func();
                sectionsAreValid.push(res);
            }
            if (!sectionsAreValid.every(Boolean)) {
                return Promise.resolve();
            }
        }

        props.setSending(true);
        const promises: Array<Promise<void>> = [];
        if (updateCallbacks) {
            for (const [key, func] of Object.entries(updateCallbacks)) {
                const res: Promise<void> = func().then(() => removeCallback(setUpdateCallbacks, key));
                promises.push(res);
            }
        }

        return Promise.allSettled(promises).then((results) => {
            if (results.some((result) => result.status === 'rejected')) {
                props.setAlert({ message: t('content.accidents.form.error'), type: 'error', duration: 6000 });
            }
            if (!props.handlingStatusList.includes(details.handling_status)) {
                props.setHandlingStatusList((prev: Array<string>) => [...prev, details.handling_status]);
            }
            setEditHandlingStatus(false);
            props.onSave(updatedDetails);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (target: string, value: any) => {
        props.handleChange((oldDetails: ExtendedAccident) => {
            if (oldDetails) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const newDetails: Record<string, any> = { ...oldDetails };
                newDetails[target] = value;
                return newDetails;
            }
        });
    };

    const textOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.name, event.target.value);
    };

    const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.onClose(e);
        setNewThirdParty(false);
    };

    const handleSaveForCoaching = (_status: STATUS, comment?: string) => {
        if (!!props.details.event) {
            setCoachingModal(false);
            api.apiV0EventEventIdCoachPost({
                eventId: props.details.event,
                coachEventDetails: {
                    action: CoachEventDetailsActionEnum.Add,
                    comment,
                },
            })
                .then((res) => {
                    const link = (
                        <Link id="view-session-link" component={RouterLink} to={`/coaching/${res.data.session_id}`}>
                            {t('content.safety.view_session')}
                        </Link>
                    );
                    props.setAlert({
                        message: t('content.safety.save_success'),
                        link,
                        type: 'success',
                        duration: 6000,
                    });
                    setIsSaved(true);
                })
                .catch(() => {
                    props.setAlert({ message: t('content.safety.save_fail'), type: 'error', duration: 6000 });
                });
        }
    };

    const conditionalTooltip = (component: React.ReactNode) => {
        if (details.accident_id === 0) {
            return (
                <Tooltip title={t('content.accidents.form.please_save')} followCursor>
                    <Box>{component}</Box>
                </Tooltip>
            );
        } else {
            return component;
        }
    };

    let videoUrl = '';
    if (props.eventDetails) {
        videoUrl = Object.entries(props.eventDetails.artifact_path)
            .map(([fileName, fileUrl]) => (isEventVideo(fileName) ? fileUrl : ''))
            .filter((e) => e != '')[0];
    }

    const isTaggingComplete =
        accidentTags.not_an_accident ||
        (validateAccidentDetails(accidentTags) &&
            accidentTags.damage_causes.length > 0 &&
            accidentTags.primary_cause !== '' &&
            accidentTags.points_of_impact.length > 0 &&
            accidentTags.impacted_objects.length > 0);
    const disableSavingMissingTags =
        profile.admin && props.details.accident_id != 0 && !!props.details.event && !isTaggingComplete;

    return (
        <Dialog id="confirm-save-dialog" fullWidth={true} maxWidth="xl" open={props.open} onClose={onClose}>
            {!!duplicateAccidentId ? (
                <ConfirmSaveDialog
                    isDialogOpen={!!duplicateAccidentId}
                    onClose={() => setDuplicateAccidentId(undefined)}
                    onConfirm={() => {
                        setDuplicateAccidentId(undefined);
                        handleSave();
                    }}
                    duplicateId={duplicateAccidentId}
                />
            ) : null}
            <Dialog id="save-for-coaching-dialog" open={coachingModal} onClose={() => setCoachingModal(false)}>
                <NewCoachingModal defaultDriver={details.driver?.driver_name || ''} onSave={handleSaveForCoaching}>
                    <Button
                        id="btn-cancel"
                        variant="outlined"
                        size="small"
                        onClick={() => setCoachingModal(false)}
                        sx={{ ml: 1 }}
                    >
                        {t('content.fleet.update.cancel')}
                    </Button>
                </NewCoachingModal>
            </Dialog>
            <Prompt when={props.detailsChanged} message={t('content.accidents.prompt_msg')} />
            <Box sx={{ backgroundColor: 'bgColor.main' }}>
                <AccidentModalHeader
                    details={details}
                    onClose={onClose}
                    checkDuplicate={checkDuplicate}
                    onSave={() => handleSave()}
                    sending={props.sending}
                    openTagging={() => setOpenTagging(true)}
                    disableSaving={disableSavingMissingTags}
                    policies={props.policies}
                    loadingPolicies={props.loadingPolicies}
                />
                <Box sx={{ p: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <Card sx={{ p: 1, mb: 1 }}>
                                <Box>
                                    <AccidentGeneralDetails
                                        handleChange={handleChange}
                                        textOnChange={textOnChange}
                                        details={details}
                                        editHandlingStatus={editHandlingStatus}
                                        setEditHandlingStatus={setEditHandlingStatus}
                                        handlingStatusList={props.handlingStatusList}
                                        setValidateCallbacks={setValidateCallbacks}
                                        customerUsers={customerUsers}
                                        allowUpdate={props.allowUpdate}
                                    />
                                </Box>
                            </Card>
                            <Card sx={{ p: 1, mb: 1 }}>
                                {conditionalTooltip(
                                    <AccidentDriverDetails
                                        accident_id={details.accident_id}
                                        details={details.driver ? details.driver : EMPTY_DRIVER_DETAILS}
                                        setUpdateCallbacks={setUpdateCallbacks}
                                        setValidateCallbacks={setValidateCallbacks}
                                        allowUpdate={props.allowUpdate}
                                    />,
                                )}
                            </Card>
                            {details.damage_list.map((damage: Damage, idx: number) => (
                                <Card sx={{ p: 1, mb: 1 }} key={damage.damage_id}>
                                    {conditionalTooltip(
                                        <AccidentDamageForm
                                            index={idx}
                                            key={idx}
                                            accident_id={details.accident_id}
                                            details={damage}
                                            setUpdateCallbacks={setUpdateCallbacks}
                                            setValidateCallbacks={setValidateCallbacks}
                                            allowUpdate={props.allowUpdate}
                                        >
                                            {idx === details.damage_list.length - 1 &&
                                            !newThirdParty &&
                                            props.allowUpdate ? (
                                                <Tooltip title={t('content.accidents.damage.add')}>
                                                    <Box>
                                                        <IconButton
                                                            id="btn-add-damage"
                                                            size="small"
                                                            onClick={() => setNewThirdParty(true)}
                                                            sx={{ position: 'absolute', bottom: 0, right: 0 }}
                                                        >
                                                            <AddCircleIcon fontSize="small" />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                            ) : null}
                                        </AccidentDamageForm>,
                                    )}
                                </Card>
                            ))}
                            {newThirdParty || details.damage_list.length === 0 ? (
                                <Card sx={{ p: 1, mb: 1 }}>
                                    {conditionalTooltip(
                                        <AccidentDamageForm
                                            index={details.damage_list.length}
                                            accident_id={details.accident_id}
                                            setNewThirdParty={setNewThirdParty}
                                            setUpdateCallbacks={setUpdateCallbacks}
                                            setValidateCallbacks={setValidateCallbacks}
                                            allowUpdate={props.allowUpdate}
                                        >
                                            {details.damage_list.length !== 0 ? (
                                                <IconButton
                                                    id="btn-cancel-add-damage"
                                                    size="small"
                                                    sx={{ position: 'absolute', bottom: 0, right: 0 }}
                                                >
                                                    <CancelIcon
                                                        fontSize="small"
                                                        onClick={() => setNewThirdParty(false)}
                                                    />
                                                </IconButton>
                                            ) : null}
                                        </AccidentDamageForm>,
                                    )}
                                </Card>
                            ) : null}
                            <Grid container columns={2} sx={{ maxHeight: '16rem' }} columnSpacing={1}>
                                <Grid item xs={1}>
                                    <Card sx={{ height: '100%' }}>
                                        {conditionalTooltip(
                                            <AccidentDamagePayment
                                                details={details}
                                                onSave={handleSave}
                                                setAlert={props.setAlert}
                                            />,
                                        )}
                                    </Card>
                                </Grid>
                                <Grid item xs={1}>
                                    <Card sx={{ height: '100%', p: 1 }}>
                                        {conditionalTooltip(
                                            <AccidentWitnessForm
                                                details={details.witness_list[0]}
                                                accidentDetails={details}
                                                setUpdateCallbacks={setUpdateCallbacks}
                                                allowUpdate={props.allowUpdate}
                                            />,
                                        )}
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Tabs
                                    value={tab}
                                    onChange={(_e, newValue) => setTab(newValue)}
                                    TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                                >
                                    <Tab
                                        label={
                                            <Typography fontSize={14}>{t('content.accidents.video.video')}</Typography>
                                        }
                                        {...a11yProps(0)}
                                        id="video-tab"
                                        data-testid={`video-tab`}
                                    />
                                    <Tab
                                        label={
                                            <Typography fontSize={14}>
                                                {t('content.accidents.video.video_requests')}
                                            </Typography>
                                        }
                                        {...a11yProps(1)}
                                        id="video-requests-tag"
                                        data-testid={`video-requests-tag`}
                                    />
                                </Tabs>
                                {isLoadingEvent ? null : (
                                    <Button
                                        id="btn-save-coaching"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSaved || !details.event}
                                        onClick={() => setCoachingModal(true)}
                                    >
                                        {isSaved ? t('content.events.coach.saved') : t('content.safety.save_event')}
                                    </Button>
                                )}
                            </Box>
                            <Box sx={{ mb: 1 }}>
                                <TabPanel value={tab} index={0}>
                                    {props.eventDetails != undefined &&
                                    details.event == props.eventDetails?.event_id ? (
                                        <VideoComponent event={props.eventDetails} video={videoUrl} />
                                    ) : (
                                        <img
                                            src={videoNA}
                                            style={{ width: '100%', aspectRatio: '8 / 3', display: 'block' }}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <Card
                                        sx={{
                                            width: '100%',
                                            aspectRatio: '8 / 3',
                                            borderTopLeftRadius: 0,
                                        }}
                                    >
                                        <AccidentVideoRequests
                                            accidentDetails={details}
                                            requests={details.command_list}
                                            onAssociate={(eventId: number) => {
                                                setTab(0);
                                                handleSave({ ...details, event: eventId });
                                            }}
                                            onClose={props.onClose}
                                            devices={devices}
                                            setAlert={props.setAlert}
                                        />
                                    </Card>
                                </TabPanel>
                            </Box>
                            <Card
                                onDragOver={(e: React.DragEvent) => {
                                    // Prevent default behavior (Prevent file from being opened)
                                    e.preventDefault();
                                    setDragAndDropHover(true);
                                }}
                                onDragEnter={() => {
                                    setDragAndDropHover(true);
                                }}
                                onDrop={(e: React.DragEvent) => {
                                    // Prevent default behavior (Prevent file from being opened)
                                    e.preventDefault();
                                    if (e.dataTransfer.files.length > 0) {
                                        setNewFiles(Array.from(e.dataTransfer.files));
                                    }
                                    setDragAndDropHover(false);
                                }}
                                onDragLeave={() => {
                                    setDragAndDropHover(false);
                                }}
                                onDragEnd={() => {
                                    setDragAndDropHover(false);
                                }}
                                onDragExit={() => {
                                    setDragAndDropHover(false);
                                }}
                                sx={{ mb: 1 }}
                            >
                                {dragAndDropHover ? (
                                    <Box
                                        sx={{
                                            p: 1,
                                            m: 1,
                                            height: '14rem',
                                            border: '2px dashed var(--gray-color)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FileUploadIcon color="primary" fontSize="large" />
                                        <Typography color="primary" variant="overline" display="block">
                                            {t('content.accidents.files.drop_here')}
                                        </Typography>
                                    </Box>
                                ) : (
                                    conditionalTooltip(
                                        <AccidentArtifactComponent
                                            details={details}
                                            onSave={handleSave}
                                            handleChange={handleChange}
                                            files={newFiles}
                                            setFiles={setNewFiles}
                                            setAlert={props.setAlert}
                                        />,
                                    )
                                )}
                            </Card>
                            <Card sx={{ mb: 1 }}>
                                {conditionalTooltip(
                                    <AccidentCommentComponent
                                        details={details}
                                        setDetails={props.setDetails}
                                        onSave={handleSave}
                                        customerUsers={customerUsers}
                                    />,
                                )}
                            </Card>
                            <Card>
                                <Box>
                                    {conditionalTooltip(
                                        <AccidentReminderComponent accidentDetails={details} users={customerUsers} />,
                                    )}
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {openTagging ? (
                <TaggingComponent
                    accidentTags={accidentTags}
                    setAccidentTags={setAccidentTags}
                    accidentId={props.details.accident_id}
                    eventId={props.details.event || null}
                    eventVideo={videoUrl}
                    setEventId={textOnChange}
                    onSave={handleSave}
                    onClose={() => setOpenTagging(false)}
                    eventLevel={props.eventDetails?.level}
                />
            ) : null}
        </Dialog>
    );
};

export default AccidentModal;
