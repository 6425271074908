import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Card, CircularProgress, MobileStepper, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CoachSession } from '../../../../backendsdk';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedLink as Link,
} from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import useProfile from '../../../../hooks/profile';
import Logo from '../../../../images/logo/logo_mini_black.svg';
import { getCustomer } from '../../../../utils/customer';
import CoachingEventSlide from './CoachingEventSlide';
import CoachingMetricsSlide from './CoachingMetricsSlide';
import DriverResponseSlide from './DriverResponseSlide';
import ManagerNotesSlide from './ManagerNotesSlide';
import MediaFileSlide from './MediaFileSlide';
import WellnessStatusSlide from './WellnessStatusSlide';

export interface SessionPresentationProps {
    sessionId: number;
    setAlert: CallableFunction;
    onComplete: CallableFunction;
}

const SessionPresentation: React.FC<SessionPresentationProps> = (props: SessionPresentationProps) => {
    const [details, setDetails] = useState<CoachSession>();
    const [activeStep, setActiveStep] = useState(0);
    const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
    const [isDiscardOpen, setIsDiscardOpen] = useState<boolean>(false);
    const [isUpdatingSession, setIsUpdatingSession] = useState<boolean>(false);
    const [isScrollable, setIsScrollable] = useState<boolean>(true);
    const [sessionCompleted, setSessionCompleted] = useState<boolean>(false);
    const history = useHistory();
    const { api } = useApi();
    const { profile } = useProfile();
    const theme = useTheme();
    const { t } = useTranslation();

    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    const { data: customer } = useQuery({ queryKey: ['customer'], queryFn: () => getCustomer(api) });

    const getCoachDetails = () => {
        api.apiV1CoachSessionIdGet({ sessionId: props.sessionId })
            .then((res) => {
                if (!!res.data.completed_at) {
                    props.setAlert({
                        message: t('content.coach.session_already_completed'),
                        type: 'error',
                        duration: 6000,
                    });
                    history.replace(`/coaching/${props.sessionId}`);
                } else {
                    setDetails({ ...res.data });
                }
            })
            .catch(() => {
                props.setAlert({ message: t('content.coach.session_not_found'), type: 'error', duration: 6000 });
                history.replace('/coaching');
            });
    };

    const updateSession = () => {
        if (details !== undefined) {
            setIsUpdatingSession(true);
            api.apiV1CoachSessionIdPost({
                sessionId: props.sessionId,
                updateCoachSession: {
                    ...details,
                    complete: true,
                },
            })
                .then(() => {
                    const updatedDetails = { ...details, completed_at: dayjs().unix(), is_saved: true };
                    props.onComplete(updatedDetails);
                    setIsConfirmOpen(false);
                    setSessionCompleted(true);
                })
                .catch(() =>
                    props.setAlert({
                        message: t('content.coach.errors.complete_session'),
                        type: 'error',
                        duration: 6000,
                    }),
                )
                .finally(() => setIsUpdatingSession(false));
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(getCoachDetails, []);

    const canGoBack = activeStep > 0;
    let canGoNext = false;
    let content = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    );
    if (details !== undefined && customer !== undefined) {
        canGoNext = activeStep < details.agenda.length + 1;
        let item = null;
        if (activeStep === 0 || activeStep === details.agenda.length + 1) {
            item = (
                <Box
                    data-testid={activeStep === 0 ? 'session-intro' : 'session-conclusion'}
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                    }}
                >
                    <img
                        src={customer?.logo_url || Logo}
                        alt="logo"
                        style={{ maxHeight: '100px', marginBottom: '8px' }}
                    />
                    <Typography variant="h5">{t('content.coach.presentation.header')}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        {activeStep === 0 ? (
                            <>
                                <Typography fontSize={18}>{dayjs().format(profile.dateFormat)}</Typography>
                                {!!details.driver_name && (
                                    <Typography fontSize={18} sx={{ fontWeight: 'bold' }}>
                                        {details.driver_name}
                                    </Typography>
                                )}
                                <Typography fontSize={18}>{`${
                                    !!details.sub_fleet_name ? `${details.sub_fleet_name}, ` : ''
                                }${profile.customer.name}`}</Typography>
                            </>
                        ) : (
                            <>
                                <Typography fontSize={18}>{t('content.coach.presentation.conclusion1')}</Typography>
                                <Typography fontSize={18}>{t('content.coach.presentation.conclusion2')}</Typography>
                            </>
                        )}
                    </Box>
                    {activeStep === details.agenda.length + 1 && (
                        <Button
                            id="btn-complete"
                            data-testid="btn-complete"
                            variant="contained"
                            color="secondary"
                            disabled={sessionCompleted}
                            onClick={() => setIsConfirmOpen(true)}
                        >
                            {t(`content.coach.details.${sessionCompleted ? 'session_completed' : 'complete_session'}`)}
                        </Button>
                    )}
                </Box>
            );
        } else {
            const currentItem = details.agenda[activeStep - 1];
            if (currentItem.type === 'event') {
                const currentEvent = details.events.find((event) => event.id === currentItem.id);
                if (currentEvent !== undefined) {
                    item = <CoachingEventSlide key={currentItem.id} event={currentEvent} />;
                }
            } else if (currentItem.type === 'notes') {
                const currentNotes = details.notes.find((note) => note.id === currentItem.id);
                if (currentNotes !== undefined) {
                    item = <ManagerNotesSlide key={currentItem.id} notes={currentNotes} />;
                }
            } else if (currentItem.type === 'response') {
                const currentResponse = details.response.find((response) => response.id === currentItem.id);
                if (currentResponse !== undefined) {
                    canGoNext = canGoNext && (currentResponse.text !== '' || !currentResponse.is_required);
                    item = (
                        <DriverResponseSlide
                            key={currentItem.id}
                            itemId={currentItem.id}
                            response={currentResponse}
                            setDetails={setDetails}
                            setIsScrollable={setIsScrollable}
                            disabled={sessionCompleted}
                        />
                    );
                }
            } else if (currentItem.type === 'metrics') {
                const currentMetrics = details.metrics.find((metric) => metric.id === currentItem.id);
                if (currentMetrics !== undefined) {
                    item = <CoachingMetricsSlide key={currentItem.id} metrics={currentMetrics} />;
                }
            } else if (currentItem.type === 'media_file') {
                const currentMediaFile = details.media_files.find((mediaFile) => mediaFile.id === currentItem.id);
                if (currentMediaFile !== undefined) {
                    item = <MediaFileSlide key={currentItem.id} mediaFile={currentMediaFile} />;
                }
            } else if (currentItem.type === 'wellness_status') {
                const currentWellness = details.wellness_statuses.find((wellness) => wellness.id === currentItem.id);
                if (currentWellness !== undefined) {
                    item = <WellnessStatusSlide key={currentItem.id} wellness={currentWellness} />;
                }
            }
        }
        content = (
            <>
                {item}
                <MobileStepper
                    data-testid="stepper"
                    variant="dots"
                    steps={details.agenda.length + 2}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width: '100%', p: 0, flexShrink: 0, minHeight: 0 }}
                    nextButton={
                        <Button
                            id="btn-next"
                            data-testid="btn-next"
                            size="small"
                            onClick={handleNext}
                            disabled={!canGoNext}
                        >
                            <Typography sx={{ lineHeight: 1 }} fontSize={14}>
                                {t('content.coach.presentation.next')}
                            </Typography>
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button
                            id="btn-back"
                            data-testid="btn-back"
                            size="small"
                            onClick={handleBack}
                            disabled={!canGoBack}
                        >
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            <Typography sx={{ lineHeight: 1 }} fontSize={14}>
                                {t('content.coach.presentation.back')}
                            </Typography>
                        </Button>
                    }
                />
            </>
        );
    }

    return (
        <Dialog
            id="session-presentation-dialog"
            open={true}
            fullScreen
            onKeyDown={(e: React.KeyboardEvent) => {
                const dirMap =
                    theme.direction === 'rtl'
                        ? { next: 'ArrowLeft', back: 'ArrowRight' }
                        : { next: 'ArrowRight', back: 'ArrowLeft' };
                if (isScrollable && !e.repeat) {
                    if (e.key === dirMap.back && canGoBack) {
                        e.preventDefault();
                        setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    } else if (e.key === dirMap.next && canGoNext) {
                        e.preventDefault();
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'bgColor.main',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Card
                    sx={{
                        width: '100%',
                        maxWidth: '700px',
                        height: '100%',
                        maxHeight: '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                    }}
                >
                    {isConfirmOpen ? (
                        <ConfirmDialog
                            isDialogOpen={isConfirmOpen}
                            headerText={t('content.coach.details.complete_session')}
                            headerIcon={<DoneIcon sx={{ mr: 0.5 }} />}
                            bodyText={t('content.coach.details.confirm_complete')}
                            buttonText={t('content.coach.complete')}
                            buttonColor="secondary"
                            onClose={() => setIsConfirmOpen(false)}
                            onConfirm={() => updateSession()}
                            isLoading={isUpdatingSession}
                        />
                    ) : null}
                    {isDiscardOpen ? (
                        <ConfirmDialog
                            isDialogOpen={isDiscardOpen}
                            headerText={t('content.coach.presentation.stop_header')}
                            bodyText={[
                                t('content.coach.presentation.stop_body1'),
                                t('content.coach.presentation.stop_body2'),
                            ]}
                            buttonText={t('content.coach.presentation.discard')}
                            buttonColor="redColor"
                            onClose={() => setIsDiscardOpen(false)}
                            onConfirm={() => history.replace(`/coaching/${props.sessionId}`)}
                            isLoading={isUpdatingSession}
                        />
                    ) : null}
                    {content}
                </Card>
                <Link
                    id="back-to-dashboard"
                    data-testid="back-to-dashboard"
                    component="button"
                    sx={{ mt: 1 }}
                    onClick={() => {
                        if (sessionCompleted) {
                            history.replace(`/coaching/${props.sessionId}`);
                        } else {
                            setIsDiscardOpen(true);
                        }
                    }}
                >
                    {t('content.coach.presentation.to_dashboard')}
                </Link>
            </Box>
        </Dialog>
    );
};

export default SessionPresentation;
