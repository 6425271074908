import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, Card, Checkbox, FormGroup, Grid, TextField, ThemeProvider, Typography } from '@mui/material';
import isEqual from 'lodash.isequal';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { UserRole } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import { unformatPhoneNumber } from '../../../utils/Str';
import { MuiSelect, MuiTextField, theme } from '../Accident/MuiFormControls';
import { PHONE_NUMBER_MASK_MAP } from '../UserSettings/UserSettings';
import { StyledFormControl } from './MuiStyled';
import { ALLOWED_ROLES } from './UsersComponent';
import { isValidPhoneNumber, validateRole, validateTextField } from './validation';

export interface NewUserModalProps {
    onAdd: CallableFunction;
    onClose: CallableFunction;
}

export interface NewUser {
    name: string;
    email: string;
    phoneNumber: string;
    subfleet?: string;
    role: UserRole | '';
}

interface ErrorMessage {
    field: string;
    message?: string;
}

const EMPTY_NEW_USER: NewUser = { name: '', email: '', phoneNumber: '', subfleet: undefined, role: '' };

const NewUserModal: React.FC<NewUserModalProps> = (props: NewUserModalProps) => {
    const [user, setUser] = useState<NewUser>(EMPTY_NEW_USER);
    const [activate, setActivate] = useState<boolean>(false);
    const [invite, setInvite] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
    const { profile } = useProfile();
    const { t } = useTranslation();

    const errorMap = Object.fromEntries(errorMessages.map((error) => [error.field, error.message]));

    const subFleets = profile.customer.sub_fleets;
    if (!subFleets.includes('')) {
        subFleets.splice(0, 0, '');
    }

    const validateFields = () => {
        const nameError = validateTextField(user.name);
        const emailError = validateTextField(user.email);
        const roleError = validateRole(user.role);
        const phoneNumberError =
            user.phoneNumber !== '' && !isValidPhoneNumber(user.phoneNumber) ? 'phone_number' : undefined;
        setErrorMessages([
            { field: 'name', message: nameError },
            { field: 'email', message: emailError },
            { field: 'role', message: roleError },
            { field: 'phoneNumber', message: phoneNumberError },
        ]);
        return ![nameError, emailError, roleError, phoneNumberError].some(Boolean);
    };

    useEffect(() => {
        if (!activate) {
            setInvite(false);
        }
    }, [activate]);

    useEffect(() => {
        if (invite) {
            setActivate(true);
        }
    }, [invite]);

    const isUserEmpty = useMemo(() => isEqual(user, EMPTY_NEW_USER), [user]);

    const handleClose = () => {
        if (!isUserEmpty) {
            if (window.confirm(t('content.accidents.prompt_msg'))) {
                props.onClose();
            }
        } else {
            props.onClose();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const phoneInput: any = (props: any) => {
        const inputElement = (
            <TextField
                id="new-user-phone-number"
                name="phone_number"
                size="small"
                fullWidth
                label={t('content.users.table.phone_number')}
                error={!!errorMap.phoneNumber}
                helperText={!!errorMap.phoneNumber ? t(`content.users.validation.${errorMap.phoneNumber}`) : undefined}
                inputProps={{ style: { direction: 'ltr' } }}
                sx={{ mb: 2 }}
                {...props}
            />
        );
        return inputElement;
    };

    return (
        <Dialog id="new-user-dialog" open={true} fullWidth={true} maxWidth="xs" onClose={handleClose}>
            <Box sx={{ backgroundColor: 'secondary.main', position: 'sticky', top: 0, p: 1, zIndex: 2 }}>
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <PersonAddIcon />
                    <Typography sx={{ ml: 0.5 }}>{t('content.users.add_user.header')}</Typography>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: 'bgColor.main', p: 1 }}>
                <Card sx={{ p: 2 }}>
                    <MuiTextField
                        id="new-user-name"
                        name="name"
                        label={t('content.users.table.name')}
                        value={user.name}
                        error={!!errorMap.name}
                        helperText={!!errorMap.name ? t(`content.users.validation.${errorMap.name}`) : undefined}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setUser((prev) => {
                                return { ...prev, name: e.target.value };
                            })
                        }
                        sx={{ mt: 1, mb: 2 }}
                    />
                    <MuiTextField
                        id="new-user-email"
                        name="email"
                        label={t('content.users.table.email')}
                        value={user.email}
                        error={!!errorMap.email}
                        helperText={!!errorMap.email ? t(`content.users.validation.${errorMap.email}`) : undefined}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setUser((prev) => {
                                return { ...prev, email: e.target.value };
                            })
                        }
                        sx={{ mb: 2 }}
                    />
                    <ThemeProvider theme={theme}>
                        <InputMask
                            mask={PHONE_NUMBER_MASK_MAP[profile.customer.settings.country]}
                            value={user.phoneNumber}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setUser((prev) => {
                                    return { ...prev, phoneNumber: e.target.value };
                                });
                            }}
                            maskChar=""
                        >
                            {phoneInput}
                        </InputMask>
                    </ThemeProvider>
                    <MuiSelect
                        id="new-user-subfleet"
                        name="subfleet"
                        label={t('content.users.table.subfleet')}
                        options={subFleets}
                        value={user.subfleet}
                        error={!!errorMap.subfleet}
                        helperText={
                            !!errorMap.subfleet ? t(`content.users.validation.${errorMap.subfleet}`) : undefined
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setUser((prev) => {
                                return { ...prev, subfleet: e.target.value };
                            })
                        }
                        sx={{ mb: 2 }}
                    />
                    <MuiSelect
                        id="new-user-role"
                        name="role"
                        label={t('content.users.table.role')}
                        options={ALLOWED_ROLES}
                        value={user.role}
                        error={!!errorMap.role}
                        helperText={!!errorMap.role ? t(`content.users.validation.${errorMap.role}`) : undefined}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setUser((prev) => {
                                return { ...prev, role: e.target.value as UserRole };
                            })
                        }
                        t={(s: string) => t(`content.users.roles.${s.toLowerCase().replaceAll(' ', '_')}`)}
                        sx={{ mb: 2 }}
                    />
                    <FormGroup>
                        <StyledFormControl
                            control={
                                <Checkbox
                                    id="activate-checkbox"
                                    size="small"
                                    checked={activate}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActivate(e.target.checked)}
                                    name="activate"
                                />
                            }
                            sx={{ height: '1.5rem' }}
                            label={t('content.users.add_user.activate')}
                        />
                        <StyledFormControl
                            control={
                                <Checkbox
                                    id="invite-checkbox"
                                    size="small"
                                    checked={invite}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInvite(e.target.checked)}
                                    name="invite"
                                />
                            }
                            label={t('content.users.add_user.invite')}
                            sx={{ height: '1.5rem' }}
                        />
                    </FormGroup>
                </Card>
                <Grid container columns={2} columnSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={1}>
                        <Button
                            id="add-user-btn"
                            fullWidth
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                if (validateFields()) {
                                    props.onAdd(
                                        { ...user, phoneNumber: unformatPhoneNumber(user.phoneNumber) },
                                        activate,
                                        invite,
                                    );
                                }
                            }}
                        >
                            {t('content.users.add_user.add')}
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            id="cancel-add-user-btn"
                            fullWidth
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                        >
                            {t('content.users.add_user.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default NewUserModal;
