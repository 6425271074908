import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface InstallerIdentificationProps {
    installerName: string;
    setInstallerName: React.Dispatch<React.SetStateAction<string>>;
    onNext: CallableFunction;
    dashboardLink: React.ReactNode;
}

const InstallerIdentification: React.FC<InstallerIdentificationProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h6" textAlign="center">
                    {t('setup_tool.installer_id.header')}
                </Typography>
                <TextField
                    required
                    fullWidth
                    value={props.installerName}
                    onChange={(e) => props.setInstallerName(e.target.value)}
                    id="installer-name-field"
                    data-testid="installer-name-field"
                    inputProps={{ 'data-testid': 'installer-name-input' }}
                    label={t('setup_tool.installer_id.installer_name')}
                    variant="outlined"
                    sx={{ my: 2 }}
                />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Box sx={{ width: '50%' }}>
                    <Button
                        id="btn-next"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={!props.installerName}
                        onClick={() => {
                            props.onNext();
                        }}
                    >
                        {t('next')}
                    </Button>
                </Box>
            </Box>
            {props.dashboardLink}
        </Box>
    );
};

export default InstallerIdentification;
