import { Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Descendant, createEditor } from 'slate';
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';

import { CoachManagerNotes } from '../../../../backendsdk';
import { Element, Leaf } from '../Agenda/RichTextField';

export interface ManagerNotesSlideProps {
    notes: CoachManagerNotes;
}

const ManagerNotesSlide: React.FC<ManagerNotesSlideProps> = ({ notes }: ManagerNotesSlideProps) => {
    const editor = useMemo(() => withReact(createEditor()), []);
    const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'auto',
                px: 4,
                py: 2,
            }}
        >
            <Slate data-testid="rich-text-field-slate" editor={editor} initialValue={notes.text as Descendant[]}>
                <Editable
                    id="rich-text-field-play"
                    data-testid="rich-text-field"
                    readOnly={true}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    className="rich-text-field"
                />
            </Slate>
        </Box>
    );
};

export default ManagerNotesSlide;
