import { Box, List, Skeleton, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MostCommonList } from '../../../../backendsdk';
import { TrackedListItemButton as ListItemButton } from '../../../../components/TrackedComponents';
import palette from '../../../ColorPalette';
import { b64EncodeUnicode } from '../../Accident/Accident';

interface OverviewTableProps {
    tableHeader: string;
    mostCommonList: MostCommonList[];
    isLoading: boolean;
}

const OverviewTable: React.FC<OverviewTableProps> = (props: OverviewTableProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const sortedData = useMemo(() => {
        return [...props.mostCommonList.sort((a, b) => b.count - a.count)];
    }, [props.mostCommonList]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    px: 1,
                    pb: 1,
                }}
            >
                <Typography fontSize={14} sx={{ fontWeight: 'bold' }}>
                    {t(`content.accident_overview.${props.tableHeader}`)}
                </Typography>
                <Typography fontSize={14} sx={{ fontWeight: 'bold' }}>
                    {t('content.accident_overview.accidents')}
                </Typography>
            </Box>
            <List disablePadding sx={{ width: '100%' }}>
                {props.isLoading
                    ? [...Array(10).keys()].map((key) => {
                          return (
                              <Box key={key} sx={{ p: 1 }}>
                                  <Skeleton variant="rectangular" height={21} />
                              </Box>
                          );
                      })
                    : sortedData.map((item, idx) => {
                          const query = new URLSearchParams();
                          query.set(
                              'filters',
                              b64EncodeUnicode(
                                  JSON.stringify({
                                      [props.tableHeader]: !!item.values
                                          ? item.values.sort((a) => (a == item.key ? 1 : -1)).map((s) => s.trim())
                                          : item.key.trim(),
                                  }),
                              ),
                          );
                          return (
                              <ListItemButton
                                  disableGutters
                                  id={`${props.tableHeader}-${idx}`}
                                  key={`${item.key}-${idx}`}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      p: 1,
                                      cursor: !!item.key ? 'pointer' : 'default',
                                  }}
                                  onClick={() => {
                                      if (!!item.key) {
                                          history.push(`/accidents?${query.toString()}`);
                                      }
                                  }}
                              >
                                  <Typography
                                      fontSize={14}
                                      sx={{ color: !!item.key ? undefined : palette.neutral[700] }}
                                  >
                                      {!!item.key ? item.key : t('content.fleet.unknown_driver')}
                                  </Typography>
                                  <Typography fontSize={14}>{item.count}</Typography>
                              </ListItemButton>
                          );
                      })}
            </List>
        </Box>
    );
};

export default OverviewTable;
