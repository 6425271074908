import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { Box, Divider, Tooltip } from '@mui/material';
import isHotkey from 'is-hotkey';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseEditor, Descendant, Editor, Element as SlateElement, Transforms, createEditor } from 'slate';
import { Editable, ReactEditor, Slate, useSlate, withReact } from 'slate-react';
import { RenderElementProps, RenderLeafProps } from 'slate-react/dist/components/editable';

import { TrackedButton as Button } from '../../../../components/TrackedComponents';
import { User, hasTextValue } from '../../../../utils/slate';
import { RTLDirectionContext } from '../../../Layout';

const ICONS: Record<string, React.ReactNode> = {
    format_bold: <FormatBoldIcon />,
    format_italic: <FormatItalicIcon />,
    format_underlined: <FormatUnderlinedIcon />,
    format_list_numbered: <FormatListNumberedIcon />,
    format_list_bulleted: <FormatListBulletedIcon />,
    format_align_left: <FormatAlignLeftIcon />,
    format_align_center: <FormatAlignCenterIcon />,
    format_align_right: <FormatAlignRightIcon />,
    format_align_justify: <FormatAlignJustifyIcon />,
};

const HOTKEYS: Record<string, TextFormat> = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
};

export type CustomText = { text: string; bold?: true; italic?: true; underline?: true };
export type CustomElement = {
    type: string;
    user?: User;
    align?: AlignSetting;
    children: (CustomText | CustomElement)[];
};

declare module 'slate' {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor;
        Element: CustomElement;
        Text: CustomText;
    }
}

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
type BlockFormat = 'numbered-list' | 'bulleted-list' | 'left' | 'center' | 'right' | 'justify';
type TextFormat = 'bold' | 'italic' | 'underline';

interface RichTextFieldProps {
    initialValue: Descendant[];
    setValue: CallableFunction;
    setHasTextValue: CallableFunction;
    disabled: boolean;
}

const RichTextField: React.FC<RichTextFieldProps> = (props: RichTextFieldProps) => {
    const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
    const editor = useMemo(() => withReact(createEditor()), []);
    const isRTL = useContext(RTLDirectionContext);

    return (
        <Slate
            data-testid="rich-text-field-slate"
            editor={editor}
            initialValue={props.initialValue}
            onChange={(value) => {
                props.setValue(value);
                props.setHasTextValue(hasTextValue(value));
            }}
        >
            <Box sx={{ display: 'flex', my: 0.5, flexWrap: 'wrap' }}>
                <MarkButton format="bold" icon="format_bold" disabled={props.disabled} />
                <MarkButton format="italic" icon="format_italic" disabled={props.disabled} />
                <MarkButton format="underline" icon="format_underlined" disabled={props.disabled} />
                <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
                <BlockButton format="numbered-list" icon="format_list_numbered" disabled={props.disabled} />
                <BlockButton format="bulleted-list" icon="format_list_bulleted" disabled={props.disabled} />
                <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
                <Box sx={{ display: 'flex', direction: isRTL ? 'rtl' : 'ltr' }}>
                    <BlockButton format="left" icon="format_align_left" disabled={props.disabled} />
                    <BlockButton format="center" icon="format_align_center" disabled={props.disabled} />
                    <BlockButton format="right" icon="format_align_right" disabled={props.disabled} />
                    <BlockButton format="justify" icon="format_align_justify" disabled={props.disabled} />
                </Box>
            </Box>
            <Editable
                id="rich-text-field"
                data-testid="rich-text-field"
                readOnly={props.disabled}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                onKeyDown={(event) => {
                    for (const hotkey in HOTKEYS) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        if (isHotkey(hotkey, event as any)) {
                            event.preventDefault();
                            const mark = HOTKEYS[hotkey];
                            toggleMark(editor, mark);
                        }
                    }
                }}
                className={`rich-text-field rich-text-field-${props.disabled ? 'disabled' : 'enabled'}`}
            />
        </Slate>
    );
};

const toggleBlock = (editor: Editor, format: BlockFormat) => {
    const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    });
    let newProperties: Partial<SlateElement>;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : (format as AlignSetting),
        };
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        };
    }
    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor: Editor, format: TextFormat) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isBlockActive = (editor: Editor, format: BlockFormat, blockType: 'type' | 'align' = 'type') => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
        }),
    );

    return !!match;
};

const isMarkActive = (editor: Editor, format: TextFormat) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const Element = (props: RenderElementProps) => {
    const { attributes, children, element } = props;
    const style = { textAlign: element.align };
    switch (element.type) {
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            );
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            );
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            );
    }
};

export const Leaf = (props: RenderLeafProps) => {
    const { attributes, leaf } = props;
    let { children } = props;
    if (leaf.bold) {
        children = <span style={{ fontWeight: 'bold' }}>{children}</span>;
    }

    if (leaf.italic) {
        children = <span style={{ fontStyle: 'italic' }}>{children}</span>;
    }

    if (leaf.underline) {
        children = <span style={{ textDecoration: 'underline' }}>{children}</span>;
    }

    return <span {...attributes}>{children}</span>;
};

interface BlockButtonProps {
    format: BlockFormat;
    icon: string;
    disabled: boolean;
}

const BlockButton = (props: BlockButtonProps) => {
    const { format, icon } = props;
    const editor = useSlate();
    const { t } = useTranslation();
    const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
    const button = (
        <Button
            id={`${format}-btn`}
            data-testid={`${format}-btn`}
            variant={isActive ? 'contained' : 'outlined'}
            disableElevation
            color="primary"
            size="small"
            onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
            disabled={props.disabled}
            sx={{
                borderRadius: '2px',
                border: 'none',
                width: '34px',
                minWidth: '34px',
                height: '34px',
                '&:hover': { border: 'none' },
            }}
        >
            {ICONS[icon]}
        </Button>
    );
    return props.disabled ? (
        button
    ) : (
        <Tooltip arrow title={t(`content.coach.add_item.notes.editor_buttons.${format}`)}>
            <Box>{button}</Box>
        </Tooltip>
    );
};

interface MarkButtonProps {
    format: TextFormat;
    icon: string;
    disabled: boolean;
}

const MarkButton = (props: MarkButtonProps) => {
    const { format, icon } = props;
    const editor = useSlate();
    const { t } = useTranslation();
    const isActive = isMarkActive(editor, format);
    const button = (
        <Button
            id={`${format}-btn`}
            data-testid={`${format}-btn`}
            variant={isActive ? 'contained' : 'outlined'}
            disableElevation
            color="primary"
            size="small"
            onMouseDown={(event) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
            disabled={props.disabled}
            sx={{
                borderRadius: '2px',
                border: 'none',
                width: '34px',
                minWidth: '34px',
                height: '34px',
                '&:hover': { border: 'none' },
            }}
        >
            {ICONS[icon]}
        </Button>
    );
    return props.disabled ? (
        button
    ) : (
        <Tooltip arrow title={t(`content.coach.add_item.notes.editor_buttons.${format}`)}>
            <Box>{button}</Box>
        </Tooltip>
    );
};

export default RichTextField;
