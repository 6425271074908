import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Box, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import HelpComponent from '../components/Help/HelpComponent';
import useApi from '../hooks/api';
import useProfile from '../hooks/profile';
import { useQuery } from '../hooks/query';
import { pagesInSections } from '../utils/Pages';
import { getStorageItem, setStorageItem } from '../utils/Storage';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from '../utils/TimeFormatter';
import LoginComponent from './Login/LoginComponent';
import theme from './MuiTheme';
import NPSComponent from './NPS';
import Navigator, { RTL_LANGUAGES } from './Navigator/Navigator';
import Content from './content/Content';

mixpanel.init('a80dbe55045dd69747c4b3a48752a34c', { track_pageview: 'url-with-path' });

export const DEFAULT_TITLE = 'OpenEyes';

export const REFRESH_TOKEN_NAME = 'OERefresh';

export const SDiv = styled.div`
    display: flex;
    overflow-y: auto;
    height: 100%;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const SVertDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const isRTL = getStorageItem('OERTL') === 'true';

export const RTLDirectionContext = React.createContext(isRTL);
export const SAFETY_MANAGER_ROLE = 'safety_manager';
export const CLIP_WATCHER_ROLE = 'clip_watcher';
export const DEFAULT_ACCIDENT_ASSIGNEE_ROLE = 'default_accident_assignee';
export const SAFETY_MANAGER_DELETE_FILES_ROLE = 'safety_manager_delete_files';

const Footer = () => {
    // placeholder
    return null;
};
const cacheDefault = createCache({ key: 'default' });

const Layout: React.FC = () => {
    const [rtlDirection, setRTLDirection] = useState<boolean>(isRTL);
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const { i18n } = useTranslation();
    const { api, isLoading, isAuthenticated, setToken, setRefreshToken } = useApi();
    const { profile, setProfile, resetProfile } = useProfile();
    const queryClient = useQueryClient();

    useEffect(() => {
        document.dir = rtlDirection ? 'rtl' : 'ltr';
    }, [rtlDirection]);

    useEffect(() => {
        window.addEventListener('storage', () => {
            if (localStorage.getItem(REFRESH_TOKEN_NAME) === null) {
                resetProfile();
                setRefreshToken(undefined);
                setToken(undefined);
            }
        });
    }, []);

    useEffect(() => {
        if (i18n.languages.length > 0) {
            const isRTL = RTL_LANGUAGES.includes(i18n.languages[0]);
            setRTLDirection(isRTL);
            setStorageItem('OERTL', isRTL.toString(), 365 * 24);
        }
    }, [i18n.languages]);

    useEffect(() => {
        if (isAuthenticated) {
            updateCustomer();
            const next = query.get('next');
            if (next) {
                try {
                    history.replace(next);
                } catch (e) {
                    history.replace('/');
                }
            }
        }
    }, [isAuthenticated]);

    const updateCustomer = () => {
        api.apiV1UserGet()
            .then((res) => {
                setProfile(res.data);
                let user: string = res.data.username;
                if (user.endsWith('-oe')) {
                    user = 'OpenEyes Admin';
                } else if (user.endsWith('-algo')) {
                    user = 'OpenEyes Algo';
                }
                Sentry.setTag('customer', res.data.customer.name);
                Sentry.setTag('username', res.data.username);
                mixpanel.identify(user);
                mixpanel.people.set({
                    $name: res.data.name,
                    $email: res.data.email,
                    customer: res.data.customer.name,
                    sub_fleet: res.data.sub_fleet,
                    role: res.data.role,
                });
                i18n.changeLanguage(res.data.language);
            })
            .catch(() => {
                setToken(undefined);
            });
    };

    const handleLogout = useCallback(() => {
        resetProfile();
        setRefreshToken(undefined);
        setToken(undefined);
        queryClient.clear();
        history.replace('/login');
    }, []);

    const directionTheme = createTheme({ ...theme, direction: rtlDirection ? 'rtl' : 'ltr' });
    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });

    if (isLoading || (isAuthenticated && profile.username === '')) {
        return (
            <Box
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const next = location.pathname + location.search;

    return isAuthenticated ? (
        <RTLDirectionContext.Provider value={rtlDirection}>
            <SDiv>
                <ThemeProvider theme={directionTheme}>
                    <CacheProvider value={rtlDirection ? cacheRtl : cacheDefault}>
                        <Navigator pagesInSections={pagesInSections} onLogout={handleLogout} />
                        <Content />
                        <Box sx={{ position: 'absolute', bottom: 20, right: 20 }}>
                            <HelpComponent />
                        </Box>
                        <AutoLogout logout={handleLogout} />
                        <NPSComponent />
                    </CacheProvider>
                </ThemeProvider>
            </SDiv>
            <Footer />
        </RTLDirectionContext.Provider>
    ) : (
        <RTLDirectionContext.Provider value={rtlDirection}>
            <SVertDiv style={{ direction: rtlDirection ? 'rtl' : 'ltr' }}>
                <Switch>
                    <Route path="/login">
                        <ThemeProvider theme={directionTheme}>
                            <CacheProvider value={rtlDirection ? cacheRtl : cacheDefault}>
                                <LoginComponent />
                            </CacheProvider>
                        </ThemeProvider>
                    </Route>
                    <Route path="*">
                        <Redirect to={`/login?next=${encodeURIComponent(next)}`} />
                    </Route>
                </Switch>
            </SVertDiv>
        </RTLDirectionContext.Provider>
    );
};

export default Layout;

interface AutoLogoutProps {
    logout: CallableFunction;
}

export const LAST_INTERACTION_KEY = 'OESessionLastInteraction';

export const AutoLogout: React.FC<AutoLogoutProps> = (props: AutoLogoutProps) => {
    const [lastInteraction, setLastInteraction] = useState<number>(Date.now());
    const { profile } = useProfile();
    const timeout = profile.customer.settings.auto_logout_after_minutes;

    useEffect(() => {
        const updateInteractionDate = () => {
            const newValue = Date.now();
            setLastInteraction(newValue);
            localStorage.setItem(LAST_INTERACTION_KEY, newValue.toString());
        };
        document.addEventListener('click', updateInteractionDate);
        return () => document.removeEventListener('click', updateInteractionDate);
    }, []);

    useEffect(() => {
        if (timeout) {
            const timerId = setTimeout(() => {
                const lastInteractionFromStorage = localStorage.getItem(LAST_INTERACTION_KEY);
                if (lastInteractionFromStorage !== null && lastInteraction.toString() !== lastInteractionFromStorage) {
                    setLastInteraction(parseInt(lastInteractionFromStorage));
                } else {
                    props.logout();
                }
            }, timeout * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND - (Date.now() - lastInteraction));
            return () => {
                clearInterval(timerId);
            };
        }
    }, [lastInteraction, props.logout, timeout]);

    return null;
};
