import { Box, Card, CardActions, CardContent, CardMedia, Grid, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FleetDriver } from '../../../backendsdk';
import { TrackedLink as Link } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import { INVALID_SCORE } from '../Metrics/SafetyWellness/MetricsComponent';
import { CardField, CardScoreField, NUMBER_ICONS } from '../Vehicles/VehicleCard';

export interface CardActionItem {
    label: string;
    value: string;
}

export interface WellnessCardProps {
    timestamp: number;
    driverName: string;
    licensePlate: string;
    subFleet: string;
    score: number;
    behaviors: string[];
    eventIds: number[];
    icStatusIds: number[];
    ffStatusIds: number[];
    mostRecentEventTimestamp: number | null;
    mostRecentEventId: number | null;
    image?: string;
    setDefaultTab?: CallableFunction;
    driver?: FleetDriver;
}

export const CARD_WIDTH = 300;
export const CARD_HEIGHT = 330;
export const CARD_MARGIN = 5;
const IMAGE_ASPECT_RATIO = 0.375;
const SCORE_COLORS = [palette.score.red, palette.score.yellow, palette.score.green];

// returns one of the three score colors based on the score
export const getScoreColor = (score: number) => {
    return SCORE_COLORS[Math.min(Math.floor(score / 33.4), 2)];
};

const WellnessCard: React.FC<WellnessCardProps> = (props: WellnessCardProps) => {
    const { t } = useTranslation();
    const { profile } = useProfile();
    const history = useHistory();

    const icon = (
        <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
            {
                NUMBER_ICONS[
                    Math.min(props.eventIds.length + props.icStatusIds.length + props.ffStatusIds.length, 10) - 1
                ]
            }
        </Box>
    );

    return (
        <Card
            sx={{
                width: CARD_WIDTH,
                height: CARD_HEIGHT,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                m: `${CARD_MARGIN}px`,
            }}
        >
            <CardContent sx={{ p: 1, pb: 0 }}>
                <Grid container columns={2}>
                    <Grid item xs={1} sx={{ p: 0.5 }}>
                        <CardField
                            name={t('table.headers.driver_name')}
                            value={props.driverName}
                            containerStyle={{ mb: 1 }}
                        />
                        <CardField
                            name={t('table.headers.license_plate')}
                            value={props.licensePlate}
                            containerStyle={{ mb: 1 }}
                        />
                        <CardField name={t('table.headers.sub_fleet')} value={props.subFleet} />
                    </Grid>
                    <Grid item xs={1}>
                        <Card sx={{ backgroundColor: palette.bgColor, p: 0.5 }}>
                            <CardScoreField
                                name={t('content.wellness.score')}
                                score={props.score}
                                licensePlate={props.licensePlate}
                                inactive={props.score === INVALID_SCORE}
                                setDefaultTab={props.setDefaultTab}
                                scoreType="wellness"
                            />
                            <CardField
                                name={t('content.wellness.behaviors.header')}
                                value={
                                    props.behaviors.length > 0
                                        ? props.behaviors.map((behavior) => t(behavior))
                                        : t('content.wellness.none')
                                }
                                containerStyle={{ mt: 1 }}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 'auto' }}>
                {!!props.mostRecentEventTimestamp ? (
                    <CardField
                        name={t('content.wellness.most_recent')}
                        value={dayjs.unix(props.mostRecentEventTimestamp).format(profile.dateTimeFormat)}
                        containerStyle={{ alignItems: 'center' }}
                        valueStyle={{ whiteSpace: 'nowrap' }}
                    />
                ) : null}
                <Box
                    onClick={() => {
                        if (!!props.mostRecentEventId) {
                            if (props.setDefaultTab) {
                                props.setDefaultTab(0);
                            }
                            history.push(`/wellness/${encodeURIComponent(props.licensePlate)}`);
                        }
                    }}
                    sx={{ position: 'relative', cursor: !!props.mostRecentEventId ? 'pointer' : 'default' }}
                >
                    {icon}
                    {props.image !== undefined ? (
                        <CardMedia
                            component="img"
                            image={props.image}
                            sx={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
                        />
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={CARD_WIDTH}
                            height={CARD_WIDTH * IMAGE_ASPECT_RATIO}
                        />
                    )}
                </Box>
                <CardActions sx={{ display: 'flex', justifyContent: 'center', height: '32px', flexShrink: 0 }}>
                    {!!props.mostRecentEventId ? (
                        <Link
                            id={`view-events-${props.licensePlate}-btn`}
                            component="button"
                            onClick={() => {
                                if (props.setDefaultTab) {
                                    props.setDefaultTab(0);
                                }
                                history.push(`/wellness/${encodeURIComponent(props.licensePlate)}`);
                            }}
                        >
                            {t('content.safety.view_events')}
                        </Link>
                    ) : (
                        <Link
                            id="no-events-link"
                            component="button"
                            disabled
                            sx={{ textDecoration: 'none', cursor: 'default' }}
                        >
                            {t('content.safety.no_events_card')}
                        </Link>
                    )}
                </CardActions>
            </Box>
        </Card>
    );
};

export default WellnessCard;
