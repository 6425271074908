import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EventIcon from '@mui/icons-material/Event';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import { Box, ListItemButton, Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

import { ExtendedCustomerDataAllOfUsers, MalfunctionTypeEnum } from '../../../backendsdk';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import { MalfunctionRow } from './MalfunctionsComponent';
import { MALFUNCTION_ICONS } from './MalfunctionsOverview';

interface ListRowData {
    rows: MalfunctionRow[];
    usersById: Record<number, ExtendedCustomerDataAllOfUsers>;
    selectionModel: GridRowSelectionModel;
    setSelectionModel: CallableFunction;
    isSelectMode: boolean;
    setIsSelectMode: CallableFunction;
}

const MalfunctionListRow: React.FC<ListChildComponentProps<ListRowData>> = (props) => {
    const history = useHistory();
    const { profile } = useProfile();
    const { t } = useTranslation();
    const { index, style } = props;
    const row = props.data.rows[index];

    useEffect(() => {
        if (props.data.selectionModel.length === 0) {
            props.data.setIsSelectMode(false);
        }
    }, [props.data.selectionModel]);

    if (!row) {
        return null;
    }

    const hasComments = row.comment_count > 0;
    const malfunctionIcon = MALFUNCTION_ICONS[row.malfunction as MalfunctionTypeEnum];
    let assigneeName = t('content.malfunctions.unassigned');
    if (!!row.assignee && row.assignee in props.data.usersById) {
        assigneeName = props.data.usersById[row.assignee].name;
    }

    return (
        <ListItemButton
            key={row.id}
            onClick={() => {
                if (props.data.isSelectMode) {
                    props.data.setSelectionModel((prev: GridRowSelectionModel) => {
                        if (prev.includes(row.id)) {
                            return prev.filter((id) => id !== row.id);
                        }
                        return [...prev, row.id];
                    });
                } else {
                    props.data.setSelectionModel([row.id]);
                    history.push(`/malfunctions/${row.id}`);
                }
            }}
            onContextMenu={(e) => {
                e.preventDefault();
                if (props.data.isSelectMode) {
                    return;
                }
                props.data.setSelectionModel([row.id]);
                props.data.setIsSelectMode(true);
            }}
            selected={props.data.selectionModel.includes(row.id)}
            style={style}
            sx={{
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: 'lightGrayColor.main',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                position: 'relative',
                py: 1.5,
                direction: 'ltr',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {malfunctionIcon}
                <Typography sx={{ ml: 0.5 }}>
                    {t(`content.malfunctions.table.type.${row.malfunction.toLocaleLowerCase()}`)}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.75 }}>
                <LocalShippingIcon color="primary" />
                <Typography sx={{ ml: 0.5 }}>{`${row.license_plate}${
                    !!row.sub_fleet ? ` (${row.sub_fleet})` : ''
                }`}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.75 }}>
                <PersonIcon color="primary" />
                <Typography sx={{ color: !!row.assignee ? 'inherit' : palette.neutral[500], ml: 0.5 }}>
                    {assigneeName}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.75 }}>
                <EventIcon color="primary" />
                <Typography sx={{ ml: 0.5 }}>{dayjs(row.last_updated).format(profile.dateTimeFormat)}</Typography>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    bottom: 0,
                    right: 16,
                }}
            >
                <Box
                    id={`malfunction-${row.id}-comments`}
                    data-testid={`malfunction-${row.id}-comments`}
                    data-value={row.comment_count}
                    sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
                >
                    <ChatBubbleIcon
                        sx={{
                            fontSize: 28,
                            color: hasComments ? palette.secondary : palette.neutral[200],
                        }}
                    />
                    {!!row.comment_count ? (
                        <Box sx={{ position: 'absolute', top: 2.5, left: 3, width: 70 / 3 }}>
                            <Typography fontSize={12} textAlign="center" fontWeight={500}>
                                {row.comment_count}
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
            </Box>
        </ListItemButton>
    );
};

export default MalfunctionListRow;
