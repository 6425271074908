import { Box, FormControl, TextField, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { TrackedMenuItem as MenuItem } from '../../../components/TrackedComponents';

interface MuiSelectProps {
    id: string;
    label: string;
    value?: string;
    options: Array<string>;
    onChange: CallableFunction;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    name?: string;
    t?: CallableFunction;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
}

export const theme = createTheme({
    typography: {
        fontSize: 12,
    },
});

export const MuiSelect: React.FC<MuiSelectProps> = (props: MuiSelectProps) => {
    return (
        <ThemeProvider theme={theme}>
            <TextField
                id={props.id}
                inputProps={{
                    'data-testid': props.id,
                }}
                label={props.label}
                variant="outlined"
                select
                size="small"
                fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
                disabled={props.disabled}
                error={props.error}
                helperText={props.helperText}
                defaultValue=""
                value={props.value}
                name={props.name}
                onChange={(e) => props.onChange(e)}
                sx={props.sx}
            >
                {props.options.map((option) => (
                    <MenuItem key={option} value={option} id={option}>
                        {props.t ? props.t(option) : option}
                    </MenuItem>
                ))}
            </TextField>
        </ThemeProvider>
    );
};

interface MuiSTextFieldProps {
    id: string;
    name: string;
    label?: string;
    placeholder?: string;
    value?: string | number;
    onChange?: CallableFunction;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    type?: string;
    multiline?: boolean;
    rows?: number;
    maxRows?: number;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
}

export const MuiTextField: React.FC<MuiSTextFieldProps> = (props: MuiSTextFieldProps) => {
    return (
        <ThemeProvider theme={theme}>
            <TextField
                id={props.id}
                label={props.label}
                placeholder={props.placeholder}
                variant="outlined"
                type={props.type ? props.type : 'text'}
                size="small"
                fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
                disabled={props.disabled}
                error={props.error}
                helperText={props.helperText}
                multiline={props.multiline ? props.multiline : false}
                rows={props.rows}
                maxRows={props.maxRows}
                value={props.value}
                name={props.name}
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(e);
                    }
                }}
                InputLabelProps={
                    props.type !== 'text'
                        ? {
                              shrink: true,
                          }
                        : undefined
                }
                sx={props.sx}
            />
        </ThemeProvider>
    );
};

interface MuiSTextFieldPropsUncontrolled {
    id: string;
    name: string;
    label: string;
    inputRef: React.Ref<HTMLInputElement>;
    disabled?: boolean;
    type?: string;
    multiline?: boolean;
    rows?: number;
    maxRows?: number;
    sx?: SxProps<Theme>;
}

export const MuiTextFieldUncontrolled: React.FC<MuiSTextFieldPropsUncontrolled> = (
    props: MuiSTextFieldPropsUncontrolled,
) => {
    return (
        <ThemeProvider theme={theme}>
            <TextField
                id={props.id}
                inputRef={props.inputRef}
                label={props.label}
                variant="outlined"
                type={props.type ? props.type : 'text'}
                size="small"
                fullWidth
                disabled={props.disabled}
                multiline={props.multiline ? props.multiline : false}
                rows={props.rows}
                maxRows={props.maxRows}
                name={props.name}
                InputLabelProps={
                    props.type !== 'text'
                        ? {
                              shrink: true,
                          }
                        : undefined
                }
                sx={props.sx}
            />
        </ThemeProvider>
    );
};

interface MuiRadioGroupProps {
    id: string;
    label?: string;
    value: string;
    options: Array<string>;
    icons?: Record<string, React.ReactNode>;
    onChange: CallableFunction;
    disabled?: boolean;
    row?: boolean;
    name?: string;
    t?: CallableFunction;
    sx?: SxProps<Theme>;
}

export const MuiRadioGroup: React.FC<MuiRadioGroupProps> = (props: MuiRadioGroupProps) => {
    return (
        <ThemeProvider theme={theme}>
            <FormControl sx={props.sx}>
                <RadioGroup
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    onChange={(e) => props.onChange(e)}
                    row={props.row}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: props.row ? 'row' : 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    {props.options.map((option) => {
                        const labelText = props.t ? props.t(option) : option;
                        return (
                            <FormControlLabel
                                key={`${props.id}-${option}`}
                                value={option}
                                control={
                                    <Radio
                                        size="small"
                                        disableRipple
                                        id={`${props.id}-${option}`}
                                        disabled={props.disabled}
                                        sx={{ p: 0, pr: 0.5, ml: 0.5 }}
                                    />
                                }
                                label={
                                    props.icons ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 0.25 }}>
                                            <Typography sx={{ mr: 0.5 }}>{labelText}</Typography>
                                            {props.icons[option]}
                                        </Box>
                                    ) : (
                                        <Typography>{labelText}</Typography>
                                    )
                                }
                            />
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </ThemeProvider>
    );
};
