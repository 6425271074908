import { Box, Card, Grid, Typography } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { CoachEventDetailsActionEnum, EventDetails } from '../../../backendsdk';
import MapContent from '../../../components/Map';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedLink as Link,
} from '../../../components/TrackedComponents';
import VideoComponent from '../../../components/video';
import useApi from '../../../hooks/api';
import { ExtendedFleetDriver } from '../../../hooks/drivers';
import useProfile from '../../../hooks/profile';
import mapNA from '../../../images/map_not_available.png';
import { isEventVideo } from '../../../utils/File';
import { accidentsPageDefs, flattenRoles } from '../../../utils/Pages';
import { isLocationValid } from '../../../utils/location';
import palette from '../../ColorPalette';
import { EVENT_TYPE_MAP } from '../Event/Defs';
import EventActionsComponent from '../Event/EventActions';
import { eventToMarker } from '../EventMarkers';
import NewCoachingModal from './NewCoachingModal';
import { CardField } from './VehicleCard';
import { STATUS } from './VehicleModal';

interface GpsTrace {
    timestamp: number;
    lat: number;
    lng: number;
    speed: number;
}

interface VehicleModalDetailsProps {
    details: EventDetails;
    setDetails: CallableFunction;
    accidentEventIds: number[];
    eventStatuses: Record<number, STATUS>;
    handleStatusChange: CallableFunction;
    setSelectedEvent: CallableFunction;
    metadata?: string;
    setAlert: CallableFunction;
    updateTripDetails?: CallableFunction;
    getDriver: (token: number | null | undefined) => ExtendedFleetDriver | undefined;
}

const VehicleModalDetails: React.FC<VehicleModalDetailsProps> = (props: VehicleModalDetailsProps) => {
    const [gpsTrace, setGpsTrace] = useState<Array<GpsTrace>>([]);
    const [gpsRoute, setGpsRoute] = useState<Array<[string, number, CallableFunction]>>([]);
    const [currentLocation, setCurrentLocation] = useState<string>();
    const [coachingModal, setCoachingModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const { api } = useApi();
    const { profile } = useProfile();
    const canViewAccident = flattenRoles(accidentsPageDefs).includes(profile.role);

    const driver = props.getDriver(props.details.trip?.driver);
    const driverName = (
        driver?.fullName ||
        props.details.device.driver_name ||
        t('content.fleet.unknown_driver')
    )?.toString();

    const isAccident = props.accidentEventIds.includes(props.details.event_id);
    let accidentLink;
    if (isAccident && canViewAccident && !!props.metadata) {
        try {
            const metadata = JSON.parse(props.metadata);
            const accidentEventsMap = metadata['event_id_to_accident_id_map'];
            const key = props.details.event_id.toString();
            if (!!accidentEventsMap && !!accidentEventsMap[key]) {
                accidentLink = (
                    <Link
                        id="accident-link"
                        data-testid="accident-link"
                        component={RouterLink}
                        to={`/accidents/${accidentEventsMap[key]}`}
                    >
                        <Typography fontSize={14}>{t('content.safety.view_accident')}</Typography>
                    </Link>
                );
            }
        } catch {
            accidentLink = null;
        }
    }

    const artifact = props.details.artifact_path[Object.keys(props.details.artifact_path).filter(isEventVideo)[0]];

    const handleEventAction = (status: STATUS, comment?: string) => {
        if (status === STATUS.SAVED) {
            setCoachingModal(false);
        }
        const prevStatus = props.eventStatuses[props.details.event_id];
        props.handleStatusChange(props.details, status);
        api.apiV0EventEventIdCoachPost({
            eventId: props.details.event_id,
            coachEventDetails: {
                action: status === STATUS.SAVED ? CoachEventDetailsActionEnum.Add : CoachEventDetailsActionEnum.Remove,
                comment,
                is_accident: isAccident,
            },
        })
            .then((res) => {
                if (status === STATUS.SAVED) {
                    const link = (
                        <Link
                            id="view-coaching-session-link"
                            component={RouterLink}
                            to={`/coaching/${res.data.session_id}`}
                        >
                            {t('content.safety.view_session')}
                        </Link>
                    );
                    props.setAlert({
                        message: t('content.safety.save_success'),
                        link,
                        type: 'success',
                        duration: 6000,
                    });
                }
            })
            .catch(() => {
                props.handleStatusChange(props.details, prevStatus);
                props.setSelectedEvent(props.details.event_id);
                const message =
                    status === STATUS.SAVED ? t('content.safety.save_fail') : t('content.safety.dismiss_fail');
                props.setAlert({ message, type: 'error', duration: 6000 });
            });
    };

    useEffect(() => {
        // get gps file
        const artifactName = Object.keys(props.details.artifact_path).find((name) => name.includes('gps.csv'));
        if (artifactName) {
            axios
                .get(props.details.artifact_path[artifactName])
                .then((response) => {
                    let headers: Record<string, number> = {};
                    const gpsTrace: Array<GpsTrace> = [];
                    const lines = response.data.split(/\r?\n/);
                    for (const line of lines) {
                        if (Object.keys(headers).length == 0 && line.includes('frame')) {
                            headers = Object.fromEntries(
                                line.split(',').map((header: string, idx: number) => [header.trim(), idx]),
                            );
                        } else if (Object.keys(headers).length > 0) {
                            const parts = line.split(',');
                            gpsTrace.push({
                                timestamp: parseInt(parts[headers['time(ms)']]) / 1000,
                                lat: parseFloat(parts[headers['latitude']]),
                                lng: parseFloat(parts[headers['longitude']]),
                                speed: parseFloat(parts[headers['speed(m/s)']]),
                            });
                        }
                    }
                    setGpsTrace(gpsTrace);
                })
                .catch(() => setGpsTrace([]));
        } else {
            setGpsTrace([]);
        }
    }, [props.details]);

    useEffect(() => {
        if (gpsTrace.length > 0) {
            const gpsTraceRoute: Array<[string, number, CallableFunction]> = gpsTrace.reduce((acc, entry) => {
                if (!isNaN(entry.lat) && !isNaN(entry.lng)) {
                    acc.push([`${entry.lat}:${entry.lng}`, 0, () => palette.accent]);
                }
                return acc;
            }, [] as Array<[string, number, CallableFunction]>);
            setGpsRoute(gpsTraceRoute);
            setCurrentLocation(gpsTraceRoute[0][0]);
        } else {
            setGpsRoute([]);
        }
    }, [gpsTrace]);

    const eventRoute: Array<[string, number, CallableFunction]> | undefined = props.details.route
        ? props.details.route.split(',').map((location) => [location.toString(), 0, () => palette.accent])
        : undefined;
    const markers: Array<React.ReactNode> = [];
    if (!!props.details.location) {
        markers.push(eventToMarker(props.details.location, props.details.type, 1));
    } else {
        const eventEstimatedLocation = eventRoute ? eventRoute[Math.floor(eventRoute.length / 2)][0] : undefined;
        markers.push(eventToMarker(eventEstimatedLocation, props.details.type, 1));
    }
    const route = gpsRoute.length > 0 ? gpsRoute : eventRoute;
    const alertMuted = props.details.tags.includes('alert-muted');

    return (
        <>
            <Dialog id="new-coaching-dialog" open={coachingModal} onClose={() => setCoachingModal(false)}>
                <NewCoachingModal defaultDriver={driverName} onSave={handleEventAction}>
                    <Button
                        id="btn-cancel-save-for-coaching"
                        variant="outlined"
                        size="small"
                        onClick={() => setCoachingModal(false)}
                        sx={{ ml: 1 }}
                    >
                        {t('content.fleet.update.cancel')}
                    </Button>
                </NewCoachingModal>
            </Dialog>
            <Grid container columns={2} columnSpacing={1} rowSpacing={0.5}>
                <Grid item xs={2} order={1} sx={{ my: 0.5 }}>
                    <EventActionsComponent
                        details={props.details}
                        setDetails={props.setDetails}
                        allowEdit={true}
                        showFindVideo
                        showReportAccident
                        showEventRoute
                        showShareEvent
                        showChangeDriver
                        setAlert={props.setAlert}
                        updateTripDetails={props.updateTripDetails}
                    />
                </Grid>
                <Grid item xs={2} order={1} sx={{ mt: -1 }}>
                    <VideoComponent
                        event={props.details}
                        video={artifact}
                        internalOnProgress={(playedSeconds: number, duration: number) => {
                            if (gpsTrace.length > 0) {
                                const idx = Math.round((playedSeconds / duration) * (gpsTrace.length - 1));
                                const location = `${gpsTrace[idx]?.lat}:${gpsTrace[idx]?.lng}`;
                                if (isLocationValid(location)) {
                                    setCurrentLocation(location);
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2} sm={1} order={{ xs: 3, sm: 2 }}>
                    <Card
                        sx={{
                            width: '100%',
                            height: '100%',
                            p: 1,
                            aspectRatio: { xs: '8 / 3', sm: '8 / 5' },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Grid container columns={2}>
                            <Grid item xs={1}>
                                <CardField
                                    name={t('table.headers.event_type')}
                                    value={t(
                                        isAccident ? 'content.safety.accident' : EVENT_TYPE_MAP[props.details.type],
                                    )}
                                    containerStyle={{ mb: 1 }}
                                />
                                <CardField
                                    name={t('table.headers.date')}
                                    value={dayjs.unix(props.details.timestamp).format(profile.dateFormat)}
                                    containerStyle={{ mb: 1 }}
                                />
                                <CardField
                                    name={t('content.safety.time')}
                                    value={dayjs.unix(props.details.timestamp).format(profile.timeFormat)}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <CardField
                                    name={t('table.headers.severity')}
                                    value={(isAccident ? 100 : props.details.severity).toFixed(2)}
                                    containerStyle={{ mb: 1 }}
                                />
                                <CardField
                                    name={t('content.safety.event_no')}
                                    value={props.details.event_id.toString()}
                                />
                            </Grid>
                        </Grid>
                        {accidentLink}
                        {alertMuted ? (
                            <Typography variant="body3" color="error">
                                {t('content.safety.alert_muted')}
                            </Typography>
                        ) : null}
                    </Card>
                </Grid>
                <Grid item xs={2} sm={1} order={{ xs: 2, sm: 3 }}>
                    {route != undefined && route.length > 0 ? (
                        <Box sx={{ width: '100%', aspectRatio: { xs: '8 / 3', sm: '8 / 5' } }}>
                            <MapContent full zoom={16} route={route} location={currentLocation} markers={markers} />
                        </Box>
                    ) : (
                        <Card
                            sx={{
                                width: '100%',
                                aspectRatio: { xs: '8 / 3', sm: '8 / 5' },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden',
                            }}
                        >
                            <img style={{ height: '100%' }} src={mapNA} />
                        </Card>
                    )}
                </Grid>
                <Grid item xs={1} order={4}>
                    <Button
                        id="save-for-coaching-btn"
                        data-testid="save-for-coaching-btn"
                        variant="contained"
                        size="small"
                        fullWidth
                        color="neutral"
                        disabled={props.eventStatuses[props.details.event_id] === STATUS.SAVED}
                        sx={{ mt: 0.5 }}
                        onClick={() => {
                            setCoachingModal(true);
                        }}
                    >
                        {props.eventStatuses[props.details.event_id] !== STATUS.SAVED
                            ? t('content.safety.save_event')
                            : t('content.safety.saved')}
                    </Button>
                </Grid>
                <Grid item xs={1} order={5}>
                    <Button
                        id="dismiss-btn"
                        data-testid="dismiss-btn"
                        variant="contained"
                        size="small"
                        fullWidth
                        color="redColor"
                        disabled={props.eventStatuses[props.details.event_id] === STATUS.DISMISSED}
                        sx={{ mt: 0.5 }}
                        onClick={() => handleEventAction(STATUS.DISMISSED)}
                    >
                        {props.eventStatuses[props.details.event_id] !== STATUS.DISMISSED
                            ? t('content.safety.dismiss')
                            : t('content.safety.dismissed')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default VehicleModalDetails;
