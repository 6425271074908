import { Box, Skeleton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CoachWellnessStatus } from '../../../../backendsdk';
import useApi from '../../../../hooks/api';
import useProfile from '../../../../hooks/profile';
import imageNA from '../../../../images/image_not_available.png';
import { WELLNESS_TYPE_MAP } from '../../Device/WellnessBox';
import { getWellnessType } from '../Agenda/WellnessStatus';

export interface WellnessStatusSlideProps {
    wellness: CoachWellnessStatus;
}

const WellnessStatusSlide: React.FC<WellnessStatusSlideProps> = ({ wellness }: WellnessStatusSlideProps) => {
    const [image, setImage] = useState<string | undefined>(undefined);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { api } = useApi();
    const wellnessType = getWellnessType(wellness);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const relevantWellness = (wellness.front_facing_wellness_status || wellness.in_cabin_wellness_status)!;

    useEffect(() => {
        api.apiV2WellnessImageWellnessTypeGet(
            { wellnessType, wellnessId: relevantWellness.id.toString() },
            { responseType: 'blob' },
        )
            .then((response) => {
                const reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = () => {
                    setImage(reader.result as string);
                };
                reader.onerror = () => setImage(imageNA);
            })
            .catch(() => setImage(imageNA));
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                overflow: 'auto',
                px: 4,
                py: 2,
            }}
        >
            <Box sx={{ height: '30px', display: 'flex', alignItems: 'end' }}>
                <Typography data-testid="wellness-title" fontSize={18}>
                    {`${t(`content.wellness.${wellnessType}`)}: ${t(
                        WELLNESS_TYPE_MAP[relevantWellness.wellness_state as keyof typeof WELLNESS_TYPE_MAP],
                    )} ${t('content.coach.presentation.on', {
                        time: dayjs.unix(relevantWellness.timestamp).format(profile.timeFormat),
                    })}`}
                </Typography>
            </Box>
            {!!image ? (
                <img style={{ width: '100%', height: 'auto' }} src={image} />
            ) : (
                <Box sx={{ width: '100%', aspectRatio: '8 / 3' }}>
                    <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
                </Box>
            )}
            {!!wellness.comment && (
                <Typography data-testid="wellness-comment" sx={{ mt: 1 }}>
                    {wellness.comment}
                </Typography>
            )}
        </Box>
    );
};

export default WellnessStatusSlide;
