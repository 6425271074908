const FINES = ['eor_fine', 'tg_fine', 'hb_fine', 'nc_fine', 'claim_fine'];

export const getFines = (metadata: string): Record<string, number> => {
    const metadataJson = JSON.parse(metadata);
    const res = Object.entries(metadataJson)
        .filter(([key, value]) => FINES.includes(key) && !!value)
        .map(([key, value]) => ({ [key.split('_')[0]]: value as number }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    return res;
};
