import { Box, SelectChangeEvent, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Artifact, ArtifactActionActionEnum } from '../../../backendsdk';
import { TrackedButton as Button } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import splitExtension from '../../../utils/File';
import { RTLDirectionContext } from '../../Layout';
import { TAG_LIST } from './Defs';
import { MuiSelect, MuiTextField, MuiTextFieldUncontrolled } from './MuiFormControls';

interface AccidentArtifactActionComponentProps {
    accident_id: number;
    artifact: Artifact;
    action: string;
    handleClick: CallableFunction;
    customerTags: Array<string>;
    setCustomerTags: CallableFunction;
    setAlert: CallableFunction;
}

const AccidentArtifactActionComponent: React.FC<AccidentArtifactActionComponentProps> = (
    props: AccidentArtifactActionComponentProps,
) => {
    const rtlDirection = useContext(RTLDirectionContext);

    let component = null;
    if (props.action == 'mail') {
        component = <AccidentArtifactActionMailComponent {...props} />;
    } else if (props.action == 'edit') {
        component = <AccidentArtifactActionEditComponent {...props} />;
    } else if (props.action == 'delete') {
        component = <AccidentArtifactActionDeleteComponent {...props} />;
    }

    return <div style={{ direction: rtlDirection ? 'rtl' : undefined }}>{component}</div>;
};

const AccidentArtifactActionMailComponent: React.FC<AccidentArtifactActionComponentProps> = (
    props: AccidentArtifactActionComponentProps,
) => {
    const [message, setMessage] = useState<string>('');
    const { t } = useTranslation();
    const addressRef = useRef<HTMLInputElement>(null);
    const { agencyApi } = useApi();

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {t('content.accidents.files.send_header', { file_name: props.artifact.file_name })}
            </Typography>
            <MuiTextFieldUncontrolled
                id="recipient-field"
                name="recipient"
                label={t('content.accidents.files.recipient')}
                inputRef={addressRef}
                sx={{ mb: 2 }}
            />
            <MuiTextField
                id="message-field"
                name="message"
                label={t('content.accidents.files.message')}
                value={message}
                multiline={true}
                maxRows={3}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
                sx={{ mb: 2 }}
            />
            <Box textAlign="center">
                <Button
                    id="btn-send-mail"
                    size="small"
                    variant="contained"
                    onClick={() => {
                        agencyApi
                            .agencyV1AccidentAccidentIdArtifactArtifactIdActionPost({
                                accidentId: props.accident_id,
                                artifactId: props.artifact.artifact_id,
                                artifactAction: {
                                    action: props.action as ArtifactActionActionEnum,
                                    recipient: addressRef.current?.value,
                                    message: message,
                                },
                            })
                            .then(() => props.handleClick())
                            .catch(() =>
                                props.setAlert({
                                    message: t('content.accidents.files.error_msg'),
                                    type: 'error',
                                    duration: 6000,
                                }),
                            );
                    }}
                    sx={{ mr: 1 }}
                >
                    {t('content.accidents.files.send_mail')}
                </Button>
                <Button id="btn-cancel" size="small" variant="outlined" onClick={() => props.handleClick(false)}>
                    {t('content.accidents.files.cancel')}
                </Button>
            </Box>
        </Box>
    );
};

const AccidentArtifactActionEditComponent: React.FC<AccidentArtifactActionComponentProps> = (
    props: AccidentArtifactActionComponentProps,
) => {
    const [name, setName] = useState<string>('');
    const [tag, setTag] = useState<string>('');
    const [newTag, setNewTag] = useState<boolean>(false);
    const { t } = useTranslation();
    const [fileBasename, fileExtension] = splitExtension(props.artifact.file_name || '');
    const { agencyApi } = useApi();

    useEffect(() => {
        if (props.artifact) {
            setName(fileBasename);
            setTag(props.artifact.tag);
        }
    }, [props.artifact]);

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {t('content.accidents.files.edit_header', { file_name: props.artifact.file_name })}
            </Typography>
            <MuiTextField
                id="filename-field"
                name="filename"
                label={t('content.accidents.files.name')}
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                sx={{ mb: 2 }}
            />
            {newTag ? (
                <MuiTextField
                    id="tag-text-field"
                    name="tag"
                    label={t('content.accidents.files.tag')}
                    value={tag}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTag(e.target.value)}
                    sx={{ mb: 2 }}
                />
            ) : (
                <MuiSelect
                    id="tag-select-field"
                    options={[...new Set([...TAG_LIST, ...props.customerTags, tag])]}
                    value={tag}
                    label={t('content.accidents.files.tag')}
                    onChange={(e: SelectChangeEvent<string>) => setTag(e.target.value)}
                    t={(s: string) => t(`content.accidents.files.${s.toLocaleLowerCase().replaceAll(' ', '_')}`, s)}
                    sx={{ mb: 2 }}
                />
            )}
            <Box textAlign="center">
                <Button
                    id="btn-new-tag"
                    size="small"
                    variant="contained"
                    onClick={() => setNewTag((p) => !p)}
                    sx={{ mr: 1 }}
                >
                    {t('content.accidents.files.new_tag')}
                </Button>

                <Button
                    id="edit-btn"
                    size="small"
                    variant="contained"
                    onClick={() => {
                        agencyApi
                            .agencyV1AccidentAccidentIdArtifactArtifactIdActionPost({
                                accidentId: props.accident_id,
                                artifactId: props.artifact.artifact_id,
                                artifactAction: {
                                    action: props.action as ArtifactActionActionEnum,
                                    name: `${name}${fileExtension}`,
                                    tag: tag,
                                },
                            })
                            .then(() => {
                                props.setCustomerTags((p: Array<string>) => [...new Set([...p, tag])]);
                                props.handleClick();
                            })
                            .catch(() =>
                                props.setAlert({
                                    message: t('content.accidents.files.error_msg'),
                                    type: 'error',
                                    duration: 6000,
                                }),
                            );
                    }}
                    sx={{ mr: 1 }}
                >
                    {t('content.accidents.files.update')}
                </Button>
                <Button id="btn-cancel" size="small" variant="outlined" onClick={() => props.handleClick(false)}>
                    {t('content.accidents.files.cancel')}
                </Button>
            </Box>
        </Box>
    );
};

const AccidentArtifactActionDeleteComponent: React.FC<AccidentArtifactActionComponentProps> = (
    props: AccidentArtifactActionComponentProps,
) => {
    const { t } = useTranslation();
    const { agencyApi } = useApi();

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {t('content.accidents.files.delete_header', { file_name: props.artifact.file_name })}
            </Typography>
            <Box textAlign="center">
                <Button
                    id="delete-btn"
                    size="small"
                    variant="contained"
                    onClick={() => {
                        agencyApi
                            .agencyV1AccidentAccidentIdArtifactArtifactIdActionPost({
                                accidentId: props.accident_id,
                                artifactId: props.artifact.artifact_id,
                                artifactAction: {
                                    action: props.action as ArtifactActionActionEnum,
                                },
                            })
                            .then(() => {
                                props.handleClick();
                            })
                            .catch(() =>
                                props.setAlert({
                                    message: t('content.accidents.files.error_msg'),
                                    type: 'error',
                                    duration: 6000,
                                }),
                            );
                    }}
                    sx={{ mr: 1 }}
                >
                    {t('content.accidents.files.delete')}
                </Button>
                <Button id="btn-cancel" size="small" variant="outlined" onClick={() => props.handleClick(false)}>
                    {t('content.accidents.files.cancel')}
                </Button>
            </Box>
        </Box>
    );
};

export default AccidentArtifactActionComponent;
