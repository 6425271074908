import TimerIcon from '@mui/icons-material/Timer';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    Slider,
    SliderValueLabel,
    TextField,
    ThemeProvider,
    Typography,
    styled,
} from '@mui/material';
import fromEntries from 'object.fromentries';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';

import { CloneEventDetailsLevelEnum, EventDetails } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import palette from '../../ColorPalette';
import { RTLDirectionContext } from '../../Layout';
import { isEventBlurred } from '../Coaching/SessionDetails';
import { OE_ROLES } from '../Users/UsersComponent';
import { toEventLevelText } from './EventDetails';

export interface EventCloneComponentProps {
    currentTime: number;
    duration?: number;
    details: EventDetails;
    artifact: string;
    onClose: CallableFunction;
    setAlert: CallableFunction;
    onEventsPage: boolean;
    setSelectedEventId?: CallableFunction;
}

interface CloneParamsInterface {
    start_time?: string;
    end_time?: string;
    blur_movie?: boolean;
    level?: CloneEventDetailsLevelEnum;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const inputField = (props: any) => {
    const inputElement = <TextField {...props} />;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (() => inputElement) as any;
};

const SEVERITY_OPTIONS = ['ALERT', 'WARNING', 'INFO'];

export const canBeTrimmed = (details: EventDetails) => {
    return details.type === 'MsgPublishVideo' || details.type === 'ManualClipRequest' || details.type === 'ManualEvent';
};

const EventCloneComponent: React.FC<EventCloneComponentProps> = (props: EventCloneComponentProps) => {
    const [cloneParams, setCloneParams] = useState<CloneParamsInterface>({});
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<string>();
    const { t } = useTranslation();
    const history = useHistory();
    const query = useQuery();
    const { api } = useApi();
    const { profile } = useProfile();
    const isRTL = useContext(RTLDirectionContext);

    const strToSeconds = (str?: string) => {
        if (!str || str.includes('_')) return undefined;
        const [hours, minutes, seconds] = str.split(':');
        return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    };
    const secondsToStr = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const sec = Math.floor(seconds % 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${sec
            .toString()
            .padStart(2, '0')}`;
    };
    const startTime = strToSeconds(cloneParams.start_time);
    const endTime = strToSeconds(cloneParams.end_time);

    const lateStartTime = !!endTime && !!startTime && endTime < startTime;
    const startTimeNegative = !!startTime && startTime < 0;
    const startTimeTooBig = !!startTime && !!props.duration && startTime > props.duration;
    const endTimeTooBig = !!endTime && !!props.duration && endTime > props.duration;

    const isFirstThumb = (index: number) => (isRTL ? index === 1 : index === 0);
    const getLabelPosition = (index: number) => ({
        [isFirstThumb(index) ? 'left' : 'right']: 'calc(50% + 1px)',
    });
    const getLabelBorderRadius = (index: number) => {
        if (isFirstThumb(index)) {
            return '0 15px 15px 15px';
        }
        return '15px 0 15px 15px';
    };

    const StyledValueLabel = styled(SliderValueLabel)(({ theme, index }) => ({
        backgroundColor: theme.palette.primary.main,
        top: '40px',
        borderRadius: getLabelBorderRadius(index),
        // remove thumb anchor
        '&::before': {
            display: 'none',
        },
        ...getLabelPosition(index),
    }));

    const cloneEvent = () => {
        if (lateStartTime || startTimeNegative || startTimeTooBig || endTimeTooBig) {
            setErrors(t('content.events.clone.error'));
            return;
        }
        setErrors(undefined);
        const requestData = {
            start_time: startTime,
            end_time: endTime,
            blur_movie: cloneParams.blur_movie,
            level: cloneParams.level,
        };
        setIsLoading(true);
        api.apiV0EventEventIdClonePost({ eventId: props.details.event_id, cloneEventDetails: requestData })
            .then((res) => {
                if (props.onEventsPage) {
                    history.push(`/events/${res.data.event_id}/${query.toString()}`);
                } else if (props.setSelectedEventId) {
                    props.setSelectedEventId(res.data.event_id.toString());
                }
            })
            .then(() => {
                props.onClose();
                setCloneParams({});
                props.setAlert({ message: t('content.events.clone.success'), type: 'success', duration: 6000 });
            })
            .catch(() => props.setAlert({ message: t('content.events.clone.failure'), type: 'error', duration: 6000 }))
            .finally(() => setIsLoading(false));
    };

    const levels = fromEntries(SEVERITY_OPTIONS.map((s) => [t(toEventLevelText(s)).toString(), s]));
    const currentLevel = cloneParams.level || props.details.level;
    if (currentLevel && !Object.values(levels).includes(currentLevel)) {
        levels[t(toEventLevelText(currentLevel)).toString()] = currentLevel;
    }

    // const formatTime = (time: number) => `${Math.floor(time / 60)}:${(time % 60).toString().padStart(2, '0')}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sliderStyles: any = {
        '& .MuiSlider-mark': {
            transform: 'scale(4)',
            backgroundColor: palette.accent,
            opacity: 1,
            zIndex: 5,
        },
    };

    if (isRTL) {
        sliderStyles['& .MuiSlider-thumb'] = {
            transform: 'translate(5px, -5px)',
        };
    } else {
        sliderStyles['& .MuiSlider-mark'].transform = 'scale(4) translateY(-15%) translateX(-15%)';
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'visible' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', m: 0, backgroundColor: 'bgColor.main' }}>
                {canBeTrimmed(props.details) ? (
                    <>
                        <ThemeProvider theme={(outerTheme) => ({ ...outerTheme, direction: 'ltr' })}>
                            <Box sx={{ px: 0.5, pt: 0.5 }}>
                                <Slider
                                    id="slider"
                                    value={[startTime || 0, endTime || props.duration || 0]}
                                    valueLabelFormat={secondsToStr}
                                    onChange={(_, v) => {
                                        if (typeof v === 'number') return;
                                        setCloneParams((p) => {
                                            return {
                                                ...p,
                                                start_time: secondsToStr(v[0]),
                                                end_time: secondsToStr(v[1]),
                                            };
                                        });
                                    }}
                                    slots={{
                                        valueLabel: StyledValueLabel,
                                    }}
                                    slotProps={{
                                        thumb: { className: 'slider-thumb' },
                                        valueLabel: { className: 'slider-label' },
                                    }}
                                    valueLabelDisplay="auto"
                                    max={props.duration}
                                    size="small"
                                    marks={[{ value: props.currentTime }]}
                                    sx={sliderStyles}
                                />
                            </Box>
                        </ThemeProvider>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <InputMask
                                mask={'99:99:99'}
                                value={cloneParams.start_time?.toString() || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setCloneParams((p) => {
                                        return { ...p, start_time: e.target.value };
                                    })
                                }
                                maskChar="_"
                            >
                                {inputField({
                                    id: 'trimmed-start-time-text-field',
                                    label: t('content.events.clone.start_time'),
                                    size: 'small',
                                    fullWidth: true,
                                    error: !!errors && (lateStartTime || startTimeNegative || startTimeTooBig),
                                    helperText:
                                        !!errors && (lateStartTime || startTimeNegative || startTimeTooBig)
                                            ? errors
                                            : undefined,
                                    InputProps: {
                                        endAdornment: (
                                            <IconButton
                                                id="btn-current-time-from"
                                                size="small"
                                                onClick={() =>
                                                    setCloneParams((p) => {
                                                        return {
                                                            ...p,
                                                            start_time: secondsToStr(props.currentTime),
                                                        };
                                                    })
                                                }
                                            >
                                                <TimerIcon fontSize="small" />
                                            </IconButton>
                                        ),
                                    },
                                    sx: { mr: 1 },
                                    ...{ 'data-testid': 'trimmed-start-time-text-field' },
                                })}
                            </InputMask>
                            <InputMask
                                mask={'99:99:99'}
                                value={cloneParams.end_time?.toString() || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setCloneParams((p) => {
                                        return { ...p, end_time: e.target.value };
                                    })
                                }
                                maskChar="_"
                            >
                                {inputField({
                                    id: 'trimmed-end-time-text-field',
                                    label: t('content.events.clone.end_time'),
                                    size: 'small',
                                    fullWidth: true,
                                    error: !!errors && (lateStartTime || endTimeTooBig),
                                    helperText: !!errors && (lateStartTime || endTimeTooBig) ? errors : undefined,
                                    InputProps: {
                                        endAdornment: (
                                            <IconButton
                                                id="btn-current-time-to"
                                                size="small"
                                                onClick={() =>
                                                    setCloneParams((p) => {
                                                        return { ...p, end_time: secondsToStr(props.currentTime) };
                                                    })
                                                }
                                            >
                                                <TimerIcon fontSize="small" />
                                            </IconButton>
                                        ),
                                    },
                                    ...{ 'data-testid': 'trimmed-end-time-text-field' },
                                })}
                            </InputMask>
                        </Box>
                    </>
                ) : null}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        mt: OE_ROLES.includes(profile.role) || !isEventBlurred(props.details) ? 1.5 : 1,
                    }}
                >
                    {OE_ROLES.includes(profile.role) ? (
                        <FormControl sx={{ flex: 1, mr: !isEventBlurred(props.details) ? 1 : 0 }}>
                            <InputLabel>{t('content.events.clone.level')}</InputLabel>
                            <Select
                                label={t('content.events.clone.level')}
                                value={currentLevel}
                                id="level-select"
                                data-testid="level-select"
                                onChange={(e) => {
                                    setCloneParams((p) => {
                                        return { ...p, level: e.target.value as CloneEventDetailsLevelEnum };
                                    });
                                }}
                                inputProps={{
                                    'data-testid': 'clone-dropdown',
                                }}
                                variant="outlined"
                                size="small"
                            >
                                {Object.entries(levels).map(([displayName, option]) => (
                                    <MenuItem key={option} value={option} id={option} data-testid={option}>
                                        {displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                    {!isEventBlurred(props.details) ? (
                        <Box sx={{ flex: 1 }}>
                            <FormControlLabel
                                id="add-blur-field"
                                data-testid="add-blur-field"
                                value="add_blur"
                                slotProps={{ typography: { variant: 'caption' } }}
                                control={
                                    <Checkbox
                                        id="add-blur-checkbox"
                                        checked={(cloneParams.blur_movie as boolean) || false}
                                        onChange={(e) =>
                                            setCloneParams((p) => {
                                                return { ...p, blur_movie: e.target.checked };
                                            })
                                        }
                                        size="small"
                                        disableRipple
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                height: 18,
                                                width: 18,
                                                marginBottom: '1.5px',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography sx={{ fontSize: 14, ml: -0.5 }}>
                                        {t('content.events.clone.blur')}
                                    </Typography>
                                }
                                sx={{ pt: 0.5, pl: 1 }}
                            />
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
                <Button
                    variant="contained"
                    id="cancel-submit-btn"
                    disabled={isLoading}
                    onClick={() => props.onClose()}
                    sx={{ mr: 1 }}
                    fullWidth
                >
                    {t('content.events.clone.cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    id="clone-submit-btn"
                    disabled={isLoading}
                    onClick={cloneEvent}
                    fullWidth
                >
                    {t('content.events.clone.submit')}
                </Button>
            </Box>
        </Box>
    );
};
export default EventCloneComponent;
