import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GridCloseIcon } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedIconButton as IconButton } from '../../components/TrackedComponents';
import { useAlert } from '../../hooks/alert';
import useApi from '../../hooks/api';
import useProfile from '../../hooks/profile';
import { OE_ROLES } from '../content/Users/UsersComponent';
import NPSFeedbackQuestion from './FeedbackQuestion';
import NPSNumericQuestion from './NumricQuestion';

export const NPS_TIMESTAMP_KEY = 'OE-NpsTimestamp';

const NPSComponent: React.FC = () => {
    const { profile } = useProfile();
    const { api } = useApi();
    const [response, setResponse] = useState<number>();
    const [responseText, setResponseText] = useState<string>('');
    const [npsTimestamp, setNpsTimestamp] = useState(parseInt(localStorage.getItem(NPS_TIMESTAMP_KEY) || '0'));
    const [alertElement, setAlert] = useAlert();
    const { t } = useTranslation();
    const now = dayjs().unix();

    const setNewNpsTimestamp = (startDays: number, endDays: number) => {
        const randomDays = startDays + Math.random() * (endDays - startDays);
        const newTimestamp = dayjs().add(randomDays, 'days').unix();
        localStorage.setItem(NPS_TIMESTAMP_KEY, newTimestamp.toString());
        setNpsTimestamp(newTimestamp);
    };

    if (npsTimestamp === 0) {
        setNewNpsTimestamp(30, 60);
        return alertElement;
    } else if (now < npsTimestamp) {
        return alertElement;
    }

    const sendNps = () => {
        if (response) {
            api.apiV0NpsPost({
                nps: {
                    response: response || 0,
                    feedback: responseText,
                },
            }).then(() => {
                setNewNpsTimestamp(180, 360);
                setAlert({
                    message: t('nps.thanks'),
                    type: 'success',
                    duration: 6000,
                });
                mixpanel.track('nps', {
                    name: 'nps sent',
                });
            });
        }
    };

    const cancelAction = (
        <IconButton
            id="nps-cancel"
            data-testid="nps-cancel"
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
                setNewNpsTimestamp(30, 60);
                mixpanel.track('nps', {
                    name: 'nps canceled',
                });
            }}
        >
            <GridCloseIcon fontSize="small" />
        </IconButton>
    );

    const backAction = (
        <IconButton
            id="nps-back"
            data-testid="nps-back"
            size="small"
            aria-label="back"
            color="inherit"
            onClick={() => setResponse(undefined)}
        >
            <ArrowBackIcon fontSize="small" />
        </IconButton>
    );

    if (!OE_ROLES.includes(profile.role)) {
        if (response === undefined) {
            return <NPSNumericQuestion setResponse={setResponse} cancelButton={cancelAction} />;
        }
        return (
            <NPSFeedbackQuestion
                response={responseText}
                setResponse={setResponseText}
                handleSend={sendNps}
                cancelButton={cancelAction}
                backButton={backAction}
            />
        );
    }
    return null;
};

export default NPSComponent;
