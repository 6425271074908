import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import {
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
    CoachEvent,
    CoachSession,
    CoachingMetadataMetadataActionEnum,
    EventDetails,
    ExtendedCustomerDataAllOfUsers,
    ShortCoachSession,
} from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { stringAvatar } from '../../../utils/Avatar';
import { coachingPageDefs, flattenRoles } from '../../../utils/Pages';
import palette from '../../ColorPalette';
import { EVENT_TYPE_MAP } from '../Event/Defs';
import CoachingAgenda from './Agenda/CoachingAgenda';
import SendSessionDialog from './SendSessionDialog';

export const getUserAvatar = (user: ExtendedCustomerDataAllOfUsers, size?: number) => {
    const avatarSize = size || 33;
    const avatarProps = !!user.image_url
        ? { src: user.image_url, sx: { width: avatarSize, height: avatarSize, mr: 1 } }
        : {
              ...stringAvatar(user.name, {
                  width: avatarSize,
                  height: avatarSize,
                  fontSize: avatarSize / 2,
                  mr: 1,
              }),
          };
    return <Avatar {...avatarProps} />;
};

const MASK_TYPES = ['maskblack', 'maskpixelated', 'maskreversibleblur'];

const NARRATIVE_MAP = {
    recurring_tg: 'content.coach.details.narratives.recurring_tg',
    recurring_eor: 'content.coach.details.narratives.recurring_eor',
    events_preceding_accident: 'content.coach.details.narratives.events_preceding_accident',
    blocked: 'content.coach.details.narratives.blocked',
    speeding: 'content.coach.details.narratives.speeding',
    braking: 'content.coach.details.narratives.braking',
};

export const BEHAVIOR_MAP: Record<string, string> = {
    ...EVENT_TYPE_MAP,
    ...NARRATIVE_MAP,
    accident: 'content.safety.accident',
};

export const isEventBlurred = (event: EventDetails) => {
    return MASK_TYPES.some((mask) => event.tags.map((t) => t.toLocaleLowerCase()).includes(mask));
};

interface SessionDetailsProps {
    sessionId: number;
    sessions: ShortCoachSession[];
    setSessions: CallableFunction;
    setTab: CallableFunction;
    setAlert: CallableFunction;
    users: ExtendedCustomerDataAllOfUsers[];
    nextSessionId?: number;
    setSessionIdToDelete: CallableFunction;
}

const SessionDetails: React.FC<SessionDetailsProps> = (props: SessionDetailsProps) => {
    const [details, setDetails] = useState<CoachSession>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSendOpen, setIsSendOpen] = useState<boolean>(false);
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
    const [isLoadingAssignee, setIsLoadingAssignee] = useState<boolean>(false);
    const [existingSessionId, setExistingSessionId] = useState<number>();
    const [editAssignee, setEditAssignee] = useState<boolean>(false);
    const session = props.sessions.find((session) => session.session_id === props.sessionId);
    const [selectedAssignee, setSelectedAssignee] = useState<string>(session?.assignee?.toString() || 'unassigned');
    const { api } = useApi();
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const match = useRouteMatch();
    const history = useHistory();
    const { profile } = useProfile();

    const sendActions = (details?.metadata || []).filter(
        (m) => m.metadata.action === CoachingMetadataMetadataActionEnum.Send,
    );
    sendActions.sort((a, b) => b.timestamp - a.timestamp);
    const mostRecentSend = sendActions[0];
    const isUndelivered = details?.last_delivery_status === 'failed' || details?.last_delivery_status === 'undelivered';
    let lastSentStr;
    if (mostRecentSend) {
        const lastSentDatetime = dayjs.unix(mostRecentSend.timestamp).format(profile.dateTimeFormat);
        lastSentStr = `${t('content.coach.sort.last_sent')}: ${lastSentDatetime}${
            isUndelivered ? ` – ${t('content.coach.undelivered')}` : ''
        }`;
    }

    const isCompleted = details !== undefined && !!details.completed_at;
    const isSaved = details !== undefined && (!details.auto_generated || details.is_saved);

    const blurrableEvents = (details?.events || []).filter((e) => !isEventBlurred(e.event_details));
    const canBeBlurred = blurrableEvents.length > 0;
    const isAllBlurred = canBeBlurred && blurrableEvents.every((e) => e.is_blurred);
    const [isBlurred, setIsBlurred] = useState<boolean>(isAllBlurred);

    useEffect(() => {
        const blurrableEvents = (details?.events || []).filter((e) => !isEventBlurred(e.event_details));
        const canBeBlurred = blurrableEvents.length > 0;
        const isAllBlurred = canBeBlurred && blurrableEvents.every((e) => e.is_blurred);
        setIsBlurred(isAllBlurred);
    }, [details]);

    useEffect(() => {
        const controller = new AbortController();
        getCoachDetails(controller);
        return () => controller.abort();
    }, [props.sessionId]);

    const getCoachDetails = (controller?: AbortController) => {
        setIsLoading(true);
        setIsError(false);
        setEditAssignee(false);
        api.apiV1CoachSessionIdGet({ sessionId: props.sessionId }, { signal: controller?.signal })
            .then((res) => {
                setDetails({ ...res.data });
                if (!!res.data.completed_at) {
                    props.setTab('completed');
                } else if (!!res.data.last_sent) {
                    props.setTab('sent');
                } else {
                    props.setTab(!res.data.auto_generated || res.data.is_saved ? 'todo' : 'suggested');
                }
                setSelectedAssignee(res.data.assignee?.toString() || 'unassigned');
                if (match.path.endsWith('send')) {
                    setIsSendOpen(true);
                    history.replace(`/coaching/${res.data.session_id}`);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                if (!controller?.signal.aborted) {
                    if (err.response?.status === 404) {
                        props.setAlert({
                            message: t('content.coach.session_not_found'),
                            type: 'error',
                            duration: 6000,
                        });
                        history.replace('/coaching');
                    } else {
                        setIsError(true);
                        setIsLoading(false);
                    }
                }
            });
    };

    const handleSave = () => {
        setIsLoadingAction(true);
        api.apiV1CoachSessionIdSavePost({ sessionId: props.sessionId })
            .then(() => {
                if (details) {
                    setDetails({ ...details, is_saved: true });
                    props.setSessions((sessions: CoachSession[]) =>
                        sessions.map((session) =>
                            session.session_id === props.sessionId
                                ? { ...session, is_saved: true, last_updated: dayjs().unix() }
                                : session,
                        ),
                    );
                    if (existingSessionId) {
                        props.setSessions((sessions: CoachSession[]) =>
                            sessions.filter((session) => session.session_id !== existingSessionId),
                        );
                        setExistingSessionId(undefined);
                    }
                    setIsLoadingAction(false);
                    props.setTab('todo');
                }
            })
            .catch(() => {
                props.setAlert({
                    message: t('content.coach.failed_to_save_session'),
                    type: 'error',
                    duration: 6000,
                });
                setIsLoadingAction(false);
            });
    };

    const handleBlurChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsLoadingAction(true);
        setIsBlurred(e.target.checked);
        const promises = [];
        const eventsToChangeBlur = (details?.events || []).filter(
            (event) => !isEventBlurred(event.event_details) && event.is_blurred !== e.target.checked,
        );
        for (const event of eventsToChangeBlur) {
            promises.push(
                api.apiV0CoachSessionIdEventItemIdPatch({
                    sessionId: props.sessionId,
                    itemId: event.id,
                    updateCoachEvent: {
                        comment: event.comment,
                        is_blurred: e.target.checked,
                    },
                }),
            );
        }
        Promise.allSettled(promises).then((results) => {
            const updatedEvents: CoachEvent[] = [];
            const rejectedResults: PromiseRejectedResult[] = [];
            for (const res of results) {
                if (res.status === 'fulfilled') {
                    updatedEvents.push(res.value.data);
                } else {
                    rejectedResults.push(res);
                }
            }
            setDetails((prev) => {
                if (prev) {
                    const newEvents = prev.events.map((event) => {
                        const updatedEvent = updatedEvents.find((e) => e.id === event.id);
                        if (updatedEvent) {
                            return updatedEvent;
                        }
                        return event;
                    });
                    return { ...prev, events: newEvents };
                }
                return prev;
            });
            if (rejectedResults.length > 0) {
                props.setAlert({
                    message: t('content.coach.errors.blur_events'),
                    type: 'error',
                    duration: 6000,
                });
            }
            setIsLoadingAction(false);
        });
    };

    const handleAssign = () => {
        const assignee = selectedAssignee === 'unassigned' ? null : parseInt(selectedAssignee);
        if (details === undefined) {
            return;
        }
        setIsLoadingAssignee(true);
        api.apiV1CoachSessionIdPatch({
            sessionId: props.sessionId,
            coachSession: {
                ...details,
                assignee,
            },
        })
            .then(() => {
                setDetails((prev) => {
                    if (prev) {
                        return { ...prev, assignee };
                    }
                    return prev;
                });
                props.setSessions((sessions: CoachSession[]) =>
                    sessions.map((session) =>
                        session.session_id === props.sessionId ? { ...session, assignee } : session,
                    ),
                );
                setIsLoadingAssignee(false);
                setEditAssignee(false);
            })
            .catch(() => {
                props.setAlert({
                    message: t('content.coach.errors.assign'),
                    type: 'error',
                    duration: 6000,
                });
                setIsLoadingAssignee(false);
            });
    };

    if (isLoading || isError) {
        return (
            <Box
                sx={{
                    p: 1,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2,
                }}
            >
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Typography>{t('content.coach.errors.load_session')}</Typography>
                        <Link
                            id="try-again-link"
                            component="button"
                            variant="overline"
                            onClick={() => getCoachDetails()}
                        >
                            {t('content.coach.errors.try_again')}
                        </Link>
                    </>
                )}
            </Box>
        );
    }

    if (!details) {
        return null;
    }

    let behaviorsField = null;
    if (details.event_count > 0) {
        const behaviors: string[] = (details.behaviors || []).map((behavior) => BEHAVIOR_MAP[behavior] || behavior);
        behaviorsField =
            behaviors.length > 0 ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold' }}>
                        {t('content.coach.details.behaviors')}
                    </Typography>
                    {behaviors
                        .sort((a, b) => a.localeCompare(b))
                        .map((behavior, idx) => (
                            <Typography
                                key={idx}
                                id={`behavior-${behavior.toLocaleLowerCase().replaceAll(' ', '-')}`}
                                sx={{ fontSize: 16 }}
                            >
                                {t(behavior)}
                            </Typography>
                        ))}
                </Box>
            ) : null;
    }

    let blurCheckbox;
    if (canBeBlurred) {
        blurCheckbox = (
            <Box sx={{ width: '100%' }}>
                <FormControlLabel
                    disabled={isCompleted}
                    control={
                        <Checkbox
                            id="blur-events-checkbox"
                            data-testid="blur-events-checkbox"
                            checked={isBlurred}
                            onChange={handleBlurChange}
                            size="small"
                            disableRipple
                            sx={{
                                '& .MuiSvgIcon-root': { height: 16, width: 16 },
                                ml: '2px',
                                mr: '-2px',
                            }}
                        />
                    }
                    label={
                        <Typography variant="body2" sx={{ mt: 0.5 }}>
                            {t('content.coach.details.blur')}
                        </Typography>
                    }
                />
            </Box>
        );
    }

    let assignee;
    let assigneeAvatar;
    if (details.assignee) {
        assignee = props.users.find((user) => user.user_id === details.assignee);
        if (assignee !== undefined) {
            assigneeAvatar = getUserAvatar(assignee);
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: isMobile ? undefined : '100%',
                backgroundColor: palette.bgColor,
            }}
        >
            {isSendOpen ? (
                <SendSessionDialog
                    details={details}
                    setDetails={setDetails}
                    setSessions={props.setSessions}
                    setTab={props.setTab}
                    setAlert={props.setAlert}
                    onClose={() => setIsSendOpen(false)}
                />
            ) : null}
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pt: 1, pl: 1.5, pr: 0.5 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="h5">{t('content.coach.details.header')}</Typography>
                    {!isCompleted ? (
                        <IconButton
                            id="btn-delete"
                            size="small"
                            disabled={isLoadingAction}
                            onClick={() => props.setSessionIdToDelete(props.sessionId)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    ) : null}
                </Box>
                {!!details.auto_generated ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5, mb: !!mostRecentSend ? 0.5 : 1 }}>
                        <AutoFixHighIcon sx={{ color: palette.neutral[500], mr: 1 }} />
                        <Typography sx={{ color: palette.neutral[500], fontSize: 14 }}>
                            {t('content.coach.details.auto_generated_heading')}
                        </Typography>
                    </Box>
                ) : null}
                {!!lastSentStr ? (
                    <Typography
                        id="last-sent-field"
                        fontSize={14}
                        sx={{ color: isUndelivered ? 'error.main' : palette.neutral[700] }}
                    >
                        {lastSentStr}
                    </Typography>
                ) : null}
                <Typography variant="overline" sx={{ mt: 1 }}>
                    {t('content.coach.details.details')}
                </Typography>
                <Grid container columns={2} rowSpacing={1} sx={{ display: 'flex', width: '100%', px: 2, mb: 1 }}>
                    {details.auto_generated ? (
                        <>
                            <Grid item xs={1}>
                                <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold' }}>
                                    {t('content.coach.details.license_plate')}
                                </Typography>
                                <Typography
                                    id="license-plate-field"
                                    data-testid="license-plate-field"
                                    sx={{ fontSize: 16 }}
                                >
                                    {details.license_plate}
                                </Typography>
                            </Grid>
                            {!!details.sub_fleet_name ? (
                                <Grid item xs={1}>
                                    <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold' }}>
                                        {t('content.coach.details.sub_fleet')}
                                    </Typography>
                                    <Typography
                                        id="sub-fleet-field"
                                        data-testid="sub-fleet-field"
                                        sx={{ fontSize: 16 }}
                                    >
                                        {details.sub_fleet_name}
                                    </Typography>
                                </Grid>
                            ) : null}
                        </>
                    ) : null}
                    <Grid item xs={1}>
                        <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold' }}>
                            {t('content.coach.details.driver_name')}
                        </Typography>
                        <Typography
                            id="driver-name-field"
                            data-testid="driver-name-field"
                            sx={{ color: !!details.driver_name ? undefined : palette.neutral[700], fontSize: 16 }}
                        >
                            {!!details.driver_name?.trim() ? details.driver_name : t('content.fleet.unknown_driver')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        {behaviorsField}
                    </Grid>
                </Grid>
                <Typography variant="overline" sx={{ mt: 1 }}>
                    {t('content.coach.details.assignee')}
                </Typography>
                {editAssignee ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', px: 2, mb: 2 }}>
                        <Select
                            id="assignee-field"
                            data-testid="assignee-field"
                            name="assignee"
                            size="small"
                            value={selectedAssignee}
                            inputProps={{ 'data-testid': 'assignee-select' }}
                            onChange={(e: SelectChangeEvent) => {
                                setSelectedAssignee(e.target.value);
                            }}
                            sx={{ height: '50px' }}
                        >
                            <MenuItem
                                id="unassigned-menu-item"
                                data-testid="unassigned-menu-item"
                                value="unassigned"
                                sx={{ height: '45px' }}
                            >
                                <Typography>{t('content.coach.details.unassigned')}</Typography>
                            </MenuItem>
                            {props.users
                                .filter(
                                    (u) =>
                                        flattenRoles(coachingPageDefs).includes(u.role) &&
                                        (!details.sub_fleet_name ||
                                            !u.sub_fleet ||
                                            details.sub_fleet_name === u.sub_fleet),
                                )
                                .map((user) => {
                                    return (
                                        <MenuItem
                                            key={user.user_id}
                                            id={`user-${user.user_id}-menu-item`}
                                            data-testid={`${user.user_id}-menu-item`}
                                            className="assignee-menu-item"
                                            value={user.user_id}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                                                {getUserAvatar(user)}
                                                <Typography>{user.name}</Typography>
                                            </Box>
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        {isLoadingAssignee ? (
                            <CircularProgress size={30} sx={{ ml: 1, color: '#707070' }} />
                        ) : (
                            <IconButton
                                id="btn-save-assignee"
                                data-testid="btn-save-assignee"
                                size="small"
                                onClick={() => handleAssign()}
                                sx={{ ml: 0.5 }}
                            >
                                <SaveIcon />
                            </IconButton>
                        )}
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', px: 2, mb: 2 }}>
                        {assigneeAvatar}
                        {assignee !== undefined ? (
                            <Typography data-testid="field-value" data-value={assignee.user_id.toString()}>
                                {assignee.name}
                            </Typography>
                        ) : (
                            <Typography
                                data-testid="field-value"
                                data-value="unassigned"
                                sx={{ color: palette.neutral[500] }}
                            >
                                {t('content.coach.details.unassigned')}
                            </Typography>
                        )}
                        {!isCompleted && isSaved ? (
                            <IconButton
                                id="btn-edit-assignee"
                                data-testid="btn-edit-assignee"
                                size="small"
                                onClick={() => setEditAssignee(true)}
                                sx={{ ml: 0.5 }}
                            >
                                <EditIcon />
                            </IconButton>
                        ) : null}
                    </Box>
                )}
            </Box>
            <Box sx={{ height: isMobile ? undefined : '100%', minHeight: 0 }}>
                <CoachingAgenda
                    details={details}
                    setDetails={setDetails}
                    setSessions={props.setSessions}
                    setAlert={props.setAlert}
                    isLoading={isLoadingAction}
                />
            </Box>
            {isMobile || !isCompleted ? (
                <Box
                    sx={{
                        flexShrink: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        pt: canBeBlurred ? 0 : 1,
                        borderTop: `1px solid ${palette.gray[400]}`,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {blurCheckbox}
                        <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                            {!isCompleted ? (
                                <>
                                    {details.auto_generated && !details.is_saved ? (
                                        <Button
                                            id="btn-save-session"
                                            data-testid="btn-save-session"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<SaveIcon />}
                                            disabled={isLoadingAction}
                                            onClick={handleSave}
                                            sx={{ mr: 1 }}
                                        >
                                            {t('content.coach.details.save')}
                                        </Button>
                                    ) : null}
                                    <Button
                                        id="btn-send-session"
                                        data-testid="btn-send-session"
                                        variant="contained"
                                        color="primary"
                                        disabled={details.agenda.length === 0 || isLoadingAction}
                                        onClick={() => setIsSendOpen(true)}
                                        startIcon={<SendIcon />}
                                        sx={{ mr: 1, flex: 1 }}
                                    >
                                        {t('content.coach.send_session.header')}
                                    </Button>
                                    <Button
                                        id="btn-complete-session"
                                        data-testid="btn-complete-session"
                                        variant="contained"
                                        color="secondary"
                                        disabled={details.agenda.length === 0 || isLoadingAction}
                                        startIcon={<PlayArrowIcon />}
                                        onClick={() => history.push(`${match.url}/play`)}
                                        sx={{ flex: 1 }}
                                    >
                                        {t('content.coach.presentation.start_session')}
                                    </Button>
                                </>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            ) : null}
        </Box>
    );
};

export default SessionDetails;
