import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseAccidentV2, PolicyV2 } from '../../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import useCallbackRef from '../../../../hooks/callbackRef';
import { useImages } from '../../../../hooks/images';
import imageNA from '../../../../images/image_not_available.png';
import { RTLDirectionContext } from '../../../Layout';
import { CARD_HEIGHT, CARD_MARGIN, CARD_WIDTH } from '../../Vehicles/VehicleCard';
import { AccidentCard } from './AccidentCard';
import { ACCIDENT_STAGE_WIDTH } from './AccidentStage';

export const NAV_BUTTON_WIDTH = 50;

export interface AccidentGridProps {
    accidents: BaseAccidentV2[];
    accidentId: number;
    policies: Array<PolicyV2>;
    setAccidents: CallableFunction;
}

const AccidentGrid: React.FC<AccidentGridProps> = (props) => {
    const { t } = useTranslation();
    const { accidentId, accidents, policies } = props;
    const [cardRows, setCardRows] = useState<number>(2);
    const [cardColumns, setCardColumns] = useState<number>(4);
    const cardsOnScreen = cardColumns * cardRows;
    let initialIndex = 0;
    const accidentIndex = accidents.findIndex((a) => a.accident_id === accidentId);
    if (accidentIndex >= 0) {
        initialIndex = Math.floor(accidentIndex / cardsOnScreen) * cardsOnScreen;
    }
    const [startingIndex, setStartingIndex] = useState<number>(initialIndex);
    const [selectedAccidentId, setSelectedAccidentId] = useState<number>();
    const [overrideBellIcon, setOverrideBellIcon] = useState<Record<number, boolean>>({});
    const { api, agencyApi } = useApi();
    const getImage = (eventStr: string) => {
        const [eventIdStr, type] = eventStr.split(':');
        const eventId = parseInt(eventIdStr);
        if (type === 'accident') {
            return agencyApi.agencyV0EventEventIdImageGet({ eventId }, { responseType: 'blob' });
        }
        return api.apiV0EventEventIdImageGet({ eventId }, { responseType: 'blob' });
    };
    const [eventImages, addToQueue] = useImages(cardsOnScreen, getImage);
    const isRTL = useContext(RTLDirectionContext);
    const [ref, node] = useCallbackRef();

    const now = dayjs().unix();

    useEffect(() => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            if (node.offsetHeight) {
                const newRows = Math.floor(node.offsetHeight / (CARD_HEIGHT + CARD_MARGIN * 2));
                setCardRows(Math.max(1, newRows));
            }
            if (node.offsetWidth) {
                const newCols = Math.floor(
                    (node.offsetWidth - NAV_BUTTON_WIDTH * 2 - ACCIDENT_STAGE_WIDTH) / (CARD_WIDTH + CARD_MARGIN * 2),
                );
                setCardColumns(Math.max(1, newCols));
            }
        });
        resizeObserver.observe(node);
        return () => resizeObserver.disconnect();
    }, [node]);

    useEffect(() => {
        setStartingIndex(0);
    }, [accidents.map((a) => a.accident_id).join(',')]);

    const isSingleColumn = cardColumns === 1;

    useEffect(() => {
        const eventKeys = accidents
            .slice(startingIndex, startingIndex + 2 * cardsOnScreen)
            .filter((a) => !!a.event)
            .map((a) => {
                const eventId = a.event;
                return `${eventId}:accident`;
            });
        addToQueue(eventKeys);
    }, [accidents, startingIndex, eventImages, cardsOnScreen]);

    useEffect(() => {
        if (accidentId) {
            const accidentExists = accidents.some((a) => a.accident_id === accidentId);
            if (accidentExists) {
                setSelectedAccidentId(accidentId);
            }
        } else {
            setSelectedAccidentId(undefined);
        }
    }, [accidents, accidentId]);

    useEffect(() => {
        window.addEventListener('keydown', keyScroll);
        return () => window.removeEventListener('keydown', keyScroll);
    });

    const keyMap: Record<string, 'previous' | 'next'> = {
        ArrowLeft: isRTL ? 'next' : 'previous',
        ArrowRight: isRTL ? 'previous' : 'next',
    };

    const keyScroll = (e: KeyboardEvent) => {
        if (e.key in keyMap && selectedAccidentId === undefined) {
            e.preventDefault();
            if (!e.repeat) {
                turnPage(keyMap[e.key]);
            }
        }
    };

    const turnPage = (direction: 'previous' | 'next') => {
        if (direction === 'previous' && startingIndex > 0) {
            setStartingIndex((prev) => Math.max(0, prev - cardsOnScreen));
        } else if (direction === 'next' && startingIndex + cards.length < accidents.length) {
            setStartingIndex((prev) => prev + cardsOnScreen);
        }
    };

    const isBellIconActive = (accident: BaseAccidentV2) => {
        if (accident.accident_id in overrideBellIcon) {
            return overrideBellIcon[accident.accident_id];
        }
        return !!accident.notification && accident.notification < now;
    };

    const updateNotificationStatus = (accidentId: number, status: boolean) => {
        setOverrideBellIcon((prev) => ({ ...prev, [accidentId]: status }));
    };

    const cards = accidents.slice(startingIndex, startingIndex + cardsOnScreen).map((card, idx) => {
        return (
            <Box
                id={`${card.accident_id}-card`}
                key={idx}
                aria-label="accident-card"
                data-testid={`${card.accident_id}-card`}
                data-card-screen-idx={idx}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <AccidentCard
                    accidentDetails={card}
                    image={!!card.event ? eventImages[`${card.event}:accident`] : imageNA}
                    showBell={isBellIconActive(card)}
                    updateNotificationStatus={updateNotificationStatus}
                    policies={policies}
                    setAccidents={props.setAccidents}
                />
            </Box>
        );
    });

    const previousBtn = (
        <Box sx={isSingleColumn ? { mr: 1 } : { gridColumn: '1', gridRow: `1 / ${cardRows + 1}` }}>
            <IconButton
                id="previous-btn"
                size="large"
                onClick={() => turnPage('previous')}
                disabled={startingIndex === 0}
            >
                {isRTL ? (
                    <NavigateNextIcon sx={{ transform: 'scale(2)' }} />
                ) : (
                    <NavigateBeforeIcon sx={{ transform: 'scale(2)' }} />
                )}
            </IconButton>
        </Box>
    );

    const nextBtn = (
        <Box sx={isSingleColumn ? undefined : { gridColumn: `${cardColumns + 2}`, gridRow: `1 / ${cardRows + 1}` }}>
            <IconButton
                id="next-btn"
                size="large"
                onClick={() => turnPage('next')}
                disabled={startingIndex + cards.length >= accidents.length}
            >
                {isRTL ? (
                    <NavigateBeforeIcon sx={{ transform: 'scale(2)' }} />
                ) : (
                    <NavigateNextIcon sx={{ transform: 'scale(2)' }} />
                )}
            </IconButton>
        </Box>
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    minHeight: `${CARD_HEIGHT + 2 * CARD_MARGIN + 45}px`,
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: isSingleColumn
                            ? '1fr'
                            : `${NAV_BUTTON_WIDTH}px repeat(${cardColumns}, 1fr) ${NAV_BUTTON_WIDTH}px`,
                        gridTemplateRows: `repeat(${cardRows}, 1fr)`,
                        height: 'calc(100% - 45px)',
                        alignItems: 'center',
                        alignContent: 'center',
                        position: 'relative',
                    }}
                    ref={ref}
                >
                    {isSingleColumn ? (
                        <>{cards}</>
                    ) : (
                        <>
                            {previousBtn}
                            {cards}
                            {nextBtn}
                        </>
                    )}
                </Box>
                {accidents.length > 0 ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: isSingleColumn ? 1 : 0 }}>
                        {isSingleColumn ? (
                            <Box sx={{ width: '100%', mb: 0.5, display: 'flex', justifyContent: 'center' }}>
                                {previousBtn}
                                {nextBtn}
                            </Box>
                        ) : null}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Typography
                                variant="overline"
                                sx={{ lineHeight: 1 }}
                                data-testid="accidents-shown"
                                data-start={startingIndex + 1}
                                data-end={startingIndex + cards.length}
                                data-total={accidents.length}
                            >
                                {t('content.accidents.accidents_shown', {
                                    start: startingIndex + 1,
                                    end: startingIndex + cards.length,
                                    total: accidents.length,
                                })}
                            </Typography>
                        </Box>
                    </Box>
                ) : null}
            </Box>
        </>
    );
};

export default AccidentGrid;
