import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NumbersIcon from '@mui/icons-material/Numbers';
import PersonIcon from '@mui/icons-material/Person';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedAccident, PolicyV2 } from '../../../backendsdk';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import ChangeStageMenu from './AccidentV1/Modal/ChangeStageMenu';
import ShareMenu from './AccidentV1/Modal/ShareMenu';

interface AccidentModalHeaderProps {
    details: ExtendedAccident;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    checkDuplicate: CallableFunction;
    onSave: CallableFunction;
    sending: boolean;
    openTagging?: CallableFunction;
    disableSaving: boolean;
    policies?: PolicyV2[];
    loadingPolicies?: boolean;
    showStageButton?: boolean;
    showShareButton?: boolean;
    changeStage?: (stage: string) => Promise<void> | undefined;
}

const AccidentModalHeader: React.FC<AccidentModalHeaderProps> = (props: AccidentModalHeaderProps) => {
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
    const [anchorElChangeStage, setAnchorElChangeStage] = useState<null | HTMLElement>(null);
    const [anchorElShare, setAnchorElShare] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { license_plate, driver_name, timestamp, stage, sub_fleet_name, location, accident_id } = props.details;
    const formatted_stage = t(`content.accidents.stage.${profile.customer.settings.country}.${stage.toLocaleLowerCase()}`, stage);

    const isChangeStageOpen = Boolean(anchorElChangeStage);
    const handleChangeStageMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElChangeStage((prev) => (!prev ? event.currentTarget : null));
    };
    const handleChangeStageMenuClose = () => {
        setAnchorElChangeStage(null);
    };

    const isShareOpen = Boolean(anchorElShare);
    const handleShareMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElShare((prev) => (!prev ? event.currentTarget : null));
    };
    const handleShareMenuClose = () => {
        setAnchorElShare(null);
    };

    const onSave = () => {
        if (props.details.accident_id === 0) {
            props.checkDuplicate();
        } else {
            props.onSave();
        }
    };

    const fields = [
        { value: license_plate, icon: <LocalShippingIcon />, title: t('content.accidents.form.license_plate') },
        { value: driver_name, icon: <PersonIcon />, title: t('content.accidents.form.driver_name') },
        {
            value: dayjs.unix(timestamp).format(profile.dateTimeFormat),
            icon: <CalendarMonthIcon />,
            title: t('content.accidents.form.date'),
        },
        { value: formatted_stage, icon: <ViewColumnIcon />, title: t('content.accidents.stage.stage') },
        { value: sub_fleet_name, icon: <GroupsIcon />, title: t('content.accidents.form.sub_fleet') },
        { value: location, icon: <LocationOnIcon />, title: t('content.accidents.form.location') },
        { value: accident_id, icon: <NumbersIcon />, title: t('content.accidents.id') },
    ];

    const isLicensePlateValid =
        props.policies !== undefined && props.policies.length > 0
            ? props.policies.filter(
                  (p) => p.license_plate.replaceAll('-', '') == props.details.license_plate.replaceAll('-', ''),
              ).length > 0
            : true;

    const saveButton = (
        <Box>
            <IconButton
                id="save-accident-btn"
                disabled={props.sending || props.disableSaving || props.loadingPolicies}
                size="small"
                sx={{ color: palette.black }}
                onClick={() => {
                    if (isLicensePlateValid) {
                        onSave();
                    } else {
                        setConfirmDialog(true);
                    }
                }}
            >
                <SaveIcon />
            </IconButton>
        </Box>
    );

    let saveButtonTooltip;
    if (props.disableSaving) {
        saveButtonTooltip = t('content.accidents.form.finish_tagging');
    } else if (props.details.accident_id === 0) {
        saveButtonTooltip = t('content.accidents.form.create');
    } else {
        saveButtonTooltip = t('content.accidents.form.update');
    }

    return (
        <Box sx={{ backgroundColor: 'secondary.main', position: 'sticky', top: 0, p: 1, zIndex: 2 }}>
            <ConfirmDialog
                isDialogOpen={confirmDialog}
                isLoading={props.sending}
                headerText={'content.accidents.form.invalid_vin.header'}
                bodyText={['content.accidents.form.invalid_vin.body1', 'content.accidents.form.invalid_vin.body2']}
                buttonText={'content.accidents.form.invalid_vin.save'}
                buttonColor="secondary"
                onConfirm={() => {
                    onSave();
                    setConfirmDialog(false);
                }}
                onClose={() => setConfirmDialog(false)}
            />
            <ChangeStageMenu
                open={isChangeStageOpen}
                currentStage={stage}
                anchorElChangeStage={anchorElChangeStage}
                handleChangeStageMenuClose={handleChangeStageMenuClose}
                changeStage={(stage: string) => (props.changeStage ? props.changeStage(stage) : null)}
            />
            <ShareMenu
                open={isShareOpen}
                anchorElShare={anchorElShare}
                handleShareMenuClose={handleShareMenuClose}
                details={props.details}
            />
            <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                {profile.admin && props.openTagging ? (
                    <Tooltip
                        title={
                            props.details.accident_id === 0
                                ? t('content.accidents.tags.disabled_on_new')
                                : t('content.accidents.tags.tooltip')
                        }
                    >
                        {/* div is used to show tooltip on disabled button */}
                        <Box>
                            <IconButton
                                id="tag-accident-btn"
                                size="small"
                                sx={{ color: palette.black }}
                                onClick={() => (props.openTagging ? props.openTagging() : null)}
                                disabled={props.details.accident_id === 0}
                            >
                                <LocalOfferIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                ) : null}
                {props.showShareButton ? (
                    <Tooltip title={t('content.accidents.modal_header.share.header')}>
                        <Box>
                            <IconButton
                                id="btn-share"
                                size="small"
                                sx={{ color: palette.black }}
                                onClick={handleShareMenuClick}
                                disabled={props.details.accident_id === 0}
                            >
                                <ShareIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                ) : null}
                {props.showStageButton ? (
                    <Tooltip title={t('content.accidents.modal_header.change_stage')}>
                        <Box>
                            <IconButton
                                id="btn-change-stage"
                                size="small"
                                sx={{ color: palette.black }}
                                onClick={handleChangeStageMenuClick}
                                disabled={props.details.accident_id === 0}
                            >
                                <FormatLineSpacingIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                ) : null}
                <Tooltip title={saveButtonTooltip}>
                    <Box>{saveButton}</Box>
                </Tooltip>
                <IconButton id="btn-close-modal" size="small" onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Grid container columns={fields.length} sx={{ alignItems: 'center' }}>
                {fields.map((field) => {
                    return (
                        <React.Fragment key={field.title}>
                            <Grid item xs={1}>
                                <Box>
                                    <Tooltip title={field.title}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                pr: 1,
                                            }}
                                        >
                                            {field.icon}
                                            <Typography
                                                sx={{
                                                    ml: 0.5,
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {field.value}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default AccidentModalHeader;
