import { Box, Card, Snackbar, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../components/TrackedComponents';

interface NPSFeedbackQuestionProps {
    response: string;
    setResponse: (response: string) => void;
    handleSend: () => void;
    cancelButton: React.ReactNode;
    backButton: React.ReactNode;
}

const NPSFeedbackQuestion: React.FC<NPSFeedbackQuestionProps> = (props: NPSFeedbackQuestionProps) => {
    const { t } = useTranslation();

    const message = (
        <React.Fragment>
            <TextField
                data-testid="nps-feedback-question"
                multiline
                onChange={(e) => props.setResponse(e.target.value)}
                variant="outlined"
                value={props.response}
                sx={{ width: '100%' }}
                autoFocus
            />
        </React.Fragment>
    );

    return (
        <Snackbar
            open={true}
            color="#000000"
            autoHideDuration={6000}
            onClose={() => null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Card sx={{ backgroundColor: 'white', p: 2 }}>
                <Box sx={{ my: 1, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography>{t('nps.recommend_reason')}</Typography>
                    </Box>
                    <Box>{props.backButton}</Box>
                    <Box>{props.cancelButton}</Box>
                </Box>
                <Box sx={{ my: 1, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ width: '90%' }}>{message}</Box>
                    <Button id="btn-send-nps" data-testid="nps-send" onClick={props.handleSend}>
                        {t('nps.send')}
                    </Button>
                </Box>
            </Card>
        </Snackbar>
    );
    return null;
};

export default NPSFeedbackQuestion;
