import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useIsMobile from '../../hooks/isMobile';
import palette from '../../layout/ColorPalette';
import { TrackedDialog as Dialog, TrackedListItemButton as ListItemButton } from '../TrackedComponents';

const getFilename = (url: string) => {
    const file = url.split('/')[6];
    const withoutExtension = file?.split('.')[0];
    return withoutExtension?.split('-')[1];
};

interface HelpVideosProps {
    isOpen: boolean;
    setIsOpen: CallableFunction;
    currentPage: string;
    relevantVideos: string[];
}

const HelpVideos: React.FC<HelpVideosProps> = (props: HelpVideosProps) => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const videoRef = useRef<HTMLVideoElement>(null);
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const [videoWatched, setVideoWatched] = useState(false);

    useEffect(() => {
        setVideoWatched(false);
    }, [currentVideoIndex]);

    useEffect(() => {
        if (videoWatched) {
            mixpanel.track('Video watched', {
                type: 'help',
                page: window.location.pathname,
            });
        }
    }, [videoWatched]);

    const handleClose = () => {
        props.setIsOpen(false);
        videoRef.current?.pause();
    };

    return (
        <Dialog
            id="help-dialog"
            data-testid="help-dialog"
            keepMounted
            open={props.isOpen}
            fullWidth
            fullScreen={isMobile}
            onClose={handleClose}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: props.relevantVideos.length > 1 ? '1200px' : '800px',
                        backgroundColor: 'primary.main',
                    },
                },
            }}
        >
            <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                <IconButton size="small" onClick={handleClose}>
                    <CloseIcon sx={{ color: palette.white }} />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'primary.main',
                    p: 1,
                    width: '100%',
                }}
            >
                <Typography fontSize={12} sx={{ color: palette.white }}>
                    {t(`navigator.${props.currentPage}`)}
                </Typography>
                <Typography
                    data-testid={`${getFilename(props.relevantVideos[currentVideoIndex]).replaceAll('_', '-')}-title`}
                    sx={{ fontWeight: 500, color: palette.white }}
                >
                    {t(`content.help.${getFilename(props.relevantVideos[currentVideoIndex])}`)}
                </Typography>
                <Grid container columns={8} columnSpacing={1}>
                    <Grid item xs={8} md={props.relevantVideos.length > 1 ? 6 : 8}>
                        <video
                            src={props.relevantVideos[currentVideoIndex]}
                            controls={true}
                            width="100%"
                            style={{ aspectRatio: '2.08 / 1' }}
                            ref={videoRef}
                            onTimeUpdate={(e) => {
                                if (e.currentTarget.duration && !videoWatched) {
                                    const percentVideoPlayed =
                                        (e.currentTarget.currentTime / e.currentTarget.duration) * 100;
                                    if (percentVideoPlayed >= 50) {
                                        setVideoWatched(true);
                                    }
                                }
                            }}
                        />
                    </Grid>
                    {props.relevantVideos.length > 1 ? (
                        <Grid item xs={8} md={2}>
                            <Typography fontSize={14} sx={{ color: palette.white }}>{`${t(
                                `navigator.${props.currentPage}`,
                            )} (${props.relevantVideos.length} videos)`}</Typography>
                            <Box sx={{ height: '100%', maxHeight: 200, overflowY: 'scroll' }}>
                                <List
                                    id="help-video-list"
                                    data-testid="help-video-list"
                                    disablePadding
                                    sx={{
                                        color: palette.neutral[400],
                                        backgroundColor: 'primary.main',
                                        '&& .Mui-selected, && .Mui-selected:hover': {
                                            bgcolor: 'primary.light',
                                            '&, & .MuiListItemIcon-root': {
                                                color: palette.white,
                                            },
                                        },
                                    }}
                                >
                                    {props.relevantVideos.map((url, index) => {
                                        return (
                                            <ListItem key={index} disablePadding>
                                                <ListItemButton
                                                    id={`help-video-${getFilename(url).replaceAll('_', '-')}`}
                                                    data-testid={`help-video-${getFilename(url).replaceAll('_', '-')}`}
                                                    disableGutters
                                                    selected={index === currentVideoIndex}
                                                    onClick={() => setCurrentVideoIndex(index)}
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    <PlayArrowIcon sx={{ mx: 0.5 }} />
                                                    {t(`content.help.${getFilename(url)}`)}
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
            </Box>
        </Dialog>
    );
};

export default HelpVideos;
