import { Box, CircularProgress, LinearProgress, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DeviceHardware } from '../../../../../backendsdk';
import { TrackedButton as Button } from '../../../../../components/TrackedComponents';
import { useAlert } from '../../../../../hooks/alert';
import useApi from '../../../../../hooks/api';
import palette from '../../../../ColorPalette';
import { HORIZONTAL_APERTURE, VERTICAL_APERTURE } from '../SetupTool';
import ImageNA from '../assets/image_not_available.png';
import FixedContainer from '../ui/FixedContainer';
import { fallbackValue } from '../utils/analysis';
import getWorker from '../workers/worker';
import { FrontFacingOverlay, INSTRUCTIONS, InCabinOverlay } from './DeviceFeedback';

interface VerifyCameraProps {
    malfunctionId: number;
    installerName: string;
    customerName: string;
    onBack: () => void;
    imei: string;
    hardware: DeviceHardware;
}

const VerifyCamera: React.FC<VerifyCameraProps> = (props) => {
    const [snapshot, setSnapshot] = useState<string>('');
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [horizontalPixel, setHorizontalPixel] = useState<number>();
    const [imageCounter, setImageCounter] = useState<number>(0);
    const [keyCounter, setKeyCounter] = useState<number>(0);
    const { t } = useTranslation();
    const { api, installApi } = useApi();
    const history = useHistory();
    const [alertElement, setAlert] = useAlert();

    const getInstallationParams = () => {
        installApi
            .installParamsDeviceIdGet({ deviceId: props.imei })
            .then((res) => {
                const workerParams = {
                    instHeight: res.data.installation_params?.instHeight,
                    instDepth: res.data.installation_params?.instDepth,
                    instHoodHeight: res.data.installation_params?.instHoodHeight,
                    'vehicle-width': res.data.installation_params?.['vehicle-width'],
                    horizontalAperture: HORIZONTAL_APERTURE[props.hardware],
                    verticalAperture: VERTICAL_APERTURE[props.hardware],
                };
                if (Object.values(workerParams).every((val) => val === undefined)) {
                    setHorizontalPixel(fallbackValue);
                } else {
                    const worker = getWorker();
                    worker.onmessage = (event) => {
                        const hp = event.data;
                        setHorizontalPixel(!!hp ? (hp[0] + hp[1]) / 2 : fallbackValue);
                    };
                    worker.onerror = () => {
                        setHorizontalPixel(fallbackValue);
                    };
                    worker.postMessage(workerParams);
                    return () => {
                        worker.terminate();
                    };
                }
            })
            .catch(() => setHorizontalPixel(fallbackValue));
    };

    const getSnapshot = () => {
        const now = new Date().getTime() / 1000;
        const since = now - 60;
        api.apiV0DeviceDeviceIdSnapshotGet(
            { deviceId: props.imei, timestamp: now, since: since },
            { responseType: 'blob' },
        )
            .then((res) => {
                setImageCounter(0);
                setKeyCounter((prev) => prev + 1);
                const reader = new window.FileReader();
                reader.readAsDataURL(res.data);
                reader.onload = () => {
                    const image = reader.result;
                    if (typeof image === 'string') {
                        setSnapshot(image);
                        setIsError(false);
                    }
                };
                reader.onerror = () => {
                    setSnapshot(ImageNA);
                    setIsError(true);
                };
            })
            .catch(() => {
                setImageCounter(0);
                setSnapshot(ImageNA);
                setIsError(true);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (props.imei) {
            getInstallationParams();
            getSnapshot();
        }
    }, [props.imei]);

    useEffect(() => {
        if (imageCounter >= 20) {
            // wait for progress bar animation to end
            const timerId = setTimeout(() => {
                getSnapshot();
            }, 400);
            return () => clearTimeout(timerId);
        } else {
            const timerId = setTimeout(() => {
                setImageCounter((prev) => prev + 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [props.imei, imageCounter]);

    const convertToBase64 = (url: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        resolve(reader.result as string);
                    };
                    reader.onerror = () => {
                        reject();
                    };
                })
                .catch(() => {
                    reject();
                });
        });
    };

    const handleDone = async () => {
        setIsLoadingAction(true);
        try {
            const base64Image = await convertToBase64(snapshot);
            await installApi.installVerifyPost({
                verifyCamerasRequest: {
                    device_id: props.imei,
                    installer: props.installerName,
                    snapshot: base64Image,
                },
            });
            history.push(`/malfunctions/${props.malfunctionId}`);
        } catch {
            setAlert({ message: t('setup_tool.verify_camera.error'), type: 'error', duration: 6000 });
        } finally {
            setIsLoadingAction(false);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0,
            }}
        >
            {alertElement}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: 0,
                }}
            >
                <Typography variant="h6" textAlign="center">
                    {t('setup_tool.verify_camera.header')}
                </Typography>
                <Typography fontSize={14} textAlign="center" sx={{ fontWeight: 500, mb: 2 }}>
                    {t('setup_tool.verify_camera.instructions')}
                </Typography>
                {isLoading ? (
                    <CircularProgress sx={{ mt: 1 }} />
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            overflowY: 'auto',
                            my: 1,
                        }}
                    >
                        {!!snapshot ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    flexShrink: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <LinearProgress
                                    key={`${keyCounter}-progress`}
                                    variant="determinate"
                                    value={(1 - imageCounter / 20) * 100}
                                    sx={{ width: '100%', flexShrink: 0 }}
                                />
                                <FixedContainer style={{ position: 'relative', marginBottom: '8px' }}>
                                    <img
                                        id="ic-image"
                                        data-testid="ic-image"
                                        className={`wellness-image ${isError ? 'no-image' : 'ic-image'}`}
                                        src={snapshot}
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                    {!isError ? <InCabinOverlay /> : null}
                                </FixedContainer>
                                <ul style={{ width: '100%', paddingLeft: '20px' }}>
                                    {INSTRUCTIONS.ic.map((item) => (
                                        <li key={item} id={`li-${item}`} data-testid={`li-${item}`}>
                                            <Typography>{t(`setup_tool.device_feedback.ic_${item}`)}</Typography>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        ) : null}
                        {!!snapshot ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    flexShrink: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <LinearProgress
                                    key={`${keyCounter}-progress`}
                                    variant="determinate"
                                    value={(1 - imageCounter / 20) * 100}
                                    sx={{ width: '100%', flexShrink: 0 }}
                                />
                                <FixedContainer style={{ position: 'relative' }}>
                                    <img
                                        id="ff-image"
                                        data-testid="ff-image"
                                        className={`wellness-image ${isError ? 'no-image' : 'ff-image'}`}
                                        src={snapshot}
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                    {!isError ? <FrontFacingOverlay horizontalPixel={horizontalPixel} /> : null}
                                </FixedContainer>
                                <ul style={{ width: '100%', paddingLeft: '20px' }}>
                                    {INSTRUCTIONS.ff.map((item) => (
                                        <li key={item} id={`li-${item}`} data-testid={`li-${item}`}>
                                            <Typography>{t(`setup_tool.device_feedback.ff_${item}`)}</Typography>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        ) : null}
                    </Box>
                )}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    minHeight: 0,
                    flexShrink: 0,
                    py: 2,
                }}
            >
                <Box sx={{ width: '50%' }}>
                    <Button
                        id="btn-verify-done"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={isError || !snapshot || isLoadingAction}
                        onClick={() => {
                            handleDone();
                        }}
                    >
                        {t('setup_tool.completed.done')}
                    </Button>
                </Box>
                {isError ? (
                    <Box sx={{ minHeight: 0, mt: 1 }}>
                        <Link component="button" onClick={() => history.push(`/malfunctions/${props.malfunctionId}`)}>
                            <Typography fontSize={14} sx={{ color: palette.neutral[700] }}>
                                {t('setup_tool.back_to_dashboard')}
                            </Typography>
                        </Link>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};

export default VerifyCamera;
