import { CoachSession } from '../../../backendsdk';
import { normalizeName } from '../../../utils/Str';

export const sortingOptions = ['last_updated', 'created_at', 'driver_name', 'license_plate', 'sub_fleet', 'events'];
export const sentSortingOptions = ['last_sent', 'created_at', 'driver_name', 'license_plate', 'sub_fleet', 'events'];
export const completedSortingOptions = [
    'completed_at',
    'created_at',
    'driver_name',
    'license_plate',
    'sub_fleet',
    'events',
];

export const sortingFunctions: Record<string, CallableFunction> = {
    last_sent: (sessions: CoachSession[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        const sentSessions = sessions.filter((session) => session.last_sent);
        const unsentSessions = sessions.filter((session) => !session.last_sent);
        const sortedSentSessions = [...sentSessions].sort((a, b) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (a.last_sent! < b.last_sent!) {
                return -1 * factor;
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (a.last_sent! > b.last_sent!) {
                return 1 * factor;
            }
            return 0;
        });
        const sortedUnsentSessions = [...unsentSessions].sort((a, b) => {
            if (a.last_updated < b.last_updated) {
                return -1 * factor;
            }
            if (a.last_updated > b.last_updated) {
                return 1 * factor;
            }
            return 0;
        });
        return [...sortedSentSessions, ...sortedUnsentSessions];
    },
    last_updated: (sessions: CoachSession[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...sessions].sort((a, b) => {
            if (a.last_updated < b.last_updated) {
                return -1 * factor;
            }
            if (a.last_updated > b.last_updated) {
                return 1 * factor;
            }
            return 0;
        });
    },
    driver_name: (sessions: CoachSession[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...sessions].sort((a, b) => {
            if (normalizeName(a.driver_name) < normalizeName(b.driver_name)) {
                return -1 * factor;
            }
            if (normalizeName(a.driver_name) > normalizeName(b.driver_name)) {
                return 1 * factor;
            }
            return 0;
        });
    },
    license_plate: (sessions: CoachSession[], order: 'asc' | 'desc') => {
        const sessionsWithLicensePlate = sessions.filter((session) => session.auto_generated);
        const factor = order === 'asc' ? 1 : -1;
        sessionsWithLicensePlate.sort((a, b) => {
            if (a.license_plate < b.license_plate) {
                return -1 * factor;
            }
            if (a.license_plate > b.license_plate) {
                return 1 * factor;
            }
            return 0;
        });
        const sessionsWithoutLicensePlate = sessions.filter((session) => !session.auto_generated);
        return [...sessionsWithLicensePlate, ...sessionsWithoutLicensePlate];
    },
    sub_fleet: (sessions: CoachSession[], order: 'asc' | 'desc') => {
        const autoSessionsWithSubFleet = sessions.filter(
            (session) => session.auto_generated && !!session.sub_fleet_name,
        );
        const factor = order === 'asc' ? 1 : -1;
        autoSessionsWithSubFleet.sort((a, b) => {
            if (a.sub_fleet_name < b.sub_fleet_name) {
                return -1 * factor;
            }
            if (a.sub_fleet_name > b.sub_fleet_name) {
                return 1 * factor;
            }
            return 0;
        });
        const autoSessionsWithoutSubFleet = sessions.filter(
            (session) => session.auto_generated && !session.sub_fleet_name,
        );
        const manualSessions = sessions.filter((session) => !session.auto_generated);
        return [...autoSessionsWithSubFleet, ...autoSessionsWithoutSubFleet, ...manualSessions];
    },
    events: (sessions: CoachSession[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...sessions].sort((a, b) => {
            if (a.event_count < b.event_count) {
                return -1 * factor;
            }
            if (a.event_count > b.event_count) {
                return 1 * factor;
            }
            return 0;
        });
    },
    created_at: (sessions: CoachSession[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...sessions].sort((a, b) => {
            if (a.created_at < b.created_at) {
                return -1 * factor;
            }
            if (a.created_at > b.created_at) {
                return 1 * factor;
            }
            return 0;
        });
    },
    completed_at: (sessions: CoachSession[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        const completedSessions = sessions.filter((session) => session.completed_at);
        completedSessions.sort((a, b) => {
            if (a.completed_at < b.completed_at) {
                return -1 * factor;
            }
            if (a.completed_at > b.completed_at) {
                return 1 * factor;
            }
            return 0;
        });
        const uncompletedSessions = sessions.filter((session) => !session.completed_at);
        return [...completedSessions, ...uncompletedSessions];
    },
};
