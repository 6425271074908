import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CoachDriverResponse, CoachSession } from '../../../../backendsdk';

export interface DriverResponseSlideProps {
    itemId: number;
    response: CoachDriverResponse;
    setDetails: CallableFunction;
    setIsScrollable: CallableFunction;
    disabled: boolean;
}

const DriverResponseSlide: React.FC<DriverResponseSlideProps> = ({
    itemId,
    response,
    setDetails,
    setIsScrollable,
    disabled,
}: DriverResponseSlideProps) => {
    const { t } = useTranslation();

    return (
        <Box
            data-testid="driver-response-slide"
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                overflow: 'auto',
                px: 4,
                py: 2,
            }}
        >
            <Box sx={{ height: '30px', display: 'flex', alignItems: 'end' }}>
                <Typography>{t('content.coach.add_item.response.instructions')}</Typography>
            </Box>
            <div style={{ width: '100%', aspectRatio: '8 / 3' }}>
                <TextField
                    id="driver-response-field"
                    disabled={disabled}
                    value={response.text}
                    onChange={(e) =>
                        setDetails((prev: CoachSession) => {
                            if (prev === undefined) {
                                return prev;
                            }
                            return {
                                ...prev,
                                response: prev.response.map((response) =>
                                    response.id === itemId ? { ...response, text: e.target.value } : response,
                                ),
                            };
                        })
                    }
                    onFocus={() => setIsScrollable(false)}
                    onBlur={() => setIsScrollable(true)}
                    multiline
                    rows={9}
                    inputProps={{ 'data-testid': 'driver-response-field', style: { height: '100%' } }}
                    sx={{ width: '100%', height: '100%', '& .MuiInputBase-root': { height: '100%' } }}
                />
            </div>
        </Box>
    );
};

export default DriverResponseSlide;
