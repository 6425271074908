import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Box, CircularProgress, Divider, List, ListItem, TextField, TextFieldProps, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import L from 'leaflet';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Device } from '../../../backendsdk';
import { ResponsiveDateTimePicker } from '../../../components/MuiDatePicker';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedListItemButton as ListItemButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { DEFAULT_IL_LOCATION, DEFAULT_US_LOCATION } from '../OrganizationSettings/OrganizationSettings';
import { MAX_DAYS_BACK } from './FindVideo';
import SearchCenterComponent from './SearchMap';

interface DeviceLocation {
    device: Device;
    trip_id: string;
    timestamp: number;
    latitude: number;
    longitude: number;
    speed: number;
    distance: number;
}

export interface FindByLocationProps {
    setDate: CallableFunction;
    setLicensePlate: CallableFunction;
    onClose: CallableFunction;
}

const MAX_TIME_RANGE = 2;

const FindByLocation: React.FC<FindByLocationProps> = (props: FindByLocationProps) => {
    const { profile } = useProfile();
    const hasDefaultLocation = profile.customer.settings.default_lat && profile.customer.settings.default_lng;
    const initialPosition = hasDefaultLocation
        ? new L.LatLng(profile.customer.settings.default_lat, profile.customer.settings.default_lng)
        : profile.customer.settings.country === 'il'
        ? DEFAULT_IL_LOCATION
        : DEFAULT_US_LOCATION;
    const [position, setPosition] = useState<L.LatLng>(initialPosition);
    const [startDate, setStartDate] = useState<Dayjs | null>();
    const [endDate, setEndDate] = useState<Dayjs | null>();
    const [locatedDevices, setLocatedDevices] = useState<string[]>([]);
    const [routes, setRoutes] = useState<Record<string, [string, number, CallableFunction][]>>({});
    const [selectedDevice, setSelectedDevice] = useState<string>();
    const [radius, setRadius] = useState<number>(100);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [alertElement, setAlert] = useAlert();
    const { i18n, t } = useTranslation();
    const { api } = useApi();
    const isMobile = useIsMobile();

    const datePickerFormat = profile.customer.settings.country == 'il' ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY hh:mm A';

    let isTimeInvalid = false;
    let isTooLong = false;
    let isTooRecent = false;
    let isFarPast = false;
    let errorMessage = '';
    if (!!startDate && !!endDate) {
        isTimeInvalid = startDate.unix() >= endDate.unix() || endDate.unix() > dayjs().unix();
        isTooLong = endDate.unix() - startDate.unix() > MAX_TIME_RANGE * SECONDS_IN_HOUR;
        isTooRecent = dayjs().unix() - endDate.unix() <= 14 * SECONDS_IN_MINUTE;
        isFarPast = startDate.unix() < dayjs().subtract(MAX_DAYS_BACK, 'day').unix();

        if (isTimeInvalid) {
            errorMessage = t('content.find_video.invalid_time');
        } else if (isTooLong) {
            errorMessage = t('content.find_video.too_long_2h');
        } else if (isTooRecent) {
            errorMessage = t('content.find_video.too_recent');
        } else if (isFarPast) {
            errorMessage = t('content.find_video.old_videos');
        }
    }

    const isError = isTimeInvalid || isTooLong || isTooRecent;
    const isActionDisabled = !startDate || !endDate || isTimeInvalid || isTooLong || isTooRecent || isLoading;

    const handleLocateDevices = () => {
        if (!!startDate && !!endDate) {
            setSelectedDevice(undefined);
            setIsLoading(true);
            api.apiV0DeviceLocateGet({
                timeFrom: startDate.set('second', 0).set('millisecond', 0).toISOString(),
                timeTo: endDate.set('second', 0).set('millisecond', 0).toISOString(),
                latitude: position.lat,
                longitude: position.lng,
                radius: radius,
            })
                .then((res) => {
                    const locationsByDevice: Record<string, DeviceLocation[]> = res.data.reduce(
                        (acc: Record<string, DeviceLocation[]>, location: DeviceLocation) => {
                            acc[location.device.license_plate] ??= [];
                            acc[location.device.license_plate].push(location);
                            return acc;
                        },
                        {} as Record<string, DeviceLocation[]>,
                    );
                    for (const device of Object.keys(locationsByDevice)) {
                        locationsByDevice[device].sort((a, b) => a.timestamp - b.timestamp);
                    }
                    const routesByDevice: Record<string, [string, number, CallableFunction][]> = Object.fromEntries(
                        Object.entries(locationsByDevice).map(([device, locations]) => [
                            device,
                            locations.map((location) => [
                                `${location.latitude}:${location.longitude}`,
                                0,
                                () => palette.accent,
                            ]),
                        ]),
                    );
                    setRoutes(routesByDevice);
                    setLocatedDevices(
                        Object.keys(Object.fromEntries(res.data.map((sample) => [sample.device.license_plate, 0]))),
                    );
                    setIsLoading(false);
                })
                .catch(() => {
                    setAlert({ message: t('content.find_video.locate_vehicles_error'), type: 'error', duration: 6000 });
                    setRoutes({});
                    setLocatedDevices([]);
                    setIsLoading(false);
                });
        }
    };

    let vehicleListContent = null;
    if (isLoading) {
        vehicleListContent = (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
            </Box>
        );
    } else if (locatedDevices) {
        if (locatedDevices.length > 0) {
            vehicleListContent = (
                <List disablePadding>
                    {locatedDevices.map((device: string) => {
                        return (
                            <ListItem key={device} disableGutters disablePadding>
                                <ListItemButton
                                    id={`vehicle-${device}`}
                                    selected={device === selectedDevice}
                                    onClick={() => setSelectedDevice(device)}
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <LocalShippingIcon
                                        fontSize="small"
                                        sx={{
                                            mr: 1,
                                            color: device === selectedDevice ? 'primary.main' : 'primary.light',
                                        }}
                                    />
                                    <Typography>{device}</Typography>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            );
        } else {
            vehicleListContent = (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Typography id="no-vehicles-found-msg" variant="overline">
                        {t('content.find_video.no_vehicles_found')}
                    </Typography>
                </Box>
            );
        }
    }

    const datePickerFromProps = {
        label: t('content.find_video.from'),
        value: startDate,
        onChange: (value: Dayjs | null) => {
            if (value !== null) {
                setStartDate(value.second(0).millisecond(0));
            }
        },
        maxDateTime: endDate || dayjs().subtract(14, 'minute'),
        slotProps: {
            textField: {
                id: 'filter-from-field',
                size: 'small',
                error: isError,
                sx: { direction: 'ltr' },
            } as TextFieldProps,
        },
        format: datePickerFormat,
        ampm: profile.locale !== 'il',
        closeOnSelect: false,
    };
    const datePickerToProps = {
        label: t('content.find_video.to'),
        value: endDate,
        onChange: (value: Dayjs | null) => {
            if (value !== null) {
                setEndDate(value.second(0).millisecond(0));
            }
        },
        maxDateTime: dayjs().subtract(14, 'minute'),
        slotProps: {
            textField: {
                id: 'filter-to-field',
                size: 'small',
                error: isError,
                sx: { mt: 1.5, direction: 'ltr' },
            } as TextFieldProps,
        },
        format: datePickerFormat,
        ampm: profile.locale !== 'il',
        closeOnSelect: false,
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'bgColor.main', height: '100%' }}>
            {alertElement}
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                    zIndex: 3000,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <MyLocationIcon sx={{ mr: 0.5 }} />
                    {t('content.find_video.with_location')}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1.5,
                        width: { xs: '100%', sm: '280px' },
                        flex: isMobile ? 1 : undefined,
                        height: { xs: '100%', sm: '550px' },
                        flexShrink: 0,
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.languages[0]}>
                        <ResponsiveDateTimePicker {...datePickerFromProps} />
                        <ResponsiveDateTimePicker {...datePickerToProps} />
                    </LocalizationProvider>
                    {errorMessage ? (
                        <Typography variant="body2" sx={{ mt: 1, color: 'error.main' }} id="filter-error-message">
                            {errorMessage}
                        </Typography>
                    ) : null}
                    <TextField
                        id="radius-dropdown"
                        label={t('content.find_video.radius')}
                        size="small"
                        select
                        value={radius}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRadius(parseInt(e.target.value))}
                        sx={{ my: 1.5 }}
                    >
                        {[100, 200, 300, 400, 500].map((option) => (
                            <MenuItem id={`radius-${option}`} key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button
                        id="btn-locate-vehicles"
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={isActionDisabled}
                        onClick={handleLocateDevices}
                    >
                        {t('content.find_video.locate_vehicles')}
                    </Button>
                    <Divider sx={{ mt: 1.5 }} />
                    <Box
                        sx={{
                            minHeight: '75px',
                            overflowY: { xs: 'visible', sm: 'scroll' },
                            height: '100%',
                        }}
                    >
                        {vehicleListContent}
                    </Box>
                    <Button
                        id="btn-select-vehicle"
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={selectedDevice === undefined}
                        onClick={() => {
                            props.setDate(startDate);
                            props.setLicensePlate(selectedDevice);
                            props.onClose();
                        }}
                    >
                        {t('content.find_video.select_vehicle')}
                    </Button>
                </Box>
                <Box sx={{ width: '100%', flexShrink: isMobile ? 0 : undefined, height: { xs: '250px', sm: '550px' } }}>
                    <SearchCenterComponent
                        position={position}
                        setPosition={setPosition}
                        radius={radius}
                        route={selectedDevice ? routes[selectedDevice] : []}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default FindByLocation;
