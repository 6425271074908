import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button, TrackedMenuItem as MenuItem } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import { StyledMenu } from '../Users/MuiStyled';

interface ActionsMenuProps {
    filter: 'active' | 'inactive';
    numberOfDrivers: number;
    areDriversSelected: boolean;
    onExport: CallableFunction;
    onUpload: CallableFunction;
    onGetBadges: CallableFunction;
    handleSetActiveState: CallableFunction;
}

const ActionsMenu: React.FC<ActionsMenuProps> = (props: ActionsMenuProps) => {
    const anchorEl = useRef(null);
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const { profile } = useProfile();

    return (
        <>
            <Button
                id="actions-menu-btn"
                data-testid="actions-menu-btn"
                variant="contained"
                disableElevation
                onClick={() => setOpen((prev) => !prev)}
                endIcon={<KeyboardArrowDownIcon />}
                buttonRef={anchorEl}
            >
                {t('content.drivers.actions')}
            </Button>
            <StyledMenu id="actions-menu" anchorEl={anchorEl.current} open={open} onClose={() => setOpen(false)}>
                <MenuItem
                    id="export-menu-item"
                    disableRipple
                    onClick={() => {
                        setOpen(false);
                        props.onExport();
                    }}
                >
                    <Typography>{t('content.drivers.export_csv')}</Typography>
                </MenuItem>
                {props.filter === 'active' ? (
                    <MenuItem
                        id="upload-menu-item"
                        data-testid="upload-menu-item"
                        disableRipple
                        onClick={() => {
                            setOpen(false);
                            props.onUpload();
                        }}
                    >
                        <Typography>{t('content.drivers.upload_csv.header')}</Typography>
                    </MenuItem>
                ) : null}
                {profile.customer.settings.use_qr_identification && props.numberOfDrivers > 0 ? (
                    <MenuItem
                        id="get-badges-menu-item"
                        disableRipple
                        onClick={() => {
                            setOpen(false);
                            props.onGetBadges(true);
                        }}
                    >
                        <Typography>{t('content.drivers.get_badges')}</Typography>
                    </MenuItem>
                ) : null}
                {props.areDriversSelected ? (
                    <MenuItem
                        id="change-status-menu-item"
                        data-testid="change-status-menu-item"
                        disableRipple
                        onClick={() => {
                            setOpen(false);
                            props.filter === 'active'
                                ? props.handleSetActiveState(false)
                                : props.handleSetActiveState(true);
                        }}
                    >
                        <Typography>
                            {t(`content.drivers.${props.filter === 'active' ? 'deactivate' : 'activate'}`)}
                        </Typography>
                    </MenuItem>
                ) : null}
            </StyledMenu>
        </>
    );
};

export default ActionsMenu;
