import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.min.js';
import * as L from 'leaflet';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';

import useIsMobile from '../../../../hooks/isMobile';
import palette from '../../../ColorPalette';
import { GeofenceGeoJSON } from '../FleetOverview';

export const DEAFULT_COLOR = palette.accent;

const hebrewTranslation = {
    tooltips: {
        firstVertex: 'לחצו כדי למקם את הנקודה הראשונה',
        continueLine: 'לחצו כדי להמשיך לצייר',
        finishPoly: 'לחצו על הנקודה הראשונה כדי לסיים',
    },
    actions: {
        finish: 'סיום',
        cancel: 'ביטול',
        removeLastVertex: 'הסרת הנקודה האחרונה',
    },
    buttonTitles: {
        drawPolyButton: 'ציור צורה',
        editButton: 'עריכה',
        dragButton: 'גרירה',
    },
};

interface GeofenceMapProps {
    geofences: GeofenceGeoJSON[];
    hoveredGeofence?: number;
    selectedGeofence?: number;
    setSelectedGeofence: CallableFunction;
    drawnGeofence?: GeofenceGeoJSON;
    setDrawnGeofence: CallableFunction;
    setIsMapInEditMode: CallableFunction;
    previewColor?: string;
}

const GeofenceMap: React.FC<GeofenceMapProps> = ({
    geofences,
    hoveredGeofence,
    selectedGeofence,
    setSelectedGeofence,
    drawnGeofence,
    setDrawnGeofence,
    setIsMapInEditMode,
    previewColor,
}) => {
    const map = useMap();
    const isMobile = useIsMobile();
    const { i18n } = useTranslation();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createHandler = (e: any) => {
        const drawnLayer = e.layer.toGeoJSON();
        drawnLayer.properties.id = 0;
        setDrawnGeofence(drawnLayer);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editHandler = (e: any) => {
        setDrawnGeofence(e.layer.toGeoJSON());
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleToggleEditMode = (e: any) => {
        setIsMapInEditMode(e.enabled);
    };

    useEffect(() => {
        if (!map) return;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fullscreenControl = new (L.Control as any).Fullscreen(true);
        map.addControl(fullscreenControl);

        if (i18n.languages[0] === 'he') {
            map.pm.setLang('hebrew', hebrewTranslation);
        }

        if (!isMobile) {
            const isListView = !selectedGeofence && !drawnGeofence;
            map.pm.addControls({
                position: 'topleft',
                drawMarker: false,
                drawCircleMarker: false,
                drawPolyline: false,
                drawRectangle: false,
                drawPolygon: isListView,
                drawCircle: false,
                drawText: false,
                editMode: !isListView,
                dragMode: !isListView,
                cutPolygon: false,
                rotateMode: false,
                removalMode: false,
            });
        }

        map.pm.setGlobalOptions({
            allowSelfIntersection: false,
            removeLayerBelowMinVertexCount: false,
            templineStyle: { color: DEAFULT_COLOR },
            hintlineStyle: { color: DEAFULT_COLOR },
        });

        let polygons = geofences;
        if (selectedGeofence) {
            polygons = geofences.filter((geofence) => geofence.properties.id === selectedGeofence);
        }
        if (drawnGeofence) {
            polygons = [drawnGeofence];
        }

        for (const polygon of polygons) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            L.geoJSON(polygon).eachLayer((layer: any) => {
                const featureColor = layer.feature?.properties?.color;
                const featureId = layer.feature?.properties?.id;
                const isHighlighed = featureId === selectedGeofence || featureId === hoveredGeofence || featureId === 0;
                if (isHighlighed) {
                    layer.setStyle({ color: previewColor || featureColor || DEAFULT_COLOR });
                } else {
                    layer.setStyle({ color: featureColor || DEAFULT_COLOR, opacity: 0.5 });
                }
                layer.on('pm:update', editHandler);
                layer.on('pm:dragend', editHandler);
                layer.on('click', () => {
                    if (!map.pm.globalDragModeEnabled()) {
                        setSelectedGeofence(polygon.properties.id);
                    }
                });
                layer.on('mouseover', () => {
                    layer.setStyle({ opacity: 1 });
                });
                layer.on('mouseout', () => {
                    if (!isHighlighed) {
                        layer.setStyle({ opacity: 0.5 });
                    }
                });
                layer.addTo(map);
            });
        }

        map.on('pm:create', createHandler);
        map.on('pm:globaleditmodetoggled', handleToggleEditMode);
        map.on('pm:globaldragmodetoggled', handleToggleEditMode);

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const layers: any = map.pm.getGeomanLayers();
            for (const layer of layers) {
                if (layer.feature !== undefined || layer._drawnByGeoman) {
                    layer.remove();
                }
            }
            map.off('pm:create', createHandler);
            map.off('pm:globaleditmodetoggled', handleToggleEditMode);
            map.off('pm:globaldragmodetoggled', handleToggleEditMode);
            map.removeControl(fullscreenControl);
            map.pm.removeControls();
        };
    }, [map, geofences, hoveredGeofence, selectedGeofence, drawnGeofence, , previewColor, isMobile]);

    useEffect(() => {
        const coordinatess = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const geomanLayers: any = map.pm.getGeomanLayers();
        for (const layer of geomanLayers) {
            if (layer.feature !== undefined || layer._drawnByGeoman) {
                coordinatess.push(layer.toGeoJSON().geometry.coordinates[0].map((c: number[]) => c.reverse()));
            }
        }
        if (coordinatess.flat().length > 0) {
            map.fitBounds(L.latLngBounds(coordinatess.flat()).pad(0.1));
        }
    }, [selectedGeofence, drawnGeofence]);

    return null;
};

export default GeofenceMap;
