import { DRIVERS } from '../DriverLogs/driversData';
import { ViolationData } from './ViolationsComponent';

export const VIOLATIONS_DATA: ViolationData[] = [
    { device: '11-222-33', driver: DRIVERS[1], date: '2023-02-02', violations: ['break', 'drive', 'shift', 'cycle'] },
    { device: '11-222-33', driver: DRIVERS[0], date: '2023-01-15', violations: ['drive'] },
    { device: '11-222-33', driver: DRIVERS[1], date: '2023-02-12', violations: ['shift', 'cycle'] },
    { device: '11-222-33', driver: DRIVERS[3], date: '2023-01-01', violations: ['cycle'] },
    { device: '11-222-33', driver: DRIVERS[3], date: '2023-02-04', violations: ['drive', 'shift'] },
    { device: '11-222-33', driver: DRIVERS[3], date: '2023-02-01', violations: ['break'] },
];
