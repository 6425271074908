import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button, TrackedMenuItem as MenuItem } from '../../../components/TrackedComponents';
import { StyledMenu } from '../Users/MuiStyled';

interface ActionsMenuProps {
    onExport: CallableFunction;
    onUpload: CallableFunction;
}

const ActionsMenu: React.FC<ActionsMenuProps> = (props: ActionsMenuProps) => {
    const anchorEl = useRef(null);
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <>
            <Button
                id="actions-menu-btn"
                data-testid="actions-menu-btn"
                variant="contained"
                disableElevation
                onClick={() => setOpen((prev) => !prev)}
                endIcon={<KeyboardArrowDownIcon />}
                buttonRef={anchorEl}
            >
                {t('content.drivers.actions')}
            </Button>
            <StyledMenu id="actions-menu" anchorEl={anchorEl.current} open={open} onClose={() => setOpen(false)}>
                <MenuItem
                    id="export-menu-item"
                    disableRipple
                    onClick={() => {
                        setOpen(false);
                        props.onExport();
                    }}
                >
                    <Typography>{t('content.drivers.export_csv')}</Typography>
                </MenuItem>
                <MenuItem
                    id="upload-menu-item"
                    data-testid="upload-menu-item"
                    disableRipple
                    onClick={() => {
                        setOpen(false);
                        props.onUpload();
                    }}
                >
                    <Typography>{t('content.drivers.upload_csv.header')}</Typography>
                </MenuItem>
            </StyledMenu>
        </>
    );
};

export default ActionsMenu;
