import { Box, Chip, Typography } from '@mui/material';
import { GridColDef, GridFilterItem } from '@mui/x-data-grid-pro';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import {
    TrackedButton as Button,
    TrackedLink as Link,
    TrackedDataGrid as StyledDataGrid,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useDevices from '../../../hooks/devices';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { useQuery as useQueryParams } from '../../../hooks/query';
import { reportsPageDefs } from '../../../utils/Pages';
import { daysNames, ordinalSuffix } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { gridLocalization } from '../OTA/MuiDeviceTable';
import ReportAlertDetailsComponent, {
    DEVICE_FILTER_OPTIONS,
    REPORT_TYPE_OPTIONS,
    SCHEDULING_OPTIONS,
    SCOPE_OPTIONS,
    deviceFilter,
    getExtraFilters,
} from './ReportDetails';
import { AlertReport, Report } from './ReportOptions';
import { ASSIGNEE_OPTIONS } from './ReportOptions';

export const EMPTY_REPORT: Report = {
    pk: 0,
    report_type: 'SAFETY_EVENTS',
    filters: {},
    is_report: true,
    report_scope: 'WEEKLY',
    report_day: 0,
    report_time: '00:00:00',
    active: true,
};

const ReportAlertComponent: React.FC = () => {
    const [showNewRerport, setShowNewReport] = useState<boolean>(false);
    const { devices, isLoading: isLoadingDevices, isError: isDeviceError } = useDevices();
    const history = useHistory();
    const match = useRouteMatch();
    const { reportType, reportAlertId } = useParams<{ reportType: string; reportAlertId: string }>();
    const { t, i18n } = useTranslation();
    const { api } = useApi();
    const { profile } = useProfile();
    const isMobile = useIsMobile();
    const getReports = async () => {
        const res = await api.apiV0ReportGet();
        return res.data;
    };
    const { data, isLoading, isError } = useQuery({ queryKey: ['reports'], queryFn: getReports });
    const queryClient = useQueryClient();
    const query = useQueryParams();

    const getGeofences = async () => {
        const res = await api.apiV0GeofenceGet();
        return res.data;
    };
    const {
        data: geofences,
        isLoading: isLoadingGeofences,
        isError: isGeofenceError,
    } = useQuery({ queryKey: ['geofences'], queryFn: getGeofences });
    const geofenceIdToName: Record<string, string> = Object.fromEntries(
        (geofences || []).map((geofence) => [geofence.id.toString(), geofence.name]),
    );

    const items: GridFilterItem[] = [];
    if (query.has('type')) {
        items.push({
            field: 'type',
            operator: 'is',
            value: query.get('type')?.toLocaleUpperCase(),
        });
    }

    const reportAlerts: AlertReport[] = useMemo(() => {
        const alertReportKey = (a: AlertReport) => `${a.pk}.${a.is_report}`;
        let newReportAlerts: AlertReport[] = [];
        if (data !== undefined) {
            newReportAlerts = ([...data.alerts, ...data.reports] as AlertReport[]).sort((a, b) =>
                alertReportKey(a as AlertReport).localeCompare(alertReportKey(b as AlertReport)),
            );
        }
        return newReportAlerts;
    }, [data, reportType, reportAlertId]);

    useEffect(() => {
        if (reportAlerts.length > 0 && reportType !== undefined && reportAlertId !== undefined) {
            const isReport = reportType === SCHEDULING_OPTIONS.REPORT.toLocaleLowerCase();
            const selectedReportAlert = reportAlerts.find(
                (r) => r.is_report === isReport && r.pk === parseInt(reportAlertId),
            );
            if (selectedReportAlert === undefined) {
                history.replace(`/reports${[...query.keys()].length > 0 ? `?${query.toString()}` : ''}`);
            }
        }
    }, [reportAlerts, reportType, reportAlertId]);

    const ALLOW_ALL_REPORT_TYPES = [...reportsPageDefs.permissions.L1, ...reportsPageDefs.permissions.L2];

    const empty_report = JSON.parse(
        JSON.stringify({
            ...EMPTY_REPORT,
            report_type: ALLOW_ALL_REPORT_TYPES.includes(profile.role)
                ? REPORT_TYPE_OPTIONS.SAFETY_EVENTS
                : REPORT_TYPE_OPTIONS.ACCIDENTS_REPORT,
        }),
    );

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${reportsPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        if (reportType !== undefined) {
            const isValidType =
                reportType === SCHEDULING_OPTIONS.REPORT.toLocaleLowerCase() ||
                reportType === SCHEDULING_OPTIONS.ALERT.toLocaleLowerCase();
            if (!isValidType || reportAlertId === undefined) {
                setShowNewReport(false);
                history.replace(`/reports${[...query.keys()].length > 0 ? `?${query.toString()}` : ''}`);
            }
        }
    }, []);

    let selectedReportAlert;
    if (reportType !== undefined && reportAlertId !== undefined) {
        const isReport = reportType === SCHEDULING_OPTIONS.REPORT.toLocaleLowerCase();
        selectedReportAlert = reportAlerts.find((r) => r.is_report === isReport && r.pk === parseInt(reportAlertId));
    }

    const getScheduleType = (a: AlertReport) => {
        if (a.is_report) {
            const details = a as Report;
            return t(`content.reports.form.scope.${details.report_scope.toLocaleLowerCase()}`, details.report_scope);
        } else {
            return t('content.reports.alert');
        }
    };

    const getReportScope = (a: AlertReport) => {
        const details = a as Report;
        const currentDate = new Date();
        currentDate.setUTCHours(parseInt(details.report_time.slice(0, 2)));
        currentDate.setUTCMinutes(parseInt(details.report_time.slice(3, 5)));
        const formatInt = (i: number) => i.toString().padStart(2, '0');
        return `${
            details.report_scope != SCOPE_OPTIONS.DAILY
                ? `${t('time.every')} ${
                      details.report_scope == SCOPE_OPTIONS.WEEKLY
                          ? t(`time.day.${daysNames[details.report_day].toLocaleLowerCase()}`)
                          : `${i18n.languages[0] == 'en' ? ordinalSuffix(details.report_day) : details.report_day}`
                  }`
                : `${t('time.every')} ${t('time.day.header').toLocaleLowerCase()}`
        } ${t('time.at', { time: `${formatInt(currentDate.getHours())}:${formatInt(currentDate.getMinutes())}` })}`;
    };

    const formatScope = (a: AlertReport) => {
        if (a.is_report) {
            return `${getScheduleType(a)}: ${getReportScope(a)}`;
        } else {
            return getScheduleType(a);
        }
    };

    const renderScope = (a: AlertReport) => {
        if (a.is_report) {
            return (
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <span style={{ fontWeight: 'bold' }}>{getScheduleType(a)}</span>:&nbsp;
                    <span>{getReportScope(a)}</span>
                </div>
            );
        } else {
            return (
                <span style={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {getScheduleType(a)}
                </span>
            );
        }
    };

    const getGroupedFilters = (tKey: string, key: string, values: Array<string>) => {
        const formattedKey = t(`content.reports.form.${tKey}.${key.toLocaleLowerCase()}`, key);
        if (values.length === 0) {
            return { key: formattedKey };
        } else if (values.length > 1) {
            return { key: `${values.length} ${t(`content.reports.form.${tKey}.${key.toLocaleLowerCase()}_plural`)}` };
        } else {
            return {
                key: formattedKey,
                value: t(`content.reports.form.filter.${values[0].toLocaleLowerCase()}`, values[0]),
            };
        }
    };

    const getUngroupedFilters = (tKey: string, key: string, values: Array<string>) => {
        let formattedValues;
        if (key != DEVICE_FILTER_OPTIONS.ALL) {
            if (key.toLocaleLowerCase() === 'assignee' && values.includes(ASSIGNEE_OPTIONS.CUSTOMER)) {
                formattedValues = [profile.customer.name];
            } else if (key.toLocaleLowerCase() === 'severity' && values.length > 0) {
                formattedValues =
                    values[0] === 'CUSTOM'
                        ? [t('content.reports.form.filter.over', { value: values[1] })]
                        : [t(`content.reports.form.filter.${values[0].toLocaleLowerCase()}`)];
            } else if (key.toLocaleLowerCase() === 'geofence' && values.length > 0) {
                formattedValues = values.map((s: string) => geofenceIdToName[s]);
            } else {
                formattedValues = values.map((s: string) =>
                    t(`content.reports.form.filter.${s.toLocaleLowerCase()}`, s),
                );
            }
        }
        return {
            key: t(`content.reports.form.${tKey}.${key.toLocaleLowerCase()}`, key),
            values: formattedValues,
        };
    };

    const formatFilters = (tKey: string, key: string, values: Array<string>, groupValues = false) => {
        if (groupValues) {
            const res = getGroupedFilters(tKey, key, values);
            return res.value ? `${res.key}: ${res.value}` : res.key;
        }
        const res = getUngroupedFilters(tKey, key, values);
        return res.values ? `${res.key}: ${res.values.join(', ')}` : res.key;
    };

    const renderFilters = (tKey: string, key: string, values: Array<string>, groupValues = false) => {
        if (groupValues) {
            const res = getGroupedFilters(tKey, key, values);
            return (
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{res.key}</span>
                    {res.value && <span>: {res.value}</span>}
                </div>
            );
        }
        const res = getUngroupedFilters(tKey, key, values);
        return (
            <Box key={key} sx={{ display: 'flex' }}>
                <span style={{ fontWeight: 'bold', paddingTop: '4px', whiteSpace: 'nowrap' }}>{res.key}</span>
                {res.values && (
                    <>
                        <span style={{ paddingTop: '4px', marginRight: '4px' }}>:</span>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            {res.values.map((value) => {
                                return (
                                    <Chip
                                        size="small"
                                        label={<Typography fontSize={12}>{value}</Typography>}
                                        key={value}
                                        sx={{ mr: 0.5, my: 0.25, cursor: 'pointer' }}
                                    />
                                );
                            })}
                        </Box>
                    </>
                )}
            </Box>
        );
    };

    const formatExtraFilters = (a: AlertReport) => {
        const filters = getExtraFilters(a);
        return filters.map(([key, values]) => formatFilters('extra_filters', key, values)).join('; ');
    };

    const renderExtraFilters = (a: AlertReport) => {
        const filters = getExtraFilters(a);
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {filters.map(([key, values]) => renderFilters('extra_filters', key, values))}
            </Box>
        );
    };

    const gridColumns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'type',
                headerName: t('content.reports.columns.type'),
                type: 'singleSelect',
                valueOptions: Object.keys(REPORT_TYPE_OPTIONS).map((option) => ({
                    value: option,
                    label: t(`content.reports.form.type.${option.toLocaleLowerCase()}`),
                })),
                valueFormatter: (params) => t(`content.reports.form.type.${params.value.toLocaleLowerCase()}`),
                minWidth: 150,
            },
            {
                field: 'time',
                headerName: t('content.reports.columns.time'),
                sortable: false,
                filterable: false,
                renderCell: (params) => renderScope(params.row.reportAlert),
                minWidth: 250,
            },
            {
                field: 'vehicles',
                headerName: t('content.reports.columns.vehicles'),
                sortable: false,
                filterable: false,
                renderCell: (params) => renderFilters('filter', ...deviceFilter(params.row.reportAlert.filters), true),
                minWidth: 150,
            },
            {
                field: 'rules',
                headerName: t('content.reports.columns.rules'),
                sortable: false,
                renderCell: (params) => renderExtraFilters(params.row.reportAlert),
                minWidth: 300,
                flex: 1,
            },
            {
                field: 'method',
                headerName: t('content.reports.form.method.header'),
                type: 'singleSelect',
                valueOptions: ['EMAIL', 'SMS'].map((option) => {
                    ({ value: option, label: t(`content.reports.form.method.${option.toLocaleLowerCase()}`) });
                }),
                valueFormatter: (params) => t(`content.reports.form.method.${params.value.toLocaleLowerCase()}`),
                minWidth: 80,
            },
            {
                field: 'active',
                headerName: t('content.reports.columns.active'),
                type: 'boolean',
                minWidth: 80,
            },
        ];
    }, [geofences]);

    const rows = useMemo(() => {
        return reportAlerts.map((report) => {
            return {
                id: report.pk,
                type: report.report_type,
                time: formatScope(report),
                vehicles: formatFilters('filter', ...deviceFilter(report.filters), true),
                rules: formatExtraFilters(report),
                active: report.active,
                method: 'method' in report ? report.method : 'EMAIL',
                reportAlert: report,
            };
        });
    }, [reportAlerts, geofences]);

    if (isError || isDeviceError || isGeofenceError) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline" sx={{ mb: -1 }}>
                    {t('content.reports.error')}
                </Typography>
                <Link
                    id="try-again-link"
                    component="button"
                    variant="overline"
                    onClick={() => {
                        if (isError) {
                            queryClient.refetchQueries(['reports']);
                        }
                        if (isDeviceError) {
                            queryClient.refetchQueries(['devices']);
                        }
                        if (isGeofenceError) {
                            queryClient.refetchQueries(['geofences']);
                        }
                    }}
                >
                    {t('content.reports.try_again')}
                </Link>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    maxWidth: '1200px',
                    pb: 2,
                }}
            >
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'end', mb: 1 }}>
                    <Typography variant="h5">{t('navigator.reports')}</Typography>
                    <Button
                        id="new-report-btn"
                        data-testid="new-report-btn"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setShowNewReport(true);
                        }}
                    >
                        {t('content.reports.new')}
                    </Button>
                </Box>
                <StyledDataGrid
                    id="reports-data-grid"
                    loading={isLoading || isLoadingDevices || isLoadingGeofences}
                    initialState={{
                        filter: {
                            filterModel: {
                                items,
                            },
                        },
                    }}
                    onFilterModelChange={(model) => {
                        if (model.items.find((item) => item.field === 'type') === undefined) {
                            history.replace(match.url);
                        }
                    }}
                    getRowHeight={() => 'auto'}
                    columns={gridColumns}
                    rows={rows}
                    density="compact"
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    disableRowSelectionOnClick
                    disableVirtualization={isMobile || process.env.JEST_WORKER_ID !== undefined}
                    getCellClassName={(params) => `${params.field}-cell`}
                    onRowClick={(params) => {
                        history.push(
                            `/reports/${
                                params.row.reportAlert.is_report
                                    ? SCHEDULING_OPTIONS.REPORT.toLocaleLowerCase()
                                    : SCHEDULING_OPTIONS.ALERT.toLocaleLowerCase()
                            }/${params.row.reportAlert.pk}${
                                [...query.keys()].length > 0 ? `?${query.toString()}` : ''
                            }`,
                        );
                    }}
                    slotProps={{
                        columnsPanel: {
                            sx: {
                                '& .MuiDataGrid-panelFooter button:first-child': {
                                    display: 'none',
                                },
                            },
                        },
                    }}
                    localeText={{
                        ...gridLocalization[i18n.languages[0]],
                        filterPanelRemoveAll: t('table.remove_all'),
                        columnMenuManageColumns: t('table.manage_columns'),
                        unpin: t('table.unpin'),
                    }}
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& .MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScrollerContent': {
                            borderBottom: `1px solid ${palette.neutral[400]}`,
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cell--editing': {
                            padding: '0 !important',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-row--dynamicHeight': { minHeight: '41px !important' },
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '6px' },
                        backgroundColor: palette.neutral[50],
                    }}
                />
            </Box>
            {selectedReportAlert !== undefined ? (
                <ReportAlertDetailsComponent
                    details={selectedReportAlert}
                    devices={devices}
                    geofences={geofenceIdToName}
                    onClose={() => {
                        setShowNewReport(false);
                        history.replace(`/reports${[...query.keys()].length > 0 ? `?${query.toString()}` : ''}`);
                    }}
                />
            ) : showNewRerport ? (
                <ReportAlertDetailsComponent
                    details={empty_report}
                    devices={devices}
                    geofences={geofenceIdToName}
                    onClose={() => setShowNewReport(false)}
                />
            ) : null}
        </>
    );
};

export default ReportAlertComponent;
