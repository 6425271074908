import { Box, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceV3, DriverScore, WellnessScore } from '../../../../backendsdk';
import useApi from '../../../../hooks/api';
import { EVENT_TYPE_MAP } from '../../Event/Defs';
import { ScoreAvatar } from '../../Metrics/SafetyWellness/DriverList';
import { INVALID_SCORE } from '../../Metrics/SafetyWellness/MetricsComponent';
import { VehicleCardProps } from '../../Vehicles/VehicleCard';
import VehicleModal from '../../Vehicles/VehicleModal';
import { WellnessCardProps } from '../../Wellness/WellnessCard';
import { behaviorsMap } from '../../Wellness/WellnessComponent';
import WellnessModal from '../../Wellness/WellnessModal';

interface ScoresPanelProps {
    device: DeviceV3;
}

const ScoresPanel: React.FC<ScoresPanelProps> = ({ device }: ScoresPanelProps) => {
    const [driverScore, setDriverScore] = useState<DriverScore>();
    const [isLoadingDriverScore, setIsLoadingDriverScore] = useState<boolean>(true);
    const [wellnessScore, setWellnessScore] = useState<WellnessScore>();
    const [isLoadingWellnessScore, setIsLoadingWellnessScore] = useState<boolean>(true);
    const [isSafetyModalOpen, setIsSafetyModalOpen] = useState<boolean>(false);
    const [isWellnessModalOpen, setIsWellnessModalOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const { api } = useApi();

    useEffect(() => {
        if (device.device.license_plate) {
            api.apiV0DriverScoresLicensePlateGet({ licensePlate: device.device.license_plate })
                .then((res) => setDriverScore(res.data))
                .finally(() => setIsLoadingDriverScore(false));
            api.apiV0WellnessScoresLicensePlateGet({ licensePlate: device.device.license_plate })
                .then((res) => setWellnessScore(res.data))
                .finally(() => setIsLoadingWellnessScore(false));
        }
    }, [device.device.license_plate]);

    let driverModalProps: VehicleCardProps | undefined;
    if (driverScore) {
        // convert the response from the API to the props expected by the VehicleModal
        driverModalProps = {
            timestamp: driverScore.timestamp,
            eventIds: driverScore.event_ids || [],
            accidentEventIds: driverScore.accident_event_ids || [],
            driverName: driverScore.driver_name,
            licensePlate: driverScore.license_plate,
            subFleet: driverScore.sub_fleet,
            score: driverScore.score,
            behaviors: driverScore.event_types.split(',').map((eventType) => EVENT_TYPE_MAP[eventType]),
            mostRecentEvent: driverScore.most_recent_event,
            inactive: driverScore.inactive,
            unknown: driverScore.unknown,
        };
    }
    let wellnessModalProps: WellnessCardProps | undefined;
    if (wellnessScore) {
        wellnessModalProps = {
            timestamp: wellnessScore.timestamp,
            driverName: wellnessScore.driver_name,
            licensePlate: wellnessScore.license_plate,
            subFleet: wellnessScore.sub_fleet,
            score: wellnessScore.score,
            behaviors: wellnessScore.event_types
                .split(',')
                .filter(Boolean)
                .map((b) => behaviorsMap[b] || b),
            eventIds: wellnessScore.event_ids,
            icStatusIds: wellnessScore.in_cabin_wellness_ids,
            ffStatusIds: wellnessScore.front_facing_wellness_ids,
            mostRecentEventTimestamp: wellnessScore.most_recent_event_datetime,
            mostRecentEventId: wellnessScore.most_recent_event_id,
        };
    }

    const scoreAvatarSkeleton = <Skeleton variant="rectangular" width={28} height={28} animation="wave" />;

    return (
        <Box sx={{ display: 'flex' }}>
            {!!driverModalProps && isSafetyModalOpen ? (
                <VehicleModal vehicle={driverModalProps} onClose={() => setIsSafetyModalOpen(false)} initialTab={1} />
            ) : null}
            {!!wellnessModalProps && isWellnessModalOpen ? (
                <WellnessModal
                    vehicle={wellnessModalProps}
                    onClose={() => setIsWellnessModalOpen(false)}
                    initialTab={1}
                />
            ) : null}
            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold', mr: 1 }}>
                    {t('content.fleet.details.scores.safety')}
                </Typography>
                {isLoadingDriverScore ? (
                    scoreAvatarSkeleton
                ) : (
                    <Box
                        id="safety-score-container"
                        onClick={() => {
                            setIsSafetyModalOpen(true);
                        }}
                        sx={{ cursor: 'pointer' }}
                    >
                        <ScoreAvatar
                            score={driverScore?.score}
                            inactive={driverScore?.inactive}
                            unknown={driverScore?.unknown}
                        />
                    </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold', mr: 1 }}>
                    {t('content.fleet.details.scores.wellness')}
                </Typography>
                {isLoadingWellnessScore ? (
                    scoreAvatarSkeleton
                ) : (
                    <Box
                        id="wellness-score-container"
                        onClick={() => {
                            setIsWellnessModalOpen(true);
                        }}
                        sx={{ cursor: 'pointer' }}
                    >
                        <ScoreAvatar score={wellnessScore?.score} inactive={wellnessScore?.score === INVALID_SCORE} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ScoresPanel;
