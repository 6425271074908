import AddCommentIcon from '@mui/icons-material/AddComment';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Grid, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';

import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../../components/TrackedComponents';

export interface AnnotateModalProps {
    loading: boolean;
    onAnnotate: CallableFunction;
    onClose: CallableFunction;
}

const AnnotateModal: React.FC<AnnotateModalProps> = (props: AnnotateModalProps) => {
    const [annotation, setAnnotation] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const validateAnnotation = () => {
        if (annotation.length < 4 || annotation.length > 60) {
            setIsError(true);
            return false;
        }
        return true;
    };

    return (
        <Dialog id="annotate-dialog" open={true} fullWidth={true} maxWidth="xs" onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AddCommentIcon sx={{ mr: 0.5 }} />
                    Annotate Records
                </Box>
            </Box>
            <Paper sx={{ backgroundColor: 'bgColor.main', p: 1, borderRadius: 0 }}>
                <Card sx={{ p: 2 }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <TextField
                            id="annotation-field"
                            label="Annotation"
                            size="small"
                            multiline
                            error={isError}
                            helperText={isError ? 'Annotation must be between 4 and 60 characters' : ''}
                            rows={2}
                            value={annotation}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAnnotation(e.target.value)}
                        />
                    </Box>
                </Card>
                <Grid container columns={2} columnSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={1}>
                        <Button
                            id="btn-cancel-annotate"
                            fullWidth
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => props.onClose()}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            id="btn-annotate"
                            fullWidth
                            data-testid="btn-annotate"
                            color="secondary"
                            variant="contained"
                            size="small"
                            disabled={props.loading}
                            onClick={() => {
                                if (validateAnnotation()) {
                                    props.onAnnotate({ comment: annotation });
                                }
                            }}
                        >
                            Annotate
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    );
};

export default AnnotateModal;
