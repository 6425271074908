import CloseIcon from '@mui/icons-material/Close';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SendIcon from '@mui/icons-material/Send';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CoachingOverviewSafetyScoreSet, ShortCoachSession } from '../../../backendsdk';
import { TrackedDialog as Dialog, TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import palette from '../../ColorPalette';
import { ScoreAvatar } from '../Metrics/SafetyWellness/DriverList';

interface CoachingEffectivenessModalProps {
    open: boolean;
    onClose: CallableFunction;
    effectivenessData: CoachingOverviewSafetyScoreSet[];
    autoSessions: ShortCoachSession[];
    tabsMap: Record<string, number>;
    setTab: CallableFunction;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1.5),
}));

const HeaderTypography = styled(Typography)(() => ({
    lineHeight: 1,
}));

const CoachingEffectivenessModal: React.FC<CoachingEffectivenessModalProps> = (
    props: CoachingEffectivenessModalProps,
) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Dialog
            id="effectiveness-dialog"
            data-testid="effectiveness-dialog"
            open={props.open}
            onClose={() => props.onClose()}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '1000px',
                    },
                },
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 4, top: 4 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
                    {t('content.coach.overview.effectiveness.header')}
                </Box>
            </Box>
            <TableContainer>
                <Table stickyHeader>
                    <colgroup>
                        <col />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="12%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                <HeaderTypography variant="overline">
                                    {t('content.coach.overview.effectiveness.driver_and_vin')}
                                </HeaderTypography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <HeaderTypography variant="overline">
                                    {t('content.coach.overview.effectiveness.on_completion')}
                                </HeaderTypography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <HeaderTypography variant="overline">
                                    {t('content.coach.overview.effectiveness.after_two_weeks')}
                                </HeaderTypography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <HeaderTypography variant="overline">
                                    {t('content.coach.overview.effectiveness.after_one_month')}
                                </HeaderTypography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <HeaderTypography variant="overline">
                                    {t('content.coach.overview.effectiveness.after_three_months')}
                                </HeaderTypography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <HeaderTypography variant="overline">
                                    {t('content.coach.overview.effectiveness.view_session')}
                                </HeaderTypography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <HeaderTypography variant="overline">
                                    {t('content.coach.overview.effectiveness.send_new_session')}
                                </HeaderTypography>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.effectivenessData.map((row, idx) => {
                            const driverName = !!row.driver_name
                                ? row.driver_name
                                : t('content.coach.overview.effectiveness.unknown_driver');
                            const scores = [row.on_completion, row.two_weeks, row.one_month, row.three_months];
                            const autoSession = props.autoSessions.find(
                                (session) =>
                                    session.driver_name === row.driver_name &&
                                    session.license_plate === row.license_plate,
                            );
                            const newSessionButton = (
                                <IconButton
                                    id={`send-session-btn-${idx}`}
                                    disabled={autoSession === undefined}
                                    onClick={() => {
                                        props.setTab(props.tabsMap['suggested']);
                                        history.push(`/coaching/${autoSession?.session_id}/send`);
                                        props.onClose();
                                    }}
                                >
                                    <SendIcon />
                                </IconButton>
                            );
                            return (
                                <StyledTableRow key={row.session_id}>
                                    <StyledTableCell component="th" scope="row">
                                        <Typography>
                                            <Typography
                                                component="span"
                                                sx={{ color: !row.driver_name ? palette.neutral[700] : undefined }}
                                            >
                                                {driverName}
                                            </Typography>
                                            {` / ${row.license_plate}`}
                                        </Typography>
                                    </StyledTableCell>
                                    {scores.map((score, idx) => (
                                        <StyledTableCell key={`score-${idx}`}>
                                            {score !== undefined ? (
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <ScoreAvatar
                                                        score={score.score}
                                                        inactive={score.reason === 'inactive'}
                                                        unknown={score.reason === 'unknown'}
                                                    />
                                                </Box>
                                            ) : null}
                                        </StyledTableCell>
                                    ))}
                                    <StyledTableCell>
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Tooltip title={t('content.coach.overview.effectiveness.view_session')}>
                                                <Box>
                                                    <IconButton
                                                        id={`view-session-btn-${idx}`}
                                                        onClick={() => {
                                                            props.setTab(props.tabsMap['completed']);
                                                            history.push(`/coaching/${row.session_id}`);
                                                            props.onClose();
                                                        }}
                                                    >
                                                        <ContentPasteSearchIcon />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            {!!autoSession ? (
                                                <Tooltip
                                                    title={t('content.coach.overview.effectiveness.send_new_session')}
                                                >
                                                    {newSessionButton}
                                                </Tooltip>
                                            ) : (
                                                newSessionButton
                                            )}
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Dialog>
    );
};

export default CoachingEffectivenessModal;
