import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CheckIcon from '@mui/icons-material/Check';
import SortIcon from '@mui/icons-material/Sort';
import { Box, CircularProgress, Grid, Tabs, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { CoachMetricByType, EventDetails, ShortCoachSession, TripDetails } from '../../../backendsdk';
import { TabPanel, a11yProps } from '../../../components/Tab';
import {
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
    TrackedMenuItem as MenuItem,
    TrackedTab as Tab,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useDevices from '../../../hooks/devices';
import useDrivers from '../../../hooks/drivers';
import { useImages } from '../../../hooks/images';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { getCustomer } from '../../../utils/customer';
import palette from '../../ColorPalette';
import { SortingModel } from '../Overview/FleetOverview';
import { OverviewStyledMenu } from '../Overview/SortMenu';
import CoachingTab from './CoachingTab';
import ScoresTab from './ScoresTab';
import { VehicleCardProps } from './VehicleCard';
import VehicleModalDetails from './VehicleModalDetails';
import VehicleModalHeader from './VehicleModalHeader';
import VehicleModalSidebar from './VehicleModalSidebar';

const REQUEST_LIMIT = 10;

export enum STATUS {
    PENDING = 0,
    SAVED = 1,
    DISMISSED = 2,
}

export const SORTING_OPTIONS = ['datetime', 'severity', 'event_type'];

export const sortingFunctions: Record<string, CallableFunction> = {
    datetime: (events: EventDetails[], order: 'asc' | 'desc') => {
        const sortedEvents = [...events];
        sortedEvents.sort((a, b) => {
            if (order === 'asc') {
                return a.timestamp - b.timestamp;
            } else {
                return b.timestamp - a.timestamp;
            }
        });
        return sortedEvents;
    },
    severity: (events: EventDetails[], order: 'asc' | 'desc') => {
        const sortedEvents = [...events];
        sortedEvents.sort((a, b) => {
            if (order === 'asc') {
                return a.severity - b.severity;
            } else {
                return b.severity - a.severity;
            }
        });
        return sortedEvents;
    },
    event_type: (events: Event[], order: 'asc' | 'desc') => {
        const sortedEvents = [...events];
        sortedEvents.sort((a, b) => {
            if (order === 'asc') {
                return a.type.localeCompare(b.type);
            } else {
                return b.type.localeCompare(a.type);
            }
        });
        return sortedEvents;
    },
};

interface VehicleModalBaseProps {
    onClose: CallableFunction;
    initialTab?: number;
    defaultSorting?: SortingModel;
}

interface VehicleModalWithVehicle extends VehicleModalBaseProps {
    vehicle: VehicleCardProps;
    licensePlate?: never;
}

interface VehicleModalWithLicensePlate extends VehicleModalBaseProps {
    licensePlate: string;
    vehicle?: never;
}

export type VehicleModalProps = VehicleModalWithVehicle | VehicleModalWithLicensePlate;

const VehicleModal: React.FC<VehicleModalProps> = (props: VehicleModalProps) => {
    const [vehicle, setVehicle] = useState<VehicleCardProps | undefined>(props.vehicle);
    const [selectedEvent, setSelectedEvent] = useState<number>();
    const [details, setDetails] = useState<EventDetails>();
    const [loadingDetails, setLoadingDetails] = useState<boolean>(true);
    const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(true);
    const [isEventsError, setIsEventsError] = useState<boolean>(false);
    const [isLoadingSessions, setIsLoadingSessions] = useState<boolean>(true);
    const [isSessionsError, setIsSessionsError] = useState<boolean>(false);
    const [coachingSessions, setCoachingSessions] = useState<ShortCoachSession[]>();
    const { api, agencyApi } = useApi();
    const getImage = (imageKey: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_type, id, _licensePlate] = imageKey.split(':', 3);
        const eventId = parseInt(id);
        if (props.vehicle?.eventIds.includes(eventId)) {
            return api.apiV0EventEventIdImageGet({ eventId }, { responseType: 'blob' });
        } else {
            return agencyApi.agencyV0EventEventIdImageGet({ eventId }, { responseType: 'blob' });
        }
    };
    const [eventImages, addToQueue] = useImages(REQUEST_LIMIT, getImage);
    const [sidebarTab, setSidebarTab] = useState<STATUS>(STATUS.PENDING);
    const [eventStatuses, setEventStatuses] = useState<Record<number, STATUS>>({});
    const [events, setEvents] = useState<EventDetails[]>([]);
    const [metadata, setMetadata] = useState<string>();
    const [autoSessionId, setAutoSessionId] = useState<number | null>();
    const [tab, setTab] = useState<number>(props.initialTab || 0);
    const [selectedSorting, setSelectedSorting] = useState<SortingModel>(
        props.defaultSorting || {
            field: 'severity',
            order: 'desc',
        },
    );
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [metricData, setMetricData] = useState<CoachMetricByType>();
    const [isLoadingMetrics, setIsLoadingMetrics] = useState<boolean>(true);
    const [isErrorMetrics, setIsErrorMetrics] = useState<boolean>(false);
    const [alertElement, setAlert] = useAlert();
    const isMobile = useIsMobile();
    const { profile } = useProfile();
    const anchorEl = useRef(null);
    const { t } = useTranslation();
    const { devices } = useDevices();
    const { getDriver } = useDrivers();

    const { data: customer, isLoading: isLoadingUsers } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['customer'],
        queryFn: () => getCustomer(api),
    });
    const customerUsers = customer?.users || [];

    let licensePlate = '';
    if (vehicle !== undefined) {
        licensePlate = vehicle.licensePlate;
    } else if (props.licensePlate !== undefined) {
        licensePlate = props.licensePlate;
    }

    const sortedEvents: EventDetails[] = useMemo(
        () => sortingFunctions[selectedSorting.field](events, selectedSorting.order),
        [events, selectedSorting],
    );

    const eventsByStatus: Record<STATUS, EventDetails[]> = {
        [STATUS.PENDING]: sortedEvents.filter((e) => eventStatuses[e.event_id] === STATUS.PENDING),
        [STATUS.SAVED]: sortedEvents.filter((e) => eventStatuses[e.event_id] === STATUS.SAVED),
        [STATUS.DISMISSED]: sortedEvents.filter((e) => eventStatuses[e.event_id] === STATUS.DISMISSED),
    };

    const updateTripDetails = (tripDetails: TripDetails) => {
        setEvents((prev) => {
            const res = prev.map((event) => {
                if (event.trip?.id === tripDetails.id) {
                    return {
                        ...event,
                        trip: tripDetails,
                    } as EventDetails;
                }
                return event;
            });
            return res;
        });
    };

    const getVehicleEvents = (licensePlate: string) => {
        return api
            .apiV0DriverScoresLicensePlateGet({ licensePlate: licensePlate })
            .then((res) => {
                const vehicleRes: VehicleCardProps = {
                    timestamp: res.data.timestamp,
                    eventIds: res.data.event_ids || [],
                    accidentEventIds: res.data.accident_event_ids || [],
                    driverName: res.data.driver_name,
                    licensePlate: res.data.license_plate,
                    subFleet: res.data.sub_fleet,
                    score: res.data.score,
                    behaviors: res.data.event_types.split(','),
                    mostRecentEvent: res.data.most_recent_event,
                    inactive: res.data.inactive,
                    unknown: res.data.unknown,
                    driver: res.data.driver,
                };
                setVehicle(vehicleRes);
                const allEvents: EventDetails[] = [];
                if (res.data.events && res.data.events.length > 0) {
                    allEvents.push(...res.data.events);
                }
                if (res.data.accident_events && res.data.accident_events.length > 0) {
                    allEvents.push(...res.data.accident_events);
                }
                allEvents.sort((a, b) => b.timestamp - a.timestamp);
                setEvents(allEvents);
                setMetadata(res.data.metadata);
                setAutoSessionId(res.data.auto_coaching_session_id);
                return vehicleRes;
            })
            .catch(() => setIsEventsError(true));
    };

    const getEventStatuses = (vehicle: VehicleCardProps) => {
        const allEventIds = vehicle.eventIds.concat(vehicle.accidentEventIds);
        return api
            .apiV0CoachEventPost({ coachEventStatus: { event_id: allEventIds } })
            .then((res) => {
                const initialStatuses = Object.fromEntries(
                    allEventIds.map((eventId: number) => {
                        let status = STATUS.PENDING;
                        if (res.data[eventId]) {
                            // change API response on BE
                            const eventStatus = res.data[eventId] as Record<string, boolean>;
                            if (eventStatus.dismissed) {
                                status = STATUS.DISMISSED;
                            } else {
                                status = STATUS.SAVED;
                            }
                        }
                        return [eventId, status];
                    }),
                );
                setEventStatuses(initialStatuses);
            })
            .catch(() => setIsEventsError(true));
    };

    const getCoachingSessions = (driverToken: number | undefined) => {
        if (driverToken === undefined) {
            setIsSessionsError(true);
            setIsLoadingSessions(false);
            return;
        }
        api.apiV1CoachGet({ driverToken, completedFrom: dayjs().subtract(1, 'year').unix(), additionalInfo: 1 })
            .then((res) => {
                setCoachingSessions(res.data);
            })
            .catch(() => setIsSessionsError(true))
            .finally(() => setIsLoadingSessions(false));
    };

    const loadData = () => {
        if (vehicle === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getVehicleEvents(props.licensePlate!).then((vehicleRes) => {
                if (vehicleRes !== undefined) {
                    getEventStatuses(vehicleRes).then(() => {
                        setIsLoadingEvents(false);
                        setIsLoadingSessions(false);
                    });
                    getCoachingSessions(vehicleRes.driver?.token);
                }
            });
        } else {
            const promises = [getVehicleEvents(vehicle.licensePlate), getEventStatuses(vehicle)];
            Promise.all(promises).then((res) => {
                setIsLoadingEvents(false);
                setIsLoadingSessions(false);
                const driverToken = (res[0] as VehicleCardProps)?.driver?.token;
                getCoachingSessions(driverToken);
            });
        }
    };

    const getEventDetails = (controller?: AbortController) => {
        if (vehicle !== undefined && selectedEvent !== undefined) {
            setLoadingDetails(true);
            if (vehicle.eventIds.includes(selectedEvent)) {
                api.apiV0EventEventIdGet({ eventId: selectedEvent }, { signal: controller?.signal })
                    .then((res: { data: EventDetails }) => {
                        const newDetails = res.data;
                        setDetails(newDetails);
                        setLoadingDetails(false);
                    })
                    .catch(() => null);
            } else {
                agencyApi
                    .agencyV0EventEventIdGet({ eventId: selectedEvent }, { signal: controller?.signal })
                    .then((res: { data: EventDetails }) => {
                        const newDetails = { ...res.data };
                        setDetails(newDetails);
                        setLoadingDetails(false);
                    })
                    .catch(() => null);
            }
        }
    };

    const getMetricData = (licensePlate: string) => {
        setIsLoadingMetrics(true);
        api.apiV0CoachMetricsGet({ licensePlate: licensePlate })
            .then((res) => {
                setMetricData(res.data);
            })
            .catch(() => setIsErrorMetrics(true))
            .finally(() => setIsLoadingMetrics(false));
    };

    const handleStatusChange = (eventDetails: EventDetails, status: STATUS) => {
        const currentTab = eventsByStatus[eventStatuses[eventDetails.event_id]];
        const eventIndex = currentTab.findIndex((e) => e.event_id === eventDetails.event_id);

        let next = undefined;
        if (eventIndex < currentTab.length - 1) {
            next = currentTab[eventIndex + 1];
        } else if (currentTab.length > 1) {
            next = currentTab[eventIndex - 1];
        }

        if (next !== undefined) {
            setSelectedEvent(next.event_id);
        } else {
            setSidebarTab(status);
        }

        setEventStatuses((prev) => {
            return { ...prev, [eventDetails.event_id]: status };
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!!licensePlate) {
            getMetricData(licensePlate);
        }
    }, [licensePlate]);

    useEffect(() => {
        if (vehicle !== undefined) {
            const eventIds = vehicle.eventIds.map((eventId) => eventId.toString());
            const accidentIds = vehicle.accidentEventIds.map((eventId) => eventId.toString());
            addToQueue(eventIds.concat(accidentIds).map((id) => `event:${id}`));
        }
    }, [vehicle]);

    useEffect(() => {
        if (events.length > 0 && Object.keys(eventStatuses).length > 0 && selectedEvent === undefined) {
            if (eventsByStatus[STATUS.PENDING].length > 0) {
                setSelectedEvent(eventsByStatus[STATUS.PENDING][0].event_id);
            } else if (eventsByStatus[STATUS.SAVED].length > 0) {
                setSelectedEvent(eventsByStatus[STATUS.SAVED][0].event_id);
                setSidebarTab(STATUS.SAVED);
            } else {
                setSelectedEvent(eventsByStatus[STATUS.DISMISSED][0].event_id);
                setSidebarTab(STATUS.DISMISSED);
            }
        }
    }, [events, eventStatuses]);

    useEffect(() => {
        if (selectedEvent !== undefined) {
            if (sidebarTab !== eventStatuses[selectedEvent]) {
                setSidebarTab(eventStatuses[selectedEvent]);
            }
            const controller = new AbortController();
            getEventDetails(controller);
        }
    }, [selectedEvent]);

    const loadingSpinner = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'bgColor.main',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                opacity: 0.5,
                zIndex: 2000,
            }}
        >
            <CircularProgress role="status" />
        </Box>
    );

    const sortMenu = (
        <>
            <Tooltip title={t('content.safety.sort.header')}>
                <Box>
                    <IconButton
                        id="btn-sort"
                        size="small"
                        buttonRef={anchorEl}
                        onClick={() => setIsMenuOpen((prev) => !prev)}
                    >
                        <SortIcon />
                    </IconButton>
                </Box>
            </Tooltip>
            <OverviewStyledMenu
                id="sort-menu"
                anchorEl={anchorEl.current}
                open={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                sx={{ mt: 0.25, zIndex: 3000 }}
            >
                {SORTING_OPTIONS.map((option) => {
                    const isSelected = option === selectedSorting.field;
                    const menuItemStyles = isSelected ? { pr: 1, pl: 0 } : { pr: 1, pl: '30px' };
                    return (
                        <MenuItem
                            key={option}
                            id={`${option.replaceAll('_', '-')}-menu-item`}
                            sx={menuItemStyles}
                            onClick={() =>
                                setSelectedSorting((prev: SortingModel) => ({
                                    field: option,
                                    order: prev.order,
                                }))
                            }
                        >
                            {isSelected ? (
                                <Box
                                    sx={{
                                        width: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CheckIcon
                                        data-testid={`${option.replaceAll('_', '-')}-selected`}
                                        fontSize="large"
                                        color="secondary"
                                    />
                                </Box>
                            ) : null}
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {t(`content.safety.sort.${option}`)}
                            </Typography>
                        </MenuItem>
                    );
                })}
                {['asc', 'desc'].map((option, idx) => {
                    const isSelected = option === selectedSorting.order;
                    const menuItemStyles = isSelected ? { pr: 1, pl: 0 } : { pr: 1, pl: '30px' };
                    const additionalStyles =
                        idx === 0
                            ? {
                                  borderTopWidth: '1px',
                                  borderTopStyle: 'solid',
                                  borderTopColor: 'lightGrayColor.main',
                              }
                            : {};
                    return (
                        <MenuItem
                            key={option}
                            id={`${option}-menu-item`}
                            sx={{ ...menuItemStyles, ...additionalStyles }}
                            onClick={() =>
                                setSelectedSorting((prev: SortingModel) => ({
                                    field: prev.field,
                                    order: option as 'asc' | 'desc',
                                }))
                            }
                        >
                            {isSelected ? (
                                <Box
                                    sx={{
                                        width: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CheckIcon
                                        data-testid={`${option.replaceAll('_', '-')}-selected`}
                                        fontSize="large"
                                        color="secondary"
                                    />
                                </Box>
                            ) : null}
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {t(`content.fleet.sort.${option}`)}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </OverviewStyledMenu>
        </>
    );

    let eventsTabContent;
    if (isLoadingEvents) {
        eventsTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.5,
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (isEventsError) {
        eventsTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                    {t('content.safety.error_events')}
                </Typography>
                <Link
                    id="try-again-link"
                    component="button"
                    onClick={() => {
                        setIsEventsError(false);
                        loadData();
                    }}
                >
                    <Typography variant="overline">{t('content.safety.try_again')}</Typography>
                </Link>
            </Box>
        );
    } else if (sortedEvents.length === 0) {
        eventsTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                    {t('content.safety.no_events')}
                </Typography>
            </Box>
        );
    } else if (vehicle !== undefined) {
        eventsTabContent = (
            <Grid container columns={8} columnSpacing={1}>
                <Grid item xs={8} md={5} sx={{ position: 'relative' }}>
                    {loadingDetails ? loadingSpinner : null}
                    {details ? (
                        <VehicleModalDetails
                            details={details}
                            setDetails={setDetails}
                            accidentEventIds={vehicle.accidentEventIds}
                            eventStatuses={eventStatuses}
                            handleStatusChange={handleStatusChange}
                            setSelectedEvent={setSelectedEvent}
                            metadata={metadata}
                            setAlert={setAlert}
                            updateTripDetails={updateTripDetails}
                            getDriver={getDriver}
                        />
                    ) : null}
                </Grid>
                <Grid item xs={8} md={3}>
                    <VehicleModalSidebar
                        tab={sidebarTab}
                        setTab={setSidebarTab}
                        sortedEvents={[
                            eventsByStatus[STATUS.PENDING],
                            eventsByStatus[STATUS.SAVED],
                            eventsByStatus[STATUS.DISMISSED],
                        ]}
                        accidentEventIds={vehicle.accidentEventIds}
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                        eventImages={eventImages}
                        isLoadingEvents={isLoadingEvents}
                        listHeight={446}
                    />
                </Grid>
            </Grid>
        );
    }

    let scoresTabContent;
    if (isLoadingMetrics) {
        scoresTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.5,
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (isErrorMetrics || isEventsError) {
        scoresTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                    {t('content.safety.error_scores')}
                </Typography>
                <Link
                    id="try-again-link"
                    component="button"
                    onClick={() => {
                        if (isEventsError) {
                            setIsEventsError(false);
                            loadData();
                        }
                        if (isErrorMetrics) {
                            setIsErrorMetrics(false);
                            getMetricData(licensePlate);
                        }
                    }}
                >
                    <Typography variant="overline">{t('content.safety.try_again')}</Typography>
                </Link>
            </Box>
        );
    } else if (vehicle !== undefined && metricData !== undefined) {
        scoresTabContent = (
            <ScoresTab
                scoreType="safety"
                vehicle={vehicle}
                device={devices.find((d) => d.device.license_plate === vehicle.licensePlate)}
                metricData={metricData}
                setAlert={setAlert}
                metadata={metadata}
                coachingSessions={coachingSessions}
            />
        );
    }

    let coachingTabContent;
    if (isLoadingEvents || isLoadingSessions || isLoadingUsers) {
        coachingTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.5,
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (isEventsError || isSessionsError) {
        coachingTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                    {t('content.safety.error_coaching')}
                </Typography>
                <Link
                    id="try-again-link"
                    component="button"
                    onClick={() => {
                        if (isEventsError) {
                            setIsEventsError(false);
                            setIsLoadingEvents(true);
                            loadData();
                        }
                        if (isSessionsError) {
                            setIsSessionsError(false);
                            setIsLoadingSessions(true);
                            getCoachingSessions(vehicle?.driver?.token);
                        }
                    }}
                >
                    <Typography variant="overline">{t('content.safety.try_again')}</Typography>
                </Link>
            </Box>
        );
    } else if (coachingSessions !== undefined) {
        coachingTabContent = <CoachingTab sessions={coachingSessions} users={customerUsers} />;
    }

    let dateRange;
    if (vehicle !== undefined) {
        const endDate = dayjs.unix(vehicle.timestamp);
        dateRange = `${endDate.subtract(14, 'day').format(profile.shortDateFormat)}–${endDate.format(
            profile.shortDateFormat,
        )}`;
    }

    return (
        <Dialog
            id="vehicle-dialog"
            open={true}
            fullWidth={true}
            maxWidth="md"
            onClose={() => props.onClose()}
            PaperProps={{ sx: { backgroundColor: 'bgColor.main' } }}
            sx={{ '& .MuiDialog-scrollPaper': { alignItems: 'start' } }}
        >
            {alertElement}
            <Box>
                <VehicleModalHeader vehicle={vehicle} onClose={props.onClose} />
                <Box
                    sx={{
                        p: 1,
                        pt: 0,
                    }}
                >
                    {!!autoSessionId && tab !== 2 ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
                            <AutoFixHighIcon sx={{ mr: 0.5 }} />
                            <Typography fontSize={14} sx={{ mr: 0.5 }}>
                                {`${t('content.safety.has_auto_coaching')} `}
                                <Link
                                    id="view-auto-coaching-session"
                                    component={RouterLink}
                                    to={`/coaching/${autoSessionId}`}
                                >
                                    {t('content.safety.view_session')}
                                </Link>
                            </Typography>
                        </Box>
                    ) : null}
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${palette.neutral[150]}`,
                            minHeight: 0,
                        }}
                    >
                        <Tabs
                            value={tab}
                            onChange={(_e, newValue) => setTab(newValue)}
                            TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                        >
                            <Tab
                                label={
                                    <Typography fontSize={14}>{`${t('content.safety.safety_events_tab')}${
                                        !!dateRange ? ` (${dateRange})` : ''
                                    }`}</Typography>
                                }
                                {...a11yProps(0)}
                                id="tab-safety-events"
                                data-testid="tab-safety-events"
                            />
                            <Tab
                                label={<Typography fontSize={14}>{t('content.safety.scores_tab')}</Typography>}
                                {...a11yProps(1)}
                                id="tab-scores"
                                data-testid="tab-scores"
                            />
                            <Tab
                                label={<Typography fontSize={14}>{t('content.safety.coaching_tab')}</Typography>}
                                {...a11yProps(2)}
                                id="tab-coaching"
                                data-testid="tab-coaching"
                            />
                        </Tabs>
                        {tab === 0 ? sortMenu : null}
                    </Box>
                    <TabPanel value={tab} index={0} sx={{ height: isMobile ? undefined : '500px', minHeight: 0 }}>
                        {eventsTabContent}
                    </TabPanel>
                    <TabPanel value={tab} index={1} sx={{ height: isMobile ? undefined : '350px', minHeight: 0 }}>
                        {scoresTabContent}
                    </TabPanel>
                    <TabPanel value={tab} index={2} sx={{ height: isMobile ? undefined : '311.25px', minHeight: 0 }}>
                        {coachingTabContent}
                    </TabPanel>
                </Box>
            </Box>
        </Dialog>
    );
};

export default VehicleModal;
