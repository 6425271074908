import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Card, Typography } from '@mui/material';
import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FleetDriver } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { normalizeName, unformatPhoneNumber } from '../../../utils/Str';

const normalizeDriverName = (firstName: string, lastName: string) => {
    return `${normalizeName(firstName)} ${normalizeName(lastName)}`;
};

const DRIVER_COLUMNS = ['first_name', 'last_name', 'phone_number', 'sub_fleet'] as const;

const DRIVER_COLUMN_MAP: Record<string, typeof DRIVER_COLUMNS[number]> = {
    'First Name': 'first_name',
    'Last Name': 'last_name',
    'Phone Number': 'phone_number',
    'Sub Fleet': 'sub_fleet',
    'שם פרטי': 'first_name',
    'שם משפחה': 'last_name',
    'מספר טלפון': 'phone_number',
    מחלקה: 'sub_fleet',
};

interface DriverRowData {
    first_name: string;
    last_name: string;
    phone_number: string;
    sub_fleet: string;
}

const checkDriverChanged = (driver: FleetDriver, driverInput: DriverRowData) => {
    return driverInput.phone_number !== driver.phone_number || driverInput.sub_fleet !== driver.sub_fleet;
};

const parseValue = (key: string, value: string) => {
    const trimmedValue = value.trim();
    if (key === 'phone_number') {
        return [key, unformatPhoneNumber(trimmedValue)];
    }
    return [key, trimmedValue];
};

const readFile = (file: File) => {
    return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result as string), false);
        reader.readAsText(file);
    });
};

const readFileContent = (text: string) => {
    const lines = text.split(/\r?\n/);
    let headers: string[] = [];
    const driverRequests: DriverRowData[] = [];
    const existingNames: Record<string, number> = {};
    for (const [lineNumber, line] of Array.from(lines.entries())) {
        if (headers.length == 0) {
            headers = line.split(',').map((header: string) => DRIVER_COLUMN_MAP[header]);
            if (!isEqual(headers, DRIVER_COLUMNS)) {
                throw new Error('invalid_structure');
            }
        } else if (!!line) {
            const parts = line.split(',');
            const rowData = Object.fromEntries(headers.map((key: string, idx: number) => parseValue(key, parts[idx])));
            const driverName = normalizeDriverName(rowData.first_name, rowData.last_name);
            const phoneNumber = unformatPhoneNumber(rowData.phone_number);
            if (driverName in existingNames) {
                throw new Error('duplicate_drivers', {
                    cause: { line1: lineNumber, line2: existingNames[driverName] },
                });
            }
            if (!rowData.first_name && !rowData.last_name) {
                throw new Error('missing_name', { cause: { line: lineNumber } });
            }
            driverRequests.push({ ...rowData, phone_number: phoneNumber });
            existingNames[driverName] = lineNumber;
        }
    }
    return driverRequests;
};

interface UploadDriversModalProps {
    onClose: CallableFunction;
    drivers: FleetDriver[];
    setDrivers: CallableFunction;
    onDownloadList: CallableFunction;
    isLoading: boolean;
    setIsLoading: CallableFunction;
    setAlert: CallableFunction;
}

interface UploadPreview {
    unchanged: number;
    changed: number;
    new: number;
}

const UploadDriversModal: React.FC<UploadDriversModalProps> = (props: UploadDriversModalProps) => {
    const [dragAndDropHover, setDragAndDropHover] = useState<boolean>(false);
    const [file, setFile] = useState<File>();
    const [preview, setPreview] = useState<UploadPreview>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [drivers, setDrivers] = useState<DriverRowData[]>([]);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { api } = useApi();

    const onDownloadTemplate = () => {
        const BOM = '\uFEFF';
        const csvContent = [
            `${t('content.drivers.first_name')},`,
            `${t('content.drivers.last_name')},`,
            `${t('content.drivers.phone_number')},`,
            `${t('content.drivers.sub_fleet')}\n`,
            `${t('content.drivers.upload_csv.john')},`,
            `${t('content.drivers.upload_csv.doe')},`,
            `${profile.customer.settings.country === 'il' ? '055-555-5555' : '(123) 456-7890'},`,
            `${t('content.drivers.upload_csv.central')}`,
        ].join('');
        const file = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = 'template.csv';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const handleUpload = () => {
        props.setIsLoading(true);
        api.apiV0DriverPatch({
            fleetDriverList: {
                drivers: drivers.map((d) => {
                    return {
                        first_name: d.first_name,
                        last_name: d.last_name,
                        phone_number: unformatPhoneNumber(d.phone_number),
                        sub_fleet: d.sub_fleet,
                        active: true,
                    };
                }),
            },
        })
            .then((res) => {
                props.setAlert({ message: t('content.drivers.upload_csv.success'), type: 'success', duration: 6000 });
                props.setDrivers((prev: FleetDriver[]) => {
                    const updatedDrivers = prev.map((driver) => {
                        const updatedDriver = res.data.find((d) => d.token === driver.token);
                        if (updatedDriver) {
                            return {
                                ...driver,
                                ...updatedDriver,
                            };
                        }
                        return driver;
                    });
                    const newDrivers = res.data.filter((driver) => !prev.some((d) => d.token === driver.token));
                    return [...updatedDrivers, ...newDrivers];
                });
                props.onClose();
            })
            .catch(() =>
                props.setAlert({ message: t('content.drivers.upload_csv.error'), type: 'error', duration: 6000 }),
            )
            .finally(() => props.setIsLoading(false));
    };

    useEffect(() => {
        const readInputFile = async (file: File) => {
            const fileContent = await readFile(file);
            return readFileContent(fileContent);
        };

        const processFile = async () => {
            if (!!file) {
                try {
                    const driverNames = props.drivers.map((driver) =>
                        normalizeDriverName(driver.first_name, driver.last_name),
                    );
                    const driversInput = await readInputFile(file);
                    const driversInputMap = driversInput.reduce((acc, driver) => {
                        const driverName = normalizeDriverName(driver.first_name, driver.last_name);
                        acc[driverName] = driver;
                        return acc;
                    }, {} as Record<string, DriverRowData>);
                    setDrivers(driversInput);
                    const newDrivers = driversInput.filter(
                        (driver) => !driverNames.includes(normalizeDriverName(driver.first_name, driver.last_name)),
                    ).length;
                    let changedDrivers = 0;
                    let unchangedDrivers = 0;
                    for (const driver of props.drivers) {
                        const driverInput = driversInputMap[normalizeDriverName(driver.first_name, driver.last_name)];
                        if (driverInput) {
                            if (checkDriverChanged(driver, driverInput)) {
                                changedDrivers++;
                            } else {
                                unchangedDrivers++;
                            }
                        }
                    }
                    setPreview({ unchanged: unchangedDrivers, changed: changedDrivers, new: newDrivers });
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (err: any) {
                    if (err.message === 'invalid_structure') {
                        setErrorMessage(t('content.drivers.upload_csv.invalid_structure'));
                    } else if (err.message === 'duplicate_drivers') {
                        setErrorMessage(
                            t('content.drivers.upload_csv.duplicate_drivers', {
                                line1: err.cause.line1 + 1,
                                line2: err.cause.line2 + 1,
                            }),
                        );
                    } else if (err.message === 'missing_name') {
                        setErrorMessage(t('content.drivers.upload_csv.missing_name', { line: err.cause.line + 1 }));
                    }
                    setPreview(undefined);
                }
            } else {
                setPreview(undefined);
                setErrorMessage('');
                setDrivers([]);
            }
        };

        processFile();
    }, [file]);

    return (
        <Dialog id="update-driver-dialog" open={true} maxWidth="xs" fullWidth onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                    minHeight: 0,
                    flexShrink: 0,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FileUploadIcon sx={{ mr: 0.5 }} />
                    <Typography>{t('content.drivers.upload_csv.header')}</Typography>
                </Box>
            </Box>
            <Box sx={{ width: '100%', p: 1 }}>
                <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 1 }}>
                    <Box sx={{ mb: 1 }}>
                        <Typography sx={{ mb: 1 }}>
                            <Typography component="span">{t('content.drivers.upload_csv.instructions_1')}</Typography>
                            <Typography component="span">{t('content.drivers.upload_csv.instructions_2')}</Typography>
                            <Typography component="span">
                                <Link
                                    id="download-list-link"
                                    component="span"
                                    onClick={() => props.onDownloadList()}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {t('content.drivers.upload_csv.instructions_3')}
                                </Link>
                            </Typography>
                            <Typography component="span">{t('content.drivers.upload_csv.instructions_4')}</Typography>
                            <Typography component="span">
                                <Link
                                    id="download-template-link"
                                    onClick={() => onDownloadTemplate()}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {t('content.drivers.upload_csv.instructions_5')}
                                </Link>
                            </Typography>
                            <Typography component="span">.</Typography>
                        </Typography>
                        <Typography sx={{ mb: 1 }}>
                            <Typography component="span" fontSize={14} sx={{ fontWeight: 'bold' }}>
                                {t('content.drivers.upload_csv.note_header')}
                            </Typography>
                            <Typography component="span" fontSize={14}>
                                {': '}
                            </Typography>
                            <Typography component="span" fontSize={14}>
                                {t('content.drivers.upload_csv.note_body')}
                            </Typography>
                        </Typography>
                    </Box>
                    <Box
                        onDragOver={(e: React.DragEvent) => {
                            // Prevent default behavior (Prevent file from being opened)
                            e.preventDefault();
                            setDragAndDropHover(true);
                        }}
                        onDragEnter={() => {
                            setDragAndDropHover(true);
                        }}
                        onDrop={(e: React.DragEvent) => {
                            // Prevent default behavior (Prevent file from being opened)
                            e.preventDefault();
                            if (e.dataTransfer.files.length > 0) {
                                setFile(e.dataTransfer.files[0]);
                            }
                            setDragAndDropHover(false);
                        }}
                        onDragLeave={() => {
                            setDragAndDropHover(false);
                        }}
                        onDragEnd={() => {
                            setDragAndDropHover(false);
                        }}
                        onDragExit={() => {
                            setDragAndDropHover(false);
                        }}
                    >
                        <Box
                            sx={{
                                p: 1,
                                height: '6rem',
                                border:
                                    file === undefined ? '2px dashed var(--gray-color)' : '1px solid var(--gray-color)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {file === undefined ? (
                                <>
                                    <FileUploadIcon
                                        color={dragAndDropHover ? 'secondary' : 'primary'}
                                        fontSize="large"
                                        sx={{ mb: 0.5, transition: 'color 0.2s' }}
                                    />
                                    <Box sx={{ display: 'flex' }}>
                                        <Link
                                            id="link-upload-file"
                                            data-testid="link-upload-file"
                                            variant="overline"
                                            component="label"
                                            sx={{ mr: 0.5, cursor: 'pointer', lineHeight: 1 }}
                                        >
                                            {t('content.drivers.upload_csv.upload_file')}
                                            <input
                                                id="file-field"
                                                data-testid="file-field"
                                                hidden
                                                type="file"
                                                accept=".csv"
                                                onChange={(e) => {
                                                    if (e.target.files && e.target.files.length > 0) {
                                                        setFile(e.target.files[0]);
                                                    }
                                                }}
                                            />
                                        </Link>
                                        <Typography color="primary" variant="overline" sx={{ lineHeight: 1 }}>
                                            {t('content.drivers.upload_csv.or_drop_here')}
                                        </Typography>
                                    </Box>
                                </>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: 14, mb: 0.5 }}>{file.name}</Typography>
                                    <Link
                                        id="clear-file-link"
                                        component="span"
                                        variant="overline"
                                        onClick={() => setFile(undefined)}
                                        sx={{ lineHeight: 1, cursor: 'pointer' }}
                                    >
                                        {t('content.drivers.upload_csv.clear')}
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {errorMessage ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                            <Typography variant="body2" color="error">
                                {errorMessage}
                            </Typography>
                        </Box>
                    ) : null}
                    {preview !== undefined ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                            <Typography variant="overline" sx={{ lineHeight: 1, mb: 0.5 }}>
                                {t('content.drivers.upload_csv.file_content')}
                            </Typography>
                            <Typography data-testid="unchanged-drivers" data-value={preview.unchanged}>
                                {t('content.drivers.upload_csv.unchanged', { count: preview.unchanged })}
                            </Typography>
                            <Typography data-testid="changed-drivers" data-value={preview.changed}>
                                {t('content.drivers.upload_csv.changed', { count: preview.changed })}
                            </Typography>
                            <Typography data-testid="new-drivers" data-value={preview.new}>
                                {t('content.drivers.upload_csv.new', { count: preview.new })}
                            </Typography>
                        </Box>
                    ) : null}
                </Card>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Button
                        id="btn-cancel"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 1 }}
                        disabled={props.isLoading}
                        onClick={() => {
                            props.onClose();
                        }}
                    >
                        {t('content.drivers.cancel')}
                    </Button>
                    <Button
                        id="btn-upload"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 1, ml: 1 }}
                        disabled={
                            preview === undefined ||
                            (preview.new === 0 && preview.changed === 0) ||
                            drivers.length === 0 ||
                            props.isLoading
                        }
                        onClick={() => handleUpload()}
                    >
                        {t('content.drivers.upload')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default UploadDriversModal;
