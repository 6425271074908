import { Box, Popover, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useProfile from '../../../../hooks/profile';
import BusTop from '../../../../images/overview-bus-top.svg';
import TruckTop from '../../../../images/overview-truck-top.svg';
import palette from '../../../ColorPalette';

interface ImpactChartTopProps {
    pointsOfImpact: Record<string, number>;
}

const IMAGE_HEIGHT = 300;
const IMAGE_WIDTH = 105;
export const MIN_RADIUS = 15;
export const MAX_RADIUS = 25;

export const getRadius = (impact: number, pointsOfImpact: Record<string, number>) => {
    const values = Object.values(pointsOfImpact);

    if (!impact || values.length === 0) {
        return 0;
    }

    const minImpact = Math.min(...values);
    const maxImpact = Math.max(...values);

    if (minImpact === maxImpact) {
        return MAX_RADIUS;
    }

    const normalizedImpact = (impact - minImpact) / (maxImpact - minImpact);
    const radius = MIN_RADIUS + normalizedImpact * (MAX_RADIUS - MIN_RADIUS);

    return radius;
};

export const getColor = (impact: number, pointsOfImpact: Record<string, number>) => {
    const totalImpacts = Object.values(pointsOfImpact).reduce((a, b) => a + b, 0);
    if (impact === 0 || totalImpacts === 0) {
        return 'transparent';
    }
    const percent = impact / Math.max(...Object.values(pointsOfImpact));
    if (percent < 0.33) {
        return '#ffc658';
    } else if (percent < 0.66) {
        return '#ff9e57';
    } else {
        return palette.bad;
    }
};

const ImpactChartTop: React.FC<ImpactChartTopProps> = (props: ImpactChartTopProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [currentPoint, setCurrentPoint] = React.useState<{ key: string; value: number }>({ key: '', value: 0 });
    const { t } = useTranslation();
    const { profile } = useProfile();
    const isProperty = profile.customer.settings.driving_type === 'property';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePopoverOpen = (event: any, key: string) => {
        setCurrentPoint({ key, value: props.pointsOfImpact[key] });
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    const containerHeight = IMAGE_HEIGHT + IMAGE_HEIGHT / 3;
    const containerWidth = IMAGE_WIDTH + IMAGE_WIDTH / 2;
    const pointsOfImpact = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(props.pointsOfImpact).filter(([key, _value]) => !['no_impact', 'unknown', ''].includes(key)),
    );

    const getCircleProps = (key: string) => {
        return {
            className: 'impact-circle',
            onMouseEnter: (event: React.MouseEvent<SVGCircleElement, MouseEvent>) => handlePopoverOpen(event, key),
            onMouseLeave: handlePopoverClose,
            r: getRadius(props.pointsOfImpact[key], pointsOfImpact),
            fill: getColor(props.pointsOfImpact[key], pointsOfImpact),
            stroke: getColor(props.pointsOfImpact[key], pointsOfImpact),
            strokeWidth: 1,
            fillOpacity: 0.5,
        };
    };

    return (
        <Box
            sx={{
                height: containerHeight,
                width: containerWidth,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                flexShrink: 0,
                mr: 3,
                overflow: 'visible',
            }}
        >
            <img
                id={isProperty ? 'truck-top' : 'bus-top'}
                data-testid={isProperty ? 'truck-top' : 'bus-top'}
                src={isProperty ? TruckTop : BusTop}
                style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }}
            />
            <svg
                height={containerHeight}
                width={containerWidth}
                style={{ position: 'absolute', top: 0, left: 0, overflow: 'visible' }}
            >
                <circle cx={IMAGE_WIDTH / 4 + 10} cy={IMAGE_HEIGHT / 6 + 40} {...getCircleProps('front_left')} />
                <circle cx={IMAGE_WIDTH / 4 + IMAGE_WIDTH / 2} cy={IMAGE_HEIGHT / 6} {...getCircleProps('front')} />
                <circle
                    cx={containerWidth - IMAGE_WIDTH / 4 - 10}
                    cy={IMAGE_HEIGHT / 6 + 40}
                    {...getCircleProps('front_right')}
                />
                <circle
                    cx={containerWidth - IMAGE_WIDTH / 4 - 10}
                    cy={IMAGE_HEIGHT / 6 + IMAGE_HEIGHT / 2}
                    {...getCircleProps('right')}
                />
                <circle
                    cx={IMAGE_WIDTH / 4 + 10}
                    cy={IMAGE_HEIGHT / 6 + IMAGE_HEIGHT / 2}
                    {...getCircleProps('left')}
                />
                <circle
                    cx={IMAGE_WIDTH / 4 + 10}
                    cy={containerHeight - IMAGE_HEIGHT / 6 - 40}
                    {...getCircleProps('rear_left')}
                />
                <circle
                    cx={IMAGE_WIDTH / 4 + IMAGE_WIDTH / 2}
                    cy={containerHeight - IMAGE_HEIGHT / 6}
                    {...getCircleProps('rear')}
                />
                <circle
                    cx={containerWidth - IMAGE_WIDTH / 4 - 10}
                    cy={containerHeight - IMAGE_HEIGHT / 6 - 40}
                    {...getCircleProps('rear_right')}
                />
            </svg>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', p: 1 }}>
                    <Typography fontSize={12} sx={{ fontWeight: 'bold' }}>
                        {`${t(`content.accidents.tags.damage_mechanism.tooltip.${currentPoint.key}`)}:`}
                    </Typography>
                    <Typography fontSize={12}>
                        {`${currentPoint.value} ${t('content.accident_overview.accidents_label')}`}
                    </Typography>
                </Box>
            </Popover>
        </Box>
    );
};

export default ImpactChartTop;
