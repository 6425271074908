import BlurOnIcon from '@mui/icons-material/BlurOn';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CoachEvent } from '../../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import palette from '../../../ColorPalette';
import { EVENT_TYPE_MAP } from '../../Event/Defs';
import { getItemIcon } from './AgendaItemModal';
import { WELLNESS_EVENT_TYPES } from './CoachingAgenda';

const IMAGE_WIDTH = 220;
const IMAGE_HEIGHT = 82.5;

interface CoachingEventProps {
    agendaItemId: number;
    event: CoachEvent;
    eventImages: Record<string, string>;
    isAccident: boolean;
    onDelete?: CallableFunction;
}

const CoachingEvent: React.FC<CoachingEventProps> = ({
    agendaItemId,
    event,
    eventImages,
    isAccident,
    onDelete,
}: CoachingEventProps) => {
    const { t } = useTranslation();
    const { profile } = useProfile();
    const eventSeverity = isAccident ? 100 : event.event_details.severity;
    const isMobile = useIsMobile();
    const imageWidth = isMobile ? IMAGE_WIDTH * 0.8 : IMAGE_WIDTH;
    const imageHeight = isMobile ? IMAGE_HEIGHT * 0.8 : IMAGE_HEIGHT;

    return (
        <Box
            data-testid={`event-${event.event}`}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {eventImages[`event:${event.event}`] !== undefined ? (
                    <Box sx={{ width: imageWidth, height: imageHeight, mr: 1, flexShrink: 0, position: 'relative' }}>
                        <img src={eventImages[`event:${event.event}`]} width="100%" />
                        {event.is_blurred ? (
                            <Tooltip title={t('content.coach.details.blurred')}>
                                <BlurOnIcon
                                    sx={{
                                        fontSize: 28,
                                        position: 'absolute',
                                        color: palette.white,
                                        bottom: 0,
                                        left: 0,
                                    }}
                                />
                            </Tooltip>
                        ) : null}
                    </Box>
                ) : (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={imageWidth}
                        height={imageHeight}
                        sx={{ mr: 1 }}
                    />
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: IMAGE_HEIGHT,
                        py: 1.5,
                        justifyContent: 'space-around',
                    }}
                >
                    <Typography id={`${event.event_details.event_id}-type`} variant="body3" sx={{ fontWeight: 'bold' }}>
                        {t(
                            isAccident
                                ? 'content.safety.accident'
                                : EVENT_TYPE_MAP[event.event_details.type] || event.event_details.type,
                        )}
                    </Typography>
                    <Typography id={`${event.event_details.event_id}-risk`} variant="body3">
                        {t('table.headers.severity')}: {eventSeverity.toFixed(2)}
                    </Typography>
                    <Typography id={`${event.event_details.event_id}-datetime`} variant="body3">
                        {dayjs.unix(event.event_details.timestamp).format(profile.dateTimeFormat)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {getItemIcon(
                    WELLNESS_EVENT_TYPES.includes(event.event_details.type) ? 'wellness_status' : 'event',
                    true,
                )}
                {!!onDelete ? (
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete();
                        }}
                        id={`delete-item-${agendaItemId}`}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </Box>
        </Box>
    );
};

export default CoachingEvent;
