import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete, Box, Card, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';

import { FleetDriver } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { normalizeName, unformatPhoneNumber } from '../../../utils/Str';
import { PHONE_NUMBER_MASK_MAP } from '../UserSettings/UserSettings';

interface DriverModalProps {
    driver: FleetDriver;
    subFleetList: string[];
    setSubFleetList: CallableFunction;
    setAlert: CallableFunction;
    onClose: CallableFunction;
    drivers: FleetDriver[];
    setDrivers: CallableFunction;
}

const DriverModal: React.FC<DriverModalProps> = (props: DriverModalProps) => {
    const [driverDetails, setDriverDetails] = useState<FleetDriver>(props.driver);
    const [selectedFile, setSelectedFile] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { api } = useApi();
    const isEditMode = props.driver.token !== 0;

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const inputFiles = Array.from(e.target.files);
            const inputFile = inputFiles[0];
            if (!inputFile.type.startsWith('image')) {
                props.setAlert({ message: t('content.drivers.invalid_file'), type: 'error', duration: 6000 });
            } else {
                // setSelectedFile(inputFile);
                const reader = new FileReader();
                reader.readAsDataURL(inputFile);
                reader.onload = (event) => {
                    // Create an Image object to manipulate the uploaded image
                    const img = new Image();
                    img.src = event.target?.result as string;
                    img.onload = () => {
                        const MAX_SIZE = 500;
                        let width = img.width;
                        let height = img.height;

                        // Calculate new dimensions while preserving aspect ratio
                        if (width > height) {
                            if (width > MAX_SIZE) {
                                height *= MAX_SIZE / width;
                                width = MAX_SIZE;
                            }
                        } else {
                            if (height > MAX_SIZE) {
                                width *= MAX_SIZE / height;
                                height = MAX_SIZE;
                            }
                        }

                        // Create a canvas element to draw the resized image
                        const canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;

                        // Draw the resized image on the canvas
                        const ctx = canvas.getContext('2d');
                        ctx?.drawImage(img, 0, 0, width, height);

                        // Get the base64 representation of the resized image
                        const base64ImageData = canvas.toDataURL(inputFile.type);
                        setSelectedFile(base64ImageData);
                    };
                };
            }
        }
    };

    const handleSave = () => {
        setIsLoading(true);
        if (isEditMode) {
            api.apiV0DriverDriverTokenPatch({
                driverToken: props.driver.token,
                fleetDriverRequest: {
                    ...driverDetails,
                    phone_number: unformatPhoneNumber(driverDetails.phone_number),
                    image: selectedFile,
                },
            })
                .then((res) => {
                    props.setDrivers((prev: FleetDriver[]) =>
                        prev.map((driver) => (driver.token === res.data.token ? res.data : driver)),
                    );
                    if (res.data.sub_fleet && !props.subFleetList.includes(res.data.sub_fleet)) {
                        props.setSubFleetList((prev: string[]) => [...prev, res.data.sub_fleet]);
                    }
                    props.onClose();
                })
                .catch(() => {
                    props.setAlert({ message: t('content.drivers.error_save'), type: 'error', duration: 6000 });
                    setIsLoading(false);
                });
        } else {
            api.apiV0DriverPost({
                fleetDriverRequest: {
                    ...driverDetails,
                    phone_number: unformatPhoneNumber(driverDetails.phone_number),
                    image: selectedFile,
                },
            })
                .then((res) => {
                    props.setDrivers((prev: FleetDriver[]) => [...prev, res.data]);
                    props.onClose();
                })
                .catch(() => {
                    props.setAlert({ message: t('content.drivers.error_save'), type: 'error', duration: 6000 });
                    setIsLoading(false);
                });
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const phoneInput: any = (props: any) => {
        const inputElement = (
            <TextField
                fullWidth
                size="small"
                id="phone-number-field"
                label={t('content.drivers.phone_number')}
                inputProps={{ 'data-testid': 'phone-number-field', style: { direction: 'ltr' } }}
                sx={{ my: 1.5 }}
                {...props}
            />
        );
        return inputElement;
    };

    const duplicateDriver = props.drivers.find(
        (d) =>
            d.token !== driverDetails.token &&
            normalizeName(driverDetails.first_name) === normalizeName(d.first_name) &&
            normalizeName(driverDetails.last_name) === normalizeName(d.last_name),
    );

    return (
        <Dialog id="fleet-driver-dialog" open={true} maxWidth="xs" fullWidth onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                    minHeight: 0,
                    flexShrink: 0,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isEditMode ? <EditIcon /> : <AddIcon />}
                    <Typography sx={{ ml: 0.5 }}>
                        {t(`content.drivers.${isEditMode ? 'edit' : 'add'}_driver`)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ width: '100%', p: 1 }}>
                <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 1 }}>
                    <Box sx={{ width: '100%', display: 'flex' }}>
                        <TextField
                            fullWidth
                            size="small"
                            id="first-name-field"
                            inputProps={{ 'data-testid': 'first-name-field' }}
                            label={t('content.drivers.first_name')}
                            value={driverDetails.first_name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setDriverDetails((prev) => ({ ...prev, first_name: e.target.value }))
                            }
                            error={!!duplicateDriver}
                            sx={{ mr: 1 }}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            id="last-name-field"
                            inputProps={{ 'data-testid': 'last-name-field' }}
                            label={t('content.drivers.last_name')}
                            value={driverDetails.last_name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setDriverDetails((prev) => ({ ...prev, last_name: e.target.value }))
                            }
                            error={!!duplicateDriver}
                        />
                    </Box>
                    {duplicateDriver && (
                        <Typography color="error" fontSize={12}>
                            {t('content.drivers.duplicate_driver')}
                        </Typography>
                    )}
                    <ReactInputMask
                        mask={PHONE_NUMBER_MASK_MAP[profile.customer.settings.country]}
                        value={driverDetails.phone_number}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setDriverDetails((prev) => ({ ...prev, phone_number: e.target.value }))
                        }
                        maskChar=""
                    >
                        {phoneInput}
                    </ReactInputMask>
                    <Autocomplete
                        id="sub-fleet-field"
                        freeSolo
                        size="small"
                        options={props.subFleetList}
                        renderInput={(params) => <TextField {...params} label={t('content.drivers.sub_fleet')} />}
                        value={driverDetails.sub_fleet}
                        onChange={(_e, value) => setDriverDetails((prev) => ({ ...prev, sub_fleet: value || '' }))}
                        sx={{ mb: 1.5 }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ color: '#666666', mr: 1 }}>{t('content.drivers.driver_image')}</Typography>
                        <Button
                            id="btn-upload-file"
                            data-testid="btn-upload-file"
                            variant="contained"
                            component="label"
                            sx={{ mr: 1 }}
                        >
                            {t('content.drivers.upload')}
                            <input
                                id="file-field"
                                data-testid="file-field"
                                hidden
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </Button>
                    </Box>
                    {!!selectedFile || !!driverDetails.image_url ? (
                        <img
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            src={!!selectedFile ? selectedFile : driverDetails.image_url!}
                            alt="driver"
                            style={{
                                maxWidth: 200,
                                maxHeight: 200,
                                objectFit: 'contain',
                                objectPosition: 'top left',
                                marginTop: 8,
                            }}
                        />
                    ) : null}
                </Card>
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    <Button
                        id="btn-cancel"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        onClick={() => props.onClose()}
                        sx={{ mr: 1 }}
                    >
                        {t('content.drivers.cancel')}
                    </Button>
                    <Button
                        id="btn-save"
                        data-testid="btn-save"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={
                            (!driverDetails.first_name && !driverDetails.last_name) || !!duplicateDriver || isLoading
                        }
                        onClick={handleSave}
                    >
                        {t('content.drivers.save')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default DriverModal;
