import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    TextField,
    Tooltip,
    Typography,
    lighten,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    GridCellParams,
    GridColDef,
    GridCsvExportMenuItem,
    GridCsvGetRowsToExportParams,
    GridRenderCellParams,
    GridRowModel,
    GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarExportContainer,
    GridToolbarFilterButton,
    GridValueFormatterParams,
    gridExpandedSortedRowIdsSelector,
    useGridApiContext,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceOperationData, DeviceV2, Wellness } from '../../../backendsdk';
import { TrackedDataGrid as StyledDataGrid } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { getDateTimeColumnType, localeObjectMap } from '../../../utils/DataGridDateTime';
import { operationsPageDefs } from '../../../utils/Pages';
import { formatTime } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import WellnessBox, { WELLNESS_TYPE_MAP } from '../Device/WellnessBox';
import WellnessContainer from '../Device/WellnessContainer';
import { gridLocalization } from '../OTA/MuiDeviceTable';
import { getFFWellnessDetails, getICWellnessDetails } from '../Overview/utils';
import { GridRenderWithOptions } from '../Users/UserDataGrid';

const wellnessClassMap: Record<string, string> = {
    well: 'wellness-well',
    unwell: 'wellness-unwell',
    unknown: 'wellness-unknown',
};

const wellnessColorMap: Record<string, string> = {
    'wellness-well': lighten(palette.wellness.green, 0.5),
    'wellness-unwell': lighten(palette.wellness.red, 0.5),
    'wellness-unknown': lighten(palette.wellness.gray, 0.5),
};

const wellnessHeaderMap: Record<string, string> = {
    duration_without_continuous: 'Duration Without Continuous',
    events_without_videos: 'Events Without Videos',
    multiple_button_presses: 'Multiple Button Presses',
    system_constantly_on: 'System Constantly On',
    multiple_improper_shutdowns: 'Multiple Improper Shutdowns',
    wrong_version: 'Wrong Version',
    wrong_config: 'Wrong Configuration',
    wrong_installation: 'Wrong Installation Params',
};
const OperationHeaderMap: Record<string, string> = {
    vehicle_status: 'Vehicle Status',
    assignee: 'Assignee',
    sim_card_status: 'SIM Card Status',
    vehicle_for_maintenance: 'Vehicle For Maintenance',
};
const OperationDefaultOptionsMap: Record<keyof typeof OperationHeaderMap, string[]> = {
    vehicle_status: ['Sold', 'Stolen', 'Garage', 'Total loss', 'Need To Provide Service'],
    assignee: [],
    sim_card_status: [],
    vehicle_for_maintenance: [],
};
const wellnessByString = {
    well: true,
    unwell: false,
    unknown: null,
};

const statusToWellnessStr = (status?: boolean | null) => {
    if (status === null) return 'unknown';
    return status ? 'well' : 'unwell';
};

const statusToBoolean = (status: keyof typeof wellnessByString): boolean | null => {
    const res = wellnessByString[status];
    if (res === undefined) {
        return null;
    }
    return res;
};

const renderWellnessCell = (params: GridRenderCellParams) => {
    if (!params.value) return '';
    if (params.row.id === '0') {
        return params.value.map((v: string, idx: number) => (
            <Chip
                key={`${params.field}-${idx}`}
                label={<Typography fontSize={12}>{v}</Typography>}
                sx={{
                    ml: !!idx ? 0.5 : 0,
                    backgroundColor: Object.values(wellnessColorMap)[idx],
                }}
            />
        ));
    }
    const cellContent = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: wellnessColorMap[wellnessClassMap[params.value as string]],
            }}
        >
            <Typography variant="body2">{params.value}</Typography>
        </Box>
    );
    const metadata = params.row.metadata[params.field];
    return !!metadata ? (
        <Tooltip followCursor title={JSON.stringify(metadata)}>
            {cellContent}
        </Tooltip>
    ) : (
        cellContent
    );
};

const SelectEditInputCell = (props: GridRenderWithOptions) => {
    const { id, field, value } = props;
    const apiRef = useGridApiContext();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = async (_event: any, newValue: string) => {
        await apiRef.current.setEditCellValue({ id, field, value: newValue });
        apiRef.current.stopCellEditMode({ id, field });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleInputChange = async (_event: any, newValue: string) => {
        await apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <Autocomplete
            freeSolo
            fullWidth
            value={value || ''}
            onChange={handleChange}
            onInputChange={handleInputChange}
            disableClearable
            options={props.options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant="standard"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        disableUnderline: true,
                        // match input style with the cell
                        style: { paddingLeft: '9px', paddingRight: '9px', fontSize: '0.875rem' },
                    }}
                    sx={{
                        '& .MuiInputBase-root': {
                            height: '100%',
                        },
                    }}
                />
            )}
            sx={{ height: '100%', '& .MuiFormControl-root': { height: '100%' } }}
        />
    );
};

const renderSelectEditExtraOptions = (params: GridRenderCellParams, options: string[]) => {
    return <SelectEditInputCell options={[...new Set(options)]} {...params} />;
};

interface QuickSearchToolbarProps {
    value: string;
    onChange: () => void;
}

const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

    const getAllRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridExpandedSortedRowIdsSelector(apiRef);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column-reverse' : 'row',
                justifyContent: 'space-between',
                alignItems: 'end',
                py: 1,
                overflow: 'none',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'end',
                    width: '100%',
                }}
            >
                <TextField
                    fullWidth={isMobile}
                    id="table-search-field"
                    variant="standard"
                    value={props.value}
                    onChange={props.onChange}
                    size="small"
                    placeholder="Search"
                    InputProps={{
                        startAdornment: <SearchIcon color="primary" fontSize="small" sx={{ mb: 0.5 }} />,
                    }}
                    sx={{ mr: 1 }}
                />
                <Box sx={{ flexShrink: 0, display: 'flex' }}>
                    <GridToolbarColumnsButton id="columns-btn" variant="outlined" sx={{ mr: 1 }} />
                    <GridToolbarFilterButton
                        componentsProps={{ button: { id: 'filter-btn', variant: 'outlined' } }}
                        sx={{ mr: 1 }}
                    />
                    <GridToolbarExportContainer id="export-btn" variant="outlined" sx={{ height: '32px' }}>
                        {/* prevent ts error when passing id to menu item */}
                        <GridCsvExportMenuItem
                            options={{ getRowsToExport: getAllRows, utf8WithBom: true }}
                            {...{ id: 'export-as-csv' }}
                        />
                    </GridToolbarExportContainer>
                </Box>
            </Box>
        </Box>
    );
};

const OperationsTable: React.FC = () => {
    const [searchText, setSearchText] = useState<string>('');
    const [wellness, setWellness] = useState<Wellness[]>([]);
    const [devices, setDevices] = useState<DeviceV2[]>([]);
    const [operationData, setOperationData] = useState<DeviceOperationData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [alertElement, setAlert] = useAlert();
    const { api } = useApi();
    const { i18n, t } = useTranslation();
    const apiRef = useGridApiRef();
    const { profile } = useProfile();
    const locale = localeObjectMap[i18n.languages[0]];

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${operationsPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        const abortController = new AbortController();

        // wellness
        const loadPageWellness = async (page: number): Promise<Array<Wellness>> => {
            try {
                const res = await api.apiAdminWellnessGet({ page, pageSize: 1000 }, { signal: abortController.signal });
                const moreData = res.data.length > 0 ? await loadPageWellness(page + 1) : [];
                return [...res.data, ...moreData] as Array<Wellness>;
            } catch {
                if (!abortController.signal.aborted) {
                    setAlert({
                        message: `Failed to load wellness data`,
                        type: 'error',
                        duration: 6000,
                    });
                }
                return [];
            }
        };
        const wellnessPromise = loadPageWellness(1).then((res) => {
            setWellness(res);
        });

        // devices
        const loadPageDevices = async (page: number): Promise<Array<DeviceV2>> => {
            try {
                const res = await api.apiAdminDeviceGet({ page, pageSize: 250 }, { signal: abortController.signal });
                const moreData = res.data.length > 0 ? await loadPageDevices(page + 1) : [];
                return [...res.data, ...moreData] as Array<DeviceV2>;
            } catch {
                if (!abortController.signal.aborted) {
                    setAlert({
                        message: `Failed to load device data`,
                        type: 'error',
                        duration: 6000,
                    });
                }
                return [];
            }
        };
        const devicesPromise = loadPageDevices(1).then((res) => {
            setDevices(res);
        });

        const operationPromise = api
            .apiAdminDeviceOperationGet({ signal: abortController.signal })
            .then((res) => {
                setOperationData(res.data);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setAlert({
                        message: `Failed to load operations data`,
                        type: 'error',
                        duration: 6000,
                    });
                }
            });

        Promise.all([wellnessPromise, devicesPromise, operationPromise]).then(() => {
            setLoading(false);
        });

        return () => abortController.abort();
    }, []);

    const wellnessData = useMemo(() => {
        if (!loading) {
            const wellnessRequestByDevice = wellness.reduce((acc, curr) => {
                if (acc[curr.device_id] === undefined) {
                    acc[curr.device_id] = [];
                }
                acc[curr.device_id].push(curr);
                return acc;
            }, {} as Record<string, Wellness[]>);
            const deviceRequestByDevice = Object.fromEntries(devices.map((d) => [d.device.device_id, d]));
            const operationRequestByDevice = operationData.reduce((acc, curr) => {
                if (acc[curr.device_id] === undefined) {
                    acc[curr.device_id] = [];
                }
                acc[curr.device_id].push(curr);
                return acc;
            }, {} as Record<string, DeviceOperationData[]>);
            const newWellnessData = Object.keys(wellnessRequestByDevice).reduce((acc, curr) => {
                const device = deviceRequestByDevice[curr];
                const wellness = wellnessRequestByDevice[curr] || [];
                const operationData = operationRequestByDevice[curr] || [];
                if (device) {
                    acc[curr] = [device, wellness, operationData];
                }
                return acc;
            }, {} as Record<string, [DeviceV2, Wellness[], DeviceOperationData[]]>);
            return newWellnessData;
        }
        return {};
    }, [loading, devices, wellness, operationData]);

    const customerToCountry = useMemo(() => {
        const ISRAEL_LICENSE_PLATE_LENGTH = [7, 8];
        const customerToCountryMap: Record<string, string> = {};
        const getCountry = (device: DeviceV2) =>
            ISRAEL_LICENSE_PLATE_LENGTH.includes(device.device.license_plate.replaceAll('-', '').length) ? 'IL' : 'US';
        devices
            .filter((device) => !device.device.license_plate.includes('-duplicate-'))
            .forEach((device) => {
                if (device.device.customer && customerToCountryMap[device.device.customer] != 'US') {
                    customerToCountryMap[device.device.customer] = getCountry(device);
                }
            });
        return customerToCountryMap;
    }, [devices]);

    const renderBooleanCell = (params: GridRenderCellParams) => {
        if (params.row.id === '0') {
            return '';
        }
        return params.value?.toString().toLocaleLowerCase() === 'true' ? <CheckIcon /> : <CloseIcon />;
    };

    const renderBooleanEdit = (params: GridRenderCellParams) => {
        const { id, field } = params;

        const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.checked });
        };

        return <Checkbox checked={params.value} onChange={handleChange} />;
    };

    const processRowUpdate = (newRow: GridRowModel, prevRow: GridRowModel) => {
        // get changed field
        const changedField = Object.entries(newRow).find(([k, v]) => {
            if (prevRow[k] === undefined) {
                return true;
            }
            return v !== prevRow[k];
        });
        if (changedField) {
            const [newName, newValue] = changedField;
            const isWellnessUpdate = Object.keys(wellnessHeaderMap).includes(newName ?? '');
            const updateWellnessState = (
                prev: Record<string, [DeviceV2, Wellness[], DeviceOperationData[]]>,
                updateName: string,
                updateStatus: keyof typeof wellnessByString,
            ) => {
                const newData = { ...prev };
                if (isWellnessUpdate) {
                    setWellness((prev) => {
                        const newWellness = {
                            device_id: newRow.id,
                            name: updateName,
                            status: statusToBoolean(updateStatus),
                        } as Wellness;
                        const wellnessIdx = prev.findIndex(
                            (w) =>
                                w.device_id === newRow.id &&
                                w.name.toLocaleLowerCase() === updateName.toLocaleLowerCase(),
                        );
                        if (wellnessIdx === -1) {
                            return [...prev, newWellness];
                        }
                        const row = [...prev];
                        row[wellnessIdx] = newWellness;
                        return row;
                    });
                } else {
                    setOperationData((prev) => {
                        const newOperation = {
                            device_id: newRow.id,
                            name: updateName,
                            status: updateStatus,
                        } as DeviceOperationData;
                        const newOperationIdx = prev.findIndex(
                            (o) =>
                                o.device_id === newRow.id &&
                                o.name.toLocaleLowerCase() === updateName.toLocaleLowerCase(),
                        );
                        if (newOperationIdx === -1) {
                            return [...prev, newOperation];
                        }
                        const row = [...prev];
                        row[newOperationIdx] = newOperation;
                        return row;
                    });
                }
                return newData;
            };

            updateWellnessState(wellnessData, newName, newValue);
            let promise: Promise<unknown>;
            if (isWellnessUpdate) {
                promise = api.apiAdminWellnessPost({
                    wellnessUpdate: {
                        device_id: newRow.id,
                        name: newName,
                        status: statusToBoolean(newValue),
                    },
                });
            } else {
                promise = api.apiAdminDeviceOperationPost({
                    deviceOperationData: {
                        device_id: newRow.id,
                        name: newName,
                        status: newValue,
                    },
                });
            }
            promise.catch(() => {
                setAlert({
                    message: `Failed to update status`,
                    type: 'error',
                    duration: 6000,
                });
                updateWellnessState(wellnessData, newName, prevRow[newName]);
            });
        }
        return newRow;
    };

    const totalWellness: Record<string, Record<string, number>> = Object.fromEntries(
        Object.keys(wellnessHeaderMap).map((key) => [key, { well: 0, unwell: 0, unknown: 0, total: 0 }]),
    );

    let rows = Object.values(wellnessData).map(([device, wellness, operationData]) => {
        const wellnessByType = Object.fromEntries(
            wellness.map((w) => {
                const wellnessName = w.name.toLocaleLowerCase();
                const wellnessStatus = statusToWellnessStr(w.status);
                if (wellnessName in totalWellness && wellnessStatus in totalWellness[wellnessName]) {
                    totalWellness[wellnessName][wellnessStatus] += 1;
                    totalWellness[wellnessName].total += 1;
                }
                return [wellnessName, wellnessStatus];
            }),
        );
        const operationDataByType = Object.fromEntries(
            operationData.map((o) => {
                const operationName = o.name.toLocaleLowerCase();
                const operationStatus = o.status;
                return [operationName, operationStatus];
            }),
        );

        return {
            device: device,
            id: device.device.device_id,
            customer: device.device.customer,
            sub_fleet: device.device.customer_sub_fleet,
            operator: device.device.operator,
            country: customerToCountry[device.device.customer],
            device_id: device.device.device_id,
            last_seen: device.last_seen ? new Date(device.last_seen * 1000) : undefined,
            version_name: device.version_name || 'N/A',
            sim_number: device.sim_number || 'N/A',
            license_plate: device.device.license_plate,
            vehicle_status: '',
            ...wellnessByType,
            ...operationDataByType,
            metadata: Object.fromEntries(wellness.map((w) => [w.name.toLocaleLowerCase(), w.metadata])),
        };
    });

    const dynamicDropdowns = Object.fromEntries(
        Object.keys(OperationHeaderMap).map((key) => [
            key,
            [...new Set(rows.map((r) => r[key as keyof typeof rows[0]]).filter((v) => v !== undefined))],
        ]),
    );
    const subFleets = [...new Set(rows.map((r) => r.sub_fleet.toString()))].sort();

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'customer',
                headerName: 'Customer',
                width: 150,
            },
            {
                field: 'sub_fleet',
                headerName: 'Sub Fleet',
                width: 100,
                type: 'singleSelect',
                valueOptions: subFleets,
            },
            {
                field: 'operator',
                headerName: 'Operator',
                width: 150,
            },
            {
                field: 'license_plate',
                headerName: 'License Plate',
                width: 150,
            },
            {
                field: 'country',
                headerName: 'Country',
                width: 50,
                type: 'singleSelect',
                valueOptions: ['IL', 'US'],
            },
            {
                field: 'device_id',
                headerName: 'IEMI',
                width: 150,
            },
            {
                field: 'last_seen',
                headerName: 'Last Seen',
                ...getDateTimeColumnType(locale),
                renderCell: (params: GridRenderCellParams) => {
                    if (params.row.id === '0') {
                        return '';
                    }
                    return params.value
                        ? formatTime((new Date().getTime() - params.value.getTime()) / 1000)
                        : 'unknown';
                },
                width: 150,
            },
            {
                field: 'version_name',
                headerName: 'Version Name',
                width: 150,
            },
            {
                field: 'sim_number',
                headerName: 'SIM Number',
                width: 150,
            },
            {
                field: 'incabin',
                headerName: 'In Cabin Wellness',
                type: 'singleSelect',
                width: 150,
                valueFormatter: (params: GridValueFormatterParams) => params.value || 'inactive',
                valueOptions: Object.entries(WELLNESS_TYPE_MAP).map(([value, label]) => ({ value, label: t(label) })),
                valueGetter: (params: GridCellParams) => {
                    return getICWellnessDetails(
                        params.row?.device || {},
                        profile.customer.settings.inactive_device_threshold,
                    );
                },
                renderCell: (params: GridRenderCellParams) => {
                    const device = params.row?.device;
                    return device ? (
                        <WellnessContainer
                            device={device}
                            wellnessType="in_cabin"
                            wellnessId={device.aggregated_wellness?.in_cabin_wellness?.id}
                            timestamp={device.aggregated_wellness?.in_cabin_wellness?.timestamp}
                            wellnessDetails={getICWellnessDetails(
                                device,
                                profile.customer.settings.inactive_device_threshold,
                            )}
                        />
                    ) : null;
                },
            },
            {
                field: 'frontfacing',
                headerName: 'Front Facing Wellness',
                type: 'singleSelect',
                width: 150,
                valueFormatter: (params: GridValueFormatterParams) => params.value ?? 'inactive',
                valueOptions: Object.entries(WELLNESS_TYPE_MAP).map(([value, label]) => ({ value, label: t(label) })),
                valueGetter: (params: GridCellParams) => {
                    return getFFWellnessDetails(
                        params.row?.device || {},
                        profile.customer.settings.inactive_device_threshold,
                    );
                },
                renderCell: (params: GridRenderCellParams) => {
                    const device = params.row?.device;
                    return device ? (
                        <WellnessContainer
                            device={device}
                            wellnessType="front_facing"
                            wellnessId={device.aggregated_wellness?.front_facing_wellness?.id}
                            timestamp={device.aggregated_wellness?.front_facing_wellness?.timestamp}
                            wellnessDetails={getFFWellnessDetails(
                                device,
                                profile.customer.settings.inactive_device_threshold,
                            )}
                        />
                    ) : null;
                },
            },
            {
                field: 'obd',
                headerName: 'OBD Wellness',
                type: 'singleSelect',
                width: 150,
                valueFormatter: (params: GridValueFormatterParams) => params.value || 'inactive',
                valueOptions: Object.entries(WELLNESS_TYPE_MAP).map(([value, label]) => ({ value, label: t(label) })),
                valueGetter: (params: GridCellParams) => {
                    const device = params.row?.device;
                    return device?.aggregated_wellness?.front_facing_wellness?.wellness_state ?? 'inactive';
                },
                renderCell: (params: GridRenderCellParams) => {
                    const obdWellnessDetails = params.row?.device?.aggregated_wellness?.obd_state?.obd_state;
                    return obdWellnessDetails && obdWellnessDetails !== 'none' ? (
                        <WellnessBox wellnessType={'obd'} wellnessDetails={obdWellnessDetails} />
                    ) : null;
                },
            },
            ...Object.entries(wellnessHeaderMap).map(
                ([key, value]) =>
                    ({
                        field: key,
                        headerName: value,
                        type: 'singleSelect',
                        valueOptions: ['well', 'unwell', 'unknown'],
                        align: 'center',
                        headerAlign: 'center',
                        minWidth: 200,
                        flex: 1,
                        renderCell: renderWellnessCell,
                        editable: true,
                        renderEditCell: (params: GridRenderCellParams) =>
                            renderSelectEditExtraOptions(params, ['well', 'unwell', 'unknown']),
                    } as GridColDef),
            ),
            ...Object.entries(OperationHeaderMap).map(
                ([key, value]) =>
                    ({
                        field: key,
                        headerName: value,
                        type: 'singleSelect',
                        valueOptions: [
                            ...new Set([...OperationDefaultOptionsMap[key], ...(dynamicDropdowns[key] as string[])]),
                        ],
                        align: 'center',
                        headerAlign: 'center',
                        minWidth: 200,
                        flex: 1,
                        editable: true,
                        renderCell: (params: GridRenderCellParams) =>
                            key === 'vehicle_for_maintenance' ? renderBooleanCell(params) : undefined,
                        renderEditCell: (params: GridRenderCellParams) =>
                            key === 'vehicle_for_maintenance'
                                ? renderBooleanEdit(params)
                                : renderSelectEditExtraOptions(params, [
                                      ...OperationDefaultOptionsMap[key],
                                      ...(dynamicDropdowns[key] as string[]),
                                  ]),
                    } as GridColDef),
            ),
        ];
    }, [dynamicDropdowns]);

    if (searchText) {
        rows = rows.filter((row) => {
            const textWithoutDashes = searchText.toLocaleLowerCase().replaceAll('-', '');
            const textLower = textWithoutDashes.toLocaleLowerCase();
            const normalizedLicensePlate = row.license_plate.toLocaleLowerCase().replaceAll('-', '');
            const normalizedCustomerName = row.customer.toLocaleLowerCase();
            const licenseMatch = !!normalizedLicensePlate && normalizedLicensePlate.includes(textWithoutDashes);
            const customerMatch = !!normalizedCustomerName && normalizedCustomerName.includes(textLower);
            return customerMatch || licenseMatch;
        });
    }

    const totalRow = {
        id: '0',
        customer: 'Total',
        license_plate: '',
        ...Object.fromEntries(
            Object.entries(totalWellness)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .filter(([_key, value]) => !!value.total)
                .map(([key, value]) => [
                    key,
                    [
                        `${((value.well / value.total) * 100).toFixed(1)}%`,
                        `${((value.unwell / value.total) * 100).toFixed(1)}%`,
                        `${((value.unknown / value.total) * 100).toFixed(1)}%`,
                    ],
                ]),
        ),
        metadata: {},
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                pb: 2,
                '& .MuiDataGrid-cell--withRenderer': {
                    p: '0 !important',
                },
            }}
        >
            {alertElement}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                <StyledDataGrid
                    id="operations-data-grid"
                    apiRef={apiRef}
                    density="compact"
                    loading={loading}
                    columns={columns}
                    rows={rows}
                    disableMultipleRowSelection
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    pagination={true}
                    getCellClassName={(params: GridCellParams) => wellnessClassMap[params.value as string]}
                    getRowClassName={(params: GridRowParams) => (params.id === '0' ? 'total-row' : '')}
                    pinnedRows={{ bottom: [totalRow] }}
                    processRowUpdate={processRowUpdate}
                    slots={{ toolbar: QuickSearchToolbar }}
                    slotProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value),
                        },
                        pagination: {
                            sx: {
                                mr: '45px',
                            },
                        },
                        columnsPanel: {
                            sx: {
                                '& .MuiDataGrid-panelFooter button:first-child': {
                                    display: 'none',
                                },
                            },
                        },
                    }}
                    localeText={
                        {
                            ...gridLocalization[i18n.languages[0]],
                            filterPanelRemoveAll: t('table.remove_all'),
                            columnMenuManageColumns: t('table.manage_columns'),
                            unpin: t('table.unpin'),
                            filterOperatorOnDate: t('table.on_date'),
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any
                    }
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& .MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScrollerContent': {
                            borderBottom: `1px solid ${palette.neutral[400]}`,
                        },
                        '& .MuiDataGrid-cell': {
                            borderColor: 'bgColor.main',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .total-row': {
                            backgroundColor: 'bgColor.main',
                            '& .MuiDataGrid-cell': {
                                border: 'none',
                            },
                        },
                        '& .total-row:hover': {
                            backgroundColor: 'bgColor.main',
                        },
                        backgroundColor: palette.neutral[50],
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default OperationsTable;
