import { Box, FormControl, InputLabel, Select, SelectChangeEvent, Tabs, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { FleetScoreDetails, FleetScoreShort, OverviewData } from '../../../backendsdk';
import { TabPanel, a11yProps } from '../../../components/Tab';
import { TrackedMenuItem as MenuItem, TrackedTab as Tab } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { accidentsMetricsPageDefs, safetyMetricsPageDefs, wellnessMetricsPageDefs } from '../../../utils/Pages';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import AccidentOverview from './AccidentOverview/AccidentOverview';
import MetricsComponent from './SafetyWellness/MetricsComponent';

export const ANIMATION_LENGTH = 1000;

const TABS = ['safety', 'wellness', 'accidents'];
const PAGE_DEFS = [safetyMetricsPageDefs, wellnessMetricsPageDefs, accidentsMetricsPageDefs];

export type QuarterYear = { quarter: number; year: number };

const getLastFiveQuarters = (date: Dayjs): QuarterYear[] => {
    const quarters: { quarter: number; year: number }[] = [];
    const currentQuarter = getQuarterAndYear(date);
    quarters.push(currentQuarter);

    let prevQuarter = { quarter: currentQuarter.quarter, year: currentQuarter.year };
    for (let i = 0; i < 4; i++) {
        prevQuarter = {
            quarter: prevQuarter.quarter - 1 === 0 ? 4 : prevQuarter.quarter - 1,
            year: prevQuarter.quarter - 1 === 0 ? prevQuarter.year - 1 : prevQuarter.year,
        };
        quarters.push(prevQuarter);
    }

    return quarters;
};

const getQuarterAndYear = (date: Dayjs): QuarterYear => {
    const month = date.month(); // 0-11
    const year = date.year();
    const quarter = Math.floor(month / 3) + 1;
    return { quarter, year };
};

const getQuarterTimestamp = (quarterYear: QuarterYear): number => {
    const { quarter, year } = quarterYear;
    if (quarter === 0 && year === 0) {
        return dayjs.utc().startOf('day').unix();
    }
    const nextQuarter = quarter === 4 ? 1 : quarter + 1;
    const nextYear = quarter === 4 ? year + 1 : year;
    const firstMonthOfQuarter = (nextQuarter - 1) * 3 + 1;
    return dayjs.utc(`${nextYear}-${firstMonthOfQuarter}-01`).unix();
};

const MetricsContent: React.FC = () => {
    const { t } = useTranslation();
    const { profile } = useProfile();
    const ALL_FLEET = t('content.metrics.all_fleet');
    const [selectedSubFleet, setSelectedSubFleet] = useState<string>(profile.sub_fleet || ALL_FLEET);
    const match = useRouteMatch();
    const metricsTab = match.path.split('/')[2];
    const initialTab = metricsTab !== undefined && TABS.includes(metricsTab) ? TABS.indexOf(metricsTab) : 0;
    const [tab, setTab] = useState<number>(initialTab);
    const { api, agencyApi } = useApi();
    const [isMetricsLoading, setIsMetricsLoading] = useState<boolean>(true);
    const [isMetricsError, setIsMetricsError] = useState<boolean>(false);
    const [isOverviewLoading, setIsOverviewLoading] = useState<boolean>(true);
    const [isOverviewError, setIsOverviewError] = useState<boolean>(false);
    const [fleetScoreDetails, setFleetScoreDetails] = useState<Record<string, FleetScoreDetails>>({});
    const [fleetScoreTrend, setFleetScoreTrend] = useState<FleetScoreShort[]>([]);
    const [overviewData, setOverviewData] = useState<OverviewData[]>([]);
    const [handlingStatusList, setHandlingStatusList] = useState<Array<string>>([]);
    const [animateMetrics, setAnimateMetrics] = useState<boolean>(true);
    const [animateAccidents, setAnimateAccidents] = useState<boolean>(true);
    const [hiddenSubFleets, setHiddenSubFleets] = useState<string[]>([]);
    const [timespan, setTimespan] = useState<QuarterYear>({ quarter: 0, year: 0 });
    const isMobile = useIsMobile();
    const history = useHistory();

    const subFleetList = profile.customer.sub_fleets.filter(Boolean).sort((a, b) => a.localeCompare(b));
    subFleetList.unshift(ALL_FLEET);
    const hasSubFleet = !!profile.sub_fleet;

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        history.push(`/metrics/${TABS[newValue]}`);
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${PAGE_DEFS[tab].name}`)}`;
    }, [tab]);

    useEffect(() => {
        if (metricsTab !== undefined && TABS.includes(metricsTab)) {
            setTab(TABS.indexOf(metricsTab));
        } else {
            history.replace('/metrics/safety');
        }
    }, [metricsTab]);

    const getMetrics = () => {
        setIsMetricsError(false);
        setIsMetricsLoading(true);
        api.apiV0FleetScoreGet({ cache: 1 })
            .then((res) => {
                if (res.data.length > 0) {
                    setFleetScoreDetails(
                        Object.fromEntries(res.data.map((fsd) => [!!fsd.sub_fleet ? fsd.sub_fleet : ALL_FLEET, fsd])),
                    );
                    return api
                        .apiV0FleetScoreTrendGet({
                            cache: 1,
                            endDate: Math.max(...res.data.map((fsd) => fsd.timestamp)),
                        })
                        .then((res) => {
                            setFleetScoreTrend(res.data);
                            setIsMetricsLoading(false);
                            setTimeout(() => setAnimateMetrics(false), ANIMATION_LENGTH);
                        });
                }
                return Promise.reject();
            })
            .catch(() => setIsMetricsError(true));
    };

    const getAccidentOverview = () => {
        setIsOverviewError(false);
        setIsOverviewLoading(true);
        api.apiV0AccidentOverviewGet()
            .then((res) => {
                setOverviewData(res.data.map((d) => (d.sub_fleet === '' ? { ...d, sub_fleet: ALL_FLEET } : d)));
                setIsOverviewLoading(false);
                setTimeout(() => setAnimateAccidents(false), ANIMATION_LENGTH);
            })
            .catch(() => setIsOverviewError(true));
    };

    useEffect(() => {
        getMetrics();
        getAccidentOverview();
    }, []);

    useEffect(() => {
        agencyApi
            .agencyV1CustomerDataGet()
            .then((res) =>
                setHandlingStatusList(res.data.handling_status.map((status: { name: string }) => status.name)),
            );
    }, []);

    return (
        <Box sx={{ width: '100%', maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column-reverse' : 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                    mb: 1,
                    borderBottom: `1px solid ${palette.gray[400]}`,
                }}
            >
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                    sx={{ width: isMobile ? '100%' : undefined }}
                    variant={isMobile ? 'fullWidth' : undefined}
                >
                    <Tab
                        label={<Typography fontSize={14}>{t('content.metrics.safety')}</Typography>}
                        {...a11yProps(0)}
                        id="safety-tab"
                        data-testid={`tab-0`}
                    />
                    <Tab
                        label={<Typography fontSize={14}>{t('content.metrics.wellness')}</Typography>}
                        {...a11yProps(1)}
                        id="wellness-tab"
                        data-testid={`tab-1`}
                    />
                    <Tab
                        label={<Typography fontSize={14}>{t('content.metrics.accidents')}</Typography>}
                        {...a11yProps(2)}
                        id="accidents-tab"
                        data-testid={`tab-2`}
                    />
                </Tabs>
                <Box sx={{ display: 'flex' }}>
                    {tab === 2 ? (
                        <FormControl fullWidth sx={{ width: 200, mb: 1, mr: 1 }}>
                            <InputLabel>{t('content.accident_overview.timespan')}</InputLabel>
                            <Select
                                label={t('content.accident_overview.timespan')}
                                value={`${timespan.quarter}-${timespan.year}`}
                                id="timespan-select"
                                data-testid="timespan-select"
                                onChange={(e) => {
                                    const [quarter, year] = (e.target.value as string).split('-').map(Number);
                                    setTimespan({ quarter, year });
                                }}
                                variant="outlined"
                                size="small"
                            >
                                <MenuItem id="last-3m-timespan" value="0-0">
                                    {t('content.accident_overview.past_three_months')}
                                </MenuItem>
                                {getLastFiveQuarters(dayjs.utc()).map((quarterYear) => (
                                    <MenuItem
                                        id={`quarter-${quarterYear.quarter}-year-${quarterYear.year}-timespan`}
                                        key={`${quarterYear.quarter}-${quarterYear.year}`}
                                        value={`${quarterYear.quarter}-${quarterYear.year}`}
                                    >
                                        {t('content.accident_overview.quarter', {
                                            quarter: quarterYear.quarter,
                                            year: quarterYear.year,
                                        })}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                    {!hasSubFleet ? (
                        <FormControl sx={{ width: 200, mb: 1 }}>
                            <InputLabel id="sub-fleet-field-label">{t('content.metrics.sub_fleet')}</InputLabel>
                            <Select
                                id="sub-fleet-field"
                                value={selectedSubFleet}
                                onChange={(e: SelectChangeEvent) => {
                                    setHiddenSubFleets((prev) => prev.filter((sf) => sf !== e.target.value));
                                    setSelectedSubFleet(e.target.value);
                                }}
                                labelId="sub-fleet-field-label"
                                label={t('content.metrics.sub_fleet')}
                                size="small"
                            >
                                {subFleetList.map((subFleet) => (
                                    <MenuItem
                                        id={`sub-fleet-${subFleet}-menu-item`}
                                        data-testid={`${subFleet}-item`}
                                        key={subFleet}
                                        value={subFleet}
                                    >
                                        {subFleet}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                </Box>
            </Box>
            <TabPanel value={tab} index={0} sx={{ width: '100%' }}>
                <MetricsComponent
                    scoreType="safety"
                    selectedSubFleet={selectedSubFleet}
                    setSelectedSubFleet={setSelectedSubFleet}
                    fleetScoreDetails={fleetScoreDetails}
                    fleetScoreTrend={fleetScoreTrend}
                    isLoading={isMetricsLoading}
                    isError={isMetricsError}
                    animate={animateMetrics}
                    getMetrics={getMetrics}
                    hiddenSubFleets={hiddenSubFleets}
                    setHiddenSubFleets={setHiddenSubFleets}
                />
            </TabPanel>
            <TabPanel value={tab} index={1} sx={{ width: '100%' }}>
                <MetricsComponent
                    scoreType="wellness"
                    selectedSubFleet={selectedSubFleet}
                    setSelectedSubFleet={setSelectedSubFleet}
                    fleetScoreDetails={fleetScoreDetails}
                    fleetScoreTrend={fleetScoreTrend}
                    isLoading={isMetricsLoading}
                    isError={isMetricsError}
                    animate={animateMetrics}
                    getMetrics={getMetrics}
                    hiddenSubFleets={hiddenSubFleets}
                    setHiddenSubFleets={setHiddenSubFleets}
                />
            </TabPanel>
            <TabPanel value={tab} index={2} sx={{ width: '100%' }}>
                <AccidentOverview
                    selectedSubFleet={selectedSubFleet}
                    setSelectedSubFleet={setSelectedSubFleet}
                    overviewData={overviewData.filter((data) => data.timestamp === getQuarterTimestamp(timespan))}
                    handlingStatusList={handlingStatusList}
                    setHandlingStatusList={setHandlingStatusList}
                    isLoading={isOverviewLoading}
                    isError={isOverviewError}
                    animate={animateAccidents}
                    getAccidentOverview={getAccidentOverview}
                    hiddenSubFleets={hiddenSubFleets}
                    setHiddenSubFleets={setHiddenSubFleets}
                    timespan={timespan}
                />
            </TabPanel>
        </Box>
    );
};

export default MetricsContent;
