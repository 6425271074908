import CloseIcon from '@mui/icons-material/Close';
import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';

export interface ConfirmSaveDialogProps {
    isDialogOpen: boolean;
    onClose: CallableFunction;
    onConfirm: CallableFunction;
    duplicateId: number;
}

const ConfirmSaveDialog: React.FC<ConfirmSaveDialogProps> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Dialog id="confirm-save-dialog" open={props.isDialogOpen} onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>{t('content.accidents.form.duplicate.header')}</Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    pt: 1,
                    pb: 2,
                    px: 3,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%' }}>
                    <Typography>{t('content.accidents.form.duplicate.body1')}</Typography>
                    <Typography>{t('content.accidents.form.duplicate.body2')}</Typography>
                </Box>
                <Box sx={{ display: 'flex', mt: 1, width: '100%', justifyContent: 'center' }}>
                    <Button
                        id="btn-view-duplicate"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(`/accidents/${props.duplicateId}`)}
                        sx={{ flexGrow: 1 }}
                    >
                        {t('content.accidents.form.duplicate.view')}
                    </Button>
                    <Button
                        id="save-btn"
                        data-testid="save-btn"
                        variant="contained"
                        color="secondary"
                        onClick={() => props.onConfirm()}
                        sx={{ ml: 1, flexGrow: 1 }}
                    >
                        {t('content.accidents.form.duplicate.save')}
                    </Button>
                </Box>
            </Paper>
        </Dialog>
    );
};

export default ConfirmSaveDialog;
