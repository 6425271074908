import { Box, Card, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { TrackedMenuItem as MenuItem } from '../../components/TrackedComponents';
import { useQuery } from '../../hooks/query';
import { DEFAULT_TITLE } from '../Layout';
import { LANGUAGES, LANGUAGES_OPTIONS } from '../Navigator/Navigator';
import './LoginComponent.css';
import LoginForm from './LoginForm';
import ResetForm from './ResetForm';

const LoginComponent: React.FC = () => {
    const { i18n, t } = useTranslation();
    const [language, setLanguage] = useState<string>(
        LANGUAGES_OPTIONS.includes(i18n.languages[0]) ? i18n.languages[0] : 'en',
    );
    const history = useHistory();
    const query = useQuery();
    const resetToken = query.get('token');

    const changeLanguage = (lang: string) => {
        setLanguage(lang);
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t('login.title')}`;
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: 350, height: 460, overflowY: 'auto' }}>
                {!!resetToken ? (
                    <ResetForm
                        resetToken={resetToken}
                        onSuccess={() => history.replace('/login?reset')}
                        errorMessage={'login.reset_error_msg'}
                    />
                ) : (
                    <LoginForm />
                )}
            </Card>
            <TextField
                size="small"
                label={t('login.language')}
                value={language}
                select
                sx={{ width: 150, mt: 2.5 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeLanguage(e.target.value)}
            >
                {LANGUAGES_OPTIONS.map((option: string) => (
                    <MenuItem id={`${option}-language-menu-item`} key={option} value={option}>
                        {LANGUAGES[option]}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

export default LoginComponent;
