import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, Paper, Slider } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';

import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';
import useCallbackRef from '../../../hooks/callbackRef';
import { getCroppedImg } from './ImageCropUtils';

interface UploadImageModalProps {
    onClose: () => void;
    onUpload: CallableFunction;
}

const readFile = (file: File) => {
    return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result as string), false);
        reader.readAsDataURL(file);
    });
};

const UploadImageModal: React.FC<UploadImageModalProps> = (props: UploadImageModalProps) => {
    const [imageSrc, setImageSrc] = useState<string>();
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [cropedImage, setCropedImage] = useState<string>();
    const [zoom, setZoom] = useState<number>(1);
    const [ref, node] = useCallbackRef<HTMLInputElement>();
    const { t } = useTranslation();

    useEffect(() => {
        // close on file input cancel
        const closeModal = () => {
            if (node && node.files && node.files.length === 0) {
                props.onClose();
            }
        };
        window.addEventListener('focus', closeModal);
        return () => {
            window.removeEventListener('focus', closeModal);
        };
    }, [node]);

    const onCropComplete = useCallback(
        (_croppedArea: Area, croppedAreaPixels: Area) => {
            getCroppedImg(imageSrc as string, croppedAreaPixels).then((newCroppedImage) => {
                if (newCroppedImage) {
                    setCropedImage(newCroppedImage);
                }
            });
        },
        [imageSrc],
    );

    const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const imageDataUrl = await readFile(file);
            setImageSrc(imageDataUrl);
        }
    };

    useEffect(() => {
        if (node && !imageSrc) {
            node.click();
        }
    }, [node]);

    return (
        <Dialog id="upload-image-dialog" open={true} fullWidth={true} maxWidth="xs" onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccountCircleIcon sx={{ mr: 0.5 }} />
                    {t('content.user_settings.profile_picture.header')}
                </Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    p: 1,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                }}
            >
                <Button id="btn-browse-image" size="small" variant="contained" component="label" sx={{ mr: 1 }}>
                    {t('content.fleet.update.file')}
                    <input
                        id="file-btn"
                        data-testid="file-btn"
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={onFileChange}
                        ref={ref}
                    />
                </Button>
                {imageSrc ? (
                    <>
                        <Box
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: 400,
                                backgroundColor: 'primary.main',
                                mt: 1,
                            }}
                        >
                            <Cropper
                                image={imageSrc}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                cropShape="round"
                                showGrid={false}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, width: '100%' }}>
                            <ZoomOutIcon />
                            <Slider
                                size="small"
                                value={zoom}
                                min={1}
                                max={5}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(_e, zoom) => setZoom(zoom as number)}
                                sx={{ mx: 1 }}
                            />
                            <ZoomInIcon />
                            <Button
                                id="save-image-btn"
                                data-testid="save-image-btn"
                                variant="contained"
                                color="secondary"
                                sx={{ ml: 1, flexShrink: 0 }}
                                onClick={() => props.onUpload(cropedImage)}
                            >
                                {t('content.user_settings.profile_picture.save')}
                            </Button>
                        </Box>
                    </>
                ) : null}
            </Paper>
        </Dialog>
    );
};

export default UploadImageModal;
