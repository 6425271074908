import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Card, InputLabel, Tooltip } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-canvas';

interface AccidentDriverSignatureProps {
    signatureUrl: string;
    setSignatureUrl: CallableFunction;
    disabled?: boolean;
}

const AccidentDriverSignature: React.FC<AccidentDriverSignatureProps> = (props: AccidentDriverSignatureProps) => {
    const { t } = useTranslation();
    const sigRef = useRef({} as SignaturePad);

    useEffect(() => {
        if (props.disabled) {
            sigRef.current.off();
        } else {
            sigRef.current.on();
        }
    }, [props.disabled]);

    useEffect(() => {
        if (props.signatureUrl) {
            sigRef.current.fromDataURL(props.signatureUrl);
        } else {
            sigRef.current.clear();
        }
    }, [props.signatureUrl]);

    const clearSignature = () => {
        sigRef.current?.clear();
        props.setSignatureUrl('');
    };

    return (
        <Card
            sx={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', position: 'relative', height: '100%' }}
        >
            {props.disabled ? null : (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        top: 0,
                        pt: 0.5,
                        alignItems: 'start',
                        fontSize: 14,
                        zIndex: 1,
                    }}
                >
                    <InputLabel sx={{ fontSize: '0.7rem', display: 'inline', lineHeight: 1.5, mr: 0.25 }}>
                        {t('content.accidents.driver.signature')}
                    </InputLabel>
                    <Tooltip title={t('content.accidents.driver.clear_signature')}>
                        <CancelIcon
                            id="clear-signature-btn"
                            fontSize="inherit"
                            onClick={clearSignature}
                            sx={{ cursor: 'pointer', color: 'neutral.main' }}
                        />
                    </Tooltip>
                </Box>
            )}
            <SignaturePad
                canvasProps={{ id: 'signature-field', className: 'sig-pad', height: '100%' }}
                ref={sigRef}
                clearOnResize={false}
                onEnd={() => props.setSignatureUrl(sigRef.current.toDataURL('image/png'))}
            />
        </Card>
    );
};

export default AccidentDriverSignature;
