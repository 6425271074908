import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import TheatersIcon from '@mui/icons-material/Theaters';
import { Box, Card, Dialog, Slider, SliderValueLabel, TextFieldProps, Typography, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CommandData, DeviceV2, TripDetails } from '../../../backendsdk';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { ResponsiveTimePicker } from '../../../components/MuiDatePicker';
import { TrackedButton as Button, TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import imageNA from '../../../images/image_not_available.png';
import { formatTimeObject, roundDownToMinute, secondsToTime } from '../../../utils/TimeFormatter';
import { RTLDirectionContext } from '../../Layout';
import SegmentImages from './SegmentImages';

interface SelectSegmentProps {
    devices: DeviceV2[];
    licensePlate: string;
    tripDetails: TripDetails;
    setRequests: CallableFunction;
    selectedRequest?: CommandData;
    setAlert: CallableFunction;
    updateMap: CallableFunction;
    onBack: CallableFunction;
    onClose: CallableFunction;
    startSnapshot: string;
    endSnapshot: string;
    initialStartTime?: number;
    accidentId?: number;
    onCreateRequest?: CallableFunction;
}

const SelectSegment: React.FC<SelectSegmentProps> = ({
    devices,
    licensePlate,
    tripDetails,
    setRequests,
    selectedRequest,
    setAlert,
    updateMap,
    onBack,
    onClose,
    startSnapshot,
    endSnapshot,
    initialStartTime,
    accidentId,
    onCreateRequest,
}: SelectSegmentProps) => {
    let initialSelectedTime = [roundDownToMinute(tripDetails.start_time), roundDownToMinute(tripDetails.end_time)];
    if (selectedRequest) {
        const requestData = JSON.parse(selectedRequest.data);
        initialSelectedTime = [requestData.start_time, requestData.end_time];
    } else if (initialStartTime) {
        const initialStart = Math.max(
            roundDownToMinute(initialStartTime - 7.5 * 60),
            roundDownToMinute(tripDetails.start_time),
        );
        const initialEnd = Math.min(initialStart + 15 * 60, roundDownToMinute(tripDetails.end_time));
        initialSelectedTime = [initialStart, initialEnd];
    }
    const [selectedTime, setSelectedTime] = useState<number[]>(initialSelectedTime);
    const initialWellnessImages: Record<number, Record<string, string>> = {};
    if (initialSelectedTime.length > 0) {
        initialWellnessImages[initialSelectedTime[0]] = { IN_CABIN: 'loading', FRONT_FACING: 'loading' };
        initialWellnessImages[initialSelectedTime[1]] = { IN_CABIN: 'loading', FRONT_FACING: 'loading' };
    }
    const [wellnessImages, setWellnessImages] = useState<Record<number, Record<string, string>>>(initialWellnessImages);
    const [wellnessImageSizes, setWellnessImageSizes] = useState<
        Record<number, Record<string, { width: number; height: number }>>
    >({});
    const initialSnapshots: Record<number, string> = {};
    if (initialSelectedTime.length > 0) {
        initialSnapshots[initialSelectedTime[0]] = !!initialSelectedTime ? 'loading' : startSnapshot;
        initialSnapshots[initialSelectedTime[1]] = !!initialSelectedTime ? 'loading' : endSnapshot;
    }
    const [snapshots, setSnapshots] = useState<Record<number, string>>(initialSnapshots);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
    const isRTL = useContext(RTLDirectionContext);
    const [invertLabels, setInvertLabels] = useState<[boolean, boolean]>([!isRTL, !isRTL]);
    const { i18n, t } = useTranslation();
    const { api } = useApi();
    const { profile } = useProfile();
    const history = useHistory();
    const query = useQuery();
    const isMobile = useIsMobile();

    const getWellnessImageSize = (timestamp: number, wellnessType: string, image: string) => {
        return new Promise<void>((resolve, reject) => {
            const imageFile = new Image();
            imageFile.src = image;
            imageFile.onload = () => {
                setWellnessImageSizes((prev) => {
                    const updated = { ...prev };
                    updated[timestamp] ??= {};
                    updated[timestamp][wellnessType] = { width: imageFile.width, height: imageFile.height };
                    return updated;
                });
                resolve();
            };
            imageFile.onerror = reject;
        });
    };

    const inCabinWellness = devices.find((d) => d.device.license_plate === licensePlate)?.aggregated_wellness
        ?.in_cabin_wellness;
    const icWellnessKey = inCabinWellness && 'wellness_state' in inCabinWellness ? 'IN_CABIN_V1' : 'IN_CABIN';

    useEffect(() => updateMap(selectedTime[0], selectedTime[1]), [selectedTime]);

    // used to invert the slider labels when they reach the edges of the container
    const updateLabelPositions = () => {
        const containerCard = document.getElementById('select-segment-overlay');
        const thumbs = document.getElementsByClassName('slider-thumb');
        const sliderLabels = document.getElementsByClassName('slider-label');
        if (containerCard && thumbs && thumbs.length === 2 && sliderLabels && sliderLabels.length === 2) {
            const firstThumb = thumbs[isRTL ? 1 : 0];
            const firstThumbRect = firstThumb.getBoundingClientRect();
            const secondThumb = thumbs[isRTL ? 0 : 1];
            const secondThumbRect = secondThumb.getBoundingClientRect();
            const containerCardRect = containerCard.getBoundingClientRect();
            let updatedInversion = [
                firstThumbRect.x - containerCardRect.x < sliderLabels[0].clientWidth,
                containerCardRect.right - secondThumbRect.right < sliderLabels[1].clientWidth,
            ];
            if (isRTL) {
                updatedInversion = updatedInversion.map((value) => !value);
            }
            setInvertLabels(updatedInversion as [boolean, boolean]);
        }
    };

    const isFirstThumb = (index: number) => (isRTL ? index === 1 : index === 0);
    const getLabelPosition = (index: number) => ({
        [isFirstThumb(index) ? (invertLabels[0] ? 'left' : 'right') : invertLabels[1] ? 'right' : 'left']:
            'calc(50% + 1px)',
    });
    const getLabelBorderRadius = (index: number) => {
        if (isFirstThumb(index)) {
            return invertLabels[0] ? '0 15px 15px 15px' : '15px 0 15px 15px';
        }
        return invertLabels[1] ? '15px 0 15px 15px' : '0 15px 15px 15px';
    };

    const StyledValueLabel = styled(SliderValueLabel)(({ theme, index }) => ({
        backgroundColor: theme.palette.primary.main,
        top: '40px',
        borderRadius: getLabelBorderRadius(index),
        // remove thumb anchor
        '&::before': {
            display: 'none',
        },
        ...getLabelPosition(index),
    }));

    const duration = secondsToTime(selectedTime[1] - selectedTime[0]);

    useEffect(() => {
        getSnapshots(selectedTime);
        for (const timestamp of selectedTime) {
            getImage(icWellnessKey, timestamp);
            getImage('FRONT_FACING', timestamp);
        }
    }, []);

    useEffect(updateLabelPositions, [selectedTime]);

    const sendVideoRequest = () => {
        const selectedDevice = devices.filter((d) => d.device.license_plate === licensePlate);
        let requestAccidentId;
        const queryAccidentId = query.get('accident_id');
        if (accidentId) {
            requestAccidentId = accidentId;
        } else if (queryAccidentId) {
            requestAccidentId = parseInt(queryAccidentId);
        }
        if (selectedDevice.length > 0) {
            setIsLoading(true);
            api.apiV0CommandPost({
                command: {
                    device_id: selectedDevice[0].device.device_id,
                    start_time: dayjs.unix(selectedTime[0]).toISOString(),
                    end_time: dayjs.unix(selectedTime[1] + 30).toISOString(),
                    accident_id: requestAccidentId,
                },
            })
                .then((res) => {
                    setAlert({
                        message: `${t('content.find_video.video_request_sent')}`,
                        type: 'success',
                        duration: 6000,
                    });
                    setRequests((prev: CommandData[]) => [...prev, res.data]);
                    if (!!onCreateRequest) {
                        onCreateRequest(res.data);
                    }
                    setIsConfirmOpen(false);
                    history.push(
                        !!accidentId ? `/accidents/${accidentId}/${res.data.id}` : `/find_video/${res.data.id}`,
                    );
                })
                .catch(() => {
                    setAlert({
                        message: t('content.find_video.video_request_failed'),
                        type: 'error',
                        duration: 6000,
                    });
                })
                .finally(() => setIsLoading(false));
        }
    };

    const setWellnessImageNotFound = (timestamp: number, wellnessType: string) => {
        setWellnessImages((prev) => {
            const updated = { ...prev };
            updated[timestamp] ??= {};
            updated[timestamp][wellnessType] = imageNA;
            return updated;
        });
    };

    const getImage = async (wellnessType: string, timestamp: number) => {
        const wellnessKey = wellnessType.includes('IN_CABIN') ? 'IN_CABIN' : 'FRONT_FACING';
        if (
            wellnessImages[timestamp] === undefined ||
            wellnessImages[timestamp][wellnessKey] === undefined ||
            wellnessImages[timestamp][wellnessKey] === 'loading'
        ) {
            setWellnessImages((prev) => {
                const updated = { ...prev };
                updated[timestamp] ??= {};
                updated[timestamp][wellnessKey] = 'loading';
                return updated;
            });
            try {
                const res = await api.apiV2WellnessImageWellnessTypeGet(
                    { wellnessType, timestamp, licensePlate },
                    { responseType: 'blob' },
                );
                const reader = new window.FileReader();
                reader.readAsDataURL(res.data);
                reader.onload = async () => {
                    const image = reader.result;
                    if (typeof image === 'string') {
                        await getWellnessImageSize(timestamp, wellnessKey, image);
                        setWellnessImages((prev) => {
                            const updated = { ...prev };
                            updated[timestamp] ??= {};
                            updated[timestamp][wellnessKey] = image;
                            return updated;
                        });
                    } else {
                        setWellnessImageNotFound(timestamp, wellnessKey);
                    }
                };
                reader.onerror = () => setWellnessImageNotFound(timestamp, wellnessKey);
            } catch (e) {
                setWellnessImageNotFound(timestamp, wellnessKey);
            }
        }
    };

    const getSnapshots = (timestamps: number[]) => {
        const device = devices.find((d) => d.device.license_plate === licensePlate);
        if (device === undefined) {
            return;
        }
        for (const timestamp of timestamps) {
            if (snapshots[timestamp] === undefined || snapshots[timestamp] === 'loading') {
                setSnapshots((prev) => {
                    const updated = { ...prev };
                    updated[timestamp] = 'loading';
                    return updated;
                });
                api.apiV0DeviceDeviceIdSnapshotGet(
                    { deviceId: device.device.device_id, timestamp },
                    { responseType: 'blob' },
                )
                    .then((res) => {
                        const reader = new window.FileReader();
                        reader.readAsDataURL(res.data);
                        reader.onload = () => {
                            const image = reader.result;
                            if (typeof image === 'string') {
                                setSnapshots((prev) => {
                                    const updated = { ...prev };
                                    updated[timestamp] = image;
                                    return updated;
                                });
                            }
                        };
                        reader.onerror = () =>
                            setSnapshots((prev) => {
                                const updated = { ...prev };
                                updated[timestamp] = 'no snapshot';
                                return updated;
                            });
                    })
                    .catch(() => {
                        setSnapshots((prev) => {
                            const updated = { ...prev };
                            updated[timestamp] = 'no snapshot';
                            return updated;
                        });
                    });
            }
        }
    };

    let isExistingRequest = false;
    if (!!selectedRequest && selectedTime.length === 2) {
        const requestData = JSON.parse(selectedRequest.data);
        const requestStartTime = requestData.start_time;
        const requestEndTime = requestData.end_time;
        const requestLicensePlate = selectedRequest.device.license_plate;
        isExistingRequest =
            selectedTime[0] === requestStartTime &&
            selectedTime[1] === requestEndTime - 30 &&
            requestLicensePlate === licensePlate;
    }

    const isInvalidTimespan = duration.hours > 0 || duration.minutes > 15;
    const isTooRecent = dayjs.unix(selectedTime[1]).isAfter(dayjs().subtract(15, 'minutes'));

    const timePickers = (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.languages[0]}>
            <ResponsiveTimePicker
                label={t('content.find_video.from')}
                value={dayjs.unix(selectedTime[0])}
                onChange={(value: Dayjs | null) => {
                    setSelectedTime((prev) => [value?.unix() ?? prev[0], prev[1]]);
                    if (value) {
                        const timestamp = value.unix();
                        getSnapshots([timestamp]);
                        getImage(icWellnessKey, timestamp);
                        getImage('FRONT_FACING', timestamp);
                    }
                }}
                slotProps={{
                    textField: {
                        id: 'from-time-field',
                        size: 'small',
                        fullWidth: isMobile,
                        sx: { direction: 'ltr', width: isMobile ? undefined : '120px', mr: 1 },
                    } as TextFieldProps,
                }}
                minTime={dayjs.unix(tripDetails.start_time).startOf('minute')}
                maxTime={dayjs.unix(tripDetails.end_time + 60).startOf('minute')}
            />
            {isMobile ? <Typography>–</Typography> : null}
            <ResponsiveTimePicker
                label={t('content.find_video.to')}
                value={dayjs.unix(selectedTime[1])}
                onChange={(value: Dayjs | null) => {
                    setSelectedTime((prev) => [prev[0], value?.unix() ?? prev[1]]);
                    if (value) {
                        const timestamp = value.unix();
                        getSnapshots([timestamp]);
                        getImage(icWellnessKey, timestamp);
                        getImage('FRONT_FACING', timestamp);
                    }
                }}
                slotProps={{
                    textField: {
                        id: 'to-time-field',
                        size: 'small',
                        fullWidth: isMobile,
                        sx: { direction: 'ltr', width: isMobile ? undefined : '120px', ml: isMobile ? 1 : 0 },
                    } as TextFieldProps,
                }}
                minTime={dayjs.unix(tripDetails.start_time).startOf('minute')}
                maxTime={dayjs.unix(tripDetails.end_time + 60).startOf('minute')}
            />
        </LocalizationProvider>
    );

    const isValidTimeRange =
        dayjs.unix(selectedTime[0]).isValid() &&
        dayjs.unix(selectedTime[1]).isValid() &&
        selectedTime[0] < selectedTime[1];

    const getVideoButton = (
        <Button
            variant="contained"
            color="secondary"
            id="get-video-btn"
            disabled={
                selectedTime.length === 0 ||
                isExistingRequest ||
                isLoading ||
                isInvalidTimespan ||
                isTooRecent ||
                !isValidTimeRange
            }
            startIcon={<TheatersIcon />}
            onClick={() => {
                if (!!selectedRequest) {
                    setIsConfirmOpen(true);
                } else {
                    sendVideoRequest();
                }
            }}
            sx={{ flexShrink: 0 }}
        >
            {isValidTimeRange
                ? `${t('content.find_video.get_video')} (${formatTimeObject(duration)})`
                : t('content.find_video.get_video')}
        </Button>
    );

    const backButton = (
        <Button
            id="back-btn"
            variant="outlined"
            color="primary"
            onClick={() => {
                onBack();
            }}
            startIcon={<ArrowBackIosNewIcon />}
            sx={{ mr: isMobile ? 'auto' : 1 }}
        >
            {t('content.find_video.back')}
        </Button>
    );

    const errorMessage = isInvalidTimespan ? (
        <Typography variant="body2" color="error">
            {t('content.find_video.duration_limit_error')}
        </Typography>
    ) : isTooRecent ? (
        <Typography variant="body2" color="error">
            {t('content.find_video.too_recent')}
        </Typography>
    ) : null;

    if (isMobile) {
        return (
            <Dialog id="select-segment-dialog" open={true} fullScreen={true} onClose={() => onClose()}>
                <Box
                    id="event-details"
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'secondary.main',
                            top: 0,
                            p: 1,
                            zIndex: 2,
                            minHeight: 0,
                        }}
                    >
                        <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                            <IconButton id="close-btn" size="small" onClick={() => onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>{t('content.find_video.select_segment')}</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            minHeight: 0,
                            height: '100%',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2,
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', mb: 1 }}>{timePickers}</Box>
                        <Typography variant="overline">{t('content.find_video.preview')}</Typography>
                        <SegmentImages
                            snapshotStart={snapshots[selectedTime[0]]}
                            snapshotEnd={snapshots[selectedTime[1]]}
                            wellnessStart={wellnessImages[selectedTime[0]]}
                            wellnessEnd={wellnessImages[selectedTime[1]]}
                            wellnessStartSizes={wellnessImageSizes[selectedTime[0]]}
                            wellnessEndSizes={wellnessImageSizes[selectedTime[1]]}
                        />
                        <Box sx={{ mt: 1 }}>{errorMessage}</Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            {backButton}
                            {getVideoButton}
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        );
    }

    return (
        <>
            <ConfirmDialog
                isDialogOpen={isConfirmOpen}
                isLoading={isLoading}
                headerText={t('content.find_video.confirm_new_header')}
                bodyText={t('content.find_video.confirm_new_body')}
                buttonText={t('content.find_video.confirm')}
                buttonColor="secondary"
                onConfirm={sendVideoRequest}
                onClose={() => setIsConfirmOpen(false)}
            />
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    maxWidth: 'calc(100% - 22px)',
                    zIndex: 1001,
                    bottom: 10,
                    left: 10,
                }}
            >
                <Card
                    id="select-segment-overlay"
                    data-testid="select-segment-overlay"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        overflow: 'visible',
                        width: '100%',
                        maxWidth: '1000px',
                        backgroundColor: 'bgColor.main',
                    }}
                >
                    <Typography sx={{ fontWeight: 'bold', mb: 0.5 }}>
                        {t('content.find_video.select_segment')}
                    </Typography>
                    <SegmentImages
                        snapshotStart={snapshots[selectedTime[0]]}
                        snapshotEnd={snapshots[selectedTime[1]]}
                        wellnessStart={wellnessImages[selectedTime[0]]}
                        wellnessEnd={wellnessImages[selectedTime[1]]}
                        wellnessStartSizes={wellnessImageSizes[selectedTime[0]]}
                        wellnessEndSizes={wellnessImageSizes[selectedTime[1]]}
                    />
                    <Slider
                        slotProps={{
                            input: { id: 'time-range-input' },
                            thumb: { className: 'slider-thumb' },
                            valueLabel: { className: 'slider-label' },
                        }}
                        min={roundDownToMinute(tripDetails.start_time)}
                        max={roundDownToMinute(tripDetails.end_time)}
                        step={60}
                        size="small"
                        value={selectedTime}
                        onChange={(_event, value) => setSelectedTime(value as number[])}
                        onChangeCommitted={(_event: React.SyntheticEvent | Event, value: number | Array<number>) => {
                            getSnapshots(value as number[]);
                            for (const timestamp of value as number[]) {
                                getImage(icWellnessKey, timestamp);
                                getImage('FRONT_FACING', timestamp);
                            }
                        }}
                        slots={{
                            valueLabel: StyledValueLabel,
                        }}
                        valueLabelDisplay="on"
                        valueLabelFormat={(value) => dayjs.unix(value).format(profile.timeFormat)}
                        sx={{ mb: 4 }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexShrink: 0,
                            width: '100%',
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            {backButton}
                            {timePickers}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ mx: 1 }}>{errorMessage}</Box>
                            {getVideoButton}
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    );
};

export default SelectSegment;
