import L, { latLngBounds } from 'leaflet';
import 'leaflet-logo-plugin/leaflet-logo.css';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useMemo, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import { DeviceV2 } from '../backendsdk';
import useProfile from '../hooks/profile';
import circleIcon from '../images/markers/circle.png';
import icon from '../images/markers/icon-blue.png';
import bigGrayIcon from '../images/markers/icon-gray-big.png';
import grayIcon from '../images/markers/icon-gray.png';
import bigIcon from '../images/markers/icon-red-big.png';
import iconShadowBig from '../images/markers/marker-shadow-big.png';
import iconShadow from '../images/markers/marker-shadow.png';
import palette from '../layout/ColorPalette';
import { getICWellnessDetails } from '../layout/content/Overview/utils';
import { isLocationValid, parseLocation } from '../utils/location';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const LeafleatLogo = require('leaflet-logo-plugin');
/*
// Get image size easy
const img = new Image();

img.onload = function () {
    alert(img.width + ', ' + img.height);
};

img.src = icon; // change to other picture
// */

const smallIconSize = [18, 30];
const bigIconSize = [32, 53];

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [smallIconSize[0] / 2, smallIconSize[1]],
});

const BigIcon = L.icon({
    iconUrl: bigIcon,
    shadowUrl: iconShadowBig,
    iconAnchor: [bigIconSize[0] / 2, bigIconSize[1]],
});

const GrayIcon = L.icon({
    iconUrl: grayIcon,
    shadowUrl: iconShadow,
    iconAnchor: [smallIconSize[0] / 2, smallIconSize[1]],
});

const BigGrayIcon = L.icon({
    iconUrl: bigGrayIcon,
    shadowUrl: iconShadowBig,
    iconAnchor: [bigIconSize[0] / 2, bigIconSize[1]],
});

const CircleIcon = L.icon({
    iconUrl: circleIcon,
    iconSize: [40, 20],
});

const getIcon = (isActive: boolean, isCenter: boolean): L.Icon => {
    if (isActive && isCenter) return BigIcon;
    else if (!isActive && isCenter) return BigGrayIcon;
    else if (!isActive && !isCenter) return GrayIcon;
    else return DefaultIcon;
};

interface MapContentProps {
    devices?: Array<DeviceV2>;
    center?: DeviceV2;
    location?: string;
    zoom?: number;
    route?: Array<[string, number | string, CallableFunction]>;
    markers?: React.ReactNode;
    eventLocation?: string;
    full?: boolean;
    height?: string;
    image?: string;
    isCached?: boolean;
    fitBoundsCounter?: number;
    darkMode?: boolean;
}

const MapContent: React.FC<MapContentProps> = (props: MapContentProps) => {
    const { devices, eventLocation, zoom, full } = props;
    const { profile } = useProfile();

    const deviceList: Array<DeviceV2> = devices ? devices : [];
    const centerLocation = useMemo(() => {
        let centerLocation;
        if (props.center == undefined || !isLocationValid(props.center.location)) {
            const validLocations = deviceList
                .filter((device) => isLocationValid(device.location))
                .map((device) => parseLocation(device.location));
            if (validLocations.length > 0) {
                centerLocation = latLngBounds(validLocations).getCenter();
            } else {
                centerLocation = undefined;
            }
        } else {
            centerLocation = parseLocation(props.center.location);
        }

        if (isLocationValid(eventLocation)) {
            centerLocation ??= parseLocation(eventLocation);
        }
        return centerLocation;
    }, [devices, eventLocation]);

    const size = full
        ? { height: props.height ? props.height : '100%', width: '100%' }
        : { height: '35em', width: '100%' };

    return (
        <MapContainer
            id="map-widget"
            style={size}
            center={centerLocation}
            zoom={zoom ?? 8}
            scrollWheelZoom={false}
            preferCanvas={true}
        >
            <CenterComponent {...props} centerLocation={centerLocation} />
            <TileLayer
                className={props.darkMode ? 'leaflet-dark' : 'leaflet-light'}
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {deviceList.length > 0 ? (
                <MarkerClusterGroup removeOutsideVisibleBounds={false}>
                    {deviceList.map((device) => {
                        const icon = getIcon(
                            getICWellnessDetails(device, profile.customer.settings.inactive_device_threshold) !=
                                'inactive', // isActive
                            device.device.device_id == props.center?.device.device_id, // isCenter
                        );
                        const iconAnchor = icon.options.iconAnchor as L.PointTuple;
                        return isLocationValid(device.location) ? (
                            <Marker key={device.device.device_id} position={parseLocation(device.location)} icon={icon}>
                                <Popup offset={[0, -iconAnchor[1]]}>{device.device.license_plate}</Popup>
                            </Marker>
                        ) : null;
                    })}
                </MarkerClusterGroup>
            ) : null}
            {props.markers}
            {props.location && isLocationValid(props.location) ? (
                <Marker position={parseLocation(props.location)} icon={CircleIcon} />
            ) : null}
        </MapContainer>
    );
};

interface CenterComponentProps extends MapContentProps {
    centerLocation?: L.LatLng | [number, number];
}

export const CenterComponent: React.FC<CenterComponentProps> = ({
    center,
    centerLocation,
    route,
    devices,
    isCached,
    image,
    fitBoundsCounter,
}) => {
    const map = useMap();
    const [polyline, setPolyline] = useState<Array<L.Polyline>>([]);
    const [mapZoom, setMapZoom] = useState<number>(map.getZoom());

    useEffect(() => {
        if (!!center) {
            map.closePopup();
            if (isLocationValid(center.location)) {
                map.setView(parseLocation(center.location), 18);
            } else if (!!devices) {
                const bounds = latLngBounds(
                    devices.filter((d) => isLocationValid(d.location)).map((d) => parseLocation(d.location)) || [],
                );
                if (bounds.isValid()) {
                    map.fitBounds(bounds);
                }
            }
        }
    }, [center?.device?.device_id]);

    useEffect(() => {
        if (isCached && !!devices && center === undefined) {
            const bounds = latLngBounds(
                devices?.filter((d) => isLocationValid(d.location)).map((d) => parseLocation(d.location)) || [],
            );
            if (bounds.isValid()) {
                map.fitBounds(bounds);
            }
        }
    }, [map, devices, isCached]);

    useEffect(() => {
        const zoomChangeTrack = () => setMapZoom(map.getZoom());
        map.on('zoom', zoomChangeTrack);

        return () => {
            map.off('zoom', zoomChangeTrack);
        };
    }, [map, mapZoom]);

    useEffect(() => {
        if (image) {
            const overlayImage = LeafleatLogo({
                position: 'bottomright',
                link: '#',
                image: image,
                width: '75px',
                height: '340px',
            });
            overlayImage.addTo(map);

            return () => overlayImage.remove();
        }
    }, [map, image]);

    useEffect(() => {
        if (route !== undefined) {
            const waypoints: Array<[L.LatLngExpression, number | string, CallableFunction]> = route
                .filter((waypoint) => isLocationValid(waypoint[0]))
                .map((waypoint) => {
                    const location = parseLocation(waypoint[0]);
                    return [L.latLng(location[0], location[1]), waypoint[1], waypoint[2]];
                });
            const blackLines: Array<L.Polyline> = [];
            const colorLines: Array<L.Polyline> = [];
            for (let i = 1; i < waypoints.length; i++) {
                blackLines.push(
                    L.polyline([waypoints[i - 1][0], waypoints[i][0]], {
                        weight: 7,
                        color: palette.black,
                    }),
                );
                colorLines.push(
                    L.polyline([waypoints[i - 1][0], waypoints[i][0]], {
                        weight: 5,
                        color: waypoints[i][2](waypoints[i][1]),
                    }),
                );
            }
            setPolyline([...blackLines, ...colorLines]);
        } else if (polyline.length > 0) {
            setPolyline([]);
        }
    }, [map, route]);

    useEffect(() => {
        polyline.map((new_polyline) => new_polyline.addTo(map));
        const bounds = L.latLngBounds(polyline.map((p) => p.getBounds().getCenter()));
        if (bounds.isValid()) {
            map.fitBounds(bounds);
        }

        return () => {
            polyline.map((new_polyline) => new_polyline.removeFrom(map));
        };
    }, [map, polyline, fitBoundsCounter]);

    useEffect(() => {
        map.invalidateSize();
    }, [map, fitBoundsCounter]);

    useEffect(() => {
        if (centerLocation != undefined) {
            map.panTo(centerLocation);
        }
    }, [centerLocation]);

    return null;
};

export default React.memo(MapContent);
