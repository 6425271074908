import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

const barSize = '1em';

const BarContainer = styled.div`
    background-color: #c2cccf;
    border-radius: ${barSize};
    display: flex;
    height: ${barSize};
    overflow: hidden;
    width: 90%;

    & > div:hover {
        filter: brightness(70%);
    }
`;

interface BarItem {
    color: string;
    width: string;
    description: string;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
}

interface BarComponentProps {
    items: Array<BarItem>;
}

export const BarComponent: React.FC<BarComponentProps> = (props: BarComponentProps) => {
    return (
        <BarContainer>
            {props.items.map((item: BarItem, idx: number) => (
                <div
                    key={idx}
                    className="testeli"
                    onMouseEnter={(e) => (item.onMouseEnter ? item.onMouseEnter(e) : undefined)}
                    onMouseLeave={(e) => (item.onMouseLeave ? item.onMouseLeave(e) : undefined)}
                    title={item.description}
                    style={{ backgroundColor: item.color, width: item.width }}
                ></div>
            ))}
        </BarContainer>
    );
};

export default BarComponent;
