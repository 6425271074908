import CloseIcon from '@mui/icons-material/Close';
import { Box, Paper, SxProps, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button, TrackedDialog as Dialog, TrackedIconButton as IconButton } from './TrackedComponents';

export interface ConfirmDialogProps {
    isDialogOpen: boolean;
    isLoading: boolean;
    headerText: string;
    headerIcon?: React.ReactNode;
    bodyText: string | string[];
    buttonText: string;
    buttonColor: 'primary' | 'secondary' | 'redColor';
    onClose: CallableFunction;
    onConfirm: CallableFunction;
    cancelText?: string;
    onCancel?: CallableFunction;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    typographyStyles?: SxProps | SxProps[];
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
    const { t } = useTranslation();
    const headerStyle = !!props.headerIcon ? { mt: 0.5 } : {};
    const bodyText = Array.isArray(props.bodyText) ? props.bodyText : [props.bodyText];

    return (
        <Dialog id="confirm-dialog" open={props.isDialogOpen} maxWidth={props.maxWidth} onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, ...headerStyle }}>
                    <IconButton id="btn-confirm" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {!!props.headerIcon ? props.headerIcon : null}
                    {t(props.headerText)}
                </Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    p: 1,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 1 }}>
                    {bodyText.map((text, idx) => (
                        <Typography
                            key={`confirm-${idx}`}
                            sx={
                                Array.isArray(props.typographyStyles)
                                    ? props.typographyStyles[idx]
                                    : props.typographyStyles
                            }
                        >
                            {t(text)}
                        </Typography>
                    ))}
                </Box>
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    <Button
                        id="cancel-btn"
                        fullWidth
                        data-testid="cancel-btn"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (props.onCancel) {
                                props.onCancel();
                            } else {
                                props.onClose();
                            }
                        }}
                        sx={{ mr: 1 }}
                    >
                        {t(props.cancelText || 'content.coach.details.cancel')}
                    </Button>
                    <Button
                        id="confirm-btn"
                        fullWidth
                        data-testid="confirm-btn"
                        variant="contained"
                        color={props.buttonColor}
                        disabled={props.isLoading}
                        onClick={(e) => props.onConfirm(e)}
                    >
                        {t(props.buttonText)}
                    </Button>
                </Box>
            </Paper>
        </Dialog>
    );
};

export default ConfirmDialog;
