import React, { Dispatch, SetStateAction } from 'react';

import { AccidentTagsV2Details, EventDetails, ExtendedAccident } from '../../../../../backendsdk';
import { isEventVideo } from '../../../../../utils/File';
import TaggingComponent from '../../Tagging';

interface TaggingWrapperTabProps {
    details: ExtendedAccident;
    eventDetails?: EventDetails;
    handleSave: CallableFunction;
    accidentTags: AccidentTagsV2Details;
    setAccidentTags: Dispatch<SetStateAction<AccidentTagsV2Details>>;
    handleChange: CallableFunction;
    onBackTab: CallableFunction;
}

const TaggingWrapperTab: React.FC<TaggingWrapperTabProps> = (props: TaggingWrapperTabProps) => {
    let videoUrl = '';
    if (props.eventDetails) {
        videoUrl = Object.entries(props.eventDetails.artifact_path)
            .map(([fileName, fileUrl]) => (isEventVideo(fileName) ? fileUrl : ''))
            .filter((e) => e != '')[0];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (target: string, value: any) => {
        props.handleChange((oldDetails: ExtendedAccident) => {
            if (oldDetails) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const newDetails: Record<string, any> = { ...oldDetails };
                newDetails[target] = value;
                return newDetails;
            }
        });
    };

    const textOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.name, event.target.value);
    };

    return (
        <TaggingComponent
            accidentTags={props.accidentTags}
            setAccidentTags={props.setAccidentTags}
            accidentId={props.details.accident_id}
            eventId={props.details.event || null}
            eventVideo={videoUrl}
            setEventId={textOnChange}
            onSave={() => props.handleSave()}
            onClose={() => props.onBackTab()}
            eventLevel={props.eventDetails?.level}
        />
    );
};

export default TaggingWrapperTab;
