import { Box, List, Typography, alpha } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent';

import { TrackedListItemButton as ListItemButton } from '../../../../components/TrackedComponents';
import { RTLDirectionContext } from '../../../Layout';
import { CustomTooltip, PIE_COLORS } from './AccidentCausesChart';

interface CustomLegendProps extends LegendProps {
    setHoveredItem: CallableFunction;
    translationPrefix?: string;
}

const CustomLegend: React.FC<CustomLegendProps> = (props: CustomLegendProps) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                height: '100%',
                direction: 'ltr',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                mt: '25px',
                ml: '50px',
            }}
        >
            <List disablePadding sx={{ width: '100%' }}>
                {(props.payload || []).map((p) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const payload = p?.payload as any;
                    const entry = payload?.payload;
                    return (
                        <ListItemButton
                            id={`${payload.name}-legend-item`}
                            data-testid={`${payload.name}-legend-item`}
                            key={`${payload.name}-legend`}
                            disableGutters
                            disableRipple={entry === undefined || !entry.clickable}
                            onMouseEnter={() => props.setHoveredItem(payload.name)}
                            onMouseLeave={() => props.setHoveredItem(undefined)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                py: 0.25,
                                px: 1,
                                cursor: entry?.clickable ? 'pointer' : 'default',
                            }}
                        >
                            <Box sx={{ width: 15, height: 15, mr: 0.5, flexShrink: 0 }}>
                                <svg
                                    viewBox="0 0 15 15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ overflow: 'visible' }}
                                >
                                    <circle cx="7.5" cy="7.5" r="7.5" fill={payload.fill} />
                                </svg>
                            </Box>
                            <Typography fontSize={12}>
                                {props.translationPrefix
                                    ? t(`${props.translationPrefix}.${payload.name}`, payload.name)
                                    : payload.name}
                            </Typography>
                        </ListItemButton>
                    );
                })}
            </List>
        </Box>
    );
};

interface OverviewPieChartProps {
    data: { name: string; value: number }[];
    colors?: string[];
    translationPrefix?: string;
}

const OverviewPieChart: React.FC<OverviewPieChartProps> = (props: OverviewPieChartProps) => {
    const [hoveredItem, setHoveredItem] = React.useState<string>();
    const isRTL = useContext(RTLDirectionContext);
    const margin = isRTL ? { right: 80 } : { left: 80 };

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <PieChart id="pie-chart" width={540} height={350} margin={margin} style={{ direction: 'ltr' }}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={props.data}
                    cx="50%"
                    cy="50%"
                    outerRadius={140}
                    label={(value) => `${(value.percent * 100).toFixed(0)}%`}
                    onMouseEnter={(e) => setHoveredItem(e.name)}
                    onMouseLeave={() => setHoveredItem(undefined)}
                >
                    {props.data.map((entry, index) => {
                        const colors = props.colors || PIE_COLORS;
                        const fill = colors[index % colors.length];
                        return (
                            <Cell
                                fill={!!hoveredItem ? alpha(fill, entry.name === hoveredItem ? 1 : 0.6) : fill}
                                key={`cell-${entry.name}`}
                                style={{
                                    transition: 'fill 0.2s',
                                }}
                            />
                        );
                    })}
                </Pie>
                <Tooltip
                    content={
                        <CustomTooltip
                            translationPrefix={props.translationPrefix}
                            units="content.accident_overview.accidents_label"
                        />
                    }
                />
                <Legend
                    content={
                        <CustomLegend setHoveredItem={setHoveredItem} translationPrefix={props.translationPrefix} />
                    }
                    layout="vertical"
                    align={isRTL ? 'left' : 'right'}
                    verticalAlign="top"
                    width={200}
                />
            </PieChart>
        </Box>
    );
};

export default OverviewPieChart;
