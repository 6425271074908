import { Avatar, ListItem, ListItemAvatar, ListItemText, Menu, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
    TrackedListItemButton as ListItemButton,
    TrackedMenuItem as MenuItem,
} from '../../components/TrackedComponents';
import useProfile from '../../hooks/profile';
import { stringAvatar } from '../../utils/Avatar';
import { userSettingsPageDefs } from '../../utils/Pages';
import palette from '../ColorPalette';
import { RTL_LANGUAGES } from './Navigator';

interface AvatarMenuProps {
    withMenu: boolean;
    onLanguageChange?: CallableFunction;
    onLogout?: CallableFunction;
    onClick?: CallableFunction;
}

const AvatarMenu: React.FC<AvatarMenuProps> = (props: AvatarMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { profile } = useProfile();
    const history = useHistory();
    const { i18n, t } = useTranslation();

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menu = (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    backgroundColor: 'primary.light',
                    color: palette.white,
                    mb: 1.5,
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        bottom: -10,
                        left: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'primary.light',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 1,
                    },
                },
            }}
            transformOrigin={{
                horizontal: RTL_LANGUAGES.includes(i18n.languages[0]) ? 'right' : 'left',
                vertical: 'bottom',
            }}
            anchorOrigin={{ horizontal: RTL_LANGUAGES.includes(i18n.languages[0]) ? 'right' : 'left', vertical: 'top' }}
        >
            <MenuItem
                id="navbar-user-settings"
                onClick={() => {
                    history.push(userSettingsPageDefs.path[0]);
                }}
            >
                {t('navigator.settings')}
            </MenuItem>
            <MenuItem
                id="navbar-logout"
                onClick={() => {
                    handleMenuClose();
                    if (props.onLogout) {
                        props.onLogout();
                    }
                }}
            >
                {t('navigator.sign_out')}
            </MenuItem>
        </Menu>
    );

    const avatarProps = profile.image_url ? { src: profile.image_url } : { ...stringAvatar(profile.name) };

    return (
        <ListItem alignItems="center" disablePadding>
            <ListItemButton id="navbar-avatar-menu" onClick={props.withMenu ? handleMenuOpen : () => props.onClick?.()}>
                <ListItemAvatar>
                    <Avatar {...avatarProps} />
                </ListItemAvatar>
                <ListItemText
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                    primary={<Typography>{profile.name}</Typography>}
                    secondary={
                        <Typography variant="caption" sx={{ color: palette.white }}>
                            {profile.customer.name}
                        </Typography>
                    }
                />
            </ListItemButton>
            {props.withMenu ? menu : null}
        </ListItem>
    );
};

export default AvatarMenu;
