import {
    Autocomplete,
    Box,
    Grid,
    TextField,
    TextFieldProps,
    ThemeProvider,
    Typography,
    createTheme,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { Driver } from '../../../backendsdk';
import { ResponsiveDatePicker } from '../../../components/MuiDatePicker';
import useApi from '../../../hooks/api';
import useDrivers from '../../../hooks/drivers';
import useProfile from '../../../hooks/profile';
import { formatDayjs } from '../../../utils/TimeFormatter';
import { STATES } from '../Compliance/Defs';
import AccidentDriverSignature from './AccidentDriverSignature';
import { MuiTextField } from './MuiFormControls';

interface AccidentDriverDetailsProps {
    accident_id: number;
    details: Driver;
    setUpdateCallbacks: CallableFunction;
    setValidateCallbacks: CallableFunction;
    allowUpdate: boolean;
    sending?: boolean;
}

export const EMPTY_DRIVER_DETAILS: Driver = {
    id_number: '',
    driver_name: '',
    date_of_birth: '',
    phone_number: '',
    address: '',
    license_type: '',
    license_number: '',
    license_issuing_state: '',
    license_issue_date: '',
    license_expiration: '',
    vehicle_owner: '',
    signature: '',
};
const DATE_FIELDS = ['date_of_birth', 'license_issue_date', 'license_expiration'];
const DRIVER_DETAILS_KEYS = Object.keys(EMPTY_DRIVER_DETAILS);
const NON_US_ONLY_FIELDS = ['id_number', 'license_type'];
const US_ONLY_FIELDS = ['license_issuing_state', 'vehicle_owner'];
const EXCLUDED_FIELDS = ['license_type', 'license_number', 'license_expiration', 'license_issue_date'];

const AccidentDriverDetails: React.FC<AccidentDriverDetailsProps> = (props: AccidentDriverDetailsProps) => {
    const { i18n, t } = useTranslation();
    const [details, setDetails] = useState<Driver>(props.details);
    const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
    const [dateFieldErrors, setDateFieldErrors] = useState<Record<string, boolean>>({
        date_of_birth: false,
        license_issue_date: false,
    });
    const { agencyApi } = useApi();
    const { profile } = useProfile();
    const { drivers } = useDrivers();
    const isUSCustomer = profile.customer.settings.country === 'us';
    let fields = DRIVER_DETAILS_KEYS.filter((key) => !EXCLUDED_FIELDS.includes(key));
    if (isUSCustomer) {
        fields = fields.filter((key) => !NON_US_ONLY_FIELDS.includes(key));
    } else {
        fields = fields.filter((key) => !US_ONLY_FIELDS.includes(key));
    }
    const disabled = props.accident_id === 0 || !props.allowUpdate || props.sending;
    const filteredDrivers = drivers.filter((driver) => !driver.is_auto_generated);

    useEffect(() => {
        setDetails(props.details);
        setDetailsChanged(false);
    }, [props.details]);

    useEffect(() => {
        if (detailsChanged) {
            props.setUpdateCallbacks((prev: Record<string, CallableFunction>) => {
                return { ...prev, driver: updateDriverDetails };
            });
        }
    }, [details]);

    useEffect(() => {
        props.setValidateCallbacks((prev: Record<string, CallableFunction>) => {
            return { ...prev, [`driver`]: validateDriverDetails };
        });
    }, [details]);

    const validateDriverDetails = () => {
        const errors = {
            date_of_birth: false,
            license_issue_date: false,
        };
        if (details.date_of_birth) {
            if (!dayjs(details.date_of_birth).isValid() || dayjs(details.date_of_birth).isAfter(dayjs())) {
                errors.date_of_birth = true;
            }
        }
        if (details.license_issue_date) {
            if (!dayjs(details.license_issue_date).isValid() || dayjs(details.license_issue_date).isAfter(dayjs())) {
                errors.license_issue_date = true;
            }
        }
        if (Object.values(errors).some(Boolean)) {
            setDateFieldErrors(errors);
            return false;
        }
        return true;
    };

    const recurrsiveUpdateDetails = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        recDetails: Record<string, any>, // recDetails is object by ref
        key: string,
        value: string | number | boolean,
    ) => {
        const SEP = '__';
        if (key in recDetails) {
            recDetails[key] = value;
        } else if (key.includes(SEP)) {
            const firstKey = key.split(SEP, 1)[0];
            const newKey = key.substr(firstKey.length + SEP.length, key.length);
            const newRecDetails = firstKey in recDetails ? recDetails[firstKey] : { [newKey.split(SEP, 1)[0]]: {} };
            recDetails[firstKey] = recurrsiveUpdateDetails(newRecDetails, newKey, value);
        } else {
            recDetails[key] = value;
        }
        return recDetails;
    };

    const updateDriverDetails = () => {
        return agencyApi.agencyV1AccidentAccidentIdDriverPost({
            accidentId: props.accident_id,
            driver: details,
        });
    };

    const handleChange = (target: string, value: string | boolean | number) => {
        setDetailsChanged(true);
        (setDetails as CallableFunction)((oldDetails: Driver) => {
            const newDetails = Object.fromEntries(
                Object.entries(oldDetails).map(([key, origin_value]) => [key, key === target ? value : origin_value]),
            );
            return recurrsiveUpdateDetails(newDetails, target, value);
        });
    };

    const textOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.name, event.target.value);
    };

    const theme = createTheme({
        typography: {
            fontSize: 12,
        },
    });

    return (
        <Box sx={{ position: 'relative' }}>
            <Prompt when={detailsChanged} message={t('content.accidents.prompt_msg')} />
            <Box sx={{ mb: 1 }}>
                <Typography variant="overline">{t('content.accidents.driver.header')}</Typography>
            </Box>
            <Grid container spacing={1.5}>
                <Grid item container xs={9} rowSpacing={2} columnSpacing={1.5}>
                    {fields
                        .filter((key) => key != 'signature')
                        .map((key) => {
                            const value = details[key as keyof Driver];
                            return (
                                <Grid key={`${key}-cell`} item xs={4}>
                                    {key === 'license_issuing_state' ? (
                                        <ThemeProvider theme={theme}>
                                            <Autocomplete
                                                id={`${key}-field`}
                                                key={key}
                                                disabled={disabled}
                                                value={value as string}
                                                options={['', ...STATES]}
                                                onInputChange={(
                                                    _event: React.SyntheticEvent,
                                                    newValue: string | null,
                                                ) => {
                                                    handleChange(key, newValue || '');
                                                }}
                                                size="small"
                                                autoHighlight
                                                freeSolo
                                                renderInput={(params) => (
                                                    <ThemeProvider theme={theme}>
                                                        <TextField
                                                            {...params}
                                                            label={t(`content.accidents.driver.${key}`)}
                                                            variant="outlined"
                                                            sx={{ direction: 'ltr' }}
                                                        />
                                                    </ThemeProvider>
                                                )}
                                            />
                                        </ThemeProvider>
                                    ) : key === 'driver_name' ? (
                                        <ThemeProvider theme={theme}>
                                            <Autocomplete
                                                id={`${key}-field`}
                                                key={key}
                                                disabled={disabled}
                                                value={value as string}
                                                options={filteredDrivers.map((driver) => driver.fullName)}
                                                onInputChange={(_event: React.SyntheticEvent, newValue: string) => {
                                                    handleChange(key, newValue || '');
                                                    handleChange(
                                                        'driver_token',
                                                        filteredDrivers.find((driver) => driver.fullName === newValue)
                                                            ?.token || '',
                                                    );
                                                }}
                                                size="small"
                                                autoHighlight
                                                freeSolo
                                                renderInput={(params) => (
                                                    <ThemeProvider theme={theme}>
                                                        <TextField
                                                            {...params}
                                                            label={t(`content.accidents.driver.${key}`)}
                                                            variant="outlined"
                                                            sx={{ direction: 'ltr' }}
                                                        />
                                                    </ThemeProvider>
                                                )}
                                            />
                                        </ThemeProvider>
                                    ) : DATE_FIELDS.includes(key) ? (
                                        <ThemeProvider theme={theme}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                                adapterLocale={i18n.languages[0]}
                                            >
                                                <ResponsiveDatePicker
                                                    id={`${key}-field`}
                                                    key={key}
                                                    disabled={disabled}
                                                    label={t(`content.accidents.driver.${key}`)}
                                                    value={!!value ? dayjs(value) : undefined}
                                                    onChange={(value: Dayjs | null) => {
                                                        if (value !== null) {
                                                            handleChange(key, formatDayjs(value));
                                                        }
                                                    }}
                                                    maxDate={dayjs()}
                                                    slotProps={{
                                                        textField: {
                                                            id: `${key}-field`,
                                                            size: 'small',
                                                            error: dateFieldErrors[key],
                                                            sx: { direction: 'ltr' },
                                                        } as TextFieldProps,
                                                    }}
                                                    format={profile.dateFormat}
                                                />
                                            </LocalizationProvider>
                                        </ThemeProvider>
                                    ) : (
                                        <MuiTextField
                                            id={`${key}-field`}
                                            key={key}
                                            error={dateFieldErrors[key]}
                                            label={t(`content.accidents.driver.${key}`)}
                                            name={key}
                                            disabled={disabled}
                                            value={value as string}
                                            onChange={textOnChange}
                                            type={DATE_FIELDS.includes(key) ? 'date' : 'text'}
                                        />
                                    )}
                                </Grid>
                            );
                        })}
                </Grid>
                <Grid item xs={3}>
                    <AccidentDriverSignature
                        signatureUrl={details.signature}
                        setSignatureUrl={(sig: string) => handleChange('signature', sig)}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AccidentDriverDetails;
