import dayjs from 'dayjs';

import { DeviceV3 } from '../../../backendsdk';
import { DeviceV2 } from '../../../backendsdk';
import { normalizeLicensePlate, normalizeName } from '../../../utils/Str';
import { SECONDS_IN_DAY } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { wellnessColorsByState, wellnessColorsMap } from '../StyledElements';

type WellnessStatus =
    | 'inactive'
    | 'blocked'
    | 'no face'
    | 'no_face'
    | 'faulty'
    | 'well'
    | 'centered_face'
    | 'peripheral_face';

export const getWellnessDetailsByState = (
    blocked: string,
    no_face: string,
    peripheral_face: string,
): WellnessStatus => {
    return blocked.toLocaleLowerCase() == 'true'
        ? 'blocked'
        : no_face.toLocaleLowerCase() == 'true'
        ? 'no face'
        : peripheral_face.toLocaleLowerCase() == 'true'
        ? 'faulty'
        : 'well';
};

export const getICWellnessDetails = (device: DeviceV2, inactive_threshold: number): WellnessStatus => {
    if (
        !device.last_seen ||
        dayjs.utc().unix() - device.last_seen > inactive_threshold * SECONDS_IN_DAY ||
        !device.aggregated_wellness ||
        !device.aggregated_wellness.in_cabin_wellness
    ) {
        return 'inactive';
    }

    if ('wellness_state' in device.aggregated_wellness.in_cabin_wellness) {
        return device.aggregated_wellness.in_cabin_wellness.wellness_state as WellnessStatus;
    }

    return getWellnessDetailsByState(
        device.aggregated_wellness.in_cabin_wellness.blocked,
        device.aggregated_wellness.in_cabin_wellness.no_face,
        device.aggregated_wellness.in_cabin_wellness.peripheral_face,
    );
};

export const getFFWellnessDetails = (device: DeviceV2, inactive_threshold: number): string => {
    if (
        !device.last_seen ||
        dayjs.utc().unix() - device.last_seen > inactive_threshold * SECONDS_IN_DAY ||
        !device.aggregated_wellness ||
        !device.aggregated_wellness.front_facing_wellness
    ) {
        return 'inactive';
    }

    return device.aggregated_wellness?.front_facing_wellness?.wellness_state ?? 'inactive';
};

export const RELEVANT_EVENT_TYPES = [
    'MsgEyesOffRoad',
    'MsgTailgatingDetection',
    'MsgRecurringTG',
    'MsgHardbrake',
    'MsgImpact',
    'MsgCollisionTrackDetection',
    'MsgSpeedingDetection',
    'MsgDriverButtonPressed',
];

export const sortingOptions = [
    'license_plate',
    'driver_name',
    'sub_fleet',
    'ic_status',
    'ff_status',
    'events',
    'trip_duration',
    'last_seen',
];

export const sortingFunctions: (inactive_threshold: number) => Record<string, CallableFunction> = (
    inactive_threshold: number,
) => ({
    license_plate: (devices: DeviceV3[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...devices].sort((a, b) => {
            if (normalizeLicensePlate(a.device.license_plate) < normalizeLicensePlate(b.device.license_plate)) {
                return -1 * factor;
            }
            if (normalizeLicensePlate(a.device.license_plate) > normalizeLicensePlate(b.device.license_plate)) {
                return 1 * factor;
            }
            return 0;
        });
    },
    driver_name: (devices: DeviceV3[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...devices].sort((a, b) => {
            if (normalizeName(a.device.driver_name) < normalizeName(b.device.driver_name)) {
                return -1 * factor;
            }
            if (normalizeName(a.device.driver_name) > normalizeName(b.device.driver_name)) {
                return 1 * factor;
            }
            return 0;
        });
    },
    sub_fleet: (devices: DeviceV3[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        return [...devices].sort((a, b) => {
            if (normalizeName(a.device.customer_sub_fleet) < normalizeName(b.device.customer_sub_fleet)) {
                return -1 * factor;
            }
            if (normalizeName(a.device.customer_sub_fleet) > normalizeName(b.device.customer_sub_fleet)) {
                return 1 * factor;
            }
            return 0;
        });
    },
    ic_status: (devices: DeviceV3[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        const colorToOrderMap = {
            [wellnessColorsMap.unknown]: 1,
            [wellnessColorsMap.unwell]: 2,
            [wellnessColorsMap.well]: 3,
        };

        return [...devices].sort((a, b) => {
            const aColor = wellnessColorsByState[getICWellnessDetails(a, inactive_threshold)];
            const bColor = wellnessColorsByState[getICWellnessDetails(b, inactive_threshold)];
            if (colorToOrderMap[aColor] < colorToOrderMap[bColor]) {
                return -1 * factor;
            }
            if (colorToOrderMap[aColor] > colorToOrderMap[bColor]) {
                return 1 * factor;
            }
            return 0;
        });
    },
    ff_status: (devices: DeviceV3[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        const colorToOrderMap = {
            [wellnessColorsMap.unknown]: 1,
            [wellnessColorsMap.unwell]: 2,
            [wellnessColorsMap.well]: 3,
        };

        return [...devices].sort((a, b) => {
            const aColor =
                wellnessColorsByState[a.aggregated_wellness?.front_facing_wellness?.wellness_state ?? 'inactive'];
            const bColor =
                wellnessColorsByState[b.aggregated_wellness?.front_facing_wellness?.wellness_state ?? 'inactive'];
            if (colorToOrderMap[aColor] < colorToOrderMap[bColor]) {
                return -1 * factor;
            }
            if (colorToOrderMap[aColor] > colorToOrderMap[bColor]) {
                return 1 * factor;
            }
            return 0;
        });
    },
    events: (devices: DeviceV3[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        const activeDevices = devices.filter((device) => !!device.current_trip);
        activeDevices.sort((a, b) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const aRelevantEvents = a.current_trip!.number_of_events_per_type!.filter((event) =>
                RELEVANT_EVENT_TYPES.includes(event.event_type),
            );
            const aEventCount = aRelevantEvents.reduce((acc, event) => acc + event.count, 0);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const bRelevantEvents = b.current_trip!.number_of_events_per_type!.filter((event) =>
                RELEVANT_EVENT_TYPES.includes(event.event_type),
            );
            const bEventCount = bRelevantEvents.reduce((acc, event) => acc + event.count, 0);
            if (aEventCount < bEventCount) {
                return -1 * factor;
            }
            if (aEventCount > bEventCount) {
                return 1 * factor;
            }
            return 0;
        });
        const inactiveDevices = sortingFunctions(inactive_threshold)['license_plate'](
            devices.filter((device) => !device.current_trip),
            'asc',
        );
        return [...activeDevices, ...inactiveDevices];
    },
    trip_duration: (devices: DeviceV3[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        const activeDevices = devices.filter((device) => !!device.current_trip);
        activeDevices.sort((a, b) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const aTripDuration = new Date().getTime() / 1000 - a.current_trip!.start_time;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const bTripDuration = new Date().getTime() / 1000 - b.current_trip!.start_time;
            if (aTripDuration < bTripDuration) {
                return -1 * factor;
            }
            if (aTripDuration > bTripDuration) {
                return 1 * factor;
            }
            return 0;
        });
        const inactiveDevices = sortingFunctions(inactive_threshold)['license_plate'](
            devices.filter((device) => !device.current_trip),
            'asc',
        );
        return [...activeDevices, ...inactiveDevices];
    },
    last_seen: (devices: DeviceV3[], order: 'asc' | 'desc') => {
        const factor = order === 'asc' ? 1 : -1;
        const inactiveDevices = devices.filter((device) => !device.current_trip);
        inactiveDevices.sort((a, b) => {
            if (a.last_seen === undefined) {
                return -1 * factor;
            }
            if (b.last_seen === undefined) {
                return 1 * factor;
            }
            if (a.last_seen < b.last_seen) {
                return -1 * factor;
            }
            if (a.last_seen > b.last_seen) {
                return 1 * factor;
            }
            return 0;
        });
        const activeDevices = sortingFunctions(inactive_threshold)['license_plate'](
            devices.filter((device) => !!device.current_trip),
            'asc',
        );
        return [...inactiveDevices, ...activeDevices];
    },
});

export const getWellnessIconColor = (icWellness: string, ffWellness: string): string => {
    let wellnessColor = palette.wellness.green;
    if (
        wellnessColorsByState[icWellness] === palette.wellness.red ||
        wellnessColorsByState[ffWellness] === palette.wellness.red
    ) {
        wellnessColor = palette.wellness.red;
    } else if (
        wellnessColorsByState[icWellness] === palette.wellness.gray ||
        wellnessColorsByState[ffWellness] === palette.wellness.gray
    ) {
        wellnessColor = palette.wellness.gray;
    }
    return wellnessColor;
};
