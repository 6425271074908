import BadgeIcon from '@mui/icons-material/Badge';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import { Box, Card, Collapse, Typography } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import React from 'react';

import CircularProgressComponent from './CircularProgress';
import { DataItem, ItemType, PROHUB_MIXPANEL_SESSION } from './Defs';
import InsightItemCard from './InsightItemCard';

interface InsightHeaderCardProps {
    type: string;
    headers: [string, string[]];
    items: DataItem[];
    cardId: number;
    openedCard: number;
    setOpenedCard: CallableFunction;
    changeDismissed: CallableFunction;
}

const ICONS: Record<ItemType, React.ReactNode> = {
    INSURANCE_INSIGHTS: <RequestPageIcon fontSize="large" />,
    DRIVER_LICENSE_EXPIRED: <BadgeIcon fontSize="large" />,
    DRIVER_LICENSE_EXPIRING: <BadgeIcon fontSize="large" />,
    DRIVER_ACCIDENTS_CURRENT_YEAR: <MinorCrashIcon fontSize="large" />,
    DRIVER_ACCIDENTS_LONG: <MinorCrashIcon fontSize="large" />,
    SAFETY_EVENTS_CLUSTERS: <CarCrashIcon fontSize="large" />,
    SAFETY_EVENTS_CLUSTERS_BUS: <BusAlertIcon fontSize="large" />,
    SAFETY_CELLPHONE_USAGE: <CarCrashIcon fontSize="large" />,
    SAFETY_CELLPHONE_USAGE_BUS: <BusAlertIcon fontSize="large" />,
    SAFETY_AGGRESSIVE_DRIVING: <CarCrashIcon fontSize="large" />,
    SAFETY_AGGRESSIVE_DRIVING_BUS: <BusAlertIcon fontSize="large" />,
    WELLNESS_INCABIN_COVER: <HeartBrokenIcon fontSize="large" />,
};

const InsightHeaderCard: React.FC<InsightHeaderCardProps> = (props) => {
    const [title, description] = props.headers;
    const dismissedItems = props.items.filter((item) => item.dismissed).length;
    const isOpened = props.openedCard == props.cardId;
    const isOdd = props.cardId % 2 == 1;

    const handleDismissEvent = (id: number, dismissed: boolean) => {
        props.changeDismissed(id, dismissed);
        if (dismissed && props.items.filter((item) => !item.dismissed && item.id != id).length == 0) {
            props.setOpenedCard(-1);
        }
    };

    const handleClick = () => {
        mixpanel.track('prohub', {
            name: `${props.type} card ${isOpened ? 'closed' : 'opened'}`,
            session_id: PROHUB_MIXPANEL_SESSION,
        });
        props.setOpenedCard((prev: number) => (prev === props.cardId ? -1 : props.cardId));
    };

    return (
        <>
            <Card
                sx={{
                    mt: '30px',
                    p: 2,
                    width: 'clamp(40%, 700px, 80%)',
                    backgroundColor: isOdd ? '#e0e0e0' : '#cdcdcd',
                    borderRadius: '15px',
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ mr: 2, flexGrow: 0 }}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            {ICONS[props.type]}
                            {isOpened ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
                        </Box>
                    </Box>
                    <Box>
                        <Typography sx={{ color: '#606060', fontSize: '12' }}>Insight #{props.cardId}</Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '18', my: 1 }}>{title}</Typography>
                        {description.map((desc, idx) => (
                            <Typography key={idx} sx={{ fontSize: '18', mb: 1 }}>
                                {desc}
                            </Typography>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                        <CircularProgressComponent
                            dismissed={dismissedItems}
                            total={props.items.length}
                            isOdd={isOdd}
                        />
                    </Box>
                </Box>
            </Card>
            <Collapse in={isOpened} sx={{ width: '100%' }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {props.items.map((item) => (
                        <InsightItemCard key={item.id} details={item} changeDismissed={handleDismissEvent} />
                    ))}
                </Box>
            </Collapse>
        </>
    );
};

export default InsightHeaderCard;
