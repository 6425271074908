import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/Delete';
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SendIcon from '@mui/icons-material/Send';
import {
    Badge,
    Box,
    Card,
    CircularProgress,
    Fade,
    LinearProgress,
    Snackbar,
    SxProps,
    Typography,
    lighten,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import React, { Ref, forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EventTagActionEnum, ExtendedFeedEvent, FeedEvent, RichText, RichTextElement } from '../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useCallbackRef from '../../../hooks/callbackRef';
import useDrivers from '../../../hooks/drivers';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import EmptyState from '../../../images/empty-state.svg';
import { eventFeedPageDefs } from '../../../utils/Pages';
import { getStorageItem, setStorageItem } from '../../../utils/Storage';
import { getCustomer } from '../../../utils/customer';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE, RTLDirectionContext } from '../../Layout';
import { EVENT_TYPE_MAP } from '../Event/Defs';
import EventVideoComponent from '../Event/EventVideo';
import { OE_ROLES } from '../Users/UsersComponent';
import { FeedComments } from './FeedComments';
import FeedEventDetailsComponent from './FeedEventDetails';
import FeedShareEventComponent from './FeedShareEventComponent';

const KEY_NAME = 'OE-feed';

export const EMPTY_FEED_EVENT: FeedEvent = {
    event_id: 0,
    likes: [],
    dislikes: [],
    comments: [],
    coaching_session_id: null,
};

const getUserIdsFromCommentText = (commentText: Array<RichText | RichTextElement>): string[] => {
    const res: string[] = [];

    commentText.map((element) => {
        if ('type' in element) {
            if (element.type === 'mention' && element.user && element.user.id) {
                res.push(element.user.id.toString());
            } else if (element.type === 'paragraph' && element.children) {
                res.push(...getUserIdsFromCommentText(element.children));
            }
        }
        // Skip if element has "text" key (no action needed here)
    });

    return res;
};

interface FeedItemProps {
    containerHeight: number;
    eventId: number;
    eventType: string;
    videoSrc: string;
    itemIndex: number;
    currentIndex: number;
    setCurrentIndex: CallableFunction;
}

const FeedItem = forwardRef<HTMLDivElement, FeedItemProps>((props, ref: Ref<HTMLDivElement>) => {
    const isRendered = Math.abs(props.currentIndex - props.itemIndex) <= 1;
    const [percentVideoPlayed, setPercentVideoPlayed] = useState<number>(0);
    const { api } = useApi();
    const { profile } = useProfile();
    const fractionalPart = percentVideoPlayed % 1;
    return (
        <Box
            ref={ref}
            sx={{
                width: '95%',
                height: props.containerHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                scrollSnapStop: 'always',
                scrollSnapAlign: 'center',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress sx={{ color: palette.white }} />
            </Box>
            {isRendered ? (
                <EventVideoComponent
                    key={props.videoSrc}
                    eventId={props.eventId}
                    eventType={props.eventType}
                    artifact={props.videoSrc}
                    viewMode={'vertical'}
                    onPlay={() => props.setCurrentIndex(props.itemIndex)}
                    containerStyle={{ backgroundColor: palette.primary }}
                    onTimeUpdate={(event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
                        const { currentTime, duration } = event.currentTarget;
                        setPercentVideoPlayed((prev) => {
                            const current = currentTime / duration;
                            const prevIntPart = Math.floor(prev);
                            const prevFracPart = prev % 1;
                            if (prevFracPart >= 0.9 && current <= 0.1) {
                                return prevIntPart + current + 1;
                            }
                            return prevIntPart + current;
                        });
                    }}
                    onPause={() => {
                        if (!OE_ROLES.includes(profile.role)) {
                            api.apiV0FeedEventIdWatchedPost({
                                eventId: props.eventId,
                                eventWatchedRequest: { percent_watched: percentVideoPlayed },
                            });
                        }
                        mixpanel.track('scroll', {
                            type: 'feed-event',
                            played: percentVideoPlayed,
                        });
                    }}
                >
                    <LinearProgress
                        key={`${props.eventId}-${Math.floor(percentVideoPlayed)}`}
                        variant="determinate"
                        value={
                            (percentVideoPlayed > 0 && fractionalPart == 0 ? 1 : fractionalPart) * 100 +
                            fractionalPart * 4
                        }
                        sx={{
                            width: '100%',
                            zIndex: 1,
                            backgroundColor: palette.primary,
                            '.MuiLinearProgress-bar': {
                                backgroundColor: palette.white,
                            },
                        }}
                    />
                </EventVideoComponent>
            ) : null}
        </Box>
    );
});

FeedItem.displayName = 'FeedItem';

const FeedComponent: React.FC = () => {
    const [containerRef, node] = useCallbackRef<HTMLDivElement>();
    const [containerHeight, setContainerHeight] = useState<number>(0);
    const isMobile = useIsMobile();
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [events, setEvents] = useState<ExtendedFeedEvent[]>([]);
    const [feedGeneratedAt, setFeedGeneratedAt] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const feedItemRefs = useRef<Array<HTMLDivElement | null>>([]);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { api } = useApi();
    const isRTL = useContext(RTLDirectionContext);
    const { getDriver } = useDrivers();
    const [snackbarMessage, setSnackbarMessage] = useState<string>();
    const [showComments, setShowComments] = useState<boolean>(false);
    const [showQuickCoaching, setShowQuickCoaching] = useState<boolean>(false);
    const [showEventDetails, setShowEventDetails] = useState<boolean>(false);
    const [isEndOfFeed, setIsEndOfFeed] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const endOfFeedRef = useRef<HTMLDivElement>(null);
    const { eventId } = useParams<{ eventId: string }>();

    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    const { data: customer } = useQuery({ queryKey: ['customer'], queryFn: () => getCustomer(api) });
    const customerUsers = customer?.users || [];
    const getEvents = () => {
        let fromIndex: number | undefined;
        api.apiV0FeedGet()
            .then((res) => {
                const events = res.data.events;
                let promotedEvent;
                if (!!eventId && !isNaN(parseInt(eventId))) {
                    promotedEvent = res.data.events.find((e) => e.event_id === parseInt(eventId));
                    if (!promotedEvent) {
                        api.apiV0FeedEventIdGet({ eventId: parseInt(eventId) }).then((eventRes) => {
                            promotedEvent = eventRes.data;
                        });
                    }
                    mixpanel.track('navigate', {
                        type: 'feed-event',
                        event_id: eventId,
                    });
                }
                const generatedAt = res.data.generated_at;
                const storageKey = `${KEY_NAME}-${profile.customer.name.toLocaleLowerCase()}-${generatedAt}`;
                setFeedGeneratedAt(generatedAt);
                const loadFromEvent = getStorageItem(storageKey);
                if (loadFromEvent) {
                    fromIndex = events.findIndex((e) => e.event_id === parseInt(loadFromEvent));
                }
                const filteredEvents = fromIndex !== undefined ? events.slice(fromIndex) : events;
                setEvents(!!promotedEvent ? [promotedEvent, ...filteredEvents] : filteredEvents);
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${eventFeedPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        const currentEvent = events[currentIndex];
        if (currentEvent) {
            setStorageItem(
                `${KEY_NAME}-${profile.customer.name.toLocaleLowerCase()}-${feedGeneratedAt || ''}`,
                currentEvent.event_id.toString(),
                1,
            );
        }
    }, [currentIndex]);

    useEffect(() => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            if (node.offsetHeight) {
                setContainerHeight(node.offsetHeight);
            }
        });
        resizeObserver.observe(node);
        return () => resizeObserver.disconnect();
    }, [node]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    mixpanel.track('end-of-feed', {
                        type: 'feed',
                        number_of_events: events.length,
                    });
                    setIsEndOfFeed(true);
                } else {
                    setIsEndOfFeed(false);
                }
            },
            {
                root: null,
                threshold: 0.5,
            },
        );

        setTimeout(() => {
            if (endOfFeedRef.current) {
                observer.observe(endOfFeedRef.current);
            }

            return () => {
                if (endOfFeedRef.current) {
                    observer.unobserve(endOfFeedRef.current);
                }
            };
        }, 1000);
    }, [events]);

    const scrollToItem = (index: number) => {
        if (feedItemRefs.current[index]) {
            feedItemRefs.current[index]?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
        setCurrentIndex(index);
    };

    const handleScrollUp = () => {
        if (currentIndex > 0) {
            scrollToItem(currentIndex - 1);
        }
    };

    const handleScrollDown = () => {
        if (currentIndex < events.length) {
            scrollToItem(currentIndex + 1);
        }
    };

    const currentEvent: ExtendedFeedEvent | undefined = events[currentIndex];

    const endOfFeed = (
        <Box
            ref={endOfFeedRef}
            sx={{
                width: '95%',
                height: containerHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                scrollSnapStop: 'always',
                scrollSnapAlign: 'center',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Typography variant="h6" sx={{ color: palette.white, mb: 0.5 }}>
                {t('content.feed.no_more')}
            </Typography>
            <img alt="No events" src={EmptyState} width="50%" />
            <Typography variant="overline" sx={{ color: palette.white, mt: 0.5 }}>
                {t('content.feed.more_later')}
            </Typography>
        </Box>
    );

    const content = (
        <>
            {events.map((event, index) => (
                <FeedItem
                    key={event.event_id}
                    containerHeight={containerHeight}
                    eventId={event.event_id}
                    eventType={event.type}
                    videoSrc={event.video_url}
                    ref={(el) => (feedItemRefs.current[index] = el)}
                    itemIndex={index}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
            ))}
            {endOfFeed}
        </>
    );

    const changeTag = async (tagName: string, addTag: boolean) => {
        if (currentEvent) {
            setEvents((prev) => {
                return prev.map((e) => {
                    if (e.event_id === currentEvent.event_id) {
                        let currentTags = e.tags || [];
                        if (addTag) {
                            currentTags.push(tagName);
                        } else {
                            currentTags = currentTags.filter((t) => t !== tagName);
                        }
                        return { ...e, tags: currentTags };
                    }
                    return e;
                });
            });
            try {
                await api.apiV0EventEventIdTagPost({
                    eventId: currentEvent.event_id,
                    eventTag: { name: tagName, action: addTag ? EventTagActionEnum.Add : EventTagActionEnum.Remove },
                });
            } catch {
                setEvents((prev) => {
                    return prev.map((e) => {
                        if (e.event_id === currentEvent.event_id) {
                            let currentTags = e.tags || [];
                            if (addTag) {
                                currentTags = currentTags.filter((t) => t !== tagName);
                            } else {
                                currentTags.push(tagName);
                            }
                            return { ...e, tags: currentTags };
                        }
                        return e;
                    });
                });
                setSnackbarMessage(
                    t(addTag ? t('content.feed.saved_for_later_error') : t('content.feed.unsaved_for_later_error')),
                );
            }
        }
    };

    if (isLoading || events.length === 0 || isError) {
        return (
            <Box
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                {isLoading ? (
                    <CircularProgress />
                ) : events.length === 0 ? (
                    <Typography variant="overline">{t('content.feed.no_events_found')}</Typography>
                ) : (
                    <Typography variant="overline">{t('content.feed.error_loading_events')}</Typography>
                )}
            </Box>
        );
    }

    const details = !!currentEvent ? (
        <Box
            sx={{
                position: 'absolute',
                top: isMobile ? 56 : 0,
                left: 0,
                zIndex: 5,
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                color: palette.neutral[50],
                p: 1,
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontSize: 20, fontWeight: 500 }}>{t(EVENT_TYPE_MAP[currentEvent.type])}</Typography>
                <Typography>{getDriver(currentEvent.driver)?.fullName}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Typography>{dayjs.unix(currentEvent.timestamp).format(profile.dateFormat)}</Typography>
                <Typography>{dayjs.unix(currentEvent.timestamp).format(profile.timeFormat)}</Typography>
            </Box>
        </Box>
    ) : null;

    const handleInteraction = async (interaction: 'likes' | 'dislikes', isInteracted: boolean) => {
        if (currentEvent) {
            const isLike = interaction === 'likes';
            const apiMethod = isLike
                ? api.apiV0FeedEventIdLikePost.bind(api)
                : api.apiV0FeedEventIdDislikePost.bind(api);
            const previousLikes = currentEvent.feed_event ? currentEvent.feed_event.likes : [];
            const previousDislikes = currentEvent.feed_event ? currentEvent.feed_event.dislikes : [];
            setEvents((prev) => {
                return prev.map((e) => {
                    if (e.event_id === currentEvent.event_id) {
                        const feed_event = e.feed_event || { ...EMPTY_FEED_EVENT, event_id: e.event_id };
                        feed_event.likes = feed_event.likes.filter((l) => l !== profile.user_id);
                        feed_event.dislikes = feed_event.dislikes.filter((l) => l !== profile.user_id);
                        if (!isInteracted) {
                            feed_event[interaction].push(profile.user_id);
                        }
                        e.feed_event = feed_event;
                    }
                    return e;
                });
            });
            apiMethod({ eventId: currentEvent.event_id }).catch(() => {
                setEvents((prev) => {
                    return prev.map((e) => {
                        if (e.event_id === currentEvent.event_id) {
                            const feed_event = e.feed_event || { ...EMPTY_FEED_EVENT, event_id: e.event_id };
                            feed_event.likes = previousLikes;
                            feed_event.dislikes = previousDislikes;
                            e.feed_event = feed_event;
                        }
                        return e;
                    });
                });
                setSnackbarMessage(t(`content.feed.${isInteracted ? 'remove' : 'add'}_${interaction}_error`));
            });
        }
    };

    const containerProps: SxProps = {
        position: 'absolute',
        bottom: 20,
        zIndex: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5px',
    };
    const rightContainerProps = { ...containerProps, [isRTL ? 'left' : 'right']: 10 };
    const leftContainerProps = { ...containerProps, [isRTL ? 'right' : 'left']: 10 };

    const activeColor = lighten(palette.secondary, 0.25);
    const isSaved = currentEvent?.tags?.includes('saved-for-later');
    const isLiked = currentEvent?.feed_event?.likes.includes(profile.user_id);
    const numberOfLikes = currentEvent?.feed_event?.likes.length;
    const isDisliked = currentEvent?.feed_event?.dislikes.includes(profile.user_id);
    const numberOfComments = currentEvent?.feed_event?.comments.length;
    const isUserCommented = currentEvent?.feed_event?.comments.some((c) => c.user_id === profile.user_id);
    const isUserMentioned = currentEvent?.feed_event?.comments.some((c) =>
        getUserIdsFromCommentText(c.content).includes(profile.user_id.toString()),
    );

    const filter = 'drop-shadow(0.5px 0.5px 2px rgba(0, 0, 0, 0.5))';
    const textShadow = '0.5px 0.5px 2px rgba(0, 0, 0, 0.75)';
    const commentIcon = (
        <CommentIcon fontSize="large" sx={{ color: isUserCommented ? activeColor : palette.white, filter }} />
    );
    const controls =
        !!currentEvent && !isEndOfFeed ? (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                }}
            >
                <Box sx={leftContainerProps}>
                    <IconButton
                        id="btn-show-details"
                        data-testid="btn-show-details"
                        data-active={!!showEventDetails}
                        size="large"
                        onClick={() => setShowEventDetails((p) => !p)}
                    >
                        <KeyboardArrowUpIcon fontSize="large" sx={{ color: palette.white, filter }} />
                    </IconButton>
                    <Typography fontSize={12} sx={{ color: palette.white, mt: -2, textShadow, zIndex: 3 }}>
                        {t('content.feed.more_details')}
                    </Typography>
                </Box>
                <Box sx={rightContainerProps}>
                    <IconButton
                        id="btn-like"
                        data-testid="btn-like"
                        data-active={!!isLiked}
                        size="large"
                        onClick={() =>
                            handleInteraction('likes', (currentEvent.feed_event?.likes || []).includes(profile.user_id))
                        }
                    >
                        {isLiked ? (
                            <FlagIcon fontSize="large" sx={{ color: activeColor, filter }} />
                        ) : (
                            <FlagOutlinedIcon fontSize="large" sx={{ color: palette.white, filter }} />
                        )}
                    </IconButton>
                    <Typography
                        data-testid="like-count"
                        data-value={numberOfLikes || 0}
                        fontSize={12}
                        sx={{
                            color: isLiked ? activeColor : palette.white,
                            mt: -2,
                            textShadow,
                            zIndex: 3,
                        }}
                    >
                        {!!numberOfLikes ? numberOfLikes : t('content.feed.flag')}
                    </Typography>
                    <IconButton
                        id="btn-dislike"
                        data-testid="btn-dislike"
                        data-active={!!isDisliked}
                        size="large"
                        onClick={() =>
                            handleInteraction(
                                'dislikes',
                                (currentEvent.feed_event?.dislikes || []).includes(profile.user_id),
                            )
                        }
                    >
                        <DeleteIcon fontSize="large" sx={{ color: isDisliked ? activeColor : palette.white, filter }} />
                    </IconButton>
                    <Typography fontSize={12} sx={{ color: palette.white, mt: -2, textShadow, zIndex: 3 }}>
                        {t('content.feed.dismiss')}
                    </Typography>
                    <IconButton
                        id="btn-comment"
                        data-testid="btn-comment"
                        data-active={!!isUserCommented}
                        size="large"
                        onClick={() => setShowComments(true)}
                    >
                        {isUserMentioned ? (
                            <Badge
                                overlap="circular"
                                variant="dot"
                                sx={{
                                    '& .MuiBadge-dot': {
                                        height: 12,
                                        minWidth: 12,
                                        borderRadius: 6,
                                        right: 3,
                                        backgroundColor: palette.red[400],
                                    },
                                }}
                            >
                                {commentIcon}
                            </Badge>
                        ) : (
                            commentIcon
                        )}
                    </IconButton>
                    <Typography fontSize={12} sx={{ color: palette.white, mt: -2, textShadow, zIndex: 3 }}>
                        {!!numberOfComments ? numberOfComments : t('content.feed.comment')}
                    </Typography>
                    <IconButton
                        id="btn-coach"
                        data-testid="btn-coach"
                        data-active={!!currentEvent.coach_session}
                        size="large"
                        onClick={() => setShowQuickCoaching(true)}
                    >
                        <SendIcon
                            fontSize="large"
                            sx={{ color: currentEvent.coach_session ? activeColor : palette.white, filter }}
                        />
                    </IconButton>
                    <Typography fontSize={12} sx={{ color: palette.white, mt: -2, textShadow, zIndex: 3 }}>
                        {t('content.feed.coach')}
                    </Typography>
                    <IconButton
                        id="btn-save"
                        data-testid="btn-save"
                        data-active={!!isSaved}
                        size="large"
                        onClick={() => changeTag('saved-for-later', !currentEvent?.tags?.includes('saved-for-later'))}
                    >
                        {isSaved ? (
                            <BookmarkIcon fontSize="large" sx={{ color: activeColor, filter }} />
                        ) : (
                            <BookmarkBorderIcon fontSize="large" sx={{ color: palette.white, filter }} />
                        )}
                    </IconButton>
                    <Typography
                        fontSize={12}
                        sx={{
                            color: isSaved ? activeColor : palette.white,
                            mt: -2,
                            textShadow,
                            zIndex: 3,
                        }}
                    >
                        {t('content.feed.save')}
                    </Typography>
                </Box>
            </Box>
        ) : null;

    const snackbar = (
        <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={3000}
            onClose={() => setSnackbarMessage(undefined)}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: isMobile ? 'left' : 'right' }}
            sx={{ width: isMobile ? '75%' : undefined }}
        />
    );

    const comments =
        !!currentEvent && !isEndOfFeed ? (
            <>
                <Fade in={showComments}>
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            backgroundColor: alpha(palette.primary, 0.5),
                            zIndex: 9,
                        }}
                        onClick={() => setShowComments(false)}
                    />
                </Fade>
                <FeedComments
                    isOpen={showComments}
                    onClose={() => setShowComments(false)}
                    comments={currentEvent?.feed_event?.comments || []}
                    containerRef={isMobile ? undefined : ref}
                    users={customerUsers}
                    eventId={currentEvent.event_id}
                    setSnackbarMessage={setSnackbarMessage}
                    setEvents={setEvents}
                />
            </>
        ) : null;

    const share =
        !!currentEvent && !isEndOfFeed ? (
            <FeedShareEventComponent
                showQuickCoaching={showQuickCoaching}
                setShowQuickCoaching={setShowQuickCoaching}
                currentEvent={currentEvent}
                setEvents={setEvents}
                setSnackbarMessage={setSnackbarMessage}
                containerRef={ref}
            />
        ) : null;

    const eventDetails =
        !!currentEvent && !isEndOfFeed ? (
            <FeedEventDetailsComponent
                eventId={currentEvent.event_id}
                showEventDetails={showEventDetails}
                setShowEventDetails={setShowEventDetails}
                currentEvent={currentEvent}
                setEvents={setEvents}
                setSnackbarMessage={setSnackbarMessage}
                containerRef={ref}
            />
        ) : null;

    const mobileContainer = (
        <Box sx={{ width: '100%', height: '100%' }}>
            {details}
            <Box
                ref={containerRef}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    minHeight: 0,
                    scrollSnapType: 'y mandatory',
                    scrollSnapStop: 'always',
                    overscrollBehavior: 'contain',
                    backgroundColor: 'primary.main',
                }}
            >
                {content}
            </Box>
            {controls}
            {snackbar}
            {comments}
            {share}
            {eventDetails}
        </Box>
    );

    const scrollingDisabled = showComments || showQuickCoaching || showEventDetails;

    const desktopContainer = (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box ref={containerRef} sx={{ width: '400px', height: '800px' }}>
                <Box
                    ref={ref}
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    {details}
                    <Card
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            overflow: 'auto',
                            scrollSnapType: 'y mandatory',
                            scrollSnapStop: 'always',
                            overscrollBehavior: 'contain',
                            position: 'relative',
                            backgroundColor: 'primary.main',
                        }}
                    >
                        {content}
                    </Card>
                    {controls}
                    {snackbar}
                    {comments}
                    {share}
                    {eventDetails}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                <IconButton
                    id="btn-previous"
                    size="large"
                    disabled={currentIndex === 0 || scrollingDisabled}
                    onClick={handleScrollUp}
                >
                    <ArrowUpwardIcon fontSize="large" />
                </IconButton>
                <IconButton
                    id="btn-next"
                    size="large"
                    disabled={currentIndex === events.length || scrollingDisabled}
                    onClick={handleScrollDown}
                >
                    <ArrowDownwardIcon fontSize="large" />
                </IconButton>
            </Box>
        </Box>
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            {isMobile ? mobileContainer : desktopContainer}
        </Box>
    );
};

export default FeedComponent;
