import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, CircularProgress, List, ListItem, Menu, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { CommandData, DeviceV3, ExtendedAccident } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import { normalizeLicensePlate } from '../../../utils/Str';
import { formatTime } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import EventDetailsComponent from '../Event/EventDetails';
import { getRequestEventId, getRequestStatus, statusToColor } from '../FindVideo/RequestList';

interface AccidentVideoRequestsProps {
    accidentDetails: ExtendedAccident;
    requests: CommandData[];
    onClose: CallableFunction;
    onAssociate: CallableFunction;
    devices: DeviceV3[];
    setAlert: CallableFunction;
}

const REQUEST_ROW_BACKGROUNDS = ['inherit', palette.neutral[100]];

const AccidentVideoRequests: React.FC<AccidentVideoRequestsProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRequest, setSelectedRequest] = useState<CommandData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedEventId, setSelectedEventId] = useState<string>();
    const { t } = useTranslation();
    const { profile } = useProfile();
    const history = useHistory();
    const location = useLocation();

    const normLicensePlateToDevice = Object.fromEntries(
        props.devices.map((d) => [normalizeLicensePlate(d.device.license_plate), d]),
    );
    let accidentLicensePlate = props.accidentDetails.license_plate;
    const accidentDevice = normLicensePlateToDevice[normalizeLicensePlate(accidentLicensePlate)];
    if (accidentDevice) {
        accidentLicensePlate = accidentDevice.device.license_plate;
    }

    const open = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, request: CommandData) => {
        setSelectedRequest(request);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    let content;
    if (props.requests.length === 0) {
        content = (
            <Box
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Typography variant="overline">{t('content.accidents.video.no_requests')}</Typography>
            </Box>
        );
    } else {
        content = (
            <List disablePadding>
                {props.requests.map((request, idx) => {
                    const requestData = JSON.parse(request.data);
                    const startTime = requestData.start_time;
                    const endTime = requestData.end_time;
                    const startTimeStr = dayjs.unix(startTime).format(profile.dateTimeFormat);
                    const status = getRequestStatus(request);
                    let statusStr = 'failure';
                    if (['pending', 'success'].includes(status)) {
                        statusStr = status;
                    }

                    return (
                        <ListItem
                            disableGutters
                            alignItems="center"
                            key={request.id}
                            sx={{
                                backgroundColor: REQUEST_ROW_BACKGROUNDS[idx % 2],
                                py: 1.5,
                            }}
                            secondaryAction={
                                <IconButton
                                    id="btn-options"
                                    size="small"
                                    sx={{ mr: 0.5 }}
                                    onClick={(e) => handleMenuClick(e, request)}
                                >
                                    <MoreHorizIcon />
                                </IconButton>
                            }
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: 28, ml: 1 }}>
                                    <Tooltip title={t(`content.find_video.status.${statusStr}`)}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', width: 17, ml: '1px' }}>
                                            <svg
                                                viewBox="0 0 17 17"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
                                                }}
                                            >
                                                <circle
                                                    data-testid={`request-${request.id}-status`}
                                                    className="status-circle"
                                                    cx="8.5"
                                                    cy="8.5"
                                                    r="8.5"
                                                    fill={statusToColor[status]}
                                                />
                                            </svg>
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Typography fontSize={14}>{`${startTimeStr}, ${formatTime(
                                    endTime - startTime,
                                    t,
                                    true,
                                    false,
                                )}`}</Typography>
                            </Box>
                        </ListItem>
                    );
                })}
            </List>
        );
    }

    const loadingSpinner = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: palette.white,
                opacity: 0.5,
                zIndex: 1,
            }}
        >
            <CircularProgress />
        </Box>
    );

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                p: 1,
                position: 'relative',
            }}
        >
            {isLoading ? loadingSpinner : null}
            {!!selectedEventId ? (
                <EventDetailsComponent
                    eventId={selectedEventId}
                    setAlert={props.setAlert}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isVisible={!!selectedEventId}
                    setIsVisible={() => setSelectedEventId('')}
                    onEventsPage={false}
                    setSelectedEventId={setSelectedEventId}
                    currentAccidentId={props.accidentDetails.accident_id}
                    showEditVideo
                    showFindVideo
                />
            ) : null}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                onAnimationEnd={() => {
                    if (!open) {
                        setSelectedRequest(null);
                    }
                }}
            >
                <MenuItem
                    id="view-request"
                    onClick={() => {
                        if (!!selectedRequest) {
                            history.push(`/find_video/${selectedRequest.id}`);
                            if (location.pathname.includes('/find_video')) {
                                props.onClose();
                            }
                        }
                    }}
                >
                    {t('content.accidents.video.view_request')}
                </MenuItem>
                {!!selectedRequest && getRequestStatus(selectedRequest) === 'success' ? (
                    <>
                        <MenuItem
                            id="view-event"
                            onClick={() => {
                                if (!!selectedRequest) {
                                    const eventId = getRequestEventId(selectedRequest);
                                    setIsLoading(true);
                                    setSelectedEventId(eventId);
                                }
                                handleMenuClose();
                            }}
                        >
                            {t('content.accidents.video.view_event')}
                        </MenuItem>
                        <MenuItem
                            id="associate-event"
                            onClick={() => {
                                if (!!selectedRequest) {
                                    const eventId = getRequestEventId(selectedRequest);
                                    if (eventId !== undefined) {
                                        props.onAssociate(parseInt(eventId));
                                    }
                                }
                                handleMenuClose();
                            }}
                        >
                            {t('content.accidents.video.associate')}
                        </MenuItem>
                    </>
                ) : null}
            </Menu>
            <Box sx={{ flex: 1, width: '100%', overflowY: 'auto' }}>{content}</Box>
            <Box sx={{ width: '33%', mt: 1, pr: 0.5 }}>
                <Button
                    id="btn-create-video-request"
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={props.accidentDetails.accident_id === 0}
                    onClick={() =>
                        history.push(
                            `/find_video?date=${props.accidentDetails.timestamp}${
                                !!props.accidentDetails.license_plate
                                    ? `&license_plate=${encodeURIComponent(accidentLicensePlate)}`
                                    : ''
                            }&accident_id=${props.accidentDetails.accident_id}`,
                        )
                    }
                >
                    {t('content.accidents.video.create')}
                </Button>
            </Box>
        </Box>
    );
};

export default AccidentVideoRequests;
