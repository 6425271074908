import { Box, Checkbox, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Truck from '../../../../images/truck.svg';

interface ImpactGraphProps {
    impactSides: string[];
    setImpactSides: React.Dispatch<React.SetStateAction<string[]>>;
    width: number;
    height: number;
}

const ImpactGraph: React.FC<ImpactGraphProps> = (props: ImpactGraphProps) => {
    const { i18n, t } = useTranslation();
    const isRTL = i18n.languages[0] === 'he';

    const handleCheck = (side: string, checked: boolean) => {
        if (checked) {
            props.setImpactSides((prev) => [...prev, side]);
        } else {
            props.setImpactSides((prev) => prev.filter((item) => item !== side));
        }
    };

    const getCheckbox = (side: string) => {
        return (
            <Tooltip
                key={side}
                followCursor={true}
                title={t(`content.accidents.tags.damage_mechanism.tooltip.${side}`, '')}
            >
                <Checkbox
                    id={`${side}-checkbox`}
                    checked={props.impactSides.includes(side)}
                    onChange={(_, checked) => handleCheck(side, checked)}
                />
            </Tooltip>
        );
    };

    return (
        <Box sx={{ ml: '34px', width: props.width, direction: isRTL ? 'rtl' : 'ltr' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ ml: isRTL ? -1 : 1 }}>{getCheckbox('front')}</Box>
                <Box sx={{ display: 'flex' }}>
                    <Box
                        sx={{
                            height: props.height,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        {getCheckbox('front_left')}
                        {getCheckbox('left')}
                        {getCheckbox('rear_left')}
                    </Box>
                    <Box sx={{ height: props.height }}>
                        <img height="100%" src={Truck} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            height: props.height,
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        {getCheckbox('front_right')}
                        {getCheckbox('right')}
                        {getCheckbox('rear_right')}
                    </Box>
                </Box>
                <Box sx={{ ml: isRTL ? -1 : 1 }}>{getCheckbox('rear')}</Box>
            </Box>
        </Box>
    );
};

export default ImpactGraph;
