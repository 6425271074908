import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../../../components/TrackedComponents';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAllParents = (node: Array<string | Record<string, any>>): string[] => {
    return node.flatMap((value) => {
        if (typeof value == 'string') {
            return [];
        }
        const key = Object.keys(value)[0];
        return [key, ...getAllParents(value[key])];
    });
};

interface TaggingTreeProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    structure: Array<string | Record<string, any>>;
    selected: string[];
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
    translationPrefix: string;
    toggleControls?: boolean;
    maxSelection?: number;
    expandByDefault?: boolean;
    additionalItem?: React.ReactNode;
}

const TaggingTree: React.FC<TaggingTreeProps> = (props: TaggingTreeProps) => {
    const [expanded, setExpanded] = useState<string[]>(props.expandByDefault ? getAllParents(props.structure) : []);
    const { i18n, t } = useTranslation();
    const isRTL = i18n.languages[0] === 'he';

    const handleToggle = (_event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleCheck = (value: string, checked: boolean) => {
        if (checked) {
            props.setSelected((prev) => {
                if (props.maxSelection === undefined || prev.length < props.maxSelection) {
                    return [...prev, value];
                }
                return prev;
            });
        } else {
            props.setSelected((prev) => prev.filter((item) => item !== value));
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const treeItems = (node: Array<string | Record<string, any>>) => {
        return node.map((value) => {
            if (typeof value == 'string') {
                if (value === 'graph') {
                    return props.additionalItem;
                }
                return (
                    <Tooltip
                        followCursor={true}
                        title={t(`content.accidents.tags.${props.translationPrefix}.tooltip.${value}`, '')}
                        key={value}
                    >
                        <TreeItem
                            nodeId={value}
                            label={
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={`${value}-checkbox`}
                                            checked={props.selected.includes(value)}
                                            onChange={(_, checked) => handleCheck(value, checked)}
                                        />
                                    }
                                    label={t(`content.accidents.tags.${props.translationPrefix}.${value}`)}
                                    sx={{ width: '100%' }}
                                />
                            }
                        />
                    </Tooltip>
                );
            }
            const key = Object.keys(value)[0];
            return (
                <TreeItem
                    key={key}
                    nodeId={key}
                    label={
                        <Box sx={{ display: 'flex', alignItems: 'center', height: 42 }}>
                            <Typography sx={{ fontWeight: 500 }}>
                                {t(`content.accidents.tags.${props.translationPrefix}.${key}`)}
                            </Typography>
                        </Box>
                    }
                >
                    {treeItems(value[key])}
                </TreeItem>
            );
        });
    };

    return (
        <>
            {props.toggleControls ? (
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <Button
                        id="expand-all-btn"
                        variant="contained"
                        onClick={() => setExpanded(getAllParents(props.structure))}
                        sx={{ mr: 1 }}
                    >
                        {t('content.accidents.tags.expand_all')}
                    </Button>
                    <Button id="collapse-all-btn" variant="contained" onClick={() => setExpanded([])} sx={{ mr: 1 }}>
                        {t('content.accidents.tags.collapse_all')}
                    </Button>
                    <Button id="clear-btn" variant="contained" color="secondary" onClick={() => props.setSelected([])}>
                        {t('content.accidents.tags.clear')}
                    </Button>
                </Box>
            ) : null}
            <Box sx={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
                <TreeView
                    aria-label="multi-select"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={isRTL ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    disableSelection
                    multiSelect
                    expanded={expanded}
                    selected={props.selected}
                    onNodeToggle={handleToggle}
                    sx={{ height: '100%', maxWidth: '500px' }}
                >
                    {treeItems(props.structure)}
                </TreeView>
            </Box>
        </>
    );
};

export default TaggingTree;
