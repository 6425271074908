import { Alert, Box, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../../../../components/TrackedComponents';
import useApi from '../../../../../hooks/api';
import useProfile from '../../../../../hooks/profile';
import palette from '../../../../ColorPalette';
import { getStoredValue, setStoredValue } from '../utils/storage';
import { EMPTY_PARAMS, Params, convertParam } from './InstallationParams';

interface OldDeviceProps {
    malfunctionId: number;
    mode: string;
    customerName: string;
    installerName: string;
    oldImei: string;
    params: Params;
    setParams: React.Dispatch<React.SetStateAction<Params>>;
    onBack: () => void;
    onNext: () => void;
    dashboardLink: React.ReactNode;
}

const OldDevice: React.FC<OldDeviceProps> = (props) => {
    const [licensePlate, setLicensePlate] = useState(props.params.license_plate);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();
    const { installApi } = useApi();
    const { profile } = useProfile();

    const suffix = `${props.malfunctionId}-${props.mode}`;

    useEffect(() => {
        const existingParams = getStoredValue<Params>('OE-params', suffix, EMPTY_PARAMS);
        setStoredValue<Params>('OE-params', suffix, { ...existingParams, license_plate: licensePlate });
    }, [props.oldImei, licensePlate]);

    const handleNextSwitch = () => {
        setIsLoading(true);
        installApi
            .installSwitchPost({
                checkDeviceRequest: {
                    customer_name: props.customerName,
                    device_id: props.oldImei,
                    license_plate: licensePlate,
                },
            })
            .then((res) => {
                const updatedParams: Params = { ...EMPTY_PARAMS };
                if (res.data.installation_params !== undefined) {
                    for (const key of Object.keys(updatedParams)) {
                        if (key in res.data.installation_params) {
                            updatedParams[key as keyof Params] = res.data.installation_params[key as keyof Params];
                        }
                    }
                    const instHorizontalOffsetStr = res.data.installation_params.instHorizontalOffset;
                    if (instHorizontalOffsetStr) {
                        const instHorizontalOffset = parseFloat(instHorizontalOffsetStr);
                        updatedParams.offsetDirection = instHorizontalOffset < 0 ? 'left' : 'right';
                        updatedParams.instHorizontalOffset = Math.abs(instHorizontalOffset).toString();
                    }
                }
                updatedParams.license_plate = licensePlate;
                const measurementSystem = profile.customer.settings.country === 'us' ? 'IMPERIAL' : 'METRIC';
                updatedParams.instHeight = convertParam(
                    parseFloat(updatedParams.instHeight),
                    'METRIC',
                    measurementSystem,
                ).toString();
                updatedParams.instDepth = convertParam(
                    parseFloat(updatedParams.instDepth),
                    'METRIC',
                    measurementSystem,
                ).toString();
                updatedParams.instHoodHeight = convertParam(
                    parseFloat(updatedParams.instHoodHeight),
                    'METRIC',
                    measurementSystem,
                ).toString();
                updatedParams.instHorizontalOffset = convertParam(
                    parseFloat(updatedParams.instHorizontalOffset),
                    'METRIC',
                    measurementSystem,
                ).toString();
                updatedParams['vehicle-width'] = convertParam(
                    parseFloat(updatedParams['vehicle-width']),
                    'METRIC',
                    measurementSystem,
                ).toString();
                props.setParams(updatedParams);
                setStoredValue<Params>('OE-params', suffix, updatedParams);
                props.onNext();
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
    };

    const handleNextRemove = () => {
        setIsLoading(true);
        installApi
            .installRemovePost({
                removeDeviceRequest: {
                    customer_name: props.customerName,
                    device_id: props.oldImei,
                    license_plate: licensePlate,
                    installer: props.installerName,
                },
            })
            .then(() => props.onNext())
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
    };

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h6" textAlign="center">
                    {t(`setup_tool.old_device.${props.mode}_header`)}
                </Typography>
                {isError ? (
                    <Alert severity="error" sx={{ alignItems: 'center', mt: 2 }}>
                        {t('setup_tool.old_device.error')}
                    </Alert>
                ) : null}
                <TextField
                    key="old-imei"
                    id="old-imei-field"
                    required
                    fullWidth
                    disabled
                    value={props.oldImei}
                    inputProps={{ 'data-testid': 'old-imei-input' }}
                    label={t('setup_tool.old_device.imei')}
                    variant="outlined"
                    sx={{ mt: 2 }}
                />
                <TextField
                    key="license-plate"
                    id="license-plate-field"
                    required
                    fullWidth
                    value={licensePlate}
                    onChange={(e) => setLicensePlate(e.target.value)}
                    inputProps={{ 'data-testid': 'license-plate-input' }}
                    label={t('setup_tool.old_device.license_plate')}
                    variant="outlined"
                    sx={{ mt: 2 }}
                />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', mt: 1 }}>
                <Box sx={{ flex: 1, mr: 1 }}>
                    <Button id="btn-back" fullWidth variant="contained" color="primary" onClick={() => props.onBack()}>
                        {t('setup_tool.back')}
                    </Button>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Button
                        id="btn-next"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={licensePlate === '' || isLoading}
                        onClick={() => {
                            if (['switch', 'adjust'].includes(props.mode)) {
                                handleNextSwitch();
                            } else if (props.mode === 'remove') {
                                handleNextRemove();
                            }
                        }}
                        data-isloading={isLoading}
                        sx={{ height: '36.5px' }}
                    >
                        {isLoading ? (
                            <CircularProgress size={24} sx={{ color: palette.neutral[400] }} />
                        ) : (
                            t('setup_tool.next')
                        )}
                    </Button>
                </Box>
            </Box>
            {props.dashboardLink}
        </Box>
    );
};

export default OldDevice;
