import { Updater, useQuery, useQueryClient } from '@tanstack/react-query';

import { FleetDriver } from '../backendsdk';
import useApi from './api';

export interface ExtendedFleetDriver extends FleetDriver {
    fullName: string;
}

const useDrivers = (
    cache?: number,
): {
    drivers: ExtendedFleetDriver[];
    isLoading: boolean;
    isError: boolean;
    getDriver: (token: number | undefined | null) => ExtendedFleetDriver | undefined;
    setDrivers: CallableFunction;
    refetch: CallableFunction;
} => {
    const queryClient = useQueryClient();
    const { api } = useApi();

    const getDriverFullName = (driver: FleetDriver): string => {
        return `${driver.first_name} ${driver.last_name}`.trim();
    };

    const getDrivers = async (): Promise<ExtendedFleetDriver[]> => {
        const { data } = await api.apiV0DriverGet({ cache: cache !== undefined ? cache : 1 });
        return data.map((driver: FleetDriver) => ({
            ...driver,
            fullName: getDriverFullName(driver),
        }));
    };

    const { data, isLoading, isError, refetch } = useQuery({
        queryKey: ['drivers'],
        queryFn: getDrivers,
        staleTime: Infinity,
        retry: false,
    });

    const getDriver = (token: number | undefined | null): ExtendedFleetDriver | undefined => {
        return data?.find((d) => d.token == token);
    };

    const setDrivers = (func: Updater<ExtendedFleetDriver[] | undefined, ExtendedFleetDriver[] | undefined>) => {
        queryClient.setQueryData(['drivers'], func);
    };

    return { drivers: data || [], isLoading, isError, getDriver, setDrivers, refetch };
};

export default useDrivers;
