import AttachmentIcon from '@mui/icons-material/Attachment';
import { Box, Fade, Grid, ImageList, ImageListItem, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedAccident } from '../../../backendsdk';
import { TrackedButton as Button, TrackedDialog as Dialog } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import { useImages } from '../../../hooks/images';
import palette from '../../ColorPalette';
import { IMAGE_FILE_EXTENTION } from './Defs';

interface BaseAccidentNoticeFormComponentProps {
    details: ExtendedAccident;
    files: Array<File> | null;
    onSave: CallableFunction;
    handleChange: CallableFunction;
    setFiles: CallableFunction;
    setAlert: CallableFunction;
}

interface AccidentNoticeModalComponentProps extends BaseAccidentNoticeFormComponentProps {
    onClose: CallableFunction;
}

const MAX_FILE_COUNT = 5;

const AccidentNoticeModal: React.FC<AccidentNoticeModalComponentProps> = (props: AccidentNoticeModalComponentProps) => {
    const [selectedImage, setSelectedImage] = useState<string[]>([]);
    const { t } = useTranslation();
    const { agencyApi } = useApi();
    const { details } = props;
    const getArtifactImage = (artifact_id: string) =>
        agencyApi.agencyV1AccidentAccidentIdArtifactArtifactIdGet(
            {
                accidentId: details.accident_id,
                artifactId: parseInt(artifact_id),
            },
            { responseType: 'blob', query: { thumbnail: true } },
        );

    const [images, addToQueue] = useImages(5, getArtifactImage);
    const imageArtifact = details.artifact_list
        ?.filter((file) => IMAGE_FILE_EXTENTION.includes(file.file_name.split('.').pop() || ''))
        .map((artifact) => artifact.artifact_id.toString());

    const disabled = selectedImage.length > MAX_FILE_COUNT;

    useEffect(() => {
        if (imageArtifact.length > 0) {
            addToQueue(imageArtifact);
        } else {
            createDriverNotice();
        }
    }, []);

    const createDriverNotice = () => {
        props.setAlert({ message: t('content.accidents.notice.creating'), type: 'info' });
        agencyApi
            .agencyV1AccidentAccidentIdNoticePost({
                accidentId: details.accident_id,
                noticeRequest: { artifacts: selectedImage.map((artifact_id) => parseInt(artifact_id)) },
            })
            .then(() => {
                props.onSave();
                props.setAlert({ message: t('content.accidents.notice.done'), type: 'success', duration: 6000 });
                props.onClose();
            })
            .catch(() => {
                props.setAlert({ message: t('content.accidents.notice.error'), type: 'error', duration: 6000 });
            });
    };

    const toggleFileSelection = (artifact_id: string) =>
        setSelectedImage((p) => {
            if (p.includes(artifact_id)) {
                return p.filter((id) => id !== artifact_id);
            }
            return [...p, artifact_id];
        });

    return imageArtifact.length > 0 ? (
        <Dialog id="notice-form-dialog" open={true} onClose={() => props.onClose()}>
            <Box sx={{ backgroundColor: 'secondary.main', position: 'sticky', top: 0, p: 1, zIndex: 2 }}>
                {t('content.accidents.notice.button')}
            </Box>
            <Box sx={{ p: 1 }}>
                <Typography>{t('content.accidents.notice.instructions')}</Typography>
                <ImageList sx={{ width: 500, height: 450, mt: 0 }} cols={3} rowHeight={164}>
                    {imageArtifact.map((artifact_id) => (
                        <ImageListItem key={artifact_id} sx={{ position: 'relative' }}>
                            {images[artifact_id] ? (
                                <img
                                    src={images[artifact_id]}
                                    loading="lazy"
                                    style={{
                                        opacity: selectedImage.includes(artifact_id) ? 1 : 0.8,
                                        transition: 'opacity 0.3s',
                                        height: 164,
                                        width: 164,
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => toggleFileSelection(artifact_id)}
                                />
                            ) : (
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                        height: 164,
                                        width: 164,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => toggleFileSelection(artifact_id)}
                                />
                            )}
                            <Fade in={selectedImage.includes(artifact_id)} timeout={300}>
                                <AttachmentIcon
                                    sx={{
                                        position: 'absolute',
                                        top: 10,
                                        left: 10,
                                        borderRadius: '50%',
                                        backgroundColor: palette.white,
                                        transform: 'rotate(225deg)',
                                    }}
                                />
                            </Fade>
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
            <Box sx={{ p: 1 }}>
                <Typography color="error" sx={{ height: 20 }}>
                    {disabled ? t('content.accidents.notice.too_many', { max: MAX_FILE_COUNT }) : ' '}
                </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
                <Grid container columns={3} columnSpacing={1} justifyContent="flex-end">
                    <Grid item xs={1}>
                        <Button
                            id="cencel-btn"
                            variant="contained"
                            size="small"
                            onClick={() => props.onClose()}
                            sx={{ width: '100%' }}
                        >
                            {t('content.accidents.notice.cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            id="notice-send-btn"
                            color="secondary"
                            variant="contained"
                            size="small"
                            onClick={createDriverNotice}
                            sx={{ width: '100%' }}
                            disabled={disabled}
                        >
                            {t('content.accidents.notice.send')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    ) : null;
};
const AccidentNoticeFormComponent: React.FC<BaseAccidentNoticeFormComponentProps> = (
    props: BaseAccidentNoticeFormComponentProps,
) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const { details } = props;
    const { t } = useTranslation();
    const disabled = details.accident_id === 0;

    return (
        <>
            {showModal ? <AccidentNoticeModal {...props} onClose={() => setShowModal(false)} /> : null}
            <Grid item xs={1}>
                <Button
                    id="notice-btn"
                    variant="contained"
                    size="small"
                    disabled={disabled}
                    onClick={() => setShowModal(true)}
                    sx={{ width: '100%' }}
                >
                    {t('content.accidents.notice.button')}
                </Button>
            </Grid>
        </>
    );
};

export default AccidentNoticeFormComponent;
