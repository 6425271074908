import { Box, Typography } from '@mui/material';
import React from 'react';

import palette from '../../../ColorPalette';
import { CARD_WIDTH, IMAGE_ASPECT_RATIO } from '../../Vehicles/VehicleCard';

interface AccidentCardImageProps {
    navigateToCommand?: (e: React.MouseEvent) => void;
    icon: React.ReactNode;
    mainText: React.ReactNode;
    secondaryText: React.ReactNode;
}

export const AccidentCardImage: React.FC<AccidentCardImageProps> = (props: AccidentCardImageProps) => {
    return (
        <Box
            onClick={props.navigateToCommand}
            sx={{
                width: CARD_WIDTH,
                height: CARD_WIDTH * IMAGE_ASPECT_RATIO,
                backgroundColor: palette.neutral[200],
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {props.icon}
                <Typography sx={{ fontWeight: 500, ml: 0.5 }}>{props.mainText}</Typography>
            </Box>
            <Typography textAlign="center" fontSize={14} sx={{ width: '80%' }}>
                {props.secondaryText}
            </Typography>
        </Box>
    );
};

export default AccidentCardImage;
