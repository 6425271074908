import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Box } from '@mui/material';
import React, { useState } from 'react';

import { Overlay } from '../../../backendsdk';
import { TrackedButton as Button, TrackedDialog as Dialog } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import { MuiTextField } from '../Accident/MuiFormControls';

interface UpdateOverlayProps {
    overlay: Overlay;
    onClose: CallableFunction;
    onSuccess: CallableFunction;
}

const UpdateOverlay: React.FC<UpdateOverlayProps> = (props) => {
    const [overlayName, setOverlayName] = useState<string>(props.overlay.name);
    const [overlayFeature, setOverlayFeature] = useState<string>(props.overlay.feature);
    const [overlay, setOverlay] = useState<string>(JSON.stringify(props.overlay.overlay, null, 2));
    const { controlApi } = useApi();
    const [alertElement, setAlert] = useAlert();

    const handleSave = () => {
        controlApi
            .controlV0OverlayOverlayIdPost({
                overlayId: props.overlay.id,
                overlayRequest: { name: overlayName, overlay: JSON.parse(overlay), feature: overlayFeature },
            })
            .then((res) => {
                props.onSuccess(res.data);
                props.onClose();
            })
            .catch((err) => {
                setAlert({ message: err.message, type: 'error', duration: 6000 });
            });
    };

    return (
        <Dialog id="update-overlay-dialog" open={true} maxWidth="md" fullWidth onClose={() => props.onClose()}>
            {alertElement}
            <Box sx={{ backgroundColor: 'secondary.main', p: 1 }}>
                {props.overlay.id === 0
                    ? 'New Configuration'
                    : `Configuration: ${props.overlay.feature}/${props.overlay.name}`}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    m: 1,
                }}
            >
                {props.overlay.id === 0 ? (
                    <MuiTextField
                        id="overlay-feature"
                        name="overlay-feature"
                        label="Overlay Feature Name"
                        value={overlayFeature}
                        sx={{ mt: 1 }}
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                            setOverlayFeature(e.target.value)
                        }
                    />
                ) : null}
                <MuiTextField
                    id="overlay-name"
                    name="overlay-name"
                    label="Overlay Name"
                    value={overlayName}
                    sx={{ mt: 1 }}
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setOverlayName(e.target.value)
                    }
                />
                <TextareaAutosize
                    style={{ overflow: 'auto', height: '65vh', margin: '1em' }}
                    value={overlay}
                    onChange={(e) => setOverlay(e.target.value)}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Button
                        id="btn-update-overlay-save"
                        color="secondary"
                        variant="contained"
                        sx={{ mr: 1 }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Button
                        id="btn-update-overlay-cancel"
                        color="primary"
                        variant="contained"
                        sx={{ mr: 1 }}
                        onClick={() => props.onClose()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default UpdateOverlay;
