import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { CommandData, DeviceV3, ExtendedAccident, TripDetails } from '../../../../../backendsdk';
import { TrackedButton as Button } from '../../../../../components/TrackedComponents';
import { useAlert } from '../../../../../hooks/alert';
import { normalizeLicensePlate } from '../../../../../utils/Str';
import palette from '../../../../ColorPalette';
import RequestList from '../../../FindVideo/RequestList';
import VideoRequestForm from '../../../FindVideo/VideoRequestForm';
import { sortingOptions } from '../../../FindVideo/utils';
import { SortingModel } from '../../../Overview/FleetOverview';
import SortMenu from '../../../Overview/SortMenu';

interface FindVideoTabProps {
    details: ExtendedAccident;
    requests: CommandData[];
    setRequests: CallableFunction;
    devices: DeviceV3[];
    isLoadingDevices: boolean;
    isLoadingRequests: boolean;
    isError: boolean;
    handleSave: CallableFunction;
    setDetails: CallableFunction;
    setAccidents: CallableFunction;
}

export const FindVideoTab: React.FC<FindVideoTabProps> = (props) => {
    const [sorting, setSorting] = useState<SortingModel>({
        field: 'created_at',
        order: 'desc',
    });
    const [selectedTrip, setSelectedTrip] = useState<TripDetails | undefined>(undefined);
    const [isRequestOpen, setIsRequestOpen] = useState<boolean>(false);
    const params = useParams<{ commandId?: string; tripId?: string }>();
    const [alertElement, setAlert] = useAlert();
    const { t } = useTranslation();
    const history = useHistory();

    const baseURL = `/accidents/${props.details.accident_id}`;
    const device = props.devices.find(
        (d) => normalizeLicensePlate(d.device.license_plate) === normalizeLicensePlate(props.details.license_plate),
    );
    const licensePlates = props.devices.map((d) => d.device.license_plate);
    const selectedRequestId = params.commandId ? parseInt(params.commandId) : undefined;
    const selectedRequest =
        licensePlates.length > 0 ? props.requests.find((r) => r.id === selectedRequestId) : undefined;

    const handleSelect = (request: CommandData) => {
        history.push(`${baseURL}/command/${request.id}`);
    };

    const handleSelectTrip = (trip: TripDetails) => {
        setSelectedTrip(trip);
        history.push(`${baseURL}/trip/${trip.trip_id}`);
    };

    useEffect(() => {
        setIsRequestOpen(!!selectedRequest);
    }, [selectedRequest]);

    const loadingIndicator = (
        <Box
            sx={{
                width: '100%',
                height: 'calc(100vh - 32px - 32px - 48px - 40px - 1px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    );

    if (props.isLoadingDevices || (props.isLoadingRequests && selectedRequestId !== undefined)) {
        return loadingIndicator;
    }

    let toolbarContent;
    if (isRequestOpen) {
        toolbarContent = (
            <>
                <Button
                    id="back-btn"
                    data-testid="back-btn"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setIsRequestOpen(false);
                        setSelectedTrip(undefined);
                        history.push(baseURL);
                    }}
                    sx={{ height: 35 }}
                >
                    {t('content.find_video.back')}
                </Button>
            </>
        );
    } else {
        toolbarContent = (
            <>
                <Button
                    id="new-request-btn"
                    data-testid="new-request-btn"
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsRequestOpen(true)}
                    startIcon={<AddIcon />}
                    sx={{ flexShrink: 0, height: 35, mr: 1 }}
                >
                    {t('content.find_video.new_request')}
                </Button>
                <SortMenu
                    options={sortingOptions}
                    selected={sorting}
                    setSelected={setSorting}
                    translationKey="content.find_video.sort"
                />
            </>
        );
    }
    toolbarContent = (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                minHeight: 0,
                flexShrink: 0,
                p: 2,
                pr: 1,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                clipPath: 'inset(0px 0px -10px 0px);',
            }}
        >
            {toolbarContent}
        </Box>
    );

    let leftPanelContent;
    if (isRequestOpen || params.tripId) {
        leftPanelContent = (
            <Box sx={{ width: '100%', height: '100%' }}>
                <VideoRequestForm
                    isLoading={props.isLoadingDevices}
                    devices={props.devices}
                    selectedRequest={selectedRequest}
                    setRequests={props.setRequests}
                    selectedTrip={selectedTrip}
                    selectedTripId={params.tripId}
                    setSelectedTrip={handleSelectTrip}
                    setAlert={setAlert}
                    toolbar={toolbarContent}
                    inAccident
                    device={device}
                    date={dayjs.unix(props.details.timestamp)}
                    associatedEvent={!!props.details.event ? props.details.event : undefined}
                    onAssociateEvent={(eventId: number, timestamp?: number) => {
                        const updatedDetails = { ...props.details, event: eventId };
                        if (timestamp) {
                            updatedDetails.timestamp = timestamp;
                        }
                        return props.handleSave(updatedDetails);
                    }}
                    accidentId={props.details.accident_id}
                    onCreateRequest={(command: CommandData) => {
                        props.setDetails((prev: ExtendedAccident) => ({ ...prev, last_command_result: 'pending' }));
                        props.setAccidents((prev: ExtendedAccident[]) => {
                            const updated = prev.map((a) => {
                                if (a.accident_id === props.details.accident_id) {
                                    return { ...a, last_command: command };
                                }
                                return a;
                            });
                            return updated;
                        });
                    }}
                />
            </Box>
        );
    } else {
        leftPanelContent = (
            <Box
                sx={{
                    width: '375px',
                    height: '100%',
                    backgroundColor: palette.neutral[50],
                    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.25)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {toolbarContent}
                <RequestList
                    requests={props.requests}
                    devices={props.devices}
                    isLoading={props.isLoadingRequests}
                    isError={props.isError}
                    onClick={handleSelect}
                    inAccident
                />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                height: 'calc(100vh - 32px - 32px - 48px - 40px - 1px)',
                overflow: 'scroll',
                minHeight: 0,
            }}
        >
            {alertElement}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    width: '100%',
                    minHeight: 0,
                }}
            >
                {leftPanelContent}
            </Box>
        </Box>
    );
};

export default FindVideoTab;
