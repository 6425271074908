import { DriverWithCounters } from '../Defs';

export const DRIVERS: DriverWithCounters[] = [
    {
        driver_id: 2,
        first_name: 'Omry',
        last_name: 'Sendik',
        driver_license_number: 'ABC123',
        driver_license_state_province: 'NY',
        phone: '1234567890',
        email: 'omrysendik@openeyes-insurance.com',
        license: 'B1',
        personal_conveyance: false,
        yard_move: false,
        current_device: {
            device_id: '869455049353325',
            customer: 'OpenEyesAI',
            operator: 'OpenEyesAI',
            customer_sub_fleet: 'ops',
            license_plate: '54-565-71',
            driver_name: 'Omry Sendik',
        },
        current_status: 1,
        home_terminal: {
            id: 1,
            name: 'NY',
            address: 'NY',
            timezone_offset_from_utc: 5,
            consecutive_days: 7,
            day_start_time: '00:00',
        },
        driving_type: 'PROPERTY',
        counters: {
            break: 3600,
            drive: 36000,
            shift: 36600,
            cycle: 10000,
            violation_today: 3600,
            violation_cycle: 0,
        },
    },
    {
        driver_id: 4,
        first_name: 'Alon',
        last_name: 'Pilosoph',
        driver_license_number: 'ABC123',
        driver_license_state_province: 'NY',
        phone: '1234567890',
        email: 'alonp@openeyes.com',
        license: 'B1',
        personal_conveyance: false,
        yard_move: false,
        current_device: {
            device_id: '869455049353325',
            customer: 'OpenEyesAI',
            operator: 'OpenEyesAI',
            customer_sub_fleet: 'ops',
            license_plate: '54-565-71',
            driver_name: 'Omry Sendik',
        },
        current_status: 2,
        home_terminal: {
            id: 1,
            name: 'NY',
            address: 'NY',
            timezone_offset_from_utc: 5,
            consecutive_days: 7,
            day_start_time: '00:00',
        },
        driving_type: 'PROPERTY',
        counters: {
            break: 3600,
            drive: 36000,
            shift: 36600,
            cycle: 10000,
            violation_today: 0,
            violation_cycle: 0,
        },
    },
    {
        driver_id: 5,
        first_name: 'Roee',
        last_name: 'Pargament',
        driver_license_number: 'ABC123',
        driver_license_state_province: 'NY',
        phone: '1234567890',
        email: 'roeep@openeyes-insurance.com',
        license: 'B1',
        personal_conveyance: false,
        yard_move: false,
        current_device: {
            device_id: '861130040133566',
            customer: 'OpenEyesAI',
            operator: 'OpenEyesAI',
            customer_sub_fleet: 'ops',
            license_plate: 'NYY7206',
            driver_name: ' Roee Pargament',
        },
        current_status: 3,
        home_terminal: {
            id: 1,
            name: 'NY',
            address: 'NY',
            timezone_offset_from_utc: 5,
            consecutive_days: 7,
            day_start_time: '00:00',
        },
        driving_type: 'PROPERTY',
        counters: {
            break: 3600,
            drive: 36000,
            shift: 36600,
            cycle: 10000,
            violation_today: 0,
            violation_cycle: 0,
        },
    },
    {
        driver_id: 6,
        first_name: 'Mariusz',
        last_name: 'Bryk',
        driver_license_number: 'ABC123',
        driver_license_state_province: 'NY',
        phone: '?',
        email: 'mariuszb@openeyes.com',
        license: '?',
        personal_conveyance: false,
        yard_move: false,
        current_device: {
            device_id: '869455049354851',
            customer: 'OpenEyesAI',
            operator: 'OpenEyesAI',
            customer_sub_fleet: 'ops',
            license_plate: 'F150',
            driver_name: 'Mario',
        },
        current_status: 4,
        home_terminal: {
            id: 1,
            name: 'NY',
            address: 'NY',
            timezone_offset_from_utc: 5,
            consecutive_days: 7,
            day_start_time: '00:00',
        },
        driving_type: 'PROPERTY',
        counters: {
            break: 3600,
            drive: 36000,
            shift: 36600,
            cycle: 10000,
            violation_today: 0,
            violation_cycle: 0,
        },
    },
    {
        driver_id: 12,
        first_name: 'Eli',
        last_name: 'Plaut',
        driver_license_number: 'ABC123',
        driver_license_state_province: 'NY',
        phone: 'aaa',
        email: 'elip@openeyes.com',
        license: 'aa',
        personal_conveyance: false,
        yard_move: false,
        current_device: {
            device_id: '869455049354851',
            customer: 'OpenEyesAI',
            operator: 'OpenEyesAI',
            customer_sub_fleet: 'ops',
            license_plate: '11-222-33',
            driver_name: 'Eli',
        },
        current_status: 3,
        home_terminal: {
            id: 2,
            name: 'City Garden',
            address: 'Tel Aviv',
            timezone_offset_from_utc: 10,
            consecutive_days: 7,
            day_start_time: '00:00',
        },
        driving_type: 'PROPERTY',
        counters: {
            break: 3600,
            drive: 36000,
            shift: 36600,
            cycle: 0,
            violation_today: 0,
            violation_cycle: 0,
        },
    },
];
