import { Box, CircularProgress, Grid, Tabs, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CoachMetricByType, EventDetails, FrontFacingWellness, InCabinWellnessV1 } from '../../../backendsdk';
import { TabPanel, a11yProps } from '../../../components/Tab';
import { TrackedDialog as Dialog, TrackedLink as Link, TrackedTab as Tab } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useDrivers from '../../../hooks/drivers';
import { useImages } from '../../../hooks/images';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import ScoresTab from '../Vehicles/ScoresTab';
import { STATUS } from '../Vehicles/VehicleModal';
import VehicleModalDetails from '../Vehicles/VehicleModalDetails';
import VehicleModalHeader from '../Vehicles/VehicleModalHeader';
import VehicleModalSidebar from '../Vehicles/VehicleModalSidebar';
import { WellnessCardProps } from './WellnessCard';
import { behaviorsMap } from './WellnessComponent';
import WellnessStatusDetails from './WellnessStatusDetails';

const REQUEST_LIMIT = 10;

interface WellnessModalBaseProps {
    onClose: CallableFunction;
    initialTab?: number;
}

interface WellnessModalWithVehicle extends WellnessModalBaseProps {
    vehicle: WellnessCardProps;
    licensePlate?: never;
}

interface VehicleModalWithLicensePlate extends WellnessModalBaseProps {
    licensePlate: string;
    vehicle?: never;
}

export const getItemId = (item: EventDetails | InCabinWellnessV1 | FrontFacingWellness) => {
    if ('event_id' in item) {
        return item.event_id;
    }
    return item.id;
};

export type WellnessModalProps = WellnessModalWithVehicle | VehicleModalWithLicensePlate;

const WellnessModal: React.FC<WellnessModalProps> = (props: WellnessModalProps) => {
    const [vehicle, setVehicle] = useState<WellnessCardProps | undefined>(props.vehicle);
    const [tab, setTab] = useState<number>(props.initialTab || 0);
    const [selectedItem, setSelectedItem] = useState<number>();
    const [details, setDetails] = useState<EventDetails | InCabinWellnessV1 | FrontFacingWellness>();
    const [loadingDetails, setLoadingDetails] = useState<boolean>(true);
    const [isLoadingItems, setIsLoadingItems] = useState<boolean>(true);
    const [isEventsError, setIsEventsError] = useState<boolean>(false);
    const { api } = useApi();
    const getImage = (imageKey: string) => {
        const [type, id] = imageKey.split(':', 2);
        if (type === 'event') {
            return api.apiV0EventEventIdImageGet({ eventId: parseInt(id) }, { responseType: 'blob' });
        } else {
            return api.apiV2WellnessImageWellnessTypeGet(
                {
                    wellnessType: type,
                    wellnessId: id,
                },
                { responseType: 'blob' },
            );
        }
    };
    const [itemImages, addToQueue] = useImages(REQUEST_LIMIT, getImage);
    const [events, setEvents] = useState<EventDetails[]>([]);
    const [inCabinStatuses, setInCabinStatuses] = useState<InCabinWellnessV1[]>([]);
    const [frontFacingStatuses, setFrontFacingStatuses] = useState<FrontFacingWellness[]>([]);
    const [sidebarTab, setSidebarTab] = useState<STATUS>(STATUS.PENDING);
    const [itemStatuses, setItemStatuses] = useState<Record<number, STATUS>>({});
    const [metricData, setMetricData] = useState<CoachMetricByType>();
    const [isLoadingMetrics, setIsLoadingMetrics] = useState<boolean>(true);
    const [isScoreError, setIsScoreError] = useState<boolean>(false);
    const [isMetricsError, setIsMetricsError] = useState<boolean>(false);
    const [alertElement, setAlert] = useAlert();
    const { t } = useTranslation();
    const { profile } = useProfile();
    const isMobile = useIsMobile();
    const { getDriver } = useDrivers();

    let licensePlate = '';
    if (vehicle !== undefined) {
        licensePlate = vehicle.licensePlate;
    } else if (props.licensePlate !== undefined) {
        licensePlate = props.licensePlate;
    }

    const sortedItems = [...events, ...inCabinStatuses, ...frontFacingStatuses].sort(
        (a, b) => b.timestamp - a.timestamp,
    );

    const itemsByStatus: Record<STATUS, (EventDetails | InCabinWellnessV1 | FrontFacingWellness)[]> = {
        [STATUS.PENDING]: sortedItems.filter((item) => itemStatuses[getItemId(item)] === STATUS.PENDING),
        [STATUS.SAVED]: sortedItems.filter((item) => itemStatuses[getItemId(item)] === STATUS.SAVED),
        [STATUS.DISMISSED]: sortedItems.filter((item) => itemStatuses[getItemId(item)] === STATUS.DISMISSED),
    };

    const loadData = () => {
        if (vehicle === undefined) {
            setIsLoadingItems(true);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getVehicleEvents(props.licensePlate!).then((vehicleRes) => {
                if (vehicleRes !== undefined) {
                    getEventStatuses(vehicleRes).then(() => {
                        setIsLoadingItems(false);
                    });
                }
            });
        } else {
            const promises = [getVehicleEvents(vehicle.licensePlate), getEventStatuses(vehicle)];
            Promise.all(promises).then(() => {
                setIsLoadingItems(false);
            });
        }
    };

    const getMetricData = (licensePlate: string) => {
        setIsLoadingMetrics(true);
        api.apiV0CoachMetricsGet({ licensePlate: licensePlate })
            .then((res) => {
                setMetricData(res.data);
            })
            .catch(() => setIsMetricsError(true))
            .finally(() => setIsLoadingMetrics(false));
    };

    const getVehicleEvents = (licensePlate: string) => {
        return api
            .apiV0WellnessScoresLicensePlateGet({ licensePlate: licensePlate })
            .then((res) => {
                const vehicleRes: WellnessCardProps = {
                    timestamp: res.data.timestamp,
                    driverName: res.data.driver_name,
                    licensePlate: res.data.license_plate,
                    subFleet: res.data.sub_fleet,
                    score: Math.round(res.data.score),
                    behaviors: res.data.event_types
                        .split(',')
                        .filter(Boolean)
                        .map((b) => behaviorsMap[b] || b),
                    eventIds: res.data.event_ids,
                    icStatusIds: res.data.in_cabin_wellness_ids,
                    ffStatusIds: res.data.front_facing_wellness_ids,
                    mostRecentEventTimestamp: res.data.most_recent_event_datetime,
                    mostRecentEventId: res.data.most_recent_event_id,
                    driver: res.data.driver,
                };
                setVehicle(vehicleRes);
                setEvents(res.data.events);
                setInCabinStatuses(res.data.in_cabin_wellness_statuses);
                setFrontFacingStatuses(res.data.front_facing_wellness_statuses);
                return vehicleRes;
            })
            .catch(() => setIsEventsError(true));
    };

    const getEventStatuses = (vehicle: WellnessCardProps) => {
        const allEventIds = [
            ...(vehicle.eventIds || []),
            ...(vehicle.icStatusIds || []),
            ...(vehicle.ffStatusIds || []),
        ];
        return api
            .apiV0CoachEventPost({ coachEventStatus: { event_id: allEventIds } })
            .then((res) => {
                const initialStatuses = Object.fromEntries(
                    allEventIds.map((eventId: number) => {
                        let status = STATUS.PENDING;
                        if (res.data[eventId]) {
                            // change API response on BE
                            const eventStatus = res.data[eventId] as Record<string, boolean>;
                            if (eventStatus.dismissed) {
                                status = STATUS.DISMISSED;
                            } else {
                                status = STATUS.SAVED;
                            }
                        }
                        return [eventId, status];
                    }),
                );
                setItemStatuses(initialStatuses);
            })
            .catch(() => setIsEventsError(true));
    };

    const getEventDetails = (controller?: AbortController) => {
        if (vehicle !== undefined && selectedItem !== undefined) {
            setLoadingDetails(true);
            api.apiV0EventEventIdGet({ eventId: selectedItem }, { signal: controller?.signal })
                .then((res: { data: EventDetails }) => {
                    const newDetails = res.data;
                    setDetails(newDetails);
                    setLoadingDetails(false);
                })
                .catch(() => null);
        }
    };

    const handleStatusChange = (
        itemDetails: EventDetails | InCabinWellnessV1 | FrontFacingWellness,
        status: STATUS,
    ) => {
        const currentTab = itemsByStatus[itemStatuses[getItemId(itemDetails)]];
        const eventIndex = currentTab.findIndex((e) => getItemId(e) === getItemId(itemDetails));

        let next = undefined;
        if (eventIndex < currentTab.length - 1) {
            next = currentTab[eventIndex + 1];
        } else if (currentTab.length > 1) {
            next = currentTab[eventIndex - 1];
        }

        if (next !== undefined) {
            setSelectedItem(getItemId(next));
        } else {
            setSidebarTab(status);
        }

        setItemStatuses((prev) => {
            return { ...prev, [getItemId(itemDetails)]: status };
        });
    };

    const getItemType = (item: EventDetails | InCabinWellnessV1 | FrontFacingWellness) => {
        if ('event_id' in item) {
            return 'event';
        } else if (inCabinStatuses.find((ic) => ic.id === item.id)) {
            return 'in_cabin_v1';
        } else {
            return 'front_facing';
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!!licensePlate) {
            getMetricData(licensePlate);
        }
    }, [licensePlate]);

    useEffect(() => {
        if (vehicle !== undefined) {
            const eventIds = vehicle.eventIds?.map((eventId) => `event:${eventId}`);
            const icStatusIds = vehicle.icStatusIds?.map((icStatusId) => `in_cabin_v1:${icStatusId}`);
            const ffStatusIds = vehicle.ffStatusIds?.map((ffStatusId) => `front_facing:${ffStatusId}`);
            const imageKeys = [...(eventIds || []), ...(icStatusIds || []), ...(ffStatusIds || [])];
            addToQueue(imageKeys);
        }
    }, [vehicle]);

    useEffect(() => {
        if (
            (events.length > 0 || inCabinStatuses.length > 0 || frontFacingStatuses.length > 0) &&
            Object.keys(itemStatuses).length > 0 &&
            details === undefined
        ) {
            if (itemsByStatus[STATUS.PENDING].length > 0) {
                setSelectedItem(getItemId(itemsByStatus[STATUS.PENDING][0]));
            } else if (itemsByStatus[STATUS.SAVED].length > 0) {
                setSelectedItem(getItemId(itemsByStatus[STATUS.SAVED][0]));
                setSidebarTab(STATUS.SAVED);
            } else {
                setSelectedItem(getItemId(itemsByStatus[STATUS.DISMISSED][0]));
                setSidebarTab(STATUS.DISMISSED);
            }
        }
    }, [events, inCabinStatuses, frontFacingStatuses, itemStatuses]);

    useEffect(() => {
        if (selectedItem !== undefined) {
            const item = sortedItems.find((i) => getItemId(i) === selectedItem);
            if (item !== undefined) {
                if (getItemType(item) === 'event') {
                    getEventDetails();
                } else {
                    setDetails(item);
                    setLoadingDetails(false);
                }
            }
        }
    }, [selectedItem]);

    const loadingSpinner = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'bgColor.main',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                opacity: 0.5,
                zIndex: 2000,
            }}
        >
            <CircularProgress role="status" />
        </Box>
    );

    let eventsTabContent;
    if (isLoadingItems) {
        eventsTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.5,
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (isEventsError) {
        eventsTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                    {t('content.safety.error_events')}
                </Typography>
                <Link
                    id="try-again-link"
                    component="button"
                    onClick={() => {
                        setIsEventsError(false);
                        setIsLoadingItems(true);
                        loadData();
                    }}
                >
                    <Typography variant="overline">{t('content.safety.try_again')}</Typography>
                </Link>
            </Box>
        );
    } else if (sortedItems.length === 0) {
        eventsTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                    {t('content.safety.no_events')}
                </Typography>
            </Box>
        );
    } else if (vehicle !== undefined) {
        eventsTabContent = (
            <Grid container columns={8} columnSpacing={1}>
                <Grid item xs={8} md={5} sx={{ position: 'relative' }}>
                    {loadingDetails ? loadingSpinner : null}
                    {details ? (
                        'event_id' in details ? (
                            <VehicleModalDetails
                                details={details}
                                setDetails={setDetails}
                                eventStatuses={itemStatuses}
                                handleStatusChange={handleStatusChange}
                                setSelectedEvent={setSelectedItem}
                                setAlert={setAlert}
                                accidentEventIds={[]}
                                getDriver={getDriver}
                            />
                        ) : (
                            <WellnessStatusDetails
                                status={details}
                                image={itemImages[`${getItemType(details)}:${details.id}`]}
                                handleStatusChange={handleStatusChange}
                                setAlert={setAlert}
                                itemType={getItemType(details)}
                                itemStatuses={itemStatuses}
                                setSelectedItem={setSelectedItem}
                                driver={vehicle.driver}
                            />
                        )
                    ) : null}
                </Grid>
                <Grid item xs={8} md={3}>
                    <VehicleModalSidebar
                        tab={sidebarTab}
                        setTab={setSidebarTab}
                        sortedEvents={[
                            itemsByStatus[STATUS.PENDING],
                            itemsByStatus[STATUS.SAVED],
                            itemsByStatus[STATUS.DISMISSED],
                        ]}
                        accidentEventIds={[]}
                        selectedEvent={!!details ? getItemId(details) : details}
                        setSelectedEvent={setSelectedItem}
                        eventImages={itemImages}
                        isLoadingEvents={isLoadingItems}
                        getItemType={getItemType}
                        listHeight={396}
                    />
                </Grid>
            </Grid>
        );
    }

    let scoresTabContent;
    if (isLoadingItems || isLoadingMetrics) {
        scoresTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.5,
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (isScoreError || isMetricsError) {
        scoresTabContent = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                    {t('content.safety.error_scores')}
                </Typography>
                <Link
                    id="try-again-link"
                    component="button"
                    onClick={() => {
                        if (isScoreError) {
                            setIsScoreError(false);
                            loadData();
                        }
                        if (isMetricsError) {
                            setIsMetricsError(false);
                            getMetricData(licensePlate);
                        }
                    }}
                >
                    <Typography variant="overline">{t('content.safety.try_again')}</Typography>
                </Link>
            </Box>
        );
    } else if (vehicle !== undefined && metricData !== undefined) {
        scoresTabContent = (
            <ScoresTab scoreType="wellness" vehicle={vehicle} metricData={metricData} setAlert={setAlert} />
        );
    }

    let dateRange;
    if (vehicle !== undefined) {
        const endDate = dayjs.unix(vehicle.timestamp);
        dateRange = `${endDate.subtract(14, 'day').format(profile.shortDateFormat)}–${endDate.format(
            profile.shortDateFormat,
        )}`;
    }

    return (
        <Dialog
            id="wellness-dialog"
            open={true}
            fullWidth={true}
            maxWidth="md"
            onClose={() => props.onClose()}
            PaperProps={{ sx: { backgroundColor: 'bgColor.main' } }}
            sx={{ '& .MuiDialog-scrollPaper': { alignItems: 'start' } }}
        >
            {alertElement}
            <Box>
                <VehicleModalHeader vehicle={vehicle} onClose={props.onClose} />
                <Box
                    sx={{
                        p: 1,
                        pt: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${palette.neutral[150]}`,
                            minHeight: 0,
                        }}
                    >
                        <Tabs
                            value={tab}
                            onChange={(_e, newValue) => setTab(newValue)}
                            TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                        >
                            <Tab
                                label={
                                    <Typography fontSize={14}>{`${t('content.wellness.wellness_events_tab')}${
                                        !!dateRange ? ` (${dateRange})` : ''
                                    }`}</Typography>
                                }
                                {...a11yProps(0)}
                                id="tab-wellness-events"
                                data-testid={`tab-wellness-events`}
                            />
                            <Tab
                                label={<Typography fontSize={14}>{t('content.wellness.scores_tab')}</Typography>}
                                {...a11yProps(1)}
                                id="tab-scores"
                                data-testid={`tab-scores`}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={tab} index={0} sx={{ height: isMobile ? undefined : '500px', minHeight: 0 }}>
                        {eventsTabContent}
                    </TabPanel>
                    <TabPanel value={tab} index={1} sx={{ height: isMobile ? undefined : '350px', minHeight: 0 }}>
                        {scoresTabContent}
                    </TabPanel>
                </Box>
            </Box>
        </Dialog>
    );
};

export default WellnessModal;
