export const MILES_TO_KM_FACTOR = 1.609;
const UNKNOWN_LOCATION = [undefined, 'unknown', 'N/A', '', 'NaN'];

export const isLocationValid = (locationString?: string): boolean => {
    if (UNKNOWN_LOCATION.includes(locationString)) {
        return false;
    }
    const [lat, lng] = parseLocation(locationString);
    const isLatValid = lat >= -90 && lat <= 90;
    const isLngValid = lng >= -180 && lng <= 180;
    return isLatValid && isLngValid;
};

export const parseLocation = (location?: string): [number, number] => {
    if (location != undefined) {
        const [lat, lng] = location.split(':').map(parseFloat);
        return [lat, lng];
    }
    return [0, 0];
};
