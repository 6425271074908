import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import palette from '../ColorPalette';

const RE_DIGIT = new RegExp(/^\d+$/);

const OTPContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 360px;
    column-gap: 10px;
`;

const OTPInputElement = styled.input`
    width: 100%;
    height: 50px;
    border: 0;
    outline: 0;
    border-bottom: 3px solid ${palette.neutral[200]};
    padding: 0;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    transition: border-color 0.15s;

    &: focus-visible {
        border-bottom: 3px solid ${palette.secondary};
    }
`;

interface OTPInputProps {
    value: string;
    valueLength: number;
    onChange: (value: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ value, valueLength, onChange }: OTPInputProps) => {
    const firstInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (value.length === 0) {
            firstInputRef.current?.focus();
        }
    }, [value]);

    const valueItems = useMemo(() => {
        const valueArray = value.split('');
        const items: Array<string> = [];

        for (let i = 0; i < valueLength; i++) {
            const char = valueArray[i];
            if (RE_DIGIT.test(char)) {
                items.push(char);
            } else {
                items.push('');
            }
        }

        return items;
    }, [value, valueLength]);

    const focusToNextInput = (target: HTMLElement) => {
        const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
        if (nextElementSibling) {
            nextElementSibling.focus();
        }
    };

    const focusToPrevInput = (target: HTMLElement) => {
        const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;
        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const target = e.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

        // only delete digit if next input element has no value
        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';
        const targetValueLength = targetValue.length;
        if (targetValueLength === 1) {
            const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

            onChange(newValue);
            if (!isTargetValueDigit) {
                return;
            }

            focusToNextInput(target);
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);

            target.blur();
        }

        if (idx === valueLength - 1) {
            target.blur();
        }
    };
    const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        const target = e.target as HTMLInputElement;

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault();
            return focusToNextInput(target);
        }

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault();
            return focusToPrevInput(target);
        }

        const targetValue = target.value;

        // keep the selection range position
        // if the same digit was typed
        target.setSelectionRange(0, targetValue.length);

        if (e.key !== 'Backspace' || targetValue !== '') {
            return;
        }

        focusToPrevInput(target);
    };

    const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const { target } = e;

        // keep focusing back until previous input
        // element has value
        const prevInputEl = target.previousElementSibling as HTMLInputElement | null;

        if (prevInputEl && prevInputEl.value === '') {
            return prevInputEl.focus();
        }

        target.setSelectionRange(0, target.value.length);
    };

    return (
        <OTPContainer data-testid="login-code" style={{ direction: 'ltr' }}>
            {valueItems.map((digit, idx) => (
                <OTPInputElement
                    key={idx}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    pattern="\d{1}"
                    maxLength={valueLength}
                    value={digit}
                    onChange={(e) => inputOnChange(e, idx)}
                    onKeyDown={inputOnKeyDown}
                    onFocus={inputOnFocus}
                    ref={idx === 0 ? firstInputRef : undefined}
                />
            ))}
        </OTPContainer>
    );
};

export default OTPInput;
