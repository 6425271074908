import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import { Autocomplete, Box, Card, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EventDetails, ExtendedCustomerDataAllOfUsers } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import { ExtendedFleetDriver } from '../../../hooks/drivers';
import { eventsPageDefs, flattenRoles } from '../../../utils/Pages';
import { getUserAvatar } from '../Coaching/SessionDetails';

interface SendEventModalProps {
    details: EventDetails;
    onClose: CallableFunction;
    users: ExtendedCustomerDataAllOfUsers[];
    setAlert: CallableFunction;
    driver?: ExtendedFleetDriver;
}
const SendEventModal: React.FC<SendEventModalProps> = (props: SendEventModalProps) => {
    const [recipients, setRecipients] = React.useState<ExtendedCustomerDataAllOfUsers[]>([]);
    const [message, setMessage] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { api } = useApi();
    const { t } = useTranslation();

    const handleSend = () => {
        setIsLoading(true);
        api.apiV0EventEventIdSendPost({
            eventId: props.details.event_id,
            sendEventRequest: { recipients: recipients.map((u) => u.user_id), message },
        })
            .then(() => {
                props.setAlert({ message: t('content.events.send_event_success'), type: 'success', duration: 6000 });
                props.onClose();
            })
            .catch(() => {
                props.setAlert({ message: t('content.events.send_event_error'), type: 'error', duration: 6000 });
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Dialog
            id="send-event-dialog"
            open={true}
            maxWidth="xs"
            fullWidth
            onClose={(_event, reason) => {
                if (reason !== 'backdropClick' || recipients.length === 0) {
                    props.onClose();
                }
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                    minHeight: 0,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <EmailIcon sx={{ mr: 0.5 }} />
                    <Typography>{t('content.events.send_event')}</Typography>
                </Box>
            </Box>
            <Box sx={{ p: 1 }}>
                <Card sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                    <Autocomplete
                        id="recipients-field"
                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size="small"
                        options={props.users.filter(
                            (u) =>
                                flattenRoles(eventsPageDefs).includes(u.role) &&
                                (!props.driver?.sub_fleet || !u.sub_fleet || props.driver?.sub_fleet === u.sub_fleet),
                        )}
                        value={recipients}
                        onChange={(_event, newValue) => setRecipients(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth label={t('content.events.recipients')} />
                        )}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => {
                            return (
                                <MenuItem id={`user-${option.user_id}-menu-item`} {...props} key={option.user_id}>
                                    {getUserAvatar(option)}
                                    <Typography variant="body2">{option.name}</Typography>
                                </MenuItem>
                            );
                        }}
                        sx={{ mb: 1.5 }}
                    />
                    <TextField
                        id="message-field"
                        label={t('content.events.message')}
                        multiline
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows={4}
                        variant="outlined"
                    />
                </Card>
                <Box sx={{ mt: 1, width: '100%', display: 'flex' }}>
                    <Button
                        id="btn-cancel"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => props.onClose()}
                        sx={{ mr: 1 }}
                    >
                        {t('content.events.cancel')}
                    </Button>
                    <Button
                        id="btn-send"
                        variant="contained"
                        color="secondary"
                        disabled={recipients.length === 0 || isLoading}
                        onClick={() => handleSend()}
                        fullWidth
                    >
                        {t('content.events.send')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default SendEventModal;
