import React, { useContext, useState } from 'react';

import { UserProfile, UserRole } from '../backendsdk';
import {
    dataGridDateFormatIL,
    dataGridDateFormatUS,
    dataGridDateTimeFormatIL,
    dataGridDateTimeFormatUS,
    dataGridTimeFormatIL,
    dataGridTimeFormatUS,
    dateFormatIL,
    dateFormatUS,
    dateTimeFormatIL,
    dateTimeFormatUS,
    shortDateFormatIL,
    shortDateFormatUS,
    timeFormatIL,
    timeFormatUS,
} from '../utils/TimeFormatter';

export interface ExtendedProfile extends UserProfile {
    dateFormat: string;
    shortDateFormat: string;
    timeFormat: string;
    dateTimeFormat: string;
    dataGridDateFormat: string;
    dataGridTimeFormat: string;
    dataGridDateTimeFormat: string;
}

export const EMPTY_PROFILE: ExtendedProfile = {
    user_id: 0,
    username: '',
    email: '',
    admin: false,
    customer: {
        name: '',
        display_name: '',
        sub_fleets: [],
        settings: {
            default_currency: '',
            default_locale: 'us',
            use_imperial_units: false,
            inactive_device_threshold: 3,
            country: 'us',
            use_auto_logout: false,
            auto_logout_after_minutes: null,
            driving_type: 'property',
            use_qr_identification: false,
            use_speeding_policy: false,
            speeding_policy_threshold: null,
            otp_enforced: false,
            default_lat: 0,
            default_lng: 0,
            deactivate_users_after_days: null,
            lock_user_after_failed_attempts: null,
            default_malfunction_assignee: null,
        },
        contact_mail: '',
    },
    roles: [],
    role: UserRole.Driver,
    name: '',
    sub_fleet: '',
    language: '',
    timezone: '',
    locale: '',
    home_page: '',
    notify_upcoming_features: false,
    notify_mentions: false,
    notify_accident_update: false,
    accident_notification_cooldown_minutes: 30,
    notify_coach_assignment: false,
    notify_pending_session: false,
    notify_pending_session_after_days: 3,
    notify_coach_completed: false,
    notify_malfunction_assignment: false,
    notify_malfunction_comment: false,
    notify_malfunction_resolved: false,
    notify_feed_event_comment: false,
    phone_number: '',
    dateFormat: dateFormatUS,
    shortDateFormat: shortDateFormatUS,
    timeFormat: timeFormatUS,
    dateTimeFormat: dateTimeFormatUS,
    dataGridDateFormat: dataGridDateFormatUS,
    dataGridTimeFormat: dataGridTimeFormatUS,
    dataGridDateTimeFormat: dataGridDateTimeFormatUS,
};

interface ProfileContextIfc {
    profile: ExtendedProfile;
    setProfile: CallableFunction;
    resetProfile: CallableFunction;
}

export const ProfileContext = React.createContext<ProfileContextIfc>({
    profile: EMPTY_PROFILE,
    setProfile: () => null,
    resetProfile: () => null,
});

interface ProfileProviderProps {
    value?: UserProfile;
    children: React.ReactNode;
}

export const ProfileProvider: React.FC<ProfileProviderProps> = (props: ProfileProviderProps) => {
    const [profile, setProfile] = useState<UserProfile>(props.value ? props.value : EMPTY_PROFILE);

    let dateFormat = dateFormatUS;
    let shortDateFormat = shortDateFormatUS;
    let timeFormat = timeFormatUS;
    let dateTimeFormat = dateTimeFormatUS;
    let dataGridDateFormat = dataGridDateFormatUS;
    let dataGridTimeFormat = dataGridTimeFormatUS;
    let dataGridDateTimeFormat = dataGridDateTimeFormatUS;

    if (profile.locale === 'il') {
        dateFormat = dateFormatIL;
        shortDateFormat = shortDateFormatIL;
        timeFormat = timeFormatIL;
        dateTimeFormat = dateTimeFormatIL;
        dataGridDateFormat = dataGridDateFormatIL;
        dataGridTimeFormat = dataGridTimeFormatIL;
        dataGridDateTimeFormat = dataGridDateTimeFormatIL;
    }

    const resetProfile = () => {
        setProfile(EMPTY_PROFILE);
    };

    return (
        <ProfileContext.Provider
            value={{
                profile: {
                    ...profile,
                    dateFormat,
                    shortDateFormat,
                    timeFormat,
                    dateTimeFormat,
                    dataGridDateFormat,
                    dataGridTimeFormat,
                    dataGridDateTimeFormat,
                },
                setProfile,
                resetProfile,
            }}
        >
            {props.children}
        </ProfileContext.Provider>
    );
};

const useProfile = () => useContext(ProfileContext);
export default useProfile;
