import { Box, LinearProgress, Typography, linearProgressClasses, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedDialog as Dialog } from '../../../components/TrackedComponents';

interface ProgressModalProps {
    numberOfFrames: number;
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 8,
    borderRadius: 4,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 4,
    },
}));

// The following were calculated using a linear regression on benchmark data
const FACTOR = 0.08017;
const OVERHEAD = 4.83;

const ProgressModal: React.FC<ProgressModalProps> = ({ numberOfFrames }) => {
    const timeToGenerateSeconds = FACTOR * numberOfFrames + OVERHEAD;
    const [progress, setProgress] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prev + 10 / timeToGenerateSeconds;
            });
        }, 100);

        return () => clearInterval(interval);
    }, [timeToGenerateSeconds]);

    return (
        <Dialog id="progress-dialog" open={true} onClose={() => null} fullWidth maxWidth="xs">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 3 }}>
                <Typography sx={{ mb: 0.5 }}>{`${t('content.find_video.generating_preview')}...`}</Typography>
                <BorderLinearProgress variant="determinate" value={progress} color="secondary" sx={{ width: '100%' }} />
            </Box>
        </Dialog>
    );
};

export default ProgressModal;
