import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Artifact, ExtendedAccident } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import { SAFETY_MANAGER_DELETE_FILES_ROLE } from '../../Layout';
import AccidentArtifactActionComponent from './AccidentArtifactsAction';
import AccidentDamageDisposalModal from './AccidentDamageDisposalModal';
import AccidentNoticeFormComponent from './AccidentNoticeFormModal';
import AccidentUpdateFile from './AccidentUpdateFile';
import { MuiTextField } from './MuiFormControls';

const FILE_ROW_BACKGROUNDS = ['inherit', palette.neutral[100]];

interface NewAccidentArtifactComponentProps {
    accident_id: number;
    onSave: CallableFunction;
    files: Array<File> | null;
    setFiles: CallableFunction;
    customerTags: Array<string>;
    setCustomerTags: CallableFunction;
    open: boolean;
    setOpen: CallableFunction;
    setAlert: CallableFunction;
}

interface AccidentArtifactComponentProps {
    accident_id: number;
    onSave: CallableFunction;
    files: Array<File> | null;
    customerTags: Array<string>;
    setCustomerTags: CallableFunction;
    artifact_list: Array<Artifact>;
    searchText: string;
    setSearchText: CallableFunction;
    setAlert: CallableFunction;
}

export const NewAccidentArtifactComponent: React.FC<NewAccidentArtifactComponentProps> = (
    props: NewAccidentArtifactComponentProps,
) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.setOpen(!!props.files);
    }, [props.files]);

    return (
        <Dialog id="new-accident-artifact-dialog" open={props.open} onClose={() => props.setOpen(false)}>
            <AccidentUpdateFile {...props} handleFileUpdate={props.onSave}>
                <Button
                    id="close-form-btn"
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        props.setOpen(false);
                    }}
                    sx={{ ml: 1 }}
                >
                    {t('content.accidents.files.close')}
                </Button>
            </AccidentUpdateFile>
        </Dialog>
    );
};

export const ExistingAccidentArtifactComponent: React.FC<AccidentArtifactComponentProps> = (
    props: AccidentArtifactComponentProps,
) => {
    const [action, setAction] = useState<[Artifact | undefined, string]>([undefined, '']);
    const { profile } = useProfile();
    const canDeleteFiles = profile.admin || profile.roles.includes(SAFETY_MANAGER_DELETE_FILES_ROLE);
    const { t } = useTranslation();
    const { agencyApi } = useApi();

    const artifacts = props.artifact_list.filter((artifact) => artifact.file_name.includes(props.searchText));

    if (artifacts.length === 0) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography variant="caption">{t('content.accidents.files.no_results')}</Typography>
                <Link
                    id="clear-search"
                    component="button"
                    variant="caption"
                    onClick={() => {
                        props.setSearchText('');
                    }}
                >
                    {t('content.accidents.files.clear_search')}
                </Link>
            </Box>
        );
    }

    return (
        <List sx={{ p: 0 }}>
            {artifacts
                .sort((a, b) => b.artifact_id - a.artifact_id)
                .map((artifact, idx: number) => (
                    <ListItem
                        disableGutters
                        alignItems="center"
                        key={artifact.artifact_id}
                        sx={{ backgroundColor: FILE_ROW_BACKGROUNDS[idx % 2], py: 0.5 }}
                    >
                        <InsertDriveFileIcon fontSize="small" sx={{ mx: 1 }} />
                        <ListItemText sx={{ lineHeight: 1 }}>
                            <Typography variant="body2" sx={{ lineHeight: 1 }}>
                                {`${t(
                                    `content.accidents.files.${artifact.tag.toLocaleLowerCase().replaceAll(' ', '_')}`,
                                    artifact.tag,
                                )}: `}
                                <Link
                                    id={`artifact-${artifact.artifact_id}-open`}
                                    title={artifact.file_name}
                                    onClick={() => {
                                        agencyApi
                                            .agencyV1AccidentAccidentIdArtifactArtifactIdGet({
                                                accidentId: props.accident_id,
                                                artifactId: artifact.artifact_id,
                                            })
                                            .then((response) => {
                                                const newWindow = window.open(
                                                    response.data,
                                                    '_blank',
                                                    'noopener,noreferrer',
                                                );
                                                if (newWindow) {
                                                    newWindow.opener = null;
                                                }
                                            });
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {artifact.file_name.slice(0, 40)}
                                    {artifact.file_name.length > 40 ? '...' : ''}
                                </Link>
                            </Typography>
                            <Typography variant="caption" color="gray">
                                {dayjs.unix(artifact.created_at).format(profile.dateTimeFormat)}
                            </Typography>
                        </ListItemText>
                        <ListItemIcon
                            sx={{
                                minWidth: '30px',
                                m: 0,
                                p: 0,
                            }}
                        >
                            <Tooltip title={t('content.accidents.files.send')}>
                                <IconButton
                                    id={`${artifact.artifact_id}-mail`}
                                    size="small"
                                    onClick={() => setAction([artifact, 'mail'])}
                                >
                                    <EmailIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemIcon
                            sx={{
                                minWidth: '30px',
                                m: 0,
                                p: 0,
                            }}
                        >
                            <Tooltip title={t('content.accidents.files.edit')}>
                                <IconButton
                                    id={`${artifact.artifact_id}-edit`}
                                    size="small"
                                    onClick={() => setAction([artifact, 'edit'])}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ListItemIcon>
                        {canDeleteFiles ? (
                            <ListItemIcon
                                sx={{
                                    minWidth: '30px',
                                    m: 0,
                                    p: 0,
                                }}
                            >
                                <Tooltip title={t('content.accidents.files.delete')}>
                                    <IconButton
                                        id={`${artifact.artifact_id}-delete`}
                                        size="small"
                                        onClick={() => setAction([artifact, 'delete'])}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </ListItemIcon>
                        ) : null}
                    </ListItem>
                ))}
            {action[0] != undefined ? (
                <Dialog id="artifact-action-dialog" open={true} onClose={() => setAction([undefined, ''])}>
                    <AccidentArtifactActionComponent
                        {...props}
                        artifact={action[0]}
                        action={action[1]}
                        handleClick={(refresh = true) => {
                            setAction([undefined, '']);
                            if (refresh) {
                                props.onSave();
                            }
                        }}
                    />
                </Dialog>
            ) : null}
        </List>
    );
};

interface BaseAccidentArtifactComponentProps {
    details: ExtendedAccident;
    files: Array<File> | null;
    onSave: CallableFunction;
    handleChange: CallableFunction;
    setFiles: CallableFunction;
    setAlert: CallableFunction;
}

const AccidentArtifactComponent: React.FC<BaseAccidentArtifactComponentProps> = (
    props: BaseAccidentArtifactComponentProps,
) => {
    const [customerTags, setCustomerTags] = useState<Array<string>>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [showDisposalLetterModal, setShowDisposalLetterModal] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const { details, files } = props;
    const { t } = useTranslation();
    const { agencyApi } = useApi();
    const disabled = props.details.accident_id === 0;

    useEffect(() => {
        agencyApi.agencyV1AccidentArtifactTagsGet().then((response) => {
            setCustomerTags(response.data);
        });
    }, []);

    useEffect(() => {
        if (!showSearch) {
            setSearchText('');
        }
    }, [showSearch]);

    const disposalLetterButton = (
        <Button
            id="disposal-btn"
            variant="contained"
            size="small"
            disabled={disabled || props.details.damage_list.length === 0}
            onClick={() => setShowDisposalLetterModal(true)}
            sx={{ width: '100%' }}
        >
            {t('content.accidents.damage.disposal_letter.create')}
        </Button>
    );

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <Typography variant="overline">{t('content.accidents.files.header')}</Typography>
                </Box>
                {details.artifact_list.length > 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mr: 1, height: 34 }}>
                        {showSearch ? (
                            <Box>
                                <MuiTextField
                                    id="search-artifact"
                                    name="search-artifact"
                                    fullWidth={false}
                                    value={searchText}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                                    sx={{ mr: 1 }}
                                />
                            </Box>
                        ) : null}
                        <Tooltip title={t(`content.accidents.files.${showSearch ? 'close_search' : 'open_search'}`)}>
                            <IconButton id="btn-search" size="small" onClick={() => setShowSearch((prev) => !prev)}>
                                {showSearch ? <SearchOffIcon /> : <SearchIcon />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : null}
            </Box>
            {details.artifact_list.length > 0 ? (
                <Box sx={{ maxHeight: '10rem', overflowY: 'scroll', p: 1 }}>
                    <ExistingAccidentArtifactComponent
                        accident_id={details.accident_id}
                        artifact_list={details.artifact_list}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        onSave={props.onSave}
                        files={null}
                        customerTags={customerTags}
                        setCustomerTags={setCustomerTags}
                        setAlert={props.setAlert}
                    />
                </Box>
            ) : (
                <Box sx={{ px: 1 }}>
                    <Typography variant="caption">{t('content.accidents.files.no_files')}</Typography>
                </Box>
            )}
            <NewAccidentArtifactComponent
                accident_id={details.accident_id}
                onSave={props.onSave}
                files={files}
                setFiles={props.setFiles}
                customerTags={customerTags}
                setCustomerTags={setCustomerTags}
                open={open}
                setOpen={setOpen}
                setAlert={props.setAlert}
            />
            <Box sx={{ p: 1 }}>
                <Grid container columns={3} columnSpacing={1}>
                    <Grid item xs={1}>
                        <Button
                            id="add-file-btn"
                            variant="contained"
                            size="small"
                            disabled={disabled}
                            onClick={() => setOpen(true)}
                            sx={{ width: '100%' }}
                        >
                            {t('content.accidents.files.add')}
                        </Button>
                    </Grid>
                    <AccidentNoticeFormComponent {...props} />
                    <Grid item xs={1}>
                        {props.details.damage_list.length === 0 && !disabled ? (
                            <Tooltip title={t('content.accidents.damage.disposal_letter.third_party_required')}>
                                <Box>{disposalLetterButton}</Box>
                            </Tooltip>
                        ) : (
                            disposalLetterButton
                        )}
                    </Grid>
                </Grid>
            </Box>
            {props.details.damage_list.length > 0 ? (
                <AccidentDamageDisposalModal
                    {...props}
                    details={props.details}
                    show={showDisposalLetterModal}
                    onClose={() => setShowDisposalLetterModal(false)}
                />
            ) : null}
        </>
    );
};

export default AccidentArtifactComponent;
