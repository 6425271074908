import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button,
    FormControlLabel,
    Stack,
    Switch,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    FilterColumnsArgs,
    GetColumnForNewFilterArgs,
    GridColDef,
    GridCsvExportMenuItem,
    GridCsvGetRowsToExportParams,
    GridLocaleText,
    GridLogicOperator,
    GridRenderCellParams,
    GridRowParams,
    GridRowSelectionModel,
    GridToolbarExportContainer,
    GridToolbarFilterButton,
    GridValueFormatterParams,
    getGridSingleSelectOperators,
    getGridStringOperators,
    gridExpandedSortedRowIdsSelector,
    heIL,
} from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceV3 } from '../../../backendsdk';
import {
    TrackedIconButton as IconButton,
    TrackedDataGrid as StyledDataGrid,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useDevices from '../../../hooks/devices';
import useProfile from '../../../hooks/profile';
import { fleetOverviewPageDefs } from '../../../utils/Pages';
import { normalizeLicensePlate } from '../../../utils/Str';
import { MILLISECONDS_IN_SECOND, formatTime } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { RTLDirectionContext } from '../../Layout';
import WellnessBox, { WELLNESS_TYPE_MAP } from '../Device/WellnessBox';
import WellnessContainer from '../Device/WellnessContainer';
import { getFFWellnessDetails, getICWellnessDetails } from '../Overview/utils';
import { OE_ROLES } from '../Users/UsersComponent';

export const gridLocalization: Record<string, Partial<GridLocaleText>> = {
    he: heIL.components.MuiDataGrid.defaultProps.localeText,
};

export const filterFields: Record<string, { columnField: string; operatorValue: string }> = {
    driver_name_contains: {
        columnField: 'driver_name',
        operatorValue: 'contains',
    },
    device_id_contains: {
        columnField: 'device_id',
        operatorValue: 'contains',
    },
    license_plate: {
        columnField: 'license_plate',
        operatorValue: 'equals',
    },
    license_plate_contains: {
        columnField: 'license_plate',
        operatorValue: 'contains',
    },
    sub_fleet: {
        columnField: 'sub_fleet',
        operatorValue: 'is',
    },
    version_name: {
        columnField: 'version_name',
        operatorValue: 'is',
    },
};

const getColumnOperators = (columnName: string) => {
    return Object.values(filterFields)
        .filter((value) => value.columnField === columnName)
        .map((value) => value.operatorValue);
};

const renderStatusCell = (params: GridRenderCellParams, inactive_threshold: number) => {
    const device: DeviceV3 = params.row.device;
    const icWellnessDetails = getICWellnessDetails(device, inactive_threshold);
    const ffWellnessDetails = getFFWellnessDetails(device, inactive_threshold);
    const obdWellnessDetails = device.aggregated_wellness?.obd_state?.obd_state;

    return (
        <Stack spacing={0.5} sx={{ alignItems: 'start', alignSelf: 'start' }}>
            <WellnessContainer
                device={device}
                wellnessId={device.aggregated_wellness?.in_cabin_wellness?.id}
                timestamp={device.aggregated_wellness?.in_cabin_wellness?.timestamp}
                wellnessType={'in_cabin'}
                wellnessDetails={icWellnessDetails}
            />
            <WellnessContainer
                device={device}
                wellnessId={device.aggregated_wellness?.front_facing_wellness?.id}
                timestamp={device.aggregated_wellness?.front_facing_wellness?.timestamp}
                wellnessType={'front_facing'}
                wellnessDetails={ffWellnessDetails}
            />
            {obdWellnessDetails && obdWellnessDetails !== 'none' ? (
                <WellnessBox wellnessType={'obd'} wellnessDetails={obdWellnessDetails} />
            ) : null}
        </Stack>
    );
};

export const filterColumns = ({ field, columns, currentFilters }: FilterColumnsArgs) => {
    // remove already filtered fields from list of columns
    const filteredFields = currentFilters?.map((item) => item.field);
    return columns
        .filter((colDef) => colDef.filterable && (colDef.field === field || !filteredFields.includes(colDef.field)))
        .map((column) => column.field);
};

export const getColumnForNewFilter = ({ currentFilters, columns }: GetColumnForNewFilterArgs) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
        .filter((colDef) => colDef.filterable && !filteredFields.includes(colDef.field))
        .find((colDef) => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
};

interface QuickSearchToolbarProps {
    value: string;
    onChange: () => void;
    useCache: boolean;
    setUseCache: CallableFunction;
    isAdmin: boolean;
    children?: React.ReactNode;
    selectedIMEI?: string;
    setAlert: CallableFunction;
}

const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
    const [showRefresh, setShowRefresh] = useState<boolean>(false);
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const ALLOW_EXPORT = [...fleetOverviewPageDefs.permissions.L1];
    const { api } = useApi();

    const createCommand = (imei: string) => {
        api.apiV0CommandCommandIdPost({ commandId: 0, remoteCommand: { device_id: imei, command: 'get_internal_db' } })
            .then((res) => {
                props.setAlert({
                    message: t('content.fleet.command_success', { command: res.data.id }),
                    type: 'success',
                    duration: 6000,
                });
            })
            .catch(() => {
                props.setAlert({ message: t('content.fleet.command_error'), type: 'error', duration: 6000 });
            });
    };

    const getAllRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridExpandedSortedRowIdsSelector(apiRef);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column-reverse' : 'row',
                alignItems: 'end',
                pb: 1,
                overflow: 'none',
                backgroundColor: palette.bgColor,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <TextField
                    fullWidth={isMobile}
                    id="table-search-field"
                    variant="standard"
                    value={props.value}
                    onChange={props.onChange}
                    size="small"
                    placeholder={t('table.search')}
                    InputProps={{
                        startAdornment: <SearchIcon color="primary" fontSize="small" sx={{ mb: 0.5 }} />,
                    }}
                    sx={{ mr: 1 }}
                />
                <Box sx={{ flexShrink: 0, display: 'flex' }}>
                    <GridToolbarFilterButton componentsProps={{ button: { variant: 'outlined' } }} />
                    {ALLOW_EXPORT ? (
                        <GridToolbarExportContainer variant="outlined" sx={{ height: '32px', ml: 1 }}>
                            <GridCsvExportMenuItem options={{ getRowsToExport: getAllRows, utf8WithBom: true }} />
                        </GridToolbarExportContainer>
                    ) : null}
                </Box>
                {props.children}
                <Button
                    variant="outlined"
                    size="small"
                    disabled={!props.selectedIMEI}
                    onClick={() => {
                        if (!!props.selectedIMEI) {
                            createCommand(props.selectedIMEI);
                        }
                    }}
                    sx={{ height: '32px', ml: 1 }}
                >
                    Get Internal DB
                </Button>
                {props.isAdmin ? (
                    <>
                        <FormControlLabel
                            control={
                                <Switch
                                    id="use-cache-switch"
                                    data-testid="use-cache-switch"
                                    checked={props.useCache}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        localStorage.setItem('OE-cache', e.target.checked ? 'true' : 'false');
                                        props.setUseCache(e.target.checked);
                                        setShowRefresh(true);
                                    }}
                                    size="small"
                                />
                            }
                            label={
                                <Typography sx={{ fontSize: 14, mt: 0.25 }}>{t('content.fleet.use_cache')}</Typography>
                            }
                            sx={{ ml: 0.5, mr: 0 }}
                        />
                        {showRefresh ? (
                            <IconButton
                                id="btn-refresh"
                                size="small"
                                onClick={() => location.reload()}
                                sx={{ ml: 0.5 }}
                            >
                                <RefreshIcon fontSize="small" />
                            </IconButton>
                        ) : null}
                    </>
                ) : null}
            </Box>
        </Box>
    );
};

interface MuiDeviceTableProps {
    singleSelection?: boolean;
    selectedDevice?: DeviceV3;
    setSelectedDevice: CallableFunction;
    selectionModel?: GridRowSelectionModel;
    setSelectionModel: CallableFunction;
    toolbar?: React.ReactNode;
    refresh?: number;
    disableRefresh?: boolean;
    setAlert: CallableFunction;
}

const MuiDeviceTable: React.FC<MuiDeviceTableProps> = (props: MuiDeviceTableProps) => {
    const [searchText, setSearchText] = useState<string>('');
    const [now, setNow] = useState<Date>(new Date());
    const { profile } = useProfile();
    const { i18n, t } = useTranslation();
    const isRTL = useContext(RTLDirectionContext);
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const isAdmin = location.host.startsWith('dashboard-test') && OE_ROLES.includes(profile.role);
    const [useCache, setUseCache] = useState<boolean>(isAdmin ? localStorage.getItem('OE-cache') === 'true' : true);
    const { devices, isLoading, updateDevices } = useDevices(useCache ? 1 : 0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateDevices();
            setNow(new Date());
        }, 10 * MILLISECONDS_IN_SECOND);
        return () => clearInterval(intervalId);
    }, [updateDevices]);

    const getTimeString = (value?: number) =>
        !!value ? t('time.ago', { time: formatTime(value, t, true) }) : t('content.fleet.unknown');

    const ALLOW_VIEW_ALL_COLUMNS = [...fleetOverviewPageDefs.permissions.L1];

    const columns = useMemo(() => {
        const sinsleSelectFilter = (fieldName: string) => ({
            ...getGridSingleSelectOperators().filter((operator) =>
                getColumnOperators(fieldName).includes(operator.value),
            )[0],
            label: t('table.is_female'),
        });

        let gridColumns: GridColDef[] = [
            {
                field: 'driver_name',
                headerName: t('table.headers.driver_name'),
                flex: 1,
                filterOperators: getGridStringOperators().filter((operator) =>
                    getColumnOperators('driver_name').includes(operator.value),
                ),
                editable: props.singleSelection,
            },
            {
                field: 'device_id',
                headerName: t('table.headers.imei'),
                flex: 1,
                filterOperators: getGridStringOperators().filter((operator) =>
                    getColumnOperators('device_id').includes(operator.value),
                ),
            },
            {
                field: 'license_plate',
                headerName: t('table.headers.license_plate'),
                flex: 1,
                filterOperators: getGridStringOperators()
                    .filter((operator) => getColumnOperators('license_plate').includes(operator.value))
                    .map((operator) => ({
                        ...operator,
                        getApplyFilterFn: (filterItem) => {
                            const filterValue = normalizeLicensePlate(filterItem.value);

                            return ({ value }) => {
                                if (!value) {
                                    return false;
                                }

                                const cellValue = normalizeLicensePlate(value);
                                return operator.value.toLocaleLowerCase() === 'contains'
                                    ? cellValue.includes(filterValue)
                                    : cellValue === filterValue;
                            };
                        },
                    })),
                renderCell: (params: GridRenderCellParams) => params.value,
                valueFormatter: (params: GridValueFormatterParams) => normalizeLicensePlate(params.value),
            },
            {
                field: 'sub_fleet',
                type: 'singleSelect',
                headerName: t('table.headers.sub_fleet'),
                flex: 1,
                valueOptions: [
                    ...new Set(devices.map((device) => device.device.customer_sub_fleet).filter(Boolean)),
                ].map((value) => ({ value, label: value })),
                filterOperators: [sinsleSelectFilter('sub_fleet')],
            },
            {
                field: 'status',
                type: 'singleSelect',
                valueOptions: Object.entries(WELLNESS_TYPE_MAP).map(([value, label]) => ({ value, label: t(label) })),
                headerName: t('table.headers.status'),
                sortable: false,
                minWidth: 250,
                flex: 1,
                valueFormatter: (params: GridValueFormatterParams) =>
                    params.value
                        .map((status: string) => t(WELLNESS_TYPE_MAP[status as keyof typeof WELLNESS_TYPE_MAP]))
                        .filter(Boolean)
                        .join(', '),
                renderCell: (params: GridRenderCellParams) =>
                    renderStatusCell(params, profile.customer.settings.inactive_device_threshold),
                filterable: false,
            },
            {
                field: 'last_seen',
                type: 'number',
                align: 'left',
                headerAlign: 'left',
                headerName: t('table.headers.last_seen'),
                flex: 1,
                renderCell: (params: GridRenderCellParams) => getTimeString(params.value),
                filterable: false,
            },
            {
                field: 'version_name',
                type: 'singleSelect',
                headerName: t('table.headers.version'),
                flex: 1,
                valueOptions: [...new Set(devices.map((device) => device.version_name).filter(Boolean))]
                    .sort((a, b) => a.localeCompare(b))
                    .map((value) => ({ value, label: value })),
                filterOperators: [sinsleSelectFilter('version_name')],
            },
        ];

        if (!ALLOW_VIEW_ALL_COLUMNS.includes(profile.role)) {
            gridColumns = gridColumns.filter((col) => col.field !== 'device_id' && col.field !== 'version_name');
        }

        return gridColumns;
    }, [profile.role, isRTL, props.singleSelection, devices]);

    let rows = devices.map((device) => {
        const icWellnessDetails = getICWellnessDetails(device, profile.customer.settings.inactive_device_threshold);
        const ffWellnessDetails = getFFWellnessDetails(device, profile.customer.settings.inactive_device_threshold);
        const obdWellnessDetails = device.aggregated_wellness?.obd_state?.obd_state;
        const wellnessStatus = [icWellnessDetails, ffWellnessDetails];
        if (obdWellnessDetails) {
            wellnessStatus.push(obdWellnessDetails);
        }
        return {
            id: device.device.device_id,
            driver_name: device.device.driver_name || '',
            device_id: device.device.device_id,
            license_plate: device.device.license_plate,
            sub_fleet: device.device.customer_sub_fleet || '',
            status: wellnessStatus,
            last_seen: device.last_seen ? now.getTime() / 1000 - device.last_seen : undefined,
            version_name: device.version_name,
            device: device,
        };
    });

    if (searchText) {
        rows = rows.filter((row) => {
            const normalizedValues = searchText
                .toLocaleLowerCase()
                .replaceAll('-', '')
                .split(',')
                .map((t) => t.trim())
                .filter(Boolean);
            const normalizedLicensePlate = row.license_plate.toLocaleLowerCase().replaceAll('-', '');
            const normalizedDriverName = row.driver_name.toLocaleLowerCase();
            const imei = row.device_id;
            const nameMatch = !!normalizedDriverName && normalizedValues.some((t) => normalizedDriverName.includes(t));
            const licenseMatch =
                !!normalizedLicensePlate && normalizedValues.some((t) => normalizedLicensePlate.includes(t));
            const imeiMatch = !!imei && normalizedValues.some((t) => imei.includes(t));
            return nameMatch || licenseMatch || imeiMatch;
        });
    }

    return (
        <Box
            sx={{
                minWidth: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
            }}
        >
            <StyledDataGrid
                id="ota-data-grid"
                density="compact"
                loading={isLoading}
                columns={columns}
                rows={rows}
                columnBuffer={columns.length}
                rowSelectionModel={props.selectionModel}
                disableRowSelectionOnClick={props.singleSelection}
                pagination={isMobile}
                onRowSelectionModelChange={(newSelectionModel) => {
                    const allIds = rows.map((r) => r.id);
                    const newIds = props.selectionModel
                        ?.filter((id) => !allIds.includes(id.toString()))
                        .concat(newSelectionModel.map((id) => id.toString()));
                    props.setSelectionModel?.([...new Set(newIds)]);
                }}
                getRowClassName={(params: GridRowParams) =>
                    props.singleSelection && params.row.device_id === props.selectedDevice?.device.device_id
                        ? 'Mui-selected'
                        : ''
                }
                onRowClick={(params: GridRowParams) => {
                    if (props.singleSelection) {
                        props.setSelectedDevice(params.row.device as DeviceV3);
                    }
                }}
                checkboxSelection={!props.singleSelection}
                getRowHeight={() => 'auto'}
                localeText={{
                    ...gridLocalization[i18n.languages[0]],
                    filterPanelRemoveAll: t('table.remove_all'),
                    columnMenuManageColumns: t('table.manage_columns'),
                    unpin: t('table.unpin'),
                }}
                slots={{ toolbar: QuickSearchToolbar }}
                slotProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value),
                        useCache,
                        setUseCache,
                        isAdmin,
                        children: props.toolbar,
                        selectedIMEI: props.singleSelection ? props.selectedDevice?.device.device_id : undefined,
                        setAlert: props.setAlert,
                    },
                    filterPanel: {
                        filterFormProps: {
                            filterColumns,
                            logicOperatorInputProps: { sx: { display: 'none' } },
                        },
                        logicOperators: [GridLogicOperator.And],
                        getColumnForNewFilter,
                    },
                    columnsPanel: {
                        sx: {
                            '& .MuiDataGrid-panelFooter button:first-child': {
                                display: 'none',
                            },
                        },
                    },
                    footerRowCount: {
                        sx: {
                            mr: props.singleSelection ? '50px' : undefined,
                        },
                    },
                }}
                sx={{
                    height: '100%',
                    width: '100%',
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '5px' },
                    backgroundColor: palette.neutral[50],
                }}
            />
        </Box>
    );
};

export default MuiDeviceTable;
