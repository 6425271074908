import { Alert, Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button, TrackedLink as Link } from '../../components/TrackedComponents';
import useApi from '../../hooks/api';
import Logo from '../../images/logo/logo_two_tone.svg';

interface ResetRequestProps {
    onBack: CallableFunction;
}

const ResetRequest: React.FC<ResetRequestProps> = (props: ResetRequestProps) => {
    const [username, setUsername] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { api } = useApi();

    const requestPasswordReset = () => {
        setIsLoading(true);
        api.apiV0SettingsUserResetPasswordPost({
            resetPasswordRequest: {
                username: username,
            },
        }).finally(() => {
            setMessage('login.reset_msg');
            setIsLoading(false);
        });
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.repeat && !isLoading && !!username) {
            requestPasswordReset();
        }
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ width: '100%', pt: 3, pb: 1 }}>
                    <img src={Logo} width="100%" />
                </Box>
                <Typography textAlign="center">{t('login.reset_password')}</Typography>
                {!!message ? (
                    <Alert severity="info" sx={{ mt: 1, mb: 2, p: 0, px: 1 }}>
                        {t(message)}
                    </Alert>
                ) : null}
            </Box>
            <Box
                onKeyDown={handleKeyDown}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <TextField
                    id="username"
                    label={t('login.username')}
                    value={username}
                    required
                    fullWidth
                    autoFocus
                    onChange={(e) => setUsername(e.target.value)}
                    sx={{ mt: !!message ? 0 : 1.5 }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                }}
            >
                <Button
                    id="login"
                    variant="contained"
                    color="secondary"
                    onClick={() => requestPasswordReset()}
                    fullWidth
                    disabled={!username || isLoading}
                >
                    {t('login.reset_password')}
                </Button>
                <Link id="back-link" component="button" fontSize={12} sx={{ mt: 1 }} onClick={() => props.onBack()}>
                    {t('login.back')}
                </Link>
            </Box>
        </>
    );
};

export default ResetRequest;
