import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedMenuItem as MenuItem } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface BaseAlertReport {
    pk: number;
    report_type: string;
    filters: Record<string, Array<string>>;
    is_report: boolean;
    active: boolean;
}

export interface Alert extends Omit<BaseAlertReport, 'is_report'> {
    is_report: false;
    cooldown_minutes: number;
    method: 'EMAIL' | 'SMS';
}
export interface Report extends Omit<BaseAlertReport, 'is_report'> {
    report_scope: 'DAILY' | 'WEEKLY' | 'MONTHLY';
    report_day: number;
    report_time: string;
    is_report: true;
}

export type AlertReport = Alert | Report;

export const SCOPE_OPTIONS = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

export const SCEDULING_OPTIONS = {
    REPORT: 'REPORT',
    ALERT: 'ALERT',
};

export const DEVICE_FILTER_OPTIONS = {
    ALL: 'ALL',
    LICENSE_PLATE: 'LICENSE_PLATE',
    DEPARTMENT: 'DEPARTMENT',
};

export const EVENT_FILTER_OPTIONS = {
    EYES_OFF_ROAD: 'EYES_OFF_ROAD',
    TAILGATE: 'TAILGATE',
    DRIVER_EVENT: 'DRIVER_EVENT',
    HARD_BRAKE: 'HARD_BRAKE',
    CRASH_INCIDENT: 'CRASH_INCIDENT',
    COLLISION_TRACK: 'COLLISION_TRACK',
    NEAR_COLLISION: 'NEAR_COLLISION',
    SPEEDING: 'SPEEDING',
    IN_CABIN_CAMERA_COVERED: 'IN_CABIN_CAMERA_COVERED',
};

export const SEVERITY_FILTER_OPTIONS = {
    ALL: 'ALL',
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    CUSTOM: 'CUSTOM',
};

interface SafetyEventFormProps {
    details: AlertReport;
    handleChange: CallableFunction;
}

export const SafetyEventForm: React.FC<SafetyEventFormProps> = (props: SafetyEventFormProps) => {
    const { t } = useTranslation();

    const setSeverityValue = (value: string) => {
        const newFilter = { ...props.details.filters };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newFilter['SEVERITY'] = [SEVERITY_FILTER_OPTIONS.CUSTOM, value];
        props.handleChange('filters', newFilter);
    };

    return (
        <>
            <Autocomplete
                id="event-types-autocomplete"
                multiple
                disableCloseOnSelect
                size="small"
                options={Object.keys(EVENT_FILTER_OPTIONS)}
                getOptionLabel={(option) => t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ padding: 0 }}>
                        <Checkbox
                            id={`${option}-checkbox`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            size="small"
                        />
                        <Typography sx={{ pt: 0.5 }}>
                            {t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                        </Typography>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label={t('content.reports.form.filter.event_header')} />
                )}
                value={props.details.filters['EVENT_TYPE'] || []}
                onChange={(_event, value) => {
                    const newFilter = { ...props.details.filters };
                    newFilter['EVENT_TYPE'] = value;
                    props.handleChange('filters', newFilter);
                }}
                sx={{ mb: 1.5 }}
            />
            <Box sx={{ width: '100%', display: 'flex' }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="severity-dropdown-label">
                        {t('content.reports.form.filter.severity_header')}
                    </InputLabel>
                    <Select
                        id="severity-dropdown"
                        labelId="severity-dropdown-label"
                        fullWidth
                        size="small"
                        label={t('content.reports.form.filter.severity_header')}
                        defaultValue="ALL"
                        value={
                            props.details.filters['SEVERITY']
                                ? props.details.filters['SEVERITY'][0]
                                : SEVERITY_FILTER_OPTIONS.ALL
                        }
                        onChange={(e: SelectChangeEvent) => {
                            const newFilter = { ...props.details.filters };
                            const value = e.target.value;
                            if (value == SEVERITY_FILTER_OPTIONS.ALL) {
                                delete newFilter['SEVERITY'];
                            } else {
                                newFilter['SEVERITY'] = [value, '0'];
                            }
                            props.handleChange('filters', newFilter);
                        }}
                    >
                        {Object.keys(SEVERITY_FILTER_OPTIONS).map((option) => (
                            <MenuItem key={option} value={option} id={option}>
                                {t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {props.details.filters.SEVERITY?.includes(SEVERITY_FILTER_OPTIONS.CUSTOM) ? (
                    <TextField
                        fullWidth
                        id="risk-value-field"
                        size="small"
                        type="number"
                        inputProps={{ min: 0, max: 100 }}
                        label={t('content.reports.form.filter.value')}
                        value={props.details.filters['SEVERITY'][1]}
                        onChange={(e) => setSeverityValue(e.target.value)}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                e.target.value = '';
                            } else if (parseInt(e.target.value) < 0) {
                                setSeverityValue('0');
                            } else if (parseInt(e.target.value) > 100) {
                                setSeverityValue('100');
                            }
                        }}
                        sx={{ ml: 1 }}
                    />
                ) : null}
            </Box>
        </>
    );
};

const GEOFENCE_TRIGGER_OPTIONS = {
    GEOFENCE_ENTER: 'GEOFENCE_ENTER',
    GEOFENCE_LEAVE: 'GEOFENCE_LEAVE',
};

interface GeofenceEventFormProps extends SafetyEventFormProps {
    geofences: Record<string, string>;
}

export const GeofenceEventForm: React.FC<GeofenceEventFormProps> = (props: GeofenceEventFormProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Autocomplete
                id="geofence-autocomplete"
                multiple
                disableCloseOnSelect
                size="small"
                options={Object.keys(props.geofences)}
                getOptionLabel={(option) => props.geofences[option]}
                renderOption={(itemProps, option, { selected }) => (
                    <li {...itemProps} style={{ padding: 0 }}>
                        <Checkbox
                            id={`${option}-checkbox`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            size="small"
                        />
                        <Typography sx={{ pt: 0.5 }}>{props.geofences[option]}</Typography>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label={t('content.reports.form.filter.geofence_header')} />
                )}
                value={props.details.filters['GEOFENCE']}
                onChange={(_event, value) => {
                    const newFilter = { ...props.details.filters };
                    newFilter['GEOFENCE'] = value;
                    props.handleChange('filters', newFilter);
                }}
                sx={{ mb: 1.5 }}
            />
            <Autocomplete
                id="trigger-autocomplete"
                multiple
                disableCloseOnSelect
                size="small"
                options={Object.keys(GEOFENCE_TRIGGER_OPTIONS)}
                getOptionLabel={(option) => t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ padding: 0 }}>
                        <Checkbox
                            id={`${option.toLocaleLowerCase()}-checkbox`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            size="small"
                        />
                        <Typography sx={{ pt: 0.5 }}>
                            {t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                        </Typography>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label={t('content.reports.form.filter.trigger_header')} />
                )}
                value={props.details.filters['TRIGGER']}
                onChange={(_event, value) => {
                    const newFilter = { ...props.details.filters };
                    newFilter['TRIGGER'] = value;
                    props.handleChange('filters', newFilter);
                }}
                sx={{ mb: 2 }}
            />
        </>
    );
};

export const WELLNESS_OPTIONS = {
    NO_FACE: 'NO_FACE',
    BLOCKED: 'BLOCKED',
    FAULTY: 'FAULTY',
    PERIPHERAL_FACE: 'PERIPHERAL_FACE',
    DISCONNECTED: 'DISCONNECTED',
    UNWELL_HORIZONTAL: 'UNWELL_HORIZONTAL',
    CAMERA_VERTICAL_HIGH: 'CAMERA_VERTICAL_HIGH',
    CAMERA_VERTICAL_LOW: 'CAMERA_VERTICAL_LOW',
    LOW_QUALITY_INPUT: 'LOW_QUALITY_INPUT',
    INSUFFICIENT_VALID_INPUT: 'INSUFFICIENT_VALID_INPUT',
};

export const ASSIGNEE_OPTIONS = {
    ALL: 'ALL',
    ASSIGNED_TO_ME: 'ASSIGNED_TO_ME',
    CUSTOMER: 'CUSTOMER',
    OPENEYES: 'OPENEYES',
};

export const ACTION_OPTIONS = {
    NEW: 'NEW',
    FIELD_CHANGE: 'FIELD_CHANGE',
    NEW_COMMENT: 'NEW_COMMENT',
};

export const FIELDS_OPTIONS = {
    STAGE: 'STAGE',
    ASSIGNEE: 'ASSIGNEE',
};

export const DeviceWellnessForm: React.FC<SafetyEventFormProps> = (props: SafetyEventFormProps) => {
    const { t } = useTranslation();

    return (
        <Autocomplete
            id="wellness-types-autocomplete"
            multiple
            disableCloseOnSelect
            size="small"
            options={Object.keys(WELLNESS_OPTIONS)}
            getOptionLabel={(option) => t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{ padding: 0 }}>
                    <Checkbox
                        id={`${option}-checkbox`}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                        size="small"
                    />
                    <Typography sx={{ pt: 0.5 }}>
                        {t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                    </Typography>
                </li>
            )}
            renderInput={(params) => <TextField {...params} label={t('content.reports.form.filter.wellness_header')} />}
            value={props.details.filters['WELLNESS_TYPE']}
            onChange={(_event, value) => {
                const newFilter = { ...props.details.filters };
                newFilter['WELLNESS_TYPE'] = value;
                props.handleChange('filters', newFilter);
            }}
            sx={{ mb: 2 }}
        />
    );
};

export const AccidentReportForm: React.FC<SafetyEventFormProps> = (props: SafetyEventFormProps) => {
    const { profile } = useProfile();
    const { t } = useTranslation();

    return props.details.is_report ? (
        <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>{t('content.reports.form.filter.assignee')}</InputLabel>
            <Select
                id="assignee-dropdown"
                fullWidth
                size="small"
                label={t('content.reports.form.filter.assignee')}
                defaultValue="ALL"
                value={props.details.filters['ASSIGNEE'] ? props.details.filters['ASSIGNEE'][0] : undefined}
                onChange={(e: SelectChangeEvent) => {
                    const newFilter = { ...props.details.filters };
                    const value = e.target.value;
                    if (value == ASSIGNEE_OPTIONS.ALL) {
                        delete newFilter['SEVERITY'];
                    } else {
                        newFilter['SEVERITY'] = [e.target.value];
                    }
                    props.handleChange('filters', newFilter);
                }}
            >
                {Object.keys(ASSIGNEE_OPTIONS).map((option) => (
                    <MenuItem key={option} value={option} id={option}>
                        {option === ASSIGNEE_OPTIONS.CUSTOMER
                            ? profile.customer.name
                            : t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    ) : (
        <>
            <Autocomplete
                id="accident-action-autocomplete"
                multiple
                disableCloseOnSelect
                size="small"
                options={Object.keys(ACTION_OPTIONS)}
                getOptionLabel={(option) => t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ padding: 0 }}>
                        <Checkbox
                            id={`${option}-checkbox`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            size="small"
                        />
                        <Typography sx={{ pt: 0.5 }}>
                            {t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                        </Typography>
                    </li>
                )}
                renderInput={(params) => <TextField {...params} label={t('content.reports.form.filter.action')} />}
                value={props.details.filters['ACTION']}
                onChange={(_event, value) => {
                    const newFilter = { ...props.details.filters };
                    newFilter['ACTION'] = value;
                    props.handleChange('filters', newFilter);
                }}
                sx={{ mb: 1.5 }}
            />
            <Autocomplete
                id="accident-field-autocomplete"
                multiple
                disableCloseOnSelect
                size="small"
                options={Object.keys(FIELDS_OPTIONS)}
                getOptionLabel={(option) => t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ padding: 0 }}>
                        <Checkbox
                            id={`${option}-checkbox`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            size="small"
                        />
                        <Typography sx={{ pt: 0.5 }}>
                            {t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                        </Typography>
                    </li>
                )}
                renderInput={(params) => <TextField {...params} label={t('content.reports.form.filter.fields')} />}
                value={props.details.filters['FIELDS']}
                onChange={(_event, value) => {
                    const newFilter = { ...props.details.filters };
                    newFilter['FIELDS'] = value;
                    props.handleChange('filters', newFilter);
                }}
                sx={{ mb: 2 }}
            />
        </>
    );
};
