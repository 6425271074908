import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';

import { EventDetails } from '../backendsdk';
import videoNA from '../images/video_not_available.png';

interface EventDetailsComponentProps
    extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
    event?: EventDetails;
    video: string;
    internalOnProgress?: CallableFunction;
}

const VideoComponent: React.FC<EventDetailsComponentProps> = (props: EventDetailsComponentProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [playStatus, setPlayStatus] = useState<Record<string, any>>({ started: false, ended: false });
    const [videoWatched, setVideoWatched] = useState(false);
    const { event, video, internalOnProgress, ...sharedProps } = props;

    useEffect(() => {
        if (videoWatched) {
            mixpanel.track('Video watched', {
                type: 'event',
                page: window.location.pathname,
            });
        }
    }, [videoWatched]);

    const player = video ? (
        <video
            id={`${event?.event_id}-video`}
            src={video}
            controls={true}
            onDurationChange={(e: React.SyntheticEvent) => {
                const { duration } = e.target as HTMLVideoElement;
                setPlayStatus((prev) => {
                    return { ...prev, duration };
                });
            }}
            onPlay={() =>
                setPlayStatus((prev) => {
                    return {
                        ...prev,
                        event_id: event?.event_id,
                        severity: event?.severity,
                        ended: false,
                        playedSeconds: prev.playedSeconds ? prev.playedSeconds : 0,
                    };
                })
            }
            onTimeUpdate={(e) => {
                const { currentTime }: { currentTime: number } = e.target as HTMLVideoElement;
                const playedSeconds = Math.trunc(currentTime);
                if (internalOnProgress) {
                    internalOnProgress(playedSeconds, playStatus.duration);
                }
                setPlayStatus((prev) => {
                    return { ...prev, currentPlayedSeconds: playedSeconds };
                });
                if (e.currentTarget.duration && !videoWatched) {
                    const percentVideoPlayed = (e.currentTarget.currentTime / e.currentTarget.duration) * 100;
                    if (percentVideoPlayed >= 50) {
                        setVideoWatched(true);
                    }
                }
            }}
            onEnded={() =>
                setPlayStatus((prev) => {
                    return { ...prev, ended: true };
                })
            }
            style={{ width: '100%', aspectRatio: '8 / 3', display: 'block' }}
            {...sharedProps}
        />
    ) : (
        <img style={{ width: '100%', height: 'auto', display: 'block' }} src={videoNA} />
    );

    return player;
};

export default VideoComponent;
