import { Box, Collapse, Divider, List, ListItem, Skeleton, Tabs, Typography, capitalize } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';

import { EventDetails, FrontFacingWellness, InCabinWellnessV1 } from '../../../backendsdk';
import { TrackedListItemButton as ListItemButton, TrackedTab as Tab } from '../../../components/TrackedComponents';
import useDrivers from '../../../hooks/drivers';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import { WELLNESS_TYPE_MAP } from '../Device/WellnessBox';
import { EVENT_TYPE_MAP } from '../Event/Defs';
import { getItemId } from '../Wellness/WellnessModal';
import { STATUS } from './VehicleModal';

interface VehicleModalSidebarProps {
    tab: STATUS;
    setTab: CallableFunction;
    sortedEvents: Array<Array<EventDetails | InCabinWellnessV1 | FrontFacingWellness>>;
    accidentEventIds: number[];
    selectedEvent: number | undefined;
    setSelectedEvent: CallableFunction;
    eventImages: Record<number, string>;
    isLoadingEvents: boolean;
    getItemType?: CallableFunction;
    listHeight: number;
}

const VehicleModalSidebar: React.FC<VehicleModalSidebarProps> = (props: VehicleModalSidebarProps) => {
    const { t } = useTranslation();
    const placeholders = [
        t('content.safety.pending_placeholder'),
        t('content.safety.saved_placeholder'),
        t('content.safety.dismissed_placeholder'),
    ];

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        props.setTab(newValue);
    };

    const a11yProps = (index: STATUS) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    };

    return (
        <>
            <Tabs variant="fullWidth" value={props.tab} onChange={handleChange} indicatorColor="secondary">
                <Tab
                    label={t('content.safety.pending_tab')}
                    {...a11yProps(STATUS.PENDING)}
                    id="pending-tag"
                    data-testid={`tab-0`}
                    wrapped
                />
                <Tab
                    label={t('content.safety.saved_tab')}
                    {...a11yProps(STATUS.SAVED)}
                    id="saved-tag"
                    data-testid={`tab-1`}
                    wrapped
                />
                <Tab
                    label={t('content.safety.dismissed_tab')}
                    {...a11yProps(STATUS.DISMISSED)}
                    id="dismissed-tag"
                    data-testid={`tab-2`}
                    wrapped
                />
            </Tabs>
            <Box sx={{ height: props.listHeight, overflowY: 'scroll' }}>
                {props.sortedEvents.map((events, idx) => {
                    return (
                        <TabPanel
                            key={idx}
                            value={props.tab}
                            index={idx}
                            events={events}
                            accidentEventIds={props.accidentEventIds}
                            selectedEvent={props.selectedEvent}
                            setSelectedEvent={props.setSelectedEvent}
                            eventImages={props.eventImages}
                            isLoading={props.isLoadingEvents}
                            placeholder={placeholders[idx]}
                            getItemType={props.getItemType}
                        />
                    );
                })}
            </Box>
        </>
    );
};

interface TabPanelProps {
    index: number;
    value: STATUS;
    events: Array<EventDetails | InCabinWellnessV1 | FrontFacingWellness>;
    accidentEventIds: number[];
    selectedEvent: number | undefined;
    setSelectedEvent: CallableFunction;
    eventImages: Record<string, string>;
    isLoading: boolean;
    placeholder: string;
    getItemType?: CallableFunction;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { getDriver } = useDrivers();
    const isCurrent = props.value === props.index;

    const isAccident = (event: EventDetails) => props.accidentEventIds.includes(event.event_id);

    const renderEvent = (e: EventDetails) => {
        return (
            <>
                {props.eventImages[`event:${getItemId(e)}`] !== undefined ? (
                    <Box sx={{ width: '180px', height: '67.5px', mr: 1, flexShrink: 0 }}>
                        <img src={props.eventImages[`event:${getItemId(e)}`]} width="100%" />
                    </Box>
                ) : (
                    <Skeleton variant="rectangular" animation="wave" width={180} height={67.5} sx={{ mr: 1 }} />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography id={`${getItemId(e)}-type`} variant="body3" sx={{ fontWeight: 'bold' }}>
                        {t(isAccident(e) ? 'content.safety.accident' : EVENT_TYPE_MAP[e.type])}
                    </Typography>
                    <Typography id={`${getItemId(e)}-driver`} variant="body3">
                        {`${t('content.trip.driver')}: `}
                        {(e.trip ? getDriver(e.trip.driver)?.fullName : e.device.driver_name) ||
                            t('content.fleet.unknown_driver')}
                    </Typography>
                    <Typography id={`${getItemId(e)}-risk`} variant="body3">
                        {t('table.headers.severity')}: {(isAccident(e) ? 100 : e.severity).toFixed(2)}
                    </Typography>
                    <Typography id={`${getItemId(e)}-datetime`} variant="body3">
                        {dayjs.unix(e.timestamp).format(profile.dateTimeFormat)}
                    </Typography>
                </Box>
            </>
        );
    };

    const renderWellnessStatus = (s: InCabinWellnessV1 | FrontFacingWellness) => {
        return (
            <>
                {!!props.getItemType && props.eventImages[`${props.getItemType(s)}:${s.id}`] !== undefined ? (
                    <Box sx={{ width: '180px', height: '67.5px', mr: 1, flexShrink: 0 }}>
                        <img src={props.eventImages[`${props.getItemType(s)}:${s.id}`]} width="100%" />
                    </Box>
                ) : (
                    <Skeleton variant="rectangular" animation="wave" width={180} height={67.5} sx={{ mr: 1 }} />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {!!props.getItemType && (
                        <Typography variant="body3">{t(`content.wellness.${props.getItemType(s)}`)}</Typography>
                    )}
                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                        {capitalize(t(WELLNESS_TYPE_MAP[s.wellness_state as keyof typeof WELLNESS_TYPE_MAP]))}
                    </Typography>
                    <Typography variant="body3">{dayjs.unix(s.timestamp).format(profile.dateFormat)}</Typography>
                </Box>
            </>
        );
    };

    return (
        <div
            role="tabpanel"
            hidden={props.value !== props.index}
            id={`tabpanel-${props.index}`}
            aria-labelledby={`tab-${props.index}`}
        >
            {isCurrent ? (
                <Box sx={{ p: 0 }}>
                    <List disablePadding>
                        <TransitionGroup>
                            {props.events.map((e) => (
                                <Collapse key={getItemId(e)}>
                                    <ListItem
                                        sx={
                                            props.selectedEvent === getItemId(e)
                                                ? { backgroundColor: palette.white, p: 0 }
                                                : { p: 0 }
                                        }
                                    >
                                        <ListItemButton
                                            id={`${getItemId(e)}-event-btn`}
                                            data-testid={`${getItemId(e)}-event-btn`}
                                            aria-selected={props.selectedEvent === getItemId(e)}
                                            sx={{ p: 1 }}
                                            onClick={() => {
                                                if (props.selectedEvent !== getItemId(e)) {
                                                    props.setSelectedEvent(getItemId(e));
                                                }
                                            }}
                                        >
                                            {'event_id' in e ? renderEvent(e) : renderWellnessStatus(e)}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </Collapse>
                            ))}
                        </TransitionGroup>
                    </List>
                    {props.events.length === 0 ? (
                        <Box textAlign="center" sx={{ pt: 2 }}>
                            <Typography variant="overline" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                {props.isLoading ? t('content.safety.loading') : props.placeholder}
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
            ) : null}
        </div>
    );
};

export default VehicleModalSidebar;
