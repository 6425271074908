import { Box, Menu, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedAccident, ExtendedCustomerDataAllOfUsers } from '../../../../../backendsdk';
import { TrackedMenuItem as MenuItem } from '../../../../../components/TrackedComponents';
import { useAlert } from '../../../../../hooks/alert';
import useApi from '../../../../../hooks/api';
import { getCustomer } from '../../../../../utils/customer';
import QuickCoachingModal from './QuickCoachingModal';
import SendAccidentModal from './SendAccidentModal';

interface ShareMenuProps {
    open: boolean;
    anchorElShare: null | HTMLElement;
    handleShareMenuClose: CallableFunction;
    details: ExtendedAccident;
}

const ShareMenu: React.FC<ShareMenuProps> = (props) => {
    const [isSendAccidentOpen, setIsSendAccidentOpen] = useState<boolean>(false);
    const [isQuickCoachingOpen, setIsQuickCoachingOpen] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const { t } = useTranslation();
    const [alertElement, setAlert] = useAlert();
    const { api, agencyApi } = useApi();

    const { data: customer } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['customer'],
        queryFn: () => getCustomer(api),
    });
    const customerUsers = customer?.users || [];

    const handleSendAccident = (recipients: ExtendedCustomerDataAllOfUsers[], message: string) => {
        setSending(true);
        agencyApi
            .agencyV1AccidentAccidentIdSendPost({
                accidentId: props.details.accident_id,
                sendAccidentRequest: {
                    recipients: recipients.map((u) => u.user_id),
                    message,
                },
            })
            .then(() => {
                setIsSendAccidentOpen(false);
                setAlert({
                    message: t('content.accidents.modal_header.share.send_accident_success'),
                    type: 'success',
                    duration: 6000,
                });
            })
            .catch(() => {
                setAlert({
                    message: t('content.accidents.modal_header.share.send_accident_error'),
                    type: 'error',
                    duration: 6000,
                });
            })
            .finally(() => {
                setSending(false);
            });
    };

    return (
        <>
            {alertElement}
            {isSendAccidentOpen ? (
                <SendAccidentModal
                    onSend={handleSendAccident}
                    onClose={() => setIsSendAccidentOpen(false)}
                    users={customerUsers}
                    sending={sending}
                />
            ) : null}
            {isQuickCoachingOpen && props.details.event ? (
                <QuickCoachingModal
                    isOpen={isQuickCoachingOpen}
                    licensePlate={props.details.license_plate}
                    driverToken={props.details.driver_token}
                    onSend={() => setIsQuickCoachingOpen(false)}
                    onClose={() => setIsQuickCoachingOpen(false)}
                    eventId={props.details.event}
                    setAlert={setAlert}
                    dialog
                />
            ) : null}
            <Menu
                id="share-menu"
                anchorEl={props.anchorElShare}
                open={props.open}
                onClose={() => props.handleShareMenuClose()}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem
                    id="copy-link-menu-item"
                    onClick={() => {
                        const fullUrl = window.location.toString();
                        navigator.clipboard.writeText(fullUrl);
                        setAlert({
                            message: t('content.accidents.modal_header.share.share_link_copied'),
                            type: 'info',
                            duration: 6000,
                        });
                        props.handleShareMenuClose();
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography>{t('content.accidents.modal_header.share.copy_link')}</Typography>
                    </Box>
                </MenuItem>
                <MenuItem
                    id="send-event-menu-item"
                    onClick={() => {
                        setIsSendAccidentOpen(true);
                        props.handleShareMenuClose();
                    }}
                >
                    {t('content.accidents.modal_header.share.send_accident')}
                </MenuItem>
                <MenuItem
                    id="quick-coaching-menu-item"
                    onClick={() => {
                        setIsQuickCoachingOpen(true);
                        props.handleShareMenuClose();
                    }}
                    disabled={!props.details.event}
                >
                    {t('content.accidents.modal_header.share.send_coaching')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default ShareMenu;
