import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DriverScore } from '../../../backendsdk';
import { TrackedLink as Link } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useCallbackRef from '../../../hooks/callbackRef';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { vehiclesPageDefs } from '../../../utils/Pages';
import { DEFAULT_TITLE } from '../../Layout';
import { EVENT_TYPE_MAP } from '../Event/Defs';
import { INVALID_SCORE } from '../Metrics/SafetyWellness/MetricsComponent';
import { CARD_HEIGHT, CARD_MARGIN, CARD_WIDTH } from './VehicleCard';
import VehicleGrid from './VehicleGrid';
import { NAV_BUTTON_WIDTH } from './VehicleGrid';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BEHAVIORS_ITEMS = [
    'content.events.types.eor',
    'content.events.types.tailgate',
    'content.events.types.hard_brake',
    'content.safety.accident',
    'content.events.types.near_collision',
] as const;

const VehiclesComponent: React.FC = () => {
    const [driverScores, setDriverScores] = useState<DriverScore[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [subfleetValue, setSubfleetValue] = useState<string[]>([]);
    const [behaviorValue, setBehaviorValue] = useState<string[]>([]);
    const [cardRows, setCardRows] = useState<number>(2);
    const [cardColumns, setCardColumns] = useState<number>(4);
    const [ref, node] = useCallbackRef();
    const { t } = useTranslation();
    const { api } = useApi();
    const isMobile = useIsMobile();
    const { profile } = useProfile();

    const loadDriverScores = async (page: number, abortController?: AbortController): Promise<DriverScore[]> => {
        const res = await api.apiV0DriverScoresGet({ cache: 1, page: page }, { signal: abortController?.signal });
        const moreData = res.data.length > 0 ? await loadDriverScores(page + 1, abortController) : [];
        return [...res.data, ...moreData] as Array<DriverScore>;
    };

    const getDriverScores = (abortController?: AbortController) => {
        setIsLoading(true);
        loadDriverScores(1, abortController)
            .then((res) => {
                setDriverScores(res);
            })
            .catch(() => (abortController?.signal.aborted ? null : setIsError(true)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${vehiclesPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        getDriverScores(abortController);
        return () => abortController.abort();
    }, []);

    useEffect(() => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            if (node.offsetHeight) {
                const newRows = Math.floor(node.offsetHeight / (CARD_HEIGHT + CARD_MARGIN * 2));
                setCardRows(Math.max(1, newRows));
            }
            if (node.offsetWidth) {
                const newCols = Math.floor((node.offsetWidth - NAV_BUTTON_WIDTH * 2) / (CARD_WIDTH + CARD_MARGIN * 2));
                setCardColumns(Math.max(1, newCols));
            }
        });
        resizeObserver.observe(node);
        return () => resizeObserver.disconnect();
    }, [node]);

    const vehiclesWithScore = driverScores
        .filter((driverScore) => driverScore.score >= 0)
        .sort((a, b) => {
            if (a.score === b.score) {
                return a.driver_name?.localeCompare(b.driver_name);
            }
            return a.score - b.score;
        });
    const vehiclesWithoutScore = driverScores
        .filter((driverScore) => driverScore.score === INVALID_SCORE)
        .sort((a, b) => {
            return a.driver_name?.localeCompare(b.driver_name);
        });
    const unwellVehicles = vehiclesWithoutScore.filter((driverScore) => driverScore.unknown);
    const inactiveVehicles = vehiclesWithoutScore.filter((driverScore) => driverScore.inactive);
    const sortedVehicles = [...vehiclesWithScore, ...unwellVehicles, ...inactiveVehicles];
    const vehicles = sortedVehicles.map((driverScore) => {
        const vehicle = {
            timestamp: driverScore.timestamp,
            eventIds: driverScore.event_ids || [],
            accidentEventIds: driverScore.accident_event_ids || [],
            driverName: driverScore.driver_name,
            licensePlate: driverScore.license_plate,
            subFleet: driverScore.sub_fleet,
            score: driverScore.score,
            behaviors: driverScore.event_types
                .split(',')
                .filter(Boolean)
                .map((eventType) => EVENT_TYPE_MAP[eventType]),
            mostRecentEvent: driverScore.most_recent_event,
            inactive: driverScore.inactive,
            unknown: driverScore.unknown,
        };
        if (
            vehicle.accidentEventIds.length > 0 ||
            (vehicle.score !== INVALID_SCORE &&
                vehicle.score < 100 &&
                vehicle.eventIds.length === 0 &&
                vehicle.accidentEventIds.length === 0)
        ) {
            vehicle.behaviors.unshift('content.safety.accident');
        }
        return vehicle;
    });

    let data = vehicles;
    if (searchText) {
        const normalizedNameText = searchText.toLocaleLowerCase();
        const normalizedLicensePlate = searchText.toLocaleUpperCase().replaceAll('-', '');
        data = data.filter((card) => {
            return (
                card.driverName?.toLocaleLowerCase().includes(normalizedNameText) ||
                card.licensePlate.toLocaleUpperCase().replaceAll('-', '').includes(normalizedLicensePlate)
            );
        });
    }
    if (subfleetValue && subfleetValue.length > 0) {
        data = data.filter((card) => subfleetValue.includes(card.subFleet?.toLocaleLowerCase()));
    }
    if (behaviorValue && behaviorValue.length > 0) {
        data = data.filter((card) => card.behaviors.some((behaviorItem) => behaviorValue.includes(behaviorItem)));
    }

    let content;
    if (isLoading) {
        content = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress size={40} />
            </Box>
        );
    } else if (isError) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.safety.error')}</Typography>
                <Link
                    id="try-again-link"
                    component="button"
                    variant="overline"
                    onClick={() => {
                        setIsError(false);
                        getDriverScores();
                    }}
                >
                    {t('content.safety.try_again')}
                </Link>
            </Box>
        );
    } else if (vehicles.length > 0 && data.length === 0) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.safety.no_data')}</Typography>
                {!!searchText || subfleetValue.length > 0 || behaviorValue.length > 0 ? (
                    <Link
                        id="clear-filters-link"
                        component="button"
                        variant="overline"
                        onClick={() => {
                            setSubfleetValue([]);
                            setBehaviorValue([]);
                            setSearchText('');
                        }}
                    >
                        {t('content.safety.clear_filters')}
                    </Link>
                ) : null}
            </Box>
        );
    } else {
        content = <VehicleGrid vehicles={data} cardRows={cardRows} cardColumns={cardColumns} ref={ref} />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <TextField
                    id="search-text-field"
                    size="small"
                    fullWidth={isMobile}
                    variant="outlined"
                    label={t('content.safety.search')}
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                    sx={{ mr: isMobile ? 0 : 1, mb: isMobile ? 1 : 0, width: 250 }}
                />
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="sub-fleet-filter"
                    size="small"
                    options={profile.customer.sub_fleets.filter(Boolean)}
                    value={subfleetValue}
                    onChange={(_, newValue) => {
                        setSubfleetValue(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={t('content.wellness.sub_fleet')} sx={{ width: 250 }} />
                    )}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ height: 40, paddingLeft: 0 }}>
                            <Checkbox
                                id={`${option}-checkbox`}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                        </li>
                    )}
                    // limits the rendered tags to one chip
                    renderTags={(value: Array<string>, getTagProps: AutocompleteRenderGetTagProps) => {
                        return (
                            <>
                                <Chip
                                    size="small"
                                    label={<Typography fontSize={12}>{value[0]}</Typography>}
                                    {...getTagProps({ index: 0 })}
                                    sx={{ borderRadius: 1, maxWidth: '160px !important' }}
                                    onDelete={undefined}
                                />
                                {value.length > 1 ? (
                                    <Typography variant="body2">{`+${value.length - 1}`}</Typography>
                                ) : null}
                            </>
                        );
                    }}
                    sx={{
                        mr: isMobile ? 0 : 1,
                        mb: isMobile ? 1 : 0,
                        '& .MuiInputBase-root': { flexWrap: 'nowrap' },
                    }}
                />
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="behaviors-item-filter"
                    size="small"
                    options={BEHAVIORS_ITEMS}
                    value={behaviorValue}
                    onChange={(_, newValue) => {
                        setBehaviorValue(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={t('content.safety.behaviors')} sx={{ width: 250 }} />
                    )}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ paddingLeft: 0 }}>
                            <Checkbox
                                id={`${option}-checkbox`}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {t(option)}
                        </li>
                    )}
                    // limits the rendered tags to one chip
                    renderTags={(value: Array<string>, getTagProps: AutocompleteRenderGetTagProps) => {
                        return (
                            <>
                                <Chip
                                    size="small"
                                    label={<Typography fontSize={12}>{t(value[0])}</Typography>}
                                    {...getTagProps({ index: 0 })}
                                    sx={{ borderRadius: 1, maxWidth: '160px !important' }}
                                    onDelete={undefined}
                                />
                                {value.length > 1 ? (
                                    <Typography variant="body2">{`+${value.length - 1}`}</Typography>
                                ) : null}
                            </>
                        );
                    }}
                    sx={{ '& .MuiInputBase-root': { flexWrap: 'nowrap' } }}
                />
            </Box>
            {content}
        </Box>
    );
};

export default VehiclesComponent;
