import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { wellnessColorsByState, wellnessColorsMap } from '../StyledElements';

interface WellnessBoxProps {
    wellnessType: 'in_cabin' | 'in_cabin_v1' | 'front_facing' | 'obd';
    wellnessDetails: string;
}

export const WELLNESS_TYPE_MAP: Record<string, string> = {
    well: 'content.fleet.wellness.well',
    faulty: 'content.fleet.wellness.faulty',
    unwell: 'content.fleet.wellness.unwell',
    'no face': 'content.fleet.wellness.no face',
    no_face: 'content.fleet.wellness.no face',
    peripheral_face: 'content.fleet.wellness.peripheral_face',
    centered_face: 'content.fleet.wellness.well',
    blocked: 'content.fleet.wellness.blocked',
    unknown: 'content.fleet.wellness.unknown',
    inactive: 'content.fleet.wellness.inactive',
    disconnected: 'content.fleet.wellness.disconnected',
    frontfacingwellnesswell: 'content.fleet.wellness.frontfacingwellnesswell',
    frontfacingwellnessunwellhorizontal: 'content.fleet.wellness.frontfacingwellnessunwellhorizontal',
    frontfacingwellnesscameraverticalhigh: 'content.fleet.wellness.frontfacingwellnesscameraverticalhigh',
    frontfacingwellnesscameraverticallow: 'content.fleet.wellness.frontfacingwellnesscameraverticallow',
    frontfacingwellnesslowqualityinput: 'content.fleet.wellness.frontfacingwellnesslowqualityinput',
    frontfacingwellnessinsufficientvalidinput: 'content.fleet.wellness.frontfacingwellnessinsufficientvalidinput',
    frontfacingwellnessunknown: 'content.fleet.wellness.frontfacingwellnessunknown',
    connectionfailure: 'content.fleet.wellness.connectionfailure',
    datafailure: 'content.fleet.wellness.datafailure',
};

const WellnessBox: React.FC<WellnessBoxProps> = (props: WellnessBoxProps) => {
    const { t } = useTranslation();
    // remove unused props from Box
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { wellnessType, wellnessDetails, ...boxProps } = props;
    const color =
        wellnessColorsByState[props.wellnessDetails.toLocaleLowerCase() as keyof typeof wellnessColorsByState] ??
        wellnessColorsByState.inactive;
    let icon;
    if (color === wellnessColorsMap.well) {
        icon = <CheckCircleIcon />;
    } else if (color === wellnessColorsMap.unwell) {
        icon = <ErrorIcon />;
    } else if (color === wellnessColorsMap.unknown) {
        icon = <HelpIcon />;
    } else {
        icon = undefined;
    }

    return (
        <Box {...boxProps}>
            <Chip
                id={`${props.wellnessType.replaceAll('_', '-')}-wellness`}
                variant="outlined"
                icon={icon}
                label={
                    <Typography fontSize={12}>{`${t(`content.fleet.${props.wellnessType}`)} ${t(
                        WELLNESS_TYPE_MAP[props.wellnessDetails.toLocaleLowerCase() as keyof typeof WELLNESS_TYPE_MAP],
                    )}`}</Typography>
                }
                sx={{
                    color,
                    borderColor: color,
                    '& .MuiChip-icon': {
                        color,
                    },
                }}
            />
        </Box>
    );
};

export default WellnessBox;
