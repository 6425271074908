import NoTransferIcon from '@mui/icons-material/NoTransfer';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import SearchIcon from '@mui/icons-material/Search';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Skeleton,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BaseAccidentV2, ExtendedCustomerDataAllOfUsers, PolicyV2 } from '../../../../backendsdk';
import { TrackedLink as Link } from '../../../../components/TrackedComponents';
import { useAlert } from '../../../../hooks/alert';
import useApi from '../../../../hooks/api';
import useProfile from '../../../../hooks/profile';
import imageNA from '../../../../images/image_not_available.png';
import { stringAvatar } from '../../../../utils/Avatar';
import { normalizeLicensePlate } from '../../../../utils/Str';
import { getCustomer } from '../../../../utils/customer';
import palette from '../../../ColorPalette';
import { RequestStatus, getRequestStatus } from '../../FindVideo/RequestList';
import { CARD_HEIGHT, CARD_MARGIN, CARD_WIDTH, CardField, IMAGE_ASPECT_RATIO } from '../../Vehicles/VehicleCard';
import { UnknownDriverIcon, UnknownVehicleIcon, VideoSearchIcon } from '../Defs';
import AccidentCardContextMenu from './AccidentCardContextMenu';
import AccidentCardImage from './AccidentCardImage';

interface AssigneeAvatarProps {
    assignee?: ExtendedCustomerDataAllOfUsers;
}

const AssigneeAvatar = ({ assignee }: AssigneeAvatarProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const assigneeName = assignee ? assignee.name : t('content.accidents.form.unassigned');
    const imageProportions = { width: '25px', height: '25px', borderRadius: '50%' };
    let avatar;
    if (!!assignee) {
        if (!!assignee.image_url) {
            avatar = (
                <img
                    src={assignee.image_url}
                    alt={assignee.name}
                    style={{
                        ...imageProportions,
                        overflow: 'hidden',
                        objectFit: 'cover',
                        objectPosition: '0 100%',
                    }}
                />
            );
        } else {
            avatar = <Avatar {...stringAvatar(assignee.name, imageProportions)} />;
        }
    } else {
        avatar = <Avatar sx={{ ...imageProportions, bgColor: theme.palette.primary.main }}> </Avatar>;
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {avatar}
            <Typography variant="body2">{assigneeName}</Typography>
        </Box>
    );
};

export interface AccidentCardProps {
    accidentDetails: BaseAccidentV2;
    policies: Array<PolicyV2>;
    image?: string;
    showBell?: boolean;
    updateNotificationStatus: (accidentId: number, status: boolean) => void;
    setAccidents: CallableFunction;
}

export const AccidentCard: React.FC<AccidentCardProps> = (props: AccidentCardProps) => {
    const [anchorPosition, setAnchorPosition] = useState<null | { mouseX: number; mouseY: number }>(null);
    const { t } = useTranslation();
    const history = useHistory();
    const { profile } = useProfile();
    const { api, agencyApi } = useApi();
    const [alertElement, setAlert] = useAlert();
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    const { data: customer } = useReactQuery({ queryKey: ['customer'], queryFn: () => getCustomer(api) });
    const theme = useTheme();

    const assignee = (customer?.users || []).find((u) => u.name.trim() == props.accidentDetails.assignee?.trim());

    const accidentDate = dayjs.unix(props.accidentDetails.timestamp).startOf('day');
    const isLicensePlateValid =
        props.policies.length > 0
            ? props.policies.some(
                  (p) =>
                      normalizeLicensePlate(p.license_plate) ==
                          normalizeLicensePlate(props.accidentDetails.license_plate) &&
                      (!p.start_date || dayjs(p.start_date) <= accidentDate) &&
                      (!p.end_date || dayjs(p.end_date) >= accidentDate),
              )
            : true;

    let cardMedia;
    let status: RequestStatus | undefined;
    let isPartialSuccess;
    let navigateToCommand;
    if (props.accidentDetails.last_command) {
        navigateToCommand = (e: React.MouseEvent) => {
            e.stopPropagation();
            history.push(
                `/accidents/${props.accidentDetails.accident_id}/command/${props.accidentDetails.last_command.id}`,
            );
        };
        status = getRequestStatus(props.accidentDetails.last_command);
        try {
            const payload = JSON.parse(props.accidentDetails.last_command.data);
            isPartialSuccess = payload.preview_available;
        } catch {
            isPartialSuccess = false;
        }
    }
    let navigateToTrip;
    if (props.accidentDetails.trip_id) {
        navigateToTrip = (e: React.MouseEvent) => {
            e.stopPropagation();
            history.push(`/accidents/${props.accidentDetails.accident_id}/trip/${props.accidentDetails.trip_id}`);
        };
    }

    const changeNotificationStatus = (isRead: boolean) => {
        props.updateNotificationStatus(props.accidentDetails.accident_id, !isRead);
        agencyApi
            .agencyV2AccidentAccidentIdNotificationPost({
                accidentId: props.accidentDetails.accident_id,
                notificationRequestV2: { read: isRead },
            })
            .catch(() => {
                setAlert({ message: t('content.accidents.notifications.error'), type: 'error', duration: 6000 });
                props.updateNotificationStatus(props.accidentDetails.accident_id, isRead); // Revert the change
            });
    };

    if (props.accidentDetails.event) {
        if (!!props.image) {
            cardMedia = (
                <CardMedia
                    component="img"
                    image={props.image}
                    sx={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
                />
            );
        } else {
            cardMedia = (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={CARD_WIDTH}
                    height={CARD_WIDTH * IMAGE_ASPECT_RATIO}
                />
            );
        }
    } else if (status === 'pending') {
        cardMedia = (
            <AccidentCardImage
                navigateToCommand={navigateToCommand}
                icon={<VideoSearchIcon />}
                mainText={t('content.accidents.video.request_sent_header')}
                secondaryText={t('content.accidents.video.request_sent_body')}
            />
        );
    } else if (status === 'failure') {
        cardMedia = (
            <AccidentCardImage
                navigateToCommand={navigateToCommand}
                icon={<VideocamOffIcon />}
                mainText={t('content.accidents.video.no_video_header')}
                secondaryText={t(`content.accidents.video.no_video_body_${isPartialSuccess ? 'preview' : 'failure'}`)}
            />
        );
    } else if (status === 'success') {
        cardMedia = (
            <AccidentCardImage
                navigateToCommand={navigateToCommand}
                icon={<VideoSearchIcon />}
                mainText={t('content.accidents.video.request_succeeded')}
                secondaryText={t('content.accidents.video.request_succeeded_body')}
            />
        );
    } else if (!props.accidentDetails.event && !!props.accidentDetails.tags?.tag?.missing_video_reason) {
        cardMedia = (
            <AccidentCardImage
                icon={<VideocamOffIcon />}
                mainText={t('content.accidents.video.no_video_header')}
                secondaryText={t(
                    `content.accidents.tags.accident_details.missing_video_reason.${props.accidentDetails.tags.tag.missing_video_reason}`,
                )}
            />
        );
    } else if (!isLicensePlateValid) {
        cardMedia = (
            <AccidentCardImage
                icon={<RemoveModeratorIcon />}
                mainText={t('content.accidents.video.no_policy')}
                secondaryText={t('content.accidents.video.no_policy_body')}
            />
        );
    } else if (!!props.accidentDetails.trip_id) {
        cardMedia = (
            <AccidentCardImage
                navigateToCommand={navigateToTrip}
                icon={<SearchIcon />}
                mainText={t('content.accidents.video.find_video')}
                secondaryText={t('content.accidents.video.find_video_body')}
            />
        );
    } else if (!!props.accidentDetails.missing_trip_reason) {
        const isProtected = props.accidentDetails.missing_trip_reason !== 'no_active_device';
        cardMedia = (
            <AccidentCardImage
                icon={isProtected ? <NoTransferIcon /> : <RemoveModeratorIcon />}
                mainText={t(`content.accidents.video.${props.accidentDetails.missing_trip_reason}`)}
                secondaryText={t(`content.accidents.video.${props.accidentDetails.missing_trip_reason}_body`)}
            />
        );
    } else {
        cardMedia = (
            <CardMedia component="img" image={imageNA} sx={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }} />
        );
    }

    return (
        <Box sx={{ position: 'relative' }}>
            {alertElement}
            <Card
                sx={{
                    width: CARD_WIDTH,
                    height: CARD_HEIGHT,
                    display: 'flex',
                    flexDirection: 'column',
                    m: `${CARD_MARGIN}px`,
                    cursor: 'pointer',
                }}
                onClick={() => {
                    props.showBell ? changeNotificationStatus(true) : null;
                    history.push(`/accidents/${props.accidentDetails.accident_id}`);
                }}
                onMouseLeave={() => setAnchorPosition(null)}
                onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    setAnchorPosition({
                        mouseX: e.clientX,
                        mouseY: e.clientY,
                    });
                }}
            >
                <AccidentCardContextMenu
                    accident_id={props.accidentDetails.accident_id}
                    open={!!anchorPosition}
                    anchorEl={anchorPosition}
                    handleAccidentCardContextMenuClose={() => setAnchorPosition(null)}
                    changeNotificationStatus={changeNotificationStatus}
                    setAccidents={props.setAccidents}
                />
                <CardContent sx={{ p: 1, pb: 0 }}>
                    <Grid container columns={2}>
                        <Grid
                            item
                            xs={1}
                            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 0.5 }}
                        >
                            <CardField
                                name={t('content.accidents.filters.accident_id')}
                                value={props.accidentDetails.accident_id.toString()}
                                containerStyle={{ mb: 1 }}
                            />
                            <CardField name={t('table.headers.driver_name')} value={[]} containerStyle={{ mb: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                                    <Typography data-testid={'driver_name-value'} variant={'body2'}>
                                        {!!props.accidentDetails.driver_name
                                            ? props.accidentDetails.driver_name
                                            : t('content.safety.unknown')}
                                    </Typography>
                                    {!props.accidentDetails.driver_token ? (
                                        <Tooltip title={t('content.accidents.unknown_driver')}>
                                            <Box id={`unknown-driver-${props.accidentDetails.accident_id}`}>
                                                <UnknownDriverIcon sx={{ fontSize: 20 }} />
                                            </Box>
                                        </Tooltip>
                                    ) : null}
                                </Box>
                            </CardField>
                            <CardField name={t('table.headers.license_plate')} value={[]} containerStyle={{ mb: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                                    <Typography data-testid={'license_plate-value'} variant={'body2'}>
                                        {!!props.accidentDetails.license_plate
                                            ? props.accidentDetails.license_plate
                                            : t('content.safety.unknown')}
                                    </Typography>
                                    {!isLicensePlateValid ? (
                                        <Tooltip title={t('content.accidents.unknown_license_plate')}>
                                            <Box id={`unknown-license-plate-${props.accidentDetails.accident_id}`}>
                                                <UnknownVehicleIcon sx={{ fontSize: 20 }} />
                                            </Box>
                                        </Tooltip>
                                    ) : null}
                                </Box>
                            </CardField>
                            <CardField
                                name={t('table.headers.sub_fleet')}
                                value={props.accidentDetails.sub_fleet_name}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 0.5 }}
                        >
                            <Card sx={{ backgroundColor: palette.bgColor, p: 0.5 }}>
                                <CardField
                                    name={t('content.accidents.form.assignee')}
                                    value={[]}
                                    containerStyle={{ mt: 1 }}
                                >
                                    <AssigneeAvatar assignee={assignee} />
                                </CardField>
                                <CardField
                                    name={t('content.accidents.filters.created_at')}
                                    value={dayjs.unix(props.accidentDetails.created_at).format(profile.dateFormat)}
                                    containerStyle={{ mt: 1 }}
                                />
                            </Card>
                            <CardField
                                name={t('content.accidents.filters.timestamp')}
                                value={dayjs.unix(props.accidentDetails.timestamp).format(profile.dateTimeFormat)}
                                containerStyle={{ mt: 1 }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 'auto' }}>
                    <Box id={`${props.accidentDetails.accident_id}-card-media`}>{cardMedia}</Box>
                    <CardActions sx={{ display: 'flex', justifyContent: 'center', height: '32px', flexShrink: 0 }}>
                        <Link
                            id="card-buttom-placeholder"
                            component="button"
                            disabled
                            sx={{ textDecoration: 'none', cursor: 'default' }}
                        />
                    </CardActions>
                </Box>
            </Card>
            {props.showBell ? (
                <Box
                    id={`${props.accidentDetails.accident_id}-notification-icon`}
                    data-testid="notification-icon"
                    sx={{
                        position: 'absolute',
                        top: -12,
                        right: -17,
                        backgroundColor: 'secondary.main',
                        borderRadius: '50%',
                        border: `1px solid ${theme.palette.primary.main}`,
                        padding: '2px',
                        width: '35px',
                        height: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <NotificationsActiveIcon
                        sx={{
                            color: 'primary.main',
                            transform: 'rotate(25deg)',
                        }}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

export default AccidentCard;
