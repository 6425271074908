import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControl, Grid, IconButton, InputLabel, Paper, Select, SelectChangeEvent } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useState } from 'react';

import { HosDriver } from '../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedMenuItem as MenuItem,
} from '../../../../components/TrackedComponents';
import { formatDayjs } from '../../../../utils/TimeFormatter';

dayjs.extend(utc);

export interface NewEditRequestProps {
    drivers: HosDriver[];
    onSubmit: CallableFunction;
    onClose: CallableFunction;
}

const NewEditRequest: React.FC<NewEditRequestProps> = (props: NewEditRequestProps) => {
    const [driverId, setDriverId] = useState<string>(props.drivers[0].driver_id.toString());
    const [date, setDate] = useState<Dayjs>(dayjs().utc());

    return (
        <Dialog id="new-edit-request-dialog" open={true} fullWidth={true} maxWidth="xs" onClose={() => props.onClose()}>
            <Paper sx={{ backgroundColor: 'bgColor.main' }}>
                <Box
                    sx={{
                        backgroundColor: 'secondary.main',
                        position: 'sticky',
                        top: 0,
                        p: 1,
                    }}
                >
                    <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                        <IconButton size="small" onClick={() => props.onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AddIcon sx={{ mr: 0.5 }} />
                        New Edit Request
                    </Box>
                </Box>
                <Grid container columns={2} spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={1}>
                        <FormControl fullWidth>
                            <InputLabel id="driver-field-label">Driver</InputLabel>
                            <Select
                                labelId="driver-field-label"
                                id="driver-field"
                                value={driverId}
                                label="Driver"
                                onChange={(e: SelectChangeEvent) => setDriverId(e.target.value)}
                            >
                                {props.drivers.map((driver) => (
                                    <MenuItem
                                        id={`driver-${driver.driver_id}-menu-item`}
                                        key={driver.driver_id}
                                        value={driver.driver_id}
                                    >
                                        {`${driver.first_name} ${driver.last_name}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date"
                                format="MM/DD/YYYY"
                                value={date}
                                maxDate={dayjs().utc()}
                                onChange={(value: Dayjs | null) => {
                                    if (value !== null) {
                                        setDate(value);
                                    }
                                }}
                                slotProps={{ textField: { fullWidth: true } }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <Button
                            id="btn-create-edit-request"
                            color="secondary"
                            variant="contained"
                            onClick={() => props.onSubmit(driverId, formatDayjs(date))}
                        >
                            Create New Request
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    );
};

export default NewEditRequest;
