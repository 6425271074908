import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ExtendedAccident } from '../../../backendsdk';
import FormComponent, { Input, Select } from '../../../components/form';
import useProfile from '../../../hooks/profile';
import { normalizeLicensePlate } from '../../../utils/Str';

const uniq = (value: string | number, index: number, self: Array<string | number>): boolean => {
    return !!value && self.indexOf(value) === index;
};

const localeSort = (a: string, b: string) => a.localeCompare(b);

const localeUniqSort = (arr: Array<string>) =>
    arr
        .filter((elem) => !!elem)
        .filter(uniq)
        .sort(localeSort)
        .filter(uniq);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type filterType = Record<string, any>;

const SDivRight = styled.div`
    margin-right: 0;
    margin-left: auto;
    min-width: 30%;
    overflow-y: scroll;
`;

interface AccidentFiltersFormProps {
    filters: filterType;
    setFilters: CallableFunction;
    accidents: Array<ExtendedAccident>;
}

const AccidentFiltersForm: React.FC<AccidentFiltersFormProps> = (props: AccidentFiltersFormProps) => {
    const { t } = useTranslation();
    const { filters, setFilters } = props;
    const { profile } = useProfile();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateFilters = (key: string, value: any) => {
        setFilters((prev: filterType) => {
            const newFilters = { ...prev };
            if (!value) {
                delete newFilters[key];
            } else {
                newFilters[key] = value;
            }
            return newFilters;
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFilters(e.target.name, e.target.value);
    };

    const handleChangeMultiple = (e: React.ChangeEvent<HTMLInputElement>) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newValues = [...(e.target as any).selectedOptions].map((elem) => elem.value);
        const newValue = newValues.length > 0 ? newValues : '';
        updateFilters(e.target.name, newValue);
    };

    const LICENSE_PALTES = localeUniqSort(
        props.accidents.map((accident) => normalizeLicensePlate(accident.license_plate)),
    );
    const DRIVERS = localeUniqSort(props.accidents.map((accident) => accident.driver_name.trim()));
    const SUB_FLEETS = localeUniqSort(props.accidents.map((accident) => accident.sub_fleet_name));
    const ASSIGNEE = localeUniqSort(props.accidents.map((accident) => accident.assignee || '')).sort(
        (a: string, b: string) => (a == profile.name ? -1 : b == profile.name ? 1 : 0),
    );
    const ACCIDENT_TYPES = localeUniqSort(props.accidents.map((accident) => accident.category));

    return (
        <SDivRight style={{ paddingTop: '0.5em', paddingRight: '1em' }}>
            <FormComponent>
                <Input
                    label={t('content.accidents.filters.free')}
                    name="free"
                    value={filters['free'] || ''}
                    onChange={handleChange}
                />
                <Input
                    label={t('content.accidents.damage.claim_no')}
                    name="claim_number"
                    value={filters['claim_number'] || ''}
                    onChange={handleChange}
                />
                <Input
                    label={t('content.accidents.filters.accident_id')}
                    name="accident_id"
                    value={filters['accident_id'] || ''}
                    onChange={handleChange}
                />
                <Input
                    label={t('content.accidents.filters.internal_id')}
                    name="internal_id"
                    value={filters['internal_id'] || ''}
                    onChange={handleChange}
                />
                <Input
                    label={t('content.accidents.form.location')}
                    name="location"
                    value={filters['location'] || ''}
                    onChange={handleChange}
                />
                <Input
                    label={t('content.accidents.form.third_party_license_plate')}
                    name="third_party_license_plate"
                    value={filters['third_party_license_plate'] || ''}
                    onChange={handleChange}
                />
                <Select
                    label={t('content.accidents.form.assignee')}
                    name="assignee"
                    value={ASSIGNEE}
                    selectedList={filters['assignee']}
                    onChange={handleChangeMultiple}
                    multiple={true}
                    t={(s: string) => (s == profile.name ? `${s} (${t('content.accidents.filters.me')})` : s)}
                />
                <Select
                    label={t('content.accidents.form.category')}
                    name="category"
                    value={ACCIDENT_TYPES}
                    selectedList={filters['category']}
                    onChange={handleChangeMultiple}
                    multiple={true}
                    t={(s: string) =>
                        t(`content.accidents.form.categories.${s.toLocaleLowerCase().replaceAll(' ', '_')}`, s)
                    }
                />
                <Select
                    label={t('content.accidents.form.license_plate')}
                    name="license_plate"
                    value={LICENSE_PALTES}
                    selectedList={filters['license_plate']}
                    onChange={handleChangeMultiple}
                    multiple={true}
                />
                <Select
                    label={t('content.accidents.form.driver_name')}
                    name="driver_name"
                    value={DRIVERS}
                    selectedList={filters['driver_name']}
                    onChange={handleChangeMultiple}
                    multiple={true}
                />
                <Select
                    label={t('content.accidents.form.department')}
                    name="sub_fleet_name"
                    value={SUB_FLEETS}
                    selectedList={filters['sub_fleet_name']}
                    onChange={handleChangeMultiple}
                    multiple={true}
                />
                <Select
                    label={t('content.accidents.filters.damage_type.header')}
                    name="damage_type"
                    value={['own_property', '3rd_party_property', 'own_mbi', '3rd_party_mbi']}
                    selectedList={filters['damage_type']}
                    onChange={handleChangeMultiple}
                    multiple={true}
                    t={(s: string) => t(`content.accidents.filters.damage_type.${s}`)}
                />
            </FormComponent>
        </SDivRight>
    );
};

export default AccidentFiltersForm;
