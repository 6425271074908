import { SxProps, Theme } from '@mui/material';

const stringToHslColor = (str: string, s: number, l: number) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};
export const stringAvatar = (name: string, sx?: SxProps<Theme>) => {
    let avatarString = name;
    if (name.includes(' ')) {
        avatarString = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    } else {
        avatarString = name.slice(0, 2);
    }
    return {
        sx: {
            ...sx,
            bgcolor: stringToHslColor(name, 30, 50),
        },
        children: avatarString,
    };
};
