import CloseIcon from '@mui/icons-material/Close';
import { Box, Skeleton, Typography, capitalize } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CoachWellnessStatus } from '../../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../../components/TrackedComponents';
import useProfile from '../../../../hooks/profile';
import { WELLNESS_TYPE_MAP } from '../../Device/WellnessBox';
import { getItemIcon } from './AgendaItemModal';

const IMAGE_WIDTH = 220;
const IMAGE_HEIGHT = 82.5;

export const getWellnessType = (status: CoachWellnessStatus): string => {
    return status.in_cabin_wellness_status ? 'in_cabin_v1' : 'front_facing';
};

interface CoachingWellnessStatusProps {
    agendaItemId: number;
    status: CoachWellnessStatus;
    images: Record<string, string>;
    onDelete?: CallableFunction;
}

const CoachingWellnessStatus: React.FC<CoachingWellnessStatusProps> = ({
    agendaItemId,
    status,
    images,
    onDelete,
}: CoachingWellnessStatusProps) => {
    const { t } = useTranslation();
    const { profile } = useProfile();
    const wellnessType = getWellnessType(status);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const relevantWellness = (status.front_facing_wellness_status || status.in_cabin_wellness_status)!;
    const imageKey = `${wellnessType}:${relevantWellness.id}`;

    return (
        <Box
            data-testid={`status-${status.id}`}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', pr: 1 }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {images[imageKey] !== undefined ? (
                    <Box sx={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT, mr: 1, flexShrink: 0, position: 'relative' }}>
                        <img src={images[imageKey]} width="100%" />
                    </Box>
                ) : (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={IMAGE_WIDTH}
                        height={IMAGE_HEIGHT}
                        sx={{ mr: 1 }}
                    />
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: IMAGE_HEIGHT,
                        py: 1.5,
                        justifyContent: 'space-around',
                    }}
                >
                    <Typography variant="body3">{t(`content.wellness.${wellnessType}`)}</Typography>
                    <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                        {capitalize(
                            t(WELLNESS_TYPE_MAP[relevantWellness.wellness_state as keyof typeof WELLNESS_TYPE_MAP]),
                        )}
                    </Typography>
                    <Typography variant="body3">
                        {dayjs.unix(relevantWellness.timestamp).format(profile.dateFormat)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {getItemIcon('wellness_status', true)}
                {!!onDelete ? (
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete();
                        }}
                        id={`delete-item-${agendaItemId}`}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </Box>
        </Box>
    );
};

export default CoachingWellnessStatus;
