import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, ListItem, Typography } from '@mui/material';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

import { DeviceV3 } from '../../../backendsdk';
import { TrackedListItemButton as ListItemButton } from '../../../components/TrackedComponents';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { formatTime } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { RTLDirectionContext } from '../../Layout';
import WellnessPanel, { WELLNESS_TO_ACTION_MAP } from './DeviceDetails/WellnessPanel';
import { getFFWellnessDetails, getICWellnessDetails, getWellnessIconColor } from './utils';
import { RELEVANT_EVENT_TYPES } from './utils';

export const ONLINE_DEVICE_HEIGHT = 115;
export const OFFLINE_DEVICE_HEIGHT = 87;

interface ListRowData {
    devices: DeviceV3[];
    setHoveredDeviceId: CallableFunction;
    now: Date;
}

const DeviceListRow: React.FC<ListChildComponentProps<ListRowData>> = (props) => {
    const { index, style } = props;
    const device = props.data.devices[index];
    const { t } = useTranslation();
    const isRTL = useContext(RTLDirectionContext);
    const { profile } = useProfile();
    const history = useHistory();
    const isMobile = useIsMobile();

    const icWellnessDetails = getICWellnessDetails(device, profile.customer.settings.inactive_device_threshold);
    const ffWellnessDetails = getFFWellnessDetails(device, profile.customer.settings.inactive_device_threshold);
    const wellnessColor = getWellnessIconColor(icWellnessDetails, ffWellnessDetails);
    const WellnessIcon = wellnessColor === palette.wellness.red ? HeartBrokenIcon : FavoriteIcon;

    const driverName = device.device.driver_name.trim();

    const timeStringPrefix = !!device.current_trip
        ? `${t('content.fleet.current_trip')}:`
        : `${t('content.fleet.last_seen')}:`;
    const timeString = !!device.current_trip
        ? formatTime(props.data.now.getTime() / 1000 - device.current_trip.start_time, t, true)
        : !!device.last_seen
        ? t('time.ago', {
              time: formatTime(props.data.now.getTime() / 1000 - device.last_seen, t, true),
          })
        : t('content.fleet.unknown');

    let eventsField = null;
    if (!!device.current_trip) {
        let value;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const relevantEvents = device.current_trip.number_of_events_per_type!.filter((event) =>
            RELEVANT_EVENT_TYPES.includes(event.event_type),
        );
        const eventCount = relevantEvents.reduce((acc, event) => acc + event.count, 0);
        if (eventCount > 0) {
            value = eventCount === 1 ? t('content.fleet.event') : t('content.fleet.events', { count: eventCount });
        } else {
            value = t('content.fleet.no_events');
        }
        eventsField = (
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: 28, display: 'flex', alignItems: 'center' }}>
                    <WarningIcon
                        fontSize="small"
                        sx={{ color: eventCount === 0 ? 'wellnessGray.main' : palette.red[700] }}
                    />
                </Box>
                <Typography
                    id={`events-${device.device.device_id}`}
                    sx={{ color: eventCount === 0 ? 'wellnessGray.main' : palette.red[700] }}
                >
                    {value}
                </Typography>
            </Box>
        );
    }

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'wellness-popover',
    });

    const hasRequiredActions =
        !!WELLNESS_TO_ACTION_MAP[icWellnessDetails] ||
        !!WELLNESS_TO_ACTION_MAP[ffWellnessDetails as keyof typeof WELLNESS_TO_ACTION_MAP];

    return (
        <ListItem
            className="device-list-item"
            data-testid="device-list-item"
            style={style}
            key={device.device.device_id}
            disablePadding
            disableGutters
            sx={{
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: 'lightGrayColor.main',
                direction: 'ltr',
                height: !!device.current_trip ? ONLINE_DEVICE_HEIGHT : OFFLINE_DEVICE_HEIGHT,
            }}
            onMouseEnter={() => props.data.setHoveredDeviceId(device.device.device_id)}
            onMouseLeave={() => props.data.setHoveredDeviceId(undefined)}
        >
            <ListItemButton
                id={`vehicle-list-item-${device.device.device_id}`}
                data-testid={`vehicle-list-item-${device.device.device_id}`}
                onClick={() => {
                    props.data.setHoveredDeviceId(undefined);
                    history.push(`/overview/${encodeURIComponent(device.device.license_plate)}`);
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        py: 1,
                        position: 'relative',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 28, display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                                <LocalShippingIcon fontSize="small" sx={{ color: 'primary.main' }} />
                            </Box>
                            <Typography
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '60%',
                                }}
                            >
                                {device.device.license_plate}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mt: 0.5, width: '100%' }}>
                            <Box sx={{ width: 28, display: 'flex', alignItems: 'center' }}>
                                <PersonIcon
                                    fontSize="small"
                                    sx={{ color: !!driverName ? 'primary.main' : 'wellnessGray.main' }}
                                />
                            </Box>
                            <Typography
                                data-testid="list-item-driver-name"
                                sx={{
                                    color: !!driverName ? undefined : palette.neutral[700],
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '80%',
                                }}
                            >
                                {`${!!driverName ? driverName : t('content.fleet.unknown_driver')}${
                                    !!device.device.customer_sub_fleet ? ` (${device.device.customer_sub_fleet})` : ''
                                }`}
                            </Typography>
                        </Box>
                        {eventsField && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>{eventsField}</Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            flexShrink: 0,
                            position: 'absolute',
                            right: 0,
                            top: 4,
                        }}
                    >
                        {isRTL ? (
                            <>
                                <Typography fontSize={12} textAlign="right" sx={{ lineHeight: 0.75 }}>
                                    {timeStringPrefix}
                                </Typography>
                                <Typography fontSize={12} textAlign="right">
                                    {timeString}
                                </Typography>
                            </>
                        ) : (
                            <Typography fontSize={12} textAlign="right">
                                {`${timeStringPrefix} ${timeString}`}
                            </Typography>
                        )}
                        <Box {...bindHover(popupState)}>
                            <WellnessIcon fontSize="small" sx={{ color: wellnessColor, mt: isRTL ? 0.5 : 1.5 }} />
                        </Box>
                        <HoverPopover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: isRTL ? 'left' : 'right',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: isRTL ? 'right' : 'left',
                            }}
                        >
                            <Box
                                sx={{
                                    width: isMobile ? '225px' : '430px',
                                    p: 1,
                                    pb: hasRequiredActions ? 0.5 : 1,
                                }}
                            >
                                <WellnessPanel device={device} />
                            </Box>
                        </HoverPopover>
                    </Box>
                </Box>
            </ListItemButton>
        </ListItem>
    );
};

export default DeviceListRow;
