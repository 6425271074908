import {
    Button,
    ButtonProps,
    Dialog,
    DialogProps,
    IconButton,
    IconButtonProps,
    Link,
    LinkProps,
    ListItemButton,
    ListItemButtonProps,
    MenuItem,
    MenuItemProps,
    Tab,
    TabProps,
} from '@mui/material';
import { DataGridProProps, GridCallbackDetails, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';

import { StyledDataGrid } from '../layout/content/Users/MuiStyled';

interface TrackedButtonProps extends ButtonProps {
    id: string;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    buttonType?: string;
    component?: React.ElementType;
}

export const TrackedButton: React.FC<TrackedButtonProps> = (props) => {
    const { buttonType, buttonRef, ...rest } = props;
    return (
        <Button
            ref={buttonRef}
            {...rest}
            onClick={(e) => {
                mixpanel.track('click', {
                    id: props.id,
                    type: buttonType || 'button',
                    page: window.location.pathname,
                });
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
        />
    );
};

interface TrackedDataGridProps extends DataGridProProps {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails<any>) => void;
    onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
    // onRowClick?: (params: { id: string }) => void;
}

export const TrackedDataGrid: React.FC<TrackedDataGridProps> = (props) => {
    return (
        <StyledDataGrid
            {...props}
            onSortModelChange={(model, details) => {
                mixpanel.track('sort', {
                    id: props.id,
                    type: 'data-grid',
                    page: window.location.pathname,
                });
                if (props.onSortModelChange) {
                    props.onSortModelChange(model, details);
                }
            }}
            onFilterModelChange={(model, details) => {
                mixpanel.track('filter', {
                    id: props.id,
                    type: 'data-grid',
                    page: window.location.pathname,
                });
                if (props.onFilterModelChange) {
                    props.onFilterModelChange(model, details);
                }
            }}
        />
    );
};

interface TrackedDialogProps extends DialogProps {
    id: string;
    open: boolean;
}

export const TrackedDialog: React.FC<TrackedDialogProps> = (props) => {
    useEffect(() => {
        if (props.open) {
            mixpanel.track('open', {
                id: props.id,
                type: 'dialog',
                page: window.location.pathname,
            });
        }
    }, [props.open]);

    return (
        <Dialog
            {...props}
            onClose={(e, reason) => {
                mixpanel.track('close', {
                    id: props.id,
                    type: 'dialog',
                    page: window.location.pathname,
                });
                if (props.onClose) {
                    props.onClose(e, reason);
                }
            }}
        />
    );
};

interface TrackedIconButton extends IconButtonProps {
    id: string;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    buttonType?: string;
}

export const TrackedIconButton: React.FC<TrackedIconButton> = (props) => {
    const { buttonType, buttonRef, ...rest } = props;
    return (
        <IconButton
            ref={buttonRef}
            {...rest}
            onClick={(e) => {
                mixpanel.track('click', {
                    id: props.id,
                    type: buttonType || 'icon-button',
                    page: window.location.pathname,
                });
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
        />
    );
};

interface TrackedMenuItemProps extends MenuItemProps {
    id: string;
    type?: string;
}

export const TrackedMenuItem: React.FC<TrackedMenuItemProps> = (props) => {
    return (
        <MenuItem
            {...props}
            onClick={(e) => {
                mixpanel.track('click', {
                    id: props.id,
                    type: props.type || 'menu-item',
                    page: window.location.pathname,
                });
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
        />
    );
};

interface TrackedLinkProps extends LinkProps {
    id: string;
    component?: React.ElementType;
    to?: string;
    disabled?: boolean;
}

export const TrackedLink: React.FC<TrackedLinkProps> = (props) => {
    return (
        <Link
            {...props}
            onClick={(e) => {
                mixpanel.track('click', {
                    id: props.id,
                    type: 'link',
                    page: window.location.pathname,
                });
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
        />
    );
};

interface TrackedListItemButtonProps extends ListItemButtonProps {
    id: string;
    type?: string;
    component?: React.ElementType;
    to?: string;
    track?: Record<string, string | number>;
}

export const TrackedListItemButton: React.FC<TrackedListItemButtonProps> = (props) => {
    return (
        <ListItemButton
            {...props}
            onClick={(e) => {
                mixpanel.track('click', {
                    id: props.id,
                    page: window.location.pathname,
                    type: props.type || 'list-item-button',
                    ...props.track,
                });
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
        />
    );
};

interface TrackedTabProps extends TabProps {
    id: string;
}

export const TrackedTab: React.FC<TrackedTabProps> = (props) => {
    return (
        <Tab
            {...props}
            onClick={(e) => {
                mixpanel.track('click', {
                    id: props.id,
                    type: 'tab',
                    page: window.location.pathname,
                });
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
        />
    );
};
