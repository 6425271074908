import PlaceIcon from '@mui/icons-material/Place';
import { Box, List, ListItem, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedLink as Link, TrackedListItemButton as ListItemButton } from '../../../../components/TrackedComponents';
import Polygon from '../../../../images/polygon.svg';
import palette from '../../../ColorPalette';
import { GeofenceGeoJSON } from '../FleetOverview';

interface GeofenceListProps {
    geofences: GeofenceGeoJSON[];
    setSelectedGeofence: CallableFunction;
    setHoveredGeofence: CallableFunction;
    onRetry: CallableFunction;
    isLoading: boolean;
    isError: boolean;
}

const GeofenceList: React.FC<GeofenceListProps> = (props: GeofenceListProps) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const polygonIcon = (
        <img
            src={Polygon}
            alt="polygon"
            width={30}
            height={30}
            style={{
                border: `1px solid ${palette.black}`,
                padding: '5px',
                margin: '0 5px',
                display: 'block',
                borderRadius: '3px',
            }}
        />
    );

    if (props.isLoading) {
        return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 2 }}>
                <Typography variant="overline">{t('content.fleet.geofence.loading')}</Typography>
            </Box>
        );
    } else if (props.isError) {
        return (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                <Typography variant="overline" sx={{ mb: -1 }}>
                    {t('content.fleet.geofence.error')}
                </Typography>
                <Link id="try-again-link" component="button" variant="overline" onClick={() => props.onRetry()}>
                    {t('content.fleet.geofence.retry')}
                </Link>
            </Box>
        );
    } else if (props.geofences.length === 0) {
        return (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
                <Typography variant="overline" sx={{ mb: -1 }}>
                    {t('content.fleet.geofence.no_geofences')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="overline" sx={{ pt: 0.5 }}>
                        {t('content.fleet.geofence.add_instructions1')}
                    </Typography>
                    {polygonIcon}
                    <Typography variant="overline" sx={{ pt: 0.5 }}>
                        {t('content.fleet.geofence.add_instructions2')}
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <List disablePadding>
            {props.geofences.map((geofence) => (
                <ListItem
                    data-testid={`geofence-list-item`}
                    disablePadding
                    disableGutters
                    key={geofence.properties.id}
                    sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'lightGrayColor.main' }}
                >
                    <ListItemButton
                        id={`geofence-list-button-${geofence.properties.id}`}
                        data-testid={`geofence-list-button-${geofence.properties.id}`}
                        onMouseEnter={() => props.setHoveredGeofence(geofence.properties.id)}
                        onMouseLeave={() => props.setHoveredGeofence(undefined)}
                        onClick={() => {
                            props.setSelectedGeofence(geofence.properties.id);
                            props.setHoveredGeofence(undefined);
                        }}
                        sx={{ display: 'flex', alignItems: 'center', py: 1.5 }}
                    >
                        <Box sx={{ width: 24, height: 24, mr: 1, position: 'relative' }}>
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="12" fill={geofence.properties.color} />
                            </svg>
                            <Box
                                sx={{
                                    width: 24,
                                    height: 24,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                            >
                                <PlaceIcon
                                    sx={{
                                        color: theme.palette.getContrastText(geofence.properties.color),
                                        fontSize: 20,
                                    }}
                                />
                            </Box>
                        </Box>
                        <Typography>{geofence.properties.name}</Typography>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default GeofenceList;
