import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';
import PauseIcon from '@mui/icons-material/Pause';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Skeleton, Slider, SxProps, ThemeProvider, Tooltip, Typography, alpha } from '@mui/material';
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import { useIsVisible } from '../../../hooks/isVisible';
import useProfile from '../../../hooks/profile';
import { TIME_INTERVALS } from '../../../utils/TimeFormatter';
import { isLocationValid } from '../../../utils/location';
import palette from '../../ColorPalette';
import { RTLDirectionContext } from '../../Layout';
import { GpsTrace, IC_EVENTS_LIST, INVALID_SPEED } from './Defs';

const formatTime = (time: number) => {
    if (isNaN(time)) {
        return '00:00';
    }
    const days = Math.floor(time / TIME_INTERVALS.Day);
    const hours = Math.floor((time % TIME_INTERVALS.Day) / TIME_INTERVALS.Hour);
    const minutes = Math.floor((time % TIME_INTERVALS.Hour) / TIME_INTERVALS.Minute);
    const seconds = Math.floor(time % TIME_INTERVALS.Minute);
    if (days > 0) {
        return `${days}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
    } else if (hours > 0) {
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export interface EventDetailsComponentProps {
    eventId: number;
    eventType: string;
    artifact: string;
    viewMode: 'pip' | 'vertical';
    setPercentVideoPlayed?: CallableFunction;
    overlay?: string;
    gpsTrace?: Array<GpsTrace>;
    setAllowEdit?: CallableFunction;
    setCurrentLocation?: CallableFunction;
    setCurrentTime?: CallableFunction;
    setVideoDuration?: CallableFunction;
    isGeneratingVideo?: boolean;
    controls?: boolean;
    onPlay?: CallableFunction;
    onPause?: CallableFunction;
    isRendered?: boolean;
    setIsLoaded?: CallableFunction;
    showOriginalVideo?: boolean;
    onLoadedMetadata?: CallableFunction;
    onDurationChange?: CallableFunction;
    onTimeUpdate?: CallableFunction;
    containerStyle?: SxProps;
    children?: React.ReactNode;
}

const EventVideoComponent: React.FC<EventDetailsComponentProps> = (props: EventDetailsComponentProps) => {
    const { eventId, eventType, artifact, gpsTrace } = props;
    const [isInCabinPrimary, setIsInCabinPrimary] = useState<boolean>(IC_EVENTS_LIST.includes(eventType));
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [seekLocation, setSeekLocation] = useState<number>(0);
    const [seekingInfo, setSeekingInfo] = useState<{ isDragging: boolean; wasPlaying: boolean }>({
        isDragging: false,
        wasPlaying: false,
    });
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [speedMeterPerSecond, setSpeedMeterPerSecond] = useState<number>(INVALID_SPEED);
    const [aspectRatio, setAspectRatio] = useState<string>('4 / 3');
    const [showOverlay, setShowOverlay] = useState<boolean>(true);
    const { profile } = useProfile();
    const { t } = useTranslation();
    const { isVisible, targetRef } = useIsVisible<HTMLDivElement>(
        {
            root: null,
            threshold: 1,
        },
        false,
    );
    const isRTL = useContext(RTLDirectionContext);
    const location = useLocation();
    const primaryVideoRef = useRef<HTMLVideoElement | null>(null);
    const secondaryVideoRef = useRef<HTMLVideoElement | null>(null);
    const isOverlayView = props.viewMode === 'pip';
    const isVerticalMonitor =
        !props.showOriginalVideo &&
        fullScreen &&
        document.documentElement.clientWidth > document.documentElement.clientHeight;
    const artifactWithHash = location.hash ? `${artifact}${location.hash}` : artifact;

    const handleStart = () => {
        // catch error in case the video already paused (play is async)
        primaryVideoRef.current?.play().catch(() => null);
        secondaryVideoRef.current?.play().catch(() => null);

        setIsPlaying(true);
        if (props.onPlay) {
            props.onPlay();
        }
    };

    const handleStop = () => {
        primaryVideoRef.current?.pause();
        secondaryVideoRef.current?.pause();
        setIsPlaying(false);
    };

    const videoDuration = primaryVideoRef.current?.duration || 0;

    const speedFactor = profile.locale === 'il' ? 1 : 0.621371;
    const speedText = profile.locale === 'il' ? 'kmh' : 'mph';

    useEffect(() => {
        if (props.viewMode === 'vertical') {
            if (isVisible) {
                handleStart();
            } else {
                handleStop();
            }
        }
    }, [isVisible]);

    useEffect(() => {
        if (props.showOriginalVideo) {
            setAspectRatio('8 / 3');
        } else if (props.viewMode === 'pip') {
            setAspectRatio('4 / 3');
        } else if (primaryVideoRef.current) {
            const videoWidth = primaryVideoRef.current.videoWidth;
            const videoHeight = primaryVideoRef.current.videoHeight;
            if (videoWidth / videoHeight === 16 / 9 || videoWidth / videoHeight === 4 / 3) {
                setAspectRatio(`${videoWidth} / ${videoHeight}`);
            }
        }
    }, [props.showOriginalVideo, props.viewMode, primaryVideoRef.current]);

    useEffect(() => {
        if (props.setVideoDuration) {
            props.setVideoDuration(primaryVideoRef.current?.duration);
        }
    }, [primaryVideoRef.current?.duration]);

    const handleSeekerChange = (_event: Event, value: number | number[]) => {
        let newValue = value as number;
        if (newValue < 3) {
            newValue = 0;
        }
        setSeekLocation(newValue);
        const newTime = (newValue * videoDuration) / 100;
        if (primaryVideoRef.current) {
            primaryVideoRef.current.currentTime = newTime;
        }
        if (secondaryVideoRef.current) {
            secondaryVideoRef.current.currentTime = newTime;
        }
    };

    let videoOverlay = null;
    if (!!props.overlay || speedMeterPerSecond != INVALID_SPEED) {
        videoOverlay = (
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: alpha(palette.black, 0.3),
                    p: 0.5,
                    zIndex: 2000,
                }}
            >
                <Typography sx={{ fontSize: fullScreen ? 18 : 12, fontWeight: 'bold', color: palette.white }}>
                    {!!props.overlay
                        ? props.overlay
                        : `${t('content.events.speed')}: ${Math.round(speedMeterPerSecond * 3.6 * speedFactor)} ${t(
                              `content.events.${speedText}`,
                          )}`}
                </Typography>
            </Box>
        );
    }

    useEffect(() => {
        // make sure to update the full screen state when the user exits full screen mode
        const syncFullScreenState = () => setFullScreen(document.fullscreenElement !== null);
        document.addEventListener('fullscreenchange', syncFullScreenState);

        return () => {
            document.removeEventListener('fullscreenchange', syncFullScreenState);
        };
    }, []);

    if (props.isGeneratingVideo) {
        return (
            <Box sx={{ width: '100%', aspectRatio }}>
                <Skeleton sx={{ width: '100%', height: '100%' }} variant="rectangular" animation="wave" />
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                >
                    <Typography variant="overline">{t('content.events.generating_video')}</Typography>
                </Box>
            </Box>
        );
    }

    let primaryVideoStyle: CSSProperties = {};
    let secondaryVideoStyle: CSSProperties = {};
    if (isOverlayView) {
        primaryVideoStyle = {
            width: isVerticalMonitor ? undefined : '100%',
            height: isVerticalMonitor ? '100vh' : undefined,
            aspectRatio,
            objectFit: 'cover',
            objectPosition: isInCabinPrimary ? '0 100%' : '100% 0',
        };
        secondaryVideoStyle = {
            position: 'absolute',
            bottom: 10,
            left: 10,
            width: '33%',
            height: '33%',
            objectFit: 'cover',
            objectPosition: isInCabinPrimary ? '100% 0' : '0 100%',
        };
    } else {
        primaryVideoStyle = {
            width: '100%',
            aspectRatio,
            objectFit: 'cover',
            objectPosition: '0 100%',
        };
        secondaryVideoStyle = {
            width: '100%',
            aspectRatio,
            objectFit: 'cover',
            objectPosition: '100% 0',
        };
    }
    if (!showOverlay) {
        secondaryVideoStyle = { ...secondaryVideoStyle, display: 'none' };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sliderStyles: any = {
        color: palette.neutral[50],
        mx: 1,
        '& .MuiSlider-mark': {
            backgroundColor: palette.accent,
            opacity: 1,
            zIndex: 5,
        },
    };

    if (isRTL) {
        sliderStyles['& .MuiSlider-thumb'] = {
            transform: 'translateX(5px) translateY(-10px)',
        };
    } else {
        sliderStyles['& .MuiSlider-mark'].transform = 'scale(4) translateY(-15%) translateX(-15%)';
    }

    return (
        <Box
            id="event-video-dialog"
            ref={targetRef}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 3,
                ...(props.containerStyle || {}),
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: isVerticalMonitor ? undefined : '100%',
                    height: isVerticalMonitor ? '100vh' : undefined,
                }}
            >
                {videoOverlay}
                {/* Primary Video */}
                <video
                    id={`${eventId}-video`}
                    ref={primaryVideoRef}
                    src={artifactWithHash}
                    muted
                    loop
                    style={primaryVideoStyle}
                    onTimeUpdate={(event) => {
                        const { currentTime, duration } = event.currentTarget;
                        if (props.setCurrentTime) {
                            props.setCurrentTime(currentTime);
                        }
                        setSeekLocation((100 * currentTime) / duration);
                        if (gpsTrace && gpsTrace.length > 0 && duration !== undefined) {
                            const idx = Math.round(Math.min(currentTime / duration, 1) * (gpsTrace.length - 1));
                            setSpeedMeterPerSecond(gpsTrace[idx].speed);
                            const lat = gpsTrace[idx].lat.toString();
                            const lng = gpsTrace[idx].lng.toString();
                            if (isLocationValid(`${lat}:${lng}`)) {
                                if (props.setCurrentLocation) {
                                    props.setCurrentLocation(`${lat}:${lng}`);
                                }
                            }
                        }
                        if (props.setPercentVideoPlayed !== undefined && videoDuration !== undefined) {
                            props.setPercentVideoPlayed(currentTime / duration);
                        }
                        if (props.onTimeUpdate) {
                            props.onTimeUpdate(event);
                        }
                    }}
                    onCanPlay={() => {
                        if (props.setAllowEdit) {
                            props.setAllowEdit(true);
                        }
                    }}
                    onLoadedMetadata={(event) => {
                        if (props.onLoadedMetadata) {
                            props.onLoadedMetadata(event);
                        }
                    }}
                    onDurationChange={(event) => {
                        if (props.onDurationChange) {
                            props.onDurationChange(event);
                        }
                    }}
                    onClick={() => {
                        if (props.controls) {
                            isPlaying ? handleStop() : handleStart();
                        }
                    }}
                    onDoubleClick={() => {
                        if (props.controls) {
                            fullScreen ? document.exitFullscreen() : targetRef.current?.requestFullscreen();
                            setFullScreen((prev) => !prev);
                        }
                    }}
                    onPause={() => {
                        if (props.onPause) {
                            props.onPause();
                        }
                    }}
                />
                {props.children}
                {/* Secondary Video */}
                {!props.showOriginalVideo ? (
                    <video
                        id={`${eventId}-secondary-video`}
                        ref={secondaryVideoRef}
                        src={artifactWithHash}
                        muted
                        loop
                        style={secondaryVideoStyle}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (isOverlayView) {
                                setIsInCabinPrimary((prev) => !prev);
                            } else if (props.controls) {
                                isPlaying ? handleStop() : handleStart();
                            }
                        }}
                    />
                ) : null}
            </Box>
            {props.controls ? (
                <ThemeProvider theme={(outerTheme) => ({ ...outerTheme, direction: 'ltr' })}>
                    <Box
                        sx={{
                            position: fullScreen ? 'absolute' : undefined,
                            bottom: fullScreen ? '0' : undefined,
                            width: '100%',
                            zIndex: 2000,
                            backgroundColor: 'rgba(0,0,0,0.7)',
                            display: 'isReady' ? 'flex' : 'none',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: isRTL ? 'row-reverse' : 'row',
                        }}
                    >
                        <Tooltip title={t(`content.events.video.controls.${isPlaying ? 'pause' : 'play'}`)}>
                            <Box>
                                <IconButton
                                    id="play-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        isPlaying ? handleStop() : handleStart();
                                    }}
                                    sx={{ color: palette.neutral[50] }}
                                >
                                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Slider
                            aria-label="seek"
                            value={seekLocation}
                            onChange={handleSeekerChange}
                            sx={sliderStyles}
                            onMouseDown={() => {
                                handleStop();
                                setSeekingInfo({ isDragging: true, wasPlaying: isPlaying });
                            }}
                            onMouseUp={() => {
                                if (seekingInfo.wasPlaying) {
                                    handleStart();
                                }
                                setSeekingInfo((p) => ({ isDragging: false, wasPlaying: p.wasPlaying }));
                            }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: isRTL ? 'row-reverse' : 'row' }}>
                            <Typography sx={{ whiteSpace: 'nowrap', color: palette.neutral[50], fontSize: 14 }}>
                                {seekLocation && videoDuration
                                    ? formatTime((seekLocation * videoDuration) / 100)
                                    : '00:00'}
                            </Typography>
                            <Typography sx={{ whiteSpace: 'nowrap', color: palette.neutral[400], fontSize: 14 }}>
                                /
                            </Typography>
                            <Typography sx={{ whiteSpace: 'nowrap', color: palette.neutral[400], fontSize: 14 }}>
                                {videoDuration ? formatTime(videoDuration) : '00:00'}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', px: 1 }}>
                            <Tooltip title={t('content.events.video.controls.download')}>
                                <Box>
                                    <IconButton
                                        id="download-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(artifact, '_blank', 'noopener,noreferrer');
                                        }}
                                        sx={{ color: palette.neutral[50], px: 0.25 }}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                            <Tooltip
                                title={t(`content.events.video.controls.${fullScreen ? 'exit' : 'open'}_full_screen`)}
                            >
                                <Box>
                                    <IconButton
                                        id="full-screen-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            fullScreen
                                                ? document.exitFullscreen()
                                                : targetRef.current?.requestFullscreen();
                                            setFullScreen((prev) => !prev);
                                        }}
                                        sx={{ color: palette.neutral[50], px: 0.25 }}
                                    >
                                        {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                    </IconButton>
                                </Box>
                            </Tooltip>
                            {!props.showOriginalVideo ? (
                                <Tooltip
                                    title={t(`content.events.video.controls.${showOverlay ? 'hide' : 'show'}_pip`)}
                                >
                                    <Box>
                                        <IconButton
                                            id="pip-button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowOverlay((p) => !p);
                                            }}
                                            sx={{ color: palette.neutral[50], px: 0.25 }}
                                        >
                                            {showOverlay ? (
                                                <ImageAspectRatioIcon />
                                            ) : (
                                                <PictureInPictureAltIcon sx={{ transform: 'rotateY(180deg)' }} />
                                            )}
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                            ) : null}
                        </Box>
                    </Box>
                </ThemeProvider>
            ) : null}
        </Box>
    );
};

export default EventVideoComponent;
