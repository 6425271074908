import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import { Box, Card, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';

interface RequestPermissionsModalProps {
    onClose: () => void;
    onRequest: (request: string) => void;
}

const RequestPermissionsModal: React.FC<RequestPermissionsModalProps> = (props: RequestPermissionsModalProps) => {
    const [request, setRequest] = useState<string>('');
    const { t } = useTranslation();

    return (
        <Dialog
            id="request-permissions-dialog"
            open={true}
            fullWidth={true}
            maxWidth="xs"
            onClose={() => props.onClose()}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <MailIcon sx={{ mr: 0.5 }} />
                    {t('content.user_settings.permissions.header')}
                </Box>
            </Box>
            <Paper sx={{ backgroundColor: 'bgColor.main', p: 1, borderRadius: 0 }}>
                <Card sx={{ p: 2 }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="body2">{t('content.user_settings.permissions.instructions1')}</Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            {t('content.user_settings.permissions.instructions2')}
                        </Typography>
                        <TextField
                            id="request-input"
                            size="small"
                            multiline
                            rows={3}
                            value={request}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRequest(e.target.value)}
                            inputProps={{ 'data-testid': 'request-input' }}
                        />
                    </Box>
                </Card>
                <Grid container columns={2} columnSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={1}>
                        <Button
                            id="btn-cancel"
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={() => props.onClose()}
                        >
                            {t('content.user_settings.permissions.cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            fullWidth
                            id="request-btn"
                            data-testid="request-btn"
                            color="secondary"
                            variant="contained"
                            disabled={!request}
                            onClick={() => {
                                props.onRequest(request);
                            }}
                        >
                            {t('content.user_settings.permissions.send')}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    );
};

export default RequestPermissionsModal;
