import React, { useCallback, useState } from 'react';

React;

// Custom hook to avoid using React's useRef
// https://tkdodo.eu/blog/avoiding-use-effect-with-callback-refs
const useCallbackRef = <THTMLElement extends HTMLElement>() => {
    const [node, setNode] = useState<THTMLElement | null>(null);

    const ref = useCallback((newNode: THTMLElement | null) => {
        setNode(newNode);
    }, []);

    return [ref, node] as const;
};

export default useCallbackRef;
