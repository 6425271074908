import { Box, Skeleton, Typography, alpha } from '@mui/material';
import dayjs from 'dayjs';
import capitalize from 'lodash.capitalize';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import imageNA from '../../../images/image_not_available.png';
import palette from '../../ColorPalette';
import { WELLNESS_TYPE_MAP } from '../Device/WellnessBox';

interface WellnessDetailsProps {
    wellnessType: string;
    wellnessId: number;
    wellnessState: string;
    wellnessTimestamp: number | null;
}

const WellnessDetails: React.FC<WellnessDetailsProps> = ({
    wellnessType,
    wellnessId,
    wellnessState,
    wellnessTimestamp,
}: WellnessDetailsProps) => {
    const { api } = useApi();
    const [wellnessImage, setWellnessImage] = useState<string>();
    const { t } = useTranslation();
    const { profile } = useProfile();

    useEffect(() => {
        api.apiV2WellnessImageWellnessTypeGet(
            {
                wellnessType,
                wellnessId: wellnessId.toString(),
            },
            { responseType: 'blob' },
        )
            .then((res) => {
                const url = URL.createObjectURL(res.data);
                setWellnessImage(url);
            })
            .catch(() => {
                setWellnessImage(imageNA);
            });
    }, [wellnessId]);

    const state = capitalize(t(WELLNESS_TYPE_MAP[wellnessState as keyof typeof WELLNESS_TYPE_MAP]));

    const wellnessImageSkeleton = (
        <div style={{ width: '100%', aspectRatio: 4 / 3, boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)' }}>
            <Skeleton variant="rectangular" width="100%" height="100%" animation="wave" />
        </div>
    );

    return (
        <Box sx={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 1 }}>
            <Typography fontSize={12} sx={{ color: 'grayColor.main', fontWeight: 'bold' }}>
                {t('content.malfunctions.details.status')}
            </Typography>
            <Typography id="wellness-state-field" fontSize={16} sx={{ mb: 1 }}>
                {state}
            </Typography>
            {!!wellnessImage ? (
                <div style={{ width: '100%', aspectRatio: 4 / 3, position: 'relative' }}>
                    <img
                        id="wellness-image"
                        data-testid="wellness-image"
                        src={wellnessImage}
                        className={`wellness-image ${
                            wellnessImage !== imageNA ? (wellnessType === 'in_cabin_v1' ? 'ic-image' : 'ff-image') : ''
                        }`}
                        style={{ boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)' }}
                    />
                    {!!wellnessTimestamp ? (
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                backgroundColor: alpha(palette.black, 0.3),
                                px: 0.5,
                            }}
                        >
                            <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: palette.white }}>
                                {dayjs.unix(wellnessTimestamp).format(profile.dateTimeFormat)}
                            </Typography>
                        </Box>
                    ) : null}
                </div>
            ) : (
                wellnessImageSkeleton
            )}
        </Box>
    );
};

export default WellnessDetails;
