import ContentCutIcon from '@mui/icons-material/ContentCut';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import PersonIcon from '@mui/icons-material/Person';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import RouteIcon from '@mui/icons-material/Route';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Menu, SvgIcon, Tooltip, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
    CoachEventDetailsActionEnum,
    EventDetails,
    EventOverlayRequest,
    EventTagActionEnum,
    TripDetails,
    TripDetailsDriverAssignmentMethodEnum,
} from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedLink as Link,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import { ExtendedFleetDriver } from '../../../hooks/drivers';
import useProfile from '../../../hooks/profile';
import { getCustomer } from '../../../utils/customer';
import { RTLDirectionContext } from '../../Layout';
import QuickCoachingModal from '../Accident/AccidentV1/Modal/QuickCoachingModal';
import { isEventBlurred } from '../Coaching/SessionDetails';
import ChangeDriverDialog from '../Trip/ChangeDriverDialog';
import { OE_ROLES } from '../Users/UsersComponent';
import { STATUS } from '../Vehicles/VehicleModal';
import CreateOverlayDialog from './CreateOverlayDialog';
import { canBeTrimmed } from './EventClone';
import EventCoachingComponent from './EventCoaching';
import ReportAccident from './ReportAccident';
import SendEventModal from './SendEventModal';

const CRASH_INCIDENT_EVENT_TYPE = 'MsgCrashIncident';
const CRASH_DISMISSED_TAG = 'crash-dismissed';
const CRASH_REPORTED_TAG = 'crash-reported';

const StyledButton = styled(Button)(({ theme }) => ({
    width: '40px',
    height: '40px',
    minWidth: 0,
    padding: 0,
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export interface EventDetailsComponentProps {
    details: EventDetails;
    setDetails: CallableFunction;
    updateTable?: CallableFunction;
    allowEdit: boolean;
    setAlert: CallableFunction;
    showEditVideo?: boolean;
    showCoaching?: boolean;
    showReportAccident?: boolean;
    showEventRoute?: boolean;
    showFindVideo?: boolean;
    showShareEvent?: boolean;
    showChangeDriver?: boolean;
    updateTripDetails?: CallableFunction;
    handleDismiss?: CallableFunction;
    currentAccidentId?: number;
    // Clone form is related to the video, therefor cannot be inside the actions bar
    showCloneForm?: boolean;
    setShowCloneForm?: CallableFunction;
    driver?: ExtendedFleetDriver;
}

const EventActionsComponent: React.FC<EventDetailsComponentProps> = (props: EventDetailsComponentProps) => {
    const [isReportAccidentOpen, setIsReportAccidentOpen] = useState<boolean>(false);
    const [isChangeDriverOpen, setIsChangeDriverOpen] = useState<boolean>(false);
    const [isCreateEventOverlay, setIsCreateEventOverlay] = useState<boolean>(false);
    const [isSendEventOpen, setIsSendEventOpen] = useState<boolean>(false);
    const [isQuickCoachingOpen, setIsQuickCoachingOpen] = useState<boolean>(false);
    const [anchorElShare, setAnchorElShare] = useState<null | HTMLElement>(null);
    const [isSaved, setIsSaved] = useState<boolean>(props.details.coach.event != undefined);
    const [coachingModal, setCoachingModal] = useState<boolean>(false);
    const history = useHistory();
    const { profile } = useProfile();
    const { t } = useTranslation();
    const isShareOpen = Boolean(anchorElShare);
    const isRTL = useContext(RTLDirectionContext);
    const { details } = props;
    const { api } = useApi();

    const { data: customer } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['customer'],
        queryFn: () => getCustomer(api),
    });
    const customerUsers = customer?.users || [];

    const handleShareMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElShare(event.currentTarget);
    };

    const handleChangeDriverModal = () => {
        setIsChangeDriverOpen((p: boolean) => !p);
    };

    const handleShareMenuClose = () => {
        setAnchorElShare(null);
    };

    const showDismissButton =
        OE_ROLES.includes(profile.role) &&
        details.type === CRASH_INCIDENT_EVENT_TYPE &&
        !details.tags.includes(CRASH_DISMISSED_TAG) &&
        !details.tags.includes(CRASH_REPORTED_TAG);

    const handleReportAccident = () => {
        if (
            OE_ROLES.includes(profile.role) &&
            details.type === CRASH_INCIDENT_EVENT_TYPE &&
            !details.tags.includes(CRASH_REPORTED_TAG)
        ) {
            api.apiV0EventEventIdTagPost({
                eventId: details.event_id,
                eventTag: { name: CRASH_REPORTED_TAG, action: EventTagActionEnum.Add },
            }).then(() => {
                props.setDetails((prev: EventDetails) => {
                    if (prev !== undefined) {
                        return { ...prev, tags: [...prev.tags, CRASH_REPORTED_TAG] };
                    }
                    return prev;
                });
                if (props.updateTable) {
                    props.updateTable({ ...details, tags: [...details.tags, CRASH_REPORTED_TAG] });
                }
                window.open(`/accidents/0?event_id=${details.event_id}`, '_blank');
            });
        } else {
            history.push(`/accidents/0?event_id=${details.event_id}`);
        }
    };

    const handleEventAction = (status: STATUS, comment?: string) => {
        if (status === STATUS.SAVED) {
            setCoachingModal(false);
        }
        // add alert on remove succeed
        api.apiV0EventEventIdCoachPost({
            eventId: props.details.event_id,
            coachEventDetails: {
                action: status === STATUS.SAVED ? CoachEventDetailsActionEnum.Add : CoachEventDetailsActionEnum.Remove,
                comment,
            },
        })
            .then((res) => {
                if (status === STATUS.SAVED) {
                    const link = (
                        <Link
                            id="view-coaching-session-link"
                            component={RouterLink}
                            to={`/coaching/${res.data.session_id}`}
                        >
                            {t('content.safety.view_session')}
                        </Link>
                    );
                    setIsSaved(true);
                    props.setAlert({
                        message: t('content.safety.save_success'),
                        link,
                        type: 'success',
                        duration: 6000,
                    });
                } else {
                    setIsSaved(false);
                    props.setAlert({
                        message: t('content.events.coach.remove_success'),
                        type: 'success',
                        duration: 6000,
                    });
                }
            })
            .catch((err) => {
                if (status === STATUS.DISMISSED && err.response?.data?.detail === 'event in completed session') {
                    return props.setAlert({
                        message: t('content.safety.dismiss_fail_completed'),
                        type: 'error',
                        duration: 6000,
                    });
                } else {
                    const message =
                        status === STATUS.SAVED ? t('content.safety.save_fail') : t('content.safety.dismiss_fail');
                    props.setAlert({ message, type: 'error', duration: 6000 });
                }
            });
    };

    const disableQuickCoaching = isSaved || !props.driver;
    let quickCoachingMenuItem = (
        <Box>
            <MenuItem
                id="quick-coaching-menu-item"
                onClick={() => {
                    setIsQuickCoachingOpen(true);
                    handleShareMenuClose();
                }}
                disabled={disableQuickCoaching}
            >
                {t('content.accidents.modal_header.share.send_coaching')}
            </MenuItem>
        </Box>
    );
    if (disableQuickCoaching) {
        quickCoachingMenuItem = (
            <Tooltip title={isSaved ? t('content.events.coach.already_saved') : t('content.events.coach.no_driver')}>
                <Box>{quickCoachingMenuItem}</Box>
            </Tooltip>
        );
    }

    return (
        <>
            <Box sx={{ display: 'flex', width: '100%', mb: 1 }}>
                {isReportAccidentOpen ? (
                    <ReportAccident
                        eventId={details.event_id}
                        onReportNewAccident={handleReportAccident}
                        onClose={() => setIsReportAccidentOpen(false)}
                        setAlert={props.setAlert}
                        details={details}
                        setDetails={props.setDetails}
                        updateTable={props.updateTable}
                        currentAccidentId={props.currentAccidentId}
                    />
                ) : null}
                {isSendEventOpen ? (
                    <SendEventModal
                        details={details}
                        onClose={() => setIsSendEventOpen(false)}
                        users={customerUsers}
                        setAlert={props.setAlert}
                        driver={props.driver}
                    />
                ) : null}
                {isQuickCoachingOpen ? (
                    <QuickCoachingModal
                        isOpen={isQuickCoachingOpen}
                        licensePlate={details.device.license_plate}
                        driverToken={props.driver?.token}
                        onSend={() => {
                            setIsQuickCoachingOpen(false);
                            setIsSaved(true);
                        }}
                        onClose={() => setIsQuickCoachingOpen(false)}
                        eventId={details.event_id}
                        setAlert={props.setAlert}
                        dialog
                    />
                ) : null}
                {isChangeDriverOpen && details.trip ? (
                    <ChangeDriverDialog
                        details={details.trip}
                        currentDriver={details.trip.driver}
                        needConfirmation={
                            details.trip.driver_assignment_method === TripDetailsDriverAssignmentMethodEnum.Manual
                        }
                        onClose={() => setIsChangeDriverOpen(false)}
                        onSuccess={(tripDetails: TripDetails) => {
                            props.setDetails((prev: EventDetails) => {
                                if (!!prev) {
                                    const newDetails = { ...prev, trip: tripDetails };
                                    if (!!props.updateTripDetails) {
                                        props.updateTripDetails(tripDetails);
                                    }
                                    return newDetails;
                                }
                            });
                        }}
                    />
                ) : null}
                {isCreateEventOverlay && profile.admin ? (
                    <CreateOverlayDialog
                        details={details}
                        onClose={() => setIsCreateEventOverlay(false)}
                        onSuccess={(eventOverlayRequest: EventOverlayRequest) => {
                            api.apiAdminOverlayPost({
                                eventOverlayRequest,
                            })
                                .then(() => {
                                    props.setAlert({
                                        message: t('content.events.create_overlay.success'),
                                        type: 'success',
                                        duration: 6000,
                                    });
                                    setIsCreateEventOverlay(false);
                                })
                                .catch(() => {
                                    props.setAlert({
                                        message: t('content.events.create_overlay.failed'),
                                        type: 'error',
                                        duration: 6000,
                                    });
                                });
                        }}
                    />
                ) : null}
                {props.showEditVideo && (canBeTrimmed(details) || !isEventBlurred(details)) ? (
                    <Tooltip title={t('content.events.clone.header')}>
                        <Box>
                            <StyledButton
                                id="clone-btn"
                                variant="contained"
                                color={props.showCloneForm ? 'secondary' : 'lightNeutral'}
                                disableElevation
                                disabled={!props.allowEdit}
                                onClick={() =>
                                    props.setShowCloneForm ? props.setShowCloneForm((prev: boolean) => !prev) : null
                                }
                            >
                                <ContentCutIcon />
                            </StyledButton>
                        </Box>
                    </Tooltip>
                ) : null}
                {props.showShareEvent ? (
                    <Tooltip title={t('content.events.share_event')}>
                        <Box>
                            <StyledButton
                                id="share-btn"
                                variant="contained"
                                color="lightNeutral"
                                disableElevation
                                onClick={handleShareMenuClick}
                            >
                                <ShareIcon />
                            </StyledButton>
                        </Box>
                    </Tooltip>
                ) : null}
                {details.level !== 'INFO' ? (
                    <>
                        {props.showReportAccident ? (
                            <Tooltip title={t('content.events.report_accident')}>
                                <Box>
                                    <StyledButton
                                        id="report-accident-btn"
                                        variant="contained"
                                        color={isReportAccidentOpen ? 'secondary' : 'lightNeutral'}
                                        disableElevation
                                        onClick={() => setIsReportAccidentOpen(true)}
                                    >
                                        <MinorCrashIcon />
                                    </StyledButton>
                                </Box>
                            </Tooltip>
                        ) : null}
                        {props.showReportAccident && showDismissButton ? (
                            <Tooltip title={t('content.events.not_an_accident')}>
                                <Box>
                                    <StyledButton
                                        id="not-an-accident-btn"
                                        onClick={() => (props.handleDismiss ? props.handleDismiss() : null)}
                                        variant="contained"
                                        color="lightNeutral"
                                        disableElevation
                                    >
                                        <NoCrashIcon />
                                    </StyledButton>
                                </Box>
                            </Tooltip>
                        ) : null}
                    </>
                ) : null}
                {props.showEventRoute && !!details.trip_id ? (
                    <Tooltip title={t('content.events.event_route')}>
                        <Box>
                            <StyledButton
                                id="event-trip-btn"
                                variant="contained"
                                color="lightNeutral"
                                disableElevation
                                onClick={() => {
                                    history.push(
                                        `/overview/${encodeURIComponent(details.device.license_plate)}/${
                                            details.trip_id
                                        }`,
                                    );
                                }}
                            >
                                <RouteIcon />
                            </StyledButton>
                        </Box>
                    </Tooltip>
                ) : null}
                {props.showFindVideo ? (
                    <Tooltip title={t('content.events.find_video')}>
                        <Box>
                            <StyledButton
                                id="find-video-btn"
                                variant="contained"
                                color="lightNeutral"
                                disableElevation
                                sx={{ p: 1, pr: isRTL ? 1.5 : 1, pl: isRTL ? 1 : 1.5, minWidth: 0, mr: 1 }}
                                onClick={() =>
                                    history.push(
                                        `/find_video?date=${details.timestamp}&license_plate=${encodeURIComponent(
                                            details.device.license_plate,
                                        )}`,
                                    )
                                }
                            >
                                <SvgIcon>
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M13.125 1.88889V0H15V9.5638C14.4165 9.29983 13.7862 9.12115 13.125 9.04365V7.55556H11.25V9.09895C8.26574 9.6303 6 12.2381 6 15.375C6 15.9366 6.07262 16.4812 6.20897 17H3.75V15.1111H1.875V17H0V0H1.875V1.88889H3.75V0H11.25V1.88889H13.125ZM1.875 13.2222H3.75V11.3333H1.875V13.2222ZM1.875 9.44444H3.75V7.55556H1.875V9.44444ZM1.875 5.66667H3.75V3.77778H1.875V5.66667ZM11.25 5.66667H13.125V3.77778H11.25V5.66667Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M17.3234 19.0846H16.6709L16.4397 18.8616C17.249 17.9201 17.7363 16.6978 17.7363 15.3681C17.7363 12.4033 15.333 10 12.3681 10C9.40328 10 7 12.4033 7 15.3681C7 18.333 9.40328 20.7363 12.3681 20.7363C13.6978 20.7363 14.9201 20.249 15.8616 19.4397L16.0846 19.6709V20.3234L20.2139 24.4444L21.4444 23.2139L17.3234 19.0846ZM12.3681 19.0846C10.3117 19.0846 8.65174 17.4246 8.65174 15.3681C8.65174 13.3117 10.3117 11.6517 12.3681 11.6517C14.4246 11.6517 16.0846 13.3117 16.0846 15.3681C16.0846 17.4246 14.4246 19.0846 12.3681 19.0846Z"
                                        fill="black"
                                    />
                                </SvgIcon>
                            </StyledButton>
                        </Box>
                    </Tooltip>
                ) : null}
                {props.showChangeDriver ? (
                    <Tooltip title={t('content.trip.change_driver')}>
                        <Box>
                            <StyledButton
                                id="change-driver-btn"
                                variant="contained"
                                color="lightNeutral"
                                disableElevation
                                onClick={handleChangeDriverModal}
                            >
                                <PersonIcon />
                            </StyledButton>
                        </Box>
                    </Tooltip>
                ) : null}
                {profile.admin ? (
                    <Tooltip title={t('content.events.create_overlay.header')}>
                        <Box>
                            <StyledButton
                                id="create-overlay-btn"
                                variant="contained"
                                color="lightNeutral"
                                disableElevation
                                onClick={() => setIsCreateEventOverlay((p: boolean) => !p)}
                            >
                                <PhotoFilterIcon />
                            </StyledButton>
                        </Box>
                    </Tooltip>
                ) : null}
            </Box>
            <Menu
                id="share-menu"
                anchorEl={anchorElShare}
                open={isShareOpen}
                onClose={handleShareMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem
                    id="share-link-menu-item"
                    onClick={() => {
                        const urlPath = `events/${details.event_id}?license_plate_contains=${details.device.license_plate}`;
                        const fullUrl = `${window.location.origin}/${urlPath}`;
                        navigator.clipboard.writeText(fullUrl);
                        props.setAlert({
                            message: t('content.events.share_link_copied'),
                            type: 'info',
                            duration: 6000,
                        });
                        handleShareMenuClose();
                    }}
                >
                    {t('content.events.copy_event_url')}
                </MenuItem>
                <MenuItem
                    id="send-event-menu-item"
                    onClick={() => {
                        setIsSendEventOpen(true);
                        handleShareMenuClose();
                    }}
                >
                    {t('content.events.send_event')}
                </MenuItem>
                {isSaved ? (
                    <MenuItem
                        id="btn-remove-from-coaching"
                        onClick={() => {
                            handleEventAction(STATUS.DISMISSED);
                            handleShareMenuClose();
                        }}
                    >
                        {t('content.events.coach.remove')}
                    </MenuItem>
                ) : (
                    <MenuItem
                        id="save-for-coaching-btn"
                        onClick={() => {
                            setCoachingModal(true);
                            handleShareMenuClose();
                        }}
                    >
                        {t('content.safety.save_event')}
                    </MenuItem>
                )}
                {quickCoachingMenuItem}
            </Menu>
            {props.showCoaching ? (
                <EventCoachingComponent
                    {...props}
                    coachingModal={coachingModal}
                    setCoachingModal={setCoachingModal}
                    handleEventAction={handleEventAction}
                    setIsSaved={setIsSaved}
                    details={details}
                />
            ) : null}
        </>
    );
};

export default EventActionsComponent;
