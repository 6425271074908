import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceV3 } from '../../../../backendsdk';

interface GeneralDetailsPanelProps {
    device: DeviceV3;
}

const GeneralDetailsPanel: React.FC<GeneralDetailsPanelProps> = ({ device }: GeneralDetailsPanelProps) => {
    const { t } = useTranslation();

    const trimmedDriverName = device.device.driver_name.trim();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: !!device.device.customer_sub_fleet ? 92 : undefined,
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold' }}>
                        {t('content.fleet.details.general_details.driver_name')}
                    </Typography>
                    <Typography
                        id="driver-name-field"
                        data-testid="driver-name-field"
                        sx={{ fontSize: 16, color: !!trimmedDriverName ? undefined : 'grayColor.main' }}
                    >
                        {!!trimmedDriverName ? trimmedDriverName : t('content.fleet.unknown_driver')}
                    </Typography>
                </Box>
                {!!device.device.customer_sub_fleet ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold' }}>
                            {t('content.fleet.details.general_details.sub_fleet')}
                        </Typography>
                        <Typography id="sub-fleet-field" data-testid="sub-fleet-field" sx={{ fontSize: 16 }}>
                            {device.device.customer_sub_fleet}
                        </Typography>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};

export default GeneralDetailsPanel;
