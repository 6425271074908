import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Box, Card, Checkbox, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import React, { ChangeEvent, useState } from 'react';
import InputMask from 'react-input-mask';

import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { PHONE_NUMBER_MASK_MAP } from '../UserSettings/UserSettings';
import { PROHUB_MIXPANEL_SESSION } from './Defs';

interface SendReminderDialogProps {
    itemId: number;
    expired: boolean;
    driverName: string;
    expiryDate: string;
    setAlert: CallableFunction;
    onClose: CallableFunction;
}

const SendReminderDialog: React.FC<SendReminderDialogProps> = (props) => {
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const expiredMessage = `${props.driverName}, your driving license is documented as expired on ${props.expiryDate}.\nPlease verify your license validity.`;
    const expiringMessage = `${props.driverName}, your driving license is documented as expiring on ${props.expiryDate}.\nPlease verify your license renewal.`;
    const [message, setMessage] = useState<string>(props.expired ? expiredMessage : expiringMessage);
    const [driverConsent, setDriverConsent] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ phoneNumber: boolean; message: boolean }>({
        phoneNumber: false,
        message: false,
    });
    const { profile } = useProfile();
    const { api } = useApi();

    const country = profile.customer.settings.country;

    const validateForm = () => {
        const newErrors = {
            phoneNumber: phoneNumber.length === 0,
            message: message.length > 300,
        };
        setErrors(newErrors);
        return !newErrors.phoneNumber && !newErrors.message;
    };

    const handleSend = () => {
        setIsLoading(true);
        api.apiV0PremiumOptimizationReminderPost({
            premiumOptimizationReminderRequest: {
                id: props.itemId,
                phone_number: phoneNumber,
                message: message,
            },
        })
            .then(() => {
                mixpanel.track('prohub', {
                    name: `${props.itemId} reminder sent`,
                    session_id: PROHUB_MIXPANEL_SESSION,
                });
                props.setAlert({ message: 'Reminder sent successfully.', type: 'success', duration: 6000 });
                props.onClose();
            })
            .catch(() => {
                mixpanel.track('prohub', {
                    name: `${props.itemId} reminder failed`,
                    session_id: PROHUB_MIXPANEL_SESSION,
                });
                props.setAlert({
                    message: 'Failed to send reminder. Please try again later.',
                    type: 'error',
                    duration: 6000,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const phoneInput: any = () => {
        const inputElement = (
            <TextField
                id="phone-number-field"
                size="small"
                label="Phone Number"
                error={errors.phoneNumber}
                helperText={errors.phoneNumber ? 'This field is required' : ''}
                inputProps={{ style: { direction: 'ltr' } }}
                sx={{ mb: 1.5 }}
            />
        );
        return inputElement;
    };

    return (
        <Dialog
            id="send-reminder-dialog"
            open={true}
            fullWidth
            maxWidth="xs"
            onClose={() => props.onClose()}
            sx={{ overflow: 'visible' }}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SendIcon sx={{ mr: 0.5 }} />
                    Send Reminder
                </Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    p: 1,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 1 }}>
                    <Typography sx={{ mb: 1.5 }}>{"Please fill the driver's phone number."}</Typography>
                    <InputMask
                        mask={PHONE_NUMBER_MASK_MAP[country]}
                        value={phoneNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                        maskChar=""
                    >
                        {phoneInput}
                    </InputMask>
                    <TextField
                        id="message-field"
                        size="small"
                        multiline
                        rows={3}
                        label="Message"
                        error={errors.message}
                        helperText={errors.message ? 'This field must include 300 characters or less' : ''}
                        value={message}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
                        sx={{ mb: 1 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="driver-consent-checkbox"
                                data-testid="driver-consent-checkbox"
                                checked={driverConsent}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDriverConsent(e.target.checked)}
                                size="small"
                                disableRipple
                                sx={{ '& .MuiSvgIcon-root': { height: 16, width: 16 } }}
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {"I confirm that I have received the driver's consent to send them a text message."}
                            </Typography>
                        }
                    />
                </Card>
                <Box sx={{ display: 'flex', mt: 1, width: '100%' }}>
                    <Button
                        id="cancel-btn"
                        data-testid="cancel-btn"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => props.onClose()}
                        sx={{ mr: 1 }}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="send-btn"
                        data-testid="send-btn"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={isLoading || !driverConsent}
                        onClick={() => {
                            if (validateForm()) {
                                handleSend();
                            }
                        }}
                    >
                        Send
                    </Button>
                </Box>
            </Paper>
        </Dialog>
    );
};

export default SendReminderDialog;
