import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import {
    GridCellParams,
    GridColDef,
    GridEditInputCell,
    GridLocaleText,
    GridPreProcessEditCellProps,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowModel,
    GridRowParams,
    GridRowSelectionModel,
    GridRowsProp,
    GridValueFormatterParams,
    getGridStringOperators,
    heIL,
    useGridApiContext,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { UserRole } from '../../../backendsdk';
import { TrackedButton as Button, TrackedDataGrid as StyledDataGrid } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { getDateTimeColumnType, localeObjectMap } from '../../../utils/DataGridDateTime';
import { formatPhoneNumber, normalizeName, unformatPhoneNumber } from '../../../utils/Str';
import palette from '../../ColorPalette';
import { PHONE_NUMBER_MASK_MAP } from '../UserSettings/UserSettings';
import { StyledTooltip } from './MuiStyled';
import { ALLOWED_ROLES, OE_ROLES, User } from './UsersComponent';
import { isValidPhoneNumber, validateTextField } from './validation';

interface QuickSearchToolbarProps {
    searchText: string;
    setSearchText: CallableFunction;
    children?: React.ReactNode;
    setNewModalOpen: CallableFunction;
}

const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                py: 1,
                backgroundColor: palette.bgColor,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                    id="table-search-field"
                    variant="standard"
                    value={props.searchText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.setSearchText(e.target.value)}
                    size="small"
                    placeholder={t('table.search')}
                    InputProps={{
                        startAdornment: <SearchIcon color="primary" fontSize="small" sx={{ mb: 0.5, mr: 0.25 }} />,
                    }}
                    sx={{ mr: 1 }}
                />
                <Button
                    id="btn-add-user"
                    size="small"
                    variant="contained"
                    color="secondary"
                    startIcon={<PersonAddIcon />}
                    onClick={() => props.setNewModalOpen(true)}
                >
                    {t('content.users.add_user.header')}
                </Button>
            </Box>
            {props.children}
        </Box>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const phoneInput: any = () => {
    const inputElement = (
        <TextField
            variant="standard"
            InputProps={{ disableUnderline: true, style: { fontSize: 14 } }}
            inputProps={{ style: { direction: 'ltr' } }}
            sx={{ pl: '9px' }}
        />
    );
    return inputElement;
};

export interface GridRenderWithOptions extends GridRenderCellParams {
    options: string[];
    t?: CallableFunction;
}

export interface UserDataGridProps {
    users: Record<number, User>;
    setUsers: CallableFunction;
    selectionModel: GridRowSelectionModel;
    setSelectionModel: CallableFunction;
    setAlert: CallableFunction;
    searchText: string;
    setSearchText: CallableFunction;
    setNewModalOpen: CallableFunction;
    children: React.ReactNode;
}

const UserDataGrid: React.FC<UserDataGridProps> = (props: UserDataGridProps) => {
    const { profile } = useProfile();
    const { i18n, t } = useTranslation();
    const { api } = useApi();
    const isMobile = useIsMobile();
    const locale = localeObjectMap[i18n.languages[0]];

    const isProtectedRole = (role: UserRole) => {
        return OE_ROLES.includes(role);
    };

    const roleReverseMap: Record<string, string> = Object.fromEntries(
        Object.values(UserRole).map((role) => [
            t(`content.users.roles.${role.toLowerCase().replaceAll(' ', '_')}`),
            role,
        ]),
    );

    const EditInputCell = (props: GridRenderEditCellParams) => {
        const { error } = props;
        return (
            <StyledTooltip open={!!error} title={!!error ? t(`content.users.validation.${error}`) : ''}>
                <Box>
                    <GridEditInputCell {...props} error={!!error} debounceMs={0} />
                </Box>
            </StyledTooltip>
        );
    };

    const renderEdit = (params: GridRenderEditCellParams) => {
        return <EditInputCell {...params} />;
    };

    const renderEditPhoneNumber = (params: GridRenderEditCellParams) => {
        const { id, field, error } = params;
        const apiRef = useGridApiContext();
        const handleChange = async (event: SelectChangeEvent) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        };

        return (
            <StyledTooltip open={!!error} title={!!error ? t(`content.users.validation.${error}`) : ''}>
                <Box>
                    <InputMask
                        mask={PHONE_NUMBER_MASK_MAP[profile.customer.settings.country]}
                        value={params.value}
                        onChange={handleChange}
                        maskChar=""
                    >
                        {phoneInput}
                    </InputMask>
                </Box>
            </StyledTooltip>
        );
    };

    const SelectEditInputCell = (props: GridRenderWithOptions) => {
        let value = props.value;
        const { id, field } = props;
        // workaround for localization issue
        if (field === 'role' && !ALLOWED_ROLES.includes(value)) {
            value = roleReverseMap[value];
        }

        const apiRef = useGridApiContext();

        const handleChange = async (event: SelectChangeEvent) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <Select value={value || ''} onChange={handleChange} size="small" sx={{ height: 1 }} native autoFocus>
                {props.options.map((option, idx) => (
                    <option key={idx} value={option}>
                        {option && props.t ? props.t(option) : option}
                    </option>
                ))}
            </Select>
        );
    };

    const renderSelectEditRole: GridColDef['renderCell'] = (params) => {
        return (
            <SelectEditInputCell
                options={params.value ? ALLOWED_ROLES : ['', ...ALLOWED_ROLES]}
                t={(s: string) => t(`content.users.roles.${s.toLowerCase().replaceAll(' ', '_')}`)}
                {...params}
            />
        );
    };

    const renderSelectEditSubfleet: GridColDef['renderCell'] = (params) => {
        const subFleets = profile.customer.sub_fleets;
        if (!subFleets.includes('')) {
            subFleets.splice(0, 0, '');
        }
        return <SelectEditInputCell options={subFleets} {...params} />;
    };

    const preProcessEditCellProps = (params: GridPreProcessEditCellProps) => {
        const errorMessage = validateTextField(params.props.value.toString());
        return { ...params.props, error: errorMessage };
    };

    const preProcessPhoneNumber = (params: GridPreProcessEditCellProps) => {
        const errorMessage = !isValidPhoneNumber(params.props.value.toString()) ? 'phone_number' : '';
        return { ...params.props, error: errorMessage };
    };

    const processRowUpdate = (newRow: GridRowModel, prevRow: GridRowModel) => {
        newRow.phoneNumber = unformatPhoneNumber(newRow.phoneNumber);
        // workaround for localization issue
        if (!ALLOWED_ROLES.includes(newRow.role)) {
            newRow.role = roleReverseMap[newRow.role];
        }
        props.setUsers((prev: Record<number, User>) => {
            return { ...prev, [newRow.id]: newRow };
        });

        const updatedUserToSend = [
            {
                id: newRow.id,
                name: newRow.name,
                email: newRow.email,
                sub_fleet: newRow.subfleet,
                role: newRow.role,
                phone_number: newRow.phoneNumber,
                is_active: newRow.activeState,
                send_invitation: false,
            },
        ];
        api.apiV0SettingsUserManagementPatch({ userDetails: updatedUserToSend })
            .then((res) => {
                props.setUsers((prev: Record<number, User>) => {
                    return {
                        ...prev,
                        [newRow.id]: {
                            ...prev[newRow.id],
                            phoneNumber: res.data.find((user) => user.id === newRow.id)?.phone_number,
                        },
                    };
                });
            })
            .catch(() => {
                props.setAlert({ message: t('content.users.alerts.error.update'), type: 'error', duration: 6000 });
                props.setUsers((prev: Record<number, User>) => {
                    return { ...prev, [newRow.id]: prevRow };
                });
            });

        return newRow;
    };

    const renderActiveStateCell = (params: GridRenderCellParams) => {
        return (
            <Tooltip title={t(`content.users.active_state.${params.value ? 'active' : 'inactive'}`)}>
                {params.value ? (
                    <Box data-testid="active">
                        <CheckIcon />
                    </Box>
                ) : (
                    <Box data-testid="inactive">
                        <CloseIcon />
                    </Box>
                )}
            </Tooltip>
        );
    };

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'name',
                headerName: t('content.users.table.name'),
                minWidth: 150,
                flex: 1,
                editable: true,
                preProcessEditCellProps,
                renderEditCell: renderEdit,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'email',
                headerName: t('content.users.table.email'),
                minWidth: 150,
                flex: 1,
                editable: true,
                preProcessEditCellProps,
                renderEditCell: renderEdit,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'subfleet',
                type: 'singleSelect',
                valueOptions: profile.customer.sub_fleets.filter(Boolean),
                headerName: t('content.users.table.subfleet'),
                minWidth: 150,
                flex: 0.6,
                editable: true,
                renderEditCell: renderSelectEditSubfleet,
            },
            {
                field: 'phoneNumber',
                headerName: t('content.users.table.phone_number'),
                minWidth: 150,
                valueFormatter: (params) => formatPhoneNumber(profile.customer.settings.country, params.value),
                editable: true,
                renderEditCell: renderEditPhoneNumber,
                preProcessEditCellProps: preProcessPhoneNumber,
            },
            {
                field: 'role',
                type: 'singleSelect',
                valueOptions: Object.keys(roleReverseMap),
                headerName: t('content.users.table.role'),
                minWidth: 150,
                flex: 0.8,
                editable: true,
                renderEditCell: renderSelectEditRole,
                valueGetter: ({ value }) =>
                    value ? t(`content.users.roles.${value.toLowerCase().replaceAll(' ', '_')}`) : '',
            },
            {
                field: 'lastLogin',
                headerName: t('content.users.table.last_login'),
                ...getDateTimeColumnType(locale),
                valueFormatter: (params: GridValueFormatterParams) =>
                    !!params.value ? dayjs(params.value).format(profile.dateTimeFormat) : '',
                minWidth: 150,
                flex: 0.7,
            },
            {
                field: 'activeState',
                type: 'boolean',
                headerName: t('content.users.table.active_state'),
                headerAlign: 'center',
                minWidth: 100,
                flex: 0.2,
                renderCell: renderActiveStateCell,
            },
        ],
        [],
    );

    const rows: GridRowsProp = useMemo(() => {
        return Object.values(props.users).sort((a, b) => a.id - b.id);
    }, [props.users]);

    const filteredRows = !!props.searchText
        ? rows.filter(
              (row) =>
                  normalizeName(row.name).includes(normalizeName(props.searchText)) ||
                  row.email.includes(props.searchText) ||
                  normalizeName(row.subfleet).includes(normalizeName(props.searchText)) ||
                  (unformatPhoneNumber(props.searchText) &&
                      unformatPhoneNumber(row.phoneNumber).includes(unformatPhoneNumber(props.searchText))),
          )
        : rows;

    const gridLocalization: Record<string, Partial<GridLocaleText>> = {
        he: heIL.components.MuiDataGrid.defaultProps.localeText,
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <StyledDataGrid
                id="users-data-grid"
                checkboxSelection
                rows={filteredRows}
                columns={columns}
                onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
                    props.setSelectionModel(newSelectionModel);
                }}
                rowSelectionModel={props.selectionModel}
                isRowSelectable={(params: GridRowParams) =>
                    !isProtectedRole(params.row.role) && params.row.id !== profile.user_id
                }
                isCellEditable={(params: GridCellParams) =>
                    !isProtectedRole(params.row.role) && params.row.id !== profile.user_id
                }
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                slotProps={{
                    columnsPanel: {
                        sx: {
                            '& .MuiDataGrid-panelFooter button:first-child': {
                                display: 'none',
                            },
                        },
                    },
                    toolbar: {
                        searchText: props.searchText,
                        setSearchText: props.setSearchText,
                        setNewModalOpen: props.setNewModalOpen,
                        children: props.children,
                    },
                }}
                localeText={
                    {
                        ...gridLocalization[i18n.languages[0]],
                        filterPanelRemoveAll: t('table.remove_all'),
                        columnMenuManageColumns: t('table.manage_columns'),
                        unpin: t('table.unpin'),
                        filterOperatorOnDate: t('table.on_date'),
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                }
                disableRowSelectionOnClick
                disableVirtualization={isMobile || process.env.JEST_WORKER_ID !== undefined}
                pagination={isMobile}
                processRowUpdate={processRowUpdate}
                columnBuffer={7}
                sx={{ width: '100%', height: '100%', backgroundColor: palette.neutral[50] }}
            />
        </LocalizationProvider>
    );
};

export default UserDataGrid;
