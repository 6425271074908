import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CoachDriverResponse, CoachSession } from '../../../../backendsdk';
import { TrackedButton as Button } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import palette from '../../../ColorPalette';
import { SaveFirstTooltip } from './CoachingAgenda';

interface DriverResponseFormProps {
    children?: React.ReactNode;
    sessionId: number;
    item?: CoachDriverResponse;
    onClose: CallableFunction;
    setDetails: CallableFunction;
    isLoading: boolean;
    setIsLoading: CallableFunction;
    isCompleted: boolean;
    isSaved: boolean;
    setAlert: CallableFunction;
    onUpdate: CallableFunction;
    addAnotherItem: boolean;
    setItemsAdded: CallableFunction;
}

const DriverResponseForm: React.FC<DriverResponseFormProps> = (props: DriverResponseFormProps) => {
    const initialRequiredValue = props.item !== undefined ? props.item.is_required : true;
    const [isRequired, setIsRequired] = useState<boolean>(initialRequiredValue);
    const { t } = useTranslation();
    const { api } = useApi();

    const isEditable = !props.isCompleted && props.isSaved;

    const handleSubmit = () => {
        props.setIsLoading(true);
        if (props.item !== undefined) {
            api.apiV0CoachSessionIdResponseResponseIdPatch({
                sessionId: props.sessionId,
                responseId: props.item.id,
                coachDriverResponse: { ...props.item, is_required: isRequired },
            })
                .then((res) => {
                    props.setDetails((prev: CoachSession) => ({
                        ...prev,
                        response: prev.response.map((response) => (response.id === res.data.id ? res.data : response)),
                    }));
                    props.onUpdate();
                    props.onClose();
                })
                .catch(() => {
                    props.setAlert({
                        message: t('content.coach.errors.update_item'),
                        type: 'error',
                        duration: 6000,
                    });
                    props.setIsLoading(false);
                });
        } else {
            api.apiV0CoachSessionIdResponsePost({
                sessionId: props.sessionId,
                driverResponseRequest: { is_required: isRequired, text: '' },
            })
                .then((res) => {
                    props.setDetails((prev: CoachSession) => ({
                        ...prev,
                        response: [...prev.response, res.data],
                        agenda: [...prev.agenda, { type: 'response', id: res.data.id }],
                    }));
                    props.onUpdate();
                    if (props.addAnotherItem) {
                        setIsRequired(true);
                        props.setIsLoading(false);
                        props.setItemsAdded((prev: number) => prev + 1);
                    } else {
                        props.onClose();
                    }
                })
                .catch(() => {
                    props.setAlert({
                        message: t('content.coach.errors.add_item'),
                        type: 'error',
                        duration: 6000,
                    });
                    props.setIsLoading(false);
                });
        }
    };

    const requiredField = (
        <Box
            sx={{
                width: '100%',
                border: `1px solid ${palette.gray[400]}`,
                backgroundColor: palette.white,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                p: 1,
            }}
        >
            <FormControlLabel
                disabled={!isEditable}
                control={
                    <Checkbox
                        id="is-required-checkbox"
                        data-testid="is-required-checkbox"
                        checked={isRequired}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setIsRequired(e.target.checked)}
                        size="small"
                        disableRipple
                        sx={{ '& .MuiSvgIcon-root': { height: 16, width: 16 }, ml: '2px', mr: '-2px' }}
                    />
                }
                label={
                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                        {t('content.coach.add_item.response.required')}
                    </Typography>
                }
            />
        </Box>
    );

    return (
        <>
            {props.isSaved ? requiredField : <SaveFirstTooltip>{requiredField}</SaveFirstTooltip>}
            {props.isCompleted && props.item ? (
                <Box
                    sx={{
                        width: '100%',
                        border: `1px solid ${palette.gray[400]}`,
                        backgroundColor: palette.white,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        p: 1,
                        mt: 1,
                    }}
                >
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                        {t('content.coach.add_item.response.instructions')}
                    </Typography>
                    <TextField
                        id="response-field"
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={props.item.text || t('content.coach.add_item.response.no_response')}
                        inputProps={{ 'data-testid': 'response-field' }}
                        sx={{ '& .MuiInputBase-root': { padding: '8px 10px' } }}
                        disabled={true}
                    />
                </Box>
            ) : null}
            {isEditable ? (
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    {props.children}
                    <Button
                        id="btn-cancel-item"
                        data-testid="btn-cancel-item"
                        variant="contained"
                        color="primary"
                        disabled={props.isLoading}
                        onClick={() => props.onClose()}
                        sx={{ ml: 'auto', flexShrink: 0 }}
                    >
                        {t('content.coach.add_item.cancel')}
                    </Button>
                    <Button
                        id="btn-save-item"
                        data-testid="btn-save-item"
                        variant="contained"
                        color="secondary"
                        sx={{ ml: 1, flexShrink: 0 }}
                        disabled={props.isLoading}
                        onClick={handleSubmit}
                    >
                        {props.item === undefined
                            ? t('content.coach.add_item.add')
                            : t('content.coach.add_item.update')}
                    </Button>
                </Box>
            ) : null}
        </>
    );
};

export default DriverResponseForm;
