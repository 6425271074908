import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import { AgencyApi, ApiApi, ControlApi, HosApi, InstallApi } from '../backendsdk';
import { REFRESH_TOKEN_NAME } from '../layout/Layout';
import { SECONDS_IN_MINUTE } from '../utils/TimeFormatter';

interface ApiContextIfc {
    api: ApiApi;
    hosApi: HosApi;
    agencyApi: AgencyApi;
    installApi: InstallApi;
    controlApi: ControlApi;
    isLoading: boolean;
    isAuthenticated: boolean;
    token: string | undefined;
    setToken: CallableFunction;
    refreshToken: string | null;
    setRefreshToken: CallableFunction;
    setTokenExpiration: CallableFunction;
}

export const ApiContext = React.createContext<ApiContextIfc>({
    api: new ApiApi(),
    hosApi: new HosApi(),
    agencyApi: new AgencyApi(),
    controlApi: new ControlApi(),
    installApi: new InstallApi(),
    isLoading: false,
    isAuthenticated: false,
    token: undefined,
    setToken: () => null,
    refreshToken: null,
    setRefreshToken: () => null,
    setTokenExpiration: () => null,
});

interface ApiProviderProps {
    children: React.ReactNode;
}

export const ApiProvider: React.FC<ApiProviderProps> = (props: ApiProviderProps) => {
    const [token, setToken] = useState<string | undefined>();
    const [refreshToken, setRefreshToken] = useState<string | null>(localStorage.getItem(REFRESH_TOKEN_NAME));
    const [tokenExpiration, setTokenExpiration] = useState<number | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(!!refreshToken && !token);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!token && refreshToken) {
            api.apiAuthRefreshPost(
                { refreshTokenRequest: { refresh: refreshToken } },
                { headers: { Authorization: null } },
            )
                .then((response) => {
                    setToken(response.data.access);
                    setTokenExpiration(response.data.expires_in);
                })
                .catch(() => logout())
                .finally(() => setIsLoading(false));
        }
    }, [token, refreshToken]);

    // refresh access token one minute before it expires
    useEffect(() => {
        if (tokenExpiration && refreshToken) {
            const timeoutId = setTimeout(() => {
                api.apiAuthRefreshPost(
                    { refreshTokenRequest: { refresh: refreshToken } },
                    { headers: { Authorization: null } },
                )
                    .then((response) => {
                        setToken(response.data.access);
                        setTokenExpiration(response.data.expires_in);
                    })
                    .catch(() => logout());
            }, (tokenExpiration - SECONDS_IN_MINUTE) * 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [token, refreshToken, tokenExpiration]);

    const logout = () => {
        setToken(undefined);
        setRefreshTokenInStorage(null);
        queryClient.clear();
    };

    const instance = axios.create({
        headers: token ? { Authorization: `Bearer ${token}` } : undefined,
    });
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401 && refreshToken) {
                return api
                    .apiAuthRefreshPost(
                        { refreshTokenRequest: { refresh: refreshToken } },
                        { headers: { Authorization: null } },
                    )
                    .then((response) => {
                        setToken(response.data.access);
                        setTokenExpiration(response.data.expires_in);
                        // return the original request
                        error.config.headers['Authorization'] = 'Bearer ' + response.data.access;
                        return axios.request(error.config);
                    })
                    .catch(() => logout());
            }
            return Promise.reject(error);
        },
    );

    const api = new ApiApi(undefined, process.env.REACT_APP_PUBLIC_API, instance);
    const hosApi = new HosApi(undefined, process.env.REACT_APP_PUBLIC_API, instance);
    const agencyApi = new AgencyApi(undefined, process.env.REACT_APP_PUBLIC_API, instance);
    const controlApi = new ControlApi(undefined, process.env.REACT_APP_PUBLIC_API, instance);
    const installApi = new InstallApi(undefined, process.env.REACT_APP_PUBLIC_API, instance);

    const setRefreshTokenInStorage = (token: string | null) => {
        if (!!token) {
            localStorage.setItem(REFRESH_TOKEN_NAME, token);
        } else {
            localStorage.removeItem(REFRESH_TOKEN_NAME);
        }
        setRefreshToken(token);
    };

    return (
        <ApiContext.Provider
            value={{
                api,
                hosApi,
                agencyApi,
                controlApi,
                installApi,
                isLoading,
                isAuthenticated: !!token,
                token,
                setToken,
                refreshToken,
                setRefreshToken: setRefreshTokenInStorage,
                setTokenExpiration,
            }}
        >
            {props.children}
        </ApiContext.Provider>
    );
};

const useApi = () => useContext(ApiContext);
export default useApi;
