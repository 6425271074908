import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Box,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    LinearProgress,
    List,
    OutlinedInput,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NewDevice } from '../../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
    TrackedListItemButton as ListItemButton,
} from '../../../../../components/TrackedComponents';
import useApi from '../../../../../hooks/api';
import palette from '../../../../ColorPalette';
import { Modes } from '../SetupTool';
import { setStoredValue } from '../utils/storage';

const TROUBLESHOOT_CONTENT = ['restart', 'power', 'indicator', 'wait'];

interface SelectNewDeviceProps {
    mode: Modes;
    malfunctionId: number;
    setNewDevice: React.Dispatch<React.SetStateAction<NewDevice | undefined>>;
    onBack: () => void;
    onNext: () => void;
    dashboardLink: React.ReactNode;
}

const SelectNewDevice: React.FC<SelectNewDeviceProps> = (props) => {
    const [selectedDevice, setSelectedDevice] = useState<NewDevice>();
    const [devices, setDevices] = useState<NewDevice[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isHelp, setIsHelp] = useState<boolean>(false);
    const [listCounter, setListCounter] = useState<number>(0);
    const [keyCounter, setKeyCounter] = useState<number>(0);
    const { t } = useTranslation();
    const { installApi } = useApi();

    const filteredDevices = devices.filter((device) => device.imei.includes(searchTerm));

    const getDeviceIds = (indicateLoading = false) => {
        if (indicateLoading) {
            setIsLoading(true);
            setIsError(false);
        }
        installApi
            .installV2Get()
            .then((response) => {
                setIsError(false);
                setListCounter(0);
                setKeyCounter((prev) => prev + 1);
                const sortedDevices = [...response.data].sort((a: NewDevice, b: NewDevice) =>
                    a.imei.localeCompare(b.imei),
                );
                setDevices(sortedDevices);
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
    };

    useEffect(getDeviceIds, []);

    useEffect(() => {
        if (listCounter >= 5) {
            // wait for progress bar animation to end
            const timerId = setTimeout(() => {
                getDeviceIds();
            }, 400);
            return () => clearTimeout(timerId);
        } else {
            const timerId = setTimeout(() => {
                setListCounter((prev) => prev + 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [listCounter]);

    useEffect(() => {
        if (selectedDevice && !filteredDevices.map((d) => d.imei).includes(selectedDevice.imei)) {
            setSelectedDevice(undefined);
        }
    }, [filteredDevices]);

    useEffect(() => {
        setStoredValue<string>('OE-new-imei', `${props.malfunctionId}-${props.mode}`, selectedDevice?.imei || '');
    }, [selectedDevice]);

    if (isHelp) {
        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', minHeight: 0 }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        minHeight: 0,
                        flexShrink: 0,
                    }}
                >
                    <Typography variant="h6" textAlign="center">
                        {t('setup_tool.troubleshoot.header')}
                    </Typography>
                </Box>
                <Box sx={{ width: '100%', height: '100%', mt: 2 }}>
                    <Typography sx={{ fontSize: 20, fontWeight: 500, mb: 0.5 }}>
                        {t('setup_tool.select_new_device.cant_find')}
                    </Typography>
                    <ul style={{ width: '100%', paddingLeft: '20px', marginTop: '0px' }}>
                        {TROUBLESHOOT_CONTENT.map((item) => (
                            <li key={item}>
                                <Typography>{t(`setup_tool.troubleshoot.${item}`)}</Typography>
                            </li>
                        ))}
                    </ul>
                    <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                        {t('setup_tool.troubleshoot.more_help')}
                    </Typography>
                    <Typography>{t('setup_tool.troubleshoot.contact')}</Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 1,
                        mb: '30px',
                        minHeight: 0,
                        flexShrink: 0,
                    }}
                >
                    <Box sx={{ width: '50%' }}>
                        <Button
                            id="btn-back"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setIsHelp(false);
                            }}
                        >
                            {t('setup_tool.back')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    }

    let content;
    if (isLoading) {
        content = (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
            </Box>
        );
    } else if (isError) {
        content = (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                <WarningIcon color="error" sx={{ fontSize: 24, mb: 0.5 }} />
                <Typography variant="overline" color="error" sx={{ lineHeight: 1, mb: 2 }}>
                    {t('setup_tool.select_new_device.error')}
                </Typography>
                <Button id="btn-retry" data-testid="btn-retry" variant="contained" onClick={() => getDeviceIds(true)}>
                    {t('setup_tool.select_new_device.retry')}
                </Button>
            </Box>
        );
    } else if (devices.length > 0 && filteredDevices.length === 0) {
        content = (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                    {t('setup_tool.select_new_device.no_devices')}
                </Typography>
            </Box>
        );
    } else {
        content = (
            <List id="device-list" data-testid="device-list" disablePadding>
                {filteredDevices.map((device) => {
                    const deviceId = device.imei;
                    return (
                        <ListItemButton
                            id={`device-${deviceId}`}
                            data-testid={`device-${deviceId}`}
                            key={deviceId}
                            selected={selectedDevice?.imei === deviceId}
                            onClick={() => setSelectedDevice(device)}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                borderBottom: `1px solid ${palette.neutral[200]}`,
                                py: 1.25,
                            }}
                        >
                            <Typography>{deviceId}</Typography>
                        </ListItemButton>
                    );
                })}
            </List>
        );
    }

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', minHeight: 0 }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: 0,
                    flexShrink: 0,
                }}
            >
                <Typography variant="h6" textAlign="center">
                    {t('setup_tool.select_new_device.header')}
                </Typography>
                <Typography fontSize={14} sx={{ fontWeight: 600 }}>
                    {t('setup_tool.select_new_device.subheader')}
                </Typography>
                {!isLoading && !isError ? (
                    <FormControl sx={{ mt: 2, mb: 1 }} fullWidth variant="outlined">
                        <InputLabel htmlFor="search-field">{t('setup_tool.select_new_device.search')}</InputLabel>
                        <OutlinedInput
                            fullWidth
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            id="search-field"
                            inputProps={{ 'data-testid': 'search-input' }}
                            label={t('setup_tool.select_new_device.search')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        id="btn-clear"
                                        aria-label="clear search"
                                        onClick={() => setSearchTerm('')}
                                        edge="end"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                ) : null}
            </Box>
            {!isLoading && !isError && !isHelp ? (
                <LinearProgress
                    key={`${keyCounter}-progress`}
                    variant="determinate"
                    value={(1 - listCounter / 5) * 100}
                    sx={{ width: '100%', flexShrink: 0 }}
                />
            ) : null}
            <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>{content}</Box>
            <Link id="cant_find" component="button" sx={{ mt: 1, mb: 0.5 }}>
                <Typography fontSize={14} onClick={() => setIsHelp(true)} sx={{ color: palette.neutral[700] }}>
                    {t('setup_tool.select_new_device.cant_find')}
                </Typography>
            </Link>
            <Box sx={{ width: '100%', display: 'flex', mt: 1, minHeight: 0, flexShrink: 0 }}>
                <Box sx={{ flex: 1, mr: 1 }}>
                    <Button id="btn-back" fullWidth variant="contained" color="primary" onClick={() => props.onBack()}>
                        {t('setup_tool.back')}
                    </Button>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Button
                        id="btn-next"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={!selectedDevice}
                        onClick={() => {
                            if (selectedDevice) {
                                props.setNewDevice(selectedDevice);
                                props.onNext();
                            }
                        }}
                    >
                        {t('setup_tool.next')}
                    </Button>
                </Box>
            </Box>
            {props.dashboardLink}
        </Box>
    );
};

export default SelectNewDevice;
