import React, { useState } from 'react';

import useProfile from '../../../hooks/profile';
import { getStorageItem, setStorageItem } from '../../../utils/Storage';
import { HOURS_IN_DAY } from '../../../utils/TimeFormatter';
import { OE_ROLES } from '../Users/UsersComponent';
import Accident from './Accident';
import AccidentV1 from './AccidentV1';
import { AccidentPageVersion } from './Defs';

const AccidentPageComponent: React.FC = () => {
    const { profile } = useProfile();
    const defaultVersion = OE_ROLES.includes(profile.role)
        ? 'v1'
        : (getStorageItem('OE-accident-page-version') as AccidentPageVersion);
    const [pageVersion, setPageVersion] = useState<AccidentPageVersion>(defaultVersion || 'v0');

    const handlePageVersionChange = (version: AccidentPageVersion) => {
        setPageVersion(version);
        setStorageItem('OE-accident-page-version', version, 7 * HOURS_IN_DAY);
    };

    return pageVersion === 'v1' ? (
        <AccidentV1 handlePageVersionChange={handlePageVersionChange} />
    ) : (
        <Accident handlePageVersionChange={handlePageVersionChange} />
    );
};

export default AccidentPageComponent;
