import { Box, Tabs, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router-dom';

import { AccidentTagsV2Details, CommandData, EventDetails, ExtendedAccident, PolicyV2 } from '../../../../backendsdk';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { TabPanel, a11yProps } from '../../../../components/Tab';
import { TrackedTab as Tab } from '../../../../components/TrackedComponents';
import { TrackedDialog as Dialog } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import useDevices from '../../../../hooks/devices';
import useProfile from '../../../../hooks/profile';
import { useQuery } from '../../../../hooks/query';
import palette from '../../../ColorPalette';
import { SYSTEM_USER_ID } from '../../FindVideo/FindVideo';
import AccidentModalHeader from './../AccidentModalHeader';
import ConfirmSaveDialog from './../ConfirmSaveDialog';
import { validateAccidentDetails } from './../Tagging/AccidentDetails';
import DetailsTab from './Modal/DetailsTab';
import DiscussionTab from './Modal/DiscussionTab';
import ExtraDetailsTab from './Modal/ExtraDetailsTab';
import FindVideoTab from './Modal/FindVideoTab';
import PaymentsTab from './Modal/PaymentsTab';
import TaggingWrapperTab from './Modal/TaggingWrapperTab';

interface AccidentModalProps {
    open: boolean;
    onClose: CallableFunction;
    details: ExtendedAccident;
    setDetails: CallableFunction;
    eventDetails?: EventDetails;
    handleChange: CallableFunction;
    onSave: CallableFunction;
    sending: boolean;
    setSending: CallableFunction;
    refreshDetails: CallableFunction;
    detailsChanged: boolean;
    handlingStatusList: Array<string>;
    setHandlingStatusList: CallableFunction;
    setAlert: CallableFunction;
    allowUpdate: boolean;
    policies?: PolicyV2[];
    loadingPolicies?: boolean;
    changeAccidentStage?: (accidentId: number, stage: string) => Promise<void>;
    setAccidents: CallableFunction;
}

export const EMPTY_ACCIDENT_TAGS: AccidentTagsV2Details = {
    source: [],
    not_an_accident: false,
    frame_number: '',
    fault: '',
    speed: '',
    clearly_visible: false,
    in_cabin_wellness: '',
    front_facing_wellness: '',
    confidence: '',
    damage_causes: [],
    primary_cause: '',
    points_of_impact: [],
    impacted_objects: [],
    missing_video_reason: 'did_not_search',
};

const TABS = ['DETAILS', 'EXTRA_DETAILS', 'FIND_VIDEO', 'DISCUSSION', 'PAYMENTS', 'TAGGING'] as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TABS_MAP: Record<typeof TABS[number], React.FC<any>> = {
    DETAILS: DetailsTab,
    EXTRA_DETAILS: ExtraDetailsTab,
    FIND_VIDEO: FindVideoTab,
    DISCUSSION: DiscussionTab,
    PAYMENTS: PaymentsTab,
    TAGGING: TaggingWrapperTab,
};

const AccidentModal: React.FC<AccidentModalProps> = (props: AccidentModalProps) => {
    const { t } = useTranslation();
    const { details } = props;
    const query = useQuery();
    const { api, agencyApi } = useApi();
    const [newThirdParty, setNewThirdParty] = useState<boolean>(false);
    const [updateCallbacks, setUpdateCallbacks] = useState<Record<string, CallableFunction>>({});
    const [validateCallbacks, setValidateCallbacks] = useState<Record<string, CallableFunction>>({});
    const [duplicateAccidentId, setDuplicateAccidentId] = useState<number>();
    const [accidentTags, setAccidentTags] = useState<AccidentTagsV2Details>(EMPTY_ACCIDENT_TAGS);
    const params = useParams<{ commandId?: string; tripId?: string }>();
    const isFindVideo = !!params.commandId || !!params.tripId;
    const [tab, setTab] = useState<[number, number]>([0, isFindVideo ? TABS.indexOf('FIND_VIDEO') : 0]); // [prev, current]
    const { devices, isLoading: isLoadingDevices } = useDevices();
    const [requests, setRequests] = useState<CommandData[]>([]);
    const [isLoadingRequests, setIsLoadingRequests] = useState<boolean>(true);
    const [requestsError, setRequestsError] = useState<boolean>(false);
    const [isConfirmExitOpen, setIsConfirmExitOpen] = useState<boolean>(false);
    const { profile } = useProfile();
    const history = useHistory();

    useEffect(() => {
        props.setSending(false);
        agencyApi
            .agencyV1AccidentAccidentIdTagsV2Get({ accidentId: props.details.accident_id })
            .then((res) => {
                setAccidentTags(res.data.tag);
            })
            .catch(() => setAccidentTags({ ...EMPTY_ACCIDENT_TAGS }));
    }, []);

    useEffect(() => {
        const eventID = query.get('event_id');
        if (eventID) {
            handleChange('event', eventID);
        }
    }, [query.toString()]);

    const removeCallback = (setCallbacks: CallableFunction, key: string) => {
        setCallbacks((prev: Record<string, CallableFunction> | undefined) => {
            if (prev !== undefined && key in prev) {
                const updated = { ...prev };
                delete updated[key];
                return updated;
            } else {
                return prev;
            }
        });
    };

    useEffect(() => {
        if (props.eventDetails && details.accident_id === 0) {
            handleChange('license_plate', props.eventDetails.device.license_plate);
            handleChange('sub_fleet_name', props.eventDetails.device.customer_sub_fleet);
            handleChange('timestamp', props.eventDetails.timestamp);
        }
    }, [props.eventDetails]);

    useEffect(() => {
        if (!newThirdParty) {
            removeCallback(setUpdateCallbacks, 'damage-0');
            removeCallback(setValidateCallbacks, 'damage-0');
        }
    }, [newThirdParty]);

    const loadRequests = () => {
        setIsLoadingRequests(true);
        api.apiV0CommandGet({ accident: props.details.accident_id })
            .then((res) => {
                // filter out retries
                const requestList = res.data.filter((r) => {
                    const requestData = JSON.parse(r.data);
                    return !requestData.retry;
                });
                setRequests(requestList);
            })
            .catch(() => setRequestsError(true))
            .finally(() => setIsLoadingRequests(false));
    };

    useEffect(loadRequests, []);

    const checkDuplicate = () => {
        agencyApi
            .agencyV1AccidentGet({
                timeFrom: dayjs.unix(details.timestamp).startOf('day').unix(),
                timeTo: dayjs.unix(details.timestamp).endOf('day').unix(),
                normLicensePlate: details.license_plate.replaceAll('-', '').toLocaleUpperCase(),
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setDuplicateAccidentId(res.data[0].accident_id);
                } else {
                    handleSave();
                }
            })
            .catch(() => handleSave());
    };

    const handleSave = (updatedDetails?: ExtendedAccident) => {
        const sectionsAreValid: Array<boolean> = [];
        if (validateCallbacks) {
            for (const func of Object.values(validateCallbacks)) {
                const res: boolean = func();
                sectionsAreValid.push(res);
            }
            if (!sectionsAreValid.every(Boolean)) {
                return Promise.resolve();
            }
        }

        props.setSending(true);
        const promises: Array<Promise<void>> = [];
        if (updateCallbacks) {
            for (const [key, func] of Object.entries(updateCallbacks)) {
                const res: Promise<void> = func().then(() => removeCallback(setUpdateCallbacks, key));
                promises.push(res);
            }
        }

        return Promise.allSettled(promises).then((results) => {
            if (results.some((result) => result.status === 'rejected')) {
                props.setAlert({ message: t('content.accidents.form.error'), type: 'error', duration: 6000 });
            }
            if (!props.handlingStatusList.includes(details.handling_status)) {
                props.setHandlingStatusList((prev: Array<string>) => [...prev, details.handling_status]);
            }
            return props.onSave(updatedDetails);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (target: string, value: any) => {
        props.handleChange((oldDetails: ExtendedAccident) => {
            if (oldDetails) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const newDetails: Record<string, any> = { ...oldDetails };
                newDetails[target] = value;
                return newDetails;
            }
        });
    };

    const handleClose = () => {
        props.onClose();
        setNewThirdParty(false);
    };

    const onClose = () => {
        if (!!details.event || isLoadingRequests) {
            handleClose();
        } else if (!requestsError && requests.filter((r) => r.user_id !== SYSTEM_USER_ID).length === 0) {
            setIsConfirmExitOpen(true);
            return;
        } else {
            handleClose();
        }
    };

    const isTaggingComplete =
        accidentTags.not_an_accident ||
        (validateAccidentDetails(accidentTags) &&
            accidentTags.damage_causes.length > 0 &&
            accidentTags.primary_cause !== '' &&
            accidentTags.points_of_impact.length > 0 &&
            accidentTags.impacted_objects.length > 0);
    const disableSavingMissingTags =
        profile.admin && props.details.accident_id != 0 && !!props.details.event && !isTaggingComplete;

    const tabProps = {
        ...props,
        updateCallbacks,
        setUpdateCallbacks,
        validateCallbacks,
        setValidateCallbacks,
        removeCallback,
        handleSave,
        accidentTags,
        setAccidentTags,
        devices,
        requests,
        setRequests,
        isLoadingDevices,
        isLoadingRequests,
        isError: requestsError,
        onBackTab: () => setTab((p) => [p[1], p[0]]),
    };

    const changeStage = (stage: string) => {
        return props.changeAccidentStage?.(details.accident_id, stage).then(() => {
            props.refreshDetails();
        });
    };

    return (
        <Dialog
            id="accident-modal"
            fullWidth
            maxWidth="xl"
            open={props.open}
            onClose={onClose}
            scroll="paper"
            sx={{ '& .MuiDialog-container': { alignItems: 'flex-start' } }}
        >
            {isConfirmExitOpen ? (
                <ConfirmDialog
                    isDialogOpen={isConfirmExitOpen}
                    isLoading={false}
                    headerText="content.accidents.video.no_requests_header"
                    bodyText={[
                        'content.accidents.video.no_requests_body1',
                        'content.accidents.video.no_requests_body2',
                    ]}
                    buttonText="content.accidents.video.find_video"
                    buttonColor="secondary"
                    cancelText="content.accidents.video.close"
                    onClose={() => setIsConfirmExitOpen(false)}
                    onCancel={() => {
                        props.onClose();
                        setNewThirdParty(false);
                    }}
                    onConfirm={() => {
                        setIsConfirmExitOpen(false);
                        setTab((p) => [p[1], TABS.indexOf('FIND_VIDEO')]);
                    }}
                />
            ) : null}
            {!!duplicateAccidentId ? (
                <ConfirmSaveDialog
                    isDialogOpen={!!duplicateAccidentId}
                    onClose={() => setDuplicateAccidentId(undefined)}
                    onConfirm={() => {
                        setDuplicateAccidentId(undefined);
                        handleSave();
                    }}
                    duplicateId={duplicateAccidentId}
                />
            ) : null}
            <Prompt when={props.detailsChanged} message={t('content.accidents.prompt_msg')} />
            <Box sx={{ backgroundColor: 'bgColor.main' }}>
                <AccidentModalHeader
                    details={details}
                    onClose={onClose}
                    checkDuplicate={checkDuplicate}
                    onSave={() => handleSave()}
                    sending={props.sending}
                    disableSaving={disableSavingMissingTags}
                    policies={props.policies}
                    loadingPolicies={props.loadingPolicies}
                    changeStage={changeStage}
                    showStageButton
                    showShareButton
                />
                <Box id={'asd'}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            minHeight: 0,
                        }}
                    >
                        <Tabs
                            value={tab[1]}
                            onChange={(_e, newValue) => {
                                if (newValue !== TABS.indexOf('FIND_VIDEO')) {
                                    history.replace(`/accidents/${props.details.accident_id}`);
                                }
                                setTab((p) => [p[1], newValue]);
                            }}
                            TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                            sx={{ width: '100%', borderBottom: `1px solid ${palette.neutral[200]}` }}
                        >
                            {TABS.map((tabName, idx) => {
                                const isDisabled = tabName !== 'DETAILS' && details.accident_id === 0;
                                return (
                                    <Tab
                                        key={tabName}
                                        label={
                                            <Typography fontSize={14}>
                                                {t(`content.accidents.tabs.${tabName.toLocaleLowerCase()}`)}
                                            </Typography>
                                        }
                                        {...a11yProps(idx)}
                                        id={`${tabName}-tab`}
                                        data-testid={`${tabName}-tab`}
                                        disabled={isDisabled || props.sending}
                                    />
                                );
                            })}
                        </Tabs>
                    </Box>
                    <Box>
                        {TABS.map((tabName, idx) => {
                            const Tab = TABS_MAP[tabName];
                            return (
                                <TabPanel key={tabName} value={tab[1]} index={idx}>
                                    <Tab {...tabProps} />
                                </TabPanel>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export default AccidentModal;
