import { Box, CircularProgress } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';

import {
    HOURS_IN_DAY,
    MINUTES_IN_DAY,
    MINUTES_IN_HOUR,
    SECONDS_IN_DAY,
    secondsToTime,
} from '../../../../utils/TimeFormatter';
import palette from '../../../ColorPalette';
import { DriverRecord, RECORD_CODE, RECORD_ORIGIN, RECORD_STATUS } from '../Defs';

dayjs.extend(utc);

export interface Edit {
    id: number;
    startTime: number;
    endTime: number;
    comment: string;
    recordCode?: RECORD_CODE;
    containsDriving?: boolean;
    status?: RECORD_STATUS;
}

const STARTING_X = 35;
const STARTING_Y = 13;
const MINUTE_IN_PIXELS = 0.3;
const ROW_HEIGHT = 18;
const TABLE_TOP = 20;
const TABLE_BOTTOM = 95;

interface GraphProps {
    records: DriverRecord[];
    startOfDay: Dayjs;
    now: Dayjs;
    editPreview?: Edit;
    loading?: boolean;
}

const timestampToX = (timestamp: number, startOfDay: Dayjs): number => {
    return STARTING_X + Math.floor((timestamp - startOfDay.unix()) / 60) * MINUTE_IN_PIXELS - 0.5;
};

const recordCodeToY = (recordCode: number): number => {
    return STARTING_Y + ROW_HEIGHT * (recordCode - 1);
};

const dutyStatusToCoords = (recordCode: number, timestamp: number, startOfDay: Dayjs): [number, number] => {
    const x = timestampToX(timestamp, startOfDay);
    const y = recordCodeToY(recordCode);
    return [x, y];
};

const recordsToPoints = (records: DriverRecord[], startOfDay: Dayjs, now: Dayjs): [number, number][] => {
    const points = [] as [number, number][];

    if (records.length > 0) {
        points.push(dutyStatusToCoords(records[0].record_code, records[0].event_datetime, startOfDay));

        for (let i = 1; i < records.length; i++) {
            points.push(dutyStatusToCoords(records[i - 1].record_code, records[i].event_datetime, startOfDay));
            points.push(dutyStatusToCoords(records[i].record_code, records[i].event_datetime, startOfDay));
        }

        const lastRecord = records[records.length - 1];
        if (!(now.unix() > startOfDay.add(1, 'day').unix())) {
            points.push(dutyStatusToCoords(lastRecord.record_code, now.unix(), startOfDay));
        } else if (lastRecord.event_datetime < startOfDay.add(1, 'day').unix()) {
            points.push([STARTING_X + HOURS_IN_DAY * MINUTES_IN_HOUR * MINUTE_IN_PIXELS, points[points.length - 1][1]]);
        }
    }

    return points;
};

const recordsToTimes = (records: DriverRecord[], startOfDay: Dayjs, now: Dayjs): number[] => {
    const hos = [0, 0, 0, 0];
    if (records.length > 0) {
        const endTime = Math.min(startOfDay.add(1, 'day').unix(), now.unix());

        for (let i = 1; i < records.length; i++) {
            hos[records[i - 1].record_code - 1] += records[i].event_datetime - records[i - 1].event_datetime;
        }

        const lastRecord = records[records.length - 1];
        hos[lastRecord.record_code - 1] += endTime - lastRecord.event_datetime;
    }
    return hos;
};

export const Graph: React.FC<GraphProps> = ({ records, startOfDay, now, editPreview, loading }) => {
    const points = recordsToPoints(records, startOfDay, now);
    let hos = recordsToTimes(records, startOfDay, now);
    let editSVG = null;
    if (editPreview) {
        const color =
            editPreview.containsDriving || editPreview.status === RECORD_STATUS.INACTIVE_CHANGE_REJECTED
                ? 'red'
                : 'blue';
        if (editPreview.recordCode !== undefined) {
            const returnStatus = [...records]
                .reverse()
                .find((record) => record.event_datetime <= editPreview.endTime)?.record_code;
            if (returnStatus !== undefined) {
                const recordEdits: DriverRecord[] = [
                    {
                        ...records[0],
                        record_origin: RECORD_ORIGIN.EDITED_OR_ENTERED_BY_THE_DRIVER,
                        event_datetime: editPreview.startTime,
                        record_code: editPreview.recordCode,
                    },
                    {
                        ...records[0],
                        record_origin: RECORD_ORIGIN.EDITED_OR_ENTERED_BY_THE_DRIVER,
                        event_datetime: editPreview.endTime,
                        record_code: returnStatus,
                    },
                ];
                const activeRecords = recordEdits
                    .concat(
                        records.filter(
                            (record) =>
                                record.event_datetime >= editPreview.endTime ||
                                record.event_datetime <= editPreview.startTime,
                        ),
                    )
                    .sort((a, b) => a.event_datetime - b.event_datetime);
                hos = recordsToTimes(activeRecords, startOfDay, now);
            }
        }

        // special case: edit until midnight of the following day
        const offsetX =
            editPreview.endTime - editPreview.startTime === SECONDS_IN_DAY ? MINUTES_IN_DAY * MINUTE_IN_PIXELS : 0;

        editSVG = (
            <>
                <rect
                    data-testid="preview-rect"
                    x={timestampToX(editPreview.startTime, startOfDay)}
                    y={TABLE_TOP}
                    width={
                        timestampToX(editPreview.endTime, startOfDay) -
                        timestampToX(editPreview.startTime, startOfDay) +
                        offsetX
                    }
                    height={TABLE_BOTTOM - TABLE_TOP}
                    style={{ fill: color, fillOpacity: 0.1 }}
                />
                <line
                    data-testid="preview-start"
                    x1={timestampToX(editPreview.startTime, startOfDay)}
                    y1={TABLE_TOP}
                    x2={timestampToX(editPreview.startTime, startOfDay)}
                    y2={TABLE_BOTTOM}
                    style={{ stroke: color, strokeWidth: 2 }}
                />
                <line
                    data-testid="preview-end"
                    x1={timestampToX(editPreview.endTime, startOfDay) + offsetX}
                    y1={TABLE_TOP}
                    x2={timestampToX(editPreview.endTime, startOfDay) + offsetX}
                    y2={TABLE_BOTTOM}
                    style={{ stroke: color, strokeWidth: 2 }}
                />
                {editPreview.recordCode !== undefined ? (
                    <g transform="translate(0,20)">
                        <line
                            data-testid="preview-status"
                            x1={timestampToX(editPreview.startTime, startOfDay)}
                            y1={recordCodeToY(editPreview.recordCode)}
                            x2={timestampToX(editPreview.endTime, startOfDay) + offsetX}
                            y2={recordCodeToY(editPreview.recordCode)}
                            style={{ stroke: color, strokeWidth: 2 }}
                        />
                    </g>
                ) : null}
            </>
        );
    }

    const loadingSpinner = (
        <Box
            sx={{
                width: '100%',
                aspectRatio: '9 / 2',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: 0.5,
                position: 'absolute',
                zIndex: 2,
            }}
        >
            <CircularProgress />
        </Box>
    );

    const hosTotal = secondsToTime(hos.reduce((sum, seconds) => sum + seconds, 0));
    const hosTime = hos.map(secondsToTime);

    return (
        <>
            {loading ? loadingSpinner : null}
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" version="1.1" viewBox="0 0 540 120" fill="red">
                <path
                    fill="none"
                    stroke="#a4a4a4"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1"
                    d="M466.813 94.502l-432-.002"
                ></path>
                <path
                    fill="none"
                    stroke="#a4a4a4"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1"
                    d="M466.813 76.502l-432-.002"
                ></path>
                <path
                    fill="none"
                    stroke="#a4a4a4"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1"
                    d="M466.813 58.502l-432-.002"
                ></path>
                <path
                    fill="none"
                    stroke="#a4a4a4"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1"
                    d="M466.813 40.502l-432-.002"
                ></path>
                <g
                    fillOpacity="1"
                    stroke="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeOpacity="1"
                    strokeWidth="1"
                    fontStretch="normal"
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="bold"
                    wordSpacing="0"
                >
                    <g>
                        <text
                            xmlSpace="preserve"
                            style={{
                                textAlign: 'start',
                            }}
                            x="7.811"
                            y="36.801"
                            fill="#000"
                            fontSize="10.667"
                            letterSpacing="0.4"
                            textAnchor="start"
                        >
                            <tspan x="7.811" y="36.801" fontSize="10.667">
                                OFF
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{
                                textAlign: 'end',
                            }}
                            x="531.59"
                            y="36.081"
                            fill={hos[0] ? '#000' : '#6f6f6f'}
                            letterSpacing="1.5"
                            textAnchor="end"
                        >
                            <tspan
                                x="533.09"
                                y="36.081"
                                style={{
                                    textAlign: 'end',
                                }}
                                fontSize="12.5"
                                textAnchor="end"
                            >
                                {hosTime[0].hours}:{hosTime[0].minutes.toString().padStart(2, '0')}:
                                {hosTime[0].seconds.toString().padStart(2, '0')}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <text
                            xmlSpace="preserve"
                            style={{
                                textAlign: 'start',
                            }}
                            x="13.767"
                            y="54.801"
                            fill="#000"
                            fontSize="10.667"
                            letterSpacing="0.4"
                            textAnchor="start"
                        >
                            <tspan x="13.767" y="54.801" fontSize="10.667">
                                SB
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{
                                textAlign: 'end',
                            }}
                            x="531.59"
                            y="54.081"
                            fill={hos[1] ? '#000' : '#6f6f6f'}
                            letterSpacing="1.5"
                            textAnchor="end"
                        >
                            <tspan
                                x="533.09"
                                y="54.081"
                                style={{
                                    textAlign: 'end',
                                }}
                                fontSize="12.5"
                                textAnchor="end"
                            >
                                {hosTime[1].hours}:{hosTime[1].minutes.toString().padStart(2, '0')}:
                                {hosTime[1].seconds.toString().padStart(2, '0')}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <text
                            xmlSpace="preserve"
                            style={{
                                textAlign: 'start',
                            }}
                            x="21.435"
                            y="72.693"
                            fill="#000"
                            fontSize="10.667"
                            letterSpacing="0.4"
                            textAnchor="start"
                            transform="scale(1.00002 .99998)"
                        >
                            <tspan x="21.435" y="72.693" strokeWidth="1" fontSize="10.667">
                                D
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{
                                textAlign: 'end',
                            }}
                            x="531.59"
                            y="72.081"
                            fill={hos[2] ? '#000' : '#6f6f6f'}
                            letterSpacing="1.5"
                            textAnchor="end"
                        >
                            <tspan
                                x="533.09"
                                y="72.081"
                                style={{
                                    textAlign: 'end',
                                }}
                                fontSize="12.5"
                                textAnchor="end"
                            >
                                {hosTime[2].hours}:{hosTime[2].minutes.toString().padStart(2, '0')}:
                                {hosTime[2].seconds.toString().padStart(2, '0')}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <text
                            xmlSpace="preserve"
                            style={{
                                textAlign: 'start',
                            }}
                            x="12.95"
                            y="90.801"
                            fill="#000"
                            fontSize="10.667"
                            letterSpacing="0.4"
                            textAnchor="start"
                        >
                            <tspan x="12.95" y="90.801" fontSize="10.667">
                                ON
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{
                                textAlign: 'end',
                            }}
                            x="531.59"
                            y="90.081"
                            fill={hos[3] ? '#000' : '#6f6f6f'}
                            letterSpacing="1.5"
                            textAnchor="end"
                        >
                            <tspan
                                x="533.09"
                                y="90.081"
                                style={{
                                    textAlign: 'end',
                                }}
                                fontSize="12.5"
                                textAnchor="end"
                            >
                                {hosTime[3].hours}:{hosTime[3].minutes.toString().padStart(2, '0')}:
                                {hosTime[3].seconds.toString().padStart(2, '0')}
                            </tspan>
                        </text>
                    </g>
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'end',
                        }}
                        x="531.59"
                        y="107.581"
                        fill="#000"
                        letterSpacing="1.5"
                        textAnchor="end"
                    >
                        <tspan
                            x="533.09"
                            y="107.581"
                            style={{
                                textAlign: 'end',
                            }}
                            fontSize="12.5"
                            textAnchor="end"
                        >
                            {hosTotal.hours}:{hosTotal.minutes.toString().padStart(2, '0')}:
                            {hosTotal.seconds.toString().padStart(2, '0')}
                        </tspan>
                    </text>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M34.492 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M43.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M39.002 34v6.376m0 11.572v6.454M39 68.906v7.562m0 11.042L39 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M48.002 34v6.332m0 11.572v6.498M48 69.217v7.348m0 10.99L48 94.805"
                    ></path>
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'center',
                        }}
                        x="34.247"
                        y="10.125"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="bold"
                        letterSpacing="0.75"
                        textAnchor="middle"
                        wordSpacing="0"
                    >
                        <tspan x="34.247" y="10.125" fontSize="5">
                            MID-
                        </tspan>
                        <tspan x="34.622" y="15.275" fontSize="5">
                            NIGHT
                        </tspan>
                    </text>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M52.493 20l-.002 75"
                    ></path>
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="50.222"
                        y="15.516"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="50.222" y="15.516" fontSize="7.5">
                            1
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M61.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M57.002 34v6.376m0 11.572v6.454M57 68.906v7.561m0 11.043L57 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M66.002 34v6.332m0 11.572v6.498M66 69.217v7.347m0 10.99L66 94.805"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="68.002"
                        y="15.407"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="68.002" y="15.407" fontSize="7.5">
                            2
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M70.494 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M79.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M75.002 34v6.376m0 11.572v6.454M75 68.906v7.561m0 11.043L75 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M84.002 34v6.332m0 11.572v6.498M84 69.217v7.347m0 10.99L84 94.805"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="86.39"
                        y="15.345"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="86.39" y="15.345" fontSize="7.5">
                            3
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M88.495 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M97.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M93.002 34v6.376m0 11.572v6.454M93 68.906v7.561m0 11.043L93 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M102.002 34v6.332m0 11.572v6.498M102 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="104.606"
                        y="15.345"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="104.606" y="15.345" fontSize="7.5">
                            4
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M106.496 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M115.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M111.002 34v6.376m0 11.572v6.454M111 68.906v7.562m0 11.042L111 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M120.002 34v6.332m0 11.572v6.498M120 69.217v7.348m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="141.039"
                        y="15.454"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="141.039" y="15.454" fontSize="7.5">
                            6
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M142.497 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M151.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M147.002 34v6.376m0 11.572v6.454M147 68.906v7.561m0 11.043L147 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M156.002 34v6.332m0 11.572v6.498M156 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="122.386"
                        y="15.345"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="122.386" y="15.345" fontSize="7.5">
                            5
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M124.496 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M133.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M129.002 34v6.376m0 11.572v6.454M129 68.906v7.561m0 11.043L129 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M138.002 34v6.332m0 11.572v6.498M138 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="158.491"
                        y="15.454"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="158.491" y="15.454" fontSize="7.5">
                            7
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M160.498 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M169.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M165.002 34v6.376m0 11.572v6.454M165 68.906v7.561m0 11.043L165 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M174.002 34v6.332m0 11.572v6.498M174 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="176.38"
                        y="15.673"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="176.38" y="15.673" fontSize="7.5">
                            8
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M178.499 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M187.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M183.002 34v6.376m0 11.572v6.454M183 68.906v7.562m0 11.042L183 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M192.002 34v6.332m0 11.572v6.498M192 69.217v7.348m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="210.304"
                        y="15.564"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="210.304" y="15.564" fontSize="7.5">
                            10
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M214.5 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M223.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M219.002 34v6.376m0 11.572v6.454M219 68.906v7.561m0 11.043L219 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M228.002 34v6.332m0 11.572v6.498M228 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="194.488"
                        y="15.345"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="194.488" y="15.345" fontSize="7.5">
                            9
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M196.5 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M205.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M201.002 34v6.376m0 11.572v6.454M201 68.906v7.561m0 11.043L201 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M210.002 34v6.332m0 11.572v6.498M210 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="228.676"
                        y="15.486"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="228.676" y="15.486" fontSize="7.5">
                            11
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M232.501 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M241.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M237.002 34v6.376m0 11.572v6.454M237 68.906v7.561m0 11.043L237 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M246.002 34v6.332m0 11.572v6.498M246 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'center',
                        }}
                        x="242.305"
                        y="15.434"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="bold"
                        letterSpacing="0.75"
                        textAnchor="middle"
                        wordSpacing="0"
                    >
                        <tspan
                            x="242.305"
                            y="15.434"
                            style={{
                                textAlign: 'start',
                            }}
                            fontSize="5"
                            textAnchor="start"
                        >
                            NOON
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M250.502 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M259.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M255.002 34v6.376m0 11.572v6.454M255 68.906v7.562m0 11.042L255 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M264.002 34v6.332m0 11.572v6.498M264 69.217v7.348m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="266.736"
                        y="15.606"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="266.736" y="15.606" fontSize="7.5">
                            1
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M268.503 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M277.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M273.002 34v6.376m0 11.572v6.454M273 68.906v7.561m0 11.043L273 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M282.002 34v6.332m0 11.572v6.498M282 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="284.516"
                        y="15.497"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="284.516" y="15.497" fontSize="7.5">
                            2
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M286.504 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M295.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M291.002 34v6.376m0 11.572v6.454M291 68.906v7.561m0 11.043L291 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M300.002 34v6.332m0 11.572v6.498M300 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="302.904"
                        y="15.435"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="302.904" y="15.435" fontSize="7.5">
                            3
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M304.504 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M313.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M309.002 34v6.376m0 11.572v6.454M309 68.906v7.561m0 11.043L309 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M318.002 34v6.332m0 11.572v6.498M318 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="321.12"
                        y="15.435"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="321.12" y="15.435" fontSize="7.5">
                            4
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M322.505 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M331.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M327.002 34v6.376m0 11.572v6.454M327 68.906v7.562m0 11.042L327 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M336.002 34v6.332m0 11.572v6.498M336 69.217v7.348m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="338.9"
                        y="15.435"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="338.9" y="15.435" fontSize="7.5">
                            5
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M340.506 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M349.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M345.002 34v6.376m0 11.572v6.454M345 68.906v7.561m0 11.043L345 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M354.002 34v6.332m0 11.572v6.498M354 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="357.552"
                        y="15.544"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="357.552" y="15.544" fontSize="7.5">
                            6
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M358.507 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M367.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M363.002 34v6.376m0 11.572v6.454M363 68.906v7.561m0 11.043L363 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M372.002 34v6.332m0 11.572v6.498M372 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="375.005"
                        y="15.544"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="375.005" y="15.544" fontSize="7.5">
                            7
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M376.508 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M385.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M381.002 34v6.376m0 11.572v6.454M381 68.906v7.561m0 11.043L381 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M390.002 34v6.332m0 11.572v6.498M390 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="392.894"
                        y="15.762"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="392.894" y="15.762" fontSize="7.5">
                            8
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M394.508 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M403.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M399.002 34v6.376m0 11.572v6.454M399 68.906v7.562m0 11.042L399 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M408.002 34v6.332m0 11.572v6.498M408 69.217v7.348m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="411.001"
                        y="15.435"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="411.001" y="15.435" fontSize="7.5">
                            9
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M412.51 20l-.003 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M421.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M417.002 34v6.376m0 11.572v6.454M417 68.906v7.561m0 11.043L417 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M426.002 34v6.332m0 11.572v6.498M426 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="426.818"
                        y="15.653"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="426.818" y="15.653" fontSize="7.5">
                            10
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M430.51 20l-.002 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M439.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M435.002 34v6.376m0 11.572v6.454M435 68.906v7.561m0 11.043L435 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M444.002 34v6.332m0 11.572v6.498M444 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'start',
                        }}
                        x="445.19"
                        y="15.576"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="0.5"
                        textAnchor="start"
                        wordSpacing="0"
                    >
                        <tspan x="445.19" y="15.576" fontSize="7.5">
                            11
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M448.51 20l-.001 75"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M457.502 27.852v12.524m0 5.074v12.952m-.001 5.385v12.757m0 5.58l-.001 12.68"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M453.002 34v6.376m0 11.572v6.454M453 68.906v7.561m0 11.043L453 94.805"
                    ></path>
                    <path
                        fill="none"
                        stroke="#a4a4a4"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="1"
                        d="M462.002 34v6.332m0 11.572v6.498M462 69.217v7.347m0 10.99l-.001 7.25"
                    ></path>
                </g>
                <g strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1">
                    <text
                        xmlSpace="preserve"
                        style={{
                            textAlign: 'center',
                        }}
                        x="467.912"
                        y="10.125"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="1"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="bold"
                        letterSpacing="0.75"
                        textAnchor="middle"
                        wordSpacing="0"
                    >
                        <tspan x="467.912" y="10.125" fontSize="5">
                            MID-
                        </tspan>
                        <tspan x="468.287" y="15.275" fontSize="5">
                            NIGHT
                        </tspan>
                    </text>
                    <path
                        fill="none"
                        stroke="#000"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        strokeWidth="0.981"
                        d="M466.512 20l-.002 75"
                    ></path>
                </g>
                <path
                    fill="none"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1.001"
                    d="M471.296 58.461l60 .001"
                ></path>
                <path
                    fill="none"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1.001"
                    d="M471.296 94.856l60 .001"
                ></path>
                <path
                    fill="none"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1.001"
                    d="M471.296 76.571l60 .001"
                ></path>
                <path
                    fill="none"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1.001"
                    d="M471.296 40.439h60"
                ></path>
                {points.length > 0 ? (
                    <g transform="translate(0,20)">
                        <polyline
                            data-testid="graph-line"
                            points={points.join(' ')}
                            fill="none"
                            stroke={palette.secondary}
                            strokeWidth="2"
                            strokeOpacity="1"
                            strokeDasharray=""
                        ></polyline>
                    </g>
                ) : null}
                {editSVG}
            </svg>
        </>
    );
};
