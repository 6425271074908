import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Tabs, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { HosDriver } from '../../../../backendsdk';
import { TabPanel } from '../../../../components/Tab';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedTab as Tab,
} from '../../../../components/TrackedComponents';
import { useAlert } from '../../../../hooks/alert';
import useApi from '../../../../hooks/api';
import useProfile from '../../../../hooks/profile';
import { dayjsToDateString, formatDayjs, shiftTimestamp } from '../../../../utils/TimeFormatter';
import { DriverRecord, EMPTY_DRIVER_RECORD, RECORD_CODE, RECORD_ORIGIN, RECORD_STATUS, RECORD_TYPE } from '../Defs';
import EditComponent from './EditComponent';
import EditRequestsDataGrid from './EditRequestsDataGrid';
import { Edit, Graph } from './Graph';
import RecordsDataGrid from './RecordsDataGrid';

dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(isSameOrAfter);

const urlToStartOfDay = (dateStr: string, offset: number) => {
    return dayjs(dateStr + 'T00:00:00.0000Z').add(offset, 'hour');
};

const isValidDate = (dateStr: string | undefined, offset: number, now: Dayjs) => {
    if (!dateStr) {
        return false;
    }
    const urlDate = urlToStartOfDay(dateStr, offset);
    return urlDate.isValid() && urlDate.unix() <= now.unix();
};

const isDateCertified = (records: DriverRecord[]) => {
    const activeRecords = records.filter((record) => record.record_status === RECORD_STATUS.ACTIVE);

    return (
        activeRecords.length === 0 ||
        activeRecords[activeRecords.length - 1].record_type ===
            RECORD_TYPE.A_DRIVERS_CERTIFICATION_RE_CERTIFICATION_OF_RECORDS
    );
};

export interface DriverLogProps {
    now: Dayjs;
    driver: HosDriver;
}

const DriverLog: React.FC<DriverLogProps> = (props: DriverLogProps) => {
    const [date, setDate] = useState<Dayjs>(props.now);
    const [allRecords, setAllRecords] = useState<DriverRecord[]>();
    const { driverId, dateStr, requestId } = useParams<{ driverId: string; dateStr: string; requestId: string }>();
    const [editMode, setEditMode] = useState<boolean>(requestId === '0');
    const [editPreview, setEditPreview] = useState<Edit>();
    const [loading, setLoading] = useState<boolean>(true);
    const [tab, setTab] = useState<number>(!!requestId && requestId !== '0' ? 1 : 0);
    const [alertElement, setAlert] = useAlert();
    const { hosApi } = useApi();
    const history = useHistory();
    const { profile } = useProfile();

    const homeTerminalOffset = props.driver.home_terminal.timezone_offset_from_utc;
    const dateString = dayjsToDateString(date, props.driver);

    let isToday = props.now.isBefore(date.add(1, 'day'));
    if (props.driver) {
        isToday = !(props.now.utc().unix() > date.add(1, 'day').unix());
    }

    let hosRecords: DriverRecord[] = [];
    if (allRecords !== undefined) {
        const activeHosRecords: DriverRecord[] = allRecords
            .filter(
                (record: DriverRecord) =>
                    record.record_type == RECORD_TYPE.A_CHANGE_IN_DRIVERS_DUTY_STATUS &&
                    record.record_status === RECORD_STATUS.ACTIVE,
            )
            .sort((a: DriverRecord, b: DriverRecord) => a.event_datetime - b.event_datetime);

        const yesterday: DriverRecord[] = activeHosRecords.filter(
            (record: DriverRecord) => record.event_datetime < date.unix(),
        );
        let first: DriverRecord;
        if (yesterday.length > 0) {
            first = { ...yesterday[yesterday.length - 1] };
            first.event_datetime = date.unix();
        } else {
            first = { ...activeHosRecords[0] };
            first.event_datetime = date.unix();
            // if there's no record on the day before, start this day with off duty
            first.record_code = RECORD_CODE.DRIVERS_DUTY_STATUS_CHANGED_TO_OFF_DUTY;
        }

        hosRecords = activeHosRecords.slice(yesterday.length);
        if (hosRecords.length === 0 || first.event_datetime !== hosRecords[0].event_datetime) {
            hosRecords.unshift(first);
        }
    }

    let todaysRecords: DriverRecord[] = [];
    let existingEdits: Edit[] = [];
    if (allRecords !== undefined) {
        todaysRecords =
            allRecords !== undefined
                ? allRecords.filter((record: DriverRecord) => record.event_datetime >= date.unix())
                : [];

        const todaysRequests: DriverRecord[] = todaysRecords.filter(
            (record) =>
                record.record_status === RECORD_STATUS.INACTIVE_CHANGE_REQUESTED ||
                record.record_status === RECORD_STATUS.INACTIVE_CHANGE_REJECTED,
        );

        const requestsByCreationDate = todaysRequests.reduce((acc: Record<string, DriverRecord[]>, r: DriverRecord) => {
            acc[r.created_date.toString()] ??= [];
            acc[r.created_date.toString()].push(r);
            return acc;
        }, {} as Record<string, DriverRecord[]>);
        const filteredRequests = Object.fromEntries(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Object.entries(requestsByCreationDate).filter(([_, v]) => v.length === 2),
        );
        existingEdits = Object.keys(filteredRequests).map((creationDate) => {
            const editRecords = filteredRequests[creationDate].sort((a, b) => a.event_datetime - b.event_datetime);
            return {
                id: editRecords[0].id,
                startTime: editRecords[0].event_datetime,
                endTime: editRecords[1].event_datetime,
                recordCode: editRecords[0].record_code,
                comment: editRecords[0].comment,
                status: editRecords[0].record_status,
            };
        });
    }

    const getRecords = (controller?: AbortController) => {
        setLoading(true);
        const dateWithOffset = date.utcOffset(-homeTerminalOffset);
        hosApi
            .hosV0DriverDriverIdRecordDateFromDateToGet(
                {
                    driverId: props.driver.driver_id,
                    dateFrom: formatDayjs(dateWithOffset.subtract(1, 'day')),
                    dateTo: formatDayjs(dateWithOffset),
                },
                { signal: controller?.signal },
            )
            .then((res) => {
                setAllRecords(res.data);
                setLoading(false);
            })
            .catch(() => {
                if (!controller?.signal.aborted) {
                    setAlert({ message: 'Error fetching driver records', type: 'error', duration: 6000 });
                }
            });
    };

    const resetState = () => {
        setAllRecords(undefined);
        setEditPreview(undefined);
    };

    useEffect(() => {
        if (props.driver && dateStr && isValidDate(dateStr, homeTerminalOffset, props.now)) {
            setDate(urlToStartOfDay(dateStr, homeTerminalOffset));
        }
    }, [props.driver, dateStr]);

    useEffect(() => {
        const controller = new AbortController();
        getRecords(controller);
        return () => controller.abort();
    }, [date]);

    useEffect(() => {
        setEditMode(requestId === '0');
    }, [requestId]);

    useEffect(() => {
        if (!editMode) {
            setEditPreview(undefined);
        }
    }, [editMode]);

    const todaysDateStr = dayjsToDateString(props.now, props.driver);
    const todaysDate = urlToStartOfDay(todaysDateStr, props.driver.home_terminal.timezone_offset_from_utc);

    if (!isValidDate(dateStr, homeTerminalOffset, props.now)) {
        return <Redirect to={`/driver_logs/${driverId}/${dayjsToDateString(props.now, props.driver)}`} />;
    }

    const changeDate = (step: number) => {
        if (props.driver) {
            history.push(`/driver_logs/${driverId}/${dayjsToDateString(date, props.driver, step)}`);
        }
    };

    const isCertified = isDateCertified(todaysRecords);

    const handleSave = (editRequest: Edit) => {
        const returnStatus = [...hosRecords]
            .reverse()
            .find(
                (record) => record.event_datetime <= shiftTimestamp(editRequest.endTime, homeTerminalOffset),
            )?.record_code;
        if (returnStatus !== undefined) {
            const recordEdits: DriverRecord[] = [
                {
                    ...EMPTY_DRIVER_RECORD,
                    device: props.driver.current_device?.license_plate || '',
                    driver: props.driver.driver_id,
                    record_type: RECORD_TYPE.A_CHANGE_IN_DRIVERS_DUTY_STATUS,
                    record_origin: RECORD_ORIGIN.EDIT_REQUESTED_BY_AN_AUTHENTICATED_USER_OTHER_THAN_THE_DRIVER,
                    event_datetime: shiftTimestamp(editRequest.startTime, homeTerminalOffset),
                    time_zone_offset_from_utc: homeTerminalOffset,
                    record_code: editRequest.recordCode as RECORD_CODE,
                    comment: editRequest.comment,
                },
                {
                    ...EMPTY_DRIVER_RECORD,
                    device: props.driver.current_device?.license_plate || '',
                    driver: props.driver.driver_id,
                    record_type: RECORD_TYPE.A_CHANGE_IN_DRIVERS_DUTY_STATUS,
                    record_origin: RECORD_ORIGIN.EDIT_REQUESTED_BY_AN_AUTHENTICATED_USER_OTHER_THAN_THE_DRIVER,
                    event_datetime: shiftTimestamp(editRequest.endTime, homeTerminalOffset),
                    time_zone_offset_from_utc: homeTerminalOffset,
                    record_code: returnStatus,
                    comment: editRequest.comment,
                },
            ];
            hosApi
                .hosV0DriverRecordAmendPost({ eventEntry: recordEdits })
                .then((res) => {
                    resetState();
                    getRecords();
                    history.replace(`/driver_logs/${driverId}/${dateString}/${res.data[0].id}`);
                })
                .catch(() => {
                    setAlert({ message: 'Failed to send edit request', type: 'error', duration: 6000 });
                    history.replace(`/driver_logs/${driverId}/${dateString}`);
                });
        }
    };

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const a11yProps = (index: number) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    };

    return (
        <Box sx={{ maxWidth: '800px', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {alertElement}
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', mb: 1 }}>
                    <Typography variant="body3">Driver Logs</Typography>
                    <Typography variant="h6">{`${props.driver.first_name} ${props.driver.last_name}`}</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {!editMode ? (
                        <IconButton
                            id="btn-previous"
                            onClick={() => {
                                setLoading(true);
                                resetState();
                                changeDate(-1);
                            }}
                            data-testid="back"
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                    ) : null}
                    <DatePicker
                        label="Date"
                        format={profile.dateFormat}
                        value={date}
                        disabled={editMode}
                        maxDate={todaysDate}
                        disableHighlightToday
                        onChange={(value: Dayjs | null) => {
                            if (value !== null) {
                                setDate(value);
                            }
                        }}
                    />
                    {!editMode ? (
                        <IconButton
                            id="btn-next"
                            onClick={() => {
                                setLoading(true);
                                resetState();
                                changeDate(1);
                            }}
                            disabled={isToday}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    ) : null}
                </Box>
                <Box
                    sx={{
                        height: '20px',
                        flexShrink: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 0.5,
                    }}
                >
                    {!isCertified ? <Typography variant="caption">Not Certified</Typography> : null}
                </Box>
                <Box sx={{ position: 'relative' }}>
                    <Graph
                        records={props.now.isAfter(date) ? hosRecords : []}
                        startOfDay={date}
                        now={props.now}
                        editPreview={
                            existingEdits.filter((request: Edit) => request.id.toString() === requestId)[0] ||
                            editPreview
                        }
                        loading={loading}
                    />
                </Box>
                {editMode ? (
                    <EditComponent
                        now={props.now}
                        date={date}
                        utcOffset={homeTerminalOffset}
                        records={hosRecords}
                        setEditPreview={setEditPreview}
                        onSave={handleSave}
                        onClose={() => {
                            history.replace(`/driver_logs/${driverId}/${dateString}`);
                        }}
                    />
                ) : (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                            <Box>
                                <Button
                                    id="btn-back"
                                    variant="contained"
                                    sx={{ mr: 1 }}
                                    onClick={() => {
                                        history.push(`/driver_logs`);
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    id="btn-edit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={loading}
                                    onClick={() => {
                                        setEditPreview(undefined);
                                        history.replace(`/driver_logs/${driverId}/${dateString}/0`);
                                    }}
                                >
                                    Request Edit
                                </Button>
                            </Box>
                        </Box>
                        {!loading ? (
                            <>
                                <Tabs
                                    value={tab}
                                    onChange={handleTabChange}
                                    TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                                >
                                    <Tab label="Records" {...a11yProps(0)} id="records-tab" data-testid={`tab-0`} />
                                    <Tab
                                        label="Edit Requests"
                                        {...a11yProps(1)}
                                        id="edit-requests-tab"
                                        data-testid={`tab-1`}
                                    />
                                </Tabs>
                                <TabPanel value={tab} index={0} sx={{ height: '100%' }}>
                                    <RecordsDataGrid
                                        driver={props.driver}
                                        date={date}
                                        isToday={isToday}
                                        records={hosRecords}
                                    />
                                </TabPanel>
                                <TabPanel value={tab} index={1} sx={{ height: '100%' }}>
                                    {existingEdits.length > 0 ? (
                                        <EditRequestsDataGrid driver={props.driver} requests={existingEdits} />
                                    ) : (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                                            <Typography variant="overline">No existing edit requests</Typography>
                                        </Box>
                                    )}
                                </TabPanel>
                            </>
                        ) : null}
                    </>
                )}
            </LocalizationProvider>
        </Box>
    );
};

export default DriverLog;
