import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
    Box,
    FormControl,
    InputLabel,
    Popover,
    Select,
    SelectChangeEvent,
    Slide,
    SlideProps,
    Snackbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Damage, ExtendedAccident, PolicyV2, UserRole } from '../../../backendsdk';
import { ResponsiveDatePicker } from '../../../components/MuiDatePicker';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import { accidentsPageDefs } from '../../../utils/Pages';
import { getStorageItem, setStorageItem } from '../../../utils/Storage';
import { b64DecodeUnicode, normalizeLicensePlate, queryParamsToObject } from '../../../utils/Str';
import { formatDateFromOrdinal, formatDateTime } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { MBI_DAMAGE } from './AccidentDamageDetails';
import AccidentDetailsComponent from './AccidentDetails';
import AccidentFiltersForm, { filterType } from './AccidentFilters';
import AccidentSpan from './AccidentSpan';
import { IL_STAGES, US_STAGES } from './Defs';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const STAGE_LEVEL: Record<string, number> = {
    new: 1,
    'pending-info': 2,
    'pending-3rd-party': 2,
    'policy-claim': 3,
    'demand-on-behalf': 3,
    'self-solve': 3,
    lawsuit: 3,
    done: 4,
};

export const b64EncodeUnicode = (str: string) => {
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_match, p1) => {
            return String.fromCharCode(parseInt('0x' + p1));
        }),
    );
};

interface StageColumnProps {
    stage: string;
    accidents: Array<ExtendedAccident>;
    setAccidents: CallableFunction;
    policies: Array<PolicyV2>;
    query: string;
    selectedAccident?: ExtendedAccident;
    setSelectedAccident: CallableFunction;
}

export const StageColumn: React.FC<StageColumnProps> = (props: StageColumnProps) => {
    const [archivedAccident, setArchivedAccident] = useState<ExtendedAccident>();
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { agencyApi } = useApi();
    const ALLOW_CHANGE_STAGE = [...accidentsPageDefs.permissions.L1, ...accidentsPageDefs.permissions.L2];

    useEffect(() => {
        if (archivedAccident) {
            setTimeout(() => {
                setArchivedAccident(undefined);
            }, 6000);
        }
    }, [archivedAccident]);

    const changeArchivedStatus = (accident: ExtendedAccident, archived: boolean) =>
        agencyApi
            .agencyV1AccidentAccidentIdPatch({
                accidentId: accident.accident_id,
                extendedAccident: { ...accident, archived },
            })
            .then(() => (archived ? archiveAccident(accident) : undoAccidentArchive(accident)));

    const archiveAccident = (accident: ExtendedAccident) => {
        setArchivedAccident(accident);
        props.setAccidents((prev: ExtendedAccident[]) =>
            prev.filter((a: ExtendedAccident) => a.accident_id !== accident.accident_id),
        );
    };

    const undoAccidentArchive = (accident: ExtendedAccident) => {
        if (accident) {
            props.setAccidents((prev: ExtendedAccident[]) =>
                [...prev, accident].sort((a, b) => b.accident_id - a.accident_id),
            );
            setArchivedAccident(undefined);
        }
    };

    return (
        <>
            <Droppable droppableId={props.stage}>
                {(provided) => (
                    <div
                        className={'grid-stage'}
                        style={{ gridArea: props.stage }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <div className={`grid-stage-header grid-stage-top-header-${STAGE_LEVEL[props.stage]}`}>
                            {t(`content.accidents.stage.${profile.customer.settings.country}.${props.stage}`)} - (
                            {props.accidents.length})
                        </div>
                        <div className={`stage-${props.stage} grid-stage-body`}>
                            {props.accidents.map((accident, index) => (
                                <Draggable
                                    draggableId={accident.accident_id.toString()}
                                    key={accident.accident_id}
                                    index={index}
                                    isDragDisabled={!ALLOW_CHANGE_STAGE.includes(profile.role)}
                                >
                                    {(provided) => (
                                        <div
                                            id={`accident-${accident.accident_id.toString()}`}
                                            style={{ border: `1px solid ${palette.black}` }}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <AccidentSpan
                                                accident={accident}
                                                policies={props.policies}
                                                setSelectedAccident={props.setSelectedAccident}
                                                selectedAccident={props.selectedAccident}
                                                query={props.query}
                                                changeArchivedStatus={changeArchivedStatus}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                    </div>
                )}
            </Droppable>

            <Snackbar
                open={!!archivedAccident}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                TransitionComponent={(props) => <Slide {...props} direction="up" />}
                message={
                    <Typography sx={{ pt: 0.5 }}>
                        {t('content.accidents.archive.success', { accident_id: archivedAccident?.accident_id }) + '. '}
                        <Link
                            id="undo-archive-link"
                            color="inherit"
                            onClick={() => (archivedAccident ? changeArchivedStatus(archivedAccident, false) : null)}
                            sx={{ cursor: 'pointer' }}
                        >
                            {t('content.accidents.archive.undo')}
                        </Link>
                    </Typography>
                }
                sx={{ '& .MuiPaper-root': { backgroundColor: 'primary.main', minWidth: 0 }, zIndex: 1200 }}
            />
        </>
    );
};

type AccidentFilterField = 'timestamp' | 'created_at';

interface AccidentComponentProps {
    handlePageVersionChange: CallableFunction;
}

const AccidentComponent: React.FC<AccidentComponentProps> = (props) => {
    const [accidents, setAccidents] = useState<Array<ExtendedAccident>>([]);
    const [policies, setPolicies] = useState<Array<PolicyV2>>([]);
    const [loadingPolicies, setLoadingPolicies] = useState<boolean>(true);
    const [selectedAccident, setSelectedAccident] = useState<ExtendedAccident>();
    const [modalMessage, setModalMessage] = useState<string | JSX.Element>();
    const [loadingCounter, setLoadingCounter] = useState<number>(0);
    const [isLoadingAccidents, setIsLoadingAccidents] = useState<boolean>(true);
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [filters, setFilters] = useState<filterType>({});
    const [handlingStatusList, setHandlingStatusList] = useState<Array<string>>([]);
    const initialDay = dayjs().date() < 16 ? 1 : 16;
    const [filterField, setFilterField] = useState<AccidentFilterField>(
        (getStorageItem('OE-filter-field') as AccidentFilterField) || 'timestamp',
    );
    const [fromDate, setFromDate] = useState<Dayjs>(
        dayjs().startOf('day').subtract(3, 'month').set('date', initialDay),
    );
    const [toDate, setToDate] = useState<Dayjs>(dayjs().endOf('day'));
    const [isPopOverOpen, setIsPopOverOpen] = useState<boolean>(true);
    const ref = useRef();
    const history = useHistory();
    const query = useQuery();
    const match = useRouteMatch();
    const { accidentId } = useParams<{ accidentId: string }>();
    const { i18n, t } = useTranslation();
    const { agencyApi } = useApi();
    const { profile } = useProfile();
    const [alertElement, setAlert] = useAlert();

    const stages = profile.customer.settings.country == 'il' ? IL_STAGES : US_STAGES;

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${accidentsPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        getPolicies();
    }, []);

    useEffect(() => {
        if (showFilters) {
            setShowFilters(false);
        }
    }, [selectedAccident]);

    useEffect(() => {
        const queryFilters = query.get('filters');
        if (!!filters && queryFilters) {
            setFilters(() => JSON.parse(b64DecodeUnicode(queryFilters)));
        }
    }, []);

    useEffect(() => {
        query.set('filters', b64EncodeUnicode(JSON.stringify(filters)));
        history.push(`${match.url}?${query.toString()}`);
    }, [filters]);

    useEffect(() => {
        agencyApi
            .agencyV1CustomerDataGet()
            .then((res) =>
                setHandlingStatusList(res.data.handling_status.map((status: { name: string }) => status.name)),
            );
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsPopOverOpen(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, [ref]);

    const resetState = () => {
        setLoadingCounter((prev) => prev + 1);
    };

    const getPolicies = () => {
        agencyApi
            .agencyV2PolicyGet()
            .then((res) => {
                setPolicies(res.data);
            })
            .catch((err) => {
                if (err.response?.status !== 404) {
                    setAlert({ message: t('content.accidents.error_loading'), type: 'error', duration: 6000 });
                }
            })
            .finally(() => setLoadingPolicies(false));
    };

    const loadAccidents = (
        controller: AbortController,
        filterBy: AccidentFilterField,
        from: Dayjs,
        to: Dayjs,
        last_id?: number,
    ) => {
        setIsLoadingAccidents(true);
        let lastId: number | undefined = undefined;
        if (last_id != undefined) {
            lastId = last_id;
        }
        const filterParams =
            filterBy === 'created_at'
                ? { createdFrom: from.unix(), createdTo: to.unix() }
                : { timeFrom: from.unix(), timeTo: to.unix() };
        agencyApi
            .agencyV1AccidentGet(
                {
                    lastId,
                    ...queryParamsToObject(query),
                    ...filterParams,
                },
                { signal: controller.signal },
            )
            .then((res) => {
                if (res.data.length > 0) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const accidents = res.data.map((a: any) => {
                        a.datetime = formatDateTime(a.datetime).replace(' ', 'T');
                        a.visiting_scene_date = a.visiting_scene_date
                            ? formatDateFromOrdinal(a.visiting_scene_date)
                            : '';
                        return a;
                    });
                    setAccidents((prevAccidents) => {
                        const seen = new Set();
                        const allAccidents = [...accidents, ...prevAccidents];
                        const newAccidents = allAccidents.reduce(
                            (obj: Array<ExtendedAccident>, elem: ExtendedAccident) => {
                                if (seen.has(elem.accident_id)) {
                                    return obj;
                                }
                                seen.add(elem.accident_id);
                                return [...obj, elem];
                            },
                            [],
                        );
                        return newAccidents.sort(
                            (a: ExtendedAccident, b: ExtendedAccident) => b.accident_id - a.accident_id,
                        );
                    });
                    if (!selectedAccident && history.location.pathname.endsWith('/accident')) {
                        const first_event = accidents[0];
                        setSelectedAccident(first_event);
                        history.push(`${match.url}/${first_event.event_id}?${query.toString()}`);
                        setModalMessage(t('content.accidents.missing_values'));
                    }
                    loadAccidents(controller, filterBy, from, to, res.data[res.data.length - 1].accident_id);
                } else {
                    setIsLoadingAccidents(false);
                }
            })
            .catch(() => {
                if (!controller?.signal.aborted) {
                    setIsLoadingAccidents(false);
                    setAlert({ message: t('content.accidents.error_loading'), type: 'error', duration: 6000 });
                }
            });
    };

    useEffect(() => {
        setStorageItem('OE-filter-field', filterField);
    }, [filterField]);

    useEffect(() => {
        if (fromDate.isValid() && toDate.isValid() && fromDate.isBefore(toDate)) {
            const controller = new AbortController();
            loadAccidents(controller, filterField, fromDate, toDate);
            return () => controller.abort();
        }
    }, [loadingCounter, filterField, fromDate, toDate]);

    useEffect(() => setLoadingCounter((prev) => prev + 1), []);

    const filterAccidents = (elem: ExtendedAccident) => {
        let result = true;
        for (const [key, filter_value] of Object.entries(filters)) {
            // optimization: stop on first false
            if (!result) {
                break;
            }
            if (key === 'claim_number') {
                result &&=
                    elem.damage_list
                        .map(
                            (damage: Damage) =>
                                damage.claim &&
                                damage.claim.claim_number &&
                                damage.claim.claim_number.includes(filter_value),
                        )
                        .filter(Boolean).length > 0;
            } else if (key == 'free') {
                const lowerFilterValue = filter_value.toString().toLocaleLowerCase();
                result &&= Boolean(
                    elem.description?.toLocaleLowerCase().includes(lowerFilterValue) ||
                        elem.internal_status?.toLocaleLowerCase().includes(lowerFilterValue) ||
                        elem.internal_id.toLocaleLowerCase().includes(lowerFilterValue) ||
                        elem.license_plate
                            .toLocaleLowerCase()
                            .replaceAll('-', '')
                            .includes(lowerFilterValue.replaceAll('-', '')) ||
                        elem.driver_name?.toLocaleLowerCase().includes(lowerFilterValue) ||
                        elem.sub_fleet_name?.toLocaleLowerCase().includes(lowerFilterValue) ||
                        elem.assignee?.toLocaleLowerCase().includes(lowerFilterValue),
                );
            } else if (key == 'date_from') {
                result &&= elem.timestamp >= filter_value;
            } else if (key == 'date_to') {
                result &&= elem.timestamp <= filter_value;
            } else if (key == 'damage_type') {
                const arrayToNumber = (array: Array<boolean>) =>
                    array.map((elem, idx) => (elem ? 2 ** idx : 0)).reduce((a, b) => a + b);
                const damageTypeFilters = filter_value.map((type: string) =>
                    arrayToNumber([type.startsWith('own'), type.endsWith('mbi')]),
                );
                result &&=
                    elem.damage_list
                        .map((damage) =>
                            damageTypeFilters.includes(
                                arrayToNumber([!damage.vehicle.third_party, damage.category == MBI_DAMAGE]),
                            ),
                        )
                        .filter(Boolean).length > 0;
            } else if (key == 'third_party_license_plate') {
                const normalizedFilterValue = normalizeLicensePlate(filter_value);
                result &&= elem.damage_list
                    .filter((damage) => damage.vehicle.third_party && damage.vehicle.license_plate)
                    .map((damage) =>
                        normalizeLicensePlate(damage.vehicle.license_plate).includes(normalizedFilterValue),
                    )
                    .some(Boolean);
            } else {
                let value = { ...elem }[key as keyof ExtendedAccident]?.toString().trim();
                if (key == 'license_plate' && value) {
                    value = normalizeLicensePlate(value);
                }
                if (
                    (filter_value &&
                        value != undefined &&
                        !value.toString().includes(filter_value) &&
                        (!Array.isArray(filter_value) || !filter_value.includes(value))) ||
                    !value
                ) {
                    result = false;
                }
            }
        }
        return result;
    };

    const downloadCSV = () => {
        agencyApi.agencyV1AccidentGet({ csv: 1, ...queryParamsToObject(query) }).then(() => {
            setModalMessage(t(`content.accidents.report will be sent to your email`));
        });
    };

    const moveAccident = (accidentId: string, stage: string) => {
        setAccidents((prev) => {
            return prev.map((accident) =>
                accident.accident_id.toString() == accidentId ? { ...accident, stage: stage } : accident,
            );
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const moveAccidentStage = (e: any) => {
        if (e.destination != null) {
            const draggedAccidentId = e.draggableId;
            const destinationStage = e.destination.droppableId;
            const accident = accidents.find((accident) => accident.accident_id == draggedAccidentId);
            if (accident != undefined) {
                setModalMessage(
                    <>
                        <Spinner animation="border" size="sm" />
                        {` ${t('content.accidents.moving_accident', {
                            stage: t(
                                `content.accidents.stage.${profile.customer.settings.country}.${destinationStage}`,
                            ),
                        })}`}
                    </>,
                );
                moveAccident(draggedAccidentId, destinationStage);
                agencyApi
                    .agencyV1AccidentAccidentIdPatch({
                        accidentId: draggedAccidentId,
                        extendedAccident: { ...accident, stage: destinationStage },
                    })
                    .then(() => {
                        setModalMessage(undefined);
                        mixpanel.track('buttonClick', {
                            language: i18n.languages[0],
                            type: 'changeStage',
                            page: window.location.pathname,
                            accident_id: accident.accident_id,
                            license_plate: accident.license_plate,
                            driver_name: accident.driver_name,
                            sub_fleet: accident.sub_fleet_name,
                            stage_from: accident.stage,
                            stage_to: destinationStage,
                            assignee: accident.assignee,
                        });
                    })
                    .catch((res) => {
                        moveAccident(draggedAccidentId, accident.stage);
                        setSelectedAccident(accident);
                        history.push(`${match.url}/${draggedAccidentId}?${query.toString()}`);
                        setModalMessage(t(`content.accidents.${res.response.data.message || 'change_stage_error'}`));
                    });
            }
        }
    };

    const accidentsWithinDateRange = accidents.filter((accident) => {
        const datetimeField = filterField == 'timestamp' ? accident.timestamp : accident.created_at;
        return accident[filterField] >= fromDate.unix() && datetimeField <= toDate.unix();
    });
    const filteredAccidents = accidentsWithinDateRange.filter(filterAccidents);

    const handleModalClose = () => {
        const params = new URLSearchParams();
        const filters = query.get('filters');
        if (filters) {
            params.set('filters', filters);
        }
        history.push(`/accidents?${params.toString()}`);
    };

    return (
        <Box sx={{ display: 'flex', height: '100%', position: 'relative' }}>
            {alertElement}
            <LoadingSnackbar isLoading={isLoadingAccidents} />
            {isLoadingModal ? (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'var(--bg-color)',
                        opacity: 0.5,
                        zIndex: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ transform: 'scale(2)' }}>
                        <Spinner animation="border" role="status" />
                    </div>
                </div>
            ) : null}
            <div
                className={`grid-${profile.customer.settings.country}`}
                style={{ position: 'relative', width: '100%', padding: '1em' }}
            >
                <div style={{ gridArea: 'header' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'end',
                            flexWrap: 'wrap',
                            mb: -1,
                        }}
                    >
                        <Box sx={{ display: 'flex', mb: 1, gap: 1 }}>
                            {profile.role != UserRole.CarShop ? (
                                <Button
                                    id="new-btn"
                                    onClick={() => {
                                        setShowFilters(false);
                                        history.push(`accidents/0?${query.toString()}`);
                                    }}
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                >
                                    {t('content.accidents.new')}
                                </Button>
                            ) : null}
                            <Button
                                id="download-report-btn"
                                onClick={downloadCSV}
                                variant="outlined"
                                startIcon={<DownloadIcon />}
                            >
                                {t('content.accidents.download')}
                            </Button>
                            <Button
                                id="show-filters-btn"
                                onClick={() => setShowFilters((prev) => !prev)}
                                variant={showFilters ? 'contained' : 'outlined'}
                                startIcon={<FilterAltIcon />}
                            >
                                {t(`content.accidents.${showFilters ? 'hide_filters' : 'show_filters'}`)}
                            </Button>
                            <Button
                                id="clear-filters-btn"
                                onClick={() => setFilters({})}
                                variant="outlined"
                                startIcon={<FilterAltOffIcon />}
                            >
                                {t('content.accidents.filters.clear')}
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 1 }}>
                            <Tooltip title={t('content.accidents.change_page_new')} ref={ref}>
                                <Box>
                                    <IconButton
                                        id="change-page-btn"
                                        onClick={() => props.handlePageVersionChange('v1')}
                                    >
                                        <SwapHorizIcon />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                            <Popover
                                open={isPopOverOpen}
                                anchorEl={ref.current}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                PaperProps={{
                                    style: {
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none',
                                        borderRadius: 0,
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        mt: '10px',
                                        '&::before': {
                                            backgroundColor: 'secondary.main',
                                            borderTop: `${palette.primary} solid 1px`,
                                            borderLeft: `${palette.primary} solid 1px`,
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            width: 12,
                                            height: 12,
                                            top: -6,
                                            transform: 'rotate(45deg)',
                                            left: 'calc(50% - 6px)',
                                        },
                                    }}
                                />
                                <Box
                                    sx={{
                                        p: 1,
                                        backgroundColor: 'secondary.main',
                                        border: `${palette.primary} solid 1px`,
                                        borderRadius: '5px',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                        maxWidth: '200px',
                                    }}
                                >
                                    <Typography color="primary">
                                        {t('content.accidents.change_page_popover')}
                                    </Typography>
                                </Box>
                            </Popover>
                            <Tooltip title={t('content.accidents.refresh')}>
                                <IconButton id="refresh-btn" onClick={resetState}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                            <FormControl sx={{ width: 175, mr: 1 }}>
                                <InputLabel id="filter-mode-label">
                                    {t('content.accidents.filters.filter_by')}
                                </InputLabel>
                                <Select
                                    id="filter-mode-field"
                                    labelId="filter-mode-label"
                                    fullWidth
                                    size="small"
                                    label={t('content.accidents.filters.filter_by')}
                                    defaultValue="ALL"
                                    value={filterField}
                                    onChange={(e: SelectChangeEvent) => {
                                        setFilterField(e.target.value as AccidentFilterField);
                                    }}
                                >
                                    {['timestamp', 'created_at'].map((option) => (
                                        <MenuItem key={option} value={option} id={option}>
                                            {t(`content.accidents.filters.${option}`)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.languages[0]}>
                                <ResponsiveDatePicker
                                    label={t('content.accidents.from')}
                                    format={profile.dateFormat}
                                    value={fromDate}
                                    onChange={(value: Dayjs | null) => {
                                        if (value !== null) {
                                            setFromDate(value.startOf('day'));
                                        }
                                    }}
                                    maxDate={toDate}
                                    slotProps={{
                                        textField: { id: 'from-field', size: 'small', sx: { width: 250, mr: 1 } },
                                    }}
                                />
                                <ResponsiveDatePicker
                                    label={t('content.accidents.to')}
                                    format={profile.dateFormat}
                                    value={toDate}
                                    onChange={(value: Dayjs | null) => {
                                        if (value !== null) {
                                            setToDate(value.endOf('day'));
                                        }
                                    }}
                                    maxDate={dayjs()}
                                    slotProps={{ textField: { id: 'to-field', size: 'small', sx: { width: 250 } } }}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                </div>
                {profile.customer.settings.country == 'il' ? (
                    <>
                        {[1, 2, 3, 4].map((idx) => (
                            <div
                                key={idx}
                                className={`grid-stage-top-header grid-stage-top-header-${idx}`}
                                style={{ gridArea: `top-header-${idx}` }}
                            >
                                {t('content.accidents.stage.stage')} {idx}
                            </div>
                        ))}
                    </>
                ) : null}
                <DragDropContext onDragEnd={moveAccidentStage}>
                    {stages.map((stage) => (
                        <StageColumn
                            key={stage}
                            stage={stage}
                            accidents={filteredAccidents.filter(
                                (accident) =>
                                    // (stage == 'new' && !accident.stage) can be removed after all accidents are in new stage
                                    accident.stage.toLocaleLowerCase() == stage || (stage == 'new' && !accident.stage),
                            )}
                            setAccidents={setAccidents}
                            policies={policies}
                            setSelectedAccident={(accident: ExtendedAccident) => {
                                setShowFilters(false);
                                setSelectedAccident(accident);
                            }}
                            selectedAccident={selectedAccident}
                            query={query.toString()}
                        />
                    ))}
                </DragDropContext>
            </div>
            {showFilters ? (
                <AccidentFiltersForm filters={filters} setFilters={setFilters} accidents={accidents} />
            ) : (
                accidentId && (
                    <AccidentDetailsComponent
                        pageVersion="v0"
                        accidentId={accidentId}
                        refreshTable={setAccidents}
                        setIsLoadingModal={setIsLoadingModal}
                        handlingStatusList={handlingStatusList}
                        setHandlingStatusList={setHandlingStatusList}
                        onClose={handleModalClose}
                        setSelectedAccident={setSelectedAccident}
                        policies={policies}
                        loadingPolicies={loadingPolicies}
                    />
                )
            )}
            <Modal show={modalMessage != undefined} onHide={() => setModalMessage(undefined)}>
                <Modal.Body onClick={() => setModalMessage(undefined)}>{modalMessage}</Modal.Body>
            </Modal>
        </Box>
    );
};

export default AccidentComponent;

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

const LoadingSnackbar: React.FC<{ isLoading: boolean }> = React.memo(function LoadingSnackbar(props: {
    isLoading: boolean;
}) {
    const { t } = useTranslation();

    return (
        <Snackbar
            open={props.isLoading}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            TransitionComponent={SlideTransition}
            message={<Typography>{t('content.accidents.loading')}</Typography>}
            sx={{ '& .MuiPaper-root': { backgroundColor: 'primary.main', minWidth: 0 }, zIndex: 1200 }}
        />
    );
});
