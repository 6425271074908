import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Alert, Box, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button, TrackedIconButton as IconButton } from '../../components/TrackedComponents';
import useApi from '../../hooks/api';
import LogoPrimary from '../../images/logo/logo_two_tone.svg';
import { validatePassword } from './Password';

interface ResetFormProps {
    resetToken?: string;
    onSuccess: () => void;
    errorMessage: string;
}

const ResetForm: React.FC<ResetFormProps> = (props: ResetFormProps) => {
    const [password1, setPassword1] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { api, isAuthenticated } = useApi();

    const handlePasswordReset = () => {
        setIsLoading(true);
        api.apiV0SettingsUserPasswordPatch({
            changePasswordRequest: {
                reset_token: props.resetToken,
                password: password1,
            },
        })
            .then(() => {
                props.onSuccess();
            })
            .catch(() => {
                setMessage(props.errorMessage);
            })
            .finally(() => setIsLoading(false));
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                p: 3,
                pt: 0,
            }}
        >
            <Box>
                {!isAuthenticated ? (
                    <>
                        <Box sx={{ width: '100%', pt: 3, pb: 1 }}>
                            <img src={LogoPrimary} width="100%" />
                        </Box>
                        <Typography textAlign="center">{t('login.change_password')}</Typography>
                    </>
                ) : null}
                {!!message ? (
                    <Alert severity="error" sx={{ mt: 1, mb: 2, p: 0, px: 1 }}>
                        {t(message)}
                    </Alert>
                ) : null}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <FormControl sx={{ mt: !!message ? 0 : 1.5, mb: 2 }} fullWidth variant="outlined">
                    <InputLabel htmlFor="password1" required>
                        {t('login.password')}
                    </InputLabel>
                    <OutlinedInput
                        id="password1"
                        type={showPassword ? 'text' : 'password'}
                        inputProps={{ autoComplete: 'new-password' }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    id="toggle-password-visibility"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={t('login.password')}
                        fullWidth
                        required
                        value={password1}
                        onChange={(e) => setPassword1(e.target.value)}
                        error={!!message}
                    />
                </FormControl>
                <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <InputLabel htmlFor="password2" required>
                        {t('login.confirm')}
                    </InputLabel>
                    <OutlinedInput
                        id="password2"
                        type={showPassword ? 'text' : 'password'}
                        inputProps={{ autoComplete: 'new-password' }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    id="toggle-password-visibility2"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={t('login.confirm')}
                        fullWidth
                        required
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        error={!!message}
                    />
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                }}
            >
                <Button
                    id="login"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        const [isValid, message] = validatePassword(password1, password2);
                        setMessage(message);
                        if (isValid) {
                            handlePasswordReset();
                        }
                    }}
                    fullWidth
                    disabled={!password1 || !password2 || isLoading}
                >
                    {t('login.change_password')}
                </Button>
            </Box>
        </Box>
    );
};

export default ResetForm;
