import { Box, Typography } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';

import { CoachMediaFile } from '../../../../backendsdk';

export interface MediaFileSlideProps {
    mediaFile: CoachMediaFile;
}

const MediaFileSlide: React.FC<MediaFileSlideProps> = ({ mediaFile }: MediaFileSlideProps) => {
    const [videoWatched, setVideoWatched] = useState(false);

    useEffect(() => {
        if (videoWatched) {
            mixpanel.track('Video watched', {
                type: 'coaching-media-file',
                page: window.location.pathname,
            });
        }
    }, [videoWatched]);

    let file;
    const fileType = mediaFile.file.file_type.split('/')[0];
    if (fileType === 'image') {
        file = <img src={mediaFile.file_url} style={{ maxWidth: '100%', maxHeight: '100%' }} />;
    } else if (fileType === 'video') {
        file = (
            <video
                src={mediaFile.file_url}
                controls
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                onTimeUpdate={(e) => {
                    if (e.currentTarget.duration && !videoWatched) {
                        const percentVideoPlayed = (e.currentTarget.currentTime / e.currentTarget.duration) * 100;
                        if (percentVideoPlayed >= 50) {
                            setVideoWatched(true);
                        }
                    }
                }}
            />
        );
    }
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                overflow: 'auto',
                px: 4,
                py: 2,
            }}
        >
            {!!mediaFile.title ? (
                <Box
                    sx={{
                        height: '30px',
                        display: 'flex',
                        width: '100%',
                        alignItems: 'end',
                        minHeight: 0,
                        flexShrink: 0,
                    }}
                >
                    <Typography data-testid="file-title" fontSize={18}>
                        {mediaFile.title}
                    </Typography>
                </Box>
            ) : null}
            <Box
                sx={{
                    flexGrow: 1,
                    minHeight: 0,
                    minWidth: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {file}
            </Box>
        </Box>
    );
};

export default MediaFileSlide;
