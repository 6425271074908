import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Tooltip } from '@mui/material';
import {
    GridColDef,
    GridEventListener,
    GridRenderCellParams,
    GridRowParams,
    GridRowSelectionModel,
    GridValueFormatterParams,
    getGridStringOperators,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HomeTerminal, HosDriver } from '../../../../backendsdk';
import { TrackedDataGrid as StyledDataGrid } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import { getDateTimeColumnType, localeObjectMap } from '../../../../utils/DataGridDateTime';
import { formatPhoneNumber } from '../../../../utils/Str';
import { timestampToDate } from '../../../../utils/TimeFormatter';
import palette from '../../../ColorPalette';
import { gridLocalization } from '../../OTA/MuiDeviceTable';

interface DriversDataGridProps {
    drivers: Record<number, HosDriver>;
    terminals: Record<number, HomeTerminal>;
    setSelectedDriver: CallableFunction;
    selectionModel: GridRowSelectionModel;
    setSelectionModel: CallableFunction;
    setEditModalOpen: CallableFunction;
}

const DriversDataGrid: React.FC<DriversDataGridProps> = (props: DriversDataGridProps) => {
    const { i18n, t } = useTranslation();
    const isMobile = useIsMobile();
    const { profile } = useProfile();
    const locale = localeObjectMap[i18n.languages[0]];

    const renderActiveStateCell = (params: GridRenderCellParams) => {
        return (
            <Tooltip title={t(`content.users.active_state.${params.value ? 'active' : 'inactive'}`)}>
                <Box>{params.value ? <CheckIcon data-testid="active" /> : <CloseIcon data-testid="inactive" />}</Box>
            </Tooltip>
        );
    };

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'firstName',
                headerName: 'First Name',
                width: 200,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'lastName',
                headerName: 'Last Name',
                width: 200,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'phone',
                headerName: 'Phone Number',
                width: 200,
                valueFormatter: (params) => formatPhoneNumber(profile.customer.settings.country, params.value),
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'homeTerminal',
                type: 'singleSelect',
                valueOptions: Object.values(props.terminals).map((terminal) => terminal.name),
                headerName: 'Home Terminal',
                width: 200,
            },
            {
                field: 'lastLogin',
                headerName: 'Last Login',
                flex: 1,
                ...getDateTimeColumnType(locale),
                valueFormatter: (params: GridValueFormatterParams) =>
                    !!params.value ? dayjs(params.value).format(profile.dateTimeFormat) : '',
                minWidth: 180,
            },
            {
                field: 'isActive',
                type: 'boolean',
                headerName: 'Active',
                headerAlign: 'center',
                width: 100,
                renderCell: renderActiveStateCell,
            },
        ],
        [props.terminals],
    );

    const rows = Object.values(props.drivers).map((driver) => {
        return {
            id: driver.driver_id,
            firstName: driver.first_name,
            lastName: driver.last_name,
            phone: driver.phone,
            homeTerminal: driver.home_terminal?.name || '',
            lastLogin: driver.last_login ? timestampToDate(driver.last_login) : '',
            isActive: driver.is_active,
        };
    });

    const handleDoubleClick: GridEventListener<'rowDoubleClick'> = (params: GridRowParams) => {
        const newSelectedDriver = props.drivers[params.id as number];
        props.setSelectedDriver({ ...newSelectedDriver, phone: formatPhoneNumber('us', newSelectedDriver.phone) });
        props.setEditModalOpen(true);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <StyledDataGrid
                id="drivers-data-grid"
                rows={rows}
                columns={columns}
                columnBuffer={7}
                checkboxSelection
                disableRowSelectionOnClick
                disableVirtualization={isMobile}
                pagination={isMobile}
                rowSelectionModel={props.selectionModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    props.setSelectionModel(newSelectionModel);
                }}
                slotProps={{
                    columnsPanel: {
                        sx: {
                            '& .MuiDataGrid-panelFooter button:first-child': {
                                display: 'none',
                            },
                        },
                    },
                }}
                onRowDoubleClick={handleDoubleClick}
                getRowClassName={(params) =>
                    `row-${params.row.firstName.toLocaleLowerCase()}-${params.row.lastName.toLocaleLowerCase()}`
                }
                localeText={
                    {
                        ...gridLocalization[i18n.languages[0]],
                        filterPanelRemoveAll: t('table.remove_all'),
                        columnMenuManageColumns: t('table.manage_columns'),
                        unpin: t('table.unpin'),
                        filterOperatorOnDate: t('table.on_date'),
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                }
                sx={{
                    height: '100%',
                    width: '100%',
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                    },
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor: palette.neutral[50],
                }}
            />
        </LocalizationProvider>
    );
};

export default DriversDataGrid;
