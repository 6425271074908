import { Autocomplete, Box, Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TripDetails } from '../../../backendsdk';
import { TrackedButton as Button, TrackedDialog as Dialog } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useDrivers from '../../../hooks/drivers';

interface ChangeDriverDialogProps {
    onClose: CallableFunction;
    currentDriver: number | null;
    needConfirmation: boolean;
    details: TripDetails;
    onSuccess: CallableFunction;
}

const ChangeDriverDialog: React.FC<ChangeDriverDialogProps> = (props) => {
    const { t } = useTranslation();
    const { drivers, isLoading, getDriver } = useDrivers();
    const initialDriver = getDriver(props.currentDriver);
    const [driverToken, setDriverToken] = useState<string>(initialDriver?.token.toString() || '');
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [isLoadingChange, setIsLoadingChange] = useState<boolean>(false);
    const { api } = useApi();

    const driverTokenToNameMap = drivers.reduce((acc: Record<string, string>, driver) => {
        acc[driver.token] = driver.fullName;
        return acc;
    }, {});

    const handleDriverChange = () => {
        setIsLoadingChange(true);
        api.apiV1TripLicensePlateTripObjIdPost({
            tripObjId: props.details.id,
            licensePlate: props.details.license_plate || '',
            tripUpdateRequest: {
                driver_token: parseInt(driverToken),
                confirm: confirmed,
            },
        })
            .then((res) => {
                props.onSuccess(res.data);
                props.onClose();
            })
            .finally(() => setIsLoadingChange(false));
    };

    return (
        <Dialog
            id="change-driver-dialog"
            open={true}
            onClose={() => {
                if (!isLoadingChange) {
                    props.onClose();
                }
            }}
            maxWidth="xs"
            fullWidth
        >
            {isLoading ? (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        m: 2,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ m: 2 }}>
                    <Typography data-testid="change-driver-dialog-title" textAlign="center" variant="h6" sx={{ mb: 2 }}>
                        {t('content.trip.change_driver')}
                    </Typography>
                    <Autocomplete
                        id="driver-name"
                        data-testid="driver-name"
                        value={driverToken}
                        renderInput={(params) => <TextField {...params} label={t('content.trip.driver_name')} />}
                        onChange={(_e, value) => setDriverToken(value?.toString() || '')}
                        options={drivers.filter((d) => d.active && !d.is_auto_generated).map((d) => d.token.toString())}
                        getOptionLabel={(option) => driverTokenToNameMap[option] || ''}
                        renderOption={(props, option) => (
                            <li {...props} data-testid={`driver-name-field-${option}`}>
                                <Typography sx={{ pt: 0.5 }}>{driverTokenToNameMap[option] || ''}</Typography>
                            </li>
                        )}
                        sx={{ mb: 2 }}
                    />
                    {props.needConfirmation ? (
                        <FormControlLabel
                            data-testid="confirm-checkbox"
                            control={<Checkbox checked={confirmed} onChange={(e) => setConfirmed(e.target.checked)} />}
                            label={t('content.trip.confirm')}
                        />
                    ) : null}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            data-testid="btn-change"
                            id="btn-change"
                            variant="contained"
                            color="secondary"
                            onClick={handleDriverChange}
                            sx={{ ml: 1 }}
                            disabled={(props.needConfirmation && !confirmed) || isLoadingChange}
                        >
                            {t('content.trip.change_driver')}
                        </Button>
                        <Button
                            data-testid="btn-cancel"
                            id="btn-cancel"
                            variant="contained"
                            onClick={() => props.onClose()}
                            sx={{ ml: 1 }}
                            disabled={isLoadingChange}
                        >
                            {t('content.trip.cancel')}
                        </Button>
                    </Box>
                </Box>
            )}
        </Dialog>
    );
};

export default ChangeDriverDialog;
