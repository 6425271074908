import { ThemeProvider } from '@mui/material';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { ReportingObserver as ReportingObserverIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserHistory } from 'history';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ApiProvider } from './hooks/api';
import { ProfileProvider } from './hooks/profile';
import Layout from './layout/Layout';
import theme from './layout/MuiTheme';

const history = createBrowserHistory();

if (!!process.env.REACT_APP_SENTRY_DNS) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost', /openeyes\.com\//, /openeyes-insurance\.com\//],
            }),
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
            new ExtraErrorDataIntegration(),
            new ReportingObserverIntegration(),
        ],
        tracesSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
    });
}

export const queryClient = new QueryClient();

const App: React.FC = () => {
    return (
        <div className="App">
            <Suspense fallback="Loading">
                <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        <ApiProvider>
                            <ProfileProvider>
                                <ThemeProvider theme={theme}>
                                    <Layout />
                                </ThemeProvider>
                            </ProfileProvider>
                        </ApiProvider>
                        {/* <ReactQueryDevtools position="bottom-right" /> */}
                    </QueryClientProvider>
                </BrowserRouter>
            </Suspense>
        </div>
    );
};

export default App;
