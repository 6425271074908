import AddCommentIcon from '@mui/icons-material/AddComment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

import { TrackedButton as Button, TrackedMenuItem as MenuItem } from '../../../../components/TrackedComponents';
import { StyledMenu } from '../../Users/MuiStyled';

interface ActionsMenuProps {
    disabled: boolean;
    tab: number;
    onAssign: CallableFunction;
    onAnnotate: CallableFunction;
}

const ActionsMenu: React.FC<ActionsMenuProps> = (props: ActionsMenuProps) => {
    const anchorEl = useRef(null);
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <Button
                id="actions-menu-btn"
                variant="contained"
                disableElevation
                disabled={props.disabled}
                size="small"
                onClick={() => setOpen((prev) => !prev)}
                endIcon={<KeyboardArrowDownIcon />}
                buttonRef={anchorEl}
                sx={{ mb: 1 }}
            >
                Actions
            </Button>
            <StyledMenu id="actions-menu" anchorEl={anchorEl.current} open={open} onClose={() => setOpen(false)}>
                <MenuItem
                    id="assign-menu-item"
                    disableRipple
                    onClick={() => {
                        setOpen(false);
                        props.onAssign();
                    }}
                >
                    <AssignmentIndIcon />
                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                        Assign
                    </Typography>
                </MenuItem>
                {props.tab === 0 ? (
                    <MenuItem
                        id="annotate-menu-item"
                        disableRipple
                        onClick={() => {
                            setOpen(false);
                            props.onAnnotate();
                        }}
                    >
                        <AddCommentIcon />
                        <Typography variant="body2" sx={{ mt: 0.5 }}>
                            Annotate
                        </Typography>
                    </MenuItem>
                ) : null}
            </StyledMenu>
        </>
    );
};

export default ActionsMenu;
