import { Box, Skeleton, Typography, alpha } from '@mui/material';
import dayjs from 'dayjs';
import capitalize from 'lodash.capitalize';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceV3 } from '../../../../backendsdk';
import useApi from '../../../../hooks/api';
import { useImages } from '../../../../hooks/images';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import imageNA from '../../../../images/image_not_available.png';
import palette from '../../../ColorPalette';
import { WELLNESS_TYPE_MAP } from '../../Device/WellnessBox';
import { wellnessColorsByState } from '../../StyledElements';
import { getFFWellnessDetails, getICWellnessDetails } from '../utils';

export const ACTION_ITEMS = [
    'content.wellness.adjust_clean',
    'content.wellness.adjust',
    'content.wellness.remove_blockage_clean',
    'content.wellness.connect_camera',
] as const;
type ActionItem = typeof ACTION_ITEMS[number];
type WellnessType = keyof typeof WELLNESS_TYPE_MAP;
export const WELLNESS_TO_ACTION_MAP: Partial<Record<WellnessType, ActionItem>> = {
    frontfacingwellnessunwellhorizontal: 'content.wellness.adjust_clean',
    frontfacingwellnesscameraverticalhigh: 'content.wellness.adjust_clean',
    frontfacingwellnesscameraverticallow: 'content.wellness.adjust_clean',
    frontfacingwellnesslowqualityinput: 'content.wellness.adjust_clean',
    frontfacingwellnessinsufficientvalidinput: 'content.wellness.adjust_clean',
    faulty: 'content.wellness.adjust',
    'no face': 'content.wellness.adjust',
    no_face: 'content.wellness.adjust',
    peripheral_face: 'content.wellness.adjust',
    blocked: 'content.wellness.remove_blockage_clean',
    disconnected: 'content.wellness.connect_camera',
};

interface WellnessPanelProps {
    device: DeviceV3;
}

const WellnessPanel: React.FC<WellnessPanelProps> = ({ device }: WellnessPanelProps) => {
    const { api } = useApi();
    const getImage = (imageKey: string) => {
        const [wellnessType, wellnessId] = imageKey.split(':', 2);
        return api.apiV2WellnessImageWellnessTypeGet(
            {
                wellnessType,
                wellnessId,
            },
            { responseType: 'blob' },
        );
    };
    const [wellnessImages, addToQueue, imageSizes] = useImages(2, getImage, true);
    const { t } = useTranslation();
    const { profile } = useProfile();
    const isMobile = useIsMobile();

    let icWellnessType = 'in_cabin';
    if (
        device.aggregated_wellness &&
        device.aggregated_wellness.in_cabin_wellness &&
        'wellness_state' in device.aggregated_wellness.in_cabin_wellness
    ) {
        icWellnessType = 'in_cabin_v1';
    }
    const icWellnessKey = `${icWellnessType}:${device.aggregated_wellness?.in_cabin_wellness?.id}`;
    const ffWellnessKey = `front_facing:${device.aggregated_wellness?.front_facing_wellness?.id}`;

    useEffect(() => {
        addToQueue([icWellnessKey, ffWellnessKey]);
    }, [device.aggregated_wellness?.in_cabin_wellness?.id, device.aggregated_wellness?.front_facing_wellness?.id]);

    const icWellnessDetails = getICWellnessDetails(device, profile.customer.settings.inactive_device_threshold);
    const ffWellnessDetails = getFFWellnessDetails(device, profile.customer.settings.inactive_device_threshold);
    const icWellnessTimestamp = device.aggregated_wellness?.in_cabin_wellness?.timestamp;
    const ffWellnessTimestamp = device.aggregated_wellness?.front_facing_wellness?.timestamp;
    const icActionValue = WELLNESS_TO_ACTION_MAP[icWellnessDetails];
    const ffActionValue = WELLNESS_TO_ACTION_MAP[ffWellnessDetails as keyof typeof WELLNESS_TO_ACTION_MAP];

    const wellnessImageSkeleton = (
        <div style={{ width: '100%', aspectRatio: 4 / 3 }}>
            <Skeleton variant="rectangular" width="100%" height="100%" animation="wave" />
        </div>
    );

    const icImageSize = imageSizes[icWellnessKey];
    const ffImageSize = imageSizes[ffWellnessKey];
    const shouldCropIC =
        icImageSize?.width !== undefined &&
        icImageSize?.height !== undefined &&
        icImageSize.width / icImageSize.height === 8 / 3;
    const shouldCropFF =
        ffImageSize?.width !== undefined &&
        ffImageSize?.height !== undefined &&
        ffImageSize.width / ffImageSize.height === 8 / 3;

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    mr: isMobile ? 0 : 2,
                    mb: isMobile ? 2 : 0,
                }}
            >
                <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold' }}>
                    {t('content.wellness.in_cabin')}
                </Typography>
                <Typography
                    data-testid="in-cabin-wellness"
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: wellnessColorsByState[icWellnessDetails],
                    }}
                >
                    {capitalize(t(WELLNESS_TYPE_MAP[icWellnessDetails as keyof typeof WELLNESS_TYPE_MAP]))}
                </Typography>
                {wellnessImages[icWellnessKey] ? (
                    <div style={{ width: '100%', aspectRatio: 4 / 3, position: 'relative' }}>
                        <img
                            src={wellnessImages[icWellnessKey]}
                            className={`wellness-image ${shouldCropIC ? 'ic-image' : ''}`}
                        />
                        {!!icWellnessTimestamp && wellnessImages[icWellnessKey] !== imageNA ? (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: alpha(palette.black, 0.3),
                                    px: 0.5,
                                }}
                            >
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: palette.white }}>
                                    {dayjs.unix(icWellnessTimestamp).format(profile.dateTimeFormat)}
                                </Typography>
                            </Box>
                        ) : null}
                    </div>
                ) : (
                    wellnessImageSkeleton
                )}
                {icActionValue !== undefined ? (
                    <Typography
                        data-testid="in-cabin-action"
                        sx={{
                            mt: 0.25,
                            fontSize: 14,
                            fontWeight: 500,
                            color: palette.primary,
                        }}
                    >
                        {t(icActionValue)}
                    </Typography>
                ) : null}
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography sx={{ fontSize: 12, color: 'grayColor.main', fontWeight: 'bold' }}>
                    {t('content.wellness.front_facing')}
                </Typography>
                <Typography
                    data-testid="front-facing-wellness"
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: wellnessColorsByState[ffWellnessDetails],
                    }}
                >
                    {capitalize(t(WELLNESS_TYPE_MAP[ffWellnessDetails as keyof typeof WELLNESS_TYPE_MAP]))}
                </Typography>
                {!!wellnessImages[ffWellnessKey] ? (
                    <div style={{ width: '100%', aspectRatio: 4 / 3, position: 'relative' }}>
                        <img
                            src={wellnessImages[ffWellnessKey]}
                            className={`wellness-image ${shouldCropFF ? 'ff-image' : ''}`}
                        />
                        {!!ffWellnessTimestamp && wellnessImages[ffWellnessKey] !== imageNA ? (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: alpha(palette.black, 0.3),
                                    px: 0.5,
                                }}
                            >
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: palette.white }}>
                                    {dayjs.unix(ffWellnessTimestamp).format(profile.dateTimeFormat)}
                                </Typography>
                            </Box>
                        ) : null}
                    </div>
                ) : (
                    wellnessImageSkeleton
                )}
                {ffActionValue !== undefined ? (
                    <Typography
                        data-testid="front-facing-action"
                        sx={{
                            mt: 0.25,
                            fontSize: 14,
                            fontWeight: 500,
                            color: palette.primary,
                        }}
                    >
                        {t(ffActionValue)}
                    </Typography>
                ) : null}
            </Box>
        </Box>
    );
};

export default WellnessPanel;
