import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton as Button } from '../../../../../components/TrackedComponents';
import { Modes } from '../SetupTool';

interface ModeIntroContent {
    what_you_need: string[];
    what_to_expect: string[];
}

export const INTRO_CONTENT: Record<Modes, ModeIntroContent> = {
    install: {
        what_you_need: ['vehicle', 'device', 'reception', 'measuring_tape', 'camera_phone'],
        what_to_expect: ['select_new_device', 'enter_measurements', 'upload_image', 'verify_view'],
    },
    switch: {
        what_you_need: ['old_device', 'new_device', 'vehicle', 'reception', 'measuring_tape', 'camera_phone'],
        what_to_expect: ['choose_old_device', 'select_new_device', 'enter_measurements', 'upload_image', 'verify_view'],
    },
    remove: {
        what_you_need: [],
        what_to_expect: [],
    },
    verify: {
        what_you_need: ['imei', 'reception'],
        what_to_expect: ['select_device', 'adjust_camera'],
    },
    adjust: {
        what_you_need: ['old_device', 'vehicle', 'reception', 'measuring_tape', 'camera_phone'],
        what_to_expect: ['choose_old_device', 'enter_measurements', 'upload_image', 'verify_view'],
    },
};

interface ProcessIntroProps {
    mode: Modes;
    onNext: CallableFunction;
    onBack: CallableFunction;
    dashboardLink: React.ReactNode;
}

const ProcessIntro: React.FC<ProcessIntroProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography id={`${props.mode}-intro-header`} variant="h6" textAlign="center" sx={{ mb: 2 }}>
                    {t(`setup_tool.select_mode.${props.mode}_header`)}
                </Typography>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography sx={{ fontSize: 20, fontWeight: 500, mb: 0.5 }}>
                        {t('setup_tool.intro.what_you_need')}
                    </Typography>
                    <ul style={{ width: '100%', paddingLeft: '20px', marginTop: '0px', marginBottom: '16px' }}>
                        {INTRO_CONTENT[props.mode].what_you_need.map((item) => (
                            <li key={item} id={`li-${item}`} data-testid={`li-${item}`}>
                                <Typography>{t(`setup_tool.intro.${props.mode}.${item}`)}</Typography>
                            </li>
                        ))}
                    </ul>
                    <Typography sx={{ fontSize: 20, fontWeight: 500, mb: 0.5 }}>
                        {t('setup_tool.intro.what_to_expect')}
                    </Typography>
                    <ol style={{ width: '100%', paddingLeft: '20px', marginTop: '0px' }}>
                        {INTRO_CONTENT[props.mode].what_to_expect.map((item) => (
                            <li key={item} id={`li-${item}`} data-testid={`li-${item}`}>
                                <Typography>{t(`setup_tool.intro.${props.mode}.${item}`)}</Typography>
                            </li>
                        ))}
                    </ol>
                </Box>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', mt: 1 }}>
                <Box sx={{ flex: 1, mr: 1 }}>
                    <Button id="btn-back" fullWidth variant="contained" color="primary" onClick={() => props.onBack()}>
                        {t('setup_tool.back')}
                    </Button>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Button
                        id="btn-next"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            props.onNext();
                        }}
                    >
                        {t('setup_tool.next')}
                    </Button>
                </Box>
            </Box>
            {props.dashboardLink}
        </Box>
    );
};

export default ProcessIntro;
