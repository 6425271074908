import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EventDetails } from '../../../backendsdk';
import { TrackedButton as Button, TrackedDialog as Dialog } from '../../../components/TrackedComponents';
import { ExtendedFleetDriver } from '../../../hooks/drivers';
import NewCoachingModal from '../Vehicles/NewCoachingModal';

interface EventCoachingDialogProps {
    setIsSaved: CallableFunction;
    coachingModal: boolean;
    setCoachingModal: CallableFunction;
    handleEventAction: CallableFunction;
    driver?: ExtendedFleetDriver;
    details: EventDetails;
}

const EventCoachingDialog: React.FC<EventCoachingDialogProps> = (props) => {
    const { coachingModal, setCoachingModal } = props;
    const { t } = useTranslation();

    useEffect(() => {
        props.setIsSaved(props.details.coach.event != undefined);
    }, [props.details.event_id]);

    return (
        <Dialog id="coaching-dialog" open={coachingModal} onClose={() => setCoachingModal(false)}>
            <NewCoachingModal defaultDriver={props.driver?.fullName} onSave={props.handleEventAction}>
                <Button
                    id="btn-cancel"
                    variant="outlined"
                    size="small"
                    onClick={() => setCoachingModal(false)}
                    sx={{ ml: 1 }}
                >
                    {t('content.fleet.update.cancel')}
                </Button>
            </NewCoachingModal>
        </Dialog>
    );
};
export default EventCoachingDialog;
