import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DoneIcon from '@mui/icons-material/Done';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedCustomerDataAllOfUsers, ShortCoachSession } from '../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import palette from '../../ColorPalette';
import { BEHAVIOR_MAP } from '../Coaching/SessionDetails';

interface CoachingTabProps {
    sessions: ShortCoachSession[];
    users: ExtendedCustomerDataAllOfUsers[];
}

const STATUS_TO_ICON_MAP: Record<string, ReactElement> = {
    todo: <PendingActionsIcon id="todo-icon" data-testid="todo-icon" fontSize="small" sx={{ color: 'primary.main' }} />,
    suggested: (
        <AutoFixHighIcon
            id="suggested-icon"
            data-testid="suggested-icon"
            fontSize="small"
            sx={{ color: 'primary.main' }}
        />
    ),
    sent: <MailOutlineIcon id="sent-icon" data-testid="sent-icon" fontSize="small" sx={{ color: 'primary.main' }} />,
    completed: (
        <DoneIcon id="completed-icon" data-testid="completed-icon" fontSize="small" sx={{ color: 'primary.main' }} />
    ),
};

const getSessionsByStatus = (status: string, sessions: ShortCoachSession[]) => {
    if (status === 'todo') {
        return sessions.filter(
            (session) => !session.completed_at && !session.last_sent && (!session.auto_generated || session.is_saved),
        );
    } else if (status === 'suggested') {
        return sessions.filter(
            (session) => !session.completed_at && !session.last_sent && session.auto_generated && !session.is_saved,
        );
    } else if (status === 'sent') {
        return sessions.filter((session) => !!session.last_sent && !session.completed_at);
    } else if (status === 'completed') {
        return sessions.filter((session) => !!session.completed_at);
    }
    return [];
};

const CoachingTab: React.FC<CoachingTabProps> = (props: CoachingTabProps) => {
    const { profile } = useProfile();
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    const sessionsByStatus = Object.fromEntries(
        ['todo', 'suggested', 'sent', 'completed'].map((status) => [
            status,
            getSessionsByStatus(status, props.sessions),
        ]),
    );

    const completedInLastQuarter = sessionsByStatus['completed'].filter((session) =>
        dayjs.unix(session.completed_at).isAfter(dayjs().subtract(3, 'month')),
    );
    const completedInLastYear = sessionsByStatus['completed'].filter((session) =>
        dayjs.unix(session.completed_at).isAfter(dayjs().subtract(1, 'year')),
    );

    const getLastSessionFieldValue = (sessions: ShortCoachSession[], method: string) => {
        const lastCompletedSession = sessions
            .filter((session) => session.completion_method === method)
            .sort((a, b) => b.completed_at - a.completed_at)[0];
        let lastSessionFieldValue;
        if (lastCompletedSession) {
            const lastSessionDate = dayjs.unix(lastCompletedSession.completed_at).format(profile.dateFormat);
            const lastSessionDaysAgo = dayjs.unix(lastCompletedSession.completed_at).diff(dayjs(), 'day');
            lastSessionFieldValue = `${lastSessionDate} (${
                !!lastSessionDaysAgo
                    ? lastSessionDaysAgo > 1
                        ? t('content.safety.coaching.days_ago', { days: lastSessionDaysAgo })
                        : t('content.safety.coaching.yesterday')
                    : t('content.safety.coaching.today')
            })`;
        }
        return lastSessionFieldValue;
    };

    const remoteFieldValue = getLastSessionFieldValue(sessionsByStatus['completed'], 'remote');
    const officeFieldValue = getLastSessionFieldValue(sessionsByStatus['completed'], 'onsite');

    return (
        <Box sx={{ width: '100%', height: '100%', pt: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                    height: '100%',
                    flex: 1,
                }}
            >
                <Card
                    sx={{
                        width: isMobile ? '100%' : '200px',
                        height: isMobile ? undefined : '100%',
                        flexShrink: 0,
                        mr: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        p: 1,
                    }}
                >
                    <Typography variant="overline" sx={{ lineHeight: 1, mb: 1 }}>
                        {t('content.safety.coaching.last_sessions')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                        <Typography sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}>
                            {t('content.safety.coaching.last_remote_session')}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>{!!remoteFieldValue ? remoteFieldValue : '-'}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                        <Typography sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}>
                            {t('content.safety.coaching.last_office_session')}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>{!!officeFieldValue ? officeFieldValue : '-'}</Typography>
                    </Box>
                    <Typography variant="overline" sx={{ lineHeight: 1, mb: 1 }}>
                        {t('content.safety.coaching.completed_sessions')}
                    </Typography>
                    <Box sx={{ width: '100%', display: 'flex' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}>
                                {t('content.safety.coaching.last_quarter')}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }}>{completedInLastQuarter.length}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                            <Typography sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}>
                                {t('content.safety.coaching.last_year')}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }}>{completedInLastYear.length}</Typography>
                        </Box>
                    </Box>
                </Card>
                <Card
                    sx={{
                        flex: 1,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        mt: isMobile ? 1 : 0,
                    }}
                >
                    <Typography variant="overline" sx={{ lineHeight: 1, minHeight: 0, mb: 1 }}>
                        {t('content.safety.coaching.driver_sessions')}
                    </Typography>
                    <Box sx={{ width: '100%', height: '100%', minHeight: 0 }}>
                        <TableContainer sx={{ height: '100%' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ p: 1 }}>
                                            <Typography
                                                sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}
                                            >
                                                {t('content.safety.coaching.status')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ p: 1 }}>
                                            <Typography
                                                sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}
                                            >
                                                {t('content.safety.coaching.last_updated')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ p: 1 }}>
                                            <Typography
                                                sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}
                                            >
                                                {t('content.safety.coaching.assigned_manager')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ p: 1 }}>
                                            <Typography
                                                sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}
                                            >
                                                {t('content.safety.coaching.behaviors')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{ p: 1 }}>
                                            <Typography
                                                sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}
                                            >
                                                {t('content.safety.coaching.events')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{ p: 1 }}>
                                            <Typography
                                                sx={{ fontSize: 12, fontWeight: 500, color: palette.neutral[500] }}
                                            >
                                                {t('content.safety.coaching.view')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(sessionsByStatus).map(([status, sessions]) => {
                                        return sessions.map((session) => {
                                            const assignee = session.assignee
                                                ? props.users.find((user) => user.user_id === session.assignee)
                                                : undefined;
                                            return (
                                                <TableRow
                                                    hover
                                                    key={session.session_id}
                                                    id={`${session.session_id}-row`}
                                                    data-testid={`${session.session_id}-row`}
                                                >
                                                    <TableCell align="center" sx={{ px: 1 }}>
                                                        <Tooltip title={t(`content.coach.${status}_sessions`)}>
                                                            <Box>{STATUS_TO_ICON_MAP[status]}</Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={{ px: 1 }}>
                                                        <Typography sx={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                                            {dayjs
                                                                .unix(session.last_updated)
                                                                .format(profile.dateTimeFormat)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ px: 1 }}>
                                                        {assignee !== undefined ? (
                                                            <Typography
                                                                data-testid={`${assignee.user_id}-field-value`}
                                                                sx={{ fontSize: 14 }}
                                                            >
                                                                {assignee.name}
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                data-testid="unassigned-field-value"
                                                                sx={{ fontSize: 14, color: palette.neutral[500] }}
                                                            >
                                                                {t('content.coach.details.unassigned')}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                    <TableCell sx={{ px: 1 }}>
                                                        <Typography sx={{ fontSize: 14 }}>
                                                            {(session.behaviors || [])
                                                                .map((behavior) => t(BEHAVIOR_MAP[behavior], behavior))
                                                                .join(', ')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ px: 1 }}>
                                                        <Typography sx={{ fontSize: 14 }}>
                                                            {session.event_count}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ width: '30px', p: 0 }}>
                                                        <IconButton
                                                            id="open-coaching-session"
                                                            size="small"
                                                            onClick={() => {
                                                                window.open(
                                                                    `/coaching/${session.session_id}`,
                                                                    '_blank',
                                                                );
                                                            }}
                                                        >
                                                            <OpenInNewIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        });
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Card>
            </Box>
        </Box>
    );
};

export default CoachingTab;
