import { Box, Chip, CircularProgress, Stack, Typography } from '@mui/material';
import {
    GridCellParams,
    GridColDef,
    GridComparatorFn,
    GridFilterItem,
    GridValueFormatterParams,
    getGridSingleSelectOperators,
    getGridStringOperators,
} from '@mui/x-data-grid-pro';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { HosDriver } from '../../../../backendsdk';
import { TrackedLink as Link, TrackedDataGrid as StyledDataGrid } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import { getDateColumnType, localeObjectMap } from '../../../../utils/DataGridDateTime';
import { violationsPageDefs } from '../../../../utils/Pages';
import palette from '../../../ColorPalette';
import { DEFAULT_TITLE } from '../../../Layout';
import { VIOLATIONS_DATA } from './violationsData';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

export interface ViolationData {
    device: string;
    driver: HosDriver;
    date: string;
    violations: ViolationType[];
}

const VIOLATION_TYPES = ['break', 'drive', 'shift', 'cycle'] as const;
type ViolationType = typeof VIOLATION_TYPES[number];

const violationsSortComparator: GridComparatorFn<ViolationType[]> = (
    violations1: ViolationType[],
    violations2: ViolationType[],
) => {
    return violations1.length - violations2.length;
};

const violationsFilterOperators = getGridSingleSelectOperators()
    .filter((operator) => operator.value === 'isAnyOf')
    .map((operator) => {
        const newOperator = { ...operator };
        const newGetApplyFilterFn = (filterItem: GridFilterItem) => {
            return (params: GridCellParams): boolean => {
                const value = params.value as ViolationType[];
                let isOk = true;
                filterItem?.value?.forEach((violation: ViolationType) => {
                    isOk = isOk && value.includes(violation);
                });
                return isOk;
            };
        };
        newOperator.getApplyFilterFn = newGetApplyFilterFn;
        return newOperator;
    });

const ViolationsComponent: React.FC = () => {
    const [fromDate, setFromDate] = useState<Dayjs>(dayjs().utc().subtract(7, 'day'));
    const [toDate, setToDate] = useState<Dayjs>(dayjs().utc());
    const [violations, setViolations] = useState<ViolationData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const { i18n, t } = useTranslation();
    const history = useHistory();
    const isMobile = useIsMobile();
    const { profile } = useProfile();
    const locale = localeObjectMap[i18n.languages[0]];

    const getViolations = () => {
        // TODO: send axios request
        setIsLoading(true);
        setIsError;
        setViolations(VIOLATIONS_DATA);
        setIsLoading(false);
        // TODO: abord request on cleanup
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${violationsPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        if (toDate.isSameOrAfter(fromDate, 'date')) {
            getViolations();
        }
    }, [fromDate, toDate]);

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'device',
                headerName: 'Device',
                width: 150,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'driver',
                headerName: 'Driver',
                width: 200,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'phone',
                headerName: 'Phone Number',
                width: 200,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'date',
                headerName: 'Date',
                ...getDateColumnType(locale),
                valueFormatter: (params: GridValueFormatterParams<Dayjs>) =>
                    dayjs(params.value).format(profile.dateFormat),
                width: 150,
            },
            {
                field: 'violations',
                headerName: 'Violations',
                minWidth: 240,
                flex: 1,
                type: 'singleSelect',
                valueOptions: [...VIOLATION_TYPES],
                renderCell: (params) => (
                    <Stack direction="row" spacing={0.5}>
                        {params.row.violations.map((violation: ViolationType) => (
                            <Chip
                                key={violation}
                                label={<Typography fontSize={12}>{violation}</Typography>}
                                sx={{ cursor: 'pointer', backgroundColor: 'redColor.main' }}
                            />
                        ))}
                    </Stack>
                ),
                sortComparator: violationsSortComparator,
                filterOperators: violationsFilterOperators,
            },
        ],
        [],
    );

    const rows = violations.map((violation, idx) => {
        return {
            id: idx,
            device: violation.device,
            driver: `${violation.driver.first_name} ${violation.driver.last_name}`,
            phone: violation.driver.phone,
            driver_id: violation.driver.driver_id,
            date: violation.date,
            violations: violation.violations,
        };
    });

    let content = null;
    if (isLoading) {
        content = <CircularProgress />;
    } else if (isError) {
        content = (
            <Typography variant="overline">
                Error fetching violation data.{' '}
                <Link id="try-again-link" component="button" variant="overline" onClick={() => getViolations()}>
                    Try again.
                </Link>
            </Typography>
        );
    } else if (violations.length === 0) {
        content = <Typography variant="overline">No violations within the specified dates</Typography>;
    } else {
        content = (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                <StyledDataGrid
                    id="violations-data-grid"
                    rows={rows}
                    columns={columns}
                    columnBuffer={3}
                    disableRowSelectionOnClick
                    disableVirtualization={isMobile}
                    pagination={isMobile}
                    onRowClick={(params) => history.push(`/driver_logs/${params.row.driver_id}/${params.row.date}`)}
                    slotProps={{
                        columnsPanel: {
                            sx: {
                                '& .MuiDataGrid-panelFooter button:first-child': {
                                    display: 'none',
                                },
                            },
                        },
                    }}
                    sx={{
                        width: '100%',
                        height: '100%',
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        backgroundColor: palette.neutral[50],
                    }}
                />
            </LocalizationProvider>
        );
    }

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '1200px', height: '100%', pb: 2 }}
        >
            <Box sx={{ display: 'flex', mb: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="From"
                        format={profile.dateFormat}
                        value={fromDate}
                        onChange={(value: Dayjs | null) => {
                            if (value !== null) {
                                setFromDate(value);
                            }
                        }}
                        maxDate={toDate}
                        slotProps={{ textField: { sx: { mr: 2 } } }}
                    />
                    <DatePicker
                        label="To"
                        format={profile.dateFormat}
                        value={toDate}
                        maxDate={dayjs().utc()}
                        onChange={(value: Dayjs | null) => {
                            if (value !== null) {
                                setToDate(value);
                            }
                        }}
                    />
                </LocalizationProvider>
            </Box>
            {content}
        </Box>
    );
};

export default ViolationsComponent;
