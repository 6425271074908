import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Descendant } from 'slate';

import { CoachManagerNotes, CoachSession } from '../../../../backendsdk';
import { TrackedButton as Button } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import { hasTextValue } from '../../../../utils/slate';
import palette from '../../../ColorPalette';
import { SaveFirstTooltip } from './CoachingAgenda';
import RichTextField from './RichTextField';

interface ManagerNotesFormProps {
    children?: React.ReactNode;
    sessionId: number;
    item?: CoachManagerNotes;
    onClose: CallableFunction;
    setDetails: CallableFunction;
    isLoading: boolean;
    setIsLoading: CallableFunction;
    isCompleted: boolean;
    isSaved: boolean;
    setAlert: CallableFunction;
    onUpdate: CallableFunction;
    addAnotherItem: boolean;
    setItemsAdded: CallableFunction;
}

const ManagerNotesForm: React.FC<ManagerNotesFormProps> = (props: ManagerNotesFormProps) => {
    const initialValue =
        props.item !== undefined
            ? (props.item.text as Descendant[])
            : [{ type: 'paragraph', children: [{ text: '' }] }];
    const [notesValue, setNotesValue] = useState<Descendant[]>(initialValue);
    const [hasText, setHasText] = useState<boolean>(hasTextValue(initialValue));
    const [editorCounter, setEditorCounter] = useState<number>(0);
    const { api } = useApi();
    const { t } = useTranslation();

    const isEditable = !props.isCompleted && props.isSaved;

    const handleSubmit = () => {
        if (props.item !== undefined) {
            props.setIsLoading(true);
            api.apiV0CoachSessionIdNotesNoteIdPatch({
                sessionId: props.sessionId,
                noteId: props.item.id,
                coachManagerNotes: {
                    ...props.item,
                    text: notesValue,
                },
            })
                .then((res) => {
                    props.setDetails((prev: CoachSession) => ({
                        ...prev,
                        notes: prev.notes.map((note) => (note.id === res.data.id ? res.data : note)),
                    }));
                    props.onUpdate();
                    props.onClose();
                })
                .catch(() => {
                    props.setAlert({
                        message: t('content.coach.errors.update_item'),
                        type: 'error',
                        duration: 6000,
                    });
                    props.setIsLoading(false);
                });
        } else {
            props.setIsLoading(true);
            api.apiV0CoachSessionIdNotesPost({
                sessionId: props.sessionId,
                managerNotesRequest: { text: notesValue },
            })
                .then((res) => {
                    props.setDetails((prev: CoachSession) => ({
                        ...prev,
                        notes: [...prev.notes, res.data],
                        agenda: [...prev.agenda, { type: 'notes', id: res.data.id }],
                    }));
                    props.onUpdate();
                    if (props.addAnotherItem) {
                        setNotesValue([{ type: 'paragraph', children: [{ text: '' }] }]);
                        setHasText(false);
                        setEditorCounter((prev) => prev + 1);
                        props.setIsLoading(false);
                        props.setItemsAdded((prev: number) => prev + 1);
                    } else {
                        props.onClose();
                    }
                })
                .catch(() => {
                    props.setAlert({
                        message: t('content.coach.errors.add_item'),
                        type: 'error',
                        duration: 6000,
                    });
                    props.setIsLoading(false);
                });
        }
    };

    const notesField = (
        <Box
            sx={{
                width: '100%',
                border: `1px solid ${palette.gray[400]}`,
                backgroundColor: palette.white,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                p: 1,
            }}
        >
            <Typography variant="body2">{t('content.coach.add_item.notes.instructions')}</Typography>
            <RichTextField
                key={`editor-${editorCounter}`}
                initialValue={notesValue}
                setValue={setNotesValue}
                setHasTextValue={setHasText}
                disabled={!isEditable}
            />
        </Box>
    );

    return (
        <>
            {props.isSaved ? notesField : <SaveFirstTooltip>{notesField}</SaveFirstTooltip>}
            {isEditable ? (
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    {props.children}
                    <Button
                        id="btn-cancel-item"
                        data-testid="btn-cancel-item"
                        variant="contained"
                        color="primary"
                        disabled={props.isLoading}
                        onClick={() => props.onClose()}
                        sx={{ ml: 'auto', flexShrink: 0 }}
                    >
                        {t('content.coach.add_item.cancel')}
                    </Button>
                    <Button
                        id="btn-save-item"
                        data-testid="btn-save-item"
                        variant="contained"
                        color="secondary"
                        sx={{ ml: 1, flexShrink: 0 }}
                        disabled={!hasText || props.isLoading}
                        onClick={handleSubmit}
                    >
                        {props.item === undefined
                            ? t('content.coach.add_item.add')
                            : t('content.coach.add_item.update')}
                    </Button>
                </Box>
            ) : null}
        </>
    );
};

export default ManagerNotesForm;
