import { Box, Popover, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useProfile from '../../../../hooks/profile';
import BusSide from '../../../../images/overview-bus-side.svg';
import TruckSide from '../../../../images/overview-truck-side.svg';
import { getColor, getRadius } from './ImpactChartTop';

interface ImpactChartSideProps {
    pointsOfImpact: Record<string, number>;
}

const ImpactChartSide: React.FC<ImpactChartSideProps> = (props: ImpactChartSideProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [currentPoint, setCurrentPoint] = React.useState<{ key: string; value: number }>({ key: '', value: 0 });
    const { t } = useTranslation();
    const { profile } = useProfile();
    const isProperty = profile.customer.settings.driving_type === 'property';
    const IMAGE_HEIGHT = isProperty ? 150 : 117;
    const IMAGE_WIDTH = isProperty ? 370 : 400;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePopoverOpen = (event: any, key: string) => {
        setCurrentPoint({ key, value: props.pointsOfImpact[key] });
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    const containerWidth = IMAGE_WIDTH;
    const containerHeight = IMAGE_HEIGHT + IMAGE_HEIGHT / 3;

    const pointsOfImpact = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(props.pointsOfImpact).filter(([key, _value]) => !['no_impact', 'unknown', ''].includes(key)),
    );

    const getCircleProps = (key: string) => {
        return {
            className: 'impact-circle',
            onMouseEnter: (event: React.MouseEvent<SVGCircleElement, MouseEvent>) => handlePopoverOpen(event, key),
            onMouseLeave: handlePopoverClose,
            r: getRadius(props.pointsOfImpact[key], pointsOfImpact),
            fill: getColor(props.pointsOfImpact[key], pointsOfImpact),
            stroke: getColor(props.pointsOfImpact[key], pointsOfImpact),
            strokeWidth: 1,
            fillOpacity: 0.5,
        };
    };

    return (
        <Box
            sx={{
                height: containerHeight,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                flexShrink: 0,
                mb: 2,
                overflow: 'visible',
            }}
        >
            <img
                id={isProperty ? 'truck-side' : 'bus-side'}
                data-testid={isProperty ? 'truck-side' : 'bus-side'}
                src={isProperty ? TruckSide : BusSide}
                style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }}
            />
            <svg
                height={containerHeight}
                width={containerWidth}
                style={{ position: 'absolute', top: 0, left: 0, overflow: 'visible' }}
            >
                <circle cx={containerWidth / 2} cy={IMAGE_HEIGHT / 6} {...getCircleProps('roof')} />
                <circle
                    cx={containerWidth / 2}
                    cy={containerHeight - IMAGE_HEIGHT / 3}
                    {...getCircleProps('undercarriage')}
                />
            </svg>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', p: 1 }}>
                    <Typography fontSize={12} sx={{ fontWeight: 'bold' }}>
                        {`${t(`content.accidents.tags.damage_mechanism.${currentPoint.key}`)}:`}
                    </Typography>
                    <Typography fontSize={12}>
                        {`${currentPoint.value} ${t('content.accident_overview.accidents_label')}`}
                    </Typography>
                </Box>
            </Popover>
        </Box>
    );
};

export default ImpactChartSide;
