import CloseIcon from '@mui/icons-material/Close';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { Box, Card, FormControl, Grid, InputLabel, Paper, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useState } from 'react';

import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../../components/TrackedComponents';
import { HomeTerminal } from '../Defs';
import { offsetToTimezone, timezoneToOffset } from './TerminalsDataGrid';

export interface AddTerminalModalProps {
    onSave: CallableFunction;
    onClose: CallableFunction;
}

const EMPTY_TERMINAL: HomeTerminal = {
    id: 0,
    name: '',
    address: '',
    timezone_offset_from_utc: 5,
    consecutive_days: 7,
    day_start_time: '00:00',
};

const AddTerminalModal: React.FC<AddTerminalModalProps> = (props: AddTerminalModalProps) => {
    const [details, setDetails] = useState<HomeTerminal>(EMPTY_TERMINAL);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDetails((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const isValid = [details.name, details.address].every((field: string) => field !== '');

    return (
        <Dialog id="add-terminal-dialog" open={true} fullWidth={true} maxWidth="xs" onClose={() => props.onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="close-modal-btn" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DomainAddIcon sx={{ mr: 0.5 }} />
                    Add Terminal
                </Box>
            </Box>
            <Paper sx={{ backgroundColor: 'bgColor.main', p: 1 }}>
                <Card sx={{ p: 2 }}>
                    <Grid container columns={2} columnSpacing={1} rowSpacing={2}>
                        <Grid item xs={2}>
                            <TextField
                                id="name-field"
                                label="Name"
                                name="name"
                                size="small"
                                fullWidth
                                value={details.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="address-field"
                                label="Address"
                                name="address"
                                size="small"
                                fullWidth
                                value={details.address}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="consecutive-days-field-label">Consecutive Days</InputLabel>
                                <Select
                                    labelId="consecutive-days-field-label"
                                    id="consecutive-days-field"
                                    name="consecutive_days"
                                    size="small"
                                    value={details.consecutive_days.toString()}
                                    label="Consecutive Days"
                                    onChange={(e: SelectChangeEvent) => {
                                        setDetails((prev) => ({
                                            ...prev,
                                            consecutive_days: parseInt(e.target.value) as 7 | 8,
                                        }));
                                    }}
                                >
                                    <MenuItem id="7-days-menu-item" key={7} value={7}>
                                        7
                                    </MenuItem>
                                    <MenuItem id="8-days-menu-item" key={8} value={8}>
                                        8
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="timezone-label">Timezone</InputLabel>
                                <Select
                                    labelId="timezone-label"
                                    id="timezone"
                                    name="timezone"
                                    size="small"
                                    value={offsetToTimezone[details.timezone_offset_from_utc]}
                                    label="Timezone"
                                    onChange={(e: SelectChangeEvent) =>
                                        setDetails((prev) => ({
                                            ...prev,
                                            timezone_offset_from_utc: timezoneToOffset[e.target.value],
                                        }))
                                    }
                                >
                                    {Object.keys(timezoneToOffset).map((timezone) => (
                                        <MenuItem id={`${timezone}-menu-item`} key={timezone} value={timezone}>
                                            {timezone}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Card>
                <Grid container columns={2} columnSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={1}>
                        <Button
                            id="add-terminal-btn"
                            color="secondary"
                            variant="contained"
                            size="small"
                            fullWidth
                            disabled={!isValid}
                            onClick={() => props.onSave(details)}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            id="cancel-btn"
                            color="primary"
                            variant="contained"
                            size="small"
                            fullWidth
                            onClick={() => props.onClose()}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    );
};

export default AddTerminalModal;
