import { Box, Select, SelectChangeEvent } from '@mui/material';
import {
    GridColDef,
    GridEditInputCell,
    GridPreProcessEditCellProps,
    GridRenderEditCellParams,
    GridRowModel,
    GridRowSelectionModel,
    getGridStringOperators,
    useGridApiContext,
} from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';

import { HomeTerminal } from '../../../../backendsdk';
import { TrackedDataGrid as StyledDataGrid } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import palette from '../../../ColorPalette';
import { StyledTooltip } from '../../Users/MuiStyled';
import { GridRenderWithOptions } from '../../Users/UserDataGrid';

export const timezoneToOffset: Record<string, number> = {
    EST: 5,
    CST: 6,
    MST: 7,
    PST: 8,
    AKT: 9,
    HST: 10,
};

export const offsetToTimezone = Object.fromEntries(
    Object.keys(timezoneToOffset).map((timezone) => [timezoneToOffset[timezone], timezone]),
);

const EditInputCell = (props: GridRenderEditCellParams) => {
    const { error } = props;
    return (
        <StyledTooltip open={!!error} title={error}>
            <Box>
                <GridEditInputCell {...props} error={!!error} debounceMs={0} />
            </Box>
        </StyledTooltip>
    );
};

const renderEdit = (params: GridRenderEditCellParams) => {
    return <EditInputCell {...params} />;
};

const renderSelectEditCycle: GridColDef['renderCell'] = (params) => {
    return <SelectEditInputCell options={['7', '8']} {...params} />;
};

const renderSelectEditTimezone: GridColDef['renderCell'] = (params) => {
    return <SelectEditInputCell options={Object.keys(timezoneToOffset)} {...params} />;
};

const SelectEditInputCell = (props: GridRenderWithOptions) => {
    const { id, field } = props;

    const apiRef = useGridApiContext();

    const handleChange = async (event: SelectChangeEvent) => {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        apiRef.current.stopCellEditMode({ id, field });
    };

    return (
        <Select value={props.value} onChange={handleChange} size="small" sx={{ height: 1 }} native autoFocus>
            {props.options.map((option, idx) => (
                <option key={idx} value={option}>
                    {option && props.t ? props.t(option) : option}
                </option>
            ))}
        </Select>
    );
};

const preProcessEditCellProps = (params: GridPreProcessEditCellProps) => {
    const errorMessage = params.props.value.toString() === '' ? 'This field is mandatory' : '';
    return { ...params.props, error: errorMessage };
};

interface TerminalsDataGridProps {
    terminals: Record<number, HomeTerminal>;
    onUpdate: CallableFunction;
    selectionModel: GridRowSelectionModel;
    setSelectionModel: CallableFunction;
    setAlert: CallableFunction;
}

const TerminalsDataGrid: React.FC<TerminalsDataGridProps> = (props: TerminalsDataGridProps) => {
    const isMobile = useIsMobile();

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Name',
                width: 200,
                editable: true,
                preProcessEditCellProps,
                renderEditCell: renderEdit,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'address',
                headerName: 'Address',
                minWidth: 200,
                flex: 1,
                editable: true,
                preProcessEditCellProps,
                renderEditCell: renderEdit,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'consecutive_days',
                type: 'singleSelect',
                valueOptions: ['7', '8'],
                headerName: 'Consecutive Days',
                width: 200,
                editable: true,
                renderEditCell: renderSelectEditCycle,
            },
            {
                field: 'timezone_offset_from_utc',
                type: 'singleSelect',
                valueOptions: Object.keys(timezoneToOffset),
                headerName: 'Timezone',
                width: 200,
                editable: true,
                renderEditCell: renderSelectEditTimezone,
            },
        ],
        [],
    );

    const rows = Object.values(props.terminals).map((terminal) => {
        return {
            id: terminal.id,
            name: terminal.name,
            address: terminal.address,
            consecutive_days: terminal.consecutive_days,
            timezone_offset_from_utc: offsetToTimezone[terminal.timezone_offset_from_utc],
        };
    });

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedTerminalToSend: HomeTerminal = {
            id: newRow.id,
            name: newRow.name,
            address: newRow.address,
            consecutive_days: parseInt(newRow.consecutive_days),
            timezone_offset_from_utc: timezoneToOffset[newRow.timezone_offset_from_utc],
            day_start_time: '00:00',
        };
        props.onUpdate(updatedTerminalToSend);

        return newRow;
    };

    return (
        <StyledDataGrid
            id="terminals-data-grid"
            rows={rows}
            columns={columns}
            autoHeight
            checkboxSelection
            disableRowSelectionOnClick
            disableVirtualization={isMobile}
            pagination={isMobile}
            rowSelectionModel={props.selectionModel}
            onRowSelectionModelChange={(newSelectionModel) => {
                props.setSelectionModel(newSelectionModel);
            }}
            processRowUpdate={processRowUpdate}
            columnBuffer={5}
            slotProps={{
                columnsPanel: {
                    sx: {
                        '& .MuiDataGrid-panelFooter button:first-child': {
                            display: 'none',
                        },
                    },
                },
            }}
            sx={{
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                backgroundColor: palette.neutral[50],
            }}
        />
    );
};

export default TerminalsDataGrid;
