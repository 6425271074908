import styled from 'styled-components';

import palette from '../ColorPalette';

export const SDiv = styled.div`
    height: 100%;
    display: flex;
    padding-bottom: 1em;

    @media (max-width: 768px) {
        flex-direction: column-reverse;

        & > div:last-child {
            min-height: 20em;
        }
    }
`;

export const SVFlexDiv = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    & > div:last-child:not(:only-of-type) {
        margin-top: auto;
    }
`;

export const SDivRightWide = styled.div`
    margin-right: 0;
    margin-left: auto;
    width: 70%;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const FilterSpan = styled.span`
    cursor: pointer;
    background-color: #262626;
    color: var(--bg-color);
    margin: 0.2em;
    border-radius: 0.3em;
    padding: 0.3em;
    font-size: 0.8em;

    &:first-child {
        margin-left: 0;
    }
`;

export const wellnessColorsMap = {
    unknown: palette.wellness.gray,
    unwell: palette.wellness.red,
    well: palette.wellness.green,
};
export const wellnessColors = [wellnessColorsMap.unknown, wellnessColorsMap.unwell, wellnessColorsMap.well];
export const wellnessColorsByState: Record<string, string> = {
    inactive: wellnessColorsMap.unknown,
    blocked: wellnessColorsMap.unwell,
    'no face': wellnessColorsMap.unwell,
    no_face: wellnessColorsMap.unwell,
    peripheral_face: wellnessColorsMap.unwell,
    faulty: wellnessColorsMap.unwell,
    well: wellnessColorsMap.well,
    centered_face: wellnessColorsMap.well,
    frontfacingwellnesswell: wellnessColorsMap.well,
    disconnected: wellnessColorsMap.unwell,
    frontfacingwellnessunwellhorizontal: wellnessColorsMap.unwell,
    frontfacingwellnesscameraverticalhigh: wellnessColorsMap.unwell,
    frontfacingwellnesscameraverticallow: wellnessColorsMap.unwell,
    frontfacingwellnesslowqualityinput: wellnessColorsMap.unwell,
    frontfacingwellnessinsufficientvalidinput: wellnessColorsMap.unwell,
    frontfacingwellnessunknown: wellnessColorsMap.unknown,
    connectionfailure: wellnessColorsMap.unwell,
    datafailure: wellnessColorsMap.unwell,
    unknown: wellnessColorsMap.unknown,
};
