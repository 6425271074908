import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Box, Chip, Slide, Typography, lighten } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Descendant, Editor, Transforms, createEditor } from 'slate';
import { withReact } from 'slate-react';

import { ExtendedCustomerDataAllOfUsers, ExtendedFeedEvent, FeedComment } from '../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { hasTextValue } from '../../../utils/slate';
import palette from '../../ColorPalette';
import { EMPTY_VALUE } from '../Accident/AccidentComments';
import MentionsTextField, { withMentions } from '../Accident/MentionsTextField';
import { MalfunctionCommentSpan } from '../Malfunctions/MalfunctionDetails';

interface FeedCommentsProps {
    isOpen: boolean;
    onClose: CallableFunction;
    comments: FeedComment[];
    containerRef?: React.RefObject<HTMLDivElement>;
    users: ExtendedCustomerDataAllOfUsers[];
    eventId: number;
    setSnackbarMessage: CallableFunction;
    setEvents: CallableFunction;
}

export const FeedComments: React.FC<FeedCommentsProps> = (props: FeedCommentsProps) => {
    const [editor] = useState<Editor>(() => withMentions(withReact(createEditor())));
    const [newComment, setNewComment] = useState<Descendant[]>(EMPTY_VALUE);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { api } = useApi();
    const { profile } = useProfile();

    const userList = props.users.map((u) => ({ id: u.user_id, name: u.name }));
    const userAvatarsMap: Record<number, string> = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        props.users.filter((u) => !!u.image_url).map((u) => [u.user_id, u.image_url!]),
    );

    const postComment = async (comment: Descendant[]) => {
        setIsLoading(true);
        try {
            const res = await api.apiV0FeedEventIdCommentPost({
                eventId: props.eventId,
                feedCommentRequest: {
                    comment,
                },
            });
            Transforms.delete(editor, {
                at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                },
            });
            setNewComment(EMPTY_VALUE);
            props.setEvents((events: ExtendedFeedEvent[]) => {
                return events.map((event) => {
                    if (event.event_id === props.eventId) {
                        return {
                            ...event,
                            feed_event: res.data,
                        };
                    }
                    return event;
                });
            });
        } catch {
            props.setSnackbarMessage(t('content.feed.comment_error'));
        } finally {
            setIsLoading(false);
        }
    };

    const reversedComments = props.comments.sort((a, b) => b.timestamp - a.timestamp);
    const commentsByDate = reversedComments.reduce((acc, comment) => {
        const date = dayjs.unix(comment.timestamp).format(profile.shortDateFormat);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(comment);
        return acc;
    }, {} as Record<string, FeedComment[]>);

    let content;
    if (reversedComments.length === 0) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    flexGrow: 1,
                }}
            >
                <Typography variant="overline" sx={{ color: palette.neutral[50] }}>
                    {t('content.feed.no_comments')}
                </Typography>
            </Box>
        );
    } else {
        content = Object.entries(commentsByDate).map(([date, commentsOnDate]) => {
            return (
                <React.Fragment key={date}>
                    {commentsOnDate.map((comment, index) => {
                        // check if previous comment is by the same author
                        const prevComment = commentsOnDate[index + 1];
                        const isSameAuthor = prevComment?.user_id === comment.user_id;
                        return (
                            <MalfunctionCommentSpan
                                key={`${comment.user_id}${comment.timestamp}`}
                                authorName={
                                    isSameAuthor ? '' : userList.find((u) => u.id === comment.user_id)?.name || ''
                                }
                                comment={{
                                    id: index,
                                    author: comment.user_id,
                                    text: comment.content,
                                    timestamp: comment.timestamp,
                                }}
                                selfColor={lighten(palette.secondary, 0.25)}
                                otherColor="#d9d9d9"
                            />
                        );
                    })}
                    <Box
                        data-date={date}
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            position: 'sticky',
                            top: 0,
                            mt: 1,
                        }}
                    >
                        <Chip label={date} sx={{ backgroundColor: palette.neutral[200] }} />
                    </Box>
                </React.Fragment>
            );
        });
    }

    const isSendDisabled = isLoading || !hasTextValue(newComment);

    return (
        <Slide in={props.isOpen} direction="up" unmountOnExit container={props.containerRef?.current}>
            <Box
                sx={{
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    minHeight: '300px',
                    maxHeight: '50%',
                    zIndex: 10,
                    backgroundColor: 'primary.main',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexShrink: 0,
                        minHeight: 0,
                        mb: 1,
                    }}
                >
                    <Typography fontSize={18} sx={{ color: palette.neutral[50] }}>
                        {props.comments.length > 0
                            ? `${t('content.feed.comments')} (${props.comments.length})`
                            : t('content.feed.comments')}
                    </Typography>
                    <IconButton size="small" id="btn-close-comments" onClick={() => props.onClose()}>
                        <CloseIcon sx={{ color: palette.neutral[50] }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        height: '100%',
                        width: '100%',
                        overflowY: 'scroll',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        mb: 1,
                    }}
                >
                    {content}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', minHeight: 0, flexShrink: 0 }}>
                    <MentionsTextField
                        editor={editor}
                        setValue={setNewComment}
                        users={userList}
                        userAvatarsMap={userAvatarsMap}
                        disabled={isLoading}
                        darkMode
                    />
                    <IconButton
                        id="btn-send-comment"
                        data-testid="btn-send-comment"
                        disabled={isSendDisabled}
                        onClick={() => postComment(newComment)}
                        sx={{ ml: '2px', opacity: isSendDisabled ? 0.5 : 1 }}
                    >
                        <SendIcon sx={{ color: palette.neutral[100] }} />
                    </IconButton>
                </Box>
            </Box>
        </Slide>
    );
};
