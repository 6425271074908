import { Box, CircularProgress, Menu } from '@mui/material';
import { LatLng, latLngBounds } from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import 'leaflet/dist/leaflet.css';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';

import { DeviceV3, RouteData, TripDetails } from '../../../backendsdk';
import { TrackedMenuItem as MenuItem } from '../../../components/TrackedComponents';
import { isLocationValid, parseLocation } from '../../../utils/location';
import { RTLDirectionContext } from '../../Layout';
import { GeofenceGeoJSON } from './FleetOverview';
import GeofenceMap from './Geofence/GeofenceMap';
import MapControls from './MapControls';
import { MapIcons, SelectedMapIcon } from './MapIcons';
import MapLegend from './MapLegend';
import MapRoute from './MapRoute';

interface OverviewMapProps {
    devices: DeviceV3[];
    hoveredDeviceId?: string;
    selectedDeviceId?: string;
    isClustered: boolean;
    setIsClustered: CallableFunction;
    selectedTrip?: TripDetails;
    setSelectedTrip: CallableFunction;
    setSelectedEventId: CallableFunction;
    setIsLoadingEvent: CallableFunction;
    isGeofenceMode: boolean;
    geofences: GeofenceGeoJSON[];
    hoveredGeofence?: number;
    selectedGeofence?: number;
    setSelectedGeofence: CallableFunction;
    drawnGeofence?: GeofenceGeoJSON;
    setDrawnGeofence: CallableFunction;
    setIsMapInEditMode: CallableFunction;
    isDarkMode: boolean;
    showList: boolean;
    showToolbar: boolean;
    previewColor?: string;
    setAlert: CallableFunction;
    setTimespan: CallableFunction;
    setAdditionalTrip: CallableFunction;
}

interface MapEventComponentProps {
    showList: boolean;
    showToolbar: boolean;
    setCurrentCoordinates: CallableFunction;
}

const MapEventComponent: React.FC<MapEventComponentProps> = (props: MapEventComponentProps) => {
    const map = useMapEvents({
        contextmenu: (e) => {
            props.setCurrentCoordinates(e.latlng);
        },
    });

    useEffect(() => {
        if (!map) return;
        map.invalidateSize();
    }, [map, props.showList, props.showToolbar]);
    return null;
};

const OverviewMap: React.FC<OverviewMapProps> = (props: OverviewMapProps) => {
    const [tripRoute, setTripRoute] = useState<RouteData>();
    const [isMapLoading, setIsMapLoading] = useState<boolean>(false);
    const [currentCoordinates, setCurrentCoordinates] = useState<LatLng>();
    const { t } = useTranslation();
    const isRTL = useContext(RTLDirectionContext);

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    useEffect(() => {
        if (props.selectedDeviceId === undefined) {
            setTripRoute(undefined);
        }
    }, [props.selectedDeviceId]);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const devicesWithLocations = props.devices.filter((device) => isLocationValid(device.location));
    const locations = devicesWithLocations.map((device) => parseLocation(device.location));
    let bounds;
    let centerAndZoom = {};
    // check if there are duplicate locations
    const uniqueLocations = new Set(locations.map((loc) => loc.toString()));
    if (uniqueLocations.size > 1) {
        bounds = latLngBounds(locations).pad(0.1);
    } else if (uniqueLocations.size === 1) {
        centerAndZoom = { center: locations[0], zoom: 16 };
    }

    return (
        <Box onContextMenu={handleContextMenu} sx={{ width: '100%', height: '100%', position: 'relative' }}>
            {isMapLoading ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'bgColor.main',
                        position: 'absolute',
                        zIndex: 1001,
                        opacity: 0.5,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : null}
            <MapContainer
                attributionControl={false}
                zoomSnap={0.5}
                id="map-widget"
                style={{ height: '100%', width: '100%' }}
                bounds={bounds}
                {...centerAndZoom}
            >
                <MapEventComponent
                    showList={props.showList}
                    showToolbar={props.showToolbar}
                    setCurrentCoordinates={setCurrentCoordinates}
                />
                <TileLayer
                    key={props.isDarkMode ? 'dark' : 'light'}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    className={props.isDarkMode ? 'leaflet-dark' : 'leaflet-light'}
                />
                <MapLegend
                    position={isRTL ? 'bottomright' : 'bottomleft'}
                    isDeviceListView={!props.selectedDeviceId && !props.selectedGeofence}
                    isTripView={!!props.selectedTrip}
                    tripRoute={tripRoute}
                />
                <MapControls
                    isClustered={props.isClustered}
                    setIsClustered={props.setIsClustered}
                    bounds={bounds}
                    isGeofenceMode={props.isGeofenceMode}
                    isSelectedElement={!!props.selectedDeviceId || !!props.selectedGeofence || !!props.drawnGeofence}
                />
                {!props.selectedGeofence ? (
                    !!props.selectedDeviceId ? (
                        <>
                            <SelectedMapIcon devices={props.devices} selectedDeviceId={props.selectedDeviceId} />
                            <MapRoute
                                tripRoute={tripRoute}
                                setTripRoute={setTripRoute}
                                selectedTrip={props.selectedTrip}
                                setSelectedTrip={props.setSelectedTrip}
                                setSelectedEventId={props.setSelectedEventId}
                                setIsLoadingEvent={props.setIsLoadingEvent}
                                isCurrentTrip={
                                    props.devices.find((device) => device.device.device_id === props.selectedDeviceId)
                                        ?.current_trip?.id === props.selectedTrip?.id
                                }
                                setIsMapLoading={setIsMapLoading}
                                setAlert={props.setAlert}
                                setTimespan={props.setTimespan}
                                setAdditionalTrip={props.setAdditionalTrip}
                            />
                        </>
                    ) : (
                        <MapIcons
                            devices={props.devices}
                            hoveredDeviceId={props.hoveredDeviceId}
                            isClustered={props.isClustered}
                            isGeofenceMode={props.isGeofenceMode}
                        />
                    )
                ) : null}
                {props.isGeofenceMode && (
                    <GeofenceMap
                        geofences={props.geofences}
                        hoveredGeofence={props.hoveredGeofence}
                        drawnGeofence={props.drawnGeofence}
                        setDrawnGeofence={props.setDrawnGeofence}
                        selectedGeofence={props.selectedGeofence}
                        setSelectedGeofence={props.setSelectedGeofence}
                        setIsMapInEditMode={props.setIsMapInEditMode}
                        previewColor={props.previewColor}
                    />
                )}
            </MapContainer>
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                }
            >
                <MenuItem
                    id="context-menu-open-in-google-maps"
                    onClick={() => {
                        handleClose();
                        window.open(
                            `http://maps.google.com/maps?q=&layer=c&cbll=${currentCoordinates?.lat},${currentCoordinates?.lng}&cbp=11,0,0,0,0`,
                        );
                    }}
                >
                    {t('content.fleet.map.open_in_street_view')}
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default OverviewMap;
