import { Box, CircularProgress, Popover, styled } from '@mui/material';
import { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceV2 } from '../../../backendsdk';
import useApi from '../../../hooks/api';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE, formatDateTime } from '../../../utils/TimeFormatter';
import { RTLDirectionContext } from '../../Layout';
import WellnessBox from './WellnessBox';

export const StyledPopover = styled(Popover)(() => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));

interface WellnessBoxProps {
    device: DeviceV2;
    wellnessType: 'in_cabin' | 'in_cabin_v1' | 'front_facing';
    wellnessId?: number;
    timestamp?: number;
    wellnessDetails: string;
}

const WellnessContainer: React.FC<WellnessBoxProps> = (props: WellnessBoxProps) => {
    const [wellnessImage, setWellnessImage] = useState<string>();
    const { t } = useTranslation();
    const isRTL = useContext(RTLDirectionContext);
    const { api } = useApi();
    const [imageSize, setImageSize] = useState<{ width: number; height: number }>();

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'wellness-popover',
    });

    const getWellnessImageSize = (image: string) => {
        return new Promise<void>((resolve, reject) => {
            const imageFile = new Image();
            imageFile.src = image;
            imageFile.onload = () => {
                setImageSize({ width: imageFile.width, height: imageFile.height });
                resolve();
            };
            imageFile.onerror = reject;
        });
    };

    useEffect(() => {
        const getImage = async () => {
            if (popupState.isOpen && wellnessImage == undefined) {
                let wellnessType = props.wellnessType;
                if (props.wellnessType === 'in_cabin') {
                    if (
                        props.device.aggregated_wellness?.in_cabin_wellness &&
                        'wellness_state' in props.device.aggregated_wellness?.in_cabin_wellness
                    ) {
                        wellnessType = 'in_cabin_v1';
                    }
                }
                try {
                    const res = await api.apiV2WellnessImageWellnessTypeGet(
                        { wellnessType, wellnessId: props.wellnessId?.toString() },
                        { responseType: 'blob' },
                    );
                    const reader = new window.FileReader();
                    reader.readAsDataURL(res.data);
                    reader.onload = async () => {
                        const image = reader.result;
                        if (typeof image === 'string') {
                            await getWellnessImageSize(image);
                            setWellnessImage(image);
                        } else {
                            setWellnessImage('');
                        }
                    };
                    reader.onerror = () => setWellnessImage('');
                } catch (e) {
                    setWellnessImage('');
                }
            }
        };
        getImage().catch(() => setWellnessImage(''));
    }, [popupState.isOpen]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setWellnessImage(undefined);
        }, 5 * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND);
        return () => clearInterval(intervalId);
    }, []);

    const shouldCropImage =
        imageSize?.width !== undefined &&
        imageSize?.height !== undefined &&
        imageSize.width / imageSize.height === 8 / 3;

    const imageClassNames = ['wellness-image'];
    if (shouldCropImage) {
        if (props.wellnessType.includes('in_cabin')) {
            imageClassNames.push('ic-image');
        } else {
            imageClassNames.push('ff-image');
        }
    }

    return (
        <>
            <WellnessBox
                wellnessType={props.wellnessType}
                wellnessDetails={props.wellnessDetails}
                {...bindHover(popupState)}
            />
            <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: isRTL ? 'right' : 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: isRTL ? 'left' : 'right',
                }}
            >
                <Box
                    sx={{
                        p: 0.5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                    }}
                >
                    {t(`content.fleet.wellness.${props.wellnessDetails.toLocaleLowerCase()}`)}
                    <Box style={{ aspectRatio: wellnessImage ? '4 / 3' : undefined, maxWidth: '250px' }}>
                        {wellnessImage === undefined ? (
                            <CircularProgress size={24} />
                        ) : (
                            <img className={imageClassNames.join(' ')} src={wellnessImage} alt="No Image Available" />
                        )}
                    </Box>
                    {wellnessImage && props.timestamp ? formatDateTime(props.timestamp) : null}
                </Box>
            </HoverPopover>
        </>
    );
};

export default WellnessContainer;
