import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import { DeviceV3 } from '../backendsdk';
import useApi from './api';

const useDevices = (
    cache?: number,
): {
    devices: DeviceV3[];
    isLoading: boolean;
    isError: boolean;
    updateDevices: CallableFunction;
} => {
    const [queryParam, setQueryParam] = useState<{ timestamp?: number }>({ timestamp: undefined });
    const queryClient = useQueryClient();
    const { api } = useApi();

    const getDevices = async (): Promise<DeviceV3[]> => {
        const { data } = await api.apiV3DeviceGet({ cache: cache !== undefined ? cache : 1 });
        setQueryParam((p) => {
            p.timestamp = data.timestamp;
            return p;
        });
        return data.data;
    };

    const { data, isLoading, isError } = useQuery({
        queryKey: ['devices'],
        queryFn: getDevices,
        staleTime: Infinity,
    });

    const updateDevices = useCallback(() => {
        api.apiV3DeviceGet(queryParam).then((res) => {
            setQueryParam((p) => {
                p.timestamp = res.data.timestamp;
                return p;
            });
            if (!res.data?.data) {
                return;
            }
            const prevDevices = queryClient.getQueryData<DeviceV3[]>(['devices']) || [];
            const prevDevicesById = Object.fromEntries(prevDevices.map((d) => [d.device.device_id, d]));
            const updatedDevicesById = Object.fromEntries(res.data.data.map((d) => [d.device.device_id, d]));
            queryClient.setQueryData(['devices'], () => Object.values({ ...prevDevicesById, ...updatedDevicesById }));
        });
    }, [api]);

    return { devices: data || [], isLoading, isError, updateDevices };
};

export default useDevices;
