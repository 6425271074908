import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Card, CircularProgress, Divider, Tabs, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewData } from '../../../../backendsdk';
import { TabPanel, a11yProps } from '../../../../components/Tab';
import { TrackedLink as Link, TrackedTab as Tab } from '../../../../components/TrackedComponents';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import { getStorageItem } from '../../../../utils/Storage';
import palette from '../../../ColorPalette';
import AccidentDetailsComponent from '../../Accident/AccidentDetails';
import { AccidentPageVersion } from '../../Accident/Defs';
import { QuarterYear } from '../MetricsContent';
import FleetScoreChart from '../SafetyWellness/FleetTrendChart';
import AccidentCausesCard from './AccidentCausesCard';
import AccidentTimesCard from './AccidentTimesCard';
import AccidentsMap from './AccidentsMap';
import FaultSpeedCard from './FaultSpeedCard';
import OverviewTable from './OverviewTable';
import PointsOfImpactCard from './PointsOfImpactCard';

dayjs.extend(utc);

interface AccidentOverviewProps {
    selectedSubFleet: string;
    setSelectedSubFleet: CallableFunction;
    overviewData: OverviewData[];
    handlingStatusList: string[];
    setHandlingStatusList: CallableFunction;
    isLoading: boolean;
    isError: boolean;
    animate: boolean;
    getAccidentOverview: CallableFunction;
    hiddenSubFleets: string[];
    setHiddenSubFleets: CallableFunction;
    timespan: QuarterYear;
}

const AccidentOverview: React.FC<AccidentOverviewProps> = (props: AccidentOverviewProps) => {
    const { overviewData, handlingStatusList, setHandlingStatusList, isLoading, isError, animate, timespan } = props;
    const { t } = useTranslation();
    const ALL_FLEET = t('content.metrics.all_fleet');
    const [hoveredSubFleet, setHoveredSubFleet] = useState<string>('');
    const [tableTab, setTableTab] = useState<number>(0);
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [selectedAccidentId, setSelectedAccidentId] = useState<string>();
    const { profile } = useProfile();
    const isMobile = useIsMobile();

    const [frequencyData, subFleets] = useMemo(() => {
        const subFleets = new Set<string>();
        const frequencyChartDataMap: Record<number, Record<string, number | null>> = {};
        if (overviewData !== undefined) {
            for (const subFleetData of overviewData) {
                const currSubFleet = subFleetData.sub_fleet || ALL_FLEET;
                for (const point of subFleetData.accident_data) {
                    subFleets.add(currSubFleet);
                    if (frequencyChartDataMap[point.timestamp] === undefined) {
                        frequencyChartDataMap[point.timestamp] = { name: point.timestamp };
                    }
                    if (point.accident_frequency === -1 || point.timestamp > dayjs().unix()) {
                        frequencyChartDataMap[point.timestamp][currSubFleet] = null;
                    } else {
                        frequencyChartDataMap[point.timestamp][currSubFleet] = Math.round(
                            point.accident_frequency * 100,
                        );
                    }
                }
            }
        }
        const frequencyChartData = Object.values(frequencyChartDataMap);
        frequencyChartData.sort((a, b) => (a.name && b.name ? a.name - b.name : 0));
        return [frequencyChartData, Array.from(subFleets)];
    }, [overviewData, timespan]);

    // reorder sub fleets to make sure the lines of the hovered subfleet and the selected subfleet are on top
    if (!!hoveredSubFleet) {
        subFleets.push(subFleets.splice(subFleets.indexOf(hoveredSubFleet), 1)[0]);
    }
    subFleets.push(subFleets.splice(subFleets.indexOf(props.selectedSubFleet), 1)[0]);

    const handleTableTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTableTab(newValue);
    };

    const currentSubFleetData = overviewData?.find((subFleetData) => subFleetData.sub_fleet === props.selectedSubFleet);

    const valueFormatter = (value: number) => `${value}%`;

    if (isError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.accident_overview.accidents_error')}</Typography>
                <Link
                    id="try-again-link"
                    component="button"
                    variant="overline"
                    onClick={() => {
                        props.getAccidentOverview();
                    }}
                >
                    {t('content.metrics.try_again')}
                </Link>
            </Box>
        );
    }

    let topCard;
    if (!isLoading && overviewData?.length === 0) {
        topCard = (
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 330,
                    p: 1,
                }}
            >
                <Typography variant="overline">{t('content.accident_overview.no_data')}</Typography>
                {props.selectedSubFleet !== ALL_FLEET ? (
                    <Link
                        id="clear-filter-link"
                        component="button"
                        variant="overline"
                        onClick={() => {
                            props.setSelectedSubFleet(ALL_FLEET);
                        }}
                    >
                        {t('content.accident_overview.clear_filter')}
                    </Link>
                ) : null}
            </Card>
        );
    } else {
        topCard = (
            <Card
                data-testid="frequency-trend-container"
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                    height: isMobile ? 500 : 330,
                    p: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                        height: isMobile ? undefined : '100%',
                        width: isMobile ? '100%' : 330,
                        p: 2,
                    }}
                >
                    <Typography textAlign="center" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                        {t('content.accident_overview.frequency_trend')}
                    </Typography>
                    <Typography fontSize={14} sx={{ mb: 1 }}>
                        {t('content.accident_overview.frequency_trend_description1')}
                    </Typography>
                    <Typography fontSize={12} sx={{ color: palette.neutral[700], mb: 1 }}>
                        {`${t('content.accident_overview.with_valid_license_plate')}.`}
                    </Typography>
                    <Typography fontSize={12} sx={{ color: palette.neutral[700] }}>
                        {t('content.accident_overview.frequency_trend_description2')}
                    </Typography>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: 2 }} />
                <FleetScoreChart
                    data={frequencyData}
                    subFleets={subFleets}
                    animate={animate}
                    selectedSubFleet={props.selectedSubFleet}
                    setSelectedSubFleet={props.setSelectedSubFleet}
                    hoveredSubFleet={hoveredSubFleet}
                    setHoveredSubFleet={setHoveredSubFleet}
                    dateFormat={profile.shortDateFormat}
                    allFleetString={ALL_FLEET}
                    YAxisProps={{
                        tickCount: 10,
                        domain: ([dataMin, dataMax]) => {
                            // Y-axis max is the next multiple of 10 above data's max value, and y-axis min is the closest multiple of 10 below data's min value
                            return [Math.floor(dataMin / 10) * 10, Math.ceil(dataMax / 10) * 10];
                        },
                        tickFormatter: valueFormatter,
                    }}
                    margin={{
                        top: 5,
                        right: 5,
                        left: -15,
                        bottom: 5,
                    }}
                    valueFormatter={valueFormatter}
                    accentColor={palette.red[300]}
                    hiddenSubFleets={props.hiddenSubFleets}
                    setHiddenSubFleets={props.setHiddenSubFleets}
                />
            </Card>
        );
    }

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '1200px',
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
                position: 'relative',
            }}
        >
            {isLoadingModal ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1500,
                        backgroundColor: 'bgColor.main',
                        opacity: 0.5,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : null}
            {topCard}
            {isLoading || currentSubFleetData !== undefined ? (
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            flex: 1,
                            mr: isMobile ? 0 : 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                                mb: 1,
                            }}
                        >
                            <Typography
                                variant="overline"
                                textAlign="center"
                                fontSize={14}
                                color={palette.neutral[700]}
                                sx={{ lineHeight: 1 }}
                            >
                                {t('content.accident_overview.locations')}
                            </Typography>
                            <Tooltip title={t('content.accident_overview.with_event')}>
                                <InfoOutlinedIcon
                                    sx={{ color: palette.neutral[700], fontSize: 16, ml: 0.25, pb: 0.1 }}
                                />
                            </Tooltip>
                        </Box>
                        <Card sx={{ width: '100%', height: isMobile ? 330 : '100%' }}>
                            <AccidentsMap
                                locations={currentSubFleetData?.accident_locations || []}
                                setSelectedAccidentId={setSelectedAccidentId}
                                isLoading={isLoading}
                            />
                        </Card>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: isMobile ? '100%' : 350,
                            flexShrink: 0,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                                mb: 1,
                            }}
                        >
                            <Typography
                                variant="overline"
                                textAlign="center"
                                fontSize={14}
                                color={palette.neutral[700]}
                                sx={{ lineHeight: 1 }}
                            >
                                {t('content.accident_overview.most_accidents')}
                            </Typography>
                            <Tooltip title={t('content.accident_overview.with_valid_license_plate')}>
                                <InfoOutlinedIcon
                                    sx={{ color: palette.neutral[700], fontSize: 16, ml: 0.25, pb: 0.1 }}
                                />
                            </Tooltip>
                        </Box>
                        <Card sx={{ width: '100%', height: 465, display: 'flex', flexDirection: 'column' }}>
                            <Tabs
                                value={tableTab}
                                onChange={handleTableTabChange}
                                TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                                sx={{ width: '100%', borderBottom: `1px solid ${palette.gray[400]}` }}
                            >
                                <Tab
                                    label={
                                        <Typography fontSize={14}>{t('content.accident_overview.drivers')}</Typography>
                                    }
                                    {...a11yProps(0)}
                                    id="tab-drivers"
                                    data-testid={`tab-drivers`}
                                    sx={{ width: '50%' }}
                                />
                                <Tab
                                    label={
                                        <Typography fontSize={14}>{t('content.accident_overview.vehicles')}</Typography>
                                    }
                                    {...a11yProps(1)}
                                    id="tab-vehicles"
                                    data-testid={`tab-vehicles`}
                                    sx={{ width: '50%' }}
                                />
                            </Tabs>
                            <TabPanel value={tableTab} index={0} sx={{ width: '100%', p: 1 }}>
                                <OverviewTable
                                    tableHeader="driver_name"
                                    mostCommonList={currentSubFleetData?.most_common_driver_names || []}
                                    isLoading={isLoading}
                                />
                            </TabPanel>
                            <TabPanel value={tableTab} index={1} sx={{ width: '100%', p: 1 }}>
                                <OverviewTable
                                    tableHeader="license_plate"
                                    mostCommonList={currentSubFleetData?.most_common_license_plates || []}
                                    isLoading={isLoading}
                                />
                            </TabPanel>
                        </Card>
                    </Box>
                </Box>
            ) : null}
            {!!selectedAccidentId ? (
                <AccidentDetailsComponent
                    pageVersion={(getStorageItem('OE-accident-page-version') as AccidentPageVersion) || 'v0'}
                    accidentId={selectedAccidentId}
                    refreshTable={() => null}
                    setIsLoadingModal={setIsLoadingModal}
                    handlingStatusList={handlingStatusList}
                    setHandlingStatusList={setHandlingStatusList}
                    onClose={() => setSelectedAccidentId(undefined)}
                />
            ) : null}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 2, mb: 1 }}>
                <Typography
                    variant="overline"
                    textAlign="center"
                    fontSize={14}
                    color={palette.neutral[700]}
                    sx={{ lineHeight: 1 }}
                >
                    {t('content.accident_overview.insights')}
                </Typography>
                <Tooltip title={t('content.accident_overview.insights_tooltip')}>
                    <InfoOutlinedIcon sx={{ color: palette.neutral[700], fontSize: 16, ml: 0.25, pb: 0.1 }} />
                </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%' }}>
                <AccidentCausesCard taggingData={currentSubFleetData?.tagging_data} isLoading={isLoading} />
                <AccidentTimesCard taggingData={currentSubFleetData?.tagging_data} isLoading={isLoading} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', mt: 2 }}>
                <PointsOfImpactCard taggingData={currentSubFleetData?.tagging_data} isLoading={isLoading} />
                <FaultSpeedCard taggingData={currentSubFleetData?.tagging_data} isLoading={isLoading} />
            </Box>
        </Box>
    );
};

export default AccidentOverview;
