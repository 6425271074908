import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AccordionDetails, Box, Collapse, Menu, Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import axios from 'axios';
import fromEntries from 'object.fromentries';
import { Resizable } from 're-resizable';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ApiApi, EventDetails, EventTagActionEnum } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import { Input } from '../../../components/form';
import useApi from '../../../hooks/api';
import useDrivers from '../../../hooks/drivers';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { isEventVideo } from '../../../utils/File';
import { eventsPageDefs } from '../../../utils/Pages';
import palette from '../../ColorPalette';
import { RTLDirectionContext } from '../../Layout';
import { eventToMarker } from '../EventMarkers';
import { MuiAccordion, MuiAccordionSummary } from '../Overview/DeviceDetails/DeviceDetailsComponent';
import { FilterSpan } from '../StyledElements';
import { GpsTrace } from './Defs';
import EventActionsComponent from './EventActions';
import EventCloneComponent from './EventClone';
import EventDetailsSectionComponent from './EventDetailsSection';
import EventVideoComponent from './EventVideo';
import RelatedEventsComponent from './RelatedEvents';

export const CRASH_INCIDENT_EVENT_TYPE = 'MsgCrashIncident';
export const CRASH_DISMISSED_TAG = 'crash-dismissed';
export const CRASH_REPORTED_TAG = 'crash-reported';

export interface CoachDetails {
    event?: number;
    comment?: string;
    completed?: boolean;
}

export const ADMIN_EVENT_TYPES = [
    'content.events.types.continuous',
    'content.events.types.broken_continuous',
    'content.events.types.crash_incident',
    'content.events.types.in_cabin_camera_covered',
    'ConfigUpload',
    'MsgImproperShutdown',
    'MsgPublishDebugFiles',
    'MsgSkipFrames',
    'MsgDisconnectedCamera',
    'MsgErrorCamera',
    'MsgFrontFacingCamUnwell',
    'MsgFrontFacingCamHigh',
    'MsgFrontFacingCamLow',
    'MsgPOSTFailure',
    'MsgScheduledReboot',
    'MsgGetInternalDb',
    'MsgGetInternalFile',
];

const EVENT_LEVEL_MAP: { [key: string]: string } = {
    INFO: 'content.events.levels.info',
    WARNING: 'content.events.levels.incident',
    ALERT: 'content.events.levels.accident',
    MANUAL: 'content.events.levels.incident',
};

export const toEventLevelText = (inputText: string): string => EVENT_LEVEL_MAP[inputText] ?? inputText;

export const getGpsTraceFromCsv = (url: string): Promise<Array<GpsTrace>> => {
    return axios.get(url).then((response) => {
        let headers: Record<string, number> = {};
        const gpsTrace: Array<GpsTrace> = [];
        const lines = response.data.split(/\r?\n/);
        for (const line of lines) {
            if (Object.keys(headers).length == 0 && line.includes('frame')) {
                headers = fromEntries(line.split(',').map((header: string, idx: number) => [header.trim(), idx]));
            } else if (Object.keys(headers).length > 0 && !!line) {
                const parts = line.split(',');
                gpsTrace.push({
                    timestamp: parseInt(parts[headers['time(ms)']]) / 1000,
                    lat: parseFloat(parts[headers['latitude']]),
                    lng: parseFloat(parts[headers['longitude']]),
                    speed: parseFloat(parts[headers['speed(m/s)']]),
                });
            }
        }
        return gpsTrace;
    });
};

export interface EventTaggingComponentProps {
    tags: Array<string>;
    details: EventDetails;
    getEventDetails: CallableFunction;
    setAlert: CallableFunction;
}

const EventTaggingComponent: React.FC<EventTaggingComponentProps> = (props: EventTaggingComponentProps) => {
    const [newTagName, setNewTagName] = useState<string>('');
    const [newDescription, setNewDescription] = useState<string>('');
    const [newFrameId, setNewFrameId] = useState<string>('');
    const { api } = useApi();

    useEffect(() => {
        setNewFrameId(props.details.start_frame_id?.toString() ?? '0');
        setNewDescription(props.details.event_description ?? '');
    }, [props.details]);

    const changeTag = (tagName: string, addTag: boolean) => {
        return api.apiV0EventEventIdTagPost({
            eventId: props.details.event_id,
            eventTag: { name: tagName, action: addTag ? EventTagActionEnum.Add : EventTagActionEnum.Remove },
        });
    };

    const patchEvent = (fieldName: string, fieldValue: string) => {
        return api
            .apiV0EventEventIdPatch({ eventId: props.details.event_id, inlineObject: { [fieldName]: fieldValue } })
            .then(() => props.getEventDetails(api));
    };

    return (
        <>
            Tags:{' '}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {props.details.tags.map((tagName) => (
                    <FilterSpan
                        id={`${tagName.replaceAll('_', '-')}-tag`}
                        key={tagName}
                        onClick={() => {
                            changeTag(tagName, false).then(() => props.getEventDetails(api));
                        }}
                    >
                        {tagName}
                        &nbsp;&times;
                    </FilterSpan>
                ))}
            </div>
            <br />
            <Input
                id="add-tag-text-field"
                label="Add Tag"
                value={newTagName}
                options={newTagName ? props.tags : []}
                handleOptionsSelect={(s: string) => setNewTagName(s)}
                onChange={(e) => setNewTagName(e.target.value)}
                onEnter={() => {
                    changeTag(newTagName, true).then(() => {
                        setNewTagName('');
                        props.getEventDetails(api);
                    });
                }}
            />
            <Input label="Description">
                <TextareaAutosize
                    id="description-text-field"
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            if (e.ctrlKey) {
                                patchEvent('event_description', newDescription);
                            }
                        }
                    }}
                    style={{ width: '180px' }}
                />
            </Input>
            <Input
                id="first-frame-text-field"
                label="Video First Frame ID"
                value={newFrameId}
                onChange={(e) => setNewFrameId(e.target.value)}
                onEnter={() => {
                    patchEvent('start_frame_id', newFrameId);
                }}
            />
            <br />
            <Typography>
                Trip ID: {props.details.trip_id}
                <Link
                    id="open-trip-btn"
                    href={`/events?trip_id=${props.details.trip_id}&level=info`}
                    target="_blank"
                    component={IconButton}
                >
                    <OpenInNewIcon />
                </Link>
            </Typography>
        </>
    );
};

export interface EventDetailsComponentProps {
    eventId: string;
    updateTable?: CallableFunction;
    updateTripDetails?: CallableFunction;
    setAlert: CallableFunction;
    isVisible?: boolean;
    setIsVisible?: CallableFunction;
    panelWidth?: number;
    setPanelWidth?: CallableFunction;
    isLoading: boolean;
    setIsLoading: CallableFunction;
    onEventsPage: boolean;
    setSelectedEventId?: CallableFunction;
    currentAccidentId?: number;
    onFilterChange?: CallableFunction;
    selectedEventIds?: GridRowSelectionModel;
    videoOnly?: boolean;
    videoWidth?: number;
    showEditVideo?: boolean;
    showFindVideo?: boolean;
    overrideVideoUrl?: string;
    setPercentVideoPlayed?: CallableFunction;
    overlay?: string;
    showReportAccident?: boolean;
    showCoaching?: boolean;
    showEventRoute?: boolean;
    setEventTimestamp?: CallableFunction;
    showOriginalVideo?: boolean;
    overflowY?: string;
    onClose?: CallableFunction;
}

const EventDetailsComponent: React.FC<EventDetailsComponentProps> = (props: EventDetailsComponentProps) => {
    const [artifact, setArtifact] = useState<string>('');
    const [allowEdit, setAllowEdit] = useState<boolean>(false);
    const [details, setDetails] = useState<EventDetails>();
    const [gpsTrace, setGpsTrace] = useState<Array<GpsTrace>>([]);
    const [gpsRoute, setGpsRoute] = useState<Array<[string, number, CallableFunction]>>([]);
    const [currentLocation, setCurrentLocation] = useState<string>();
    const [showCloneForm, setShowCloneForm] = useState<boolean>(false);
    const [fitBoundsCounter, setFitBoundsCounter] = useState<number>(0);
    const [anchorElOptions, setAnchorElOptions] = useState<null | HTMLElement>(null);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [videoDuration, setVideoDuration] = useState<number>();
    const isMobile = useIsMobile();
    const [openInDialog, setOpenInDialog] = useState<boolean>(
        (isMobile || !props.onEventsPage || localStorage.getItem('OE-event-dialog') === 'true') && !props.videoOnly,
    );
    const eventId = props.eventId;
    const history = useHistory();
    const { profile } = useProfile();
    const isAdmin = profile.admin;
    const { t } = useTranslation();
    const { api } = useApi();
    const { getDriver } = useDrivers();
    const ALLOW_VIEW_METADATA = [...eventsPageDefs.permissions.L1];
    const isOptionsOpen = Boolean(anchorElOptions);
    const apiRef = useRef(api);
    const isRTL = useContext(RTLDirectionContext);

    useEffect(() => {
        apiRef.current = api;
    }, [api]);

    useEffect(() => {
        // get gps file
        if (details) {
            const artifactUrl = Object.entries(details.artifact_path).find(([name]) => name.includes('gps.csv'))?.[1];
            if (artifactUrl) {
                getGpsTraceFromCsv(artifactUrl).then((gpsTrace) => {
                    setGpsTrace(gpsTrace);
                });
            } else {
                setGpsTrace([]);
            }
        }
    }, [details]);

    useEffect(() => {
        if (gpsTrace.length > 0) {
            const gpsTraceRoute: Array<[string, number, CallableFunction]> = gpsTrace.reduce((acc, entry) => {
                if (!isNaN(entry.lat) && !isNaN(entry.lng)) {
                    acc.push([`${entry.lat}:${entry.lng}`, 0, () => palette.accent]);
                }
                return acc;
            }, [] as Array<[string, number, CallableFunction]>);
            setGpsRoute(gpsTraceRoute);
            setCurrentLocation(gpsTraceRoute[0][0]);
        } else {
            setGpsRoute([]);
        }
    }, [gpsTrace]);

    const handleOptionsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElOptions(event.currentTarget);
    };

    const handleOptionsMenuClose = () => {
        setAnchorElOptions(null);
    };

    const handleModalClose = () => {
        setDetails(undefined);
        if (props.onEventsPage) {
            history.replace('/events');
        }
    };

    const getEventDetails = (api: ApiApi, controller?: AbortController, reload?: boolean) => {
        setAllowEdit(false);
        if (reload) {
        } else {
            setShowCloneForm(false);
        }
        setCurrentLocation(undefined);
        api.apiV0EventEventIdGet({ eventId: parseInt(eventId) }, { signal: controller?.signal })
            .then((res: { data: EventDetails }) => {
                const newDetails = res.data;
                if (res.data.event_id.toString() == eventId) {
                    setDetails(newDetails);
                    setArtifact(res.data.artifact_path[Object.keys(res.data.artifact_path).filter(isEventVideo)[0]]);
                    if (props.updateTable) {
                        props.updateTable(res.data);
                    }
                }
            })
            .catch(() => {
                if (!controller?.signal.aborted) {
                    handleModalClose();
                }
            })
            .finally(() => props.setIsLoading(false));
        setArtifact('');
    };

    useEffect(() => {
        const controller = new AbortController();
        getEventDetails(apiRef.current, controller);
        const interval = setInterval(() => getEventDetails(apiRef.current, controller, true), 30 * 60 * 1000);
        return () => {
            clearInterval(interval);
            controller.abort();
        };
    }, [eventId]);

    useEffect(() => {
        if (details !== undefined) {
            const isGeneratingVideo =
                details.event_description?.includes('original event id') &&
                !Object.keys(details.artifact_path)
                    .map((key) => isEventVideo(key))
                    .some(Boolean);
            if (isGeneratingVideo) {
                const timer = setTimeout(() => {
                    getEventDetails(apiRef.current, undefined, true);
                }, 5000);
                return () => clearTimeout(timer);
            }
        }
    }, [details]);

    useEffect(() => {
        if (props.setEventTimestamp && videoDuration && details) {
            props.setEventTimestamp(details.timestamp + videoDuration / 2);
        }
    }, [props.setEventTimestamp, videoDuration, details]);

    const driver = getDriver(details?.trip?.driver);

    if (details == undefined || (!props.onEventsPage && eventId != details.event_id.toString())) {
        return null;
    }

    const eventRoute: Array<[string, number, CallableFunction]> | undefined = details.route
        ? details.route.split(',').map((location) => [location.toString(), 0, () => palette.accent])
        : undefined;
    const markers: Array<React.ReactNode> = [];
    if (!!details.location) {
        markers.push(eventToMarker(details.location, details.type, 1));
    } else {
        const eventEstimatedLocation = eventRoute ? eventRoute[Math.floor(eventRoute.length / 2)][0] : undefined;
        markers.push(eventToMarker(eventEstimatedLocation, details.type, 1));
    }

    const getConfig = () => {
        api.apiV1EventGet({ tripId: details.trip_id, eventType: 'ConfigUpload', level: 'info' })
            .then((res) => {
                api.apiV0EventEventIdGet({ eventId: res.data.events[0].event_id }).then((res) => {
                    const url = res.data.artifact_path['edmond_config.json'];
                    const link = document.createElement('a');
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(() => null);
    };

    const handleDismiss = () => {
        const eventIds = props.selectedEventIds || [details.event_id];
        const promises = eventIds.map((eventId) =>
            api.apiV0EventEventIdTagPost({
                eventId: parseInt(eventId.toString()),
                eventTag: { name: CRASH_DISMISSED_TAG, action: EventTagActionEnum.Add },
            }),
        );
        Promise.all(promises).then(() => {
            setDetails((prev) => {
                if (details) {
                    return { ...details, tags: [...details.tags, CRASH_DISMISSED_TAG] };
                }
                return prev;
            });
            if (props.updateTable) {
                if (eventIds.length === 1) {
                    props.updateTable({ ...details, tags: [...details.tags, CRASH_DISMISSED_TAG] });
                } else {
                    props.updateTable(eventIds.map((id) => ({ event_id: id, tags: [CRASH_DISMISSED_TAG] })));
                }
            }
        });
    };

    if ((props.isVisible !== undefined && !props.isVisible) || (openInDialog && props.isLoading)) {
        return null;
    }

    const showMetadata = ALLOW_VIEW_METADATA.includes(profile.role) || isAdmin;

    const content = (
        <Box
            id="event-details"
            sx={{
                height: isMobile ? undefined : '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                    minHeight: 0,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    {!isMobile ? (
                        <IconButton id="options-btn" size="small" onClick={handleOptionsMenuClick}>
                            <MoreHorizIcon />
                        </IconButton>
                    ) : null}
                    {props.isVisible !== undefined && props.setIsVisible !== undefined ? (
                        <IconButton
                            id="close-btn"
                            size="small"
                            onClick={() => {
                                if (props.onClose) {
                                    props.onClose();
                                } else if (props.setIsVisible !== undefined) {
                                    props.setIsVisible(false);
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{t('content.events.details')}</Typography>
                </Box>
            </Box>
            <Menu
                id="options-menu"
                anchorEl={anchorElOptions}
                open={isOptionsOpen}
                onClose={handleOptionsMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {props.onEventsPage ? (
                    <MenuItem
                        id="open-in-layout-btn"
                        onClick={() => {
                            setOpenInDialog((prev) => {
                                localStorage.setItem('OE-event-dialog', (!prev).toString());
                                return !prev;
                            });
                            handleOptionsMenuClose();
                        }}
                    >
                        {openInDialog ? t('content.events.open_in_sidebar') : t('content.events.open_in_dialog')}
                    </MenuItem>
                ) : (
                    <MenuItem
                        id="open-in-page-btn"
                        onClick={() => {
                            history.push(`/events/${details.event_id}`);
                        }}
                    >
                        {t('content.events.open_in_events_page')}
                    </MenuItem>
                )}
            </Menu>
            <Box
                sx={{
                    minHeight: 0,
                    height: '100%',
                    overflowX: 'hidden',
                    overflowY: props.overflowY || 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%',
                        p: 1,
                        pb: !!props.overrideVideoUrl ? 0 : 1,
                    }}
                >
                    <EventActionsComponent
                        details={details}
                        setDetails={setDetails}
                        updateTable={props.updateTable}
                        allowEdit={allowEdit}
                        setAlert={props.setAlert}
                        showEditVideo={!!props.showEditVideo}
                        showFindVideo={!!props.showFindVideo}
                        showCoaching={props.showCoaching === undefined ? true : props.showCoaching}
                        showReportAccident={props.showReportAccident === undefined ? true : props.showReportAccident}
                        showEventRoute={props.showEventRoute}
                        showShareEvent
                        showChangeDriver
                        updateTripDetails={props.updateTripDetails}
                        handleDismiss={handleDismiss}
                        showCloneForm={showCloneForm}
                        setShowCloneForm={setShowCloneForm}
                        currentAccidentId={props.currentAccidentId}
                        driver={driver}
                    />
                    <div
                        style={{
                            position: 'relative',
                            width: props.videoWidth ? props.videoWidth : '100%',
                            aspectRatio: '8 / 3',
                        }}
                    >
                        <EventVideoComponent
                            eventId={details.event_id}
                            eventType={details.type}
                            setPercentVideoPlayed={props.setPercentVideoPlayed}
                            overlay={props.overlay}
                            gpsTrace={gpsTrace}
                            artifact={props.overrideVideoUrl ?? artifact}
                            setAllowEdit={setAllowEdit}
                            setCurrentLocation={setCurrentLocation}
                            setCurrentTime={setCurrentTime}
                            setVideoDuration={setVideoDuration}
                            isGeneratingVideo={details.event_description?.includes('original event id') && !artifact}
                            viewMode="pip"
                            showOriginalVideo={props.showOriginalVideo}
                            controls
                        />
                        <Collapse in={showCloneForm}>
                            <EventCloneComponent
                                duration={videoDuration}
                                currentTime={currentTime}
                                details={details}
                                artifact={artifact}
                                onClose={() => setShowCloneForm(false)}
                                setAlert={props.setAlert}
                                onEventsPage={props.onEventsPage}
                                setSelectedEventId={props.setSelectedEventId}
                            />
                        </Collapse>
                    </div>
                    {!props.videoOnly ? (
                        <EventDetailsSectionComponent
                            eventId={eventId}
                            isVisible={props.isVisible}
                            isLoading={props.isLoading}
                            onEventsPage={props.onEventsPage}
                            openInDialog={openInDialog}
                            fitBoundsCounter={fitBoundsCounter}
                            details={details}
                            gpsRoute={gpsRoute}
                            currentLocation={currentLocation}
                        />
                    ) : null}
                </Box>
                {(!openInDialog || isMobile) && !props.videoOnly ? (
                    <>
                        {props.onFilterChange !== undefined && (
                            <MuiAccordion
                                defaultExpanded
                                disableGutters
                                sx={{ borderLeft: 'none', borderRight: 'none' }}
                            >
                                <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} id="general-details-panel-header">
                                    <Typography>{t('content.events.more_events')}</Typography>
                                </MuiAccordionSummary>
                                <AccordionDetails sx={{ p: 1 }}>
                                    <RelatedEventsComponent
                                        eventId={eventId}
                                        eventDetails={details}
                                        onFilterChange={props.onFilterChange}
                                    />
                                </AccordionDetails>
                            </MuiAccordion>
                        )}
                        {showMetadata && (
                            <MuiAccordion
                                defaultExpanded
                                disableGutters
                                sx={{ borderLeft: 'none', borderRight: 'none' }}
                            >
                                <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} id="general-details-panel-header">
                                    <Typography>Metadata</Typography>
                                </MuiAccordionSummary>
                                <AccordionDetails sx={{ pr: 1.5 }}>
                                    <EventTaggingComponent
                                        {...props}
                                        tags={details.tags}
                                        details={details}
                                        getEventDetails={getEventDetails}
                                    />
                                    <Button
                                        id="get-config-btn"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={getConfig}
                                        sx={{ mt: 1 }}
                                    >
                                        Get Config
                                    </Button>
                                    <Box sx={{ overflow: 'auto' }}>
                                        <ul>
                                            {Object.entries(details.artifact_path).map(([filename, url]) => (
                                                <li key={filename}>
                                                    {/* id is the end of the filename, assumes the unique part ends with number */}
                                                    <Link
                                                        id={filename.replace(/.*\d_/, '')}
                                                        href={url}
                                                        title={filename}
                                                    >
                                                        {filename.length > 80 ? '...' + filename.substr(-79) : filename}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </Box>
                                </AccordionDetails>
                            </MuiAccordion>
                        )}
                    </>
                ) : null}
            </Box>
        </Box>
    );

    return openInDialog ? (
        <Dialog
            id="event-details-dialog"
            open={props.isVisible !== undefined ? props.isVisible : true}
            onClose={() => {
                if (props.setIsVisible !== undefined) {
                    props.setIsVisible(false);
                }
            }}
            fullScreen={isMobile}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '700px',
                        backgroundColor: 'bgColor.main',
                    },
                },
            }}
        >
            {content}
        </Dialog>
    ) : !props.videoOnly ? (
        <Resizable
            defaultSize={{ height: '100%', width: props.panelWidth || '30%' }}
            minWidth={400}
            maxWidth="50%"
            enable={{
                top: false,
                bottom: false,
                left: !isRTL,
                right: isRTL,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
            }}
            onResizeStop={(_event, _direction, ref) => {
                if (props.setPanelWidth) {
                    props.setPanelWidth(ref.clientWidth);
                }
                setFitBoundsCounter((prev) => prev + 1);
            }}
            style={{
                boxShadow:
                    '2px 0px 4px -1px rgba(0,0,0,0.2),4px 0px 5px 0px rgba(0,0,0,0.14),1px 0px 10px 0px rgba(0,0,0,0.12)',
            }}
        >
            {content}
        </Resizable>
    ) : (
        content
    );
};

export default EventDetailsComponent;
