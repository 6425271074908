export const INVALID_SPEED = 9999;

export interface GpsTrace {
    timestamp: number;
    lat: number;
    lng: number;
    speed: number;
}

export const IC_EVENTS_LIST: string[] = [
    'MsgEyesOffRoad',
    'MsgRecurringEOR',
    'MsgInCabinCameraCovered',
    'MsgDisconnectedCamera',
];

export const EVENT_TYPE_MAP: Record<string, string> = {
    MsgEyesOffRoad: 'content.events.types.eor',
    MsgTailgatingDetection: 'content.events.types.tailgate',
    MsgPublishVideo: 'content.events.types.continuous',
    MsgPublishBrokenVideo: 'content.events.types.broken_continuous',
    MsgDriverButtonPressed: 'content.events.types.button_pressed',
    MsgHardbrake: 'content.events.types.hard_brake',
    ManualEvent: 'content.events.types.manual_event',
    ManualClipRequest: 'content.events.types.clip_request',
    GeofenceEnter: 'content.events.types.geofence_enter',
    GeofenceLeave: 'content.events.types.geofence_leave',
    MsgRecurringTG: 'content.events.types.recurring_tailgate',
    MsgRecurringEOR: 'content.events.types.recurring_eyes_off_road',
    MsgImpact: 'content.events.types.impact',
    MsgCrashIncident: 'content.events.types.crash_incident',
    MsgCollisionTrackDetection: 'content.events.types.collision_track',
    MsgSpeedingDetection: 'content.events.types.speeding',
    MsgInCabinCameraCovered: 'content.events.types.in_cabin_camera_covered',
    MsgNearCollision: 'content.events.types.near_collision',
    ConfigUpload: 'ConfigUpload',
    MsgImproperShutdown: 'MsgImproperShutdown',
    MsgPublishDebugFiles: 'MsgPublishDebugFiles',
    MsgSkipFrames: 'MsgSkipFrames',
    MsgDisconnectedCamera: 'MsgDisconnectedCamera',
    MsgErrorCamera: 'MsgErrorCamera',
    MsgFrontFacingCamUnwell: 'MsgFrontFacingCamUnwell',
    MsgFrontFacingCamHigh: 'MsgFrontFacingCamHigh',
    MsgFrontFacingCamLow: 'MsgFrontFacingCamLow',
    MsgPOSTFailure: 'MsgPOSTFailure',
    MsgScheduledReboot: 'MsgScheduledReboot',
    MsgGetInternalDb: 'MsgGetInternalDb',
    MsgGetInternalFile: 'MsgGetInternalFile',
};
