import { Icon, PointTuple } from 'leaflet';
import React from 'react';
import { Marker } from 'react-leaflet';

import cameraBlockedIcon from '../../images/markers/camera_blocked.svg';
import collisionTrackIcon from '../../images/markers/ct.svg';
import driverPressedIcon from '../../images/markers/driver_pressed.svg';
import eorIcon from '../../images/markers/eor.svg';
import generalIcon from '../../images/markers/general.svg';
import geofenceEnterIcon from '../../images/markers/geofence_enter.svg';
import geofenceLeaveIcon from '../../images/markers/geofence_leave.svg';
import hardBrakeIcon from '../../images/markers/hard_brake.svg';
import impactIcon from '../../images/markers/impact.svg';
import noFaceIcon from '../../images/markers/no_face.svg';
import ridIcon from '../../images/markers/rid.svg';
import speedingIcon from '../../images/markers/speeding.svg';
import tgIcon from '../../images/markers/tg.svg';
import { isLocationValid, parseLocation } from '../../utils/location';

const iconSize = [50, 50];

export const eventTypeToIconMap: Record<string, string> = {
    MsgEyesOffRoad: eorIcon,
    MsgTailgatingDetection: tgIcon,
    MsgRecurringTG: ridIcon,
    MsgHardbrake: hardBrakeIcon,
    MsgDriverButtonPressed: driverPressedIcon,
    MsgImpact: impactIcon,
    MsgCrashIncident: impactIcon,
    MsgCameraBlocked: cameraBlockedIcon,
    MsgCameraNoFace: noFaceIcon,
    GeofenceEnter: geofenceEnterIcon,
    GeofenceLeave: geofenceLeaveIcon,
    MsgSpeedingDetection: speedingIcon,
    MsgCollisionTrackDetection: collisionTrackIcon,
};

export const eventTypeToIcon = (eventType: string): Icon => {
    let icon = generalIcon;
    if (eventType in eventTypeToIconMap) {
        icon = eventTypeToIconMap[eventType];
    }

    return new Icon({
        iconUrl: icon,
        iconAnchor: [25, 50],
        iconSize: iconSize as PointTuple,
        className: `${eventType}-map-marker`,
    });
};

export const eventToMarker = (location: string | undefined, type: string, idx: number): React.ReactNode => {
    return isLocationValid(location ?? '') ? (
        <Marker key={idx} position={parseLocation(location ?? '')} icon={eventTypeToIcon(type)} />
    ) : null;
};
