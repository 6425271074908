import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import CropFreeIcon from '@mui/icons-material/CropFree';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { Autocomplete, Box, Chip, CircularProgress, Dialog, Link, TextField, Typography, lighten } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { Descendant, Editor, Transforms, createEditor } from 'slate';
import { withReact } from 'slate-react';

import {
    ExtendedCustomerDataAllOfUsers,
    Malfunction,
    MalfunctionComment,
    MalfunctionPolicyCustomerTypeEnum,
    MalfunctionTypeEnum,
    UserRole,
} from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { flattenRoles, malfunctionsPageDefs } from '../../../utils/Pages';
import { hasTextValue } from '../../../utils/slate';
import palette from '../../ColorPalette';
import { EMPTY_VALUE } from '../Accident/AccidentComments';
import MentionsTextField, { withMentions } from '../Accident/MentionsTextField';
import { getUserAvatar } from '../Coaching/SessionDetails';
import { MALFUNCTION_ICONS } from './MalfunctionsOverview';
import SetupTool, { Modes, SETUP_TOOL_MODES } from './SetupTool/SetupTool';
import WellnessDetails from './WellnessDetails';

interface MalfunctionDetailsProps {
    malfunction: Malfunction;
    vehicleMalfunctions: Malfunction[];
    onClose: CallableFunction;
    users: ExtendedCustomerDataAllOfUsers[];
    setAlert: CallableFunction;
    isLoadingUsers: boolean;
    isErrorUsers: boolean;
}

const MalfunctionDetails: React.FC<MalfunctionDetailsProps> = (props: MalfunctionDetailsProps) => {
    const [editor] = useState<Editor>(() => withMentions(withReact(createEditor())));
    const [newComment, setNewComment] = useState<Descendant[]>(EMPTY_VALUE);
    const [comments, setComments] = useState<MalfunctionComment[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoadingAssignee, setIsLoadingAssignee] = useState<boolean>(false);
    const [editAssignee, setEditAssignee] = useState<boolean>(false);
    const [selectedAssignee, setSelectedAssignee] = useState<number | null>(props.malfunction.assignee);
    const [malfunctionImage, setMalfunctionImage] = useState<string>();
    const { modeStr } = useParams<{ modeStr: string }>();
    const { t } = useTranslation();
    const { profile } = useProfile();
    const isMobile = useIsMobile();
    const history = useHistory();
    const { api } = useApi();
    const queryClient = useQueryClient();

    const isSetupToolOpen = !!modeStr && SETUP_TOOL_MODES.includes(modeStr as Modes);

    const showLoadingState = props.isLoadingUsers || isLoading;
    const showErrorState = props.isErrorUsers || isError;

    const userList = props.users.map((u) => ({ id: u.user_id, name: u.name }));
    const userAvatarsMap: Record<number, string> = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        props.users.filter((u) => !!u.image_url).map((u) => [u.user_id, u.image_url!]),
    );
    const userById: Record<number, ExtendedCustomerDataAllOfUsers> = Object.fromEntries(
        props.users.map((u) => [u.user_id, u]),
    );

    const loadComments = () => {
        setIsLoading(true);
        setIsError(false);
        api.apiV0MalfunctionMalfunctionIdCommentGet({ malfunctionId: props.malfunction.id })
            .then((res) => {
                setComments(res.data);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(loadComments, []);

    useEffect(() => {
        if (!!modeStr && !SETUP_TOOL_MODES.includes(modeStr as Modes)) {
            history.replace(`/malfunctions/${props.malfunction.id}`);
        }
    }, [modeStr]);

    useEffect(() => {
        if ('image_path' in props.malfunction.metadata) {
            api.apiV0MalfunctionMalfunctionIdImageGet({ malfunctionId: props.malfunction.id }, { responseType: 'blob' })
                .then((res) => {
                    const url = URL.createObjectURL(res.data);
                    setMalfunctionImage(url);
                })
                .catch(() => {
                    // do nothing
                });
        } else {
            setMalfunctionImage(undefined);
        }
    }, [props.malfunction]);

    const handleClose = () => {
        props.onClose();
        history.push('/malfunctions');
    };

    const handleAssign = () => {
        setIsLoadingAssignee(true);
        api.apiV0MalfunctionPatch({
            malfunctionAssigneeRequest: { malfunctions: [props.malfunction.id], assignee: selectedAssignee },
        })
            .then(() => {
                queryClient.setQueryData<Malfunction[]>(['malfunctions'], (prev) =>
                    (prev || []).map((malfunction) => {
                        if (malfunction.id === props.malfunction.id) {
                            return {
                                ...malfunction,
                                assignee: selectedAssignee,
                            };
                        }
                        return malfunction;
                    }),
                );
                setIsLoadingAssignee(false);
                setEditAssignee(false);
            })
            .catch(() => {
                props.setAlert({
                    message: t('content.coach.errors.assign'),
                    type: 'error',
                    duration: 6000,
                });
                setIsLoadingAssignee(false);
            });
    };

    const postComment = (comment: Descendant[]) => {
        setIsLoadingAction(true);
        api.apiV0MalfunctionMalfunctionIdCommentPost({
            malfunctionId: props.malfunction.id,
            malfunctionCommentRequest: {
                text: comment,
            },
        })
            .then((res) => {
                Transforms.delete(editor, {
                    at: {
                        anchor: Editor.start(editor, []),
                        focus: Editor.end(editor, []),
                    },
                });
                setNewComment(EMPTY_VALUE);
                setComments((prev) => [...prev, res.data]);
                queryClient.setQueryData<Malfunction[]>(['malfunctions'], (prev) =>
                    (prev || []).map((malfunction) => {
                        if (malfunction.id === props.malfunction.id) {
                            return {
                                ...malfunction,
                                comment_count: malfunction.comment_count + 1,
                            };
                        }
                        return malfunction;
                    }),
                );
            })
            .catch(() =>
                props.setAlert({
                    message: t('content.malfunctions.details.error_sending_comment'),
                    type: 'error',
                    duration: 6000,
                }),
            )
            .finally(() => setIsLoadingAction(false));
    };

    const deviceWellnessSolution = (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
            <Typography>{t('content.malfunctions.details.camera_solution_1')}</Typography>
            <Button
                id="verify-btn"
                variant="contained"
                color="primary"
                startIcon={<CropFreeIcon />}
                onClick={() => {
                    history.push(`/malfunctions/${props.malfunction.id}/verify`);
                }}
                sx={{ my: 1 }}
            >
                {t('setup_tool.select_mode.verify_header')}
            </Button>
            <Button
                id="switch-btn"
                variant="contained"
                color="primary"
                startIcon={<AutorenewIcon />}
                onClick={() => {
                    history.push(`/malfunctions/${props.malfunction.id}/switch`);
                }}
            >
                {t('setup_tool.select_mode.switch_header')}
            </Button>
        </Box>
    );

    const solutions: Record<MalfunctionTypeEnum, React.ReactNode> = {
        [MalfunctionTypeEnum.FrontFacing]: deviceWellnessSolution,
        [MalfunctionTypeEnum.InCabinV1]: deviceWellnessSolution,
        [MalfunctionTypeEnum.MultipleButtonPresses]: (
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography>{t('content.malfunctions.details.button_solution_1')}</Typography>
                    <Typography sx={{ my: 1 }}>{t('content.malfunctions.details.button_solution_2')}</Typography>
                </Box>
            </Box>
        ),
        [MalfunctionTypeEnum.PendingInstallation]: (
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Button
                    id="install-btn"
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        history.push(`/malfunctions/${props.malfunction.id}/install`);
                    }}
                >
                    {t('setup_tool.select_mode.install_header')}
                </Button>
            </Box>
        ),
        [MalfunctionTypeEnum.PendingRemoval]: (
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Button
                    id="remove-btn"
                    variant="contained"
                    color="primary"
                    startIcon={<CloseIcon />}
                    onClick={() => {
                        history.push(`/malfunctions/${props.malfunction.id}/remove`);
                    }}
                >
                    {t('setup_tool.select_mode.remove_header')}
                </Button>
            </Box>
        ),
        [MalfunctionTypeEnum.WrongInstallation]: (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                <Typography>{t('content.malfunctions.details.wrong_installation_solution_1')}</Typography>
                <Button
                    id="adjust-btn"
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                        history.push(`/malfunctions/${props.malfunction.id}/adjust`);
                    }}
                >
                    {t('setup_tool.select_mode.adjust_header')}
                </Button>
            </Box>
        ),
        [MalfunctionTypeEnum.SystemConstantlyOn]: (
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography>{t('content.malfunctions.details.system_constantly_on_solution_1')}</Typography>
                    <Typography sx={{ my: 1 }}>
                        {t('content.malfunctions.details.system_constantly_on_solution_2')}
                    </Typography>
                </Box>
            </Box>
        ),
        [MalfunctionTypeEnum.WrongLicensePlate]: (
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography>{t('content.malfunctions.details.wrong_license_plate_solution_1')}</Typography>
                    <Typography sx={{ my: 1 }}>
                        {t('content.malfunctions.details.wrong_license_plate_solution_2')}
                    </Typography>
                </Box>
            </Box>
        ),
        [MalfunctionTypeEnum.InactiveDevice]: (
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography>{t('content.malfunctions.details.inacive_device_solution_1')}</Typography>
                    <Typography sx={{ my: 1 }}>
                        {t('content.malfunctions.details.inacive_device_solution_2')}
                    </Typography>
                    {deviceWellnessSolution}
                </Box>
            </Box>
        ),
    };

    let assignee;
    let assigneeAvatar;
    if (props.malfunction.assignee) {
        assignee = props.users.find((user) => user.user_id === props.malfunction.assignee);
        if (assignee !== undefined) {
            assigneeAvatar = getUserAvatar(assignee);
        }
    }

    const assigneeSection = (
        <>
            <Typography variant="overline" sx={{ mt: 2 }}>
                {t('content.malfunctions.table.assignee')}
            </Typography>
            {editAssignee ? (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Autocomplete
                        fullWidth
                        id="assignee-field"
                        data-testid="assignee-field"
                        size="small"
                        value={selectedAssignee}
                        onChange={(_event, newValue) => setSelectedAssignee(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{ ...params.inputProps, 'data-testid': 'assignee-field-input' }}
                                fullWidth
                            />
                        )}
                        getOptionLabel={(option) => userById[option]?.name || ''}
                        renderOption={(props, option) => {
                            const user = userById[option];
                            return (
                                <MenuItem
                                    id={`user-${user.user_id}-menu-item`}
                                    data-testid={`user-${user.user_id}-menu-item`}
                                    {...props}
                                    key={user.user_id}
                                >
                                    {getUserAvatar(user)}
                                    <Typography variant="body2">{user.name}</Typography>
                                </MenuItem>
                            );
                        }}
                        options={props.users
                            .filter(
                                (u) =>
                                    flattenRoles(malfunctionsPageDefs).includes(u.role) &&
                                    (!props.malfunction.sub_fleet ||
                                        !u.sub_fleet ||
                                        props.malfunction.sub_fleet === u.sub_fleet),
                            )
                            .map((u) => u.user_id)}
                    />
                    <Box sx={{ width: '40px', display: 'flex', justifyContent: 'center' }}>
                        {isLoadingAssignee ? (
                            <CircularProgress size={24} sx={{ ml: 1, color: '#707070' }} />
                        ) : (
                            <IconButton
                                id="btn-save-assignee"
                                data-testid="btn-save-assignee"
                                size="small"
                                onClick={() => handleAssign()}
                                sx={{ ml: 'auto' }}
                            >
                                <SaveIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '40px',
                        flexShrink: 0,
                    }}
                >
                    {assigneeAvatar}
                    {assignee !== undefined ? (
                        <Typography data-testid="assignee-field-value" data-value={assignee.user_id.toString()}>
                            {assignee.name}
                        </Typography>
                    ) : (
                        <Typography
                            data-testid="assignee-field-value"
                            data-value="unassigned"
                            sx={{ color: palette.neutral[500] }}
                        >
                            {t('content.malfunctions.unassigned')}
                        </Typography>
                    )}
                    <IconButton
                        id="btn-edit-assignee"
                        data-testid="btn-edit-assignee"
                        size="small"
                        onClick={() => setEditAssignee(true)}
                        sx={{ ml: 'auto' }}
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
            )}
        </>
    );

    const otherMalfunctions = props.vehicleMalfunctions.filter((m) => m.id !== props.malfunction.id);

    let commentsSection;
    if (showLoadingState || showErrorState) {
        commentsSection = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                {showLoadingState ? (
                    <CircularProgress />
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="overline" lineHeight={1}>
                            {t('content.malfunctions.details.error_loading_comments')}
                        </Typography>
                        <Link
                            id="try-again-comments-link"
                            component="button"
                            variant="overline"
                            lineHeight={1}
                            onClick={() => {
                                loadComments();
                            }}
                            sx={{ mt: 1.5 }}
                        >
                            {t('content.malfunctions.try_again')}
                        </Link>
                    </Box>
                )}
            </Box>
        );
    } else {
        const reversedComments = comments.sort((a, b) => b.timestamp - a.timestamp);
        const commentsByDate = reversedComments.reduce((acc, comment) => {
            const date = dayjs.unix(comment.timestamp).format(profile.shortDateFormat);
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(comment);
            return acc;
        }, {} as Record<string, MalfunctionComment[]>);

        commentsSection =
            reversedComments.length > 0 ? (
                Object.entries(commentsByDate).map(([date, commentsOnDate]) => {
                    return (
                        <React.Fragment key={date}>
                            {commentsOnDate.map((comment, index) => {
                                // check if previous comment is by the same author
                                const prevComment = commentsOnDate[index + 1];
                                const isSameAuthor = prevComment?.author === comment.author;
                                return (
                                    <MalfunctionCommentSpan
                                        key={comment.id}
                                        authorName={
                                            isSameAuthor
                                                ? ''
                                                : userList.find((u) => u.id === comment.author)?.name || ''
                                        }
                                        comment={comment}
                                    />
                                );
                            })}
                            <Box
                                data-date={date}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    position: 'sticky',
                                    top: 0,
                                    mt: 1,
                                }}
                            >
                                <Chip label={date} sx={{ backgroundColor: palette.neutral[200] }} />
                            </Box>
                        </React.Fragment>
                    );
                })
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography variant="overline">{t('content.malfunctions.details.no_comments')}</Typography>
                </Box>
            );
        const doneStr = t('content.malfunctions.details.fixed');
        const doneComment = [
            {
                type: 'paragraph',
                children: [
                    {
                        text: doneStr,
                    },
                ],
            },
        ];
        const lastComment = reversedComments[0];
        if (
            profile.role === UserRole.Technician &&
            (!lastComment ||
                lastComment.author !== profile.user_id ||
                !dayjs.unix(lastComment.timestamp).isSame(dayjs(), 'day') ||
                !isEqual(reversedComments[0]?.text, doneComment))
        ) {
            commentsSection = (
                <>
                    <Button
                        id="btn-malfunction-fixed"
                        variant="outlined"
                        disabled={isLoadingAction}
                        onClick={() => postComment(doneComment)}
                        sx={{ mt: 1 }}
                    >
                        {doneStr}
                    </Button>
                    {commentsSection}
                </>
            );
        }
    }

    const content = (
        <>
            {!!isSetupToolOpen ? (
                <SetupTool
                    malfunctionId={props.malfunction.id}
                    mode={modeStr as Modes}
                    imei={!!props.malfunction.device_id ? props.malfunction.device_id : undefined}
                    hardware={!!props.malfunction.hardware ? props.malfunction.hardware : undefined}
                    onClose={() => history.replace(`/malfunctions/${props.malfunction.id}`)}
                    policyId={props.malfunction.policy_id || undefined}
                    expectedLicensePlate={
                        props.malfunction.policy_customer_type !== MalfunctionPolicyCustomerTypeEnum.Pending
                            ? props.malfunction.license_plate
                            : undefined
                    }
                />
            ) : null}
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                    minHeight: 0,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="close-btn" size="small" onClick={() => handleClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{t('content.malfunctions.details.header')}</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    height: '100%',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1,
                }}
            >
                <Typography fontSize={12} sx={{ color: 'grayColor.main', fontWeight: 'bold' }}>
                    {t('content.malfunctions.table.malfunction')}
                </Typography>
                <Typography fontSize={16} sx={{ mb: 1 }}>
                    {t(`content.malfunctions.table.type.${props.malfunction.type.toLocaleLowerCase()}`)}
                </Typography>
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <Typography fontSize={12} sx={{ color: 'grayColor.main', fontWeight: 'bold' }}>
                            {t('content.malfunctions.table.license_plate')}
                        </Typography>
                        <Typography fontSize={16} sx={{ mb: 1 }}>
                            {props.malfunction.license_plate}
                        </Typography>
                    </Box>
                    {!!props.malfunction.sub_fleet ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <Typography fontSize={12} sx={{ color: 'grayColor.main', fontWeight: 'bold' }}>
                                {t('content.malfunctions.table.sub_fleet')}
                            </Typography>
                            <Typography fontSize={16} sx={{ mb: 1 }}>
                                {props.malfunction.sub_fleet}
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <Typography fontSize={12} sx={{ color: 'grayColor.main', fontWeight: 'bold' }}>
                            {t('content.malfunctions.table.since')}
                        </Typography>
                        <Typography fontSize={16}>
                            {dayjs.unix(props.malfunction.since).format(profile.dateTimeFormat)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <Typography fontSize={12} sx={{ color: 'grayColor.main', fontWeight: 'bold' }}>
                            {t('content.malfunctions.table.last_updated')}
                        </Typography>
                        <Typography fontSize={16}>
                            {dayjs.unix(props.malfunction.updated_at).format(profile.dateTimeFormat)}
                        </Typography>
                    </Box>
                </Box>
                {!!props.malfunction.metadata.wellness_id && !!props.malfunction.metadata.wellness_state ? (
                    <WellnessDetails
                        wellnessType={props.malfunction.type.toLocaleLowerCase()}
                        wellnessId={props.malfunction.metadata.wellness_id}
                        wellnessState={props.malfunction.metadata.wellness_state}
                        wellnessTimestamp={props.malfunction.metadata.wellness_timestamp}
                    />
                ) : null}
                {!!malfunctionImage ? (
                    <img
                        src={malfunctionImage}
                        alt="installation-image"
                        style={{ maxWidth: '250px', maxHeight: '300px' }}
                    />
                ) : null}
                {!!solutions[props.malfunction.type] ? (
                    <>
                        <Typography variant="overline" sx={{ mt: 2 }}>
                            {t('content.malfunctions.details.how_to_fix')}
                        </Typography>
                        {solutions[props.malfunction.type]}
                    </>
                ) : null}
                {assigneeSection}
                {otherMalfunctions.length > 0 ? (
                    <>
                        <Typography variant="overline" sx={{ mt: 2 }}>
                            {t('content.malfunctions.details.other_malfunctions')}
                        </Typography>
                        {otherMalfunctions.map((m) => (
                            <Link component={RouterLink} to={`/malfunctions/${m.id}`} key={m.id}>
                                <Box key={`other-${m.id}`} sx={{ display: 'flex', alignItems: 'center' }}>
                                    {MALFUNCTION_ICONS[m.type]}
                                    {t(`content.malfunctions.table.type.${m.type.toLocaleLowerCase()}`)}
                                </Box>
                            </Link>
                        ))}
                    </>
                ) : null}
                <Typography variant="overline" sx={{ mt: 2 }}>
                    {t('content.malfunctions.details.conversation')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box
                        id="comment-section"
                        sx={{
                            overflowY: 'scroll',
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            width: '100%',
                            height: '250px',
                            borderRadius: '4px',
                            border: '1px solid #bdbdbd',
                            p: 1,
                            mb: 1,
                        }}
                    >
                        {commentsSection}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <MentionsTextField
                            containerSelector={isMobile ? '[role=dialog]' : '#malfunction-details-panel'}
                            editor={editor}
                            setValue={setNewComment}
                            users={userList}
                            userAvatarsMap={userAvatarsMap}
                            disabled={showLoadingState || showErrorState || isLoadingAction}
                        />
                        <IconButton
                            id="btn-send-comment"
                            data-testid="btn-send-comment"
                            disabled={
                                showLoadingState || showErrorState || !hasTextValue(newComment) || isLoadingAction
                            }
                            onClick={() => postComment(newComment)}
                            sx={{ ml: '2px' }}
                        >
                            <SendIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </>
    );

    if (isMobile) {
        return (
            <Dialog
                open
                fullScreen
                onClose={() => handleClose()}
                PaperProps={{
                    style: {
                        backgroundColor: palette.neutral[50],
                    },
                }}
            >
                {content}
            </Dialog>
        );
    } else {
        return (
            <Box
                id="malfunction-details-panel"
                data-testid="malfunction-details-panel"
                data-malfunction-id={props.malfunction.id}
                sx={{
                    width: '350px',
                    height: '100%',
                    boxShadow: isMobile
                        ? '2px 0px 4px -1px rgba(0,0,0,0.2),4px 0px 5px 0px rgba(0,0,0,0.14),1px 0px 10px 0px rgba(0,0,0,0.12)'
                        : undefined,
                    backgroundColor: palette.bgColor,
                    flexShrink: 0,
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    zIndex: 10,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}
            >
                {content}
            </Box>
        );
    }
};

export default MalfunctionDetails;

interface MalfunctionCommentSpanProps {
    authorName: string;
    comment: MalfunctionComment;
    selfColor?: string;
    otherColor?: string;
}

export const MalfunctionCommentSpan: React.FC<MalfunctionCommentSpanProps> = (props: MalfunctionCommentSpanProps) => {
    const { comment } = props;
    const [editor] = useState<Editor>(() => withMentions(withReact(createEditor())));
    const { profile } = useProfile();
    const isSelf = comment.author === profile.user_id;
    const selfColor = props.selfColor || lighten(palette.accent, 0.8);
    const otherColor = props.otherColor || '#f9f9f9';
    return (
        <Box
            sx={{
                backgroundColor: isSelf ? selfColor : otherColor,
                borderRadius: '4px',
                mr: isSelf ? 0 : 'auto',
                ml: isSelf ? 'auto' : 0,
                boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25);',
                px: 1,
                py: 0.5,
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography fontSize={12} sx={{ color: palette.primary, fontWeight: 500 }}>
                {props.authorName}
            </Typography>
            <MentionsTextField initialValue={comment.text as Descendant[]} editor={editor} readOnly />
            <Typography fontSize={12} sx={{ ml: 'auto' }}>
                {dayjs.unix(comment.timestamp).format(profile.timeFormat)}
            </Typography>
        </Box>
    );
};
