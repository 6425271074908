export const normalizeString = (str: string): string => str.toString().replaceAll('-', '').toLocaleUpperCase();

const snakeToCamel = (str: string) => {
    return str.replace(/([-_]\w)/g, (matches) => matches[1].toUpperCase());
};

export const queryParamsToObject = (query: URLSearchParams) => {
    const obj: Record<string, string> = {};
    for (const [key, value] of query) {
        obj[snakeToCamel(key)] = value;
    }
    return obj;
};

export const normalizeLicensePlate = (licensePlate: string) =>
    licensePlate.toLocaleLowerCase().replaceAll('-', '').trim();

export const normalizeName = (driverName: string) => driverName.toLocaleLowerCase().trim();

export const formatPhoneNumber = (country: string, phoneNumber: string) => {
    if (phoneNumber === '') {
        return '';
    }
    if (country === 'il') {
        return phoneNumber.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    } else {
        return phoneNumber.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
};

export const unformatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D+/g, '');
};

export const b64DecodeUnicode = (str: string) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
        atob(str)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );
};
