import { Box, CircularProgress, Tabs, Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    HosDriver,
    HosUnassignedAssignmentRequest,
    HosUnassignedRecordSet,
    OverrideDriving,
    OverrideStop,
} from '../../../../backendsdk';
import { TabPanel, a11yProps } from '../../../../components/Tab';
import { TrackedLink as Link, TrackedTab as Tab } from '../../../../components/TrackedComponents';
import { useAlert } from '../../../../hooks/alert';
import useApi from '../../../../hooks/api';
import useProfile from '../../../../hooks/profile';
import { unassignedPageDefs } from '../../../../utils/Pages';
import { MINUTES_IN_HOUR, formatDayjs } from '../../../../utils/TimeFormatter';
import { DEFAULT_TITLE } from '../../../Layout';
import { offsetToTimezone } from '../Drivers/TerminalsDataGrid';
import ActionsMenu from './ActionsMenu';
import AnnotateModal from './AnnotateModal';
import AnnotatedDataGrid, { AnnotatedRow } from './AnnotatedDataGrid';
import AssignModal from './AssignModal';
import UnassignedDataGrid, { UnassignedRow } from './UnassignedDataGrid';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

interface UnassignedRecords extends HosUnassignedRecordSet {
    request?: HosUnassignedAssignmentRequest;
}

export const timestampToDateTimeString = (timestamp: number, locale: string, driver?: HosDriver) => {
    let date = dayjs.unix(timestamp);
    if (driver) {
        date = date.utcOffset(-driver.home_terminal.timezone_offset_from_utc);
    }
    return `${date.format(locale === 'il' ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY, LT')} ${
        offsetToTimezone[-date.utcOffset() / MINUTES_IN_HOUR] || `GMT${date.format('Z')}`
    }`;
};

const UnassignedComponent: React.FC = () => {
    const [fromDate, setFromDate] = useState<Dayjs>(dayjs().startOf('day').subtract(7, 'day'));
    const [toDate, setToDate] = useState<Dayjs>(dayjs().startOf('day'));
    const [drivers, setDrivers] = useState<Record<number, HosDriver>>({});
    const [records, setRecords] = useState<UnassignedRecords[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [isLoadingDrivers, setIsLoadingDrivers] = useState<boolean>(true);
    const [isLoadingRecords, setIsLoadingRecords] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tab, setTab] = useState<number>(0);
    const [assign, setAssign] = useState<boolean>(false);
    const [annotate, setAnnotate] = useState<boolean>(false);
    const { api, hosApi } = useApi();
    const { t } = useTranslation();
    const [alertElement, setAlert] = useAlert();
    const { profile } = useProfile();

    const isAnnotated = (record: UnassignedRecords) => {
        return record.annotated && !record.request;
    };

    const unassignedRows: UnassignedRow[] = records
        .filter((group) => !isAnnotated(group))
        .map((group) => {
            const roundedStartTime = Math.round(group.start_time / 60) * 60;
            const roundedEndTime = Math.round(group.end_time / 60) * 60;
            const driver = group.request?.driver_id ? drivers[group.request.driver_id] : undefined;
            return {
                id: group.records[0].id,
                device: group.device.license_plate,
                startTime: timestampToDateTimeString(roundedStartTime, profile.locale, driver),
                endTime: timestampToDateTimeString(roundedEndTime, profile.locale, driver),
                duration: roundedEndTime - roundedStartTime,
                driving_status: [
                    group.request?.override_driving || OverrideDriving.D,
                    group.request?.override_stop || OverrideStop.On,
                ],
                driver,
                status: group.request?.status || '',
            };
        });

    const annotatedRows: AnnotatedRow[] = records
        .filter((group) => isAnnotated(group))
        .map((group) => {
            const roundedStartTime = Math.round(group.start_time / 60) * 60;
            const roundedEndTime = Math.round(group.end_time / 60) * 60;
            return {
                id: group.records[0].id,
                device: group.device.license_plate,
                startTime: timestampToDateTimeString(roundedStartTime, profile.locale),
                endTime: timestampToDateTimeString(roundedEndTime, profile.locale),
                duration: roundedEndTime - roundedStartTime,
                annotation: group.comment || '',
            };
        });

    const handleAssign = (data: {
        driverId?: number;
        overrideDriving?: OverrideDriving;
        overrideStop?: OverrideStop;
        comment?: string;
    }) => {
        setIsLoading(true);
        const requestData = records
            .filter((group) => selectionModel.includes(group.records[0].id))
            .map((group) => ({
                id: group.request?.id,
                device_id: group.device.device_id,
                start_time: group.start_time,
                end_time: group.end_time,
                driver_id: data.driverId,
                override_driving: data.overrideDriving,
                override_stop: data.overrideStop,
                comment: data.comment,
            }));
        hosApi
            .hosV0RecordUnassignedPost({
                hosUnassignedAssignmentRequest: requestData,
            })
            .then(() => {
                setAssign(false);
                setAnnotate(false);
                setSelectionModel([]);
                getUnassignedRecords();
            })
            .catch(() => {
                setAlert({
                    message: `Failed to ${data.driverId ? 'assign' : 'annotate'} records`,
                    type: 'error',
                    duration: 6000,
                });
            })
            .finally(() => setIsLoading(false));
    };

    const getUnassignedRecords = (controller?: AbortController) => {
        setIsLoadingRecords(true);
        hosApi
            .hosV0RecordUnassignedDateFromDateToGet(
                { dateFrom: formatDayjs(fromDate), dateTo: formatDayjs(toDate) },
                { signal: controller?.signal },
            )
            .then((res) => {
                res.data = JSON.parse(JSON.stringify(res.data));
                const unassigned: UnassignedRecords[] = res.data.unassigned_records || [];
                const requests = res.data.assignment_requests || [];
                for (const request of requests) {
                    const record = unassigned.find(
                        (group) =>
                            group.device.device_id === request.device_id && group.start_time === request.start_time,
                    );
                    if (record) {
                        record.request = request;
                    }
                }
                setRecords(unassigned);
                setIsLoadingRecords(false);
            })
            .catch(() => {
                if (!controller?.signal.aborted) {
                    setIsError(true);
                    setIsLoadingRecords(false);
                }
            });
    };

    const getDrivers = () => {
        setIsLoadingDrivers(true);
        api.hosV0SettingsCustomerGet()
            .then((res) => {
                const allDrivers = res.data.drivers.map((driver: HosDriver) => {
                    return [driver.driver_id, driver];
                });
                setDrivers(Object.fromEntries(allDrivers));
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoadingDrivers(false));
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${unassignedPageDefs.name}`)}`;
    }, []);

    useEffect(() => getDrivers(), []);

    useEffect(() => {
        const controller = new AbortController();
        if (toDate.isSameOrAfter(fromDate, 'date')) {
            getUnassignedRecords(controller);
        }
        return () => controller.abort();
    }, [fromDate, toDate]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    let content = null;
    if (isLoadingDrivers || isLoadingRecords) {
        content = <CircularProgress data-testid="loading" />;
    } else if (isError) {
        content = (
            <Typography variant="overline">
                Error fetching unassigned records.{' '}
                <Link
                    id="try-again-link"
                    component="button"
                    variant="overline"
                    onClick={() => {
                        setIsError(false);
                        getUnassignedRecords();
                    }}
                >
                    Try again.
                </Link>
            </Typography>
        );
    } else {
        content = (
            <>
                <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        TabIndicatorProps={{ sx: { bgcolor: 'secondary.main', height: 3 } }}
                    >
                        <Tab label="Unassigned" {...a11yProps(0)} id="unassigned-tab" data-testid={`tab-0`} />
                        <Tab label="Annotated" {...a11yProps(1)} id="annotated-tab" data-testid={`tab-1`} />
                    </Tabs>
                    <ActionsMenu
                        disabled={selectionModel.length === 0}
                        tab={tab}
                        onAssign={() => setAssign(true)}
                        onAnnotate={() => setAnnotate(true)}
                    />
                </Box>
                <TabPanel value={tab} index={0} sx={{ height: '100%' }}>
                    {unassignedRows.length > 0 ? (
                        <UnassignedDataGrid
                            rows={unassignedRows}
                            selectionModel={selectionModel}
                            setSelectionModel={setSelectionModel}
                        />
                    ) : (
                        <Typography variant="overline">No unassigned records within the specified dates</Typography>
                    )}
                </TabPanel>
                <TabPanel value={tab} index={1} sx={{ height: '100%' }}>
                    {annotatedRows.length > 0 ? (
                        <AnnotatedDataGrid rows={annotatedRows} setSelectionModel={setSelectionModel} />
                    ) : (
                        <Typography variant="overline">No annotated records within the specified dates</Typography>
                    )}
                </TabPanel>
            </>
        );
    }

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '1200px', height: '100%', pb: 2 }}
        >
            {alertElement}
            {assign ? (
                <AssignModal
                    loading={isLoading}
                    drivers={Object.values(drivers)}
                    onAssign={handleAssign}
                    onClose={() => setAssign(false)}
                />
            ) : null}
            {annotate ? (
                <AnnotateModal loading={isLoading} onAnnotate={handleAssign} onClose={() => setAnnotate(false)} />
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                    mb: 1,
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="From"
                            format="MM/DD/YYYY"
                            value={fromDate}
                            onChange={(value: Dayjs | null) => {
                                if (value !== null) {
                                    setFromDate(value);
                                }
                            }}
                            maxDate={toDate}
                            slotProps={{ textField: { sx: { mr: 2 } } }}
                        />
                        <DatePicker
                            label="To"
                            format="MM/DD/YYYY"
                            value={toDate}
                            maxDate={dayjs().utc()}
                            onChange={(value: Dayjs | null) => {
                                if (value !== null) {
                                    setToDate(value);
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            {content}
        </Box>
    );
};

export default UnassignedComponent;
