import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import RouteIcon from '@mui/icons-material/Route';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Card, CircularProgress, LinearProgress, Skeleton, Tooltip, Typography, alpha } from '@mui/material';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useHistory } from 'react-router-dom';

import {
    CommandData,
    DeviceV3,
    ExtendedRouteData,
    ShortLocationSample,
    TripDetails,
    VideoPreviewRequest,
    VideoPreviewRequestStatusEnum,
} from '../../../backendsdk';
import ConfirmDialog from '../../../components/ConfirmDialog';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import { useImages } from '../../../hooks/images';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { SECONDS_IN_MINUTE } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import EventDetailsComponent from '../Event/EventDetails';
import EventVideoComponent from '../Event/EventVideo';
import MapRoute from '../Overview/MapRoute';
import ProgressModal from './ProgressModal';
import { getRequestEventId, getRequestStatus } from './RequestList';
import SelectSegment from './SelectSegment';

const EVENT_OVERLAY_HEIGHT = 340;
const PREVIEW_OVERLAY_HEIGHT = 288;
const BUTTON_HEIGHT = 36.5;

interface FindVideoMapInterface {
    isLoading: boolean;
    setIsLoading: CallableFunction;
    selectedTrip?: TripDetails;
    setSelectedTrip: CallableFunction;
    setSelectedRequest: CallableFunction;
    setRequests: CallableFunction;
    selectedRequest?: CommandData;
    selectSegment: boolean;
    setSelectSegment: CallableFunction;
    devices: DeviceV3[];
    setAlert: CallableFunction;
    setTrips: CallableFunction;
    accidentId?: number;
    associatedEvent?: number;
    onAssociateEvent?: CallableFunction;
    isSidePanelMinimized?: boolean;
    setInvalidateFunc?: CallableFunction;
    onCreateRequest?: CallableFunction;
    showDialog: boolean;
    setShowDialog: CallableFunction;
}

export const FindVideoMap: React.FC<FindVideoMapInterface> = (props: FindVideoMapInterface) => {
    const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(true);
    const [selectedEventId, setSelectedEventId] = useState<string>();
    const [tripRoute, setTripRoute] = useState<ExtendedRouteData>();
    const [previewRequest, setPreviewRequest] = useState<VideoPreviewRequest>();
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
    const [showProgressModal, setShowProgressModal] = useState<boolean>(false);
    const [isLoadingSaveAsEvent, setIsLoadingSaveAsEvent] = useState<boolean>(false);
    const [selectedTime, setSelectedTime] = useState<number[] | undefined>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [overlayHeight, setOverlayHeight] = useState<number>(0);
    const [percentVideoPlayed, setPercentVideoPlayed] = useState<number>(0);
    const [videoWatched, setVideoWatched] = useState(false);
    const [isConfirmPartialOpen, setIsConfirmPartialOpen] = useState(false);
    const [isConfirmTimestampOpen, setIsConfirmTimestampOpen] = useState(false);
    const [eventTimestamp, setEventTimestamp] = useState<number>();
    const { api } = useApi();
    const { profile } = useProfile();
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const timerRef = useRef<NodeJS.Timer>();
    const history = useHistory();

    const device = props.devices.find((device) => device.device.license_plate === props.selectedTrip?.license_plate);

    const getSnapshot = (timestamp: string) => {
        if (device) {
            return api.apiV0DeviceDeviceIdSnapshotGet(
                {
                    deviceId: device.device.device_id,
                    timestamp: parseInt(timestamp),
                    exact: 1,
                },
                { responseType: 'blob' },
            );
        }
        return Promise.reject();
    };
    const [images, addToQueue] = useImages(5, getSnapshot);

    const isCurrentTrip = !!props.selectedTrip && props.selectedTrip.end_time > dayjs().unix() - 15 * SECONDS_IN_MINUTE;

    const handleGeneratePreview = (isPartial: boolean) => {
        if (!props.selectedTrip) {
            return;
        }
        setIsLoadingAction(true);
        setShowProgressModal(true);
        api.apiV0VideoPreviewRequestPost({
            videoPreviewRequestBody: { trip_id: props.selectedTrip.id, partial: isPartial },
        })
            .then((res) => {
                setPreviewRequest(res.data);
            })
            .finally(() => setIsLoadingAction(false));
    };

    const handleSaveAsEventAndAssociate = async () => {
        if (!previewRequest) {
            return;
        }
        setIsLoadingSaveAsEvent(true);
        try {
            let eventId = previewRequest.event_id;
            if (!eventId) {
                const res = await api.apiV0VideoPreviewRequestEventPreviewRequestIdPost({
                    previewRequestId: previewRequest.id,
                });
                setPreviewRequest(res.data);
                eventId = res.data.event_id;
                setOverlayHeight(EVENT_OVERLAY_HEIGHT);
                setIsLoadingSaveAsEvent(false);
            }
            if (props.onAssociateEvent) {
                await props.onAssociateEvent(eventId);
            }
        } catch (error) {
            setIsLoadingSaveAsEvent(false);
            props.setAlert({
                message: 'content.find_video.save_as_event_failed',
                type: 'error',
                duration: 6000,
            });
        }
        setIsLoadingSaveAsEvent(false);
    };

    const handleSaveAsEvent = () => {
        if (!previewRequest) {
            return;
        }
        setIsLoadingSaveAsEvent(true);
        api.apiV0VideoPreviewRequestEventPreviewRequestIdPost({ previewRequestId: previewRequest.id })
            .then((res) => {
                setPreviewRequest(res.data);
                setOverlayHeight(EVENT_OVERLAY_HEIGHT);
                setIsLoadingSaveAsEvent(false);
            })
            .catch(() => {
                setIsLoadingSaveAsEvent(false);
                props.setAlert({
                    message: 'content.find_video.save_as_event_failed',
                    type: 'error',
                    duration: 6000,
                });
            });
    };

    const handleAssociate = async (timestamp?: number) => {
        if (!props.onAssociateEvent || !selectedEventId) {
            return;
        }
        setIsLoadingSaveAsEvent(true);
        await props.onAssociateEvent(parseInt(selectedEventId), timestamp);
        setIsLoadingSaveAsEvent(false);
    };

    const clearPreviewInterval = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = undefined;
        }
    };

    const updateTrip = (trip: TripDetails) => {
        props.setTrips((prev: TripDetails[] | undefined) => {
            if (!prev) {
                return prev;
            }
            return prev.map((t) => {
                if (t.id === trip.id) {
                    return trip;
                }
                return t;
            });
        });
    };

    const currentLocation = useMemo(() => {
        let currentLocation: ShortLocationSample | undefined;
        if (percentVideoPlayed !== undefined && !!props.selectedTrip && !!tripRoute && !!tripRoute.route.length) {
            const selectedRouteSegment =
                !!selectedTime && selectedTime.length > 0
                    ? tripRoute.route.filter((item: ShortLocationSample) => {
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          return item.timestamp >= selectedTime![0] && item.timestamp <= selectedTime![1];
                      })
                    : tripRoute.route;
            const selectedRouteStartTime = selectedRouteSegment[0]?.timestamp;
            const selectedRouteEndTime = selectedRouteSegment[selectedRouteSegment.length - 1]?.timestamp;
            if (!!selectedRouteStartTime && !!selectedRouteEndTime) {
                const time =
                    selectedRouteStartTime + (selectedRouteEndTime - selectedRouteStartTime) * percentVideoPlayed;
                let min = Infinity;
                let closest;
                for (const location of selectedRouteSegment) {
                    const dist = Math.abs(location.timestamp - time);
                    if (dist < min) {
                        min = dist;
                        closest = location;
                    }
                }
                currentLocation = closest;
            }
        }
        return currentLocation;
    }, [percentVideoPlayed]);

    useEffect(clearPreviewInterval, []);

    useEffect(() => {
        if (videoWatched) {
            mixpanel.track('Video watched', {
                type: 'preview-video',
                page: window.location.pathname,
            });
        }
    }, [videoWatched]);

    useEffect(() => {
        props.setIsLoading(true);
        props.setSelectSegment(false);
        clearPreviewInterval();
        setSelectedEventId(undefined);
        setPreviewRequest(undefined);
        setEventTimestamp(undefined);
        setVideoWatched(false);
        setIsModalOpen(true);
        if (!props.selectedTrip && !props.selectedRequest) {
            return;
        }
        const controller = new AbortController();
        if (!!props.selectedTrip) {
            const promises = [
                api
                    .apiV0VideoPreviewRequestTripIdGet({ tripId: props.selectedTrip.id }, { signal: controller.signal })
                    .then((res) => ({ previewRequest: res.data }))
                    .catch(() => ({ previewRequest: undefined })),
                api
                    .apiV2TripLicensePlateGet(
                        {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            licensePlate: props.selectedTrip.license_plate!,
                            tripId: props.selectedTrip.trip_id,
                            snapshots: 1,
                        },
                        { signal: controller.signal },
                    )
                    .then((res) => ({ tripRoute: res.data }))
                    .catch(() => ({ tripRoute: undefined })),
            ];
            // render api results together to avoid flickering
            Promise.allSettled(promises).then((results) => {
                if (controller.signal.aborted) {
                    return;
                }
                const [previewRequestResult, tripRouteResult] = results;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const previewRes = (previewRequestResult as any).value.previewRequest as
                    | VideoPreviewRequest
                    | undefined;
                setPreviewRequest(previewRes);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const tripRouteRes = (tripRouteResult as any).value.tripRoute as ExtendedRouteData | undefined;
                setTripRoute(tripRouteRes);
                setOverlayHeight(
                    (props.selectedRequest && getRequestStatus(props.selectedRequest) === 'success') ||
                        !!previewRes?.event_id
                        ? EVENT_OVERLAY_HEIGHT
                        : !!previewRes
                        ? PREVIEW_OVERLAY_HEIGHT
                        : BUTTON_HEIGHT,
                );
                setIsLoadingEvent(true);
                props.setIsLoading(false);
            });
        } else if (!!props.selectedRequest) {
            const data = JSON.parse(props.selectedRequest.data);
            const startTime = dayjs.unix(data.start_time).toISOString();
            const endTime = dayjs.unix(data.end_time).toISOString();
            const licensePlate = props.selectedRequest.device.license_plate;
            api.apiV2TripLicensePlateGet(
                {
                    licensePlate,
                    timeFrom: startTime,
                    timeTo: endTime,
                },
                { signal: controller.signal },
            )
                .then((res) => {
                    setTripRoute(res.data);
                    if (props.selectedRequest && getRequestStatus(props.selectedRequest) === 'success') {
                        setOverlayHeight(EVENT_OVERLAY_HEIGHT);
                    }
                })
                .catch(() => setTripRoute(undefined))
                .finally(() => props.setIsLoading(false));
        }
        return () => controller.abort();
    }, [props.selectedTrip?.trip_id]);

    useEffect(() => {
        if (props.selectedRequest) {
            if (getRequestStatus(props.selectedRequest) === 'success') {
                setIsLoadingEvent(true);
            }
            const payload = JSON.parse(props.selectedRequest.data);
            const startTime = payload.start_time;
            const endTime = payload.end_time;
            setSelectedTime([startTime, endTime]);
        } else {
            setSelectedTime(undefined);
        }
    }, [props.selectedRequest]);

    useEffect(() => {
        if (previewRequest?.status === VideoPreviewRequestStatusEnum.Pending) {
            const interval = setInterval(() => {
                api.apiV0VideoPreviewRequestTripIdGet({ tripId: previewRequest.trip_id }).then((res) => {
                    setPreviewRequest((prev) => {
                        const updatedPreviewRequest = res.data;
                        if (
                            prev?.status === VideoPreviewRequestStatusEnum.Pending &&
                            updatedPreviewRequest.status === VideoPreviewRequestStatusEnum.Failure
                        ) {
                            props.setAlert({
                                message: t('content.find_video.preview_failed'),
                                type: 'error',
                                duration: 6000,
                            });
                            setShowProgressModal(false);
                        } else if (updatedPreviewRequest.status === VideoPreviewRequestStatusEnum.Success) {
                            setShowProgressModal(false);
                        }
                        return updatedPreviewRequest;
                    });
                });
            }, 5000);
            timerRef.current = interval;
        } else {
            if (previewRequest?.status === VideoPreviewRequestStatusEnum.Success) {
                setOverlayHeight(previewRequest.event_id ? EVENT_OVERLAY_HEIGHT : PREVIEW_OVERLAY_HEIGHT);
            }
            clearPreviewInterval();
        }
        return () => clearPreviewInterval();
    }, [previewRequest?.status, api]);

    useEffect(() => {
        if (tripRoute?.snapshots && tripRoute.snapshots.length > 0) {
            const firstSnapshot = tripRoute.snapshots[0];
            const lastSnapshot = tripRoute.snapshots[tripRoute.snapshots.length - 1];
            addToQueue([firstSnapshot, lastSnapshot]);
        }
    }, [tripRoute?.snapshots]);

    const loadingSpinner = (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: palette.bgColor,
                opacity: 0.5,
                zIndex: 1002,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    );

    const eventDetailsProps = isMobile ? { isVisible: isModalOpen, setIsVisible: setIsModalOpen } : {};
    const showControls = !isMobile || !isLoadingEvent;
    let isBottomBar = false;

    const getEventSkeleton = (numberOfButtons: number) => {
        if (isMobile) {
            return null;
        }
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '40px',
                        backgroundColor: 'secondary.main',
                        pl: 1,
                    }}
                >
                    <Typography>{t('content.events.details')}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                    <Box sx={{ display: 'flex' }}>
                        {[...Array(numberOfButtons)].map((_, idx) => {
                            return (
                                <Skeleton
                                    key={idx}
                                    variant="rectangular"
                                    animation="wave"
                                    width={40}
                                    height={40}
                                    sx={{ borderRadius: '4px', mr: 1 }}
                                />
                            );
                        })}
                    </Box>
                    <Box sx={{ width: '500px', height: '227.5px', mt: 1 }}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{ width: '100%', height: '100%', display: 'block' }}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };

    const isSelectedEventAssociated = !!selectedEventId && props.associatedEvent?.toString() == selectedEventId;

    let overlay = null;
    let overlayContent = null;
    if (selectedEventId) {
        overlayContent = (
            <>
                {isLoadingEvent ? getEventSkeleton(props.onAssociateEvent ? 3 : 5) : null}
                <EventDetailsComponent
                    eventId={selectedEventId}
                    setAlert={props.setAlert}
                    isLoading={isLoadingEvent}
                    setIsLoading={setIsLoadingEvent}
                    onEventsPage={false}
                    videoOnly={true}
                    videoWidth={isMobile ? undefined : 500}
                    setSelectedEventId={setSelectedEventId}
                    showEditVideo
                    showCoaching={!props.onAssociateEvent}
                    showReportAccident={!props.onAssociateEvent}
                    showEventRoute={false}
                    updateTripDetails={updateTrip}
                    setEventTimestamp={setEventTimestamp}
                    showOriginalVideo
                    {...eventDetailsProps}
                />
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', p: 1, pt: 0 }}>
                    <Button
                        id="overlay-back-btn"
                        data-testid="overlay-back-btn"
                        variant="outlined"
                        color="primary"
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={() => {
                            setIsLoadingEvent(true);
                            setSelectedEventId(undefined);
                        }}
                    >
                        {t('content.find_video.back')}
                    </Button>
                    {!!props.onAssociateEvent ? (
                        <Button
                            id="associate-with-accident-btn"
                            data-testid="associate-with-accident-btn"
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={() => {
                                if (eventTimestamp) {
                                    setIsConfirmTimestampOpen(true);
                                } else {
                                    handleAssociate();
                                }
                            }}
                            disabled={isLoadingSaveAsEvent || isSelectedEventAssociated || !eventTimestamp}
                        >
                            {t(
                                `content.find_video.${
                                    isSelectedEventAssociated
                                        ? 'associated'
                                        : isLoadingSaveAsEvent
                                        ? 'associating'
                                        : 'associate'
                                }`,
                            )}
                        </Button>
                    ) : null}
                </Box>
            </>
        );
    } else if (!!props.selectedRequest && getRequestStatus(props.selectedRequest) === 'success') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const eventId = getRequestEventId(props.selectedRequest)!;
        overlayContent = (
            <>
                {isLoadingEvent ? getEventSkeleton(5) : null}
                <EventDetailsComponent
                    eventId={eventId}
                    setAlert={props.setAlert}
                    isLoading={isLoadingEvent}
                    setIsLoading={setIsLoadingEvent}
                    onEventsPage={false}
                    videoOnly={true}
                    videoWidth={isMobile ? undefined : 500}
                    setSelectedEventId={(eventId: number) => {
                        setSelectedEventId(eventId.toString());
                    }}
                    overlay={
                        !!currentLocation ? dayjs.unix(currentLocation.timestamp).format(profile.timeFormat) : undefined
                    }
                    showEditVideo
                    showOriginalVideo
                    onClose={() => {
                        props.setShowDialog(false);
                    }}
                    {...eventDetailsProps}
                />
                {showControls ? (
                    <Box sx={{ p: 1, pt: 0 }}>
                        <Button
                            id="overlay-back-btn"
                            data-testid="overlay-back-btn"
                            variant="outlined"
                            color="primary"
                            startIcon={<ArrowBackIosNewIcon />}
                            onClick={() => {
                                if (!!previewRequest?.event_id) {
                                    setIsLoadingEvent(true);
                                }
                                props.setSelectedRequest(undefined);
                                if (!props.selectedTrip) {
                                    setTripRoute(undefined);
                                }
                                // remove query params and keep url params
                                history.push(history.location.pathname);
                            }}
                        >
                            {t('content.find_video.back')}
                        </Button>
                    </Box>
                ) : null}
            </>
        );
    } else if (!!tripRoute && tripRoute.snapshots?.length === 0) {
        overlayContent = (
            <Button
                id="select-segment-btn"
                data-testid="select-segment-btn"
                variant="contained"
                color="secondary"
                startIcon={<RouteIcon />}
                onClick={() => props.setSelectSegment(true)}
            >
                {t('content.find_video.select_segment')}
            </Button>
        );
    } else if (previewRequest?.status === VideoPreviewRequestStatusEnum.Success && !!tripRoute) {
        if (
            (props.onAssociateEvent && props.associatedEvent === previewRequest.event_id) ||
            (!props.onAssociateEvent && !!previewRequest.event_id)
        ) {
            overlayContent = (
                <>
                    {isLoadingEvent ? getEventSkeleton(props.onAssociateEvent ? 3 : 4) : null}
                    <EventDetailsComponent
                        eventId={previewRequest.event_id.toString()}
                        setAlert={props.setAlert}
                        isLoading={isLoadingEvent}
                        setIsLoading={setIsLoadingEvent}
                        onEventsPage={false}
                        videoOnly={true}
                        videoWidth={isMobile ? undefined : 500}
                        overrideVideoUrl={previewRequest.video_url}
                        setSelectedEventId={setSelectedEventId}
                        setPercentVideoPlayed={setPercentVideoPlayed}
                        overlay={
                            !!currentLocation
                                ? dayjs.unix(currentLocation.timestamp).format(profile.timeFormat)
                                : undefined
                        }
                        showEditVideo
                        showCoaching={false}
                        showReportAccident={!props.onAssociateEvent}
                        updateTripDetails={updateTrip}
                        setEventTimestamp={setEventTimestamp}
                        showOriginalVideo
                        overflowY="hidden"
                        onClose={() => {
                            props.setShowDialog(false);
                        }}
                        {...eventDetailsProps}
                    />
                    {showControls ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: isMobile ? 'flex-end' : 'center',
                                justifyContent: 'flex-end',
                                p: 1,
                                pt: isLoadingEvent ? 0 : 1,
                            }}
                        >
                            <Button
                                id="saved-as-event-btn"
                                data-testid="saved-as-event-btn"
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                disabled
                            >
                                {t(`content.find_video.${!!props.onAssociateEvent ? 'associated' : 'saved_as_event'}`)}
                            </Button>
                            <Button
                                id="select-segment-btn"
                                data-testid="select-segment-btn"
                                variant="contained"
                                color="secondary"
                                startIcon={<RouteIcon />}
                                onClick={() => props.setSelectSegment(true)}
                                sx={{ ml: 1, mt: isMobile ? 1 : 0 }}
                            >
                                {t('content.find_video.select_segment')}
                            </Button>
                        </Box>
                    ) : null}
                </>
            );
        } else {
            overlayContent = (
                <>
                    <Box
                        sx={{
                            backgroundColor: 'secondary.main',
                            position: 'sticky',
                            top: 0,
                            p: 1,
                            zIndex: 3000,
                        }}
                    >
                        <Typography
                            sx={{
                                width: '90%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >{`${t(
                            `content.find_video.trip_preview${previewRequest.partial ? '_partial' : ''}`,
                        )}: ${`${dayjs.unix(tripRoute.trip?.start_time).format(profile.dateTimeFormat)} – ${dayjs
                            .unix(tripRoute.trip?.end_time)
                            .format(profile.dateTimeFormat)}`}`}</Typography>
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                right: 0,
                                top: 0,
                                mt: 0.5,
                                mr: 0.5,
                            }}
                        >
                            {previewRequest.partial ? (
                                <Tooltip title={t('content.find_video.refresh_preview')}>
                                    <IconButton
                                        id="refresh-preview-btn"
                                        onClick={() => handleGeneratePreview(isCurrentTrip)}
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            {isMobile ? (
                                <IconButton
                                    id="close-btn"
                                    size="small"
                                    onClick={() => {
                                        props.setShowDialog(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ) : null}
                        </Box>
                    </Box>
                    <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
                            {!!currentLocation ? (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        backgroundColor: alpha(palette.black, 0.3),
                                        p: 0.5,
                                        zIndex: 2,
                                    }}
                                >
                                    <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: palette.white }}>
                                        {dayjs.unix(currentLocation.timestamp).format(profile.timeFormat)}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                        <Box style={{ width: '100%', maxWidth: '500px' }}>
                            <EventVideoComponent
                                eventId={0}
                                eventType="timelapse"
                                controls
                                artifact={previewRequest.video_url}
                                viewMode={'vertical'}
                                showOriginalVideo
                                onLoadedMetadata={(e: React.SyntheticEvent<HTMLVideoElement>) => {
                                    setEventTimestamp(e.currentTarget.duration);
                                }}
                                onDurationChange={(e: React.SyntheticEvent<HTMLVideoElement>) => {
                                    setEventTimestamp(e.currentTarget.duration);
                                }}
                                onTimeUpdate={(e: React.SyntheticEvent<HTMLVideoElement>) => {
                                    if (e.currentTarget.duration) {
                                        const percentVideoPlayed =
                                            e.currentTarget.currentTime / e.currentTarget.duration;
                                        setPercentVideoPlayed(percentVideoPlayed);
                                        if (!videoWatched) {
                                            if (percentVideoPlayed >= 0.5) {
                                                setVideoWatched(true);
                                            }
                                        }
                                    }
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: isMobile ? 'flex-end' : 'center',
                                justifyContent: 'flex-end',
                                mt: 1,
                            }}
                        >
                            {isLoadingSaveAsEvent ? (
                                <Button
                                    id="saving-as-event-btn"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CircularProgress size={16} sx={{ color: '#a6a6a6' }} />}
                                    disabled
                                >
                                    {t(
                                        `content.find_video.${
                                            !!props.onAssociateEvent ? 'associating' : 'saving_as_event'
                                        }`,
                                    )}
                                </Button>
                            ) : (
                                <Button
                                    id="save-as-event-btn"
                                    data-testid="save-as-event-btn"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    disabled={!eventTimestamp}
                                    onClick={props.onAssociateEvent ? handleSaveAsEventAndAssociate : handleSaveAsEvent}
                                >
                                    {t(
                                        `content.find_video.${
                                            !!props.onAssociateEvent ? 'associate' : 'save_as_event'
                                        }`,
                                    )}
                                </Button>
                            )}
                            <Button
                                id="select-segment-btn"
                                data-testid="select-segment-btn"
                                variant="contained"
                                color="secondary"
                                startIcon={<RouteIcon />}
                                onClick={() => props.setSelectSegment(true)}
                                sx={{ ml: 1, mt: isMobile ? 1 : 0 }}
                            >
                                {t('content.find_video.select_segment')}
                            </Button>
                        </Box>
                    </Box>
                </>
            );
        }
    } else if (isLoadingAction || previewRequest?.status === VideoPreviewRequestStatusEnum.Pending) {
        isBottomBar = isMobile;
        overlayContent = (
            <Button
                id="btn-generating-preview"
                variant="contained"
                color="primary"
                disabled
                startIcon={<CircularProgress size={16} sx={{ color: '#a6a6a6' }} />}
            >
                {t('content.find_video.generating_preview')}
            </Button>
        );
    } else if (props.selectedTrip && !props.isLoading) {
        isBottomBar = isMobile;
        overlayContent = (
            <Button
                id="generate-preview-btn"
                data-testid="generate-preview-btn"
                variant="contained"
                color="primary"
                startIcon={<PlayArrowIcon />}
                onClick={() => {
                    if (isCurrentTrip) {
                        setIsConfirmPartialOpen(true);
                    } else {
                        handleGeneratePreview(false);
                    }
                }}
            >
                {t('content.find_video.generate_preview')}
            </Button>
        );
    }

    if (isMobile && props.showDialog && !!overlayContent) {
        if (isBottomBar) {
            return (
                <Box
                    sx={{
                        width: '100%',
                        position: 'absolute',
                        bottom: 0,
                        zIndex: 1003,
                        p: 1.25,
                        backgroundColor: palette.bgColor,
                        boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    {overlayContent}
                </Box>
            );
        } else {
            overlayContent = (
                <Dialog
                    id="overlay-dialog"
                    open={props.showDialog}
                    onClose={() => {
                        props.setShowDialog(false);
                    }}
                    fullScreen
                    sx={{
                        '& .MuiDialog-container': {
                            '& .MuiPaper-root': {
                                backgroundColor: 'bgColor.main',
                                height: '100%',
                            },
                        },
                    }}
                >
                    <Box sx={{ width: '100%', position: 'relative' }}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                position: 'absolute',
                                zIndex: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: 'secondary.main',
                                    height: '40px',
                                    width: '100%',
                                }}
                            ></Box>
                            <LinearProgress sx={{ width: '100%', height: '3px' }} />
                        </Box>
                        <Box sx={{ width: '100%', position: 'absolute', zIndex: 2, backgroundColor: palette.bgColor }}>
                            {overlayContent}
                        </Box>
                    </Box>
                </Dialog>
            );
        }
    }

    if (!!overlayContent && (!!tripRoute || !props.selectedTrip)) {
        overlay = (
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 10,
                    left: 10,
                    zIndex: !!props.selectedTrip ? 1001 : 1003,
                    maxWidth: 'calc(100% - 20px)',
                }}
            >
                <Card sx={{ width: '100%', height: '100%', backgroundColor: 'bgColor.main' }}>{overlayContent}</Card>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                }}
            >
                {!!tripRoute?.snapshots && !!tripRoute.snapshots.length && showProgressModal ? (
                    <ProgressModal numberOfFrames={tripRoute.snapshots.length} />
                ) : null}
                <ConfirmDialog
                    isDialogOpen={isConfirmPartialOpen}
                    isLoading={false}
                    onClose={() => setIsConfirmPartialOpen(false)}
                    onConfirm={() => {
                        setIsConfirmPartialOpen(false);
                        handleGeneratePreview(true);
                    }}
                    headerText={'content.find_video.confirm_partial_header'}
                    bodyText={['content.find_video.confirm_partial_body1', 'content.find_video.confirm_partial_body2']}
                    buttonText={'content.find_video.confirm'}
                    buttonColor="secondary"
                />
                <ConfirmDialog
                    isDialogOpen={isConfirmTimestampOpen}
                    isLoading={isLoadingSaveAsEvent}
                    headerText={'content.find_video.update_time_header'}
                    bodyText={'content.find_video.update_time_body'}
                    buttonText={'content.find_video.yes'}
                    cancelText={'content.find_video.no'}
                    buttonColor={'secondary'}
                    onClose={() => setIsConfirmTimestampOpen(false)}
                    onConfirm={() => handleAssociate(eventTimestamp)}
                    onCancel={() => handleAssociate()}
                />
                {props.isLoading ? loadingSpinner : null}
                {!isMobile ? (
                    <MapContainer
                        zoomSnap={0.5}
                        zoom={10}
                        center={[profile.customer.settings.default_lat, profile.customer.settings.default_lng]}
                        id="map-widget"
                        style={{ height: '100%', width: '100%' }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            className="leaflet-light"
                        />
                        <MapRoute
                            device={device}
                            selectedTrip={props.selectedTrip}
                            setSelectedTrip={props.setSelectedTrip}
                            selectedTime={selectedTime}
                            tripRoute={tripRoute}
                            isCurrentTrip={false}
                            setAlert={props.setAlert}
                            setIsMapLoading={props.setIsLoading}
                            setSelectedEventId={(eventId: string) => {
                                props.setSelectSegment(false);
                                setEventTimestamp(undefined);
                                setSelectedEventId(eventId);
                            }}
                            setIsLoadingEvent={setIsLoadingEvent}
                            overlayHeight={overlayHeight}
                            percentVideoPlayed={percentVideoPlayed}
                            isSelectSegment={props.selectSegment}
                            snapshotTimestamps={tripRoute?.snapshots}
                            currentLocation={currentLocation}
                            images={images}
                            addToQueue={addToQueue}
                            setInvalidateFunc={props.setInvalidateFunc}
                        />
                    </MapContainer>
                ) : null}
                {!!props.selectedTrip && !!tripRoute && props.selectSegment ? (
                    <SelectSegment
                        devices={props.devices}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        licensePlate={props.selectedTrip.license_plate!}
                        tripDetails={props.selectedTrip}
                        selectedRequest={props.selectedRequest}
                        setRequests={props.setRequests}
                        setAlert={props.setAlert}
                        updateMap={(startTime: number, endTime: number) => setSelectedTime([startTime, endTime])}
                        onBack={() => {
                            let selectedTime = undefined;
                            if (props.selectedRequest) {
                                const payload = JSON.parse(props.selectedRequest.data);
                                selectedTime = [payload.start_time, payload.end_time];
                            }
                            setSelectedTime(selectedTime);
                            if (previewRequest?.event_id) {
                                setIsLoadingEvent(true);
                            }
                            setOverlayHeight(previewRequest?.event_id ? EVENT_OVERLAY_HEIGHT : PREVIEW_OVERLAY_HEIGHT);
                            setPercentVideoPlayed(0);
                            props.setSelectSegment(false);
                        }}
                        onClose={() => props.setShowDialog(false)}
                        startSnapshot={
                            !!tripRoute?.snapshots && !!tripRoute.snapshots[0]
                                ? images[tripRoute.snapshots[0]] || 'loading'
                                : 'loading'
                        }
                        endSnapshot={
                            !!tripRoute?.snapshots && !!tripRoute.snapshots[tripRoute.snapshots.length - 1]
                                ? images[tripRoute.snapshots[tripRoute.snapshots.length - 1]] || 'loading'
                                : 'loading'
                        }
                        initialStartTime={currentLocation?.timestamp}
                        accidentId={props.accidentId}
                        onCreateRequest={props.onCreateRequest}
                    />
                ) : (
                    overlay
                )}
            </Box>
        </>
    );
};

export default FindVideoMap;
