import { Box, Card, Divider, FormControl, RadioGroup, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import {
    CoachMetricByType,
    CoachMetricDataPoint,
    CoachMetricsAdditionalDataEnum,
    CoachMetricsTimeFrameEnum,
    DeviceV3,
    ShortCoachSession,
} from '../../../backendsdk';
import { TrackedButton as Button, TrackedLink as Link } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { SECONDS_IN_DAY } from '../../../utils/TimeFormatter';
import { CustomFormControl } from '../Coaching/Agenda/MetricsForm';
import MetricsGraph from '../Coaching/Agenda/MetricsGraph';
import { ScoreAvatar } from '../Metrics/SafetyWellness/DriverList';
import { INVALID_SCORE, isScoreValid } from '../Metrics/SafetyWellness/MetricsComponent';
import { WellnessCardProps } from '../Wellness/WellnessCard';
import { getFines } from './FinesParser';
import NewCoachingMetricsModal from './NewCoachingMetricsModal';
import { VehicleCardProps } from './VehicleCard';

interface ScoresTabProps {
    scoreType: 'safety' | 'wellness';
    vehicle: VehicleCardProps | WellnessCardProps;
    device?: DeviceV3;
    metricData: CoachMetricByType;
    setAlert: CallableFunction;
    metadata?: string;
    coachingSessions?: ShortCoachSession[];
}

type TimeFrame = Exclude<CoachMetricsTimeFrameEnum, CoachMetricsTimeFrameEnum.Current>;

const ScoresTab: React.FC<ScoresTabProps> = (props: ScoresTabProps) => {
    const [timeFrame, setTimeFrame] = useState<TimeFrame>(CoachMetricsTimeFrameEnum.Week);
    const [additionalData, setAdditionalData] = useState<CoachMetricsAdditionalDataEnum>(
        CoachMetricsAdditionalDataEnum.None,
    );
    const [isSavingModalOpen, setIsSavingModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { api } = useApi();
    const { profile } = useProfile();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const relevantData = props.metricData[props.scoreType][timeFrame];
    let metricChartData;
    if (relevantData) {
        metricChartData = relevantData
            .sort((a, b) => a.timestamp - b.timestamp)
            .map((metric) => ({
                name: metric.timestamp,
                score: isScoreValid(metric.score) ? Math.round(metric.score) : null,
                metadata: metric.metadata,
                fleet_score: isScoreValid(metric.fleet_score) ? Math.round(metric.fleet_score) : null,
                sub_fleet_score: isScoreValid(metric.sub_fleet_score) ? Math.round(metric.sub_fleet_score) : null,
            }));
    }

    const hasSubFleet = !!props.vehicle.subFleet;
    let availableCurrentData: (keyof CoachMetricDataPoint)[] = ['score', 'sub_fleet_score', 'fleet_score'];
    let availablePastData = Object.values(CoachMetricsAdditionalDataEnum);
    if (!hasSubFleet) {
        availableCurrentData = availableCurrentData.filter((scoreType) => scoreType !== 'sub_fleet_score');
        availablePastData = availablePastData.filter((scoreType) => scoreType !== 'sub_fleet');
    }

    let scoreComponents;
    if (props.metadata) {
        try {
            const fines = getFines(props.metadata);
            const components: React.ReactNode[] = Object.entries(fines).map(([key, fine]) => {
                return (
                    <Typography data-testid={`${key}-fine-${fine}`} key={key} fontSize={14}>
                        {t(`content.safety.scores.components.point${fine > 1 ? 's' : ''}`, { fine })}{' '}
                        {t(`content.safety.scores.components.${key}_fine`)}
                    </Typography>
                );
            });

            if (components.length > 0) {
                scoreComponents = (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography variant="overline" sx={{ lineHeight: 1, mb: 1 }}>
                            {t('content.safety.scores.components.header')}
                        </Typography>
                        {components}
                    </Box>
                );
            }
        } catch (e) {
            scoreComponents = null;
        }
    }

    let relevantSessions;
    if (relevantData && relevantData.length > 0) {
        relevantSessions = (props.coachingSessions || []).filter(
            (session) =>
                !!session.completed_at &&
                session.completed_at > relevantData[0].timestamp &&
                session.completed_at < relevantData[relevantData.length - 1].timestamp + SECONDS_IN_DAY,
        );
    }

    const handleSave = (driverToken: number, chosenTime: string) => {
        setIsLoading(true);
        const chosenTimeFrame = chosenTime === 'current' ? 'current' : timeFrame;
        api.apiV0CoachMetricsPost({
            coachMetricsSaveRequest: {
                driver_token: driverToken,
                license_plate: props.vehicle.licensePlate,
                sub_fleet: props.vehicle.subFleet || '',
                score_type: props.scoreType,
                time_frame: chosenTimeFrame,
                additional_data: additionalData,
                metric_data: props.metricData[props.scoreType][chosenTimeFrame],
            },
        })
            .then((res) => {
                setIsLoading(false);
                setIsSavingModalOpen(false);
                const link = (
                    <Link
                        id="view-coaching-session-link"
                        component={RouterLink}
                        to={`/coaching/${res.data.session_id}`}
                    >
                        {t('content.safety.view_session')}
                    </Link>
                );
                props.setAlert({
                    message: t('content.safety.scores.save_for_coaching.success'),
                    link,
                    type: 'success',
                    duration: 6000,
                });
            })
            .catch(() => {
                setIsLoading(false);
                props.setAlert({
                    message: t('content.safety.scores.save_for_coaching.failure'),
                    type: 'error',
                    duration: 6000,
                });
            });
    };

    return (
        <Box sx={{ width: '100%', height: '100%', pt: 1 }}>
            {!!isSavingModalOpen ? (
                <NewCoachingMetricsModal
                    defaultDriver={
                        !!props.vehicle.driver
                            ? `${props.vehicle.driver.first_name} ${props.vehicle.driver.last_name}`
                            : undefined
                    }
                    driverToken={props.vehicle.driver?.token}
                    onSave={handleSave}
                    onClose={() => setIsSavingModalOpen(false)}
                    isLoading={isLoading}
                />
            ) : null}
            <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        width: '100%',
                        height: '100%',
                        flex: 1,
                    }}
                >
                    <Card
                        sx={{
                            width: isMobile ? '100%' : '300px',
                            height: isMobile ? undefined : '100%',
                            flexShrink: 0,
                            mr: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            p: 1,
                        }}
                    >
                        <Typography variant="overline" sx={{ lineHeight: 1 }}>
                            {`${t('content.safety.scores.current')} (${dayjs
                                .unix(props.metricData[props.scoreType].current[0].timestamp)
                                .format(profile.shortDateFormat)})`}
                        </Typography>
                        <Box sx={{ display: 'flex', width: '100%', mt: 2, mb: 3 }}>
                            {availableCurrentData.map((scoreType) => (
                                <Box
                                    key={scoreType}
                                    data-testid={`${scoreType.replaceAll('_', '-')}-current`}
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography fontSize={14}>{t(`content.safety.scores.${scoreType}`)}</Typography>
                                    <ScoreAvatar
                                        score={props.metricData[props.scoreType].current[0][scoreType] as number}
                                        inactive={
                                            props.metricData[props.scoreType].current[0].score === INVALID_SCORE &&
                                            props.metricData[props.scoreType].current[0].reason_for_no_score ===
                                                'inactive'
                                        }
                                        unknown={
                                            props.metricData[props.scoreType].current[0].score === INVALID_SCORE &&
                                            props.metricData[props.scoreType].current[0].reason_for_no_score ===
                                                'unknown'
                                        }
                                        size={35}
                                        fontSize={18}
                                    />
                                </Box>
                            ))}
                        </Box>
                        {scoreComponents}
                    </Card>
                    <Card
                        sx={{
                            flex: 1,
                            height: '100%',
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            p: 1,
                            mt: isMobile ? 1 : 0,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                width: isMobile ? '100%' : '160px',
                                flexShrink: 0,
                                pr: 1,
                            }}
                        >
                            <Typography variant="overline" sx={{ lineHeight: 1, mb: 2 }}>
                                {t('content.safety.scores.history')}
                            </Typography>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: isMobile ? 'row' : 'column' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        mb: 2,
                                    }}
                                >
                                    <Typography variant="overline" sx={{ fontSize: 10, lineHeight: 1.5 }}>
                                        {t('content.coach.add_item.metrics.time_frame')}
                                    </Typography>
                                    <FormControl sx={{ ml: 0.25 }}>
                                        <RadioGroup
                                            value={timeFrame}
                                            onChange={(e) => {
                                                setTimeFrame(e.target.value as TimeFrame);
                                            }}
                                        >
                                            {['week', 'month', 'quarter'].map((option) => (
                                                <CustomFormControl
                                                    key={option}
                                                    value={option}
                                                    label={t(`content.coach.add_item.metrics.${option}`)}
                                                    checked={timeFrame === option}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="overline"
                                        sx={{ fontSize: 10, lineHeight: 1.5, mt: isMobile ? 0 : 1.5 }}
                                    >
                                        {t('content.coach.add_item.metrics.additional_data')}
                                    </Typography>
                                    <FormControl sx={{ ml: 0.25 }}>
                                        <RadioGroup
                                            value={additionalData}
                                            onChange={(e) => {
                                                setAdditionalData(e.target.value as CoachMetricsAdditionalDataEnum);
                                            }}
                                        >
                                            {availablePastData.map((option) => (
                                                <CustomFormControl
                                                    key={option}
                                                    value={option}
                                                    label={t(`content.coach.add_item.metrics.${option}_score`)}
                                                    checked={additionalData === option}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 0 }} />
                        <Box sx={{ width: '100%', height: isMobile ? '300px' : '100%', p: 1 }}>
                            {!!metricChartData ? (
                                <MetricsGraph
                                    metricChartData={metricChartData}
                                    scoreType={props.scoreType}
                                    additionalData={additionalData}
                                    coachingSessions={relevantSessions}
                                    onScoreCard
                                />
                            ) : null}
                        </Box>
                    </Card>
                </Box>
                <Button
                    id="save-metrics-for-coaching"
                    data-testid="save-metrics-for-coaching"
                    variant="contained"
                    size="small"
                    color="neutral"
                    onClick={() => setIsSavingModalOpen(true)}
                    disabled={props.vehicle.driver === undefined}
                    sx={{ mt: 1, ml: 'auto' }}
                >
                    {t('content.safety.save')}
                </Button>
            </Box>
        </Box>
    );
};

export default ScoresTab;
