import { Box, Grid, TextField, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';

import { TrackedButton as Button } from '../../../../components/TrackedComponents';
import { shiftTimestamp, timestampWithoutLocalOffset } from '../../../../utils/TimeFormatter';
import { DriverRecord, RECORD_CODE, abbrMap } from '../Defs';

interface EditComponentProps {
    now: Dayjs;
    date: Dayjs;
    utcOffset: number;
    records: DriverRecord[];
    setEditPreview: CallableFunction;
    onSave: CallableFunction;
    onClose: CallableFunction;
}

interface Timespan {
    start: Dayjs | null;
    end: Dayjs | null;
}

const EditComponent: React.FC<EditComponentProps> = (props: EditComponentProps) => {
    const [timespan, setTimespan] = useState<Timespan>({ start: null, end: null });
    const [recordCode, setRecordCode] = useState<RECORD_CODE>();
    const [comment, setComment] = useState<string>('');
    const [commentError, setCommentError] = useState<string>('');

    let startTimestamp: number, endTimestamp: number;
    let containsDriving = false;
    let isTimeInvalid = false;
    if (timespan.start !== null && timespan.end !== null && timespan.start.isValid() && timespan.end.isValid()) {
        startTimestamp = timestampWithoutLocalOffset(timespan.start);
        endTimestamp = timestampWithoutLocalOffset(timespan.end);
        const recordsInEditSpan: DriverRecord[] = props.records.filter(
            (record) =>
                record.event_datetime > shiftTimestamp(startTimestamp, props.utcOffset) &&
                record.event_datetime < shiftTimestamp(endTimestamp, props.utcOffset),
        );
        // cover cases where driving started before edit time range and continues within that range
        const firstRecordBefore = [...props.records]
            .reverse()
            .find((record) => record.event_datetime <= shiftTimestamp(startTimestamp, props.utcOffset));
        if (firstRecordBefore !== undefined) {
            recordsInEditSpan.unshift(firstRecordBefore);
        }
        containsDriving = recordsInEditSpan.some(
            (record) => record.record_code === RECORD_CODE.DRIVERS_DUTY_STATUS_CHANGED_TO_DRIVING,
        );
        isTimeInvalid =
            shiftTimestamp(endTimestamp, props.utcOffset) > props.now.unix() || startTimestamp >= endTimestamp;
    }
    const validEdit =
        timespan.start !== null &&
        timespan.end !== null &&
        timespan.start.isValid() &&
        timespan.end.isValid() &&
        !containsDriving &&
        !isTimeInvalid &&
        recordCode !== undefined;

    const validateComment = () => {
        if (comment.length >= 4 && comment.length <= 60) {
            setCommentError('');
            return true;
        }
        setCommentError('Comment must be between 4 and 60 characters');
        return false;
    };

    const handleTimeChange = (field: 'start' | 'end', newValue: Dayjs) => {
        setTimespan((prev: Timespan) => {
            const updated = { ...prev };
            let newValueWithDate = newValue
                .set('year', props.date.get('year'))
                .set('month', props.date.get('month'))
                .set('date', props.date.get('date'))
                .set('second', 0);
            if (field === 'end' && newValue.isSame(newValue.startOf('day'), 'minute')) {
                newValueWithDate = newValueWithDate.add(1, 'day');
            }
            updated[field] = newValueWithDate;
            return updated;
        });
    };

    useEffect(() => {
        if (
            timespan.start !== null &&
            timespan.end !== null &&
            timespan.start.isValid() &&
            timespan.end.isValid() &&
            !isTimeInvalid
        ) {
            props.setEditPreview({
                id: 0,
                startTime: shiftTimestamp(startTimestamp, props.utcOffset),
                endTime: shiftTimestamp(endTimestamp, props.utcOffset),
                recordCode: recordCode,
                containsDriving,
            });
        } else {
            props.setEditPreview(undefined);
        }
    }, [timespan, recordCode]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Grid container columns={4} width={350} spacing={1}>
                <Grid item xs={4} sx={{ mb: 1 }} textAlign="center">
                    <Typography variant="overline">Time</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TimePicker
                        label="Start time"
                        value={timespan.start}
                        onChange={(value: Dayjs | null) => {
                            if (value !== null) {
                                handleTimeChange('start', value);
                            }
                        }}
                        slotProps={{ textField: { error: isTimeInvalid || containsDriving } }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TimePicker
                        label="End time"
                        value={timespan.end}
                        onChange={(value: Dayjs | null) => {
                            if (value !== null) {
                                handleTimeChange('end', value);
                            }
                        }}
                        slotProps={{ textField: { error: isTimeInvalid || containsDriving } }}
                    />
                </Grid>
                {isTimeInvalid ? (
                    <Grid item xs={4} sx={{ mt: 1 }} textAlign="center">
                        <Typography variant="body2" sx={{ color: 'error.main' }}>
                            Please enter a valid time span
                        </Typography>
                    </Grid>
                ) : null}
                {containsDriving ? (
                    <Grid item xs={4} sx={{ mt: 1 }}>
                        <Typography variant="body2" sx={{ color: 'error.main' }}>
                            You cannot edit recorded driving time
                        </Typography>
                    </Grid>
                ) : null}
                <Grid item xs={4} sx={{ mt: 1 }} textAlign="center">
                    <Typography variant="overline">New Status</Typography>
                </Grid>
                {Object.keys(abbrMap).map((key) => {
                    return (
                        <Grid item key={key} xs={1}>
                            <Button
                                id={`record-code-${key}`}
                                variant={
                                    recordCode == RECORD_CODE[key as keyof typeof RECORD_CODE]
                                        ? 'contained'
                                        : 'outlined'
                                }
                                color={
                                    recordCode == RECORD_CODE[key as keyof typeof RECORD_CODE] ? 'secondary' : 'primary'
                                }
                                fullWidth
                                sx={{ fontSize: '1.5rem', width: '80px', aspectRatio: '1 / 1' }}
                                onClick={() => setRecordCode(RECORD_CODE[key as keyof typeof RECORD_CODE])}
                            >
                                {abbrMap[key as keyof typeof RECORD_CODE]}
                            </Button>
                        </Grid>
                    );
                })}
                <Grid item xs={4} sx={{ mt: 3 }}>
                    <TextField
                        value={comment}
                        error={!!commentError}
                        helperText={commentError}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
                        label="Comment"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2} sx={{ mt: 3 }}>
                    <Button
                        id="btn-cancel-edit"
                        variant="contained"
                        fullWidth
                        sx={{ mr: 1 }}
                        onClick={() => {
                            props.onClose();
                            setTimespan({ start: null, end: null });
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={2} sx={{ mt: 3 }}>
                    <Button
                        id="btn-save-edit"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={!validEdit}
                        onClick={() => {
                            if (validateComment()) {
                                props.onSave({
                                    id: 0,
                                    startTime: startTimestamp,
                                    endTime: endTimestamp,
                                    recordCode,
                                    comment,
                                });
                            }
                        }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EditComponent;
