import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CoachMetrics, CoachMetricsAdditionalDataEnum } from '../../../../backendsdk';
import useProfile from '../../../../hooks/profile';
import { ScoreAvatar } from '../../Metrics/SafetyWellness/DriverList';
import { INVALID_SCORE } from '../../Metrics/SafetyWellness/MetricsComponent';
import MetricsGraph from '../Agenda/MetricsGraph';

export interface CoachingMetricsSlideProps {
    metrics: CoachMetrics;
}

const CoachingMetricsSlide: React.FC<CoachingMetricsSlideProps> = ({ metrics }: CoachingMetricsSlideProps) => {
    const { t } = useTranslation();
    const { profile } = useProfile();
    const metricChartData = metrics.metric_data
        .sort((a, b) => a.timestamp - b.timestamp)
        .map((metric) => ({
            name: metric.timestamp,
            score: metric.score !== INVALID_SCORE ? Math.round(metric.score) : null,
            metadata: metric.metadata,
            fleet_score: metric.fleet_score !== INVALID_SCORE ? Math.round(metric.fleet_score) : null,
            sub_fleet_score: metric.sub_fleet_score !== INVALID_SCORE ? Math.round(metric.sub_fleet_score) : null,
        }));
    const reasonForNoScore = metrics.metric_data[0].reason_for_no_score;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'auto',
                px: 4,
                py: 2,
            }}
        >
            {metrics.time_frame === 'current' ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography data-testid="metrics-header" fontSize={16} textAlign="center">
                                {t('content.coach.add_item.metrics.metric_header_score', {
                                    score_type: t(
                                        `content.coach.add_item.metrics.${metrics.score_type}_score_definite`,
                                    ),
                                })}
                            </Typography>
                            <Typography fontSize={16} textAlign="center" sx={{ mb: 1 }}>
                                {t('content.coach.add_item.metrics.metric_header_date', {
                                    date_from: dayjs.unix(metricChartData[0].name).format(profile.dateFormat),
                                })}
                            </Typography>
                            <ScoreAvatar
                                score={metricChartData[0].score !== null ? metricChartData[0].score : INVALID_SCORE}
                                size={72}
                                fontSize={40}
                                inactive={reasonForNoScore === 'inactive'}
                                unknown={reasonForNoScore === 'unknown'}
                            />
                        </Box>
                        {metrics.additional_data !== CoachMetricsAdditionalDataEnum.None ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    ml: 2,
                                }}
                            >
                                <Typography fontSize={16} textAlign="center">
                                    {t(
                                        `content.coach.add_item.metrics.metric_header_${metrics.additional_data}_score`,
                                        {
                                            score_type: t(
                                                `content.coach.add_item.metrics.${metrics.score_type}_score_definite`,
                                            ),
                                        },
                                    )}
                                </Typography>
                                <Typography fontSize={16} textAlign="center" sx={{ mb: 1 }}>
                                    {t('content.coach.add_item.metrics.metric_header_date', {
                                        date_from: dayjs.unix(metricChartData[0].name).format(profile.dateFormat),
                                    })}
                                </Typography>
                                <ScoreAvatar
                                    score={
                                        metricChartData[0][`${metrics.additional_data}_score`] !== null
                                            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                              metricChartData[0][`${metrics.additional_data}_score`]!
                                            : INVALID_SCORE
                                    }
                                    size={72}
                                    fontSize={40}
                                />
                            </Box>
                        ) : null}
                    </Box>
                    <Typography data-testid="metrics-footer" fontSize={14} sx={{ mt: 1.5 }}>
                        {t(`content.metrics.${metrics.score_type}_tooltip`)}
                    </Typography>
                </Box>
            ) : (
                <MetricsGraph
                    metricChartData={metricChartData}
                    scoreType={metrics.score_type}
                    additionalData={metrics.additional_data}
                    isPresenting={true}
                />
            )}
        </Box>
    );
};

export default CoachingMetricsSlide;
