import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EventDetails, EventOverlayRequest, EventOverlayRequestLanguageEnum } from '../../../backendsdk';
import { TrackedButton as Button, TrackedDialog as Dialog } from '../../../components/TrackedComponents';
import useProfile from '../../../hooks/profile';
import { isEventVideo } from '../../../utils/File';
import { RTLDirectionContext } from '../../Layout';

const AUDIO_FILE_LIST = [
    'accident_detected.mp3',
    'collision_warning_beep_loud.mp3',
    'collision_warning_beep.mp3',
    'collision_warning.mp3',
    'eld_disconnect_issue.mp3',
    'eld_log_in.mp3',
    'eld_malfunction_detected.mp3',
    'eld_time_exceeded.mp3',
    'eld_violation_alert.mp3',
    'eor_rid.mp3',
    'event_reported.mp3',
    'ff_calibration.mp3',
    'ff_malfunction.mp3',
    'ff_too_high.mp3',
    'ff_too_low.mp3',
    'ff_wellness.mp3',
    'following_too_close.mp3',
    'hard_acceleration.mp3',
    'hard_brake.mp3',
    'hard_cornering.mp3',
    'ic_malfunction.mp3',
    'identification_succeeded.mp3',
    'in_cabin_camera_blocked.mp3',
    'in_cabin_sleep.mp3',
    'in_cabin_wake_up.mp3',
    'look_at_the_road.mp3',
    'no_face.mp3',
    'pedestrian_ahead.mp3',
    'peripheral_face.mp3',
    'please_identify.mp3',
    'safe_ride.mp3',
    'slow_down.mp3',
    'system_sleep.mp3',
    'system_wake_up.mp3',
    'tg_rid.mp3',
    'wake_up.mp3',
] as const;
type AudioFile = typeof AUDIO_FILE_LIST[number];

interface ChangeDriverDialogProps {
    details: EventDetails;
    onClose: CallableFunction;
    onSuccess: (eventOverlayRequest: EventOverlayRequest) => void;
}

const CreateOverlayDialog: React.FC<ChangeDriverDialogProps> = (props) => {
    const { profile } = useProfile();
    const { t } = useTranslation();
    const [recipients, setRecipients] = useState<string>(profile.email);
    const [firstFrame, setFirstFrame] = useState<string>(props.details.start_frame_id?.toString() || '0');
    const [delaySeconds, setDelaySeconds] = useState<string>('15');
    const [language, setLanguage] = useState<EventOverlayRequestLanguageEnum>(EventOverlayRequestLanguageEnum.EnglishF);
    const [audio, setAudio] = useState<AudioFile>(AUDIO_FILE_LIST[0]);
    const isRTL = useContext(RTLDirectionContext);

    const artifact = props.details.artifact_path[Object.keys(props.details.artifact_path).filter(isEventVideo)[0]];

    const isDisabled =
        parseInt(delaySeconds).toString() != delaySeconds || parseInt(firstFrame).toString() != firstFrame;

    return (
        <Dialog id="create-overlay-dialog" open={true} onClose={() => props.onClose()} maxWidth="xs" fullWidth>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    m: 2,
                }}
            >
                <Typography data-testid="create-overlay-dialog-title" textAlign="center" variant="h6" sx={{ mb: 2 }}>
                    {t('content.events.create_overlay.header')}
                </Typography>
                <TextField
                    id="recipients"
                    label={t('content.events.create_overlay.recipients')}
                    value={recipients}
                    onChange={(e) => setRecipients(e.target.value)}
                    fullWidth
                />
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        width: '100%',
                    }}
                >
                    <TextField
                        id="first_frame_number"
                        label={t('content.events.create_overlay.first_frame')}
                        value={firstFrame}
                        onChange={(e) => setFirstFrame(e.target.value)}
                    />
                    <Box
                        sx={{
                            width: '50%',
                            overflow: 'hidden',
                            display: 'block',
                            height: '56px',
                            border: '1px solid #c4c4c4',
                            borderRadius: '4px',
                            direction: isRTL ? 'rtl' : 'ltr',
                        }}
                    >
                        <video
                            width="1920"
                            src={artifact}
                            style={{
                                objectPosition: '0px -4px',
                            }}
                        />
                    </Box>
                </Box>
                <TextField
                    id="delay"
                    label={t('content.events.create_overlay.delay')}
                    value={delaySeconds}
                    onChange={(e) => setDelaySeconds(e.target.value)}
                    fullWidth
                />
                <Autocomplete
                    id="language"
                    data-testid="language"
                    value={language}
                    renderInput={(params) => (
                        <TextField {...params} label={t('content.events.create_overlay.language.header')} />
                    )}
                    onChange={(_e, value) => setLanguage(value)}
                    options={Object.values(EventOverlayRequestLanguageEnum)}
                    getOptionLabel={(option) => t(`content.events.create_overlay.language.${option}`)}
                    renderOption={(props, option) => (
                        <li {...props} data-testid={`language-field-${option}`}>
                            <Typography sx={{ pt: 0.5 }}>
                                {t(`content.events.create_overlay.language.${option}`)}
                            </Typography>
                        </li>
                    )}
                    disableClearable
                />
                <Autocomplete
                    id="audio"
                    data-testid="audio"
                    value={audio}
                    renderInput={(params) => <TextField {...params} label={t('content.events.create_overlay.audio')} />}
                    onChange={(_e, value) => setAudio(value)}
                    options={[...AUDIO_FILE_LIST].sort()}
                    getOptionLabel={(option) => option.split('.')[0].replaceAll('_', ' ')}
                    renderOption={(props, option) => (
                        <li {...props} data-testid={`audio-field-${option}`}>
                            <Typography sx={{ pt: 0.5 }}>{option.split('.')[0].replaceAll('_', ' ')}</Typography>
                        </li>
                    )}
                    disableClearable
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        id="btn-create-overlay"
                        data-testid="btn-create-overlay"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            props.onSuccess({
                                event_id: props.details.event_id,
                                first_frame_number: parseInt(firstFrame),
                                delay_s: parseInt(delaySeconds),
                                recipients,
                                language,
                                audio,
                            })
                        }
                        sx={{ ml: 1 }}
                        disabled={isDisabled}
                    >
                        {t('content.events.create_overlay.header')}
                    </Button>
                    <Button
                        data-testid="btn-cancel"
                        id="btn-cancel"
                        variant="contained"
                        onClick={() => props.onClose()}
                        sx={{ ml: 1 }}
                    >
                        {t('content.trip.cancel')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default CreateOverlayDialog;
