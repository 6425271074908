import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Typography } from '@mui/material';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import { useImages } from '../../../hooks/images';
import imageNA from '../../../images/image_not_available.png';
import { RTLDirectionContext } from '../../Layout';
import WellnessCard, { CARD_HEIGHT, CARD_MARGIN } from './WellnessCard';
import { WellnessCardProps } from './WellnessCard';
import WellnessModal from './WellnessModal';

export const NAV_BUTTON_WIDTH = 50;

const getImageKey = (card: WellnessCardProps, mostRecentEventId: number) => {
    if (card.icStatusIds.includes(mostRecentEventId)) {
        return `in_cabin_v1:${mostRecentEventId}`;
    } else if (card.ffStatusIds.includes(mostRecentEventId)) {
        return `front_facing:${mostRecentEventId}`;
    } else {
        return `event:${mostRecentEventId}`;
    }
};

export interface WellnessGridProps {
    vehicles: Array<WellnessCardProps>;
    cardRows: number;
    cardColumns: number;
    ref?: React.ForwardedRef<HTMLDivElement>;
}

const WellnessGrid: React.FC<WellnessGridProps> = forwardRef<HTMLDivElement, WellnessGridProps>((props, ref) => {
    const { t } = useTranslation();
    const { licensePlate } = useParams<{ licensePlate: string }>();
    const cardsOnScreen = props.cardRows * props.cardColumns;
    let initialIndex = 0;
    const vehicleIndex = props.vehicles.findIndex((v) => v.licensePlate === licensePlate);
    if (vehicleIndex >= 0) {
        initialIndex = Math.floor(vehicleIndex / cardsOnScreen) * cardsOnScreen;
    }
    const [startingIndex, setStartingIndex] = useState<number>(initialIndex);
    const [selectedLicensePlate, setSelectedLicensePlate] = useState<string>();
    const [defaultTab, setDefaultTab] = useState<number>(0);
    const { api } = useApi();
    const getImage = (imageKey: string) => {
        const [type, id] = imageKey.split(':', 2);
        if (type === 'event') {
            return api.apiV0EventEventIdImageGet({ eventId: parseInt(id) }, { responseType: 'blob' });
        } else {
            return api.apiV2WellnessImageWellnessTypeGet(
                {
                    wellnessType: type,
                    wellnessId: id,
                },
                { responseType: 'blob' },
            );
        }
    };
    const [wellnessImages, addToQueue] = useImages(cardsOnScreen, getImage, true);
    const isRTL = useContext(RTLDirectionContext);
    const isSingleColumn = props.cardColumns === 1;
    const history = useHistory();

    useEffect(() => {
        addToQueue(
            props.vehicles
                .slice(startingIndex, startingIndex + 2 * cardsOnScreen)
                .filter((v) => !!v.mostRecentEventId)
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .map((v) => getImageKey(v, v.mostRecentEventId!)),
        );
    }, [props.vehicles, props.cardRows, props.cardColumns, startingIndex]);

    useEffect(() => {
        window.addEventListener('keydown', keyScroll);
        return () => window.removeEventListener('keydown', keyScroll);
    });

    useEffect(() => {
        if (licensePlate) {
            const vehicleExists = props.vehicles.some((v) => v.licensePlate === licensePlate);
            if (vehicleExists) {
                setSelectedLicensePlate(licensePlate);
            } else {
                history.replace('/wellness');
            }
        } else {
            setSelectedLicensePlate(undefined);
        }
    }, [licensePlate]);

    useEffect(() => {
        setStartingIndex(0);
    }, [props.vehicles.length]);

    const keyMap: Record<string, 'previous' | 'next'> = {
        ArrowLeft: isRTL ? 'next' : 'previous',
        ArrowRight: isRTL ? 'previous' : 'next',
    };

    const keyScroll = (e: KeyboardEvent) => {
        if (e.key in keyMap && selectedLicensePlate === undefined) {
            e.preventDefault();
            if (!e.repeat) {
                turnPage(keyMap[e.key]);
            }
        }
    };
    const turnPage = (direction: 'previous' | 'next') => {
        if (direction === 'previous' && startingIndex > 0) {
            setStartingIndex((prev) => Math.max(0, prev - cardsOnScreen));
        } else if (direction === 'next' && startingIndex + cards.length < props.vehicles.length) {
            setStartingIndex((prev) => prev + cardsOnScreen);
        }
    };

    const cards = props.vehicles.slice(startingIndex, startingIndex + cardsOnScreen).map((card, idx) => {
        return (
            <Box
                id={`${card.licensePlate}-card`}
                key={idx}
                aria-label="vehicle-card"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <WellnessCard
                    timestamp={card.timestamp}
                    driverName={card.driverName}
                    licensePlate={card.licensePlate}
                    subFleet={card.subFleet}
                    score={card.score}
                    behaviors={card.behaviors}
                    setDefaultTab={setDefaultTab}
                    eventIds={card.eventIds}
                    icStatusIds={card.icStatusIds}
                    ffStatusIds={card.ffStatusIds}
                    mostRecentEventId={card.mostRecentEventId}
                    mostRecentEventTimestamp={card.mostRecentEventTimestamp}
                    image={
                        !!card.mostRecentEventId ? wellnessImages[getImageKey(card, card.mostRecentEventId)] : imageNA
                    }
                />
            </Box>
        );
    });

    const previousBtn = (
        <Box sx={isSingleColumn ? { mr: 1 } : { gridColumn: '1', gridRow: `1 / ${props.cardRows + 1}` }}>
            <IconButton
                id="previous-btn"
                data-testid="previous-btn"
                size="large"
                onClick={() => turnPage('previous')}
                disabled={startingIndex === 0}
            >
                {isRTL ? (
                    <NavigateNextIcon sx={{ transform: 'scale(2)' }} />
                ) : (
                    <NavigateBeforeIcon sx={{ transform: 'scale(2)' }} />
                )}
            </IconButton>
        </Box>
    );

    const nextBtn = (
        <Box
            sx={
                isSingleColumn
                    ? undefined
                    : { gridColumn: `${props.cardColumns + 2}`, gridRow: `1 / ${props.cardRows + 1}` }
            }
        >
            <IconButton
                id="next-btn"
                data-testid="next-btn"
                size="large"
                onClick={() => turnPage('next')}
                disabled={startingIndex + cards.length === props.vehicles.length}
            >
                {isRTL ? (
                    <NavigateBeforeIcon sx={{ transform: 'scale(2)' }} />
                ) : (
                    <NavigateNextIcon sx={{ transform: 'scale(2)' }} />
                )}
            </IconButton>
        </Box>
    );

    const selectedVehicle = props.vehicles.find((v) => v.licensePlate === selectedLicensePlate);

    return (
        <>
            {selectedVehicle ? (
                <WellnessModal
                    vehicle={selectedVehicle}
                    onClose={() => history.push('/wellness')}
                    initialTab={defaultTab}
                />
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    minHeight: `${CARD_HEIGHT + 2 * CARD_MARGIN + 45}px`,
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: isSingleColumn
                            ? '1fr'
                            : `${NAV_BUTTON_WIDTH}px repeat(${props.cardColumns}, 1fr) ${NAV_BUTTON_WIDTH}px`,
                        gridTemplateRows: `repeat(${props.cardRows}, 1fr)`,
                        height: 'calc(100% - 45px)',
                        alignItems: 'center',
                        alignContent: 'center',
                        position: 'relative',
                    }}
                    ref={ref}
                >
                    {isSingleColumn ? (
                        <>{cards}</>
                    ) : (
                        <>
                            {previousBtn}
                            {cards}
                            {nextBtn}
                        </>
                    )}
                </Box>
                {props.vehicles.length > 0 ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: isSingleColumn ? 1 : 0 }}>
                        {isSingleColumn ? (
                            <Box sx={{ width: '100%', mb: 0.5, display: 'flex', justifyContent: 'center' }}>
                                {previousBtn}
                                {nextBtn}
                            </Box>
                        ) : null}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Typography variant="overline" sx={{ lineHeight: 1 }}>
                                {t('content.safety.vehicles_shown', {
                                    start: startingIndex + 1,
                                    end: startingIndex + cards.length,
                                    total: props.vehicles.length,
                                })}
                            </Typography>
                        </Box>
                    </Box>
                ) : null}
            </Box>
        </>
    );
});
// necessary for debugging when using forwardRef
WellnessGrid.displayName = 'WellnessGrid';
export default WellnessGrid;
