import React from 'react';
import styled from 'styled-components';

const SMenu = styled.div`
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    max-height: 300px;
    overflow-y: scroll;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 3px;
`;

const DMenuItem = styled.a`
    color: black;
    width: 15em;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
        background-color: #f1f1f1;
    }
`;

interface DropdownItemProps {
    value: string;
    setValue: CallableFunction;
}

const DropdownItem: React.FC<DropdownItemProps> = (props: DropdownItemProps) => {
    return (
        <DMenuItem className="menu-item" onClick={() => props.setValue(props.value)}>
            {props.value}
        </DMenuItem>
    );
};

interface DropdownProps {
    values: Array<string | number>;
    setValue: CallableFunction;
}

const uniq = (value: string | number, index: number, self: Array<string | number>): boolean => {
    return value != '' && self.indexOf(value) === index;
};

export const DropDown: React.FC<DropdownProps> = (props: DropdownProps) => {
    return (
        <SMenu>
            {props.values.filter(uniq).map((value) => (
                <DropdownItem key={value} value={value.toString()} setValue={props.setValue} />
            ))}
        </SMenu>
    );
};
export default DropDown;
