import { Box, Card, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedAccident } from '../../../../../backendsdk';
import AccidentDamagePayment from '../../AccidentDamagePayment';

interface PaymentsTabProps {
    details: ExtendedAccident;
    onSave: CallableFunction;
    setSending: CallableFunction;
    handlingStatusList: Array<string>;
    setHandlingStatusList: CallableFunction;
    setAlert: CallableFunction;
    updateCallbacks: Record<string, CallableFunction>;
    setUpdateCallbacks: CallableFunction;
    validateCallbacks: Record<string, CallableFunction>;
    setValidateCallbacks: CallableFunction;
    removeCallbacks: CallableFunction;
    handleSave: CallableFunction;
    sending: boolean;
}

const PaymentsTab: React.FC<PaymentsTabProps> = (props: PaymentsTabProps) => {
    const { t } = useTranslation();
    const { details } = props;

    const conditionalTooltip = (component: React.ReactNode) => {
        if (details.accident_id === 0) {
            return (
                <Tooltip title={t('content.accidents.form.please_save')} followCursor>
                    <Box>{component}</Box>
                </Tooltip>
            );
        } else {
            return component;
        }
    };

    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={1} columns={2}>
                <Grid item xs={1}>
                    <Card sx={{ height: '100%' }}>
                        {conditionalTooltip(
                            <AccidentDamagePayment
                                details={details}
                                onSave={props.handleSave}
                                setAlert={props.setAlert}
                                disabled={props.sending}
                            />,
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PaymentsTab;
