import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmDialog from '../../../components/ConfirmDialog';
import useApi from '../../../hooks/api';

interface RemoveModalProps {
    items: Array<number>;
    type?: string;
    onClose: CallableFunction;
    setAlert: CallableFunction;
    setPolicies: () => void;
}

const RemoveModal: React.FC<RemoveModalProps> = (props: RemoveModalProps) => {
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const { t } = useTranslation();
    const { agencyApi } = useApi();

    const handleDelete = () => {
        setIsLoadingDelete(true);
        agencyApi
            .agencyV2PolicyDelete({ policyDeleteRequest: { policy_ids: props.items } })
            .then(() => {
                props.setPolicies();
                props.onClose();
            })
            .catch(() => props.setAlert({ message: t('content.policies.remove_error'), duration: 6000, type: 'error' }))
            .finally(() => setIsLoadingDelete(false));
    };

    return (
        <ConfirmDialog
            isDialogOpen={true}
            isLoading={isLoadingDelete}
            headerText={t('content.policies.remove')}
            bodyText={t('content.policies.confirm_remove', { count: props.items.length })}
            onClose={() => {
                if (!isLoadingDelete) {
                    props.onClose();
                }
            }}
            onConfirm={() => handleDelete()}
            buttonText={t('content.policies.remove')}
            buttonColor="redColor"
        />
    );
};

export default RemoveModal;
