import CallToActionIcon from '@mui/icons-material/CallToAction';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LightModeIcon from '@mui/icons-material/LightMode';
import MapIcon from '@mui/icons-material/Map';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import ViewListIcon from '@mui/icons-material/ViewList';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import {
    Box,
    CircularProgress,
    FormControlLabel,
    SpeedDial,
    SpeedDialAction,
    SpeedDialActionProps,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { VariableSizeList } from 'react-window';

import { AlertReportList, Geofence, TripDetails } from '../../../backendsdk';
import { TrackedButton as Button, TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useCallbackRef from '../../../hooks/callbackRef';
import useDevices from '../../../hooks/devices';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { fleetOverviewPageDefs } from '../../../utils/Pages';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_DAY } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import EventDetailsComponent from '../Event/EventDetails';
import { REPORT_TYPE_OPTIONS } from '../Reports/ReportDetails';
import { AlertReport } from '../Reports/ReportOptions';
import { OE_ROLES } from '../Users/UsersComponent';
import DeviceDetails from './DeviceDetails';
import { WELLNESS_TO_ACTION_MAP } from './DeviceDetails/WellnessPanel';
import DeviceListRow, { OFFLINE_DEVICE_HEIGHT, ONLINE_DEVICE_HEIGHT } from './DeviceListRow';
import FilterComponent from './FilterComponent';
import GeofenceDetails, { GeofenceOptions } from './Geofence/GeofenceDetails';
import GeofenceList from './Geofence/GeofenceList';
import OverviewMap from './OverviewMap';
import SortMenu from './SortMenu';
import {
    getFFWellnessDetails,
    getICWellnessDetails,
    getWellnessIconColor,
    sortingFunctions,
    sortingOptions,
} from './utils';

export interface FilterModel {
    subFleets: string[];
    wellnessStatus: string[];
    requiredAction: string[];
    currentStatus: string[];
}

export interface SortingModel {
    field: string;
    order: 'asc' | 'desc';
}

export interface GeoJSONFeatureProperties {
    id: number;
    name: string;
    events: GeofenceOptions;
    color: string;
}

export type GeofenceGeoJSON = GeoJSON.Feature<GeoJSON.Polygon, GeoJSONFeatureProperties>;

const geofenceToGeoJSON = (geofence: Geofence): GeofenceGeoJSON => {
    return {
        type: 'Feature',
        geometry: { type: 'Polygon', coordinates: [geofence.coordinates] },
        properties: {
            id: geofence.id,
            name: geofence.name,
            events: {
                enter: geofence.event_on_enter,
                leave: geofence.event_on_leave,
            },
            color: geofence.color,
        },
    };
};

const updateReportAlerts = (reportAlerts: AlertReport[], selectedGeofence: number) => {
    const filteredReportAlerts = reportAlerts.filter(
        (report) =>
            !(
                report.report_type === REPORT_TYPE_OPTIONS.GEOFENCE_EVENTS &&
                report.filters.GEOFENCE &&
                report.filters.GEOFENCE.length === 1 &&
                report.filters.GEOFENCE[0] === selectedGeofence.toString()
            ),
    );
    const updatedReportAlerts = filteredReportAlerts.map((report) => {
        if (
            report.report_type === REPORT_TYPE_OPTIONS.GEOFENCE_EVENTS &&
            report.filters.GEOFENCE &&
            report.filters.GEOFENCE.includes(selectedGeofence.toString())
        ) {
            return {
                ...report,
                filters: {
                    ...report.filters,
                    GEOFENCE: report.filters.GEOFENCE.filter((g) => g !== selectedGeofence.toString()),
                },
            };
        }
        return report;
    });
    return updatedReportAlerts;
};

export const EMPTY_FILTERS: FilterModel = { subFleets: [], wellnessStatus: [], requiredAction: [], currentStatus: [] };
export const MAX_VERTICES = 30;

const FleetOverview: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const [containerRef, node] = useCallbackRef();
    const [height, setHeight] = useState<number>(870);
    const [searchText, setSearchText] = useState<string>('');
    const [sorting, setSorting] = useState<SortingModel>({
        field: 'license_plate',
        order: 'asc',
    });
    const [filters, setFilters] = useState<FilterModel>(EMPTY_FILTERS);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const isFilterSet =
        filters.subFleets.length > 0 ||
        filters.wellnessStatus.length > 0 ||
        filters.requiredAction.length > 0 ||
        filters.currentStatus.length > 0;
    const { profile } = useProfile();
    const isAdmin = location.host.startsWith('dashboard-test') && OE_ROLES.includes(profile.role);
    const [useCache, setUseCache] = useState<boolean>(isAdmin ? localStorage.getItem('OE-cache') === 'true' : true);
    const [showRefresh, setShowRefresh] = useState<boolean>(false);
    const { devices, isLoading, isError, updateDevices } = useDevices(useCache ? 1 : 0);
    const mapSettingsStr = localStorage.getItem('OE-map-settings');
    const mapSettings = mapSettingsStr
        ? JSON.parse(mapSettingsStr)
        : {
              isDarkMode: false,
              isClustered: false,
              isGeofenceMode: false,
              mapView: false,
              showList: true,
              showToolbar: true,
          };
    const [isGeofenceMode, setIsGeofenceMode] = useState<boolean>(mapSettings.isGeofenceMode);
    const [drawnGeofence, setDrawnGeofence] = useState<GeofenceGeoJSON>();
    const [hoveredGeofence, setHoveredGeofence] = useState<number>();
    const [selectedGeofence, setSelectedGeofence] = useState<number>();
    const [isUpdatingGeofence, setIsUpdatingGeofence] = useState<boolean>(false);
    const [previewColor, setPreviewColor] = useState<string>();
    const [isMapInEditMode, setIsMapInEditMode] = useState<boolean>(false);
    const [hoveredDeviceId, setHoveredDeviceId] = useState<string>();
    const { licensePlate } = useParams<{ licensePlate: string }>();
    let initialSelectedDeviceId = undefined;
    if (devices.length > 0) {
        if (licensePlate) {
            const decodedLicensePlate = decodeURIComponent(licensePlate);
            const deviceFromURL = devices.find((device) => device.device.license_plate === decodedLicensePlate);
            if (deviceFromURL) {
                initialSelectedDeviceId = deviceFromURL.device.device_id;
            }
        }
    }
    const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(initialSelectedDeviceId);
    const [selectedTrip, setSelectedTrip] = useState<TripDetails>();
    const [selectedEventId, setSelectedEventId] = useState<string>();
    const [mapView, setMapView] = useState<boolean>(mapSettings.mapView);
    const [isClustered, setIsClustered] = useState<boolean>(mapSettings.isClustered);
    const [now, setNow] = useState<Date>(new Date());
    const [alertElement, setAlert] = useAlert();
    const [isDarkMode, setIsDarkMode] = useState<boolean>(mapSettings.isDarkMode);
    const [showList, setShowList] = useState<boolean>(mapSettings.showList);
    const [showToolbar, setShowToolbar] = useState<boolean>(mapSettings.showToolbar);
    const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(false);
    const [timespan, setTimespan] = useState<'day' | 'week'>('day');
    const [additionalTrip, setAdditionalTrip] = useState<TripDetails>();
    const listRef = React.useRef<VariableSizeList>(null);
    const history = useHistory();
    const { api } = useApi();
    const queryClient = useQueryClient();
    const getGeofences = async () => {
        const res = await api.apiV0GeofenceGet();
        return res.data;
    };
    const {
        data: geofenceData,
        isLoading: isLoadingGeofences,
        isError: isGeofenceError,
    } = useQuery({ queryKey: ['geofences'], queryFn: getGeofences });
    const geofences = useMemo(() => {
        return (geofenceData || []).map((geofence) => geofenceToGeoJSON(geofence));
    }, [geofenceData]);

    const getReports = async () => {
        const res = await api.apiV0ReportGet();
        return res.data;
    };
    const {
        data: reports,
        isLoading: isLoadingReports,
        isError: isReportError,
    } = useQuery({ queryKey: ['reports'], queryFn: getReports });

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${fleetOverviewPageDefs.name}`)}`;
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateDevices();
            setNow(new Date());
        }, 10 * MILLISECONDS_IN_SECOND);
        return () => clearInterval(intervalId);
    }, [updateDevices]);

    useEffect(() => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            if (node.offsetHeight) {
                setHeight(node.offsetHeight);
            }
        });
        resizeObserver.observe(node);
        return () => resizeObserver.disconnect();
    }, [node]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [devices, searchText, filters, sorting]);

    useEffect(() => {
        if (devices.length > 0) {
            let deviceId = undefined;
            if (licensePlate) {
                const decodedLicensePlate = decodeURIComponent(licensePlate);
                const deviceFromURL = devices.find((device) => device.device.license_plate === decodedLicensePlate);
                if (deviceFromURL) {
                    deviceId = deviceFromURL.device.device_id;
                } else {
                    history.replace('/overview');
                }
            }
            setSelectedDeviceId(deviceId);
        }
    }, [licensePlate, devices]);

    useEffect(() => {
        const mapSettings = {
            isDarkMode,
            isClustered,
            isGeofenceMode,
            mapView,
            showList,
            showToolbar,
        };
        localStorage.setItem('OE-map-settings', JSON.stringify(mapSettings));
    }, [showList, showToolbar, isDarkMode, isClustered, isGeofenceMode, mapView]);

    const filteredDevices = useMemo(() => {
        let filteredDevices = devices;
        if (searchText) {
            filteredDevices = filteredDevices.filter((device) => {
                const normalizedText = searchText.toLocaleLowerCase().replaceAll('-', '');
                const normalizedLicensePlate = device.device.license_plate.toLocaleLowerCase().replaceAll('-', '');
                const normalizedDriverName = device.device.driver_name.toLocaleLowerCase();
                const nameMatch = !!normalizedDriverName && normalizedDriverName.includes(normalizedText);
                const licenseMatch = !!normalizedLicensePlate && normalizedLicensePlate.includes(normalizedText);
                return nameMatch || licenseMatch;
            });
        }

        if (isFilterSet) {
            if (filters.subFleets.length > 0) {
                filteredDevices = filteredDevices.filter((device) => {
                    return filters.subFleets.includes(device.device.customer_sub_fleet);
                });
            }
            if (filters.wellnessStatus.length > 0) {
                filteredDevices = filteredDevices.filter((device) => {
                    const icWellnessDetails = getICWellnessDetails(
                        device,
                        profile.customer.settings.inactive_device_threshold,
                    );
                    const ffWellnessDetails = getFFWellnessDetails(
                        device,
                        profile.customer.settings.inactive_device_threshold,
                    );
                    const wellnessColor = getWellnessIconColor(icWellnessDetails, ffWellnessDetails);
                    return filters.wellnessStatus.includes(wellnessColor);
                });
            }
            if (filters.requiredAction.length > 0) {
                filteredDevices = filteredDevices.filter((device) => {
                    const icWellnessDetails = getICWellnessDetails(
                        device,
                        profile.customer.settings.inactive_device_threshold,
                    );
                    const ffWellnessDetails = getFFWellnessDetails(
                        device,
                        profile.customer.settings.inactive_device_threshold,
                    );
                    const icRquiredAction = WELLNESS_TO_ACTION_MAP[icWellnessDetails];
                    const ffRquiredAction =
                        WELLNESS_TO_ACTION_MAP[ffWellnessDetails as keyof typeof WELLNESS_TO_ACTION_MAP];
                    return (
                        filters.requiredAction.includes(icRquiredAction || '') ||
                        filters.requiredAction.includes(ffRquiredAction || '')
                    );
                });
            }
            if (filters.currentStatus.length > 0) {
                filteredDevices = filteredDevices.filter((device) => {
                    let deviceStatus;
                    const isOnline = !!device.current_trip;
                    if (isOnline) {
                        deviceStatus = 'online';
                    } else {
                        if (
                            device.last_seen !== undefined &&
                            new Date().getTime() / 1000 - device.last_seen <
                                profile.customer.settings.inactive_device_threshold * SECONDS_IN_DAY
                        ) {
                            deviceStatus = 'offline';
                        } else {
                            deviceStatus = 'inactive';
                        }
                    }
                    return filters.currentStatus.includes(deviceStatus);
                });
            }
        }

        filteredDevices = sortingFunctions(profile.customer.settings.inactive_device_threshold)[sorting.field](
            filteredDevices,
            sorting.order,
        );
        return filteredDevices;
    }, [devices, searchText, sorting, filters]);

    if (isLoading && devices.length === 0) {
        return (
            <Box
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <CircularProgress data-testid="loading" />
            </Box>
        );
    }

    if (isError && devices.length === 0) {
        return (
            <Box
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Typography variant="overline">{t('content.fleet.error')}</Typography>
            </Box>
        );
    }

    let toolbar;
    if (selectedDeviceId || selectedGeofence || drawnGeofence) {
        toolbar = (
            <Button
                id="back-btn"
                variant="outlined"
                disabled={isMapInEditMode || isUpdatingGeofence}
                onClick={() => {
                    history.push('/overview');
                    setSelectedTrip(undefined);
                    setSelectedGeofence(undefined);
                    setDrawnGeofence(undefined);
                }}
                sx={{ height: 35 }}
            >
                {t('content.fleet.back')}
            </Button>
        );
    } else {
        toolbar = (
            <>
                <TextField
                    fullWidth={isMobile}
                    id="search-field"
                    variant="outlined"
                    size="small"
                    placeholder={t('content.fleet.search')}
                    disabled={isGeofenceMode}
                    value={searchText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                    sx={{ '& .MuiInputBase-input': { paddingY: '0px', paddingLeft: '8px' } }}
                    inputProps={{ sx: { height: 35 } }}
                />
                <SortMenu
                    disabled={isGeofenceMode}
                    options={sortingOptions}
                    selected={sorting}
                    setSelected={setSorting}
                    translationKey="content.fleet.sort"
                />
                <Button
                    id="filter-btn"
                    variant={isFilterOpen || isFilterSet ? 'contained' : 'outlined'}
                    color="primary"
                    startIcon={<FilterAltIcon />}
                    disabled={isGeofenceMode}
                    sx={{
                        flexShrink: 0,
                        height: 35,
                        mr: isMobile ? 0 : 1,
                    }}
                    onClick={() => setIsFilterOpen((prev) => !prev)}
                >
                    {t('content.fleet.filter.header')}
                </Button>
                {!isMobile ? (
                    <>
                        <Button
                            id="geofence-btn"
                            variant={isGeofenceMode ? 'contained' : 'outlined'}
                            startIcon={<ShareLocationIcon />}
                            color="primary"
                            sx={{
                                flexShrink: 0,
                                height: 35,
                                mr: 1,
                            }}
                            onClick={() => setIsGeofenceMode((prev) => !prev)}
                        >
                            {t('content.fleet.geofence.header')}
                        </Button>
                        {isAdmin ? (
                            <>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            id="use-cache-switch"
                                            data-testid="use-cache-switch"
                                            checked={useCache}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                localStorage.setItem('OE-cache', e.target.checked ? 'true' : 'false');
                                                setUseCache(e.target.checked);
                                                setShowRefresh(true);
                                            }}
                                            size="small"
                                        />
                                    }
                                    label={
                                        <Typography sx={{ fontSize: 14 }}>{t('content.fleet.use_cache')}</Typography>
                                    }
                                    sx={{ ml: -0.5, mr: 0 }}
                                />
                                {showRefresh ? (
                                    <IconButton
                                        id="btn-refresh"
                                        size="small"
                                        onClick={() => location.reload()}
                                        sx={{ ml: 0.5 }}
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                ) : null}
                            </>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    }

    const saveGeofenceHandler = (name: string, events: GeofenceOptions, color: string) => {
        const isNewGeofence = selectedGeofence === undefined;
        let coordinates = [];
        if (isNewGeofence) {
            if (!drawnGeofence) return;
            coordinates = drawnGeofence.geometry.coordinates[0];
        } else {
            const editedGeofence = geofences.find((geofence) => geofence.properties.id === selectedGeofence);
            if (!editedGeofence) return;
            coordinates = (drawnGeofence || editedGeofence).geometry.coordinates[0];
        }

        setIsUpdatingGeofence(true);
        const apiMethod = (geofence: Geofence) =>
            isNewGeofence
                ? api.apiV0GeofencePost({ geofence })
                : api.apiV0GeofenceGeofenceIdPatch({ geofenceId: geofence.id, geofence });
        apiMethod({
            id: isNewGeofence ? 0 : selectedGeofence,
            name,
            event_on_enter: events.enter,
            event_on_leave: events.leave,
            color,
            coordinates,
        })
            .then((res) => {
                queryClient.setQueryData(['geofences'], (prev: Geofence[] | undefined) => {
                    if (prev === undefined) return prev;
                    if (isNewGeofence) {
                        return [...prev, res.data];
                    } else {
                        return prev.map((geofence) => (geofence.id === selectedGeofence ? res.data : geofence));
                    }
                });
                setDrawnGeofence(undefined);
                setSelectedGeofence(undefined);
            })
            .catch(() => {
                setAlert({
                    message: t(`content.fleet.geofence.${isNewGeofence ? 'save_error' : 'update_error'}`),
                    type: 'error',
                    duration: 6000,
                });
            })
            .finally(() => {
                setIsUpdatingGeofence(false);
            });
    };

    const deleteHandler = () => {
        if (selectedGeofence === undefined) return;

        setIsUpdatingGeofence(true);
        api.apiV0GeofenceGeofenceIdDelete({ geofenceId: selectedGeofence })
            .then(() => {
                queryClient.setQueryData(['geofences'], (prev: Geofence[] | undefined) => {
                    if (prev === undefined) {
                        return prev;
                    }
                    return prev.filter((geofence) => geofence.id !== selectedGeofence);
                });
                queryClient.setQueryData(['reports'], (prev: AlertReportList | undefined) => {
                    if (prev) {
                        return {
                            alerts: updateReportAlerts(prev.alerts as AlertReport[], selectedGeofence),
                            reports: updateReportAlerts(prev.reports as AlertReport[], selectedGeofence),
                        } as AlertReportList;
                    }
                    return prev;
                });
                setDrawnGeofence(undefined);
                setSelectedGeofence(undefined);
            })
            .catch(() => {
                setAlert({ message: t('content.fleet.geofence.delete_error'), type: 'error', duration: 6000 });
            })
            .finally(() => setIsUpdatingGeofence(false));
    };

    const cancelHandler = () => {
        setDrawnGeofence(undefined);
        setSelectedGeofence(undefined);
    };

    let content;
    if (selectedDeviceId) {
        const selectedDevice = devices.find((device) => device.device.device_id === selectedDeviceId);
        content = (
            <DeviceDetails
                devices={filteredDevices}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                device={selectedDevice!}
                selectedTrip={selectedTrip}
                setSelectedTrip={setSelectedTrip}
                timespan={timespan}
                setTimespan={setTimespan}
                additionalTrip={additionalTrip}
            />
        );
    } else if (isFilterOpen) {
        content = (
            <FilterComponent
                filters={filters}
                setFilters={setFilters}
                setIsFilterOpen={setIsFilterOpen}
                subFleets={[...new Set(devices.map((device) => device.device.customer_sub_fleet))]}
                results={filteredDevices.length}
            />
        );
    } else if (isGeofenceMode) {
        const isValidPolygon = !(
            drawnGeofence?.geometry?.coordinates && drawnGeofence.geometry.coordinates[0].length > MAX_VERTICES
        );
        if (!!selectedGeofence) {
            content = (
                <GeofenceDetails
                    details={geofences.find((geofence) => geofence.properties.id === selectedGeofence)?.properties}
                    reports={reports || { alerts: [], reports: [] }}
                    devices={devices}
                    geofences={geofenceData}
                    isMapInEditMode={isMapInEditMode}
                    onSave={saveGeofenceHandler}
                    onCancel={cancelHandler}
                    onDelete={deleteHandler}
                    isValidPolygon={isValidPolygon}
                    setPreviewColor={setPreviewColor}
                    isLoading={isUpdatingGeofence}
                />
            );
        } else if (!!drawnGeofence) {
            content = (
                <GeofenceDetails
                    isMapInEditMode={isMapInEditMode}
                    onSave={saveGeofenceHandler}
                    onCancel={cancelHandler}
                    onDelete={cancelHandler}
                    isValidPolygon={isValidPolygon}
                    setPreviewColor={setPreviewColor}
                    isLoading={isUpdatingGeofence}
                />
            );
        } else {
            content = (
                <GeofenceList
                    geofences={geofences}
                    setHoveredGeofence={setHoveredGeofence}
                    setSelectedGeofence={setSelectedGeofence}
                    onRetry={() => {
                        if (isGeofenceError) {
                            queryClient.refetchQueries(['geofences']);
                        }
                        if (isReportError) {
                            queryClient.refetchQueries(['reports']);
                        }
                    }}
                    isLoading={isLoadingGeofences || isLoadingReports}
                    isError={isGeofenceError || isReportError}
                />
            );
        }
    } else if (filteredDevices.length > 0) {
        content = (
            <VariableSizeList
                height={height}
                width="100%"
                itemSize={(index) =>
                    !!filteredDevices[index].current_trip ? ONLINE_DEVICE_HEIGHT : OFFLINE_DEVICE_HEIGHT
                }
                itemCount={filteredDevices.length}
                overscanCount={5}
                itemData={{ devices: filteredDevices, setHoveredDeviceId, now }}
                ref={listRef}
            >
                {DeviceListRow}
            </VariableSizeList>
        );
    } else {
        content = (
            <Box sx={{ p: 2 }}>
                <Typography variant="overline">{t('content.fleet.no_vehicle_found')}</Typography>
            </Box>
        );
    }

    const mobileMapViewAction: SpeedDialActionProps = {
        icon: mapView ? <ViewListIcon /> : <MapIcon />,
        tooltipTitle: t(`content.fleet.map.settings.${mapView ? 'list' : 'map'}_view`),
        onClick: () => setMapView((prev) => !prev),
    };
    const darkModeAction: SpeedDialActionProps = {
        icon: isDarkMode ? <LightModeIcon /> : <DarkModeIcon />,
        tooltipTitle: t(`content.fleet.map.settings.${isDarkMode ? 'light' : 'dark'}_mode`),
        onClick: () => setIsDarkMode((prev) => !prev),
    };
    const markerClusterAction: SpeedDialActionProps = {
        icon: <WorkspacesIcon color={isClustered ? 'primary' : 'inherit'} />,
        tooltipTitle: t('content.fleet.map.settings.cluster'),
        onClick: () => setIsClustered((prev) => !prev),
        FabProps: { sx: { backgroundColor: isClustered ? '#d8d8d8' : undefined } },
    };
    const geofenceAction: SpeedDialActionProps = {
        icon: <ShareLocationIcon color={isGeofenceMode ? 'primary' : 'inherit'} />,
        tooltipTitle: t('content.fleet.map.settings.geofence'),
        onClick: () => setIsGeofenceMode((prev) => !prev),
        FabProps: { sx: { backgroundColor: isGeofenceMode ? '#d8d8d8' : undefined } },
    };
    const hideListAction: SpeedDialActionProps = {
        icon: <ViewListIcon color={showList ? 'primary' : 'inherit'} />,
        tooltipTitle: t(`content.fleet.map.settings.${showList ? 'hide' : 'show'}_list`),
        onClick: () => setShowList((prev) => !prev),
        FabProps: { sx: { backgroundColor: showList ? '#d8d8d8' : undefined } },
    };
    const hideToolbarAction: SpeedDialActionProps = {
        icon: <CallToActionIcon color={showList ? 'primary' : 'inherit'} sx={{ transform: 'rotate(180deg)' }} />,
        tooltipTitle: t(`content.fleet.map.settings.${showToolbar ? 'hide' : 'show'}_toolbar`),
        onClick: () => setShowToolbar((prev) => !prev),
        FabProps: { sx: { backgroundColor: showToolbar ? '#d8d8d8' : undefined } },
    };

    let actions: SpeedDialActionProps[];
    if (isMobile) {
        actions = [mobileMapViewAction, darkModeAction, markerClusterAction, geofenceAction];
    } else {
        actions = [hideListAction, hideToolbarAction, darkModeAction, markerClusterAction, geofenceAction];
    }

    const loadingEventSpinner = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'bgColor.main',
                opacity: 0.5,
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 2000,
            }}
        >
            <CircularProgress data-testid="loading-event" />
        </Box>
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', position: 'relative' }}>
            {alertElement}
            {isLoadingEvent ? loadingEventSpinner : null}
            {!!selectedEventId ? (
                <EventDetailsComponent
                    eventId={selectedEventId}
                    setAlert={setAlert}
                    isLoading={isLoadingEvent}
                    setIsLoading={setIsLoadingEvent}
                    isVisible={!!selectedEventId}
                    setIsVisible={() => setSelectedEventId('')}
                    onEventsPage={false}
                    setSelectedEventId={setSelectedEventId}
                    showEditVideo
                    showFindVideo
                />
            ) : null}
            {(!isMobile || !mapView) && showToolbar ? (
                <Box
                    sx={{
                        height: 70,
                        width: '100%',
                        flexShrink: 0,
                        backgroundColor: palette.neutral[50],
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        display: 'flex',
                        alignItems: 'center',
                        p: 2,
                        zIndex: 1001,
                    }}
                >
                    {toolbar}
                </Box>
            ) : null}
            <Box sx={{ width: '100%', height: '100%', display: 'flex', minHeight: 0 }}>
                {(!isMobile || !mapView) && showList ? (
                    <Box
                        sx={{
                            height: '100%',
                            width: isMobile ? '100%' : 435,
                            flexShrink: 0,
                            borderRightWidth: 1,
                            borderRightStyle: 'solid',
                            borderRightColor: 'lightGrayColor.main',
                        }}
                        ref={containerRef}
                    >
                        {content}
                    </Box>
                ) : null}
                {!isMobile || mapView ? (
                    <div style={{ width: '100%', height: '100%', direction: 'ltr' }}>
                        <OverviewMap
                            {...{
                                devices: filteredDevices,
                                hoveredDeviceId,
                                selectedDeviceId,
                                isClustered,
                                setIsClustered,
                                selectedTrip,
                                setSelectedTrip,
                                setSelectedEventId,
                                setIsLoadingEvent,
                                isGeofenceMode,
                                geofences,
                                hoveredGeofence,
                                selectedGeofence,
                                setSelectedGeofence,
                                drawnGeofence,
                                setDrawnGeofence,
                                setIsMapInEditMode,
                                previewColor,
                                isDarkMode,
                                showList,
                                showToolbar,
                                setAlert,
                                setTimespan,
                                setAdditionalTrip,
                            }}
                        />
                    </div>
                ) : null}
            </Box>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                FabProps={{ sx: { width: 40, height: 40 } }}
                sx={{
                    position: 'absolute',
                    bottom: 75,
                    right: 12,
                    '& .MuiSpeedDial-actions': { marginBottom: '-40px' },
                }}
                icon={<SettingsIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction key={action.tooltipTitle as string} {...action} />
                ))}
            </SpeedDial>
        </Box>
    );
};

export default FleetOverview;
