import { useMediaQuery, useTheme } from '@mui/material';
import {
    DesktopDatePicker,
    DesktopDateTimePicker,
    DesktopTimePicker,
    MobileDatePicker,
    MobileDateTimePicker,
    MobileTimePicker,
} from '@mui/x-date-pickers';
import React from 'react';

import useIsMobile from '../hooks/isMobile';
import useProfile from '../hooks/profile';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ResponsiveDateTimePicker: React.FC<any> = (props: any) => {
    const { profile } = useProfile();
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const ampm = profile.customer.settings.country === 'us';

    const desktopProps = { ...props };
    if (!isMobile) {
        desktopProps.slotProps = {
            ...(props.slotProps || {}),
            popper: { className: 'datetime-picker-dialog' },
        };
    }
    return isMobile ? (
        <MobileDateTimePicker ampm={ampm} {...props} />
    ) : (
        <DesktopDateTimePicker ampm={ampm} {...desktopProps} />
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ResponsiveDatePicker: React.FC<any> = (props: any) => {
    const isMobile = useIsMobile();
    return isMobile ? <MobileDatePicker {...props} /> : <DesktopDatePicker {...props} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ResponsiveTimePicker: React.FC<any> = (props: any) => {
    const { profile } = useProfile();
    const isMobile = useIsMobile();
    const ampm = profile.locale === 'us';
    return isMobile ? <MobileTimePicker ampm={ampm} {...props} /> : <DesktopTimePicker ampm={ampm} {...props} />;
};
