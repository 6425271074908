import { Box, Card, Dialog, Step, StepLabel, Stepper, Typography } from '@mui/material';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DeviceHardware, InstallDeviceRequest, NewDevice } from '../../../../backendsdk';
import { TrackedLink as Link } from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import palette from '../../../ColorPalette';
import OpenEyesLogo from './assets/openeyes-logo.svg';
import SetupToolLogo from './assets/setup-tool-logo.svg';
import Completed from './pages/Completed';
import DeviceFeedback from './pages/DeviceFeedback';
import InstallationParams, { EMPTY_PARAMS, Params, convertParam } from './pages/InstallationParams';
import InstallerIdentification from './pages/InstallerIdentification';
import OldDevice from './pages/OldDevice';
import ProcessIntro from './pages/ProcessIntro';
import SelectNewDevice from './pages/SelectNewDevice';
import UploadPhoto from './pages/UploadPhoto';
import VerifyCamera from './pages/VerifyCamera';
import { fallbackValue } from './utils/analysis';
import { getStoredValue, setStoredValue } from './utils/storage';
import getWorker from './workers/worker';

export const HORIZONTAL_APERTURE: Record<DeviceHardware, number> = {
    [DeviceHardware.MitacK220]: 87,
    [DeviceHardware.MitacSprint]: 87,
    [DeviceHardware.ReachengR1]: 57,
};

export const VERTICAL_APERTURE: Record<DeviceHardware, number> = {
    [DeviceHardware.MitacK220]: 64,
    [DeviceHardware.MitacSprint]: 64,
    [DeviceHardware.ReachengR1]: 46,
};

export const cleanup = (suffix: string) => {
    localStorage.removeItem(`OE-page-${suffix}`);
    localStorage.removeItem(`OE-mode-${suffix}`);
    localStorage.removeItem(`OE-step-${suffix}`);
    localStorage.removeItem(`OE-old-imei-${suffix}`);
    localStorage.removeItem(`OE-new-device-${suffix}`);
    localStorage.removeItem(`OE-params-${suffix}`);
    localStorage.removeItem(`OE-form-stage-${suffix}`);
};

export enum Pages {
    InstallerIdentification,
    Intro,
    VerifyCamera,
    OldDevice,
    SelectNewDevice,
    InstallationParams,
    UploadPhoto,
    Feedback,
    Completed,
}

export const PAGE_ORDER: Record<Modes, Pages[]> = {
    install: [Pages.SelectNewDevice, Pages.InstallationParams, Pages.UploadPhoto, Pages.Feedback],
    switch: [Pages.OldDevice, Pages.SelectNewDevice, Pages.InstallationParams, Pages.UploadPhoto, Pages.Feedback],
    remove: [Pages.OldDevice],
    verify: [Pages.VerifyCamera],
    adjust: [Pages.OldDevice, Pages.InstallationParams, Pages.UploadPhoto, Pages.Feedback],
};

export interface WorkerParams {
    instHeight: number;
    instDepth: number;
    instHoodHeight: number;
    'vehicle-width': number;
    horizontalAperture: number;
    verticalAperture: number;
}

interface SetupToolProps {
    malfunctionId: number;
    mode: Modes;
    imei?: string;
    hardware?: DeviceHardware;
    policyId?: number;
    expectedLicensePlate?: string;
    onClose: CallableFunction;
}

export const SETUP_TOOL_MODES = ['install', 'switch', 'remove', 'verify', 'adjust'] as const;
export type Modes = typeof SETUP_TOOL_MODES[number];

const MODES_WITH_INTRO = ['install', 'switch', 'adjust'];

const SetupTool: React.FC<SetupToolProps> = (props: SetupToolProps) => {
    const suffix = `${props.malfunctionId}-${props.mode}`;
    const [page, setPage] = useState<number>(getStoredValue<number>('OE-page', suffix, Pages.InstallerIdentification));
    const [installerName, setInstallerName] = useState<string>(getStoredValue<string>('OE-installer-name', '', ''));
    const [newDevice, setNewDevice] = useState<NewDevice | undefined>(
        getStoredValue<NewDevice | undefined>('OE-new-device', suffix, undefined),
    );
    const [params, setParams] = useState<Params>(getStoredValue<Params>('OE-params', suffix, EMPTY_PARAMS));
    const [workerParams, setWorkerParams] = useState<WorkerParams | undefined>(
        getStoredValue<WorkerParams | undefined>('OE-worker-params', suffix, undefined),
    );
    const [horizontalPixel, setHorizontalPixel] = useState<number>();
    const [formStage, setFormStage] = useState<number>(getStoredValue<number>('OE-form-stage', suffix, 0));
    const [photo, setPhoto] = useState<File | null>(null);
    const isMobile = useIsMobile();
    const { i18n, t } = useTranslation();
    const isRTL = i18n.languages[0] === 'he';
    const { installApi } = useApi();
    const { profile } = useProfile();
    const history = useHistory();

    const device = useMemo(() => {
        let device = newDevice;
        if (props.mode == 'adjust' && props.hardware && props.imei) {
            device = { hardware: props.hardware, imei: props.imei };
        }
        return device;
    }, [newDevice, props.hardware, props.imei]);

    const modePages = PAGE_ORDER[props.mode];
    let step;
    if (modePages) {
        if (page === Pages.Completed) {
            step = { current: modePages.length, total: modePages.length };
        } else {
            if (modePages.includes(page)) {
                step = { current: modePages.indexOf(page), total: modePages.length };
            }
        }
    }

    useEffect(() => {
        setStoredValue<string>('OE-installer-name', '', installerName);
    }, [installerName]);

    useEffect(() => {
        if (workerParams) {
            setStoredValue('OE-worker-params', suffix, workerParams);
            const worker = getWorker();
            worker.onmessage = (event) => {
                const hp = event.data;
                setHorizontalPixel(!!hp ? (hp[0] + hp[1]) / 2 : fallbackValue);
            };
            worker.onerror = () => {
                setHorizontalPixel(fallbackValue);
            };
            worker.postMessage(workerParams);
            return () => {
                worker.terminate();
            };
        }
    }, [workerParams]);

    useEffect(() => {
        if (![Pages.VerifyCamera, Pages.Completed].includes(page)) {
            setStoredValue<number>('OE-page', suffix, Math.min(page, Pages.UploadPhoto));
        }
    }, [page]);

    useEffect(() => {
        if (device) {
            if (props.mode !== 'switch') {
                localStorage.removeItem('OE-params');
            }
            setStoredValue<NewDevice>('OE-new-device', suffix, device);
        }
    }, [device]);

    useEffect(() => {
        document.dir = isRTL ? 'rtl' : 'ltr';
    }, [isRTL]);

    const uploadImage = async (image: File) => {
        if (!device) {
            return;
        }
        try {
            const post_res = await installApi.installV2UploadPost({
                installationFileRequestV2: { device_id: device.imei, file_name: image.name },
            });
            const compressedFile = await imageCompression(image, { maxSizeMB: 1 });
            const config = {
                headers: {
                    'Content-Type': image.type,
                },
            };
            await axios.put(post_res.data.upload_url, compressedFile, config);
        } catch (error) {
            // pass
        }
    };

    const measurementSystem = profile.customer.settings.country === 'us' ? 'IMPERIAL' : 'METRIC';

    const installDevice = () => {
        if (!device) {
            return Promise.reject();
        }

        const offsetFactor = params.offsetDirection === 'left' ? -1 : 1;
        const formattedParams: InstallDeviceRequest = {
            installer: installerName,
            device_id: device.imei,
            customer_name: profile.customer.name,
            license_plate: params.license_plate,
            instHeight: convertParam(parseFloat(params.instHeight), measurementSystem, 'METRIC'),
            instDepth: convertParam(parseFloat(params.instDepth), measurementSystem, 'METRIC'),
            instHoodHeight: convertParam(parseFloat(params.instHoodHeight), measurementSystem, 'METRIC'),
            instHorizontalOffset: convertParam(
                parseFloat(params.instHorizontalOffset) * offsetFactor,
                measurementSystem,
                'METRIC',
            ),
            vehicle_weight: parseFloat(params['vehicle-weight']),
            vehicle_width: convertParam(parseFloat(params['vehicle-width']), measurementSystem, 'METRIC'),
            old_imei: props.imei || '',
            policy_id: props.policyId,
        };

        return installApi.installPost({ installDeviceRequest: formattedParams });
    };

    useEffect(() => {
        if (photo) {
            uploadImage(photo);
        }
    }, [photo]);

    useEffect(() => {
        if (location.pathname.endsWith('/clear')) {
            cleanup(suffix);
            history.replace(`/malfunctions/${props.malfunctionId}`);
        }
    }, [location.pathname]);

    const handleNext = () => {
        setPage((prev) => {
            const pages = PAGE_ORDER[props.mode];
            const lastPage = pages[pages.length - 1];
            if (prev === lastPage) {
                return Pages.Completed;
            }
            return pages[pages.indexOf(page) + 1];
        });
    };

    const handleBack = () => {
        setPage((prev) => {
            const pages = PAGE_ORDER[props.mode];
            const firstPage = pages[0];
            if (prev === firstPage) {
                if (MODES_WITH_INTRO.includes(props.mode)) {
                    return Pages.Intro;
                }
                return Pages.InstallerIdentification;
            }
            return pages[pages.indexOf(page) - 1];
        });
    };

    const dashboardLink = (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', minHeight: 0, flexShrink: 0, my: 1 }}>
            <Link
                id="back-to-dashboard-link"
                component="button"
                onClick={() => history.push(`/malfunctions/${props.malfunctionId}`)}
            >
                <Typography fontSize={14} sx={{ color: palette.neutral[700] }}>
                    {t('setup_tool.back_to_dashboard')}
                </Typography>
            </Link>
        </Box>
    );

    let currentPage;
    if (page === Pages.InstallerIdentification) {
        currentPage = (
            <InstallerIdentification
                installerName={installerName}
                setInstallerName={setInstallerName}
                onNext={() => setPage(MODES_WITH_INTRO.includes(props.mode) ? Pages.Intro : PAGE_ORDER[props.mode][0])}
                dashboardLink={dashboardLink}
            />
        );
    } else if (page === Pages.VerifyCamera && props.imei) {
        currentPage = (
            <VerifyCamera
                malfunctionId={props.malfunctionId}
                installerName={installerName}
                customerName={profile.customer.name}
                onBack={handleBack}
                imei={props.imei}
                hardware={props.hardware || DeviceHardware.ReachengR1}
            />
        );
    } else if (page === Pages.Intro) {
        currentPage = (
            <ProcessIntro
                mode={props.mode}
                onNext={handleNext}
                onBack={() => setPage(Pages.InstallerIdentification)}
                dashboardLink={dashboardLink}
            />
        );
    } else if (page === Pages.OldDevice && props.imei) {
        currentPage = (
            <OldDevice
                malfunctionId={props.malfunctionId}
                mode={props.mode}
                customerName={profile.customer.name}
                installerName={installerName}
                oldImei={props.imei}
                params={params}
                setParams={setParams}
                onBack={handleBack}
                onNext={handleNext}
                dashboardLink={dashboardLink}
            />
        );
    } else if (page === Pages.SelectNewDevice) {
        currentPage = (
            <SelectNewDevice
                mode={props.mode}
                malfunctionId={props.malfunctionId}
                setNewDevice={setNewDevice}
                onBack={handleBack}
                onNext={handleNext}
                dashboardLink={dashboardLink}
            />
        );
    } else if (page === Pages.InstallationParams && !!device) {
        currentPage = (
            <InstallationParams
                malfunctionId={props.malfunctionId}
                customer={profile.customer}
                mode={props.mode}
                newDevice={device}
                params={params}
                formStage={formStage}
                setFormStage={setFormStage}
                setParams={setParams}
                onBack={handleBack}
                onNext={handleNext}
                runWorker={(params: {
                    instHeight: string;
                    instDepth: string;
                    instHoodHeight: string;
                    'vehicle-width': string;
                }) => {
                    const hardware = device.hardware;
                    setWorkerParams({
                        instHeight: convertParam(parseFloat(params.instHeight), measurementSystem, 'METRIC'),
                        instDepth: convertParam(parseFloat(params.instDepth), measurementSystem, 'METRIC'),
                        instHoodHeight: convertParam(parseFloat(params.instHoodHeight), measurementSystem, 'METRIC'),
                        'vehicle-width': convertParam(parseFloat(params['vehicle-width']), measurementSystem, 'METRIC'),
                        horizontalAperture: HORIZONTAL_APERTURE[hardware || DeviceHardware.ReachengR1],
                        verticalAperture: VERTICAL_APERTURE[hardware || DeviceHardware.ReachengR1],
                    });
                }}
                dashboardLink={dashboardLink}
                expectedLicensePlate={props.expectedLicensePlate}
            />
        );
    } else if (page === Pages.UploadPhoto) {
        currentPage = (
            <UploadPhoto
                photo={photo}
                setPhoto={setPhoto}
                onBack={handleBack}
                onNext={handleNext}
                dashboardLink={dashboardLink}
            />
        );
    } else if (page === Pages.Feedback && device) {
        currentPage = (
            <DeviceFeedback
                horizontalPixel={horizontalPixel}
                newDevice={device}
                onInstall={installDevice}
                onBack={handleBack}
                onNext={handleNext}
                dashboardLink={dashboardLink}
            />
        );
    } else if (page === Pages.Completed) {
        currentPage = (
            <Completed
                mode={props.mode}
                malfunctionId={props.malfunctionId}
                onDone={() => {
                    history.replace('/malfunctions');
                }}
            />
        );
    }

    const content = (
        <Box
            sx={{
                width: '85%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                minHeight: 0,
                flexShrink: 0,
            }}
        >
            {step !== undefined && step.total > 1 ? (
                <Stepper
                    id="stepper"
                    data-current-step={step.current}
                    activeStep={step.current}
                    alternativeLabel
                    sx={{ position: 'absolute', top: '10px', width: '100%', flexShrink: 0 }}
                >
                    {Array.from({ length: step.total }, (_, index) => (
                        <Step key={index}>
                            <StepLabel></StepLabel>
                        </Step>
                    ))}
                </Stepper>
            ) : null}
            <Box
                sx={{
                    width: '60px',
                    mt: ['install', 'switch', 'adjust'].includes(props.mode) ? '60px' : '20px',
                    mb: 0.5,
                    flexShrink: 0,
                }}
            >
                <img src={SetupToolLogo} alt="Setup Tool Logo" width="100%" />
            </Box>
            <Box sx={{ width: '200px', flexShrink: 0 }}>
                <img src={OpenEyesLogo} alt="OpenEyes Logo" width="100%" />
            </Box>
            {currentPage}
        </Box>
    );

    const mobileContainer = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'auto',
                minHeight: 0,
            }}
        >
            {content}
        </Box>
    );

    const desktopContainer = (
        <Card
            sx={{
                width: '100%',
                maxWidth: '400px',
                height: '100%',
                maxHeight: '800px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'auto',
            }}
        >
            {content}
        </Card>
    );

    return (
        <Dialog id="setup-tool-dialog" open={true} fullScreen>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                {isMobile ? mobileContainer : desktopContainer}
            </Box>
        </Dialog>
    );
};

export default SetupTool;
