import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TFunction } from 'i18next';

import { HosDriver } from '../backendsdk';

dayjs.extend(utc);

export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;
export const SECONDS_IN_DAY = HOURS_IN_DAY * SECONDS_IN_HOUR;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_SECOND * SECONDS_IN_DAY;

export const NOW_TS = new Date().getTime() / MILLISECONDS_IN_SECOND;
export const TIME_INTERVALS: { [key: string]: number } = {
    Minute: SECONDS_IN_MINUTE,
    Hour: SECONDS_IN_HOUR,
    Day: SECONDS_IN_DAY,
    Week: 7 * SECONDS_IN_DAY,
    Month: 30 * SECONDS_IN_DAY,
    Year: 365 * SECONDS_IN_DAY,
};

export const dateFormatIL = 'D/M/YYYY';
export const dateFormatUS = 'M/D/YYYY';
export const shortDateFormatIL = 'D/M';
export const shortDateFormatUS = 'M/D';
export const timeFormatIL = 'HH:mm';
export const timeFormatUS = 'h:mm A';
export const dateTimeFormatIL = `${dateFormatIL} ${timeFormatIL}`;
export const dateTimeFormatUS = `${dateFormatUS} ${timeFormatUS}`;
export const dataGridDateFormatIL = 'dd/MM/yyyy';
export const dataGridDateFormatUS = 'MM/dd/yyyy';
export const dataGridTimeFormatIL = 'H:mm';
export const dataGridTimeFormatUS = 'h:mm aaa';
export const dataGridDateTimeFormatIL = `${dataGridDateFormatIL} ${dataGridTimeFormatIL}`;
export const dataGridDateTimeFormatUS = `${dataGridDateFormatUS} ${dataGridTimeFormatUS}`;

export const timestampToDate = (timestamp: number) => {
    return new Date(timestamp * MILLISECONDS_IN_SECOND);
};

export const dateToTimestamp = (date: Date) => {
    return date.getTime() / MILLISECONDS_IN_SECOND;
};

const displayFormatDateTime = (date: Date) => {
    return date.toLocaleString('sv-SE').slice(0, 16);
};

export const displayFormatDate = (date: Date) => {
    return displayFormatDateTime(date).slice(0, 10);
};

export const formatDateTime = (timestamp: number): string => {
    return displayFormatDateTime(timestampToDate(timestamp));
};

export const formatDate = (timestamp: number): string => {
    return displayFormatDate(timestampToDate(timestamp));
};

export const formatDateFromOrdinal = (ordinaltime: number): string => {
    return displayFormatDate(fromordinal(ordinaltime));
};

export const dateReducerMap = (timestamp: number): string => {
    const timeDiff = NOW_TS - timestamp;
    if (timeDiff < TIME_INTERVALS['Hour']) {
        return 'Last Hour';
    } else if (timeDiff < TIME_INTERVALS['Day']) {
        return 'Last Day';
    } else if (timeDiff < TIME_INTERVALS['Week']) {
        return 'Last Week';
    } else if (timeDiff < TIME_INTERVALS['Month']) {
        return 'Last Month';
    } else {
        return 'Last Year';
    }
};

export const getDays = (seconds: number): number => {
    return Math.floor(seconds / SECONDS_IN_DAY);
};

export const getHours = (seconds: number): number => {
    return Math.floor(seconds / SECONDS_IN_HOUR);
};

export const getMinutes = (seconds: number): number => {
    return Math.floor(seconds / SECONDS_IN_MINUTE);
};

export const formatTime = (
    seconds: number,
    t?: TFunction,
    short = false,
    fromNow = true,
    showSeconds = true,
): string => {
    if (isNaN(seconds)) {
        return t ? t('content.fleet.unknown') : 'Unknown';
    }
    const formatted_time = [];
    let remaining_seconds = Math.floor(seconds);
    if (fromNow && Math.floor(remaining_seconds) <= 0) {
        return t ? t('time.now') : 'Now';
    }
    const prefix = !!short ? 'time.short' : 'time';
    const days = getDays(remaining_seconds);
    if (days > 0) {
        remaining_seconds -= days * SECONDS_IN_DAY;
        const timeStr = t ? t(`${prefix}.days`, { amount: days }) : short ? `${days}d` : `${days} days`;
        formatted_time.push(timeStr);
    }
    const hours = getHours(remaining_seconds);
    if (hours > 0) {
        remaining_seconds -= hours * SECONDS_IN_HOUR;
        const timeStr = t ? t(`${prefix}.hours`, { amount: hours }) : short ? `${hours}h` : `${hours} hours`;
        formatted_time.push(timeStr);
    }
    if (formatted_time.length < 2 && days === 0) {
        const minutes = getMinutes(remaining_seconds);
        if (minutes > 0) {
            remaining_seconds -= minutes * SECONDS_IN_MINUTE;
            const timeStr = t
                ? t(`${prefix}.minutes`, { amount: minutes })
                : short
                ? `${minutes}m`
                : `${minutes} minutes`;
            formatted_time.push(timeStr);
        }
    }

    if (
        showSeconds &&
        formatted_time.length < 2 &&
        days === 0 &&
        hours === 0 &&
        (remaining_seconds > 0 || formatted_time.length === 0)
    ) {
        const timeStr = t
            ? t(`${prefix}.seconds`, { amount: remaining_seconds })
            : short
            ? `${remaining_seconds}s`
            : `${remaining_seconds} seconds`;
        formatted_time.push(timeStr);
    }

    return formatted_time.join(' ');
};

// from: https://gist.github.com/ViktorovEugene/9584b96a97831c3c460f
const toMilliSeconds = (value: number) => {
    return value * (24 * 60 * 60 * 1000);
};

export const fromordinal = (daysNumber: number): Date => {
    /* This is the JS implementation of the python's
     * ``datetime.date.fromordinal()`` method.
     * It returns the date object gotten from the day's number
     * if we take the first day as the 1 January of the 0001 year.
     */
    const zeroDate = new Date('0000-12-31');
    zeroDate.setHours(0);
    const zeroMilliSecond = zeroDate.getTime();
    return new Date(toMilliSeconds(daysNumber) + zeroMilliSecond);
};

export const daysNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const english_ordinal_rules = new Intl.PluralRules('en', { type: 'ordinal' });
const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
};

export const ordinalSuffix = (aNumber: number): string => {
    const suffix = suffixes[english_ordinal_rules.select(aNumber) as 'one' | 'two' | 'few' | 'other'];
    return aNumber + suffix;
};

export interface Time {
    hours: number;
    minutes: number;
    seconds: number;
}

export const secondsToTime = (totalSeconds: number): Time => {
    const hours = Math.floor(totalSeconds / SECONDS_IN_HOUR);
    const minutes = Math.floor((totalSeconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
    const seconds = Math.floor(totalSeconds % SECONDS_IN_MINUTE);
    return { hours, minutes, seconds };
};

export const formatTimeObject = (time: Time) => {
    return `${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}`;
};

export const formatDayjs = (date: Dayjs, format?: string) => {
    return !!format ? date.format(format) : date.format().split('T')[0];
};

export const dayjsToDateString = (date: Dayjs, driver: HosDriver, dayDelta = 0, format?: string) => {
    return formatDayjs(date.utcOffset(-driver.home_terminal.timezone_offset_from_utc).add(dayDelta, 'day'), format);
};

export const timestampToDateString = (timestamp: number, driver: HosDriver, dayDelta = 0, format?: string) => {
    return dayjsToDateString(dayjs.unix(timestamp), driver, dayDelta, format);
};

export const timestampWithoutLocalOffset = (date: Dayjs) => {
    return date.unix() + date.utcOffset() * SECONDS_IN_MINUTE;
};

export const shiftTimestamp = (timestamp: number, offset: number) => {
    return timestamp + SECONDS_IN_HOUR * offset;
};

export const armyTimeTo12HourLabel = (hour: number): string => {
    if ([0, 12].includes(hour)) {
        return '12';
    }
    if (hour > 12) {
        return (hour - 12).toString();
    }
    return hour.toString();
};

export const roundDownToMinute = (timestamp: number) => {
    return Math.floor(timestamp / SECONDS_IN_MINUTE) * SECONDS_IN_MINUTE;
};
