import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AccidentTagsV2Details } from '../../../../backendsdk';
import { TrackedButton as Button } from '../../../../components/TrackedComponents';
import VideoComponent from '../../../../components/video';
import useApi from '../../../../hooks/api';
import useProfile from '../../../../hooks/profile';
import videoNA from '../../../../images/video_not_available.png';
import { isEventVideo } from '../../../../utils/File';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SOURCE_OPTIONS = ['openeyes_video', 'customer_video', 'customer_report', 'other'];
const FAULT_OPTIONS = ['own', '3rd_party', 'shared', 'unknown'];
const SPEED_OPTIONS = ['<0kph', '0kph', '1-20kph', '20-50kph', '>=50kph', 'unknown'];
const IN_CABIN_WELLNESS_OPTIONS = ['yes', 'blocked', 'no_face', 'peripheral', 'frozen_image', 'unknown'];
const FRONT_FACING_WELLNESS_OPTIONS = [
    'yes',
    'too_low_or_too_high',
    'blocked',
    'low_or_bad_visibility',
    'frozen_image',
    'unknown',
];
const CONFIDENCE_OPTIONS = ['high', 'medium', 'low'];
const MISSING_VIDEO_OPTIONS = [
    'shutdown_in_proximity_to_the_accident',
    'video_skipped_and_accident_not_recorded',
    'no_events_on_this_day',
    'event_has_no_video',
    'impact_not_seen_in_video',
    'vehicle_not_protected',
    'did_not_search',
    'camera_not_aligned',
    'camera_blocked',
    'no_video_from_event_time',
    'incorrect_report_time',
    'passenger_body_injury',
    'accident_while_parked',
];

export const validateAccidentDetails = (accidentDetails: AccidentTagsV2Details): boolean => {
    return (
        accidentDetails.source.length > 0 &&
        accidentDetails.fault !== '' &&
        accidentDetails.speed !== '' &&
        accidentDetails.in_cabin_wellness !== '' &&
        accidentDetails.front_facing_wellness !== '' &&
        accidentDetails.confidence !== ''
    );
};

interface AccidentDetailsProps {
    accidentDetails: AccidentTagsV2Details;
    eventId?: number | null;
    setEventId: (event: React.ChangeEvent<HTMLInputElement>) => void;
    eventVideo?: string;
    setAccidentTags: React.Dispatch<React.SetStateAction<AccidentTagsV2Details>>;
    isComplete: boolean;
    onSave: (details: AccidentTagsV2Details, clear?: boolean) => void;
    onClose: () => void;
    isSaving: boolean;
    eventLevel?: string;
    setEventLevel: CallableFunction;
}

const AccidentDetails: React.FC<AccidentDetailsProps> = (props) => {
    const [video, setVideo] = React.useState<string | undefined>(props.eventVideo);
    const [event, setEvent] = React.useState<number>();
    const { t } = useTranslation();
    const { api } = useApi();
    const { profile } = useProfile();
    const speedUnit = profile.locale === 'il' ? 'kmh' : 'mph';
    const { accidentDetails, setAccidentTags, isComplete, onSave, onClose, isSaving } = props;

    useEffect(() => {
        if (!!event) {
            api.apiV0EventEventIdGet({ eventId: event })
                .then((res) => {
                    setVideo(
                        Object.entries(res.data.artifact_path)
                            .map(([fileName, fileUrl]) => (isEventVideo(fileName) ? fileUrl : ''))
                            .filter(Boolean)[0],
                    );
                    props.setEventLevel(res.data.level);
                })
                .catch(() => setVideo(undefined));
        }
    }, [event]);

    const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEvent(parseInt(event.target.value));
        props.setEventId(event);
    };

    useEffect(() => {
        if (props.eventVideo) {
            setAccidentTags((prev) => ({ ...prev, missing_video_reason: '' }));
        } else if (accidentDetails.missing_video_reason === '') {
            setAccidentTags((prev) => ({ ...prev, missing_video_reason: 'did_not_search' }));
        }
    }, [props.eventVideo]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '100%',
                width: '100%',
                overflowY: 'auto',
                p: 2,
            }}
        >
            <Autocomplete
                id="source-of-tagging-field"
                multiple
                disableCloseOnSelect
                size="small"
                options={SOURCE_OPTIONS}
                getOptionLabel={(option) =>
                    t(`content.accidents.tags.accident_details.source_of_tagging.${option.toLocaleLowerCase()}`)
                }
                renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ padding: 0 }}>
                        <Checkbox
                            id={`${option}-checkbox`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            size="small"
                        />
                        <Typography sx={{ pt: 0.5 }}>
                            {t(
                                `content.accidents.tags.accident_details.source_of_tagging.${option.toLocaleLowerCase()}`,
                            )}
                        </Typography>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t('content.accidents.tags.accident_details.source_of_tagging.header')}
                    />
                )}
                value={accidentDetails.source}
                onChange={(_event, value) => {
                    setAccidentTags((prev) => ({ ...prev, source: value }));
                }}
                sx={{ width: '250px', mb: 1 }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        id="not-an-accident-checkbox"
                        size="small"
                        disableRipple
                        checked={accidentDetails.not_an_accident}
                        onChange={(_, checked) => setAccidentTags((prev) => ({ ...prev, not_an_accident: checked }))}
                    />
                }
                label={t(`content.accidents.tags.accident_details.not_an_accident`)}
                sx={{ width: '250px', mb: 1.5 }}
            />
            <Typography variant="overline" sx={{ mb: 1 }}>
                {t('content.accidents.tags.accident_details.event_details')}
            </Typography>
            <TextField
                id="event-number-field"
                size="small"
                label={t('content.accidents.tags.accident_details.event_number')}
                value={props.eventId || ''}
                onChange={handleEventChange}
                sx={{ width: '250px', mb: 1 }}
                name="event"
                error={props.eventLevel === 'INFO'}
                helperText={
                    props.eventLevel === 'INFO' ? t('content.accidents.tags.accident_details.invalid_event') : null
                }
            />
            <Box sx={{ mb: 1, width: '100%', aspectRatio: '8 / 3' }}>
                {!!video ? (
                    <VideoComponent video={video} />
                ) : (
                    <img src={videoNA} style={{ width: '100%', aspectRatio: '8 / 3' }} />
                )}
            </Box>
            {!!video ? (
                <TextField
                    id="frame-number-field"
                    size="small"
                    label={t('content.accidents.tags.accident_details.frame_number')}
                    value={accidentDetails.frame_number}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setAccidentTags((prev) => ({ ...prev, frame_number: e.target.value }))
                    }
                    sx={{ width: '250px', mb: 2 }}
                />
            ) : (
                <FormControl size="small" sx={{ width: '250px', mb: 2 }}>
                    <InputLabel>{t('content.accidents.tags.accident_details.missing_video_reason.header')}</InputLabel>
                    <Select
                        id="missing-video-reason-field"
                        size="small"
                        label={t('content.accidents.tags.accident_details.missing_video_reason.header')}
                        value={accidentDetails.missing_video_reason}
                        onChange={(e: SelectChangeEvent) =>
                            setAccidentTags((prev) => ({ ...prev, missing_video_reason: e.target.value }))
                        }
                    >
                        {MISSING_VIDEO_OPTIONS.map((option) => (
                            <MenuItem id={`${option}-option`} key={option} value={option}>
                                {t(`content.accidents.tags.accident_details.missing_video_reason.${option}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <Typography variant="overline" sx={{ mb: 1 }}>
                {t('content.accidents.tags.accident_details.header')}
            </Typography>
            <FormControl size="small" sx={{ width: '250px', mb: 2 }}>
                <InputLabel>{t('content.accidents.tags.accident_details.fault.header')}</InputLabel>
                <Select
                    id="fault-field"
                    size="small"
                    label={t('content.accidents.tags.accident_details.fault.header')}
                    value={accidentDetails.fault}
                    onChange={(e: SelectChangeEvent) => setAccidentTags((prev) => ({ ...prev, fault: e.target.value }))}
                >
                    {FAULT_OPTIONS.map((option) => (
                        <MenuItem id={`${option}-option`} key={option} value={option}>
                            {t(`content.accidents.tags.accident_details.fault.${option}`)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: '250px', mb: 1 }}>
                <InputLabel>{t('content.accidents.tags.accident_details.speed.header')}</InputLabel>
                <Select
                    id="speed-field"
                    size="small"
                    label={t('content.accidents.tags.accident_details.speed.header')}
                    value={accidentDetails.speed}
                    onChange={(e: SelectChangeEvent) => setAccidentTags((prev) => ({ ...prev, speed: e.target.value }))}
                >
                    {SPEED_OPTIONS.map((option) => (
                        <MenuItem id={`${option}-option`} key={option} value={option}>
                            {t(`content.accidents.tags.accident_details.speed.${speedUnit}.${option}`)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        id="impact-visible-checkbox"
                        size="small"
                        disableRipple
                        checked={accidentDetails.clearly_visible}
                        onChange={(_, checked) => setAccidentTags((prev) => ({ ...prev, clearly_visible: checked }))}
                    />
                }
                label={t(`content.accidents.tags.accident_details.impact_visible`)}
                sx={{ width: '250px', mb: 1 }}
            />
            <Typography variant="overline" sx={{ mb: 1 }}>
                {t('content.accidents.tags.accident_details.wellness')}
            </Typography>
            <FormControl size="small" sx={{ width: '250px', mb: 2 }}>
                <InputLabel>{t('content.accidents.tags.accident_details.in_cabin_wellness.header')}</InputLabel>
                <Select
                    id="in-cabin-wellness-field"
                    size="small"
                    label={t('content.accidents.tags.accident_details.in_cabin_wellness.header')}
                    value={accidentDetails.in_cabin_wellness}
                    onChange={(e: SelectChangeEvent) =>
                        setAccidentTags((prev) => ({ ...prev, in_cabin_wellness: e.target.value }))
                    }
                >
                    {IN_CABIN_WELLNESS_OPTIONS.map((option) => (
                        <MenuItem id={`${option}-option`} key={option} value={option}>
                            {t(`content.accidents.tags.accident_details.in_cabin_wellness.${option}`)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: '250px', mb: 2 }}>
                <InputLabel>{t('content.accidents.tags.accident_details.front_facing_wellness.header')}</InputLabel>
                <Select
                    id="front-facing-wellness-field"
                    size="small"
                    label={t('content.accidents.tags.accident_details.front_facing_wellness.header')}
                    value={accidentDetails.front_facing_wellness}
                    onChange={(e: SelectChangeEvent) =>
                        setAccidentTags((prev) => ({ ...prev, front_facing_wellness: e.target.value }))
                    }
                >
                    {FRONT_FACING_WELLNESS_OPTIONS.map((option) => (
                        <MenuItem id={`${option}-option`} key={option} value={option}>
                            {t(`content.accidents.tags.accident_details.front_facing_wellness.${option}`)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: '250px', mt: 2, mb: 2 }}>
                <InputLabel>{t('content.accidents.tags.accident_details.confidence.header')}</InputLabel>
                <Select
                    id="confidence-field"
                    size="small"
                    label={t('content.accidents.tags.accident_details.confidence.header')}
                    value={accidentDetails.confidence}
                    onChange={(e: SelectChangeEvent) =>
                        setAccidentTags((prev) => ({ ...prev, confidence: e.target.value }))
                    }
                >
                    {CONFIDENCE_OPTIONS.map((option) => (
                        <MenuItem id={`${option}-option`} key={option} value={option}>
                            {t(`content.accidents.tags.accident_details.confidence.${option}`)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                id="save-and-continue-btn"
                disabled={!isComplete || isSaving}
                variant="contained"
                onClick={() => onSave(accidentDetails)}
                sx={{ mb: 1 }}
            >
                {t('content.accidents.tags.save_and_continue')}
            </Button>
            <Button
                id="save-and-exit-btn"
                disabled={!isComplete || isSaving}
                variant="contained"
                color="secondary"
                onClick={() => onSave(accidentDetails, true)}
                sx={{ mb: 1 }}
            >
                {t('content.accidents.tags.save_and_exit')}
            </Button>
            <Button id="cancel-btn" disabled={isSaving} variant="contained" color="redColor" onClick={() => onClose()}>
                {t('content.accidents.tags.cancel')}
            </Button>
        </Box>
    );
};

export default AccidentDetails;
