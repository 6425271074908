export const damageCauses = [
    {
        driver_error: [
            {
                law_violations: [
                    'wrong_way_driving',
                    'not_stopping_at_stop_sign',
                    'running_red_light',
                    'illegal_u_turn',
                    'turning_from_wrong_lane',
                    'not_yielding_to_pedestrians',
                    'not_yielding_to_vehicle',
                    'other_violation',
                ],
            },
            {
                distracted_driving: [
                    'using_mobile_phone',
                    'eating_or_drinking',
                    'talking_to_passengers',
                    'adjusting_radio_navigation',
                    'external_distractions',
                    'paperwork',
                    'other_internal_distraction',
                ],
            },
            {
                fatigue: ['drowsy_driving', 'falling_asleep_at_wheel'],
            },
            {
                speeding: ['exceeding_speed_limit', 'too_fast_for_conditions'],
            },
            {
                aggressive_driving: ['tailgating', 'other_aggressive_driving'],
            },
            { misjudgment_of_speed_or_dimensions: ['misjudgment_of_speed', 'misjudgment_of_dimensions'] },
            'driving_under_influence',
            'vehicle_operation_mistake',
        ],
    },
    {
        extreme_environmental_conditions: [
            'vehicle_slipped',
            'damage_to_vehicle_due_to_potholes_or_bad_road',
            'heavy_wind',
            'objects_or_animals_on_the_road',
        ],
    },
    {
        mechanical_failure: ['brake_failure', 'tire_blowout', 'other_mechanical_failure'],
    },
    {
        other_road_users: ['another_vehicles_mechanical_failure', 'pedestrian_error', 'another_vehicles_driver_error'],
    },
    {
        visibility_issues: ['blind_zone', 'poor_light_conditions', 'obstructed_view'],
    },
    'passengers_fault',
    'fire',
    'theft',
    'intentional_damage_by_driver',
    'intentional_damage_by_third_party',
    'other_damage_while_vehicle_was_idle_or_off',
    'unknown',
];

export const pointsOfImpact = [
    {
        point_of_impact: ['graph', 'roof', 'undercarriage', 'no_impact', 'unknown'],
    },
];

export const impactedObjects = [
    {
        impacted_object: [
            'driver_of_insured_vehicle',
            'passenger_of_insured_vehicle',
            'third_party_vehicle',
            'third_party_driver_or_passenger',
            'pedestrian',
            'other',
            'unknown',
            'none',
        ],
    },
];
