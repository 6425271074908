export interface MapData {
    location: [number, number];
    description: string;
    events: [string, number, number, number][];
}

interface ExpiryData {
    driverName: string;
    expiryDate: string;
    expired: boolean;
}

export interface DataItem {
    id: number;
    type: string;
    header: string;
    content: string[][];
    actions: string[];
    dismissed: boolean;
    map?: MapData;
    expiryData?: ExpiryData;
    licensePlate?: string;
    coachingSession?: number;
    collapsedActions?: boolean;
    imageWidth?: string;
}

export const ITEM_TYPES_LIST = [
    'INSURANCE_INSIGHTS',
    'DRIVER_LICENSE_EXPIRED',
    'DRIVER_LICENSE_EXPIRING',
    'DRIVER_ACCIDENTS_CURRENT_YEAR',
    'DRIVER_ACCIDENTS_LONG',
    'SAFETY_EVENTS_CLUSTERS',
    'SAFETY_EVENTS_CLUSTERS_BUS',
    'SAFETY_CELLPHONE_USAGE',
    'SAFETY_CELLPHONE_USAGE_BUS',
    'SAFETY_AGGRESSIVE_DRIVING',
    'SAFETY_AGGRESSIVE_DRIVING_BUS',
    'WELLNESS_INCABIN_COVER',
];

export type ItemType = typeof ITEM_TYPES_LIST[number];

export const PROHUB_MIXPANEL_SESSION = new Date().getTime();
