import L, { PointExpression } from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import React, { useEffect, useState } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useMap } from 'react-leaflet';
import { useHistory } from 'react-router-dom';

import { DeviceV3 } from '../../../backendsdk';
import offlineSvg from '../../../images/markers/overview/marker-offline.svg';
import onlineSvg from '../../../images/markers/overview/marker-online.svg';
import selectedSvg from '../../../images/markers/overview/marker-selected.svg';
import { isLocationValid, parseLocation } from '../../../utils/location';
import palette from '../../ColorPalette';

const overlap = (rect1: DOMRect, rect2: DOMRect) => {
    return !(
        rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom
    );
};

export const hideOverlappingLabels = () => {
    const spiderfiedMarkers = document.querySelectorAll('.no-hide');
    const otherMarkers = document.querySelectorAll('.map-marker:not(.no-hide)');
    const allMarkers = Array.from(spiderfiedMarkers).concat(Array.from(otherMarkers));

    const res = Array(allMarkers.length).fill('visible');
    const rects = Array.from(allMarkers).map((m) => m.getBoundingClientRect());
    for (let i = 0; i < rects.length; i++) {
        if (res[i] != 'hidden') {
            for (let j = i + 1; j < rects.length; j++) {
                if (i !== j && overlap(rects[i], rects[j]) && !allMarkers[j].classList.contains('no-hide')) {
                    res[j] = 'hidden';
                }
            }
        }
    }
    for (let i = 0; i < allMarkers.length; i++) {
        (allMarkers[i] as HTMLElement).style.visibility = res[i];
    }
};

export const getIcon = (label: string, status: 'online' | 'offline' | 'selected', clickable = true) => {
    const iconSize = !!label ? [25, 58] : [25, 25];
    return L.divIcon({
        html: ReactDOMServer.renderToStaticMarkup(
            <div
                className={`${status}-map-marker`}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: clickable ? 'pointer' : 'grab',
                }}
            >
                {!!label ? (
                    <div
                        className="map-marker"
                        style={{
                            backgroundColor: palette.primary,
                            color: palette.white,
                            borderRadius: '5px',
                            border: `2px solid ${palette.neutral[500]}`,
                            padding: '0 5px',
                            marginBottom: '3px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {label}
                    </div>
                ) : null}
                <img src={status === 'selected' ? selectedSvg : status === 'online' ? onlineSvg : offlineSvg} />
            </div>,
        ),
        iconSize: iconSize as PointExpression,
        iconAnchor: [iconSize[0] / 2, iconSize[1]],
        className: 'div-icon',
    });
};

interface MapIconsProps {
    devices: DeviceV3[];
    hoveredDeviceId?: string;
    isClustered: boolean;
    isGeofenceMode: boolean;
}

export const MapIcons: React.FC<MapIconsProps> = ({ devices, hoveredDeviceId, isClustered, isGeofenceMode }) => {
    const map = useMap();
    const [mapZoom, setMapZoom] = useState<number>(map.getZoom());
    const history = useHistory();

    useEffect(() => {
        const zoomChangeTrack = () =>
            setMapZoom((prev) => {
                const newMapZoom = map.getZoom();
                if (prev != newMapZoom) {
                    if (isClustered) {
                        // wait for transition to end
                        setTimeout(hideOverlappingLabels, 200);
                    } else {
                        hideOverlappingLabels();
                    }
                }
                return newMapZoom;
            });
        map.on('zoom', zoomChangeTrack);

        return () => {
            map.off('zoom', zoomChangeTrack);
        };
    }, [map, mapZoom]);

    useEffect(() => {
        if (!map) return;

        const markers: L.Marker[] = [];
        const markersLayer = L.markerClusterGroup({ spiderfyDistanceMultiplier: 2.5 });

        // hide overlapping labels when markers are spiderfied and unspiderfied
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        markersLayer.on('spiderfied', function (cluster: any) {
            for (const marker of cluster.markers) {
                // make sure labels of unspiderfied markers are not hidden
                marker._icon.querySelector('.map-marker').classList.add('no-hide');
            }
            // wait for transition to end
            setTimeout(hideOverlappingLabels, 200);
        });
        markersLayer.on('unspiderfied', function () {
            // wait for transition to end
            setTimeout(hideOverlappingLabels, 200);
        });

        for (const device of devices.filter((device) => isLocationValid(device.location))) {
            const divIcon = getIcon(
                device.device.license_plate,
                !!device.current_trip ? 'online' : 'offline',
                !isGeofenceMode,
            );
            const marker = L.marker(parseLocation(device.location), { icon: divIcon });
            if (!isGeofenceMode) {
                marker.on('click', () => {
                    history.push(`/overview/${encodeURIComponent(device.device.license_plate)}`);
                });
            }
            if (isClustered) {
                markersLayer.addLayer(marker);
            } else {
                markers.push(marker.addTo(map));
            }
        }

        if (isClustered) {
            map.addLayer(markersLayer);
            return () => markersLayer.remove();
        } else {
            hideOverlappingLabels();
            return () => {
                markers.forEach((marker) => marker.remove());
            };
        }
    }, [map, devices, isClustered, isGeofenceMode]);

    useEffect(() => {
        if (!map) return;

        if (hoveredDeviceId) {
            const hoveredDevice = devices.find((device) => device.device.device_id === hoveredDeviceId);
            if (hoveredDevice && isLocationValid(hoveredDevice.location)) {
                const hoveredDivIcon = getIcon(hoveredDevice.device.license_plate, 'selected');
                const hoveredMarker = L.marker(parseLocation(hoveredDevice.location), {
                    icon: hoveredDivIcon,
                    zIndexOffset: 1000,
                }).addTo(map);
                return () => {
                    hoveredMarker.remove();
                };
            }
        }
    }, [map, devices, hoveredDeviceId]);

    return null;
};

interface SelectedMapIconProps {
    devices: DeviceV3[];
    selectedDeviceId?: string;
}

export const SelectedMapIcon: React.FC<SelectedMapIconProps> = ({ devices, selectedDeviceId }) => {
    const map = useMap();

    useEffect(() => {
        if (!map) return;

        if (selectedDeviceId) {
            const selectedDevice = devices.find((device) => device.device.device_id === selectedDeviceId);
            if (selectedDevice && isLocationValid(selectedDevice.location)) {
                const deviceLocation = parseLocation(selectedDevice.location);
                const selectedDivIcon = getIcon(selectedDevice.device.license_plate, 'selected');
                const selectedMarker = L.marker(deviceLocation, {
                    icon: selectedDivIcon,
                    zIndexOffset: 1000,
                }).addTo(map);
                return () => {
                    selectedMarker.remove();
                };
            }
        }
    }, [map, devices, selectedDeviceId]);

    useEffect(() => {
        if (selectedDeviceId) {
            const selectedDevice = devices.find((device) => device.device.device_id === selectedDeviceId);
            if (selectedDevice && isLocationValid(selectedDevice.location)) {
                const deviceLocation = parseLocation(selectedDevice.location);
                map.panTo(deviceLocation);
            }
        }
    }, [selectedDeviceId]);

    return null;
};
