import { Box, Card, List, Typography, alpha } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent';

import {
    TrackedButton as Button,
    TrackedListItemButton as ListItemButton,
} from '../../../../components/TrackedComponents';
import { RTLDirectionContext } from '../../../Layout';
import { Tree, countOccurrences, overviewDamageCauses } from './utils';

export const PIE_COLORS = ['#ff8686', '#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a1c77b', '#ffc658', '#ff9e57'];

interface CustomTooltipProps extends TooltipProps<number, string> {
    units: string;
    translationPrefix?: string;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = (props: CustomTooltipProps) => {
    const { t } = useTranslation();

    const payload = props.payload?.[0]?.payload;
    if (payload === undefined) {
        return null;
    }
    return (
        <Card
            id="mouse-over-tooltip"
            sx={{ maxHeight: 250, p: 1, display: 'flex', flexDirection: 'column', direction: 'ltr' }}
        >
            <Typography fontSize={12} sx={{ fontWeight: 'bold' }}>
                {props.translationPrefix
                    ? `${t(`${props.translationPrefix}.${payload.name}`, payload.name)}: `
                    : `${payload.name}:`}
            </Typography>
            <Typography fontSize={12}>{`${payload.value} ${t(props.units)}`}</Typography>
        </Card>
    );
};

interface CustomLegendProps extends LegendProps {
    setTreeStack: CallableFunction;
    setHoveredCause: CallableFunction;
}

const CustomLegend: React.FC<CustomLegendProps> = (props: CustomLegendProps) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                mt: '25px',
                ml: '25px',
                direction: 'ltr',
            }}
        >
            <List disablePadding sx={{ width: '100%' }}>
                {(props.payload || []).map((p) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const payload = p?.payload as any;
                    const entry = payload?.payload;
                    return (
                        <ListItemButton
                            id={`${payload.name}-legend-item`}
                            data-testid={`${payload.name}-legend-item`}
                            key={`${payload.name}-legend`}
                            disableGutters
                            disableRipple={entry === undefined || !entry.clickable}
                            onMouseEnter={() => props.setHoveredCause(payload.name)}
                            onMouseLeave={() => props.setHoveredCause(undefined)}
                            onClick={() => {
                                if (entry !== undefined && entry.clickable) {
                                    props.setTreeStack((prev: Tree[]) => [
                                        ...prev,
                                        { category: entry.name, tree: entry.subtree },
                                    ]);
                                }
                            }}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                py: 0.25,
                                px: 1,
                                cursor: entry?.clickable ? 'pointer' : 'default',
                            }}
                        >
                            <Box sx={{ width: 15, height: 15, mr: 0.5, flexShrink: 0 }}>
                                <svg
                                    viewBox="0 0 15 15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ overflow: 'visible' }}
                                >
                                    <circle cx="7.5" cy="7.5" r="7.5" fill={payload.fill} />
                                </svg>
                            </Box>
                            <Typography fontSize={12}>
                                {t(`content.accidents.tags.damage_cause.${payload.name}`)}
                            </Typography>
                        </ListItemButton>
                    );
                })}
            </List>
        </Box>
    );
};

interface TreeStack {
    category: string;
    tree: Tree;
}

interface AccidentCausesChartProps {
    causes: Record<string, number>;
}

const AccidentCausesChart: React.FC<AccidentCausesChartProps> = (props: AccidentCausesChartProps) => {
    const [treeStack, setTreeStack] = useState<TreeStack[]>([{ category: 'all', tree: overviewDamageCauses }]);
    const [hoveredCause, setHoveredCause] = useState<string>();
    const { t } = useTranslation();
    const isRTL = useContext(RTLDirectionContext);

    const currentTree = treeStack[treeStack.length - 1].tree;
    let data = currentTree.map((category) => {
        const isLeaf = typeof category === 'string';
        const categoryName = isLeaf ? category : Object.keys(category)[0];
        const categoryCauses = isLeaf ? [category] : Object.values(category)[0];
        return {
            name: categoryName,
            subtree: categoryCauses,
            clickable: !isLeaf,
            value: countOccurrences(categoryCauses, props.causes),
        };
    });
    data = data.filter((entry) => entry.value > 0);
    const margin = isRTL ? { right: 35 } : { left: 35 };

    return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
            {treeStack.length > 1 ? (
                <Button
                    id="back-btn"
                    data-testid="back-btn"
                    size="small"
                    variant="contained"
                    color="primary"
                    sx={{ position: 'absolute', top: '25px', zIndex: 1 }}
                    onClick={() => setTreeStack((prev) => prev.slice(0, -1))}
                >
                    {t('content.accident_overview.back')}
                </Button>
            ) : null}
            {data !== undefined ? (
                <ResponsiveContainer width="100%" height="100%" className="chart-container">
                    <PieChart id="accident-causes-chart" margin={margin}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            data={data}
                            cx="50%"
                            cy="50%"
                            outerRadius={150}
                            label={(value) => `${(value.percent * 100).toFixed(0)}%`}
                            onMouseEnter={(e) => setHoveredCause(e.name)}
                            onMouseLeave={() => setHoveredCause(undefined)}
                            paddingAngle={1}
                            innerRadius={80}
                        >
                            <Label
                                value={t(
                                    `content.accidents.tags.damage_cause.${treeStack[treeStack.length - 1].category}`,
                                )}
                                position="center"
                                width={100}
                            />
                            {data.map((entry, index) => {
                                const fill = PIE_COLORS[index % PIE_COLORS.length];
                                return (
                                    <Cell
                                        fill={
                                            !!hoveredCause ? alpha(fill, entry.name === hoveredCause ? 1 : 0.6) : fill
                                        }
                                        key={`cell-${index}`}
                                        onClick={() => {
                                            if (entry.clickable) {
                                                setTreeStack((prev) => [
                                                    ...prev,
                                                    { category: entry.name, tree: entry.subtree },
                                                ]);
                                            }
                                        }}
                                        style={{
                                            cursor: entry.clickable ? 'pointer' : 'default',
                                            transition: 'fill 0.2s',
                                        }}
                                    />
                                );
                            })}
                        </Pie>
                        <Tooltip
                            content={
                                <CustomTooltip
                                    translationPrefix="content.accidents.tags.damage_cause"
                                    units="content.accident_overview.accidents_label"
                                />
                            }
                        />
                        <Legend
                            content={<CustomLegend setTreeStack={setTreeStack} setHoveredCause={setHoveredCause} />}
                            layout="vertical"
                            align={isRTL ? 'left' : 'right'}
                            verticalAlign="top"
                            width={200}
                        />
                    </PieChart>
                </ResponsiveContainer>
            ) : null}
        </Box>
    );
};

export default AccidentCausesChart;
