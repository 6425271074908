export const validatePassword = (password1: string, password2: string): [boolean, string] => {
    const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
    if (password1 !== password2) {
        return [false, 'login.validation.match'];
    } else if (password1.length < 6) {
        return [false, 'login.validation.length'];
    } else if (!pattern.test(password1)) {
        return [false, 'login.validation.content'];
    }
    return [true, ''];
};
