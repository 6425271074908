import { Box, Typography } from '@mui/material';
import React from 'react';

import Logo from '../../../images/logo/logo_mini.svg';
import LogoText from '../../../images/logo/logo_secondary.svg';

const PremiumOptimizationPageHeader: React.FC = () => {
    return (
        <Box
            sx={{
                width: '100%',
                pt: 1,
                height: 'clamp(175px, 20vh, 230px)',
                backgroundColor: '#515151',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            <Box sx={{ width: '30%', direction: 'rtl' }}>
                <img
                    data-testid="logo-image"
                    src={Logo}
                    style={{
                        width: '20%',
                        marginRight: 20,
                        objectFit: 'contain',
                        filter: 'grayscale(1)',
                    }}
                />
            </Box>
            <Box
                sx={{
                    width: '40%',
                    color: '#b7b7b7',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }}
            >
                <img
                    data-testid="logo-text"
                    src={LogoText}
                    style={{ width: '100%', objectFit: 'contain', filter: 'grayscale(1)' }}
                />
                <Typography variant="h5">PREMIUM OPTIMIZATION HUB</Typography>
                <Typography variant="h5" style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                    SEE • ACT • SAVE
                </Typography>
            </Box>
            <Box sx={{ width: '30%' }} />
        </Box>
    );
};

export default PremiumOptimizationPageHeader;
