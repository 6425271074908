import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Autocomplete,
    Box,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AlertOrReport, DeviceV2 } from '../../../backendsdk';
import { ResponsiveTimePicker } from '../../../components/MuiDatePicker';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useDrivers from '../../../hooks/drivers';
import useProfile from '../../../hooks/profile';
import { reportsPageDefs } from '../../../utils/Pages';
import { daysNames } from '../../../utils/TimeFormatter';
import {
    AccidentReportForm,
    AlertReport,
    DeviceWellnessForm,
    GeofenceEventForm,
    Report,
    SEVERITY_FILTER_OPTIONS,
    SafetyEventForm,
} from './ReportOptions';

dayjs.extend(utc);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const uniq = (value: string | number, index: number, self: Array<string | number>): boolean => {
    return value != '' && self.indexOf(value) === index;
};

export interface BaseAlertReport {
    pk: number;
    report_type: string;
    filters: Record<string, Array<string>>;
    is_report: boolean;
    active: boolean;
}

export const SCOPE_OPTIONS = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

export const SCHEDULING_OPTIONS = {
    ALERT: 'ALERT',
    REPORT: 'REPORT',
};

export const DEVICE_FILTER_OPTIONS: Record<string, string> = {
    ALL: 'ALL',
    DRIVER: 'DRIVER',
    LICENSE_PLATE: 'LICENSE_PLATE',
    DEPARTMENT: 'DEPARTMENT',
} as const;

export const REPORT_TYPE_OPTIONS: Record<string, string> = {
    SAFETY_EVENTS: 'SAFETY_EVENTS',
    GEOFENCE_EVENTS: 'GEOFENCE_EVENTS',
    DEVICE_WELLNESS: 'DEVICE_WELLNESS',
    INACTIVE_DEVICES: 'INACTIVE_DEVICES',
    ACCIDENTS_REPORT: 'ACCIDENTS_REPORT',
    DRIVER_SCORES: 'DRIVER_SCORES',
    OPERATION_REPORT: 'OPERATION_REPORT',
} as const;

export const FILTER_AVIALABLE: Record<string, readonly string[]> = {
    [DEVICE_FILTER_OPTIONS.ALL]: Object.values(REPORT_TYPE_OPTIONS),
    [DEVICE_FILTER_OPTIONS.DEPARTMENT]: Object.values(REPORT_TYPE_OPTIONS),
    [DEVICE_FILTER_OPTIONS.LICENSE_PLATE]: Object.values(REPORT_TYPE_OPTIONS),
    [DEVICE_FILTER_OPTIONS.DRIVER]: [
        REPORT_TYPE_OPTIONS.SAFETY_EVENTS,
        REPORT_TYPE_OPTIONS.GEOFENCE_EVENTS,
        REPORT_TYPE_OPTIONS.DEVICE_WELLNESS,
        REPORT_TYPE_OPTIONS.INACTIVE_DEVICES,
        REPORT_TYPE_OPTIONS.DRIVER_SCORES,
        REPORT_TYPE_OPTIONS.OPERATION_REPORT,
    ],
} as const;

export const deviceFilterKey = (filters: Record<string, Array<string>>): string => {
    const key = Object.keys(filters).filter((key) => Object.values(DEVICE_FILTER_OPTIONS).includes(key));
    if (key.length > 0) {
        return key[0];
    }
    return DEVICE_FILTER_OPTIONS.ALL;
};

export const deviceFilter = (filters: Record<string, Array<string>>): [string, Array<string>] => {
    const key = deviceFilterKey(filters);
    return [key, filters[key] ?? []];
};

export const getExtraFilters = (a: AlertReport): Array<[string, Array<string>]> => {
    return Object.entries(a.filters)
        .reverse()
        .filter(([key, values]) => !Object.values(DEVICE_FILTER_OPTIONS).includes(key) && values.length > 0);
};

export interface CommentDetails {
    comment_id: number;
    created_time: number;
    user: string;
    text: string;
}

export interface ReportAlertDetailsComponentProps {
    details: AlertReport;
    devices: Array<DeviceV2>;
    geofences: Record<string, string>;
    onClose: CallableFunction;
    fromOtherPage?: boolean;
}

export interface ReportAlertFormProps {
    details: AlertReport;
    handleChange: CallableFunction;
    devices: Array<DeviceV2>;
    geofences: Record<string, string>;
    setIsError: CallableFunction;
    setDetailsChanged: CallableFunction;
}

const ReportAlertForm: React.FC<ReportAlertFormProps> = (props: ReportAlertFormProps) => {
    const { profile } = useProfile();
    const { t } = useTranslation();
    const [reportTime, setReportTime] = useState<Dayjs | null>(
        props.details.is_report
            ? dayjs(props.details.report_time, 'HH:mm:ss').add(dayjs().utcOffset(), 'minute')
            : null,
    );
    const { drivers } = useDrivers();
    const ALLOW_VIEW_ALL_SUBFLEETS = [...reportsPageDefs.permissions.L1];
    const ALLOW_ALL_REPORT_TYPES = [...reportsPageDefs.permissions.L1, ...reportsPageDefs.permissions.L2];

    const keys = props.details.filters == undefined ? [] : Object.keys(props.details.filters);
    const deviceFilterKey =
        Object.keys(DEVICE_FILTER_OPTIONS).find((key) => keys.includes(key)) ?? DEVICE_FILTER_OPTIONS.ALL;

    const filteredDeviceFilterOptions = DEVICE_FILTER_OPTIONS;
    if (!ALLOW_VIEW_ALL_SUBFLEETS.includes(profile.role) && profile.sub_fleet !== '') {
        delete filteredDeviceFilterOptions.DEPARTMENT;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (target: string, value: any) => {
        props.setDetailsChanged(true);
        props.handleChange((oldDetails: AlertReport) => {
            if (oldDetails) {
                const newDetails = Object.fromEntries(
                    Object.entries(oldDetails).map(([key, origin_value]) => [
                        key,
                        key === target ? value : origin_value,
                    ]),
                );
                newDetails[target] = value;
                return newDetails;
            }
        });
    };

    useEffect(() => {
        if (props.details.is_report) {
            setReportTime(dayjs(props.details.report_time, 'HH:mm:ss').add(dayjs().utcOffset(), 'minute'));
        }
    }, [(props.details as Report).report_time]);

    const deviceFilterOptions = useMemo(() => {
        const options: Record<string, string[]> = { [DEVICE_FILTER_OPTIONS.ALL]: ['ALL'] };
        const filteredDevices =
            ALLOW_VIEW_ALL_SUBFLEETS.includes(profile.role) || profile.sub_fleet === ''
                ? props.devices
                : props.devices.filter((d) => d.device.customer_sub_fleet === profile.sub_fleet);
        options[DEVICE_FILTER_OPTIONS.LICENSE_PLATE] = filteredDevices
            .map((d) => d.device.license_plate)
            .filter(uniq)
            .sort((a, b) => a.localeCompare(b));
        options[DEVICE_FILTER_OPTIONS.DEPARTMENT] = props.devices
            .map((d) => d.device.customer_sub_fleet ?? '')
            .filter(uniq)
            .sort((a, b) => a.localeCompare(b));
        options[DEVICE_FILTER_OPTIONS.DRIVER] = drivers
            .filter((d) => !d.is_auto_generated && d.active)
            .map((d) => d.fullName)
            .filter(uniq)
            .sort((a, b) => a.localeCompare(b));

        return options;
    }, [deviceFilterKey, profile, props.devices, drivers]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
                {ALLOW_ALL_REPORT_TYPES.includes(profile.role) ? (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="type-dropdown-label">{t('content.reports.form.type.header')}</InputLabel>
                        <Select
                            id="type-dropdown"
                            labelId="type-dropdown-label"
                            fullWidth
                            size="small"
                            label="Type"
                            value={props.details.report_type}
                            onChange={(e: SelectChangeEvent) => {
                                handleChange('filters', {});
                                handleChange('report_type', e.target.value);
                                if (
                                    [REPORT_TYPE_OPTIONS.DRIVER_SCORES, REPORT_TYPE_OPTIONS.OPERATION_REPORT].includes(
                                        e.target.value,
                                    )
                                ) {
                                    handleChange('is_report', true);
                                }
                            }}
                        >
                            {Object.keys(REPORT_TYPE_OPTIONS).map((option) => (
                                <MenuItem key={option} value={option} id={option}>
                                    {t(`content.reports.form.type.${option.toLocaleLowerCase()}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : null}
                <Typography variant="overline" sx={{ mb: 0.5 }}>
                    {t('content.reports.form.filters')}
                </Typography>
                <FormControl fullWidth sx={{ mb: 1.5 }}>
                    <InputLabel id="filter-vehicles-dropdown-label">
                        {t('content.reports.form.filter.filters_header')}
                    </InputLabel>
                    <Select
                        id="filter-vehicles-dropdown"
                        labelId="filter-vehicles-dropdown-label"
                        fullWidth
                        size="small"
                        label={t('content.reports.form.filter.filters_header')}
                        value={deviceFilterKey}
                        onChange={(e: SelectChangeEvent) => {
                            const newFilter = { ...props.details.filters };
                            for (const key of Object.keys(DEVICE_FILTER_OPTIONS)) {
                                delete newFilter[key];
                            }
                            newFilter[e.target.value] = [];
                            handleChange('filters', newFilter);
                        }}
                    >
                        {Object.keys(DEVICE_FILTER_OPTIONS)
                            .filter((option) => FILTER_AVIALABLE[option].includes(props.details.report_type))
                            .map((option) => {
                                return (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        id={option}
                                        disabled={!deviceFilterOptions[option]?.length}
                                    >
                                        {t(`content.reports.form.filter.${option.toLocaleLowerCase()}`)}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                {deviceFilterKey == DEVICE_FILTER_OPTIONS.ALL ? null : (
                    <Autocomplete
                        id="filter-vehicles-values-autocomplete"
                        data-testid="filter-vehicles-values-autocomplete"
                        multiple
                        disableCloseOnSelect
                        size="small"
                        options={deviceFilterOptions[deviceFilterKey] || []}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} style={{ padding: 0 }}>
                                <Checkbox
                                    id={`${option}-checkbox`}
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                    size="small"
                                />
                                <Typography sx={{ pt: 0.5 }}>{option}</Typography>
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t(`content.reports.form.filter.${deviceFilterKey.toLocaleLowerCase()}`)}
                            />
                        )}
                        onChange={(_event, value) => {
                            const newFilter = { ...props.details.filters };
                            delete newFilter[DEVICE_FILTER_OPTIONS.LICENSE_PLATE];
                            delete newFilter[DEVICE_FILTER_OPTIONS.DEPARTMENT];
                            newFilter[deviceFilterKey] = value;
                            handleChange('filters', newFilter);
                        }}
                        value={props.details.filters[deviceFilterKey]}
                        sx={{ mb: 2 }}
                    />
                )}
                {props.details.report_type == REPORT_TYPE_OPTIONS.SAFETY_EVENTS && (
                    <SafetyEventForm handleChange={handleChange} details={props.details} />
                )}
                {props.details.report_type == REPORT_TYPE_OPTIONS.GEOFENCE_EVENTS && (
                    <GeofenceEventForm
                        geofences={props.geofences}
                        handleChange={handleChange}
                        details={props.details}
                    />
                )}
                {props.details.report_type == REPORT_TYPE_OPTIONS.DEVICE_WELLNESS && (
                    <DeviceWellnessForm handleChange={handleChange} details={props.details} />
                )}
                {props.details.report_type == REPORT_TYPE_OPTIONS.ACCIDENTS_REPORT && (
                    <AccidentReportForm handleChange={handleChange} details={props.details} />
                )}
                <Typography variant="overline" sx={{ mb: 0.5 }}>
                    {t('content.reports.form.schedule')}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        mb: props.details.is_report ? 1.5 : 0,
                        height: props.details.is_report ? undefined : 92,
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel id="scheduling-dropdown-label">{t('content.reports.form.scheduling')}</InputLabel>
                        <Select
                            id="scheduling-dropdown"
                            labelId="scheduling-dropdown-label"
                            disabled={
                                props.details.pk != 0 ||
                                [REPORT_TYPE_OPTIONS.DRIVER_SCORES, REPORT_TYPE_OPTIONS.OPERATION_REPORT].includes(
                                    props.details.report_type,
                                )
                            }
                            fullWidth
                            size="small"
                            label={t('content.reports.form.scheduling')}
                            value={
                                props.details.is_report
                                    ? SCHEDULING_OPTIONS.REPORT.toLocaleLowerCase()
                                    : SCHEDULING_OPTIONS.ALERT.toLocaleLowerCase()
                            }
                            onChange={(e: SelectChangeEvent) => {
                                if (e.target.value == SCHEDULING_OPTIONS.ALERT.toLocaleLowerCase()) {
                                    props.setIsError(false);
                                }
                                handleChange(
                                    'is_report',
                                    e.target.value == SCHEDULING_OPTIONS.REPORT.toLocaleLowerCase(),
                                );
                            }}
                        >
                            {[
                                SCHEDULING_OPTIONS.REPORT.toLocaleLowerCase(),
                                SCHEDULING_OPTIONS.ALERT.toLocaleLowerCase(),
                            ].map((option) => (
                                <MenuItem key={option} value={option} id={option}>
                                    {t(`content.reports.${option}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {props.details.is_report ? (
                        <FormControl fullWidth sx={{ ml: 1.5 }}>
                            <InputLabel id="scope-dropdown-label">{t('content.reports.form.scope.header')}</InputLabel>
                            <Select
                                id="scope-dropdown"
                                labelId="scope-dropdown-label"
                                fullWidth
                                size="small"
                                label={t('content.reports.form.scope.header')}
                                value={props.details.report_scope}
                                onChange={(e: SelectChangeEvent) => {
                                    handleChange('report_scope', e.target.value);
                                    handleChange('report_day', e.target.value === SCOPE_OPTIONS.WEEKLY ? 0 : 1);
                                }}
                            >
                                {Object.keys(SCOPE_OPTIONS).map((option) => (
                                    <MenuItem key={option} value={option} id={option}>
                                        {t(`content.reports.form.scope.${option.toLocaleLowerCase()}`)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            fullWidth
                            size="small"
                            type="number"
                            id="delay-field"
                            label={t('content.reports.form.delay')}
                            value={
                                props.details.cooldown_minutes != undefined
                                    ? props.details.cooldown_minutes.toString()
                                    : 15
                            }
                            onChange={(e) => handleChange('cooldown_minutes', parseInt(e.target.value) || 0)}
                            helperText={t('content.reports.form.delay_help')}
                            sx={{
                                ml: 1,
                                '& input[type=number]': {
                                    MozAppearance: 'textfield',
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                            }}
                        />
                    )}
                </Box>
                {props.details.is_report ? (
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        {props.details.report_scope.toLocaleLowerCase() == SCOPE_OPTIONS.WEEKLY.toLocaleLowerCase() ? (
                            <FormControl fullWidth sx={{ mr: 1.5 }}>
                                <InputLabel id="day-dropdown-label">{t('time.day.header')}</InputLabel>
                                <Select
                                    id="day-dropdown"
                                    labelId="day-dropdown-label"
                                    fullWidth
                                    size="small"
                                    label={t('time.day.header')}
                                    value={daysNames[props.details.report_day]}
                                    onChange={(e: SelectChangeEvent) => {
                                        handleChange('report_day', daysNames.indexOf(e.target.value) ?? 0);
                                    }}
                                >
                                    {daysNames.map((option) => (
                                        <MenuItem key={option} value={option} id={option}>
                                            {t(`time.day.${option.toLocaleLowerCase()}`)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : null}
                        {props.details.report_scope.toLocaleLowerCase() == SCOPE_OPTIONS.MONTHLY.toLocaleLowerCase() ? (
                            <FormControl fullWidth sx={{ mr: 1.5 }}>
                                <InputLabel>{t('time.day.header')}</InputLabel>
                                <Select
                                    id="day-dropdown"
                                    fullWidth
                                    size="small"
                                    label={t('time.day.header')}
                                    value={props.details.report_day.toString()}
                                    onChange={(e: SelectChangeEvent) => {
                                        handleChange('report_day', e.target.value);
                                    }}
                                >
                                    {[...Array(30).keys()]
                                        .map((i) => (i + 1).toString())
                                        .map((option) => (
                                            <MenuItem key={option} value={option} id={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        ) : null}
                        <ResponsiveTimePicker
                            slotProps={{ textField: { id: 'time-field', fullWidth: true, size: 'small' } }}
                            label={t('content.reports.form.time')}
                            value={reportTime}
                            onChange={(value: Dayjs | null) => {
                                if (value && value.isValid()) {
                                    props.setIsError(false);
                                    handleChange(
                                        'report_time',
                                        value.subtract(dayjs().utcOffset(), 'minute').format('HH:mm:ss'),
                                    );
                                } else {
                                    props.setIsError(true);
                                }
                            }}
                        />
                    </Box>
                ) : (
                    <FormControl fullWidth sx={{ mb: 1.5 }}>
                        <InputLabel>{t('content.reports.form.method.header')}</InputLabel>
                        <Select
                            id="day-dropdown"
                            fullWidth
                            size="small"
                            label={t('content.reports.form.method.header')}
                            value={props.details.method?.toString() || 'EMAIL'}
                            onChange={(e: SelectChangeEvent) => {
                                handleChange('method', e.target.value);
                            }}
                        >
                            {['EMAIL', 'SMS'].map((option) => {
                                const smsDisabled = option == 'SMS' && !profile.phone_number;
                                const item = (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        id={option}
                                        disabled={smsDisabled}
                                        data-testid={option}
                                    >
                                        {t(`content.reports.form.method.${option.toLocaleLowerCase()}`)}
                                    </MenuItem>
                                );
                                return smsDisabled ? (
                                    <Tooltip
                                        key={option}
                                        title={t('content.reports.form.method.sms_disabled')}
                                        followCursor
                                        componentsProps={{ tooltip: { sx: { backgroundColor: 'redColor.main' } } }}
                                    >
                                        <Box>{item}</Box>
                                    </Tooltip>
                                ) : (
                                    item
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            id="active-checkbox"
                            data-testid="active-checkbox"
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            inputProps={{ 'data-testid': 'active-checkbox-input' } as any}
                            disableRipple
                            checked={props.details.active}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('active', e.target.checked)}
                            size="small"
                        />
                    }
                    label={
                        <Typography variant="body2" sx={{ mt: 0.25 }}>
                            {t('content.reports.columns.active')}
                        </Typography>
                    }
                    sx={{
                        '& .MuiCheckbox-root': {
                            py: 0,
                            pr: 0.5,
                        },
                        mt: props.details.is_report ? 2 : 0,
                    }}
                />
            </Card>
        </LocalizationProvider>
    );
};

const DeleteModal: React.FC<{ onClose: CallableFunction; onDelete: CallableFunction }> = ({ onClose, onDelete }) => {
    const { t } = useTranslation();

    return (
        <Dialog id="report-details-dialog" open={true} onClose={() => onClose()}>
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DeleteIcon sx={{ mr: 0.5 }} />
                    {t('content.reports.delete_report')}
                </Box>
            </Box>
            <Paper
                sx={{
                    backgroundColor: 'bgColor.main',
                    pt: 1,
                    pb: 2,
                    px: 3,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography>{t('content.reports.confirm_delete')}</Typography>
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <Button
                        id="cancel-delete-btn"
                        data-testid="cancel-delete-btn"
                        variant="contained"
                        color="primary"
                        onClick={() => onClose()}
                        sx={{ mr: 1 }}
                    >
                        {t('content.reports.cancel')}
                    </Button>
                    <Button
                        id="confirm-delete-btn"
                        data-testid="confirm-delete-btn"
                        variant="contained"
                        color="error"
                        onClick={() => {
                            onClose();
                            onDelete();
                        }}
                    >
                        {t('content.reports.delete')}
                    </Button>
                </Box>
            </Paper>
        </Dialog>
    );
};

const ReportAlertDetailsComponent: React.FC<ReportAlertDetailsComponentProps> = (
    props: ReportAlertDetailsComponentProps,
) => {
    const [details, setDetails] = useState<AlertReport>(props.details);
    const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [alertElement, setAlert] = useAlert();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { api } = useApi();
    const history = useHistory();

    const updateReportAlertDetails = () => {
        setIsLoading(true);
        let future;
        if (details.pk > 0) {
            future = api.apiV0ReportReportIdPatch({ reportId: details.pk, alertOrReport: details as AlertOrReport });
        } else {
            future = api.apiV0ReportPost({ alertOrReport: details as AlertOrReport });
        }
        future
            .then((res) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                queryClient.setQueryData(['reports'], (old: any) => {
                    if (old !== undefined) {
                        const type = details.is_report ? 'reports' : 'alerts';
                        if (details.pk === 0) {
                            return { ...old, [type]: [...old[type], res.data] };
                        }
                        return {
                            ...old,
                            [type]: old[type].map((r: AlertReport) => (r.pk === res.data.pk ? res.data : r)),
                        };
                    }
                });
                setDetails(res.data as AlertReport);
                setDetailsChanged(false);
                if (props.fromOtherPage) {
                    props.onClose();
                } else {
                    history.push(`/reports/${details.is_report ? 'report' : 'alert'}/${res.data.pk}`);
                }
            })
            .catch(() => setAlert({ message: t('content.reports.error_alert.save'), type: 'error', duration: 6000 }))
            .finally(() => setIsLoading(false));
    };

    const deleteReport = () => {
        if (details.pk > 0) {
            setIsLoading(true);
            api.apiV0ReportReportIdDelete({ reportId: details.pk, alertOrReport: details as AlertOrReport })
                .then(() => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    queryClient.setQueryData(['reports'], (old: any) => {
                        if (old !== undefined) {
                            const type = details.is_report ? 'reports' : 'alerts';
                            return { ...old, [type]: old[type].filter((r: AlertReport) => r.pk !== details.pk) };
                        }
                    });
                    props.onClose();
                })
                .catch(() => {
                    setAlert({ message: t('content.reports.error_alert.delete'), type: 'error', duration: 6000 });
                    setIsLoading(false);
                });
        }
    };

    const handleSend = () => {
        setIsLoading(true);
        api.apiV0ReportReportIdSendPost({ reportId: props.details.pk })
            .then(() => {
                setAlert({
                    message: t('content.reports.success_alert.send'),
                    type: 'success',
                    duration: 6000,
                });
            })
            .catch(() => {
                setAlert({
                    message: t('content.reports.error_alert.send'),
                    type: 'error',
                    duration: 6000,
                });
            })
            .finally(() => setIsLoading(false));
    };

    const isInvalidSeverity =
        details.filters.SEVERITY?.includes(SEVERITY_FILTER_OPTIONS.CUSTOM) && details.filters.SEVERITY[1] === '';

    let sendButton = (
        <Box sx={{ flex: 1, mr: 1 }}>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                id="send-btn"
                data-testid="send-btn"
                disabled={isLoading || detailsChanged}
                onClick={handleSend}
            >
                {t('content.reports.form.send')}
            </Button>
        </Box>
    );
    if (detailsChanged) {
        sendButton = <Tooltip title={t('content.reports.form.send_disabled')}>{sendButton}</Tooltip>;
    }

    return (
        <Dialog
            id="delete-report-dialog"
            open={true}
            fullWidth={true}
            maxWidth="xs"
            onClose={() => {
                if (!isLoading) {
                    props.onClose();
                }
            }}
        >
            {alertElement}
            {showDeleteModal ? <DeleteModal onClose={() => setShowDeleteModal(false)} onDelete={deleteReport} /> : null}
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    py: 0.5,
                    pl: 1,
                    pr: 0.5,
                    zIndex: 2,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {props.details.pk === 0 ? t('content.reports.new') : t('content.reports.edit')}
                    <Box sx={{ display: 'flex' }}>
                        {props.details.pk > 0 ? (
                            <IconButton
                                size="small"
                                id="delete-btn"
                                data-testid="delete-btn"
                                disabled={isLoading}
                                onClick={() => setShowDeleteModal(true)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        ) : null}
                        <IconButton
                            id="btn-close"
                            data-testid="btn-close"
                            size="small"
                            disabled={isLoading}
                            onClick={() => props.onClose()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Paper sx={{ backgroundColor: 'bgColor.main', p: 1 }}>
                <ReportAlertForm
                    details={details}
                    handleChange={setDetails}
                    devices={props.devices}
                    geofences={props.geofences}
                    setIsError={setIsError}
                    setDetailsChanged={setDetailsChanged}
                />
                <Box sx={{ width: '100%', display: 'flex', mt: 1 }}>
                    <Box sx={{ flex: 1, mr: 1 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            id="cancel-btn"
                            disabled={isLoading}
                            onClick={() => props.onClose()}
                        >
                            {t('content.reports.form.cancel')}
                        </Button>
                    </Box>
                    {props.details.pk > 0 && details.is_report ? sendButton : null}
                    <Box sx={{ flex: 1 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            id="create-btn"
                            data-testid="create-btn"
                            disabled={isLoading || isError || isInvalidSeverity}
                            onClick={updateReportAlertDetails}
                        >
                            {t('content.reports.form.save')}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Dialog>
    );
};

export default ReportAlertDetailsComponent;
