const baseColors = {
    red: {
        700: '#c90000',
        500: '#dc5555',
        400: '#de6964',
        300: '#f77a74',
        200: '#edaba6',
    },
    orange: {
        500: '#ff8351',
    },
    yellow: {
        500: '#e5a23c',
    },
    green: {
        900: '#1b5e20',
        700: '#538a57',
    },
    neutral: {
        50: '#f9f9f9',
        100: '#f5f5f5',
        150: '#e0e0e0',
        200: '#d3d3d3',
        300: '#c5c5c5',
        400: '#8b8b8b',
        500: '#707070',
        700: '#5b5b5b',
    },
    gray: {
        400: '#9ca3af',
        600: '#4b5563',
    },
    black: '#000000',
    white: '#ffffff',
};

const palette = {
    ...baseColors,
    primary: '#262626',
    secondary: '#e5b265',
    accent: '#2d9dc9',
    bgColor: '#f5f5f5',
    good: '#82ca9d',
    bad: '#ff8686',
    wellness: {
        gray: baseColors.neutral[400],
        red: baseColors.red[400],
        green: baseColors.green[700],
    },
    score: {
        green: baseColors.green[900],
        yellow: baseColors.yellow[500],
        orange: baseColors.orange[500],
        lightRed: baseColors.red[300],
        red: baseColors.red[700],
    },
};

export default palette;
