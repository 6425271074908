import React, { ChangeEventHandler, FormEventHandler, MouseEventHandler, useRef } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormFile, { FormFileProps } from 'react-bootstrap/FormFile';
import Row from 'react-bootstrap/Row';
import { FormControlProps } from 'react-bootstrap/esm/FormControl';

import DropDown from './dropdown';
import './form.css';

interface InputProps extends FormControlProps {
    label: string | JSX.Element;
    value?: string | number;
    rows?: number;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    name?: string;
    onClick?: MouseEventHandler<HTMLInputElement>;
    options?: Array<string>;
    sortOptions?: boolean;
    handleOptionsSelect?: CallableFunction;
    placeholder?: string;
    children?: React.ReactNode;
    help?: string;
    onEnter?: CallableFunction;
}

interface CheckboxProps extends Omit<InputProps, 'value'> {
    value: boolean;
    radio?: boolean;
}

interface Checkbox3WayProps extends Omit<CheckboxProps, 'onChange' | 'value'> {
    value: boolean | null;
    onChange: CallableFunction;
}

interface SelectProps extends Omit<InputProps, 'value'> {
    value: Array<string>;
    selected?: string;
    selectedList?: Array<string>;
    multiple?: boolean;
    t?: CallableFunction;
}

interface FileInputProps extends Omit<InputProps, 'children'>, Omit<FormFileProps, 'label'> {
    mozdirectory?: string;
    webkitdirectory?: string;
    multiple?: boolean;
}

interface FormProps {
    children: React.ReactNode;
    onSubmit?: FormEventHandler<HTMLFormElement>;
    autoComplete?: string;
}

interface HelpProps {
    text: string;
}

export const Help: React.FC<HelpProps> = (props: HelpProps) => {
    return (
        <OverlayTrigger overlay={<Tooltip id="tooltip">{props.text}</Tooltip>}>
            <span style={{ color: '#777' }}>{' (?)'}</span>
        </OverlayTrigger>
    );
};

export const Input: React.FC<InputProps> = (props: InputProps) => {
    const { value, handleOptionsSelect, onEnter, ...sharedProps } = props;
    const sortOptions = props.sortOptions != undefined ? props.sortOptions : true;

    const handleEnterPressed = (event: KeyboardEvent) => {
        if (onEnter != undefined && event.key == 'Enter') {
            onEnter(event);
        }
    };

    return (
        <Form.Group as={Row} controlId={props.name}>
            {props.label ? (
                <Form.Label column>
                    {props.label}
                    {props.help ? <Help text={props.help} /> : null}
                </Form.Label>
            ) : null}
            <Col>
                {props.children ? (
                    props.children
                ) : (
                    <>
                        <Form.Control
                            {...sharedProps}
                            onKeyPress={handleEnterPressed}
                            value={value}
                            name={props.name}
                        />
                        {value != undefined && props.options && handleOptionsSelect ? (
                            <DropDown
                                values={props.options
                                    .filter((e: string) => e.includes(value.toString()) && e != value)
                                    .sort((a, b) => (sortOptions ? a.localeCompare(b) : 0))}
                                setValue={handleOptionsSelect}
                            />
                        ) : null}
                    </>
                )}
            </Col>
        </Form.Group>
    );
};

export const FileInput: React.FC<FileInputProps> = (props: FileInputProps) => {
    return (
        <Form.Group as={Row} controlId={props.name}>
            <Form.Label column>{props.label}</Form.Label>
            <Col>
                <FormFile.Input {...props} />
            </Col>
        </Form.Group>
    );
};

export const FileButton: React.FC<FileInputProps> = (props: FileInputProps) => {
    const inputFile = useRef(null);

    const onUpdateButtonClick = () => {
        if (inputFile.current != null) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (inputFile.current as any).click();
        }
    };

    return (
        <>
            <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} onChange={props.onChange} />
            <Button onClick={onUpdateButtonClick}>{props.label}</Button>
        </>
    );
};

export const Select: React.FC<SelectProps> = (props: SelectProps) => {
    const selectedList = props.selectedList ?? [];
    return (
        <Form.Group as={Row} controlId={props.name}>
            {props.label ? <Form.Label column>{props.label}</Form.Label> : null}
            <Col>
                <Form.Control
                    id={props.id}
                    style={{ fontSize: 14, width: '100%' }}
                    as="select"
                    onChange={props.onChange}
                    custom
                    multiple={props.multiple}
                    disabled={props.disabled}
                    name={props.name}
                >
                    {props.value.map((elem) => {
                        const label = props.t ? props.t(elem) : elem;
                        return (
                            <option
                                id={label}
                                key={elem}
                                value={elem}
                                selected={props.selected == elem || selectedList.includes(elem)}
                            >
                                {label}
                            </option>
                        );
                    })}
                </Form.Control>
            </Col>
        </Form.Group>
    );
};

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
    const radioType = props.radio ? 'radio' : undefined;
    return (
        <Form.Group as={Row} controlId={props.name}>
            <Form.Label column>{props.label}</Form.Label>
            <Col>
                <Form.Check
                    id={props.id}
                    checked={!!props.value}
                    onChange={props.onChange}
                    name={props.name}
                    type={radioType}
                    disabled={props.disabled}
                />
            </Col>
        </Form.Group>
    );
};

export const Checkbox3Way: React.FC<Checkbox3WayProps> = (props: Checkbox3WayProps) => {
    return (
        <Form.Group as={Row} controlId={props.name}>
            <Form.Label column>{props.label}</Form.Label>
            <Col>
                <Form.Check
                    id={`${props.label}-Yes`}
                    label="Yes"
                    checked={props.value === true}
                    onChange={() => props.onChange(true)}
                    disabled={props.disabled}
                    type="radio"
                />
                <Form.Check
                    id={`${props.label}-No`}
                    label="No"
                    checked={props.value === false}
                    onChange={() => props.onChange(false)}
                    disabled={props.disabled}
                    type="radio"
                />
                <Form.Check
                    id={`${props.label}-Null`}
                    label="Unknown"
                    checked={props.value === null}
                    onChange={() => props.onChange(null)}
                    disabled={props.disabled}
                    type="radio"
                />
            </Col>
        </Form.Group>
    );
};

export const FormComponent: React.FC<FormProps> = (props: FormProps) => {
    return (
        <Form
            className="form"
            autoComplete={props.autoComplete !== undefined ? props.autoComplete : 'off'}
            onSubmit={props.onSubmit}
        >
            {props.children}
        </Form>
    );
};

export default FormComponent;
