import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import RouteIcon from '@mui/icons-material/Route';
import TheatersIcon from '@mui/icons-material/Theaters';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Autocomplete,
    Box,
    CircularProgress,
    Fade,
    List,
    ListItem,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CommandData, DeviceV3, TripDetails } from '../../../backendsdk';
import { ResponsiveDatePicker, ResponsiveTimePicker } from '../../../components/MuiDatePicker';
import {
    TrackedDialog as Dialog,
    TrackedLink as Link,
    TrackedListItemButton as ListItemButton,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useDrivers from '../../../hooks/drivers';
import useIsMobile from '../../../hooks/isMobile';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import { getStorageItem } from '../../../utils/Storage';
import { normalizeLicensePlate } from '../../../utils/Str';
import { formatTime } from '../../../utils/TimeFormatter';
import { MILES_TO_KM_FACTOR } from '../../../utils/location';
import palette from '../../ColorPalette';
import AccidentDetailsComponent from '../Accident/AccidentDetails';
import { AccidentPageVersion } from '../Accident/Defs';
import { RELEVANT_EVENT_TYPES } from '../Overview/utils';
import FindByLocation from './FindByLocation';
import { MAX_DAYS_BACK } from './FindVideo';
import FindVideoMap from './FindVideoMap';
import RequestList from './RequestList';

interface VideoRequestFormProps {
    selectedRequest?: CommandData;
    setRequests: CallableFunction;
    selectedTrip?: TripDetails;
    selectedTripId?: string;
    setSelectedTrip: CallableFunction;
    isLoading: boolean;
    devices: DeviceV3[];
    setAlert: CallableFunction;
    handlingStatusList?: string[];
    setHandlingStatusList?: CallableFunction;
    inAccident?: boolean;
    toolbar?: React.ReactNode;
    device?: DeviceV3;
    date?: Dayjs;
    accidentId?: number;
    associatedEvent?: number;
    onAssociateEvent?: CallableFunction;
    onCreateRequest?: CallableFunction;
}

const VideoRequestForm: React.FC<VideoRequestFormProps> = (props: VideoRequestFormProps) => {
    const query = useQuery();
    let initialDate: Dayjs | null = null;
    let initialLicensePlate = '';
    if (props.device !== undefined) {
        initialLicensePlate = props.device.device.license_plate;
    } else if (props.selectedRequest) {
        const payload = JSON.parse(props.selectedRequest.data);
        initialDate = dayjs.unix(payload.start_time);
        initialLicensePlate = props.selectedRequest.device.license_plate;
    } else {
        const queryDate = query.get('date');
        initialDate = queryDate !== null ? dayjs.unix(parseInt(queryDate)) : null;
        initialLicensePlate = query.get('license_plate') || '';
    }
    const [date, setDate] = useState<Dayjs | null>(props.date || initialDate);
    const [timeRange, setTimeRange] = useState<DateRange<Dayjs>>([null, null]);
    const [licensePlate, setLicensePlate] = useState<string>(initialLicensePlate);
    const [withLocation, setWithLocation] = useState<boolean>(false);
    const [trips, setTrips] = useState<TripDetails[]>();
    const [isLoadingTrips, setIsLoadingTrips] = useState<boolean>(false);
    const [isErrorTrips, setIsErrorTrips] = useState<boolean>(false);
    const [requestOnMap, setRequestOnMap] = useState<CommandData | undefined>(props.selectedRequest);
    const [isMapLoading, setIsMapLoading] = useState<boolean>(false);
    const [selectSegment, setSelectSegment] = useState<boolean>(false);
    const [selectedAccidentId, setSelectedAccidentId] = useState<string>();
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [isMultipleRoutes, setIsMultipleRoutes] = useState<boolean>(false);
    const [showMinimizeButton, setShowMinimizeButton] = useState<boolean>(false);
    const [isMinimized, setIsMinimized] = useState<boolean>(false);
    const [showContent, setShowContent] = useState<boolean>(true);
    const [invalidateFunc, setInvalidateFunc] = useState<CallableFunction>();
    const [showDialog, setShowDialog] = useState<boolean>(true);
    const { getDriver } = useDrivers();
    const { i18n, t } = useTranslation();
    const { profile } = useProfile();
    const { api } = useApi();
    const history = useHistory();
    const isMobile = useIsMobile();

    const distanceUnit = profile.locale === 'il' ? 'km' : 'mi';
    const unknownVehicle = props.inAccident && props.device === undefined;

    useEffect(() => {
        setTimeRange((prev) => {
            if (date === null) {
                return [null, null];
            } else {
                const updatedTimeRange = [...prev] as DateRange<Dayjs>;
                if (prev[0] !== null) {
                    updatedTimeRange[0] = date.set('hour', prev[0].hour()).set('minute', prev[0].minute());
                }
                if (prev[1] !== null) {
                    updatedTimeRange[1] = date.set('hour', prev[1].hour()).set('minute', prev[1].minute());
                }
                return updatedTimeRange;
            }
        });
    }, [date]);

    useEffect(() => {
        setRequestOnMap(props.selectedRequest);
    }, [props.selectedRequest]);

    const getTrips = () => {
        if (!!date && date.isValid() && !!licensePlate) {
            let fromField = date.startOf('day').unix();
            if (timeRange[0] !== null && timeRange[0].isValid()) {
                fromField = date
                    .set('hour', timeRange[0].hour())
                    .set('minute', timeRange[0].minute())
                    .set('second', 0)
                    .unix();
            }
            let toField = date.endOf('day').unix();
            if (timeRange[1] !== null && timeRange[1].isValid()) {
                toField = date
                    .set('hour', timeRange[1].hour())
                    .set('minute', timeRange[1].minute())
                    .set('second', 59)
                    .unix();
            }
            setIsErrorTrips(false);
            setIsLoadingTrips(true);
            api.apiV1TripGet({
                licensePlate: licensePlate,
                from: fromField,
                to: toField,
            })
                .then((res) => {
                    const tripsResponse = res.data.filter((trip) => Math.floor(trip.duration) > 0).reverse();
                    setTrips(tripsResponse);
                    if (requestOnMap !== undefined) {
                        const payload = JSON.parse(requestOnMap.data);
                        const relevantTrips = tripsResponse.filter(
                            (trip) => trip.start_time <= payload.end_time && trip.end_time >= payload.start_time,
                        );
                        if (relevantTrips.length === 1) {
                            if (relevantTrips[0].id !== props.selectedTrip?.id) {
                                props.setSelectedTrip(relevantTrips[0]);
                            }
                            setIsMultipleRoutes(false);
                        } else {
                            props.setSelectedTrip(undefined);
                            setIsMultipleRoutes(true);
                        }
                    } else if (initialDate !== null) {
                        const queryDate = initialDate;
                        const relevantTrips = tripsResponse.filter(
                            (trip) =>
                                Math.floor(trip.start_time) <= queryDate.unix() &&
                                Math.floor(trip.end_time) >= queryDate.unix(),
                        );
                        if (relevantTrips.length === 1) {
                            if (relevantTrips[0].id !== props.selectedTrip?.id) {
                                props.setSelectedTrip(relevantTrips[0]);
                            }
                        }
                    } else if (!!props.date && !!props.selectedTripId) {
                        const relevantTrips = tripsResponse.filter((trip) => trip.trip_id === props.selectedTripId);
                        if (relevantTrips.length === 1) {
                            if (relevantTrips[0].id !== props.selectedTrip?.id) {
                                props.setSelectedTrip(relevantTrips[0]);
                            }
                        }
                    }
                })
                .catch(() => setIsErrorTrips(true))
                .finally(() => setIsLoadingTrips(false));
        } else {
            setTrips([]);
        }
    };

    useEffect(() => {
        getTrips();
    }, [date, timeRange, licensePlate]);

    const getTripListItem = (trip: TripDetails, idx: number) => {
        const driver = getDriver(trip.driver);
        const driverName = driver?.fullName;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const relevantEvents = trip.number_of_events_per_type!.filter((event) =>
            RELEVANT_EVENT_TYPES.includes(event.event_type),
        );
        let eventsField;
        const eventCount = relevantEvents.reduce((acc, event) => acc + event.count, 0);
        if (eventCount > 0) {
            eventsField =
                eventCount === 1 ? t('content.fleet.event') : t('content.fleet.events', { count: eventCount });
        }
        let tripDistance = trip.distance / 1000;
        if (distanceUnit === 'mi') {
            tripDistance = tripDistance / MILES_TO_KM_FACTOR;
        }
        const tripDistanceStr = tripDistance.toFixed(2);
        let isSelected = false;
        if (requestOnMap) {
            const payload = JSON.parse(requestOnMap.data);
            isSelected = trip.start_time <= payload.end_time && trip.end_time >= payload.start_time;
        }

        const isFilteredFrom = timeRange[0] !== null && timeRange[0].isValid();
        const isFilteredTo = timeRange[1] !== null && timeRange[1].isValid();

        const listItemButton = (
            <ListItemButton
                id={`trip-${trip.id}-button`}
                data-testid={`trip-${trip.id}-button`}
                selected={!!props.selectedTrip ? props.selectedTrip.id === trip.id : isSelected}
                onClick={() => {
                    setShowDialog(true);
                    if (props.selectedTrip?.id !== trip.id) {
                        setIsMapLoading(true);
                        props.setSelectedTrip(trip);
                    }
                    setSelectSegment(false);
                    setIsMultipleRoutes(false);
                    setRequestOnMap(undefined);
                    // remove query params and keep url params
                    history.push(history.location.pathname);
                }}
                sx={{ p: 1 }}
                track={{
                    active_filters: `date${isFilteredFrom ? ' time-from' : ''}${isFilteredTo ? ' time-to' : ''}`,
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', px: 1, py: 0.5 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography id={`row-${idx}-trip-times`} component="span">{`${dayjs
                            .unix(trip.start_time)
                            .format(profile.dateTimeFormat)} – ${dayjs
                            .unix(trip.end_time)
                            .format(profile.dateTimeFormat)}`}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TimelapseIcon fontSize="small" sx={{ height: 15, width: 15, mr: 0.5 }} />
                        <Typography id={`row-${idx}-trip-duration`} className="trip-duration-field" sx={{ mr: 1 }}>
                            {formatTime(trip.duration, t, true, false)}
                        </Typography>
                        <RouteIcon fontSize="small" sx={{ height: 15, width: 15, mr: 0.5 }} />
                        <Typography id={`row-${idx}-trip-distance`} sx={{ mr: 1 }}>{`${tripDistanceStr} ${t(
                            `content.fleet.details.trips.${distanceUnit}`,
                        )}`}</Typography>
                        {eventCount > 0 ? (
                            <>
                                <WarningIcon
                                    fontSize="small"
                                    sx={{ height: 15, width: 15, mr: 0.5, color: palette.red[700] }}
                                />
                                <Typography id={`row-${idx}-trip-events`} sx={{ color: palette.red[700] }}>
                                    {eventsField}
                                </Typography>
                            </>
                        ) : null}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.25 }}>
                        <PersonIcon fontSize="small" sx={{ height: 15, width: 15, mr: 0.5 }} />
                        {driverName}
                    </Box>
                </Box>
            </ListItemButton>
        );
        return (
            <ListItem
                key={trip.id}
                data-testid="trip-list-item"
                disableGutters
                disablePadding
                sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'lightGrayColor.main' }}
            >
                {listItemButton}
            </ListItem>
        );
    };

    let content = null;
    if (isLoadingTrips) {
        content = (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (trips !== undefined && trips.length > 0) {
        content = (
            <List disablePadding sx={{ width: '100%', height: '100%' }}>
                {trips
                    .sort((a, b) => b.start_time - a.start_time)
                    .map((trip, idx) => {
                        return getTripListItem(trip, idx);
                    })}
            </List>
        );
    } else if (trips !== undefined && trips.length === 0) {
        content = (
            <Typography variant="overline" sx={{ ml: 2 }}>
                {t('content.fleet.details.trips.no_trips_found')}
            </Typography>
        );
    } else if (isErrorTrips) {
        content = (
            <Typography variant="overline" color="error" sx={{ ml: 2 }}>
                {t('content.fleet.details.trips.error_loading_trips')}
            </Typography>
        );
    }

    const loadingModal = (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: palette.bgColor,
                opacity: 0.5,
                zIndex: 1,
            }}
        >
            <CircularProgress />
        </Box>
    );

    const licensePlates = [...new Set(props.devices.map((d) => d.device.license_plate))].sort((a, b) =>
        normalizeLicensePlate(a).localeCompare(normalizeLicensePlate(b)),
    );

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', position: 'relative' }}>
            <Box
                onMouseEnter={() => {
                    if (props.onAssociateEvent) {
                        setShowMinimizeButton(true);
                    }
                }}
                onMouseLeave={() => {
                    if (!isMinimized) {
                        setShowMinimizeButton(false);
                    }
                }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: isMobile ? '100%' : isMinimized ? '20px' : '375px',
                    height: '100%',
                    flexShrink: 0,
                    backgroundColor: palette.neutral[50],
                    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.25)',
                    minHeight: 0,
                    zIndex: 1003,
                    position: 'relative',
                    transition: 'width 0.3s',
                }}
            >
                {showContent ? props.toolbar : null}
                <Box
                    sx={{
                        position: 'absolute',
                        width: '50px',
                        height: '50px',
                        right: '-37.5px',
                        top: '110px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Fade in={showMinimizeButton}>
                        <MuiButton
                            id="minimize-sidepanel"
                            variant="contained"
                            disableElevation
                            onClick={() =>
                                setIsMinimized((prev) => {
                                    if (!prev) {
                                        setShowContent(false);
                                    } else {
                                        setTimeout(() => setShowContent(true), 250);
                                    }
                                    setTimeout(() => invalidateFunc && invalidateFunc(), 300);
                                    return !prev;
                                })
                            }
                            sx={{
                                p: 0,
                                borderRadius: '50%',
                                minWidth: 0,
                                height: '25px',
                                width: '25px',
                                display: 'flex',
                                justifyContent: 'center',
                                zIndex: 1001,
                            }}
                        >
                            {isMinimized ? (
                                <ArrowForwardIosIcon sx={{ fontSize: 14, ml: '2px' }} />
                            ) : (
                                <ArrowBackIosNewIcon sx={{ fontSize: 14 }} />
                            )}
                        </MuiButton>
                    </Fade>
                </Box>
                {showContent ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                            minHeight: 0,
                            pt: props.inAccident ? 0.5 : 0,
                        }}
                    >
                        <Dialog
                            id="find-by-location-dialog"
                            fullScreen={isMobile}
                            open={withLocation}
                            fullWidth={true}
                            maxWidth="md"
                            onClose={() => setWithLocation(false)}
                        >
                            <FindByLocation
                                setDate={setDate}
                                setLicensePlate={setLicensePlate}
                                onClose={() => setWithLocation(false)}
                            />
                        </Dialog>
                        {!!selectedAccidentId && !!props.handlingStatusList && !!props.setHandlingStatusList ? (
                            <AccidentDetailsComponent
                                pageVersion={
                                    (getStorageItem('OE-accident-page-version') as AccidentPageVersion) || 'v0'
                                }
                                accidentId={selectedAccidentId}
                                refreshTable={() => null}
                                setIsLoadingModal={setIsLoadingModal}
                                handlingStatusList={props.handlingStatusList}
                                setHandlingStatusList={props.setHandlingStatusList}
                                onClose={() => setSelectedAccidentId(undefined)}
                            />
                        ) : null}
                        {isLoadingModal ? loadingModal : null}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                            }}
                        >
                            {isMobile && isMapLoading ? (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: palette.bgColor,
                                        opacity: 0.5,
                                        zIndex: 1002,
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            {!props.inAccident ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: 60,
                                        flexShrink: 0,
                                        backgroundColor: palette.neutral[200],
                                        minHeight: 0,
                                    }}
                                >
                                    <TheatersIcon sx={{ fontSize: 24, ml: 1.75, mr: 1 }} />
                                    <Typography fontSize={20} sx={{ fontWeight: 500 }}>
                                        {!!requestOnMap
                                            ? t('content.find_video.request_details_header')
                                            : t('content.find_video.new_request_header')}
                                    </Typography>
                                </Box>
                            ) : null}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                    minHeight: 0,
                                    flexShrink: 0,
                                }}
                            >
                                {!!requestOnMap ? (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            flexShrink: 0,
                                            mb: 1,
                                        }}
                                    >
                                        <RequestList
                                            requests={[requestOnMap]}
                                            devices={props.devices}
                                            isLoading={false}
                                            isError={false}
                                            inAccident
                                            disabled
                                        />
                                    </Box>
                                ) : null}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        minHeight: 0,
                                        flexShrink: 0,
                                        p: 2,
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.languages[0]}>
                                        <ResponsiveDatePicker
                                            label={t('content.find_video.date')}
                                            value={date}
                                            onChange={(value: Dayjs | null) => {
                                                setRequestOnMap(undefined);
                                                setDate(value);
                                            }}
                                            maxDate={dayjs()}
                                            format={profile.dateFormat}
                                            slotProps={{
                                                textField: {
                                                    required: true,
                                                    id: 'date-field',
                                                    'data-testid': 'date-field',
                                                    fullWidth: true,
                                                    size: 'small',
                                                    sx: { direction: 'ltr' },
                                                } as TextFieldProps,
                                            }}
                                            sx={{ mb: 2 }}
                                        />
                                        <Box sx={{ display: 'flex', width: '100%', mt: 2 }}>
                                            <ResponsiveTimePicker
                                                label={t('content.find_video.from')}
                                                value={timeRange[0]}
                                                onChange={(value: Dayjs | null) =>
                                                    setTimeRange((prev) => [value, prev[1]])
                                                }
                                                slotProps={{
                                                    textField: {
                                                        id: 'from-time-field',
                                                        'data-testid': 'from-time-field',
                                                        fullWidth: true,
                                                        size: 'small',
                                                        sx: { direction: 'ltr', mr: 2 },
                                                    } as TextFieldProps,
                                                }}
                                                maxTime={
                                                    !!timeRange[1]
                                                        ? timeRange[1]
                                                        : date?.isSame(dayjs(), 'day')
                                                        ? dayjs()
                                                        : undefined
                                                }
                                            />
                                            <ResponsiveTimePicker
                                                label={t('content.find_video.to')}
                                                value={timeRange[1]}
                                                onChange={(value: Dayjs | null) =>
                                                    setTimeRange((prev) => [prev[0], value])
                                                }
                                                slotProps={{
                                                    textField: {
                                                        id: 'to-time-field',
                                                        'data-testid': 'to-time-field',
                                                        fullWidth: true,
                                                        size: 'small',
                                                        sx: { direction: 'ltr' },
                                                    } as TextFieldProps,
                                                }}
                                                maxTime={date?.isSame(dayjs(), 'day') ? dayjs() : undefined}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                    {!!date && date < dayjs().subtract(MAX_DAYS_BACK, 'day') ? (
                                        <Typography fontSize={12} color="error">
                                            {t('content.find_video.old_videos')}
                                        </Typography>
                                    ) : null}
                                    {props.device === undefined ? (
                                        <>
                                            {!!unknownVehicle ? (
                                                <Typography fontSize={12} color="error" sx={{ mt: 1.5, mb: -0.5 }}>
                                                    {t('content.find_video.unknown_vehicle')}
                                                </Typography>
                                            ) : null}
                                            <Autocomplete
                                                options={['', ...licensePlates]}
                                                filterOptions={(options, params) => {
                                                    const filtered = options.filter((option) =>
                                                        normalizeLicensePlate(option).includes(
                                                            normalizeLicensePlate(params.inputValue),
                                                        ),
                                                    );
                                                    if (filtered.length === 0) {
                                                        filtered.push(params.inputValue);
                                                    }
                                                    return filtered;
                                                }}
                                                onChange={(_event: React.SyntheticEvent, newValue: string | null) => {
                                                    setRequestOnMap(undefined);
                                                    setLicensePlate(newValue || '');
                                                }}
                                                size="small"
                                                id="license-plate-field"
                                                value={licensePlate}
                                                fullWidth
                                                autoHighlight
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        data-testid="license-plate-field"
                                                        label={t('content.find_video.license_plate')}
                                                        variant="outlined"
                                                        sx={{ direction: 'ltr' }}
                                                    />
                                                )}
                                                sx={{ mt: 2, mb: 0.5 }}
                                            />
                                            <Link
                                                id="find-by-location-link"
                                                component="button"
                                                onClick={() => setWithLocation(true)}
                                            >
                                                {t('content.find_video.no_vin')}
                                            </Link>
                                        </>
                                    ) : null}
                                </Box>
                            </Box>
                            {!!content ? (
                                <Typography sx={{ ml: 2, mb: 0.5, fontWeight: 500 }}>
                                    {t('content.find_video.vehicle_trips')}
                                </Typography>
                            ) : null}
                            <Box sx={{ width: '100%', height: '100%', minHeight: 0, overflowY: 'auto' }}>{content}</Box>
                        </Box>
                    </Box>
                ) : null}
            </Box>
            {!!props.selectedTrip || isMultipleRoutes ? (
                <FindVideoMap
                    isLoading={isMapLoading}
                    setIsLoading={setIsMapLoading}
                    selectedTrip={props.selectedTrip}
                    setSelectedTrip={props.setSelectedTrip}
                    selectedRequest={requestOnMap}
                    selectSegment={selectSegment}
                    setSelectSegment={setSelectSegment}
                    setSelectedRequest={setRequestOnMap}
                    setRequests={props.setRequests}
                    setAlert={props.setAlert}
                    devices={props.devices}
                    associatedEvent={props.associatedEvent}
                    onAssociateEvent={props.onAssociateEvent}
                    setTrips={setTrips}
                    accidentId={props.accidentId}
                    setInvalidateFunc={setInvalidateFunc}
                    onCreateRequest={props.onCreateRequest}
                    showDialog={showDialog}
                    setShowDialog={(value: boolean) => {
                        if (!value) {
                            setSelectSegment(false);
                            setIsMultipleRoutes(false);
                            setRequestOnMap(undefined);
                            props.setSelectedTrip(undefined);
                        }
                        setShowDialog(value);
                    }}
                />
            ) : null}
        </Box>
    );
};

export default VideoRequestForm;
