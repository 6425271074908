import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CoachMediaFile, MediaFile } from '../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
} from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';

interface FileDetailsDialogProps {
    selectedFile: File | MediaFile;
    setFiles: CallableFunction;
    onClose: CallableFunction;
    setAlert: CallableFunction;
    setExpandedTreeItems: CallableFunction;
}

const FileDetailsDialog: React.FC<FileDetailsDialogProps> = (props: FileDetailsDialogProps) => {
    let initialFileName;
    let initialIsPublic;
    let isNewFile;
    if (props.selectedFile instanceof File) {
        initialFileName = props.selectedFile.name.split('.')[0];
        initialIsPublic = false;
        isNewFile = true;
    } else {
        initialFileName = props.selectedFile.file_name;
        initialIsPublic = props.selectedFile.is_public;
        isNewFile = false;
    }
    const [fileName, setFileName] = useState<string>(initialFileName);
    const [isPublic, setIsPublic] = useState<boolean>(initialIsPublic);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { api } = useApi();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.selectedFile instanceof File) {
            const fileType = props.selectedFile.type.split('/')[0];
            if (fileType !== 'image' && fileType !== 'video') {
                props.setAlert({
                    message: t('content.coach.add_item.media_file.library.error.type'),
                    type: 'error',
                    duration: 6000,
                });
                props.onClose();
            }
        }
    }, [props.selectedFile]);

    const uploadFile = async (file: File) => {
        try {
            setIsLoading(true);
            const post_res = await api.apiV0MediaFilesPost({
                uploadMediaFileRequest: { file_name: fileName, is_public: isPublic },
            });
            const config = {
                headers: {
                    'Content-Type': file.type,
                },
            };
            await axios.put(post_res.data.upload_url, file, config);
            const put_res = await api.apiV0MediaFilesPut({
                mediaFileRequest: {
                    file_name: fileName,
                    file_type: file.type,
                    path: post_res.data.s3_path,
                    is_public: isPublic,
                },
            });
            props.setFiles((prev: CoachMediaFile[]) => [...prev, put_res.data]);
            props.setExpandedTreeItems((prev: string[]) =>
                Array.from(new Set([...prev, isPublic ? 'public' : 'private'])),
            );
            props.onClose();
        } catch {
            props.setAlert({
                message: t('content.coach.add_item.media_file.library.error.upload'),
                type: 'error',
                duration: 6000,
            });
            setIsLoading(false);
        }
    };

    const updateFile = (mediaFile: MediaFile) => {
        setIsLoading(true);
        const mediaFileId = mediaFile.id;
        api.apiV0MediaFilesMediaFileIdPatch({
            mediaFileId,
            mediaFile: { ...mediaFile, file_name: fileName, is_public: isPublic },
        })
            .then(() => {
                props.setFiles((prev: CoachMediaFile[]) =>
                    prev.map((file) =>
                        file.id === mediaFileId ? { ...file, file_name: fileName, is_public: isPublic } : file,
                    ),
                );
                props.setExpandedTreeItems((prev: string[]) =>
                    Array.from(new Set([...prev, isPublic ? 'public' : 'private'])),
                );
                props.onClose();
            })
            .catch(() => {
                setIsLoading(false);
                props.setAlert({
                    message: t('content.coach.add_item.media_file.library.error.edit'),
                    type: 'error',
                    duration: 6000,
                });
            });
    };

    return (
        <Dialog
            id="file-name-dialog"
            data-testid="file-name-dialog"
            open={true}
            onClose={() => props.onClose()}
            maxWidth="xs"
            fullWidth
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '350px',
                    },
                },
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 4, top: 4 }}>
                    <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
                    {t(`content.coach.add_item.media_file.library.${isNewFile ? 'upload' : 'edit'}_file`)}
                </Box>
            </Box>
            <Box sx={{ p: 1, width: '100%' }}>
                <Paper sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 1 }}>
                    <TextField
                        id="file-name"
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        inputProps={{ 'data-testid': 'file-name-field' } as any}
                        label={t('content.coach.add_item.media_file.library.file_name')}
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                    />
                    <FormControlLabel
                        id="make-public-field"
                        data-testid="make-public-field"
                        value="last_completed"
                        control={
                            <Checkbox
                                id="make-public-checkbox"
                                checked={isPublic}
                                onChange={(e) => setIsPublic(e.target.checked)}
                                size="small"
                                disableRipple
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        height: 18,
                                        width: 18,
                                        marginBottom: '2px',
                                    },
                                }}
                            />
                        }
                        label={
                            <Typography sx={{ fontSize: 14 }}>
                                {t('content.coach.add_item.media_file.library.make_public')}
                            </Typography>
                        }
                    />
                </Paper>
                <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
                    <Button
                        id="btn-cancel"
                        data-testid="btn-cancel"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => props.onClose()}
                        sx={{ mr: 1 }}
                    >
                        {t('content.coach.add_item.media_file.library.cancel')}
                    </Button>
                    <Button
                        id="btn-upload"
                        data-testid="btn-upload"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={!fileName || isLoading}
                        onClick={() => {
                            if (props.selectedFile instanceof File) {
                                uploadFile(props.selectedFile);
                            } else {
                                updateFile(props.selectedFile);
                            }
                        }}
                    >
                        {t(`content.coach.add_item.media_file.library.${isNewFile ? 'upload' : 'update'}`)}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default FileDetailsDialog;
