import AddCardIcon from '@mui/icons-material/AddCard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import EditIcon from '@mui/icons-material/Edit';
import { Box, List, ListItem, ListItemText, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { ExtendedAccident, Payment } from '../../../backendsdk';
import { TrackedDialog as Dialog, TrackedIconButton as IconButton } from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { formatDate } from '../../../utils/TimeFormatter';
import palette from '../../ColorPalette';
import { EMPTY_ACCIDENT_DAMAGE_DETAILS } from './AccidentDamageDetails';
import { MuiRadioGroup, MuiSelect, MuiTextField } from './MuiFormControls';

const PAYMENT_ROW_BACKGROUNDS = ['inherit', palette.neutral[100]];

const TRANSACTION_DIRECTIONS: Record<string, React.ReactNode> = {
    requested: <CreditCardIcon key="requested" fontSize="small" sx={{ color: palette.wellness.gray, mr: 0.5 }} />,
    income: <CreditCardIcon key="income" fontSize="small" sx={{ color: palette.wellness.green, mr: 0.5 }} />,
    expense: <CreditCardIcon key="expense" fontSize="small" sx={{ color: palette.wellness.red, mr: 0.5 }} />,
};

export const PAYMENT_TAGS = [
    'unknown',
    'receipt_from_insurer',
    'payment_to_third_party',
    'deductible',
    'driver_charge',
    'garage_arrangement',
    'receipt_from_third_party',
    'direct_payment_to_garage',
];

const PAYMENT_TAGS_US = ['reserves', 'paid'];

const EMPTY_PAYMENT_DETAILS: Payment = {
    damage_id: 0,
    payment_id: 0,
    created_time: 0,
    cost: 0,
    paid_by: 'customer',
    transaction_direction: 'requested',
    currency: '',
    tag: PAYMENT_TAGS[0],
};

interface AccidentDamagePaymentProps {
    details: ExtendedAccident;
    onSave: CallableFunction;
    setAlert: CallableFunction;
    disabled?: boolean;
}

const AccidentDamagePayment: React.FC<AccidentDamagePaymentProps> = (props: AccidentDamagePaymentProps) => {
    const empty_payment_details = JSON.parse(JSON.stringify(EMPTY_PAYMENT_DETAILS));
    const { profile } = useProfile();
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [editPayment, setEditPayment] = useState<Payment>({
        ...empty_payment_details,
        currency: profile.customer.settings.default_currency,
    });
    const allPayments = props.details.damage_list.reduce(
        (acc, damage) => acc.concat(damage.payment_list),
        [] as Payment[],
    );
    const disabled = props.details.accident_id === 0 || props.disabled;

    return (
        <Box sx={{ p: 1 }}>
            <Box>
                <Typography variant="overline">{t('content.accidents.payments.header')}</Typography>
            </Box>
            <Box sx={{ maxHeight: '8rem', overflowY: 'scroll', display: 'flex', flexDirection: 'column-reverse' }}>
                <List sx={{ p: 0 }}>
                    {allPayments.map((payment, idx: number) => (
                        <AccidentDamagePaymentSpan
                            id={idx}
                            key={payment.payment_id}
                            details={payment}
                            customerName={profile.customer.name}
                            handleClick={() => {
                                setOpen(true);
                                setEditPayment(payment);
                            }}
                            background={PAYMENT_ROW_BACKGROUNDS[idx % 2]}
                        />
                    ))}
                </List>
            </Box>
            <AccidentDamagePaymentForm
                paymentDetails={{ ...empty_payment_details, currency: profile.customer.settings.default_currency }}
                details={props.details}
                onSave={props.onSave}
                disabled={disabled}
                setAlert={props.setAlert}
            />
            <Dialog id="accident-payment-dialog" open={open} onClose={() => setOpen(false)}>
                <Box sx={{ p: 3 }}>
                    <AccidentDamagePaymentForm
                        paymentDetails={editPayment}
                        details={props.details}
                        onSave={props.onSave}
                        closeDialog={() => setOpen(false)}
                        disabled={disabled}
                        setAlert={props.setAlert}
                    />
                </Box>
            </Dialog>
        </Box>
    );
};

export default AccidentDamagePayment;

interface AccidentDamagePaymentSpanProps {
    id: number;
    details: Payment;
    customerName: string;
    handleClick: CallableFunction;
    background: string;
}

export const AccidentDamagePaymentSpan: React.FC<AccidentDamagePaymentSpanProps> = (
    props: AccidentDamagePaymentSpanProps,
) => {
    const { t } = useTranslation();
    return (
        <ListItem
            disableGutters
            secondaryAction={
                <Tooltip title={t('content.accidents.payments.edit_payment')}>
                    <IconButton id="btn-edit-payment" size="small" onClick={() => props.handleClick()} sx={{ mr: 0.5 }}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            }
            sx={{ backgroundColor: props.background, py: 0.5, pl: 0.5 }}
        >
            <Tooltip title={t(`content.accidents.payments.${props.details.transaction_direction}`)}>
                {(TRANSACTION_DIRECTIONS[props.details.transaction_direction] as React.ReactElement) || (
                    <CreditCardIcon key="requested" fontSize="small" />
                )}
            </Tooltip>
            <ListItemText>
                <Typography variant="body2" id={`payment-${props.id}`}>
                    {`${formatDate(props.details.created_time)}: ${props.details.cost} ${props.details.currency} (${
                        props.details.tag
                            ? t(`content.accidents.payments.tags.${props.details.tag}`)
                            : paidByFormat(props.details.paid_by, props.customerName, t)
                    })`}
                </Typography>
            </ListItemText>
        </ListItem>
    );
};

const paidByFormat = (paidByString: string, customerName: string, t?: TFunction) => {
    if (paidByString.toLocaleLowerCase() == 'customer') {
        return customerName;
    } else {
        return t
            ? t(`content.accidents.payments.${paidByString.toLocaleLowerCase().replaceAll(' ', '_')}`)
            : paidByString;
    }
};

interface AccidentDamagePaymentFormProps {
    paymentDetails: Payment;
    details: ExtendedAccident;
    onSave: CallableFunction;
    setAlert: CallableFunction;
    children?: React.ReactNode;
    disabled?: boolean;
    closeDialog?: CallableFunction;
}

export const AccidentDamagePaymentForm: React.FC<AccidentDamagePaymentFormProps> = (
    props: AccidentDamagePaymentFormProps,
) => {
    const empty_accident_details = JSON.parse(JSON.stringify(EMPTY_ACCIDENT_DAMAGE_DETAILS));
    const empty_payment_details = JSON.parse(JSON.stringify(EMPTY_PAYMENT_DETAILS));
    const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
    const { profile } = useProfile();
    const paymentIdToDamageId = {} as Record<number, number>;
    for (const damage of props.details.damage_list) {
        for (const payment of damage.payment_list) {
            paymentIdToDamageId[payment.payment_id] = damage.damage_id;
        }
    }

    const paymentId = props.paymentDetails.payment_id;
    const [details, setDetails] = useState<Payment>(props.paymentDetails);
    const { agencyApi } = useApi();
    const { t } = useTranslation();
    const isUSCustomer = profile.customer.settings.country === 'us';

    const handleChange = (name: string, value: string | number | boolean) => {
        (setDetails as CallableFunction)((oldDetails: Payment) => {
            setDetailsChanged(true);
            const newDetails = Object.fromEntries(
                Object.entries(oldDetails).map(([key, origin_value]) => [key, key === name ? value : origin_value]),
            );
            newDetails[name] = value;
            return newDetails;
        });
    };

    const createPayment = (damageId: number) => {
        let promise;
        if (paymentId > 0) {
            promise = agencyApi.agencyV1AccidentAccidentIdDamageDamageIdPaymentPaymentIdPatch({
                accidentId: props.details.accident_id,
                damageId: damageId,
                paymentId: paymentId,
                payment: { ...details, damage_id: damageId },
            });
        } else {
            promise = agencyApi.agencyV1AccidentAccidentIdDamageDamageIdPaymentPost({
                accidentId: props.details.accident_id,
                damageId: damageId,
                payment: { ...details, damage_id: damageId },
            });
        }
        promise
            .then(() => {
                props.onSave();
                setDetails({ ...empty_payment_details, currency: profile.customer.settings.default_currency });
                setDetailsChanged(false);
            })
            .catch(() => {
                props.setAlert({
                    message: t('content.accidents.payments.error'),
                    type: 'error',
                    duration: 6000,
                });
            });
    };

    const updateAccidentDamagePaymentDetails = () => {
        if (props.closeDialog) {
            props.closeDialog();
        }
        if (paymentId === 0) {
            if (props.details.damage_list.length === 0) {
                agencyApi
                    .agencyV1AccidentAccidentIdDamagePost({
                        accidentId: props.details.accident_id,
                        damage: { ...empty_accident_details, accident_id: props.details.accident_id },
                    })
                    .then((res) => {
                        if (res.data.damage_id) {
                            createPayment(res.data.damage_id);
                        }
                    });
            } else {
                createPayment(props.details.damage_list[0].damage_id);
            }
        } else {
            createPayment(paymentIdToDamageId[paymentId]);
        }
    };

    const paymentButton = (
        <Box>
            <IconButton
                id={`${paymentId > 0 ? 'edit' : 'new'}-payment-btn`}
                disabled={props.disabled || details.cost.toString() === ''}
                size="small"
                color="primary"
                onClick={updateAccidentDamagePaymentDetails}
            >
                {paymentId === 0 ? <AddCardIcon /> : <CreditScoreIcon />}
            </IconButton>
        </Box>
    );

    return (
        <Box sx={{ mt: 'auto' }}>
            <Prompt when={detailsChanged} message={t('content.accidents.prompt_msg')} />
            {paymentId > 0 ? <Typography>{t('content.accidents.payments.edit_payment')}</Typography> : null}
            <MuiRadioGroup
                id={`${paymentId > 0 ? 'edit-' : ''}payment-type`}
                value={details.transaction_direction}
                options={Object.keys(TRANSACTION_DIRECTIONS)}
                icons={TRANSACTION_DIRECTIONS}
                disabled={props.disabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange('transaction_direction', e.target.value)
                }
                t={(direction: string) => t(`content.accidents.payments.${direction}`)}
                row={true}
                sx={{ px: 0.5, py: 0.5 }}
            />
            <Box sx={{ display: 'flex', mt: 1 }}>
                <MuiTextField
                    id={`${paymentId > 0 ? 'edit-' : ''}payment-cost`}
                    label={t('content.accidents.payments.cost')}
                    value={details.cost}
                    disabled={props.disabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e.target.name, e.target.value);
                    }}
                    name="cost"
                    type="number"
                />
                <MuiSelect
                    id={`${paymentId > 0 ? 'edit-' : ''}payment-tag`}
                    label={t('content.accidents.payments.tag')}
                    value={details.tag}
                    options={isUSCustomer ? [...PAYMENT_TAGS, ...PAYMENT_TAGS_US] : PAYMENT_TAGS}
                    disabled={props.disabled}
                    onChange={(e: SelectChangeEvent<string>) => handleChange(e.target.name, e.target.value)}
                    name="tag"
                    t={(tag: string) => t(`content.accidents.payments.tags.${tag}`)}
                    sx={{ mx: 1 }}
                />
                {!props.disabled ? (
                    <Tooltip
                        title={
                            paymentId > 0
                                ? t('content.accidents.payments.update_payment')
                                : t('content.accidents.payments.add')
                        }
                    >
                        {paymentButton}
                    </Tooltip>
                ) : (
                    paymentButton
                )}
            </Box>
        </Box>
    );
};
