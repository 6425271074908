import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Checkbox, Select, SelectChangeEvent, TextField, useMediaQuery, useTheme } from '@mui/material';
import {
    GridCellParams,
    GridColDef,
    GridCsvExportMenuItem,
    GridCsvGetRowsToExportParams,
    GridEditInputCell,
    GridFilterModel,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowEditStopParams,
    GridRowEditStopReasons,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowSelectionModel,
    GridSortModel,
    GridToolbarExportContainer,
    GridToolbarFilterButton,
    GridValueFormatterParams,
    GridValueGetterParams,
    gridExpandedSortedRowIdsSelector,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PolicyV2 } from '../../../backendsdk';
import { TrackedButton as Button, TrackedDataGrid as StyledDataGrid } from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useDevices from '../../../hooks/devices';
import useProfile from '../../../hooks/profile';
import { useQuery } from '../../../hooks/query';
import { getDateColumnType, localeObjectMap } from '../../../utils/DataGridDateTime';
import { policiesPageDefs } from '../../../utils/Pages';
import { normalizeLicensePlate } from '../../../utils/Str';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { gridLocalization } from '../OTA/MuiDeviceTable';
import { GridRenderWithOptions } from '../Users/UserDataGrid';
import { EMPTY_POLICY, POLICY_COLUMNS, POLICY_OPTIONS_LIST } from './Defs';
import RemoveModal from './RemoveModal';
import UploadPoliciesModal from './UploadPoliciesCSV';

const IMMUTABLE_COLUMNS = ['id'];

interface QuickSearchToolbarProps {
    searchText: string;
    disableAdd: boolean;
    isDeviceLoading: boolean;
    onSearchTextChange: () => void;
    onAddPolicy: () => void;
    onUploadPolicies: () => void;
    pendingRemovals: boolean;
    onPendingRemovals: () => void;
    pendingInstallations: boolean;
    onPendingInstallations: () => void;
    pendingPolicies: boolean;
    onPendingPolicies: () => void;
    onDelete: () => void;
    isPoliciesSelected: boolean;
}

const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

    const getAllRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridExpandedSortedRowIdsSelector(apiRef);

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
        >
            <TextField
                fullWidth={isMobile}
                id="table-search-field"
                variant="outlined"
                value={props.searchText}
                onChange={props.onSearchTextChange}
                size="small"
                placeholder={t('table.search')}
                sx={{
                    mr: isMobile ? 0 : 1,
                    mt: isMobile ? 1 : 0,
                    '& .MuiInputBase-input': { paddingY: '0px', paddingLeft: '8px' },
                    mb: 1,
                }}
                inputProps={{ sx: { height: 35 } }}
            />
            <Button
                id="add-policy-btn"
                size="small"
                color="primary"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={props.onAddPolicy}
                disabled={props.disableAdd}
                sx={{ height: 35, mr: 1, mb: 1 }}
            >
                {t('content.policies.add')}
            </Button>
            <Button
                id="upload-policies-btn"
                size="small"
                color="primary"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                onClick={props.onUploadPolicies}
                disabled={props.disableAdd}
                sx={{ height: 35, mr: 1, mb: 1 }}
            >
                {t('content.policies.upload.header')}
            </Button>
            <GridToolbarFilterButton
                componentsProps={{ button: { id: 'filter-btn', variant: 'outlined' } }}
                sx={{ mr: 1, mb: 1 }}
            />
            <GridToolbarExportContainer id="export-btn" variant="outlined" sx={{ height: 35, mr: 1, mb: 1 }}>
                {/* prevent ts error when passing id to menu item */}
                <GridCsvExportMenuItem
                    options={{ getRowsToExport: getAllRows, utf8WithBom: true }}
                    {...{ id: 'export-as-csv' }}
                />
            </GridToolbarExportContainer>
            <Button
                id="pending-removals-btn"
                size="small"
                color="primary"
                variant={props.pendingRemovals ? 'contained' : 'outlined'}
                startIcon={<RemoveModeratorIcon />}
                onClick={props.onPendingRemovals}
                disabled={props.isDeviceLoading}
                sx={{ height: 35, mr: 1, mb: 1 }}
            >
                {t('content.policies.pending_removals')}
            </Button>
            <Button
                id="pending-installations-btn"
                size="small"
                color="primary"
                variant={props.pendingInstallations ? 'contained' : 'outlined'}
                startIcon={<EngineeringIcon />}
                onClick={props.onPendingInstallations}
                disabled={props.isDeviceLoading}
                sx={{ height: 35, mr: 1, mb: 1 }}
            >
                {t('content.policies.pending_installations')}
            </Button>
            <Button
                id="pending-policies-btn"
                size="small"
                color="primary"
                variant={props.pendingPolicies ? 'contained' : 'outlined'}
                startIcon={<PendingActionsIcon />}
                onClick={props.onPendingPolicies}
                disabled={props.isDeviceLoading}
                sx={{ height: 35, mr: 1, mb: 1 }}
            >
                {t('content.policies.pending_policies')}
            </Button>
            <Button
                id="remove-policies-btn"
                size="small"
                color="redColor"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={props.onDelete}
                disabled={!props.isPoliciesSelected}
                sx={{ height: 35, mr: 1, mb: 1 }}
            >
                {t('content.policies.remove')}
            </Button>
        </Box>
    );
};

const PolicyComponent: React.FC = () => {
    const [policies, setPolicies] = useState<Array<PolicyV2>>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [pendingRemovals, setPendingRemovals] = useState(false);
    const [pendingInstallations, setPendingInstallations] = useState(false);
    const [pendingPolicies, setPendingPolicies] = useState(false);
    const { i18n, t } = useTranslation();
    const query = useQuery();
    const { agencyApi } = useApi();
    const apiRef = useGridApiRef();
    const [alertElement, setAlert] = useAlert();
    const { devices, isLoading: isDeviceLoading } = useDevices();
    const { profile } = useProfile();
    const locale = localeObjectMap[i18n.languages[0]];

    const devicesLicensePlates = useMemo(
        () => devices.map((d) => normalizeLicensePlate(d.device.license_plate)),
        [devices],
    );

    const today = dayjs();
    const isPolicyActive = (policy: PolicyV2) =>
        !policy.cancellation_date &&
        !policy.total_loss &&
        (policy.customer_type == 'pending' || dayjs(policy.end_date).isAfter(today));

    const compliantLicensePlatesWithActivePolicies = useMemo(() => {
        const uniqueLicensePlates = new Set(
            policies
                .filter((policy) => isPolicyActive(policy) && !policy.driver_refuse)
                .map((policy) => normalizeLicensePlate(policy.license_plate)),
        );
        return Array.from(uniqueLicensePlates);
    }, [policies]);

    let rows = policies;
    if (pendingInstallations) {
        rows = rows.filter((row) => {
            if (isPolicyActive(row) && !row.driver_refuse) {
                const normalizedLicensePlate = normalizeLicensePlate(row.license_plate);
                return !devicesLicensePlates.includes(normalizedLicensePlate);
            }
            return false;
        });
    }
    if (pendingRemovals) {
        rows = rows.filter((row) => {
            const normalizedLicensePlate = normalizeLicensePlate(row.license_plate);
            if (devicesLicensePlates.includes(normalizedLicensePlate)) {
                return !compliantLicensePlatesWithActivePolicies.includes(normalizedLicensePlate);
            }
            return false;
        });
    }
    if (pendingPolicies) {
        rows = rows.filter((row) => row.customer_type === 'pending');
    }
    if (searchText) {
        rows = rows.filter((row) => {
            const normalizedText = normalizeLicensePlate(searchText);
            const normalizedLicensePlate = normalizeLicensePlate(row.license_plate);
            return !!normalizedLicensePlate && normalizedLicensePlate.includes(normalizedText);
        });
    }
    const rowIdtoRowNum = Object.fromEntries(rows.map((row, index) => [row.id, index]));

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${policiesPageDefs.name}`)}`;
    }, []);

    const loadPolicies = (controller?: AbortController) => {
        setIsLoading(true);
        agencyApi
            .agencyV2PolicyGet({ signal: controller?.signal })
            .then((res) => {
                setPolicies(res.data);
                setIsLoading(false);
            })
            .catch((res) => {
                if (!controller?.signal.aborted) {
                    const message = res.response.status === 404 ? 'no_data' : 'fetch_error';
                    setAlert({
                        message: t(`content.policies.${message}`),
                        type: 'error',
                        duration: 6000,
                    });
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        const controller = new AbortController();
        loadPolicies(controller);
        return () => controller.abort();
    }, []);

    useEffect(() => {
        const licensePlate = query.get('license_plate');
        if (licensePlate) {
            setSearchText(licensePlate);
        }
    }, [query]);

    const EditInputCell = (props: GridRenderEditCellParams) => {
        return (
            <GridEditInputCell
                {...props}
                // match input style with the cell
                inputProps={{
                    id: `${props.field.replaceAll('_', '-')}-input`,
                    'data-testid': `${props.id}-${props.field.replaceAll('_', '-')}-input`,
                    style: { paddingLeft: '9px', paddingRight: '9px', paddingBottom: '1px' },
                }}
                sx={{ height: '100%' }}
                debounceMs={0}
            />
        );
    };

    const renderEdit = (params: GridRenderEditCellParams) => {
        return <EditInputCell {...params} />;
    };

    const SelectEditInputCell = (props: GridRenderWithOptions) => {
        const { id, field } = props;
        const handleChange = async (event: SelectChangeEvent) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        };

        return (
            <Select
                id={`${props.field.replaceAll('_', '-')}-input`}
                data-testid={`${props.id}-${props.field.replaceAll('_', '-')}-input`}
                value={props.value}
                onChange={handleChange}
                size="small"
                sx={{
                    height: '100%',
                    width: '100%',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                    },
                }}
                inputProps={{ sx: { pt: 0.75, pl: 1.25, fontSize: 14 } }}
                native
            >
                {props.options.map((option, idx) => (
                    <option key={idx} value={option}>
                        {option && props.t ? props.t(option) : option}
                    </option>
                ))}
            </Select>
        );
    };

    const renderSelectEdit = (params: GridRenderCellParams) => {
        return (
            <SelectEditInputCell
                options={POLICY_OPTIONS_LIST[params.field as keyof typeof POLICY_OPTIONS_LIST]}
                t={(s: string) => t(`content.policies.${params.field}.${s}`, s)}
                {...params}
            />
        );
    };

    const renderBooleanCell = (params: GridRenderCellParams) => {
        return params.value ? <CheckIcon /> : <CloseIcon />;
    };

    const renderBooleanEdit = (params: GridRenderCellParams) => {
        const { id, field } = params;

        const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.checked });
        };

        return (
            <Checkbox
                id={`${params.field.replaceAll('_', '-')}-input`}
                data-testid={`${params.id}-${params.field.replaceAll('_', '-')}-input`}
                checked={params.value}
                onChange={handleChange}
            />
        );
    };

    const renderCellMap: Record<string, (params: GridRenderCellParams) => JSX.Element> = {
        boolean: renderBooleanCell,
        number: (params: GridRenderCellParams) => (!!params.value ? params.value : ''),
    };

    const renderEditMap: Record<string, (params: GridRenderEditCellParams) => JSX.Element> = {
        string: renderEdit,
        singleSelect: renderSelectEdit,
        boolean: renderBooleanEdit,
        number: renderEdit,
        date: renderEdit,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uploadNewPolicies = (newPolicies: Array<Record<string, any>>) => {
        createNewPolicies(newPolicies as Array<PolicyV2>)
            .then((res) => {
                setPolicies((prev) => [...prev, ...res.data]);
                setOpenUploadModal(false);
            })
            .catch((err) => {
                const errorMessage = err.response.data.details.split('. ')[1];
                const errorDetails = t(`content.policies.errors.${errorMessage}`, errorMessage);
                setAlert({
                    message: `${t('content.policies.add_error')}. ${errorDetails}`,
                    type: 'error',
                    duration: 6000,
                });
                return err;
            });
    };

    const createNewPolicies = (newPolicies: Array<PolicyV2>) => {
        return agencyApi.agencyV2PolicyPost({ policyV2: newPolicies });
    };

    const processRowUpdate = (newRow: GridRowModel, prevRow: GridRowModel) => {
        // reformat the date fields before sending to the server
        const { ...formattedRow } = { ...newRow };
        delete formattedRow['customer'];
        for (const [column, value] of Object.entries(formattedRow)) {
            if (POLICY_COLUMNS[column as keyof PolicyV2] === 'date' && !!value && value instanceof Date) {
                formattedRow[column] = dayjs(value).format('YYYY-MM-DD');
            }
        }
        if (JSON.stringify(formattedRow) === JSON.stringify(prevRow)) {
            // the row is unchanged, so don't update
            if (newRow.id === 0) {
                // the row was new, so remove it from the list
                setPolicies((prev) => prev.filter((policy) => policy.id !== newRow.id));
            }
            return prevRow;
        }

        if (newRow.id > 0) {
            return agencyApi
                .agencyV2PolicyPolicyIdPost({ policyId: newRow.id, policyV2: [formattedRow as PolicyV2] })
                .then(() => {
                    setPolicies((prev: Array<PolicyV2>) => {
                        return prev.map((policy) => (policy.id == newRow.id ? (newRow as PolicyV2) : policy));
                    });
                    return newRow;
                })
                .catch((err) => {
                    const errorMessage = err.response.data.details.split('. ')[1];
                    const errorDetails = t(`content.policies.errors.${errorMessage}`, errorMessage);
                    setAlert({
                        message: `${t('content.policies.update_error')}. ${errorDetails}`,
                        type: 'error',
                        duration: 6000,
                    });
                    return err;
                });
        } else {
            return createNewPolicies([formattedRow as PolicyV2])
                .then((res) => {
                    setPolicies((prev: Array<PolicyV2>) => {
                        return prev.map((policy) =>
                            policy.id === newRow.id ? ({ ...newRow, id: res.data[0].id } as PolicyV2) : policy,
                        );
                    });
                    return newRow;
                })
                .catch((err) => {
                    const errorMessage = err.response.data.details.split('. ')[1];
                    const errorDetails = t(`content.policies.errors.${errorMessage}`, errorMessage);
                    setAlert({
                        message: `${t('content.policies.add_error')}. ${errorDetails}`,
                        type: 'error',
                        duration: 6000,
                    });
                    return err;
                });
        }
    };

    const handleRowEditStop = (params: GridRowEditStopParams) => {
        // if adding a new row is cancelled, remove it from the list
        if (params.id === 0 && params.reason === GridRowEditStopReasons.escapeKeyDown) {
            setPolicies((prev) => prev.filter((policy) => policy.id !== params.id));
        }
    };

    const handleAddPolicy = () => {
        // before adding a new row, reset the search text and remove sorting and filtering
        setSearchText('');
        setFilterModel({ items: [] });
        setSortModel([]);
        setPolicies((prev) => [{ ...EMPTY_POLICY }, ...prev]);
        setRowModesModel((prev) => ({
            ...prev,
            [0]: { mode: GridRowModes.Edit, fieldToFocus: 'license_plate' },
        }));
    };

    const columns: GridColDef[] = useMemo(() => {
        return Object.entries(POLICY_COLUMNS)
            .filter(([columnNmae]) => columnNmae != 'customer')
            .map(([columnName, columnType]) => {
                const typeProperties = {
                    date: {
                        ...getDateColumnType(locale),
                        valueFormatter: (params: GridValueFormatterParams) =>
                            !!params.value ? dayjs(params.value).format(profile.dateFormat) : '',
                        valueGetter: (params: GridValueGetterParams) => (params.value ? new Date(params.value) : null),
                    },
                    singleSelect: {
                        valueOptions: POLICY_OPTIONS_LIST[columnName as keyof typeof POLICY_OPTIONS_LIST]?.map(
                            (value: string) => ({
                                value,
                                label: t(`content.policies.${columnName}.${value}`, value),
                            }),
                        ),
                        valueFormatter: (params: GridValueFormatterParams) =>
                            t(`content.policies.${params.field}.${params.value}`, params.value),
                    },
                };

                return {
                    field: columnName,
                    type: columnType,
                    headerName: t(`table.headers.policies.${columnName}`),
                    headerAlign: 'left',
                    align: 'left',
                    renderEditCell: renderEditMap[columnType],
                    renderCell: renderCellMap[columnType],
                    ...typeProperties[columnType as keyof typeof typeProperties],
                    width: columnName == 'id' ? 100 : 150,
                    editable: !IMMUTABLE_COLUMNS.includes(columnName),
                    hideable: columnName !== 'license_plate',
                };
            });
    }, [i18n.languages[0]]);

    useEffect(() => {
        // if a new row is being edited, go to the first page
        if (rowModesModel[0] && rowModesModel[0].mode === 'edit') {
            setPaginationModel((prev) => ({ ...prev, page: 0 }));
            apiRef.current.scrollToIndexes({
                rowIndex: 0,
                colIndex: 0,
            });
        }
    }, [rowModesModel]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                pb: 2,
            }}
        >
            {alertElement}
            {openUploadModal ? (
                <UploadPoliciesModal onClose={() => setOpenUploadModal(false)} onUpload={uploadNewPolicies} />
            ) : null}
            {removeModalOpen ? (
                <RemoveModal
                    setAlert={setAlert}
                    onClose={() => setRemoveModalOpen(false)}
                    items={selectionModel.map((rowId) => parseInt(rowId.toString()))}
                    setPolicies={() => {
                        setPolicies((prev) => prev.filter((policy) => !selectionModel.includes(policy.id)));
                        setAlert({
                            message: t('content.policies.remove_success'),
                            type: 'success',
                            duration: 6000,
                        });
                        setSelectionModel([]);
                    }}
                />
            ) : null}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                <StyledDataGrid
                    id="policies-data-grid"
                    apiRef={apiRef}
                    density="compact"
                    loading={isLoading}
                    columns={columns}
                    rows={rows}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={() => null}
                    disableMultipleRowSelection
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    checkboxSelection
                    disableRowSelectionOnClick
                    pagination={true}
                    editMode="row"
                    filterModel={filterModel}
                    onFilterModelChange={setFilterModel}
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={setRowModesModel}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    getCellClassName={(params: GridCellParams) => `${params.field}-${rowIdtoRowNum[params.row.id]}`}
                    getRowClassName={(params: GridRowModel) =>
                        `row-${params.id} row-license-plate-${normalizeLicensePlate(params.row.license_plate)}`
                    }
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    onRowEditStop={handleRowEditStop}
                    localeText={{
                        ...gridLocalization[i18n.languages[0]],
                        filterPanelRemoveAll: t('table.remove_all'),
                        columnMenuManageColumns: t('table.manage_columns'),
                        unpin: t('table.unpin'),
                    }}
                    slots={{ toolbar: QuickSearchToolbar }}
                    slotProps={{
                        toolbar: {
                            searchText,
                            onSearchTextChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                                setSearchText(event.target.value),
                            disableAdd: rows.map((row) => row.id).includes(0),
                            isDeviceLoading: isDeviceLoading,
                            onAddPolicy: handleAddPolicy,
                            onUploadPolicies: () => setOpenUploadModal(true),
                            pendingRemovals: pendingRemovals,
                            onPendingRemovals: () => {
                                setPendingRemovals(!pendingRemovals);
                                setPendingInstallations(false);
                                setPendingPolicies(false);
                            },
                            pendingInstallations: pendingInstallations,
                            onPendingInstallations: () => {
                                setPendingRemovals(false);
                                setPendingInstallations(!pendingInstallations);
                                setPendingPolicies(false);
                            },
                            pendingPolicies: pendingPolicies,
                            onPendingPolicies: () => {
                                setPendingRemovals(false);
                                setPendingInstallations(false);
                                setPendingPolicies(!pendingPolicies);
                            },
                            onDelete: () => setRemoveModalOpen(true),
                            isPoliciesSelected: selectionModel.length > 0,
                        },
                        pagination: {
                            sx: {
                                mr: '45px',
                            },
                        },
                        columnsPanel: {
                            sx: {
                                '& .MuiDataGrid-panelFooter button:first-child': {
                                    display: 'none',
                                },
                            },
                        },
                        baseCheckbox: {
                            inputProps: {
                                className: 'select-checkbox',
                            },
                        },
                    }}
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& .MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScrollerContent': {
                            borderBottom: `1px solid ${palette.neutral[400]}`,
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cell--editing': {
                            padding: '0 !important',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        backgroundColor: palette.neutral[50],
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default PolicyComponent;
