import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Alert,
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
} from '../../components/TrackedComponents';
import useApi from '../../hooks/api';
import { useQuery } from '../../hooks/query';
import LogoPrimary from '../../images/logo/logo_two_tone.svg';
import { removeStorageItem } from '../../utils/Storage';
import { REFRESH_TOKEN_NAME } from '../Layout';
import OTPInput from './OTPInput';
import ResetRequest from './ResetRequest';

const LoginForm: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [sendCode, setSendCode] = useState<boolean>(false);
    const [resetPassword, setResetPassword] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');
    const query = useQuery();
    const [message, setMessage] = useState<string>(query.has('reset') ? 'login.reset_success' : '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { api, setToken, setRefreshToken, setTokenExpiration } = useApi();
    const queryClient = useQueryClient();

    const loginUser = (code?: string) => {
        queryClient.clear();
        setMessage('');
        setIsLoading(true);
        api.apiAuthPost({
            userCredentials: {
                username: username,
                password: password,
                code: code,
            },
        })
            .then((res) => {
                if (res.status == 200 && res.data.access) {
                    const token: string = res.data.access;
                    setToken(token);
                    setRefreshToken(res.data.refresh);
                    setTokenExpiration(res.data.expires_in);
                } else {
                    if (sendCode && code !== undefined) {
                        setMessage('login.code_error');
                        setCode('');
                    }
                    setSendCode(true);
                }
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    setMessage('login.credentials_msg');
                } else {
                    setMessage('login.error_msg');
                }
                removeStorageItem(REFRESH_TOKEN_NAME);
            })
            .finally(() => setIsLoading(false));
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.repeat && !isLoading) {
            if (sendCode) {
                loginUser(code);
            } else {
                loginUser();
            }
        }
    };

    useEffect(() => {
        if (code.length === 6 && /\d{6}/.test(code)) {
            loginUser(code);
        }
    }, [code]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const loginCredentialsForm = (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ width: '100%', pt: 3, pb: 1 }}>
                    <img src={LogoPrimary} width="100%" />
                </Box>
                <Typography textAlign="center">{t('login.login_btn')}</Typography>
                {!!message ? (
                    <Alert
                        severity={message === 'login.reset_success' ? 'success' : 'error'}
                        sx={{ mt: 1, mb: 2, p: 0, px: 1 }}
                    >
                        {t(message)}
                    </Alert>
                ) : null}
            </Box>
            <Box
                onKeyDown={handleKeyDown}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <TextField
                    id="username"
                    label={t('login.username')}
                    value={username}
                    error={message === 'login.credentials_msg'}
                    required
                    fullWidth
                    autoFocus
                    onChange={(e) => setUsername(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: !!message ? 0 : 1.5, mb: 2 }}
                />
                <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <InputLabel htmlFor="password" required shrink>
                        {t('login.password')}
                    </InputLabel>
                    <OutlinedInput
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    id="toggle-password-visibility"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={t('login.password')}
                        fullWidth
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={message === 'login.credentials_msg'}
                        notched
                    />
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                }}
            >
                <Button
                    id="login"
                    variant="contained"
                    color="secondary"
                    onClick={() => loginUser()}
                    fullWidth
                    disabled={isLoading}
                >
                    {isLoading ? t('login.loading') : t('login.login_btn')}
                </Button>
                <Link
                    id="forgot-password-link"
                    component="button"
                    fontSize={12}
                    sx={{ mt: 1 }}
                    onClick={() => {
                        setMessage('');
                        setResetPassword(true);
                    }}
                >
                    {t('login.forgot_password')}
                </Link>
            </Box>
        </>
    );

    const OTPForm = (
        <>
            <Box>
                <Box sx={{ width: '100%', pt: 3, pb: 1 }}>
                    <img src={LogoPrimary} width="100%" />
                </Box>
                <Typography textAlign="center">{t('login.authenticate')}</Typography>
                {!!message ? (
                    <Alert severity="error" sx={{ mt: 1, mb: 2, p: 0, px: 1 }}>
                        {t(message)}
                    </Alert>
                ) : null}
            </Box>
            <Box
                onKeyDown={handleKeyDown}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography variant="body2" sx={{ mt: !!message ? 0 : 1.5, mb: 1 }}>
                    {t('login.code_msg')}
                </Typography>
                <OTPInput value={code} valueLength={6} onChange={(value: string) => setCode(value)} />
                <Link
                    id="resend-code-link"
                    component="button"
                    fontSize={12}
                    sx={{ mt: 1.5 }}
                    onClick={() => loginUser()}
                >
                    {t('login.code_resend')}
                </Link>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                }}
            >
                <Button
                    id="login"
                    variant="contained"
                    color="secondary"
                    disabled={isLoading}
                    onClick={() => loginUser(code)}
                    fullWidth
                >
                    {t('login.login_btn')}
                </Button>
                <Link
                    id="back-to-login-link"
                    component="button"
                    fontSize={12}
                    sx={{ mt: 1 }}
                    onClick={() => {
                        setMessage('');
                        setSendCode(false);
                    }}
                >
                    {t('login.back')}
                </Link>
            </Box>
        </>
    );

    const formContent = sendCode ? OTPForm : loginCredentialsForm;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                p: 3,
                pt: 0,
            }}
        >
            {resetPassword ? (
                <ResetRequest
                    onBack={() => {
                        setMessage('');
                        setResetPassword(false);
                    }}
                />
            ) : (
                formContent
            )}
        </Box>
    );
};

export default LoginForm;
