import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, CircularProgress, IconButton, Typography, lighten } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

import { ReleaseNotes } from '../../backendsdk';
import useApi from '../../hooks/api';
import useIsMobile from '../../hooks/isMobile';
import useProfile from '../../hooks/profile';
import palette from '../../layout/ColorPalette';
import { TrackedDialog as Dialog } from '../TrackedComponents';

interface ReleaseNotesProps {
    onClose: CallableFunction;
    setLastViewed: CallableFunction;
}

export const CURRENT_VERSION = '2023.01.08-0';
export const RELEASE_NOTES_KEY = 'OE-release-notes';
const TAG_COLOR_MAP: Record<string, string> = {
    new_features: lighten(palette.accent, 0.25),
    improvements: lighten('#9369bd', 0.3),
};

const ReleaseNotesComponent: React.FC<ReleaseNotesProps> = (props: ReleaseNotesProps) => {
    const [releaseNotes, setReleaseNotes] = React.useState<ReleaseNotes[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { api } = useApi();
    const { profile } = useProfile();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    useEffect(() => {
        setIsLoading(true);
        api.apiV0ReleaseNotesGet().then((res) => {
            setReleaseNotes(res.data);
            setIsLoading(false);
            props.setLastViewed(CURRENT_VERSION);
            localStorage.setItem(RELEASE_NOTES_KEY, CURRENT_VERSION);
        });
    }, []);

    let content;
    if (isLoading) {
        content = (
            <Box
                sx={{
                    backgroundColor: palette.bgColor,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (releaseNotes.length === 0) {
        content = (
            <Box
                sx={{
                    backgroundColor: palette.bgColor,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline">{t('content.help.release_notes.no_release_notes')}</Typography>
            </Box>
        );
    } else {
        content = (
            <Box
                className="release-notes"
                sx={{
                    backgroundColor: palette.bgColor,
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {releaseNotes.map((releaseNote, idx) => (
                    <Box
                        key={releaseNote.timestamp}
                        data-testid={`release-notes-${releaseNote.version}`}
                        sx={{
                            mb: idx === releaseNotes.length - 1 ? 0 : 1,
                            p: 1,
                            borderRadius: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            position: 'relative',
                            backgroundColor: palette.white,
                            boxShadow:
                                '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            {releaseNote.tags.map((tag) => (
                                <Chip
                                    key={`${releaseNote.version}-${tag}`}
                                    label={t(`content.help.release_notes.tags.${tag}`, tag)}
                                    sx={{ backgroundColor: TAG_COLOR_MAP[tag], borderRadius: '4px', mr: 1 }}
                                />
                            ))}
                            <Chip
                                label={dayjs.unix(releaseNote.timestamp).format(profile.dateFormat)}
                                sx={{ borderRadius: '4px' }}
                            />
                        </Box>
                        <Markdown>{releaseNote.release_notes}</Markdown>
                    </Box>
                ))}
            </Box>
        );
    }

    return (
        <Dialog
            id="release-notes-dialog"
            data-testid="release-notes-dialog"
            open={true}
            onClose={() => props.onClose()}
            fullScreen={isMobile}
            sx={
                !isMobile
                    ? {
                          '& .MuiDialog-container': {
                              '& .MuiPaper-root': {
                                  width: '100%',
                                  maxWidth: '500px',
                                  height: '500px',
                              },
                          },
                      }
                    : {}
            }
        >
            <Box
                sx={{
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: 0,
                    p: 1,
                    zIndex: 2,
                }}
            >
                <Box sx={{ position: 'absolute', display: 'flex', right: 4, top: 4 }}>
                    <IconButton size="small" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
                    {t('content.help.release_notes.header')}
                </Box>
            </Box>
            {content}
        </Dialog>
    );
};

export default ReleaseNotesComponent;
