import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    Box,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DeviceV3, TripDetails } from '../../../../backendsdk';
import { TrackedIconButton as IconButton } from '../../../../components/TrackedComponents';
import palette from '../../../ColorPalette';
import { RTLDirectionContext } from '../../../Layout';
import GeneralDetailsPanel from './GeneralDetailsPanel';
import ScoresPanel from './ScoresPanel';
import TripsPanel from './TripsPanel';
import WellnessPanel from './WellnessPanel';

export const MuiAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.bgColor.main,
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export const MuiAccordionSummary = styled((props: AccordionSummaryProps) => <AccordionSummary {...props} />)(
    ({ theme }) => ({
        color: theme.palette.grayColor.main,
        '& .MuiTypography-root': {
            fontWeight: 'bold',
        },
    }),
);

interface DeviceDetailsComponentProps {
    devices: DeviceV3[];
    device: DeviceV3;
    selectedTrip?: TripDetails;
    setSelectedTrip: CallableFunction;
    timespan: 'day' | 'week';
    setTimespan: CallableFunction;
    additionalTrip?: TripDetails;
}

const DeviceDetailsComponent: React.FC<DeviceDetailsComponentProps> = (props: DeviceDetailsComponentProps) => {
    const { device } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const isRTL = useContext(RTLDirectionContext);

    const currentDeviceIndex = props.devices.findIndex((d) => d.device.device_id === device.device.device_id);
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                minHeight: 0,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: 60,
                    flexShrink: 0,
                    backgroundColor: palette.neutral[200],
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocalShippingIcon sx={{ fontSize: 28, ml: 1.75, mr: 1 }} />
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {device.device.license_plate}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={t('content.fleet.details.previous')}>
                        <Box>
                            <IconButton
                                id="btn-previous-device"
                                disabled={currentDeviceIndex === 0}
                                onClick={() => {
                                    const previousDevice = props.devices[currentDeviceIndex - 1];
                                    history.push(
                                        `/overview/${encodeURIComponent(previousDevice.device.license_plate)}`,
                                    );
                                }}
                            >
                                {isRTL ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
                            </IconButton>
                        </Box>
                    </Tooltip>
                    <Tooltip title={t('content.fleet.details.next')}>
                        <Box>
                            <IconButton
                                id="btn-next-device"
                                disabled={currentDeviceIndex === props.devices.length - 1}
                                onClick={() => {
                                    const nextDevice = props.devices[currentDeviceIndex + 1];
                                    history.push(`/overview/${encodeURIComponent(nextDevice.device.license_plate)}`);
                                }}
                                sx={{ mr: 1 }}
                            >
                                {isRTL ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
                            </IconButton>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
            <Box sx={{ width: '100%', height: '100%', overflowY: 'auto', minHeight: 0 }}>
                <MuiAccordion defaultExpanded disableGutters>
                    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} id="general-details-panel-header">
                        <Typography>{t('content.fleet.details.general_details.header')}</Typography>
                    </MuiAccordionSummary>
                    <AccordionDetails sx={{ pr: 1.5 }}>
                        <GeneralDetailsPanel device={device} />
                    </AccordionDetails>
                </MuiAccordion>
                <MuiAccordion defaultExpanded disableGutters>
                    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} id="scores-panel-header">
                        <Typography>{t('content.fleet.details.scores.header')}</Typography>
                    </MuiAccordionSummary>
                    <AccordionDetails>
                        <ScoresPanel device={device} />
                    </AccordionDetails>
                </MuiAccordion>
                <MuiAccordion defaultExpanded disableGutters>
                    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} id="wellness-panel-header">
                        <Typography>{t('content.fleet.details.wellness.header')}</Typography>
                    </MuiAccordionSummary>
                    <AccordionDetails>
                        <WellnessPanel device={device} />
                    </AccordionDetails>
                </MuiAccordion>
                <MuiAccordion defaultExpanded disableGutters>
                    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} id="trips-panel-header">
                        <Typography>{t('content.fleet.details.trips.header')}</Typography>
                    </MuiAccordionSummary>
                    <AccordionDetails>
                        <TripsPanel {...props} />
                    </AccordionDetails>
                </MuiAccordion>
            </Box>
        </Box>
    );
};

export default DeviceDetailsComponent;
