import CloseIcon from '@mui/icons-material/Close';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import { Box, Card, FormControlLabel, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { EventDetails, EventTagActionEnum } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedDialog as Dialog,
    TrackedIconButton as IconButton,
    TrackedLink as Link,
} from '../../../components/TrackedComponents';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { CustomRadio } from '../Coaching/Agenda/MetricsForm';
import { OE_ROLES } from '../Users/UsersComponent';
import { CRASH_INCIDENT_EVENT_TYPE, CRASH_REPORTED_TAG } from './EventDetails';

interface ReportAccidentInterface {
    eventId: number;
    onClose: CallableFunction;
    onReportNewAccident: CallableFunction;
    setAlert: CallableFunction;
    details: EventDetails;
    setDetails: CallableFunction;
    updateTable?: CallableFunction;
    currentAccidentId?: number;
}

export const ReportAccident: React.FC<ReportAccidentInterface> = (props) => {
    const [reportOption, setReportOption] = useState<'new' | 'current' | 'existing'>('new');
    const [accidentId, setAccidentId] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { api, agencyApi } = useApi();
    const { t } = useTranslation();
    const { profile } = useProfile();

    const handleReportExistingAccident = async (accidentId: string) => {
        setIsLoading(true);
        let accidentData;
        try {
            accidentData = await agencyApi.agencyV1AccidentAccidentIdGet({ accidentId: parseInt(accidentId) });
        } catch {
            props.setAlert({
                type: 'error',
                message: 'Accident not found',
                duration: 6000,
            });
            return setIsLoading(false);
        }
        try {
            await agencyApi.agencyV1AccidentAccidentIdPatch({
                accidentId: parseInt(accidentId),
                extendedAccident: { ...accidentData.data, event: props.eventId },
            });
            const link = (
                <Link id="view-accident-link" component={RouterLink} to={`/accidents/${accidentId}`}>
                    {t('content.events.report.view_accident')}
                </Link>
            );
            props.setAlert({
                type: 'success',
                link,
                message: t('content.events.report.success_report_existing'),
                duration: 6000,
            });
            if (
                OE_ROLES.includes(profile.role) &&
                props.details.type === CRASH_INCIDENT_EVENT_TYPE &&
                !props.details.tags.includes(CRASH_REPORTED_TAG)
            ) {
                await api.apiV0EventEventIdTagPost({
                    eventId: props.details.event_id,
                    eventTag: { name: CRASH_REPORTED_TAG, action: EventTagActionEnum.Add },
                });
                props.setDetails((prev: EventDetails | undefined) => {
                    if (prev) {
                        return { ...prev, tags: [...prev.tags, CRASH_REPORTED_TAG] };
                    }
                });
                if (props.updateTable) {
                    props.updateTable({ ...props.details, tags: [...props.details.tags, CRASH_REPORTED_TAG] });
                }
            }
            props.onClose();
        } catch {
            props.setAlert({
                type: 'error',
                message: t('content.events.report.error_report_existing'),
                duration: 6000,
            });
            return setIsLoading(false);
        }
    };

    return (
        <Dialog id="report-accident-dialog" open={true} fullWidth maxWidth="xs" onClose={() => props.onClose()}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        backgroundColor: 'secondary.main',
                        position: 'sticky',
                        top: 0,
                        p: 1,
                        zIndex: 2,
                    }}
                >
                    <Box sx={{ position: 'absolute', display: 'flex', right: 0, top: 0, mt: 0.5, mr: 0.5 }}>
                        <IconButton id="btn-close-modal" size="small" onClick={() => props.onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MinorCrashIcon sx={{ mr: 0.5 }} />
                        {t('content.events.report.header')}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'bgColor.main',
                        p: 1,
                        m: 0,
                    }}
                >
                    <Card sx={{ width: '100%', p: 1 }}>
                        <RadioGroup
                            value={reportOption}
                            onChange={(e) => {
                                setReportOption(e.target.value as 'new' | 'existing');
                            }}
                            sx={{ alignItems: 'flex-start' }}
                        >
                            <FormControlLabel
                                id="new-radio"
                                data-testid="new-radio"
                                value="new"
                                control={
                                    <CustomRadio
                                        id="new-radio-btn"
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        inputProps={{ 'data-testid': 'new-radio-btn' } as any}
                                        checked={reportOption === 'new'}
                                        disableRipple
                                    />
                                }
                                label={<Typography sx={{ fontSize: 14 }}>{t('content.events.report.new')}</Typography>}
                            />
                            {!!props.currentAccidentId ? (
                                <FormControlLabel
                                    id="current-radio"
                                    data-testid="current-radio"
                                    value="current"
                                    control={
                                        <CustomRadio
                                            id="current-radio-btn"
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            inputProps={{ 'data-testid': 'current-radio-btn' } as any}
                                            checked={reportOption === 'current'}
                                            disableRipple
                                        />
                                    }
                                    label={
                                        <Typography sx={{ fontSize: 14 }}>
                                            {t('content.events.report.current')}
                                        </Typography>
                                    }
                                    sx={{ mt: 0.75 }}
                                />
                            ) : null}
                            <Box sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
                                <FormControlLabel
                                    id="existing-radio"
                                    data-testid="existing-radio"
                                    value="existing"
                                    control={
                                        <CustomRadio
                                            id="existing-radio-btn"
                                            checked={reportOption === 'existing'}
                                            disableRipple
                                        />
                                    }
                                    label={
                                        <Typography sx={{ fontSize: 14 }}>
                                            {t('content.events.report.existing')}
                                        </Typography>
                                    }
                                    sx={{ flexShrink: 0, mr: 0.5 }}
                                />
                                <TextField
                                    id="accident-number"
                                    size="small"
                                    label={t('content.events.report.accident_number')}
                                    disabled={reportOption !== 'existing'}
                                    value={accidentId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccidentId(e.target.value)}
                                />
                            </Box>
                        </RadioGroup>
                    </Card>
                    <Box sx={{ width: '100%', display: 'flex', mt: 1 }}>
                        <Button
                            id="btn-cancel"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => props.onClose()}
                            disabled={isLoading}
                            sx={{ mr: 1 }}
                        >
                            {t('content.events.report.cancel')}
                        </Button>
                        <Button
                            id="btn-continue"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={(reportOption === 'existing' && !accidentId) || isLoading}
                            onClick={() => {
                                if (reportOption === 'new') {
                                    props.onReportNewAccident();
                                } else if (reportOption === 'current' && !!props.currentAccidentId) {
                                    handleReportExistingAccident(props.currentAccidentId.toString());
                                } else if (!!accidentId) {
                                    handleReportExistingAccident(accidentId);
                                }
                            }}
                        >
                            {t('content.events.report.continue')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export default ReportAccident;
