import { Box, Card, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TaggingData } from '../../../../backendsdk';
import ImpactChartSide from './ImpactChartSide';
import ImpactChartTop from './ImpactChartTop';

interface PointsOfImpactCardProps {
    taggingData?: TaggingData;
    isLoading: boolean;
}

const PointsOfImpactCard: React.FC<PointsOfImpactCardProps> = ({ taggingData, isLoading }: PointsOfImpactCardProps) => {
    const { t } = useTranslation();

    const taggedPointsOfImpact = taggingData?.points_of_impact;

    let content;
    if (isLoading) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (!taggedPointsOfImpact || Object.values(taggedPointsOfImpact).length === 0) {
        content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography variant="overline">{t('content.accident_overview.no_data')}</Typography>
            </Box>
        );
    } else {
        content = (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', p: 4 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {t('content.accident_overview.points_of_impact_header')}
                        </Typography>
                        <Typography fontSize={14} sx={{ mt: 1, mb: 0.5 }}>
                            {t('content.accident_overview.points_of_impact_instructions1')}
                        </Typography>
                        <Typography fontSize={14}>
                            {t('content.accident_overview.points_of_impact_instructions2')}
                        </Typography>
                    </Box>
                    {!!taggedPointsOfImpact ? <ImpactChartSide pointsOfImpact={taggedPointsOfImpact} /> : null}
                </Box>
                {!!taggedPointsOfImpact ? <ImpactChartTop pointsOfImpact={taggedPointsOfImpact} /> : null}
            </>
        );
    }

    return (
        <Card
            data-testid="impact-chart-container"
            sx={{
                width: '100%',
                height: 400,
                display: 'flex',
                mr: 2,
            }}
        >
            {content}
        </Card>
    );
};

export default PointsOfImpactCard;
