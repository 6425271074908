import AddIcon from '@mui/icons-material/Add';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { Box, Menu, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import {
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridRowModel,
    GridRowModesModel,
    GridValueFormatterParams,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerConfig, Overlay } from '../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedIconButton as IconButton,
    TrackedMenuItem as MenuItem,
    TrackedDataGrid as StyledDataGrid,
} from '../../../components/TrackedComponents';
import { useAlert } from '../../../hooks/alert';
import useApi from '../../../hooks/api';
import useProfile from '../../../hooks/profile';
import { localeObjectMap } from '../../../utils/DataGridDateTime';
import { CustomerConfigPageDefs } from '../../../utils/Pages';
import palette from '../../ColorPalette';
import { DEFAULT_TITLE } from '../../Layout';
import { gridLocalization } from '../OTA/MuiDeviceTable';
import { GridRenderWithOptions } from '../Users/UserDataGrid';
import AddCustomerModal from './AddCustomerModal';
import ConfigModal from './ConfigModal';
import QuickSearchToolbar from './QuickSearchToolbar';
import UpdateOverlay from './UpdateOverlay';

interface CustomerConfigComponentProps {
    customers: CustomerConfig[];
    setCustomers: React.Dispatch<React.SetStateAction<CustomerConfig[]>>;
    overlays: Overlay[];
    setOverlays: React.Dispatch<React.SetStateAction<Overlay[]>>;
    loadCustomer: () => void;
    toolbar: React.ReactNode;
}

const CustomerConfigComponent: React.FC<CustomerConfigComponentProps> = (props) => {
    const { customers, setCustomers, overlays, setOverlays, loadCustomer } = props;
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showConfigRow, setShowConfigRow] = useState<number>(-1);
    const [showEditOverlay, setShowEditOverlay] = useState<number>(-1);
    const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false);
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; overlayId: number } | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const { i18n, t } = useTranslation();
    const { controlApi } = useApi();
    const apiRef = useGridApiRef();
    const { profile } = useProfile();
    const [alertElement, setAlert] = useAlert();
    const locale = localeObjectMap[i18n.languages[0]];
    const buttonRef = useRef<HTMLButtonElement>(null);

    const OVERLAY_NAMES_BY_ID = Object.fromEntries(overlays.map((overlay) => [overlay.id, overlay]));
    const rows = customers.map((customer) => {
        return {
            id: customer.id,
            customer: customer.customer,
            sub_fleet: customer.sub_fleet,
            timestamp: customer.timestamp,
            ...Object.fromEntries(
                customer.overlays.map((overlay_id) => [OVERLAY_NAMES_BY_ID[overlay_id]?.feature || 'N/A', overlay_id]),
            ),
        };
    });
    const filteredRows = rows.filter(
        (row) =>
            row.customer.toLowerCase().includes(searchText.toLowerCase()) ||
            row.sub_fleet.toLowerCase().includes(searchText.toLowerCase()),
    );

    const rowIdtoRowNum = Object.fromEntries(rows.map((row, index) => [row.id, index]));

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${CustomerConfigPageDefs.name}`)}`;
    }, []);

    const SelectEditInputCell = (props: GridRenderWithOptions) => {
        const { id, field } = props;
        const handleChange = async (event: SelectChangeEvent) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <Select
                id={`${props.field.replaceAll('_', '-')}-input`}
                data-testid={`${props.id}-${props.field.replaceAll('_', '-')}-input`}
                value={props.value}
                onChange={handleChange}
                size="small"
                sx={{
                    height: '100%',
                    width: '100%',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                    },
                }}
                inputProps={{ sx: { pt: 0.75, pl: 1.25, fontSize: 14 } }}
                native
            >
                {!props.value ? <option value={undefined}>N/A</option> : null}
                {props.options.map((option, idx) => (
                    <option key={idx} value={option}>
                        {option && props.t ? props.t(option) : option}
                    </option>
                ))}
            </Select>
        );
    };

    const renderSelectEdit = (params: GridRenderCellParams) => {
        return (
            <SelectEditInputCell
                options={overlays
                    .filter((overlay) => overlay.feature === params.field)
                    .map((overlay) => overlay.id.toString())}
                t={(s: string) => overlays.find((overlay) => overlay.id.toString() === s)?.name || s}
                {...params}
            />
        );
    };

    const renderCell = (params: GridRenderCellParams) => {
        return (
            <Tooltip
                sx={{ width: '100%' }}
                title={
                    params.field == 'version' || OVERLAY_NAMES_BY_ID[params.value] === undefined
                        ? undefined
                        : JSON.stringify(OVERLAY_NAMES_BY_ID[params.value].overlay, null, 2)
                }
                onContextMenu={(e) => {
                    e.preventDefault();
                    setContextMenu({ mouseX: e.clientX, mouseY: e.clientY, overlayId: params.value });
                }}
            >
                {OVERLAY_NAMES_BY_ID[params.value] ? (
                    <Typography>{OVERLAY_NAMES_BY_ID[params.value].name}</Typography>
                ) : (
                    <Typography sx={{ color: 'lightGrayColor.main' }}>N/A</Typography>
                )}
            </Tooltip>
        );
    };

    const showConfigCell = (params: GridRenderCellParams) => {
        return (
            <IconButton
                id={`${params.id}-show-btn`}
                data-testid={`${params.id}-show-btn`}
                onClick={() => setShowConfigRow(params.row.id)}
                color="primary"
            >
                <FindInPageIcon />
            </IconButton>
        );
    };

    const processRowUpdate = (newRow: GridRowModel, prevRow: GridRowModel) => {
        // reformat the date fields before sending to the server
        const { ...formattedRow } = { ...newRow };
        if (JSON.stringify(formattedRow) === JSON.stringify(prevRow)) {
            // the row is unchanged, so don't update
            return prevRow;
        }

        return controlApi
            .controlV0CustomerConfigPost({ body: newRow })
            .then((res) => {
                setCustomers((prev: Array<CustomerConfig>) => {
                    return prev.map((row) => {
                        if (row.id === newRow.id) {
                            return res.data;
                        }
                        return row;
                    });
                });
                return newRow;
            })
            .catch((err) => {
                const errorMessage = err.response.data.details.split('. ')[1];
                const errorDetails = t(`content.flavor.errors.${errorMessage}`, errorMessage);
                setAlert({
                    message: `${t('content.flavor.update_error')}. ${errorDetails}`,
                    type: 'error',
                    duration: 6000,
                });
                return err;
            });
    };

    const columns: GridColDef[] = useMemo(() => {
        const columns: GridColDef[] = [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'show',
                width: 75,
                renderCell: showConfigCell,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
                disableColumnMenu: true,
                disableExport: true,
            },
            {
                field: 'id',
                type: 'number',
                headerName: 'id',
                headerAlign: 'left',
                align: 'left',
                width: 50,
                editable: false,
                hideable: true,
            },
            {
                field: 'customer',
                type: 'string',
                headerName: 'customer',
                headerAlign: 'left',
                align: 'left',
                width: 150,
                editable: false,
                hideable: true,
            },
            {
                field: 'sub_fleet',
                type: 'string',
                headerName: 'sub_fleet',
                headerAlign: 'left',
                align: 'left',
                width: 150,
                editable: true,
                hideable: true,
            },
            {
                field: 'timestamp',
                type: 'number',
                headerName: 'timestamp',
                headerAlign: 'left',
                align: 'left',
                width: 150,
                renderCell: (params) => dayjs.unix(params.value).format(profile.dateTimeFormat),
                editable: true,
                hideable: true,
            },
        ];
        const overlayUniqueColumns = [...new Set(overlays.map((overlay) => overlay.feature))];
        // sort overlayUniqueColumns version is first, than abc
        overlayUniqueColumns.sort((a, b) => {
            if (a === 'version') {
                return -1;
            }
            if (b === 'version') {
                return 1;
            }
            return a.localeCompare(b);
        });
        columns.push(
            ...overlayUniqueColumns.map((column): GridColDef => {
                return {
                    field: column,
                    type: 'string',
                    headerName: column,
                    headerAlign: 'left',
                    align: 'left',
                    width: 150,
                    renderCell: renderCell,
                    renderEditCell: renderSelectEdit,
                    valueFormatter: (params: GridValueFormatterParams) =>
                        OVERLAY_NAMES_BY_ID[params.value]?.name || (
                            <Typography sx={{ color: 'lightGrayColor.main' }}>N/A</Typography>
                        ),
                    editable: true,
                    hideable: true,
                };
            }),
        );
        return columns;
    }, [overlays]);

    const selectedCustomer = customers.find((customer) => customer.id === showConfigRow);
    const selectedOverlay = overlays.find((overlay) => overlay.id === showEditOverlay);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                pb: 2,
            }}
        >
            {alertElement}
            {!!selectedCustomer ? (
                <ConfigModal
                    customer={selectedCustomer.customer}
                    sub_fleet={selectedCustomer.sub_fleet}
                    onClose={() => setShowConfigRow(-1)}
                />
            ) : null}
            {!!selectedOverlay || showEditOverlay === 0 ? (
                <UpdateOverlay
                    overlay={selectedOverlay || ({ id: 0 } as Overlay)}
                    onClose={() => setShowEditOverlay(-1)}
                    onSuccess={(newOverlay: Overlay) =>
                        setOverlays((prev: Overlay[]) => {
                            const oldOverlay = prev.find((overlay) => overlay.id === newOverlay.id);
                            return oldOverlay
                                ? prev.map((overlay) => (newOverlay.id === overlay.id ? newOverlay : overlay))
                                : [...prev, newOverlay];
                        })
                    }
                />
            ) : null}
            {showAddCustomer ? (
                <AddCustomerModal onClose={() => setShowAddCustomer(false)} onSuccess={loadCustomer} />
            ) : null}
            <Menu
                open={contextMenu !== null}
                onClose={() => setContextMenu(null)}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                }
            >
                <MenuItem
                    id="edit-overlay-menu-item"
                    onClick={() => {
                        setShowEditOverlay(contextMenu?.overlayId || -1);
                        setContextMenu(null);
                    }}
                >
                    Edit Overlay
                </MenuItem>
            </Menu>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                {/** Actions are hidden for now */}
                <Box
                    id="actions"
                    sx={{
                        width: '100%',
                        display: 'none',
                        justifyContent: 'flex-end',
                        mb: 1,
                        gap: 1,
                    }}
                >
                    <Button
                        id="btn-add-overlay"
                        variant="contained"
                        color="primary"
                        onClick={() => setShowEditOverlay(0)}
                        startIcon={<AddIcon />}
                    >
                        Add Overlay
                    </Button>
                    <Button
                        id="btn-add-customer"
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAddCustomer(true)}
                        startIcon={<AddIcon />}
                    >
                        Add Customer
                    </Button>
                </Box>
                <StyledDataGrid
                    id="customer-config-data-grid"
                    apiRef={apiRef}
                    density="compact"
                    columns={columns}
                    rows={filteredRows}
                    editMode="row"
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={() => null}
                    disableMultipleRowSelection
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    disableRowSelectionOnClick
                    pagination={true}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={setRowModesModel}
                    getCellClassName={(params: GridCellParams) => `${params.field}-${rowIdtoRowNum[params.row.id]}`}
                    getRowClassName={(params: GridRowModel) => `row-${params.id}`}
                    initialState={{
                        pinnedColumns: { left: ['customer', 'sub_fleet'] },
                        sorting: {
                            sortModel: [{ field: 'customer', sort: 'asc' }],
                        },
                    }}
                    localeText={{
                        ...gridLocalization[i18n.languages[0]],
                        filterPanelRemoveAll: t('table.remove_all'),
                        columnMenuManageColumns: t('table.manage_columns'),
                        unpin: t('table.unpin'),
                    }}
                    slots={{ toolbar: QuickSearchToolbar }}
                    slotProps={{
                        panel: {
                            anchorEl: buttonRef.current,
                            placement: 'bottom-end',
                        },
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value),
                            refresh: () => props.loadCustomer(),
                            children: props.toolbar,
                            filterButtonRef: buttonRef,
                        },
                        pagination: {
                            sx: {
                                mr: '45px',
                            },
                        },
                        columnsPanel: {
                            sx: {
                                '& .MuiDataGrid-panelFooter button:first-child': {
                                    display: 'none',
                                },
                            },
                        },
                        baseCheckbox: {
                            inputProps: {
                                className: 'select-checkbox',
                            },
                        },
                    }}
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& .MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScrollerContent': {
                            borderBottom: `1px solid ${palette.neutral[400]}`,
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cell--editing': {
                            padding: '0 !important',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        backgroundColor: palette.neutral[50],
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default CustomerConfigComponent;
