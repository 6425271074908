import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, Typography } from '@mui/material';
import { GridColDef, GridRowParams, GridValueFormatterParams, getGridStringOperators } from '@mui/x-data-grid-pro';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { HosDriver } from '../../../../backendsdk';
import {
    TrackedButton as Button,
    TrackedLink as Link,
    TrackedDataGrid as StyledDataGrid,
} from '../../../../components/TrackedComponents';
import useApi from '../../../../hooks/api';
import useIsMobile from '../../../../hooks/isMobile';
import useProfile from '../../../../hooks/profile';
import { getDateColumnType, localeObjectMap } from '../../../../utils/DataGridDateTime';
import { logEditsPageDefs } from '../../../../utils/Pages';
import { formatDayjs, timestampToDateString } from '../../../../utils/TimeFormatter';
import palette from '../../../ColorPalette';
import { DEFAULT_TITLE } from '../../../Layout';
import { DriverRecord, RECORD_CODE, RECORD_STATUS, abbrMap } from '../Defs';
import { driverResponseMap, getEditColumns } from '../DriverLogs/EditRequestsDataGrid';
import { Edit } from '../DriverLogs/Graph';
import { timestampsToRangeString } from '../DriverLogs/RecordsDataGrid';
import NewEditRequest from './NewEditRequest';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

export interface ExtendedEdit extends Edit {
    driver_id: number;
}

export interface LogEditsComponentProps {
    now: Dayjs;
}

const LogEditsComponent: React.FC<LogEditsComponentProps> = (props: LogEditsComponentProps) => {
    const [fromDate, setFromDate] = useState<Dayjs>(props.now.subtract(7, 'day'));
    const [toDate, setToDate] = useState<Dayjs>(props.now);
    const [requests, setRequests] = useState<ExtendedEdit[]>([]);
    const [drivers, setDrivers] = useState<Record<number, HosDriver>>({});
    const [newRequest, setNewRequest] = useState<boolean>(false);
    const [isLoadingDrivers, setIsLoadingDrivers] = useState<boolean>(true);
    const [isLoadingRequests, setIsLoadingRequests] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const history = useHistory();
    const { api, hosApi } = useApi();
    const { i18n, t } = useTranslation();
    const isMobile = useIsMobile();
    const { profile } = useProfile();
    const locale = localeObjectMap[i18n.languages[0]];

    const getDrivers = () => {
        setIsLoadingDrivers(true);
        api.hosV0SettingsCustomerGet()
            .then((res) => {
                const allDrivers = res.data.drivers.map((driver: HosDriver) => {
                    return [driver.driver_id, driver];
                });
                setDrivers(Object.fromEntries(allDrivers));
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoadingDrivers(false));
    };

    const getRequests = (controller?: AbortController) => {
        setIsLoadingRequests(true);
        hosApi
            .hosV0RecordAmendDateFromDateToGet(
                { dateFrom: formatDayjs(fromDate), dateTo: formatDayjs(toDate) },
                { signal: controller?.signal },
            )
            .then((res) => {
                setRequests(
                    res.data.map((pair: DriverRecord[]) => {
                        return {
                            id: pair[0].id,
                            startTime: pair[0].event_datetime,
                            endTime: pair[1].event_datetime,
                            comment: pair[0].comment,
                            recordCode: pair[0].record_code,
                            status: pair[0].record_status,
                            driver_id: pair[0].driver,
                        };
                    }),
                );
            })
            .catch(() => {
                if (!controller?.signal.aborted) {
                    setIsError(true);
                }
            })
            .finally(() => setIsLoadingRequests(false));
    };

    useEffect(() => {
        document.title = `${DEFAULT_TITLE} | ${t(`navigator.${logEditsPageDefs.name}`)}`;
    }, []);

    useEffect(() => getDrivers(), []);

    useEffect(() => {
        if (toDate.isSameOrAfter(fromDate, 'date')) {
            const controller = new AbortController();
            getRequests(controller);
            return () => controller.abort();
        }
    }, [fromDate, toDate]);

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'driver',
                headerName: 'Driver',
                width: 150,
                filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
            },
            {
                field: 'date',
                headerName: 'Date',
                ...getDateColumnType(locale),
                valueFormatter: (params: GridValueFormatterParams<string>) =>
                    dayjs(params.value).format(profile.dateFormat),
                width: 120,
            },
            ...getEditColumns(true),
        ],
        [],
    );

    const filteredRequests = requests.filter((request) => drivers[request.driver_id] !== undefined);

    const rows = filteredRequests.map((request) => {
        const driver = drivers[request.driver_id];
        return {
            id: request.id,
            driver_id: request.driver_id,
            driver: `${driver.first_name} ${driver.last_name}`,
            date: timestampToDateString(request.startTime, driver),
            time: timestampsToRangeString(request.startTime, request.endTime, profile, driver),
            duration: request.endTime - request.startTime,
            status: abbrMap[RECORD_CODE[request.recordCode as number] as keyof typeof abbrMap],
            comment: request.comment,
            response:
                request.status !== undefined
                    ? driverResponseMap[RECORD_STATUS[request.status] as keyof typeof RECORD_STATUS]
                    : '',
        };
    });

    const handleCreateNewRequest = (driverId: string, dateStr: string) => {
        history.push(`/driver_logs/${driverId}/${dateStr}/0`);
    };

    let content = null;
    if (isLoadingDrivers || isLoadingRequests) {
        content = <CircularProgress />;
    } else if (isError) {
        content = (
            <Typography variant="overline">
                Error fetching edit requests.{' '}
                <Link
                    id="try-again-link"
                    component="button"
                    variant="overline"
                    onClick={() => {
                        setIsError(false);
                        getDrivers();
                        getRequests();
                    }}
                >
                    Try again.
                </Link>
            </Typography>
        );
    } else if (requests.length === 0) {
        content = <Typography variant="overline">No edit requests within the specified dates</Typography>;
    } else {
        content = (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                <StyledDataGrid
                    id="log-edits-data-grid"
                    rows={rows}
                    columns={columns}
                    columnBuffer={7}
                    disableRowSelectionOnClick
                    disableVirtualization={isMobile}
                    pagination={isMobile}
                    onRowClick={(params: GridRowParams) =>
                        history.push(`/driver_logs/${params.row.driver_id}/${params.row.date}/${params.id}`)
                    }
                    slotProps={{
                        columnsPanel: {
                            sx: {
                                '& .MuiDataGrid-panelFooter button:first-child': {
                                    display: 'none',
                                },
                            },
                        },
                    }}
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        backgroundColor: palette.neutral[50],
                    }}
                />
            </LocalizationProvider>
        );
    }

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '1200px', height: '100%', pb: 2 }}
        >
            {newRequest ? (
                <NewEditRequest
                    drivers={Object.values(drivers)}
                    onSubmit={handleCreateNewRequest}
                    onClose={() => setNewRequest(false)}
                />
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                    mb: 2,
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="From"
                            format={profile.dateFormat}
                            value={fromDate}
                            onChange={(value: Dayjs | null) => {
                                if (value !== null) {
                                    setFromDate(value);
                                }
                            }}
                            maxDate={toDate}
                            slotProps={{ textField: { sx: { mr: 2 } } }}
                        />
                        <DatePicker
                            label="To"
                            format={profile.dateFormat}
                            value={toDate}
                            maxDate={props.now}
                            onChange={(value: Dayjs | null) => {
                                if (value !== null) {
                                    setToDate(value);
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Box>
                <Button
                    id="btn-new-request"
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="secondary"
                    disabled={Object.keys(drivers).length === 0}
                    onClick={() => setNewRequest(true)}
                >
                    New Request
                </Button>
            </Box>
            {content}
        </Box>
    );
};

export default LogEditsComponent;
